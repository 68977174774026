import React, { Component } from "react";
import { Link } from "react-router-dom";
import Checkboxes from "../loginCheckbox.js";

import Grid from "@material-ui/core/Grid";

class LoginUtilityLogBuild extends Component {
  constructor() {
    super();
    this.checkboxesRef = React.createRef();
  }

  render() {
    return (
      <Grid container alignItems="center">
        <Checkboxes ref={this.checkboxesRef} />
        <Grid className="box-forget-password">
          <Link to="/logbuild/forgot-password" className="txt-forget-passowrd">
            パスワードを忘れましたか？
          </Link>
        </Grid>
      </Grid>
    );
  }
}

export default LoginUtilityLogBuild;
