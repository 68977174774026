import Component from "./Common";
import { translateMessage } from "utils/mapper";

export default class Upload extends Component {
  sitesUC = null;

  constructor(props) {
    super(props);
    this.state = {
      models: []
    };
  }
  fetch() {
    this.props
      .search(this.sitesUC)
      .then(res => {
        if (res.data === undefined) return Promise.reject();
        return res.data;
      })
      .then(models => this.setState({ models }));
    // .catch(e => console.log(e));
  }

  upload(event) {
    const element = event.target;
    const files = element.files;
    const exts = element.accept.split(',').map(ext => ext.toLowerCase());
    var containsInvalidMimeType = false;
    for(var i = 0; i < files.length; i++) {
      var file = files[i];
      containsInvalidMimeType = !exts.some(ext => file.name.toLowerCase().endsWith(ext));
      if(containsInvalidMimeType) {
        break;
      }
    }
    if(containsInvalidMimeType) {
      const message = translateMessage(
        this.props.message.mimetype,
        this.props.regexObject
      );
      alert(message);
      return;
    }
    const formData = new FormData();
    formData.append("sitesUC", this.sitesUC);
    for (var i = 0; i < files.length; i++) formData.append("file", files[i]);
    this.props.postFile(formData);
    element.value = "";
  }
}
