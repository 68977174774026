import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import AddIcon from "@material-ui/icons/Add";

import ShopDialog from "./shopDialog";

import { fetchBuilderList } from "./service/service";
import { POST_BUILDER_SUCCESS } from "./constant/constant";
import PaginationControl from "../../common/Pagination";
import SearchField from "../SearchField";

class Shop extends Component {
  state = {
    isDialogShow: false,
    toggleDialogDelete: false,
    builderData: [],
    buildersFilter: [],
    furiganaSearch: ""
  };

  componentDidMount() {
    this.props.fetchBuilderList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type && nextProps.type === POST_BUILDER_SUCCESS) {
      if (nextProps.uniqueCode !== undefined) {
        this.props.history.push(`/logbuild/builders/${nextProps.uniqueCode}`);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(
      prevProps.builders !== this.props.builders ||
      prevState.furiganaSearch !== this.state.furiganaSearch
    ) {
      this.filter(this.state.furiganaSearch);
    }
  }

  toggleDialog = () => {
    this.setState({
      isDialogShow: !this.state.isDialogShow
    });
  };

  onClickShopItem = uniqueCode => () => {
    this.props.history.push(`/logbuild/builders/${uniqueCode}`);
  };

  onChangeData = data => {
    this.setState({
      builderData: data
    });
  };

  onSearchChange = data => {
    this.setState({
      furiganaSearch: data
    });
  };

  filter = furigana => {
    let FilterArray = this.props.builders.filter(function (element) {
      return element.furigana.includes(furigana) || element.name.includes(furigana);
    });
    this.setState({
      buildersFilter: FilterArray
    });
  };


  render() {
    const { isDialogShow, builderData, toggleDialogDelete } = this.state;

    return (
      <Grid container wrap="nowrap" className="sites-staft">
        <Grid container className="sites-staft">
          <Grid container justify="space-between" className="padding-content">
            <Grid item style={{marginRight: "16px"}}>
              <SearchField
                placeholder="顧客名から検索"
                setValue={this.onSearchChange}
                value={this.state.furiganaSearch}
              />
            </Grid>
            <Grid item>
              <Button
                onClick={this.toggleDialog}
                size="small"
                variant="text"
                className="button-add-new"
              >
                <AddIcon />
                会社登録
              </Button>
            </Grid>
          </Grid>
          <TableContainer>
            <Table size="medium" aria-label="table" className="sites-table">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell className="white-text padding-first-th">
                    顧客名
                  </TableCell>
                  <TableCell className="white-text">ふりがな</TableCell>
                  <TableCell className="white-text">登録日</TableCell>
                  <TableCell className="white-text">契約更新日</TableCell>
                  <TableCell className="white-text">契約ロボット台数</TableCell>
                  <TableCell className="white-text"> ステータス</TableCell>
                  <TableCell className="white-text">利用停止日</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {builderData.map((item, index) => (
                  <TableRow
                    key={index}
                    onClick={this.onClickShopItem(item.uniqueCode)}
                    className="cursor-pointer"
                  >
                    <TableCell className="padding-first-td">
                      {item.name}
                    </TableCell>
                    <TableCell>{item.furigana}</TableCell>
                    <TableCell>
                      {item.agreeMentDate &&
                        format(new Date(item.agreeMentDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell>
                      {item.contractRenewDate &&
                        format(
                          new Date(item.contractRenewDate),
                          "yyyy年M月d日"
                        )}
                    </TableCell>
                    <TableCell>{item.robotCount}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>
                      {item.cancellationDate &&
                        format(new Date(item.cancellationDate), "yyyy年M月d日")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justify="space-between" className="padding-content">
            <Button
              onClick={this.toggleDialog}
              size="small"
              variant="text"
              className="button-add-new"
            >
              <AddIcon />
                会社登録
            </Button>
          </Grid>
          <Grid container justify="center">
            <PaginationControl
              limit={20}
              datas={this.state.buildersFilter}
              changeData={this.onChangeData}
            />
          </Grid>
        </Grid>
        {(isDialogShow || toggleDialogDelete) && (
          <ShopDialog
            isDialogShow={isDialogShow}
            toggleDialogDelete={toggleDialogDelete}
            onCloseDialog={this.toggleDialog}
            isEdit={false}
          />
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  builders: state.builderReducer.builderListReducer.builders,
  uniqueCode: state.builderReducer.builderListReducer.uniqueCode,
  type: state.builderReducer.builderListReducer.type
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBuilderList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
