import {
  FETCH_FEATURE_LIST_SUCCESSS,
  PUT_FEATURE_SUCCESS,
  GET_FEATURE_DETAIL_SUCCESS
} from "../constant/constant";

const initialState = {
  features: [],
  featureDetail: {},
};

export const featureListReducer = (originState = initialState, action) => {
  const state = { ...originState, type: action.type };
  switch (action.type) {
    case FETCH_FEATURE_LIST_SUCCESSS:
      return {
        ...state,
        features: action.features
      };
    case PUT_FEATURE_SUCCESS:
      return {
        ...state,
        uniqueCode: action.data.uniqueCode
      };
    case GET_FEATURE_DETAIL_SUCCESS:
      return {
        ...state,
        featureDetail: action.featureDetail
      };

    default:
      return state;
  }
};

export const getFeatures = state => state.featureReducer.features;
