import {FETCH_AUTHS_PENDING,FETCH_AUTHS_SUCCESS,FETCH_AUTHS_ERROR} from '../constant/constant';

const initialState = {
  pending: false,
  store: [],
  error: null
};
export const authsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUTHS_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_AUTHS_SUCCESS:
      return {
        ...state,
        pending: false,
        auth: action.auths
      };
    case FETCH_AUTHS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };

    default:
      return state;
  }

};
export const getAuths = state => state;