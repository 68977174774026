import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import InputControl from "../../common/InputControl";
import Grid from "@material-ui/core/Grid";
import { Formik, Field } from "formik";
import { forgotPasswordLogBuild } from "./service/service";
import { forgotPassworLogBuildSuccess } from "./action/action";
import { dispatchError } from "../../common/Stores/service/service";
import "../../common/css/base.css";
import "../../common/css/auth.css";

class ForgotPasswordLogBuild extends Component {
  componentDidMount() {
    this.props.forgotPassworLogBuildSuccess(false);
    this.props.dispatchError(null);
  }

  checktButtonClick = formik => e => {
    e.preventDefault();
    formik.handleSubmit();
  };

  onSubmit = id => {
    this.props.forgotPasswordLogBuild(id);
  };

  render() {
    const { emailConfirmLogBuild, error } = this.props;
    const values = {
      id: ""
    };

    return (
      <Formik
        initialValues={values}
        enableReinitialize
        onSubmit={values => this.onSubmit(values)}
        render={formik => (
          <form onSubmit={this.checktButtonClick(formik)}>
            <Grid className="area-login-wrap">
              <Grid container justify="space-between">
                <Grid className="area-logo" />
                <Grid className="area-login">
                  <Grid>
                    <h4 className="ttl-login">パスワードのリセット</h4>
                    {emailConfirmLogBuild ? (
                      <p className="txt-login-sub">
                        パスワード再設定のためのメールを送信しました。​
                        <br />
                        メールを確認して下さい。
                      </p>
                    ) : (
                      <p className="txt-login-sub">
                        メールアドレスを入力して下さい。
                        <br />
                        パスワード再設定のためのメールを送信します。
                      </p>
                    )}
                  </Grid>
                  <Grid className="wrap-form">
                    {!emailConfirmLogBuild && (
                      <Grid>
                        <Field
                          label="メールアドレス​"
                          name="id"
                          type="text"
                          onChange={formik.handleChange}
                          variant="filled"
                          className="input-login"
                          component={InputControl}
                        />
                        <span className="required">
                          {error && error.id && (
                            <span className="error">{error.id}</span>
                          )}
                        </span>
                      </Grid>
                    )}
                    <Grid container justify="center">
                      {!emailConfirmLogBuild ? (
                        <button type="submit" className="btn-login">
                          メールを送信​
                        </button>
                      ) : (
                        <button type="button" className="btn-login btn-link">
                          <Link
                            to="/logbuild/login"
                            className="white-text decorator-none"
                          >
                            ログイン画面に戻る
                          </Link>
                        </button>
                      )}
                      {!emailConfirmLogBuild && (
                        <Link
                          to="/logbuild/login"
                          className="return-login text-blue mt-20"
                        >
                          ログイン画面に戻る
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  error: state.storesReducer.error,
  type: state.auth.type,
  emailConfirmLogBuild: state.auth.emailConfirmLogBuild
});

export default connect(mapStateToProps, {
  forgotPasswordLogBuild,
  forgotPassworLogBuildSuccess,
  dispatchError
})(ForgotPasswordLogBuild);
