import React, { useRef } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Button, Grid, Tooltip, IconButton } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/ChevronRight";
import Component from "../../../Component";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import FilterListIcon from "@material-ui/icons/FilterList";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import RoomIcon from "@material-ui/icons/Room";
import { ResizeSensor } from "css-element-queries";
import { setDrawingPhoto } from "../action/action";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { TYPE_SELECT_IMAGE_3D, TYPE_DIALOG, MODE_DEVICE } from "./constants";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import PanToolOutlinedIcon from "@material-ui/icons/PanToolOutlined";
import DrawingPhoto from "../DrawingPhoto";

class MiniWindowViewer extends React.Component {
  cancelSubmission = false;

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      urlPhoto: undefined,
      currentDrawingWithSpots: undefined,
      listSpots: [],
      drawingName: "",
      drawing: {},
      heightMiniWindow: 80,
      widthMiniWindow: 120,
      isSwitch: false
    };
    this.transformComponentRef = React.createRef();
  }

  componentDidMount() {
    const { drawingsWithSpots, drawingCode, drawingPhoto } = this.props;
    window.addEventListener("resize", this.updateDimensions);
    if (drawingsWithSpots && drawingCode) {
      const listSpot = drawingsWithSpots.find(
        item => item.uniqueCode === drawingCode
      );
      this.setState({
        urlPhoto: drawingPhoto,
        listSpots: (listSpot && listSpot.spots) || [],
        drawingName: listSpot && listSpot.name,
        drawing: listSpot
      });
    }
    const element = document.getElementById("container-mini-viewer");
    new ResizeSensor(element, () => {
      if(!this.cancelSubmission){
        this.setState({
          widthMiniWindow: element.offsetWidth + 2, // sum padding 2 px
          heightMiniWindow: element.offsetHeight + 2
        });
      }
      
      this.updateDimensions();
    });

    const currentIndex = drawingsWithSpots.findIndex(
      item => item.uniqueCode === drawingCode
    );

    const drawing = drawingsWithSpots[currentIndex];
    if (drawing) {
      this.props.onSetDrawingPhoto({
        drawingPhoto: drawing.path,
        drawingBlob: drawing.blob,
        drawingCode: drawing.uniqueCode
      });
    }
  }

  updateDimensions = e => {
    this.setPositionMarker();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.drawingPhoto !== this.props.drawingPhoto ||
      prevProps.drawingCode !== this.props.drawingCode
    ) {
      if (this.props.drawingsWithSpots) {
        const listSpot = this.props.drawingsWithSpots.find(
          item => item.uniqueCode === this.props.drawingCode
        );
        this.setState(
          {
            urlPhoto: this.props.drawingPhoto,
            listSpots: (listSpot && listSpot.spots) || [],
            drawingName: listSpot && listSpot.name,
            drawing: listSpot
          },
          () => {
            if (prevProps.drawingPhoto === this.props.drawingPhoto) {
              this.setPositionMarker();
            }
          }
        );
      }
    }
    if (this.state.isSwitch) {
      this.setState({
        isSwitch: false
      });
    }

    if(prevProps.spotCurrent !== this.props.spotCurrent && this.props.drawingsWithSpots) {
      const currentIndex = this.props.drawingsWithSpots.findIndex(
        itemDrawing => itemDrawing.spots.find(itemSpot => itemSpot.uniqueCode === this.props.spotCurrent)
      );
      if (currentIndex >= 0) {  
        const drawing = this.props.drawingsWithSpots[currentIndex];
        this.props.onSetDrawingPhoto({
          drawingPhoto: drawing.path,
          drawingBlob: drawing.blob,
          drawingCode: drawing.uniqueCode
        });
      }
    }
  }

  componentWillUnmount(){
    this.cancelSubmission = true;
  }

  returnDefaultSize = () => {
    let currentRef = this.transformComponentRef.current;
    if (currentRef != null) {
      currentRef.context.dispatch.resetTransform();
    }
  };
  onSwitchDrawing = switchType => {
    const { drawingCode, drawingsWithSpots } = this.props;
    const currentIndex = drawingsWithSpots.findIndex(
      item => item.uniqueCode === drawingCode
    );
    if (switchType === "prev") {
      const drawingPrev = drawingsWithSpots[currentIndex - 1].path;
      const drawingPrevBlob = drawingsWithSpots[currentIndex - 1].blob;
      const drawingPrevCode = drawingsWithSpots[currentIndex - 1].uniqueCode;
      this.props.onSetDrawingPhoto({
        drawingPhoto: drawingPrev,
        drawingBlob: drawingPrevBlob,
        drawingCode: drawingPrevCode
      });
    } else {
      const drawingNext = drawingsWithSpots[currentIndex + 1].path;
      const drawingNextBlob = drawingsWithSpots[currentIndex + 1].blob;
      const drawingNextCode = drawingsWithSpots[currentIndex + 1].uniqueCode;
      this.props.onSetDrawingPhoto({
        drawingPhoto: drawingNext,
        drawingBlob: drawingNextBlob,
        drawingCode: drawingNextCode
      });
    }
    this.setState(
      {
        isSwitch: true
      },
      () => {
        setTimeout(this.returnDefaultSize, 100);
      }
    );
  };

  handleDisableSwitch = switchType => {
    const { drawingCode, drawingsWithSpots } = this.props;
    const currentIndex = drawingsWithSpots.findIndex(
      item => item.uniqueCode === drawingCode
    );
    //disable both button if spots is empty
    if (drawingsWithSpots.length === 0) {
      return true;
    }
    if (currentIndex === 0 && switchType === "prev") {
      return true;
    } else if (
      currentIndex === drawingsWithSpots.length - 1 &&
      switchType === "next"
    ) {
      return true;
    }
    return false;
  };

  setPositionMarker = () => {
    if (!this.cancelSubmission) {
      const posAfter = [];
      let element = document.getElementById("primary-image");

      let naturalWidth = element ? element.naturalWidth : 0;

      let naturalHeight = element ? element.naturalHeight : 0;

      const elMap = document.getElementsByClassName("img-mini-parent")[0];
      let scaleMap = elMap ? elMap.offsetWidth / elMap.offsetHeight : 0;
      let isVerticalImg = naturalHeight * scaleMap > naturalWidth;

      let offsetWidth = 0;
      let offsetHeight = 0;

      if (isVerticalImg) {
        offsetWidth = elMap
          ? (elMap.offsetHeight * naturalWidth) / naturalHeight
          : 0;

        offsetHeight = elMap ? elMap.offsetHeight : 0;
      } else {
        offsetWidth = elMap ? elMap.offsetWidth : 0;

        offsetHeight = elMap
          ? (elMap.offsetWidth * naturalHeight) / naturalWidth
          : 0;
      }

      let offsetLeft = elMap ? (elMap.offsetWidth - offsetWidth) / 2 : 0;

      let offsetTop = elMap ? (elMap.offsetHeight - offsetHeight) / 2 : 0;
      this.state.listSpots.map(pos => {
        posAfter.push({
          uniqueCode: pos.uniqueCode,
          name: pos.name,
          posX: (pos.x * offsetWidth) / naturalWidth + offsetLeft,
          posY: (pos.y * offsetHeight) / naturalHeight + offsetTop
        });
      });
      this.setState({
        currentDrawingWithSpots: {
          isVerticalImg: isVerticalImg,
          positions: posAfter
        }
      });
    }
  };

  showDialogViewer = () => {
    this.props.onShowDialogViewer(true);
    this.props.onSetTypeDialog({
      typeDialog: TYPE_DIALOG.ZOOM_IMAGE,
      modeDevice: MODE_DEVICE.SP
    });
  };

  handleShowPanelAdjust = () => {
    const { showPanelAdjustImage, onSetImageShootUnAdjusted } = this.props;
    this.props.onShowPannelAdjust(!showPanelAdjustImage);
    onSetImageShootUnAdjusted({});
  };

  onAdjustImage = () => {
    const { showPanelAdjustImage, logWalkInfo, classification } = this.props;
    // if (!showPanelAdjustImage) {
    //   this.props.onGetUnAdjustPhoto(logWalkInfo.sitesUC, classification);
    // }
    this.handleShowPanelAdjust();
  };

  chooseShootSpot = value => {
    this.props.onResetFlagRedirectNoImage();
    // if (!this.props.imageShootUnAdjusted.shootCode) {
    const { historyList } = this.props;
    const shoot = this.props.listShootCurrentActive.find(
      item => item.spotsUC === value.uniqueCode || item.spotsUC === value
    );
    this.props.resetPreviousShootDirection();
    this.props.onSetSpotCurrent(value.uniqueCode);
    this.props.onSetTypeSelectImage3D(TYPE_SELECT_IMAGE_3D.SPOT);
    this.props.onSetIsDefaultHistory();
    if (!!shoot) {
      if (this.props.classification === "supplier") {
        this.props.history.replace(
          "/sp/logwalk-viewer/shoots/" + shoot.uniqueCode
        );
      } else {
        this.props.history.replace(
          "/logwalk-viewer/shoots/" + shoot.uniqueCode
        );
      }
    } else {
      this.props.fetchShootLogWalk();
      if (!this.props.viewerUnAdjustedImage) {
        if (this.props.logWalkInfo.recordsUC === "default") {
          this.props.onSetHistoryViewer(
            historyList && historyList[0]
              ? historyList[0].uniqueCode
              : undefined
          );
        }
        if (this.props.classification === "supplier") {
          this.props.history.replace("/sp/logwalk-viewer/shoots/no-image");
        } else {
          this.props.history.replace("/logwalk-viewer/shoots/no-image");
        }
      } else {
        const params = `?sitesUC=${this.props.match.params.id}&spotsUC=${value.uniqueCode}`;
        if (this.props.classification === "supplier") {
          this.props.history.push(
            "/sp/logwalk-viewer/shoots/no-image" + params
          );
        } else {
          this.props.history.push("/logwalk-viewer/shoots/no-image" + params);
        }
      }
    }
    this.props.onResetWidthHeightMiniWindow();
    this.props.onResetPositionMiniWindowViewer();
    // }
  };

  checkActiveSpot = data => {
    const { listShootCurrentActive } = this.props;
    const indexActive = listShootCurrentActive.findIndex(
      item => item.spotsUC === data
    );
    return indexActive > -1;
  };

  tapToShowFullImage = () => {
    const { modeDevice } = this.props;
    if (modeDevice === MODE_DEVICE.SP) {
      this.props.onShowDialogViewer(true);
      this.props.onSetTypeDialog({
        typeDialog: TYPE_DIALOG.ZOOM_IMAGE,
        modeDevice: MODE_DEVICE.SP
      });
    }
  };

  checkShowHand = () => {
    const { widthMiniWindow, heightMiniWindow } = this.state;
    return widthMiniWindow === 120 && heightMiniWindow === 80;
  };

  render() {
    const { drawingName, isSwitch } = this.state;
    const {
      spotCurrent,
      drawingCode,
      drawingBlob,
      imageShootUnAdjusted,
      drawingPhoto,
      typeDialog
    } = this.props;

    return (
      <div
        className="container-mini-viewer"
        id="container-mini-viewer"
        style={{ direction: "ltr" }}
        onClick={e => e.preventDefault()}
      >
        <Grid container style={{ height: "100%" }}>
          <Grid container item>
            <Grid item xs={1} />
            {this.props.modeDevice !== MODE_DEVICE.SP && (
              <Grid item xs={10} alignContent="center">
                <strong>
                  <div
                    className={`image-title-viewer ${
                      this.props.isShowHand && "hand-move"
                    }`}
                  >
                    <Typography variant="h8">{drawingName}</Typography>
                  </div>
                </strong>
              </Grid>
            )}
            {this.props.modeDevice === MODE_DEVICE.SP && (
              <Grid
                item
                xs={10}
                alignContent="center"
                onClick={this.tapToShowFullImage}
              >
                <strong>
                  <div
                    className={`image-title-viewer ${
                      this.props.isShowHand && "hand-move"
                    }`}
                  >
                    <Typography variant="h8">{drawingName}</Typography>
                  </div>
                </strong>
              </Grid>
            )}
            <Grid item xs={1}>
              {this.props.modeDevice !== MODE_DEVICE.SP && (
                <Tooltip title="最大表示">
                  <IconButton
                    onClick={this.showDialogViewer}
                    disabled={imageShootUnAdjusted.shootCode}
                    size="small"
                  >
                    <ZoomOutMapIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {/*{*/}
              {/*  this.props.modeDevice === MODE_DEVICE.SP &&*/}
              {/*    this.checkShowHand() &&*/}
              {/*  <strong className="cursor">*/}
              {/*    <PanToolOutlinedIcon fontSize="small" />*/}
              {/*  </strong>*/}
              {/*}*/}
            </Grid>
          </Grid>
          {this.props.modeDevice === MODE_DEVICE.SP && (
            <Grid container item className="image-container">
              <Grid item xs={1} className="position-btn">
                <Button
                  name="prev"
                  className="btn-switch"
                  size="small"
                  onClick={() => this.onSwitchDrawing("prev")}
                  disabled={this.handleDisableSwitch("prev")}
                >
                  <KeyboardArrowLeftIcon />
                </Button>
              </Grid>
              <Grid
                item
                xs={10}
                className="img-mini-parent content-drawing-dialog-container"
              >
                <div
                // onClick={this.tapToShowFullImage}
                >
                  <div>
                    <DrawingPhoto
                      tapToShowFullImage={this.tapToShowFullImage}
                      class="img-mini"
                      id="primary-image"
                      alt=""
                      drawingsUC={drawingCode}
                      blob={drawingBlob}
                      loadMarker={this.setPositionMarker}
                      apiPath={drawingPhoto}
                      isLogWalkViewer={true}
                      spotCurrent={spotCurrent}
                      imageShootUnAdjusted={this.props.imageShootUnAdjusted}
                      checkActiveSpot={this.checkActiveSpot}
                      chooseShootSpot={this.chooseShootSpot}
                      positions={this.state.currentDrawingWithSpots?.positions}
                      isMiniWindowViewer={true}
                      isSP={true}
                    />
                  </div>
                </div>
              </Grid>
              <Grid className="position-btn" item xs={1}>
                <Button
                  name="next"
                  className="btn-switch"
                  size="small"
                  onClick={() => this.onSwitchDrawing("next")}
                  disabled={this.handleDisableSwitch("next")}
                >
                  <KeyboardArrowRight />
                </Button>
              </Grid>
            </Grid>
          )}
          {this.props.modeDevice === MODE_DEVICE.PC && (
            <>
              <TransformWrapper defaultScale={1}>
                <Grid container item className="image-container mt-5">
                  <Grid item xs={1} className="position-btn">
                    <Button
                      name="prev"
                      className="btn-switch"
                      size="small"
                      onClick={() => this.onSwitchDrawing("prev")}
                      disabled={this.handleDisableSwitch("prev")}
                    >
                      <KeyboardArrowLeftIcon />
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    className="img-mini-parent content-drawing-dialog-container"
                  >
                    <TransformComponent ref={this.transformComponentRef}>
                      <div

                      // onClick={this.tapToShowFullImage}
                      >
                        <div onClick={this.tapToShowFullImage}>
                          <DrawingPhoto
                            class="img-mini"
                            id="primary-image"
                            alt=""
                            drawingsUC={drawingCode}
                            blob={drawingBlob}
                            loadMarker={this.setPositionMarker}
                            apiPath={drawingPhoto}
                            isLogWalkViewer={true}
                            spotCurrent={spotCurrent}
                            imageShootUnAdjusted={
                              this.props.imageShootUnAdjusted
                            }
                            checkActiveSpot={this.checkActiveSpot}
                            chooseShootSpot={this.chooseShootSpot}
                            positions={
                              this.state.currentDrawingWithSpots?.positions
                            }
                            typeDialog={typeDialog}
                            isPreventMovingToLWViewer={
                              this.props.isPreventMovingToLWViewer
                            }
                          />
                        </div>
                      </div>
                    </TransformComponent>
                  </Grid>
                  <Grid className="position-btn" item xs={1}>
                    <Button
                      name="next"
                      className="btn-switch"
                      size="small"
                      onClick={() => this.onSwitchDrawing("next")}
                      disabled={this.handleDisableSwitch("next")}
                    >
                      <KeyboardArrowRight />
                    </Button>
                  </Grid>
                </Grid>
              </TransformWrapper>
              {/* <Grid container item className="mt-5">
                <Grid item xs={11} />
                <Grid item xs={1}>
                  <Tooltip title="Adjust image">
                    <IconButton
                      onClick={this.handleShowPanelAdjust}
                      size="small"
                      disabled={!historyListAdjustPhoto.length}
                    >
                      <ControlCameraIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid> */}
            </>
          )}
        </Grid>
      </div>
    );
  }
}

export default compose(withRouter)(MiniWindowViewer);
