import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRobot,
  faUserFriends,
  faTh,
  faStoreAlt
} from "@fortawesome/free-solid-svg-icons";
import FeatureFlag from "../../common/img/FeatureFlag.svg";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import "../css/base.css";
import "../../common/css/header.css";
import imageSrc from "../../common/img/logo.svg";

const menuListLogBuilds = [
  {
    title: "ダッシュボード",
    link: "/logbuild/dashboard",
    icon: (
      <FontAwesomeIcon
        size="2x"
        icon={faTh}
        className="white-text width-icon"
      />
    )
  },
  {
    title: "顧客の管理",
    link: "/logbuild/builders",
    icon: (
      <FontAwesomeIcon
        size="2x"
        icon={faStoreAlt}
        className="white-text width-icon"
      />
    )
  },
  {
    title: "ロボットの管理",
    link: "/logbuild/robots",
    icon: (
      <FontAwesomeIcon
        size="2x"
        icon={faRobot}
        className="white-text width-icon"
      />
    )
  },
  {
    title: "社員の管理",
    link: "/logbuild/employees",
    icon: (
      <FontAwesomeIcon
        size="2x"
        icon={faUserFriends}
        className="white-text width-icon"
      />
    )
  },
  {
    title: "フィーチャーフラグ​",
    link: "/logbuild/features",
    icon: (
      <img src={FeatureFlag} />
    )
  },
];

class HeaderLogBuild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowMenu: false
    };
  }

  toggleDrawer = isShow => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ isShowMenu: isShow });
  };

  sideList = menu => (
    <Grid
      role="presentation"
      onClick={this.toggleDrawer(false)}
      onKeyDown={this.toggleDrawer(false)}
    >
      <List disablePadding className="padding-y">
        {menu.map((item, index) => (
          <Link to={item.link} key={index} className="reset-link">
            <ListItem className="white-text list-item" button key={index}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          </Link>
        ))}
      </List>
    </Grid>
  );

  render() {
    const { isShowMenu } = this.state;
    const { titlePage, builderName, match } = this.props;

    return (
      <AppBar
        position="static"
        color="default"
        className="header-menu bg-red"
      >
        <Grid container wrap="nowrap">
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className="header-left valign-wrapper"
          >
            <IconButton
              size="small"
              onClick={this.toggleDrawer(true)}
              edge="start"
              className="header-menu-button"
              aria-label="menu-button"
            >
              <MenuIcon className="icon" />
            </IconButton>
            <Link
              to="/logbuild/dashboard"
              className="reset-link"
            >
              <img alt="Logo" height="50px" src={imageSrc} />
            </Link>
          </Grid>
          <Grid className="header-right valign-wrapper">
            <Typography
              variant="h5"
              className="primary-font-family white-text text-bold"
            >
              {titlePage}
            </Typography>
            <Grid className="valign-wrapper">
              <Typography
                variant="h6"
                className="primary-font-family white-text mr-15 text-bold valign-wrapper"
              >
                {builderName}
              </Typography>
              <Link
                to="/logbuild/mypage/profile"
                className="reset-link"
              >
                <IconButton
                  size="small"
                  aria-label="menu-button"
                  className="white-text"
                >
                  <AccountCircleIcon className="icon" />
                </IconButton>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Drawer
          open={isShowMenu}
          onClose={this.toggleDrawer(false)}
          className="draw-menu"
          classes={{
            paper: "draw-paper"
          }}
        >
          {this.sideList(menuListLogBuilds)}
        </Drawer>
      </AppBar>
    );
  }
}

HeaderLogBuild.propTypes = {};

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogBuild);
