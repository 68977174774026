import React from "react";
import PropTypes from "prop-types";

function IconStarFavorite(props) {
  const { isFavorite } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
    >
      <path
        fill={isFavorite ? "#FFD506" : "#c7c7c7"}
        d="M12.723 3.445a2 2 0 013.555 0l2.406 4.665a2 2 0 001.438 1.054l5.161.89a2 2 0 011.1 3.358l-3.7 3.837a2 2 0 00-.539 1.674l.764 5.279a2 2 0 01-2.878 2.073l-4.63-2.331a2 2 0 00-1.8 0l-4.634 2.332A2 2 0 016.088 24.2l.764-5.279a2 2 0 00-.539-1.674l-3.7-3.837a2 2 0 011.1-3.358l5.161-.89a2 2 0 001.442-1.052z"
      ></path>
    </svg>
  );
}

IconStarFavorite.propTypes = {
  isFavorite: PropTypes.bool
};

IconStarFavorite.defaultProps = {
  isFavorite: false
};

export default IconStarFavorite;
