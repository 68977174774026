import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchField from "../../SearchField";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

function InfoStaffDialog(props) {
  const {
    isShowDialogStaff,
    toggleDialogStaff,
    tableClass,
    staffCandidates,
    siteInfoStaff,
    handleAddStaffCandidate
  } = props;

  const [searchFurigana, setSearchFurigana] = useState("");
  const [onlyShowCurrentStaff, setOnlyShowCurrentStaff] = useState(false);

  const [allStaffs, setAllStaffs] = useState([]);
  const [showStaffs, setShowStaffs] = useState([]);

  const [selectedStaffs, setSelectedStaffs] = useState([]);
  const [showConfirmExit, setShowConfirmExit] = useState(false);

  useEffect(() => {
    let tempArr = [...siteInfoStaff, ...staffCandidates];
    tempArr = tempArr.sort((a, b) => {
      return (
        a.furigana.localeCompare(b.furigana, "jp") ||
        a.uniqueCode.localeCompare(b.uniqueCode, "en")
      );
    });
    setAllStaffs(tempArr);
  }, [siteInfoStaff, staffCandidates]);

  useEffect(() => {
    if (onlyShowCurrentStaff) {
      setShowStaffs(
        selectedStaffs.filter(
          element =>
            element.furigana.includes(searchFurigana) ||
            element.name.includes(searchFurigana)
        )
      );
    } else {
      setShowStaffs(
        allStaffs.filter(
          element =>
            element.furigana.includes(searchFurigana) ||
            element.name.includes(searchFurigana)
        )
      );
    }
  }, [allStaffs, searchFurigana, onlyShowCurrentStaff, selectedStaffs]);

  const toggleSelectStaff = staff => {
    if (staff.assignDate) return;

    if (selectedStaffs.find(item => item.uniqueCode === staff.uniqueCode)) {
      setSelectedStaffs(
        selectedStaffs.filter(item => item.uniqueCode !== staff.uniqueCode)
      );
    } else {
      setSelectedStaffs(
        [...selectedStaffs, staff].sort((a, b) => {
          return (
            a.furigana.localeCompare(b.furigana, "jp") ||
            a.uniqueCode.localeCompare(b.uniqueCode, "en")
          );
        })
      );
    }
  };

  const checkAllShowAreSelected = () => {
    for (let i = 0; i < showStaffs.length; i++) {
      const element = showStaffs[i];
      if (
        !(
          siteInfoStaff.find(item => item.uniqueCode === element.uniqueCode) ||
          selectedStaffs.find(item => item.uniqueCode === element.uniqueCode)
        )
      ) {
        return false;
      }
    }
    return true;
  };

  const checkDisableSelectAll = () => {
    if (showStaffs.length === 0) return true;

    for (let i = 0; i < showStaffs.length; i++) {
      const element = showStaffs[i];
      if (!siteInfoStaff.find(item => item.uniqueCode === element.uniqueCode)) {
        return false;
      }
    }
    return true;
  };

  const selectAll = () => {
    if (checkAllShowAreSelected()) {
      setSelectedStaffs(
        selectedStaffs.filter(
          selectedStaff =>
            !showStaffs.find(
              item => item.uniqueCode === selectedStaff.uniqueCode
            )
        )
      );
    } else {
      const tempAdd = showStaffs.filter(
        showStaff =>
          !(
            siteInfoStaff.find(
              item => item.uniqueCode === showStaff.uniqueCode
            ) ||
            selectedStaffs.find(
              item => item.uniqueCode === showStaff.uniqueCode
            )
          )
      );

      setSelectedStaffs(
        [...selectedStaffs, ...tempAdd].sort((a, b) => {
          return (
            a.furigana.localeCompare(b.furigana, "jp") ||
            a.uniqueCode.localeCompare(b.uniqueCode, "en")
          );
        })
      );
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={isShowDialogStaff}
        aria-labelledby="max-width-dialog-title"
        fullWidth
        onClose={() => {}}
        maxWidth="lg"
        PaperProps={{
          className: "dialog dialog-sign-up dialog-min-height",
          style: { height: "76vh" }
        }}
      >
        <Fab
          onClick={() => {
            if (selectedStaffs.length > 0) {
              setShowConfirmExit(true);
            } else {
              toggleDialogStaff();
            }
          }}
          size="small"
          className="white-text close-button"
        >
          <CloseIcon />
        </Fab>

        <DialogTitle disableTypography>
          <Grid container justify="space-between" className="mb-15">
            <Typography variant="h4" className="dialog-title">
              担当者の追加
            </Typography>
          </Grid>
          <Grid container justify="space-between" wrap="nowrap">
            <Grid item>
              <Grid
                container
                justify="flex-start"
                alignItems="cennter"
                wrap="wrap"
              >
                <Grid item style={{ marginRight: "24px" }}>
                  <SearchField
                    value={searchFurigana}
                    setValue={setSearchFurigana}
                    placeholder="社員名から検索"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  handleAddStaffCandidate(
                    selectedStaffs.map(item => item.uniqueCode)
                  );
                  toggleDialogStaff();
                }}
                disabled={selectedStaffs.length === 0}
                style={{
                  backgroundColor:
                    selectedStaffs.length === 0 ? "#808080" : "#162f50",
                  color: "#ffffff",
                  padding: "4px 10px",
                  fontSize: "1rem",
                  fontWeight: "normal"
                }}
                size="small"
                variant="text"
                className="button-add-new"
              >
                追加
              </Button>
            </Grid>
          </Grid>
          <Grid container style={{ margin: "4px 0px" }}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={onlyShowCurrentStaff}
                    onChange={e => setOnlyShowCurrentStaff(e.target.checked)}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={`選択中の担当者（${selectedStaffs.length}人）`}
              />
            </Grid>
          </Grid>
          <Grid container style={{ margin: "4px 0px" }}>
            <Grid item>
              <Button
                onClick={() => selectAll()}
                style={{
                  backgroundColor: checkAllShowAreSelected()
                    ? "#ffffff"
                    : "#162f50",
                  color: checkAllShowAreSelected() ? "#162f50" : "#fff",
                  padding: "4px 10px",
                  fontSize: "1rem",
                  fontWeight: "normal",
                  border: "1px solid #162f50"
                }}
                disabled={checkDisableSelectAll()}
                size="small"
                variant="text"
              >
                {checkAllShowAreSelected() ? "すべて解除" : "すべて選択"}
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table size="small" aria-label="table" className={tableClass}>
              <TableHead className="table-head">
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell className="white-text">担当者名</TableCell>
                  <TableCell className="white-text">役職</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {showStaffs.map(staff => (
                  <TableRow
                    key={staff.uniqueCode}
                    hover
                    onClick={() => toggleSelectStaff(staff)}
                    style={{ opacity: staff.assignDate ? "0.4" : "1" }}
                  >
                    <TableCell align="right" className={"info-cell-more"}>
                      {staff.assignDate ? (
                        <span style={{ fontSize: "30px", color: "#3c3c3c" }}>
                          <CheckCircleIcon fontSize="inherit" color="inherit" />
                        </span>
                      ) : selectedStaffs.find(
                          item => item.uniqueCode === staff.uniqueCode
                        ) ? (
                        <span style={{ fontSize: "30px", color: "#92D050" }}>
                          <CheckCircleIcon fontSize="inherit" color="inherit" />
                        </span>
                      ) : (
                        <span style={{ fontSize: "30px", color: "#D0CECE" }}>
                          <RadioButtonUncheckedIcon
                            fontSize="inherit"
                            color="inherit"
                          />
                        </span>
                      )}
                    </TableCell>
                    <TableCell>{staff.name}</TableCell>
                    <TableCell>{staff.jobName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showConfirmExit}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ backgroundColor: "#e0e0e0eb" }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
            marginBottom: "20px"
          }}
        >
          現場への担当者追加が完了していません。
          <br />
          終了してよろしいですか。
        </DialogTitle>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={() => {
              setShowConfirmExit(false);
              toggleDialogStaff();
            }}
            className="btn-dialog-yes"
            style={{
              color: "#FFFFFF",
              backgroundColor: "#00356D",
              margin: "0px 8px"
            }}
          >
            はい
          </Button>
          <Button
            onClick={() => setShowConfirmExit(false)}
            className="btn-dialog-no"
            autoFocus
            style={{
              color: "#FFFFFF",
              backgroundColor: "#C7C7C7",
              margin: "0px 8px"
            }}
          >
            いいえ
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default InfoStaffDialog;
