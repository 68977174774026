import React from "react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import CommonDialog from "../../CommonDialog";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";
import { getPhotos } from "./reducer/reducer";
import {
  fetchPhotoInfo
} from "./service/service";
import {
  DELETE_PHOTO_REFER,
  DELETE_PHOTO_SUCCESS,
  GET_PHOTO_INFO_SUCCESS,
} from "./constant/constant";

import DialogDelete from "../../../common/DialogDelete";
import { format } from "date-fns";
import { updateSitePhoto, deleteSitePhoto } from "./service/service";
import { translateMessage, trimObjValues } from "utils/mapper";
import {
  translateValidate,
  messageValidate
} from "../../../common/Stores/service/service";

class EditImageDialog extends CommonDialog {
  fieldList = [
    {
      name: "name",
      label: "ファイル名",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    }
  ];

  state = {
    options: [
      {
        name: "1階",
        selected: false
      },
      {
        name: "2階",
        selected: true
      },
      {
        name: "玄関",
        selected: false
      },
      {
        name: "トイレ",
        selected: true
      }
    ],
    clientError: null,
    submitFlag: false,
    isShowDialogForceDeleteFile: false,
    isRefer: false,
    isLockedPhoto: false
  };

  componentWillReceiveProps(props) {
    if (props.type === DELETE_PHOTO_REFER) {
      this.setState({
        isLockedPhoto: true
      })
      this.openDialogForceDelete();
    };

    if (props.type === DELETE_PHOTO_SUCCESS) {
      this.props.closeImage();
      this.setState({
        isLockedPhoto: false,
        anchorEl: null
      });
    };

    if (props.type === GET_PHOTO_INFO_SUCCESS) {
      const photoInfo = props.photoInfo;
      if (photoInfo) {
        this.setState(
          {
            initialValues: { name: photoInfo.name, regisionDate: photoInfo.createdAt },
            isRefer: photoInfo.isRefer,
            isLockedPhoto: false
          },
          () => {
            this.setState({
              isDialogDeleteShow: false,
              isDialogShowImageEdit: true
            });
          }
        );
      }

    }
  }

  // componentDidMount() {
  //   this.props.fetchPhotoInfo(this.props.photoId);
  // }

  componentDidMount() {
    const { islogMeet, initialValues } = this.props
    this.props.translateValidate();
    this.props.messageValidate();
    if (islogMeet != true) {
      this.props.fetchPhotoInfo(this.props.photoId);
    }
    else {
      this.setState(
        {
          initialValues: { name: initialValues.name, regisionDate: initialValues.createdAt }
        })
    }
  }

  changeStatus = (index, selected) => () => {
    const newValue = Object.assign([], this.state.options);
    newValue[index].selected = !selected;
    this.setState({
      options: newValue
    });
  };

  toggleDialogDelete = () => {
    this.props.toggleDialogDelete();
  };

  openDialogForceDelete = () => {
    this.setState({
      isShowDialogForceDeleteFile: true
    });
  };

  handleOpenDialogDelete = () => {
    if (this.state.isRefer) {
      this.openDialogForceDelete();
    }
    else {
      this.props.toggleDialogDelete();
    }
  }

  onDeleteSubmit = () => {
    if (this.props.islogMeet) {
      this.props.deleteImages(this.props.photoId);
      this.props.onClose();
      return;
    }
    this.props.deleteSitePhoto(this.props.photoId, this.state.isRefer, this.props.closeImage);
    this.setState({
      anchorEl: null,
      isShowDialogForceDeleteFile: false
    });
  };

  onForceDeleteSubmit = () => {
    // this.props.deleteSitePhoto(this.props.photoId, true, this.props.closeImage);
    // if (this.props.closeImage !== undefined) {
    //   this.props.closeImage();
    // }
    if (this.state.isLockedPhoto) {
      this.props.deleteSitePhoto(this.props.photoId, true, this.props.closeImage);
      if (this.props.closeImage !== undefined) {
        this.props.closeImage();
      }
    }
    else {
      this.setState({
        isShowDialogForceDeleteFile: false
      });
      this.props.openDialogDelete();
    }

  };

  closeDialogForceDelete = () => {
    // this.props.toggleDialogDelete();
    this.setState({
      isShowDialogForceDeleteFile: false
    });
  };

  onSubmit = values => {
    if (this.props.islogMeet) {
      let clientError = null;

      const trimedValues = trimObjValues(values);

      if (!trimedValues.name) {
        clientError = {
          ...clientError,
          name: translateMessage(
            this.props.message.required,
            this.props.regexObject
          )
        };
      }
      this.setState({
        clientError: clientError
      });
      this.props.updateImageName(this.props.photoId, values);
      return;
    }


    if (values.regisionDate !== null) {
      const date = format(new Date(values.regisionDate), "yyyy/M/d");
      values.regisionDate = date;
    }

    values.sitesUC = this.props.match.params.id;

    let clientError = null;

    const trimedValues = trimObjValues(values);

    if (!trimedValues.name) {
      clientError = {
        ...clientError,
        name: translateMessage(
          this.props.message.required,
          this.props.regexObject
        )
      };
    }

    this.setState({
      clientError: clientError
    });

    if (!clientError) {
      this.props.updateSitePhoto(this.props.photoId, values);
    }
  };

  render() {
    const {
      isDialogShow,
      onClose,
      title,
      submitButtonTitle,
      isDialogDeleteShow
    } = this.props;
    const { isShowDialogForceDeleteFile, initialValues } = this.state

    let contentClassName = "content-wrap";

    return (
      <>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={values => this.onSubmit(values)}
        >
          {formik => (
            <Dialog
              open={isDialogShow}
              aria-labelledby="max-width-dialog-title"
              fullWidth
              onClose={() => { }}
              maxWidth="lg"
              PaperProps={{
                className: "dialog dialog-sign-up"
              }}
              onKeyPress={event => {
                if (event.key === "Enter" && !this.state.submitFlag) {
                  formik.handleSubmit(formik.values);
                  this.setState({ submitFlag: true });
                }
              }}
              onClick={() => {
                this.setState({ submitFlag: false });
              }}
            >
              <Fab
                onClick={onClose}
                size="small"
                className="white-text close-button"
              >
                <CloseIcon />
              </Fab>

              <DialogTitle disableTypography>
                <Typography variant="h4" className="dialog-title">
                  {title}
                </Typography>
              </DialogTitle>
              <DialogContent className={contentClassName}>
                <Grid container>
                  <Grid item sm={12} md={12} xs={12} className="col">
                    {this.renderField(this.fieldList, formik)}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="dialog-action wrap">
                <Button
                  onClick={() => {
                    this.setState({ submitFlag: true });
                    formik.handleSubmit();
                  }}
                  color="primary"
                  className="dialog-button"
                  disabled={this.state.submitFlag}
                >
                  {submitButtonTitle}
                </Button>
                <Grid container justify="flex-end">
                  <Button
                    color="primary"
                    className="dialog-button-remove"
                    onClick={this.handleOpenDialogDelete}
                  >
                    <DeleteSharpIcon className="icon-margin" />
                    削除
                  </Button>
                </Grid>
              </DialogActions>
            </Dialog>
          )}
        </Formik>
        {isDialogDeleteShow && (
          <DialogDelete
            open={isDialogDeleteShow}
            onClose={this.toggleDialogDelete}
            onDeleteSubmit={this.onDeleteSubmit}
            PaperProps={{
              className: "dialog dialog-sign-up"
            }}
          />
        )}
        <DialogDelete
          open={isShowDialogForceDeleteFile}
          onClose={this.closeDialogForceDelete}
          onDeleteSubmit={this.onForceDeleteSubmit}
          isTextBottomLeft={true}
          isTextNoKeepAll={true}
          textTop="削除しますか？"
          textBottom="このファイルを参照しているインフォタグが存在します。​"
          PaperProps={{
            className: "dialog dialog-sign-up"
          }}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  photoInfo: getPhotos(state.rootSitesReducer.siteInfoReducer.photoInfo),
  type: state.rootSitesReducer.siteInfoReducer.type,
  submitErrors: state.storesReducer.error,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});

export default connect(mapStateToProps, {
  fetchPhotoInfo,
  updateSitePhoto,
  deleteSitePhoto,
  translateValidate,
  messageValidate
})(withRouter(EditImageDialog));
