import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { createUserAcknowledgement } from "../Stores/service/service";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";

class DialogRobots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogConfirmShow: true
    };
    this.onClose = props.onClose ? props.onClose : () => { };
  }

  closeDialog = e => {
    this.setState({
      isDialogConfirmShow: false
    });
    this.onClose();
  };

  onCreateUserAcknowledgement = (id, status) => () => {
    if (status && status === 1) {
      const value = {
        uniqueCode: id
      };
      this.props.createUserAcknowledgement(value);
    };
  };

  render() {
    const { robots } = this.props;
    const { isDialogConfirmShow } = this.state;

    return (
      <Dialog
        open={isDialogConfirmShow}
        aria-labelledby="max-width-dialog-title"
        fullWidth
        onClose={() =>{}}
        maxWidth="xs"
        PaperProps={{
          className: "dialog dialog-sign-up dialog-confirm"
        }}
      >
        <Fab
          onClick={this.closeDialog}
          size="small"
          className="white-text close-button"
        >
          <CloseIcon />
        </Fab>

        <DialogTitle disableTypography>
          <Typography className="center confirm-title">
            どのロボットを起動しますか？
          </Typography>
        </DialogTitle>
        <DialogContent className="confirm-content">
          {robots.map((robot, index) => (
            <Button
              key={index}
              size="large"
              className={
                robot.useStatus === 1
                  ? "confirm-button-green"
                  : "confirm-button-grey"
              }
              onClick={this.onCreateUserAcknowledgement(
                robot.uniqueCode,
                robot.useStatus
              )}
            >
              {robot.robotName}
              {robot.robotName && robot.location && ` | ${robot.location}`}
            </Button>
          ))}
        </DialogContent>
        <DialogActions className="confirm-action">
          <Button className="button-text-blue" onClick={this.closeDialog}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogRobots.propTypes = {
  robots: PropTypes.array.isRequired
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createUserAcknowledgement: createUserAcknowledgement
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DialogRobots);
