import {
  FETCH_SITES_SUCCESS,
  POST_SITES_SUCCESS,
  PUT_SITES_SUCCESS,
  DELETE_SITES_SUCCESS,
  UPDATE_SITE_SUCCESS,
  COMPLETE_SITES_SUCCESS,
  CLEAR_TYPE,
  CHANGE_TYPE_LIST_SITES_SUCCESS
} from "../constant/constant";

const initialState = {
  pending: false,
  site: [],
  error: null,
  isCardList: true
};
export const sitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SITES_SUCCESS:
      return {
        ...state,
        pending: false,
        site: action.site,
        type: action.type
      };
    case POST_SITES_SUCCESS:
      return {
        ...state,
        type: action.type,
        pending: false,
        error: null,
        uniqueCode: action.staff.UniqueCode
        // staff: [...state.staff, action.staffs]
      };
    case UPDATE_SITE_SUCCESS:
      return {
        ...state,
        pending: true,
        error: null,
        type: UPDATE_SITE_SUCCESS
      };
    case PUT_SITES_SUCCESS:
      return {
        ...state,
        pending: true,
        error: null,
        type: PUT_SITES_SUCCESS
      };
    case DELETE_SITES_SUCCESS:
      return {
        ...state,
        type: action.type,
        pending: false,
        error: null
      };
    case CLEAR_TYPE:
      return {
        ...state,
        type: null
      };
    case COMPLETE_SITES_SUCCESS:
      return {
        ...state,
        type: action.type,
        pending: false,
        error: null
      };
    case CHANGE_TYPE_LIST_SITES_SUCCESS:
      return {
        ...state,
        type: action.type,
        pending: false,
        error: null,
        isCardList: action.isCardList
      };
    default:
      return state;
  }
};
export const getSites = state => state;
export const getSitesPending = state => state;
export const getSitesError = state => state;
export const getUniqueCode = state => state;
