import marzipano from "marzipano";
import { format } from "date-fns";

import IconInfoTemp from "../../../../common/img/info.png";
import IconLink from "../../../../common/img/new-link.png";
import IconInfo from "../../../../common/img/info-icon.svg";
import IconHaveFile from "../../../../common/img/havefile.png";
import IconHaveChat from "../../../../common/img/havechat.png";

export const createTempInfoHotspotElement = color => {
  var wrapper = document.createElement("div");
  wrapper.classList.add("temp-hotspot");
  wrapper.style.backgroundColor = color;
  wrapper.addEventListener("click", e => {
    e.stopPropagation();
  });

  var icon = document.createElement("img");
  icon.src = IconInfoTemp;
  wrapper.appendChild(icon);

  stopTouchAndScrollEventPropagation(wrapper);
  return wrapper;
};

export const createInfoHotSpotElement = (
  infoHotspot,
  scene,
  openHotspotInfo,
  cancelCreateHotspotInfo,
  getZIndexHotspotInfo,
  increaseZIndexHotspotInfo
) => {
  var timer;
  var wrapper = document.createElement("div");
  wrapper.classList.add("info-hotspot-wrapper");
  wrapper.classList.add("no-zoom");
  wrapper.setAttribute("id", "info-hotspot-wrapper-" + infoHotspot.uniqueCode);
  wrapper.addEventListener("click", e => {
    e.preventDefault();
  });

  wrapper.addEventListener("mouseover", e => {
    var infoHotspotWrapper = document.getElementById(
      "info-hotspot-wrapper-" + infoHotspot.uniqueCode
    );
    if (infoHotspotWrapper) {
      if (timer) {
        clearTimeout(timer);
      }
      increaseZIndexHotspotInfo();
      infoHotspotWrapper.style.zIndex = getZIndexHotspotInfo();
      infoHotspotWrapper.classList.add("hotspot-info-show-summary");
    }
  });

  wrapper.addEventListener("mouseout", e => {
    var infoHotspotWrapper = document.getElementById(
      "info-hotspot-wrapper-" + infoHotspot.uniqueCode
    );
    if (infoHotspotWrapper) {
      timer = setTimeout(() => {
        infoHotspotWrapper.classList.remove("hotspot-info-show-summary");
      }, 2000);
    }
  });

  // info-hotspot-icon-wrapper
  var iconWrapper = document.createElement("div");
  iconWrapper.classList.add("info-hotspot-icon-wrapper");
  var icon = document.createElement("div");
  icon.innerHTML = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g transform="translate(-2193 -348)">
      <g fill="#fff" stroke="${infoHotspot.icon.color}" stroke-width="2px" transform="translate(2193 348)">
        <circle stroke="none" cx="20" cy="20" r="20" />
        <circle fill="none" cx="20" cy="20" r="19" />
      </g>
      <g transform="translate(2197 352)">
        <path
          fill="${infoHotspot.icon.color}"
          d="M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0ZM19.33,24.8q-1.235.488-1.971.742a5.2,5.2,0,0,1-1.709.256,3.407,3.407,0,0,1-2.326-.73,2.356,2.356,0,0,1-.828-1.852,6.726,6.726,0,0,1,.061-.893c.042-.3.108-.645.2-1.028l1.031-3.641c.091-.349.169-.681.232-.99a4.39,4.39,0,0,0,.092-.857,1.32,1.32,0,0,0-.287-.971,1.631,1.631,0,0,0-1.1-.272,2.89,2.89,0,0,0-.819.122c-.278.085-.519.163-.717.238l.272-1.122q1.012-.412,1.937-.706a5.722,5.722,0,0,1,1.747-.3,3.343,3.343,0,0,1,2.292.718,2.385,2.385,0,0,1,.8,1.864q0,.238-.056.836a5.592,5.592,0,0,1-.206,1.1l-1.025,3.63c-.084.291-.158.624-.226,1a5.271,5.271,0,0,0-.1.848,1.218,1.218,0,0,0,.324.986,1.812,1.812,0,0,0,1.12.263,3.249,3.249,0,0,0,.848-.131,4.821,4.821,0,0,0,.685-.23Zm-.182-14.734a2.447,2.447,0,0,1-1.727.666,2.473,2.473,0,0,1-1.734-.666,2.127,2.127,0,0,1-.722-1.616,2.149,2.149,0,0,1,.722-1.62,2.455,2.455,0,0,1,1.734-.673,2.427,2.427,0,0,1,1.727.673,2.182,2.182,0,0,1,0,3.236Z"
        />
      </g>
    </g>
  </svg>
  `
  icon.src = IconInfo;
  iconWrapper.appendChild(icon);

  iconWrapper.addEventListener("touchstart", e => {
    let destinationViewParameters = {
      yaw: infoHotspot.yaw,
      pitch: infoHotspot.pitch
    };
    var options = {
      transitionDuration: 500
    };

    var infoHotspotWrapper = document.getElementById(
      "info-hotspot-wrapper-" + infoHotspot.uniqueCode
    );
    if (infoHotspotWrapper) {
      if (timer) {
        clearTimeout(timer);
      }

      if (infoHotspotWrapper.classList.contains("hotspot-info-show-summary")) {
        infoHotspotWrapper.classList.remove("hotspot-info-show-summary");
      } else {
        scene.lookTo(destinationViewParameters, options);
        infoHotspotWrapper.classList.add("hotspot-info-show-summary");
      }
    }
  });

  // info-hotspot-summary-box-wrapper
  var summaryBoxWrapper = document.createElement("div");
  if (infoHotspot.hasFile || infoHotspot?.hasChat) {
    summaryBoxWrapper.style.backgroundColor = "#025b8a";
    summaryBoxWrapper.style.boxShadow =
      `rgba(31, 193, 216, 0.8) 0px 0px 5px 5px`;
    iconWrapper.style.boxShadow = `${infoHotspot.icon.color} 0px 0px 5px 5px`;
  }

  summaryBoxWrapper.classList.add("info-hotspot-summary-box-wrapper");

  //  info-hotspot-summary-box-title
  var title = document.createElement("div");
  title.classList.add("info-hotspot-summary-box-title");
  title.innerHTML = infoHotspot.title
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");

  // info-hotspot-summary-box-content
  var content = document.createElement("div");
  content.classList.add("info-hotspot-summary-box-content");

  var date = format(new Date(infoHotspot.createdAt), "yyyy/MM/dd (HH:mm)");
  var createdByAndCreatedAt = document.createElement("h3");
  createdByAndCreatedAt.innerHTML =
    infoHotspot.createdBy + "&nbsp;&nbsp;&nbsp;" + date;

  var description = document.createElement("p");
  description.innerHTML = infoHotspot.description
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/\n/g, "<br>");
  description.classList.add("info-hotspot-summary-box-description");

  content.appendChild(createdByAndCreatedAt);
  content.appendChild(description);

  // info-hotspot-summary-box-actions
  var actions = document.createElement("div");
  actions.classList.add("info-hotspot-summary-box-actions");

  var messageAndFileIcon = document.createElement("div");

  var messageIcon = document.createElement("img");
  messageIcon.src = IconHaveChat;
  var fileIcon = document.createElement("img");
  fileIcon.src = IconHaveFile;

  if (infoHotspot?.hasChat) {
    messageAndFileIcon.appendChild(messageIcon);
  }

  if (infoHotspot.hasFile) {
    messageAndFileIcon.appendChild(fileIcon);
  }

  var openDetailButton = document.createElement("button");
  openDetailButton.innerHTML = "詳細";
  openDetailButton.addEventListener("click", e => {
    e.stopPropagation();
    openHotspotInfo(infoHotspot.uniqueCode);
    cancelCreateHotspotInfo();
  });

  actions.appendChild(messageAndFileIcon);
  actions.appendChild(openDetailButton);

  summaryBoxWrapper.appendChild(title);
  summaryBoxWrapper.appendChild(content);
  summaryBoxWrapper.appendChild(actions);

  wrapper.appendChild(iconWrapper);
  wrapper.appendChild(summaryBoxWrapper);

  stopTouchAndScrollEventPropagation(wrapper);
  return wrapper;
};

export const createLinkHotspotElement = (hotspot, onClickHotspotLink) => {
  let wrapper = document.createElement("div");
  if(!hotspot.isActiveSpot) return wrapper;  

  wrapper.classList.add("hotspot");
  wrapper.classList.add("link-hotspot");

  let icon = document.createElement("img");
  icon.src = IconLink;
  icon.classList.add("link-hotspot-icon");
  
  const transformProperties = [
    "-ms-transform",
    "-webkit-transform",
    "transform"
  ];
  for (let i = 0; i < transformProperties.length; i++) {
    let property = transformProperties[i];
    icon.style[property] = "rotate(" + hotspot.rotation + "rad)";
  }

  wrapper.addEventListener("click", e => {
    e.stopPropagation();
    onClickHotspotLink(hotspot);
  });

  let tooltip = document.createElement("div");
  tooltip.classList.add("hotspot-tooltip");
  tooltip.classList.add("link-hotspot-tooltip");
  if(!hotspot.isActiveSpot) {
    tooltip.style.setProperty('cursor', 'not-allowed', 'important');
  }
  tooltip.innerHTML = hotspot.name;

  wrapper.appendChild(icon);
  wrapper.appendChild(tooltip);

  stopTouchAndScrollEventPropagation(wrapper);

  return wrapper;
};

export const startAutoRotate = viewer => {
  if (viewer) {
    const autorotate = marzipano.autorotate({
      yawSpeed: 0.03,
      targetPitch: 0,
      targetFov: Math.PI / 2
    });
    viewer.startMovement(autorotate);
    viewer.setIdleMovement(3000, autorotate);
  }
};

export const stopAutoRotate = viewer => {
  if (viewer) {
    viewer.stopMovement();
    viewer.setIdleMovement(Infinity);
  }
};

export const stopTouchAndScrollEventPropagation = element => {
  var eventList = [
    "touchstart",
    "touchmove",
    "touchend",
    "touchcancel",
    "wheel",
    "mousewheel"
  ];
  for (var i = 0; i < eventList.length; i++) {
    element.addEventListener(eventList[i], function (event) {
      event.stopPropagation();
    });
  }
};

export const clearTempHotSpotInfo = (hotspotContainer) => {
  const allHotspot = hotspotContainer.listHotspots();
  allHotspot.forEach(hotspot => {
    if(hotspot.domElement().className.includes("temp-hotspot")) {
      hotspotContainer.destroyHotspot(hotspot)
    }
  });
};

export const clearAllHotSpotLink = (hotspotContainer) => {
  const allHotspot = hotspotContainer.listHotspots();
  allHotspot.forEach(hotspot => {
    if(hotspot.domElement().className.includes("link-hotspot")) {
      hotspotContainer.destroyHotspot(hotspot)
    }
  });
};

export const clearAllHotSpotInfo = (hotspotContainer) => {
  const allHotspot = hotspotContainer.listHotspots();
  allHotspot.forEach(hotspot => {
    if(hotspot.domElement().className.includes("info-hotspot-wrapper")) {
      hotspotContainer.destroyHotspot(hotspot)
    }
  });
};

export const createHtmlContentToShowImage = (
  tileImageUrls,
  base64ImageUrl,
  yaw,
  pitch
) => {
  let source = ''
  if(tileImageUrls.preview) {
    source = `
      var source = Marzipano.ImageUrlSource.fromString("${tileImageUrls.tile}", {
        cubeMapPreviewUrl: "${tileImageUrls.preview}"
      });

      // Create geometry.
      var levels = [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true
        },
        {
          tileSize: 512,
          size: 512
        },
        {
          tileSize: 512,
          size: 1024
        },
        {
          tileSize: 512,
          size: 2048
        }
      ];
      var geometry = new Marzipano.CubeGeometry(levels);    
    `
  } else {
    source = `
      var source = Marzipano.ImageUrlSource.fromString("${base64ImageUrl}");
      var geometry = new Marzipano.EquirectGeometry([{ width: 4000 }]);
    `
  }

  let html = `<html>
      <body style="margin: 0;">
        <div id="pano" style="width:100%; height:100%;"></div>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/marzipano/0.10.2/marzipano.js" integrity="sha512-ir2jZ6Hz/Cf+gtVoZGAeKluqMN8xD9IY1vl1/2zL+xGGJfi92roMegfbqoKyZXEc8NALMKP/j/uRRhKuUirVuA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
        <script>
          var panoElement = document.getElementById("pano");

          var viewerOpts = {
            controls: {
              mouseViewMode: "drag"
            }
          };
      
          viewer = new Marzipano.Viewer(panoElement, viewerOpts);
      
          ${source}
      
          var limiter = Marzipano.util.compose(
            Marzipano.RectilinearView.limit.vfov(2.09439333333333, 2.09439333333333),
            Marzipano.RectilinearView.limit.hfov(2.09439333333333, 2.09439333333333),
            Marzipano.RectilinearView.limit.pitch(${pitch}, ${pitch}),
            Marzipano.RectilinearView.limit.yaw(${yaw}, ${yaw})
          );
          var view = new Marzipano.RectilinearView(
            { yaw: ${yaw}, pitch: ${pitch} },
            limiter
          );
      
          var scene = viewer.createScene({
            source: source,
            geometry: geometry,
            view: view,
          });
          scene.switchTo({}, () => {});
      
          var destinationViewParameters = {
            yaw: ${yaw},
            pitch: ${pitch},
            fov: 2.09439333333333,
            hfov: 2.09439333333333,
          };
      
          var options = {
            transitionDuration: 100,
          };

          scene.lookTo(destinationViewParameters, options);
        </script>
      </body>
    </html>`;

  return html;
};

export const createMiniInfoHotSpotElement = (miniIconHotspotClass) => {
  var miniHotspot = document.createElement("div");
  miniHotspot.classList.add(miniIconHotspotClass);
  var icon = document.createElement("img");
  icon.src = IconInfo;
  miniHotspot.appendChild(icon);
  return miniHotspot;
};
