export const convertSerialNumber = serialNumber => {
  let newSerialNumber = "";
  if (serialNumber) {
    for (let i = 0; i < 8 - serialNumber.toString().length; i++) {
      newSerialNumber = newSerialNumber.concat("0");
    }
    newSerialNumber = newSerialNumber.concat(serialNumber);
  }
  return newSerialNumber;
};

export const convertType = (name, extension) => {
  const nameArray = name.split(".");
  nameArray.push(extension);
  const newName = nameArray.join(".");
  return newName;
};

export const pagingData = (datas, offset, limit) => {
  return datas.slice(offset, offset === 0 ? limit : offset + limit);
};

export const translateMessage = (message, objectRegex) => {
  let messageValidate = "";
  if (objectRegex) {
    const newArray = Object.keys(objectRegex);
    newArray.forEach(item => {
      if (message.match(item)) {
        messageValidate = objectRegex[item];
        return true;
      }
    });

    if (message.includes("not match")) {
      messageValidate = "一致しません";
    }

    return messageValidate;
  }
  return message;
};

export const trimObjValues = obj => {
  return Object.keys(obj).reduce((acc, curr) => {
    if (typeof obj[curr] === "string") {
      acc[curr] = obj[curr].trim();
    } else {
      acc[curr] = obj[curr];
    }
    return acc;
  }, {});
};

export const arrayBufferToBase64 = buffer => {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const findUnique = (arr, predicate) => {
  var found = {};
  arr.forEach(d => {
    found[predicate(d)] = d;
  });
  return Object.keys(found).map(key => found[key]);
};

export const getTextLength = input => {
  if (input) {
    let len = 0;
    for (let i = 0; i < input.length; i++) {
      let code = input.charCodeAt(i);
      if (
        (code >= 0x0020 && code <= 0x1fff) ||
        (code >= 0xff61 && code <= 0xff9f)
      ) {
        len += 1;
      } else if ((code >= 0x2000 && code <= 0xff60) || code >= 0xffa0) {
        len += 2;
      } else {
        len += 0;
      }
    }
    return len;
  }
  return 0;
};

export const getWebSocketRobotURL = () => {
  const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
  const host = window.location.host;
  return `${protocol}${host}/ws/robot-usestatus/subscribe`;
};
