import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useMediaQuery } from "react-responsive";
const mediaQueryTabletOrMobile = "(max-width: 960px)";

function LogoutPage(props) {
  const isTabletOrMobile = useMediaQuery({ query: mediaQueryTabletOrMobile });

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{
        fontSize: "1rem",
        height: isTabletOrMobile ? "calc(100vh - 65px)" : "auto"
      }}
    >
      <Grid item className="m-5">
        本当にログアウトしますか？
      </Grid>
      <Grid item className="m-5">
        <Button
          variant="contained"
          style={{
            width: 150,
            backgroundColor: "#E8E8E8",
            fontSize: "1rem"
          }}
          onClick={props.logoutUser}
        >
          ログアウト
        </Button>
      </Grid>
    </Grid>
  );
}

export default LogoutPage;
