import {
  FETCH_SITES_SUCCESS,
  POST_SITES_SUCCESS,
  UPDATE_SITE_SUCCESS,
  PUT_SITES_SUCCESS,
  DELETE_SITES_SUCCESS,
  CLEAR_TYPE,
  COMPLETE_SITES_SUCCESS,
  CHANGE_TYPE_LIST_SITES_SUCCESS
} from "../constant/constant";

export const fetchSitesSuccess = site => {
  return {
    type: FETCH_SITES_SUCCESS,
    site: site
  };
};

export const postSitesSuccess = staff => {
  return {
    type: POST_SITES_SUCCESS,
    staff: staff
  };
};

export const putSitessSuccess = site => {
  return {
    type: PUT_SITES_SUCCESS,
    site: site
  };
};

export const updateSiteSuccess = site => {
  return {
    type: UPDATE_SITE_SUCCESS,
    site: site
  };
};

export const deleteSitesSuccess = () => {
  return {
    type: DELETE_SITES_SUCCESS
  };
};

export const clearType = () => {
  return {
    type: CLEAR_TYPE
  };
};

export const completeSitesSuccess = () => {
  return {
    type: COMPLETE_SITES_SUCCESS
  };
};

export const changeTypeListSitesSuccess = (isCardList) =>{
  return {
    type: CHANGE_TYPE_LIST_SITES_SUCCESS,
    isCardList
  }
}