import React, { useEffect, useState } from "react";

import { api } from "../../../../../../common/api/api";
import nextImg from "../common/img/next_img.svg";
import emptyImg from "../../../../../../common/img/empty.jpg";

function ViewFileItem(props) {
  const { files, currentFile, nextFile, previousFile } = props;

  const [img, setImg] = useState(null);

  useEffect(() => {
    if (currentFile) {
      fetch();
    }
  }, [currentFile]);

  function fetch() {
    var isBlob = currentFile.blob ? true : false;
    const url = isBlob ? currentFile.blob.resized.replace(/^\/api/, '') : currentFile.thumbnail.slice(4);
    api
      .get(url, {
        responseType: isBlob ? undefined : "arraybuffer"
      })
      .then(res => {
        if (res.data === undefined) return Promise.reject();
        if (isBlob) {
          var url = res.data.blobData;
        } else {
          var blob = new Blob([res.data], { type: "image/jpeg" });
          var url = URL.createObjectURL(blob);
        }
        return url;
      })
      .then(url => setImg(renderImg(url)))
      .catch(e => {
        if (e?.response?.status === 404) {
          setImg(renderImg(""));
        }
      });
  }

  function renderImg(data) {
    return <img src={data || emptyImg} style={{ width: "100%", height: "auto" }} alt=""/>;
  }

  const renderOptions = () => {
    const allFile = files.length;
    const orderOfCurrentFile = files.findIndex(
      file => file.uniqueCode === currentFile.uniqueCode
    );

    return (
      <React.Fragment>
        <div
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            backgroundColor: "#007CFF",
            color: "white",
            fontSize: "17px",
            padding: "0px 12px"
          }}
        >{`${orderOfCurrentFile + 1} / ${allFile}`}</div>

        {orderOfCurrentFile !== 0 && (
          <img
            onClick={() => previousFile()}
            style={{
              position: "absolute",
              width: "30px",
              height: "100px",
              top: "50%",
              left: "10px",
              marginTop: "-50px",
              transform: "rotate(180deg)",
              cursor: "pointer"
            }}
            src={nextImg}
            alt=""
          ></img>
        )}

        {orderOfCurrentFile + 1 !== allFile && (
          <img
            onClick={() => nextFile()}
            style={{
              position: "absolute",
              width: "30px",
              height: "100px",
              top: "50%",
              right: "10px",
              marginTop: "-50px",
              cursor: "pointer"
            }}
            src={nextImg}
            alt=""
          ></img>
        )}
      </React.Fragment>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      {img}
      {img && renderOptions()}
    </div>
  );
}

ViewFileItem.propTypes = {};

export default ViewFileItem;
