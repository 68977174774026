import React from "react";

function IconTrashCan(props) {
  const { isActive, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
    >
      <defs>
        <filter
          id="a"
          width="48"
          height="48"
          x="0"
          y="0"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3"></feOffset>
          <feGaussianBlur result="b" stdDeviation="3"></feGaussianBlur>
          <feFlood floodOpacity="0.161"></feFlood>
          <feComposite in2="b" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
      </defs>
      <g filter="url(#a)" transform="translate(-8630 -788) translate(8630 788)">
        <path fill="none" d="M0 0H30V30H0z" transform="translate(9 6)"></path>
      </g>
      <g fill={isActive ? "#fff" : "#333"}>
        <path
          d="M64.169 2.875h-3.784v-.719A2.114 2.114 0 0058.321 0h-2.753A2.114 2.114 0 0053.5 2.156v.719h-3.78A1.762 1.762 0 0048 4.672v2.516a.7.7 0 00.688.719h.376l.594 13.04A2.1 2.1 0 0051.72 23h10.449a2.1 2.1 0 002.062-2.054l.594-13.04h.375a.7.7 0 00.688-.719V4.672a1.762 1.762 0 00-1.719-1.797zm-9.289-.719a.7.7 0 01.688-.719h2.752a.7.7 0 01.688.719v.719H54.88zm-5.5 2.516a.352.352 0 01.344-.359h14.445a.352.352 0 01.344.359v1.8H49.376zm13.48 16.206a.7.7 0 01-.687.685H51.72a.7.7 0 01-.687-.685l-.591-12.972h13.005z"
          transform="translate(-8630 -788) translate(8597 798)"
        ></path>
        <path
          d="M56.945 19.982a.755.755 0 00.755-.755v-9.81a.755.755 0 00-1.509 0v9.81a.755.755 0 00.754.755zM60.357 19.982a.755.755 0 00.755-.755v-9.81a.755.755 0 00-1.509 0v9.81a.755.755 0 00.754.755zM53.532 19.982a.755.755 0 00.755-.755v-9.81a.755.755 0 00-1.509 0v9.81a.755.755 0 00.754.755z"
          transform="translate(-8630 -788) translate(8597 798)"
        ></path>
      </g>
    </svg>
  );
}

IconTrashCan.defaultProps = {
  size: "40"
}
export default IconTrashCan;
