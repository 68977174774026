import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { api } from "../../../common/api/api";
import Component from "./Common";
import InfoBuilder from "./InfoBuilder";
import InfoBuilderSP from "./InfoBuilderSP";
import Pc from "../../../common/breakpoints/Pc";
import Sp from "../../../common/breakpoints/Sp";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CommonDialog from "../../CommonDialog";
import PhoneIcon from "@material-ui/icons/Phone";
import {
  getSiteInfo,
  getSiteInfoRobot,
  getSiteInfoStaff,
  getSiteInfoSupplier,
  getStaffCandidates,
  getSupplierCandidates,
  getSiteInfoRobotCandidates,
  getType
} from "./reducer/reducer";
import {
  fetchSiteInfo,
  fetchSiteInfoRobot,
  fetchSiteInfoStaff,
  fetchSiteInfoSupplier,
  fetchStaffCandidates,
  fetchSupplierCandidates,
  fetchSiteInfoRobotCandidate,
  addSiteInfoRobotCandidate,
  deleteSiteInfoRobotCandidate,
  addStaffCandidate,
  addSupplierCandidate,
  deleteBuilderStaffCandidate,
  deleteSupplierStaffCandidate,
  updateSiteInfoRobot,
  fetchInfoData,
  showHideDialogInfoRobot,
  showHideDialogStaff,
  showHideDialogSupplier,
  showHideDialogEditInfo,
  showHideDialogDeleteInfo,
  setInfoValues
} from "./service/service";
import {
  dispatchError as fillError,
  createUserAcknowledgement
} from "../../../common/Stores/service/service";
import { format } from "date-fns";
import Popover from "@material-ui/core/Popover";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";
import { translateMessage, trimObjValues } from "utils/mapper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import DialogDelete from "../../../common/DialogDelete";
import { PUT_SITE_INFO_ROBOT_SUCCESS } from "./constant/constant";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import SearchField from "../../SearchField";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import InfoStaffDialog from "./InfoStaffDialog";
import InfoSupplierDialog from "./InfoSupplierDialog";
import jwt_decode from "jwt-decode";
import logmeetConfig from "../../../common/config/LogmeetConfig";
import { configStorage } from "../../../common/storage/storage";

class Info extends Component {
  fieldList = [
    {
      name: "robotName",
      label: "ロボット名",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    },
    {
      name: "location",
      label: "設置場所",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: false
    },
    {
      name: "assignEndDate",
      label: "配置終了日",
      type: DIALOG_FIELD_TYPES.DateTime,
      isRequired: false,
      guidMessage: "※配置終了日を登録すると、ロボットの現場配置を終了します"
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      isDialogInfoRobotShow: false,
      isDialogDeleteShow: false,
      isDialogStaffShow: false,
      isDialogSupplierShow: false,
      isDialogEdit: false,
      currentIds: [],
      valuesEdit: {
        robotName: "",
        location: "",
        assignEndDate: new Date()
      },
      anchorEl: null,
      userType: "",
      isSP: false,
      currentUserUniqueCode: null,
      receiver: null
    };
  }

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);
    if (nextProps.type === PUT_SITE_INFO_ROBOT_SUCCESS) {
      this.props.showHideDialogEditInfo();
    }
  }

  componentWillMount() {
    const token = localStorage.jwtToken
      ? localStorage.jwtToken
      : api.defaults.headers.common["Authorization"];
    const decoded = jwt_decode(token);
    this.setState({ currentUserUniqueCode: decoded.uniqueCode });
  }

  toggleCallModal = (receiver) => {
    this.setState({
      receiver: receiver ? receiver : null
    })
  }

  render() {
    const { clientError, anchorEl, popoverValue, userType, isSP, currentUserUniqueCode, receiver } = this.state;

    const {
      match,
      siteInfoRobotCandidate,
      addSiteInfoRobotCandidate,
      deleteSiteInfoRobotCandidate,
      staffCandidates,
      supplierCandidates,
      addStaffCandidate,
      addSupplierCandidate,
      deleteBuilderStaffCandidate,
      deleteSupplierStaffCandidate,
      updateSiteInfoRobot,
      error,
      siteInfoStaff,
      siteInfoSupplier,
      logMeetFlag
    } = this.props;

    const showDialogInfoRobot = () => {
      this.props.showHideDialogInfoRobot();
    };

    const closeDialogInfoRobot = () => {
      this.setState({
        currentIds: []
      });
      this.props.showHideDialogInfoRobot();
    };

    const toggleDialogStaff = () => {
      this.setState({
        currentIds: []
      });
      this.props.showHideDialogStaff();
    };

    const toggleDialogSupplier = () => {
      this.setState({
        currentIds: []
      });
      this.props.showHideDialogSupplier();
    };

    const addInfoRobotCandidate = id => () => {
      const value = {
        sitesUC: match.params.id,
        robotsUC: id
      };
      const newCurrent = [...this.state.currentIds];
      if (!newCurrent.includes(id)) {
        newCurrent.push(id);
      }
      this.setState({
        currentIds: newCurrent
      });
      if (this.state.currentIds.includes(id)) {
        return;
      }
      addSiteInfoRobotCandidate(value);
    };

    const handleAddStaffCandidate = staffIds => {
      const value = {
        sitesUC: match.params.id,
        usersUCs: staffIds
      };

      addStaffCandidate(value, () => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        this.props.history.go(0);
      });
    };

    const handleAddSupplierCandidate = supplierIds => {
      const value = {
        sitesUC: match.params.id,
        usersUCs: supplierIds
      };

      addSupplierCandidate(value, () => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        this.props.history.go(0);
      });
    };

    const toggleDialogEdit = () => {
      this.props.fillError("");
      this.setState({
        anchorEl: null,
        clientError: null
      });
      this.props.showHideDialogEditInfo();
    };

    const onEditSubmit = values => {
      const { sitesUC, robotsUC } = popoverValue;
      const newValue = {
        sitesUC,
        robotsUC,
        ...values
      };

      let clientError = null;

      const trimedNewValue = trimObjValues(newValue);

      if (!trimedNewValue.robotName) {
        clientError = {
          ...clientError,
          robotName: translateMessage(
            this.props.message.required,
            this.props.regexObject
          )
        };
      }

      if (trimedNewValue.assignEndDate?.toString() === "Invalid Date") {
        clientError = {
          ...clientError,
          assignEndDate: translateMessage(
            this.props.message.date,
            this.props.regexObject
          )
        };
      }

      this.setState({
        clientError: clientError
      });

      if (!clientError) {
        updateSiteInfoRobot(newValue);
        toggleDialogEdit();
      }
    };

    const showPopover = (value, userType) => event => {
      const newValue = Object.assign({ sitesUC: match.params.id }, value);
      if (newValue.robotsUC) {
        const infoValues = {
          robotName: newValue.robotName,
          location: newValue.location,
          assignEndDate: newValue.assignEndDate
        };
        this.props.setInfoValues(infoValues);
      }
      this.setState({
        anchorEl: event.currentTarget,
        popoverValue: newValue,
        userType
      });
    };

    const closePopover = () => {
      this.setState({
        anchorEl: null
      });
      this.props.setInfoValues({});
    };

    const toggleDialogDelete = () => {
      this.setState({
        anchorEl: null
      });
      this.props.showHideDialogDeleteInfo();
    };

    const onDeleteValue = () => {
      this.setState({
        anchorEl: null
      });
      if (popoverValue.robotsUC) {
        toggleDialogDelete();
      } else {
        if (userType === "Builder") {
          deleteBuilderStaffCandidate(popoverValue, () => {
            alert("エラーが発生しました。 お手数ですが、再度お試しください。");
            this.props.history.go(0);
          });
        } else {
          deleteSupplierStaffCandidate(popoverValue, () => {
            alert("エラーが発生しました。 お手数ですが、再度お試しください。");
            this.props.history.go(0);
          });
        }
      }
    };
    const robotDeleteSubmit = () => {
      this.setState({
        anchorEl: null
      });
      deleteSiteInfoRobotCandidate(popoverValue);
      toggleDialogDelete();
    }
    const setSP = () => {
      this.setState({
        isSP: true
      });
    };

    const setPC = () => {
      this.setState({
        isSP: false
      });
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    let classSiteTable = "sites-table-sp";
    let classFirstTh = "";
    let classFirstTd = "";
    if (!this.state.isSP) {
      classSiteTable = "sites-table";
      classFirstTh = "padding-first-th";
      classFirstTd = "padding-first-td";
    }

    return (
      <>
        <Sp>
          <InfoBuilderSP
            sitesUC={this.sitesUC}
            showDialogInfoRobot={showDialogInfoRobot}
            toggleDialogStaff={toggleDialogStaff}
            toggleDialogSupplier={toggleDialogSupplier}
            showPopover={showPopover}
            setSP={setSP}
            currentUserUniqueCode={currentUserUniqueCode}
            toggleCallModal={this.toggleCallModal}
            logMeetFlag={logMeetFlag}
          />
        </Sp>
        <Pc>
          <InfoBuilder
            sitesUC={this.sitesUC}
            showDialogInfoRobot={showDialogInfoRobot}
            toggleDialogStaff={toggleDialogStaff}
            toggleDialogSupplier={toggleDialogSupplier}
            showPopover={showPopover}
            setPC={setPC}
            currentUserUniqueCode={currentUserUniqueCode}
            toggleCallModal={this.toggleCallModal}
            logMeetFlag={logMeetFlag}
          />
        </Pc>

        {receiver && (
          <div className="calling-confirm-modal">
            <div className="calling-confirm-container"
              style={{ textAlign: "center" }}
            >
              <Fab
                onClick={() => { this.toggleCallModal(null) }}
                size="small"
                className="close-button"
                style={{ backgroundColor: "#162F50" }}
              >
                <CloseIcon style={{ color: "white" }} />
              </Fab>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold"
                }}
              >この電話をかけますか？</p>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  width: "100%"
                }}
                className="text-overflow-ellipsis"
              >{receiver.name}</p>
              <Button
                onClick={() => {
                  const classification = window.location.pathname.includes("/sp/")
                  ? 1 // Supplier
                  : 0 // Builder;

                  const logMeetUrl = logmeetConfig.getLogMeetURL(window.location.hostname);
                  const url = `${logMeetUrl}?${new URLSearchParams({
                      token: configStorage().replace("Bearer ", ""),
                      siteUC: match.params.id,
                      userUC: receiver.uniqueCode,
                      role: classification
                    })}`;
                  window.open(url, '_blank').focus();
                  this.toggleCallModal(null);
                }}
                style={{
                  backgroundColor: "#162F50",
                  color: "white",
                  width: "254px",
                  height: "48px",
                  margin: "20px 0"
                }}
              >
                <PhoneIcon style={{ color: "white", width: "22px", height: "22px" }} />
                &nbsp;
                <span
                  style={{
                    fontSize: "16px",
                    fontFamily: "Noto Sans JP",
                    fontWeight: 500
                  }}
                >発信する</span>
              </Button>
            </div>
          </div>
        )}

        <Dialog
          open={this.props.isShowDialogInfoRobot}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          onClose={() => { }}
          maxWidth="lg"
          PaperProps={{
            className: "dialog dialog-sign-up dialog-min-height"
          }}
        >
          <Fab
            onClick={closeDialogInfoRobot}
            size="small"
            className="white-text close-button"
          >
            <CloseIcon />
          </Fab>

          <DialogTitle disableTypography>
            <Grid container justify="space-between" className="mb-25">
              <Typography variant="h4" className="dialog-title">
                ロボット一覧
              </Typography>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table size="small" aria-label="table" className={classSiteTable}>
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell className={`white-text ${classFirstTh}`}>
                      ロボット名
                    </TableCell>
                    {!isSP && (
                      <>
                        <TableCell className="white-text">現場登録日</TableCell>
                        <TableCell className="white-text">
                          配置終了日
                          <ArrowDownwardIcon className="icon-small-size s-va-m margin-left" />
                        </TableCell>
                      </>
                    )}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="table-body">
                  {siteInfoRobotCandidate &&
                    siteInfoRobotCandidate.map(candidate => (
                      <TableRow key={candidate.uniqueCode}>
                        <TableCell className={classFirstTd}>
                          {candidate.robotName}
                        </TableCell>
                        {!isSP && (
                          <>
                            <TableCell>
                              {candidate.assignStartDate &&
                                format(
                                  new Date(candidate.assignStartDate),
                                  "yyyy年M月d日"
                                )}
                            </TableCell>
                            <TableCell>
                              {candidate.assignEndDate &&
                                format(
                                  new Date(candidate.assignEndDate),
                                  "yyyy年M月d日"
                                )}
                            </TableCell>
                          </>
                        )}

                        <TableCell
                          align="right"
                          className={isSP && "info-cell-more"}
                        >
                          <Button
                            onClick={addInfoRobotCandidate(
                              candidate.uniqueCode
                            )}
                            size="small"
                            variant="text"
                            className="button-add"
                          >
                            <AddIcon className="icon-margin" />
                            {!isSP && "現場追加"}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>

        {
          this.props.isShowDialogStaff && (
            <InfoStaffDialog
              isShowDialogStaff={this.props.isShowDialogStaff}
              toggleDialogStaff={toggleDialogStaff}
              tableClass={classSiteTable}
              staffCandidates={staffCandidates}
              siteInfoStaff={siteInfoStaff}
              handleAddStaffCandidate={handleAddStaffCandidate}
            />
          )
        }

        {
          this.props.isShowDialogSupplier && (
            <InfoSupplierDialog
              isShowDialogSupplier={this.props.isShowDialogSupplier}
              toggleDialogSupplier={toggleDialogSupplier}
              tableClass={classSiteTable}
              supplierCandidates={supplierCandidates}
              siteInfoSupplier={siteInfoSupplier}
              handleAddSupplierCandidate={handleAddSupplierCandidate}
            />
          )
        }

        {this.props.isShowDialogEditInfo && (
          <CommonDialog
            title={"ロボット情報編集"}
            isDialogShow={this.props.isShowDialogEditInfo}
            initialValues={this.props.infoValues}
            onSubmit={onEditSubmit}
            onClose={toggleDialogEdit}
            fieldList={this.fieldList}
            submitButtonTitle={"登録"}
            submitErrors={clientError ? clientError : error}
            isSingleColumn={true}
          />
        )}

        <DialogDelete
          open={this.props.isShowDialogDeleteInfo}
          onClose={toggleDialogDelete}
          onDeleteSubmit={robotDeleteSubmit}
          textContent="※削除を行うと、現場割り当て履歴には残りません。現場割当履歴への登録をする場合は、編集から現場配置日の登録を実行してください。本当に削除しますか？"
          PaperProps={{
            className: "dialog dialog-sign-up"
          }}
        />

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuList className="popover-sites">
            {userType === "Robot" && (
              <MenuItem onClick={toggleDialogEdit} className="popover-item">
                編集
              </MenuItem>
            )}
            <MenuItem onClick={onDeleteValue} className="popover-item">
              削除
            </MenuItem>
          </MenuList>
        </Popover>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  finishLoading: state.storesReducer.finishLoading,
  siteInfo: getSiteInfo(state.rootSitesReducer.siteInfoReducer.siteInfo),
  siteInfoRobot: getSiteInfoRobot(
    state.rootSitesReducer.siteInfoReducer.siteInfoRobot
  ),
  siteInfoStaff: getSiteInfoStaff(
    state.rootSitesReducer.siteInfoReducer.siteInfoStaff
  ),
  siteInfoSupplier: getSiteInfoSupplier(
    state.rootSitesReducer.siteInfoReducer.siteInfoSupplier
  ),
  staffCandidates: getStaffCandidates(
    state.rootSitesReducer.siteInfoReducer.staffCandidates
  ),
  supplierCandidates: getSupplierCandidates(
    state.rootSitesReducer.siteInfoReducer.supplierCandidates
  ),
  siteInfoRobot: getSiteInfoRobot(
    state.rootSitesReducer.siteInfoReducer.siteInfoRobot
  ),
  siteInfoRobotCandidate: getSiteInfoRobotCandidates(
    state.rootSitesReducer.siteInfoReducer.siteInfoRobotCandidate
  ),
  isShowDialogInfoRobot:
    state.rootSitesReducer.siteInfoReducer.isShowDialogInfoRobot,
  isShowDialogStaff: state.rootSitesReducer.siteInfoReducer.isShowDialogStaff,
  isShowDialogSupplier:
    state.rootSitesReducer.siteInfoReducer.isShowDialogSupplier,
  isShowDialogEditInfo:
    state.rootSitesReducer.siteInfoReducer.isShowDialogEditInfo,
  isShowDialogDeleteInfo:
    state.rootSitesReducer.siteInfoReducer.isShowDialogDeleteInfo,
  infoValues: state.rootSitesReducer.siteInfoReducer.infoValues,
  error: state.storesReducer.error,
  type: getType(state.rootSitesReducer.siteInfoReducer.type),
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSiteInfo: fetchSiteInfo,
      fetchSiteInfoRobot: fetchSiteInfoRobot,
      fetchSiteInfoStaff: fetchSiteInfoStaff,
      fetchSiteInfoSupplier: fetchSiteInfoSupplier,
      fetchStaffCandidates: fetchStaffCandidates,
      fetchSupplierCandidates: fetchSupplierCandidates,
      fetchSiteInfoRobotCandidate: fetchSiteInfoRobotCandidate,
      addSiteInfoRobotCandidate: addSiteInfoRobotCandidate,
      deleteSiteInfoRobotCandidate: deleteSiteInfoRobotCandidate,
      addStaffCandidate: addStaffCandidate,
      addSupplierCandidate: addSupplierCandidate,
      deleteBuilderStaffCandidate: deleteBuilderStaffCandidate,
      deleteSupplierStaffCandidate: deleteSupplierStaffCandidate,
      updateSiteInfoRobot: updateSiteInfoRobot,
      showHideDialogInfoRobot: showHideDialogInfoRobot,
      showHideDialogStaff: showHideDialogStaff,
      showHideDialogSupplier: showHideDialogSupplier,
      showHideDialogEditInfo: showHideDialogEditInfo,
      showHideDialogDeleteInfo: showHideDialogDeleteInfo,
      setInfoValues: setInfoValues,
      fetchInfoData,
      fillError,
      createUserAcknowledgement
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Info));
