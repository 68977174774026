import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Component from "./Upload";
import { search, download, post } from "../../../actions/fileActions";

import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { format } from "date-fns";
import {
  downloadFile,
  postFile,
  searchFile,
  updateSiteFile,
  deleteSiteFile
} from "./service/service";
import { dispatchError as putFileError } from "../../../common/Stores/service/service";
import { getFiles } from "./reducer/reducer";
import { DELETE_FILE_SUCCESS, PUT_FILE_SUCCESS } from "./constant/constant";
import { Typography } from "@material-ui/core";
import {
  translateValidate,
  messageValidate
} from "../../../common/Stores/service/service";
import UploadIcon from "@material-ui/icons/CloudUpload";

class FilesBuilderSP extends Component {
  state = {
    anchorEl: null,
    typeReload: [PUT_FILE_SUCCESS, DELETE_FILE_SUCCESS],
    disalbeNextBack: false,
    currentFile: null,
    clientError: null
  };

  componentDidMount() { }

  onFileClick(id, blob) {
    this.props.downloadFile(id, blob);
  }

  fetch() { }

  render() {
    const { files } = this.props;

    return (
      <>
        <input
          type="file"
          id="file"
          accept=".pdf"
          multiple
          onChange={e => this.upload(e)}
          style={{ display: "none" }}
        />
        <Grid className="second-tab">
          <MenuList>
            {files.map((model, index) => (
              <MenuItem
                onClick={() =>
                  this.onFileClick(model.fileId, model.blob)
                }
                key={model.fileId}
                className="dashboard-sp-item"
              >
                <Grid container justify="space-between" wrap="nowrap">
                  <Grid className="item-left">
                    <Typography
                      variant="h5"
                      className="text-bold font-family white-space-normal"
                      style={{ fontSize: "1rem" }}
                    >
                      {model.name}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className="font-family grey-light white-space-normal"
                    >
                      <span style={{ width: 105, display: "inline-block" }}>
                        {format(new Date(model.date), "yyyy年M月d日")}{" "}
                      </span>
                      <span>{model.creator}</span>
                    </Typography>
                  </Grid>
                  <Grid className="item-right" alignItems="center" style={{ flexDirection: 'row' }}>
                    <Fab
                      onClick={this.props.showPopover(model)}
                      size="small"
                      className="more-vert-btn"
                    >
                      <MoreVertIcon />
                    </Fab>
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </MenuList>
        </Grid>
        <Fab className="button-sp button-upload-sp button-logwalk-menu-center-sp">
          <label htmlFor="file" style={{ color: "inherit" }}>
            <UploadIcon className="white-text icon" style={{ width: "26px", height: "26px" }} />
          </label>
        </Fab>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  files: getFiles(state.rootSitesReducer.siteInfoReducer.files),
  file: state.rootSitesReducer.siteInfoReducer.file,
  type: state.rootSitesReducer.siteInfoReducer.type,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});

export default connect(mapStateToProps, {
  search,
  post,
  download,
  downloadFile,
  postFile,
  searchFile,
  updateSiteFile,
  putFileError,
  deleteSiteFile,
  translateValidate,
  messageValidate
})(withRouter(FilesBuilderSP));
