import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { search, download, post } from "../../../actions/fileActions";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Popover from "@material-ui/core/Popover";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { format } from "date-fns";
import {
  fetchFile,
  postFile,
  searchFile,
  updateSiteFile,
  deleteSiteFile,
  fetchFileInfo,
  showHideDialogEditFile,
  showHideDialogDeleteFile,
  setFileValues
} from "./service/service";
import { dispatchError as putFileError } from "../../../common/Stores/service/service";
import { getFiles } from "./reducer/reducer";
import CommonDialog from "../../CommonDialog";
import DialogDelete from "../../../common/DialogDelete";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";
import {
  DELETE_FILE_SUCCESS,
  PUT_FILE_SUCCESS,
  DELETE_FILE_REFER,
  GET_FILE_SUCCESS,
  GET_FILE_INFO_SUCCESS
} from "./constant/constant";
import PaginationControl from "../../../common/Pagination";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import {
  faChevronRight,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { translateMessage, trimObjValues } from "utils/mapper";
import {
  translateValidate,
  messageValidate
} from "../../../common/Stores/service/service";
import FilesBuilder from "./FilesBuilder";
import FilesBuilderSP from "./FilesBuilderSP";
import Pc from "../../../common/breakpoints/Pc";
import Sp from "../../../common/breakpoints/Sp";
import Component from "./Upload";
class Files extends Component {
  state = {
    anchorEl: null,
    isDialogShow: false,
    isDialogDeleteShow: false,
    clientError: null,
    isShowDialogForceDeleteFile: false,
    type: null,
    typeReload: [PUT_FILE_SUCCESS, DELETE_FILE_SUCCESS],
    isRefer: false,
    isLockedFile: false,
    isFetchInfoFirst: false,
    getFileInfoType: ""
  };

  fieldList = [
    {
      name: "name",
      label: "ファイル名",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    }
  ];

  componentWillReceiveProps(props) {
    if (props.type === DELETE_FILE_SUCCESS) {
      this.closeDialogForceDelete();
      this.closeDialogDelete();
    }
    if (props.type === DELETE_FILE_REFER) {
      this.setState({
        isLockedFile: true
      })
      this.openDialogForceDelete();
    }

    if (props.type === GET_FILE_INFO_SUCCESS && this.state.isFetchInfoFirst && this.state.getFileInfoType === "DELETE") {
      const Info = props.fileInfo;
      if (Info) {
        if (Info.isRefer) {
          this.openDialogForceDelete();
        } else {
          this.openDialogDelete();
        }
        this.setState(
          {
            isRefer: Info.isRefer,
            isLockedFile: false,
            isFetchInfoFirst: false,
            getFileInfoType: ""
          }
        );
      }

    }

    if (props.type === GET_FILE_INFO_SUCCESS && this.state.isFetchInfoFirst && this.state.getFileInfoType === "UPDATE") {
      const Info = props.fileInfo;
      if (Info) {
        this.setState(
          {
            isFetchInfoFirst: false,
            FileUpdateValues: {
              id: Info.uniqueCode,
              name: Info.name,
              sitesUC: this.props.fileValues.sitesUC,
              getFileInfoType: ""
            }
          },
          () => {
            this.openDialogEdit();
          }
        );
      }

    }

    if (!this.isChanged(props)) return;
    this.init(props);
    this.fetch();
    this.props.searchFile(this.sitesUC);
    this.closeDialog();
  }

  fetch() { }

  showPopover = model => event => {
    event.preventDefault();
    event.stopPropagation();
    const fileValues = {
      id: model.fileId,
      name: model.name
    };
    this.props.setFileValues(fileValues);
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  closePopover = () => {
    this.setState({
      anchorEl: null
    });
    this.props.setFileValues({});
  };

  openDialogEdit = () => {
    this.props.putFileError("");
    this.setState({
      anchorEl: null
    });
    this.props.showHideDialogEditFile();
  };

  closeDialogEdit = () => {
    this.setState({ anchorEl: null, clientError: null });
    this.props.showHideDialogEditFile();
  };

  onSubmit = fileValues => {
    let clientError = null;

    fileValues.sitesUC = this.props.match.params.id;

    const trimedFileValues = trimObjValues(fileValues);

    if (!trimedFileValues.name) {
      clientError = {
        ...clientError,
        name: translateMessage(
          this.props.message.required,
          this.props.regexObject
        )
      };
    }
    this.setState({
      clientError: clientError
    });

    if (!clientError) {
      this.props.updateSiteFile(this.props.fileValues.id, fileValues);
      this.closeDialogEdit();
    }
  };

  openDialogDelete = () => {
    this.setState({
      anchorEl: null
    });
    this.props.showHideDialogDeleteFile();
  };

  openDialogForceDelete = () => {
    this.setState({
      anchorEl: null,
      isShowDialogForceDeleteFile: true
    });
  };

  closeDialogDelete = () => {
    this.setState({ anchorEl: null });
    this.props.showHideDialogDeleteFile();
  };

  closeDialogForceDelete = () => {
    this.setState({
      anchorEl: null,
      isShowDialogForceDeleteFile: false
    });
  };

  onDeleteSubmit = () => {
    this.props.deleteSiteFile(this.props.fileValues.id, this.state.isRefer);
    this.closeDialogDelete();
  };

  onForceDeleteSubmit = () => {
    if (this.state.isLockedFile) {
      this.props.deleteSiteFile(this.props.fileValues.id, true);
      this.closeDialogForceDelete();
    }
    else {
      this.openDialogDelete();
      this.closeDialogForceDelete();
    }
  };

  handleOpenDeleteDialog = () => {
    this.setState(
      {
        isRefer: false,
        isLockedFile: false,
        isFetchInfoFirst: true,
        getFileInfoType: "DELETE"
      },
      () => {
        this.props.fetchFileInfo(this.props.fileValues.id)
      }
    );
  }

  handleOpenEditDialog = () => {
    this.setState(
      {
        isFetchInfoFirst: true,
        getFileInfoType: "UPDATE"
      },
      () => {
        this.props.fetchFileInfo(this.props.fileValues.id)
      }
    );
  }

  isChanged(nextProps) {
    const nextType = nextProps.type;
    const { typeReload, type } = this.state;
    this.setState({type: nextProps.type});
    return (
      this.props.sitesUC !== nextProps.sitesUC || (type !== nextType && typeReload.includes(nextType))
    );
  }

  closeDialog() {
    if (this.props.isShowDialogEditFile) this.closeDialogEdit();
    if (this.props.isShowDialogDeleteFile) this.closeDialogDelete();
    if (this.props.isDialogPDFOpen) this.closeDialogPDF();
  }

  render() {
    const { anchorEl, clientError, isShowDialogForceDeleteFile, FileUpdateValues } = this.state;
    const { submitErrors } = this.props;

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <>
        <Sp>
          <FilesBuilderSP
            sitesUC={this.sitesUC}
            showPopover={this.showPopover}
          />
        </Sp>
        <Pc>
          <FilesBuilder
            sitesUC={this.sitesUC}
            showPopover={this.showPopover}
            openDialogEdit={this.openDialogEdit}
          />
        </Pc>

        {this.props.isShowDialogEditFile && (
          <CommonDialog
            title={"ファイル情報編集"}
            isDialogShow={this.props.isShowDialogEditFile}
            initialValues={FileUpdateValues}
            onClose={this.closeDialogEdit}
            fieldList={this.fieldList}
            submitButtonTitle={"登録"}
            isSingleColumn={true}
            onSubmit={this.onSubmit}
            submitErrors={clientError ? clientError : submitErrors}
          />
        )}
        <DialogDelete
          open={this.props.isShowDialogDeleteFile}
          onClose={this.closeDialogDelete}
          onDeleteSubmit={this.onDeleteSubmit}
          PaperProps={{
            className: "dialog dialog-sign-up"
          }}
        />

        <DialogDelete
          open={isShowDialogForceDeleteFile}
          onClose={this.closeDialogForceDelete}
          onDeleteSubmit={this.onForceDeleteSubmit}
          isTextBottomLeft={true}
          isTextNoKeepAll={true}
          textTop="削除しますか？"
          textBottom="このファイルを参照しているインフォタグが存在します。​"
          PaperProps={{
            className: "dialog dialog-sign-up"
          }}
        />

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.closePopover}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuList className="popover-sites">
            <MenuItem onClick={this.handleOpenEditDialog} className="popover-item">
              編集
            </MenuItem>
            <MenuItem onClick={this.handleOpenDeleteDialog} className="popover-item">
              削除
            </MenuItem>
          </MenuList>
        </Popover>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  files: getFiles(state.rootSitesReducer.siteInfoReducer.files),
  file: state.rootSitesReducer.siteInfoReducer.file,
  fileInfo: getFiles(state.rootSitesReducer.siteInfoReducer.fileInfo),
  type: state.rootSitesReducer.siteInfoReducer.type,
  isShowDialogEditFile:
    state.rootSitesReducer.siteInfoReducer.isShowDialogEditFile,
  isShowDialogDeleteFile:
    state.rootSitesReducer.siteInfoReducer.isShowDialogDeleteFile,
  fileValues: state.rootSitesReducer.siteInfoReducer.fileValues,
  submitErrors: state.storesReducer.error,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});

export default connect(mapStateToProps, {
  putFileError,
  showHideDialogEditFile,
  showHideDialogDeleteFile,
  deleteSiteFile,
  fetchFileInfo,
  updateSiteFile,
  setFileValues,
  searchFile
})(withRouter(Files));
