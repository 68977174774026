import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Fab from "@material-ui/core/Fab";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";

import {
  fetchRobotLogBuildDetailPage,
  fetchRobotEdit,
  wsUpdatedRobots,
  wsClearRobots,
  changeRobotUC
} from "./service/service";
import { robotClearType } from "../action/action";
import { getRobotDetails } from "./reducer/robotDetails";
import { getRobotEntries } from "./reducer/robotEntries";
import RobotLogBuildInfo from "./RobotInfo/RobotLogBuildInfo";
import RobotLogBuildHistory from "./RobotHistory/RobotLogBuildHistory";
import RobotDialog from "../RobotDialog";
import { convertSerialNumber } from "../../../utils/mapper";
import { createUserAcknowledgement } from "../../../common/Stores/service/service";

import "../../../common/css/base.css";
import "../../../common/css/log.css";
import { PUT_ROBOT_SUCCESS } from "../constant/constant";
import RobotStatus from "common/RobotStatus";
import { BUTTON_LOGKUN } from "common/constants/constants";
import { getWebSocketRobotURL } from "utils/mapper";
const WS_TIME_REPEAT = 10000; //10s

class RobotlogBuildDetail extends Component {
  robotsUC = undefined;
  ws = null;
  wsInterval = null;
  pingInterval = null;
  firstChange = true;
  state = {
    tabValue: "ロボット情報",
    isDialogShow: false,
    isDialogDeleteShow: false
  };

  componentDidMount() {
    this.props.fetchRobotLogBuildDetailPage(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.props.fetchRobotLogBuildDetailPage(nextProps.match.params.id);
    }
    if (
      this.state.isDialogShow &&
      this.props.type !== nextProps.type &&
      nextProps.type === PUT_ROBOT_SUCCESS
    ) {
      this.toogleDialog();
    }

    if (this.robotsUC !== nextProps.robotLogBuildDetails.uniqueCode) {
      if (
        (nextProps.type !== undefined &&
          (!this.firstChange ||
            this.props.robotUC === this.props.match.params.id)) ||
        nextProps.type === undefined
      ) {
        if (this.ws) {
          this.closeWS();
        }

        this.openWS([nextProps.robotLogBuildDetails.uniqueCode]);

        this.wsInterval = setInterval(() => {
          if (this.ws.readyState === 3) {
            clearInterval(this.pingInterval);
            this.openWS([nextProps.robotLogBuildDetails.uniqueCode]);
          }
        }, WS_TIME_REPEAT);
      }
    }

    if (nextProps.type !== undefined && this.firstChange) {
      this.firstChange = false;
    }
    this.robotsUC = nextProps.robotLogBuildDetails.uniqueCode;
  }

  componentWillUnmount() {
    if (this.ws) {
      this.closeWS();
      this.props.changeRobotUC(this.props.match.params.id);
    }
  }

  openWS = robotsUC => {
    this.ws = new WebSocket(getWebSocketRobotURL());
    this.ws.addEventListener("open", () => {
      this.ws.send(JSON.stringify(robotsUC));

      this.pingInterval = setInterval(() => {
        this.ws.send("ping");
      }, WS_TIME_REPEAT);
    });

    this.ws.onmessage = s => {
      if (s.data !== "pong") {
        this.props.wsUpdatedRobots(JSON.parse(s.data));
      }
    };
  };

  closeWS = () => {
    this.ws.onclose = function () {}; // disable onclose handler first
    this.ws.close();
    this.props.wsClearRobots();
    clearInterval(this.pingInterval);
    clearInterval(this.wsInterval);
  };

  tabChange = (e, value) => {
    this.setState({
      tabValue: value
    });
  };

  toogleDialog = () => {
    this.setState({ isDialogShow: !this.state.isDialogShow });
  };

  onCreateUserAcknowledgement = (id, status) => () => {
    const value = {
      uniqueCode: id
    };
    if (status) {
      this.props.createUserAcknowledgement(value);
    }
  };

  toggleDialogDelete = () => {
    this.setState({
      isDialogDeleteShow: !this.state.isDialogDeleteShow,
      isDialogShow: false
    });
  };

  render() {
    const { tabValue, isDialogShow, isDialogDeleteShow } = this.state;
    const {
      robotLogBuildEntries,
      robotLogBuildDetails,
      match,
      type,
      robotsUpdate
    } = this.props;

    return (
      <Grid container wrap="nowrap">
        <Grid className="log-list-wrap">
          <List disablePadding className="log-list">
            {robotLogBuildEntries &&
              robotLogBuildEntries.map(entries => (
                <Link
                  to={`/logbuild/robots/${entries.uniqueCode}`}
                  key={entries.uniqueCode}
                  className="reset-link"
                >
                  <ListItem
                    selected={entries.uniqueCode === match.params.id}
                    button
                    className="image-list-item"
                  >
                    <ListItemText
                      className="clip-text"
                      primary={entries.name}
                    />
                  </ListItem>
                </Link>
              ))}
          </List>
          <Link to="/logbuild/robots" className="reset-link">
            <Fab variant="extended" className="back-btn">
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                className="icon-margin"
              />
              一覧に戻る
            </Fab>
          </Link>
        </Grid>
        <Grid className="log-content">
          <Grid container wrap="nowrap" justify="space-between">
            <Grid className="log-title">
              <Typography variant="h4" className="title-name">
                {robotLogBuildDetails.robotNo}
              </Typography>
              <Fab
                onClick={this.toogleDialog}
                disableRipple
                variant="extended"
                className="log-button-reset"
              >
                <EditIcon className="icon-margin" />
                編集
              </Fab>
            </Grid>
            <Grid className="header-robot-logbuild-wrap">
              <Grid className="mr-40">
                <Typography
                  className="font-family text-bold"
                  variant="h6"
                  align="right"
                >
                  {robotLogBuildDetails.builderName}
                </Typography>
                <Typography
                  className="font-family text-bold keep-all"
                  variant="h6"
                  align="right"
                >
                  {robotLogBuildDetails.leaseStatusName}
                </Typography>
              </Grid>
              <RobotStatus
                typeOfRobotStatus={BUTTON_LOGKUN}
                robots={[robotLogBuildDetails]}
                robotsUpdate={[robotsUpdate]}
                forceCreateUserAcknowledgement={true}
              />
            </Grid>
          </Grid>
          <Grid className="sites-tab">
            <Tabs
              className="tabs"
              value={tabValue}
              onChange={this.tabChange}
              aria-label="simple tabs example"
            >
              <Tab
                className="tab-button"
                label="ロボット情報"
                value="ロボット情報"
              />
              <Tab className="tab-button" label="貸出履歴" value="貸出履歴" />
            </Tabs>
            {tabValue === "ロボット情報" ? (
              <RobotLogBuildInfo />
            ) : (
              <RobotLogBuildHistory
                leaseStatus={robotLogBuildDetails.leaseStatus}
              />
            )}
          </Grid>
        </Grid>
        {(isDialogShow || isDialogDeleteShow) && (
          <RobotDialog
            isDialogShow={isDialogShow}
            isDialogDeleteShow={isDialogDeleteShow}
            toggleDialogDelete={this.toggleDialogDelete}
            onClose={this.toogleDialog}
            isEdit={true}
            robotId={this.props.match.params.id}
            history={this.props.history}
          />
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  robotLogBuildDetails: getRobotDetails(
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .robotLogBuildDetails
  ),
  robotLogBuildEntries: getRobotEntries(
    state.rootRobotsReducer.rootRobotDetailsReducer.robotEntriesReducer
      .robotLogBuildEntries
  ),
  type: state.rootRobotsReducer.robotsReducer.type,
  robotsUpdate:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .wsUpdatedRobots,
  robotUC:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer.robotUC
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRobotLogBuildDetailPage,
      robotClearType,
      createUserAcknowledgement,
      fetchRobotEdit,
      wsUpdatedRobots,
      wsClearRobots,
      changeRobotUC
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RobotlogBuildDetail));
