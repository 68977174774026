import {
  fetchSitesSuccess,
  postSitesSuccess,
  updateSiteSuccess,
  deleteSitesSuccess,
  completeSitesSuccess,
  clearType as actionClearType,
  addImageSiteSuccess,
  changeTypeListSitesSuccess
} from "../action/action";
import { api } from "../../../common/api/api";
import {
  fetchOneSite,
  fetchSiteInfo,
  fetchEntries
} from "../site/service/service";

import {
  dispatchError,
  enableLoading,
  finishLoading
} from "../../../common/Stores/service/service";

export const fetchSiteList = (isCompleteSite = false) => {
  const url = isCompleteSite
    ? "builder/completed-sites"
    : "/builder/incomplete-sites";
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(url)
      .then(res => dispatch(fetchSitesSuccess(res.data)))
      .then(() => {
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const addSite = (site, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/sites`, site, {
        "content-type": "multipart/form-data"
      })
      .then(res => dispatch(postSitesSuccess(res.data)))
      .then(() => {
        dispatch(finishLoading());
      })
      .catch(err => {
       if(handleError && err.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(err));
        }
      });
  };
};

export const reloadAfterUpdateSite = siteId => {
  return dispatch => {
    dispatch(enableLoading());
    dispatch(fetchOneSite(siteId)).then(res => {
      Promise.all([
        dispatch(fetchSiteInfo(siteId)),
        dispatch(fetchEntries(res && res.CompletedAt ? true : false))
      ])
        .then(() => {
          dispatch(finishLoading());
        })
        .catch(errors => {
          dispatch(dispatchError(errors));
        });
    });
  };
};

export const updateSite = (site, id, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/sites/${id}`, site)
      .then(res => dispatch(updateSiteSuccess(res.data)))
      .then(() => {
        dispatch(finishLoading());
      })
      .catch(err => {
       if(handleError && err.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(err));
        }
      });
  };
};
export const deleteSite = (id, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/sites/${id}`)
      .then(res => dispatch(deleteSitesSuccess(res.data)))
      .then(() => {
        dispatch(finishLoading());
      })
      .catch(err => {
       if(handleError && err.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(err));
        }
      });
  };
};

export const clearType = () => {
  return dispatch => {
    dispatch(actionClearType());
  };
};

export const completeSite = id => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/builder/sites/${id}/complete`)
      .then(res => dispatch(completeSitesSuccess(res.data)))
      .then(() => {
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const changeTypeListSites = isCardList => {
  return dispatch => {
    dispatch(changeTypeListSitesSuccess(isCardList));
  };
};
