import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { api } from "../../common/api/api";
import axios from "axios";
import logo from "../../common/img/card-image.png";

export class CardImage extends Component {
  class = "";
  alt = "card image";
  url = "";
  blob = "";
  ajaxRequest = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      img: "",
      
    };
  }

  componentWillMount() {
    this.url = this.props.url;
    this.blob = this.props.blob;
    this.fetch();
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) this.props.history.push("/login");
  }

  componentWillReceiveProps() {
    if (this.url !== this.props.url || this.blob !== this.props.blob) {
      this.fetch();
    }
  }
  fetch() {
    var isBlob = this.props.blob ? true : false;
    var url = isBlob ? this.props.blob : this.props.url;

    if (url) {
      api
        .get(url.substring(5), {
          responseType: isBlob ? undefined : "arraybuffer",
          cancelToken: this.ajaxRequest.token
        })
        .then(res => {
          if (res.data === undefined) return Promise.reject();
          if (isBlob) {
            var url = res.data.blobData;
          } else {
            var blob = new Blob([res.data], { type: "image/jpeg" });
            var url = URL.createObjectURL(blob);
          }
          this.class = "";
          return url;
        })
        .then(url => this.img(url))
        .then(img => {
          this.setState({ img });
        })
        .catch(e => {
          if (e?.response?.status === 404) {
            this.class = "";
            this.setState({ img: this.img("") });
          }
        });
    } else {
      this.class = "logo-image";
      this.setState({ img: this.img(logo) });
    }
  }

  componentWillUnmount() {
    this.ajaxRequest.cancel();
  }

  img(data) {
    return <img src={data} className={this.class} alt={this.alt} />;
  }

  render() {
    return this.state.img;
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(withRouter(CardImage));
