import React from "react";

import { withRouter } from "react-router-dom";
import { Grid, Tooltip, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import { ResizeSensor } from "css-element-queries";
import {
  MODE_DEVICE
} from "../sites/site/LogWalkViewer/constants";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import DrawingPhoto from "./DrawingPhoto";

class MiniWindowViewer extends React.Component {
  cancelSubmission = false;

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      positions: [],
      heightMiniWindow: 80,
      widthMiniWindow: 120
    };
    this.transformComponentRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    const element = document.getElementById("container-mini-viewer");
    new ResizeSensor(element, () => {
      if (!this.cancelSubmission) {
        this.setState({
          widthMiniWindow: element.offsetWidth + 2, // sum padding 2 px
          heightMiniWindow: element.offsetHeight + 2
        });
      }

      this.updateDimensions();
    });
  }

  updateDimensions = e => {
    this.setPositionMarker();
  };

  componentWillUnmount() {
    this.cancelSubmission = true;
  }

  returnDefaultSize = () => {
    let currentRef = this.transformComponentRef.current;
    if (currentRef != null) {
      currentRef.context.dispatch.resetTransform();
    }
  };

  setPositionMarker = () => {
    if (!this.cancelSubmission) {
      const posAfter = [];
      let element = document.getElementById("primary-image");
      let naturalWidth = element ? element.naturalWidth : 0;

      let naturalHeight = element ? element.naturalHeight : 0;

      const elMap = document.getElementsByClassName("img-mini-parent")[0];
      let scaleMap = elMap ? elMap.offsetWidth / elMap.offsetHeight : 0;
      let isVerticalImg = naturalHeight * scaleMap > naturalWidth;

      let offsetWidth = 0;
      let offsetHeight = 0;

      if (isVerticalImg) {
        offsetWidth = elMap
          ? (elMap.offsetHeight * naturalWidth) / naturalHeight
          : 0;

        offsetHeight = elMap ? elMap.offsetHeight : 0;
      } else {
        offsetWidth = elMap ? elMap.offsetWidth : 0;

        offsetHeight = elMap
          ? (elMap.offsetWidth * naturalHeight) / naturalWidth
          : 0;
      }

      let offsetLeft = elMap ? (elMap.offsetWidth - offsetWidth) / 2 : 0;

      let offsetTop = elMap ? (elMap.offsetHeight - offsetHeight) / 2 : 0;

      this.props.listSpots.map(pos => {
        posAfter.push({
          posX: (pos.x * offsetWidth) / naturalWidth + offsetLeft,
          posY: (pos.y * offsetHeight) / naturalHeight + offsetTop
        });
      });
      this.setState({
        positions: posAfter
      });
    }
  };

  showDialogViewer = () => {
    this.props.onShowDialogViewer(true);
  };

  tapToShowFullImage = () => {
    const { modeDevice } = this.props;
    if (modeDevice === MODE_DEVICE.SP) {
      this.props.onShowDialogViewer(true);
    }
  };

  checkShowHand = () => {
    const { widthMiniWindow, heightMiniWindow } = this.state;
    return widthMiniWindow === 120 && heightMiniWindow === 80;
  };

  render() {
    const { drawingName } = this.props;

    return (
      <div
        className="container-mini-viewer"
        id="container-mini-viewer"
        style={{ direction: "ltr" }}
        onClick={e => e.preventDefault()}
      >
        <Grid container style={{ height: "100%" }}>
          <Grid container item>
            <Grid item xs={1} />
            {this.props.modeDevice !== MODE_DEVICE.SP && (
              <Grid item xs={10} alignContent="center">
                <strong>
                  <div className={`image-title-viewer`}>
                    <Typography variant="h8">{drawingName}</Typography>
                  </div>
                </strong>
              </Grid>
            )}

            {this.props.modeDevice === MODE_DEVICE.SP && (
              <Grid
                item
                xs={10}
                alignContent="center"
                onClick={this.tapToShowFullImage}
              >
                <strong>
                  <div className={`image-title-viewer`}>
                    <Typography variant="h8">{drawingName}</Typography>
                  </div>
                </strong>
              </Grid>
            )}

            <Grid item xs={1}>
              {this.props.modeDevice !== MODE_DEVICE.SP && (
                <Tooltip title="最大表示">
                  <IconButton onClick={this.showDialogViewer} size="small">
                    <ZoomOutMapIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          {this.props.modeDevice === MODE_DEVICE.SP && (
            <Grid container item className="image-container">
              <Grid item xs={1} className="position-btn"></Grid>
              <Grid
                item
                xs={10}
                className="img-mini-parent content-drawing-dialog-container"
              >
                <div
                onClick={this.tapToShowFullImage}
                >
                  <div>
                    <DrawingPhoto
                      tapToShowFullImage={this.tapToShowFullImage}
                      class="img-mini"
                      id="primary-image"
                      alt=""
                      isSP={true}
                      positions={this.state.positions}
                      drawingUrl={this.props.urlPhoto}
                      drawingBlob={this.props.drawingBlob}
                      drawingName={this.props.drawingName}
                      loadMarker={this.setPositionMarker}
                    />
                  </div>
                </div>
              </Grid>
              <Grid className="position-btn" item xs={1}></Grid>
            </Grid>
          )}
          {this.props.modeDevice === MODE_DEVICE.PC && (
            <>
              <TransformWrapper defaultScale={1}>
                <Grid container item className="image-container mt-5">
                  <Grid item xs={1} className="position-btn"></Grid>
                  <Grid
                    item
                    xs={10}
                    className="img-mini-parent content-drawing-dialog-container"
                  >
                    <TransformComponent ref={this.transformComponentRef}>
                      <div

                      // onClick={this.tapToShowFullImage}
                      >
                        <div onClick={this.tapToShowFullImage}>
                          <DrawingPhoto
                            class="img-mini"
                            id="primary-image"
                            alt=""
                            positions={this.state.positions}
                            drawingUrl={this.props.urlPhoto}
                            drawingBlob={this.props.drawingBlob}
                            drawingName={this.props.drawingName}
                            loadMarker={this.setPositionMarker}
                          />
                        </div>
                      </div>
                    </TransformComponent>
                  </Grid>
                  <Grid className="position-btn" item xs={1}></Grid>
                </Grid>
              </TransformWrapper>
            </>
          )}
        </Grid>
      </div>
    );
  }
}

export default withRouter(MiniWindowViewer);
