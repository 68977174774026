import React from "react";
import PropTypes from "prop-types";
import IconEdit from "../hotspotInfoIcons/IconEdit";

function Label(props) {
  const { label } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "17px",
        fontWeight: "bold",
        lineHeight: "24px",
        padding: "30px 0px 10px"
      }}
    >
      <span
        style={{
          cursor: "default",
        }}
      >
        {label}
      </span>
      <IconEdit />
    </div>
  );
}

Label.propTypes = {
  label: PropTypes.string,
  isRequire: PropTypes.bool
};

Label.defaultProps = {
  isRequire: true
};

export default Label;
