import { SET_CURRENT_USER, USER_LOADING } from "../../../actions/types";
import {
  POST_LOGIN_START,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_ERROR,
  POST_REGISTER_ERROR,
  POST_REGISTER_SUCCESS,
  POST_REGISTER_START,
  FORGOT_PASSWORD_SUCCESS,
  FETCH_REGEX_SUCCESS,
  FORGOT_PASSWORD_SP_SUCCESS,
  FORGOT_PASSWORD_LOGBUILD_SUCCESS
} from "../constant/constant";

const isEmpty = require("is-empty");
const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  emailConfirm: false,
  emailConfirmSP: false,
  emailConfirmLogBuild: false
};

export default function (originState = initialState, action) {
  const state = { ...originState, type: action.type };
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true
      };

    // Start refactor
    case POST_LOGIN_START:
      return {
        ...state,
        pending: true
      };
    case POST_LOGIN_SUCCESS:
      return {
        ...state,
        pending: false
      };
    case POST_LOGIN_ERROR:
      return {
        ...state,
        pending: false
      };
    case POST_REGISTER_START:
      return {
        ...state,
        pending: true
      };
    case POST_REGISTER_SUCCESS:
      return {
        ...state,
        pending: false
      };
    case POST_REGISTER_ERROR:
      return {
        ...state,
        pending: false
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        pending: false,
        emailConfirm: action.status
      };
    case FETCH_REGEX_SUCCESS:
      return {
        ...state,
        regex: action.regex
      };
    case FORGOT_PASSWORD_SP_SUCCESS: {
      return {
        ...state,
        emailConfirmSP: action.status
      };
    }
    case FORGOT_PASSWORD_LOGBUILD_SUCCESS: {
      return {
        ...state,
        emailConfirmLogBuild: action.status
      };
    }

    // End refactor
    default:
      return state;
  }
}
