import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Component from "./Upload";
import {
  fetchPhotos,
  showOriginalPhoto,
  postPhoto as postFile,
  downloadPhoto
} from "./service/service";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import UploadIcon from "@material-ui/icons/CloudUpload";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";

import { format } from "date-fns";
import Photo from "./Photo";
import { getPhotos } from "./reducer/reducer";
import EditImageDialog from "./editImageDialog";
import {
  PUT_PHOTO_SUCCESS,
  DELETE_PHOTO_SUCCESS,
  GET_PHOTOS_SUCCESS
} from "./constant/constant";
import {
  dispatchError as fillPhotoError,
  finishLoading
} from "../../../common/Stores/service/service";
import LazyLoad from "react-lazyload";
import { api } from "../../../common/api/api";
import axios from "axios";
import ReactDOM from "react-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Swipeable } from "react-swipeable";
import Popover from "@material-ui/core/Popover";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import {
  translateValidate,
  messageValidate
} from "../../../common/Stores/service/service";

const RIGHT = "-1";
const LEFT = "+1";

class PhotosBuilderSP extends Component {
  ajaxRequest = axios.CancelToken.source();

  shoudHideLoading = true;

  state = {
    isDialogShowImage: false,
    isDialogShowImageEdit: false,
    isDialogDeleteShow: false,
    currentPhoto: {},
    anchorEl: null,
    disalbeNextBack: false,
    initialValuesEdit: {
      name: "",
      regisionDate: ""
    },
    options: [
      {
        name: "1階",
        selected: false
      },
      {
        name: "2階",
        selected: true
      },
      {
        name: "玄関",
        selected: false
      },
      {
        name: "トイレ",
        selected: true
      }
    ],
    typeReload: [PUT_PHOTO_SUCCESS, DELETE_PHOTO_SUCCESS]
  };

  changeStatus = (index, selected) => () => {
    const newValue = Object.assign([], this.state.options);
    newValue[index].selected = !selected;
    this.setState({
      options: newValue
    });
  };

  fetch() {
    this.props.fetchPhotos(this.sitesUC);
  }

  fetchOnDialog(id, name, newDate, blobData) {
    var isBlob = blobData ? true : false;
    api
      .get(
        isBlob
          ? blobData.resized.replace("/api", "")
          : "/builder/site-photos/" + id + "/thumbnail",
        {
          responseType: isBlob ? undefined : "arraybuffer",
          cancelToken: this.ajaxRequest.token
        }
      )
      .then(res => {
        if (res.data === undefined) return Promise.reject();
        var url = "";
        if (res.data.blobData) {
          url = res.data.blobData;
        } else {
          var blob = new Blob([res.data], { type: "image/jpeg" });
          url = URL.createObjectURL(blob);
        }

        this.setState(
          {
            currentPhoto: {
              photoId: id,
              url: url,
              blob: blobData,
              name: name
            },
            initialValuesEdit: { name: name, regisionDate: newDate }
          },
          () => {
            this.setState({ disalbeNextBack: false });
          }
        );

        ReactDOM.render(
          <Grid className="div-photo">
            <img
              src={url}
              alt={id}
              onClick={() => this.onImageClick(id, name, url, newDate)}
            ></img>
          </Grid>,
          document.querySelector(`span[photoid="${id}"]`)
        );
      })
      .catch(e => {
        if (e?.response?.status === 404) {
          this.setState({ url: "" });
        }
      });
  }

  isChanged(nextProps) {
    const nextType = nextProps.type;
    const { typeReload } = this.state;
    return (
      this.props.sitesUC !== nextProps.sitesUC ||
      (this.props.type !== nextType && typeReload.includes(nextType))
    );
  }

  closeDialog() {
    if (this.state.isDialogShowImageEdit) {
      this.toggleEditImageDialogShow();
      this.toggleDialogImage();
    }
  }

  onImageClick = (photoId, blob, name, url, extension, date) => {
    this.setState({
      currentPhoto: {
        photoId: photoId,
        blob: blob,
        name: name,
        url: url,
        extension: extension
      },
      initialValuesEdit: { name: name, regisionDate: date }
    });
    this.toggleDialogImage();
  };

  onSwiped(direction) {
    const change = direction === RIGHT;
    this.handleChangeImage(!change);
    setTimeout(() => {
      this.setState({
        onSwiping: false
      });
    }, 1);
  }

  showPopover = e => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  closePopover = e => {
    this.setState({
      anchorEl: null
    });
  };

  downloadPhoto = () => {
    const { currentPhoto } = this.state;
    this.props.downloadPhoto(
      currentPhoto.photoId,
      currentPhoto.blob,
      currentPhoto.name,
      currentPhoto.extension
    );
    this.closePopover();
  };

  handleKeyDown = event => {
    if (event.keyCode === 37) {
      this.handleChangeImage(false);
    }

    if (event.keyCode === 39) {
      this.handleChangeImage(true);
    }
  };

  handleChangeImage = isNext => {
    this.setState({ disalbeNextBack: true });
    const { photos } = this.props;
    const { currentPhoto } = this.state;
    const size = photos.length;
    for (let i = 0; i < size; i++) {
      const currentIndex = photos[i].photos.findIndex(
        photo => photo.photoId === currentPhoto.photoId
      );
      if (currentIndex !== -1) {
        const ptSize = photos[i].photos.length;

        let newIndex = 0;
        let dateIndex = i;
        if (isNext) {
          newIndex = currentIndex + 1;
          if (newIndex === ptSize) {
            dateIndex = dateIndex + 1 >= size ? 0 : dateIndex + 1;
            newIndex = 0;
          }
        } else {
          newIndex = currentIndex - 1;
          if (newIndex < 0) {
            dateIndex = dateIndex - 1 < 0 ? size - 1 : dateIndex - 1;
            newIndex = photos[dateIndex].photos.length - 1;
          }
        }

        const newPhoto = photos[dateIndex].photos[newIndex];

        const newDate = photos[dateIndex].date;
        if (newPhoto === undefined) {
          this.setState({ disalbeNextBack: false });
          break;
        }

        if (newPhoto.url === undefined) {
          this.fetchOnDialog(newPhoto.photoId, newPhoto.name, newDate, newPhoto.blob);
        } else {
          this.setState(
            {
              currentPhoto: {
                photoId: newPhoto.photoId,
                blob: newPhoto.blob,
                url: newPhoto.url,
                name: newPhoto.name,
                extension: newPhoto.extension
              },
              initialValuesEdit: { name: newPhoto.name, regisionDate: newDate }
            },
            () => {
              this.setState({ disalbeNextBack: false });
            }
          );
        }
        break;
      }
    }
  };

  onGetUrlPhotoSuccess = (photoId, name, url) => {
    const { photos, finishLoading, loading, loadPhotoTab } = this.props;
    const size = photos.length;
    for (let i = 0; i < size; i++) {
      const pt = photos[i].photos.find(photo => photo.photoId === photoId);
      if (pt !== undefined) {
        pt.url = url;
        pt.name = name;
        break;
      }
    }

    for (let i = 0; i < photos.length; i++) {
      if (
        photos[i].photos.filter(p => p.url === undefined || p.url === null)
          .length > 0
      ) {
        this.shoudHideLoading = false;
        break;
      } else {
        this.shoudHideLoading = true;
      }
    }
    if (this.shoudHideLoading) {
      this.shoudHideLoading = false;
      if (loading && loadPhotoTab) {
        finishLoading();
      } else this.props.onHideLoading();
    }
  };

  toggleDialogDelete = () => {
    this.props.fillPhotoError("");
    this.setState({
      isDialogDeleteShow: !this.state.isDialogDeleteShow,
      isDialogShowImageEdit: false
    });
  };

  openDialogDelete = () => {
    this.props.fillPhotoError("");
    this.setState({
      isDialogDeleteShow: true
    });
  };


  toggleDialogImage = () => {
    this.setState({
      isDialogShowImage: !this.state.isDialogShowImage
    });
  };

  showOriginalPhoto = currentPhoto => {
    this.props.showOriginalPhoto(currentPhoto.photoId, currentPhoto.blob);
  };

  toggleEditImageDialogShow = () => {
    this.props.fillPhotoError("");
    this.setState({
      isDialogShowImageEdit: !this.state.isDialogShowImageEdit,
      anchorEl: null
    });
  };

  componentDidMount() {
    this.props.translateValidate();
    this.props.messageValidate();
  }

  closeImage = () => {
    this.props.fillPhotoError("");
    this.setState({
      isDialogDeleteShow: false,
      isDialogShowImageEdit: false,
      isDialogShowImage: false,
      anchorEl: null
    });
  };

  componentWillUnmount() {
    this.ajaxRequest.cancel();

    if (this.props.photos !== undefined) this.props.photos.length = 0;
    finishLoading();
  }

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);

    if (this.props.match.params.id !== nextProps.match.params.id) return;
    if (nextProps.type === DELETE_PHOTO_SUCCESS) {
      this.setState({
        isDialogDeleteShow: false
      });
    }

    const oldList = this.props.photos;
    const newList = nextProps.photos;

    if (
      oldList === undefined ||
      oldList.length === 0 ||
      newList === undefined ||
      newList.length === 0
    )
      return;

    for (let i = 0; i < oldList.length; i++) {
      const newDate = newList.find(date => date.date === oldList[i].date);
      if (newDate === undefined) continue;
      const oldPhotos = oldList[i].photos;
      for (let j = 0; j < oldPhotos.length; j++) {
        const temp = oldPhotos[j];
        const newPhoto = newDate.photos.find(
          photo => photo.photoId === temp.photoId
        );
        if (newPhoto === undefined) continue;
        newPhoto.url = temp.url;
      }
    }
  }

  componentDidUpdate() {
    const { finishLoading, type } = this.props;

    if (type !== GET_PHOTOS_SUCCESS) {
      return;
    }

    finishLoading();
  }

  render() {
    const { photos } = this.props;
    const {
      isDialogShowImage,
      currentPhoto,
      disalbeNextBack,
      initialValuesEdit,
      isDialogShowImageEdit,
      isDialogDeleteShow,
      anchorEl
    } = this.state;

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <>
        <Grid className="image-wrap-sp">
          {photos &&
            photos.map(datePhotos => (
              <Grid className="image-item" key={datePhotos.date}>
                {/* [NOTE]: 現場写真一覧の日付隠し対応 */}
                <Typography
                  className="image-cap"
                  variant="h6"
                  style={{ fontSize: "1rem", fontWeight: "bold" }}
                >
                  {format(new Date(datePhotos.date), "yyyy年M月d日")}
                </Typography>
                {datePhotos.photos.map(photo => (
                  <Grid
                    className="cursor-pointer"
                    key={photo.photoId}
                    photoid={photo.photoId}
                    component="span"
                  >
                    <LazyLoad
                      overflow
                      offset={100}
                      placeholder={<div className="placeholder-image-sp"></div>}
                    >
                      <Grid className="div-photo-sp">
                        <Photo
                          name={photo.name}
                          alt={photo.photoId}
                          PhotosUC={photo.photoId}
                          blob={photo.blob}
                          onClick={() =>
                            this.onImageClick(
                              photo.photoId,
                              photo.blob,
                              photo.name,
                              photo.url,
                              photo.extension,
                              datePhotos.date
                            )
                          }
                          onGetSuccess={this.onGetUrlPhotoSuccess}
                        />
                      </Grid>
                    </LazyLoad>
                  </Grid>
                ))}
              </Grid>
            ))}
        </Grid>
        {(isDialogShowImageEdit || isDialogDeleteShow) && (
          <EditImageDialog
            title={"写真情報編集"}
            isDialogShow={isDialogShowImageEdit}
            isDialogDeleteShow={isDialogDeleteShow}
            initialValues={initialValuesEdit}
            toggleDialogDelete={this.toggleDialogDelete}
            onClose={this.toggleEditImageDialogShow}
            closeImage={this.closeImage}
            submitButtonTitle={"登録"}
            openDialogDelete={this.openDialogDelete}
            photoId={currentPhoto.photoId}
          />
        )}
        <input
          type="file"
          id="file"
          accept=".jpg,.jpeg,.png"
          multiple
          onChange={e => this.upload(e)}
          style={{ display: "none" }}
        />

        <Fab className="button-sp button-upload-sp button-logwalk-menu-center-sp">
          <label htmlFor="file" style={{ color: "inherit" }}>
            <UploadIcon className="white-text icon" style={{ width: "26px", height: "26px" }}/>
          </label>
        </Fab>

        <Dialog
          open={isDialogShowImage}
          aria-labelledby="max-width-dialog-title"
          PaperProps={{
            className: "dialog image-dialog-edit"
          }}
          onKeyDown={this.handleKeyDown}
        >
          <DialogTitle disableTypography className="header-dialog">
            <Fab onClick={this.toggleDialogImage} size="small">
              <ArrowBackIosIcon />
            </Fab>
            <Typography variant="body1">{currentPhoto.name}</Typography>
            <Fab onClick={this.showPopover} size="small">
              <MoreVertIcon />
            </Fab>
          </DialogTitle>
          <DialogContent className="content-dialog">
            <Grid>
              <Swipeable
                trackMouse
                preventDefaultTouchmoveEvent
                onSwipedLeft={() => this.onSwiped(LEFT)}
                onSwipedRight={() => this.onSwiped(RIGHT)}
                onSwiping={this.onSwiping}
              >
                <img
                  src={currentPhoto.url}
                  alt={currentPhoto.photoId}
                  draggable={false}
                  onClick={() => this.showOriginalPhoto(currentPhoto)}
                />
                <Fab
                  className="white-text reset-button-image button-prev-dialog"
                  disabled={disalbeNextBack}
                  onClick={() => this.handleChangeImage(false)}
                >
                  <FontAwesomeIcon
                    size="3x"
                    icon={faChevronLeft}
                    className="white-text"
                  />
                </Fab>
                <Fab
                  className="white-text reset-button-image button-next-dialog"
                  disabled={disalbeNextBack}
                  onClick={() => this.handleChangeImage(true)}
                >
                  <FontAwesomeIcon
                    size="3x"
                    icon={faChevronRight}
                    className="white-text"
                  />
                </Fab>
              </Swipeable>
            </Grid>
          </DialogContent>
        </Dialog>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.closePopover}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuList className="popover-sites">
            <MenuItem onClick={this.toggleEditImageDialogShow}>編集</MenuItem>
            <MenuItem onClick={this.downloadPhoto}>ダウンロード</MenuItem>
          </MenuList>
        </Popover>
      </>
    );
  }
}

PhotosBuilderSP.propTypes = {
  sitesUC: PropTypes.string,
  onHideLoading: PropTypes.func,
  loadPhotoTab: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth,
  photos: getPhotos(state.rootSitesReducer.siteInfoReducer.photos),
  type: state.rootSitesReducer.siteInfoReducer.type,
  loading: state.storesReducer.pending,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPhotos,
      showOriginalPhoto,
      postFile,
      downloadPhoto,
      fillPhotoError,
      finishLoading,
      translateValidate,
      messageValidate
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PhotosBuilderSP));
