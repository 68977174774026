import {
  fetchRobotSuccess,
  fetchRobotLogBuildSuccess,
  createRobotSuccess,
  updateRobotSuccess,
  updateRobotStart,
  updateRobotError,
  deleteRobotSuccess
} from "../action/action";
import { api } from "../../../common/api/api";

import {
  dispatchError,
  enableLoading,
  finishLoading
} from "../../../common/Stores/service/service";
import { fetchRobotLogBuildDetailInfo } from "../robotDetails/RobotInfo/service/service";

export const fetchRobot = () => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/robots`)
      .then(res => {
        dispatch(fetchRobotSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchRobotLogBuild = () => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/robots`)
      .then(res => {
        dispatch(fetchRobotLogBuildSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const createRobot = value => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/logbuild/robots`, value)
      .then(res => {
        dispatch(createRobotSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const updateRobot = (robotUC, value) => {
  return dispatch => {
    dispatch(updateRobotStart());
    api
      .put(`/logbuild/robots/${robotUC}`, value)
      .then(res => {
        dispatch(updateRobotSuccess());
        dispatch(fetchRobotLogBuildDetailInfo(robotUC));
      })
      .catch(err => {
        dispatch(dispatchError(err));
        dispatch(updateRobotError());
      });
  };
};

export const deleteRobot = robotUC => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/logbuild/robots/${robotUC}`)
      .then(res => {
        dispatch(deleteRobotSuccess());
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};
