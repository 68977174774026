import {
  FETCH_BUILDER_DETAIL_SUCCESSS,
  FETCH_BUILDER_ENTRIES_SUCCESS,
  FETCH_BUILDER_SUMMARY_SUCCESSS,
  FETCH_BUILDER_SUCCESS,
  FETCH_ROBOT_ENTRIES_SUCCESS,
  FETCH_STAFF_ENTRIES_SUCCESS,
  FETCH_BUILDER_ROBOT_LEASES_SUCCESS,
  FETCH_ROBOT_LEASES_SUCCESS,
  FETCH_AGREEMENT_LOGS_SUCCESS,
  FETCH_AGREEMENT_LOGS_DETAIL_SUCCESS,
  FETCH_BUILDER_FEATURE_FLAG_SUCCESS
} from "../constant/constant";

const initialState = {
  builderDetail: "",
  entries: [],
  summary: "",
  builder: {},
  robotEntries: [],
  staffEntries: [],
  robotLease: [],
  featureFlag: [],
  agreements: [],
  agreementDetail: ""
};

export const builderDetailReducer = (originState = initialState, action) => {
  const state = { ...originState, type: action.type };
  switch (action.type) {
    case FETCH_BUILDER_DETAIL_SUCCESSS:
      return {
        ...state,
        builderDetail: action.builderDetail
      };
    case FETCH_BUILDER_SUCCESS:
      return {
        ...state,
        builder: action.builder
      };
    case FETCH_BUILDER_ENTRIES_SUCCESS:
      return {
        ...state,
        entries: action.entries
      };
    case FETCH_BUILDER_SUMMARY_SUCCESSS:
      return {
        ...state,
        summary: action.summary
      };
    case FETCH_ROBOT_ENTRIES_SUCCESS:
      return {
        ...state,
        robotEntries: action.robotEntries
      };
    case FETCH_STAFF_ENTRIES_SUCCESS:
      return {
        ...state,
        staffEntries: action.staffEntries
      };
    case FETCH_BUILDER_ROBOT_LEASES_SUCCESS:
      return {
        ...state,
        robotLease: action.robotLease
      };
    case FETCH_BUILDER_FEATURE_FLAG_SUCCESS:
      return {
        ...state,
        featureFlag: action.featureFlag
      };
    case FETCH_ROBOT_LEASES_SUCCESS:
      return {
        ...state,
        robotLeases: action.robots
      };
    case FETCH_AGREEMENT_LOGS_SUCCESS:
      return {
        ...state,
        agreements: action.agreements
      };
    case FETCH_AGREEMENT_LOGS_DETAIL_SUCCESS:
      return {
        ...state,
        agreementDetail: action.agreement
      };

    default:
      return state;
  }
};

export const getBuilder = state => state.builderReducer.builder;
