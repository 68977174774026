import React, { useEffect, useRef, useState } from "react";
import marzipano from "marzipano";
import IconInfo from "../hotspotInfoIcons/IconInfo";

function ReviewHotspotPosition(props) {
  const {
    miniBlobDataLogWalk,
    yaw,
    pitch,
    iconColor,
    largeBase64,
    setLargeBase64,
    base64,
    setBase64
  } = props;

  const marzipanoRef = useRef(null);
  const [isTakingSnapshot, setIsTakingSnapshot] = useState(false);
  const [isTakingLargeSnapshot, setIsTakingLargeSnapshot] = useState(false);
  useEffect(() => {
    if (
      base64 === "" &&
      miniBlobDataLogWalk &&
      miniBlobDataLogWalk.geoType !== "" &&
      !isTakingSnapshot
    ) {
      setIsTakingSnapshot(true);
      if (miniBlobDataLogWalk && miniBlobDataLogWalk.geoType === "EQR") {
        initEQRPano(
          marzipanoRef.current,
          miniBlobDataLogWalk,
          lookToTakeSnapshot
        );
      } else if (
        miniBlobDataLogWalk &&
        miniBlobDataLogWalk.geoType === "Tile"
      ) {
        initTileAzBlogStorage(
          marzipanoRef.current,
          miniBlobDataLogWalk,
          lookToTakeSnapshot
        );
      } else {
        setIsTakingSnapshot(false);
      }
    }
  }, [base64, miniBlobDataLogWalk]);

  useEffect(() => {
    if (
      largeBase64 === "" &&
      miniBlobDataLogWalk &&
      miniBlobDataLogWalk.geoType !== "" &&
      !isTakingLargeSnapshot
    ) {
      setIsTakingLargeSnapshot(true);
      let tempPano = document.getElementById(
        "temp-logwalk-viewer-container-to-take-snapshot"
      );
      if (tempPano) {
        tempPano.remove();
      }

      tempPano = document.createElement("div");
      tempPano.style.width = "100%";
      tempPano.style.height = "100%";
      tempPano.style.zIndex = "-1";
      tempPano.style.position = "absolute";
      tempPano.style.id = "temp-logwalk-viewer-container-to-take-snapshot";
      document
        .getElementsByClassName("logwalk-viewer-container")[0]
        .append(tempPano);

      if (miniBlobDataLogWalk.geoType === "EQR") {
        initEQRPano(tempPano, miniBlobDataLogWalk, viewer => {
          const base64Img = viewer.stage().takeSnapshot({ quality: 100 });
          setLargeBase64(base64Img);
          viewer.destroy();
          tempPano.remove();
          setIsTakingLargeSnapshot(false);
        });
      } else if (miniBlobDataLogWalk.geoType === "Tile") {
        initTileAzBlogStorage(tempPano, miniBlobDataLogWalk, viewer => {
          const base64Img = viewer.stage().takeSnapshot({ quality: 100 });
          setLargeBase64(base64Img);
          viewer.destroy();
          tempPano.remove();
          setIsTakingLargeSnapshot(false);
        });
      } else {
        setIsTakingLargeSnapshot(false);
      }
    }
  }, [largeBase64, miniBlobDataLogWalk]);

  const initTileAzBlogStorage = (pano, blobDataLogWalk, lookToSuccess) => {
    const viewerOpts = {
      controls: {
        mouseViewMode: "drag"
      },
      stage: { preserveDrawingBuffer: true }
    };
    const blobExtention = new marzipano.AzBlobExtension(
      blobDataLogWalk.blobDataLst,
      pano
    );
    const viewer = new marzipano.Viewer(pano, viewerOpts, blobExtention);
    const urlPrefix = "";

    const source = marzipano.ImageUrlSource.fromString(
      urlPrefix + "/{z}/{f}/{y}/{x}.jpg",
      { cubeMapPreviewUrl: urlPrefix + "/preview.jpg" }
    );

    const LEVELS = [
      {
        tileSize: 256,
        size: 256,
        fallbackOnly: true
      },
      {
        tileSize: 512,
        size: 512
      },
      {
        tileSize: 512,
        size: 1024
      },
      {
        tileSize: 512,
        size: 2048
      }
    ];
    const geometry = new marzipano.CubeGeometry(LEVELS);

    const limiter = marzipano.util.compose(
      marzipano.RectilinearView.limit.vfov(Math.PI*10/180, Math.PI*120/180),
      marzipano.RectilinearView.limit.hfov(Math.PI*10/180, Math.PI*120/180),
      marzipano.RectilinearView.limit.pitch(pitch, pitch),
      marzipano.RectilinearView.limit.yaw(yaw, yaw)
    );
    const view = new marzipano.RectilinearView({ yaw, pitch }, limiter);

    const scene = viewer.createScene({
      source: source,
      geometry: geometry,
      view: view
    });

    scene.switchTo({ transitionDuration: 100 }, () => {});

    var firstRenderComplete = false;
    viewer.stage().addEventListener("renderComplete", function (complete) {
      if (complete && !firstRenderComplete) {
        firstRenderComplete = true;
        setTimeout(() => lookToSuccess(viewer), 200);
      }
    });
  };

  const initEQRPano = (pano, blobDataLogWalk, lookToSuccess) => {
    const viewerOpts = {
      controls: {
        mouseViewMode: "drag"
      },
      stage: { preserveDrawingBuffer: true }
    };
    const blobExtention = new marzipano.AzBlobExtension(
      blobDataLogWalk
        ? blobDataLogWalk.blobDataLst
        : [
            "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="
          ],
      pano
    );
    const viewer = new marzipano.Viewer(pano, viewerOpts, blobExtention);
    const eqrBlobSearchKey = "/eqr";
    const source = marzipano.ImageUrlSource.fromString(eqrBlobSearchKey);
    const geometry = new marzipano.EquirectGeometry([{ width: 4000 }]);

    const limiter = marzipano.util.compose(
      marzipano.RectilinearView.limit.vfov(Math.PI*10/180, Math.PI*120/180),
      marzipano.RectilinearView.limit.hfov(Math.PI*10/180, Math.PI*120/180),
      marzipano.RectilinearView.limit.pitch(pitch, pitch),
      marzipano.RectilinearView.limit.yaw(yaw, yaw)
    );
    const view = new marzipano.RectilinearView({ yaw, pitch }, limiter);

    const scene = viewer.createScene({
      source: source,
      geometry: geometry,
      view: view
    });

    scene.switchTo({ transitionDuration: 100 }, () => {});

    var firstRenderComplete = false;
    viewer.stage().addEventListener("renderComplete", function (complete) {
      if (complete && !firstRenderComplete) {
        firstRenderComplete = true;
        setTimeout(() => lookToSuccess(viewer), 200);
      }
    });
  };

  const lookToTakeSnapshot = viewer => {
    const base64Img = viewer.stage().takeSnapshot({ quality: 100 });
    setBase64(base64Img);
    setIsTakingSnapshot(false);
    viewer.destroy();
  };

  const openImageInNewTab = () => {
    if (largeBase64 !== "") {
      const image = new Image();
      image.src = largeBase64;

      const w = window.open("");
      w.document.write(image.outerHTML);
    }

  };


  return (
    <div
      className="hotspot-mini-block"
      style={{
        width: "100%",
        paddingTop: "75%",
        position: "relative",
        zIndex: 100
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
          zIndex: "-1"
        }}
      >
        <div
          ref={marzipanoRef}
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: "block"
          }}
          onClick={openImageInNewTab}
        >
          {base64 !== "" && <img src={base64} alt=""  style={{width: "100%"}}/>}
        </div>
      </div>
      <div className="image-hotspot">
        <IconInfo width="24px" height="24px" color={iconColor} />
      </div>
    </div>
  );
}

ReviewHotspotPosition.defaultProps = {
  shootImageTile: "",
  shootImageBase64: "",
  yaw: 0,
  pitch: 0,
  iconColor: "#06f"
};

export default ReviewHotspotPosition;
