import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";

import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { bindActionCreators } from "redux";

import { logoutUser } from "../auth/service/service";

import "../../common/css/base.css";
import "../../common/css/profile.css";

import ProfilePage from "./ProfilePage";
import PassWordPage from "./PassWordPage";
import LicencePage from "./LicencePage";
import { messageValidate } from "../../common/Stores/service/service";
import Pc from "../../common/breakpoints/Pc";
import LogoutPage from "./LogoutPage";

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      selectedIndex: 1
    };
  }

  componentDidMount() {
    const thisPath = this.props.history.location.pathname;
    this.checkUrl(thisPath);
    this.props.messageValidate();
  }

  componentWillReceiveProps(nextProps) {
    const nextPath = nextProps.history.location.pathname;
    this.checkUrl(nextPath);
  }

  checkUrl = path => {
    let newSelected = 1;
    if (this.props.location.pathname.includes("logbuild")) {
      if (path === "/logbuild/mypage/password") {
        newSelected = 1;
      } else if (path === "/logbuild/mypage/logout") {
        newSelected = 2;
      }
    } else {
      if (path === "/mypage/password") {
        newSelected = 2;
      } else if (path === "/licence") {
        newSelected = 3;
      } else if (path === "/mypage/logout") {
        newSelected = 4;
      } else {
        newSelected = 1;
      }
    }

    this.setState({ selectedIndex: newSelected });
  };

  logOut = () => {
    this.props.logoutUser(!this.props.location.pathname.includes("/logbuild/") && !this.props.location.pathname.includes("/sp/"));
  };

  toProfileManager = () => {
    if (this.props.location.pathname.includes("logbuild")) {
      this.props.history.push("/logbuild/mypage/profile");
    } else {
      this.props.history.push("/mypage/profile");
    }
  };

  toLicence = () => {
    this.props.history.push("/licence");
  };

  toLogOut = () => {
    if (this.props.location.pathname.includes("logbuild")) {
      this.props.history.push("/logbuild/mypage/logout");
    } else {
      this.props.history.push("/mypage/logout");
    }
  };

  toPasswordManager = () => {
    if (this.props.location.pathname.includes("logbuild")) {
      this.props.history.push("/logbuild/mypage/password");
    } else {
      this.props.history.push("/mypage/password");
    }
  };

  render() {
    const { selectedIndex } = this.state;
    const { match, location } = this.props;
    const logbuildOrPc = children =>
      this.props.location.pathname.includes("logbuild") ? (
        <>{children}</>
      ) : (
        <Pc>{children}</Pc>
      );

    return (
      <Grid container className="profile page-content">
        <Grid container wrap="nowrap" className="profile-side-bar">
          {logbuildOrPc(
            <Grid className="profile-list-wrap">
              <List disablePadding className="">
                {!location.pathname.includes("logbuild") && (
                  <ListItem
                    onClick={this.toProfileManager}
                    selected={selectedIndex === 1}
                    button
                    className="image-list-item"
                  >
                    <ListItemText
                      className="clip-text"
                      primary="プロフィール情報"
                    />
                  </ListItem>
                )}
                <ListItem
                  onClick={this.toPasswordManager}
                  selected={
                    selectedIndex ===
                    (location.pathname.includes("logbuild") ? 1 : 2)
                  }
                  button
                  className="image-list-item"
                >
                  <ListItemText
                    className="clip-text"
                    primary="パスワード変更"
                  />
                </ListItem>
                {!location.pathname.includes("logbuild") && (
                  <ListItem
                    onClick={this.toLicence}
                    selected={selectedIndex === 3}
                    button
                    className="image-list-item"
                  >
                    <ListItemText
                      className="clip-text"
                      primary="法律に基づく情報"
                    />
                  </ListItem>
                )}
                <ListItem
                  onClick={this.toLogOut}
                  selected={
                    selectedIndex ===
                    (location.pathname.includes("logbuild") ? 3 : 4)
                  }
                  button
                  className="image-list-item"
                >
                  <ListItemText className="clip-text" primary="ログアウト" />
                </ListItem>
              </List>
            </Grid>
          )}
          {selectedIndex === 1 && !location.pathname.includes("logbuild") && (
            <ProfilePage pathname={match.path} />
          )}
          {selectedIndex ===
            (location.pathname.includes("logbuild") ? 1 : 2) && (
              <PassWordPage pathname={match.path} />
            )}
          {!location.pathname.includes("logbuild") && selectedIndex === 3 && (
            <LicencePage />
          )}
          {selectedIndex ===
            (location.pathname.includes("logbuild") ? 2 : 4) && (
              <LogoutPage logoutUser={this.logOut} />
            )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logoutUser: logoutUser,
      messageValidate: messageValidate
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Profile));
