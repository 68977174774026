import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { format } from "date-fns";
import PropTypes from "prop-types";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PlayCircleOutlineOutlinedIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "../../common/css/base.css";
import "../../common/css/sites.css";
import CardImage from "../../common/CardImage";
import RobotStatus from "common/RobotStatus";
import { BUTTON_LOGKUN } from "common/constants/constants";
import LazyLoad from "react-lazyload";
const widthItem = 300;
class SiteCardList extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  render() {
    const { numberData, maxCols } = this.state;

    const { siteData, robotsUpdate } = this.props;

    const datas = siteData;

    const shouldEnableRobotButton = robots => {
      for (let i = 0; i < robots.length; i++) {
        if (robots[i].useStatus === 1) return true;
      }
      return false;
    };

    return (
      <Grid container>
        {datas.map(model => (
          <Grid item className="card-item">
            <Grid
              className="card-content"
              onClick={this.props.to(model.uniqueCode)}
            >
              <Grid class="card-header">
                <Typography
                  variant="h4"
                  className="text-bold text-center card-title text-over-wrap"
                >
                  {model.name}
                </Typography>

                {model.lastRecordedAt ? (
                  <Grid className="p-20">
                    <Typography
                      variant="body2"
                      className="text-bold"
                      style={{
                        textAlign: "right",
                        fontSize: 13.125
                      }}
                    >
                      {format(new Date(model.lastRecordedAt), "yyyy年M月d日")}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="text-bold text-10"
                      style={{
                        textAlign: "right"
                      }}
                    >
                      (LogWalk写真更新日)
                    </Typography>
                  </Grid>
                ) : (
                  <Grid className="p-20">
                    <Typography
                      variant="body2"
                      className="text-bold"
                      style={{
                        textAlign: "right",
                        fontSize: 13.125
                      }}
                    >
                      {format(new Date(model.regisionDate), "yyyy年M月d日")}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="text-bold text-10"
                      style={{
                        textAlign: "right"
                      }}
                    >
                      (現場登録日)
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid class="card-body">
                <Grid className="image-profile">
                  <LazyLoad
                    offset={-100}
                    placeholder={<div className="placeholder-card-image"></div>}
                  >
                    <CardImage url={model.image} blob={model.profilePic} />
                  </LazyLoad>
                </Grid>
                <Typography
                  variant="body2"
                  className="text-bold mt-5"
                  style={{ height: 18 }}
                >
                  {model.address}
                </Typography>
              </Grid>
              <Grid class="card-footer">
                <Grid xs={6}>
                  <Fab
                    className={
                      model.logWalkViewer
                        ? "m-5 white-text button-logWalk"
                        : "m-5 white-text button-logWalk-disable"
                    }
                    onClick={e => {
                      if (model.logWalkViewer) {
                        this.props.showDialogDrawing(model.uniqueCode);
                      }

                      e.stopPropagation();
                    }}
                    variant="extended"
                  >
                    <PlayCircleOutlineOutlinedIcon className="icon icon-margin" />
                    Log Walk起動
                  </Fab>
                </Grid>
                <Grid xs={6} className="p-5" onClick={e => e.stopPropagation()}>
                  <RobotStatus
                    typeOfRobotStatus={BUTTON_LOGKUN}
                    robots={model.robots}
                    robotsUpdate={[robotsUpdate]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }
}

SiteCardList.propTypes = {};

export default SiteCardList;
