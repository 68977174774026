import React from 'react';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { ErrorMessage } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
  },
  form: {
    marginBottom: 0,
    marginTop: theme.spacing(3),
  },
}));

function SelectControl(props) {
  const classes = useStyles();
  const {
    field: { name, value },
    form,
    title,
    required,
    disabled,
    className,
    ...others
  } = props;

  return (
    <FormControl
      margin="normal"
      fullWidth
      required={required}
      disabled={disabled}
      className={`${classes.form} ${className}`}
    >
      {title && <InputLabel shrink={true} htmlFor={name}>{title}</InputLabel>}
      <Select
        value={value}
        input={<Input name={name} id={name} autoFocus={props.autoFocus} />}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        {...others}
      >
        {props.children}
      </Select>
      <ErrorMessage name={name}>
        {msg => <div className={classes.error}>{msg}</div>}
      </ErrorMessage>
    </FormControl>
  );
}

SelectControl.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  field: PropTypes.object,
  form: PropTypes.object,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  autoFocus: PropTypes.bool,
};

SelectControl.defaultProps = {
  required: false,
  autoFocus: false,
};

export default SelectControl;
