import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import { bindActionCreators } from "redux";
import { Formik, Field } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import DrawingPhoto from "../DrawingPhoto";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { getDrawingsWithSpots } from "../reducer/reducer";
import "../../../../common/css/copy-drawing-dialog.css";
import { MAXIMUM_DRAWING } from "../constant/constant";
import CopyDrawingStep from "./CopyDrawingStep";
import { copyDrawingsWithSpot } from "../service/service";
import Pc from "common/breakpoints/Pc";
import Sp from "common/breakpoints/Sp";
import { getTextLength } from "utils/mapper";
import { MODE_DEVICE } from "../LogWalkViewer/constants";
const MAX_LENGTH_OF_NAME = 16;

class CopyDrawingDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      positions: null,
      showSpots: true,
      isVerticalImg: false,
      pageValue: "1",
      isSetNameMode: false,
      isDisabledSubmit: true,
      drawingsName: [],
      isShowError: false,
      modeDevice: null
    };
  }

  componentWillMount() {
    this.setState({
      modeDevice: window.innerWidth < 960 ? MODE_DEVICE.SP : MODE_DEVICE.PC
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = e => {
    this.setState({
      modeDevice: window.innerWidth <= 960 ? MODE_DEVICE.SP : MODE_DEVICE.PC
    });
    this.setPositionMarker();
  };

  loadMarker = e => {
    this.setPositionMarker();
  };

  setPositionMarker() {
    let { drawingsWithSpots, currentDrawing } = this.props;

    if (drawingsWithSpots?.length > 0) {
      let positionAfter = [];
      let posAfter = [];

      // Drawing preview
      let elementPrimary = document.getElementById("primary-drawing-copy");
      if (elementPrimary) {
        let naturalWidthPrimary = elementPrimary
          ? elementPrimary.naturalWidth
          : 0;

        let naturalHeightPrimary = elementPrimary
          ? elementPrimary.naturalHeight
          : 0;

        const elMap = document.getElementsByClassName("drawing-block")[0];
        let scaleMap = elMap ? elMap.offsetWidth / elMap.offsetHeight : 0;
        let isVerticalImg =
          naturalHeightPrimary * scaleMap > naturalWidthPrimary;

        let offsetWidthPrimary = 0;
        let offsetHeightPrimary = 0;
        if (isVerticalImg) {
          offsetWidthPrimary =
            (elMap.offsetHeight * naturalWidthPrimary) / naturalHeightPrimary;

          offsetHeightPrimary = elMap.offsetHeight;
        } else {
          offsetWidthPrimary = elMap.offsetWidth;

          offsetHeightPrimary =
            (elMap.offsetWidth * naturalHeightPrimary) / naturalWidthPrimary;
        }

        let offsetLeftPrimary = (elMap.offsetWidth - offsetWidthPrimary) / 2;

        let offsetTopPrimary = (elMap.offsetHeight - offsetHeightPrimary) / 2;

        let posAfterPrimary = [];
        drawingsWithSpots[currentDrawing].spots.map(pos => {
          posAfterPrimary.push({
            uniqueCode: pos.uniqueCode,
            name: pos.name,
            posX:
              (pos.x * offsetWidthPrimary) / naturalWidthPrimary +
              offsetLeftPrimary,
            posY:
              (pos.y * offsetHeightPrimary) / naturalHeightPrimary +
              offsetTopPrimary,
            hasShoot: pos.hasShoot
          });
        });

        this.setState({
          isVerticalImg: isVerticalImg,
          positions: posAfterPrimary
        });
      }
    }
  }

  showHideSpot = () => {
    if (this.props.drawingsWithSpots[this.props.currentDrawing].spots.length) {
      this.setState({
        showSpots: !this.state.showSpots
      });
    }
  };

  setPageValue = value => {
    this.setState({ pageValue: value });
  };

  setPageValueOnInput = e => {
    let value = e.target.value;

    let listPage = [
      ...Array(MAXIMUM_DRAWING - this.props.drawingsWithSpots.length).keys()
    ].map(e => {
      return (e + 1).toString();
    });

    if (listPage.includes(value)) {
      this.setPageValue(value);
    }
  };

  setNameMode = () => {
    this.setState(
      { isSetNameMode: !this.state.isSetNameMode },
      this.setPositionMarker
    );
  };

  disableSubmit = isDisabledSubmit => {
    this.setState({ isDisabledSubmit });
  };

  setDrawingName = drawingsName => {
    this.setState({ drawingsName });
  };

  onSubmit = () => {
    const { drawingsWithSpots, currentDrawing, match } = this.props;
    const { showSpots, drawingsName } = this.state;
    const drawingsUC = drawingsWithSpots[currentDrawing].uniqueCode;

    if (
      drawingsName.some(
        drawingName =>
          !drawingName || getTextLength(drawingName) > MAX_LENGTH_OF_NAME
      )
    ) {
      this.setState({ isShowError: true });
      return;
    }

    const spotsUCs = [];
    if (showSpots) {
      drawingsWithSpots[currentDrawing].spots.map(item => {
        spotsUCs.push(item.uniqueCode);
      });
    }

    this.props.copyDrawingsWithSpot(
      match.params.id,
      drawingsUC,
      spotsUCs,
      drawingsName
    );
    this.props.onCloseAll();
  };

  hideErrors = () => {
    this.setState({
      isShowError: false
    });
  };

  render() {
    const {
      positions,
      showSpots,
      isVerticalImg,
      pageValue,
      isSetNameMode,
      isDisabledSubmit,
      isShowError,
      modeDevice
    } = this.state;
    const { drawingsWithSpots, currentDrawing } = this.props;
    return (
      <>
        <Dialog
          open={this.props.open}
          onClose={() => {}}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="md"
          PaperProps={{
            className: "dialog dialog-sign-up dialog-copy-drawing"
          }}
        >
          <Fab
            onClick={this.props.onClose}
            size="small"
            className="white-text close-button"
          >
            <CloseIcon />
          </Fab>
          <DialogContent className="copy-drawing-content">
            {modeDevice === MODE_DEVICE.PC && (
              <Grid container direction="row" justify="space-between">
                {isSetNameMode ? (
                  <Grid></Grid>
                ) : (
                  <Grid
                    container
                    alignItems="flex-start"
                    className="checkbox-copy-drawing"
                  >
                    <Checkbox
                      className=""
                      color="primary"
                      checked={
                        drawingsWithSpots[currentDrawing].spots.length
                          ? showSpots
                          : false
                      }
                      disabled={!drawingsWithSpots[currentDrawing].spots.length}
                      onClick={this.showHideSpot}
                      inputProps={{ "aria-label": "Primary checkbox" }}
                    />
                    <Grid
                      className="display-inline-block checkbox-label"
                      onClick={this.showHideSpot}
                    >
                      <Typography>図面が持つ地点を複製する</Typography>
                      <Typography>※写真は複製されません</Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  direction="column"
                  className="copy-drawing-btn-block"
                >
                  {isSetNameMode ? (
                    <Grid>
                      <Button
                        onClick={this.setNameMode}
                        color="primary"
                        className="dialog-button m-5"
                      >
                        戻る
                      </Button>
                      <Button
                        color="primary"
                        className="dialog-button m-5"
                        variant="contained"
                        disabled={isDisabledSubmit}
                        onClick={this.onSubmit}
                      >
                        決定
                      </Button>
                    </Grid>
                  ) : (
                    <Grid>
                      <Button
                        onClick={this.props.onClose}
                        color="primary"
                        className="dialog-button m-5"
                      >
                        キャンセル
                      </Button>
                      <Button
                        color="primary"
                        className="dialog-button m-5"
                        variant="contained"
                        onClick={this.setNameMode}
                      >
                        次へ
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {modeDevice === MODE_DEVICE.SP && (
              <Grid container direction="row" justify="space-between">
                <Grid
                  container
                  direction="column"
                  className="copy-drawing-btn-block"
                >
                  {isSetNameMode ? (
                    <Grid>
                      <Button
                        onClick={this.setNameMode}
                        color="primary"
                        className="dialog-button m-5"
                      >
                        戻る
                      </Button>
                      <Button
                        color="primary"
                        className="dialog-button m-5"
                        variant="contained"
                        disabled={isDisabledSubmit}
                        onClick={this.onSubmit}
                      >
                        決定
                      </Button>
                    </Grid>
                  ) : (
                    <Grid>
                      <Grid>
                        <Button
                          onClick={this.props.onClose}
                          color="primary"
                          className="dialog-button m-5"
                        >
                          キャンセル
                        </Button>
                        <Button
                          color="primary"
                          className="dialog-button m-5"
                          variant="contained"
                          onClick={this.setNameMode}
                        >
                          次へ
                        </Button>
                      </Grid>
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        className="option-area"
                      >
                        <Grid
                          container
                          alignItems="center"
                          className="checkbox-copy-drawing"
                        >
                          <Checkbox
                            className=""
                            color="primary"
                            checked={
                              drawingsWithSpots[currentDrawing].spots.length
                                ? showSpots
                                : false
                            }
                            disabled={
                              !drawingsWithSpots[currentDrawing].spots.length
                            }
                            onClick={this.showHideSpot}
                            inputProps={{ "aria-label": "Primary checkbox" }}
                          />
                          <Grid
                            className="display-inline-block checkbox-label"
                            onClick={this.showHideSpot}
                          >
                            <Typography>図面が持つ地点を複製する</Typography>
                            <Typography>※写真は複製されません</Typography>
                          </Grid>
                        </Grid>
                        <Autocomplete
                          className="page-value"
                          value={pageValue}
                          onChange={(event, newValue) => {
                            this.setPageValue(newValue);
                          }}
                          noOptionsText={"無効"}
                          options={[
                            ...Array(
                              MAXIMUM_DRAWING - drawingsWithSpots.length
                            ).keys()
                          ].map(e => {
                            return (e + 1).toString();
                          })}
                          disableClearable
                          autoHighlight
                          renderInput={params => (
                            <TextField
                              className={`input-reset`}
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              variant="filled"
                              label="複製枚数"
                              margin="normal"
                              onChange={this.setPageValueOnInput}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid container spacing={3} justify="center" className="my-0">
              <Grid
                item
                xs={12}
                sm={isSetNameMode ? 7 : 12}
                md={isSetNameMode ? 8 : 11}
              >
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{ height: "100%" }}
                >
                  <Grid
                    className={`drawing-block drawing-border ${
                      isVerticalImg ? "vertical-primary-block" : ""
                    } ${isSetNameMode ? "set-name-mode" : ""}`}
                  >
                    <DrawingPhoto
                      id={`primary-drawing-copy`}
                      drawingsUC={drawingsWithSpots[currentDrawing].uniqueCode}
                      blob={drawingsWithSpots[currentDrawing].blob}
                      alt={drawingsWithSpots[currentDrawing].name}
                      loadMarker={this.loadMarker}
                      positions={showSpots ? positions : null}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={isSetNameMode ? 5 : "auto"}
                md={isSetNameMode ? 4 : 1}
                className={`${isSetNameMode ? "right-block" : ""} p-0`}
              >
                {isSetNameMode ? (
                  <Grid style={{ height: "inherit" }}>
                    <Typography variant="body2" className="m-15 text-bold">
                      図面名を登録する（{pageValue}枚）
                    </Typography>
                    <CopyDrawingStep
                      maxPage={pageValue}
                      disableSubmit={this.disableSubmit}
                      setDrawingName={this.setDrawingName}
                      isShowError={isShowError}
                      hideErrors={this.hideErrors}
                    ></CopyDrawingStep>
                  </Grid>
                ) : (
                  modeDevice === MODE_DEVICE.PC && (
                    <Autocomplete
                      className="page-value"
                      value={pageValue}
                      onChange={(event, newValue) => {
                        this.setPageValue(newValue);
                      }}
                      style={{ width: "100%" }}
                      options={[
                        ...Array(
                          MAXIMUM_DRAWING - drawingsWithSpots.length
                        ).keys()
                      ].map(e => {
                        return (e + 1).toString();
                      })}
                      disableClearable
                      autoHighlight
                      noOptionsText={"無効"}
                      renderInput={params => (
                        <TextField
                          className={`input-reset`}
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          variant="filled"
                          label="複製枚数"
                          margin="normal"
                          onChange={this.setPageValueOnInput}
                        />
                      )}
                    />
                  )
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

CopyDrawingDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

const mapStateToProps = state => ({
  // type: state.rootSitesReducer.siteInfoReducer.type,
  // submitErrors: state.storesReducer.error,
  // regexObject: state.storesReducer.regexObject,
  // message: state.storesReducer.message,
  // error: state.storesReducer.error,
  // loading: state.storesReducer.pending,
  drawingsWithSpots: getDrawingsWithSpots(
    state.rootSitesReducer.siteInfoReducer.drawingsWithSpots
  )
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // dispatchError,
      // postDrawing,
      // postPhoto,
      // finishLoading,
      // translateValidate: translateValidate
      copyDrawingsWithSpot
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CopyDrawingDialog));
