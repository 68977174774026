import { fetchBuilderListSuccess, postBuilderSuccess } from "../action/action";
import {
  finishLoading,
  dispatchError,
  enableLoading
} from "common/Stores/service/service";
import { api } from "common/api/api";

export const fetchBuilderList = () => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/builders`)
      .then(res => {
        dispatch(fetchBuilderListSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const postBuilder = (values, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/logbuild/builders`, values)
      .then(res => {
        dispatch(postBuilderSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        if(handleError && err.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(err));
        }
      });
  };
};
