import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "material-ui-flat-pagination";
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';

import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import RobotDialog from "./RobotDialog";
import { POST_ROBOT_SUCCESS } from "./constant/constant";
import { convertSerialNumber } from "../../utils/mapper";

import { fetchRobotLogBuild } from "./service/service";

import { getRobotLogBuild, getUniCode } from "./reducer/robot";
import PaginationControl from "../../common/Pagination";

import "../../common/css/base.css";
import "../../common/css/log.css";

class RobotLogBuild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogShow: false,
      logbuildData: []
    };
  }

  componentDidMount() {
    this.props.fetchRobotLogBuild();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.type !== nextProps.type &&
      nextProps.type === POST_ROBOT_SUCCESS
    ) {
      if (nextProps.uniqueCode !== undefined) {
        this.props.history.push(`/logbuild/robots/${nextProps.uniqueCode}`);
      }
    }
  }

  toogleDialog = () => {
    this.setState({ isDialogShow: !this.state.isDialogShow });
  };

  onChangeData = data => {
    this.setState({
      logbuildData: data
    });
  };

  render() {
    const { logBuild } = this.props;
    const { isDialogShow, logbuildData } = this.state;

    return (
      <Grid container wrap="nowrap" className="carpenter">
        <Grid container>
          <Grid container justify="space-between" className="padding-content">
            <Button
              onClick={this.toogleDialog}
              size="small"
              variant="text"
              className="button-add"
            >
              <AddIcon className="icon-margin" />
              ロボット登録
            </Button>
          </Grid>
          <TableContainer>
            <Table component="div" aria-label="table">
              <TableHead component="div" className="table-head">
                <TableRow component="div">
                  <TableCell
                    component="span"
                    className="white-text padding-first-th"
                  >
                    製造番号
                    <ArrowUpwardSharpIcon className="icon-small-size s-va-m margin-left arrow-up" />
                  </TableCell>
                  <TableCell
                    component="span"
                    className="white-text padding-first-th"
                  >
                    貸出工務店名
                  </TableCell>
                  <TableCell
                    component="span"
                    className="white-text padding-first-th"
                  >
                    貸出日
                  </TableCell>
                  <TableCell
                    component="span"
                    className="white-text padding-first-th"
                  >
                    ステータス
                  </TableCell>
                  <TableCell
                    component="span"
                    className="white-text padding-first-th"
                  >
                    利用停止日
                  </TableCell>
                  <TableCell
                    component="span"
                    className="white-text padding-first-th"
                  >
                    回収日
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody component="div" className="table-body">
                {logbuildData.map(item => (
                  <TableRow
                    component={Link}
                    to={`/logbuild/robots/${item.uniqueCode}`}
                    key={item.uniqueCode}
                    className="cursor-pointer reset-link"
                  >
                    <TableCell component="span" className="padding-first-td">
                      {item.robotNo}
                    </TableCell>
                    <TableCell component="span" className="padding-first-td">
                      {item.builderName}
                    </TableCell>
                    <TableCell component="span" className="padding-first-td">
                      {item.leaseStartDate &&
                        format(new Date(item.leaseStartDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell component="span" className="padding-first-td">
                      {item.leaseStatusName}
                    </TableCell>
                    <TableCell component="span" className="padding-first-td">
                      {item.leaseEndDate &&
                        format(new Date(item.leaseEndDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell component="span" className="padding-first-td">
                      {item.withdrawalDate &&
                        format(new Date(item.withdrawalDate), "yyyy年M月d日")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justify="space-between" className="padding-content">
            <Button
              onClick={this.toogleDialog}
              size="small"
              variant="text"
              className="button-add"
            >
              <AddIcon className="icon-margin" />
              ロボット登録
            </Button>
          </Grid>
          <Grid container justify="center">
            <PaginationControl
              limit={20}
              datas={logBuild}
              changeData={this.onChangeData}
            />
          </Grid>
        </Grid>

        {isDialogShow && (
          <RobotDialog
            isDialogShow={isDialogShow}
            onClose={this.toogleDialog}
            isEdit={false}
          />
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  logBuild: getRobotLogBuild(
    state.rootRobotsReducer.robotsReducer.robotLogBuild
  ),
  uniqueCode: getUniCode(state.rootRobotsReducer.robotsReducer.uniqueCode),
  type: state.rootRobotsReducer.robotsReducer.type
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchRobotLogBuild }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RobotLogBuild));
