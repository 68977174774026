import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  actions: {
    position: "absolute",
    bottom: "30px",
    left: "50%",
    marginLeft: "-150px",
    width: "300px",
    height: "45px",
    display: "flex",
    transition: "bottom 0.25s ease 0s",
    justifyContent: "space-between",
    "& button": {
      width: "140px",
      height: "45px",
      borderRadius: "5px",
      fontSize: "17px",
      fontWeight: "bold",
      textAlign: "center"
    }
  }
});

function PointSelectHotspotInfo(props) {
  const classes = useStyles();
  const {
    chooseNewHotspotInfoPosition,
    cancelCreateHotspotInfo,
    positionNewHotspotInfo
  } = props;

  const isShowDirectionDrawer = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.isShowDirectionDrawer
  );

  const handleCreate = () => {
    if (positionNewHotspotInfo) {
      chooseNewHotspotInfoPosition();
    }
  };

  const handleCancel = () => {
    cancelCreateHotspotInfo();
  };

  return (
    <React.Fragment>
      <div className={classes.actions} style={{ bottom: isShowDirectionDrawer ? "60px" : "" }}>
        <button
          style={{
            backgroundColor: positionNewHotspotInfo ? "#00346C" : "#E0E0E0",
            color: positionNewHotspotInfo ? "white" : "#A8A8A8"
          }}
          onClick={handleCreate}
        >
          決定
        </button>
        <button
          style={{
            backgroundColor: "white",
            color: "black"
          }}
          onClick={handleCancel}
        >
          キャンセル
        </button>
      </div>
    </React.Fragment>
  );
}

export default PointSelectHotspotInfo;
