import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 36}
      height={props.size || 36}
      viewBox="0 0 28 28"
    >
      <g transform="translate(5048 -2827)">
        <path
          fill="none"
          d="M0 0H28V28H0z"
          data-name="Rectangle 693"
          transform="translate(-5048 2827)"
        ></path>
        <g
          fill="#333333"
          data-name="Group 1862"
          transform="translate(-81.658 -17.9)"
        >
          <path
            d="M-4885.871 2871.283v-7.571l8.569-8.57 8.57 8.57v7.571H-4873v-5.831h-3.2v5.831z"
            data-name="Path 909"
            transform="translate(-75.188 -1.649)"
          ></path>
          <path
            d="M-4867.21 2862.205a1.658 1.658 0 01-1.168-.48l-9.939-9.83-9.941 9.83a1.661 1.661 0 01-2.349-.013 1.661 1.661 0 01.013-2.348l11.109-10.984a1.66 1.66 0 012.335 0l11.106 10.984a1.66 1.66 0 01.014 2.348 1.656 1.656 0 01-1.18.493z"
            data-name="Path 910"
            transform="translate(-74)"
          ></path>
        </g>
      </g>
    </svg>
  );
}


export default Icon;
