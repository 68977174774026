import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "material-ui-flat-pagination";
import Popover from "@material-ui/core/Popover";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import StaffLogBuildDialog from "./StaffLogBuildDialog";
import { apiError } from "../../../common/Stores/action/action";
import { regexSignUp } from "../../auth/service/service";
import ChangePasswordLogBuildDialog from "./ChangePasswordLogBuildDialog";
import { fetchLogBuildStaff } from "./service/service";
import {
  translateValidate,
  messageValidate
} from "../../../common/Stores/service/service";
import PaginationControl from "../../../common/Pagination";

class StaffLogBuild extends Component {
  state = {
    isDialogShow: false,
    isDialogDeleteShow: false,
    isEdit: false,
    anchorEl: null,
    unicodeEdit: "",
    isChangePasswordDialog: false,
    staffLogBuildData: [],
    initialValues: {
      password: "",
      password_confirmation: ""
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.type &&
      (nextProps.type === "POST_LOGBUILD_STAFF_SUCCESS" ||
        nextProps.type === "PUT_LOGBUILD_STAFF_SUCCESS" ||
        nextProps.type === "DELETE_LOGBUILD_STAFF_SUCCESS" ||
        nextProps.type === "PUT_PASSWORD_LOGBUILD_SUCCESS")
    ) {
      this.setState({
        isDialogShow: false,
        isChangePasswordDialog: false
      });
    }
  }

  componentDidMount() {
    this.props.regexSignUp();
    this.props.translateValidate();
    this.props.fetchLogBuildStaff();
    this.props.messageValidate();
  }

  showDialog = () => {
    this.setState({
      isDialogShow: true,
      unicodeEdit: "",
      isEdit: false
    });
  };

  showDialogEdit = () => {
    this.setState({
      isDialogShow: true,
      isEdit: true,
      anchorEl: null
    });
  };

  closeDialog = () => {
    this.setState({
      isDialogShow: false,
      isEdit: false
    });
  };

  showPopover = uniqueCode => e => {
    this.props.apiError(null);
    this.setState({
      anchorEl: e.currentTarget,
      unicodeEdit: uniqueCode
    });
  };

  closePopover = () => {
    this.setState({
      anchorEl: null
    });
  };

  togglePasswordDialog = () => {
    this.setState({
      isChangePasswordDialog: !this.state.isChangePasswordDialog,
      anchorEl: null
    });
  };

  onChangeData = data => {
    this.setState({
      staffLogBuildData: data
    });
  };

  toggleDialogDelete = () => {
    this.setState({
      isDialogDeleteShow: !this.state.isDialogDeleteShow,
      isDialogShow: false
    });
  };

  render() {
    const {
      isDialogShow,
      isEdit,
      anchorEl,
      staffLogBuildData,
      isChangePasswordDialog,
      isDialogDeleteShow
    } = this.state;
    const { regex, staffLogBuild } = this.props;

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <Grid container wrap="nowrap" className="sites-staft">
        <Grid container className="sites-staft">
          <Grid container justify="space-between" className="padding-content">
            <Button
              onClick={this.showDialog}
              size="small"
              variant="text"
              className="button-add"
            >
              <AddIcon className="icon-margin" />
              社員登録​
            </Button>
          </Grid>
          <TableContainer>
            <Table size="small" aria-label="table" className="sites-table">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell className="white-text padding-first-th">
                    社員名
                    <ArrowDownwardIcon className="icon-small-size s-va-m margin-left" />
                  </TableCell>
                  <TableCell className="white-text"> メールアドレス</TableCell>
                  <TableCell className="white-text">個人電話番号</TableCell>
                  <TableCell className="white-text"> 退社日</TableCell>
                  <TableCell className="white-text">権限</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {staffLogBuildData.map((row, index) => (
                  <TableRow key={row.uniqueCode}>
                    <TableCell className="padding-first-td">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>
                      {row.retirementDate &&
                        format(new Date(row.retirementDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell>{row.authName}</TableCell>
                    <TableCell className="padding-last-td s-ta-r">
                      <Fab
                        onClick={this.showPopover(row.uniqueCode)}
                        size="small"
                        className="more-vert-btn"
                      >
                        <MoreVertIcon />
                      </Fab>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justify="space-between" className="padding-content">
            <Button
              onClick={this.showDialog}
              size="small"
              variant="text"
              className="button-add"
            >
              <AddIcon className="icon-margin" />
              社員登録​
            </Button>
          </Grid>
          <Grid container justify="center">
            <PaginationControl
              limit={20}
              datas={staffLogBuild}
              changeData={this.onChangeData}
            />
          </Grid>
        </Grid>
        {(isDialogShow || isDialogDeleteShow) && (
          <StaffLogBuildDialog
            isDialogShow={isDialogShow}
            isDialogDeleteShow={isDialogDeleteShow}
            toggleDialogDelete={this.toggleDialogDelete}
            isEdit={isEdit}
            onCloseDialog={this.closeDialog}
            staffId={this.state.unicodeEdit}
          />
        )}

        {isChangePasswordDialog && (
          <ChangePasswordLogBuildDialog
            isDialogShow={isChangePasswordDialog}
            onClose={this.togglePasswordDialog}
            staffId={this.state.unicodeEdit}
            initialValues={this.state.initialValues}
            regex={regex}
          />
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.closePopover}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuList className="popover-sites">
            <MenuItem onClick={this.showDialogEdit}>編集</MenuItem>
            <MenuItem onClick={this.togglePasswordDialog}>
              パスワード変更
            </MenuItem>
          </MenuList>
        </Popover>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  regex: state.auth.regex,
  staffLogBuild: state.staffLogBuildReducer.staffLogBuild,
  type: state.staffLogBuildReducer.type
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      apiError: apiError,
      regexSignUp: regexSignUp,
      fetchLogBuildStaff: fetchLogBuildStaff,
      translateValidate: translateValidate,
      messageValidate: messageValidate
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(StaffLogBuild);