import React from "react";
import TableCell from "@material-ui/core/TableCell";

function CustomTableCell(props) {
  const { isTabletOrMobile, isTiniMobile, cellHeader, children } = props;
  return (
    <TableCell
      component="span"
      align="center"
      size={isTabletOrMobile ? "small" : "medium"}
      style={
        isTiniMobile
          ? { paddingRight: "6px", paddingLeft: "6px", fontSize: "12px" }
          : { paddingTop: "12px", paddingBottom: "12px", fontSize: "17px" }
      }
      className={cellHeader ? "white-text" : ""}
    >
      {children}
    </TableCell>
  );
}

export default CustomTableCell;
