import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUserSP, clearErrors } from "./service/service";
import classnames from "classnames";
import InputControl from "../../common/InputControl";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Formik, Field } from "formik";

import Validator from "validator";
import Checkboxes from "../loginCheckbox.js";

import "../../common/css/base.css";
import "../../common/css/auth.css";
import { translateMessage } from "utils/mapper";
import {
  translateValidate,
  messageValidate
} from "../../common/Stores/service/service";

class LoginSP extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      onSubmit: "",
      nextButtonClick: false,
      isPasswordShow: false
    };
    this.loginUtilityRef = React.createRef();
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/sp/dashboard");
      return true;
    }
    this.props.translateValidate();
    this.props.messageValidate();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/sp/dashboard"); // push user to dashboard when they login
    }

    //displaying errors
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  onChange = e => {
    e.preventDefault();
    this.setState({ [e.target.id]: e.target.value });
  };

  isAutoLoginChecked() {
    const loginUtility = this.loginUtilityRef.current;
    return loginUtility.state.checked;
  }

  onSubmit = values => {
    const userData = {
      id: values.email,
      password: values.password
    };

    const autoLogin = this.isAutoLoginChecked();
    this.props.loginUserSP(userData, autoLogin);
  };

  isEmptyEmail(email) {
    return Validator.isEmpty(email);
  }

  isEmailFormat(email) {
    return Validator.isEmail(email);
  }

  validateForm(email, password) {
    const key = "email";
    const { message } = this.props;
    const key_password = "password";
    const errors = this.state.errors;
    if (this.isEmptyEmail(email))
      errors[key] = translateMessage(
        this.getMessage("required", message),
        this.props.regexObject
      );
    else if (!this.isEmailFormat(email))
      errors[key] = translateMessage(
        this.getMessage("email", message),
        this.props.regexObject
      );
    else delete errors[key];
    if (this.isEmptyEmail(password))
      errors[key_password] = translateMessage(
        this.getMessage("required", message),
        this.props.regexObject
      );
    this.setState({ errors: errors });
    return Object.keys(errors).length === 0;
  }

  getMessage = (key, message) => {
    return message[key];
  };

  checktButtonClick = formik => {
    const { values } = formik;
    if (!this.validateForm(values.email, values.password)) return;
    formik.handleSubmit();
  };

  buttonSubmitClick = formik => e => {
    e.preventDefault();
    this.setState({ errors: {} }, () => this.checktButtonClick(formik));
  };

  render() {
    const { errors, email, password, isPasswordShow } = this.state;
    const values = {
      email: email,
      password: password
    };

    const showPassword = () => {
      this.setState({
        isPasswordShow: !this.state.isPasswordShow
      });
    };

    return (
      <Formik
        initialValues={values}
        enableReinitialize
        onSubmit={values => this.onSubmit(values)}
        render={formik => (
          <form onSubmit={this.buttonSubmitClick(formik)}>
            <Grid className="area-login-wrap">
              <Grid container justify="center">
                <Grid className="area-logo-sp" />
                <Grid className="area-login-sp">
                  <Grid>
                    <Typography
                      align="center"
                      variant="h4"
                      className="title-name mb-10"
                    >
                      職人でログイン
                    </Typography>
                  </Grid>
                  <Grid>
                    <Grid>
                      <Field
                        label="メールアドレス"
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        variant="filled"
                        className="input-login input-login-sp"
                        component={InputControl}
                      />
                      <span className="red-text">
                        {errors.email}
                        {errors.emailnotfound}
                      </span>
                    </Grid>

                    <Grid className="relative">
                      <Field
                        label="パスワード"
                        name="password"
                        type={isPasswordShow ? "text" : "password"}
                        onChange={formik.handleChange}
                        variant="filled"
                        className={classnames(
                          "input-login input-login-sp pdr-50",
                          {
                            invalid: errors.password || errors.passwordincorrect
                          }
                        )}
                        component={InputControl}
                        autoComplete="new-password"
                      />
                      <IconButton
                        color="primary"
                        aria-label="copy"
                        className="show-password"
                        onClick={showPassword}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <span className="red-text">
                        {errors.password}
                        {errors.passwordincorrect}
                      </span>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      className="mt-25"
                    >
                      <Checkboxes ref={this.loginUtilityRef} />
                    </Grid>
                    <Grid container justify="center">
                      <button
                        type="submit"
                        className="btn-login"
                        onClick={this.buttonSubmitClick(formik)}
                        style={{marginBottom: "25px"}}
                      >
                        ログイン
                      </button>
                      <span className="red-text">{errors.error}</span>
                    </Grid>
                    <Grid container justify="center" className="mt-10">
                      <Link to="/login" className="txt-link-login">
                        建設会社の方はこちら
                      </Link>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      className="mt-10"
                    >
                      <Link
                        to="/sp/forgot-password"
                        className="txt-forget-passowrd"
                      >
                        パスワードを忘れましたか？
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}

LoginSP.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.string.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});
export default connect(mapStateToProps, {
  loginUserSP,
  clearErrors,
  translateValidate,
  messageValidate
})(LoginSP);
