import axios from "axios";
import store from "../config/store/store";
import { axiosError } from "../Stores/action/action";
import { logoutUser } from "../../components/auth/service/service";

export const api = axios.create({
  baseURL: "/api/"
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if(!axios.isCancel(error)) {
      if (
        !error.response.config.url.endsWith("/thumbnail") &&
        !error.response.config.url.endsWith("/unlock") &&
        !error.response.config.url.endsWith("/image?bin") &&
        !error.response.config.url.endsWith("/thumbnail?bin") &&
        !(
          error.response.config.url.startsWith("/builder/drawings/") &&
          error.response.config.url.endsWith("/file?bin")
        ) &&
        error.response.config.url.search(/\/site-photos\/.+\/info/) === -1 &&
        !error.response.config.url.includes("/logwalk-viewer/shoots")
      ) {
        switch (error.response.status) {
          case 404:
            if (
              (error.response.config.url.endsWith("/exists") &&
                error.response.config.method === "post") ||
              (error.response.config.url.endsWith("/infos") &&
                error.response.config.method === "post")
            ) {
              break;
            }
            store.dispatch(axiosError(error.response));
            break;
          case 401:
            store.dispatch(axiosError(error.response));
            store.dispatch(logoutUser());
            break;
          default:
            break;
        }
      }
    }
    return Promise.reject(error);
  }
);
