import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <g transform="translate(5672 -2872)">
        <g
          fill="#fff"
          data-name="Group 1867"
          transform="translate(-657.06 1.007)"
        >
          <g data-name="Group 1868" transform="translate(-5013.915 2885.111)">
            <g data-name="Group 1863">
              <path
                d="M-4885.87 2863.4v-3.873l4.384-4.384 4.384 4.384v3.873h-2.187v-2.982h-1.639v2.982z"
                data-name="Path 909"
                transform="translate(4887.931 -2852.281)"
              ></path>
              <path
                d="M-4878.872 2855.218a.843.843 0 01-.6-.245l-5.085-5.029-5.086 5.029a.849.849 0 01-1.2-.007.849.849 0 01.006-1.2l5.684-5.619a.85.85 0 011.194 0l5.682 5.619a.849.849 0 01.006 1.2.845.845 0 01-.601.252z"
                data-name="Path 910"
                transform="translate(4891.087 -2847.9)"
              ></path>
            </g>
            <g data-name="Group 1864" transform="translate(13.033)">
              <path
                d="M-4885.87 2863.4v-3.873l4.384-4.384 4.384 4.384v3.873h-2.187v-2.982h-1.639v2.982z"
                data-name="Path 909"
                transform="translate(4887.931 -2852.281)"
              ></path>
              <path
                d="M-4878.872 2855.218a.843.843 0 01-.6-.245l-5.085-5.029-5.086 5.029a.849.849 0 01-1.2-.007.849.849 0 01.006-1.2l5.684-5.619a.85.85 0 011.194 0l5.682 5.619a.849.849 0 01.006 1.2.845.845 0 01-.601.252z"
                data-name="Path 910"
                transform="translate(4891.087 -2847.9)"
              ></path>
            </g>
          </g>
          <g data-name="Group 1865" transform="translate(-5007.737 2873.993)">
            <path
              d="M-4885.87 2863.4v-3.873l4.384-4.384 4.384 4.384v3.873h-2.187v-2.982h-1.639v2.982z"
              data-name="Path 909"
              transform="translate(4887.931 -2852.281)"
            ></path>
            <path
              d="M-4878.872 2855.218a.843.843 0 01-.6-.245l-5.085-5.029-5.086 5.029a.849.849 0 01-1.2-.007.849.849 0 01.006-1.2l5.684-5.619a.85.85 0 011.194 0l5.682 5.619a.849.849 0 01.006 1.2.845.845 0 01-.601.252z"
              data-name="Path 910"
              transform="translate(4891.087 -2847.9)"
            ></path>
          </g>
        </g>
        <path
          fill="none"
          d="M0 0H28V28H0z"
          data-name="Rectangle 694"
          transform="translate(-5672 2872)"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
