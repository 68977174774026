import { combineReducers } from "redux";
import {robotEntriesReducer} from './robotEntries';
import {robotDetailsReducer} from './robotDetails';
import {robotDetailInfoReducer} from '../RobotInfo/reducer/reducer';
const rootRobotDetailsReducer = combineReducers({
  robotEntriesReducer: robotEntriesReducer,
  robotDetailsReducer: robotDetailsReducer,
  robotDetailInfoReducer: robotDetailInfoReducer,
});
export default rootRobotDetailsReducer;
