import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import log from "../../../../common/img/log.png";
import RobotStatus from "common/RobotStatus";
import { ICON_LOGKUN } from "common/constants/constants";

class RobotInfoBuilderSP extends Component {
  render() {
    return (
      <Grid
        style={{
          width: "100%",
          backgroundImage: `url(${log})`,
          backgroundSize: "contain",
          backgroundBlendMode: "lighten",
          backgroundColor: "rgba(255,255,255,0.8)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          paddingBottom: 100
        }}
      >
        <Grid
          container
          style={{
            justifyContent: "center",
            padding: "0 10%"
          }}
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family">
              <Typography
                variant="subtitle2"
                component="span"
                className="font-family"
                style={{ fontSize: "1rem" }}
              >
                ロボット名
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family mb-15">
              {this.props.robotInfo.robotName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family">
              <Typography
                variant="subtitle2"
                component="span"
                className="font-family"
                style={{ fontSize: "1rem" }}
              >
                ロボットID
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family mb-15">
              {this.props.robotInfo.robotId}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family">
              <Typography
                variant="subtitle2"
                component="span"
                className="font-family"
                style={{ fontSize: "1rem" }}
              >
                シリアルナンバー
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family mb-15">
              {this.props.robotInfo.cerealCode}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family">
              <Typography
                variant="subtitle2"
                component="span"
                className="font-family"
                style={{ fontSize: "1rem" }}
              >
                レンタル開始日
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family mb-15">
              {this.props.robotInfo.leaseStartDate
                ? format(
                    new Date(this.props.robotInfo.leaseStartDate),
                    "yyyy年M月d日"
                  )
                : null}
            </Typography>
          </Grid>
          <Grid item xs={12} className="mb-15">
            <React.Fragment>
              <Typography variant="subtitle1" className="font-family">
                <Typography
                  variant="subtitle2"
                  component="span"
                  className="font-family"
                  style={{ fontSize: "1rem" }}
                >
                  ステータス
                </Typography>
              </Typography>
              <Grid item xs={12}>
            <RobotStatus
              typeOfRobotStatus={ICON_LOGKUN}
              robotsUpdate={this.props.robotsUpdate}
              robots={[this.props.robotInfo]}
              isSmall={true}
            />
            {/* <Typography variant="subtitle1" className="font-family mb-15">
              <div
                className={`status ${this.props.robotInfo.useStatus === 1 ? 'status-green' : 'status-yellow'}`} 
                style={{display: 'inline-block', height: 10, width: 10, borderRadius: 10}}
              />
              {this.props.robotInfo.useStatusName}
            </Typography> */}
          </Grid>
            </React.Fragment>
            
          </Grid>
          
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family">
              <Typography
                variant="subtitle2"
                component="span"
                className="font-family"
                style={{ fontSize: "1rem" }}
              >
                現場
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family mb-15">
              {this.props.robotInfo.siteName
                ? this.props.robotInfo.siteName
                : "(現場未登録)"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default RobotInfoBuilderSP;
