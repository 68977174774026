import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import "../../common/css/base.css";
import "../../common/css/log.css";
import { fetchDashBoardLB } from "components/dashboard/service/service";
import { convertSerialNumber } from "utils/mapper";

class DashboardLogbuild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      onSubmit: "",
      nextButtonClick: false,
      isPasswordShow: false,
      isDialogConfirmShow: false
    };
  }

  fakeRobots = [
    {
      uniqueCode: "1",
      robotName: "#0005",
      siteName: null,
      leaseStartDate: "2020-04-13T09:58:44.841Z",
      useStatus: null,
      useStatusName: null
    },
    {
      uniqueCode: "2",
      robotName: "#0005",
      siteName: null,
      leaseStartDate: "2020-04-13T09:58:44.841Z",
      useStatus: null,
      useStatusName: null
    },
    {
      uniqueCode: "3",
      robotName: "#0005",
      siteName: null,
      leaseStartDate: "2020-04-13T09:58:44.841Z",
      useStatus: null,
      useStatusName: null
    }
  ];

  componentDidMount() {
    this.props.fetchDashBoardLB();
  }

  render() {
    const { dashboardLB } = this.props;
    return (
      <Grid className="dashboard">
        <Typography variant="h5" className="dashboard-title text-bold">
          最近閲覧したロボット
        </Typography>
        <TableContainer className="dashboard-loguibld-table">
          <Table
            component="div"
            size="medium"
            aria-label="table"
            className="carpenter-table"
          >
            <TableHead component="div" className="table-head">
              <TableRow component="div">
                <TableCell component="span" className="white-text">
                  管理番号
                </TableCell>
                <TableCell component="span" className="white-text">
                  貸出工務店名
                </TableCell>
                <TableCell component="span" className="white-text">
                  貸出日
                </TableCell>
                <TableCell component="span" className="white-text">
                  ステータス
                </TableCell>
                <TableCell component="span" className="white-text">
                  利用停止日
                </TableCell>
                <TableCell component="span" className="white-text">
                  回収日
                </TableCell>
                <TableCell component="span"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody component="div" className="table-body">
              {dashboardLB.map(item => (
                <TableRow
                  component={Link}
                  to={`/logbuild/robots/${item.robotsUC}`}
                  key={item.robotsUC}
                  className="cursor-pointer reset-link"
                >
                  <TableCell component="span">
                    {item.serialNumber &&
                      `#${convertSerialNumber(item.serialNumber)}`}
                  </TableCell>
                  <TableCell component="span">{item.builderName}</TableCell>
                  <TableCell component="span">
                    {item.leaseStartDate &&
                      format(new Date(item.leaseStartDate), "yyyy年M月d日")}
                  </TableCell>
                  <TableCell component="span">{item.leaseStatusName}</TableCell>
                  <TableCell component="span">
                    {item.leaseEndDate &&
                      format(new Date(item.leaseEndDate), "yyyy年M月d日")}
                  </TableCell>
                  <TableCell component="span">
                    {item.withdrawalDate &&
                      format(new Date(item.withdrawalDate), "yyyy年M月d日")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  dashboardLB: state.dashboardsReducer.dashboardLB
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDashBoardLB: fetchDashBoardLB
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardLogbuild));
