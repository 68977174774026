import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import { Typography } from "@material-ui/core";
import ButtonRobot from "../../common/ButtonRobot";
import "../../common/css/base.css";
import "../../common/css/sites-staft.css";
import { BUTTON_LOGKUN_SP, ICON_LOGKUN } from "common/constants/constants";
import RobotStatus from "common/RobotStatus";

class RobotBuilderSP extends Component {
  render() {
    const {
      robots,
      robotsUpdate,
      to,
      onCreateUserAcknowledgement
    } = this.props;

    return (
      <>
        <Typography
          variant="h5"
          className="dashboard-sp-title font-family text-bold"
        >
          ロボットの管理
        </Typography>
        <MenuList>
          {robots.map(robot => (
            <MenuItem
              key={robot.uniqueCode}
              onClick={to(robot.uniqueCode)}
              className="dashboard-sp-item"
            >
              <Grid container justify="space-between" wrap="nowrap">
                <Grid className="item-left">
                  <Typography
                    variant="h5"
                    className="text-bold font-family white-space-normal"
                    style={{ fontSize: "1rem" }}
                  >
                    <RobotStatus
                      typeOfRobotStatus={ICON_LOGKUN}
                      robotsUpdate={robotsUpdate}
                      robots={[robot]}
                      isSmall={true}
                      noText={true}
                    />
                    {robot.robotName}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className="font-family grey-light white-space-normal"
                  >
                    {robot.siteName ? robot.siteName : "(現場未登録)"}
                  </Typography>
                </Grid>
                <Grid
                  className="item-right"
                  alignItems="center"
                  style={{ flexDirection: "row" }}
                  onClick={e => e.stopPropagation()}
                >
                  <RobotStatus
                    typeOfRobotStatus={BUTTON_LOGKUN_SP}
                    robotsUpdate={robotsUpdate}
                    robots={[robot]}
                    forceCreateUserAcknowledgement={true}
                  />
                  {/* <ButtonRobot
                    robots={
                      robotsUpdate &&
                      robotsUpdate.findIndex(
                        x => x.robotsUC === robot.uniqueCode
                      ) != -1
                        ? robotsUpdate[
                            robotsUpdate.findIndex(
                              x => x.robotsUC === robot.uniqueCode
                            )
                          ]
                        : robot
                    }
                    size="small"
                    onClick={onCreateUserAcknowledgement(
                      robot.uniqueCode,
                      robot.useStatus
                    )}
                  /> */}
                </Grid>
              </Grid>
            </MenuItem>
          ))}
        </MenuList>
      </>
    );
  }
}

RobotBuilderSP.propTypes = {
  robots: PropTypes.array.isRequired,
  to: PropTypes.func.isRequired,
  onCreateUserAcknowledgement: PropTypes.func.isRequired
};

export default RobotBuilderSP;
