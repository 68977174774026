import { GET_LIST_DETAIL_SUCCESS } from "../constant/constant";

const initialState = {
  carpenterDetail: []
};

export const carpentersDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_DETAIL_SUCCESS:
      return {
        ...state,
        carpenterDetail: action.carpenterDetail
      };

    default:
      return state;
  }
};

export const getDetailCarpenters = state => state;
