import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs
} from "@material-ui/core";

import PhotoTab from "./PhotoTab/PhotoTab";
import FilePdfTab from "./FilePdfTab/FilePdfTab";
import { makeStyles } from "@material-ui/core/styles";
import { api } from "common/api/api";
import { useHistory, useLocation } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";

const mediaQueryTablet = "(max-width: 960px)";
const mediaQueryMobile = "(max-width: 680px)";

const useStyles = makeStyles({
  dialogActionButton: {
    width: "120px",
    textAlign: "center",
    padding: "6px 0px",
    borderRadius: "6px",
    backgroundColor: "#192D51",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "400",
    color: "white",
    margin: "8px 16px 8px 16px"
  },
  tabMobile: {
    width: "50%",
    minWidth: "50%"
  },
  tabsRoot: {
    borderBottom: "1px solid #e0e0e0"
  },
  dialogActionsMobile: {
    display: "flex",
    padding: "8px",
    alignItems: "center",
    justifyContent: "center"
  }
});

function ChooseExistFile(props) {
  const {
    mode, // 'send-to-comet', 'update-info', 'create-info'
    isOpen, // all
    sitesUC, // all
    onClose, // all
    excludeSelects, // all
    initTab, // option

    hotspotInfo, // send-to-comet
    cometChatUser, // send-to-comet

    acceptSelectedSuccess, // update-info
    infoUC, // update-info
    shoot, // update-info

    addToTemporary, // create-info
    doCompletedSendFiles
  } = props;

  const [tab, setTab] = useState("image");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [sending, setSending] = useState(false);

  let history = useHistory();
  let location = useLocation();
  const classes = useStyles();
  const isTablet = useMediaQuery({ query: mediaQueryTablet });
  const isMobile = useMediaQuery({ query: mediaQueryMobile });
  useEffect(() => {
    if (initTab === "file" || initTab === "image") {
      setTab(initTab);
    }
  }, [initTab]);

  useEffect(() => {
    removeSelected();
  }, [isOpen]);

  const removeSelected = () => {
    setSelectedFiles([]);
    setSelectedPhotos([]);
  };

  const selectFile = fileInfo => {
    if (
      excludeSelects.find(
        file =>
          fileInfo.fileId === file.uniqueCode || fileInfo.fileId === file.fileId
      )
    )
      return;
    if (selectedFiles.find(file => fileInfo.fileId === file.fileId)) {
      setSelectedFiles(
        selectedFiles.filter(file => fileInfo.fileId !== file.fileId)
      );
    } else {
      setSelectedFiles(selectedFiles.concat([fileInfo]));
    }
  };

  const selectPhoto = photoInfo => {
    if (
      excludeSelects.find(
        photo =>
          photoInfo.photoId === photo.uniqueCode ||
          photoInfo.photoId === photo.photoId
      )
    )
      return;

    if (selectedPhotos.find(photo => photoInfo.photoId === photo.photoId)) {
      setSelectedPhotos(
        selectedPhotos.filter(photo => photoInfo.photoId !== photo.photoId)
      );
    } else {
      setSelectedPhotos(selectedPhotos.concat([photoInfo]));
    }
  };

  const handleChange = (e, newValue) => {
    setTab(newValue);
  };

  const sendToComet = () => {
    if (hotspotInfo) {
      setSending(true);
      let data = {
        appID: cometChatUser.appID,
        authToken: cometChatUser.authToken,
        threadID: hotspotInfo.messageId,
        groupID: hotspotInfo.sitesUC
      };
      const classification = location.pathname.includes("/sp/")
        ? "supplier"
        : "builder";

      let sendPromise = [];
      if (selectedFiles.length > 0) {
        const sendFilePromise = api.post(
          `/${classification}/cometchat/send-file?sitesUC=${hotspotInfo.sitesUC}`,
          { ...data, fileIDs: selectedFiles.map(item => item.fileId) }
        );
        sendPromise.push(sendFilePromise);
      }
      if (selectedPhotos.length > 0) {
        const sendPhotoPromise = api.post(
          `/${classification}/cometchat/send-photo?sitesUC=${hotspotInfo.sitesUC}`,
          { ...data, photoIDs: selectedPhotos.map(item => item.photoId) }
        );
        sendPromise.push(sendPhotoPromise);
      }

      Promise.all(sendPromise)
        .then(res => {
          setSelectedFiles([]);
          setSelectedPhotos([]);
          setSending(false);
          onClose();
          doCompletedSendFiles();
        })
        .catch(err => {
          setSending(false);
        });
    }
  };

  const acceptSelected = () => {
    let filesUniqueCode = selectedPhotos.map(item => item.photoId).reverse();
    filesUniqueCode = filesUniqueCode.concat(
      selectedFiles.map(item => item.fileId).reverse()
    );

    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    api
      .post(`/${classification}/logwalk-viewer/infos/${infoUC}/exists`, {
        filesUniqueCode
      })
      .then(res => {
        acceptSelectedSuccess();
        onClose();
      })
      .catch(err => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        if (location.pathname.includes("/sp/")) {
          history.replace(
            `/sp/logwalk-viewer/shoots/${shoot.uniqueCode}?info=${infoUC}`
          );
          history.go(0);
        } else {
          history.replace(
            `/logwalk-viewer/shoots/${shoot.uniqueCode}?info=${infoUC}`
          );
          history.go(0);
        }
      });
  };

  const checkEnable = () => {
    return selectedFiles.length > 0 || selectedPhotos.length > 0;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
      PaperProps={{ style: { height: "76vh" } }}
    >
      <DialogTitle>
        <Tabs
          className="tabs"
          value={tab}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
          centered={isMobile}
          classes={{ root: classes.tabsRoot }}
        >
          <Tab
            className="tab-button"
            label="写真"
            value={"image"}
            classes={isMobile ? { root: classes.tabMobile } : {}}
          />
          <Tab
            className="tab-button"
            label="ファイル(PDF)"
            value={"file"}
            classes={isMobile ? { root: classes.tabMobile } : {}}
          />
        </Tabs>
      </DialogTitle>
      <DialogContent>
        <Grid className="sites-tab">
          {sitesUC !== undefined && (
            <Grid>
              {tab === "image" && (
                <PhotoTab
                  sitesUC={sitesUC}
                  selectedPhotos={selectedPhotos}
                  selectPhoto={selectPhoto}
                  excludeSelects={excludeSelects}
                  isTabletOrMobile={isTablet}
                />
              )}
              {tab === "file" && (
                <FilePdfTab
                  sitesUC={sitesUC}
                  selectedFiles={selectedFiles}
                  selectFile={selectFile}
                  excludeSelects={excludeSelects}
                  isTabletOrMobile={isTablet}
                />
              )}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        classes={isMobile ? { root: classes.dialogActionsMobile } : {}}
      >
        <Button
          onClick={() => {
            if (checkEnable()) {
              if (mode === "send-to-comet") {
                sendToComet();
              } else if (mode === "update-info") {
                acceptSelected();
              } else if (mode === "create-info") {
                addToTemporary(selectedPhotos.concat(selectedFiles));
                removeSelected();
                onClose();
              }
            }
          }}
          className={classes.dialogActionButton}
          style={
            checkEnable()
              ? { backgroundColor: "#192D51", cursor: "pointer" }
              : { backgroundColor: "#707070", cursor: "default" }
          }
        >
          追加
          {sending && (
            <span
              style={{ fontSize: "20px", color: "white", marginLeft: "10px" }}
            >
              <CircularProgress size="20px" color="inherit" />
            </span>
          )}
        </Button>
        <Button
          onClick={onClose}
          className={classes.dialogActionButton}
          style={{ backgroundColor: "#192D51", cursor: "pointer" }}
        >
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChooseExistFile;
