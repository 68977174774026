import {
  FETCH_ROBOT_SUCCESS,
  FETCH_ROBOT_LOG_BUILD_SUCCESS,
  POST_ROBOT_SUCCESS,
  PUT_ROBOT_SUCCESS,
  PUT_ROBOT_ERROR,
  PUT_ROBOT_START,
  ROBOT_CLEAR_TYPE,
  DELETE_ROBOT_SUCCESS
} from "../constant/constant";

export const fetchRobotSuccess = robot => {
  return {
    type: FETCH_ROBOT_SUCCESS,
    robot: robot
  };
};

export const fetchRobotLogBuildSuccess = robotLogBuild => {
  return {
    type: FETCH_ROBOT_LOG_BUILD_SUCCESS,
    robotLogBuild: robotLogBuild
  };
};

export const createRobotSuccess = robot => {
  return {
    type: POST_ROBOT_SUCCESS,
    robot
  };
};

export const updateRobotSuccess = () => {
  return {
    type: PUT_ROBOT_SUCCESS
  };
};

export const updateRobotError = () => {
  return {
    type: PUT_ROBOT_ERROR
  };
};

export const updateRobotStart = () => {
  return {
    type: PUT_ROBOT_START
  };
};

export const robotClearType = () => {
  return {
    type: ROBOT_CLEAR_TYPE
  };
};

export const deleteRobotSuccess = () => {
  return {
    type: DELETE_ROBOT_SUCCESS
  };
};
