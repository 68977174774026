import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchRobotDetailPage,
  wsUpdatedRobots,
  wsClearRobots,
  changeRobotUC
} from "./service/service";
import { getRobotEntries } from "./reducer/robotEntries";
import { getType, getRobotDetailInfo } from "./RobotInfo/reducer/reducer";
import { createUserAcknowledgement } from "../../../common/Stores/service/service";
import DialogContactRepair from "../../../common/DialogContactRepair";
import DialogRobotEdit from "../../../common/DialogRobotEdit";
import DialogRobotDelete from "../../../common/DialogRobotDelete";
import DialogRobotReturn from "../../../common/DialogRobotReturn";
import Sp from "../../../common/breakpoints/Sp";
import Pc from "../../../common/breakpoints/Pc";
import RobotDetailBuilderSP from "./RobotDetailBuilderSP";
import RobotDetailBuilderPC from "./RobotDetailBuilderPC";
import "../../../common/css/base.css";
import "../../../common/css/log.css";
import { FETCH_ROBOT_DETAIL_INFO_SUCCESS } from "./RobotInfo/constant/constant";
import { getWebSocketRobotURL } from "utils/mapper";
const WS_TIME_REPEAT = 10000; //10s

class RobotDetail extends Component {
  robotsUC = undefined;
  ws = null;
  wsInterval = null;
  pingInterval = null;
  countFetch = 0;
  constructor(props) {
    super(props);
    this.state = {
      tabValue: "ロボット情報"
    };
  }

  componentDidMount() {
    this.props.fetchRobotDetailPage(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.props.fetchRobotDetailPage(nextProps.match.params.id);
    }

    if (this.robotsUC !== nextProps.robotDetails.uniqueCode) {
      if (
        !nextProps.robotUC ||
        (nextProps.robotUC &&
          (this.countFetch === 1 ||
            (this.countFetch === 0 &&
              this.props.match.params.id === nextProps.robotUC)))
      ) {
        if (this.ws) {
          this.ws.onclose = function () {}; // disable onclose handler first
          this.ws.close();
          this.props.wsClearRobots();
          clearInterval(this.pingInterval);
          clearInterval(this.wsInterval);
        }

        this.openWS([nextProps.robotDetails.uniqueCode]);

        this.wsInterval = setInterval(() => {
          if (this.ws.readyState === 3) {
            clearInterval(this.pingInterval);
            this.openWS([nextProps.robotDetails.uniqueCode]);
          }
        }, WS_TIME_REPEAT);

        if (this.countFetch === 1) {
          this.countFetch = 0;
        }
      }
    }

    if (nextProps.robotUC) {
      this.countFetch = 1;
    }

    this.robotsUC = nextProps.robotDetails.uniqueCode;
  }

  componentWillUnmount() {
    clearInterval(this.wsInterval);
    clearInterval(this.pingInterval);
    if (this.ws) {
      this.ws.onclose = () => {}; // disable onclose handler first
      this.ws.close();
      this.props.wsClearRobots();
    }

    this.props.changeRobotUC(this.props.match.params.id);
  }

  openWS = robotsUC => {
    this.ws = new WebSocket(getWebSocketRobotURL());
    this.ws.addEventListener("open", () => {
      this.ws.send(JSON.stringify(robotsUC));

      this.pingInterval = setInterval(() => {
        this.ws.send("ping");
      }, WS_TIME_REPEAT);
    });

    this.ws.onmessage = s => {
      if (s.data !== "pong") {
        this.props.wsUpdatedRobots(JSON.parse(s.data));
      }
    };
  };

  render() {
    const { robotsUpdate } = this.props;

    const showEditForm = () => {
      this.setState({
        isDialogEditShow: true
      });
    };

    const onCloseDialogEdit = () => {
      this.setState({
        isDialogEditShow: false
      });
    };

    const onUpdateSuccess = () => {
      this.props.fetchRobotDetailPage(this.props.match.params.id);
    };

    const showDialogContactRepair = () => {
      this.setState({
        isDialogContactRepair: true
      });
    };

    const onCloseDialogContactRepair = () => {
      this.setState({
        isDialogContactRepair: false
      });
    };

    const toggleRequestDialog = () => {
      this.setState({
        isRequestDialogShow: !this.state.isRequestDialogShow
      });
    };

    const onReturnSuccess = () => {
      this.props.history.push("/robots");
    };

    const toggleDialogDelete = () => {
      this.setState({
        isDialogDeleteShow: !this.state.isDialogDeleteShow,
        isDialogEditShow: false
      });
    };

    const onDeleteSuccess = () => {
      this.props.fetchRobotDetailPage(this.props.match.params.id);
    };

    const onClickButtonReturn = () => {
      this.props.history.push("/robots");
    };

    const onClickButtonRobot = () => {
      const value = {
        uniqueCode: this.props.robotDetails.uniqueCode
      };
      if (this.props.robotDetails.useStatus) {
        this.props.createUserAcknowledgement(value);
      }
    };

    const tabChange = (e, value) => {
      this.setState({
        tabValue: value
      });
    };

    return (
      <>
        <Pc>
          <RobotDetailBuilderPC
            robotDetails={this.props.robotDetails}
            robotInfo={this.props.robotInfo}
            tabValue={this.state.tabValue}
            robotEntries={this.props.robotEntries}
            robotHistory={this.props.robotHistory}
            robotsUpdate={robotsUpdate}
            tabChange={tabChange}
            showEditForm={showEditForm}
            showDialogContactRepair={showDialogContactRepair}
            toggleRequestDialog={toggleRequestDialog}
            onClickButtonReturn={onClickButtonReturn}
            onClickButtonRobot={onClickButtonRobot}
          />
        </Pc>

        <Sp>
          <RobotDetailBuilderSP
            robotDetails={this.props.robotDetails}
            robotInfo={this.props.robotInfo}
            tabValue={this.state.tabValue}
            robotHistory={this.props.robotHistory}
            robotsUpdate={robotsUpdate}
            tabChange={tabChange}
            showEditForm={showEditForm}
            showDialogContactRepair={showDialogContactRepair}
            toggleRequestDialog={toggleRequestDialog}
            onClickButtonReturn={onClickButtonReturn}
            onClickButtonRobot={onClickButtonRobot}
          />
        </Sp>

        {this.state.isDialogEditShow && (
          <DialogRobotEdit
            uniqueCode={this.props.robotDetails.uniqueCode}
            open={this.state.isDialogEditShow}
            onClose={onCloseDialogEdit}
            onSuccess={onUpdateSuccess}
            onClickDelete={toggleDialogDelete}
          />
        )}

        {this.state.isDialogContactRepair && (
          <DialogContactRepair
            dialogType="Contact"
            open={this.state.isDialogContactRepair}
            uniqueCode={this.props.robotDetails.uniqueCode}
            onClose={onCloseDialogContactRepair}
          />
        )}

        {this.state.isDialogDeleteShow && (
          <DialogRobotDelete
            open={this.state.isDialogDeleteShow}
            uniqueCode={this.props.robotDetails.uniqueCode}
            onClose={toggleDialogDelete}
            onSuccess={onDeleteSuccess}
          />
        )}

        {this.state.isRequestDialogShow && (
          <DialogRobotReturn
            open={this.state.isRequestDialogShow}
            uniqueCode={this.props.robotDetails.uniqueCode}
            onClose={toggleRequestDialog}
            onSuccess={onReturnSuccess}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  robotDetails: getRobotDetailInfo(
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailInfoReducer
      .robotInfo
  ),
  robotInfo: getRobotDetailInfo(
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailInfoReducer
      .robotInfo
  ),
  robotEntries: getRobotEntries(
    state.rootRobotsReducer.rootRobotDetailsReducer.robotEntriesReducer
      .robotEntries
  ),
  type: getType(
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailInfoReducer.type
  ),
  robotHistory:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .builderRobotHistory,
  robotsUpdate:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .wsUpdatedRobots,
  robotUC:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer.robotUC
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRobotDetailPage,
      createUserAcknowledgement,
      wsUpdatedRobots,
      wsClearRobots,
      changeRobotUC
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RobotDetail));
