import React, { memo } from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
const CommonMapOptionDialogSP = ({
  onClose,
  title,
  titleClasses,
  buttonOptions,
  ...rest
}) => {
  return (
    <Dialog
      aria-labelledby="max-width-dialog-title"
      fullWidth
      maxWidth="xs"
      onClose={() => {}}
      {...rest}
    >
      <Fab onClick={onClose} size="small" className="white-text close-button">
        <CloseIcon />
      </Fab>
      <DialogTitle
        disableTypography
        style={{ padding: "16px 8px"}}
      >
        <Typography variant="h5" className={`dialog-title ${titleClasses}`}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent className="confirm-content">
        {buttonOptions &&
          buttonOptions.map(buttonProperties => {
            const {
              buttonTitle,
              buttonOnCick,
              buttonClasses,
              buttonIsDisable
            } = buttonProperties;
            if (buttonTitle) {
              return (
                <Button
                  onClick={() => buttonOnCick()}
                  disabled={buttonIsDisable}
                  size="large"
                  className={buttonClasses ? buttonClasses : ""}
                >
                  {buttonTitle}
                </Button>
              );
            }
            return null;
          })}
      </DialogContent>
    </Dialog>
  );
};

CommonMapOptionDialogSP.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string
};

export default memo(CommonMapOptionDialogSP);
