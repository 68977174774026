export const FETCH_ROBOT_DETAIL_SUCCESS = "FETCH_ROBOT_DETAIL_SUCCESS";
export const FETCH_ROBOT_ENTRIES_SUCCESS = "FETCH_ROBOT_ENTRIES_SUCCESS";
export const FETCH_ROBOT_LOG_BUILD_DETAIL_SUCCESS =
  "FETCH_ROBOT_LOG_BUILD_DETAIL_SUCCESS";
export const FETCH_ROBOT_LOG_BUILD_ENTRIES_SUCCESS =
  "FETCH_ROBOT_LOG_BUILD_ENTRIES_SUCCESS";
export const FETCH_LOG_BUILD_BUILDER_ENTRIES_SUCCESS =
  "FETCH_LOG_BUILD_BUILDER_ENTRIES_SUCCESS";
export const FETCH_LOG_BUILD_STAFF_ENTRIES_SUCCESS =
  "FETCH_LOG_BUILD_STAFF_ENTRIES_SUCCESS";

export const FETCH_LOG_BUILD_RBOT_HISTORY_SUCCESS =
  "FETCH_LOG_BUILD_RBOT_HISTORY_SUCCESS";

export const POST_LOG_BUILD_RBOT_HISTORY_SUCCESS =
  "POST_LOG_BUILD_RBOT_HISTORY_SUCCESS";

export const PUT_LOG_BUILD_RBOT_HISTORY_SUCCESS =
  "PUT_LOG_BUILD_RBOT_HISTORY_SUCCESS";

export const FETCH_LOG_BUILD_RBOT_LEASE_HISTORY_SUCCESS =
  "FETCH_LOG_BUILD_RBOT_LEASE_HISTORY_SUCCESS";

export const DELETE_LOG_BUILD_ROBOT_HISTORY_SUCCESS =
  "DELETE_LOG_BUILD_ROBOT_HISTORY_SUCCESS";

export const FETCH_ROBOT_HISTORY_SUCCESS = "FETCH_ROBOT_HISTORY_SUCCESS";

export const FETCH_BUILDER_ROBOT_AVAILABLE_SUCCESS =
  "FETCH_BUILDER_ROBOT_AVAILABLE_SUCCESS";

export const FETCH_ROBOT_EDIT_SUCCESS = "FETCH_ROBOT_EDIT_SUCCESS";

export const WS_UPDATED_ROBOTS_SUCCESS = "WS_UPDATED_ROBOTS_SUCCESS";

export const WS_CLEAR_ROBOTS_SUCCESS = "WS_CLEAR_ROBOTS_SUCCESS";

export const ROBOT_UC_CHANGE_SUCCESS = "ROBOT_UC_CHANGE_SUCCESS";