import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchRobot } from "./service/service";
import { getRobots } from "./reducer/robot";
import { createUserAcknowledgement } from "../../common/Stores/service/service";
import "../../common/css/base.css";
import "../../common/css/log.css";
import Sp from "../../common/breakpoints/Sp";
import Pc from "../../common/breakpoints/Pc";
import RobotBuilderSP from "./RobotBuilderSP";
import RobotBuilderPC from "./RobotBuilderPC";
import {
  wsUpdatedRobots,
  wsClearRobots
} from "../robots/robotDetails/service/service";
import { FETCH_ROBOT_SUCCESS } from "./constant/constant";
import { getWebSocketRobotURL } from "utils/mapper";
const WS_TIME_REPEAT = 10000; //10s
class Robot extends Component {
  ws = null;
  wsInterval = null;

  componentDidMount() {
    const { state } = this.props.history.location;
    this.props.fetchRobot();
    if(state  && state.reload)
    {
      state.reload = false;
    }
  }

  componentWillReceiveProps(props) {
    const { state } = props.history.location;
    if(state  && state.reload)
    {
      this.props.fetchRobot();
      state.reload = false;
    }
    if (props.type === FETCH_ROBOT_SUCCESS && props.robots && !this.ws) {
      let robotsUC = [];

      props.robots.map((robot, i) => {
        robotsUC = [...robotsUC, robot.uniqueCode];
      });

      if (robotsUC.length > 0) {
        this.openWS(robotsUC);

        this.wsInterval = setInterval(() => {
          if (this.ws === null) {
            this.openWS(robotsUC);
          }
        }, WS_TIME_REPEAT);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.wsInterval);
    clearInterval(this.pingInterval);
    if (this.ws) {
      this.ws.onclose = () => {}; // disable onclose handler first
      this.ws.close();
      this.props.wsClearRobots();
    }
  }

  openWS = robotsUC => {
    var pingInterval = null;
    this.ws = new WebSocket(getWebSocketRobotURL());
    this.ws.addEventListener("open", () => {
      this.ws.send(JSON.stringify(robotsUC));

      pingInterval = setInterval(() => {
        this.ws.send("ping");
      }, WS_TIME_REPEAT);
    });

    this.ws.addEventListener("close", () => {
      clearInterval(pingInterval);
      if (this.ws) {
        this.ws.onclose = () => {}; // disable onclose handler first
        this.ws.close();
        this.ws = null;
      }
    });

    this.ws.onmessage = s => {
      if (s.data !== "pong") {
        this.props.wsUpdatedRobots(JSON.parse(s.data));
      }
    };
  };

  onCreateUserAcknowledgement = (id, status) => e => {
    e.preventDefault();
    e.stopPropagation();
    const value = {
      uniqueCode: id
    };
    if (status) {
      this.props.createUserAcknowledgement(value);
    }
  };

  to = id => () => {
    this.props.history.push(`/robots/${id}`);
  };

  render() {
    const { robots, robotsUpdate } = this.props;
    return (
      <>
        <Sp>
          <RobotBuilderSP
            robots={robots}
            to={this.to}
            robotsUpdate={[robotsUpdate]}
            onCreateUserAcknowledgement={this.onCreateUserAcknowledgement}
          />
        </Sp>
        <Pc>
          <RobotBuilderPC
            robots={robots}
            to={this.to}
            robotsUpdate={[robotsUpdate]}
          />
        </Pc>
      </>
    );
  }
}

Robot.propTypes = {
  robots: PropTypes.array.isRequired,
  fetchRobot: PropTypes.func.isRequired,
  createUserAcknowledgement: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  type: state.rootRobotsReducer.robotsReducer.type,
  robots: getRobots(state.rootRobotsReducer.robotsReducer.robot),
  robotsUpdate:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .wsUpdatedRobots
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRobot: fetchRobot,
      createUserAcknowledgement: createUserAcknowledgement,
      wsUpdatedRobots: wsUpdatedRobots,
      wsClearRobots: wsClearRobots
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Robot));
