import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import InfoIconItem from "./InfoIconItem";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import IconInfo from "../hotspotInfoIcons/IconInfo";
import IconExpandSelect from "../hotspotInfoIcons/IconExpandSelect";
const useStyles = makeStyles({
  root: {
    paddingBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  selectButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "45px",
    width: "100%",
    border: "1px solid #c7c7c7",
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    boxSizing: "border-box",
    padding: "0px  20px",
    cursor: "pointer",
    "& div": {
      display: "flex",
      alignItems: "center"
    },
    "&:hover": {
      backgroundColor: "#E5EAF0"
    }
  },
  itemText: {
    fontSize: "17px",
    fontWeight: "normal",
    marginLeft: "20px"
  },
  customScrollbar: {
    "&::-webkit-scrollbar": {
      backgroundColor: "#fff",
      width: "16px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#babac0",
      borderRadius: "16px",
      border: "4px solid #fff"
    },
    "&::-webkit-scrollbar-button": {
      display: "none"
    }
  }
});

function SelectInfoIcon(props) {
  const classes = useStyles();
  const { allIcons, icon, setIcon } = props;

  const selectInfoIconRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState("0"); 

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setPopoverWidth(window.getComputedStyle(selectInfoIconRef.current).width);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showCurrentIcon = () => {
    const iconObj = allIcons.find(item => item.uniqueCode === icon.uniqueCode);
    if (iconObj)
      return (
        <React.Fragment>
          <IconInfo color={iconObj.color} width="30" height="30" />
          <span className={classes.itemText}>{iconObj.label}</span>
        </React.Fragment>
      );
  };

  return (
    <React.Fragment>
      <div
        onClick={handleClick}
        ref={selectInfoIconRef}
        className={classes.root}
        style={{ paddingBottom: "10px" }}
      >
        <div className={classes.selectButton}>
          <div>{showCurrentIcon()}</div>
          <span
            style={{
              transform: Boolean(anchorEl) ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform  0.3s linear"
            }}
          >
            <IconExpandSelect />
          </span>
        </div>
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            width: popoverWidth,
            overflow: "hidden",
            height: "190px",
            padding: "12px 0px"
          }
        }}
      >
        <div style={{width: "100%", height: "100%", overflow: "auto"}} className={classes.customScrollbar}>
          {allIcons.map((iconItem, index) => (
            <React.Fragment>
              {index !== 0 && (
                <hr
                  style={{border: "none", borderTop: "1px solid #BEBEBE", margin: "0px 20px" }}
                />
              )}
              <InfoIconItem
                key={iconItem.uniqueCode}
                icon={icon}
                iconItem={iconItem}
                setIcon={iconItem => {
                  setIcon(iconItem);
                  setAnchorEl(null);
                }}
              />
            </React.Fragment>
          ))}
        </div>
      </Popover>
    </React.Fragment>
  );
}

export default SelectInfoIcon;
