import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import { bindActionCreators } from "redux";
import { Formik, Field } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import DrawingPhoto from "../DrawingPhoto";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { getDrawingsWithSpots } from "../reducer/reducer";
import "../../../../common/css/copy-drawing-dialog.css";
import { MAXIMUM_DRAWING } from "../constant/constant";
import { getTextLength } from "utils/mapper";
import { translateMessage, trimObjValues } from "utils/mapper";

const MAX_LENGTH_OF_NAME = 12;
const MAX_FLOOR = 99;
const STEP = {
  OPTION_EDIT: 1,
  EDIT_FLOOR: 2,
  EDIT_FLOOR_NAME: 3,
  EDIT_NAME_FLOOR: 4,
  EDIT_CUSTOM: 5
};
class CopyDrawingStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: [1],
      nameOfDrawing: "",
      firstStep: null,
      drawingsName: [],
      floor: {
        min: null,
        max: null
      },
      errors: []
    };
  }

  componentWillMount() {
    if (this.props.maxPage) {
      this.setState({
        floor: {
          min: 1,
          max: this.props.maxPage
        }
      });
    }
  }

  nextStep = (newStep, isFirstStep = false) => {
    const { step, floor, nameOfDrawing, drawingsName } = this.state;

    let clientError = null;

    // Validate data when setting drawing name
    if (
      step[step.length - 1] === STEP.EDIT_NAME_FLOOR ||
      step[step.length - 1] === STEP.EDIT_FLOOR_NAME
    ) {
      if (!nameOfDrawing) {
        clientError = {
          ...clientError,
          drawingName: translateMessage(
            this.props.message.required,
            this.props.regexObject
          )
        };
      } else if (getTextLength(nameOfDrawing) > MAX_LENGTH_OF_NAME) {
        clientError = {
          ...clientError,
          drawingName: translateMessage(
            this.props.message.maximumCharacter,
            this.props.regexObject
          )
        };
      }
    }

    if (clientError) {
      this.setState({
        errors: clientError
      });

      return;
    }

    this.setState({
      step: [...step, newStep]
    });

    // If choose step from menu, set first step
    if (isFirstStep) {
      this.setState({ firstStep: newStep }, () => {
        if (newStep === STEP.EDIT_CUSTOM) {
          this.props.setDrawingName(this.createDrawingsName());
          this.props.disableSubmit(false);
        }
      });
    }

    if (!isFirstStep && newStep === STEP.EDIT_CUSTOM) {
      this.props.setDrawingName(this.createDrawingsName());
      this.props.disableSubmit(false);
    }

    // Remove errors messages
    this.setState({ errors: [] });
  };

  backStep = () => {
    let stepTemp = this.state.step;
    stepTemp.pop();
    this.setState({
      step: stepTemp
    });

    this.props.disableSubmit(true);

    this.hideErrorMessages();
  };

  hideErrorMessages = () => {
    this.setState({
      errors: []
    });
    this.props.hideErrors();
  };

  onChangeNameDefault = e => {
    var value = e.target.value.trim();

    // Check max length of full-size and half-size
    if (getTextLength(value) > MAX_LENGTH_OF_NAME) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState({ nameOfDrawing: value });
  };

  onChangeNameCustom = (e, index) => {
    let { drawingsName } = this.state;
    var value = e.target.value.trim();

    if (getTextLength(value) > MAX_LENGTH_OF_NAME + 4) {
      e.preventDefault();
      e.stopPropagation();
    }

    drawingsName[index] = value;

    this.setState(
      {
        drawingsName
      },
      this.props.setDrawingName(drawingsName)
    );
  };

  createDrawingsName = () => {
    const { firstStep, nameOfDrawing, floor } = this.state;
    let drawingsName = [];

    // Create list name of drawing
    [...Array(parseInt(this.props.maxPage)).keys()].map(e => {
      let floorName =
        parseInt(e) + parseInt(floor.min) <= parseInt(floor.max)
          ? `${parseInt(e) + parseInt(floor.min)}F`
          : "";
      switch (firstStep) {
        case STEP.EDIT_FLOOR:
          drawingsName = [...drawingsName, floorName];
          break;
        case STEP.EDIT_FLOOR_NAME:
          drawingsName = [
            ...drawingsName,
            floorName ? `${floorName}-${nameOfDrawing}` : ""
          ];
          break;
        case STEP.EDIT_NAME_FLOOR:
          drawingsName = [
            ...drawingsName,
            floorName ? `${nameOfDrawing}-${floorName}` : ""
          ];
          break;
        case STEP.EDIT_CUSTOM:
          drawingsName = [...drawingsName, ""];
          break;
        default:
          break;
      }
    });

    this.setState({ drawingsName });

    return drawingsName;
  };

  setPageValue = (value, isMin = true) => {
    let { floor } = this.state;
    const { maxPage } = this.props;

    if (isMin) {
      floor.min = value;
      floor.max =
        parseInt(value) > parseInt(floor.max) ||
        parseInt(value) + parseInt(maxPage) - 1 < parseInt(floor.max)
          ? parseInt(value) + parseInt(maxPage) - 1 <= MAX_FLOOR
            ? parseInt(value) + parseInt(maxPage) - 1
            : MAX_FLOOR
          : floor.max;
    } else {
      floor.max = value;
    }

    this.setState({ floor });
  };

  setPageValueOnInput = (e, isMin = true) => {
    const { floor } = this.state;
    const { maxPage } = this.props;
    let value = e.target.value;

    let listPage = [...Array(MAX_FLOOR).keys()].map(e => {
      return (e + 1).toString();
    });

    if (isMin) {
      if (listPage.includes(value)) {
        this.setPageValue(value, isMin);
      }
    } else {
      if (
        listPage
          .slice(
            parseInt(floor.min) - 1,
            parseInt(floor.min) + parseInt(this.props.maxPage) - 1 <= MAX_FLOOR
              ? parseInt(floor.min) + parseInt(this.props.maxPage) - 1
              : MAX_FLOOR
          )
          .includes(value)
      ) {
        this.setPageValue(value, isMin);
      }
    }
  };

  renderStep = () => {
    const { step } = this.state;
    switch (step[step.length - 1]) {
      // Option select type to setting drawings name
      case STEP.OPTION_EDIT:
        return (
          <Grid className="step-block">
            <Typography variant="body2" className="text-bold">
              図面名のルールを決める
            </Typography>
            <Button
              className="m-15 button-rule"
              onClick={() => this.nextStep(STEP.EDIT_FLOOR, true)}
            >
              階数のみ
              <br />
              例：11F
            </Button>
            <Button
              className="m-15 button-rule"
              onClick={() => this.nextStep(STEP.EDIT_FLOOR_NAME, true)}
            >
              階数を前に付ける
              <br />
              例：11F-A館
            </Button>
            <Button
              className="m-15 button-rule"
              onClick={() => this.nextStep(STEP.EDIT_NAME_FLOOR, true)}
            >
              階数を後に付ける
              <br />
              例：A館-11F
            </Button>
            <Button
              className="m-15 button-rule"
              onClick={() => this.nextStep(STEP.EDIT_CUSTOM, true)}
            >
              任意の図面名を付ける
            </Button>
          </Grid>
        );

      // Setting drawings name is floor number only
      case STEP.EDIT_FLOOR:
        return this.renderFromEditFloor();

      // Setting drawings name with floor number and name
      case STEP.EDIT_FLOOR_NAME:
        return this.renderFormEditName();

      // Setting drawings name with name and floor number
      case STEP.EDIT_NAME_FLOOR:
        return this.renderFormEditName();

      // Setting drawings name with custom type
      case STEP.EDIT_CUSTOM:
        return this.renderFormCustom();

      default:
        break;
    }
  };

  renderButtonGroup = () => {
    const { firstStep, step } = this.state;
    const lastStep = step[step.length - 1];
    let label1 = "";
    let label2 = "";

    switch (lastStep) {
      case STEP.EDIT_FLOOR:
        label1 = "階数を設定する";
        if (firstStep === STEP.EDIT_FLOOR) {
          label2 = "階数のみ";
        } else if (firstStep === STEP.EDIT_FLOOR_NAME) {
          label2 = "階数を前に付ける";
        } else if (firstStep === STEP.EDIT_NAME_FLOOR) {
          label2 = "階数を後に付ける";
        }
        break;
      case STEP.EDIT_FLOOR_NAME:
        label1 = "階数を前に付ける";
        label2 = "例：11F-A館";
        break;
      case STEP.EDIT_NAME_FLOOR:
        label1 = "階数を後に付ける";
        label2 = "例：A館-11F";
        break;
      case STEP.EDIT_CUSTOM:
        label1 = "図面名を入力する";
        label2 = "";
        break;
      default:
        break;
    }

    return (
      <Grid className="button-step-block">
        <Grid className="group-button">
          <Button className="step-button" onClick={this.backStep}>
            戻る
          </Button>
          {lastStep !== STEP.EDIT_CUSTOM && (
            <Button
              className="step-button"
              onClick={() =>
                this.nextStep(
                  lastStep === STEP.EDIT_FLOOR
                    ? STEP.EDIT_CUSTOM
                    : STEP.EDIT_FLOOR
                )
              }
            >
              次へ
            </Button>
          )}
        </Grid>
        <Grid className="group-note">
          <Typography variant="body2" className="text-bold">
            <span className="text-underline">{label1}</span>
            {label2 && <br />}
            {label2}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  renderFormEditName = () => {
    const { nameOfDrawing, firstStep, errors } = this.state;
    const name = nameOfDrawing !== "" ? nameOfDrawing : "”名前”";
    const lastStep = this.state.step[this.state.step.length - 1];
    return (
      <Grid className="step-block group-edit-name">
        {this.renderButtonGroup()}
        <Grid>
          <Typography variant="body2" className="text-bold">
            {firstStep === STEP.EDIT_FLOOR_NAME
              ? "図面名の後ろに付ける名前を入力してください"
              : "図面名の前に付ける名前を入力してください"}
          </Typography>
          <Typography variant="body2" className="text-bold">
            {lastStep === STEP.EDIT_FLOOR_NAME
              ? `参考：11F-${name}`
              : `参考：${name}-11F`}
          </Typography>
        </Grid>
        <Grid>
          <TextField
            className={`input-reset input-name`}
            variant="filled"
            label="名前"
            onChange={this.onChangeNameDefault}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 12 }}
            margin="normal"
            value={nameOfDrawing}
          />
          {errors["drawingName"] && (
            <span className="error">{errors["drawingName"]}</span>
          )}
        </Grid>
      </Grid>
    );
  };

  renderFromEditFloor = () => {
    const { floor, errors } = this.state;
    const { maxPage } = this.props;

    const maxValue = floor.min
      ? parseInt(floor.min) + parseInt(maxPage) - 1 <= MAX_FLOOR
        ? parseInt(floor.min) + parseInt(maxPage) - 1
        : MAX_FLOOR
      : 0;

    return (
      <Grid className="step-block group-edit-floor">
        {this.renderButtonGroup()}
        <Autocomplete
          className="page-value"
          inputProps={{ style: { textAlign: "center" } }}
          value={floor.min ? floor.min.toString() : ""}
          onChange={(event, newValue) => {
            this.setPageValue(newValue, true);
          }}
          options={[...Array(MAX_FLOOR).keys()].map(e => {
            return (e + 1).toString();
          })}
          noOptionsText={"無効"}
          disableClearable
          autoHighlight
          renderInput={params => (
            <TextField
              className={`input-reset`}
              {...params}
              InputLabelProps={{ shrink: true }}
              variant="filled"
              label="開始階"
              margin="normal"
              onChange={e => this.setPageValueOnInput(e, true)}
            />
          )}
        />

        <span className="m-5">ー</span>
        <Autocomplete
          className="page-value"
          value={floor.max.toString()}
          onChange={(event, newValue) => {
            this.setPageValue(newValue, false);
          }}
          options={[...Array(maxValue).keys()]
            .map(e => {
              return (e + 1).toString();
            })
            .slice(floor.min ? floor.min - 1 : 1, maxValue)}
          noOptionsText={"無効"}
          disableClearable
          autoHighlight
          renderInput={params => (
            <TextField
              className={`input-reset`}
              {...params}
              InputLabelProps={{ shrink: true }}
              variant="filled"
              label="終了階"
              margin="normal"
              onChange={e => this.setPageValueOnInput(e, false)}
            />
          )}
        />

        {errors["floorName"] && (
          <span className="error text-left p-10">{errors["floorName"]}</span>
        )}
      </Grid>
    );
  };

  renderFormCustom = () => {
    const { drawingsName, errors } = this.state;

    const renderErrorMessage = drawingName => {
      if (!drawingName) {
        return (
          <span className="error text-left pl-30 pb-10">
            {translateMessage(
              this.props.message.required,
              this.props.regexObject
            )}
          </span>
        );
      } else if (getTextLength(drawingName) > MAX_LENGTH_OF_NAME + 4) {
        return (
          <span className="error text-left pl-30 pb-10">
            {translateMessage(
              this.props.message.maximumCharacter,
              this.props.regexObject
            )}
          </span>
        );
      }
    };

    return (
      <Grid className="step-block group-confirm">
        {this.renderButtonGroup()}
        <Grid className="name-group">
          {drawingsName.map((drawingName, idx) => {
            return (
              <>
                <Grid class="name-item">
                  <Grid item className="drawing-name-index text-bold">
                    {idx + 1}
                  </Grid>
                  <Grid item className="drawing-name-text">
                    <TextField
                      className={`input-reset`}
                      variant="filled"
                      label="図面名"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 16 }}
                      margin="normal"
                      value={drawingName}
                      onChange={e => this.onChangeNameCustom(e, idx)}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  {this.props.isShowError && renderErrorMessage(drawingName)}
                </Grid>
              </>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  render() {
    return this.renderStep();
  }
}

CopyDrawingStep.propTypes = {
  maxPage: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // translateValidate,
      // messageValidate
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CopyDrawingStep));
