import React, { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Dialog from "@material-ui/core/Dialog";
import Drawer from "@material-ui/core/Drawer";
import { useMediaQuery } from "react-responsive";
import { Grid } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import IconClose from "../../../../common/img/close.png";
import Button from "@material-ui/core/Button";
import CloseButton from "./CloseButton";
import CheckBoxIcon from "./icons/CheckBoxIcon";
import NumberInfoIcon from "./icons/NumberInfoIcon";
import { makeStyles } from "@material-ui/core/styles";
import infoImg from "../../../../common/img/info.png";
import { Typography } from "@material-ui/core";

const mediaQueryTabletOrMobile = "(max-width: 960px)";

const useStyles = makeStyles({
  drawingBox: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "20px",
    marginLeft: "-12px",
    "& > span:first-child": {
      width: "40px",
      height: "40px",
      padding: "12px",
      cursor: "pointer"
    }
  },

  drawingRow: {
    width: "100%",
    height: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F8F9F9",
    padding: "0px 10px",
    cursor: "pointer",
    "& > div:first-child": {
      fontSize: "15px",
      fontWeight: "bold",
      lineHeight: "20px",
      color: "#333333",
      overflow: "hidden"
    },
    "& > span:last-child": {
      display: "flex",
      alignItems: "center"
    }
  },

  showMoreIcon: {
    fontSize: "24px",
    lineHeight: "24px",
    padding: "8px",
    width: "40px",
    height: "40px",
    color: "#C7C7C7",
    transition: "transform 0.3s",
    marginLeft: "6px"
  },

  listSpotBox: {
    width: "100%",
    padding: "4px 0px 4px 30px",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "4px",
    transition: "height 0.3s",
    overflow: "hidden"
  },

  spotRow: {
    display: "flex",
    alignItems: "center",
    paddingRight: "20px",
    cursor: "pointer"
  },

  spotRowName: {
    display: "flex",
    alignItems: "center",
    flexGrow: "1",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& span": {
      width: "40px",
      height: "40px",
      padding: "12px",
      marginLeft: "-12px"
    }
  },

  spotRowInfo: {
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
    lineHeight: "20px",
    color: "#00356D",
    fontWeight: "500",
    marginLeft: "10px",
    "& > span:first-child": {
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      overflow: "hidden",
      backgroundColor: "#00356D",
      marginRight: "12px"
    }
  },

  formTitle: {
    fontSize: "20px",
    lineHeight: "26px",
    fontWeight: "bold",
    color: "#333333",
    padding: "20px"
  },

  formAction: {
    width: "100%",
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    "& button": {
      width: "124px",
      height: "100%",
      fontSize: "15px",
      fontWeight: "500",
      lineHeight: "23px",
      padding: "6px",
      textAlign: "center",
      borderRadius: "4px",
      color: "#FFFFFF",
      margin: "0px 10px",
      letterSpacing: "0"
    }
  },
  paper: {
    maxHeight: "90vh",
    padding: "20px 0px"
  },
  customScrollbar: {
    "&::-webkit-scrollbar": {
      backgroundColor: "#fff",
      width: "16px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#babac0",
      borderRadius: "16px",
      border: "4px solid #fff"
    },
    "&::-webkit-scrollbar-button": {
      display: "none"
    }
  },
  truncateText: {
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "wrap"
  }
});

function SelectDrawingPointField(props) {
  const { drawing, selectedSpots, selectSpot, selectDrawing } = props;
  const [showListSpot, setShowListSpot] = useState(false);
  const classes = useStyles();

  const getNumberOfAllHotspot = () => {
    if (drawing.spots.length > 0) {
      let allHotspot = 0;
      for (let i = 0; i < drawing.spots.length; i++) {
        allHotspot += drawing.spots[i].hotspotCount;
      } 
      return allHotspot;
    }
    return 0;
  };

  const checkSpotIsSelected = spot => {
    return selectedSpots.find(
      selectedSpot => selectedSpot.uniqueCode === spot.uniqueCode
    );
  };

  const checkDrawingIsSelected = () => {
    return drawing.spots.every(spot =>
      selectedSpots.find(
        selectedSpot => selectedSpot.uniqueCode === spot.uniqueCode
      )
    );
  };

  return (
    <div style={{ padding: "0px 20px" }}>
      <div className={classes.drawingBox}>
        <span onClick={() => selectDrawing(drawing)}>
          {drawing.spots.length > 0 && (
            <CheckBoxIcon check={checkDrawingIsSelected()} />
          )}
        </span>
        <div style={{ flexGrow: "1", width: "calc(100% - 40px)" }}>
          <div
            className={classes.drawingRow}
            onClick={() => selectDrawing(drawing)}
          >
            <div
              style={{
                overflow: "hidden"
              }}
            >
              <Typography
                variant="h6"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              >
                {drawing.name}
              </Typography>
            </div>
            <span
              style={{
                fontWeight: "normal",
                marginLeft: "10px",
                flexShrink: "0",
                flexGrow: "1",
                marginRight: "10px"
              }}
            >
              {`(撮影地点：${drawing.spots.length})`}
            </span>
            <span>
              <span style={{ display: "flex", justifyContent: "center" }}>
                <NumberInfoIcon />
              </span>
              <span
                style={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  color: "#00356D"
                }}
              >
                {getNumberOfAllHotspot()}
              </span>
              <span
                className={classes.showMoreIcon}
                style={{
                  transform: showListSpot ? "rotate(180deg)" : "rotate(0deg)"
                }}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  setShowListSpot(!showListSpot);
                }}
              >
                <ArrowDropDownIcon fontSize="inherit" color="inherit" />
              </span>
            </span>
          </div>

          {drawing.spots.length > 0 && (
            <div
              className={classes.listSpotBox}
              style={{
                height: showListSpot
                  ? `${drawing.spots.length * 41 + 8}px`
                  : "0px",
                padding: showListSpot ? "4px 0px 4px 30px" : "0px 0px 0px 30px"
              }}
            >
              {drawing.spots.map((spot, index) => (
                <div
                  className={classes.spotRow}
                  style={{
                    borderTop: index !== 0 ? "1px solid #C7C7C7" : "none"
                  }}
                  onClick={() => selectSpot(spot)}
                >
                  <div className={classes.spotRowName}>
                    <span>
                      <CheckBoxIcon check={checkSpotIsSelected(spot)} />
                    </span>
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                      }}
                    >
                      <span>{spot.name}</span>
                    </div>
                  </div>

                  <div className={classes.spotRowInfo}>
                    <span>
                      <img
                        src={infoImg}
                        alt="info"
                        width="20px"
                        height="20px"
                      />
                    </span>

                    <span>
                      {`${spot.hotspotCount}/${getNumberOfAllHotspot()}`}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function SelectDrawingPointForm(props) {
  const {
    drawings,
    selectedSpots,
    selectSpot,
    selectDrawing,
    handleClose,
    selectAllSpot
  } = props;
  const classes = useStyles();

  const countAllSpot = () => {
    let allSpots = 0;
    drawings.forEach(e => {
      allSpots += e.spots.length;
    });
    return allSpots;
  };

  return (
    <>
      <h3 className={classes.formTitle}>図面と地点を選択</h3>
      {drawings.length !== 0 && (
        <div
          style={{ flexGrow: "1", overflowY: "auto" }}
          className={classes.customScrollbar}
        >
          {drawings.map(drawing => (
            <SelectDrawingPointField
              drawing={drawing}
              selectedSpots={selectedSpots}
              selectSpot={selectSpot}
              selectDrawing={selectDrawing}
            />
          ))}
        </div>
      )}
      <div className={classes.formAction}>
        <button
          style={{
            backgroundColor: "#00346C"
          }}
          onClick={handleClose}
        >
          OK
        </button>

        <button
          style={{
            backgroundColor: "#C7C7C7"
          }}
          onClick={selectAllSpot}
        >
          {selectedSpots.length < countAllSpot()
            ? "すべてチェック"
            : "すべてクリア"}
        </button>
      </div>
    </>
  );
}

function SelectDrawingAndPoint(props) {
  const {
    drawings,
    selectedSpots,
    selectSpot,
    selectDrawing,
    open,
    handleClose,
    selectAllSpot
  } = props;
  const isTabletOrMobile = useMediaQuery({ query: mediaQueryTabletOrMobile });
  const classes = useStyles();
  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <Drawer anchor="bottom" open={open} onClose={handleClose}>
          <React.Fragment>
            <div
              style={{
                width: "100%",
                padding: "32px 0px",
                height: "86vh",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <SelectDrawingPointForm
                drawings={drawings}
                selectedSpots={selectedSpots}
                selectSpot={selectSpot}
                selectDrawing={selectDrawing}
                handleClose={handleClose}
                selectAllSpot={selectAllSpot}
              />
            </div>

            <CloseButton handleClose={handleClose} />
          </React.Fragment>
        </Drawer>
      ) : (
        <Dialog
          onClose={handleClose}
          open={open}
          maxWidth="sm"
          fullWidth={true}
          classes={{
            paper: classes.paper
          }}
        >
          <React.Fragment>
            <SelectDrawingPointForm
              drawings={drawings}
              selectedSpots={selectedSpots}
              selectSpot={selectSpot}
              selectDrawing={selectDrawing}
              handleClose={handleClose}
              selectAllSpot={selectAllSpot}
            />
            <CloseButton handleClose={handleClose} />
          </React.Fragment>
        </Dialog>
      )}
    </React.Fragment>
  );
}

export default SelectDrawingAndPoint;
