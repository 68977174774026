import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

function SearchField(props) {
  const { value, setValue, placeholder } = props;
  return (
    <div
      style={{
        width: "100%",
        // maxWidth: "300px",
        minWidth: "120px",
        position: "relative",
        display: "flex",
        height: "40px"
      }}
    >
      <div
        style={{
          width: "40px",
          height: "40px",
          border: "none",
          position: "absolute",
          left: "0",
          fontSize: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#989898"
        }}
      >
        <SearchIcon fontSize="inherit" color="inherit" />
      </div>
      <input
        type="text"
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder={placeholder || ""}
        style={{
          width: "100%",
          border: "1px solid #787878",
          height: "40px",
          boxSizing: "border-box",
          padding: "4px 40px",
          borderRadius: "20px",
          backgroundColor: "white",
          boxShadow: "none"
        }}
      />
      <button
        style={{
          width: "40px",
          height: "40px",
          border: "none",
          position: "absolute",
          right: "0",
          fontSize: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#989898"
        }}
        onClick={() => setValue("")}
      >
        <ClearOutlinedIcon fontSize="inherit" color="inherit" />
      </button>
    </div>
  );
}

export default SearchField;
