import React from "react";

function ArrowBack() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <defs>
        <filter
          id="a"
          width="48"
          height="48"
          x="0"
          y="0"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3"></feOffset>
          <feGaussianBlur result="b" stdDeviation="3"></feGaussianBlur>
          <feFlood floodOpacity="0.161"></feFlood>
          <feComposite in2="b" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
      </defs>
      <g filter="url(#a)" transform="translate(9 6) translate(-9 -6)">
        <path fill="none" d="M0 0H30V30H0z" transform="translate(9 6)"></path>
      </g>
      <path
        fill="#333"
        stroke="#333"
        d="M.534 11.272l6.445 6.445a.76.76 0 101.074-1.074l-5.026-5.026 17.495.001a.76.76 0 10-.004-1.521l-17.733.002 5.267-5.267a.76.76 0 10-1.074-1.074L.536 10.2a.759.759 0 00-.002 1.072z"
        transform="translate(9 6) translate(4.446 3.713)"
      ></path>
    </svg>
  );
}

export default ArrowBack;
