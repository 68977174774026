import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUserLogBuild } from "./service/service";
import classnames from "classnames";
import InputControl from "../../common/InputControl";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Formik, Field } from "formik";

import Validator from "validator";
import LoginUtilityLogBuild from "./LoginUtilityLogBuild";

import "../../common/css/base.css";
import "../../common/css/auth.css";
import { translateMessage } from "utils/mapper";
import {
  translateValidate,
  messageValidate
} from "../../common/Stores/service/service";

class LoginLogBuild extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      onSubmit: "",
      nextButtonClick: false,
      isPasswordShow: false
    };
    this.loginUtilityRef = React.createRef();
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/logbuild/dashboard");
      return true;
    }
    this.props.translateValidate();
    this.props.messageValidate();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/logbuild/dashboard"); // push user to dashboard when they login
    }

    //displaying errors
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    e.preventDefault();
    this.setState({ [e.target.id]: e.target.value });
  };

  isAutoLoginChecked() {
    const loginUtility = this.loginUtilityRef.current;
    const checkboxes = loginUtility.checkboxesRef.current;
    return checkboxes.state.checked;
  }

  onSubmit = values => {
    const userData = {
      id: values.email,
      password: values.password
    };

    const autoLogin = this.isAutoLoginChecked();

    this.props.loginUserLogBuild(userData, autoLogin);
  };

  isEmptyEmail(email) {
    return Validator.isEmpty(email);
  }

  isEmailFormat(email) {
    return Validator.isEmail(email);
  }

  validateEmail(email) {
    const key = "email";
    const { message } = this.props;
    const errors = this.state.errors;
    if (this.isEmptyEmail(email))
      errors[key] = translateMessage(
        this.getMessage("required", message),
        this.props.regexObject
      );
    else if (!this.isEmailFormat(email))
      errors[key] = translateMessage(
        this.getMessage("email", message),
        this.props.regexObject
      );
    else delete errors[key];
    this.setState({ errors: errors });
    return !(key in this.state.errors);
  }

  getMessage = (key, message) => {
    return message[key];
  };

  checktButtonClick = formik => e => {
    e.preventDefault();
    const { values } = formik;
    if (!this.validateEmail(values.email)) return;

    if (this.state.nextButtonClick) {
      formik.handleSubmit();
    } else {
      this.setState({ nextButtonClick: true });
    }
  };

  render() {
    const {
      errors,
      email,
      password,
      nextButtonClick,
      isPasswordShow
    } = this.state;
    // if(Component) return <Component />;
    const values = {
      email: email,
      password: password
    };

    const showPassword = () => {
      this.setState({
        isPasswordShow: !this.state.isPasswordShow
      });
    };

    return (
      <Formik
        initialValues={values}
        enableReinitialize
        onSubmit={values => this.onSubmit(values)}
        render={formik => (
          <form onSubmit={this.checktButtonClick(formik)}>
            <Grid className="area-login-wrap">
              <Grid container justify="space-between">
                <Grid className="area-logo" />
                <Grid className="area-login">
                  <Grid>
                    <h4 className="ttl-login">ログイン</h4>
                    <p className="txt-login-sub">
                      logbuildにログインしてください
                    </p>
                  </Grid>
                  <Grid className="wrap-form">
                    <Grid>
                      <Field
                        label="メールアドレス"
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        variant="filled"
                        className="input-login"
                        component={InputControl}
                      />
                      <span className="red-text">
                        {errors.email}
                        {errors.emailnotfound}
                      </span>
                    </Grid>

                    {nextButtonClick && (
                      <Grid className="relative">
                        <Field
                          label="パスワード"
                          name="password"
                          type={isPasswordShow ? "text" : "password"}
                          onChange={formik.handleChange}
                          variant="filled"
                          // className="input-login"
                          className={classnames("input-login pdr-50", {
                            invalid: errors.password || errors.passwordincorrect
                          })}
                          component={InputControl}
                        />
                        <IconButton
                          color="primary"
                          aria-label="copy"
                          className="show-password"
                          onClick={showPassword}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <span className="red-text">
                          {errors.password}
                          {errors.passwordincorrect}
                        </span>
                      </Grid>
                    )}
                    {nextButtonClick && (
                      <LoginUtilityLogBuild ref={this.loginUtilityRef} />
                    )}

                    <Grid container justify="center">
                      <button
                        type="submit"
                        className="btn-login"
                        onClick={this.checktButtonClick(formik)}
                      >
                        {nextButtonClick ? "ログイン" : "次へ"}
                      </button>
                      <span className="red-text">{errors.error}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}
//export default Login;

LoginLogBuild.propTypes = {
  loginUserLogBuild: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});
export default connect(mapStateToProps, {
  loginUserLogBuild,
  translateValidate,
  messageValidate
})(LoginLogBuild);
