import React from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Component from "./Common";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getSiteInfo, getDrawingsWithSpots } from "./reducer/reducer";
import { fetchDrawingFile, sortDrawingImage } from "./service/service";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import DrawingPhoto from "./DrawingPhoto";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CloseIcon from "@material-ui/icons/Close";
import ShowMenu from "../../../common/img/ShowMenu.svg";
import CompassIconSeleted from "../../../common/img/compassbtniconseleted.svg";
import CompassView from "./compass/CompassView";
import CompassViewSP from "./compass/CompassViewSP";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  translateValidate,
  messageValidate
} from "../../../common/Stores/service/service";
import MapDialog from "./MapDialog";
import PinSpotHelp from "../../../common/img/PinSpotHelp.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CommonMapOptionDialogSP from "./CommonMapOptionDialogSP";
import Slide from "@material-ui/core/Slide";
import CompassSettingSP from "./compass/CompassSettingSP";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { getRedirectFromUrl } from "./reducer/reducer";
import { setRedirectFromUrl } from "./action/action";
import ChooseLogWalkModeButton from "./Maps/ChooseLogWalkModeButton";
import IconFullImage from "../../../common/img/fullimage.svg";
import ViewTask from "./Maps/ViewTask";
import SearchIcon from "@material-ui/icons/Search";
import querySearch from "stringquery";
import { isMobile } from "react-device-detect";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const maximumDrawing = 50;
const maxNameLength = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle
});
const styles = {
  largeIcon: {
    width: 60,
    height: 60
  }
};

class MapsBuilderSP extends Component {
  touchTimeout = null;
  unTouchTimeout = null;
  cancelSubmission = false;
  scrollData = {
    rangeMove: 0,
    startValue: 0
  };

  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.wrapperRef = React.createRef();
    this.messageRef = React.createRef();
    this.drawingBlockRef = React.createRef();
    this.drawingRef = React.createRef();
    this.imgRef = React.createRef();
  }

  state = {
    positions: [],
    drawingsWithSpots: this.props.drawingsWithSpots,
    isMarkMap: false,
    currentDrawing: 0,
    isShowDialogMap: false,
    isCheckedHelp: false,
    isShowDialogPinSpotHelp: false,
    showHideBtnPrev: false,
    showHideBtnNext: false,
    isShowAddPointDialog: false,
    countFetching: 0,
    fetchDone: false,
    firstTouchOneFinger: true,
    noteText: false,
    touchesDefault: [],
    prevScale: {
      pageX: 0,
      pageY: 0,
      scale: 1
    },
    isShowDialogOption: false,
    isCompassSetting: false,
    isShowMenuButton: true,
    sitesUC: "",
    isSavingCompassSetting: false,
    selectedDrawing: [],
    currentselectedDrawingIndex: 0,
    isClickToDrawing: false,
    openFilterInfoBox: false,
    openDrawingBox: false,
    showLinkInfos: false
  };

  onUpdateTransform = ({ x, y, scale }) => {
    if (this.imgRef.current) {
      const value = make3dTransformValue({ x, y, scale });

      this.imgRef.current.style.setProperty("transform", value);
    }
  };

  componentWillReceiveProps(props) {
    if (props.drawingsWithSpots === this.state.drawingsWithSpots) return;
    this.setState(
      {
        drawingsWithSpots: this.props.drawingsWithSpots,
        sitesUC: this.props.match.params.id
      },
      () => {
        this.showHideButtonNextPrev();
      }
    );
    if (this.props.isShowOptionButton) {
      if (!this.state.isCompassSetting) {
        this.setState({ isShowMenuButton: true });
      }
    }
    this.setPositionMarker();
  }

  componentWillMount() {
    let viewHeight = window.innerHeight * 0.01;
    document.documentElement.style.setProperty(
      "--view-height",
      `${viewHeight}px`
    );

    this.setState({
      sitesUC: this.props.match.params.id
    });
  }
  componentDidMount() {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let viewHeight = window.innerHeight * 0.01;
      document.documentElement.style.setProperty(
        "--view-height",
        `${viewHeight}px`
      );
      this.updateDimensions();
    });
    document.addEventListener("click", this.handleDrawingClick);
    document.addEventListener("gesturestart", e => {
      e.preventDefault();
    });

    const paramsSearch = querySearch(this.props.location.search);
    const keys = Object.keys(paramsSearch);
    if (keys.includes("info")) {
      this.props.setIsViewVRSpace(false);
    }
    this.setCurrentDrawing();
  }

  setCurrentDrawing = () => {
    if (
      this.props.redirectFromUrl.isRedirectFromUrl &&
      this.props.redirectFromUrl.currentDrawingUC
    ) {
      const currentDrawing = this.state.drawingsWithSpots.findIndex(
        item => item.uniqueCode === this.props.redirectFromUrl.currentDrawingUC
      );

      this.setState(
        {
          currentDrawing: currentDrawing === -1 ? 0 : currentDrawing
        },
        () => {
          const container = document.querySelector(".grid-list-sp");
          const currentPosition = 196 * (this.state.currentDrawing + 1);

          if (container && container.clientWidth < currentPosition)
            container.scrollTo(currentPosition - container.clientWidth, 0);
        }
      );
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    document.removeEventListener("click", this.handleDrawingClick);
    document.removeEventListener("gesturestart", e => {
      e.preventDefault();
    });
    this.cancelSubmission = true;
  }
  
  fetchDone = () => {
    this.setState(
      {
        countFetching: this.state.countFetching + 1
      },
      () => {
        if (this.state.countFetching === this.props.drawingsWithSpots.length) {
          this.setState({ fetchDone: true });
        }
      }
    );
  };

  reorder = (list, startIndex, endIndex) => {
    let drawingsList = list;

    const [removed] = drawingsList.splice(startIndex, 1);

    drawingsList.splice(endIndex, 0, removed);

    this.setState({ drawingsWithSpots: drawingsList });

    let result = [];

    drawingsList.forEach(item => {
      result.push(item.uniqueCode);
    });

    return result;
  };

  onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (
      !destination ||
      (source.droppableId === destination.droppableId &&
        source.index === destination.index)
    ) {
      return;
    }

    let uniqueCodes = [];
    if (source.droppableId === destination.droppableId) {
      uniqueCodes = this.reorder(
        this.state.drawingsWithSpots,
        result.source.index,
        result.destination.index
      );
    }
    let siteUC = this.props.match.params.id;
    this.props.sortDrawingImage(siteUC, uniqueCodes);
  }

  updateDimensions = e => {
    this.setPositionMarker();
    this.setPositionMarkerCompass();
  };
  setPositionMarker() {
    let { drawingsWithSpots } = this.props;
    let { currentDrawing } = this.state;

    if (drawingsWithSpots != undefined && drawingsWithSpots.length > 0) {
      // If current drawing has been deleted, set current drawing to first
      if (!drawingsWithSpots[currentDrawing]) {
        this.setState({ currentDrawing: 0 });
        return;
      }

      // Drawing preview
      let elementPrimary = document.getElementById("primary-image-sp");
      if (elementPrimary) {
        let naturalWidthPrimary = elementPrimary
          ? elementPrimary.naturalWidth
          : 0;

        let naturalHeightPrimary = elementPrimary
          ? elementPrimary.naturalHeight
          : 0;

        const elMap = document.getElementById("primary-map-sp");
        let scaleMap = elMap ? elMap.offsetWidth / elMap.offsetHeight : 0;
        let isVerticalImg =
          naturalHeightPrimary * scaleMap > naturalWidthPrimary;
        let offsetWidthPrimary = 0;
        let offsetHeightPrimary = 0;
        if (isVerticalImg) {
          offsetWidthPrimary =
            (elMap.offsetHeight * naturalWidthPrimary) / naturalHeightPrimary;

          offsetHeightPrimary = elMap.offsetHeight;
        } else {
          offsetWidthPrimary = elMap.offsetWidth;

          offsetHeightPrimary =
            (elMap.offsetWidth * naturalHeightPrimary) / naturalWidthPrimary;
        }

        let offsetLeftPrimary = (elMap.offsetWidth - offsetWidthPrimary) / 2;

        let offsetTopPrimary = (elMap.offsetHeight - offsetHeightPrimary) / 2;

        let posAfterPrimary = [];
        drawingsWithSpots[currentDrawing].spots.map(pos => {
          posAfterPrimary.push({
            uniqueCode: pos.uniqueCode,
            name: pos.name,
            posX:
              (pos.x * offsetWidthPrimary) / naturalWidthPrimary +
              offsetLeftPrimary,
            posY:
              (pos.y * offsetHeightPrimary) / naturalHeightPrimary +
              offsetTopPrimary,
            hasShoot: pos.hasShoot
          });
        });

        this.setState({
          currentDrawingWithSpots: {
            isVerticalImg: isVerticalImg,
            positions: posAfterPrimary
          }
        });

        let itemsAfter = [];
        let tempDrawingsWithSpots = drawingsWithSpots;

        drawingsWithSpots.map((item, idx_item) => {
          let posAfter = [];
          item.spots.map(pos => {
            let element =
              document.getElementsByClassName("sub-image")[idx_item];
            let offsetWidth = element ? element.offsetWidth : 0;

            let offsetHeight = element ? element.offsetHeight : 0;

            let naturalWidth = element ? element.naturalWidth : 0;

            let naturalHeight = element ? element.naturalHeight : 0;

            let offsetLeft = element ? element.offsetLeft : 0;

            let offsetTop = element ? element.offsetTop : 0;

            tempDrawingsWithSpots[idx_item].isVerticalImg =
              naturalHeight > naturalWidth;

            posAfter.push({
              uniqueCode: pos.uniqueCode,
              name: pos.name,
              posX: (pos.x * offsetWidth) / naturalWidth + offsetLeft + 2,
              posY: (pos.y * offsetHeight) / naturalHeight + offsetTop + 2,
              hasShoot: pos.hasShoot
            });
          });

          itemsAfter.push(posAfter);
        });
        this.setState({
          positions: itemsAfter,
          drawingsWithSpots: tempDrawingsWithSpots
        });
      }
    }
  }
  setPositionMarkerCompass() {
    if(!this.cancelSubmission){
      const { currentselectedDrawingIndex, drawingsWithSpots } = this.state;

      if (drawingsWithSpots?.length > 0) {
        let positionAfter = [];
        let posAfter = [];
  
        // Primary image
        drawingsWithSpots[currentselectedDrawingIndex].spots.map(pos => {
          let element = document.getElementsByClassName(
            "sub-image-compass-primary"
          )[0];
          let offsetWidth = element ? element.offsetWidth : 0;
  
          let offsetHeight = element ? element.offsetHeight : 0;
  
          let naturalWidth = element ? element.naturalWidth : 0;
  
          let naturalHeight = element ? element.naturalHeight : 0;
  
          let offsetLeft = element ? element.offsetLeft : 0;
  
          let offsetTop = element ? element.offsetTop : 0;
  
          positionAfter.push({
            uniqueCode: pos.uniqueCode,
            name: pos.name,
            posX: (pos.x * offsetWidth) / naturalWidth + offsetLeft,
            posY: (pos.y * offsetHeight) / naturalHeight + offsetTop,
            hasShoot: pos.hasShoot
          });
        });
  
        this.setState({
          positionsCompass: positionAfter
        });
      }
    }    
  }

  markMap = e => {
    this.setState({
      isMarkMap: !this.state.isMarkMap
    });
  };

  showMapDialog = drawingUC => {
    this.markMap();
    this.props.showMapDialog(drawingUC);
  };

  loadMarker = e => {
    setTimeout(() => {
      this.setPositionMarker();
    }, 10);
  };
  loadMarkerCompass = e => {
    setTimeout(() => {
      this.setPositionMarkerCompass();
    }, 10);
  };

  changeDrawing = offset => {
    this.setState({ currentDrawing: this.state.currentDrawing + offset });
  };

  previousDrawing = () => {
    this.changeDrawing(-1);
  };

  nextDrawing = () => {
    this.changeDrawing(1);
  };

  selectDrawing = selectMapDrawing => {
    this.setState({ currentDrawing: selectMapDrawing });
  };

  scroll = (e, isNext) => {
    // if (e.deltaY < 0) {
    //   if (this.state.currentDrawing > 0) this.previousDrawing();
    // } else {
    //   if (this.state.currentDrawing < this.state.drawingsWithSpots.length - 1)
    //     this.nextDrawing();
    // }
    let delta = 1;
    if (isNext) {
      delta = -1;
    }

    const el = document.getElementsByClassName("grid-list-sp")[0];
    if (el)
      document.getElementsByClassName("grid-list-sp")[0].scrollLeft -=
        delta * 150; // Multiplied by 150
    this.showHideButtonNextPrev();
  };

  showHideButtonNextPrev = () => {
    let hideBtnPrev = false;
    let hideBtnNext = false;
    const el = document.getElementsByClassName("grid-list-sp")[0];
    if (el && el.scrollLeft > 0) {
      hideBtnPrev = true;
    } else {
      hideBtnPrev = false;
    }

    if (el && el.scrollLeft < el.scrollWidth - el.clientWidth - 10) {
      hideBtnNext = true;
    } else {
      hideBtnNext = false;
    }
    this.setState({
      showHideBtnPrev: hideBtnPrev,
      showHideBtnNext: hideBtnNext
    });
  };

  showDialogMap = () => {
    if (!localStorage.getItem("hideHelp") && isMobile) {
      this.setState({ isShowDialogPinSpotHelp: true });
    } else {
      this.setState({
        isShowDialogMap: true,
        showLinkInfos: false
      });
    }
    this.setState({
      isShowAddPointDialog: false
    });

    this.props.showHideDialogMap(true);
  };

  showDialogLinkMap = () => {
    this.setState({
      isShowDialogMap: true,
      showLinkInfos: true,
      isShowAddPointDialog: false
    });

    this.props.showHideDialogMap(true);
  };

  hideDialogMap = () => {
    this.setState({
      isShowDialogMap: false
    });
    this.props.showHideDialogMap(true);
  };

  handleCheckHelp = () => {
    this.setState({
      isCheckedHelp: !this.state.isCheckedHelp
    });
  };

  hideDialogPinSpotHelp = () => {
    if (this.state.isCheckedHelp) {
      localStorage.setItem("hideHelp", "true");
      this.setState({
        isCheckedHelp: !this.state.isCheckedHelp
      });
    }

    this.setState({
      isShowDialogPinSpotHelp: false,
      isShowDialogMap: true
    });
  };

  toggleAddPointDialog = () => {
    this.setState({ isShowAddPointDialog: !this.state.isShowAddPointDialog });
  };

  fetch() {}

  returnDefaultSize = () => {
    let currentRef = this.wrapperRef.current;
    if (currentRef != null) {
      currentRef.scaleTo({ x: 0, y: 0, scale: 1 });
    }
  };

  onTouchStart = e => {
    const scrollData = {
      rangeMove: e.touches[0].clientY,
      startValue: document.querySelector(".image-content").scrollTop
    };

    if (this.touchTimeout) {
      clearTimeout(this.touchTimeout);
    }

    if (this.unTouchTimeout) {
      clearTimeout(this.unTouchTimeout);
    }

    if (e.targetTouches.length === 2) {
      this.setState({
        touchesDefault: e.targetTouches,
        noteText: false
      });
    } else {
      if (
        !this.state.noteText &&
        !this.state.isCompassSetting &&
        !this.state.isDraggingCompass
      ) {
        this.touchTimeout = setTimeout(() => {
          this.setState({
            noteText: true,
            touchesDefault: []
          });

          this.scrollData = scrollData;
        }, 0);
      }
    }
  };

  onTouchMove = e => {
    if (!e.currentTarget.classList.contains("noteText")) {
      document
        .querySelector(".image-content")
        .scrollTo(
          0,
          this.scrollData.startValue -
            (e.touches[0].clientY - this.scrollData.rangeMove)
        );
    }

    e.stopPropagation();
  };

  onTouchEnd = () => {
    if (this.unTouchTimeout) {
      clearTimeout(this.unTouchTimeout);
    }
    this.unTouchTimeout = setTimeout(() => {
      this.setState({
        noteText: false
      });
    }, 1000);
  };

  handleSavingCompass = savingStatus => {
    this.setState({
      selectedDrawing: [],
      currentselectedDrawingIndex: this.state.currentDrawing
    });

    this.setState({
      isSavingCompassSetting: savingStatus
    });
    this.disableHeaderSP(savingStatus);
    // if (savingStatus) {
    //   document.getElementById("list_drawings").scrollIntoView();
    // }
    // else {
    //   document.getElementById("draggable-primary").scrollIntoView();
    // }
  };
  handleCompassDrag = dragStatus => {
    this.setState({
      isDraggingCompass: dragStatus
    });
  };
  handleSavingCompassCheckAll = () => {
    let array = [];
    this.state.drawingsWithSpots.forEach(item => array.push(item.uniqueCode));
    this.setState({ selectedDrawing: array });
  };
  handleSavingCompassDirection = savingDirection => {
    this.setState({
      savingDirection: savingDirection
    });
  };
  handleDrawingClick = event => {
    if (this.drawingRef.current) {
      const { target } = event;
      this.setState({
        isClickToDrawing: this.drawingRef.current.contains(target)
      });
    }
  };
  toggleDialogOption = () => {
    this.setState({
      isShowDialogOption: !this.state.isShowDialogOption
    });
  };
  toggleCompassSetting = () => {
    this.setState({
      isShowDialogOption: false,
      noteText: this.state.isCompassSetting,
      isCompassSetting: !this.state.isCompassSetting,
      isShowMenuButton: !this.state.isShowMenuButton
    });
    this.props.toggleOptionButton();
  };
  PreCurrentCompassDrawing = () => {
    if (
      this.state.drawingsWithSpots &&
      this.state.currentselectedDrawingIndex > 0
    ) {
      this.setState({
        currentselectedDrawingIndex: this.state.currentselectedDrawingIndex - 1
      });
    }
  };
  NextCurrentCompassDrawing = () => {
    if (
      this.state.drawingsWithSpots &&
      this.state.currentselectedDrawingIndex <
        this.state.drawingsWithSpots.length - 1
    ) {
      this.setState({
        currentselectedDrawingIndex: this.state.currentselectedDrawingIndex + 1
      });
    }
  };
  handleChangeListDrawing = DrawingUC => {
    let array = [...this.state.selectedDrawing];
    var indexofDrawingUC = array.indexOf(DrawingUC);
    if (indexofDrawingUC !== -1) {
      array.splice(indexofDrawingUC, 1);
      this.setState({ selectedDrawing: array });
    } else {
      this.setState({
        selectedDrawing: [...this.state.selectedDrawing, DrawingUC]
      });
    }
  };
  disableHeaderSP = value => {
    if (value) {
      var element = document.createElement("div");
      element.className = "fixed-box-header-sp";
      document.getElementById("header-builder-sp").appendChild(element);
    } else {
      var element = document.getElementsByClassName("fixed-box-header-sp")[0];
      if (element) {
        element.parentNode.removeChild(element);
      }
    }
  };

  render() {
    const {
      positions,
      drawingsWithSpots,
      isMarkMap,
      currentDrawing,
      currentDrawingWithSpots,
      isShowDialogMap,
      isCheckedHelp,
      isShowDialogPinSpotHelp,
      showHideBtnPrev,
      showHideBtnNext,
      isShowAddPointDialog,
      fetchDone,
      noteText,
      isShowDialogOption,
      isCompassSetting,
      isShowMenuButton,
      sitesUC,
      selectedDrawing,
      isClickToDrawing,
      isSavingCompassSetting,
      currentselectedDrawingIndex,
      positionsCompass,
      savingDirection,
      openFilterInfoBox,
      openDrawingBox,
      showLinkInfos
    } = this.state;
    const {
      setIsViewVRSpace,
      isViewVRSpace,
      hasWalkThroughFeature,
      showConfirmDeleteLink,
      showConfirmCreateLink,
      toggleConfirmCreateLink,
      toggleConfirmDeleteLink
    } = this.props;
    const toggleAddDrawingDialog = () => {
      if (drawingsWithSpots.length < maximumDrawing) {
        this.setState({
          isShowDialogOption: !this.state.isShowDialogOption
        });
        this.props.toggleAddDrawingDialog();
      }
    };
    const toggleAddPointDialog = () => {
      this.setState({
        isShowDialogOption: !this.state.isShowDialogOption
      });
      // if (
      //   drawingsWithSpots[currentDrawing] &&
      //   drawingsWithSpots[currentDrawing].spots.length > 0
      // ) {
      this.toggleAddPointDialog();
      // } else {
      //   this.showDialogMap();
      // }
    };
    const setFilterInfoBox = isOpenFilterInfoBox => {
      this.setState({
        openFilterInfoBox: isOpenFilterInfoBox
      });
    };

    const setOpenDrawingBox = isOpenDrawingBox => {
      this.setState({
        openDrawingBox: isOpenDrawingBox
      });
    };

    return (
      drawingsWithSpots !== undefined && (
        <React.Fragment>
          {drawingsWithSpots.some(drawing =>
            drawing.spots.some(spot => spot.hasShoot)
          ) &&
            !isCompassSetting && (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                justify="space-between"
                alignItems="center"
                style={{ marginBottom: "20px" }}
              >
                <Grid item>
                  <ChooseLogWalkModeButton
                    setIsViewVRSpace={setIsViewVRSpace}
                    isViewVRSpace={isViewVRSpace}
                    isSP
                  />
                </Grid>
                <Grid item>
                  {!isViewVRSpace && (
                    <Button
                      style={{
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        fontSize: "17px"
                      }}
                      onClick={() => setOpenDrawingBox(true)}
                    >
                      <img
                        src={IconFullImage}
                        alt=""
                        style={{ width: "28px", height: "28px" }}
                      />
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          marginLeft: "4px",
                          fontFamily: "roboto"
                        }}
                      >
                        図面から検索
                      </span>
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}

          {!isViewVRSpace ? (
            <ViewTask
              sitesUC={sitesUC}
              isSP
              openFilterInfoBox={openFilterInfoBox}
              setOpenFilterInfoBox={setFilterInfoBox}
              openDrawingBox={openDrawingBox}
              setOpenDrawingBox={setOpenDrawingBox}
              drawingsWithSpots={drawingsWithSpots}
            />
          ) : (
            <Grid className="relative-box-sp">
              {isCompassSetting && (
                <Grid
                  container
                  className="add-image option-btn-container-sp"
                  style={{ height: "40px" }}
                  alignItems="center"
                >
                  <Button
                    size="small"
                    variant="text"
                    className={`button-add add-point-enable`}
                    disabled={drawingsWithSpots.length === 0}
                    onClick={this.toggleCompassSetting}
                    style={{ height: "32px" }}
                  >
                    <img src={CompassIconSeleted} />
                    方位角の設定
                  </Button>
                </Grid>
              )}
              <Grid>
                {drawingsWithSpots && drawingsWithSpots.length > 0 && (
                  <DragDropContext
                    className={"my-5 sub-map"}
                    onDragEnd={this.onDragEnd}
                  >
                    <Grid ref={this.drawingRef}>
                      <div
                        id={"draggable-primary-sp"}
                        className="draggable-primary-sp"
                        ref={this.drawingBlockRef}
                        onTouchStart={this.onTouchStart}
                        onTouchEnd={this.onTouchEnd}
                        onTouchMove={this.onTouchMove}
                      >
                        <CompassSettingSP
                          direction={
                            drawingsWithSpots[currentDrawing].direction === null
                              ? 0
                              : drawingsWithSpots[currentDrawing].direction
                          }
                          isNullDirection={
                            drawingsWithSpots[currentDrawing].direction === null
                          }
                          bounds={"draggable-primary-sp"}
                          isCompassSetting={isCompassSetting}
                          sitesUC={sitesUC}
                          currentDrawingUCs={
                            drawingsWithSpots[currentDrawing].uniqueCode
                          }
                          drawingsWithSpots={drawingsWithSpots}
                          selectedDrawing={selectedDrawing}
                          handleSavingParent={this.handleSavingCompass}
                          handleSavingDirectionParent={
                            this.handleSavingCompassDirection
                          }
                          handleSavingCompassCheckAll={
                            this.handleSavingCompassCheckAll
                          }
                          handleCompassDragParent={this.handleCompassDrag}
                          isClickToDrawing={isClickToDrawing}
                          noteText={noteText}
                        />
                        <QuickPinchZoom
                          onUpdate={this.onUpdateTransform}
                          enabled={!noteText}
                          ref={this.wrapperRef}
                          tapZoomFactor={0}
                          inertia={false}
                          verticalPadding={200}
                          horizontalPadding={200}
                        >
                          <Grid
                            id={"primary-map-sp"}
                            ref={this.imgRef}
                            className={`${
                              currentDrawingWithSpots?.isVerticalImg &&
                              "vertical-primary-block"
                            }`}
                            onClick={() => {
                              return isMarkMap
                                ? this.showMapDialog(
                                    drawingsWithSpots[currentDrawing].uniqueCode
                                  )
                                : undefined;
                            }}
                          >
                            <DrawingPhoto
                              id="primary-image-sp"
                              name={drawingsWithSpots[currentDrawing].name}
                              drawingsUC={
                                drawingsWithSpots[currentDrawing].uniqueCode
                              }
                              blob={
                                drawingsWithSpots[currentDrawing].blob
                              }
                              loadMarker={this.loadMarker}
                              positions={currentDrawingWithSpots?.positions}
                              isHideMarker={isCompassSetting}
                              currentDrawingUC={
                                drawingsWithSpots[currentDrawing].uniqueCode
                              }
                            />
                          </Grid>
                        </QuickPinchZoom>
                        <Grid
                          className={`noteText${noteText ? " show" : ""}`}
                          ref={this.messageRef}
                          // onTouchStart={this.onTouchStart}
                          // onTouchEnd={this.onTouchEnd}
                          // onTouchMove={this.onTouchMove}
                        >
                          図面を操作するためには、２本指で操作を開始してください
                        </Grid>
                      </div>
                    </Grid>

                    <Grid className="my-20 position-relative">
                      {drawingsWithSpots.length > 0 && (
                        <Droppable
                          droppableId="droppable2"
                          direction="horizontal"
                        >
                          {(provided, snapshot) => (
                            <div
                              className="grid-list-sp"
                              ref={provided.innerRef}
                              onScroll={this.showHideButtonNextPrev}
                            >
                              {showHideBtnPrev && (
                                <Fab
                                  size="small"
                                  className="btn-prev-drawing"
                                  onClick={e => this.scroll(e, false)}
                                >
                                  <ArrowBackIcon />
                                </Fab>
                              )}
                              {drawingsWithSpots.map((drawing, index) => (
                                <Draggable
                                  key={drawing.uniqueCode}
                                  draggableId={drawing.uniqueCode}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={`sub-block ${
                                        currentDrawing === index &&
                                        "current-drawing"
                                      }`}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                      onClick={() => {
                                        this.props.setSelectedIndexDrawing(
                                          index
                                        );
                                        this.props.setRedirectFromUrl({
                                          isRedirectFromUrl: false,
                                          isDialog: false,
                                          isCardList: true,
                                          currentDrawingUC: null,
                                          from: ""
                                        });
                                        return isMarkMap
                                          ? this.showMapDialog(
                                              drawing.uniqueCode
                                            )
                                          : this.setState(
                                              {
                                                currentDrawing: index
                                              },
                                              () => {
                                                // Some params are not ready to set, so need set timeout to make sure it's ready
                                                setTimeout(
                                                  this.returnDefaultSize,
                                                  100
                                                );
                                              }
                                            );
                                      }}
                                    >
                                      <Grid className="sub-block-title-sp text-center">
                                        <Typography
                                          variant="body2"
                                          variantMapping={{ body2: "span" }}
                                          className="text-bold text-center mb-5"
                                        >
                                          {drawing.name}
                                        </Typography>
                                        <span
                                          onClick={e => {
                                            this.props.toggleDialogEditMap(
                                              drawing.uniqueCode
                                            );
                                            e.stopPropagation();
                                          }}
                                          className="edit-button"
                                        >
                                          <EditIcon className="icon-margin" />
                                        </span>
                                      </Grid>
                                      <div
                                        id={`sub-img-block-${drawing.uniqueCode}`}
                                        className={`text-left ${
                                          isMarkMap
                                            ? "sub-img-block-sp selected"
                                            : "sub-img-block-sp"
                                        } ${
                                          drawing.isVerticalImg
                                            ? "vertical-sub-compass-block"
                                            : ""
                                        }`}
                                      >
                                        <DrawingPhoto
                                          class={`sub-image`}
                                          drawingsUC={drawing.uniqueCode}
                                          blob={drawing.blob}
                                          fetchDone={this.fetchDone}
                                          alt={drawing.title}
                                          id={drawing.uniqueCode}
                                          loadMarker={this.loadMarker}
                                          positions={positions[index]}
                                          isHideMarker={isCompassSetting}
                                          currentDrawingUC={
                                            drawingsWithSpots[currentDrawing]
                                              .uniqueCode
                                          }
                                        />
                                        <CompassView
                                          direction={drawing.direction}
                                          drawingsUC={drawing.uniqueCode}
                                          isSavingCompassSetting={
                                            isSavingCompassSetting
                                          }
                                          currentDrawing={
                                            currentDrawing === index
                                          }
                                          isSelected={
                                            selectedDrawing.indexOf(
                                              drawing.uniqueCode
                                            ) !== -1
                                          }
                                          isCompassSetting={isCompassSetting}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                              {showHideBtnNext && (
                                <Fab
                                  className="btn-next-drawing"
                                  size="small"
                                  onClick={e => this.scroll(e, true)}
                                >
                                  <ArrowForwardIcon />
                                </Fab>
                              )}
                            </div>
                          )}
                        </Droppable>
                      )}
                    </Grid>
                  </DragDropContext>
                )}
              </Grid>
              {isShowMenuButton && (
                <Fab
                  className={`button-sp button-add-drawing-sp button-logwalk-menu-center-sp`}
                  onClick={this.toggleDialogOption}
                >
                  <label style={{ color: "inherit" }}>
                    <img src={ShowMenu} />
                  </label>
                </Fab>
              )}
              {isShowDialogMap && (
                <MapDialog
                  currentDrawing={currentDrawing}
                  open={isShowDialogMap}
                  onClose={this.hideDialogMap}
                  toggleDialogAddPoint={this.props.toggleDialogAddPoint}
                  toggleDialogEditPoint={this.props.toggleDialogEditPoint}
                  previousDrawing={this.previousDrawing}
                  nextDrawing={this.nextDrawing}
                  selectDrawing={this.selectDrawing}
                  hasWalkThroughFeature={hasWalkThroughFeature}
                  showLinkInfos={showLinkInfos}
                  toggleConfirmCreateLink={toggleConfirmCreateLink}
                  toggleConfirmDeleteLink={toggleConfirmDeleteLink}
                />
              )}
              {isSavingCompassSetting && <Grid className="fixed-box"></Grid>}
              {isSavingCompassSetting && (
                <div
                  className={`sub-block-compass-sp current-drawing-compass-sp`}
                >
                  <div className={`selected-drawing-container`}>
                    <div
                      className={`selected-drawing-checkbox-wrapper ${
                        selectedDrawing.indexOf(
                          drawingsWithSpots[currentselectedDrawingIndex]
                            .uniqueCode
                        ) == -1 &&
                        currentselectedDrawingIndex !== currentDrawing
                          ? "display-none"
                          : ""
                      }`}
                    />
                    <Typography className="item-name">
                      <Grid className="item-checkbox">
                        <Checkbox
                          className={`selected-photos compass-selected-drawing p-0
                        ${
                          selectedDrawing.indexOf(
                            drawingsWithSpots[currentselectedDrawingIndex]
                              .uniqueCode
                          ) == -1
                            ? "compass-selected-drawing-white"
                            : ""
                        }
                        `}
                          color="primary"
                          checked={
                            selectedDrawing.indexOf(
                              drawingsWithSpots[currentselectedDrawingIndex]
                                .uniqueCode
                            ) !== -1 ||
                            currentselectedDrawingIndex === currentDrawing
                          }
                          onClick={e => {
                            this.handleChangeListDrawing(
                              drawingsWithSpots[currentselectedDrawingIndex]
                                .uniqueCode
                            );
                          }}
                          // checked={copyList.includes(item.uniqueCode)}
                          inputProps={{ "aria-label": "Primary checkbox" }}
                        />
                      </Grid>
                      Select
                    </Typography>
                  </div>

                  <div
                    onClick={this.NextCurrentCompassDrawing}
                    className={`compass-arrow-right ${
                      currentselectedDrawingIndex ===
                      drawingsWithSpots.length - 1
                        ? "compass-sp-arrow-right-disable"
                        : ""
                    }`}
                  ></div>
                  <div
                    onClick={this.PreCurrentCompassDrawing}
                    className={`compass-arrow-left ${
                      currentselectedDrawingIndex === 0
                        ? "compass-sp-arrow-left-disable"
                        : ""
                    }`}
                  ></div>
                  <Grid className="sub-block-title-sp sub-block-compass-sp-title text-center">
                    <Typography
                      variant="body2"
                      variantMapping={{ body2: "span" }}
                      className="text-bold text-center mb-5"
                    >
                      {drawingsWithSpots[currentselectedDrawingIndex].name}
                    </Typography>
                    <span
                      onClick={e => {
                        this.props.toggleDialogEditMap(
                          drawingsWithSpots[currentselectedDrawingIndex]
                            .uniqueCode
                        );
                        e.stopPropagation();
                      }}
                      className="edit-button"
                    >
                      <EditIcon className="icon-margin" />
                    </span>
                  </Grid>
                  <div
                    id={"sub-img-block-compass-sp"}
                    className={`sub-img-block-compass-sp ${
                      drawingsWithSpots[currentselectedDrawingIndex]
                        .isVerticalImg
                        ? "vertical-sub-compass-block"
                        : ""
                    }`}
                  >
                    <DrawingPhoto
                      id="sub-image-compass"
                      class="sub-image sub-image-compass-primary"
                      drawingsUC={
                        drawingsWithSpots[currentselectedDrawingIndex]
                          .uniqueCode
                      }
                      blob={drawingsWithSpots[currentselectedDrawingIndex].blob}
                      fetchDone={this.fetchDone}
                      alt={drawingsWithSpots[currentselectedDrawingIndex].title}
                      id={
                        drawingsWithSpots[currentselectedDrawingIndex]
                          .uniqueCode
                      }
                      loadMarker={this.loadMarkerCompass}
                      positions={positionsCompass}
                      isHideMarker={true}
                    />
                    <CompassViewSP
                      direction={
                        drawingsWithSpots[currentselectedDrawingIndex]
                          .direction == null
                          ? 0
                          : drawingsWithSpots[currentselectedDrawingIndex]
                              .direction
                      }
                      drawingsUC={
                        drawingsWithSpots[currentselectedDrawingIndex]
                          .uniqueCode
                      }
                      isSavingCompassSetting={true}
                      savingDirection={savingDirection}
                      currentDrawing={
                        currentDrawing === currentselectedDrawingIndex
                      }
                      isSelected={
                        selectedDrawing.indexOf(
                          drawingsWithSpots[currentselectedDrawingIndex]
                            .uniqueCode
                        ) !== -1
                      }
                      isCompassSetting={isCompassSetting}
                      bounds={"sub-img-block-compass-sp"}
                    />
                  </div>
                </div>
              )}
              <Dialog
                open={isShowDialogPinSpotHelp}
                onClose={this.hideDialogPinSpotHelp}
                aria-labelledby="max-width-dialog-title"
                maxWidth="xs"
                PaperProps={{
                  className: "dialog paper-image"
                }}
              >
                <DialogContent className="pin-spot-help">
                  <Grid className="pin-spot-help-description">
                    <img src={PinSpotHelp} alt="Pin Spot Help" />
                    <Typography className="text-bold">
                      地点追加方法は、
                    </Typography>
                    <Typography className="text-bold">
                      追加したい場所を長押し
                    </Typography>
                  </Grid>
                  <Grid className="pin-spot-help-action">
                    <FormControlLabel
                      style={{
                        color: "#fff"
                      }}
                      control={
                        <Checkbox
                          checked={isCheckedHelp}
                          onChange={this.handleCheckHelp}
                          name="checkedA"
                          color="primary"
                        />
                      }
                      label="今後この表示をしない"
                    />
                    <Button
                      variant="contained"
                      onClick={this.hideDialogPinSpotHelp}
                    >
                      OK
                    </Button>
                  </Grid>
                </DialogContent>
              </Dialog>

              <CommonMapOptionDialogSP
                open={isShowAddPointDialog}
                onClose={this.toggleAddPointDialog}
                title="地点情報の設定"
                buttonOptions={[
                  {
                    buttonTitle: "新しい地点を追加する",
                    buttonOnCick: this.showDialogMap,
                    buttonClasses: "confirm-button-blue"
                  },
                  {
                    buttonTitle: "この図面から地点を複製する",
                    buttonOnCick: () => {
                      this.props.openDialogCopy();
                      this.toggleAddPointDialog();
                    },
                    buttonIsDisable: !(
                      fetchDone &&
                      drawingsWithSpots.length > 1 &&
                      drawingsWithSpots[currentDrawing].spots.length
                    ),
                    buttonClasses: "confirm-button-sliver"
                  },
                  {
                    buttonTitle: hasWalkThroughFeature ? "地点をつなぐ" : "",
                    buttonIsDisable:
                      drawingsWithSpots[currentDrawing]?.spots?.length === 0,
                    buttonOnCick: () => {
                      this.showDialogLinkMap();
                    },
                    buttonClasses: "confirm-button-outline"
                  }
                ]}
                PaperProps={{
                  className: "dialog dialog-sign-up map-option-dialog"
                }}
              ></CommonMapOptionDialogSP>

              <Dialog
                open={isShowDialogOption}
                onClose={() => {}}
                TransitionComponent={Transition}
                keepMounted
                BackdropProps={{
                  className: "dialog-backdrop-transparent"
                }}
                PaperProps={{
                  className: "dialog-option-transitions"
                }}
              >
                <CloseIcon
                  onClick={this.toggleDialogOption}
                  size="small"
                  className="dialog-option-transitions-close-button"
                />
                <DialogTitle></DialogTitle>
                <DialogContent>
                  <ul>
                    <li
                      className={`${
                        drawingsWithSpots.length >= maximumDrawing &&
                        "li-log-sp-disable"
                      }`}
                      onClick={toggleAddDrawingDialog}
                    >
                      図面の追加
                    </li>
                    <li
                      className={`${
                        drawingsWithSpots.length === 0 && "li-log-sp-disable"
                      }`}
                      onClick={
                        drawingsWithSpots.length === 0
                          ? () => {
                              return;
                            }
                          : toggleAddPointDialog
                      }
                    >
                      地点情報の設定
                    </li>
                    <li
                      className={`${
                        drawingsWithSpots.length === 0 && "li-log-sp-disable"
                      }`}
                      onClick={
                        drawingsWithSpots.length === 0
                          ? () => {
                              return;
                            }
                          : this.toggleCompassSetting
                      }
                    >
                      方位角の設定
                    </li>
                  </ul>
                </DialogContent>
              </Dialog>
            </Grid>
          )}

          {isShowMenuButton && (
            <Fab
              className={`button-sp button-add-drawing-sp button-logwalk-menu-center-sp`}
              onClick={() =>
                isViewVRSpace
                  ? this.toggleDialogOption()
                  : setFilterInfoBox(true)
              }
            >
              {isViewVRSpace ? (
                <label style={{ color: "inherit" }}>
                  <img src={ShowMenu} />
                </label>
              ) : (
                <span
                  style={{
                    fontSize: "32px",
                    lineHeight: "32px",
                    color: "white",
                    marginTop: "4px"
                  }}
                >
                  <SearchIcon fontSize="inherit" color="inherit" />
                </span>
              )}
            </Fab>
          )}
        </React.Fragment>
      )
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  type: state.rootSitesReducer.siteInfoReducer.type,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message,
  siteInfo: getSiteInfo(state.rootSitesReducer.siteInfoReducer.siteInfo),
  drawingsWithSpots: getDrawingsWithSpots(
    state.rootSitesReducer.siteInfoReducer.drawingsWithSpots
  ),
  redirectFromUrl: getRedirectFromUrl(
    state.rootSitesReducer.siteInfoReducer.redirectFromUrl
  )
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      translateValidate,
      messageValidate,
      fetchDrawingFile,
      sortDrawingImage,
      setRedirectFromUrl: data => dispatch(setRedirectFromUrl(data))
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MapsBuilderSP));
