import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import HeaderSP from "../layout/HeaderSP";
import HeaderLogBuild from "../layout/HeaderLogBuild";
import ErrorPage from "../../components/error/Index";
import MasterLayout from "./MasterLayout";

const PrivateRoute = ({
  component: Component,
  isNoHeader,
  isHeaderSP,
  isHeaderLogBuild,
  auth,
  axiosError,
  noRedirect,
  isLogwalkViewerSP,
  isFixSpot,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated === true && !noRedirect ? (
        <MasterLayout isFixSpot={isFixSpot}>
          {isHeaderSP ? (
            <HeaderSP
              titlePage={
                axiosError && axiosError.status === 404 ? "" : rest.titlePage
              }
              {...props}
            />
          ) : isHeaderLogBuild ? (
            <HeaderLogBuild
              titlePage={
                axiosError && axiosError.status === 404 ? "" : rest.titlePage
              }
              {...props}
            />
          ) : isNoHeader ? (
            <></>
          ) : (
            <Header
              titlePage={
                axiosError && axiosError.status === 404 ? "" : rest.titlePage
              }
              disableHeader={
                !(axiosError && axiosError.status === 404 ? "" : rest.titlePage)
              }
              isFixSpot={isFixSpot}
              {...props}
            />
          )}
          {axiosError && axiosError.status === 404 ? (
            <ErrorPage />
          ) : (
            <Component {...props} />
          )}
        </MasterLayout>
      ) : (
        <>
          {noRedirect && axiosError?.status !== 401 ? (
            <>
              {axiosError && axiosError.status === 404 ? (
                <ErrorPage />
              ) : (
                <Component {...props} />
              )}
            </>
          ) : (
            <>
              {isHeaderSP || isLogwalkViewerSP ? (
                <Redirect to="/sp/login" />
              ) : isHeaderLogBuild ? (
                <Redirect to="/logbuild/login" />
              ) : (
                <Redirect to="/login" />
              )}
            </>
          )}
        </>
      )
    }
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  axiosError: state.storesReducer.axiosError
});

export default connect(mapStateToProps)(PrivateRoute);
