import React from 'react';
import MediaQuery from 'react-responsive';

import {
  isTablet
} from "react-device-detect";

const mediaQuery = '(max-width: 959px)';

export default function Sp(props) {
  return (
    isTablet ? 
    <>{props.children}</>
    :
    <MediaQuery query={mediaQuery}>
        {props.children}
    </MediaQuery>
  );
}
