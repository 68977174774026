import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Fab,
  Grid
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import DrawingBox from "./DrawingBox";
import FixSpots360ImgBox from "./FixSpots360ImgBox";
import FixSpotsShootList from "./FixSpotsShootList";
import { api } from "common/api/api";
import { useHistory, useParams } from "react-router-dom";

function FixSpots(props) {
  const [drawingsWithSpots, setDrawingsWithSpots] = useState([]);
  const [listShootInfo, setListShootInfo] = useState([]);
  const [listShootInfoInit, setListShootInfoInit] = useState([]);
  const [showShoot, setShowShoot] = useState(null);
  const [madeChanges, setMadeChanges] = useState(false);
  const [recordInfoInit, setRecordInfoInit] = useState(null);
  const [showConfirmRemoveShootFromSpot, setShowConfirmRemoveShootFromSpot] =
    useState("");
  const [showConfirmSaveChange, setShowConfirmSaveChange] = useState(false);
  const [showConfirmCancelChange, setShowConfirmCancelChange] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    api
      .get(`/builder/fix-spots/records/${id}`)
      .then(fixSpotsRes => {
        const fixSpotsData = fixSpotsRes.data;
        const shoots = fixSpotsData.shoots;

        setRecordInfoInit(fixSpotsData);
        setListShootInfo(shoots);

        if (fixSpotsData.site) {
          api
            .get(
              `/builder/logwalk-viewer/sites/${fixSpotsData.site.uniqueCode}/drawings`
            )
            .then(drawingsRes => {
              setDrawingsWithSpots(drawingsRes.data);
            });
        }

        if (shoots.length > 0) {
          let fetchDetailListShootInfo = [];
          for (let i = 0; i < shoots.length; i++) {
            const fetchDetailShootInfo = api.get(
              `/builder/logwalk-viewer/shoots/${shoots[i].uniqueCode}`
            );
            fetchDetailListShootInfo.push(fetchDetailShootInfo);
          }

          Promise.all(fetchDetailListShootInfo).then(listShootInfoRes => {
            let tempListShootInfo = [];
            for (let i = 0; i < listShootInfoRes.length; i++) {
              const shootElement = shoots[i];
              const listShootInfoResElement = listShootInfoRes[i].data;
              tempListShootInfo[i] = {
                ...shootElement,
                ...listShootInfoResElement
              };
            }

            setListShootInfoInit(tempListShootInfo);

            tempListShootInfo = tempListShootInfo.map(item => {
              return {
                ...item,
                spotsUC: null,
                spotName: "",
                compassSetting: null
              };
            });
            setListShootInfo(tempListShootInfo);
            setShowShoot(tempListShootInfo[0]);
          });
        }
      })
      .catch(err => {
        if (err.response.status === 403) {
          history.push("/not-found");
        }
      });
  }, []);

  const checkCompleteChange = () => {
    for (let i = 0; i < listShootInfo.length; i++) {
      if (listShootInfo[i].spotsUC === null) {
        return false;
      }
    }

    if (!madeChanges) {
      return false;
    }
    return true;
  };

  const assignCurrentShootToSpot = spot => {
    if (showShoot && !showShoot.spotsUC) {
      let tempListShootInfo = [];
      const shootIndex = listShootInfo.findIndex(
        item => item.uniqueCode === showShoot.uniqueCode
      );

      const drawing = drawingsWithSpots.find(drawingItem =>
        drawingItem.spots.find(
          spotItem => spot.uniqueCode === spotItem.uniqueCode
        )
      );

      const tempShootInfo = {
        ...listShootInfo[shootIndex],
        spotsUC: spot.uniqueCode,
        spotName: spot.name,
        compassSetting: drawing.direction
      };

      for (let i = 0; i < listShootInfo.length; i++) {
        if (shootIndex === i) {
          tempListShootInfo[i] = tempShootInfo;
        } else {
          tempListShootInfo[i] = listShootInfo[i];
        }
      }

      setListShootInfo(tempListShootInfo);
      setShowShoot(tempShootInfo);
      if (!madeChanges) {
        setMadeChanges(true);
        api.post(`/builder/fix-spots/records/${id}/lock`).then(() => {});
      }
    }
  };

  const removeShootFromSpot = shootsUC => {
    let tempListShootInfo = [];

    const shootIndex = listShootInfo.findIndex(
      item => item.uniqueCode === shootsUC
    );
    const tempShootInfo = {
      ...listShootInfo[shootIndex],
      spotsUC: null,
      spotName: ""
    };
    for (let i = 0; i < listShootInfo.length; i++) {
      if (shootIndex === i) {
        tempListShootInfo[i] = tempShootInfo;
      } else {
        tempListShootInfo[i] = listShootInfo[i];
      }
    }
    setListShootInfo(tempListShootInfo);
    if (!madeChanges) {
      setMadeChanges(true);
      api.post(`/builder/fix-spots/records/${id}/lock`).then(() => {});
    }
    if (shootsUC === showShoot.uniqueCode) {
      setShowShoot(tempShootInfo);
    }
  };

  const saveChange = () => {
    const shoots = listShootInfo.map(item => {
      return {
        uniqueCode: item.uniqueCode,
        spotsUC: item.spotsUC
      };
    });
    const data = { shoots };
    api.put(`/builder/fix-spots/records/${id}`, data).then(res => {
      history.push(`/fix-spots/sites/${recordInfoInit.site.uniqueCode}`);
    });
  };

  return (
    <React.Fragment>
      <div>
        <Grid container style={{ height: "68vh" }}>
          <Grid
            item
            xs={7}
            style={{
              height: "100%",
              border: "2px solid #ffffff",
              position: "relative"
            }}
          >
            <FixSpots360ImgBox
              showShoot={showShoot}
              recordInfoInit={recordInfoInit}
            />
          </Grid>
          <Grid item xs={5}>
            {drawingsWithSpots.length > 0 && (
              <DrawingBox
                drawingsWithSpots={drawingsWithSpots}
                listShootInfo={listShootInfo}
                listShootInfoInit={listShootInfoInit}
                assignCurrentShootToSpot={assignCurrentShootToSpot}
                showShoot={showShoot}
                checkCompleteChange={checkCompleteChange}
                setShowConfirmSaveChange={setShowConfirmSaveChange}
                setShowConfirmCancelChange={setShowConfirmCancelChange}
              />
            )}
          </Grid>
        </Grid>
        <Grid style={{ width: "100%" }}>
          {showShoot && (
            <FixSpotsShootList
              listShootInfo={listShootInfo}
              showShoot={showShoot}
              setShowShoot={setShowShoot}
              drawingsWithSpots={drawingsWithSpots}
              setShowConfirmRemoveShootFromSpot={
                setShowConfirmRemoveShootFromSpot
              }
            />
          )}
        </Grid>
      </div>
      <Dialog
        open={showConfirmRemoveShootFromSpot}
        onClose={() => setShowConfirmRemoveShootFromSpot("")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          backgroundColor: "#e0e0e0eb"
        }}
        PaperProps={{
          style: { padding: "15px 0px", overflowY: "unset" }
        }}
      >
        <Fab
          onClick={() => setShowConfirmRemoveShootFromSpot("")}
          size="small"
          className="white-text close-button"
        >
          <CloseIcon />
        </Fab>
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: "500",
            display: "flex",
            justifyContent: "center",
            marginBottom: "12px"
          }}
        >
          写真への登録を解除しますか？
        </DialogTitle>
        <DialogActions
          style={{ justifyContent: "center", flexDirection: "column" }}
        >
          <Button
            onClick={() => {
              removeShootFromSpot(showConfirmRemoveShootFromSpot);
              setShowConfirmRemoveShootFromSpot("");
            }}
            style={{
              color: "#ffffff",
              backgroundColor: "#737070",
              margin: "0px 20px 20px 20px",
              textAlign: "center",
              width: "360px",
              padding: "10px"
            }}
          >
            登録を解除する
          </Button>
          <Button
            onClick={() => {
              setShowConfirmRemoveShootFromSpot("");
            }}
            style={{
              color: "#162f50",
              backgroundColor: "#C7C7C7",
              margin: "0px 20px 20px 20px",
              textAlign: "center",
              width: "360px",
              padding: "10px"
            }}
          >
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showConfirmSaveChange}
        onClose={() => setShowConfirmSaveChange(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ backgroundColor: "#e0e0e0eb" }}
        PaperProps={{
          style: { padding: "15px 0px", overflowY: "unset" }
        }}
      >
        <Fab
          onClick={() => setShowConfirmSaveChange(false)}
          size="small"
          className="white-text close-button"
        >
          <CloseIcon />
        </Fab>
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: "500",
            display: "flex",
            justifyContent: "center",
            marginBottom: "12px"
          }}
        >
          地点への写真登録を完了しますか？
        </DialogTitle>
        <DialogActions
          style={{ justifyContent: "center", flexDirection: "column" }}
        >
          <Button
            onClick={() => {
              saveChange();
              setShowConfirmSaveChange(false);
            }}
            style={{
              color: "#ffffff",
              backgroundColor: "#8FC31F",
              margin: "0px 20px 20px 20px",
              textAlign: "center",
              width: "360px",
              padding: "10px"
            }}
          >
            登録を完了する
          </Button>
          <Button
            onClick={() => {
              setShowConfirmSaveChange(false);
            }}
            style={{
              color: "#162f50",
              backgroundColor: "#C7C7C7",
              margin: "0px 20px 20px 20px",
              textAlign: "center",
              width: "360px",
              padding: "10px"
            }}
          >
            登録画面へ戻る
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showConfirmCancelChange}
        onClose={() => setShowConfirmCancelChange(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ backgroundColor: "#e0e0e0eb" }}
        PaperProps={{
          style: { padding: "15px 0px", overflowY: "unset" }
        }}
      >
        <Fab
          onClick={() => setShowConfirmCancelChange(false)}
          size="small"
          className="white-text close-button"
        >
          <CloseIcon />
        </Fab>
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: "500",
            display: "flex",
            justifyContent: "center",
            marginBottom: "12px"
          }}
        >
          地点への登録を中止しますか？
        </DialogTitle>
        <DialogActions
          style={{ justifyContent: "center", flexDirection: "column" }}
        >
          <Button
            onClick={() => {
              if (recordInfoInit && madeChanges) {
                api.post(`/builder/fix-spots/records/${id}/unlock`).then(() => {
                  history.push(
                    `/fix-spots/sites/${recordInfoInit.site.uniqueCode}`
                  );
                });
              } else {
                history.push(
                  `/fix-spots/sites/${recordInfoInit.site.uniqueCode}`
                );
              }
            }}
            style={{
              color: "#ffffff",
              backgroundColor: "#737070",
              margin: "0px 20px 20px 20px",
              textAlign: "center",
              width: "360px",
              padding: "10px"
            }}
          >
            登録をやめる
          </Button>
          <Button
            onClick={() => {
              setShowConfirmCancelChange(false);
            }}
            style={{
              color: "#162f50",
              backgroundColor: "#C7C7C7",
              margin: "0px 20px 20px 20px",
              textAlign: "center",
              width: "360px",
              padding: "10px"
            }}
          >
            登録画面へ戻る
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default FixSpots;
