import {
  FETCH_STORES_PENDING,
  FETCH_STORES_SUCCESS,
  FETCH_STORES_ERROR,
  FETCH_PIC_SUCCESS,
  FETCH_PIC_ERROR,
  AXIOS_ERROR,
  CLEAR_AXIOS_ERROR,
  FETCH_BUILDER_NAME,
  FETCH_BUILDER_FLAG,
  API_ERROR,
  DISABLE_LOADING,
  ENABLE_LOADING,
  FINISH_LOADING,
  FETCH_BUILDER_JOB_SUCCESS,
  POST_USER_ACKNOWLEDGEMENT_SUCCESS,
  FETCH_REGEX_VALIDATE_SUCCESSS,
  FETCH_MESSAGE_VALIDATE_SUCCESSS,
  CLOSE_PASSWORD_DIALOG_SUCCESS,
  PUT_PASSWORD_SP_SUCCESSS,
  CHECK_AUTH,
  DISABLE_CHECK_AUTH,
  SHOW_LOG_MEET_HISTORY
} from "../constant/constant";

export const fetchStoresPending = () => {
  return {
    type: FETCH_STORES_PENDING
  };
};

export const fetchStoresSuccess = stores => {
  return {
    type: FETCH_STORES_SUCCESS,
    stores: stores
  };
};

export const fetchStoresError = error => {
  return {
    type: FETCH_STORES_ERROR,
    error: error
  };
};


export const fetchPICSuccess = PIC => {
  return {
    type: FETCH_PIC_SUCCESS,
    PIC: PIC
  };
};

export const fetchPICError = error => {
  return {
    type: FETCH_PIC_ERROR,
    error: error
  };
};

export const axiosError = response => {
  return {
    type: AXIOS_ERROR,
    error: response
  };
};

export const clearError = () => {
  return {
    type: CLEAR_AXIOS_ERROR
  };
};

export const fetchBuilderName = data => {
  return {
    type: FETCH_BUILDER_NAME,
    data: data
  };
};

export const fetchBuilderFlag = data => {
  return {
    type: FETCH_BUILDER_FLAG,
    data: data
  };
};

export const showLoadingIndicator = () => {
  return {
    type: ENABLE_LOADING
  };
};

export const hideLoadingIndicator = () => {
  return {
    type: DISABLE_LOADING
  };
};

export const finishLoadingData = () => {
  return {
    type: FINISH_LOADING
  };
};

export const apiError = error => {
  return {
    type: API_ERROR,
    error: error
  };
};

export const postUserAcknowledgementSuccess = data => {
  return {
    type: POST_USER_ACKNOWLEDGEMENT_SUCCESS,
    data
  };
};

export const fetchBuilderJobSuccess = builderJob => {
  return {
    type: FETCH_BUILDER_JOB_SUCCESS,
    builderJob: builderJob
  };
};

export const fetchRegexValidateSuccess = regexObject => {
  return {
    type: FETCH_REGEX_VALIDATE_SUCCESSS,
    regexObject: regexObject
  };
};

export const fetchMessageValidateSuccess = message => {
  return {
    type: FETCH_MESSAGE_VALIDATE_SUCCESSS,
    message: message
  };
};

export const closePasswordDialogSuccess = () => {
  return {
    type: CLOSE_PASSWORD_DIALOG_SUCCESS
  };
};

export const putPasswordSpSuccess = () => {
  return {
    type: PUT_PASSWORD_SP_SUCCESSS
  };
};

export const checkAuthSuccess = () => {
  return {
    type: CHECK_AUTH
  };
};

export const disCheckAuthSuccess = () => {
  return {
    type: DISABLE_CHECK_AUTH
  };
};

export const showLogMeetHistory = () => {
  return {
    type: SHOW_LOG_MEET_HISTORY
  };
};
