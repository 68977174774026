import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PlayCircleOutlineOutlinedIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import EditIcon from "@material-ui/icons/Edit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import Photos from "./Photos";
import Files from "./Files";
import Info from "./Info";
import Maps from "./Maps";
import ShareUrl from "./ShareUrl";
import LogMeet from "./LogMeet";
import { finishLoading } from "../../../common/Stores/service/service";
import {
  showHideDialogMapFromLogWalk,
  clearDrawingsWithSpot,
  setIsBack,
  incompleteSite
} from "./service/service";
import RobotStatus from "common/RobotStatus";
import { BUTTON_LOGKUN } from "common/constants/constants";
import { getType } from "./reducer/reducer";
import {
  wsUpdatedRobots,
  wsClearRobots
} from "../../robots/robotDetails/service/service";
import { getWebSocketRobotURL } from "utils/mapper";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { format } from "date-fns";
import HomeIcon from "./HomeIcon"
import { Button } from "@material-ui/core";
import { isMobile, isTablet } from "react-device-detect";

const WS_TIME_REPEAT = 10000; //10s

const Element = {
  None: false,
  Photos: 1,
  Files: 2,
  Info: 3,
  Maps: 4,
  ShareUrl: 5,
  Logmeet: 6
};

class SiteBuilder extends Component {
  uniqueCode = null;
  storesUC = null;
  loadAllPhotoSuccess = false;
  sitesUCChange = null;
  ws = null;
  wsInterval = null;
  pingInterval = null;
  robotsUC = [];
  countFetch = 0;

  constructor(props) {
    super(props);
    this.state = {
      model: {},
      sites: [],
      // isDialogIncompleteShow: false,
    };
  }

  componentWillMount() {
    this.uniqueCode = this.props.match.params.id;
  }

  componentWillReceiveProps(props) {
    if (props.site && this.sitesUCChange !== props.site.UniqueCode) {
      if (!props.isBack || (props.isBack && this.countFetch === 1)) {
        if (this.ws) {
          this.ws.onclose = function () { }; // disable onclose handler first
          this.ws.close();
          this.props.wsClearRobots();
          clearInterval(this.wsInterval);
          clearInterval(this.pingInterval);
        }

        this.robotsUC = this.updateRobotsChange(props.site.Robots);
        if (this.robotsUC.length > 0) {
          this.openWS(this.robotsUC);

          this.wsInterval = setInterval(() => {
            if (this.ws.readyState === 3) {
              clearInterval(this.pingInterval);
              this.openWS(this.robotsUC);
            }
          }, WS_TIME_REPEAT);
        }

        if (this.countFetch === 1) {
          this.countFetch = 0;
        }
      }

      if (props.isBack) {
        this.countFetch = 1;
      }

      this.sitesUCChange = props.site.UniqueCode;
    }
  }

  updateRobotsChange = robotsNew => {
    let robotsUCNew = [];
    robotsNew.map((robotNew, idx) => {
      robotsUCNew = [...robotsUCNew, robotNew.uniqueCode];
    });

    if (this.robotsUC.length == robotsUCNew.length) {
      robotsUCNew.map((robotUCNew, idx) => {
        if (robotUCNew !== this.robotsUC[idx]) {
          return robotsUCNew;
        }
      });
      return [];
    }
    return robotsUCNew;
  };

  componentWillUnmount() {
    clearInterval(this.wsInterval);
    clearInterval(this.pingInterval);

    if (this.ws) {
      this.ws.onclose = () => { }; // disable onclose handler first
      this.ws.close();
      this.props.wsClearRobots();
    }
    this.props.setIsBack(true);
  }

  openWS = robotsUC => {
    this.ws = new WebSocket(getWebSocketRobotURL());
    this.ws.addEventListener("open", () => {
      this.ws.send(JSON.stringify(robotsUC));

      this.pingInterval = setInterval(() => {
        this.ws.send("ping");
      }, WS_TIME_REPEAT);
    });

    this.ws.onmessage = s => {
      if (s.data !== "pong") {
        this.props.wsUpdatedRobots(JSON.parse(s.data));
      }
    };
  };

  handleHideLoading = () => {
    const { loading, loadPhotoTab, finishLoading } = this.props;
    if (loading && loadPhotoTab) {
      finishLoading();
    } else this.loadAllPhotoSuccess = true;
  };

  isIdChanged(props) {
    return this.uniqueCode !== props.match.params.id;
  }

  to(id) {
    if (this.props.site) this.props.site.UniqueCode = undefined;
    this.props.history.push("/sites/" + id);
    this.uniqueCode = id;
    this.props.clearDrawingsWithSpot();
  }

  toSitesPage() {
    this.props.history.push("/sites");
  }

  clearFilter(models) {
    models.forEach(model => (model.match = true));
  }

  filter() {
    const sites = this.state.sites;
    this.clearFilter(sites);
    this.setState({ sites });
  }

  selectStore(storesUC) {
    this.storesUC = storesUC;
    this.filter();
  }

  shouldEnableRobotButton = robots => {
    for (let i = 0; i < robots.length; i++) {
      if (robots[i].useStatus === 1) return true;
    }
    return false;
  };

  handleIncompleteSite = () => {
    this.props.incompleteSite(this.props.site.UniqueCode, () => {
      this.props.history.push("/sites", { reload: true, isBack: true, isCompleteSite: true })
    })
  }

  render() {
    const {
      entries,
      site,
      maps,
      match,
      loadPhotoTab,
      robotsUpdate
    } = this.props;
    return (
      <Grid container className="sites-image page-content">
        <Grid container wrap="nowrap" className="sites-right">
          <Grid className="image-list-wrap">
            <List disablePadding className="image-list">
              {entries &&
                entries.map(entry => (
                  <ListItem
                    onClick={() => {
                      entry.uniqueCode !== this.uniqueCode &&
                        this.to(entry.uniqueCode);
                    }}
                    selected={match.params.id === entry.uniqueCode}
                    key={entry.uniqueCode}
                    button
                    className="image-list-item"
                  >
                    <ListItemText className="clip-text" primary={entry.name} />
                  </ListItem>
                ))}
            </List>
            <Link to={{ pathname: "/sites", state: { reload: true, isBack: true, isCompleteSite: site && site.CompletedAt ? true : false } }} className="reset-link">
              <Fab variant="extended" className="back-btn">
                <FontAwesomeIcon
                  icon={faArrowCircleLeft}
                  className="icon-margin"
                />
                一覧に戻る
              </Fab>
            </Link>
          </Grid>
          <Grid className="image-content">
            <Grid container wrap="nowrap">
              <Grid container class="title-block">

                {site && site.CompletedAt
                  ? (
                    <React.Fragment>
                      <HomeIcon />
                      <Typography variant="h4" className="onsite-name max-width-435" style={{ marginLeft: "14px" }}>
                        {site && site.Name}
                      </Typography>
                      <Grid
                        container
                        alignItems="center"
                        className="complete-site ml-10"
                      >
                        <CheckCircleOutlineIcon className="icon-margin" />
                        <Typography variant="h6" className="dialog-title " >
                          完了日：
                          {format(new Date(site.CompletedAt), "yyyy/M/d/eee")}
                        </Typography>
                        <Button className="incomplete-site-btn" onClick={() => this.handleIncompleteSite()}>未完了に戻す</Button>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <HomeIcon />
                      <Typography variant="h4" className="onsite-name max-width-170" style={{ marginLeft: "14px" }}>
                        {site && site.Name}
                      </Typography>
                      <Fab
                        disableRipple
                        onClick={this.props.showDialogEdit}
                        size="small"
                        variant="extended"
                        className="edit-button"
                      >
                        <EditIcon className="icon-margin" />
                        編集
                      </Fab>
                    </React.Fragment>
                  )}
              </Grid>
              {site && (
                <Fab
                  className={
                    site.LogWalkViewer
                      ? "white-text button-logWalk mr-5"
                      : "white-text button-logWalk-disable mr-5"
                  }
                  variant="extended"
                  onClick={e => {
                    if (site.LogWalkViewer) {
                      this.props.showDialogDrawing(site.UniqueCode);
                    }
                    e.stopPropagation();
                  }}
                >
                  <PlayCircleOutlineOutlinedIcon className="icon-margin" />
                  Log Walk起動
                </Fab>
              )}
              <RobotStatus
                typeOfRobotStatus={BUTTON_LOGKUN}
                robots={site?.Robots}
                robotsUpdate={[robotsUpdate]}
              />
            </Grid>
            <Grid className="sites-tab">
              <Tabs
                className="tabs"
                value={this.props.tabElement}
                onChange={this.props.selectElement}
                aria-label="simple tabs example"
              >
                <Tab
                  className="tab-button"
                  label="写真"
                  value={Element.Photos}
                  style={{ minWidth: "128px" }}
                />
                <Tab
                  className="tab-button"
                  label="ファイル(PDF)"
                  value={Element.Files}
                  style={{ minWidth: "128px" }}
                />
                <Tab
                  className="tab-button"
                  label="現場情報"
                  value={Element.Info}
                  style={{ minWidth: "128px" }}
                />
                <Tab
                  className="tab-button text-transform-none"
                  label="Log Walk"
                  value={Element.Maps}
                  style={{ minWidth: "128px" }}
                />
                {site && site.LogMeetFlag &&  !(isMobile || isTablet) && (
                  <Tab
                    className="tab-button text-transform-none"
                    label="Log Meet"
                    value={Element.Logmeet}
                    style={{ minWidth: "128px" }}
                  />
                )}
                <Tab
                  className="tab-button text-transform-none"
                  label="URL共有一覧"
                  value={Element.ShareUrl}
                  style={{ minWidth: "128px" }}
                />
              </Tabs>
              {site && site.UniqueCode !== undefined && (
                <Grid>
                  {this.props.tabElement === Element.Photos && (
                    <Photos
                      sitesUC={site.UniqueCode}
                      loadPhotoTab={loadPhotoTab}
                      onHideLoading={this.handleHideLoading}
                    />
                  )}
                  {this.props.tabElement === Element.Files && (
                    <Files sitesUC={site.UniqueCode} />
                  )}
                  {this.props.tabElement === Element.Info && (
                    <Info sitesUC={site.UniqueCode} logMeetFlag={site && site.LogMeetFlag} />
                  )}
                  {this.props.tabElement === Element.Maps && (
                    <Maps sitesUC={site.UniqueCode} hasWalkThroughFeature={site.WalkThrough} />
                  )}
                  {this.props.tabElement === Element.ShareUrl && (
                    <ShareUrl sitesUC={site.UniqueCode} />
                  )}
                  {site.LogMeetFlag && this.props.tabElement === Element.Logmeet && (
                    <LogMeet sitesUC={site.UniqueCode} />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  loadPhotoTab: state.rootSitesReducer.siteInfoReducer.loadPhotoTab,
  loading: state.storesReducer.pending,
  tabElement: state.rootSitesReducer.siteInfoReducer.tabElement,
  type: getType(state.rootSitesReducer.sitesReducer.type),
  robotsUpdate:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .wsUpdatedRobots,
  isBack: state.rootSitesReducer.siteInfoReducer.isBack
});

export default connect(mapStateToProps, {
  finishLoading,
  showHideDialogMapFromLogWalk,
  clearDrawingsWithSpot,
  wsUpdatedRobots,
  wsClearRobots,
  setIsBack,
  incompleteSite
})(withRouter(SiteBuilder));
