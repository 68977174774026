import {
  FETCH_DASHBOARDS_SUCCESS,
  POST_DASHBOARDS_SUCCESS,
  FETCH_DASHBOARDS_SP_SUCCESS,
  FETCH_DASHBOARDS_LB_SUCCESS,
  CHANGE_TYPE_LIST_DASHBOARD_SUCCESS
} from "../constant/constant";

export const fetchDashBoardsSuccess = dashboards => {
  return {
    type: FETCH_DASHBOARDS_SUCCESS,
    dashboards: dashboards
  };
};

export const postDashBoardsSuccess = dashboard => {
  return {
    type: POST_DASHBOARDS_SUCCESS,
    dashboard: dashboard
  };
};

export const fetchDashBoardPCSuccess = dashboardSP => {
  return {
    type: FETCH_DASHBOARDS_SP_SUCCESS,
    dashboardSP: dashboardSP
  };
};

export const fetchDashBoardLBSuccess = dashboardLB => {
  return {
    type: FETCH_DASHBOARDS_LB_SUCCESS,
    dashboardLB: dashboardLB
  };
};

export const changeTypeListDashboardSuccess = isCardList => {
  return {
    type: CHANGE_TYPE_LIST_DASHBOARD_SUCCESS,
    isCardList
  };
};
