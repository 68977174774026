import { MenuItem, Popover } from "@material-ui/core";
import React, { useRef, useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconInfo from "./icons/IconInfo";
import CheckBoxIcon from "./icons/CheckBoxIcon";
import { useMediaQuery } from "react-responsive";
import Brightness1Icon from "@material-ui/icons/Brightness1";

const mediaQueryShowModeSP = "(max-width: 1560px)";
const useStyles = makeStyles({
  selectFieldWrapper: {
    width: "100%",
    height: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "0.5px solid #707070",
    borderRadius: "4px",
    cursor: "pointer",
    "& > div:first-child": {
      flexGrow: "1",
      padding: "0px 12px",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center"
    },
    "& > span:last-child": {
      color: "#C7C7C7",
      fontSize: "24px",
      lineHeight: "24px",
      width: "24px",
      height: "24px",
      marginRight: "4px"
    }
  },

  fieldLabel: {
    color: "#333333",
    fontSize: "12px",
    marginBottom: "2px",
    marginTop: "16px"
  },

  customScrollbar: {
    "&::-webkit-scrollbar": {
      backgroundColor: "#fff",
      width: "16px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#babac0",
      borderRadius: "16px",
      border: "4px solid #fff"
    },
    "&::-webkit-scrollbar-button": {
      display: "none"
    }
  },

  selectDrawingAndPointField: {},
  truncateText: {
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "wrap"
  },

  resetFilterButton: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    "& button": {
      height: "45px",
      width: "270px",
      borderRadius: "4px",
      backgroundColor: "#C7C7C7",
      color: "white",
      fontSize: "15px",
      lineHeight: "26px",
      fontWeight: "normal",
      padding: "9px 0",
      textAlign: "center",
      letterSpacing: "0"
    }
  }
});

function SelectField(props) {
  const { value, options, selectValue, label } = props;
  const [anchorElSelect, setAnchorElSelect] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState("0");

  const selectRef = useRef(null);
  const classes = useStyles();

  const handleClick = event => {
    setAnchorElSelect(event.currentTarget);
    setPopoverWidth(window.getComputedStyle(selectRef.current).width);
  };

  const getLabel = () => {
    if (options.length === 0 || !value) return "";
    const option = options.find(
      item => item.value.uniqueCode === value.uniqueCode
    );
    if (option) return option.label;
    return "";
  };

  const isSelected = option => {
    if (!value) return false;
    return value.uniqueCode === option.value.uniqueCode;
  };

  const getPopoverHeight = () => {
    let heightOptions = options.length * 40 + 24;
    if (heightOptions < 200) return heightOptions + "px";
    return "200px";
  };

  const showModeSP = useMediaQuery({ query: mediaQueryShowModeSP });

  return (
    <>
      <div style={{ width: "100%" }}>
        <p className={classes.fieldLabel}>{label}</p>

        <div
          className={classes.selectFieldWrapper}
          onClick={handleClick}
          ref={selectRef}
          style={showModeSP ? { height: 35 } : {}}
        >
          <div>{getLabel()}</div>
          <span>
            <ArrowDropDownIcon fontSize="inherit" color="inherit" />
          </span>
        </div>
      </div>

      <Popover
        open={Boolean(anchorElSelect)}
        anchorEl={anchorElSelect}
        onClose={() => setAnchorElSelect(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            width: popoverWidth,
            overflow: "hidden",
            height: getPopoverHeight(),
            padding: "12px 0px"
          }
        }}
      >
        <div
          style={{ width: "100%", height: "100%", overflow: "auto" }}
          className={classes.customScrollbar}
        >
          {options.map(option => (
            <MenuItem
              onClick={() => {
                selectValue(option.value);
                setAnchorElSelect(null);
              }}
              style={{
                backgroundColor: isSelected(option) ? "#F8F9F9" : "white",
                height: "40px",
                minHeight: "40px",
                paddingTop: "0",
                paddingBottom: "0"
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </div>
      </Popover>
    </>
  );
}

function FilterInfoBox(props) {
  const {
    drawingsWithSpots,
    setOpenSelectDrawingAndPoint,
    selectedSpots,

    allIcons,
    allMembers,
    allStatus,

    filterTitle,
    setFilterTitle,

    filterIcon,
    setFilterIcon,

    filterStatus,
    setFilterStatus,

    filterMember,
    setFilterMember,

    filterIsCreator,
    setFilterIsCreator,

    resetFilter
  } = props;
  const classes = useStyles();
  const showModeSP = useMediaQuery({ query: mediaQueryShowModeSP });

  const getSelectDrawingAndPointText = () => {
    if (selectedSpots.length === 0) {
      return <span>すべて</span>;
    }

    const selectedDrawings = drawingsWithSpots.filter(drawing =>
      drawing.spots.some(spot =>
        selectedSpots.find(
          selectedSpot => selectedSpot.uniqueCode === spot.uniqueCode
        )
      )
    );
    return (
      <span className={classes.truncateText} style={{ color: "#007CFF" }}>
        {selectedDrawings.map(
          (selectedDrawing, index) =>
            index < 20 &&
            (selectedDrawing.name.length > 3
              ? `${
                  (index !== 0 ? "、" : "") + selectedDrawing.name.slice(0, 3)
                }...`
              : `${(index !== 0 ? "、" : "") + selectedDrawing.name}`)
        )}
      </span>
    );
  };

  const getFilterStatuslabel = status => {
    if (status.uniqueCode === "all") return "すべて";
    if (status.uniqueCode === "incomplete") return status.label;
    return (
      <>
        <span
          style={{
            fontSize: "10px",
            color: "red",
            lineHeight: "inherit",
            marginRight: "3px"
          }}
        >
          <Brightness1Icon color="inherit" fontSize="inherit" />
        </span>
        完了
      </>
    );
  };
  return (
    <React.Fragment>
      <div style={{ width: "100%" }}>
        <h3
          style={{
            fontSize: "20px",
            lineHeight: "30px",
            marginBottom: "12px",
            color: "#00356D",
            fontWeight: "bold"
          }}
        >
          検索
        </h3>

        <div
          style={{
            border: "0.5px solid #707070",
            borderRadius: "4px",
            height: showModeSP ? "35px" : "40px",
            overflow: "hidden",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "nowrap",
            backgroundColor: "#f8f9f9"
          }}
        >
          <input
            type="text"
            placeholder="タイトルから検索"
            value={filterTitle}
            onChange={e => setFilterTitle(e.target.value)}
            style={{
              flexGrow: "1",
              height: "100%",
              border: "none",
              backgroundColor: "#f8f9f9",
              padding: "0px 12px"
            }}
          />
        </div>

        <Grid container spacing={3}>
          <Grid item xs={showModeSP ? 12 : 7}>
            <Grid container spacing={3}>
              <Grid item xs={7} style={{ padding: "5px 12px" }}>
                <SelectField
                  label="アイコン"
                  value={filterIcon}
                  options={allIcons.map(icon => {
                    return {
                      value: icon,
                      label:
                        icon.uniqueCode === "all" ? (
                          <span style={{ width: "100%" }}>すべて</span>
                        ) : (
                          <React.Fragment>
                            <IconInfo
                              color={icon.color}
                              width="30"
                              height="30"
                            />
                            <span style={{ flexGrow: "1", marginLeft: "10px" }}>
                              {icon.label}
                            </span>
                          </React.Fragment>
                        )
                    };
                  })}
                  selectValue={setFilterIcon}
                />
              </Grid>
              <Grid item xs={5} style={{ padding: "5px 12px" }}>
                <SelectField
                  label="状態"
                  value={filterStatus}
                  options={allStatus.map(status => {
                    return {
                      value: status,
                      label: (
                        <span style={{ width: "100%" }}>
                          {getFilterStatuslabel(status)}
                        </span>
                      )
                    };
                  })}
                  selectValue={setFilterStatus}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item style={{ flexGrow: 1, padding: "5px 12px" }}>
            <Grid container>
              <SelectField
                label="名前で絞り込む"
                value={filterMember}
                options={allMembers.map(member => {
                  return {
                    value: member,
                    label: (
                      <span>
                        {member.uniqueCode === "all" ? "すべて" : member.name}
                      </span>
                    )
                  };
                })}
                selectValue={setFilterMember}
              />
            </Grid>
          </Grid>
          <Grid
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            style={{
              display: "flex",
              height: "100%",
              marginLeft: "-12px",
              cursor: "pointer",
              padding: "5px 12px"
            }}
            onClick={() => setFilterIsCreator(!filterIsCreator)}
          >
            <div style={{ width: "40px", height: "40px", padding: "12px" }}>
              <CheckBoxIcon check={filterIsCreator} />
            </div>
            <span style={{ lineHeight: "40px" }}>作成者で検索</span>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={showModeSP ? 12 : 7} style={{ padding: "5px 12px" }}>
            <Grid container>
              <p className={classes.fieldLabel}>図面と地点を検索</p>
              <div
                className={classes.selectFieldWrapper}
                onClick={() => setOpenSelectDrawingAndPoint(true)}
                style={{
                  backgroundColor: "#F8F9F9",
                  height: showModeSP ? 35 : 40
                }}
              >
                <p style={{ padding: "0px 12px" }}>
                  {getSelectDrawingAndPointText()}
                </p>
                <span style={{}}>
                  <ArrowForwardIosIcon color="inherit" fontSize="inherit" />
                </span>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={showModeSP ? 12 : 5} style={{ padding: "5px 12px" }}>
            <div
              className={classes.resetFilterButton}
              style={{
                justifyContent: showModeSP ? "center" : "flex-end"
              }}
            >
              <button
                style={
                  showModeSP
                    ? {
                        marginTop: 0,
                        height: 35,
                        lineHeight: "19px"
                      }
                    : {
                        marginTop: 0
                      }
                }
                onClick={resetFilter}
              >
                検索条件を元に戻して全て表示する
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default FilterInfoBox;
