export const FETCH_SITE_INFO_SUCCESS = "FETCH_SITE_INFO_SUCCESS";

export const FETCH_SITE_INFO_ROBOT_SUCCESS = "FETCH_SITE_INFO_ROBOT_SUCCESS";

export const FETCH_SITE_INFO_STAFF_SUCCESS = "FETCH_SITE_INFO_STAFF_SUCCESS";

export const FETCH_SITE_INFO_SUPPLIER_SUCCESS =
  "FETCH_SITE_INFO_SUPPLIER_SUCCESS";

export const FETCH_STAFF_CANDIDATES_SUCCESS =
  "FETCH_SITE_STAFF_CANDIDATE_SUCCESS";

export const FETCH_SUPPLIER_CANDIDATES_SUCCESS =
  "FETCH_SITE_SUPPLIER_CANDIDATE_SUCCESS";

export const FETCH_SITE_INFO_ROBOT_CANDIDATE_SUCCESS =
  "FETCH_SITE_INFO_ROBOT_CANDIDATE_SUCCESS";

export const POST_SITE_INFO_ROBOT_CANDIDATE_SUCCESS =
  "POST_SITE_INFO_ROBOT_CANDIDATE_SUCCESS";

export const DELETE_SITE_INFO_ROBOT_CANDIDATE_SUCCESS =
  "DELETE_SITE_INFO_ROBOT_CANDIDATE_SUCCESS";

export const POST_STAFF_CANDIDATE_SUCCESS = "POST_STAFF_CANDIDATE_SUCCESS";

export const DELETE_STAFF_CANDIDATE_SUCCESS = "DELETE_STAFF_CANDIDATE_SUCCESS";

export const POST_SUPPLIER_CANDIDATE_SUCCESS =
  "POST_SUPPLIER_CANDIDATE_SUCCESS";

export const FETCH_SITE_SUCCESS = "FETCH_SITE_SUCCESS";

export const GET_SITES_DETAIL_SUCCESS = "GET_SITES_DETAIL_SUCCESS";

export const GET_FILE_SUCCESS = "GET_FILE_SUCCESS";

export const POST_FILE_SUCCESS = "POST_FILE_SUCCESS";

export const SEARCH_FILE_SUCCESS = "SEARCH_FILE_SUCCESS";

export const GET_PHOTOS_SUCCESS = "GET_PHOTOS_SUCCESS";

export const GET_PHOTO_INFO_SUCCESS = "GET_PHOTO_INFO_SUCCESS";

export const GET_FILE_INFO_SUCCESS = "GET_FILE_INFO_SUCCESS";

export const POST_PHOTOS_SUCCESS = "POST_PHOTOS_SUCCESS";

export const FETCH_ENTRIES_SUCCESS = "FETCH_ENTRIES_SUCCESS";

export const PUT_SITE_INFO_ROBOT_SUCCESS = "PUT_SITE_INFO_ROBOT_SUCCESS";

export const PUT_FILE_SUCCESS = "PUT_FILE_SUCCESS";

export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";

export const DELETE_FILE_REFER = "DELETE_FILE_REFER";

export const DELETE_PHOTO_REFER = "DELETE_PHOTO_REFER";

export const PUT_PHOTO_SUCCESS = "PUT_IMAGE_SUCCESS";

export const DELETE_PHOTO_SUCCESS = "DELETE_IMAGE_SUCCESS";

export const GET_PHOTOS_SP_SUCCESS = "GET_PHOTOS_SP_SUCCESS";

export const SEARCH_FILE_SP_SUCCESS = "SEARCH_FILE_SP_SUCCESS";

export const FETCH_SITE_ROBOT_SP_SUCCESS = "FETCH_SITE_ROBOT_SP_SUCCESS";

export const FETCH_SITE_BUILDER_SP_SUCCESS = "FETCH_SITE_BUILDER_SP_SUCCESS";

export const FETCH_SITE_SUPPLIER_SP_SUCCESS = "FETCH_SITE_SUPPLIER_SP_SUCCESS";

export const FETCH_PHOTO_TAB_SUCCESS = "FETCH_PHOTO_TAB_SUCCESS";

export const PUT_SITE_PHOTO_SP_SUCCESS = "PUT_SITE_PHOTO_SP_SUCCESS";

export const GET_ORIGINAL_PHOTO_SUCCESS = "GET_ORIGINAL_PHOTO_SUCCESS";

export const SHOW_HIDE_DIALOG_INFO_ROBOT_SUCCESS =
  "SHOW_HIDE_DIALOG_INFO_ROBOT_SUCCESS";

export const SHOW_HIDE_DIALOG_STAFF_SUCCESS = "SHOW_HIDE_DIALOG_STAFF_SUCCESS";

export const SHOW_HIDE_DIALOG_SUPPLIER_SUCCESS =
  "SHOW_HIDE_DIALOG_SUPPLIER_SUCCESS";

export const SHOW_HIDE_DIALOG_EDIT_INFO_SUCCESS =
  "SHOW_HIDE_DIALOG_EDIT_INFO_SUCCESS";

export const SHOW_HIDE_DIALOG_DELETE_INFO_SUCCESS =
  "SHOW_HIDE_DIALOG_DELETE_INFO_SUCCESS";

export const SET_INFO_VALUES_SUCCESS = "SET_INFO_VALUES_SUCCESS";

export const SHOW_HIDE_DIALOG_EDIT_FILE_SUCCESS =
  "SHOW_HIDE_DIALOG_EDIT_FILE_SUCCESS";

export const SHOW_HIDE_DIALOG_DELETE_FILE_SUCCESS =
  "SHOW_HIDE_DIALOG_DELETE_FILE_SUCCESS";

export const SET_FILE_VALUES_SUCCESS = "SET_FILE_VALUES_SUCCESS";

export const FETCH_SITE_DRAWINGS_SUCCESS = "FETCH_SITE_DRAWINGS_SUCCESS";

export const FETCH_SITE_RECORDS_EXISTS_SPOTSUC_IS_NULL_SUCCESS =
  "FETCH_SITE_RECORDS_EXISTS_SPOTSUC_IS_NULL_SUCCESS";

export const FETCH_DRAWING_IMAGE_SUCCESS = "FETCH_DRAWING_IMAGE_SUCCESS";

export const SORT_DRAWING_IMAGE_SUCCESS = "SORT_DRAWING_IMAGE_SUCCESS";

export const POST_DRAWINGS_SUCCESS = "POST_DRAWINGS_SUCCESS";

export const GET_DRAWING_WITH_SPOTS_SUCCESS = "GET_DRAWING_WITH_SPOTS_SUCCESS";

export const PUT_DRAWING_INFO_SUCCESS = "PUT_DRAWING_INFO_SUCCESS";

export const POST_SPOT_SUCCESS = "POST_SPOT_SUCCESS";

export const PUT_SPOT_SUCCESS = "PUT_SPOT_SUCCESS";

export const DELETE_DRAWING_SUCCESS = "DELETE_DRAWING_SUCCESS";

export const DELETE_SPOT_SUCCESS = "DELETE_SPOT_SUCCESS";

export const SHOW_HIDE_DIALOG_PREVIEW_PDF_SUCCESS =
  "SHOW_HIDE_DIALOG_PREVIEW_PDF_SUCCESS";

export const SHOW_HIDE_DIALOG_MAP_SUCCESS = "SHOW_HIDE_DIALOG_MAP_SUCCESS";

export const SHOW_HIDE_DIALOG_EDIT_MAP_SUCCESS =
  "SHOW_HIDE_DIALOG_EDIT_MAP_SUCCESS";

export const SHOW_HIDE_DIALOG_ADD_POINT_SUCCESS =
  "SHOW_HIDE_DIALOG_ADD_POINT_SUCCESS";

export const SHOW_HIDE_DIALOG_EDIT_POINT_SUCCESS =
  "SHOW_HIDE_DIALOG_EDIT_POINT_SUCCESS";

export const GET_SHOOTS_SUCCESS = "GET_SHOOTS_SUCCESS";

export const CHANGE_SHOOTS_EDIT_LINK = "CHANGE_SHOOTS_EDIT_LINK";

export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS";

export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";

export const GET_LIST_DRAWING_SUCCESS = "GET_LIST_DRAWING_SUCCESS";

export const GET_URL_IMAGE_SHOOT_SUCCESS = "GET_URL_IMAGE_SHOOT_SUCCESS";

// export const GET_URL_IMAGE_SHOOT_TILE_SUCCESS =
//   "GET_URL_IMAGE_SHOOT_TILE_SUCCESS";

export const GET_BLOB_DATA_LOGWALK_SUCCESS = "GET_BLOB_DATA_LOGWALK_SUCCESS"

export const UPDATE_SHOOT_HOTSPOT_INFO_NOTI = "UPDATE_SHOOT_HOTSPOT_INFO_NOTI"

export const UPDATE_IS_SHARE_URL = "UPDATE_IS_SHARE_URL"

export const SHOW_HIDE_DIALOG_DELETE_SUCCESS =
  "SHOW_HIDE_DIALOG_DELETE_SUCCESS";

export const SHOW_HIDE_DIALOG_MAP_FROM_LOG_WALK =
  "SHOW_HIDE_DIALOG_MAP_FROM_LOG_WALK";

export const SET_TAB_ELEMENT_SUCCESS = "SET_TAB_ELEMENT_SUCCESS";

export const SET_DRAWING_PHOTO = "SET_DRAWING_PHOTO";

export const GET_UNADJUSTED_PHOTO_SUCCESS = "GET_UNADJUSTED_PHOTO_SUCCESS";

export const SET_LIST_ADJUST_PHOTO_SELECTED = "SET_LIST_ADJUST_PHOTO_SELECTED";

export const SET_LIST_ADJUST_PHOTO = "SET_LIST_ADJUST_PHOTO";

export const RESET_LIST_ADJUST_PHOTO = "RESET_LIST_ADJUST_PHOTO";

export const ADD_ADJUST_IMAGE_SUCCESS = "ADD_ADJUST_IMAGE_SUCCESS";

export const GET_LIST_SHOOTS_SPOT_SUCCESS = "GET_LIST_SHOOTS_SPOT_SUCCESS";

export const SET_HISTORY_VIEWER = "SET_HISTORY_VIEWER";

export const SET_SPOT_CURRENT = "SET_SPOT_CURRENT";

export const OPEN_HOTSPOT_INFO = "OPEN_HOTSPOT_INFO";

export const CREATE_HOTSPOT_INFO = "CREATE_HOTSPOT_INFO";

export const CANCEL_CREATE_HOTSPOT_INFO = "CANCEL_CREATE_HOTSPOT_INFO";

export const CREATE_HOTSPOT_INFO_SUCCESS = "CREATE_HOTSPOT_INFO_SUCCESS";

export const DELETE_HOTSPOT_INFO_SUCCESS = "DELETE_HOTSPOT_INFO_SUCCESS";

export const CLOSE_HOTSPOT_INFO = "CLOSE_HOTSPOT_INFO";

export const SET_TYPE_SELECT_IMAGE_3D = "SET_TYPE_SELECT_IMAGE_3D";

export const SET_TYPE_DIALOG = "SET_TYPE_DIALOG";

export const ADD_IMAGE_NOT_ADJUSTED = "ADD_IMAGE_NOT_ADJUSTED";

export const SHARE_SHOOTS_UC_SUCCESS = "SHARE_SHOOTS_UC_SUCCESS";

export const LOAD_INIT_NO_IMAGE = "LOAD_INIT_NO_IMAGE";

export const SET_IMAGE_SHOOT_UN_ADJUSTED = "SET_IMAGE_SHOOT_UN_ADJUSTED";

export const RESET_STORE = "RESET_STORE";

export const RESET_LIST_SPOTS_CURRENT = "RESET_LIST_SPOTS_CURRENT";

export const RESET_LIST_INFO_HOTSPOT_CURRENT =
  "RESET_LIST_INFO_HOTSPOT_CURRENT";

export const INIT_VIEW_UNADJUSTED_IMAGE = "INIT_VIEW_UNADJUSTED_IMAGE";

export const SET_REDIRECT_FROM_URL = "SET_REDIRECT_FROM_URL";

export const SET_IS_DEFAULT_HISTORY = "SET_IS_DEFAULT_HISTORY";

export const SET_PROGRESS_BAR_LOAD_IMAGE3D = "SET_PROGRESS_BAR_LOAD_IMAGE3D";

export const CLEAR_DRAWINGS_WITH_SPOTS = "CLEAR_DRAWINGS_WITH_SPOTS";

export const GET_SITE_SP_SUCCESS = "GET_SITE_SP_SUCCESS";

export const SET_IS_COMPLETE_SITE_SUCCESS = "SET_IS_COMPLETE_SITE_SUCCESS";

export const SET_IS_BACK_SUCCESS = "SET_IS_BACK_SUCCESS";

export const COPY_DRAWINGS_SUCCESS = "COPY_DRAWINGS_SUCCESS";

export const MAXIMUM_DRAWING = 50;

export const COPY_DRAWINGS_WITH_SPOTS_SUCCESS =
  "COPY_DRAWINGS_WITH_SPOTS_SUCCESS";

export const COMPASS_SETTING_DIRECTION_SUCCESS =
  "COMPASS_SETTING_DIRECTION_SUCCESS";

export const SHOW_DIRECTION_DRAWER = "SHOW_DIRECTION_DRAWER";
export const HIDE_DIRECTION_DRAWER = "HIDE_DIRECTION_DRAWER";

export const LOGIN_COMETCHAT_SUCCESS = "LOGIN_COMETCHAT_SUCCESS";
export const LOGOUT_COMETCHAT_SUCCESS = "LOGOUT_COMETCHAT_SUCCESS";

export const SAVE_CONDITION_HOTSPOT_INFO_SUCCESS = "SAVE_CONDITION_HOTSPOT_INFO_SUCCESS";
export const SAVE_CONDITION_SITE_INFO_SUCCESS = "SAVE_CONDITION_SITE_INFO_SUCCESS";
export const SET_SHOW_SHARE_URL_DIALOG_EDIT = "SET_SHOW_SHARE_URL_DIALOG_EDIT";