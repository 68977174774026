import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";

import "../../../common/css/shop.css";

import ShopDialog from "../shopDialog";

import { fetchBuilderDetail } from "components/shops/shopDetail/service/service";
import {
  PUT_LOGBUILD_BUIDLER_SUCCESS,
  DELETE_LOGBUILD_BUIDLER_SUCCESS
} from "./constant/constant";

class RobotLogBuildInfo extends Component {
  state = {
    isDialogShow: false,
    isDialogDeleteShow: false
  };

  componentDidMount() {
    this.props.fetchBuilderDetail(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    const nextType = nextProps.type;
    const currentType = this.props.type;
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.props.fetchBuilderDetail(nextProps.match.params.id);
    }

    if (currentType !== nextType && nextType === PUT_LOGBUILD_BUIDLER_SUCCESS) {
      this.props.fetchSumaryData(nextProps.match.params.id);
      this.setState({ isDialogShow: false });
    }

    if (nextType === DELETE_LOGBUILD_BUIDLER_SUCCESS) {
      this.props.history.push("/logbuild/builders");
    }
  }

  toggleDialog = () => {
    this.setState({
      isDialogShow: !this.state.isDialogShow
    });
  };

  toggleDialogDelete = () => {
    this.setState({
      isDialogDeleteShow: !this.state.isDialogDeleteShow,
      isDialogShow: false
    });
  };

  render() {
    const { isDialogShow, isDialogDeleteShow } = this.state;
    const { builderDetail, match } = this.props;

    return (
      <Grid className="shop-customer-tab">
        <Card className="shop-customer-box-lending">
          <CardContent>
            <Grid container justify="center" alignItems="center">
              <Grid className="lending-title">
                <Typography variant="subtitle1" className="font-family">
                  ロボット貸出台数
                </Typography>
              </Grid>
              <Typography variant="h2">
                {builderDetail.robotCount}
              </Typography>
            </Grid>
          </CardContent>
        </Card>

        <Card className="shop-customer-box-info">
          <Grid container justify="space-between">
            <Typography variant="h6" className="font-family text-bold">
              顧客情報
            </Typography>
            <Fab
              disableRipple
              onClick={this.toggleDialog}
              size="small"
              variant="extended"
              className="edit-button"
            >
              <EditIcon className="icon-margin" />
              編集
            </Fab>
          </Grid>
          <CardContent className="shop-customer-info">
            <Grid container>
              <Grid item md={6}>
                <TableContainer>
                  <Table aria-label="table">
                    <TableBody className="">
                      <TableRow>
                        <TableCell>ふりがな​</TableCell>
                        <TableCell>{builderDetail.furigana}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>郵便番号</TableCell>
                        <TableCell>〒{builderDetail.zipCode}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>住所​</TableCell>
                        <TableCell>{builderDetail.address}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>電話番号</TableCell>
                        <TableCell>{builderDetail.phone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="keep-all">
                          メールアドレス​
                        </TableCell>
                        <TableCell>{builderDetail.email}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>初期登録URL​</TableCell>
                        <TableCell>{builderDetail.signUpUrl}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item md={6}>
                <TableContainer>
                  <Table aria-label="table">
                    <TableBody className="">
                      <TableRow>
                        <TableCell>登録日​</TableCell>
                        <TableCell>
                          {builderDetail.agreeMentDate &&
                            format(
                              new Date(builderDetail.agreeMentDate),
                              "yyyy年M月d日"
                            )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>契約更新日</TableCell>
                        <TableCell>
                          {builderDetail.contractRenewDate &&
                            format(
                              new Date(builderDetail.contractRenewDate),
                              "yyyy年M月d日"
                            )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>担当者名​</TableCell>
                        <TableCell>{builderDetail.agreementUser}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>担当者電話番号</TableCell>
                        <TableCell>
                          {builderDetail.agreementUserPhone}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="keep-all">
                          担当者メールアドレス​
                        </TableCell>
                        <TableCell>{builderDetail.agreementUserMail}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>利用停止日</TableCell>
                        <TableCell>
                          {builderDetail.cancellationDate &&
                            format(
                              new Date(builderDetail.cancellationDate),
                              "yyyy年M月d日"
                            )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {(isDialogShow || isDialogDeleteShow) && (
          <ShopDialog
            isDialogShow={isDialogShow}
            isDialogDeleteShow={isDialogDeleteShow}
            toggleDialogDelete={this.toggleDialogDelete}
            onCloseDialog={this.toggleDialog}
            isEdit={true}
            builderUniqueCode={match.params.id}
          />
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  builderDetail: state.builderReducer.builderDetailReducer.builderDetail,
  type: state.builderReducer.builderDetailReducer.type
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBuilderDetail
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RobotLogBuildInfo));
