import {
  updateUserPasswordSuccess,
  updateUserLogBuildPasswordSuccess,
  fetchProfileSuccess,
  putProfileSuccess,
  putPasswordStart,
  putProfileStart
} from "../action/action";
import {
  dispatchError,
  enableLoading,
  finishLoading,
  fetchStores
} from "../../../common/Stores/service/service";
import { api } from "../../../common/api/api";
import { fetchJob } from "components/users/Staff/service/service";

export const updateUserPassword = newPassWord => {
  return dispatch => {
    dispatch(enableLoading());
    dispatch(putPasswordStart());
    api
      .put(`/builder/user-password`, { password: newPassWord })
      .then(res => {
        dispatch(updateUserPasswordSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const updateUserLogBuildPassword = newPassWord => {
  return dispatch => {
    dispatch(enableLoading());
    dispatch(putPasswordStart());
    api
      .put(`/logbuild/user-password`, { password: newPassWord })
      .then(res => {
        dispatch(updateUserLogBuildPasswordSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchUserProfile = () => {
  return dispatch =>
    api
      .get(`/builder/user-profile`)
      .then(res => {
        dispatch(fetchProfileSuccess(res.data));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
};

export const putUserProfile = (profile, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    dispatch(putProfileStart());
    api
      .put(`/builder/user-profile`, profile)
      .then(res => {
        dispatch(putProfileSuccess());
        dispatch(dispatchError(null));
        dispatch(finishLoading());
      })
      .catch(err => {
        if(handleError && err.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(err));
        }
      });
  };
};

export const fetchProfileData = () => {
  return dispatch => {
    dispatch(enableLoading());
    Promise.all([
      dispatch(fetchUserProfile()),
      dispatch(fetchJob()),
      dispatch(fetchStores())
    ])
      .then(() => {
        dispatch(finishLoading());
      })
      .catch(errors => {
        dispatch(dispatchError(errors));
      });
  };
};
