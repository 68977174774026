import {
  PUT_USER_PASSWORD_BUILDER_SUCCESS,
  PUT_USER_PASSWORD_LOGBUILD_SUCCESS,
  FETCH_USER_PROFILE_SUCCESS,
  PUT_USER_PROFILE_SUCCESS,
  PUT_USER_PROFILE_START,
  PUT_USER_PASSWORD_START
} from "../constant/constant";

export const updateUserPasswordSuccess = userPassword => {
  return {
    type: PUT_USER_PASSWORD_BUILDER_SUCCESS,
    userPassword
  };
};

export const updateUserLogBuildPasswordSuccess = userLogBuildPassword => {
  return {
    type: PUT_USER_PASSWORD_LOGBUILD_SUCCESS,
    userLogBuildPassword
  };
};

export const fetchProfileSuccess = profile => {
  return {
    type: FETCH_USER_PROFILE_SUCCESS,
    profile
  };
};

export const putProfileSuccess = () => {
  return {
    type: PUT_USER_PROFILE_SUCCESS
  };
};

export const putProfileStart = () => {
  return {
    type: PUT_USER_PROFILE_START
  };
};

export const putPasswordStart = () => {
  return {
    type: PUT_USER_PASSWORD_START
  };
};
