import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { api } from "../../../../../../common/api/api";
import axios from "axios";
import { CometChatMessageActions, CometChatThreadedMessageReplyCount, CometChatReadReceipt } from "../";
import { CometChatMessageReactions } from "../Extensions";

import { CometChatContext } from "../../../util/CometChatContext";
import * as enums from "../../../util/enums.js";
import { checkMessageForExtensionsData } from "../../../util/common";

import { theme } from "../../../resources/theme";

import {
	messageContainerStyle,
	messageWrapperStyle,
	messageImgWrapper,
	messageInfoWrapperStyle,
	messageReactionsWrapperStyle,
	messageTxtWrapperStyle
} from "./style";

import srcIcon from "./resources/1px.png";
class CometChatSenderImageMessageBubble extends React.Component {
	static contextType = CometChatContext;
	timer = null;
	loggedInUser;

	constructor(props, context) {
		super(props, context);
		this._isMounted = false;
		this.imgRef = React.createRef();

		this.state = {
			imageUrl: srcIcon,
			isHovering: false,
		};

		this.context.getLoggedinUser().then(user => {
			this.loggedInUser = { ...user };
		});
	}

	shouldComponentUpdate(nextProps, nextState) {
		const currentMessageStr = JSON.stringify(this.props.message);
		const nextMessageStr = JSON.stringify(nextProps.message);

		if (currentMessageStr !== nextMessageStr
			|| this.state.imageUrl !== nextState.imageUrl
			|| this.state.isHovering !== nextState.isHovering) {
			return true;
		}

		return false;
	}

	componentDidMount() {
		this.getData();
		this._isMounted = true;
		// this.setImage();
	}

	componentDidUpdate(prevProps) {
		const previousMessageStr = JSON.stringify(prevProps.message);
		const currentMessageStr = JSON.stringify(this.props.message);

		if (previousMessageStr !== currentMessageStr) {
			this.setImage();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	chooseImage = thumbnailGenerationObject => {
		const smallUrl = thumbnailGenerationObject["url_small"];
		const mediumUrl = thumbnailGenerationObject["url_medium"];

		const mq = window.matchMedia(this.props.theme.breakPoints[0]);

		let imageToDownload = mediumUrl;
		if (mq.matches) {
			imageToDownload = smallUrl;
		}

		return imageToDownload;
	};

	setImage = () => {
		const thumbnailGenerationData = checkMessageForExtensionsData(this.props.message, "thumbnail-generation");
		console.log(thumbnailGenerationData);
		if (thumbnailGenerationData) {
			const mq = window.matchMedia(this.props.theme.breakPoints[0]);
			mq.addListener(() => {
				const imageToDownload = this.chooseImage(thumbnailGenerationData);
				let img = new Image();
				img.src = imageToDownload;
				img.onload = () => {
					if (this._isMounted) {
						this.setState({ imageUrl: img.src });
					}
				};
			});

			const imageToDownload = this.chooseImage(thumbnailGenerationData);
			this.downloadImage(imageToDownload)
				.then(response => {
					let img = new Image();
					img.src = imageToDownload;
					img.onload = () => {
						if (this._isMounted) {
							this.setState({ imageUrl: img.src });
						}
					};
				})
				.catch(error => console.error(error));
		} else {
			this.setMessageImageUrl();
		}
	};



	setMessageImageUrl = async () => {
		let img = new Image();
		if (this.props.message.data.hasOwnProperty("url")) {
			// Check image was saved in logbuild system
			if (this.props.message.data.uniqueCode) {
				let data = await this.fetch(this.props.message.data.uniqueCode);
				if (data.Url) {
					img.src = data.Url;
				}
			} else {
				img.src = this.props.message.data.url;
			}

		} else if (this.props.message.data.hasOwnProperty("file")) {
			const reader = new FileReader();
			reader.onload = function () {
				img.src = reader.result;
			};
			reader.readAsDataURL(this.props.message.data.file);
		}
		img.onload = () => {
			if (this._isMounted) {
				this.setState({ imageUrl: img.src });
			}
		};
	};

	async fetch(id) {
		if (this.source) {
			this.source.cancel();
		}
		const CancelToken = axios.CancelToken;
		this.source = CancelToken.source();
		const classification = window.location.pathname.includes("/sp/")
			? "supplier"
			: "builder";
		var info = await api.get(`/${classification}/site-photos/${id}/info`,
			{
				cancelToken: this.source.token
			})
			.then(res => {
				if (res.data === undefined) return Promise.reject();
				return res.data;
			})
			.catch(e => {
				this.setState({ FetchDone: false, isDeleted: true });
				return {
					Status: false,
					Code: e?.response?.status
				};
			});
		if (info.Status === false) {
			return info;
		}
		var isBlob = info.blob ? true : false;
		var path = isBlob ? info.blob.resized.replace(/^\/api/, '') : `/${classification}/site-photos/${id}/thumbnail`;
		const res = await api.get(path,
			{
				responseType: isBlob ? undefined : "arraybuffer",
				cancelToken: this.source.token
			})
			.then(res => {
				if (res.data === undefined) return Promise.reject();
				if (isBlob) {
					var url = res.data.blobData;
				} else {
					var blob = new Blob([res.data], { type: "image/jpeg" });
					var url = URL.createObjectURL(blob);
				}
				return {
					Status: true,
					Code: 200,
					Url: url
				};
			})
			.catch(e => {
				this.setState({ FetchDone: false, isDeleted: true });
				return {
					Status: false,
					Code: e?.response?.status
				};
			});

		return await res;
	}


	downloadImage(imgUrl) {
		const promise = new Promise((resolve, reject) => {
			const xhr = new XMLHttpRequest();
			xhr.open("GET", imgUrl, true);
			xhr.responseType = "blob";

			xhr.onload = () => {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						this.timer = null;
						resolve(imgUrl);
					} else if (xhr.status === 403) {
						this.timer = setTimeout(() => {
							this.downloadImage(imgUrl)
								.then(response => resolve(imgUrl))
								.catch(error => reject(error));
						}, 800);
					}
				} else {
					reject(xhr.statusText);
				}
			};

			xhr.onerror = event => reject(new Error("There was a network error.", event));
			xhr.ontimeout = event => reject(new Error("There was a timeout error.", event));
			xhr.send();
		});

		return promise;
	}

	open = () => {
		this.props.actionGenerated(enums.ACTIONS["VIEW_ORIGINAL_IMAGE"], this.props.message);
	};

	handleMouseHover = (isHovering) => {
		this.setState({ isHovering });
	};

	async getData() {
		try {
			if (this.props.message.data.isDeleted) {
				this.setState({
					isDeleted: true
				}, () => {
					this.setImage();
				})
			} else {
				this.setImage();
			}
		} catch (err) {
			console.log(err);
			return false;
		}
	}

	render() {
		const { isDeleted } = this.state;
		let messageReactions = null;
		let messageImageWrapper = null;
		const reactionsData = checkMessageForExtensionsData(this.props.message, "reactions");
		if (reactionsData) {
			if (Object.keys(reactionsData).length) {
				messageReactions = (
					<div css={messageReactionsWrapperStyle()} className="message__reaction__wrapper">
						<CometChatMessageReactions message={this.props.message} actionGenerated={this.props.actionGenerated} />
					</div>
				);
			}
		}

		let toolTipView = null;
		if (this.state.isHovering) {
			toolTipView = <CometChatMessageActions message={this.props.message} actionGenerated={this.props.actionGenerated} />;
		}

		if (this.props.message.data.isDeleted) {
			messageImageWrapper = (<div css={messageTxtWrapperStyle(this.context)} className="message__txt__wrapper"
				style={{ backgroundColor: "#FFFFFF", color: "rgba(0, 0, 0, 0.87)", boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)" }}>
				<p className="message__txt">
					⚠️ ファイルが削除されました。
				</p>
			</div>)
		}
		else {
			messageImageWrapper = (<div css={messageImgWrapper(this.context)} onClick={this.open} className="message__img__wrapper">
				<img
					src={this.state.imageUrl}
					alt={this.state.imageUrl}
					ref={el => {
						this.imgRef = el;
					}}
					style={{ boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)" }}
					onLoad={this.props.loadedCallback}
				/>
			</div>)
		}

		return (
			<div css={messageContainerStyle()} className="sender__message__container message__image" onMouseEnter={() => this.handleMouseHover(true)} onMouseLeave={() => this.handleMouseHover(false)}
				style={{ marginBottom: "10px" }}>
				{toolTipView}

				<div style={{ display: "flex", justifyContent: "flex-end", alignSelf: "flex-end" }}>
					<div css={messageInfoWrapperStyle()} className="message__info__wrapper">
						<CometChatThreadedMessageReplyCount message={this.props.message} actionGenerated={this.props.actionGenerated} />
						<CometChatReadReceipt message={this.props.message} />
					</div>

					<div css={messageWrapperStyle()} className="message__wrapper">
						{messageImageWrapper}
					</div>
				</div>

				{this.props.message?.sender?.uid === this.loggedInUser?.uid &&
					this.props.message.hasOwnProperty("error") &&
					<div style={{ alignSelf: "flex-end", paddingRight: "2px", fontSize: "10px", marginTop: "1px" }}>送信失敗</div>}

				{messageReactions}
			</div>
		);
	}
}

// Specifies the default values for props:
CometChatSenderImageMessageBubble.defaultProps = {
	theme: theme,
	actionGenerated: {},
};

CometChatSenderImageMessageBubble.propTypes = {
	theme: PropTypes.object,
	actionGenerated: PropTypes.func.isRequired,
	message: PropTypes.object.isRequired,
};

export { CometChatSenderImageMessageBubble };