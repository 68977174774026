import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import ViewFileItem from "./ViewFileItem";
import EditFileName from "./EditFileName";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import IconPlusUploadFile from "../hotspotInfoIcons/IconPlusUploadFile";
import ArrowBack from "../hotspotInfoIcons/ArrowBack";
import SvgButtonHover from "./SvgButtonHover";
import IconTrashCan from "../hotspotInfoIcons/IconTrashCan";
import IconFullImage from "../hotspotInfoIcons/IconFullImage";
import IconUploadFile from "../hotspotInfoIcons/IconUploadFile";
import { api } from "../../../../../../common/api/api";
import { isMobile } from "react-device-detect";
import ChooseExistedFileDialog from "../../ChooseExistedFileDialog";
import MenuUploadFile from "../../ChooseExistedFileDialog/MenuUploadFile/MenuUploadFile";

const useStyles = makeStyles({
  root: {},

  uploadFileMode: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    border: "2px dashed #C7C7C7",
    padding: "24px 0px",
    borderRadius: "8px",
    marginBottom: "20px",
    "& p": {
      fontSize: "17px",
      fontWeight: "normal",
      lineHeight: "23px",
      color: "#C7C7C7",
      textAlign: "center"
    },
    "& span": {
      width: "60px",
      height: "60px",
      margin: "36px auto"
    },
    "& button": {
      width: "220px",
      height: "45px",
      fontSize: "17px",
      lineHeight: "45px",
      textAlign: "center",
      color: "white",
      borderRadius: "4px",
      backgroundColor: "#00356D",
      margin: "0px auto"
    }
  },
  backToListView: {
    width: "100%",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    backgroundColor: "#C7C7C7",
    fontSize: "17px",
    fontWeight: "bold",
    margin: "20px 0px"
  },

  viewFileWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  viewFileActions: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0px"
  },
  dialogTitle: {
    textAlign: "center"
  }
});
function ViewListFile(props) {
  const {
    files,
    currentHotspotInfoId,
    uploadFileSuccess,
    saveFileNameSuccess,
    deleteFileSuccess,
    shoot,
    sitesUC
  } = props;

  const [isUploadFile, setIsUploadFile] = useState(false);
  const [currentShowFile, setCurrentShowFile] = useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [showMenuUploadFile, setShowMenuUploadFile] = useState(false);
  const [openChooseExistFileDialog, setOpenChooseExistFileDialog] =
    useState(false);
  const classes = useStyles();
  const fileRef = useRef(null);
  const fileCameraRef = useRef(null);
  let location = useLocation();
  let history = useHistory();
  useEffect(() => {
    if (files.length === 0) {
      setIsUploadFile(true);
      setCurrentShowFile(null);
    } else {
      setIsUploadFile(false);

      if (!currentShowFile) {
        setCurrentShowFile(files[0]);
      } else {
        const currentIndex = files.findIndex(
          file => file.uniqueCode === currentShowFile.uniqueCode
        );
        if (currentIndex === -1) {
          setCurrentShowFile(files[0]);
        } else {
          setCurrentShowFile(files[currentIndex]);
        }
      }
    }
  }, [files]);

  const handleUploadFiles = files => {
    const exts = ".pdf,.jpg,.jpeg,.png"
      .split(",")
      .map(ext => ext.toLowerCase());
    let containsInvalidMimeType = false;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      containsInvalidMimeType = !exts.some(ext =>
        file.name.toLowerCase().endsWith(ext)
      );
      if (containsInvalidMimeType) {
        break;
      }
    }
    if (containsInvalidMimeType) {
      alert("無効なファイル形式を含んでいます");
      return;
    }

    let getBase64AllFile = [];
    for (let i = 0; i < files.length; i++) {
      getBase64AllFile.push(getBase64(files[i]));
    }
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    Promise.all(getBase64AllFile)
      .then(data =>
        api.post(
          `${classification}/logwalk-viewer/infos/${currentHotspotInfoId}/files`,
          { files: data }
        )
      )
      .then(() => uploadFileSuccess())
      .catch(() => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        if (location.pathname.includes("/sp/")) {
          history.replace(
            `/sp/logwalk-viewer/shoots/${shoot.uniqueCode}?info=${currentHotspotInfoId}`
          );
          history.go(0);
        } else {
          history.replace(
            `/logwalk-viewer/shoots/${shoot.uniqueCode}?info=${currentHotspotInfoId}`
          );
          history.go(0);
        }
      });
  };

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve({
          name: file.name.substr(0, file.name.lastIndexOf(".")),
          file: reader.result.slice(reader.result.indexOf(",") + 1)
        });
      };
      reader.onerror = error => reject(error);
    });
  };

  const handleDeleteFile = () => {
    if (currentShowFile) {
      const classification = location.pathname.includes("/sp/")
        ? "supplier"
        : "builder";
      api
        .delete(
          `/${classification}/logwalk-viewer/info-files/${currentShowFile.uniqueCode}`,
          {
            data: {
              infoUCs: currentHotspotInfoId,
              shootUCs: shoot.uniqueCode
            }
          })
        .then(() => {
          deleteFileSuccess(currentShowFile.uniqueCode);
          setOpenConfirmDelete(false);
        });
    }
  };

  const nextFile = () => {
    const currentIndex = files.findIndex(
      file => file.uniqueCode === currentShowFile.uniqueCode
    );

    if (currentIndex === files.length - 1) return;
    setCurrentShowFile(files[currentIndex + 1]);
  };

  const previousFile = () => {
    const currentIndex = files.findIndex(
      file => file.uniqueCode === currentShowFile.uniqueCode
    );

    if (currentIndex === 0) return;
    setCurrentShowFile(files[currentIndex - 1]);
  };

  const showFullFile = () => {
    if (currentShowFile) {
      var isBlob = currentShowFile.blob ? true : false;
      var path = isBlob ? currentShowFile.blob.org.replace(/^\/api/, '') : currentShowFile.file.slice(4);
      api
        .get(path, {
          responseType: isBlob ? undefined : "arraybuffer"
        })
        .then(res => {
          const type =
            currentShowFile.extension === "pdf"
              ? "application/pdf"
              : "image/jpeg";
          if (isBlob) {
            var url = res.data.blobData;
            if (currentShowFile.extension === "pdf") {
              window.open(url, "_blank");
            } else {
              var w = window.open('', "_blank");
              w.document.write(`<img src="${url}">`);
            }
          } else {
            var blob = new Blob([res.data], { type: type });
            var url = URL.createObjectURL(blob);
            window.open(url, "_blank");
          }
        })
        .catch(() => {
          alert("エラーが発生しました。 お手数ですが、再度お試しください。");
          if (location.pathname.includes("/sp/")) {
            history.replace(
              `/sp/logwalk-viewer/shoots/${shoot.uniqueCode}?info=${currentHotspotInfoId}`
            );
            history.go(0);
          } else {
            history.replace(
              `/logwalk-viewer/shoots/${shoot.uniqueCode}?info=${currentHotspotInfoId}`
            );
            history.go(0);
          }
        });
    }
  };

  const handleSaveName = fileName => {
    if (fileName !== "") {
      const classification = location.pathname.includes("/sp/")
        ? "supplier"
        : "builder";
      api
        .put(
          `/${classification}/logwalk-viewer/info-files/${currentShowFile.uniqueCode}`,
          {
            infosUC: currentHotspotInfoId,
            name: fileName 
          }
        )
        .then(() => {
          saveFileNameSuccess(currentShowFile.uniqueCode, fileName);
        });
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    handleUploadFiles(e.dataTransfer.files);
  };

  return (
    <React.Fragment>
      {isUploadFile ? (
        <React.Fragment>
          <p
            style={{
              textAlign: "center",
              fontSize: "17px",
              color: "#333333",
              margin: "30px 0px"
            }}
          >
            ファイル（画像 or PDF）を追加
          </p>
          <div
            className={classes.uploadFileMode}
            onDrop={handleDrop}
            onDragEnter={e => e.preventDefault()}
            onDragOver={e => e.preventDefault()}
            onDragLeave={e => e.preventDefault()}
          >
            <p>ドラッグ&ドロップまたは</p>
            <span>
              <IconPlusUploadFile />
            </span>
            {isMobile && (
              <button
                onClick={() => {
                  fileCameraRef.current.accept = "image/*";
                  fileCameraRef.current.click();
                }}
                style={{ touchAction: "manipulation", marginBottom: "20px" }}
              >
                写真を撮る
              </button>
            )}
            <button
              onClick={() => {
                setShowMenuUploadFile(true);
              }}
              style={{ touchAction: "manipulation" }}
            >
              ファイルを追加
            </button>
          </div>

          {files.length > 0 && (
            <button
              className={classes.backToListView}
              style={{ backgroundColor: "#C7C7C7" }}
              onClick={() => setIsUploadFile(false)}
            >
              <span
                style={{ width: "45px", height: "45px", overflow: "hidden" }}
              >
                <ArrowBack />
              </span>
              <span>戻る</span>
            </button>
          )}
        </React.Fragment>
      ) : (
        <div className={classes.viewFileWrapper}>
          {currentShowFile && (
            <React.Fragment>
              <EditFileName
                currentFile={currentShowFile}
                handleSaveName={handleSaveName}
              />
              <ViewFileItem
                files={files}
                currentFile={currentShowFile}
                nextFile={nextFile}
                previousFile={previousFile}
              />
              <div className={classes.viewFileActions}>
                <SvgButtonHover
                  Icon={IconFullImage}
                  title="拡大表示"
                  handleClick={showFullFile}
                  activeBg="#00356D"
                />

                <SvgButtonHover
                  Icon={IconUploadFile}
                  title="アップロード"
                  handleClick={() => setIsUploadFile(true)}
                  activeBg="#00356D"
                  withBorder
                />

                <SvgButtonHover
                  Icon={IconTrashCan}
                  title="削除"
                  handleClick={() => setOpenConfirmDelete(true)}
                  activeBg="red"
                />
              </div>
            </React.Fragment>
          )}
        </div>
      )}

      <Dialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ backgroundColor: "#e0e0e0eb" }}
        className={classes.dialogDelete}
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          {"削除しますか?"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleDeleteFile}
            className="btn-dialog-yes"
            style={{ color: "#FFFFFF", backgroundColor: "#00356D" }}
          >
            Yes
          </Button>
          <Button
            onClick={() => setOpenConfirmDelete(false)}
            className="btn-dialog-no"
            autoFocus
            style={{ color: "#FFFFFF", backgroundColor: "#C7C7C7" }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {showMenuUploadFile && (
        <MenuUploadFile
          uploadNewFile={() => {
            fileRef.current.accept = ".pdf,.jpg,.jpeg,.png";
            fileRef.current.click();
          }}
          chooseExistedFile={() => setOpenChooseExistFileDialog(true)}
          closeMenu={() => setShowMenuUploadFile(false)}
        />
      )}


      <ChooseExistedFileDialog
        mode="update-info"
        isOpen={openChooseExistFileDialog}
        onClose={() => setOpenChooseExistFileDialog(false)}
        sitesUC={sitesUC}
        excludeSelects={files}
        infoUC={currentHotspotInfoId}
        acceptSelectedSuccess={() => {
          uploadFileSuccess();
          setOpenChooseExistFileDialog(false);
        }}
        shoot={shoot}
      />

      <input
        type="file"
        id="file"
        accept=".pdf,.jpg,.jpeg,.png"
        onChange={e => handleUploadFiles(e.target.files)}
        onClick={e => (e.target.value = null)}
        style={{ display: "none" }}
        ref={fileRef}
        multiple
      />
      <input
        type="file"
        id="file"
        accept="image/*"
        onChange={e => handleUploadFiles(e.target.files)}
        onClick={e => (e.target.value = null)}
        style={{ display: "none" }}
        ref={fileCameraRef}
        multiple
        capture
      />
    </React.Fragment>
  );
}

ViewListFile.propTypes = {};

export default ViewListFile;
