import React from "react";
import { Fab, TableCell, TableRow } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { format } from "date-fns";

function FilePdf(props) {
  const {
    fileInfo,
    excludeSelects,
    selectedFiles,
    selectFile,
    isTabletOrMobile
  } = props;

  return (
    <TableRow
      key={fileInfo.fileId}
      style={{ cursor: "pointer" }}
      hover
      onClick={() => selectFile(fileInfo)}
    >
      {isTabletOrMobile ? (
        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
          {excludeSelects.find(item => item.uniqueCode === fileInfo.fileId) ? (
            <span style={{ fontSize: "30px", color: "#D0CECE" }}>
              <CheckCircleIcon fontSize="inherit" color="inherit" />
            </span>
          ) : selectedFiles.find(item => item.fileId === fileInfo.fileId) ? (
            <span style={{ fontSize: "30px", color: "#92D050" }}>
              <CheckCircleIcon fontSize="inherit" color="inherit" />
            </span>
          ) : (
            <span style={{ fontSize: "30px", color: "#D0CECE" }}>
              <RadioButtonUncheckedIcon fontSize="inherit" color="inherit" />
            </span>
          )}

          <div style={{ flexGrow: "1", width: "100%", marginLeft: "6px", fontSize: "14px" }}>
            <p style={{fontWeight: "bold", fontSize: "14px"}}>{fileInfo.name}</p>
            <div style={{display: "flex", flexWrap: "wrap"}}>
              <span style={{fontSize: "12px", color: "#b1b1b1", marginRight: "16px"}}>{format(new Date(fileInfo.date), "yyyy年M月d日")}</span>
              <span style={{fontSize: "12px", color: "#b1b1b1"}}>{fileInfo.creator}</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <TableCell className="padding-first-td">
            {excludeSelects.find(
              item => item.uniqueCode === fileInfo.fileId || item.fileId === fileInfo.fileId
            ) ? (
              <span style={{ fontSize: "30px", color: "#D0CECE" }}>
                <CheckCircleIcon fontSize="inherit" color="inherit" />
              </span>
            ) : selectedFiles.find(item => item.fileId === fileInfo.fileId) ? (
              <span style={{ fontSize: "30px", color: "#92D050" }}>
                <CheckCircleIcon fontSize="inherit" color="inherit" />
              </span>
            ) : (
              <span style={{ fontSize: "30px", color: "#D0CECE" }}>
                <RadioButtonUncheckedIcon fontSize="inherit" color="inherit" />
              </span>
            )}
          </TableCell>
          <TableCell>{fileInfo.name}</TableCell>
          <TableCell className="text-center">{fileInfo.creator}</TableCell>
          <TableCell className="text-center">
            {format(new Date(fileInfo.date), "yyyy年M月d日")}
          </TableCell>
        </>
      )}
    </TableRow>
  );
}

export default FilePdf;
