import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Component from "../Common";
import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Checkbox from "@material-ui/core/Checkbox";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";
import SelectControl from "../../../../common/SelectControl";
import {
  getHistoryListAdjustPhoto,
  getListAdjustPhotoSelected,
  getListAdjustPhoto,
  getTypeDialog,
  getUrlImageShootsUCNotAdjusted,
  getDrawingsList,
  getListShootsSpot
} from "./reducer";
import { compose } from "redux";
import MenuItem from "@material-ui/core/MenuItem";
import {
  setListAdjustPhoto,
  setListAdjustPhotoSelected,
  resetListAdjustPhoto,
  getUrlImageShootSuccess,
  setTypeDialog,
  setImageShootUnAdjusted
} from "../action/action";
import { getPhotoShoots } from "../service/service";
import { TYPE_DIALOG } from "./constants";
import { MODE_DEVICE } from "components/sites/site/LogWalkViewer/constants";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { format } from "date-fns";
import {
  GET_UNADJUSTED_PHOTO_SUCCESS,
  RESET_LIST_ADJUST_PHOTO,
  SET_LIST_ADJUST_PHOTO_SELECTED
} from "../constant/constant";
import Pc from "../../../../common/breakpoints/Pc";
import Sp from "../../../../common/breakpoints/Sp";
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/ja";
import { api } from "../../../../common/api/api";
import axios from "axios";
import { arrayBufferToBase64 } from "../../../../utils/mapper";

class PreviewImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: ""
    };
  }

  componentWillMount() {
    this.fetch();
  }

  fetch() {
    if (this.props.apiPath) {
      let isBlob = true;
      if (this.props.apiPath.includes("/file?bin")) {
        isBlob = false;
      }

      api
        .get(
          this.props.apiPath.replace("/api", ""),
          isBlob
            ? {}
            : {
                responseType: "arraybuffer"
              }
        )
        .then(res => {
          if (res.data) {
            this.setState({ isTile: res.data.geoType === "Tile" });
            if (isBlob) {
              this.setState({
                imgSrc:
                  res.data.geoType === "EQR"
                    ? res.data.blobDataLst[0]
                    : res.data.blobDataLst.find(o => o.layer === "/preview.jpg")
                        .url
              });
            } else {
              this.setState({
                imgSrc: `data:image/jpeg;base64,${arrayBufferToBase64(
                  res.data
                )}`
              });
            }
          }
        })
        .catch(e => {
          if (e?.response?.status === 404) {
          }
        });
    }
  }

  render() {
    return this.state.imgSrc ? (
      this.state.isTile ? (
        <div
          onClick={() => {
            this.props.onSelected(this.props.currentPage);
          }}
        >
          <div
            class="sprite img0"
            style={{
              backgroundImage: `url(${this.state.imgSrc})`
            }}
          ></div>
          <div
            class="sprite img1"
            style={{
              backgroundImage: `url(${this.state.imgSrc})`
            }}
          ></div>
          <div
            class="sprite img2"
            style={{
              backgroundImage: `url(${this.state.imgSrc})`
            }}
          ></div>
        </div>
      ) : (
        <img
          src={this.state.imgSrc}
          alt="Preview"
          onClick={() => {
            this.props.onSelected(this.props.currentPage);
          }}
          className="img-adjust"
        />
      )
    ) : (
      <div
        style={{ backgroundColor: "black" }}
        onClick={() => {
          this.props.onSelected(this.props.currentPage);
        }}
        className="img-adjust"
      ></div>
    );
  }
}

class AdjustImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createAt: "",
      pages: 0,
      currentPage: 0,
      contentPages: [],
      selectedPage: null,
      checked: "",
      isShowDatePicker: false,
      isShowTimePicker: false,
      selectedDays: [],
      histories: [],
      disabledDays: [],
      times: [],
      pickedDay: "",
      currentHistory: null,
      isDisabled: false,
      isFirstLoad: false,
      currentIdx: 0
    };
  }

  componentWillMount() {
    const { historyListAdjustPhoto, classification, logWalkInfo, match } =
      this.props;
    if (historyListAdjustPhoto && historyListAdjustPhoto.length) {
      this.setState({ currentHistory: historyListAdjustPhoto[0] });
    }

    this.props.onGetUnAdjustPhoto(
      logWalkInfo.sitesUC ? logWalkInfo.sitesUC : match.params.id,
      classification
    );
    if (
      historyListAdjustPhoto &&
      historyListAdjustPhoto.length &&
      historyListAdjustPhoto[0].shoots
    ) {
      this.props.resetListAdjustPhoto();
    }
  }

  initLoad = historyListAdjustPhoto => {
    const { logWalkInfo } = this.props;
    let selectHistory;
    // What is this?
    // if (logWalkInfo.recordsUC) {
    //   // LogWalkViewer
    //   if (logWalkInfo.spotsUC) {
    //     selectHistory = historyListAdjustPhoto[0];
    //   } else {
    //     // shoot doesnt belong to a spot => find it
    //     selectHistory = historyListAdjustPhoto.find(
    //       e => e.uniqueCode === logWalkInfo.recordsUC
    //     );
    //   }
    // } else {
      // ViewUnAdjustedImage
      selectHistory = historyListAdjustPhoto[0];
    // }
    if (!selectHistory) {
      selectHistory = historyListAdjustPhoto[0];
    }
    this.setState({
      createAt: selectHistory.uniqueCode,
      pages: selectHistory.shoots && selectHistory.shoots.length,
      isFirstLoad: false
    });
    for (const item of selectHistory.shoots) {
      this.props.getAdjustPhoto(item);
    }
  };

  componentDidMount() {
    const { historyListAdjustPhoto } = this.props;
    if (
      !this.props.listAdjustPhoto.length ||
      !this.props.viewerUnAdjustedImage
    ) {
      this.initLoad(historyListAdjustPhoto);
    } else {
      this.setState({ isFirstLoad: true });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.type === GET_UNADJUSTED_PHOTO_SUCCESS){
      this.props.onSetListAdjustPhotoSelected(this.state.currentHistory);
    }
    
    if (
      prevProps.listAdjustPhoto !== this.props.listAdjustPhoto &&
      (this.props.listAdjustPhoto.length ===
       this.props.listAdjustPhotoSelected.shoots.length || this.props.type === SET_LIST_ADJUST_PHOTO_SELECTED) &&
      !this.state.selectedPage
    ) {
      this.onSelected(0);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.changeClickOutsideHistory !==
      nextProps.changeClickOutsideHistory
    ) {
      this.toggleDatePicker(false);
    }

    if (
      nextProps.type === GET_UNADJUSTED_PHOTO_SUCCESS &&
      !nextProps.listAdjustPhoto.length
    ) {
      const itemSelectedCurrent = this.props.historyListAdjustPhoto.find(
        item => item.uniqueCode === this.state.currentHistory.uniqueCode
      );

      const itemSelectedNext = nextProps.historyListAdjustPhoto.find(
        item => item.uniqueCode === this.state.currentHistory.uniqueCode
      );

      if (!itemSelectedNext) {
        this.setState({ currentHistory: nextProps.historyListAdjustPhoto[0] });

        this.setState({
          pages: nextProps.historyListAdjustPhoto[0]
            ? nextProps.historyListAdjustPhoto[0].shoots.length
            : 0,
          currentPage: 0,
          selectedPage: null,
          getDone: true,
          currentIdx: 0
        });

        if (nextProps.historyListAdjustPhoto[0]) {
          for (const item of nextProps.historyListAdjustPhoto[0].shoots) {
            this.props.getAdjustPhoto(item);
          }
        }
      } else if (
        itemSelectedCurrent.shoots?.length !== itemSelectedNext.shoots?.length
      ) {
        this.setState({
          pages: itemSelectedNext.shoots.length,
          currentPage: 0,
          selectedPage: null
        });

        for (const item of itemSelectedNext.shoots) {
          this.props.getAdjustPhoto(item);
        }
      }

      setTimeout(() => {
        if (this.state.isFirstLoad && !this.state.getDone) {
          this.initLoad(nextProps.historyListAdjustPhoto);
        }
      }, 200);
    }

    if (
      nextProps.isShowDirectionDrawer !== this.props.isShowDirectionDrawer ||
      nextProps.changeDirection !== this.props.changeDirection
    ) {
      this.toggleDatePicker(false);
    }

    this.renewHistories();
  }

  switchTypeOfImage = (adjustPhoto, currentPage) => {
    return (
      <PreviewImage
        currentPage={currentPage}
        onSelected={this.onSelected}
        apiPath={adjustPhoto.blob}
      ></PreviewImage>
    );
  };

  listImages = isPC => {
    const { listAdjustPhoto, imageShootUnAdjusted } = this.props;
    const { currentPage, selectedPage, checked } = this.state;
    let currentPages = [];
    let selectedIdx = 0;
    if (isPC) {
      for (let i = 0; i < 3; i++) {
        if (listAdjustPhoto[currentPage + i]) {
          currentPages.push(currentPage + i);
          if (
            listAdjustPhoto[currentPage + i].uniqueCode ===
            imageShootUnAdjusted.shootCode
          ) {
            selectedIdx = currentPage + i;
          }
        }
      }
    } else {
      if (listAdjustPhoto[currentPage]) {
        currentPages.push(currentPage);
      }
    }

    if (currentPages) {
      const list = currentPages.map(currentPage => {
        return (
          <Grid
            key={listAdjustPhoto[currentPage].uniqueCode}
            key1={listAdjustPhoto[currentPage].uniqueCode}
            // className="sub-image-block m-5"
            className={`adjust-image-block sub-image-viewer ${
              selectedIdx === (isPC ? currentPage : 0)
                ? "adjust-image-selected"
                : ""
            }`}
          >
            {this.switchTypeOfImage(listAdjustPhoto[currentPage], currentPage)}
            {!isPC && (
              <p className="adjustImageNum-sp">
                {currentPage + 1}/{listAdjustPhoto.length}
              </p>
            )}
          </Grid>
        );
      });
      return list;
    }
    return <p>Loading...</p>;
  };

  handleChecked = (e, currentPage) => {
    const { listAdjustPhoto } = this.props;
    this.props.onShowDialogViewer(true);
    this.props.onSetTypeDialog({
      typeDialog: TYPE_DIALOG.ADJUST_IMAGE,
      shootsSelected: listAdjustPhoto[this.state.selectedPage ?? 0].uniqueCode
    });

    // if (this.state.checked === listAdjustPhoto[currentPage].uniqueCode) {
    //   this.setState({ checked: ""})
    // }else {
    //   this.setState({
    //     checked: listAdjustPhoto[currentPage].uniqueCode
    //   })
    // }
  };

  onSelected = currentPage => {
    this.props.onOpenAdjustImage();
    const { listAdjustPhoto, onSetImageShootUnAdjusted } = this.props;

    this.setState({ selectedPage: currentPage });
    onSetImageShootUnAdjusted({
      blob: listAdjustPhoto[currentPage].blob,
      shootCode: listAdjustPhoto[currentPage].uniqueCode,
      tagName: listAdjustPhoto[currentPage].tagName,
      createdBy: listAdjustPhoto[currentPage].createdBy
    });
    this.props.fetchShootLogWalk(
      listAdjustPhoto[currentPage].blob?.replace(`/api`, "")
    );
  };

  handleChangeHistory = value => {
    const { historyListAdjustPhoto, onSetListAdjustPhotoSelected } = this.props;

    const itemSelected = historyListAdjustPhoto.find(
      item => item.uniqueCode === value
    );

    this.setState({
      createAt: value,
      pages: itemSelected.shoots.length,
      currentPage: 0,
      selectedPage: null
    });
    onSetListAdjustPhotoSelected(itemSelected);
    this.props.resetListAdjustPhoto();

    for (const item of itemSelected.shoots) {
      this.props.getAdjustPhoto(item);
    }
  };

  changePage = index => {
    this.setState({
      currentPage: this.state.currentPage + index
    });
  };

  previousPage = () => {
    this.changePage(-1);
    if (this.props.modeDevice === MODE_DEVICE.SP) {
      this.onSelected(this.state.currentPage - 1);
    }
  };

  nextPage = () => {
    this.changePage(1);
    if (this.props.modeDevice === MODE_DEVICE.SP) {
      this.onSelected(this.state.currentPage + 1);
    }
  };

  checkInActiveCheckbox = () => {
    const { drawingsWithSpots } = this.props;
    let countSpot = 0;
    drawingsWithSpots.forEach(item => {
      if (item.spots) {
        countSpot += item.spots.length;
      }
    });
    return !countSpot;
  };

  renewHistories = () => {
    let histories = [];
    this.props.historyListAdjustPhoto.map(item => {
      histories.push({
        day: item.createdAt.substr(0, 10),
        time: item.createdAt.substr(13, 5)
      });
    });

    this.setState({ histories }, () => {
      this.setDisabledDays();
      this.setSelectedDays();
    });
  };

  setSelectedDays = () => {
    let selectedDays = [];
    this.state.histories.map(item => selectedDays.push(new Date(item.day)));
    this.setState({ selectedDays });
  };

  setDisabledDays = () => {
    const { histories } = this.state;
    if (histories && histories.length) {
      let disabledDays = [];

      disabledDays.push({
        after: new Date(histories[0].day),
        before: new Date(histories[histories.length - 1].day)
      });

      if (histories.length > 1) {
        for (let i = 1; i < histories.length; i++) {
          if (histories[i].day !== histories[i - 1].day) {
            disabledDays.push({
              after: new Date(histories[i].day),
              before: new Date(histories[i - 1].day)
            });
          }
        }
      } else {
        disabledDays.push({
          after: new Date(histories[0].day),
          before: new Date(histories[0].day)
        });
      }

      this.setState({ disabledDays });
    }
  };

  toggleDatePicker = value => {
    if (this.state.isShowTimePicker) {
      this.toggleTimePicker(false);
    }
    this.setState({
      isShowDatePicker:
        value !== undefined ? value : !this.state.isShowDatePicker
    });
  };

  toggleTimePicker = isShow => {
    this.setState({ isShowTimePicker: isShow });
  };

  onChangeDateHistory = days => {
    const itemChange = this.state.selectedDays.find(
      (
        i => a =>
          a !== days[i] || !++i
      )(0)
    );

    if (itemChange) {
      this.toggleDatePicker();
      this.toggleTimePicker();
    }
  };

  handleDayClick = (day, { selected }) => {
    const historyDay = this.state.histories.filter(history =>
      DateUtils.isSameDay(new Date(history.day), day)
    );

    if (historyDay && historyDay.length) {
      const times = historyDay.map(obj => obj.time);
      this.setState(
        {
          pickedDay: historyDay[0].day,
          times
        },
        () => {
          this.toggleDatePicker();
          if (times.length === 1) {
            this.handleTimeClick(0);
          }

          if (times.length > 1 && this.props.modeDevice === MODE_DEVICE.SP) {
            this.toggleTimePicker(true);
          }
        }
      );
    }
  };

  handleTimeClick = idx => {
    const newIdx =
      this.props.historyListAdjustPhoto.findIndex(
        item =>
          item.createdAt.substr(0, 10) ===
          format(new Date(this.state.pickedDay), "yyyy-MM-dd")
      ) + idx;

    const history = this.props.historyListAdjustPhoto[newIdx];

    this.setState({ currentIdx: newIdx });
    if (
      history &&
      history.uniqueCode !== this.state.currentHistory.uniqueCode
    ) {
      this.setCurrentHistory(history);
      this.handleChangeHistory(history.uniqueCode);
    }

    if (this.state.isShowTimePicker) {
      this.toggleTimePicker();
    }

    if (this.props.modeDevice === MODE_DEVICE.PC) {
      this.toggleDatePicker();
    }
  };

  handleChangeHistoryButton = isNext => {
    const { currentHistory, histories, currentIdx } = this.state;

    const newIdx = isNext ? currentIdx - 1 : currentIdx + 1;

    const history = this.props.historyListAdjustPhoto[newIdx];

    this.setCurrentHistory(history);
    this.handleChangeHistory(history.uniqueCode);
    this.setState({ currentIdx: newIdx });
  };

  setCurrentHistory = currentHistory => {
    this.setState({ currentHistory });
  };

  handleDayMouseEnter = day => {
    const historyDay = this.state.histories.filter(history =>
      DateUtils.isSameDay(new Date(history.day), day)
    );

    if (historyDay && historyDay.length) {
      const times = historyDay.map(obj => obj.time);
      this.setState(
        {
          pickedDay: historyDay[0].day,
          times
        },
        () => {
          if (times.length > 1) {
            this.toggleTimePicker(true);
          }
        }
      );
    }

    if (historyDay && historyDay.length) {
      const times = historyDay.map(obj => obj.time);
      if (times.length > 1) {
        this.setState(
          {
            pickedDay: historyDay[0].day,
            times
          },
          () => {
            this.toggleTimePicker(true);
          }
        );
      }
    }
  };

  formatMonthTitle(d, locale = "jp") {
    return `${d.getFullYear()}年${d.getMonth() + 1}月`;
  }

  render() {
    const {
      historyListAdjustPhoto,
      listAdjustPhotoSelected,
      listAdjustPhoto,
      isShowDirectionDrawer,
      blobDataLogWalk
    } = this.props;

    const {
      createAt,
      currentPage,
      pages,
      currentHistory,
      histories,
      isShowDatePicker,
      isShowTimePicker,
      disabledDays,
      times,
      currentIdx
    } = this.state;

    return (
      <React.Fragment>
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
          spacing={2}
          style={{ position: "relative", touchAction: "manipulation" }}
        >
          <Grid item xs={12} className="pd-10">
            {/* <SelectControl
              name="name"
              field={{ name: "history", value: createAt }}
              className="input-reset reset-select select-viewer"
              onChange={this.handleChangeHistory}
            >
              {historyListAdjustPhoto.map(item => (
                <MenuItem
                  className="select-item"
                  value={item.uniqueCode}
                  key={item.uniqueCode}
                >
                  {item.createdAt}
                </MenuItem>
              ))}
            </SelectControl> */}
            <Grid className="history-control no-select">
              <IconButton
                size="small"
                disabled={currentIdx === histories.length - 1}
                onClick={e => {
                  e.preventDefault();
                  this.handleChangeHistoryButton(false);
                }}
                className="no-zoom"
              >
                <ArrowLeftIcon fontSize="small" />
              </IconButton>
              <Typography
                variant="subtitle1"
                className="label current-history"
                onClick={e => {
                  this.toggleDatePicker();
                  e.preventDefault();
                }}
              >
                {currentHistory ? currentHistory.createdAt : ""}
              </Typography>

              <IconButton
                size="small"
                disabled={currentIdx === 0}
                onClick={e => {
                  this.handleChangeHistoryButton(true);
                  e.preventDefault();
                }}
                className="no-zoom"
              >
                <ArrowRightIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          {!isShowDatePicker && !isShowTimePicker && (
            <>
              <Sp>
                <Grid
                  className="image-landscape-sp no-zoom"
                  onClick={e => e.preventDefault()}
                >
                  <Grid item className="p-0 arrow-block-viewer m-auto">
                    {currentPage > 0 && (
                      <IconButton
                        onClick={e => {
                          this.previousPage();
                          e.preventDefault();
                        }}
                        size="small"
                        className="switch-img-adjust"
                      >
                        <ArrowLeftIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item className="list-image-adjust p-0 no-zoom">
                    {this.listImages(false)}
                  </Grid>
                  <Grid item className="p-0 arrow-block-viewer m-auto ml-5">
                    {currentPage + 1 < pages && (
                      <IconButton
                        onClick={e => {
                          this.nextPage();
                          e.preventDefault();
                        }}
                        size="small"
                        className="switch-img-adjust"
                      >
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Sp>
              <Pc>
                <Grid className="image-landscape">
                  <Grid item className="p-0 arrow-block-viewer m-auto">
                    {currentPage > 0 && (
                      <IconButton
                        onClick={e => {
                          this.previousPage();
                          e.preventDefault();
                        }}
                        size="small"
                        className="switch-img-adjust"
                      >
                        <ArrowUpwardIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item className="list-image-adjust p-0">
                    {this.listImages(true)}
                  </Grid>
                  <Grid item className="p-0 arrow-block-viewer m-auto">
                    {currentPage + 3 < pages && (
                      <IconButton
                        onClick={e => {
                          this.nextPage();
                          e.preventDefault();
                        }}
                        size="small"
                        className="switch-img-adjust"
                      >
                        <ArrowDownwardIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Pc>
            </>
          )}
        </Grid>
        {isShowDatePicker && (
          <Grid
            className={`container-history-calendar no-zoom`}
            onClick={e => {
              this.toggleDatePicker();
              e.preventDefault();
            }}
          >
            <Grid
              className={`history-picker unadjust-history`}
              style={{ touchAction: "manipulation" }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <DayPicker
                month={new Date(currentHistory.createdAt.substr(0, 10))}
                fixedWeeks
                selectedDays={
                  currentHistory
                    ? [new Date(currentHistory.createdAt.substr(0, 10))]
                    : []
                }
                disabledDays={disabledDays}
                onDayClick={this.handleDayClick}
                onDayMouseEnter={
                  this.props.modeDevice === MODE_DEVICE.PC
                    ? this.handleDayMouseEnter
                    : undefined
                }
                localeUtils={{
                  ...MomentLocaleUtils,
                  formatMonthTitle: this.formatMonthTitle
                }}
                locale={"ja"}
              />
            </Grid>
          </Grid>
        )}

        {isShowTimePicker && (
          <Grid
            className={
              this.props.modeDevice === MODE_DEVICE.PC
                ? "time-picker-adjust-pc"
                : "time-picker"
            }
            style={
              this.props.modeDevice === MODE_DEVICE.PC
                ? {
                    left: window.event ? window.event.clientX : 0,
                    top: window.event ? window.event.clientY : 0
                  }
                : {}
            }
            onMouseLeave={() =>
              this.props.modeDevice === MODE_DEVICE.PC
                ? this.toggleTimePicker(false)
                : undefined
            }
            onClick={e => e.preventDefault()}
          >
            <Grid className={`time-items`}>
              {times.map((item, idx) => {
                return (
                  <Typography
                    variant="subtitle1"
                    className="label time-item no-select"
                    onClick={() => this.handleTimeClick(idx)}
                  >
                    {item}
                  </Typography>
                );
              })}
            </Grid>
          </Grid>
        )}
        <Grid
          className="btn-add-image-to-point"
          style={{
            bottom: isShowDirectionDrawer && blobDataLogWalk ? "70px" : "15px",
            transition: "bottom 0.25s ease 0s"
          }}
        >
          <Button
            style={{ touchAction: "manipulation" }}
            disabled={!listAdjustPhoto || listAdjustPhoto.length === 0}
            onClick={e => {
              this.handleChecked(e, currentPage);
              this.toggleDatePicker(false);
              e.preventDefault();
            }}
          >
            撮影ポイントに追加
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  type: state.rootSitesReducer.siteInfoReducer.type,
  historyListAdjustPhoto: getHistoryListAdjustPhoto(
    state.rootSitesReducer.logWalkViewReducer.historyListAdjustPhoto
  ),
  listAdjustPhotoSelected: getListAdjustPhotoSelected(
    state.rootSitesReducer.logWalkViewReducer.listAdjustPhotoSelected
  ),
  listAdjustPhoto: getListAdjustPhoto(
    state.rootSitesReducer.logWalkViewReducer.listAdjustPhoto
  ),
  typeDialog: getTypeDialog(
    state.rootSitesReducer.logWalkViewReducer.typeDialog
  ),
  imageShootUnAdjusted: getUrlImageShootsUCNotAdjusted(
    state.rootSitesReducer.logWalkViewReducer.imageShootUnAdjusted
  ),
  drawingsWithSpots: getDrawingsList(
    state.rootSitesReducer.logWalkViewReducer.drawingsList
  ),
  listShootsSpot: getListShootsSpot(
    state.rootSitesReducer.logWalkViewReducer.listShootsSpot
  )
});

const mapDispatchToProps = dispatch => {
  return {
    onSetListAdjustPhotoSelected: value =>
      dispatch(setListAdjustPhotoSelected(value)),
    getAdjustPhoto: item => dispatch(getPhotoShoots(item)),
    resetListAdjustPhoto: () => dispatch(resetListAdjustPhoto()),
    onSetShootImage: data => dispatch(getUrlImageShootSuccess(data)),
    onSetTypeDialog: data => dispatch(setTypeDialog(data)),
    onSetImageShootUnAdjusted: data => dispatch(setImageShootUnAdjusted(data))
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(AdjustImage);
