import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Component from "../../Component";
import {
  fetchSiteDrawings,
  putDrawingInfo,
  postSpot,
  postLink,
  deleteLink,
  putSpot,
  deleteDrawing,
  deleteSpot,
  showHideDialogPreviewPdf,
  showHideDialogMap,
  showHideDialogEditMap,
  showHideDialogAddPoint,
  showHideDialogEditPoint,
  showHideDialogDelete,
  getDrawingWithSpots,
  copyDrawing,
  clearDrawingsWithSpot
} from "./service/service";
import { getDrawingsWithSpots } from "./reducer/reducer";
import MapsBuilder from "./MapsBuilder";
import MapsBuilderSP from "./MapsBuilderSP";
import Pc from "../../../common/breakpoints/Pc";
import Sp from "../../../common/breakpoints/Sp";
import PdfPreview from "./PdfPreview";
import CopyDrawingDialog from "./copyDrawingDialog/CopyDrawingDialog";
import { translateMessage, trimObjValues, getTextLength } from "utils/mapper";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";
import CommonDialog from "../../CommonDialog";
import {
  dispatchError as fillError,
} from "../../../common/Stores/service/service";
import DialogDelete from "../../../common/DialogDelete";
import { GET_DRAWING_WITH_SPOTS_SUCCESS } from "./constant/constant";
import {
  Grid,
  Button,
  MenuItem,
  Typography,
  Checkbox
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import MapOptionDialog from "./MapOptionDialog";
import CommonMapOptionDialogSP from "./CommonMapOptionDialogSP";

const maxNameLength = 16;
const ITEM_HEIGHT = 68;

class Maps extends Component {
  fieldListAddPoint = [
    {
      name: "name",
      label: "撮影ポイント名",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    }
  ];

  fieldListEditPoint = [
    {
      name: "name",
      label: "撮影ポイント名",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    }
  ];

  fieldListEditMap = [
    {
      name: "name",
      label: "図面名",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true,
      maxLength: maxNameLength
    }
  ];

  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.state = {
      fileName: "",
      file: null,
      clientError: null,
      drawingUC: null,
      defaultEditMapName: "",
      defaultSpotUC: null,
      pos: null,
      isOpenFromMain: false,
      currentDrawingsUC: null,
      isShowDialogMap: false,
      selectedIndex: null,
      copyList: [],
      isShowAddDrawingDialog: false,
      isShowCopyDrawingDialog: false,
      deleteDrawingParams: null,
      isShowDialogConfirmDelete: false,
      isViewVRSpace: true,
      showConfirmCreateLink: false,
      showConfirmDeleteLink: false
    };
  }

  componentWillMount() {
    this.fetch();
  }

  componentDidMount() {
    window.addEventListener("resize", this.setScreenOrientation);
    if (!this.props.auth.isAuthenticated) this.props.history.push("/login");
  }

  componentWillReceiveProps(props) {
    if (
      props.type === GET_DRAWING_WITH_SPOTS_SUCCESS &&
      this.state.isOpenFromMain
    ) {
      this.props.fillError("");
      this.props.showHideDialogEditMap();
      this.setState({
        clientError: null,
        isOpenFromMain: false
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setScreenOrientation);
    this.props.clearDrawingsWithSpot();
  }

  setScreenOrientation = e => {
    if (this.state.isShowDialogCopy && window.innerWidth >= 960) {
      const element = document.getElementsByClassName(
        "copy-drawing-wrapper"
      )[0];
      if (
        document.getElementsByClassName("copy-drawing-container-child")[0]
          .offsetHeight > 420
      ) {
        element.style.top = "50%";
        element.style.transform = "translate(-50%, -50%)";
      } else {
        element.style.top = "30px";
        element.style.transform = "translate(-50%, 0)";
      }
    }

    if (
      window.matchMedia("(orientation: landscape)").matches &&
      this.state.isShowDialogCopy
    ) {
      const element = document.getElementsByClassName("copy-drawing")[0];
      element.scrollTop = element.scrollHeight;
    }
  };
  fetch() {
    this.props.fetchSiteDrawings(this.props.sitesUC);
  }
  toggleOptionButtons = () => {
    this.props.toggleOptionButtons();
  };

  render() {
    const {
      clientError,
      defaultSpotUC,
      isOpenFromMain,
      isShowDialogCopy,
      selectedIndex,
      copyList,
      isShowAddDrawingDialog,
      isShowCopyDrawingDialog,
      isShowDialogConfirmDelete,
      isViewVRSpace,
      showConfirmCreateLink,
      showConfirmDeleteLink
    } = this.state;

    const {
      isEdit,
      error,
      sitesUC,
      drawingWithSpots,
      isShowDialogPreviewPDF,
      pdfFile,
      drawingsUC,
      isShowDialogEditMap,
      isShowDialogAddPoint,
      spotData,
      isShowDialogDelete,
      drawingsWithSpots,
      hasWalkThroughFeature
    } = this.props;

    const showMapDialog = drawingsUC => {
      this.props.showHideDialogMap(drawingsUC);
    };

    const closeMapDialog = () => {
      this.props.showHideDialogMap(null);
    };

    const showPreviewPDF = event => {
      toggleAddDrawingDialog();
      const element = event.target;
      const files = element.files;
      const exts = element.accept.split(",").map(ext => ext.toLowerCase());
      var containsInvalidMimeType = false;
      for (var i = 0; i < files.length; i++) {
        var file = files[i];
        containsInvalidMimeType = !exts.some(ext =>
          file.name.toLowerCase().endsWith(ext)
        );
        if (containsInvalidMimeType) {
          break;
        }
      }
      if (containsInvalidMimeType) {
        const message = translateMessage(
          this.props.message.mimetype,
          this.props.regexObject
        );
        alert(message);
        return;
      }
      const fileInfo = {
        file: URL.createObjectURL(event.target.files[0]),
        fileName: event.target.files[0].name
      };
      this.props.showHideDialogPreviewPdf(fileInfo);
    };

    const closePreviewPDF = () => {
      const fileInfo = null;
      this.props.showHideDialogPreviewPdf(fileInfo);
    };

    // For add point dialog
    const onAddPointSubmit = value => {
      let clientError = null;

      const trimedFileValues = trimObjValues(value);

      if (!trimedFileValues.name) {
        clientError = {
          ...clientError,
          name: translateMessage(
            this.props.message.required,
            this.props.regexObject
          )
        };
      }

      this.setState({
        clientError: clientError
      });
      if (!clientError) {
        if (!isEdit) {
          // For add new spot
          const data = {
            name: value.name,
            x: spotData.pos.x,
            y: spotData.pos.y
          };
          this.props.postSpot(this.state.currentDrawingsUC, sitesUC, data);
          toggleDialogAddPoint(null);
        } else {
          // For edit spot
          this.props.putSpot(
            spotData.pos.uniqueCode,
            this.state.currentDrawingsUC,
            sitesUC,
            value
          );
          toggleDialogEditPoint(null);
        }
      }
    };

    const onCreateLinkSubmit = (currentDrawingsUC, drawings) => {
      const data = {
        drawings: drawings
      };

      this.props.postLink(sitesUC, data, currentDrawingsUC);
      toggleConfirmCreateLink();
    };

    const onDeleteLinkSubmit = (currentDrawingsUC, drawings) => {
      const data = {
        drawings: drawings
      };

      this.props.deleteLink(sitesUC, data, currentDrawingsUC);
      toggleConfirmDeleteLink();
    };

    const toggleDialogAddPoint = (pos, drawingsUC) => {
      this.props.showHideDialogAddPoint({ pos });
      this.props.fillError("");
      this.setState({
        currentDrawingsUC: drawingsUC,
        clientError: null
      });
    };

    // For edit point dialog
    const onEditPointSubmit = values => {};

    const toggleDialogEditPoint = (pos, drawingsUC) => {
      this.props.showHideDialogEditPoint({ pos });

      this.props.fillError("");
      this.setState({
        clientError: null,
        currentDrawingsUC: drawingsUC
      });
    };

    // For edit map dialog
    const onEditMapSubmit = value => {
      let clientError = null;

      const trimedFileValues = trimObjValues(value);

      if (!trimedFileValues.name) {
        clientError = {
          ...clientError,
          name: translateMessage(
            this.props.message.required,
            this.props.regexObject
          )
        };
      }

      if (getTextLength(trimedFileValues.name) > maxNameLength) {
        clientError = {
          ...clientError,
          name: translateMessage(
            this.props.message.maximumCharacter,
            this.props.regexObject
          )
        };
      }

      this.setState({
        clientError: clientError
      });

      if (!clientError) {
        this.props.putDrawingInfo(drawingWithSpots.uniqueCode, sitesUC, value);
        if (!isOpenFromMain) toggleDialogEditMap();
      }
    };

    const toggleDialogEditMapFromMain = drawingsUC => {
      this.setState({ isOpenFromMain: true });
      this.props.getDrawingWithSpots(drawingsUC);
    };

    const toggleDialogEditMap = () => {
      this.props.fillError("");
      this.props.showHideDialogEditMap();
      this.setState({
        clientError: null
      });
    };

    // For delete dialog
    const onDeleteSubmit = value => {
      if (isShowDialogEditMap) {
        this.setState({ deleteDrawingParams: value });
        toggleDialogConfirmDelete();
      }

      if (isShowDialogAddPoint && isEdit) {
        this.props.deleteSpot(
          spotData.pos.uniqueCode,
          this.state.currentDrawingsUC,
          sitesUC,
          value
        );
        toggleDialogDelete();
        toggleDialogEditPoint(null);
      }
    };

    const toggleDialogConfirmDelete = () => {
      this.setState({
        isShowDialogConfirmDelete: !this.state.isShowDialogConfirmDelete
      });
    };

    const onDeleteDrawing = () => {
      this.props.deleteDrawing(
        drawingWithSpots.uniqueCode,
        sitesUC,
        this.state.deleteDrawingParams
      );
      toggleDialogDelete();
      toggleDialogEditMap();
      toggleDialogConfirmDelete();
      this.setState({ deleteDrawingParams: null });
    };

    const toggleDialogDelete = () => {
      this.props.showHideDialogDelete();
    };

    const showHideDialogMap = isShow => {
      this.setState({
        isShowDialogMap: isShow
      });
    };

    const closeDialogCopy = () => {
      this.setState({
        isShowDialogCopy: null,
        copyList: []
      });

      if (document.getElementsByClassName("grid-list-sp").length !== 0) {
        document
          .getElementsByClassName("grid-list-sp")[0]
          .classList.add("scrollbarWidthNone");
      }

      if (
        document.getElementsByClassName("image-content").length !== 0 &&
        window.innerWidth < 960
      ) {
        document.getElementsByClassName("image-content")[0].style.overflow =
          "auto";
      }
    };

    const openDialogCopy = e => {
      this.setState(
        {
          isShowDialogCopy: e
            ? e.currentTarget
            : document.getElementsByClassName("relative-box-sp")[0],
          copyList: []
        },
        () => {
          this.setScreenOrientation();
        }
      );

      if (document.getElementsByClassName("grid-list-sp").length !== 0) {
        document
          .getElementsByClassName("grid-list-sp")[0]
          .classList.remove("scrollbarWidthNone");
      }

      if (
        document.getElementsByClassName("image-content").length !== 0 &&
        window.innerWidth < 960
      ) {
        document.getElementsByClassName("image-content")[0].style.overflow =
          "visible";
      }
    };

    const setSelectedIndexDrawing = index => {
      this.setState({ selectedIndex: index });
    };

    const canCopy = copyDrawingWithSpots => {
      if (copyDrawingWithSpots.spots.length > 0) {
        return false;
      }

      var copyElement = document.getElementById(
        copyDrawingWithSpots.uniqueCode
      );

      var currentDrawingWithSpots =
        drawingsWithSpots[
          selectedIndex && selectedIndex < drawingsWithSpots.length
            ? selectedIndex
            : 0
        ];

      var spots = currentDrawingWithSpots.spots;

      if (currentDrawingWithSpots) {
        for (var i = 0; i < spots.length; i++) {
          if (
            spots[i].x < 0 ||
            spots[i].x > copyElement.naturalWidth ||
            spots[i].y < 0 ||
            spots[i].y > copyElement.naturalHeight
          ) {
            return false;
          }
        }
      } else {
        return false;
      }
      return true;
    };

    const checkedCopyDrawing = drawingUC => {
      var listDrawingUC = copyList;
      var index = copyList.indexOf(drawingUC);
      if (index > -1) {
        listDrawingUC.splice(index, 1);
      } else {
        listDrawingUC.push(drawingUC);
      }

      this.setState({ copyList: listDrawingUC });
    };

    const onSubmitCopy = () => {
      var index =
        selectedIndex && selectedIndex < drawingsWithSpots.length
          ? selectedIndex
          : 0;
      const spots =
        drawingsWithSpots[index] && drawingsWithSpots[index].spots
          ? drawingsWithSpots[index].spots
          : [];

      if (spots.length > 0)
        this.props.copyDrawing(
          this.props.sitesUC,
          copyList,
          spots.map(spot => ({ name: spot.name, x: spot.x, y: spot.y }))
        );

      closeDialogCopy();
      this.setState({ copyList: [] });
    };

    const toggleAddDrawingDialog = () => {
      this.setState({
        isShowAddDrawingDialog: !this.state.isShowAddDrawingDialog
      });
    };

    const toggleCopyDrawingDialog = drawingsUC => {
      this.setState({
        isShowCopyDrawingDialog: !this.state.isShowCopyDrawingDialog,
        currentDrawingsUC: drawingsUC
      });
    };

    const closeAllDialog = () => {
      toggleCopyDrawingDialog();
      toggleAddDrawingDialog();
    };

    const changeDrawing = drawingsUC => {
      this.setState({ currentDrawingsUC: drawingsUC });
    };

    const setIsViewVRSpace = viewVRSpace => {
      if(viewVRSpace){
        this.props.history.replace(this.props.history.location.pathname);  
      }
      this.setState({ isViewVRSpace: viewVRSpace });
    };

    const toggleConfirmCreateLink = drawingsUC => {
      if (this.state.showConfirmCreateLink) {
        this.setState({
          showConfirmCreateLink: false
        });
      } else {
        this.setState({
          showConfirmCreateLink: true,
          currentDrawingsUC: drawingsUC
        });
      }
    };
    const toggleConfirmDeleteLink = drawingsUC => {
      if (this.state.showConfirmDeleteLink) {
        this.setState({
          showConfirmDeleteLink: false
        });
      } else {
        this.setState({
          showConfirmDeleteLink: true,
          currentDrawingsUC: drawingsUC
        });
      }
    };

    return (
      <>
        <Sp>
          <MapsBuilderSP
            showMapDialog={showMapDialog}
            toggleDialogEditMap={toggleDialogEditMapFromMain}
            toggleDialogAddPoint={toggleDialogAddPoint}
            toggleDialogEditPoint={toggleDialogEditPoint}
            showHideDialogMap={showHideDialogMap}
            openDialogCopy={openDialogCopy}
            setSelectedIndexDrawing={setSelectedIndexDrawing}
            toggleAddDrawingDialog={toggleAddDrawingDialog}
            toggleOptionButton={this.toggleOptionButtons}
            istabElementMaps={this.props.istabElementMaps}
            isShowOptionButton={this.props.isShowOptionButton}
            setIsViewVRSpace={setIsViewVRSpace}
            isViewVRSpace={isViewVRSpace}
            hasWalkThroughFeature={hasWalkThroughFeature}
            showConfirmDeleteLink={showConfirmDeleteLink}
            showConfirmCreateLink={showConfirmCreateLink}
            toggleConfirmCreateLink={toggleConfirmCreateLink}
            toggleConfirmDeleteLink={toggleConfirmDeleteLink}
          />
        </Sp>
        <Pc>
          <MapsBuilder
            toggleDialogEditMap={toggleDialogEditMapFromMain}
            toggleDialogAddPoint={toggleDialogAddPoint}
            toggleDialogEditPoint={toggleDialogEditPoint}
            openDialogCopy={openDialogCopy}
            isShowDialogCopy={this.state.isShowDialogCopy}
            closeDialogCopy={() => closeDialogCopy()}
            setSelectedIndexDrawing={setSelectedIndexDrawing}
            toggleAddDrawingDialog={toggleAddDrawingDialog}
            setIsViewVRSpace={setIsViewVRSpace}
            isViewVRSpace={isViewVRSpace}
            hasWalkThroughFeature={hasWalkThroughFeature}
            showConfirmDeleteLink={showConfirmDeleteLink}
            showConfirmCreateLink={showConfirmCreateLink}
            toggleConfirmCreateLink={toggleConfirmCreateLink}
            toggleConfirmDeleteLink={toggleConfirmDeleteLink}
          />
        </Pc>
        <input
          type="file"
          id="file"
          accept=".pdf"
          onChange={e => showPreviewPDF(e)}
          onClick={e => (e.target.value = null)}
          style={{ display: "none" }}
          ref={this.fileRef}
        />

        {isShowDialogAddPoint && (
          <CommonDialog
            title={isEdit ? "撮影ポイントの編集" : "撮影ポイントの追加"}
            isDialogShow={isShowDialogAddPoint}
            initialValues={isEdit ? { name: spotData.pos.name } : { name: "" }}
            onSubmit={onAddPointSubmit}
            onClose={isEdit ? toggleDialogEditPoint : toggleDialogAddPoint}
            fieldList={this.fieldListAddPoint}
            submitButtonTitle={"登録"}
            isEdit={isEdit && !spotData.pos.hasShoot}
            submitErrors={clientError ? clientError : error}
            onDeleteClick={toggleDialogDelete}
            isSingleColumn={true}
          />
        )}

        {isShowDialogEditMap && (
          <CommonDialog
            title={"図面の編集"}
            isDialogShow={isShowDialogEditMap}
            initialValues={{ name: drawingWithSpots.name }}
            onSubmit={onEditMapSubmit}
            onClose={toggleDialogEditMap}
            fieldList={this.fieldListEditMap}
            submitButtonTitle={"登録"}
            isEdit={true}
            submitErrors={clientError ? clientError : error}
            onDeleteClick={toggleDialogDelete}
            isSingleColumn={true}
          />
        )}

        {isShowDialogDelete && (
          <DialogDelete
            open={isShowDialogDelete}
            onClose={toggleDialogDelete}
            onDeleteSubmit={onDeleteSubmit}
            isLogWalk={isShowDialogEditMap}
            PaperProps={{
              className: "dialog dialog-sign-up"
            }}
          />
        )}

        {isShowDialogConfirmDelete && (
          <DialogDelete
            open={isShowDialogDelete}
            onClose={toggleDialogConfirmDelete}
            onDeleteSubmit={onDeleteDrawing}
            PaperProps={{
              className: "dialog dialog-sign-up"
            }}
            textContent="インフォタグも削除されます。本当によろしいですか？"
          />
        )}

        {isShowDialogPreviewPDF && (
          <PdfPreview
            file={pdfFile.file}
            fileName={pdfFile.fileName}
            open={isShowDialogPreviewPDF}
            onClose={closePreviewPDF}
            siteUC={this.props.sitesUC}
          />
        )}

        {isShowCopyDrawingDialog && (
          <CopyDrawingDialog
            open={isShowCopyDrawingDialog}
            onClose={toggleCopyDrawingDialog}
            onCloseAll={closeAllDialog}
            currentDrawing={
              selectedIndex && selectedIndex < drawingsWithSpots.length
                ? selectedIndex
                : 0
            }
          ></CopyDrawingDialog>
        )}

        {isShowDialogCopy && (
          <Grid
            className={`copy-drawing ${
              window.innerWidth < 960
                ? "copy-drawing-container-sp"
                : "copy-drawing-container"
            }`}
          >
            <Grid className="copy-drawing-container-child">
              <Grid
                className={"copy-drawing-wrapper"}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <React.Fragment>
                  <Fab
                    onClick={closeDialogCopy}
                    size="small"
                    className="white-text close-button-2"
                    style={{ right: "-20px" }}
                  >
                    <CloseIcon />
                  </Fab>

                  <Grid className="p-10">
                    <Typography variant="body2" className="menu-note">
                      地点情報をコピーする図面を選択してください
                    </Typography>
                    <Typography variant="body2" className="menu-note">
                      ※複数選択可
                    </Typography>
                  </Grid>
                  <Grid className={"menu-content"}>
                    {drawingsWithSpots &&
                      drawingsWithSpots.map(
                        (item, idx) =>
                          item.uniqueCode !==
                            drawingsWithSpots[
                              selectedIndex &&
                              selectedIndex < drawingsWithSpots.length
                                ? selectedIndex
                                : 0
                            ].uniqueCode && (
                            <MenuItem
                              className="menu-item"
                              disabled={!canCopy(item)}
                              key={item.uniqueCode}
                              // selected={
                              //   drawingsWithSpots[currentPage].uniqueCode ===
                              //   item.uniqueCode
                              // }
                              title={item.name}
                              onClick={e => {
                                checkedCopyDrawing(item.uniqueCode);
                              }}
                            >
                              <Grid
                                key={item.uniqueCode}
                                className="menu-item-container"
                              >
                                <Grid className="item-checkbox">
                                  {canCopy(item) && (
                                    <Checkbox
                                      className="selected-photos p-0"
                                      color="primary"
                                      // onClick={e => {
                                      //   checkedCopyDrawing(item.uniqueCode);
                                      // }}
                                      checked={copyList.includes(
                                        item.uniqueCode
                                      )}
                                      inputProps={{
                                        "aria-label": "Primary checkbox"
                                      }}
                                    />
                                  )}
                                </Grid>
                                <Grid className="item-text">
                                  <Typography className="item-name">
                                    {item.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </MenuItem>
                          )
                      )}
                  </Grid>
                  <Grid className="text-center p-5">
                    <Button
                      onClick={onSubmitCopy}
                      disabled={copyList.length === 0}
                      size="small"
                      variant="text"
                      className={
                        copyList.length === 0
                          ? "btn-disable"
                          : "button-add add-point-enable"
                      }
                    >
                      <label style={{ color: "inherit" }}>決定</label>
                    </Button>
                  </Grid>
                </React.Fragment>
              </Grid>
            </Grid>
          </Grid>
        )}

        {showConfirmCreateLink && (
          <CommonMapOptionDialogSP
            open={showConfirmCreateLink}
            onClose={() => this.setState({ showConfirmCreateLink: false })}
            title="「地点をつなぐ」の設定先を選択"
            titleClasses="white-text text-center"
            buttonOptions={[
              {
                buttonTitle: "全ての図面に反映",
                buttonOnCick: () =>
                  onCreateLinkSubmit(
                    this.state.currentDrawingsUC,
                    this.props.drawingsWithSpots.map(item => item.uniqueCode)
                  ),
                buttonClasses: "compass-setting-confirm-dialog-btn-option-sp"
              },
              {
                buttonTitle: "この図面のみに反映",
                buttonOnCick: () =>
                  onCreateLinkSubmit(this.state.currentDrawingsUC, [
                    this.state.currentDrawingsUC
                  ]),
                buttonClasses: "compass-setting-confirm-dialog-btn-option-sp"
              }
            ]}
            PaperProps={{
              className:
                "dialog dialog-sign-up map-option-dialog compass-setting-confirm-dialog-container"
            }}
          ></CommonMapOptionDialogSP>
        )}

        {showConfirmDeleteLink && (
          <CommonMapOptionDialogSP
            open={showConfirmDeleteLink}
            onClose={() => this.setState({ showConfirmDeleteLink: false })}
            title="「地点をつなぐ」を解除する"
            titleClasses="text-center"
            buttonOptions={[
              {
                buttonTitle: "全ての図面に反映",
                buttonOnCick: () =>
                  onDeleteLinkSubmit(
                    this.state.currentDrawingsUC,
                    this.props.drawingsWithSpots.map(item => item.uniqueCode)
                  ),
                buttonClasses: "LW-link-confirm-delete-btn-option"
              },
              {
                buttonTitle: "この図面のみに反映",
                buttonOnCick: () =>
                  onDeleteLinkSubmit(this.state.currentDrawingsUC, [
                    this.state.currentDrawingsUC
                  ]),
                buttonClasses: "LW-link-confirm-delete-btn-option"
              }
            ]}
            PaperProps={{
              className:
                "dialog dialog-sign-up map-option-dialog delete-link-LW-confirm-dialog-container"
            }}
          ></CommonMapOptionDialogSP>
        )}

        <MapOptionDialog
          open={isShowAddDrawingDialog}
          onClose={toggleAddDrawingDialog}
          title="図面の追加"
          textButton1="新しい図面を追加する"
          onClickButton1={() => {
            this.fileRef.current.click();
          }}
          textButton2="この図面から複製する"
          onClickButton2={toggleCopyDrawingDialog}
          disabledButton2={!drawingsWithSpots || !drawingsWithSpots.length}
          PaperProps={{
            className: "dialog dialog-sign-up map-option-dialog"
          }}
        ></MapOptionDialog>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  type: state.rootSitesReducer.siteInfoReducer.type,
  submitErrors: state.storesReducer.error,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message,
  drawingWithSpots: state.rootSitesReducer.siteInfoReducer.drawingWithSpots,
  error: state.storesReducer.error,
  isShowDialogPreviewPDF:
    state.rootSitesReducer.siteInfoReducer.isShowDialogPreviewPDF,
  pdfFile: state.rootSitesReducer.siteInfoReducer.pdfFile,
  isShowDialogEditMap:
    state.rootSitesReducer.siteInfoReducer.isShowDialogEditMap,
  drawingsUC: state.rootSitesReducer.siteInfoReducer.drawingsUC,
  isShowDialogAddPoint:
    state.rootSitesReducer.siteInfoReducer.isShowDialogAddPoint,
  spotData: state.rootSitesReducer.siteInfoReducer.spotData,
  isEdit: state.rootSitesReducer.siteInfoReducer.isEdit,
  isShowDialogDelete: state.rootSitesReducer.siteInfoReducer.isShowDialogDelete,
  drawingsWithSpots: getDrawingsWithSpots(
    state.rootSitesReducer.siteInfoReducer.drawingsWithSpots
  )
});

export default connect(mapStateToProps, {
  fetchSiteDrawings,
  putDrawingInfo,
  postSpot,
  postLink,
  deleteLink,
  putSpot,
  deleteDrawing,
  deleteSpot,
  fillError,
  showHideDialogPreviewPdf,
  showHideDialogMap,
  showHideDialogEditMap,
  showHideDialogAddPoint,
  showHideDialogEditPoint,
  showHideDialogDelete,
  getDrawingWithSpots,
  copyDrawing,
  clearDrawingsWithSpot
})(withRouter(Maps));
