import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { format } from "date-fns";
import marzipano from "marzipano";
import querySearch from "stringquery";
import { Button, Grid, IconButton, Snackbar } from "@material-ui/core";
import { Formik, Field } from "formik";
import Component from "../../../Component";
import CloseIcon from "@material-ui/icons/Close";
import Draggable from "react-draggable";
import MiniWindowViewer from "./MiniWindowViewer";
import DialogViewer from "./DialogViewer";
import AdjustImage from "./AdjustImage";
import LinearProgress from "@material-ui/core/LinearProgress";
import PointSelectHotspotInfo from "./PointSelectHotspotInfo";
import {
  fetchDrawingFile,
  fetchShootInfo,
  fetchShootLogWalk,
  fetchShootLogWalkUpdate,
  getUnAdjustedPhoto,
  addImageNotAdjustedApi,
  shareShootsUC,
  fetchInfoLogwalkView,
  editLinkPositionOnShoot,
  postScreenshotVRPhoto
} from "../service/service";
import {
  getDrawingCode,
  getDrawingBlob,
  getDrawingsList,
  getDrawingsPhoto,
  getURLShoot,
  // getURLShootTile,
  getBlobDataLogWalk,
  getLogWalkInfo,
  getCategoryList,
  getHistoryList,
  getListShootsSpot,
  getListShootsCurrentActive,
  getSpotCurrent,
  getTypeSelectImage3D,
  getHistoryListAdjustPhoto,
  getTypeDialog,
  getShootsUCNotAdjusted,
  getListAdjustPhotoSelected,
  getUrlImageShootsUCNotAdjusted,
  getIsDefaultHistory,
  getProgressLoadImage3D,
  getListShootCurrentActiveLatest,
  getListSpotsCurrent,
  getCurrentHotspotInfoId,
  getIsCreateHotspotInfo,
  getIsOpenHotspotInfo,
  getIsShowDirectionDrawer
} from "./reducer";
import { getRedirectFromUrl } from "../reducer/reducer";
import {
  setDrawingPhoto,
  setHistoryViewer,
  getUrlImageShootSuccess,
  // getUrlImageShootTileSuccess,
  setSpotCurrent,
  setTypeSelectImage3D,
  setTypeDialog,
  setImageShootUnAdjusted,
  resetStore,
  setIsDefaultHistory,
  openHotspotInfo,
  createHotspotInfo,
  closeHotspotInfo,
  createHotspotInfoSuccess,
  cancelCreateHotspotInfo,
  showDirectionDrawer,
  hideDirectionDrawer,
  loginCometChatSuccess,
  logoutCometChatSuccess
} from "../action/action";
import {
  createTempInfoHotspotElement,
  createInfoHotSpotElement,
  createLinkHotspotElement,
  clearTempHotSpotInfo,
  clearAllHotSpotLink,
  clearAllHotSpotInfo
} from "./marzipanoUtils";

import {
  MODE_DEVICE,
  TYPE_SELECT_IMAGE_3D,
  TYPE_DIALOG,
  IMAGE_3D_LOAD_STATUS
} from "./constants";
import "../../../../common/css/logwalk-veiwer.css";
import ViewerInfoAreaPC from "./ViewerInfoAreaPC";
import Pc from "../../../../common/breakpoints/Pc";
import Sp from "../../../../common/breakpoints/Sp";
import ViewerInfoAreaSP from "./ViewerInfoAreaSP";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { ResizeSensor } from "css-element-queries";
import HotspotInfoDrawer from "./HotspotInfoDrawer";
import "./HotspotInfoDrawer/common/css/hostpotInfo.css";
import CreateInfoMouseIcon from "./CreateInfoMouseIcon";
import { api } from "../../../../common/api/api";
import axios from "axios";
import { CometChat } from "@cometchat-pro/chat";
import EditLinkPosition from "./EditLinkPosition";
import MenuScreenshotVRSpace from "./MenuScreenshotVRSpace";
import { shareScreenShootVRSpace } from "../../../../utils/webShareAPI";
import { arrayBufferToBase64 } from "../../../../utils/mapper";
import ShareUrlDialog from "./ShareUrlDialog";
class LogWalkViewer extends Component {
  isMove = null;
  source = null;
  ajaxRequest = axios.CancelToken.source();
  blobExtention = null;
  pano = null;
  geoType = null;
  sasTimeout = null;
  dragTimeout = null;

  constructor(props) {
    super(props);
    this.state = {
      showDialogViewer: false,
      showPanelAdjustImage: false,
      showHistory: false,
      viewer: undefined,
      panorama: undefined,
      classification: "builder",
      heightMiniWindow: 80,
      widthMiniWindow: 120,
      progress: 0,
      positionMiniWindowViewer: { x: 0, y: 0 },
      oldPositionMiniWindowViewer: { x: 0, y: 0 },
      loadPhotoDone: false,
      tagId: 0,
      isRedirectFromNoImage: false,
      isOpenAdjust: false,
      showSimple: false,
      showComplex: false,
      createHotspotMode: false,
      newHostpotInfoColor: null,
      positionNewHotspotInfo: null,
      showRichInfoHotspot: false,
      changeClickOutsideHistory: false,
      imageNotTransfer: false,
      mousePosition: { x: 0, y: 0 },
      changeDirection: false,
      zIndexInfoHotspot: 1,
      hasPhoto: "",
      convertingPhoto: false,
      cometchatAuthToken: null,
      isShowAccomplishedHotspotInfo: false,
      isShowUnfinishedHotspotInfo: true,
      isShowLink: true,
      showModeEditLink: false,
      selectedLinkForEditing: null,
      previousShootDirection: null,
      isRefreshSas: false,
      screenshotVRBase64: "",
      showSnackbarUploadScreenshotVR: false,
      reloadBlobDataLst403: false,
      isShowShareUrlDialog: false
    };
    this.inputRef = React.createRef();
    this.inputRef1 = React.createRef();
    this.miniWindowViewerRef = React.createRef();
  }

  // Fix bug viewer display white color at bottom
  showToolBarPortrait = () => {
    // const { typeDialog } = this.props;
    // if (window.matchMedia("(orientation: portrait)").matches) {
    //   setTimeout(() => {
    //     window.scrollTo(0, 10);
    //   }, 250);
    // }
  };

  componentDidMount() {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
      this.showToolBarPortrait();
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
    var ElementQueries = require("css-element-queries/src/ElementQueries");
    // attaches to DOMLoadContent and does anything for you
    ElementQueries.listen();
    // or if you want to trigger it yourself:
    // 'init' parses all available CSS and attach ResizeSensor to those elements which
    // have rules attached (make sure this is called after 'load' event, because
    // CSS files are not ready when domReady is fired.
    ElementQueries.init();

    document.getElementsByTagName("html")[0].style.overflow = "hidden";

    const element = document.getElementById("mini-window");
    new ResizeSensor(element, () => {
      this.setState({
        widthMiniWindow: element.offsetWidth,
        heightMiniWindow: element.offsetHeight
      });
    });

    const { match, onInitShoot, location } = this.props;
    if (match.path.includes("/sp/")) {
      this.setState({ classification: "supplier" }, () => {
        this.loadInit();
      });
    } else {
      this.loadInit();
    }

    this.pano = this.inputRef.current;

    this.pano.addEventListener("mousedown", e => {
      this.isMove = false;
    });

    this.pano.addEventListener("mousemove", e => {
      this.isMove = true;
    });

    this.pano.addEventListener("click", e => {
      if (
        this.state.createHotspotMode &&
        !(this.props.isCreateHotspotInfo && this.props.isOpenHotspotInfo)
      ) {
        if (!this.props.blobDataLogWalk) return;

        if (!this.isMove && this.viewer) {
          clearTempHotSpotInfo(this.viewer.scene().hotspotContainer());

          let view = this.viewer.view();
          let loc = view.screenToCoordinates({
            x: e.clientX,
            y: e.clientY
          });
          let positionNewHotspotInfo = { yaw: loc.yaw, pitch: loc.pitch };
          this.setState({ positionNewHotspotInfo });
          let scene = this.viewer.scene();

          let element = createTempInfoHotspotElement(
            this.state.newHostpotInfoColor.color
          );
          scene
            .hotspotContainer()
            .createHotspot(element, positionNewHotspotInfo, {
              perspective: { extraRotations: "rotateZ(-7deg) rotateY(15deg)" }
            });

          let destinationViewParameters = {
            yaw: loc.yaw,
            pitch: loc.pitch
          };
          var options = {
            transitionDuration: 500
          };
          scene.lookTo(destinationViewParameters, options);
        }
      }
    });

    this.pano.addEventListener("sasExpired", this.handleUpdateBlobDataLst);

    document.addEventListener("gesturestart", e => {
      e.preventDefault();
    });
  }

  chooseShootSpot = value => {
    this.onResetFlagRedirectNoImage();
    // if (!this.props.imageShootUnAdjusted.shootCode) {
    const { historyList } = this.props;
    const shoot = this.props.listShootCurrentActive.find(
      item => item.spotsUC === value.uniqueCode || item.spotsUC === value
    );
    this.props.onSetSpotCurrent(value.uniqueCode);
    this.props.onSetTypeSelectImage3D(TYPE_SELECT_IMAGE_3D.SPOT);
    this.props.onSetIsDefaultHistory();
    if (!!shoot) {
      if (this.state.classification === "supplier") {
        this.props.history.replace(
          "/sp/logwalk-viewer/shoots/" + shoot.uniqueCode
        );
      } else {
        this.props.history.replace(
          "/logwalk-viewer/shoots/" + shoot.uniqueCode
        );
      }
    } else {
      this.setState({ isRefreshSas: false }, this.props.fetchShootLogWalk());
      if (!this.props.viewerUnAdjustedImage) {
        if (this.props.logWalkInfo.recordsUC === "default") {
          this.props.onSetHistoryViewer(
            historyList && historyList[0]
              ? historyList[0].uniqueCode
              : undefined
          );
        }
        if (this.props.classification === "supplier") {
          this.props.history.replace("/sp/logwalk-viewer/shoots/no-image");
        } else {
          this.props.history.replace("/logwalk-viewer/shoots/no-image");
        }
      } else {
        const params = `?sitesUC=${this.props.match.params.id}&spotsUC=${value.uniqueCode}`;
        if (this.props.classification === "supplier") {
          this.props.history.push(
            "/sp/logwalk-viewer/shoots/no-image" + params
          );
        } else {
          this.props.history.push("/logwalk-viewer/shoots/no-image" + params);
        }
      }
    }
    this.onResetWidthHeightMiniWindow();
    this.onResetPositionMiniWindowViewer();
    // }
  };

  checkActiveSpot = spotsUC => {
    const { listShootCurrentActive } = this.props;
    const indexActive = listShootCurrentActive.findIndex(
      item => item.spotsUC === spotsUC
    );
    return indexActive > -1;
  };

  loadInit = () => {
    const {
      match,
      onInitShoot,
      location,
      fetchInfoLogwalkView,
      noImage,
      sitesUCNoImage,
      spotsUCNoImage,
      viewerUnAdjustedImage
    } = this.props;
    const codeDrawingPhoto = match.params.id;
    if (!viewerUnAdjustedImage) {
      if (codeDrawingPhoto === "no-image") {
        if (location.search) {
          const paramsSearch = querySearch(location.search);
          this.props.onSetTypeSelectImage3D(TYPE_SELECT_IMAGE_3D.SPOT);
          fetchInfoLogwalkView(paramsSearch, this.state.classification, true);
        }
      } else {
        // Add params hotSpotData to process redirect view on builder to supplier and reverse
        const paramsSearch = querySearch(location.search);
        let hotSpotData = null;
        if (paramsSearch && paramsSearch.info) {
          hotSpotData = {
            infoUC: paramsSearch.info,
            isRedirectLogin: "isRedirectLogin" in paramsSearch
          };
        }
        onInitShoot(
          codeDrawingPhoto,
          this.state.classification,
          this.onRedirectNotfoundPage,
          this.onSetProgressBar,
          this.redirectNoImage,
          hotSpotData,
          this.onRedirectTo
        );
      }

      if (location.search !== "") {
        const { info } = querySearch(location.search);
        this.props.onOpenHotspotInfo(info);
      }
    } else {
      // this.props.onSetTypeSelectImage3D(TYPE_SELECT_IMAGE_3D.SPOT);
      // fetchInfoLogwalkView({ sitesUC: sitesUCNoImage, spotsUC: spotsUCNoImage }, this.state.classification, true);
      this.setState({
        isOpenAdjust: true,
        showPanelAdjustImage: true
      });
    }
  };

  onRedirectNotfoundPage = statusCode => {
    const { classification } = this.state;
    if (statusCode === 404) {
      if (classification === "supplier") {
        this.props.history.push("/sp/not-found");
      } else {
        this.props.history.push("/not-found");
      }
    } else if (statusCode === 401) {
      if (classification === "supplier") {
        this.props.history.push("/sp/login");
      } else {
        this.props.history.push("/login");
      }
    }
  };

  onRedirectTo = url => {
    if (url) this.props.history.push(url);
  };

  getZIndexHotspotInfo = () => {
    return this.state.zIndexInfoHotspot;
  };
  increaseZIndexHotspotInfo = () => {
    this.setState({ zIndexInfoHotspot: this.state.zIndexInfoHotspot + 1 });
  };

  resetZIndexHotspotInfo = () => {
    this.setState({ zIndexInfoHotspot: 1 });
  };

  handleUpdateBlobDataLst = () => {
      if (!this.state.reloadBlobDataLst403) {
        this.setState({ reloadBlobDataLst403: true });

        let isBlob = true;
        if (this.props.blobDataLogWalk.blob.includes("/file?bin")) {
          isBlob = false;
        }

        fetchShootLogWalkUpdate(this.props.blobDataLogWalk.blob)
          .then(res => {
            if (res.data) {
              let tmpBlobDataLst;

              if (isBlob) {
                tmpBlobDataLst = res.data.blobDataLst
              } else {

                tmpBlobDataLst = [
                  `data:image/jpeg;base64,${arrayBufferToBase64(res.data)}`
                ];
              }

              this.blobExtention.updateBlobDataLst(tmpBlobDataLst);
              this.setState({ reloadBlobDataLst403: false });
            }
          })
          .catch(() => this.setState({ reloadBlobDataLst403: false }));
      }
  }

  initTileAzBlogStorage(blobDataLogWalk) {
    this.geoType = "Tile";
    var viewerOpts = {
      controls: {
        mouseViewMode: "drag"
      }
    };
    this.blobExtention = new marzipano.AzBlobExtension(
      blobDataLogWalk.blobDataLst,
      this.pano
    );

    if (!this.viewer) {
      //   this.viewer.destroy();
      // }

      this.viewer = new marzipano.Viewer(
        this.pano,
        viewerOpts,
        this.blobExtention
      );
    }
    else{
      this.viewer.updateAzBlobExt(
        this.blobExtention,
        viewerOpts
        );
    }

    var urlPrefix = "";
    var source = marzipano.ImageUrlSource.fromString(
      urlPrefix + "/{z}/{f}/{y}/{x}.jpg",
      { cubeMapPreviewUrl: urlPrefix + "/preview.jpg" }
    );
    var LEVELS = [
      {
        tileSize: 256,
        size: 256,
        fallbackOnly: true
      },
      {
        tileSize: 512,
        size: 512
      },
      {
        tileSize: 512,
        size: 1024
      },
      {
        tileSize: 512,
        size: 2048
      }
    ];
    var geometry = new marzipano.CubeGeometry(LEVELS);

    this.switchPhoto(source, geometry, blobDataLogWalk);
  }

  initEQRPano(blobDataLogWalk) {
    this.geoType = "EQR";
    var viewerOpts = {
      controls: {
        mouseViewMode: "drag"
      },
      stage: { preserveDrawingBuffer: true }
    };
    this.blobExtention = new marzipano.AzBlobExtension(
      blobDataLogWalk
        ? blobDataLogWalk.blobDataLst
        : [
            "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="
          ],
      this.pano
    );

    if (!this.viewer) {
      //   this.viewer.destroy();
      // }

      this.viewer = new marzipano.Viewer(
        this.pano,
        viewerOpts,
        this.blobExtention
      );
    }
    else{
      this.viewer.updateAzBlobExt(
        this.blobExtention,
        viewerOpts
        );
    }
    
    const eqrBlobSearchKey = "/eqr";
    var source = marzipano.ImageUrlSource.fromString(eqrBlobSearchKey);
    var geometry = new marzipano.EquirectGeometry([{ width: 4000 }]);

    this.switchPhoto(source, geometry, blobDataLogWalk);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.match.path !== this.props.match.path &&
      this.props.match.path.includes("/logwalk-viewer/shoots")
    ) {
      const { match, onInitShoot, location } = this.props;
      if (match.path.includes("/sp/")) {
        this.setState({ classification: "supplier" }, () => {
          this.loadInit();
        });
      } else {
        this.setState({ classification: "builder" }, () => {
          this.loadInit();
        });
      }
    }
    if (prevProps.spotCurrent !== this.props.spotCurrent) {
      if (!this.props.spotCurrent) {
        this.setState({
          isOpenAdjust: true,
          showPanelAdjustImage: true
        });
      }
    }

    const { blobDataLogWalk, imageShootUnAdjusted, spotCurrent } = this.props;

    if (
      (blobDataLogWalk !== prevProps.blobDataLogWalk ||
        (blobDataLogWalk &&
          blobDataLogWalk.blob !== prevProps.blobDataLogWalk.blob)) &&
      !this.state.isRefreshSas
    ) {
      if (this.props.isCreateHotspotInfo && this.props.isOpenHotspotInfo) {
        this.cancelCreateHotspotInfo();
      }
      this.resetZIndexHotspotInfo();
      this.setPanorama(blobDataLogWalk);
    }

    if (
      blobDataLogWalk &&
      blobDataLogWalk.refreshSasEstimatedTime !==
      prevProps.blobDataLogWalk?.refreshSasEstimatedTime
    ) {
      if (blobDataLogWalk.isRefresh) {
        this.blobExtention.updateBlobDataLst(blobDataLogWalk.blobDataLst);
      }

      if (this.sasTimeout) {
        clearTimeout(this.sasTimeout);
        this.setState({ isRefreshSas: false });
      }

      const countDown =
        Date.parse(blobDataLogWalk.refreshSasEstimatedTime) -
        Date.parse(new Date().toUTCString());
      if (countDown) {
        this.sasTimeout = setTimeout(() => {
          this.setState(
            { isRefreshSas: true },
            this.props.fetchShootLogWalk(this.props.blobDataLogWalk?.blob, true)
          );
        }, countDown);
      }
    }

    if (!blobDataLogWalk && this.sasTimeout) {
      clearTimeout(this.sasTimeout);
    }

    if (
      (prevProps.logWalkInfo.infos !== this.props.logWalkInfo.infos ||
        prevState.isShowUnfinishedHotspotInfo !==
        this.state.isShowUnfinishedHotspotInfo ||
        prevState.isShowAccomplishedHotspotInfo !==
        this.state.isShowAccomplishedHotspotInfo ||
        prevState.showModeEditLink !== this.state.showModeEditLink ||
        prevState.screenshotVRBase64 !== this.state.screenshotVRBase64) &&
      !this.state.isOpenAdjust
    ) {
      if (this.viewer) {
        clearTempHotSpotInfo(this.viewer.scene().hotspotContainer());
        clearAllHotSpotInfo(this.viewer.scene().hotspotContainer());
      }

      if (
        blobDataLogWalk &&
        this.viewer &&
        !this.state.showModeEditLink &&
        !this.state.screenshotVRBase64
      ) {
        if (this.props.logWalkInfo.infos != []) {
          const scene = this.viewer.scene();
          const logWalkInfoYaw = this.props.logWalkInfo.yaw || 0;
          this.props.logWalkInfo.infos.forEach(hotspotRaw => {
            let hotspot = {
              ...hotspotRaw,
              yaw: this.getValidYaw(
                hotspotRaw.yaw + (hotspotRaw.isHistory ? logWalkInfoYaw : 0)
              )
            };

            if (
              this.state.isShowUnfinishedHotspotInfo &&
              !hotspot.completedAt
            ) {
              var element = createInfoHotSpotElement(
                hotspot,
                scene,
                this.props.onOpenHotspotInfo,
                this.cancelCreateHotspotInfo,
                this.getZIndexHotspotInfo,
                this.increaseZIndexHotspotInfo
              );
              scene.hotspotContainer().createHotspot(element, {
                yaw: hotspot.yaw,
                pitch: hotspot.pitch
              });
            }

            if (
              this.state.isShowAccomplishedHotspotInfo &&
              hotspot.completedAt
            ) {
              var element = createInfoHotSpotElement(
                hotspot,
                scene,
                this.props.onOpenHotspotInfo,
                this.cancelCreateHotspotInfo,
                this.getZIndexHotspotInfo,
                this.increaseZIndexHotspotInfo
              );
              scene.hotspotContainer().createHotspot(element, {
                yaw: hotspot.yaw,
                pitch: hotspot.pitch
              });
            }
          });
        }
      }
    }

    if (
      (prevProps.drawingsWithSpots !== this.props.drawingsWithSpots ||
        prevProps.logWalkInfo.spotsUC !== this.props.logWalkInfo.spotsUC ||
        prevState.showModeEditLink !== this.state.showModeEditLink ||
        prevState.isShowLink !== this.state.isShowLink ||
        prevState.selectedLinkForEditing !== this.state.selectedLinkForEditing ||
        prevProps.blobDataLogWalk !== this.props.blobDataLogWalk ||
        prevProps.listShootCurrentActive !== this.props.listShootCurrentActive ||
        prevState.screenshotVRBase64 !== this.state.screenshotVRBase64) &&
      this.props.logWalkInfo?.walkThrough &&
      !this.state.isOpenAdjust
    ) {
      if (blobDataLogWalk && this.viewer) {
        if (
          (this.state.showModeEditLink && this.state.selectedLinkForEditing) ||
          (!this.state.isShowLink &&
            !(
              this.state.showModeEditLink && !this.state.selectedLinkForEditing
            )) ||
          this.state.screenshotVRBase64
        ) {
          clearAllHotSpotLink(this.viewer.scene().hotspotContainer());
        } else {
          clearAllHotSpotLink(this.viewer.scene().hotspotContainer());
          if (this.props.drawingsWithSpots) {
            const currentDrawing = this.props.drawingsWithSpots.find(drawing => {
              const findSpot = drawing.spots.find(
                spot => spot.uniqueCode === this.props.logWalkInfo.spotsUC
              );
              return findSpot;
            });
            
            if(currentDrawing) {
              const currentSpot = currentDrawing.spots.find(
                spot => spot.uniqueCode === this.props.logWalkInfo.spotsUC
              );
    
              if (
                currentDrawing.direction !== null &&
                currentDrawing.direction !== undefined &&
                this.props.logWalkInfo.direction !== null &&
                this.props.logWalkInfo.direction !== undefined &&
                currentSpot &&
                currentSpot.links
              ) {
                let links = [];
                const fixedPitch = 0.5;
                const drawingDirection = currentDrawing.direction;
                const shootDirection = this.props.logWalkInfo.direction;
                const correlationAngleEditLink = this.props.logWalkInfo.yaw;
                const correlationAngle =
                  (((drawingDirection + shootDirection) % 360) / 180) * Math.PI;
    
                currentSpot.links.forEach(link => {
                  links.push({
                    uniqueCode: link.spotsUC,
                    name: currentDrawing.spots.find(
                      spot => spot.uniqueCode === link.spotsUC
                    ).name,
                    yaw: link.dir - correlationAngle + correlationAngleEditLink,
                    pitch: fixedPitch,
                    rotation: 0,
                    isActiveSpot: this.checkActiveSpot(link.spotsUC)
                  });
                });
    
                const scene = this.viewer.scene();
                links.forEach(hotspot => {
                  let element = createLinkHotspotElement(hotspot, spotsUC => {
                    if(this.state.createHotspotMode) return;
                    if (this.state.showModeEditLink) {
                      this.selectLinkForEditing(hotspot);
                    } else {
                      if (hotspot.isActiveSpot) {
                        const view = scene.view();
                        this.setState({
                          previousShootDirection:
                            (this.props.logWalkInfo.direction * Math.PI) / 180 +
                            view.yaw() -
                            (this.props.logWalkInfo.yaw || 0)
                        }, () => {
                          this.chooseShootSpot(spotsUC);
                        });
                      }
                    }
                  });
                  scene.hotspotContainer().createHotspot(element, {
                    yaw: hotspot.yaw,
                    pitch: hotspot.pitch
                  });
                });
              }
            }
          }
        }
      }
    }

    if (
      prevProps.imageShootUnAdjusted.shootCode !==
      imageShootUnAdjusted.shootCode
    ) {
      if (!this.props.imageShootUnAdjusted.shootCode) {
        if (!this.state.isOpenAdjust) {
          this.setPanorama(blobDataLogWalk);
        }
      } else {
        this.resetZIndexHotspotInfo();
        this.setPanorama(
          imageShootUnAdjusted && imageShootUnAdjusted.tile
            ? imageShootUnAdjusted.tile
            : "",
          imageShootUnAdjusted && imageShootUnAdjusted.preview
            ? imageShootUnAdjusted.preview
            : "",
          imageShootUnAdjusted && imageShootUnAdjusted.levels
            ? imageShootUnAdjusted.levels
            : "",
          imageShootUnAdjusted.url
        );
      }
    }

    if (
      prevProps.match.params.id !== this.props.match.params.id &&
      this.props.match.params.id !== "no-image"
    ) {
      this.props.onInitShoot(
        this.props.match.params.id,
        this.state.classification,
        this.onRedirectNotfoundPage,
        this.onSetProgressBar,
        this.redirectNoImage
      );
    }

    if (
      prevProps.logWalkInfo.recordsUC !== this.props.logWalkInfo.recordsUC &&
      prevProps.listShootCurrentActive !== this.props.listShootCurrentActive &&
      !this.props.viewerUnAdjustedImage
    ) {
      if (this.checkHasShootPhoto(this.props.logWalkInfo.recordsUC, false)) {
        const shoot = this.props.listShootCurrentActive.find(
          item => item.spotsUC === this.props.spotCurrent
        );
        if (this.state.classification === "supplier") {
          this.props.history.replace(
            "/sp/logwalk-viewer/shoots/" + shoot.uniqueCode
          );
        } else {
          this.props.history.replace(
            "/logwalk-viewer/shoots/" + shoot.uniqueCode
          );
        }
      } else {
        this.setState({ isRefreshSas: false }, this.props.fetchShootLogWalk());
        if (this.state.classification === "supplier") {
          this.props.history.push("/sp/logwalk-viewer/shoots/no-image");
        } else {
          this.props.history.push("/logwalk-viewer/shoots/no-image");
        }
      }
    }

    if (this.viewer && this.props.isShowDirectionDrawer) {
      this.unregisterControls();
      this.registerControls();
    }

    if (
      this.props.logWalkInfo &&
      this.props.logWalkInfo.buildersUC &&
      prevProps.logWalkInfo?.buildersUC !== this.props.logWalkInfo?.buildersUC
    ) {
      api
        .post(
          `/${this.state.classification}/cometchat/builders/${this.props.logWalkInfo.buildersUC}/login`
        )
        .then(res => {
          const appID = res.data.appID;
          const region = res.data.appRegion || "us";
          const authToken = res.data.authToken;
          this.setState({ cometchatAuthToken: authToken });

          const appSetting = new CometChat.AppSettingsBuilder()
            .subscribePresenceForAllUsers()
            .setRegion(region)
            .build();
          CometChat.init(appID, appSetting).then(
            () => {
              if (CometChat.setSource) {
                CometChat.setSource("ui-kit", "web", "reactjs");
              }
              // console.log("Initialization completed successfully");
              CometChat.login(authToken).then(
                user => {
                  // console.log("Login Successful:", { user });
                  this.props.loginCometChatSuccess({ ...user, appID: appID });
                  if (
                    window.location.href.indexOf("/logwalk-viewer/shoots/") < 0 &&
                    !this.isLogout
                  ) {
                    this.isLogout = true;
                    CometChat.logout().then(() => {
                      const data = { authToken: authToken };
                      api.post(
                        `/${this.state.classification}/cometchat/builders/${this.props.logWalkInfo.buildersUC}/logout`,
                        data
                      );
                      this.props.logoutCometChatSuccess();
                    });
                  }
                },
                error => {
                  // console.log("Login failed with exception:", { error });
                }
              );
            },
            error => {
              // console.log("Initialization failed with error:", error);
            }
          );
        })
        .catch(error => {
          if (error.response.status === 500) {
            alert("エラーが発生しました。 お手数ですが、再度お試しください。");
            this.props.history.go(0);
          }
        });
    }

    if(prevState.showModeEditLink !== this.state.showModeEditLink) {
      const linkElementList = document.getElementsByClassName("link-hotspot");
      if(linkElementList.length > 0) {
        if(this.state.showModeEditLink) {
          for (let i = 0; i < linkElementList.length; i++) {
            const link = linkElementList[i];
            link.classList.add("show-full")
          } 
        } else {
          for (let i = 0; i < linkElementList.length; i++) {
            const link = linkElementList[i];
            link.classList.remove("show-full")
          }
        }
      }
    }

    if(prevState.createHotspotMode !== this.state.createHotspotMode) {
      const linkElementList = document.getElementsByClassName("link-hotspot");
      if(linkElementList.length > 0) {
        if(this.state.createHotspotMode) {
          for (let i = 0; i < linkElementList.length; i++) {
            const link = linkElementList[i];
            link.childNodes[0].style["cursor"] = "default";
            link.childNodes[1].style["cursor"] = "default";
          } 
        } else {
          for (let i = 0; i < linkElementList.length; i++) {
            const link = linkElementList[i];
            link.childNodes[0].style["cursor"] = "pointer";
            link.childNodes[1].style["cursor"] = "pointer";
          }
        }
      }
    }
  }

  registerControls = () => {
    //Hide calendar history
    document.querySelectorAll(".direction-btn").forEach(e =>
      e.addEventListener("click", () =>
        this.setState({
          changeDirection: !this.state.changeDirection
        })
      )
    );
    var viewUpElement = document.querySelector("#viewUp");
    var viewDownElement = document.querySelector("#viewDown");
    var viewLeftElement = document.querySelector("#viewLeft");
    var viewRightElement = document.querySelector("#viewRight");
    var viewInElement = document.querySelector("#viewIn");
    var viewOutElement = document.querySelector("#viewOut");
    var controls = this.viewer.controls();
    if (viewUpElement) {
      controls.registerMethod(
        "upElement",
        new marzipano.ElementPressControlMethod(viewUpElement, "y", -0.7, 3),
        true
      );
      controls.registerMethod(
        "downElement",
        new marzipano.ElementPressControlMethod(viewDownElement, "y", 0.7, 3),
        true
      );
      controls.registerMethod(
        "leftElement",
        new marzipano.ElementPressControlMethod(viewLeftElement, "x", -0.7, 3),
        true
      );
      controls.registerMethod(
        "rightElement",
        new marzipano.ElementPressControlMethod(viewRightElement, "x", 0.7, 3),
        true
      );
      controls.registerMethod(
        "inElement",
        new marzipano.ElementPressControlMethod(viewInElement, "zoom", -0.7, 3),
        true
      );
      controls.registerMethod(
        "outElement",
        new marzipano.ElementPressControlMethod(viewOutElement, "zoom", 0.7, 3),
        true
      );
    }
  };

  unregisterControls = () => {
    document
      .querySelectorAll(".direction-btn")
      .forEach(e => e.removeEventListener("click", () => {}));

    var controls = this.viewer.controls();
    if (controls.method("upElement")) {
      controls.unregisterMethod("upElement");
      controls.unregisterMethod("downElement");
      controls.unregisterMethod("leftElement");
      controls.unregisterMethod("rightElement");
      controls.unregisterMethod("inElement");
      controls.unregisterMethod("outElement");
    }
  };

  redirectNoImage = () => {
    this.setState({ isRefreshSas: false }, this.props.fetchShootLogWalk());

    this.setState({ isRedirectFromNoImage: true });
  };

  setPanorama = blobDataLogWalk => {
    if (blobDataLogWalk) {
      this.setState({ loadPhotoDone: false });

      if (blobDataLogWalk.geoType === "Tile") {
        this.initTileAzBlogStorage(blobDataLogWalk);
      } else {
        this.initEQRPano(blobDataLogWalk);
      }
    } else {
      this.initEQRPano();
      this.setState({ hasPhoto: false });
    }
  };

  switchPhoto = (source, geometry, hasPhoto) => {
    const listScenes = this.viewer.listScenes()
    if(this.viewer && listScenes.length > 0) {
      for (let i = 0; i < listScenes.length; i++) {
        const element = listScenes[i];
        this.viewer.destroyScene(element);
      }
    }

    const imageNotTransfer = this.checkImageNotTransfer(
      this.props.logWalkInfo.recordsUC
    );

    // Create view.
    var limiter = marzipano.util.compose(
      marzipano.RectilinearView.limit.vfov(Math.PI * 10 / 180, Math.PI * 120 / 180),
      marzipano.RectilinearView.limit.hfov(Math.PI * 10 / 180, Math.PI * 120 / 180),
      marzipano.RectilinearView.limit.pitch(-Math.PI / 2, Math.PI / 2)
    );
    var view = new marzipano.RectilinearView({ yaw: 0 }, limiter);
    var scene = this.viewer.createScene({
      source: source,
      geometry: geometry,
      view: view
    });

    // Display scene.
    scene.switchTo({}, () => {
      this.setState({
        loadPhotoDone: true,
        imageNotTransfer,
        hasPhoto: hasPhoto,
        convertingPhoto: this.state.showPanelAdjustImage
          ? this.props.imageShootUnAdjusted.tiling
          : this.props.logWalkInfo.tiling
      });
    });

    clearAllHotSpotLink(this.viewer.scene().hotspotContainer());
    if (hasPhoto && !this.state.showPanelAdjustImage) {
      if (
        this.props.drawingsWithSpots &&
        this.props.logWalkInfo?.walkThrough &&
        this.state.isShowLink &&
        this.props.logWalkInfo
      ) {
        const currentDrawing = this.props.drawingsWithSpots.find(drawing => {
          const findSpot = drawing.spots.find(
            spot => spot.uniqueCode === this.props.logWalkInfo.spotsUC
          );
          return findSpot;
        });
        
        if(currentDrawing) {
          const currentSpot = currentDrawing.spots.find(
            spot => spot.uniqueCode === this.props.logWalkInfo.spotsUC
          );

          if (
            currentDrawing.direction !== null &&
            currentDrawing.direction !== undefined &&
            this.props.logWalkInfo.direction !== null &&
            this.props.logWalkInfo.direction !== undefined &&
            currentSpot &&
            currentSpot.links
          ) {
            let links = [];
            const fixedPitch = 0.5;
            const drawingDirection = currentDrawing.direction;
            const shootDirection = this.props.logWalkInfo.direction;
            const correlationAngleEditLink = this.props.logWalkInfo.yaw;
            const correlationAngle =
              (((drawingDirection + shootDirection) % 360) / 180) * Math.PI;

            currentSpot.links.forEach(link => {
              links.push({
                uniqueCode: link.spotsUC,
                name: currentDrawing.spots.find(
                  spot => spot.uniqueCode === link.spotsUC
                ).name,
                yaw: link.dir - correlationAngle + correlationAngleEditLink,
                pitch: fixedPitch,
                rotation: 0,
                isActiveSpot: this.checkActiveSpot(link.spotsUC)
              });
            });

            const scene = this.viewer.scene();
            links.forEach(hotspot => {
              let element = createLinkHotspotElement(hotspot, spotsUC => {
                if(this.state.createHotspotMode) return;
                if (this.state.showModeEditLink) {
                  this.selectLinkForEditing(hotspot);
                } else {
                  if (hotspot.isActiveSpot) {
                    const view = scene.view();
                    this.setState({
                        previousShootDirection:
                          (this.props.logWalkInfo.direction * Math.PI) / 180 +
                          view.yaw() -
                          (this.props.logWalkInfo.yaw || 0)
                      }, () => {
                        this.chooseShootSpot(spotsUC);
                      });
                  }
                }
              });
              scene.hotspotContainer().createHotspot(element, {
                yaw: hotspot.yaw,
                pitch: hotspot.pitch
              });
            });
          }
        }
      }

      if (this.props.logWalkInfo.infos != []) {
        const scene = this.viewer.scene();
        const logWalkInfoYaw = this.props.logWalkInfo.yaw || 0;
        this.props.logWalkInfo.infos.forEach(hotspotRaw => {
          let hotspot = {
            ...hotspotRaw,
            yaw: this.getValidYaw(
              hotspotRaw.yaw + (hotspotRaw.isHistory ? logWalkInfoYaw : 0)
            )
          };

          if (this.state.isShowUnfinishedHotspotInfo && !hotspot.completedAt) {
            var element = createInfoHotSpotElement(
              hotspot,
              scene,
              this.props.onOpenHotspotInfo,
              this.cancelCreateHotspotInfo,
              this.getZIndexHotspotInfo,
              this.increaseZIndexHotspotInfo
            );
            scene.hotspotContainer().createHotspot(element, {
              yaw: hotspot.yaw,
              pitch: hotspot.pitch
            });
          }

          if (this.state.isShowAccomplishedHotspotInfo && hotspot.completedAt) {
            var element = createInfoHotSpotElement(
              hotspot,
              scene,
              this.props.onOpenHotspotInfo,
              this.cancelCreateHotspotInfo,
              this.getZIndexHotspotInfo,
              this.increaseZIndexHotspotInfo
            );
            scene.hotspotContainer().createHotspot(element, {
              yaw: hotspot.yaw,
              pitch: hotspot.pitch
            });
          }
        });
      }
    }

    let destinationViewParameters = {
      fov: "2.09439333333333",
      hfov: "2.09439333333333"
    };
    if (hasPhoto && !this.state.showPanelAdjustImage) {
      if (this.props.location.search !== "") {
        const { info } = querySearch(this.props.location.search);
        const rotateToHotspot = this.props.logWalkInfo.infos.find(
          hotspot => hotspot.uniqueCode === info
        );
        if (rotateToHotspot) {
          const logWalkInfoYaw = this.props.logWalkInfo.yaw || 0;
          destinationViewParameters["yaw"] =
            rotateToHotspot.yaw +
            (rotateToHotspot.isHistory ? logWalkInfoYaw : 0);
          destinationViewParameters["pitch"] = rotateToHotspot.pitch;

          this.setState({ isShowAccomplishedHotspotInfo: !!rotateToHotspot.completedAt });
        }
      }

      if (destinationViewParameters["yaw"] === undefined) {
        const currentShootDirection = this.props.logWalkInfo.direction;
        const previousShootDirection = this.state.previousShootDirection;
        const compassSetting = this.props.logWalkInfo.compassSetting;
        if (typeof previousShootDirection === "number") {
          if (typeof currentShootDirection === "number") {
            destinationViewParameters["yaw"] =
              previousShootDirection -
              (currentShootDirection / 180) * Math.PI +
              (this.props.logWalkInfo.yaw || 0);
            destinationViewParameters["pitch"] = (5 / 180) * Math.PI;
          }
        } else {
          if (typeof currentShootDirection === "number") {
            if (typeof compassSetting === "number") {
              destinationViewParameters["yaw"] =
                -((currentShootDirection + compassSetting) / 180) * Math.PI +
                (this.props.logWalkInfo.yaw || 0);
              destinationViewParameters["pitch"] = (5 / 180) * Math.PI;
            }
          }
        }
      }
    }

    var options = {
      transitionDuration: 100
    };
    scene.lookTo(destinationViewParameters, options);

    if (this.props.logWalkInfo.walkThrough) {
      scene.addEventListener("viewChange", () => {
        if (this.dragTimeout) {
          clearTimeout(this.dragTimeout);
        }
        const hotspotLinkEls = document.getElementsByClassName("link-hotspot");

        if (hotspotLinkEls && hotspotLinkEls.length > 0) {
          for (let hotspotLinkEl of hotspotLinkEls) {
            hotspotLinkEl.classList.add("hotspot-link-view-change");
          }

          this.dragTimeout = setTimeout(() => {
            for (let hotspotLinkEl of hotspotLinkEls) {
              hotspotLinkEl.classList.remove("hotspot-link-view-change");
            }
          }, 500);
        }
      });
    }
  };

  componentWillUnmount() {
    document.getElementsByTagName("html")[0].style.overflow = null;
    this.props.onSetImageShootUnAdjusted({});
    if (!this.props.viewerUnAdjustedImage) {
      this.props.resetStore();
    }
    window.removeEventListener("resize", this.showToolBarPortrait);
    document.removeEventListener("gesturestart", e => {
      e.preventDefault();
    });

    if (
      this.state.cometchatAuthToken &&
      this.props.logWalkInfo &&
      this.props.logWalkInfo.buildersUC &&
      !this.isLogout
    ) {
      this.isLogout = true;
      CometChat.logout().then(() => {
        const data = { authToken: this.state.cometchatAuthToken };
        api.post(
          `/${this.state.classification}/cometchat/builders/${this.props.logWalkInfo.buildersUC}/logout`,
          data
        );
        this.props.logoutCometChatSuccess();
      });
    }

    if (this.sasTimeout) {
      clearTimeout(this.sasTimeout);
    }
  }

  handleShowPanelAdjust = value => {
    this.setState({
      showPanelAdjustImage: value
    });
  };

  onResetPositionMiniWindowViewer = () => {
    this.setState({ positionMiniWindowViewer: { x: 0, y: 0 } });
  };

  handleChangeHistory = (e, historyDate) => {
    const { historyList } = this.props;
    this.onResetFlagRedirectNoImage();

    if (
      this.state.viewer &&
      typeof this.props.logWalkInfo.direction === "number"
    ) {
      if(this.state.hasPhoto) {
        const scene = this.viewer.scene();
        const view = scene.view();
        this.setState({
          previousShootDirection:
            (this.props.logWalkInfo.direction * Math.PI) / 180 +
            view.yaw() -
            (this.props.logWalkInfo.yaw || 0)
        });
      } else {
        this.resetPreviousShootDirection();
      }

    }

    this.props.onSetHistoryViewer(
      historyDate ?? (e ? e.target.value : "default")
    );
    // }

    this.props.onSetTypeSelectImage3D(TYPE_SELECT_IMAGE_3D.HISTORY);
    this.props.onSetIsDefaultHistory();
  };

  handleShowDialogViewer = value => {
    this.setState({ showDialogViewer: value });
  };

  updateDimensions = e => {
    this.setPositionMarker();
  };

  onBack = () => {
    const { redirectFromUrl, logWalkInfo } = this.props;
    if (
      redirectFromUrl.isRedirectFromUrl &&
      redirectFromUrl.from &&
      !redirectFromUrl.logwalk
    ) {
      this.props.history.push(redirectFromUrl.from);
    } else {
      if (this.state.classification === "builder") {
        this.props.history.push("/sites");
      } else {
        this.props.history.push("/sp/dashboard");
      }
    }
  };

  checkHasShootPhoto = (value, checkWaiting = true) => {
    const {
      listShootsSpot,
      spotCurrent,
      isDefaultHistory,
      listShootCurrentActiveLatest
    } = this.props;
    if (value === "default") {
      const indexHasShoot = listShootCurrentActiveLatest.findIndex(item =>
        checkWaiting
          ? item.spotsUC === spotCurrent &&
            item.status === IMAGE_3D_LOAD_STATUS.READY
          : item.spotsUC === spotCurrent
      );
      return indexHasShoot > -1;
    } else {
      const history = listShootsSpot.find(item => item.uniqueCode === value);
      if (history) {
        const indexHasShoot = history.shoots.findIndex(item =>
          checkWaiting
            ? item.spotsUC === spotCurrent &&
              item.status === IMAGE_3D_LOAD_STATUS.READY
            : item.spotsUC === spotCurrent
        );
        return indexHasShoot > -1;
      }
    }

    return false;
  };

  checkImageNotTransfer = value => {
    const {
      listShootsSpot,
      spotCurrent,
      isDefaultHistory,
      listShootCurrentActiveLatest
    } = this.props;
    if (value === "default") {
      const indexHasShoot = listShootCurrentActiveLatest.findIndex(
        item =>
          item.spotsUC === spotCurrent &&
          item.status !== IMAGE_3D_LOAD_STATUS.READY
      );
      return indexHasShoot > -1;
    } else {
      const history = listShootsSpot.find(item => item.uniqueCode === value);
      if (history) {
        const indexHasShoot = history.shoots.findIndex(
          item =>
            item.spotsUC === spotCurrent &&
            item.status !== IMAGE_3D_LOAD_STATUS.READY
        );
        return indexHasShoot > -1;
      }
    }

    return false;
  };

  handleShareShootsUC = () => {
    const { logWalkInfo, onShareShootsUC } = this.props;
    const { classification } = this.state;
    onShareShootsUC({
      shootsUC: this.props.match.params.id,
      share: !logWalkInfo.share,
      classification
    });
  };

  backToDrawing = () => {
    const {
      historyList,
      onSetHistoryViewer,
      viewerUnAdjustedImage,
      onSetImageShootUnAdjusted,
      onSetTypeSelectImage3D
    } = this.props;
    this.setState({ isRefreshSas: false }, this.props.fetchShootLogWalk());
    this.onCloseAdjustImage();
    this.setState({ showPanelAdjustImage: false });
    onSetImageShootUnAdjusted({});
    this.props.onSetTypeDialog({
      typeDialog: null
    });
    if (viewerUnAdjustedImage) {
      const recordUC =
        historyList && historyList[0] ? historyList[0].uniqueCode : undefined;
      onSetHistoryViewer(recordUC);
      onSetTypeSelectImage3D(TYPE_SELECT_IMAGE_3D.VIEWER);
    }

    if (this.props.spotCurrent) {
      this.loadInit();

      const shoot = this.props.listShootCurrentActive.find(
        item => item.spotsUC === this.props.spotCurrent
      );

      if (shoot) {
        if (this.state.classification === "supplier") {
          this.props.history.replace(
            "/sp/logwalk-viewer/shoots/" + shoot.uniqueCode
          );
        } else {
          this.props.history.replace(
            "/logwalk-viewer/shoots/" + shoot.uniqueCode
          );
        }
      } else {
        this.setState({ isRefreshSas: false }, this.props.fetchShootLogWalk());

        if (this.state.classification === "supplier") {
          this.props.history.push("/sp/logwalk-viewer/shoots/no-image");
        } else {
          this.props.history.push("/logwalk-viewer/shoots/no-image");
        }
      }
    }
  };

  onResetWidthHeightMiniWindow = () => {
    let width = 0;
    let height = 0;
    if (window.innerWidth > 560) {
      width = 250;
      height = 150;
    } else {
      width = 120;
      height = 80;
    }
    document.getElementsByClassName(
      "mini-window"
    )[0].style.width = `${width}px`;
    document.getElementsByClassName(
      "mini-window"
    )[0].style.height = `${height}px`;
  };

  checkShowHand = () => {
    const { widthMiniWindow, heightMiniWindow } = this.state;
    return widthMiniWindow === 120 && heightMiniWindow === 80;
  };

  onSetProgressBar = value => {
    this.setState({ progress: value });
  };

  onControlledDrag(e, positionMiniWindowViewer) {
    const { x, y } = positionMiniWindowViewer;
    this.setState({
      positionMiniWindowViewer: { x: x, y: y }
    });
  }

  onResetFlagRedirectNoImage = () => {
    this.setState({ isRedirectFromNoImage: false });
  };

  onOpenAdjustImage = () => {
    this.setState({ isOpenAdjust: true });
  };

  onCloseAdjustImage = () => {
    this.setState({ isOpenAdjust: false });
  };

  toggleDrawer = isShow => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ showRichInfoHotspot: isShow });
  };

  handleStart = () => {
    this.setState({
      oldPositionMiniWindowViewer: this.state.positionMiniWindowViewer
    });
  };

  handleStop = () => {
    this.setState({
      showDialogViewer:
        this.state.positionMiniWindowViewer ===
        this.state.oldPositionMiniWindowViewer
    });
    this.props.onSetTypeDialog({
      typeDialog: TYPE_DIALOG.ZOOM_IMAGE,
      modeDevice: MODE_DEVICE.SP
    });
  };

  chooseNewHotspotInfoColor = color => {
    clearTempHotSpotInfo(this.viewer.scene().hotspotContainer());
    this.cancelCreateHotspotInfo();
    this.setState({
      createHotspotMode: true,
      newHostpotInfoColor: color,
      positionNewHotspotInfo: null
    });
    document.addEventListener("mousemove", this.onMouseMove);
  };

  onMouseMove = e => {
    this.setState({ mousePosition: { x: e.clientX, y: e.clientY } });
  };

  chooseNewHotspotInfoPosition = () => {
    if (this.state.positionNewHotspotInfo === null) {
      return;
    }
    this.setState({
      createHotspotMode: false
    });

    this.props.onCreateHotspotInfo();

    document.removeEventListener("mousemove", this.onMouseMove);
  };

  cancelCreateHotspotInfo = () => {
    this.setState({
      createHotspotMode: false,
      newHostpotInfoColor: null,
      positionNewHotspotInfo: null
    });
    if (this.props.isCreateHotspotInfo && this.props.isOpenHotspotInfo) {
      this.props.onCloseHotspotInfo();
    }
    clearTempHotSpotInfo(this.viewer.scene().hotspotContainer());
    if (this.props.isCreateHotspotInfo) {
      this.props.onCancelCreateHotspotInfo();
    }

    document.removeEventListener("mousemove", this.onMouseMove);
  };

  changeNewHostpotInfoColor = iconColor => {
    this.setState({ newHostpotInfoColor: iconColor });
    clearTempHotSpotInfo(this.viewer.scene().hotspotContainer());
    const { viewer } = this.state;

    let scene = viewer.scene();

    let element = createTempInfoHotspotElement(iconColor.color);
    scene
      .hotspotContainer()
      .createHotspot(element, this.state.positionNewHotspotInfo, {
        perspective: { extraRotations: "rotateZ(-7deg) rotateY(15deg)" }
      });
  };

  containerClick = () => {
    this.setState({
      changeClickOutsideHistory: !this.state.changeClickOutsideHistory
    });
  };

  rotateToHotspotPosition = (yaw, pitch) => {
    if (this.viewer) {
      const scene = this.viewer.scene();
      const destinationViewParameters = {
        yaw: yaw,
        pitch: pitch
      };
      const options = {
        transitionDuration: 500
      };
      scene.lookTo(destinationViewParameters, options);
    }
  };

  toggleShowLink = () => {
    this.setState({
      isShowLink: !this.state.isShowLink
    });
  };

  toggleShowUnfinishedHotspotInfo = () => {
    this.setState({
      isShowUnfinishedHotspotInfo: !this.state.isShowUnfinishedHotspotInfo
    });
  };

  toggleShowAccomplishedHotspotInfo = () => {
    this.setState({
      isShowAccomplishedHotspotInfo: !this.state.isShowAccomplishedHotspotInfo
    });
  };

  toggleShowModeEditLink = () => {
    if (this.state.showModeEditLink) {
      this.setState({ showModeEditLink: false, selectedLinkForEditing: null });
    } else {
      this.setState({ showModeEditLink: true });
    }
    if (this.state.selectedLinkForEditing) {
      const view = this.viewer.scene().view();
      const limiter = marzipano.util.compose(
        marzipano.RectilinearView.limit.vfov(Math.PI * 10 / 180, Math.PI * 120 / 180),
        marzipano.RectilinearView.limit.hfov(Math.PI * 10 / 180, Math.PI * 120 / 180),
        marzipano.RectilinearView.limit.pitch(-Math.PI / 2, Math.PI / 2)
      );
      view.setLimiter(limiter);
    }
  };

  selectLinkForEditing = link => {
    const scene = this.viewer.scene();
    let destinationViewParameters = {
      fov: "2.09439333333333",
      hfov: "2.09439333333333",
      yaw: link.yaw,
      pitch: link.pitch
    };

    var options = {
      transitionDuration: 200
    };
    scene.lookTo(destinationViewParameters, options, () => {
      this.setState({ selectedLinkForEditing: link });
      const fixedPitch = 0.5;
      const view = scene.view();
      const limiter = marzipano.util.compose(
        marzipano.RectilinearView.limit.vfov(Math.PI * 10 / 180, Math.PI * 120 / 180),
        marzipano.RectilinearView.limit.hfov(Math.PI * 10 / 180, Math.PI * 120 / 180),
        marzipano.RectilinearView.limit.pitch(fixedPitch, fixedPitch)
      );
      view.setLimiter(limiter);
    });
  };

  editLinkPosition = () => {
    const view = this.viewer.scene().view();
    let newYaw =
      view.yaw() -
      this.state.selectedLinkForEditing.yaw +
      this.props.logWalkInfo.yaw;
    newYaw = newYaw % (2 * Math.PI);
    if (newYaw < 0) {
      newYaw += 2 * Math.PI;
    }

    const data = {
      yaw: newYaw,
      pitch: 0
    };

    this.props.onEditLinkPositionOnShoot(
      this.props.match.params.id,
      this.state.classification,
      data,
      () => {
        this.setState({
          showModeEditLink: false,
          selectedLinkForEditing: null
        });
        const limiter = marzipano.util.compose(
          marzipano.RectilinearView.limit.vfov(Math.PI * 10 / 180, Math.PI * 120 / 180),
          marzipano.RectilinearView.limit.hfov(Math.PI * 10 / 180, Math.PI * 120 / 180),
          marzipano.RectilinearView.limit.pitch(-Math.PI / 2, Math.PI / 2)
        );
        view.setLimiter(limiter);
      }
    );
  };

  checkActiveSpot = data => {
    const { listShootCurrentActive } = this.props;
    if (listShootCurrentActive) {
      const indexActive = listShootCurrentActive.findIndex(
        item => item.spotsUC === data
      );
      return indexActive > -1;
    }
    return false;
  };

  checkLogwalkViewerHasLinkIcon = () => {
    if (
      typeof this.props.logWalkInfo.direction !== "number" ||
      typeof this.props.logWalkInfo.compassSetting !== "number" ||
      !this.props.drawingsWithSpots ||
      !this.props.logWalkInfo.spotsUC
    ) {
      return false;
    }

    return this.props.drawingsWithSpots.find(drawing =>
      drawing.spots.find(
        spot =>
          spot.uniqueCode === this.props.logWalkInfo.spotsUC &&
          spot.links &&
          spot.links.length > 0
      )
    );
  };

  resetPreviousShootDirection = () => {
    this.setState({ previousShootDirection: null });
  };

  getValidYaw = yaw => {
    const temp = yaw % (2 * Math.PI);
    if (temp >= 0) {
      return temp;
    } else {
      return temp + 2 * Math.PI;
    }
  };

  closeMenuScreenshotVRSpace = () => {
    this.setState({ screenshotVRBase64: "" });
  };

  openMenuScreenshotVRSpace = () => {
    if (this.viewer) {
      const base64Img = this.viewer.stage().takeSnapshot({ quality: 100 });
      this.setState({ screenshotVRBase64: base64Img });
    }
  };

  takeScreenshotVRAndDownoad = () => {
    var a = document.createElement("a");
    a.href = this.state.screenshotVRBase64;
    a.setAttribute("target", "_blank");
    const siteName = this.props.logWalkInfo.siteName;
    const spotName = this.props.logWalkInfo.spotName;
    a.download = `${format(new Date(), "yyyyMMdd")}_${siteName}_${spotName}.jpg`;
    a.click();
    this.setState({ screenshotVRBase64: "" });
  };

  takeScreenshotVRAndShare = files => {
    const siteName = this.props.logWalkInfo.siteName;
    const spotName = this.props.logWalkInfo.spotName;
    shareScreenShootVRSpace(siteName, spotName, files, () => {
      this.closeMenuScreenshotVRSpace();
    });
  };

  takeScreenshotVRAndUploadToLogBuild = (files) => {
    const sitesUC = this.props.logWalkInfo.sitesUC;

    const formData = new FormData();
    formData.append("sitesUC", sitesUC);
    formData.append("file", files[0]);

    this.props.postScreenshotVRPhoto(
      formData,
      this.state.classification,
      () => {
        this.closeMenuScreenshotVRSpace();
        this.setShowSnackbarUploadScreenshotVR(true);
      }
    );
  };

  setShowSnackbarUploadScreenshotVR = (value) => {
    this.setState({ showSnackbarUploadScreenshotVR: value });
  }

  setIsShowShareUrlDialog = (value) => {
    this.setState({ isShowShareUrlDialog: value });
  }

  render() {
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };

    const {
      showDialogViewer,
      showPanelAdjustImage,
      progress,
      loadPhotoDone,
      isRedirectFromNoImage,
      isOpenAdjust,
      createHotspotMode,
      newHostpotInfoColor,
      positionNewHotspotInfo,
      imageNotTransfer,
      mousePosition,
      changeDirection,
      hasPhoto,
      convertingPhoto,
      isShowUnfinishedHotspotInfo,
      isShowAccomplishedHotspotInfo,
      isShowLink,
      showModeEditLink,
      selectedLinkForEditing
    } = this.state;
    const {
      logWalkInfo,
      historyList,
      imageShootUnAdjusted,
      typeSelectImage3D,
      blobDataLogWalk,
      historyListAdjustPhoto,
      viewerUnAdjustedImage,
      progressLoadImage3D,
      redirectFromUrl,
      listShootsSpot,
      fetchShootLogWalk,
      listAdjustPhotoSelected
    } = this.props;

    return (
      <Formik initialValues={{}} enableReinitialize onSubmit>
        {formik => (
          <div className="parent logwalk-viewer-container">
          <Grid
              className={`converting-photo ${convertingPhoto ? "show" : ""}`}
            >
              【データ変換中】
            </Grid>
            <div
              id="container"
              className="image-viewer"
              ref={this.inputRef}
              onClick={this.containerClick}
            />
            {createHotspotMode && (
              <PointSelectHotspotInfo
                chooseNewHotspotInfoPosition={this.chooseNewHotspotInfoPosition}
                cancelCreateHotspotInfo={this.cancelCreateHotspotInfo}
                positionNewHotspotInfo={positionNewHotspotInfo}
              />
            )}
            {showModeEditLink && (
              <EditLinkPosition
                editLinkPosition={this.editLinkPosition}
                toggleShowModeEditLink={this.toggleShowModeEditLink}
                selectedLinkForEditing={selectedLinkForEditing}
              />
            )}
            {
              !createHotspotMode &&
              !showModeEditLink && (
              <React.Fragment>
                <Sp>
                  <ViewerInfoAreaSP
                    imageShootUnAdjusted={imageShootUnAdjusted}
                    logWalkInfo={logWalkInfo}
                    handleChangeHistory={this.handleChangeHistory}
                    blobDataLogWalk={blobDataLogWalk}
                    checkHasShootPhoto={this.checkHasShootPhoto}
                    handleShareShootsUC={this.handleShareShootsUC}
                    isDefaultHistory={this.props.isDefaultHistory}
                    showHistory={!showPanelAdjustImage}
                    isRedirectFromDialog={redirectFromUrl.isRedirectFromUrl}
                    isLoadDone={blobDataLogWalk}
                    chooseNewHotspotInfoColor={this.chooseNewHotspotInfoColor}
                    newHostpotInfoColor={this.state.newHostpotInfoColor}
                    isShowDirectionDrawer={this.props.isShowDirectionDrawer}
                    onShowDirectionDrawer={this.props.onShowDirectionDrawer}
                    onHideDirectionDrawer={this.props.onHideDirectionDrawer}
                    isShowUnfinishedHotspotInfo={isShowUnfinishedHotspotInfo}
                    isShowAccomplishedHotspotInfo={
                      isShowAccomplishedHotspotInfo
                    }
                    isShowLink={isShowLink}
                    toggleShowLink={this.toggleShowLink}
                    toggleShowUnfinishedHotspotInfo={
                      this.toggleShowUnfinishedHotspotInfo
                    }
                    toggleShowAccomplishedHotspotInfo={
                      this.toggleShowAccomplishedHotspotInfo
                    }
                    showModeEditLink={showModeEditLink}
                    toggleShowModeEditLink={this.toggleShowModeEditLink}
                    listShootsSpot={listShootsSpot}
                    showWalkThrough={this.props.logWalkInfo?.walkThrough}
                    logwalkViewerHasLinkIcon={() => this.checkLogwalkViewerHasLinkIcon()}
                    openMenuScreenshotVRSpace={this.openMenuScreenshotVRSpace}
                    displayNone={this.state.screenshotVRBase64}
                    showShareUrlDialog={() => this.setIsShowShareUrlDialog(true)}
                    classification={this.state.classification}
                    isSharedUrl={this.props.logWalkInfo?.isSharedUrl}
                  />
                </Sp>
                <Pc>
                  <ViewerInfoAreaPC
                    imageShootUnAdjusted={imageShootUnAdjusted}
                    logWalkInfo={logWalkInfo}
                    handleChangeHistory={this.handleChangeHistory}
                    blobDataLogWalk={blobDataLogWalk}
                    checkHasShootPhoto={this.checkHasShootPhoto}
                    handleShareShootsUC={this.handleShareShootsUC}
                    isDefaultHistory={this.props.isDefaultHistory}
                    showHistory={!showPanelAdjustImage}
                    isRedirectFromDialog={redirectFromUrl.isRedirectFromUrl}
                    isLoadDone={blobDataLogWalk}
                    chooseNewHotspotInfoColor={this.chooseNewHotspotInfoColor}
                    newHostpotInfoColor={this.state.newHostpotInfoColor}
                    isShowDirectionDrawer={this.props.isShowDirectionDrawer}
                    onShowDirectionDrawer={this.props.onShowDirectionDrawer}
                    onHideDirectionDrawer={this.props.onHideDirectionDrawer}
                    isShowUnfinishedHotspotInfo={isShowUnfinishedHotspotInfo}
                    isShowAccomplishedHotspotInfo={
                      isShowAccomplishedHotspotInfo
                    }
                    isShowLink={isShowLink}
                    toggleShowLink={this.toggleShowLink}
                    toggleShowUnfinishedHotspotInfo={
                      this.toggleShowUnfinishedHotspotInfo
                    }
                    toggleShowAccomplishedHotspotInfo={
                      this.toggleShowAccomplishedHotspotInfo
                    }
                    showModeEditLink={showModeEditLink}
                    toggleShowModeEditLink={this.toggleShowModeEditLink}
                    listShootsSpot={listShootsSpot}
                    showWalkThrough={this.props.logWalkInfo?.walkThrough}
                    logwalkViewerHasLinkIcon={() => this.checkLogwalkViewerHasLinkIcon()}
                    openMenuScreenshotVRSpace={this.openMenuScreenshotVRSpace}
                    displayNone={this.state.screenshotVRBase64}
                    showShareUrlDialog={() => this.setIsShowShareUrlDialog(true)}
                    classification={this.state.classification}
                    isSharedUrl={this.props.logWalkInfo?.isSharedUrl}
                  />
                </Pc>
              </React.Fragment>
            )}

            {/*<div className="functions-viewer">*/}
            {!createHotspotMode ? (
              <React.Fragment>
                {showPanelAdjustImage && historyListAdjustPhoto.length ? (
                  <React.Fragment>
                    <div
                      className="task-adjust"
                      style={{ top: convertingPhoto ? 50 : 20 }}
                    >
                      <AdjustImage
                        {...this.state}
                        modeDevice={
                          window.innerWidth < 960
                            ? MODE_DEVICE.SP
                            : MODE_DEVICE.PC
                        }
                        onShowDialogViewer={this.handleShowDialogViewer}
                        classification={this.state.classification}
                        onOpenAdjustImage={this.onOpenAdjustImage}
                        viewerUnAdjustedImage={viewerUnAdjustedImage}
                        isShowDirectionDrawer={this.props.isShowDirectionDrawer}
                        changeDirection={changeDirection}
                        onGetUnAdjustPhoto={this.props.onGetUnAdjustPhoto}
                        logWalkInfo={logWalkInfo}
                        isLoadDone={
                          imageShootUnAdjusted.url || imageShootUnAdjusted.tile
                        }
                        blobDataLogWalk={blobDataLogWalk}
                        fetchShootLogWalk={url => {
                          this.setState(
                            { isRefreshSas: false },
                            fetchShootLogWalk(url)
                          );
                        }}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Sp>
                      <Draggable
                        handle=".image-title-viewer"
                        bounds="body"
                        position={this.state.positionMiniWindowViewer}
                        onStart={this.handleStart}
                        onStop={this.handleStop}
                        // {...dragHandlers}
                        onDrag={this.onControlledDrag.bind(this)}
                      >
                        <div
                          className="mini-window-parent"
                          style={{
                            top: convertingPhoto ? 50 : 20,
                            display: this.state.screenshotVRBase64 ? "none" : "block"
                          }}
                          ref={this.miniWindowViewerRef}
                        >
                          <div
                            id="mini-window"
                            className="mini-window"
                            ref={this.inputRef1}
                          >
                            {/* {this.checkShowHand() && (
                                <strong>
                                  <div className="hand-move"></div>
                                </strong>
                              )} */}
                            {progressLoadImage3D &&
                              !progressLoadImage3D.isShow && (
                                <MiniWindowViewer
                                  modeDevice={MODE_DEVICE.SP}
                                  onShowDialogViewer={
                                    this.handleShowDialogViewer
                                  }
                                  drawingBlob={this.props.drawingBlob}
                                  drawingPhoto={this.props.drawingPhoto}
                                  drawingsWithSpots={
                                    this.props.drawingsWithSpots
                                  }
                                  drawingCode={this.props.drawingCode}
                                  logWalkInfo={this.props.logWalkInfo}
                                  onSetDrawingPhoto={
                                    this.props.onSetDrawingPhoto
                                  }
                                  onShowPannelAdjust={
                                    this.handleShowPanelAdjust
                                  }
                                  showPanelAdjustImage={showPanelAdjustImage}
                                  onGetUnAdjustPhoto={
                                    this.props.onGetUnAdjustPhoto
                                  }
                                  listShootCurrentActive={
                                    this.props.listShootCurrentActive
                                  }
                                  fetchShootLogWalk={url => {
                                    this.setState(
                                      { isRefreshSas: false },
                                      fetchShootLogWalk(url)
                                    );
                                  }}
                                  onSetSpotCurrent={this.props.onSetSpotCurrent}
                                  spotCurrent={this.props.spotCurrent}
                                  onSetTypeSelectImage3D={
                                    this.props.onSetTypeSelectImage3D
                                  }
                                  historyListAdjustPhoto={
                                    this.props.historyListAdjustPhoto
                                  }
                                  onSetTypeDialog={this.props.onSetTypeDialog}
                                  classification={this.state.classification}
                                  imageShootUnAdjusted={
                                    this.props.imageShootUnAdjusted
                                  }
                                  viewerUnAdjustedImage={viewerUnAdjustedImage}
                                  onSetImageShootUnAdjusted={
                                    this.props.onSetImageShootUnAdjusted
                                  }
                                  onSetIsDefaultHistory={
                                    this.props.onSetIsDefaultHistory
                                  }
                                  onSetHistoryViewer={
                                    this.props.onSetHistoryViewer
                                  }
                                  historyList={this.props.historyList}
                                  onResetWidthHeightMiniWindow={
                                    this.onResetWidthHeightMiniWindow
                                  }
                                  onResetPositionMiniWindowViewer={
                                    this.onResetPositionMiniWindowViewer
                                  }
                                  isShowHand={this.checkShowHand}
                                  onResetFlagRedirectNoImage={
                                    this.onResetFlagRedirectNoImage
                                  }
                                  typeDialog={this.props.typeDialog}
                                  resetPreviousShootDirection={
                                    this.resetPreviousShootDirection
                                  }
                                />
                              )}
                          </div>
                        </div>
                      </Draggable>
                    </Sp>

                    <Pc>
                      <div
                        className="mini-window-parent"
                        style={{
                          top: convertingPhoto ? 50 : 20,
                          display: this.state.screenshotVRBase64 ? "none" : "block"
                        }}
                      >
                        <div
                          id="mini-window"
                          className="mini-window"
                          ref={this.inputRef1}
                        >
                          {progressLoadImage3D && !progressLoadImage3D.isShow && (
                            <MiniWindowViewer
                              modeDevice={MODE_DEVICE.PC}
                              onShowDialogViewer={this.handleShowDialogViewer}
                              drawingBlob={this.props.drawingBlob}
                              drawingPhoto={this.props.drawingPhoto}
                              drawingsWithSpots={this.props.drawingsWithSpots}
                              drawingCode={this.props.drawingCode}
                              logWalkInfo={this.props.logWalkInfo}
                              onSetDrawingPhoto={this.props.onSetDrawingPhoto}
                              onShowPannelAdjust={this.handleShowPanelAdjust}
                              showPanelAdjustImage={showPanelAdjustImage}
                              onGetUnAdjustPhoto={this.props.onGetUnAdjustPhoto}
                              isPreventMovingToLWViewer={
                                this.state.showModeEditLink
                              }
                              listShootCurrentActive={
                                this.props.listShootCurrentActive
                              }
                              fetchShootLogWalk={url => {
                                this.setState(
                                  { isRefreshSas: false },
                                  fetchShootLogWalk(url)
                                );
                              }}
                              onSetSpotCurrent={this.props.onSetSpotCurrent}
                              spotCurrent={this.props.spotCurrent}
                              onSetTypeSelectImage3D={
                                this.props.onSetTypeSelectImage3D
                              }
                              historyListAdjustPhoto={
                                this.props.historyListAdjustPhoto
                              }
                              onSetTypeDialog={this.props.onSetTypeDialog}
                              classification={this.state.classification}
                              imageShootUnAdjusted={
                                this.props.imageShootUnAdjusted
                              }
                              viewerUnAdjustedImage={viewerUnAdjustedImage}
                              onSetImageShootUnAdjusted={
                                this.props.onSetImageShootUnAdjusted
                              }
                              onSetIsDefaultHistory={
                                this.props.onSetIsDefaultHistory
                              }
                              onSetHistoryViewer={this.props.onSetHistoryViewer}
                              historyList={this.props.historyList}
                              onResetWidthHeightMiniWindow={
                                this.onResetWidthHeightMiniWindow
                              }
                              onResetPositionMiniWindowViewer={
                                this.onResetPositionMiniWindowViewer
                              }
                              onResetFlagRedirectNoImage={
                                this.onResetFlagRedirectNoImage
                              }
                              typeDialog={this.props.typeDialog}
                              resetPreviousShootDirection={
                                this.resetPreviousShootDirection
                              }
                            />
                          )}
                        </div>
                      </div>
                    </Pc>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : null}

            {!hasPhoto &&
              this.checkHasShootPhoto(
                this.props.logWalkInfo.recordsUC,
                false
              ) &&
              (isRedirectFromNoImage ||
                this.checkImageNotTransfer(this.props.logWalkInfo.recordsUC)) &&
              logWalkInfo.spotsUC &&
              !showPanelAdjustImage && (
                <Grid container className="no-image">
                  <Grid item xs={12}>
                    <p>撮影写真はまだ転送されておりません。</p>
                    <p>別の日付、地点を選択してください。</p>
                  </Grid>
                </Grid>
              )}
            {!hasPhoto &&
              isRedirectFromNoImage &&
              logWalkInfo.spotsUC &&
              Object.entries(imageShootUnAdjusted).length !== 0 &&
              !imageShootUnAdjusted.blob &&
              !blobDataLogWalk &&
              isOpenAdjust && (
                <Grid container className="no-image">
                  <Grid item xs={12}>
                    <p>撮影写真はまだ転送されておりません。</p>
                  </Grid>
                </Grid>
              )}
            {!hasPhoto &&
              !blobDataLogWalk &&
              isOpenAdjust &&
              Object.entries(imageShootUnAdjusted).length !== 0 &&
              !imageShootUnAdjusted.blob &&
              showPanelAdjustImage && (
                <Grid container className="no-image">
                  <Grid item xs={12}>
                    <p>撮影写真はまだ転送されておりません。</p>
                  </Grid>
                </Grid>
              )}

            {!hasPhoto &&
              !blobDataLogWalk &&
              typeSelectImage3D === TYPE_SELECT_IMAGE_3D.SPOT &&
              !this.checkHasShootPhoto(
                this.props.logWalkInfo.recordsUC,
                false
              ) &&
              !imageShootUnAdjusted.shootCode && (
                <Grid container className="no-image">
                  <Grid item xs={12}>
                    <p>日付ではこの地点の写真は存在しません</p>
                    <p>別の日付、地点を選択してください</p>
                  </Grid>
                </Grid>
              )}

            {!hasPhoto &&
              showPanelAdjustImage &&
              Object.entries(imageShootUnAdjusted).length === 0 &&
              !historyListAdjustPhoto.length && (
                <Grid container className="no-image">
                  <Grid item xs={12}>
                    <p>現在、未整理写真は存在しません。</p>
                  </Grid>
                </Grid>
              )}

            {!hasPhoto &&
              !blobDataLogWalk &&
              !showPanelAdjustImage &&
              typeSelectImage3D === TYPE_SELECT_IMAGE_3D.HISTORY &&
              !this.checkHasShootPhoto(
                this.props.logWalkInfo.recordsUC,
                false
              ) &&
              !imageShootUnAdjusted.shootCode && (
                <Grid container className="no-image">
                  <Grid item xs={12}>
                    {historyList.find(
                      item => item.uniqueCode === logWalkInfo.recordsUC
                    )?.dateUtc ? (
                      <p>
                        {`この地点では ${format(
                          new Date(
                            historyList.find(
                              item => item.uniqueCode === logWalkInfo.recordsUC
                            ).dateUtc
                          ),
                          "MM 月 dd 日 HH 時 mm 分"
                        )} の写真は存在しません`}
                      </p>
                    ) : (
                      <p>日付ではこの地点の写真は存在しません</p>
                    )}

                    <p>別の日付、地点を選択してください</p>
                  </Grid>
                </Grid>
              )}
            {progressLoadImage3D && progressLoadImage3D.isShow && (
                <Grid container className="no-image">
                <Grid item xs={12}>
                <div className="progress-bar">
                    <LinearProgress variant="determinate" value={progress} />
                  </div>
                </Grid>
              </Grid>
            )}

            {!hasPhoto &&
              !blobDataLogWalk &&
              !imageShootUnAdjusted.shootCode &&
              !logWalkInfo.spotsUC &&
              !isOpenAdjust &&
              typeSelectImage3D === TYPE_SELECT_IMAGE_3D.VIEWER && (
                <Grid container className="no-image">
                  <Grid item xs={12}>
                    <p>図面から地点を選択してください</p>
                  </Grid>
                </Grid>
              )}
            {showPanelAdjustImage ? (
              !this.state.screenshotVRBase64 &&
              (<IconButton
                onClick={this.backToDrawing}
                size="medium"
                className="back-btn-viewer"
                style={{ top: convertingPhoto ? 50 : 20 }}
              >
                <ExitToAppIcon fontSize="small" />
              </IconButton>)
            ) : (
              !this.state.screenshotVRBase64 &&
              (<IconButton
                onClick={this.onBack}
                size="medium"
                className="back-btn-viewer"
                    style={{ top: convertingPhoto ? 50 : 20 }}
                  >
                <CloseIcon fontSize="small" />
              </IconButton>)
                )}
            <Pc>
              <DialogViewer
                open={showDialogViewer}
                modeDevice={MODE_DEVICE.PC}
                onClose={this.handleShowDialogViewer}
                drawingPhoto={this.props.drawingPhoto}
                drawingsWithSpots={this.props.drawingsWithSpots}
                drawingCode={this.props.drawingCode}
                drawingBlob={this.props.drawingBlob}
                logWalkInfo={this.props.logWalkInfo}
                onSetDrawingPhoto={this.props.onSetDrawingPhoto}
                onShowPannelAdjust={this.handleShowPanelAdjust}
                showPanelAdjustImage={showPanelAdjustImage}
                onGetUnAdjustPhoto={this.props.onGetUnAdjustPhoto}
                listShootCurrentActive={this.props.listShootCurrentActive}
                fetchShootLogWalk={url => {
                  this.setState(
                    { isRefreshSas: false },
                    this.props.fetchShootLogWalk(url)
                  );
                }}
                onSetSpotCurrent={this.props.onSetSpotCurrent}
                spotCurrent={this.props.spotCurrent}
                onSetTypeSelectImage3D={this.props.onSetTypeSelectImage3D}
                historyListAdjustPhoto={this.props.historyListAdjustPhoto}
                typeDialog={this.props.typeDialog}
                onSetTypeDialog={this.props.onSetTypeDialog}
                shootsUCNotAdjusted={this.props.shootsUCNotAdjusted}
                addImageNotAdjusted={this.props.addImageNotAdjusted}
                listShootsSpot={this.props.listShootsSpot}
                listAdjustPhotoSelected={this.props.listAdjustPhotoSelected}
                classification={this.state.classification}
                imageShootUnAdjusted={this.props.imageShootUnAdjusted}
                onSetImageShootUnAdjusted={this.props.onSetImageShootUnAdjusted}
                onRedirectNotfoundPage={this.onRedirectNotfoundPage}
                onResetWidthHeightMiniWindow={this.onResetWidthHeightMiniWindow}
                onSetProgressBar={this.onSetProgressBar}
                onSetHistoryViewer={this.props.onSetHistoryViewer}
                historyList={this.props.historyList}
                onResetPositionMiniWindowViewer={
                  this.onResetPositionMiniWindowViewer
                }
                redirectNoImage={this.redirectNoImage}
                onResetFlagRedirectNoImage={this.onResetFlagRedirectNoImage}
                onOpenAdjustImage={this.onOpenAdjustImage}
                backToDrawing={this.backToDrawing}
                handleChangeHistory={this.handleChangeHistory}
                resetPreviousShootDirection={this.resetPreviousShootDirection}
                isPreventMovingToLWViewer={this.state.showModeEditLink}
              />
            </Pc>
            <Sp>
              <DialogViewer
                open={showDialogViewer}
                modeDevice={MODE_DEVICE.SP}
                onClose={this.handleShowDialogViewer}
                drawingPhoto={this.props.drawingPhoto}
                drawingsWithSpots={this.props.drawingsWithSpots}
                drawingCode={this.props.drawingCode}
                drawingBlob={this.props.drawingBlob}
                logWalkInfo={this.props.logWalkInfo}
                onSetDrawingPhoto={this.props.onSetDrawingPhoto}
                onShowPannelAdjust={this.handleShowPanelAdjust}
                showPanelAdjustImage={showPanelAdjustImage}
                onGetUnAdjustPhoto={this.props.onGetUnAdjustPhoto}
                listShootCurrentActive={this.props.listShootCurrentActive}
                fetchShootLogWalk={url => {
                  this.setState(
                    { isRefreshSas: false },
                    this.props.fetchShootLogWalk(url)
                  );
                }}
                onSetSpotCurrent={this.props.onSetSpotCurrent}
                spotCurrent={this.props.spotCurrent}
                onSetTypeSelectImage3D={this.props.onSetTypeSelectImage3D}
                historyListAdjustPhoto={this.props.historyListAdjustPhoto}
                typeDialog={this.props.typeDialog}
                onSetTypeDialog={this.props.onSetTypeDialog}
                shootsUCNotAdjusted={this.props.shootsUCNotAdjusted}
                addImageNotAdjusted={this.props.addImageNotAdjusted}
                listShootsSpot={this.props.listShootsSpot}
                listAdjustPhotoSelected={this.props.listAdjustPhotoSelected}
                classification={this.state.classification}
                imageShootUnAdjusted={this.props.imageShootUnAdjusted}
                onSetImageShootUnAdjusted={this.props.onSetImageShootUnAdjusted}
                onRedirectNotfoundPage={this.onRedirectNotfoundPage}
                onResetWidthHeightMiniWindow={this.onResetWidthHeightMiniWindow}
                onSetProgressBar={this.onSetProgressBar}
                onSetHistoryViewer={this.props.onSetHistoryViewer}
                historyList={this.props.historyList}
                onResetPositionMiniWindowViewer={
                  this.onResetPositionMiniWindowViewer
                }
                redirectNoImage={this.redirectNoImage}
                onResetFlagRedirectNoImage={this.onResetFlagRedirectNoImage}
                onOpenAdjustImage={this.onOpenAdjustImage}
                backToDrawing={this.backToDrawing}
                handleChangeHistory={this.handleChangeHistory}
                resetPreviousShootDirection={this.resetPreviousShootDirection}
                isPreventMovingToLWViewer={this.state.showModeEditLink}
              />
            </Sp>

            {(this.props.isCreateHotspotInfo ||
              this.props.currentHotspotInfoId) &&
            !this.state.showModeEditLink ? (
              <HotspotInfoDrawer
                newHostpotInfoColor={this.state.newHostpotInfoColor}
                positionNewHotspotInfo={this.state.positionNewHotspotInfo}
                drawerCancelCreateHotspot={this.cancelCreateHotspotInfo}
                onResetFlagRedirectNoImage={this.onResetFlagRedirectNoImage}
                changeNewHostpotInfoColor={this.changeNewHostpotInfoColor}
                rotateToHotspotPosition={this.rotateToHotspotPosition}
                backToDrawing={this.backToDrawing}
                logWalkInfoImage={!!this.props.blobDataLogWalk}
                displayNone={this.state.screenshotVRBase64}
              />
            ) : null}

            {createHotspotMode && (
              <CreateInfoMouseIcon
                mousePosition={mousePosition}
                color={newHostpotInfoColor.color}
              />
            )}

            {this.props.blobDataLogWalk && this.state.screenshotVRBase64 && (
              <MenuScreenshotVRSpace
                viewer={this.viewer}
                closeMenu={this.closeMenuScreenshotVRSpace}
                screenshotVRBase64={this.state.screenshotVRBase64}
                takeScreenshotVRAndDownoad={this.takeScreenshotVRAndDownoad}
                takeScreenshotVRAndShare={this.takeScreenshotVRAndShare}
                takeScreenshotVRAndUploadToLogBuild={
                  this.takeScreenshotVRAndUploadToLogBuild
                }
              />
            )}

{
              this.state.isShowShareUrlDialog && (
                <ShareUrlDialog
                  open={this.state.isShowShareUrlDialog}
                  handleClose={() => this.setIsShowShareUrlDialog(false)}
                  shootsUC={this.props.match.params.id}
                  currentSpotName={this.props.logWalkInfo.spotName}
                  currentSiteName={this.props.logWalkInfo.siteName}
                />                
              )
            }



            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              open={this.state.showSnackbarUploadScreenshotVR}
              autoHideDuration={3000}
              onClose={() => this.setShowSnackbarUploadScreenshotVR(false)}
              onExited={() => this.setShowSnackbarUploadScreenshotVR(false)}
              message="保存しました"
              action={
                <React.Fragment>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    style={{ padding: "4px" }}
                    onClick={() => this.setShowSnackbarUploadScreenshotVR(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </React.Fragment>
              }
            />
          </div>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  type: state.rootSitesReducer.logWalkViewReducer.type,
  auth: state.auth,
  drawingPhoto: getDrawingsPhoto(
    state.rootSitesReducer.logWalkViewReducer.drawingPhoto
  ),
  drawingsWithSpots: getDrawingsList(
    state.rootSitesReducer.logWalkViewReducer.drawingsList
  ),
  // urlShoot: getURLShoot(
  //   state.rootSitesReducer.logWalkViewReducer.urlImageShoot
  // ),
  // urlShootTile: getURLShootTile(
  //   state.rootSitesReducer.logWalkViewReducer.urlImageShootTile
  // ),
  blobDataLogWalk: getBlobDataLogWalk(
    state.rootSitesReducer.logWalkViewReducer.blobDataLogWalk
  ),
  drawingCode: getDrawingCode(
    state.rootSitesReducer.logWalkViewReducer.drawingCode
  ),
  drawingBlob: getDrawingBlob(
    state.rootSitesReducer.logWalkViewReducer.drawingBlob
  ),
  logWalkInfo: getLogWalkInfo(
    state.rootSitesReducer.logWalkViewReducer.logWalkInfo
  ),
  historyList: getHistoryList(
    state.rootSitesReducer.logWalkViewReducer.historyList
  ),
  categoryList: getCategoryList(
    state.rootSitesReducer.logWalkViewReducer.categoryList
  ),
  listShootsSpot: getListShootsSpot(
    state.rootSitesReducer.logWalkViewReducer.listShootsSpot
  ),
  listShootCurrentActive: getListShootsCurrentActive(
    state.rootSitesReducer.logWalkViewReducer.listShootCurrentActive
  ),
  spotCurrent: getSpotCurrent(
    state.rootSitesReducer.logWalkViewReducer.spotCurrent
  ),
  typeSelectImage3D: getTypeSelectImage3D(
    state.rootSitesReducer.logWalkViewReducer.typeSelectImage3D
  ),
  historyListAdjustPhoto: getHistoryListAdjustPhoto(
    state.rootSitesReducer.logWalkViewReducer.historyListAdjustPhoto
  ),
  typeDialog: getTypeDialog(
    state.rootSitesReducer.logWalkViewReducer.typeDialog
  ),
  shootsUCNotAdjusted: getShootsUCNotAdjusted(
    state.rootSitesReducer.logWalkViewReducer.shootsUCNotAdjusted
  ),
  listAdjustPhotoSelected: getListAdjustPhotoSelected(
    state.rootSitesReducer.logWalkViewReducer.listAdjustPhotoSelected
  ),
  imageShootUnAdjusted: getUrlImageShootsUCNotAdjusted(
    state.rootSitesReducer.logWalkViewReducer.imageShootUnAdjusted
  ),
  redirectFromUrl: getRedirectFromUrl(
    state.rootSitesReducer.siteInfoReducer.redirectFromUrl
  ),
  isDefaultHistory: getIsDefaultHistory(
    state.rootSitesReducer.logWalkViewReducer.isDefaultHistory
  ),
  progressLoadImage3D: getProgressLoadImage3D(
    state.rootSitesReducer.logWalkViewReducer.progressLoadImage3D
  ),
  listShootCurrentActiveLatest: getListShootCurrentActiveLatest(
    state.rootSitesReducer.logWalkViewReducer.listShootCurrentActiveLatest
  ),
  listSpotsCurrent: getListSpotsCurrent(
    state.rootSitesReducer.logWalkViewReducer.listSpotsCurrent
  ),
  currentHotspotInfoId: getCurrentHotspotInfoId(
    state.rootSitesReducer.logWalkViewReducer.currentHotspotInfoId
  ),
  isCreateHotspotInfo: getIsCreateHotspotInfo(
    state.rootSitesReducer.logWalkViewReducer.isCreateHotspotInfo
  ),
  isOpenHotspotInfo: getIsOpenHotspotInfo(
    state.rootSitesReducer.logWalkViewReducer.isOpenHotspotInfo
  ),
  isShowDirectionDrawer: getIsShowDirectionDrawer(
    state.rootSitesReducer.logWalkViewReducer.isShowDirectionDrawer
  )
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    onFetchPhoto: drawingCode => dispatch(fetchDrawingFile(drawingCode)),
    onInitShoot: (
      shootUC,
      classification,
      redirectNotfound,
      onSetProgressBar,
      redirectNoImage,
      hotSpotData,
      redirectTo
    ) =>
      dispatch(
        fetchShootInfo(
          shootUC,
          classification,
          redirectNotfound,
          onSetProgressBar,
          redirectNoImage,
          null,
          null,
          null,
          hotSpotData,
          redirectTo
        )
      ),
    onSetDrawingPhoto: data => dispatch(setDrawingPhoto(data)),
    onGetUnAdjustPhoto: (siteUc, classification) =>
      dispatch(getUnAdjustedPhoto(siteUc, classification)),
    onSetHistoryViewer: data => dispatch(setHistoryViewer(data)),
    fetchShootLogWalk: (data, isRefresh) =>
      dispatch(fetchShootLogWalk(data, isRefresh)),
    onSetSpotCurrent: data => dispatch(setSpotCurrent(data)),
    onSetTypeSelectImage3D: data => dispatch(setTypeSelectImage3D(data)),
    onSetTypeDialog: data => dispatch(setTypeDialog(data)),
    addImageNotAdjusted: data => dispatch(addImageNotAdjustedApi(data)),
    onShareShootsUC: (data, classification) =>
      dispatch(shareShootsUC(data, classification)),
    fetchInfoLogwalkView: (infoData, classification, isNoImage) =>
      dispatch(fetchInfoLogwalkView(infoData, classification, isNoImage)),
    onSetImageShootUnAdjusted: data => dispatch(setImageShootUnAdjusted(data)),
    onSetIsDefaultHistory: data => dispatch(setIsDefaultHistory(data)),
    resetStore: () => dispatch(resetStore()),
    onOpenHotspotInfo: currentHotspotInfoId =>
      dispatch(openHotspotInfo(currentHotspotInfoId)),
    onCreateHotspotInfo: () => dispatch(createHotspotInfo()),
    onCloseHotspotInfo: () => dispatch(closeHotspotInfo()),
    onCreateHotspotInfoSuccess: data =>
      dispatch(createHotspotInfoSuccess(data)),
    onCancelCreateHotspotInfo: () => dispatch(cancelCreateHotspotInfo()),
    onShowDirectionDrawer: () => dispatch(showDirectionDrawer()),
    onHideDirectionDrawer: () => dispatch(hideDirectionDrawer()),
    loginCometChatSuccess: data => dispatch(loginCometChatSuccess(data)),
    logoutCometChatSuccess: () => dispatch(logoutCometChatSuccess()),
    postScreenshotVRPhoto: (
      formData,
      classification,
      postScreenshotVRPhotoSuccess
    ) =>
      dispatch(
        postScreenshotVRPhoto(
          formData,
          classification,
          postScreenshotVRPhotoSuccess
        )
      ),
    onEditLinkPositionOnShoot: (
      shootsUC,
      classification,
      data,
      cancelModeEditLink
    ) =>
      dispatch(
        editLinkPositionOnShoot(
          shootsUC,
          classification,
          data,
          cancelModeEditLink
        )
      )
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(LogWalkViewer);
