import React, { useEffect, useState, useRef, useCallback } from "react";
import { api } from "../../common/api/api";
import { enableLoading, finishLoading } from "common/Stores/service/service";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles, Button } from "@material-ui/core";
import LogMeetHistoryAll from "./LogMeetHistoryAll";
import LogMeetHistoryImage from "./LogMeetHistoryImage";
import LogMeetHistoryDetail from "./LogMeetHistoryDetail";
import LogMeetHistoryAllSP from "./LogMeetHistoryAllSP";
import LogMeetHistoryImageSP from "./LogMeetHistoryImageSP";
import LogMeetHistoryDetailSP from "./LogMeetHistoryDetailSP";
import Pc from "../../common/breakpoints/Pc";
import Sp from "../../common/breakpoints/Sp";
const mediaTiniMobile = "(max-width: 430px)";
const useStyles = makeStyles({
  buttonWrapper: {
    width: "144px",
    height: "36px",
    borderRadius: "8px",
    display: "flex",
    position: "relative",
    backgroundColor: "#ECEDED",
    fontFamily: "Noto Sans JP",
    marginRight: "14px",
    fontSize: "14px",
    fontWeight: "normal"
  },
  buttonWrapperTiniSP: {
    width: "144px",
    height: "36px",
    borderRadius: "8px",
    display: "flex",
    position: "relative",
    backgroundColor: "#ECEDED",
    fontFamily: "Noto Sans JP",
    marginRight: "14px",
    fontSize: "14px",
    fontWeight: "normal"
  },
  button: {
    width: "50%",
    height: "100%",
    zIndex: 2,
    color: "white",
    transition: "0.3s"
  },
  bg: {
    borderRadius: "8px",
    backgroundColor: "#162F50",
    position: "absolute",
    height: "100%",
    width: "50%",
    top: "0",
    left: "0",
    transition: "left 0.3s"
  }
});

function LogMeet(props) {
  const historyImagesRef = useRef();
  const historyAllRef = useRef();
  const historyImagesRefSP = useRef();
  const historyAllRefSP = useRef();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [historyDetail, setHistoryDetail] = useState(null);
  const [allHistories, setAllHistories] = useState([]);
  const [sites, setSites] = useState([]);
  const [tabState, setTabState] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [currentUserUniqueCode, setCurrentUserUniqueCode] = useState(null);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);

  const showLogMeetHistory = useSelector(
    state => state.storesReducer.showLogMeetHistory
  );

  const toggleTabState = () => {
    setTabState(!tabState);
  }

  const toggleHistoryDetailModal = value => {
    setHistoryDetail(value);
  }

  useEffect(() => {
    toggleHistoryDetailModal(null);
    if (loaded && tabState) {
      historyAllRef.current && historyAllRef.current.reloadFirstFromOutside();
      historyAllRefSP.current && historyAllRefSP.current.reloadFirstFromOutside();
    }
    if (loaded && (!tabState)) {
      historyImagesRef.current && historyImagesRef.current.reloadFirstFromOutside();
      historyImagesRefSP.current && historyImagesRefSP.current.reloadFirstFromOutside();
    }
  }, [showLogMeetHistory])

  useEffect(() => {
    fetchSites();
  }, []);

  useEffect(() => {
    if (!historyDetail && currentItemIndex) {
      //reload and scroll to current item
      if (!tabState) {
        // historyImagesRef.current.reloadFromOutside();
      }
      else {
        // historyAllRef.current.reloadFromOutside();

      }
    }
  }, [historyDetail]);

  const fetchSites = () => {
    setHistoryDetail(null);
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/sites`)
      .then(res => {
        setSites(res.data.sites);
        dispatch(finishLoading());
        setLoaded(true);
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  };

  return (
    <>
      <Pc>
        <Grid container className="site-logmeet-tab-container"
          style={{
            display: historyDetail ? "none" : "flex",
            padding: "0 20px"
          }}
        >
          <Grid item xs={12} style={{ display: "flex" }}>
            <span style={{
              fontFamily: "M PLUS 1",
              fontWeight: "normal",
              fontSize: "20px",
              marginRight: "48px"
            }}>自分の通話履歴</span>

            <div className={classes.buttonWrapper}>
              <button
                className={classes.button}
                onClick={() => toggleTabState()}
                style={{
                  color: tabState ? "white" : "#808080"
                }}
              >
                すべて
              </button>
              <button
                className={classes.button}
                onClick={() => toggleTabState()}
                style={{
                  color: !tabState ? "white" : "#808080"
                }}
              >
                写真
              </button>
              <div
                className={classes.bg}
                style={{ left: tabState ? "0" : "50%" }}
              ></div>
            </div>


          </Grid>
          <Grid item xs={12} style={{ display: "flex", marginTop: "18px" }}>

            {tabState ?
              (
                <LogMeetHistoryAll
                  ref={historyAllRef}
                  toggleHistoryDetailModal={toggleHistoryDetailModal}
                  setCurrentItemIndex={setCurrentItemIndex}
                />
              )
              :
              (
                <LogMeetHistoryImage
                  ref={historyImagesRef}
                  toggleHistoryDetailModal={toggleHistoryDetailModal}
                  setCurrentItemIndex={setCurrentItemIndex}
                />
              )
            }
          </Grid>
        </Grid>
        {historyDetail && (
          <LogMeetHistoryDetail
            // sitesUC={sitesUC}
            sites={sites}
            historyDetail={historyDetail}
            toggleHistoryDetailModal={toggleHistoryDetailModal}
          />
        )}
      </Pc>
      <Sp>
        <Grid container className="site-logmeet-tab-container"
          style={{
            display: historyDetail ? "none" : "flex",
            padding: "0 20px"
          }}
        >
          <Grid item xs={12} style={{ display: "flex" }}>
            <span style={{
              fontFamily: "M PLUS 1",
              fontWeight: "normal",
              fontSize: "21px",
              marginRight: "20px",
              color: "#162F50"
            }}>現場の通話履歴</span>

            <div className={classes.buttonWrapper}>
              <button
                className={classes.button}
                onClick={() => toggleTabState()}
                style={{
                  color: tabState ? "white" : "#808080"
                }}
              >
                すべて
              </button>
              <button
                className={classes.button}
                onClick={() => toggleTabState()}
                style={{
                  color: !tabState ? "white" : "#808080"
                }}
              >
                写真
              </button>
              <div
                className={classes.bg}
                style={{ left: tabState ? "0" : "50%" }}
              ></div>
            </div>


          </Grid>
          <Grid item xs={12} style={{ display: "flex", marginTop: "18px" }}>

            {tabState ?
              (
                <LogMeetHistoryAllSP
                  ref={historyAllRefSP}
                  toggleHistoryDetailModal={toggleHistoryDetailModal}
                  setCurrentItemIndex={setCurrentItemIndex}
                />
              )
              :
              (
                <LogMeetHistoryImageSP
                  ref={historyImagesRefSP}
                  toggleHistoryDetailModal={toggleHistoryDetailModal}
                  setCurrentItemIndex={setCurrentItemIndex}
                />
              )
            }
          </Grid>
        </Grid>
        {historyDetail && (
          <LogMeetHistoryDetailSP
            // sitesUC={sitesUC}
            sites={sites}
            historyDetail={historyDetail}
            toggleHistoryDetailModal={toggleHistoryDetailModal}
          />
        )}
      </Sp>
    </>
  );
}

export default LogMeet;