import React, { useRef, useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DrawingPhoto from "./DrawingPhoto";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useLocation } from "react-router";
import nextImg from "../../../../../common/img/next_img.svg";
import closeImg from "../../../../../common/img/close.png";

const useStyles = makeStyles({
  transformWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    position: "relative",
    backgroundColor: "black"
  },
  drawingPhoto: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    margin: "auto",
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    overflow: "hidden",
    display: "inline-block",
    backgroundColor: "black"
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: "#d6d6d6"
    }
  }
});

function DrawingBox(props) {
  const { drawingsWithSpots, selectedSpots, selectSpot, handleClose } = props;
  const [currentDrawingIndex, setCurrentDrawingIndex] = useState(0);
  const [spotPositions, setSpotPositions] = useState([]);
  const location = useLocation();
  const classes = useStyles();
  const transformComponentRef = useRef(null);
  
  useEffect(() => {
    window.addEventListener("resize", () => {
      calculateSpotPositions();
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);
  const nextDrawing = () => {
    if (currentDrawingIndex < drawingsWithSpots.length - 1) {
      setCurrentDrawingIndex(currentDrawingIndex + 1);
      setTimeout(returnDefaultSize, 100);
    }
  };

  const previousDrawing = () => {
    if (currentDrawingIndex > 0) {
      setCurrentDrawingIndex(currentDrawingIndex - 1);
      setTimeout(returnDefaultSize, 100);
    }
  };

  const calculateSpotPositions = () => {
    let element = document.getElementById("drawing-box-image");

    let naturalWidth = element ? element.naturalWidth : 0;

    let naturalHeight = element ? element.naturalHeight : 0;

    const elMap = document.getElementById("drawing-box-wrapper");
    let scaleMap = elMap ? elMap.offsetWidth / elMap.offsetHeight : 0;
    let isVerticalImg = naturalHeight * scaleMap > naturalWidth;

    let offsetWidth = 0;
    let offsetHeight = 0;

    if (isVerticalImg) {
      offsetWidth = elMap
        ? (elMap.offsetHeight * naturalWidth) / naturalHeight
        : 0;

      offsetHeight = elMap ? elMap.offsetHeight : 0;
    } else {
      offsetWidth = elMap ? elMap.offsetWidth : 0;

      offsetHeight = elMap
        ? (elMap.offsetWidth * naturalHeight) / naturalWidth
        : 0;
    }

    let offsetLeft = elMap ? (elMap.offsetWidth - offsetWidth) / 2 : 0;

    let offsetTop = elMap ? (elMap.offsetHeight - offsetHeight) / 2 : 0;

    let positions = [];

    drawingsWithSpots[currentDrawingIndex].spots.map(pos => {
      positions.push({
        ...pos,
        posX: (pos.x * offsetWidth) / naturalWidth + offsetLeft,
        posY: (pos.y * offsetHeight) / naturalHeight + offsetTop
      });
    });

    setSpotPositions(positions);
  };

  const returnDefaultSize = () => {
    let currentRef = transformComponentRef.current;
    if (currentRef != null) {
      currentRef.context.dispatch.resetTransform();
    }
  };

  return (
    <div
      style={{ width: "100%", height: "100%", border: "1px solid black" }}
      id="drawing-box-wrapper"
    >
      <TransformWrapper defaultScale={1}>
        <div className={classes.transformWrapper}>
          <TransformComponent ref={transformComponentRef}>
            <div className="img-mini-parent-sp">
              <DrawingPhoto
                id="drawing-box-image"
                name={drawingsWithSpots[currentDrawingIndex]?.name}
                drawingsUC={drawingsWithSpots[currentDrawingIndex]?.uniqueCode}
                blob={drawingsWithSpots[currentDrawingIndex]?.blob}
                isBuilder={!location.pathname.includes("/sp/")}
                calculateSpotPositions={calculateSpotPositions}
                positions={spotPositions}
                selectSpot={selectSpot}
                selectedSpots={selectedSpots}
                class={classes.drawingPhoto}
              />
            </div>
          </TransformComponent>
          <div
            style={{
              width: "100%",
              height: "56px",
              backgroundColor: "rgba(0,0,0,0.4)",
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              padding: "8xp 15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <span
              style={{
                fontSize: "20px",
                lineHeight: "26px",
                fontWeight: "600",
                textShadow: "0px 3px 6px #00000029",
                color: "#FFFFFF",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            >
              {drawingsWithSpots[currentDrawingIndex]?.name}
            </span>
            {handleClose && (
              <img
                src={closeImg}
                alt="previous"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  width: "30px",
                  height: "30px"
                }}
                onClick={handleClose}
              />
            )}
          </div>
          <div
            style={{
              width: "48px",
              height: "78px",
              backgroundColor: "rgba(0,0,0,0.4)",
              position: "absolute",
              top: "50%",
              transform: "translate(0px, -50%)",
              left: "0px",
              padding: "8xp 15px",
              cursor: "pointer"
            }}
            onClick={previousDrawing}
          >
            <img
              src={nextImg}
              alt="previous"
              style={{
                transform: "rotateY(180deg)",
                width: "100%",
                height: "100%"
              }}
            />
          </div>
          <div
            style={{
              width: "48px",
              height: "78px",
              backgroundColor: "rgba(0,0,0,0.4)",
              position: "absolute",
              top: "50%",
              transform: "translate(0px, -50%)",
              right: "0px",
              padding: "8xp 15px",
              cursor: "pointer"
            }}
            onClick={nextDrawing}
          >
            <img
              src={nextImg}
              alt="next"
              style={{
                width: "100%",
                height: "100%"
              }}
            />
          </div>
        </div>
      </TransformWrapper>
    </div>
  );
}

export default DrawingBox;
