import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import InputControl from "../../common/InputControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Formik, Field } from "formik";
import Validator from "validator";
import Checkboxes from "../loginCheckbox.js";
import "../../common/css/base.css";
import "../../common/css/auth.css";
import { translateMessage } from "utils/mapper";

class LoginBuilderSP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.email,
      password: props.password,
      errors: props.errors,
      isPasswordShow: props.isPasswordShow,
      isAutoLoginChecked: props.isAutoLoginChecked
    };
    this.loginUtilityRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    //displaying errors
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = (e, formik) => {
    e.preventDefault();
    this.setState({ [e.target.id]: e.target.value });
    formik.handleChange(e);
    this.props.onChange(e.target.name, e.target.value);
  };

  isAutoLoginChecked() {
    const loginUtility = this.loginUtilityRef.current;
    return loginUtility.state.checked;
  }

  onSubmit = values => {
    const autoLogin = this.isAutoLoginChecked();
    this.props.onSubmit(values.email, values.password, autoLogin);
  };

  isEmptyEmail(email) {
    return Validator.isEmpty(email);
  }

  isEmailFormat(email) {
    return Validator.isEmail(email);
  }

  validateForm(email, password) {
    const key = "email";
    const { message } = this.props;
    const key_password = "password";
    const errors = this.state.errors;
    if (this.isEmptyEmail(email))
      errors[key] = translateMessage(
        this.getMessage("required", message),
        this.props.regexObject
      );
    else if (!this.isEmailFormat(email))
      errors[key] = translateMessage(
        this.getMessage("email", message),
        this.props.regexObject
      );
    else delete errors[key];
    if (this.isEmptyEmail(password))
      errors[key_password] = translateMessage(
        this.getMessage("required", message),
        this.props.regexObject
      );
    this.setState({ errors: errors });
    return Object.keys(errors).length === 0;
  }

  getMessage = (key, message) => {
    return message[key];
  };

  checktButtonClick = formik => {
    const { values } = formik;
    if (!this.validateForm(values.email, values.password)) return;
    formik.handleSubmit();
  };

  buttonSubmitClick = formik => e => {
    e.preventDefault();
    this.setState({ errors: {} }, () => this.checktButtonClick(formik));
  };

  render() {
    const { errors, email, password, isPasswordShow, isAutoLoginChecked } =
      this.state;
    const values = {
      email: email,
      password: password
    };

    const showPassword = () => {
      const isPasswordShow = !this.state.isPasswordShow;
      this.setState({
        isPasswordShow
      });
      this.props.onChange("isPasswordShow", isPasswordShow);
    };

    return (
      <Formik
        initialValues={values}
        enableReinitialize
        onSubmit={values => this.onSubmit(values)}
        render={formik => (
          <form onSubmit={this.buttonSubmitClick(formik)}>
            <Grid className="area-login-wrap">
              <Grid container justify="center">
                <Grid className="area-logo-sp" />
                <Grid className="area-login-sp">
                  <Grid>
                    <Typography
                      align="center"
                      variant="h4"
                      className="title-name mb-10"
                    >
                      社員でログイン
                    </Typography>
                  </Grid>
                  <Grid>
                    <Grid>
                      <Field
                        label="メールアドレス"
                        name="email"
                        type="email"
                        onChange={e => this.onChange(e, formik)}
                        variant="filled"
                        className="input-login input-login-sp"
                        component={InputControl}
                      />
                      <span className="red-text">
                        {errors.email}
                        {errors.emailnotfound}
                      </span>
                    </Grid>

                    <Grid className="relative">
                      <Field
                        label="パスワード"
                        name="password"
                        type={isPasswordShow ? "text" : "password"}
                        onChange={e => this.onChange(e, formik)}
                        variant="filled"
                        className={classnames(
                          "input-login input-login-sp pdr-50",
                          {
                            invalid: errors.password || errors.passwordincorrect
                          }
                        )}
                        component={InputControl}
                        autoComplete="new-password"
                      />
                      <IconButton
                        color="primary"
                        aria-label="copy"
                        className="show-password"
                        onClick={showPassword}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <span className="red-text">
                        {errors.password}
                        {errors.passwordincorrect}
                      </span>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      className="mt-25"
                    >
                      <Checkboxes
                        ref={this.loginUtilityRef}
                        isAutoLoginChecked={isAutoLoginChecked}
                        onChange={isAutoLoginChecked =>
                          this.props.onChange(
                            "isAutoLoginChecked",
                            isAutoLoginChecked
                          )
                        }
                      />
                    </Grid>
                    <Grid container justify="center">
                      <button
                        type="submit"
                        className="btn-login"
                        onClick={this.buttonSubmitClick(formik)}
                        style={{marginBottom: "25px"}}
                      >
                        ログイン
                      </button>
                      <span className="red-text">{errors.error}</span>
                    </Grid>
                    <Grid container justify="center" className="mt-10">
                      <Link to="sp/login" className="txt-link-login">
                        職人の方はこちら
                      </Link>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      className="mt-10"
                    >
                      <Link
                        to="/forgot-password"
                        className="txt-forget-passowrd"
                      >
                        パスワードを忘れましたか？
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}

LoginBuilderSP.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});
export default connect(mapStateToProps, {})(withRouter(LoginBuilderSP));
