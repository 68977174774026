import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import { bindActionCreators } from "redux";
import { dispatchError } from "../../../common/Stores/service/service";
import { translateValidate } from "../../../common/Stores/service/service";
import { Formik, Field } from "formik";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import PDFJS from "pdfjs-dist/webpack";
import { PDFtoIMG } from "react-pdf-to-image";
import { postPhoto, postDrawing } from "./service/service";
import { getDrawingsWithSpots } from "./reducer/reducer";
import { translateMessage, trimObjValues, getTextLength } from "utils/mapper";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  dispatchError as fillPhotoError,
  finishLoading
} from "../../../common/Stores/service/service";
import Checkbox from "@material-ui/core/Checkbox";
import { index } from "actions/siteActions";
import Pc from "../../../common/breakpoints/Pc";
import Sp from "../../../common/breakpoints/Sp";
import EditIcon from "@material-ui/icons/Edit";
import ErrorDialog from "components/ErrorDialog";
import CommonDialog from "../../CommonDialog";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";
import base64url from "base64url";
import { isTablet } from "react-device-detect";
const maxNameLength = 16;
const maximumDrawing = 50;

class PdfPreview extends Component {
  fieldListEditName = [
    {
      name: "name",
      label: "図面名",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true,
      maxLength: maxNameLength
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      pages: 0,
      currentPage: 0,
      contentPages: null,
      selectedPage: 0,
      checked: [],
      src: null,
      crop: {},
      cropData: [],
      cropTemp: null,
      rotateImage: null,
      isAddCrop: false,
      countRotate: 1,
      errorMessage: null,
      errorFileName: [],
      isShowDialogError: false,
      indexDrawings: [],
      isChanged: false,
      isShowDialogEditName: false,
      drawingsName: [],
      clientError: null,
      indexChecked: null,
      isEditName: true,
      isVerticalImg: false,
      disableRotate: false,
      isRotate: false,
      changeWithRotate: false
    };
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
    this.getScale();
  };

  addCropData = (crop, scaleX, scaleY) => {
    const cropData = this.state.cropData;
    const idx = cropData
      ? cropData.findIndex(item => item.selected === this.state.selectedPage)
      : null;

    if (idx !== -1) {
      cropData.splice(idx, 1);
    }

    cropData.push({
      selected: this.state.selectedPage,
      x: crop.x,
      y: crop.y,
      width: crop.width,
      height: crop.height,
      scaleX: scaleX,
      scaleY: scaleY
    });

    this.setState({ cropData: cropData });
  };

  checkCropOutsideImage = (cropData, offsetNew) => {
    if (
      cropData.offsetImage.offsetLeft + cropData.x >= offsetNew.offsetLeft &&
      cropData.offsetImage.offsetLeft + cropData.x <=
        offsetNew.offsetLeft + offsetNew.offsetWidth &&
      cropData.offsetImage.offsetTop + cropData.y >= offsetNew.offsetTop &&
      cropData.offsetImage.offsetTop + cropData.y <=
        offsetNew.offsetTop + offsetNew.offsetHeight &&
      cropData.offsetImage.offsetLeft + cropData.x + cropData.width <=
        offsetNew.offsetWidth + offsetNew.offsetLeft &&
      cropData.offsetImage.offsetTop + cropData.y + cropData.height <=
        offsetNew.offsetHeight + offsetNew.offsetTop
    ) {
      return false;
    } else {
      return true;
    }
  };

  onCropComplete = crop => {
    const {
      selectedPage,
      indexDrawings,
      countRotate,
      isRotate,
      changeWithRotate
    } = this.state;

    if (
      indexDrawings[selectedPage][0].toString().split("-").length < 3 &&
      crop &&
      crop.width > 0 &&
      crop.height > 0
    ) {
      this.setState({ isAddCrop: true });
    } else {
      this.setState({ isAddCrop: false });
    }

    setTimeout(() => {
      const element = document.getElementsByClassName("ReactCrop__image")[0];
      const scaleX = element.naturalWidth / element.width;
      const scaleY = element.naturalHeight / element.height;
      if (this.state.isChanged && this.state.cropTemp !== null) {
        const elPrimary = document.getElementsByClassName(
          "primary-image-block"
        )[0];

        const isOutside = this.checkCropOutsideImage(this.state.cropTemp, {
          offsetTop: elPrimary.offsetTop,
          offsetLeft: elPrimary.offsetLeft,
          offsetWidth: elPrimary.offsetWidth,
          offsetHeight: elPrimary.offsetHeight
        });

        if (isOutside) {
          this.setState({
            crop: {},
            cropTemp: null,
            isAddCrop: false
          });
        } else if (!changeWithRotate) {
          this.setState({
            cropTemp: {
              selected: this.state.selectedPage,
              x:
                crop.x +
                this.state.cropTemp.offsetImage.offsetLeft -
                elPrimary.offsetLeft,
              y:
                crop.y +
                this.state.cropTemp.offsetImage.offsetTop -
                elPrimary.offsetTop,
              width: crop.width,
              height: crop.height,
              scaleX: scaleX,
              scaleY: scaleY,
              offsetImage: {
                offsetTop: document.getElementsByClassName(
                  "primary-image-block"
                )[0].offsetTop,
                offsetLeft: document.getElementsByClassName(
                  "primary-image-block"
                )[0].offsetLeft
              }
            },
            crop: {
              aspect: undefined,
              height: crop.height,
              width: crop.width,
              x:
                crop.x +
                this.state.cropTemp.offsetImage.offsetLeft -
                elPrimary.offsetLeft,
              y:
                crop.y +
                this.state.cropTemp.offsetImage.offsetTop -
                elPrimary.offsetTop,
              unit: "px"
            }
          });
        }
      } else if (!isRotate) {
        const cropTemp = {
          selected: this.state.selectedPage,
          x: crop.x,
          y: crop.y,
          width: crop.width,
          height: crop.height,
          scaleX: scaleX,
          scaleY: scaleY,
          offsetImage: {
            offsetTop: document.getElementsByClassName("primary-image-block")[0]
              .offsetTop,
            offsetLeft: document.getElementsByClassName(
              "primary-image-block"
            )[0].offsetLeft
          }
        };
        this.setState({ cropTemp });
      }

      if (changeWithRotate) {
        this.setState({
          isRotate: false,
          changeWithRotate: false
        });
      }

      if (isRotate) {
        this.setState({ isRotate: false });
      }

      this.setState({ isChanged: false });
    }, 100);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg = (image, crop, scaleX, scaleY, fileName) => {
    let canvas = document.createElement("canvas");
    canvas.width = Math.ceil(crop.width * scaleX - 1);
    canvas.height = Math.ceil(crop.height * scaleY - 1);
    let ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    // As Base64 string
    const base64Image = canvas.toDataURL("image/jpeg");
    return base64Image;

    // As Blob
    // return new Promise((resolve, reject) => {
    //   canvas.toBlob(
    //     blob => {
    //       if (!blob) {
    //         //reject(new Error('Canvas is empty'));
    //         console.error("Canvas is empty");
    //         return;
    //       }
    //       blob.name = fileName;
    //       window.URL.revokeObjectURL(this.fileUrl);
    //       this.fileUrl = window.URL.createObjectURL(blob);
    //       resolve(this.fileUrl);
    //     },
    //     "image/jpeg",
    //     1
    //   );
    // });
  };

  rotateImage = base64Data => {
    this.setState({ disableRotate: true, isRotate: true });
    let canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var image = new Image();
    image.src = base64Data;

    canvas.width = image.height;
    canvas.height = image.width;
    ctx.rotate((270 * Math.PI) / 180);

    ctx.drawImage(image, -image.width, 0);

    const base64Image = canvas.toDataURL("image/jpeg");
    // this.removeCropArea(this.state.currentPage);
    const primaryImage = document.getElementsByClassName("ReactCrop__image")[0];

    const offsetHeightBefore = primaryImage.offsetHeight;
    const offsetWidthBefore = primaryImage.offsetWidth;
    this.setState(
      {
        rotateImage: base64Image,
        countRotate:
          this.state.countRotate === 4 ? 1 : this.state.countRotate + 1
      },
      () => {
        setTimeout(() => {
          const scale = primaryImage.offsetWidth / offsetHeightBefore;

          let cropData = {
            aspect: undefined,
            height: this.state.crop.width * scale,
            unit: "px",
            width: this.state.crop.height * scale,
            x: this.state.crop.y * scale,
            y:
              (offsetWidthBefore -
                (this.state.crop.x + this.state.crop.width)) *
              scale
          };

          const element =
            document.getElementsByClassName("ReactCrop__image")[0];
          const scaleX = element.naturalWidth / element.width;
          const scaleY = element.naturalHeight / element.height;

          const cropTemp = {
            selected: this.state.selectedPage,
            x: this.state.crop.y * scale,
            y:
              (offsetWidthBefore -
                (this.state.crop.x + this.state.crop.width)) *
              scale,
            width: this.state.crop.height * scale,
            height: this.state.crop.width * scale,
            scaleX: scaleX,
            scaleY: scaleY,
            offsetImage: {
              offsetTop: document.getElementsByClassName(
                "primary-image-block"
              )[0].offsetTop,
              offsetLeft: document.getElementsByClassName(
                "primary-image-block"
              )[0].offsetLeft
            }
          };
          this.setState({ cropTemp, disableRotate: false });
          this.onCropChange(cropData);
        }, 100);
      }
    );
  };

  resetCropByRotateState = (scale, rotateStatus, element) => {
    const scaleX = element.naturalWidth / element.width;
    const scaleY = element.naturalHeight / element.height;
    const { crop, selectedPage } = this.state;
    let cropData = null;
    let cropTemp = null;

    switch (rotateStatus) {
      case 1:
        cropData = crop;
        cropTemp = this.state.cropTemp;
        break;
      case 2:
        cropData = {
          aspect: undefined,
          unit: "px",
          height: crop.width * scale,
          width: crop.height * scale,
          x: element.offsetWidth - (crop.y + crop.height) * scale,
          y: crop.x * scale
        };

        cropTemp = {
          selected: selectedPage,
          x: element.offsetWidth - (crop.y + crop.height) * scale,
          y: crop.x * scale,
          width: crop.height * scale,
          height: crop.width * scale,
          scaleX: scaleX,
          scaleY: scaleY,
          offsetImage: {
            offsetTop: document.getElementsByClassName("primary-image-block")[0]
              .offsetTop,
            offsetLeft: document.getElementsByClassName(
              "primary-image-block"
            )[0].offsetLeft
          }
        };
        break;

      case 3:
        cropData = {
          aspect: undefined,
          unit: "px",
          height: crop.height,
          width: crop.width,
          x: element.offsetWidth - (crop.x + crop.width),
          y: element.offsetHeight - (crop.y + crop.height)
        };

        cropTemp = {
          selected: selectedPage,
          x: element.offsetWidth - (crop.x + crop.width),
          y: element.offsetHeight - (crop.y + crop.height),
          width: crop.width,
          height: crop.height,
          scaleX: scaleX,
          scaleY: scaleY,
          offsetImage: {
            offsetTop: document.getElementsByClassName("primary-image-block")[0]
              .offsetTop,
            offsetLeft: document.getElementsByClassName(
              "primary-image-block"
            )[0].offsetLeft
          }
        };
        break;

      case 4:
        cropData = {
          aspect: undefined,
          unit: "px",
          height: crop.width * scale,
          width: crop.height * scale,
          x: crop.y * scale,
          y: element.offsetHeight - (crop.x + crop.width) * scale
        };

        cropTemp = {
          selected: selectedPage,
          x: crop.y * scale,
          y: element.offsetHeight - (crop.x + crop.width) * scale,
          width: crop.height * scale,
          height: crop.width * scale,
          scaleX: scaleX,
          scaleY: scaleY,
          offsetImage: {
            offsetTop: document.getElementsByClassName("primary-image-block")[0]
              .offsetTop,
            offsetLeft: document.getElementsByClassName(
              "primary-image-block"
            )[0].offsetLeft
          }
        };
        break;

      default:
        break;
    }

    this.setState({
      cropTemp,
      isRotate: rotateStatus.toString() !== "1",
      rotateImage: null
    });

    this.onCropChange(cropData);
  };

  appendImage = () => {
    let {
      rotateImage,
      contentPages,
      pages,
      selectedPage,
      indexDrawings,
      countRotate
    } = this.state;

    if (rotateImage) {
      const listImage = contentPages;
      let indexDrawingsTemp = indexDrawings;
      listImage.push(rotateImage);

      indexDrawingsTemp[selectedPage][1] += 1;

      const index = [indexDrawingsTemp[selectedPage].join("-"), 0];

      indexDrawingsTemp.push(index);

      const element = document.getElementsByClassName("ReactCrop__image")[0];

      const offsetWidthBefore = element.offsetHeight;

      const rotateStatus = countRotate;
      this.setState(
        {
          contentPages: listImage,
          pages: pages + 1,
          currentPage: listImage.length - 3,
          checked: [...this.state.checked, indexDrawingsTemp.length - 1],
          rotateImage: null,
          countRotate: 1,
          isRotate: true
        },
        () => {
          this.zoomAnimation();

          this.showHideDialogEditName(pages);

          setTimeout(() => {
            const scale = element.offsetWidth / offsetWidthBefore;
            this.resetCropByRotateState(scale, rotateStatus, element);
          }, 100);
        }
      );
    }
  };

  cropAndAppendImage = () => {
    let {
      cropTemp,
      rotateImage,
      selectedPage,
      contentPages,
      pages,
      indexDrawings
    } = this.state;

    let base64Data = rotateImage
      ? rotateImage
      : selectedPage
      ? contentPages[selectedPage]
      : contentPages[0];

    var image = new Image();
    image.src = base64Data;

    const cropImage = this.getCroppedImg(
      image,
      cropTemp,
      cropTemp.scaleX,
      cropTemp.scaleY,
      "newFile.jpeg"
    );

    if (cropImage) {
      const listImage = contentPages;
      let indexDrawingsTemp = indexDrawings;
      listImage.push(cropImage);

      indexDrawingsTemp[selectedPage][1] += 1;

      const index = [indexDrawingsTemp[selectedPage].join("-"), 0];

      indexDrawingsTemp.push(index);
      this.setState(
        {
          contentPages: listImage,
          pages: pages + 1,
          currentPage: listImage.length - 3,
          indexDrawings: indexDrawingsTemp,
          checked: [...this.state.checked, indexDrawingsTemp.length - 1]
        },
        () => {
          this.zoomAnimation();
          this.showHideDialogEditName(pages);
        }
      );
    }
  };

  zoomAnimation = () => {
    const imgEl = document.getElementsByClassName("sub-image-block");
    if (imgEl.length >= 3) {
      imgEl[2].classList.add("is-animated");

      setTimeout(() => {
        if (imgEl && imgEl[2]) {
          imgEl[2].classList.remove("is-animated");
        }
      }, 1000);
    }
  };

  componentWillMount() {
    this.props.dispatchError(null);

    if (document.getElementsByClassName("grid-list-sp").length !== 0) {
      document
        .getElementsByClassName("grid-list-sp")[0]
        .classList.add("scrollbarWidthNone");
    }
  }

  componentDidMount() {
    this.props.translateValidate();

    if (this.props.file) {
      fetch(this.props.file)
        .then(data => {
          return data.blob();
        })
        .then(blob => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            PDFJS.getDocument(reader.result).promise.then(pdf => {
              const contentPages = [];
              const indexDrawings = [];
              this.pdf = pdf;
              for (let i = 0; i < this.pdf.numPages; i++) {
                contentPages.push(this.getPage(i + 1));
                indexDrawings.push([i + 1, 0]);
              }
              Promise.all(contentPages).then(contentPages => {
                this.setState({
                  pages: pdf.numPages,
                  contentPages,
                  indexDrawings
                });
              });
            });
          };
        });
    }
    window.addEventListener("resize", this.getScale);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getScale);
    if (document.getElementsByClassName("grid-list-sp").length !== 0) {
      document
        .getElementsByClassName("grid-list-sp")[0]
        .classList.remove("scrollbarWidthNone");
    }
  }

  getScale = () => {
    const { contentPages, rotateImage, selectedPage } = this.state;
    if (contentPages) {
      let base64Primary = rotateImage
        ? rotateImage
        : selectedPage
        ? contentPages[selectedPage]
        : contentPages[0];

      var image = new Image();
      image.src = base64Primary;
      const elMap = document.getElementById("drawing-area");
      let scaleMap = elMap ? elMap.offsetWidth / elMap.offsetHeight : 0;

      let isVerticalImg =
        image.naturalHeight * scaleMap > image.naturalWidth &&
        image.naturalHeight > elMap.offsetHeight;

      this.setState({ isVerticalImg });
    }
  };

  getPage = num => {
    return new Promise((resolve, reject) => {
      this.pdf.getPage(num).then(page => {
        const scale = 1.5;
        const viewport = page.getViewport(scale);
        const canvas = document.createElement("canvas");
        const canvasContext = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        page
          .render({
            canvasContext,
            viewport
          })
          .promise.then(() => {
            resolve(canvas.toDataURL("image/jpeg"));
          });
      });
    });
  };

  showHideDialogEditName = (indexChecked = null) => {
    let checkedTemp = this.state.checked;
    if (this.state.indexChecked !== null && this.state.isShowDialogEditName) {
      checkedTemp.pop();
    }

    this.setState({
      isShowDialogEditName: !this.state.isShowDialogEditName,
      indexChecked,
      checked: checkedTemp,
      clientError: null
    });
  };

  onChangeName = e => {
    const { drawingsName, indexChecked, selectedPage } = this.state;
    var value = e.target.value.trim();
    // clearTimeout(saveValue);
    // saveValue = setTimeout(function () {
    // Runs 1 second (1000 ms) after the last change

    if (getTextLength(value) > maxNameLength) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Save drawing name and close dialog
    else {
      var index = drawingsName.findIndex(
        item => item.idx === (indexChecked ?? selectedPage)
      );

      if (index !== -1) {
        var drawingsNameTemp = drawingsName;
        drawingsNameTemp[index].name = value;
        this.setState({
          drawingsName: drawingsNameTemp,
          indexChecked: null
        });
      } else {
        let drawingName = {
          idx: indexChecked ?? selectedPage,
          name: value
        };

        this.setState({
          drawingsName: [...drawingsName, drawingName],
          indexChecked: null
        });
      }
    }
    // }, 1000);
  };

  focusInputName = () => {
    this.searchInput.focus();
  };

  render() {
    let {
      pages,
      currentPage,
      contentPages,
      selectedPage,
      checked,
      crop,
      cropData,
      rotateImage,
      isAddCrop,
      countRotate,
      errorMessage,
      isShowDialogError,
      indexDrawings,
      isShowDialogEditName,
      drawingsName,
      clientError,
      indexChecked,
      errorFileName,
      isEditName,
      disableRotate
    } = this.state;

    const onSelected = currentPage => {
      const element = document.getElementsByClassName("ReactCrop__image")[0];
      const offsetWidthBefore = element.offsetHeight;
      const rotateStatus = this.state.countRotate;
      if (this.state.selectedPage !== currentPage) {
        this.setState(
          {
            selectedPage: currentPage,
            rotateImage: null,
            countRotate: 1,
            isChanged: true,
            isRotate: false,
            changeWithRotate: this.state.countRotate.toString() !== "1"
          },
          () => {
            setTimeout(() => {
              const scale = element.offsetWidth / offsetWidthBefore;
              this.resetCropByRotateState(scale, rotateStatus, element);
            }, 100);
          }
        );
      }
    };

    const handleChecked = (e, currentPage) => {
      let { checked, selectedPage, cropTemp } = this.state;

      const indexChecked = checked.indexOf(currentPage);

      if (indexChecked > -1) {
        checked.splice(indexChecked, 1);
        cropData.splice(indexChecked, 1);
        // this.removeCropArea(currentPage);
        this.onCropChange({});
      } else {
        checked.push(currentPage);
        if (
          cropTemp &&
          cropTemp.width !== 0 &&
          cropTemp.height !== 0 &&
          selectedPage === currentPage
        ) {
          let cropData = this.state.cropData;
          const idx = cropData
            ? cropData.findIndex(item => item.selected === currentPage)
            : null;

          if (idx !== -1) {
            cropData[idx] = cropTemp;
          } else {
            cropData.push(cropTemp);
          }

          this.setState({
            cropData
          });
        }
        if (
          (window.innerWidth < 960 || isTablet) &&
          !drawingsName.some(item => item.idx === currentPage)
        ) {
          this.showHideDialogEditName(currentPage);
        }
      }

      this.setState({
        checked: checked
      });

      e.stopPropagation();
    };

    // const getImageDimensions = file => {
    //   return new Promise(function (resolved, rejected) {
    //     var img = new Image();
    //     img.onload = function () {
    //       resolved({
    //         naturalWidth: img.naturalWidth,
    //         naturalHeight: img.naturalHeight
    //       });
    //     };
    //     img.onerror = rejected;
    //     img.src = file;
    //   });
    // };

    let selectedImage, listImages;
    if (contentPages) {
      let base64Primary = rotateImage
        ? rotateImage
        : selectedPage
        ? contentPages[selectedPage]
        : contentPages[0];

      var image = new Image();
      image.src = base64Primary;
      // const elMap = document.getElementById("drawing-area");
      // let scaleMap = elMap ? elMap.offsetWidth / elMap.offsetHeight : 0;

      // let isVerticalImg =
      //   image.naturalHeight * scaleMap > image.naturalWidth &&
      //   image.naturalHeight > elMap.offsetHeight;
      selectedImage = (
        <Grid
          className={`primary-image-block ${
            this.state.isVerticalImg ? "vertical-primary-block" : ""
          }`}
        >
          <ReactCrop
            src={
              rotateImage
                ? rotateImage
                : selectedPage
                ? contentPages[selectedPage]
                : contentPages[0]
            }
            crop={crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        </Grid>
      );

      let currentPages = [];
      for (let i = 0; i < 3; i++) {
        if (contentPages[currentPage + i]) {
          currentPages.push(currentPage + i);
        }
      }

      listImages = currentPages.map((currentPage, index) => {
        return (
          <Grid
            key={index}
            className={
              selectedPage == currentPage
                ? "sub-image-area m-5 selected"
                : "sub-image-area m-5"
            }
            onClick={() => {
              onSelected(currentPage);
            }}
          >
            <Grid className="sub-image-index">
              {indexDrawings[currentPage][0]}
            </Grid>
            <Grid className={`sub-image-block anim-box zoomin`}>
              <img key={index} src={contentPages[currentPage]} />
              <Grid class="sub-image-name">
                <span>
                  {drawingsName.find(item => item.idx === currentPage)?.name}
                </span>
              </Grid>
              <Checkbox
                className="selected-photos p-0"
                color="primary"
                checked={checked.includes(currentPage)}
                onClick={e => {
                  handleChecked(e, currentPage);
                }}
                inputProps={{ "aria-label": "Primary checkbox" }}
              />
            </Grid>
          </Grid>
        );
      });
    } else {
      selectedImage = <p>Loading...</p>;
      listImages = <p>Loading...</p>;
    }

    const changePage = offset => {
      this.setState({ currentPage: this.state.currentPage + offset });
    };

    const previousPage = () => {
      changePage(-1);
    };

    const nextPage = () => {
      changePage(1);
    };

    const wheel = e => {
      if (e.deltaY < 0) {
        if (currentPage > 0) previousPage();
      } else {
        if (currentPage + 3 < pages) nextPage();
      }
    };

    const onSubmit = e => {
      const { drawingsWithSpots, fileName } = this.props;
      const { checked, indexDrawings } = this.state;

      // Validate if no drawing checked
      if (checked.length === 0) {
        const message = translateMessage(
          this.props.message.required,
          this.props.regexObject
        );
        alert("Drawing " + message);
        return;
      }

      // Validate when not input name of drawings
      let errorFileName = [];
      var listCheckedSort = checked.sort(function (a, b) {
        return a - b;
      });
      for (let i = 0; i < listCheckedSort.length; i++) {
        const pageNum = checked[i];
        if (!drawingsName.find(item => item.idx === pageNum)?.name) {
          errorFileName.push(this.state.indexDrawings[pageNum][0]);
        }
      }

      if (errorFileName.length > 0) {
        // const message = translateMessage(
        //   this.props.message.noHaveFileName,
        //   this.props.regexObject
        // );

        this.setState({ errorFileName, isShowDialogError: true });
        return;
      }

      // Validate maximum 50 drawings
      if (drawingsWithSpots.length + checked.length > maximumDrawing) {
        const message = translateMessage(
          this.props.message.overLimitUpload,
          this.props.regexObject
        );
        alert(message);
        return;
      }

      // Format drawing before submit
      const formData = new FormData();
      for (let i = 0; i < checked.length; i++) {
        let data = null;
        const pageNum = checked[i];
        data = contentPages[pageNum];

        var file = dataURLtoFile(
          data ?? null,
          // fileName.substr(0, fileName.lastIndexOf(".")) + ".jpg"
          base64url(drawingsName.find(item => item.idx === pageNum)?.name) +
            ".jpg"
        );
        formData.append("file", file);
      }

      this.props.postDrawing(this.props.siteUC, formData);
      this.props.onClose();

      if (this.props.error) {
        alert(this.props.error);
      }
    };

    const dataURLtoFile = (dataUrl, filename) => {
      var arr = dataUrl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    };

    const toggleDialogError = () => {
      this.setState({ isShowDialogError: !isShowDialogError });
    };

    const onEditNameSubmit = value => {
      // Validate drawing name
      let clientError = null;

      const trimedValues = trimObjValues(value);

      if (!trimedValues.name) {
        clientError = {
          ...clientError,
          name: translateMessage(
            this.props.message.required,
            this.props.regexObject
          )
        };
      }

      if (getTextLength(trimedValues.name) > maxNameLength) {
        clientError = {
          ...clientError,
          name: translateMessage(
            this.props.message.maximumCharacter,
            this.props.regexObject
          )
        };
      }

      this.setState({
        clientError: clientError
      });

      // Save drawing name and close dialog
      if (!clientError) {
        var index = drawingsName.findIndex(
          item => item.idx === (indexChecked ?? selectedPage)
        );

        if (index !== -1) {
          var drawingsNameTemp = drawingsName;
          drawingsNameTemp[index].name = trimedValues.name;
          this.setState({
            drawingsName: drawingsNameTemp,
            indexChecked: null
          });
        } else {
          let drawingName = {
            idx: indexChecked ?? selectedPage,
            name: trimedValues.name
          };

          this.setState({
            drawingsName: [...drawingsName, drawingName],
            indexChecked: null
          });
        }

        this.showHideDialogEditName();
      }
    };

    return (
      <>
        <Formik initialValues={this.props.initialValues} enableReinitialize>
          {formik => (
            <Dialog
              open={this.props.open}
              onClose={() => {}}
              aria-labelledby="max-width-dialog-title"
              // fullScreen
              maxWidth={false}
              PaperProps={{
                className: "dialog dialog-sign-up dialog-pdf-upload"
              }}
            >
              <Fab
                onClick={this.props.onClose}
                size="small"
                className="white-text close-button"
              >
                <CloseIcon />
              </Fab>

              <DialogContent className="pdf-preview-content">
                <Sp>
                  <Grid container justify="flex-end">
                    <Grid
                      container
                      direction="column"
                      className="pdf-btn-block"
                    >
                      <Grid>
                        <Button
                          onClick={this.props.onClose}
                          color="primary"
                          className="dialog-button m-5"
                        >
                          キャンセル
                        </Button>
                        <Button
                          onClick={onSubmit}
                          color="primary"
                          className="dialog-button m-5"
                          variant="contained"
                          disabled={this.state.checked.length === 0}
                        >
                          追加
                        </Button>
                      </Grid>
                      <Grid>
                        <Typography className="text-bold mr-5" variant="span">
                          追加するページをチェックしてください
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    justify="center"
                    className="mt-10"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      className="page-selected-sp mt-10"
                    >
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid
                          className="drawing-name-primary"
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid container direction="row" alignItems="center">
                            <span className="primary-index mr-5">
                              {indexDrawings.length > 0
                                ? indexDrawings[selectedPage][0]
                                : ""}
                            </span>
                            <span>
                              図面名：
                              {
                                drawingsName.find(
                                  item => item.idx === selectedPage
                                )?.name
                              }
                            </span>
                          </Grid>
                          <Grid className="btn-edit-name">
                            <span
                              onClick={e => {
                                this.showHideDialogEditName();
                                e.stopPropagation();
                              }}
                              className="edit-button"
                            >
                              <EditIcon className="icon-margin" />
                            </span>
                          </Grid>
                        </Grid>
                        <Grid className="mr-5">
                          <Fab
                            onClick={() =>
                              this.rotateImage(
                                rotateImage
                                  ? rotateImage
                                  : contentPages[selectedPage]
                              )
                            }
                            disabled={disableRotate}
                            size="small"
                            className="button-rotate mr-10"
                          >
                            <span class="material-icons">
                              rotate_90_degrees_ccw
                            </span>
                          </Fab>
                          <Checkbox
                            className="selected-primary p-0"
                            color="primary"
                            checked={checked.includes(selectedPage)}
                            onClick={e => {
                              handleChecked(e, selectedPage);
                            }}
                            inputProps={{ "aria-label": "Primary checkbox" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        id="drawing-area"
                      >
                        {selectedImage}
                      </Grid>
                      <Grid className="image-button-block">
                        <Button
                          onClick={this.appendImage}
                          color="primary"
                          className="dialog-button m-5"
                          variant="contained"
                          disabled={
                            indexDrawings.length > 0 &&
                            indexDrawings[selectedPage][0].toString().split("-")
                              .length < 3 &&
                            rotateImage &&
                            countRotate !== 1
                              ? false
                              : true
                          }
                        >
                          追加
                        </Button>
                        <Button
                          onClick={this.cropAndAppendImage}
                          color="primary"
                          className="dialog-button m-5"
                          variant="contained"
                          disabled={!isAddCrop}
                        >
                          選択範囲を追加
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className="page-unselected-sp p-0"
                    >
                      <Grid
                        onWheel={e => wheel(e)}
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        {pages > 3 && (
                          <Grid item className="p-0 arrow-block">
                            {currentPage > 0 && (
                              <Fab className="btn-prev" onClick={previousPage}>
                                <label style={{ color: "inherit" }}>
                                  <ArrowUpwardIcon className="icon" />
                                </label>
                              </Fab>
                            )}
                          </Grid>
                        )}
                        <Grid item className="p-0">
                          {listImages}
                        </Grid>
                        {pages > 3 && (
                          <Grid item className="p-0 arrow-block">
                            {currentPage + 3 < pages && (
                              <Fab className="btn-next" onClick={nextPage}>
                                <label style={{ color: "inherit" }}>
                                  <ArrowDownwardIcon className="icon" />
                                </label>
                              </Fab>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Sp>
                <Pc>
                  <Grid container spacing={3} alignItems="flex-start">
                    <Grid item xs={12} sm={8} className="page-selected-pc">
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid
                          className="drawing-name-primary"
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                          onClick={e => {
                            this.focusInputName();
                            e.stopPropagation();
                          }}
                        >
                          <Grid container direction="row" alignItems="center">
                            <span className="primary-index mr-5">
                              {indexDrawings.length > 0 &&
                                indexDrawings[selectedPage][0]}
                            </span>
                            <span>図面名：</span>
                            <input
                              type="text"
                              className="input-drawing-name"
                              onChange={this.onChangeName}
                              value={
                                drawingsName.find(
                                  item => item.idx === selectedPage
                                )?.name ?? ""
                              }
                              ref={inputEl => (this.searchInput = inputEl)}
                            />
                          </Grid>
                          <Grid className="btn-edit-name">
                            <span className="edit-button">
                              <EditIcon className="icon-margin" />
                            </span>
                          </Grid>
                        </Grid>
                        <Grid className="mr-5">
                          <Fab
                            onClick={() =>
                              this.rotateImage(
                                rotateImage
                                  ? rotateImage
                                  : contentPages[selectedPage]
                              )
                            }
                            disabled={disableRotate}
                            size="small"
                            className="button-rotate mr-10"
                          >
                            <span class="material-icons">
                              rotate_90_degrees_ccw
                            </span>
                          </Fab>
                          <Checkbox
                            className="selected-primary p-0"
                            color="primary"
                            checked={checked.includes(selectedPage)}
                            onClick={e => {
                              handleChecked(e, selectedPage);
                            }}
                            inputProps={{ "aria-label": "Primary checkbox" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        id="drawing-area"
                      >
                        {selectedImage}
                      </Grid>
                      <Grid className="image-button-block">
                        <Button
                          onClick={this.appendImage}
                          color="primary"
                          className="dialog-button m-5"
                          variant="contained"
                          disabled={
                            indexDrawings.length > 0 &&
                            indexDrawings[selectedPage][0].toString().split("-")
                              .length < 3 &&
                            rotateImage &&
                            countRotate !== 1
                              ? false
                              : true
                          }
                        >
                          追加
                        </Button>
                        <Button
                          onClick={this.cropAndAppendImage}
                          color="primary"
                          className="dialog-button m-5"
                          variant="contained"
                          disabled={!isAddCrop}
                        >
                          選択範囲を追加
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} className="page-unselected-pc">
                      <Grid
                        container
                        direction="column"
                        className="pdf-btn-block"
                      >
                        <Grid>
                          <Button
                            onClick={this.props.onClose}
                            color="primary"
                            className="dialog-button m-5"
                          >
                            キャンセル
                          </Button>
                          <Button
                            onClick={onSubmit}
                            color="primary"
                            className="dialog-button m-5"
                            variant="contained"
                            disabled={this.state.checked.length === 0}
                          >
                            追加
                          </Button>
                        </Grid>
                        <Grid>
                          <Typography className="text-bold mr-5" variant="span">
                            追加するページをチェックしてください
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        onWheel={e => wheel(e)}
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item className="p-0 arrow-block">
                          {currentPage > 0 && (
                            <Fab className="btn-prev" onClick={previousPage}>
                              <label style={{ color: "inherit" }}>
                                <ArrowUpwardIcon className="icon" />
                              </label>
                            </Fab>
                          )}
                        </Grid>

                        <Grid item className="p-0">
                          {listImages}
                        </Grid>

                        <Grid item className="p-0 arrow-block">
                          {currentPage + 3 < pages && (
                            <Fab className="btn-next" onClick={nextPage}>
                              <label style={{ color: "inherit" }}>
                                <ArrowDownwardIcon className="icon" />
                              </label>
                            </Fab>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Pc>
              </DialogContent>
            </Dialog>
          )}
        </Formik>

        {isShowDialogError && (
          <ErrorDialog
            open={isShowDialogError}
            onClose={toggleDialogError}
            textContent={
              <Grid>
                ページ番号{" "}
                <span className="label-highlight">
                  {errorFileName.join(", ")}
                </span>{" "}
                の図面名を設定してください
              </Grid>
            }
            PaperProps={{
              className: "dialog dialog-sign-up"
            }}
          />
        )}

        {isShowDialogEditName && (
          <CommonDialog
            title={
              drawingsName.find(
                item => item.idx === (indexChecked ?? selectedPage)
              )?.name
                ? "図面名の編集"
                : "図面名の追加"
            }
            isDialogShow={isShowDialogEditName}
            initialValues={{
              name: drawingsName.find(
                item => item.idx === (indexChecked ?? selectedPage)
              )?.name
            }}
            onSubmit={onEditNameSubmit}
            onClose={this.showHideDialogEditName}
            fieldList={this.fieldListEditName}
            submitButtonTitle={
              drawingsName.find(
                item => item.idx === (indexChecked ?? selectedPage)
              )?.name
                ? "登録"
                : "追加"
            }
            isEdit={false}
            submitErrors={clientError}
            isSingleColumn={true}
          />
        )}
      </>
    );
  }
}

PdfPreview.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

const mapStateToProps = state => ({
  type: state.rootSitesReducer.siteInfoReducer.type,
  submitErrors: state.storesReducer.error,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message,
  error: state.storesReducer.error,
  loading: state.storesReducer.pending,
  drawingsWithSpots: getDrawingsWithSpots(
    state.rootSitesReducer.siteInfoReducer.drawingsWithSpots
  )
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchError,
      postDrawing,
      postPhoto,
      finishLoading,
      translateValidate: translateValidate
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PdfPreview));
