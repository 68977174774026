import axios from "axios";
import {
  GET_ERRORS,
} from "./types";

const URL = '/api/files'

export const search = (siteId) => dispatch => {
  return axios
    .get(URL + "?siteId=" + siteId)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const fetch = (id) => dispatch => {
  return axios
    .get(URL + "/" + id)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const post = (formData) => dispatch => {
  return axios
    .post(URL, formData)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const download = (id) => dispatch => {
  return axios
    .get(URL + "/" + id + '/download', {responseType: 'arraybuffer'})
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
