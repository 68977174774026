import {
  FETCH_ROBOT_DETAIL_INFO_SUCCESS,
  FETCH_ROBOT_INFO_EDIT_SUCCESS,
  PUT_ROBOT_INFO_STARTED,
  PUT_ROBOT_INFO_SUCCESS,
  DELETE_ROBOT_INFO_STARTED,
  DELETE_ROBOT_INFO_SUCCESS,
  FETCH_ROBOT_LOG_BUILD_DETAIL_INFO_SUCCESS,
  RETURN_ROBOT_SUCCESS
} from "../constant/constant";

export const fetchRobotDetailInfoSuccess = robotInfo => {
  return {
    type: FETCH_ROBOT_DETAIL_INFO_SUCCESS,
    robotInfo: robotInfo
  };
};

export const fetchRobotLogBuildDetailInfoSuccess = robotLogBuildInfo => {
  return {
    type: FETCH_ROBOT_LOG_BUILD_DETAIL_INFO_SUCCESS,
    robotLogBuildInfo: robotLogBuildInfo
  };
};

export const fetchRobotInfoEditSuccess = robotInfoEdit => {
  return {
    type: FETCH_ROBOT_INFO_EDIT_SUCCESS,
    robotInfoEdit: robotInfoEdit
  };
};

export const putRobotInfoStarted = () => {
  return {
    type: PUT_ROBOT_INFO_STARTED
  };
};

export const putRobotInfoSuccess = robotInfoUpdate => {
  return {
    type: PUT_ROBOT_INFO_SUCCESS,
    robotInfoUpdate: robotInfoUpdate
  };
};

export const deleteRobotInfoStarted = () => {
  return {
    type: DELETE_ROBOT_INFO_STARTED
  };
};

export const deleteRobotInfoSuccess = robotInfoEditDelete => {
  return {
    type: DELETE_ROBOT_INFO_SUCCESS,
    robotInfoEditDelete: robotInfoEditDelete
  };
};

export const returnRobotSuccess = () => {
  return {
    type: RETURN_ROBOT_SUCCESS
  };
};
