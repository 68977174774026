import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import { logoutUser } from "../../components/auth/service/service";
import { getBuilderName } from "../Stores/reducer/reducer";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ClockIcon from "../../common/img/clock_icon.svg";
import { Link } from "react-router-dom";
import { showLogMeetHistory } from "../../common/Stores/action/action";

class HeaderBuilderSP extends Component {
  state = {
    anchorEl: null,
    anchorElHelpPage: null
  };

  componentWillMount() {
    this.props.onMount();
  }

  showMenu = e => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  showHelpPageMenu = e => {
    this.setState({
      anchorElHelpPage: e.currentTarget
    });
    document.ontouchmove = function (event) {
      event.preventDefault();
    };
  };

  closeMenu = () => {
    this.setState({
      anchorEl: null
    });
  };

  closeHelpPageMenu = () => {
    this.setState({
      anchorElHelpPage: null
    });

    document.ontouchmove = function () { };
  };

  linkTo = path => () => {
    this.props.history.push(path);
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const { builderName, showDrawer, builderFlag, showLogMeetHistory } = this.props;
    const { anchorEl, anchorElHelpPage } = this.state;
    const open = Boolean(anchorEl);
    const openHelpPage = Boolean(anchorElHelpPage);
    const id = open ? "simple-popover" : undefined;
    const helpPageId = openHelpPage ? "simple-popover" : undefined;

    return (
      <>
        <AppBar
          position="fixed"
          color="default"
          className="header-menu"
          style={{ height: 64 }}
          id="header-builder-sp"
        >
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className="header-sp"
          >
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="header-left valign-wrapper"
              style={{
                background: "initial",
                padding: "initial",
                flexBasis: "initial",
                minWidth: "initial",
                width: "initial"
              }}
            >
              <IconButton
                size="small"
                onClick={showDrawer}
                edge="start"
                className="header-menu-button"
                aria-label="menu-button"
                style={{ color: "white" }}
              >
                <MenuIcon className="icon" />
              </IconButton>
            </Grid>
            <Grid
              className="valign-wrapper"
              style={{ width: "calc(100% - 270px)", padding: "initial" }}
            >
              <Grid className="valign-wrapper" style={{ width: "100%" }}>
                <Typography
                  variant="h6"
                  className="primary-font-family white-text text-bold valign-wrapper"
                  style={{
                    width: "100%",
                    textAlign: "right",
                    display: "inline",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  {builderName}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              className="header-right valign-wrapper"
              style={{ width: "initial", padding: "initial" }}
            >
              <Grid className="valign-wrapper">
                <IconButton
                  size="small"
                  aria-label="menu-button"
                  className="white-text"
                  onClick={this.showMenu}
                >
                  <AccountCircleIcon className="icon" />
                </IconButton>
              </Grid>
              <Grid className="valign-wrapper">
                <IconButton
                  size="small"
                  aria-label="menu-button"
                  className="white-text"
                  onClick={this.showHelpPageMenu}
                >
                  <HelpOutlineIcon className="icon" />
                </IconButton>

                {builderFlag && (<Link to="/call-history" className="reset-link">
                  <Button
                    size="small"
                    aria-label="menu-button"
                    className="white-text"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      width: "124px",
                      height: "40px",
                      marginLeft: "12px"
                    }}
                    onClick={showLogMeetHistory}
                  >
                    <img src={ClockIcon} />
                    <span
                      style={{
                        color: "#162F50",
                        fontFamily: "Noto Sans JP",
                        fontWeight: "bold",
                        fontSize: "16px",
                        lineHeight: "28px"
                      }}
                    >
                      &nbsp;
                      通話履歴
                    </span>
                  </Button>
                </Link>)}
              </Grid>
            </Grid>
          </Grid>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={this.closeMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <MenuList className="popover-sites">
              <MenuItem
                className="popover-item"
                onClick={this.linkTo("/mypage/profile")}
              >
                プロフィール情報
              </MenuItem>
              <MenuItem
                onClick={this.linkTo("/mypage/password")}
                className="popover-item"
              >
                パスワード変更
              </MenuItem>
              <MenuItem
                onClick={this.linkTo("/licence")}
                className="popover-item"
              >
                法律に基づく情報
              </MenuItem>
              <MenuItem
                onClick={this.linkTo("/mypage/logout")}
                className="popover-item"
              >
                ログアウト
              </MenuItem>
            </MenuList>
          </Popover>
          <Popover
            id={helpPageId}
            open={openHelpPage}
            anchorEl={anchorElHelpPage}
            onClose={this.closeHelpPageMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <MenuList>
              <div
                style={{
                  borderBottom: "1px solid black",
                  cursor: "default",
                  backgroundColor: "white",
                  padding: "6px 16px",
                  fontSize: "15px",
                  outline: "none",
                  userSelect: "none"
                }}
              >
                ヘルプ
              </div>
              <MenuItem
                onClick={() =>
                  window.open("https://www.log-build.com/ls-help/", "_blank")
                }
                style={{ minHeight: "auto" }}
              >
                マニュアルガイド
              </MenuItem>
              <MenuItem
                onClick={() =>
                  window.open(
                    "https://www.log-build.com/ls-help/#link001",
                    "_blank"
                  )
                }
                style={{ minHeight: "auto" }}
              >
                動画マニュアル
              </MenuItem>
              <MenuItem
                onClick={() =>
                  window.open(
                    "https://www.log-build.com/ls-help/#link002",
                    "_blank"
                  )
                }
                style={{ minHeight: "auto" }}
              >
                アプリダウンロード
              </MenuItem>
              <MenuItem
                onClick={() =>
                  window.open("https://www.log-build.com/profile/", "_blank")
                }
                style={{ minHeight: "auto" }}
              >
                新着情報
              </MenuItem>
              <MenuItem
                onClick={() =>
                  window.open(
                    "https://www.log-build.com/user-support/",
                    "_blank"
                  )
                }
                style={{ minHeight: "auto" }}
              >
                お問合せ
              </MenuItem>
            </MenuList>
          </Popover>
        </AppBar>
        <div style={{ height: 65 }}></div>
      </>
    );
  }
}

HeaderBuilderSP.propTypes = {};

const mapStateToProps = state => ({
  builderName: getBuilderName(state.storesReducer.builderName)
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logoutUser: logoutUser,
      showLogMeetHistory
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeaderBuilderSP));
