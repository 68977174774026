import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Component from "../Common";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Menu,
  MenuItem
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/ChevronRight";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";
import RoomIcon from "@material-ui/icons/Room";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { TYPE_SELECT_IMAGE_3D, TYPE_DIALOG, MODE_DEVICE } from "./constants";
import { compose } from "redux";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DrawingPhoto from "../DrawingPhoto";
const ITEM_HEIGHT = 48;

class DialogViewer extends React.Component {
  cancelSubmission = false;
  constructor(props) {
    super(props);
    this.state = {
      urlPhoto: undefined,
      drawingBlob: undefined,
      positions: [],
      listSpots: [],
      drawingName: "",
      drawing: {},
      showList: false,
      isSwitch: false
    };
    this.transformComponentRef = React.createRef();
  }
  componentWillMount() {
    window.addEventListener("resize", this.updateDimensions);
    window.addEventListener("resize", this.setScreenOrientation);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    if (this.state) {
      this.setScreenOrientation();
    }

    const { drawingCode, drawingsWithSpots } = this.props;

    if (this.props.drawingsWithSpots && this.props.open) {
      const listSpot = this.props.drawingsWithSpots.find(
        item => item.uniqueCode === this.props.drawingCode
      );

      this.setState({
        urlPhoto: listSpot && listSpot.path,
        drawingBlob: listSpot && listSpot.blob,
        listSpots: listSpot && listSpot.spots,
        drawingName: listSpot && listSpot.name,
        drawing: listSpot
      });
    }
    // window.addEventListener("click",this.returnDefaultSize);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.open !== this.props.open) {
      if (this.props.drawingsWithSpots && this.props.open) {
        const listSpot = this.props.drawingsWithSpots.find(
          item => item.uniqueCode === this.props.drawingCode
        );
        this.setState({
          urlPhoto: listSpot && listSpot.path,
          drawingBlob: listSpot && listSpot.blob,
          listSpots: listSpot && listSpot.spots,
          drawingName: listSpot && listSpot.name,
          drawing: listSpot
        });
      }
    }
    if (
      prevState.drawing &&
      this.state.drawing &&
      prevState.drawing.uniqueCode !== this.state.drawing.uniqueCode
    ) {
      if (prevState.urlPhoto === this.state.urlPhoto) {
        this.setPositionMarker();
      }
    }
    if (this.state.isSwitch) {
      this.setState({
        isSwitch: false
      });
    }
  }

  componentWillUnmount() {
    this.cancelSubmission = true;
  }

  setPositionMarker = () => {
    if (!this.cancelSubmission) {
      const posAfter = [];
      this.state.listSpots.map(pos => {
        let element = document.getElementById("primary-image-dialog");
        let offsetWidth = element ? element.offsetWidth : 0;

        let offsetHeight = element ? element.offsetHeight : 0;

        let naturalWidth = element ? element.naturalWidth : 0;

        let naturalHeight = element ? element.naturalHeight : 0;

        let offsetLeft = element ? element.offsetLeft : 0;

        let offsetTop = element ? element.offsetTop : 0;

        posAfter.push({
          uniqueCode: pos.uniqueCode,
          name: pos.name,
          posX: (pos.x * offsetWidth) / naturalWidth + offsetLeft,
          posY: (pos.y * offsetHeight) / naturalHeight + offsetTop
        });
      });
      this.setState({
        positions: posAfter
      });
    }
  };

  setScreenOrientation = e => {
    if (!this.cancelSubmission) {
      const { typeDialog } = this.props;
      if (window.matchMedia("(orientation: portrait)").matches) {
        this.setState({
          screenOrientation: "portrait"
        });
      }
      if (window.matchMedia("(orientation: landscape)").matches) {
        // window.scrollTo(0, 1);
        this.setState({
          screenOrientation: "landscape"
        });
      }
    }
  };
  updateDimensions = e => {
    const { drawingCode, drawingsWithSpots } = this.props;
    if (this.props.drawingsWithSpots.length > 0) {
      this.setPositionMarker();
    }
  };

  returnDefaultSize = () => {
    let currentRef = this.transformComponentRef.current;
    if (currentRef != null) {
      currentRef.context.dispatch.resetTransform();
    }
  };

  handleCloseDialog = () => {
    if (this.props.typeDialog === TYPE_DIALOG.ZOOM_IMAGE) {
      this.props.onSetTypeDialog({ typeDialog: null });
    }
    this.props.onClose(false);
  };

  onSwitchDrawing = switchType => {
    const { drawingCode, drawingsWithSpots } = this.props;
    const currentIndex = drawingsWithSpots.findIndex(
      item => item.uniqueCode === this.state.drawing.uniqueCode
    );
    let listSpot = {};
    if (switchType === "prev") {
      listSpot = this.props.drawingsWithSpots[currentIndex - 1];
    } else {
      listSpot = this.props.drawingsWithSpots[currentIndex + 1];
    }
    this.setState(
      {
        urlPhoto: listSpot && listSpot.path,
        drawingBlob: listSpot && listSpot.blob, 
        listSpots: listSpot && listSpot.spots,
        drawingName: listSpot && listSpot.name,
        drawing: listSpot,
        isSwitch: true
      },
      () => {
        // Some params are not ready to set, so need set timeout to make sure it's ready
        setTimeout(this.returnDefaultSize, 100);
      }
    );
  };

  handleDisableSwitch = switchType => {
    const { drawingCode, drawingsWithSpots } = this.props;
    const currentIndex = drawingsWithSpots.findIndex(
      item => item.uniqueCode === this.state.drawing.uniqueCode
    );
    //disable both button if spots is empty
    if (drawingsWithSpots.length === 0) {
      return true;
    }

    return (
      (currentIndex === 0 && switchType === "prev") ||
      (currentIndex === drawingsWithSpots.length - 1 && switchType === "next")
    );
  };
  checkActiveSpot = data => {
    const {
      listShootCurrentActive,
      typeDialog,
      listShootsSpot,
      listAdjustPhotoSelected
    } = this.props;

    if (typeDialog === TYPE_DIALOG.ZOOM_IMAGE) {
      const indexActive = listShootCurrentActive.findIndex(
        item => item.spotsUC === data
      );
      return indexActive > -1;
    }

    if (typeDialog === TYPE_DIALOG.ADJUST_IMAGE && listAdjustPhotoSelected) {
      const listShoot = listShootsSpot.find(
        item => item.uniqueCode === listAdjustPhotoSelected.uniqueCode
      );
      if (listShoot) {
        const indexActive = listShoot.shoots.findIndex(
          item => item.spotsUC === data
        );
        return indexActive === -1;
      } else {
        return true;
      }
    }
  };

  handleAddImageNotAdjusted = spotsUC => {
    const {
      shootsUCNotAdjusted,
      addImageNotAdjusted,
      logWalkInfo,
      typeDialog,
      classification,
      onRedirectNotfoundPage,
      onSetProgressBar,
      historyList,
      redirectNoImage,
      backToDrawing,
      historyListAdjustPhoto,
      handleChangeHistory
    } = this.props;
    this.props.resetPreviousShootDirection();
    this.props.onResetFlagRedirectNoImage();
    const sitesUC = this.props.match.path.includes(
      "/logwalk-viewer/sites/:id/spots/null"
    )
      ? this.props.match.params.id
      : null;

    const shootsUCRoot = this.props.match.path.includes(
      "/logwalk-viewer/sites/:id/spots/null"
    )
      ? "no-image"
      : null;
    const data = {
      spotsUC,
      sitesUC: logWalkInfo.sitesUC ? logWalkInfo.sitesUC : sitesUC,
      shootsUC: shootsUCNotAdjusted,
      shootsUCRoot: shootsUCRoot ? shootsUCRoot : this.props.match.params.id,
      isLastDrawingUnAdjust:
        historyListAdjustPhoto.length === 1 &&
        historyListAdjustPhoto[0].shoots.length === 1
    };

    if (
      typeDialog === TYPE_DIALOG.ADJUST_IMAGE &&
      this.checkActiveSpot(spotsUC)
    ) {
      addImageNotAdjusted({
        value: data,
        onClose: this.handleCloseDialog,
        classification,
        onRedirectNotfoundPage,
        onSetProgressBar,
        redirectNoImage,
        backToDrawing,
        handleChangeHistory
      });
      this.props.onOpenAdjustImage();
      this.props.onResetPositionMiniWindowViewer();      
    }
    if (typeDialog === TYPE_DIALOG.ZOOM_IMAGE) {
      const shoot = this.props.listShootCurrentActive.find(
        item => item.spotsUC === spotsUC
      );
      this.props.onSetSpotCurrent(spotsUC);
      this.props.onSetTypeSelectImage3D(TYPE_SELECT_IMAGE_3D.SPOT);
      this.handleCloseDialog();
      this.props.onResetWidthHeightMiniWindow();
      this.props.onResetPositionMiniWindowViewer(); 
      if (!!shoot) {
        if (this.props.classification === "supplier") {
          this.props.history.replace(
            "/sp/logwalk-viewer/shoots/" + shoot.uniqueCode
          );
        } else {
          this.props.history.replace(
            "/logwalk-viewer/shoots/" + shoot.uniqueCode
          );
        }
      } else {
        this.props.fetchShootLogWalk();
        if (this.props.logWalkInfo.recordsUC === "default") {
          this.props.onSetHistoryViewer(
            historyList && historyList[0]
              ? historyList[0].uniqueCode
              : undefined
          );
        }
        if (this.props.classification === "supplier") {
          this.props.history.replace("/sp/logwalk-viewer/shoots/no-image");
        } else {
          this.props.history.replace("/logwalk-viewer/shoots/no-image");
        }
      }
    }
  };

  handleShowPanelAdjust = () => {
    const { showPanelAdjustImage } = this.props;
    this.handleCloseDialog();
    this.props.onShowPannelAdjust(!showPanelAdjustImage);
  };

  showListDraw = e => {
    this.setState({ showList: e.currentTarget });
  };

  closeListDraw = e => {
    this.setState({ showList: false });
  };

  handleSwitchDrawing = uniqueCode => {
    const { drawingCode, drawingsWithSpots } = this.props;
    const drawing = drawingsWithSpots.find(
      item => item.uniqueCode === uniqueCode
    );
    if (drawing) {
      this.setState(
        {
          urlPhoto: drawing.path,
          drawingBlob: drawing.blob,
          listSpots: drawing.spots,
          drawingName: drawing.name,
          drawing: drawing
        },
        () => {
          // Some params are not ready to set, so need set timeout to make sure it's ready
          setTimeout(this.returnDefaultSize, 100);
        }
      );
    }
    this.props.onResetPositionMiniWindowViewer();
    this.closeListDraw();
  };

  render() {
    const { urlPhoto, drawingBlob, showList, drawing, isSwitch, screenOrientation } =
      this.state;
    const {
      spotCurrent,
      historyListAdjustPhoto,
      typeDialog,
      drawingsWithSpots,
      drawingCode,
      imageShootUnAdjusted
    } = this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleCloseDialog}
        aria-labelledby="max-width-dialog-title"
        fullWidth
        maxWidth="lg"
        scroll="body"
        PaperProps={{
          // className: "dialog-min-height container-mobile-header"
          className:
            this.props.modeDevice === MODE_DEVICE.SP
              ? "  container-mobile-header dialog paper-image drawing-dialog-height-update-sp m-0"
              : " container-mobile-header dialog paper-image drawing-dialog-height-update m-0"
        }}
      >
        {this.props.modeDevice === MODE_DEVICE.SP &&
          screenOrientation === "portrait" && (
            <Grid
              container
              // spacing={2}
              justify="space-between"
              className="container-mobile-header dialog-viewer-header-container mb-5"
            >
              <Grid item className="text-center">
                {typeDialog === TYPE_DIALOG.ZOOM_IMAGE &&
                  this.props.modeDevice === MODE_DEVICE.SP && (
                    <Fab onClick={this.handleCloseDialog} size="small">
                      <ArrowBackIcon />
                    </Fab>
                  )}
                {typeDialog === TYPE_DIALOG.ADJUST_IMAGE &&
                  this.props.modeDevice === MODE_DEVICE.SP && (
                    <Fab onClick={this.handleCloseDialog} size="small">
                      <ArrowBackIcon />
                    </Fab>
                  )}
              </Grid>
              <Grid item className="viewer-dialog-title">
                <Typography variant="h6"></Typography>
              </Grid>
              <Grid item>
                {/*<Tooltip title="List drawing">*/}
                {this.props.modeDevice === MODE_DEVICE.SP && (
                  <Fab
                    className="btn-choose-dialog-viewer-header"
                    size="small"
                    aria-controls="simple-menu"
                    onClick={this.showListDraw}
                  >
                    <MenuIcon />
                  </Fab>
                )}
              </Grid>
            </Grid>
          )}
        {(this.props.modeDevice === MODE_DEVICE.PC ||
          (this.props.modeDevice === MODE_DEVICE.SP &&
            screenOrientation === "portrait")) && (
          <div className="drawing-dialog-bg">
            <DialogTitle
              disableTypography
              className={
                this.props.modeDevice === MODE_DEVICE.SP
                  ? "dialog-viewer-header-update-sp"
                  : "dialog-viewer-header-update"
              }
            >
              <Grid
                container
                spacing={2}
                justify="space-between"
                className="container-mobile-header"
              >
                <Grid item className="text-center position-btn">
                  {typeDialog === TYPE_DIALOG.ZOOM_IMAGE &&
                    this.props.modeDevice !== MODE_DEVICE.SP && (
                      <Fab onClick={this.handleCloseDialog} size="small">
                        <ArrowBackIcon />
                      </Fab>
                    )}
                  {typeDialog === TYPE_DIALOG.ADJUST_IMAGE &&
                    this.props.modeDevice !== MODE_DEVICE.SP && (
                      <Fab onClick={this.handleCloseDialog} size="small">
                        <ArrowBackIcon />
                      </Fab>
                    )}
                  {this.props.modeDevice === MODE_DEVICE.SP && (
                    <Button
                      name="prev"
                      className="btn-switch no-zoom"
                      size="small"
                      onClick={e => {
                        this.onSwitchDrawing("prev");
                        e.preventDefault();
                      }}
                      disabled={this.handleDisableSwitch("prev")}
                    >
                      <KeyboardArrowLeftIcon />
                    </Button>
                  )}
                </Grid>
                <Grid
                  item
                  className="viewer-dialog-title dialog-viewer-header-title"
                >
                  <Typography variant="h6">{this.state.drawingName}</Typography>
                </Grid>
                <Grid item className="text-center position-btn">
                  {(this.props.modeDevice === MODE_DEVICE.PC ||
                    (this.props.modeDevice === MODE_DEVICE.SP &&
                      screenOrientation == "landscape")) && (
                    <Fab
                      className={"btn-choose-dialog-viewer-header"}
                      size="small"
                      aria-controls="simple-menu"
                      onClick={this.showListDraw}
                    >
                      <MenuIcon />
                    </Fab>
                  )}
                  {/* {this.props.modeDevice === MODE_DEVICE.PC && (
                  <Fab
                    className={`${this.state.showList && "btn-choose"}`}
                    size="small"
                    aria-controls="simple-menu"
                    onClick={this.showListDraw}
                  >
                    <MenuIcon />
                  </Fab>
                )} */}

                  {this.props.modeDevice === MODE_DEVICE.SP && (
                    <Button
                      name="next"
                      className="btn-switch no-zoom"
                      size="small"
                      onClick={e => {
                        this.onSwitchDrawing("next");
                        e.preventDefault();
                      }}
                      disabled={this.handleDisableSwitch("next")}
                    >
                      <KeyboardArrowRight />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent className="viewer-content-dialog-update overflow-hidden ">
              <Grid
                container
                spacing={0}
                className={
                  this.props.modeDevice !== MODE_DEVICE.SP
                    ? "padding-bottom-8"
                    : ""
                }
              >
                {this.props.modeDevice === MODE_DEVICE.PC && (
                  <Grid item xs={1} className="position-btn">
                    <Button
                      name="prev"
                      className="btn-switch"
                      size="small"
                      onClick={e => {
                        this.onSwitchDrawing("prev");
                        e.preventDefault();
                      }}
                      disabled={this.handleDisableSwitch("prev")}
                    >
                      <KeyboardArrowLeftIcon />
                    </Button>
                  </Grid>
                )}
                <Grid
                  item
                  xs={this.props.modeDevice === MODE_DEVICE.PC ? 10 : 12}
                  md={10}
                >
                  <div
                    className={`text-center 
                    ${
                      this.props.modeDevice !== MODE_DEVICE.SP
                        ? "content-drawing-dialog"
                        : "content-drawing-dialog-sp"
                    } `}
                  >
                    <TransformWrapper defaultScale={1}>
                      <TransformComponent ref={this.transformComponentRef}>
                        <div
                          className={`img-mini-parent-dialog-sp ${
                            typeDialog === TYPE_DIALOG.ADJUST_IMAGE &&
                            this.props.modeDevice !== MODE_DEVICE.SP
                              ? "background-gray-anpha95"
                              : ""
                          } `}
                        >
                          <DrawingPhoto
                            onClick={this.tapToShowFullImage}
                            class="img-mini-dialog"
                            id="primary-image-dialog"
                            alt=""
                            drawingsUC={drawingCode}
                            blob={drawingBlob}
                            loadMarker={this.setPositionMarker}
                            apiPath={urlPhoto}
                            isLogWalkViewer={true}
                            spotCurrent={spotCurrent}
                            imageShootUnAdjusted={imageShootUnAdjusted}
                            checkActiveSpot={this.checkActiveSpot}
                            chooseShootSpot={this.handleAddImageNotAdjusted}
                            positions={this.state.positions}
                            typeDialog={typeDialog}
                            isPreventMovingToLWViewer={
                              this.props.isPreventMovingToLWViewer
                            }
                          />
                          {/* <img
                            className="img-mini-dialog"
                            id="primary-image-dialog"
                            src={urlPhoto}
                            onLoad={this.setPositionMarker}
                            alt=""
                          /> */}
                          {/* {this.state.positions.map((pos, idx) => (
                            <div key={pos.uniqueCode}>
                              <div className="relative">
                                {typeDialog === TYPE_DIALOG.ZOOM_IMAGE && (
                                  <FiberManualRecordIcon
                                    stroke={"black"}
                                    strokeWidth={1}
                                    className={`marker ${
                                      this.checkActiveSpot(pos.uniqueCode)
                                        ? "icon-spot"
                                        : "icon-spot-unactive"
                                    }`}
                                    onClick={() =>
                                      this.handleAddImageNotAdjusted(
                                        pos.uniqueCode
                                      )
                                    }
                                    style={{
                                      left: pos["posX"],
                                      top: pos["posY"]
                                    }}
                                  />
                                )}
                                {typeDialog === TYPE_DIALOG.ADJUST_IMAGE && (
                                  <FiberManualRecordIcon
                                    stroke={"black"}
                                    strokeWidth={1}
                                    className={`marker ${
                                      this.checkActiveSpot(pos.uniqueCode)
                                        ? "icon-spot-logwalkViewer"
                                        : "icon-spot-unactive-logwalkViewer"
                                    }`}
                                    onClick={() =>
                                      this.handleAddImageNotAdjusted(
                                        pos.uniqueCode
                                      )
                                    }
                                    style={{
                                      left: pos["posX"],
                                      top: pos["posY"]
                                    }}
                                  />
                                )}

                                {pos.uniqueCode === spotCurrent &&
                                  typeDialog === TYPE_DIALOG.ZOOM_IMAGE && (
                                    <RoomIcon
                                      className="icon-spot-current marker-selected"
                                      style={{
                                        left: pos["posX"],
                                        top: pos["posY"]
                                      }}
                                    />
                                  )}
                              </div>
                            </div>
                          ))} */}
                        </div>
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
                </Grid>
                {this.props.modeDevice === MODE_DEVICE.PC && (
                  <Grid
                    item
                    xs={1}
                    md={1}
                    className="drawing-dialog-btn-landscape"
                    container
                    alignItems="center"
                  >
                    {/* <Button
                      name="next"
                      className="btn-switch"
                      size="small"
                      onClick={this.nextPage}
                      disabled={currentPage >= drawingsWithSpots.length - 1}
                    >
                      <KeyboardArrowRight />
                    </Button> */}
                    <Button
                      name="next"
                      className="btn-switch"
                      size="small"
                      onClick={e => {
                        this.onSwitchDrawing("next");
                        e.preventDefault();
                      }}
                      disabled={this.handleDisableSwitch("next")}
                    >
                      <KeyboardArrowRight />
                    </Button>
                  </Grid>
                )}
              </Grid>
              {this.props.modeDevice === MODE_DEVICE.PC && (
                <Grid
                  container
                  spacing={0}
                  justify="space-between"
                  className="container-mobile-header mb-5 drawing-dialog-footer"
                >
                  <Grid item xs={2} className="text-center"></Grid>
                  <Grid item xs={8} className="drawing-dialog-title ">
                    {typeDialog === TYPE_DIALOG.ADJUST_IMAGE && (
                      <p>この写真を登録する撮影ポイントを選択してください</p>
                    )}
                  </Grid>
                  <Grid item xs={2} className="text-right ">
                    {typeDialog != TYPE_DIALOG.ADJUST_IMAGE && (
                      <Fab
                        onClick={() => {
                          !this.props.isPreventMovingToLWViewer &&
                            this.handleShowPanelAdjust();
                        }}
                        size="small"
                        disabled={!historyListAdjustPhoto.length}
                        style={{
                          cursor: this.props.isPreventMovingToLWViewer ? "default" : "poiter",
                          backgroundColor: this.props.isPreventMovingToLWViewer ? "white" : "",
                        }}
                        disableRipple={this.props.isPreventMovingToLWViewer}
                      >
                        <ControlCameraIcon />
                      </Fab>
                    )}
                  </Grid>
                </Grid>
              )}
            </DialogContent>
          </div>
        )}
        {this.props.modeDevice === MODE_DEVICE.SP &&
          screenOrientation === "portrait" && (
            <Grid
              container
              spacing={0}
              justify="space-between"
              className="container-mobile-header mb-5 drawing-dialog-footer"
            >
              <Grid
                item
                xs={12}
                className="drawing-dialog-title text-color-white"
              >
                {typeDialog === TYPE_DIALOG.ADJUST_IMAGE && (
                  <p>この写真を登録する撮影ポイントを選択してください</p>
                )}
              </Grid>
              {typeDialog != TYPE_DIALOG.ADJUST_IMAGE && (
                <Grid container spacing={0} justify="flex-end">
                  <Fab
                    onClick={() => {
                      !this.props.isPreventMovingToLWViewer &&
                        this.handleShowPanelAdjust();
                    }}
                    size="small"
                    disabled={!historyListAdjustPhoto.length}
                    style={{
                      cursor: this.props.isPreventMovingToLWViewer ? "default" : "poiter",
                    }}
                    disableRipple={this.props.isPreventMovingToLWViewer}
                  >
                    <ControlCameraIcon />
                  </Fab>
                </Grid>
              )}
            </Grid>
          )}
        <Menu
          className="menu-list"
          id="simple-menu"
          anchorEl={showList}
          getContentAnchorEl={null}
          open={Boolean(showList)}
          onClose={this.closeListDraw}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              // border: "2px solid #162f50",
              color: "#162f50",
              borderRadius: 0,
              paddingRight: 10
            }
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          {drawingsWithSpots &&
            drawingsWithSpots.map(item => (
              // <Grid className="menu-item" key={item.uniqueCode}>
              //   <Grid className="item-icon">
              //     {drawing.uniqueCode === item.uniqueCode && (
              //       <RoomIcon className="icon-selected" />
              //     )}
              //   </Grid>
              //   <MenuItem
              //     key={item.uniqueCode}
              //     selected={drawing.uniqueCode === item.uniqueCode}
              //     title={item.name}
              //     onClick={() =>
              //       this.handleSwitchDrawing(item.uniqueCode)
              //     }
              //   >
              //     <Typography className="item-name">
              //       {item.name}
              //     </Typography>
              //   </MenuItem>
              // </Grid>

              <MenuItem
                className="menu-item"
                key={item.uniqueCode}
                selected={drawing.uniqueCode === item.uniqueCode}
                title={item.name}
                onClick={() => this.handleSwitchDrawing(item.uniqueCode)}
              >
                <Grid key={item.uniqueCode} className="menu-item-container">
                  <Grid className="item-icon">
                    {drawing.uniqueCode === item.uniqueCode && (
                      <RoomIcon className="icon-selected" />
                    )}
                  </Grid>
                  <Grid className="item-text">
                    <Typography className="item-name">{item.name}</Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
        </Menu>
        {/* SP - Landscape view */}

        {this.props.modeDevice === MODE_DEVICE.SP &&
          screenOrientation === "landscape" && (
            <Grid
              container
              spacing={0}
              className="drawing-dialog-container-landscap"
            >
              <Grid item xs={2}>
                <Grid
                  container
                  direction="column"
                  justify="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid
                    md={6}
                    alignItems="center"
                    container
                    item
                    className="position-btn"
                  >
                    {typeDialog === TYPE_DIALOG.ZOOM_IMAGE &&
                      this.props.modeDevice === MODE_DEVICE.SP && (
                        <Tooltip
                          title="Exit"
                          className="DialogViewer-btn-header-sp"
                        >
                          <Fab onClick={this.handleCloseDialog} size="small">
                            <ArrowBackIcon />
                          </Fab>
                        </Tooltip>
                      )}
                    {typeDialog === TYPE_DIALOG.ADJUST_IMAGE &&
                      this.props.modeDevice === MODE_DEVICE.SP && (
                        <Fab onClick={this.handleCloseDialog} size="small">
                          <ArrowBackIcon />
                        </Fab>
                      )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} className="">
                <DialogContent className="drawing-dialog-content-landscap">
                  <Grid
                    container
                    direction="column"
                    justify="flex-end"
                    alignItems="center"
                    className=""
                  >
                    <Grid
                      container
                      item
                      className="drawing-dialog-bg viewer-dialog-title dialog-viewer-header-title"
                    >
                      <Grid
                        container
                        spacing={0}
                        justify="space-between"
                        className="container-mobile-header"
                      >
                        <Grid item xs={2} className="text-center position-btn">
                          <Button
                            name="prev"
                            className="btn-switch "
                            size="small"
                            onClick={() => this.onSwitchDrawing("prev")}
                            disabled={this.handleDisableSwitch("prev")}
                          >
                            <KeyboardArrowLeftIcon />
                          </Button>
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          className="viewer-dialog-title dialog-viewer-header-title"
                        >
                          <Typography variant="h6">
                            {this.state.drawingName}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} className="text-center position-btn">
                          <Button
                            name="next"
                            className="btn-switch"
                            size="small"
                            onClick={() => this.onSwitchDrawing("next")}
                            disabled={this.handleDisableSwitch("next")}
                          >
                            <KeyboardArrowRight />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      className="viewer-content-dialog-update  drawing-dialog-bg"
                    >
                      <div
                        className={`text-center 
                    ${
                      this.props.modeDevice !== MODE_DEVICE.SP
                        ? "content-drawing-dialog"
                        : "content-drawing-dialog-sp"
                    } `}
                      >
                        <TransformWrapper defaultScale={1}>
                          <TransformComponent ref={this.transformComponentRef}>
                            <div
                              className={`img-mini-parent-dialog-sp ${
                                typeDialog === TYPE_DIALOG.ADJUST_IMAGE &&
                                this.props.modeDevice !== MODE_DEVICE.SP
                                  ? "background-gray-anpha95"
                                  : ""
                              } `}
                            >
                              <DrawingPhoto
                                onClick={this.tapToShowFullImage}
                                class="img-mini-dialog"
                                id="primary-image-dialog"
                                alt=""
                                drawingsUC={drawingCode}
                                blob={drawingBlob}
                                loadMarker={this.setPositionMarker}
                                apiPath={urlPhoto}
                                isLogWalkViewer={true}
                                spotCurrent={spotCurrent}
                                imageShootUnAdjusted={imageShootUnAdjusted}
                                checkActiveSpot={this.checkActiveSpot}
                                chooseShootSpot={this.handleAddImageNotAdjusted}
                                positions={this.state.positions}
                                typeDialog={typeDialog}
                                isPreventMovingToLWViewer={
                                  this.props.isPreventMovingToLWViewer
                                }
                              />
                              {/* <img
                                className="img-mini-dialog"
                                id="primary-image-dialog"
                                src={urlPhoto}
                                onLoad={this.setPositionMarker}
                                alt=""
                              />
                              {this.state.positions.map((pos, idx) => (
                                <div key={pos.uniqueCode}>
                                  <div className="relative">
                                    {typeDialog === TYPE_DIALOG.ZOOM_IMAGE && (
                                      <FiberManualRecordIcon
                                        stroke={"black"}
                                        strokeWidth={1}
                                        className={`marker ${
                                          this.checkActiveSpot(pos.uniqueCode)
                                            ? "icon-spot"
                                            : "icon-spot-unactive"
                                        }`}
                                        onClick={() =>
                                          this.handleAddImageNotAdjusted(
                                            pos.uniqueCode
                                          )
                                        }
                                        style={{
                                          left: pos["posX"],
                                          top: pos["posY"]
                                        }}
                                      />
                                    )}
                                    {typeDialog ===
                                      TYPE_DIALOG.ADJUST_IMAGE && (
                                      <FiberManualRecordIcon
                                        stroke={"black"}
                                        strokeWidth={1}
                                        className={`marker ${
                                          this.checkActiveSpot(pos.uniqueCode)
                                            ? "icon-spot-logwalkViewer"
                                            : "icon-spot-unactive-logwalkViewer"
                                        }`}
                                        onClick={() =>
                                          this.handleAddImageNotAdjusted(
                                            pos.uniqueCode
                                          )
                                        }
                                        style={{
                                          left: pos["posX"],
                                          top: pos["posY"]
                                        }}
                                      />
                                    )}

                                    {pos.uniqueCode === spotCurrent &&
                                      typeDialog === TYPE_DIALOG.ZOOM_IMAGE && (
                                        <RoomIcon
                                          className="icon-spot-current marker-selected"
                                          style={{
                                            left: pos["posX"],
                                            top: pos["posY"]
                                          }}
                                        />
                                      )}
                                  </div>
                                </div>
                              ))} */}
                            </div>
                          </TransformComponent>
                        </TransformWrapper>
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Grid>
              <Grid item xs={2}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  style={{ height: "100%" }}
                >
                  <Grid
                    container
                    item
                    className="position-btn justify-content-flex-start"
                    alignItems="center"
                    style={{ height: "50%" }}
                  >
                    <Fab
                      className={"btn-choose-dialog-viewer-header"}
                      size="small"
                      aria-controls="simple-menu"
                      onClick={this.showListDraw}
                    >
                      <MenuIcon />
                    </Fab>
                  </Grid>

                  <Grid
                    container
                    alignItems="center"
                    item
                    className="position-btn justify-content-flex-end"
                    style={{ height: "50%" }}
                  >
                    {typeDialog != TYPE_DIALOG.ADJUST_IMAGE && (
                      <Fab
                        onClick={() => {
                          !this.props.isPreventMovingToLWViewer &&
                            this.handleShowPanelAdjust();
                        }}
                        size="small"
                        disabled={!historyListAdjustPhoto.length}
                        style={{
                          cursor: this.props.isPreventMovingToLWViewer ? "default" : "poiter",
                        }}
                        disableRipple={this.props.isPreventMovingToLWViewer}
                      >
                        <ControlCameraIcon />
                      </Fab>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item className="drawing-dialog-title">
                {typeDialog === TYPE_DIALOG.ADJUST_IMAGE && (
                  <p className="text-color-white dialog-title-landscap-w100">
                    この写真を登録する撮影ポイントを選択してください
                  </p>
                )}
              </Grid>
            </Grid>
          )}
      </Dialog>
    );
  }
}

export default compose(withRouter)(DialogViewer);
