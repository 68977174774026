import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Drawer, Grid, Paper } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import SearchIcon from "@material-ui/icons/Search";

import CloseButton from "./CloseButton";
import FilterInfoBox from "./FilterInfoBox";
import InfoTagListBox from "./InfoTagListBox";

import { api } from "../../common/api/api";
import {
  setRedirectFromUrl,
  saveConditionHotSpotInfoSuccess
} from "../sites/site/action/action";
import { enableLoading, finishLoading } from "common/Stores/service/service";
const mediaQueryTabletOrMobile = "(max-width: 960px)";
const allStatus = [
  { uniqueCode: "all", label: "すべて" },
  { uniqueCode: "incomplete", label: "未完了" },
  { uniqueCode: "complete", label: "完了" }
];

function ListInfoHotspots (props) {
  const [filterTitle, setFilterTitle] = useState("");
  const [filterSiteName, setFilterSiteName] = useState("");

  const [allIcons, setAllIcons] = useState([]);
  const [filterIcon, setFilterIcon] = useState(null);

  const [filterStatus, setFilterStatus] = useState(null);

  const [allAuthors, setAllAuthors] = useState([]);
  const [filterAuthor, setFilterAuthor] = useState(null);

  const [allHotspots, setAllHotspots] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const [hotspotToShow, setHotspotToShow] = useState(null);
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [loadInit, setLoadInit] = useState(true);

  const [openFilterInfoBox, setOpenFilterInfoBox] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const conditionSelector = useSelector(
    state => state.rootSitesReducer.siteInfoReducer.conditionHotSpotInfo
  );

  const redirectFromUrl = useSelector(
    state => state.rootSitesReducer.siteInfoReducer.redirectFromUrl
  );
  const isTabletOrMobile = useMediaQuery({ query: mediaQueryTabletOrMobile });

  useEffect(() => {
    dispatch(enableLoading());
    setLoadInit(true);
  
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    const fetchAllColors = api.get(`/${classification}/info-icons`);

    const fetchAllHotspots = api.get(`/builder/hotspots`);

    const fetchAllHotspotNotifications = api.get(
      `/builder/hotspotnotifications`
    );

    Promise.all([
      fetchAllColors,
      fetchAllHotspots,
      fetchAllHotspotNotifications
    ]).then(res => {
      let tempAllIcons = res[0].data;

      tempAllIcons.unshift({ uniqueCode: "all" });
      setAllIcons(tempAllIcons);
      setAllHotspots(res[1].data); // Thoai
      setAllNotifications(res[2].data);
      setAllAuthors(getAllAuthorFilter(res[1].data));

      if (
        redirectFromUrl &&
        redirectFromUrl.isRedirectFromUrl &&
        redirectFromUrl.from.includes("/info-hotspots") &&
        conditionSelector
      ) {
        setFilterTitle(conditionSelector.title || "");

        setFilterSiteName(conditionSelector.siteName || "");

        setFilterIcon(conditionSelector.icon || { uniqueCode: "all" });

        setFilterAuthor(conditionSelector.author || { uniqueCode: "all" });

        setFilterStatus(
          conditionSelector.status || {
            uniqueCode: "incomplete",
            label: "未完了"
          }
        );

        dispatch(
          setRedirectFromUrl({
            isRedirectFromUrl: false,
            isDialog: false,
            isCardList: true,
            currentDrawingUC: null,
            from: ""
          })
        );
      } else {
        setFilterIcon({ uniqueCode: "all" });
        setFilterAuthor({ uniqueCode: "all" });
        setFilterStatus({ uniqueCode: "incomplete", label: "未完了" });
      }
      dispatch(finishLoading());
      setLoadInit(false);
    });

    dispatch(
      setRedirectFromUrl({
        isRedirectFromUrl: true,
        from: `/info-hotspots`
      })
    );
  }, []);

  useEffect(() => {
    if(!loadInit) {
      setHotspotToShow(filterHotspot());
    }
  }, [
    allHotspots,
    filterTitle,
    filterSiteName,
    filterIcon,
    filterStatus,
    filterAuthor,
    loadInit
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [paginationNumber]);
  
  const filterHotspot = () => {
    let tempShowHotspot = allHotspots;
    setPaginationNumber(1);

    if (filterTitle !== "") {
      tempShowHotspot = tempShowHotspot.filter(item =>
        item.info.title.includes(filterTitle)
      );
    }

    if (filterSiteName !== "") {
      tempShowHotspot = tempShowHotspot.filter(item =>
        item.site.name.includes(filterSiteName)
      );
    }

    if (filterIcon && filterIcon.uniqueCode !== "all") {
      tempShowHotspot = tempShowHotspot.filter(
        item => item.icon.uniqueCode === filterIcon.uniqueCode
      );
    }

    if (filterStatus && filterStatus.uniqueCode !== "all") {
      if (filterStatus.uniqueCode === "incomplete") {
        tempShowHotspot = tempShowHotspot.filter(
          item => !item.info.completedAt
        );
      } else if (filterStatus.uniqueCode === "complete") {
        tempShowHotspot = tempShowHotspot.filter(item => item.info.completedAt);
      }
    }

    if (filterAuthor && filterAuthor.uniqueCode !== "all") {
      tempShowHotspot = tempShowHotspot.filter(
        item => item.info.createdByUniqueCode + item.info.createdBy === filterAuthor.uniqueCode
      );
    }

    return tempShowHotspot;
  };

  const reloadAllHotspots = () => {
    dispatch(enableLoading());

    const fetchAllHotspots = api.get(`/builder/hotspots`);

    const fetchAllHotspotNotifications = api.get(`/builder/hotspotnotifications`);

    Promise.all([ fetchAllHotspots, fetchAllHotspotNotifications]).then(res => {
      setAllHotspots(res[0].data);
      setAllNotifications(res[1].data);
      setAllAuthors(getAllAuthorFilter(res[0].data));
      dispatch(finishLoading());
    })
  };

  const getAllAuthorFilter = filterData => {
    const tempAllAuthorFilter = [{ uniqueCode: "all" }];
    filterData.forEach(item => {
      if (
        !tempAllAuthorFilter.find(
          author => author.uniqueCode === item.info.createdByUniqueCode + item.info.createdBy
        )
      ) {
        tempAllAuthorFilter.push({
          uniqueCode: item.info.createdByUniqueCode + item.info.createdBy,
          name: item.info.createdBy
        });
      }
    });
    return tempAllAuthorFilter;
  };

  const resetFilter = () => {
    setFilterTitle("");
    setFilterSiteName("");
    setFilterIcon({ uniqueCode: "all" });
    setFilterAuthor(allAuthors.find(member => member.uniqueCode === "all"));
    setFilterStatus({ uniqueCode: "incomplete", label: "未完了" });
  };

  const saveCondition = () => {
    dispatch(
      saveConditionHotSpotInfoSuccess({
        title: filterTitle,
        siteName: filterSiteName,
        icon: filterIcon,
        status: filterStatus,
        author: filterAuthor
      })
    );
  };
  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <React.Fragment>
          <Drawer
            anchor="bottom"
            open={openFilterInfoBox}
            onClose={() => setOpenFilterInfoBox(false)}
            
          >
            <React.Fragment>
              <CloseButton handleClose={() => setOpenFilterInfoBox(false)} />
              <div style={{ padding: "30px 38px"}}>
                <FilterInfoBox
                  allIcons={allIcons}
                  allAuthors={allAuthors}
                  allStatus={allStatus}
                  filterTitle={filterTitle}
                  setFilterTitle={setFilterTitle}
                  filterSiteName={filterSiteName}
                  setFilterSiteName={setFilterSiteName}
                  filterIcon={filterIcon}
                  setFilterIcon={setFilterIcon}
                  filterStatus={filterStatus}
                  setFilterStatus={setFilterStatus}
                  filterAuthor={filterAuthor}
                  setFilterAuthor={setFilterAuthor}
                  resetFilter={resetFilter}
                  showModeSP
                />
              </div>
            </React.Fragment>
          </Drawer>

          <Fab
            className={`button-add-sp`}
            onClick={() => setOpenFilterInfoBox(true)}
          >
            <span
              style={{
                fontSize: "32px",
                lineHeight: "32px",
                color: "white",
                marginTop: "4px"
              }}
            >
              <SearchIcon fontSize="inherit" color="inherit" />
            </span>
          </Fab>
        </React.Fragment>
      ) : (
        <div style={{ width: "100%" }}>
          <Paper

            style={{ boxShadow:'0px 3px 6px #00000029', borderRadius:'0', padding: isTabletOrMobile ? "20px 18px" : "20px 38px" }}
            elevation={3}
          >
            <FilterInfoBox
              allIcons={allIcons}
              allAuthors={allAuthors}
              allStatus={allStatus}
              filterTitle={filterTitle}
              setFilterTitle={setFilterTitle}
              filterSiteName={filterSiteName}
              setFilterSiteName={setFilterSiteName}
              filterIcon={filterIcon}
              setFilterIcon={setFilterIcon}
              filterStatus={filterStatus}
              setFilterStatus={setFilterStatus}
              filterAuthor={filterAuthor}
              setFilterAuthor={setFilterAuthor}
              resetFilter={resetFilter}
              showModeSP={isTabletOrMobile}
            />
          </Paper>
        </div>
      )}

      <div
        style={{
          boxShadow:'rgb(0 0 0 / 4%) -4px -4px 4px',
          padding: isTabletOrMobile ? "20px 18px" : "20px 38px",
          marginTop: isTabletOrMobile ? "0px" : "20px",
        }}
      >
        <h2
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "#00356D"
          }}
        >
          タスク一覧
        </h2>
        <Grid container style={{ marginTop: "20px" }}>
          <InfoTagListBox
            hostpotInfos={hotspotToShow}
            paginationNumber={paginationNumber}
            setPaginationNumber={setPaginationNumber}
            allNotifications={allNotifications}
            isTabletOrMobile={isTabletOrMobile}
            reloadAllHotspots={reloadAllHotspots}
            allHotspots={allHotspots}
            showModeSP={isTabletOrMobile}
            saveCondition={saveCondition}
          />
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default ListInfoHotspots;
