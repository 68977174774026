import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

import HidePanelPC from "./common/img/hidepanel_PC.svg";
import HidePanelSP from "./common/img/hidepanel_SP.svg";

import { closeHotspotInfo } from "../../action/action";

const mediaQueryTabletOrMobile = "(max-width: 960px)";

const MIN_WIDTH_DRAWER = 550; // 500 + 50

const useStyles = makeStyles({
  drawerPaperSp: {
    width: "100%",
    backgroundColor: "white",
    "& .hide-panel-image": {
      width: "20px",
      height: "100px",
      position: "absolute",
      top: "50%",
      right: "0px",
      marginTop: "-50px",
      zIndex: "2"
    },
    "& .panel-content": {
      width: "100%",
      height: "100%"
    }
  },

  drawerPaperPc: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    border: "none",
    "& .hide-panel-image": {
      width: "45px",
      height: "130px",
      position: "absolute",
      top: "50%",
      right: "0px",
      marginTop: "-65px"
    },
    "& .panel-content": {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      marginRight: "50px",
      border: "none",
      boxSizing: "border-box",
      backgroundColor: "white",
      fontFamily: "Roboto",
      position: "relative",
      overflowY: "auto"
    }
  },

  flexibleWidth: {
    width: "6px",
    height: "100%",
    background: "linear-gradient(to right, #545454, #FFFFFF)",
    position: "absolute",
    top: "0",
    right: "44px",
    zIndex: "1",
    cursor: "col-resize"
  }
});

function FlexibleWidthDrawer(props) {
  const { children, displayNone } = props;
  const classes = useStyles();

  const [isResizing, setIsResizing] = useState(false);
  const [drawerPcWidth, setDrawerPcWidth] = useState(MIN_WIDTH_DRAWER);

  const isTabletOrMobile = useMediaQuery({ query: mediaQueryTabletOrMobile });
  const dispatch = useDispatch();
  const open = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.isOpenHotspotInfo
  );

  return (
    <React.Fragment>
      <Drawer
        variant="persistent"
        open={open}
        anchor={"left"}
        onClose={() => {}}
        classes={{
          paper: isTabletOrMobile
            ? classes.drawerPaperSp
            : classes.drawerPaperPc
        }}
        PaperProps={{
          id:"hotspot-info",
          style: isTabletOrMobile
            ? { height: "100%", width: "100%", touchAction: "manipulation"}
            : { height: "100%", width: drawerPcWidth + "px", touchAction: "manipulation" }
        }}
        style={{display: displayNone ? "none" : "flex"}}
      >
        <div
          onMouseMove={e => {
            if (isResizing) {
              if (e.clientX > 500 && e.clientX < 900) {
                setDrawerPcWidth(e.clientX + 50);
              }
              e.preventDefault();
            }
          }}
          onMouseUp={() => setIsResizing(false)}
          onMouseLeave={() => setIsResizing(false)}
          style={{ width: "100%", height: "100%" }}
        >
          <div className="panel-content">{children}</div>

          {!isTabletOrMobile ? (
            <div
              className={classes.flexibleWidth}
              onMouseDown={() => {
                setIsResizing(true);
              }}
              onMouseUp={() => setIsResizing(false)}
            />
          ) : null}

          <img
            onClick={(e) => {
              dispatch(closeHotspotInfo());
              e.preventDefault();
            }}
            src={isTabletOrMobile ? HidePanelSP : HidePanelPC}
            className="hide-panel-image"
            alt=''
          />
        </div>
      </Drawer>
    </React.Fragment>
  );
}

FlexibleWidthDrawer.propTypes = {};

export default FlexibleWidthDrawer;
