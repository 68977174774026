import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={26}
            height={26}
            viewBox="0 0 26 26"
            {...props}
        >
            <path
                fill={props.color || "#00356D"}
                d="M18.654 6.25h-2.962v-.562A1.655 1.655 0 0014.077 4h-2.154a1.655 1.655 0 00-1.615 1.688v.562H7.346A1.379 1.379 0 006 7.656v1.969a.551.551 0 00.538.563h.294L7.3 20.393A1.646 1.646 0 008.911 22h8.177a1.646 1.646 0 001.612-1.607l.465-10.205h.294A.551.551 0 0020 9.625V7.656a1.379 1.379 0 00-1.346-1.406zm-7.269-.562a.552.552 0 01.538-.563h2.154a.552.552 0 01.538.563v.562h-3.23zM7.077 7.656a.276.276 0 01.269-.281h11.308a.276.276 0 01.269.281v1.407H7.077zm10.549 12.683a.549.549 0 01-.538.536H8.911a.549.549 0 01-.538-.536l-.462-10.151h10.178z"
            />
            <path
                fill={props.color || "#00356D"}
                d="M13 19.637a.591.591 0 00.591-.591v-7.677a.591.591 0 00-1.181 0v7.677a.591.591 0 00.59.591zM15.671 19.637a.591.591 0 00.591-.591v-7.677a.591.591 0 10-1.181 0v7.677a.591.591 0 00.59.591zM10.33 19.637a.591.591 0 00.591-.591v-7.677a.591.591 0 00-1.181 0v7.677a.591.591 0 00.59.591z"
            />
            <path fill="none" d="M0 0h26v26H0z" />
        </svg>
    )
}

export default SvgComponent
