import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconInfo from "./hotspotInfoIcons/IconInfo";
import Popover from "@material-ui/core/Popover";
import { api } from "../../../../../common/api/api";
import DoneIcon from "@material-ui/icons/Done";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CloseIcon from "../../../../../common/img/close.svg";
import SelectIcon from "../../../../../common/img/selecticon.svg";

const useStyles = makeStyles({
  buttonShow: {
    width: "32px",
    height: "32px",
    borderRadius: "4px",
    marginRight: "8px",
    overflow: "hidden",
    background: `url(${SelectIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "52px 52px",
    backgroundPosition: "-10px -10px"
  },

  colorItem: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "100%",
    boxSizing: "border-box",
    padding: "0px",
    cursor: "pointer",
    userSelect: "none",
    "& p": {
      marginLeft: "14px",
      fontSize: "15px",
      boxSizing: "border-box",
      lineHeight: "40px",
      borderTop: "1px solid #888888",
      flexGrow: "1"
    },
    "& p.first-item": {
      borderTop: "none"
    }
  },

  loadding: {
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },

  customScrollbar: {
    "&::-webkit-scrollbar": {
      width: "16px"
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#babac0",
      borderRadius: "16px",
      border: "4px solid rgba(229, 229, 229, 0.92)",
      cursor: "pointer"
    },
    "&::-webkit-scrollbar-button": {
      display: "none"
    }
  }
});

function ChooseInitialColor(props) {
  const classes = useStyles();
  const { handleChooseColor, newHostpotInfoColor } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [allColors, setAllColors] = useState([]);
  const [loadingColor, setLoadingColor] = useState(false);

  const buildersUC = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.logWalkInfo.buildersUC
  );

  const handleShow = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let location = useLocation();

  useEffect(() => {
    let cancelSubmission = false;

    if (buildersUC) {
      setLoadingColor(true);
      const classification = location.pathname.includes("/sp/")
        ? "supplier"
        : "builder";

      api
        .get(
          `/${classification}/logwalk-viewer/builders/${buildersUC}/info-icons`
        )
        .then(res => {
          if (!cancelSubmission) {
            setAllColors(res.data);
            setLoadingColor(false);
          }
        });
    }

    return () => {
      cancelSubmission = true;
    };
  }, [buildersUC]);

  return (
    <React.Fragment>
      <button
        onClick={handleShow}
        className={`${classes.buttonShow} no-select`}
        // style={!Boolean(anchorEl) ? { border: "1px solid black" } : null}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            padding: "12px 12px 8px 10px",
            minWidth: "268px",
            minHeight: "150px",
            backgroundColor: "rgba(0, 0, 0, 0)",
            boxShadow: "none",
            height: "100vh",
            maxHeight: "202px",
            overflowY: "hidden"
          }
        }}
      >
        <span
          style={{
            position: "absolute",
            display: "block",
            top: "0px",
            right: "0px",
            width: "22px",
            height: "22px",
            borderRadius: "50%",
            overflow: "hidden",
            cursor: "pointer"
          }}
          onClick={handleClose}
        >
          <img src={CloseIcon} width="22px" height="22px" />
        </span>
        <div
          style={{
            padding: "11px 15px",
            borderRadius: "10px",
            backgroundColor: "#e5e5e5eb",
            height: "100%",
            overflowY: "auto",
            display: "block"
          }}
          className={classes.customScrollbar}
        >
          {loadingColor ? (
            <span className={classes.loadding}>
              <CircularProgress />
            </span>
          ) : (
            allColors.map((color, index) => (
              <div
                key={color.uniqueCode}
                className={classes.colorItem}
                onClick={() => {
                  handleChooseColor(color);
                  setAnchorEl(null);
                }}
              >
                <IconInfo color={color.color} width="25" height="25" />
                <p className={index === 0 ? "first-item" : ""}>
                  <span>{color.label}</span>
                  {color.uniqueCode === newHostpotInfoColor?.uniqueCode ? (
                    <span
                      style={{
                        float: "right",
                        width: "24px",
                        height: "24px",
                        marginTop: "6px"
                      }}
                    >
                      <DoneIcon color="primary" />
                    </span>
                  ) : null}
                </p>
              </div>
            ))
          )}
        </div>
      </Popover>
    </React.Fragment>
  );
}

export default ChooseInitialColor;
