import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Button, Fab } from "@material-ui/core";
import clock_icon from "../../common/img/clock_icon.svg"
import pen_icon from "../../common/img/pen_icon.svg"
import add_icon from "../../common/img/add_icon.svg"
import site_icon from "../../common/img/site_icon.svg"
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { enableLoading, finishLoading } from "common/Stores/service/service";
import { api } from "../../common/api/api";
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import PhoneIcon from "@material-ui/icons/Phone";
import logmeetConfig from "../../common/config/LogmeetConfig";
import { configStorage } from "../../common/storage/storage";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import LogMeetPhoto from "./LogMeetPhoto";

import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import EditIcon from "@material-ui/icons/Edit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import axios from "axios";
import { saveAs } from "file-saver";
import EditImageDialog from "../sites/site/editImageDialog";

function LogMeetHistoryDetail(props) {
  const { historyDetail, toggleHistoryDetailModal, sitesUC, sites } = props;
  const [title, setTitle] = useState("");
  const [isFocusTitle, setIsFocusTitle] = useState(false);
  const [isFocusMemo, setIsFocusMemo] = useState(false);
  const [isShowMemo, setIsShowMemo] = useState(false);
  const [isShowSites, setIsShowSites] = useState(false);
  const [memo, setMemo] = useState("");
  const [baseHistory, setBaseHistory] = useState(null);
  const [siteData, setSiteData] = useState(null);
  const [imagesArr, setImagesArr] = useState([]);
  const [sitesFilter, setSitesFilter] = useState([]);
  const [receiver, setReceiver] = useState(null);
  const [currentUserUniqueCode, setCurrentUserUniqueCode] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const [isDialogShowImage, setIsDialogShowImage] = useState(false);
  const [isDialogShowImageEdit, setIsDialogShowImageEdit] = useState(false);
  const [isDialogDeleteShow, setIsDialogDeleteShow] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [currentPhoto, setCurrentPhoto] = useState({});
  const [imagesArrLoaded, setImagesArrLoaded] = useState([]);

  useEffect(() => {
    const token = api.defaults.headers.common["Authorization"];
    const decoded = jwt_decode(token);
    setCurrentUserUniqueCode(decoded.uniqueCode);
    fetchHistories();
    setSitesFilter(sites);
    return () => {
    }
  }, []);

  const filterSites = (value) => {
    setSitesFilter(value ? sites.filter(item => item.name.includes(value)) : sites);
  }

  const fetchHistories = () => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/talk/${historyDetail.uid}`)
      .then(res => {
        setBaseHistory(res.data);
        setTitle(res.data.title);
        setSiteData(res.data.site);
        setMemo(res.data.memo);
        if (res.data.memo) {
          setIsShowMemo(true);
        }
        setImagesArr(res.data.images);
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  };

  const updateTitle = () => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .patch(`/${classification}/logmeet/talk/${historyDetail.uid}`, {
        title: title
      })
      .then(res => {
        setBaseHistory(res.data);
        setTitle(res.data.title);
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  };

  const updateMemo = () => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .patch(`/${classification}/logmeet/talk/${historyDetail.uid}`, {
        memo: memo
      })
      .then(res => {
        setBaseHistory(res.data);
        setMemo(res.data.memo);
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  };

  const days = ["日", "月", "火", "水", "木", "金", "土"];
  const diffMinutes = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  const uploadPhoto = (event) => {
    const element = event.target;
    const files = element.files;
    const exts = element.accept.split(',').map(ext => ext.toLowerCase());
    var containsInvalidMimeType = false;
    for (var i = 0; i < files.length; i++) {
      var file = files[i];
      containsInvalidMimeType = !exts.some(ext => file.name.toLowerCase().endsWith(ext));
      if (containsInvalidMimeType) {
        break;
      }
    }
    if (containsInvalidMimeType) {
      alert("無効なファイル形式を含んでいます");
      return;
    }
    const formData = new FormData();
    formData.append("postedAt", Date.now());
    for (var i = 0; i < files.length; i++) formData.append("file", files[i]);

    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .post(`/${classification}/logmeet/talk/${historyDetail.uid}/image`, formData)
      .then(res => {
        setImagesArr(res.data.images);
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
    element.value = "";
  }

  const registerSites = (site) => {
    setIsShowSites(false);
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .patch(`/${classification}/logmeet/talk/${historyDetail.uid}`, {
        siteUC: site.uid
      })
      .then(res => {
        setBaseHistory(res.data);
        dispatch(finishLoading());
        setSiteData(site);
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  }

  const toggleDialogImage = () => {
    setIsDialogShowImage(!isDialogShowImage)
  };

  const onImageClick = (photoId, name, url, postedAt) => {
    setCurrentPhoto({
      photoId: photoId,
      name: name,
      url: url,
      postedAt: postedAt
    })
    toggleDialogImage();
    const currentIndex = imagesArr.findIndex(
      photo => photo.uid === photoId
    );
    setCurrentPhotoIndex(currentIndex);
  };

  const handleKeyDown = event => {

    if ((event.keyCode === 37 && currentPhotoIndex <= 0) || (event.keyCode === 39 && currentPhotoIndex >= imagesArr.length - 1)) {
      return;
    }
    if (event.keyCode === 37) {
      handleChangeImage(false);
    }

    if (event.keyCode === 39) {
      handleChangeImage(true);
    }
  };

  const handleChangeImage = isNext => {
    const size = imagesArr.length;
    const currentIndex = imagesArr.findIndex(
      photo => photo.uid === currentPhoto.photoId
    );
    if (currentIndex !== -1) {
      let newIndex = 0;
      if (isNext) {
        newIndex = currentIndex + 1;
      } else {
        newIndex = currentIndex - 1;
      }
      const newPhoto = imagesArr[newIndex];
      const currentIndexLoaded = imagesArrLoaded.findIndex(
        photo => photo.id === newPhoto.uid
      );
      setCurrentPhotoIndex(newIndex);
      setCurrentPhoto({
        photoId: imagesArrLoaded[currentIndexLoaded].id,
        name: imagesArrLoaded[currentIndexLoaded].name ? imagesArrLoaded[currentIndexLoaded].name : `${imagesArrLoaded[currentIndexLoaded].postedAt}_image`,
        url: imagesArrLoaded[currentIndexLoaded].url,
        postedAt: imagesArrLoaded[currentIndexLoaded].postedAt,
      })
    }
  };

  const imageLoaded = (img) => {
    setImagesArrLoaded(currentArray => [...currentArray, img])
  }

  const downloadCurrentPhoto = () => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/talk/${historyDetail.uid}/image?file=${currentPhoto.photoId}`)
      .then(res => {
        if (res.data.blobData) {
          downloadBlob(res.data.blobData);
        }
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  }

  const downloadBlob = (blob) => {
    dispatch(enableLoading());
    axios
      .get(blob, {
        responseType: 'arraybuffer'
      })
      .then(res => {
        save(res.data, convertType(currentPhoto.name, "jpg"));
        dispatch(finishLoading());
      })
  }

  const save = (data, name) => {
    const file = new File([data], name);
    saveAs(file);
  };

  const convertType = (name, extension) => {
    const nameArray = name.split(".");
    nameArray.push(extension);
    const newName = nameArray.join(".");
    return newName;
  };

  const showOriginalPhoto = () => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/talk/${historyDetail.uid}/image?file=${currentPhoto.photoId}`)
      .then(res => {
        if (res.data.blobData) {
          var url = res.data.blobData
          var w = window.open('', "_blank");
          w.document.write(`<img src="${url}">`)
        } else {
          var blob = new Blob([res.data], { type: "image/jpg" });
          var url = URL.createObjectURL(blob);
          window.open(url, "_blank");
        }
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  }

  const toggleDialogDelete = () => {
    setIsDialogDeleteShow(!isDialogDeleteShow);
    setIsDialogShowImageEdit(false);
  };

  const openDialogDelete = () => {
    setIsDialogDeleteShow(true);
  };

  const closeEditImageDialogShow = () => {
    setIsDialogDeleteShow(false);
    setIsDialogShowImageEdit(false);
  };

  const openEditImageDialogShow = () => {
    setIsDialogDeleteShow(false);
    setIsDialogShowImageEdit(true);
  };

  const updateImageName = (id, values) => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .patch(`/${classification}/logmeet/talk/${historyDetail.uid}/image?file=${id}`,
        {
          name: values.name
        }
      )
      .then(res => {
        setImagesArr(res.data.images);
        closeEditImageDialogShow();
        toggleDialogImage();
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  }

  const deleteImages = (id) => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .delete(`/${classification}/logmeet/talk/${historyDetail.uid}/image?file=${id}`)
      .then(res => {
        fetchHistories();
        toggleDialogImage();
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  }

  return (
    <>
      <Grid container className="site-logmeet-tab-detail-container"
        style={{ padding: "0 24px" }}
      >
        <Grid item xs={12} style={{ display: "flex" }}>
          <span style={{
            fontFamily: "M PLUS 1",
            fontWeight: "normal",
            fontSize: "14px",
            textDecoration: "underline",
            cursor: "pointer",
            padding: "24px 0px"
          }}
            onClick={() => {
              // if (title != baseHistory.title || memo != baseHistory.memo) {
              //   window.alert("Unsave warning")
              // } else {
              //   toggleHistoryDetailModal(null)
              // }
              toggleHistoryDetailModal(null)
            }}
          > {`<通話一覧へ戻る`}</span>
        </Grid>
        {baseHistory && (
          <Grid item xs={12}
            style={{
              display: "flex",
              backgroundColor: "#F8F9F9",
              border: "solid 1px #C7C7C7",
              borderRadius: "8px",
              padding: "16px 24px",
              color: "#333333"
            }}
          >
            <Grid container>
              {siteData ?
                (
                  <>
                    <Grid item xs={12}
                      className="text-overflow-ellipsis"
                      style={{
                        fontFamily: "M PLUS 1",
                        fontWeight: "normal",
                        fontSize: "14px",
                        color: "#333333",
                        marginBottom: "18px"
                      }}
                    >
                      <img src={site_icon} />
                      &nbsp;
                      {siteData.name}
                    </Grid>
                  </>
                ) :
                (
                  <>
                    <Grid item xs={12}
                      style={{ position: "relative" }}
                    >
                      <Button
                        onClick={() => { setIsShowSites(true) }}
                        style={{
                          fontFamily: "Noto Sans JP",
                          fontWeight: "bold",
                          fontSize: "16px",
                          backgroundColor: "#162F50",
                          marginBottom: "18px",
                          color: "white",
                          width: "246px",
                          height: "40px",
                          borderRadius: "4px",
                          justifyContent: "space-between",
                          padding: "9px 12px"
                        }}>
                        <span>現場選択</span>
                        <AddIcon style={{ color: "white" }} />
                      </Button>
                      {isShowSites && (
                        <>
                          <div className="history-search-sites-container">
                            <Grid container>
                              <Grid item xs={12}
                                style={{
                                  backgroundColor: "#ECEDED",
                                  borderTopLeftRadius: "8px",
                                  borderTopRightRadius: "8px",
                                  borderBottomLeftRadius: "4px",
                                  borderBottomRightRadius: "4px",
                                  padding: "0 24px 0 4px",
                                  height: "32px",
                                  marginBottom: "12px"
                                }}
                              >
                                <input
                                  style={{
                                    border: "unset",
                                    height: "32px",
                                  }}
                                  placeholder="名前で検索"
                                  type="text"
                                  onChange={e => filterSites(e.target.value)}
                                />
                                <SearchIcon style={{
                                  color: "#9FA0A0",
                                  position: "absolute",
                                  right: "20px",
                                  top: "18px"
                                }} />
                              </Grid>
                              <div
                                style={{
                                  width: "100%",
                                  maxHeight: "350px",
                                  overflowY: "scroll"
                                }}
                              >
                                {sitesFilter && (sitesFilter.map((site, index) =>
                                (
                                  <Grid item xs={12}
                                    style={{
                                      borderBottom: index === sites.length - 1 ? "unset" : "solid 1px #C7C7C7",
                                      padding: "8px 0"
                                    }}
                                  >
                                    <Grid container>
                                      <Grid item xs={3}>
                                        <Button
                                          className="btn-select-sites-history"
                                          style={{
                                            backgroundColor: "#162F50",
                                            // width: "36px",
                                            minWidth: "36px",
                                            padding: "6px",
                                            color: "white",
                                            height: "22px",
                                            fontFamily: "Noto Sans JP",
                                            fontWeight: "normal",
                                            fontSize: "12px"
                                          }}
                                          onClick={() => {
                                            registerSites(site);
                                          }}
                                        >
                                          選択
                                        </Button>
                                      </Grid>
                                      <Grid item xs={9}
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          fontFamily: "Noto Sans JP",
                                          fontWeight: "normal",
                                          fontSize: "16px",
                                          color: "#333333"
                                        }}
                                      >
                                        <span>{site.name}</span>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )))}
                              </div>


                            </Grid>
                          </div>
                          <div className="history-search-sites-wrapper"
                            onClick={() => { setIsShowSites(false) }}
                          >
                          </div>
                        </>
                      )}
                    </Grid>

                  </>
                )
              }


              <Grid item xs={12}
                style={{
                  fontFamily: "M PLUS 1",
                  fontWeight: "normal",
                  fontSize: "14px",
                  color: "#333333",
                  marginBottom: "18px"
                }}
              >
                <img src={clock_icon} />
                {format(new Date(baseHistory.startAt), " yyyy年M月d日 ")}
                ({days[new Date(baseHistory.startAt).getDay()]}){" "}
                {format(new Date(baseHistory.startAt), "HH:mm 〜  ")}&nbsp;
                {diffMinutes(new Date(baseHistory.startAt), new Date(baseHistory.endAt))}分
              </Grid>
              <Grid item xs={1}
                style={{
                  fontFamily: "M PLUS 1",
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "#162F50"
                }}
              >
                タイトル
              </Grid>
              <Grid item xs={8}
                style={{
                  fontFamily: "M PLUS 1",
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "#162F50",
                  display: "flex",
                  position: "relative"
                }}
              >
                <input type="text"
                  style={{
                    height: "32px",
                    border: "solid 1px #F0F0F0",
                    borderRadius: "8px",
                    width: "100%",
                    backgroundColor: "white",
                    paddingLeft: (!title || title.length === 0) ? "20px" : "8px"
                  }}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  placeholder="タイトルを入力（12字まで）"
                  onFocus={() => { setIsFocusTitle(true) }}
                  onBlur={() => { setIsFocusTitle(false) }}
                  maxLength={12}
                />
                {(!title || title.length === 0) && (<img src={pen_icon}
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "3px",
                    zIndex: "1005"
                  }}
                />)}
              </Grid>
              <Grid item xs={3}
                style={{
                  fontFamily: "M PLUS 1",
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "#162F50",
                }}
              >
                {baseHistory && title != baseHistory.title && (
                  <Button
                    onClick={() => {
                      updateTitle();
                    }}
                    style={{
                      width: "72px",
                      height: "32px",
                      backgroundColor: "#162F50",
                      color: "white",
                      fontFamily: "Noto Sans JP",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginLeft: "24px"
                    }}
                  >保存</Button>
                )}

              </Grid>
              {(isShowMemo || (memo && memo.length > 0)) && (
                <>
                  <Grid item xs={1}
                    style={{
                      fontFamily: "M PLUS 1",
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#162F50"
                    }}
                  >
                    メモ
                  </Grid>
                  <Grid item xs={8}
                    style={{
                      fontFamily: "M PLUS 1",
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#162F50",
                      display: "flex",
                      position: "relative"
                    }}
                  >
                    <textarea type="text" style={{
                      height: "160px",
                      border: "solid 1px #F0F0F0",
                      borderRadius: "8px",
                      width: "100%",
                      backgroundColor: "white",
                      padding: (!memo || memo.length === 0) ? "4px 20px" : "4px 8px"
                    }}
                      maxLength={1000}
                      value={memo}
                      onChange={e => setMemo(e.target.value)}
                      placeholder="メモを入力（1000文字まで）"
                    />
                    {(!memo || memo.length === 0) && (<img src={pen_icon}
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "3px",
                        zIndex: "1005"
                      }}
                    />)}
                  </Grid>
                  <Grid item xs={3}
                    style={{
                      fontFamily: "M PLUS 1",
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#162F50",
                      position: "relative"
                    }}
                  >
                    {baseHistory && memo != baseHistory.memo && (
                      <Button
                        onClick={() => {
                          updateMemo();
                        }}
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          width: "72px",
                          height: "32px",
                          backgroundColor: "#162F50",
                          color: "white",
                          fontFamily: "Noto Sans JP",
                          fontSize: "14px",
                          fontWeight: "bold",
                          marginLeft: "24px"
                        }}
                      >保存</Button>
                    )}
                  </Grid>
                </>
              )}
              {(!isShowMemo && (!memo || memo.length === 0)) && (
                <>
                  <Grid item xs={9}
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      onClick={() => {
                        setIsShowMemo(true)
                      }}
                    >
                      <img src={add_icon} />
                      メモ追加
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                  </Grid>
                </>
              )}

              <Grid item xs={1}
                style={{
                  fontFamily: "M PLUS 1",
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "#162F50",
                  marginTop: "12px"
                }}
              >
                参加者
              </Grid>
              <Grid item xs={11}
                style={{
                  marginTop: "12px"
                }}
              >
                <Grid container>
                  {baseHistory && (baseHistory.joinUsers
                    .map(joinUsers => {
                      if (joinUsers) {
                        return (
                          <Grid item
                            className="text-overflow-ellipsis"
                            style={{
                              fontFamily: "M PLUS 1",
                              fontWeight: "normal",
                              fontSize: "14px",
                              color: "#333333",
                              borderRadius: "24px",
                              backgroundColor: "white",
                              height: "36px",
                              padding: "4px 12px",
                              border: "solid 1px #F0F0F0",
                              marginRight: "8px",
                              cursor: currentUserUniqueCode === joinUsers.uid ? "unset" : "pointer",
                              maxWidth: `${80 / baseHistory.joinUsers.length}%`
                            }}
                            onClick={() => {
                              if (currentUserUniqueCode != joinUsers.uid) {
                                setReceiver(joinUsers);
                              }
                            }}
                          >
                            {currentUserUniqueCode != joinUsers.uid && (
                              <>
                                <Fab
                                  size="small"
                                  style={{ backgroundColor: "#162F50", width: "24px", height: "24px", minHeight: "24px" }}
                                  className="more-vert-btn"
                                >
                                  <PhoneIcon style={{ color: "white", width: "16px", height: "16px" }} />
                                </Fab>
                                &nbsp;
                              </>
                            )}
                            <span>{joinUsers.name}</span>
                          </Grid>
                        )
                      }
                    }))}
                </Grid>
              </Grid>
              <Grid item xs={6}
                style={{
                  fontFamily: "M PLUS 1",
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "#162F50",
                  marginTop: "12px"
                }}
              >
                写真
              </Grid>

              <Grid item xs={6}
                style={{
                  marginTop: "12px",
                  textAlign: "right"
                }}
              >
                <input
                  type="file"
                  id="file"
                  accept=".jpg,.jpeg,.png"
                  // multiple
                  onChange={e => uploadPhoto(e)}
                  style={{ display: "none" }}
                />
                <Button
                >
                  <AddIcon style={{ color: "#162F50" }} />
                  <label htmlFor="file"
                    style={{
                      fontFamily: "M PLUS 1",
                      fontWeight: "normal",
                      fontSize: "12px",
                      color: "#162F50",
                    }}
                  >
                    &nbsp;
                    写真アップロード
                  </label>
                </Button>
              </Grid>

              <Grid item xs={12}
                style={{
                  fontFamily: "M PLUS 1",
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "#162F50",
                  marginTop: "12px"
                }}
              >
                <Grid container>
                  {imagesArr && (imagesArr
                    // .sort((a, b) => a.postedAt > b.postedAt ? -1 : 1)
                    .map((photo, index) => (
                      <>
                        <Grid item style={{
                          width: "20%",
                          position: "relative",
                          paddingTop: "11.25%"
                        }}>
                          <LogMeetPhoto
                            key={photo.uid}
                            alt={photo.uid}
                            PhotosUC={photo.uid}
                            path={photo.path}
                            name={photo.name ? photo.name : `${photo.postedAt}_image`}
                            storage={photo.storage}
                            postedAt={photo.postedAt}
                            style={{
                              top: "0",
                              left: "0",
                              position: "absolute",
                              objectFit: "cover",
                              width: "calc(100% - 3px)",
                              height: "calc(100% - 3px)"
                            }}
                            onImageClick={onImageClick}
                            onImageLoaded={imageLoaded}
                            logmeetDetail={true}
                          />
                        </Grid>
                      </>
                    )))}
                </Grid>
              </Grid>

            </Grid>
          </Grid>)}
      </Grid>

      {receiver && (
        <div className="calling-confirm-modal">
          <div className="calling-confirm-container"
            style={{ textAlign: "center" }}
          >
            <Fab
              onClick={() => { setReceiver(null) }}
              size="small"
              className="close-button"
              style={{ backgroundColor: "#162F50" }}
            >
              <CloseIcon style={{ color: "white" }} />
            </Fab>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold"
              }}
            >この電話をかけますか？</p>
            <p
              style={{
                fontSize: "14px",
                fontWeight: 500,
                width: "100%"
              }}
              className="text-overflow-ellipsis"
            >{receiver.name}</p>
            <Button
              onClick={() => {
                const classification = window.location.pathname.includes("/sp/")
                  ? 1 // Supplier
                  : 0 // Builder;

                const logMeetUrl = logmeetConfig.getLogMeetURL(window.location.hostname);
                const url = `${logMeetUrl}?${new URLSearchParams({
                  token: configStorage().replace("Bearer ", ""),
                  siteUC: baseHistory.site ? baseHistory.site.uid : "",
                  userUC: receiver.uid,
                  role: classification
                })}`;
                window.open(url, '_blank').focus();
                setReceiver(null);
              }}
              style={{
                backgroundColor: "#162F50",
                color: "white",
                width: "254px",
                height: "48px",
                margin: "20px 0"
              }}
            >
              <PhoneIcon style={{ color: "white", width: "22px", height: "22px" }} />
              &nbsp;
              <span
                style={{
                  fontSize: "16px",
                  fontFamily: "Noto Sans JP",
                  fontWeight: 500
                }}
              >発信する</span>
            </Button>
          </div>
        </div>
      )
      }

      {(isDialogShowImageEdit || isDialogDeleteShow) && (
        <EditImageDialog
          title={"写真情報編集"}
          isDialogShow={isDialogShowImageEdit}
          isDialogDeleteShow={isDialogDeleteShow}
          initialValues={{ name: currentPhoto.name }}
          toggleDialogDelete={toggleDialogDelete}
          openDialogDelete={openDialogDelete}
          onClose={closeEditImageDialogShow}
          submitButtonTitle={"登録"}
          photoId={currentPhoto.photoId}
          islogMeet={true}
          updateImageName={updateImageName}
          deleteImages={deleteImages}
        />
      )}

      <Dialog
        open={isDialogShowImage}
        aria-labelledby="max-width-dialog-title"
        fullWidth
        onClose={() => { }}
        maxWidth="lg"
        PaperProps={{
          className: "dialog paper-image"
        }}
        onKeyDown={handleKeyDown}
      >
        <DialogTitle
          disableTypography
          className="padding-dialog-image title-image"
        >
          <Grid container justify="space-between" className="flex-nowrap">
            <Grid className="log-title">
              <Typography
                variant="h4"
                className="dialog-title white-text mr-15"
              >
                {currentPhoto.name}
              </Typography>
              <Fab
                onClick={openEditImageDialogShow}
                size="small"
                className="white-text log-button-reset"
              >
                <EditIcon />
              </Fab>
            </Grid>
            <Fab
              size="small"
              className="white-text log-button-reset"
              onClick={() => {
                toggleDialogImage();
              }}
            >
              <CloseIcon />
            </Fab>
          </Grid>
        </DialogTitle>
        <DialogContent
          className="image-content-dialog padding-dialog-image"
          style={{ paddingBottom: 44 }}
        >
          <Grid className="image-wrap-content">
            <img
              onClick={() => showOriginalPhoto()}
              src={currentPhoto.url}
              alt={currentPhoto.photoId}
            />
          </Grid>
          <Grid className="button-group-dialog">
            <Fab
              className="white-text reset-button-image"
              disabled={currentPhotoIndex === 0}
              onClick={() => handleChangeImage(false)}
            >
              <FontAwesomeIcon
                size="3x"
                icon={faChevronLeft}
                className="white-text"
              />
            </Fab>
            <Fab
              className="white-text reset-button-image"
              disabled={currentPhotoIndex === (imagesArr.length - 1)}
              onClick={() => handleChangeImage(true)}
            >
              <FontAwesomeIcon
                size="3x"
                icon={faChevronRight}
                className="white-text"
              />
            </Fab>
          </Grid>
        </DialogContent>
        <DialogTitle
          disableTypography
          className="padding-dialog-image title-image"
          style={{ top: "initial", bottom: 0 }}
        >
          <Grid container justify="space-between" className="flex-nowrap">
            <Grid className="log-title"></Grid>
            <Fab
              className="white-text reset-button-image button-download"
              // disabled={disalbeNextBack}
              onClick={() => downloadCurrentPhoto()}
            >
              <CloudDownloadIcon className="white-text" fontSize="large" />
            </Fab>
          </Grid>
        </DialogTitle>
      </Dialog>
    </>
  );
}

export default LogMeetHistoryDetail;