import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CommonDialog from "../../CommonDialog";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";
import DialogDelete from "../../../common/DialogDelete";
import {
  postAgreementLogs,
  fetchStaffEntries,
  fetchAgreementDetail,
  putAgreementDetail,
  deleteAgreement
} from "./service/service";
import { dispatchError } from "common/Stores/service/service";
import { fetchAgreementLogsDetailSuccess } from "./action/action";

class ShopDialog extends React.Component {
  state = {
    isDialogDeleteShow: false,
    paramUrl: null
  };

  defaultValues = {
    agreementDate: new Date(),
    agreementUser: -1
  };

  fieldList = [
    {
      name: "agreementDate",
      label: "契約日​",
      type: DIALOG_FIELD_TYPES.DateTime,
      isRequired: true
    },
    {
      name: "agreementUser",
      label: "契約担当者",
      type: DIALOG_FIELD_TYPES.DropDownBox,
      options: [],
      isRequired: true
    }
  ];

  fieldListEdit = [
    ...this.fieldList,
    {
      name: "cancellationDate",
      label: "解約日",
      type: DIALOG_FIELD_TYPES.DateTime
    }
  ];

  componentWillMount() {
    const {
      isEdit,
      builderUC,
      agreementIndex,
      fetchStaffEntries,
      fetchAgreementDetail
    } = this.props;
    fetchStaffEntries();
    if (isEdit && agreementIndex !== undefined) {
      const url = `${builderUC}.${agreementIndex}`;
      fetchAgreementDetail(url);
      this.setState({ paramUrl: url });
    }
  }

  componentWillUnmount() {
    this.props.dispatchError("");
    this.props.fetchAgreementLogsDetailSuccess("");
  }

  toggleDialogDelete = () => {
    this.setState({ isDialogDeleteShow: !this.state.isDialogDeleteShow });
  };

  onSubmit = valuesForm => {
    const { isEdit, putAgreementDetail, postAgreementLogs } = this.props;
    const agreement = { ...valuesForm };
    if (agreement.agreementUser === -1) agreement.agreementUser = "";
    if (isEdit) {
      putAgreementDetail(this.state.paramUrl, agreement);
    } else {
      const agreementNew = { ...agreement, buildersUC: this.props.builderUC };
      postAgreementLogs(agreementNew);
    }
  };

  onDeleteSubmit = () => {
    this.props.deleteAgreement(this.state.paramUrl);
  };

  render() {
    const {
      isDialogShow,
      onCloseDialog,
      isEdit,
      staffEntries,
      agreementDetail,
      submitErrors
    } = this.props;

    const staffIndex = this.fieldList.findIndex(
      field => field.name === "agreementUser"
    );
    this.fieldList[staffIndex].options = staffEntries;

    return (
      <>
        <CommonDialog
          title={isEdit ? "契約編集" : "契約登録"}
          isDialogShow={isDialogShow}
          initialValues={agreementDetail ? agreementDetail : this.defaultValues}
          onSubmit={this.onSubmit}
          isEdit={isEdit}
          onClose={onCloseDialog}
          fieldList={isEdit ? this.fieldListEdit : this.fieldList}
          submitButtonTitle={isEdit ? "登録" : "登録​"}
          onDeleteClick={this.toggleDialogDelete}
          isSingleColumn={true}
          submitErrors={submitErrors}
        />
        <DialogDelete
          open={this.state.isDialogDeleteShow}
          onClose={this.toggleDialogDelete}
          PaperProps={{
            className: "dialog dialog-sign-up"
          }}
          onDeleteSubmit={this.onDeleteSubmit}
        />
      </>
    );
  }
}

ShopDialog.propTypes = {
  staffEntries: PropTypes.array,
  builderUC: PropTypes.string,
  agreementIndex: PropTypes.number,
  agreementDetail: PropTypes.object,
  isEdit: PropTypes.bool,
  submitErrors: PropTypes.object
};

const mapStateToProps = state => ({
  staffEntries: state.builderReducer.builderDetailReducer.staffEntries,
  submitErrors: state.storesReducer.error,
  agreementDetail: state.builderReducer.builderDetailReducer.agreementDetail
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchStaffEntries,
      postAgreementLogs,
      fetchAgreementDetail,
      dispatchError,
      fetchAgreementLogsDetailSuccess,
      putAgreementDetail,
      deleteAgreement
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShopDialog);
