import React from "react";
import PropTypes from "prop-types";

function IconInfo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      style={{ margin: "-6px" }}
    >
      <g transform="translate(-1332 -2449)">
        <path
          fill="none"
          d="M0 0H40V40H0z"
          transform="translate(1332 2449)"
        ></path>
        <path
          fill="#ffffff"
          d="M792.506 886.187q-1.139.4-1.816.6a5.374 5.374 0 01-1.576.209 3.42 3.42 0 01-2.144-.595 1.831 1.831 0 01-.758-1.504 4.853 4.853 0 01.056-.727 8.148 8.148 0 01.184-.837l.95-2.966c.084-.285.156-.555.213-.807a3.177 3.177 0 00.085-.7 1 1 0 00-.265-.791 1.653 1.653 0 00-1.015-.222 2.99 2.99 0 00-.755.1c-.256.07-.478.132-.66.194l.251-.914q.933-.336 1.785-.575a5.907 5.907 0 011.61-.241 3.356 3.356 0 012.112.585 1.847 1.847 0 01.742 1.518c0 .129-.017.356-.051.681a4.087 4.087 0 01-.19.895l-.952 2.959a7.549 7.549 0 00-.208.812 3.817 3.817 0 00-.091.691.927.927 0 00.3.8 1.843 1.843 0 001.032.214 3.36 3.36 0 00.782-.107 4.855 4.855 0 00.632-.188zm-.167-12a2.428 2.428 0 01-1.592.543 2.454 2.454 0 01-1.6-.543 1.638 1.638 0 010-2.636 2.434 2.434 0 011.6-.548 2.406 2.406 0 011.592.548 1.643 1.643 0 010 2.636z"
          transform="translate(563 1590)"
        ></path>
        <g
          fill="none"
          stroke="#ffffff"
          strokeWidth="1.5"
          transform="translate(563 1590) translate(776 866)"
        >
          <circle cx="13" cy="13" r="13" stroke="none"></circle>
          <circle cx="13" cy="13" r="12.25"></circle>
        </g>
      </g>
    </svg>
  );
}

function IconInfoHeader(props) {
  return (
    <span
      style={{
        width: "28px",
        height: "28px",
        borderRadius: "50%",
        overflow: "hidden"
      }}
    >
      <IconInfo />
    </span>
  );
}
export default IconInfoHeader;
