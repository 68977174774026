import React, { useEffect, useState } from "react";
import CommonDialog from "../CommonDialog";
import { makeStyles } from "@material-ui/core/styles";
import DialogDelete from "../../../common/DialogDelete";
import { api } from "../../../common/api/api";
import ScrollContainer from "react-indiana-drag-scroll";
import IconColor from "./IconColor";
import { fetchEditIconInfo } from "./fakeAPI";

const useStyles = makeStyles({
  root: {},
  dialogContent: {
    padding: "0px",
    margin: "0px",
    fontSize: "17px",
    fontWeight: "normal",
    lineHeight: "23px",
    "& p": {
      fontWeight: "bold",
      paddingBottom: "10px",
      marginTop: "20px"
    },
    "& input": {
      width: "100%",
      height: "45px",
      padding: "11px 20px",
      backgroundColor: "#F8F9F9",
      borderRadius: "5px",
      border: "1px solid #C7C7C7 !important",
      boxShadow: "none !important",
      boxSizing: "border-box"
    }
  },
  colorItem: {
    width: "45px",
    height: "45px",
    padding: "2px",
    margin: "6px",
    borderRadius: "5px",
    border: "1px solid white",
    cursor: "pointer"
  },
  saveButton: {
    backgroundColor: "#00346C",
    borderRadius: "4px",
    width: "150px",
    height: "45px",
    lineHeight: "23px",
    padding: "11px 0px",
    textAlign: "center",
    color: "white",
    margin: "auto"
  },
  deleteContentDialog: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    lineHeight: "23px",
    paddingBottom: "15px",
    "& svg": {
      marginBottom: "15px"
    }
  },
  deleteActions: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "& button": {
      width: "120px",
      height: "45px",
      padding: "11px 0px",
      backgroundColor: "#C7C7C7",
      borderRadius: "4px",
      lineHeight: "23px",
      fontWeight: "bold",
      textAlign: "center",
      color: "white"
    }
  }
});

function IconInfoDialog(props) {
  const {
    createdIconInfo,
    editIconInfo,
    deleteIconInfo,
    handleCreateIconInfo,
    handleEditIconInfo,
    handleDeleteIconInfo,
    closeIconInfoDialog
  } = props;

  const classes = useStyles();

  const [listColorInfos, setListColorInfos] = useState([]);
  const [iconInfoLabel, setIconInfoLabel] = useState("");
  const [iconInfoLabelError, setIconInfoLabelError] = useState(false);
  const [colorInfo, setColorInfo] = useState(null);
  const [colorInfoError, setColorInfoError] = useState(false);
  const [iconInfoPreset, setIconInfoPreset] = useState(false);

  useEffect(() => {
    if (editIconInfo) {
      resetState();
      if (listColorInfos.length === 0) {
        Promise.all([
          api.get(`/builder/info-colors/entries`),
          // fetchEditIconInfo(editIconInfo.uniqueCode)
          api.get(`/builder/info-icons/${editIconInfo.uniqueCode}`)
        ]).then(res => {
          setListColorInfos(res[0].data);
          setIconInfoLabel(res[1].data.label);
          const currentColor = res[0].data.find(
            color => color.uniqueCode === res[1].data.infoColorsUC
          );
          setColorInfo(currentColor);
          setIconInfoPreset(res[1].data.preset);
        });
      } else {
        // fetchEditIconInfo(editIconInfo.uniqueCode).then(res => {
        api.get(`/builder/info-icons/${editIconInfo.uniqueCode}`).then(res => {
          setIconInfoLabel(res.data.label);
          const currentColor = listColorInfos.find(
            color => color.uniqueCode === res.data.infoColorsUC
          );
          setColorInfo(currentColor);
          setIconInfoPreset(res.data.preset);
        });
      }
    }
  }, [editIconInfo]);

  useEffect(() => {
    resetState();
    if (createdIconInfo && listColorInfos.length === 0) {
      api.get(`/builder/info-colors/entries`).then(res => {
        setListColorInfos(res.data);
      });
    }
  }, [createdIconInfo]);

  const handleClickSave = () => {
    if (
      iconInfoLabel.length === 0 ||
      iconInfoLabel.length > 7 ||
      colorInfo === null
    ) {
      setColorInfoError(colorInfo === null);
      setIconInfoLabelError(
        iconInfoLabel.length === 0 || iconInfoLabel.length > 7
      );
      return;
    }

    if (editIconInfo) {
      handleEditIconInfo(editIconInfo.uniqueCode, {
        label: iconInfoLabel,
        infoColorsUC: colorInfo.uniqueCode
      });
    } else {
      handleCreateIconInfo({
        label: iconInfoLabel,
        infoColorsUC: colorInfo.uniqueCode
      });
    }
  };

  const resetState = () => {
    setIconInfoLabel("");
    setColorInfo(null);
    setIconInfoPreset(false);
    setColorInfoError(false);
    setIconInfoLabelError(false);
  };

  return (
    <React.Fragment>
      <CommonDialog
        title={
          deleteIconInfo
            ? "アイコンの削除"
            : createdIconInfo
              ? "アイコンの追加"
              : "アイコンの編集"
        }
        isDialogShow={Boolean(
          createdIconInfo || editIconInfo || deleteIconInfo
        )}
        onClose={() => closeIconInfoDialog()}
        dialogContent={
          deleteIconInfo ? (
            <div className={classes.deleteContentDialog}>
              <IconColor
                width="30px"
                height="30px"
                color={deleteIconInfo.color}
              />
              <span>本当に削除しますか？</span>
              <span>削除すると元に戻すことができません。</span>
            </div>
          ) : (
            <div className={classes.dialogContent}>
              <p>
                <span style={{ color: "red" }}>*</span>ラベル
              </p>
              <input
                maxLength="7"
                value={iconInfoLabel}
                onChange={e => {
                  setIconInfoLabel(e.target.value)
                  if (e.target.value.length === 0 || e.target.value.length > 7) {
                    setIconInfoLabelError(true)
                  } else {
                    setIconInfoLabelError(false)
                  }
                }}
                placeholder="テキストを入力"
                style={{ border: "1px solid #C7C7C7 !important" }}
              />
              {iconInfoLabelError && (
                <span style={{ color: "red" }}>
                  必須項目です。 7 文字まで登録可能です。
                </span>
              )}
              <p>
                <span style={{ color: "red" }}>*</span>カラー
              </p>
              <div style={{ width: "100%", height: "57px" }}>
                <ScrollContainer
                  className="scroll-container"
                  vertical
                  horizontal
                  hideScrollbars
                  activationDistance={4}
                >
                  <ul style={{ display: "flex", flexDirection: "row" }}>
                    {listColorInfos.length > 0
                      ? listColorInfos.map(color => (
                        <span
                          key={color.uniqueCode}
                          className={classes.colorItem}
                          style={
                            colorInfo?.uniqueCode === color.uniqueCode
                              ? {
                                border: "1px solid #007CFF",
                                backgroundColor: "#EBF5FF"
                              }
                              : null
                          }
                          onClick={() => setColorInfo(color)}
                        >
                          <IconColor
                            width="39px"
                            height="39px"
                            color={color.color}
                          />
                        </span>
                      ))
                      : null}
                  </ul>
                </ScrollContainer>
              </div>
              {colorInfoError && (
                <span style={{ color: "red" }}>必須項目です</span>
              )}
            </div>
          )
        }
        dialogActions={
          deleteIconInfo ? (
            <div className={classes.deleteActions}>
              <button
                style={{ backgroundColor: "#00346C", marginRight: "20px" }}
                onClick={() => handleDeleteIconInfo(deleteIconInfo.uniqueCode)}
              >
                Yes
              </button>
              <button onClick={() => closeIconInfoDialog()}>No</button>
            </div>
          ) : (
            <button
              onClick={handleClickSave}
              className={classes.saveButton}
              style={{ backgroundColor: "#00346C" }}
            >
              登録
            </button>
          )
        }
      />
    </React.Fragment>
  );
}

export default IconInfoDialog;