import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      viewBox="0 0 28 28"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path
            data-name="Rectangle 507"
            transform="translate(-4064 625)"
            fill="#fff"
            stroke="#707070"
            d="M0 0h28v28H0z"
          />
        </clipPath>
      </defs>
      <g transform="translate(4064 -625)" clipPath="url(#prefix__a)">
        <g data-name="Group 1421" fill="#fff">
          <path
            data-name="Path 485"
            d="M-4052.031 640.066l-.448 1.107a20.282 20.282 0 00-2.491 1.095l.021 2.002a19.728 19.728 0 002.515 1.078l.478 1.118a20.121 20.121 0 00-.95 2.545l1.429 1.429a18.845 18.845 0 002.501-1.003l1.118.473a21.405 21.405 0 001.145 2.514l2.001.021a18.777 18.777 0 001.024-2.495l1.106-.45a20.8 20.8 0 002.57 1.003l1.4-1.4a19.789 19.789 0 00-1.056-2.525l.448-1.11a20.176 20.176 0 002.49-1.093l-.023-2.003a20.165 20.165 0 00-2.511-1.074l-.473-1.12a19.842 19.842 0 00.947-2.547l-1.43-1.429a18.975 18.975 0 00-2.502 1.002l-1.119-.474a21.311 21.311 0 00-1.143-2.512l-2.002-.022a19.069 19.069 0 00-1.02 2.493l-1.108.449a20.842 20.842 0 00-2.57-1.004l-1.407 1.407a19.847 19.847 0 001.06 2.525zm8.223 1.214a2.888 2.888 0 11-4.128-.044 2.924 2.924 0 014.128.044z"
          />
          <path
            data-name="Path 486"
            d="M-4060.008 628.092l-.65.623a15.225 15.225 0 00-2.05-.014l-.598 1.381a15.066 15.066 0 001.401 1.524l-.017.914a14.822 14.822 0 00-1.437 1.461l.54 1.423a13.887 13.887 0 002.037.076l.622.672a15.808 15.808 0 00.012 2.083l1.376.632a14.614 14.614 0 001.471-1.4l.906.03a15.778 15.778 0 001.453 1.48l1.397-.532a14.621 14.621 0 00.053-2.067l.65-.623a15.247 15.247 0 002.046.016l.597-1.382a15.458 15.458 0 00-1.403-1.515l.02-.913a14.835 14.835 0 001.435-1.465l-.544-1.424a14.07 14.07 0 00-2.028-.082l-.626-.67a15.834 15.834 0 00-.014-2.083l-1.372-.63a14.256 14.256 0 00-1.473 1.402l-.9-.033a15.814 15.814 0 00-1.462-1.48l-1.396.533a15.354 15.354 0 00-.046 2.063zm2.458 2.067a2.227 2.227 0 11-1.239 2.857 2.208 2.208 0 011.239-2.858z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
