import React from "react";
import PropTypes from "prop-types";
import IconInfo from "../hotspotInfoIcons/IconInfo";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
    padding: "0px 18px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E0E0E0"
    }
  },
  itemText: {
    fontSize: "15px",
    flexGrow: "1",
    fontWeight: "normal",
    marginLeft: "14px",
    boxSizing: "border-box",
    lineHeight: "40px",
    display: "flex",
    justifyContent: "space-between",
  },
  icon: {
    marginTop: "10px",
    marginLeft: "10px",
    marginRight: "15px",
  },
});

function InfoIconItem(props) {
  const classes = useStyles();
  const { iconItem, setIcon, icon } = props;
  return (
    <div className={classes.root} onClick={() => setIcon(iconItem)}>
      <div style={{cursor: "pointer", width:"25px", height:"25px"}}>
        <IconInfo color={iconItem.color} width="25" height="25" />
      </div>
      <span className={classes.itemText}>
        <div style={{cursor: "pointer"}}>
          {iconItem.label}
        </div>
        <div></div>
        {
          icon.uniqueCode === iconItem.uniqueCode 
          ?  <CheckIcon className={classes.icon}/> 
          : null
        }
      </span>
    </div>
  );
}

InfoIconItem.propTypes = {
  colorItem: PropTypes.object,
  setIcon: PropTypes.func
};

export default InfoIconItem;
