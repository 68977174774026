import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AppBar from "@material-ui/core/AppBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRobot,
  faTools,
  faUserFriends,
  faHardHat,
  faTh,
  faCogs
} from "@fortawesome/free-solid-svg-icons";
import { fetchUserMyself } from "../Stores/service/service";
import { getBuilderName, getBuilderFlag } from "../Stores/reducer/reducer";
import "../css/base.css";
import "../../common/css/header.css";
import Sp from "../breakpoints/Sp";
import Pc from "../breakpoints/Pc";
import HeaderBuilderSP from "./HeaderBuilderSP";
import HeaderBuilderPC from "./HeaderBuilderPC";
import SettingIcon from "./SettingIcon.js";
import IconInfoHeader from "./IconInfoHeader";
import IconShareUrlHeader from "./IconShareUrlHeader";
import HomeIcon from "./HomeIcon";
import HomeGroupIcon from "./HomeGroupIcon";

const menuLists = [
  {
    title: "担当現場",
    link: "/dashboard",
    icon: (
      <HomeIcon />
    )
  },
  {
    title: "全ての現場",
    link: "/sites",
    icon: (
      <HomeGroupIcon />
    )
  },
  {
    title: "タスク一覧",
    link: "/info-hotspots",
    icon: (
      <IconInfoHeader />
    )
  },
  {
    title: "URL共有一覧",
    link: "/share-urls",
    icon: (
      <IconShareUrlHeader />
    )
  },
  {
    title: "社員の管理",
    link: "/employees",
    icon: (
      <FontAwesomeIcon
        size="2x"
        icon={faUserFriends}
        className="white-text width-icon"
      />
    )
  },
  {
    title: "職人の管理",
    link: "/workers",
    icon: (
      <FontAwesomeIcon
        size="2x"
        icon={faHardHat}
        className="white-text width-icon"
      />
    )
  },
  {
    title: "ロボットの管理",
    link: "/robots",
    icon: (
      <FontAwesomeIcon
        size="2x"
        icon={faRobot}
        className="white-text width-icon"
      />
    )
  },
  {
    title: "各種設定",
    link: "/settings",
    icon: (
      <SettingIcon />
    )
  }
];

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowMenu: false,
      paperProps: {}
    };
  }

  componentDidMount() {
    this.props.fetchUserMyself();
  }

  toggleDrawer = isShow => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ isShowMenu: isShow });
  };

  sideList = menu => (
    <Grid
      role="presentation"
      onClick={this.toggleDrawer(false)}
      onKeyDown={this.toggleDrawer(false)}
    >
      <List disablePadding className="padding-y">
        {menu.map((item, index) => (
          <Link
            to={{ pathname: item.link, state: { reload: true, isBack: false } }}
            className="reset-link"
          >
            <ListItem className="white-text list-item" button key={index}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          </Link>
        ))}
      </List>
    </Grid>
  );

  render() {
    const { isFixSpot } = this.props;
    return (
      <>
        {isFixSpot ? (
          <>
            <HeaderBuilderPC
              showDrawer={this.toggleDrawer(true)}
              titlePage={this.props.titlePage}
              disableHeader={this.props.disableHeader}
              onMount={() => this.setState({ paperProps: {} })}
              isFixSpot={true}
            />
          </>
        )
          : (
            <>
              <Sp>
                <HeaderBuilderSP
                  showDrawer={this.toggleDrawer(true)}
                  titlePage={this.props.titlePage}
                  onMount={() =>
                    this.setState({ paperProps: { style: { top: 65 } } })
                  }
                  builderFlag={this.props.builderFlag}
                />
              </Sp>
              <Pc>
                <HeaderBuilderPC
                  showDrawer={this.toggleDrawer(true)}
                  titlePage={this.props.titlePage}
                  disableHeader={this.props.disableHeader}
                  onMount={() => this.setState({ paperProps: {} })}
                  builderFlag={this.props.builderFlag}
                />
              </Pc>
              <Drawer
                open={this.state.isShowMenu}
                onClose={this.toggleDrawer(false)}
                className="draw-menu"
                classes={{
                  paper: "draw-paper"
                }}
                PaperProps={this.state.paperProps}
              >
                {this.sideList(menuLists)}
              </Drawer>
            </>
          )
        }
      </>
    );
  }
}

Header.propTypes = {};

const mapStateToProps = state => ({
  builderName: getBuilderName(state.storesReducer.builderName),
  builderFlag: getBuilderFlag(state.storesReducer.builderFlag)
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserMyself: fetchUserMyself
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
