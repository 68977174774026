import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from "@material-ui/core/Popover";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import TableRow from "@material-ui/core/TableRow";
import DialogDelete from "../../../common/DialogDelete";

import "../../../common/css/shop.css";

import {
  PUT_BUILDER_FEATURE_FLAG_SUCCESS
} from "./constant/constant";
import {
  fetchBuilderFeatureFlag,
  putBuilderFeatureFlag
} from "./service/service";
import { dispatchError, finishLoading } from "common/Stores/service/service";



class ShopFeatureFlagTab extends Component {
  state = {
    anchorEl: null
  };
  componentDidMount() {
    this.props.fetchBuilderFeatureFlag(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.props.fetchBuilderFeatureFlag(nextProps.match.params.id);
    }
    if (nextProps.type === PUT_BUILDER_FEATURE_FLAG_SUCCESS) {
      this.setState({ isDialogConfirm: false, anchorEl: null });
    }
  }

  onUpdateStatusSubmit = () => {
    this.props.putBuilderFeatureFlag(this.props.match.params.id,
      {
        Id: this.state.FeatureFlagIdSelected,
        Status: !this.state.FeatureFlagStatusSelected
      });

  };
  showPopover = (Id, Status) => e => {
    this.setState({
      anchorEl: e.currentTarget,
      FeatureFlagIdSelected: Id,
      FeatureFlagStatusSelected: Status
    });
  };

  closePopover = () => {
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const { anchorEl, FeatureFlagStatusSelected, isDialogConfirm } = this.state;
    const { featureFlag } = this.props;

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const days = ['日', '月', '火', '水', '木', '金', '土'];
    const onCloseDialogConfirm = () => {
      this.setState({ isDialogConfirm: false, anchorEl: null });
    };
    const onShowDialogConfirm = () => {
      this.setState({ isDialogConfirm: true, anchorEl: null });
    };
    return (
      <Grid className="shop-robot">
        <Grid>
          <TableContainer>
            <Table size="small" aria-label="table">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell className="white-text text-center">フィーチャー名</TableCell>
                  <TableCell className="white-text text-center">ON / OFF</TableCell>
                  <TableCell className="white-text text-center">最終更新日時</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {featureFlag.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {item.FeatureMaster.Name}
                    </TableCell>
                    <TableCell className={`text-center ${item.Status ? "color-red" : ""}`}>{item.Status ? "ON" : "OFF"}</TableCell>
                    <TableCell className="text-center">
                      <p>
                        {item.UpdatedAt && format(new Date(item.UpdatedAt), "yyyy.MM.dd")} ({days[new Date(item.UpdatedAt).getDay()]})
                      </p>
                      <p>
                        {item.UpdatedAt && format(new Date(item.UpdatedAt), "HH:mm")}
                      </p>
                    </TableCell>
                    <TableCell className="padding-last-td s-ta-r">
                      <Fab
                        onClick={this.showPopover(item.Id, item.Status)}
                        size="small"
                        className="more-vert-btn"
                      >
                        <MoreVertIcon />
                      </Fab>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.closePopover}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuList className="popover-sites">
            <MenuItem onClick={onShowDialogConfirm}>{FeatureFlagStatusSelected ? "OFFにする" : "ONにする"}</MenuItem>
          </MenuList>
        </Popover>
        <DialogDelete
          open={this.state.isDialogConfirm}
          onClose={onCloseDialogConfirm}
          textTop={FeatureFlagStatusSelected ? "OFFにしますか？" : "ONにしますか？"}
          textBottom={""}
          titleCancel={"いいえ"}
          titleSubmit={"はい"}
          onDeleteSubmit={this.onUpdateStatusSubmit}
          PaperProps={{
            className: "dialog dialog-sign-up"
          }}
        />
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  featureFlag: state.builderReducer.builderDetailReducer.featureFlag,
  error: state.storesReducer.error,
  type: state.builderReducer.builderDetailReducer.type
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBuilderFeatureFlag,
      putBuilderFeatureFlag,
      dispatchError,
      finishLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShopFeatureFlagTab));
