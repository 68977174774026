import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Component from "./Common";
import SiteBuilder from "./SiteBuilder";
import SiteBuilderSP from "./SiteBuilderSP";
import Pc from "../../../common/breakpoints/Pc";
import Sp from "../../../common/breakpoints/Sp";
import DialogRobots from "../../../common/DialogRobots";
import MapsDialog from "./MapDialog";
import { reloadAfterUpdateSite } from "../service/service";
import {
  COMPLETE_SITES_SUCCESS,
  POST_SITES_SUCCESS
} from "../constant/constant";
import { MODE_DEVICE } from "components/sites/site/LogWalkViewer/constants";
import { fetchPhotoTabSuccess } from "./action/action";
import {
  finishLoading,
  translateValidate,
  messageValidate
} from "../../../common/Stores/service/service";
import { getEntries, getType, getSite, getFiles } from "./reducer/reducer";
import {
  fetchPhotoTabData,
  searchFile,
  fetchInfoData,
  showHideDialogMapFromLogWalk,
  setTabElement
} from "./service/service";
import SiteDialog from "./SiteDialog";
import DrawingDialog from "components/DrawingDialog";
import stringquery from "stringquery";
import { setRedirectFromUrl } from "./action/action";
import querySearch from "stringquery";

const Element = {
  None: false,
  Photos: 1,
  Files: 2,
  Info: 3,
  Map: 4,
  ShareUrl: 5
};

class Index extends Component {
  loadAllPhotoSuccess = false;
  uniqueCode = null;

  constructor(props) {
    super(props);
    this.init(props);
    this.state = {
      isDialogShow: false,
      isDialogRobotsShow: false,
      // element: Element.Photos
      isShowDialogDrawing: false,
      sitesUC: null
    };
  }

  componentWillMount() {
    this.init(this.props);
    this.fetch();
    this.props.reloadAfterUpdateSite(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.init(nextProps);
      this.fetch();
      this.props.reloadAfterUpdateSite(nextProps.match.params.id);
    }
    if (!this.props.site && nextProps.site) {
      (({ location, site }) => {
        const queries = stringquery(location.search);
        if (!("logwalk" in queries)) {
          return;
        }
        if (!site || !site.LogWalkViewer) {
          return;
        }
        this.showHideDialogDrawing(site.UniqueCode);
      })(nextProps);
    }

    if (nextProps.type === COMPLETE_SITES_SUCCESS) {
      this.props.reloadAfterUpdateSite(this.props.match.params.id);
    }
  }

  componentDidMount() {
    super.componentDidMount();

    this.props.fetchPhotoTabData(this.uniqueCode);
    const element =
      this.props.type === POST_SITES_SUCCESS
        ? Element.Info
        : this.props.tabElement;
    this.props.setTabElement(element);
    this.props.translateValidate();
    this.props.messageValidate();
    const paramsSearch = querySearch(this.props.location.search);
    const tabIndex = Object.values(Element).find(item => paramsSearch.tab == item);
    if (tabIndex) {
      this.props.setTabElement(tabIndex);
    }

    // if (this.props.redirectFromUrl.isDialog) {
    //   this.showHideDialogDrawing(this.props.match.params.id);
    //   this.props.setRedirectFromUrl({
    //     isRedirectFromUrl: false,
    //     isDialog: false,
    //     isCardList: true,
    //     from: ""
    //   });
    // }
  }

  componentWillUnmount() {
    if (this.props.site) this.props.site.UniqueCode = undefined;
    fetchPhotoTabSuccess(false);
    finishLoading();
  }

  componentDidUpdate() {
    const { loading, loadPhotoTab, finishLoading } = this.props;
    if (loading && loadPhotoTab && this.loadAllPhotoSuccess) {
      finishLoading();
    }
  }

  fetch() {
    this.props.searchFile(this.uniqueCode);
    const { fetchInfoData } = this.props;
    fetchInfoData(this.uniqueCode);
  }

  init(props) {
    this.uniqueCode = props.match.params.id;
  }

  showHideDialogDrawing = (sitesUC = null) => {
    this.setState({
      isShowDialogDrawing: !this.state.isShowDialogDrawing,
      sitesUC
    });
  };

  render() {
    const { isShowDialogDrawing, sitesUC } = this.state;

    const showDialogEdit = () => {
      this.setState({
        isDialogShow: true
      });
    };
    const closeDialog = () => {
      this.setState({
        isDialogShow: false
      });
    };
    const onUpdate = () => {
      this.props.reloadAfterUpdateSite(this.props.match.params.id);
    };
    const showDialogRobots = e => {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        isDialogRobotsShow: true
      });
    };
    const closeDialogRobots = () => {
      this.setState({
        isDialogRobotsShow: false
      });
    };
    const onClickButtonReturn = () => {
      this.props.history.push("/sites");
    };

    const selectElement = (e, element) => {
      this.props.setTabElement(element);
      this.props.history.push(this.props.match.url);
    };

    const handleHideLoading = () => {
      const { loading, loadPhotoTab, finishLoading } = this.props;
      if (loading && loadPhotoTab) {
        finishLoading();
      } else this.loadAllPhotoSuccess = true;
    };

    return (
      <>
        <Sp>
          <SiteBuilderSP
            site={this.props.site}
            showDialogEdit={showDialogEdit}
            showDialogRobots={showDialogRobots}
            onClickButtonReturn={onClickButtonReturn}
            uniqueCode={this.uniqueCode}
            selectElement={selectElement}
            onHideLoading={handleHideLoading}
            showDialogDrawing={this.showHideDialogDrawing}
          />
        </Sp>
        <Pc>
          <SiteBuilder
            site={this.props.site}
            entries={this.props.entries}
            showDialogEdit={showDialogEdit}
            showDialogRobots={showDialogRobots}
            uniqueCode={this.uniqueCode}
            selectElement={selectElement}
            onHideLoading={handleHideLoading}
            showDialogDrawing={this.showHideDialogDrawing}
          />
        </Pc>
        {this.state.isDialogRobotsShow && (
          <DialogRobots
            robots={this.props.site.Robots}
            onClose={closeDialogRobots}
          />
        )}
        {this.state.isDialogShow && (
          <SiteDialog
            isDialogShow={this.state.isDialogShow}
            onClose={closeDialog}
            onUpdate={onUpdate}
            isEdit={true}
            siteId={this.props.match.params.id}
            hasLogwalkPhoto={this.props.site.LogWalkViewer}
          />
        )}

        {/* {isShowDialogDrawing && (
          <DrawingDialog
            open={isShowDialogDrawing}
            onClose={this.showHideDialogDrawing}
            sitesUC={sitesUC}
          />
        )} */}
        {isShowDialogDrawing && (
          <Pc>
            <DrawingDialog
              open={isShowDialogDrawing}
              modeDevice={MODE_DEVICE.PC}
              onClose={this.showHideDialogDrawing}
              sitesUC={sitesUC}
            />
          </Pc>
        )}
        {isShowDialogDrawing && (
          <Sp>
            <DrawingDialog
              open={isShowDialogDrawing}
              modeDevice={MODE_DEVICE.SP}
              onClose={this.showHideDialogDrawing}
              sitesUC={sitesUC}
            />
          </Sp>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  site: getSite(state.rootSitesReducer.siteInfoReducer.site),
  entries: getEntries(state.rootSitesReducer.siteInfoReducer.entries),
  type: getType(state.rootSitesReducer.sitesReducer.type),
  loadPhotoTab: state.rootSitesReducer.siteInfoReducer.loadPhotoTab,
  loading: state.storesReducer.pending,
  files: getFiles(state.rootSitesReducer.siteInfoReducer.files),
  isShowDialogMapFromLogWalk:
    state.rootSitesReducer.siteInfoReducer.isShowDialogMapFromLogWalk,
  tabElement: state.rootSitesReducer.siteInfoReducer.tabElement,
  isCompleteSite: state.rootSitesReducer.siteInfoReducer.isCompleteSite,
  redirectFromUrl: state.rootSitesReducer.siteInfoReducer.redirectFromUrl
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      reloadAfterUpdateSite,
      fetchPhotoTabSuccess,
      finishLoading,
      fetchPhotoTabData,
      searchFile,
      fetchInfoData,
      translateValidate,
      messageValidate,
      showHideDialogMapFromLogWalk,
      setTabElement,
      setRedirectFromUrl: data => dispatch(setRedirectFromUrl(data))
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index));
