import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchDashBoard } from "./service/service";
import { bindActionCreators } from "redux";
import { getDashboards } from "./reducer/reducer";
import Pc from "../../common/breakpoints/Pc";
import Sp from "../../common/breakpoints/Sp";
import DashboardBuilder from "./DashboardBuilder";
import DashboardBuilderSP from "./DashboardBuilderSP";
import DialogRobots from "../../common/DialogRobots";
import DrawingDialog from "components/DrawingDialog";
import { FETCH_DASHBOARDS_SUCCESS } from "./constant/constant";
import { MODE_DEVICE } from "components/sites/site/LogWalkViewer/constants";
import {
  wsUpdatedRobots,
  wsClearRobots
} from "../robots/robotDetails/service/service";
import { getWebSocketRobotURL } from "utils/mapper";
const WS_TIME_REPEAT = 10000; //10s

class Dashboard extends Component {
  ws = null;
  wsInterval = null;

  constructor() {
    super();
    this.state = {
      isDialogConfirmShow: false,
      robots: [],
      isShowDialogDrawing: false,
      sitesUC: null,
      fetching: true,
      redirectFromUrl: null
    };
  }

  componentWillMount() {
    const { state } = this.props.history.location;
    if (this.props.redirectFromUrl.isRedirectFromUrl) {
      this.setState({ redirectFromUrl: this.props.redirectFromUrl });
    }

    this.props.fetchDashBoard();
    if (state && state.reload) {
      state.reload = false;
    }
  }

  componentWillReceiveProps(props) {
    const { state } = props.history.location;
    if (state && state.reload) {
      this.props.fetchDashBoard();
      state.reload = false;
    }
    if (props.type === FETCH_DASHBOARDS_SUCCESS) {
      this.setState({ fetching: false });

      if (this.ws) {
        this.ws.onclose = function () {}; // disable onclose handler first
        this.ws.close();
        this.props.wsClearRobots();
        clearInterval(this.wsInterval);
        clearInterval(this.pingInterval);
      }

      let robotsUC = [];
      props.dashboards.map((item, idx) => {
        item.robots.map((robot, robot_idx) => {
          robotsUC = [...robotsUC, robot.uniqueCode];
        });
      });

      if (robotsUC.length > 0) {
        this.openWS(robotsUC);

        this.wsInterval = setInterval(() => {
          if (this.ws === null) {
            this.openWS(robotsUC);
          }
        }, WS_TIME_REPEAT);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.wsInterval);
    clearInterval(this.pingInterval);
    if (this.ws) {
      this.ws.onclose = () => {}; // disable onclose handler first
      this.ws.close();
      this.props.wsClearRobots();
    }
  }

  openWS = robotsUC => {
    var pingInterval = null;
    this.ws = new WebSocket(getWebSocketRobotURL());
    this.ws.addEventListener("open", () => {
      this.ws.send(JSON.stringify(robotsUC));

      pingInterval = setInterval(() => {
        this.ws.send("ping");
      }, WS_TIME_REPEAT);
    });

    this.ws.addEventListener("close", () => {
      clearInterval(pingInterval);
      if (this.ws) {
        this.ws.onclose = () => {}; // disable onclose handler first
        this.ws.close();
        this.ws = null;
      }
    });

    this.ws.onmessage = s => {
      if (s.data !== "pong") {
        this.props.wsUpdatedRobots(JSON.parse(s.data));
      }
    };
  };

  to = id => () => {
    this.props.history.push("/sites/" + id);
  };

  shouldEnableRobotButton = robots => {
    for (let i = 0; i < robots.length; i++) {
      if (robots[i].useStatus === 1) return true;
    }
    return false;
  };

  showDialog = robots => e => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.shouldEnableRobotButton(robots)) return;
    this.setState({
      robots: robots,
      isDialogConfirmShow: true
    });
  };

  closeDialog = e => {
    this.setState({
      isDialogConfirmShow: false
    });
  };

  showHideDialogDrawing = (sitesUC = null) => {
    this.setState({
      isShowDialogDrawing: !this.state.isShowDialogDrawing,
      sitesUC
    });
  };

  render() {
    const { dashboards, robotsUpdate } = this.props;
    const {
      fetching,
      isDialogConfirmShow,
      robots,
      isShowDialogDrawing,
      sitesUC,
      redirectFromUrl
    } = this.state;

    return fetching ? (
      <></>
    ) : (
      <>
        <Sp>
          <DashboardBuilderSP
            dashboards={dashboards}
            to={this.to}
            shouldEnableRobotButton={this.shouldEnableRobotButton}
            showDialog={this.showDialog}
            showDialogDrawing={this.showHideDialogDrawing}
            robotsUpdate={robotsUpdate}
          />
        </Sp>
        <Pc>
          <DashboardBuilder
            dashboards={dashboards}
            to={this.to}
            shouldEnableRobotButton={this.shouldEnableRobotButton}
            showDialog={this.showDialog}
            showDialogDrawing={this.showHideDialogDrawing}
            robotsUpdate={robotsUpdate}
            redirectFromUrl={redirectFromUrl}
          />
        </Pc>
        {isDialogConfirmShow && (
          <DialogRobots robots={robots} onClose={this.closeDialog} />
        )}

        {isShowDialogDrawing && (
          <Pc>
            <DrawingDialog
              open={isShowDialogDrawing}
              modeDevice={MODE_DEVICE.PC}
              onClose={this.showHideDialogDrawing}
              sitesUC={sitesUC}
            />
          </Pc>
          // <DrawingDialog
          //   open={isShowDialogDrawing}
          //   onClose={this.showHideDialogDrawing}
          //   sitesUC={sitesUC}
          // />
        )}
        {isShowDialogDrawing && (
          <Sp>
            <DrawingDialog
              open={isShowDialogDrawing}
              modeDevice={MODE_DEVICE.SP}
              onClose={this.showHideDialogDrawing}
              sitesUC={sitesUC}
            />
          </Sp>
          // <DrawingDialog
          //   open={isShowDialogDrawing}
          //   onClose={this.showHideDialogDrawing}
          //   sitesUC={sitesUC}
          // />
        )}
      </>
    );
  }
}

Dashboard.propTypes = {
  fetchDashBoard: PropTypes.func.isRequired,
  dashboards: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  dashboards: getDashboards(state.dashboardsReducer.dashboard),
  type: state.rootSitesReducer.siteInfoReducer.type,
  robotsUpdate:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .wsUpdatedRobots,
  redirectFromUrl: state.rootSitesReducer.siteInfoReducer.redirectFromUrl
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDashBoard: fetchDashBoard,
      wsUpdatedRobots: wsUpdatedRobots,
      wsClearRobots: wsClearRobots
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
