import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import { Grid } from "@material-ui/core";
import ConfirmChangeStatusDialog from "../ConfirmChangeStatusDialog";
import NextHotspotIcon from "../icons/NextHotspotIcon";
import SelectStatus from "./SelectStatus";
import IconInfo from "../icons/IconInfo";
import CloseButton from "../CloseButton";
import ViewFile from "./ViewFile";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import "moment/locale/ja";
import { setRedirectFromUrl } from "../../sites/site/action/action";
import { api } from "../../../common/api/api";

const useStyles = makeStyles({
  label: {
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "20px",
    color: "#333333"
  },
  nextHotspotPC: {
    position: "absolute",
    right: "-140px",
    top: "50%",
    marginTop: "-74px",
    width: "120px",
    height: "148px",
    padding: "12px",
    backgroundColor: "rgba(0,0,0,0.2)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
    fontWeight: "500",
    cursor: "pointer",
    borderRadius: "3px"
  },
  nextHotspotSP: {
    position: "absolute",
    right: "-16px",
    bottom: "14px",
    width: "76px",
    height: "96px",
    padding: "8px",
    backgroundColor: "rgba(0,0,0,0.2)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
    fontWeight: "500",
    cursor: "pointer",
    borderRadius: "2px"
  },
  previousHotspotPC: {
    position: "absolute",
    left: "-140px",
    top: "50%",
    marginTop: "-74px",
    width: "120px",
    height: "148px",
    padding: "12px",
    backgroundColor: "rgba(0,0,0,0.2)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
    fontWeight: "500",
    cursor: "pointer",
    borderRadius: "3px"
  },
  previousHotspotSP: {
    position: "absolute",
    left: "-16px",
    bottom: "14px",
    width: "76px",
    height: "96px",
    padding: "8px",
    backgroundColor: "rgba(0,0,0,0.2)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
    fontWeight: "500",
    cursor: "pointer",
    borderRadius: "2px"
  },
  chatUnreadFlag: {
    color: "white",
    backgroundColor: "#EF2222",
    fontSize: "12px",
    lineHeight: "19px",
    borderRadius: "10px",
    padding: "0px 4px",
    marginBottom: "8px"
  },
  goToHotspotInfoButton: {
    backgroundColor: "#FF8675",
    color: "white",
    width: "160px",
    height: "35px",
    marginTop: "20px",
    marginBottom: "4px",
    textAlign: "center",
    borderRadius: "4px"
  },
  truncateText1: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
});

function DetailHotspotInfoDialog (props) {
  const {
    hotspot,
    onClose,
    showModeSP,
    nextHotspot,
    previousHotspot,
    allHotspots,
    reloadAllHotspots,
    allNotifications,
    saveCondition
  } = props;
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loadDetail, setLoadDetail] = useState(false);
  const [detailHotspot, setDetailHotspot] = useState(null);
  const [isShowConfirmChangeStatus, setIsShowConfirmChangeStatus] = useState(
    false
  );

  useEffect(() => {
    fetchDetailHotspot();
  }, [hotspot]);

  const redirectToLogWalkViewer = () => {
    dispatch(
      setRedirectFromUrl({
        isRedirectFromUrl: true,
        from: `/info-hotspots?tab=4&info=${hotspot.info.uniqueCode}&detail=1`
      })
    );
    saveCondition();
    history.push(
      "/logwalk-viewer/shoots/" +
        hotspot.shoot.uniqueCode +
        "?info=" +
        hotspot.info.uniqueCode
    );
  };
  const fetchDetailHotspot = () => {
    if (hotspot && hotspot.info.uniqueCode) {
      setLoadDetail(true);
      const classification = location.pathname.includes("/sp/")
        ? "supplier"
        : "builder";

      api
        .get(
          `/${classification}/logwalk-viewer/infos/${hotspot.info.uniqueCode}`
        )
        .then(res => {
          setDetailHotspot(res.data);
          setLoadDetail(false);
        })
        .catch(err => {
          setLoadDetail(false);
        });
    }
  };

  const getOrderNumber = () => {
    const currentIndex = allHotspots.findIndex(
      item => item.info.uniqueCode === hotspot.info.uniqueCode
    );
    if (currentIndex >= 0) {
      return `[ ${currentIndex + 1}/${allHotspots.length} ]`;
    }

    return "リストにありません";
  };

  const checkDisablePrevious = () => {
    if (!hotspot) return true;
    const currentIndex = allHotspots.findIndex(
      item => item.info.uniqueCode === hotspot.info.uniqueCode
    );

    return currentIndex <= 0;
  };

  const checkDisableNext = () => {
    if (!hotspot) return true;
    const currentIndex = allHotspots.findIndex(
      item => item.info.uniqueCode === hotspot.info.uniqueCode
    );

    return currentIndex === -1 || currentIndex >= allHotspots.length - 1;
  };

  const changeStatus = () => {
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    api
      .post(
        `/${classification}/logwalk-viewer/infos/${hotspot.info.uniqueCode}/${
          detailHotspot.completedAt ? "uncomplete" : "complete"
        }`
      )
      .then(() => {
        setIsShowConfirmChangeStatus(false);
        reloadAllHotspots();
        fetchDetailHotspot();
      })
      .catch(() => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        reloadAllHotspots();
        fetchDetailHotspot();
      });
  };

  const getNotiChat = () => {
    if (allNotifications) {
      const chatNoti = allNotifications.find(
        item =>
          item.infosUC === hotspot.info.uniqueCode &&
          item.category === "chat"
      );
      return chatNoti ? (
        <span className={classes.chatUnreadFlag}>チャット未読あり</span>
      ) : null;
    }
  };

  return (
    <React.Fragment>
      <Dialog
        // onClose={onClose}
        open={!!hotspot}
        maxWidth='sm'
        fullWidth={true}
        PaperProps={{
          style: {
            overflowY: "unset",
            width: showModeSP ? "calc(100% - 32px)" : "calc(100% - 64px)",
            maxHeight: showModeSP ? "calc(100% - 24px)" : "calc(100% - 64px)",
            margin: showModeSP ? "16px" : "32px"
          }
        }}
      >
        <React.Fragment>
          <CloseButton handleClose={onClose} />
          {!loadDetail && detailHotspot && hotspot ? (
            <div
              style={{
                paddingTop: "25px",
                paddingBottom: "25px",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div
                style={{
                  padding: !showModeSP
                    ? "0px 40px 10px 40px"
                    : "0px 16px 10px 16px",
                  flexShrink: "0"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "4px"
                  }}
                >
                  <span>
                    <IconInfo
                      color={detailHotspot.icon.color}
                      width='30'
                      height='30'
                    />
                  </span>
                  <span
                    className={classes.truncateText1}
                    style={{
                      fontSize: "15px",
                      lineHeight: "20px",
                      marginLeft: "10px",
                      fontWeight: "500",
                      flexGrow: "1"
                    }}
                  >
                    {hotspot.icon.label}
                  </span>
                </div>
                {getNotiChat()}
              </div>

              <div style={{ flexGrow: "1", overflowY: "auto" }}>
                <div
                  style={{
                    padding: !showModeSP ? "10px 40px" : "10px 16px",
                    backgroundColor: "#F8F9F9"
                  }}
                >
                  <Grid container style={{ marginBottom: "10px" }}>
                    <Grid item xs={3}>
                      <span className={classes.label}>タイトル</span>
                    </Grid>
                    <Grid item xs={9} style={{ overflow: "hidden" }}>
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "20px",
                          color: "#333333",
                          wordBreak: "break-word"
                        }}
                      >
                        {detailHotspot.title}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginBottom: "10px" }}>
                    <Grid item xs={3}>
                      <span className={classes.label}>報告内容</span>
                    </Grid>
                    <Grid item xs={9}>
                      <div style={{ wordBreak: "break-word" }}>
                        {detailHotspot.description}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginBottom: "10px" }}>
                    <Grid item xs={3}>
                      <span className={classes.label}>通知メンバー</span>
                    </Grid>
                    <Grid item xs={9}>
                      <div>
                        {detailHotspot.mentions.map(mention => (
                          <span
                            style={{
                              marginRight: "20px",
                              display: "inline-block"
                            }}
                          >
                            {mention.name}
                          </span>
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                  <ViewFile
                    files={detailHotspot.files}
                    fetchDetailHotspot={fetchDetailHotspot}
                  />
                </div>

                <div
                  style={{ padding: !showModeSP ? "10px 40px" : "10px 16px" }}
                >
                  <Grid container style={{ marginBottom: "10px" }}>
                    <Grid item xs={3}>
                      <span className={classes.label}>状態</span>
                    </Grid>
                    <Grid item xs={9}>
                      <SelectStatus
                        completedAt={detailHotspot.completedAt}
                        showConfirmChangeStatus={() =>
                          setIsShowConfirmChangeStatus(true)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginBottom: "10px" }}>
                    <Grid item xs={3}>
                      作成者
                    </Grid>
                    <Grid item xs={9}>
                      <div className={classes.truncateText1}>
                        {hotspot.info.createdBy}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginBottom: "10px" }}>
                    <Grid item xs={3}>
                      <span className={classes.label}>図面名</span>
                    </Grid>
                    <Grid item xs={9}>
                      <div className={classes.truncateText1}>
                        {hotspot.drawing.name}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginBottom: "10px" }}>
                    <Grid item xs={3}>
                      <span className={classes.label}>地点名</span>
                    </Grid>
                    <Grid item xs={9}>
                      <div style={{ wordBreak: "break-word" }}>
                        {hotspot.spot.name}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginBottom: "10px" }}>
                    <Grid item xs={3}>
                      <span className={classes.label}>撮影日時</span>
                    </Grid>
                    <Grid item xs={9}>
                      {format(
                        new Date(hotspot.record.createdAt),
                        "yyyy/MM/dd (eee) HH:mm",
                        { locale: ja }
                      )}
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginBottom: "10px" }}>
                    <Grid item xs={3}>
                      <span className={classes.label}>更新日時</span>
                    </Grid>
                    <Grid item xs={9}>
                      {format(
                        new Date(hotspot.info.updatedAt),
                        "yyyy/MM/dd (eee) HH:mm",
                        { locale: ja }
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className={classes.goToHotspotInfoButton}
                    onClick={redirectToLogWalkViewer}
                  >
                    ここを見る
                  </button>
                </div>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "17px",
                    color: "#00356D",
                    fontWeight: "500"
                  }}
                >
                  {getOrderNumber()}
                </p>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: "40vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CircularProgress color='primary' />
            </div>
          )}
          {!checkDisablePrevious() && (
            <div
              className={
                showModeSP
                  ? classes.previousHotspotSP
                  : classes.previousHotspotPC
              }
              onClick={() => previousHotspot()}
            >
              <div style={{ width: "100%" }}>
                <NextHotspotIcon isPrevious={true} />
              </div>

              <span style={{ width: "100%", textAlign: "center" }}>前へ</span>
            </div>
          )}
          {!checkDisableNext() && (
            <div
              className={
                showModeSP ? classes.nextHotspotSP : classes.nextHotspotPC
              }
              onClick={() => nextHotspot()}
            >
              <div style={{ width: "100%" }}>
                <NextHotspotIcon />
              </div>
              <span style={{ width: "100%", textAlign: "center" }}>次へ</span>
            </div>
          )}
        </React.Fragment>
      </Dialog>
      <ConfirmChangeStatusDialog
        open={isShowConfirmChangeStatus}
        handleClose={() => setIsShowConfirmChangeStatus(false)}
        completedAt={detailHotspot?.completedAt}
        changeStatus={changeStatus}
      />
    </React.Fragment>
  );
}

export default DetailHotspotInfoDialog;
