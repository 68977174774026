import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";

import {
  CometChatMessageActions,
  CometChatThreadedMessageReplyCount,
  CometChatReadReceipt
} from "../";
import { CometChatMessageReactions } from "../Extensions";
import { CometChatAvatar } from "../../Shared";

import { CometChatContext } from "../../../util/CometChatContext";
import { checkMessageForExtensionsData } from "../../../util/common";

import { theme } from "../../../resources/theme";

import {
  messageContainerStyle,
  messageWrapperStyle,
  messageThumbnailStyle,
  messageDetailStyle,
  nameWrapperStyle,
  nameStyle,
  messageVideoContainerStyle,
  messageVideoWrapperStyle,
  messageInfoWrapperStyle,
  messageReactionsWrapperStyle
} from "./style";

class CometChatReceiverVideoMessageBubble extends React.Component {
  static contextType = CometChatContext;

  constructor(props) {
    super(props);

    this.state = {
      isHovering: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const currentMessageStr = JSON.stringify(this.props.message);
    const nextMessageStr = JSON.stringify(nextProps.message);

    if (
      currentMessageStr !== nextMessageStr ||
      this.state.isHovering !== nextState.isHovering
    ) {
      return true;
    }
    return false;
  }

	handleMouseHover = (isHovering) => {
		this.setState({ isHovering });
	};

  render() {
    let avatar = null,
      name = null;
    if (this.props.message.receiverType === CometChat.RECEIVER_TYPE.GROUP) {
      // avatar = (
      // 	<div css={messageThumbnailStyle()} className="message__thumbnail">
      // 		<CometChatAvatar user={this.props.message.sender} />
      // 	</div>
      // );

      if (this.props.isDisplayAvatar) {
        name = (
          <div
            css={nameWrapperStyle(avatar)}
            className="message__name__wrapper"
            style={{ display: "flex", alignItems: "center" }}
          >
            {this.props.message.sender.metadata?.classification ===
              "builder" && (
              <span
                style={{
                  display: "inline-block",
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  backgroundColor: "#8FC31F"
                }}
              ></span>
            )}
            &nbsp;
            <span css={nameStyle(this.context)} className="message__name">
              {this.props.message.sender.name}
              {this.props.message.sender.metadata?.company
                ? " / " + this.props.message.sender.metadata?.company
                : ""}
            </span>
          </div>
        );
      }
    }

    let messageReactions = null;
    const reactionsData = checkMessageForExtensionsData(
      this.props.message,
      "reactions"
    );
    if (reactionsData) {
      if (Object.keys(reactionsData).length) {
        messageReactions = (
          <div
            css={messageReactionsWrapperStyle()}
            className="message__reaction__wrapper"
          >
            <CometChatMessageReactions
              message={this.props.message}
              actionGenerated={this.props.actionGenerated}
            />
          </div>
        );
      }
    }

    let toolTipView = null;
    if (this.state.isHovering) {
      toolTipView = (
        <CometChatMessageActions
          message={this.props.message}
          actionGenerated={this.props.actionGenerated}
        />
      );
    }

    return (
      <div
        css={messageContainerStyle()}
        className="receiver__message__container message__video"
        onMouseEnter={() => this.handleMouseHover(true)}
        onMouseLeave={() => this.handleMouseHover(false)}
        style={{ nBottom: "10px", maxWidth: "70%" }}
      >
        <div css={messageWrapperStyle()} className="message__wrapper">
          {avatar}
          <div css={messageDetailStyle(name)} className="message__details">
            {name}
            {toolTipView}

            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <div
                css={messageVideoContainerStyle()}
                className="message__video__container"
              >
                <div
                  css={messageVideoWrapperStyle()}
                  className="message__video__wrapper"
                >
                  <video
                    controls
                    style={{ boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)" }}
                    onSuspend={this.props.suspendCallback}
                    onLoadedData={this.props.loadedCallback}
                    onError={this.props.errorCallback}
                  >
                    <source src={this.props.message.data.url} />
                  </video>
                </div>
              </div>

              <div
                css={messageInfoWrapperStyle()}
                className="message__info__wrapper"
              >
                <CometChatReadReceipt message={this.props.message} />
                <CometChatThreadedMessageReplyCount
                  message={this.props.message}
                  actionGenerated={this.props.actionGenerated}
                />
              </div>
            </div>

            {messageReactions}
          </div>
        </div>
      </div>
    );
  }
}

// Specifies the default values for props:
CometChatReceiverVideoMessageBubble.defaultProps = {
  theme: theme,
  actionGenerated: {}
};

CometChatReceiverVideoMessageBubble.propTypes = {
  theme: PropTypes.object,
  actionGenerated: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired
};

export { CometChatReceiverVideoMessageBubble };
