import React from "react";
import { withRouter } from "react-router-dom";

import axios from "axios";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import RoomIcon from "@material-ui/icons/Room";

export class DrawingPhoto extends React.Component {
  drawingsUC = null;
  drawingBlob = null;
  alt = "";
  id = null;
  class = null;
  isAddPoint = false;
  source = null;
  ajaxRequest = axios.CancelToken.source();
  cancelSubmission = false;

  constructor(props) {
    super(props);
    this.state = {
      img: null,
      url: "",
      fetching: true,
      isShowAddPointDialog: false
    };
    this.isBuilder = !props.isSp;
    this.handleButtonRelease = this.handleButtonRelease.bind(this);
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.drawingUrl !== this.props.drawingUrl || prevProps.drawingBlob !== this.props.drawingBlob)  {
      this.init(this.props);
      this.fetch();
    }
  }


  componentWillMount() {
    this.init(this.props);
    this.fetch();
  }

  init(props) {
    this.drawingUrl = props.drawingUrl;
    this.drawingBlob = props.drawingBlob;
    this.alt = props.drawingName === undefined ? "" : props.drawingName;
  }

  fetch() {
    if (this.source) {
      this.source.cancel();
    }
    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();
    var isBlob = this.drawingBlob ? true : false;
    var url = isBlob ? this.drawingBlob : this.drawingUrl;
    if (url) {
      axios
        .get(url, {
          responseType: isBlob ? undefined : "arraybuffer",
          cancelToken: this.source.token
        })
        .then(res => {
          if (!this.cancelSubmission) {
            if (res.data === undefined) return Promise.reject();
            if (isBlob) {
              var url = res.data.blobData;
            } else {
              var blob = new Blob([res.data], { type: "image/jpeg" });
              var url = URL.createObjectURL(blob);
            }
            return url;
          }
        })
        .then(url => this.img(url))
        .then(img => {
          if (!this.cancelSubmission) {
            this.setState({ img });
          }
        })
        .catch(e => {
          if (e?.response?.status === 404) {
            this.setState({ img: this.img("") });
          }
        });
    }
  }

  componentWillUnmount() {
    this.ajaxRequest.cancel();
    this.cancelSubmission = true;
  }

  handleButtonRelease() {
    clearTimeout(this.buttonPressTimer);
  }

  handleDrawingClick = e => {
    this.props.tapToShowFullImage();
  };

  handleDrawingTouch = e => {
    document.getElementsByTagName("body")[0].classList.add("disable-select");
  };

  handleDrawingTouchEnd = () => {
    document.getElementsByTagName("body")[0].classList.remove("disable-select");
  };

  img(data) {
    let { isSP } = this.props;
    return (
      <React.Fragment>
        <img
          draggable={false}
          id={this.props.id}
          className={this.props.class}
          src={data}
          alt={this.alt}
          onDragStart={e => e.preventDefault()}
          onLoad={e => {
            this.setState({ fetching: false });
            this.props.loadMarker();
          }}
          onContextMenu={e => {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }}
          onClick={e => {
            this.handleDrawingClick(e);
          }}
          onTouchStart={e => this.handleDrawingTouch(e)}
          onTouchEnd={e => {
            this.handleDrawingTouchEnd();
          }}
          onMouseUp={isSP && this.handleButtonRelease}
          onMouseLeave={isSP && this.handleButtonRelease}
        />
      </React.Fragment>
    );
  }

  render() {
    const { positions } = this.props;
    const { fetching, img } = this.state;

    const renderSpot = pos => {
      return (
        <React.Fragment>
          <FiberManualRecordIcon
            stroke={"black"}
            stroke-width={1}
            className="marker icon-spot"
            style={{
              left: pos["posX"],
              top: pos["posY"]
            }}
          />
          <RoomIcon
            className="icon-spot-current marker-selected"
            style={{
              left: pos["posX"],
              top: pos["posY"]
            }}
          />
        </React.Fragment>
      );
    };

    return (
      <>
        {img}
        {!fetching &&
          positions &&
          positions.map(pos => (
            <div key={pos.uniqueCode}>{renderSpot(pos)}</div>
          ))}
      </>
    );
  }
}

export default withRouter(DrawingPhoto);
