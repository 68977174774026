import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik } from "formik";
import { forgotPassword } from "./service/service";
import { forgotPasswordSuccess } from "./action/action";
import { dispatchError } from "../../common/Stores/service/service";
import Sp from "../../common/breakpoints/Sp";
import Pc from "../../common/breakpoints/Pc";
import ForgotPasswordBuilderSP from "./ForgotPasswordBuilderSP";
import ForgotPasswordBuilderPC from "./ForgotPasswordBuilderPC";
import "../../common/css/base.css";
import "../../common/css/auth.css";

class ForgotPassword extends Component {
  componentDidMount() {
    this.props.forgotPasswordSuccess(false);
    this.props.dispatchError(null);
  }

  render() {
    const checktButtonClick = formik => e => {
      e.preventDefault();
      formik.handleSubmit();
    };
  
    const onSubmit = id => {
      this.props.forgotPassword(id);
    };

    const initialValues = {
      id: ""
    };

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={values => onSubmit(values)}
        render={formik => (
          <form onSubmit={checktButtonClick(formik)}>
            <Sp>
              <ForgotPasswordBuilderSP
                formik={formik}
                emailConfirm={this.props.emailConfirm}
                error={this.props.error}
              />
            </Sp>
            <Pc>
              <ForgotPasswordBuilderPC
                formik={formik}
                emailConfirm={this.props.emailConfirm}
                error={this.props.error}
              />
            </Pc>
          </form>
        )}
      />
    );
  }
}

ForgotPassword.propTypes = {
  error: PropTypes.object,
  emailConfirm: PropTypes.bool,
};

const mapStateToProps = state => ({
  error: state.storesReducer.error,
  emailConfirm: state.auth.emailConfirm
});

export default connect(mapStateToProps, {
  forgotPassword,
  forgotPasswordSuccess,
  dispatchError
})(ForgotPassword);
