import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router";
import { api } from "../../../../../../common/api/api";

const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
    height: "100%",
    "& img": {
      width: "100%",
      height: "auto",
      position: "absolute",
      left: "0%",
      top: "50%",
      transform: "translate(0%, -50%)"
    }
  },
  fullHeight: {
    width: "100%",
    height: "100%",
    "& img": {
      height: "100%",
      width: "auto",
      position: "absolute",
      left: "50%",
      top: "0%",
      transform: "translate(-50%, 0%)"
    }
  }
});

function Photo(props) {
  const { photoInfo } = props;

  const [image, setImage] = useState(null);
  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);

  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    if (photoInfo && photoInfo.photoId) {
    }
    getThumbnail();
  }, [photoInfo]);

  const getThumbnail = () => {
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    var isBlob = photoInfo.blob ? true : false;
    var path = photoInfo.blob ? photoInfo.blob.resized.replace(/^\/api/, '') : `/${classification}/site-photos/${photoInfo.photoId}/thumbnail`;
    api
      .get(path, {
        responseType: isBlob ? undefined : "arraybuffer"
      })
      .then(res => {
        if (res.data === undefined) return Promise.reject();
        if (isBlob) {
          var url = res.data.blobData;
        } else {
          var blob = new Blob([res.data], { type: "image/jpeg" });
          var url = URL.createObjectURL(blob);
        }
        return url;
      })
      .then(url => img(url))
      .then(img => setImage(img))
      .catch(e => {
        if (e?.response?.status === 404) {
          setImage(img(""));
        }
      });
  };

  const img = data => {
    return (
      <img
        src={data}
        alt=""
        onLoad={e => {
          setWidth(e.currentTarget.naturalWidth);
          setHeight(e.currentTarget.naturalHeight);
        }}
      />
    );
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0"
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "block",
          overflow: "hidden",
          position: "absolute"
        }}
        className={width / height > 4 / 3 ? classes.fullHeight : classes.fullWidth}
      >
        {image}
      </div>
    </div>
  );
}

export default Photo;
