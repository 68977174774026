//To set the authorize token
//to set and delete the Authorization header for
//our axios requests depending on whether a user is logged in or not
//
//Remember in Part 1 how we set an Authorization header
//in Postman when testing our private api route.

import axios from "axios";
import { api } from "../common/api/api";

const setAuthToken = token => {
  if (token) {
    // Apply authorization token to every request if logged in
    api.defaults.headers.common["Authorization"] = token;
  } else {
    // Delete auth header
    delete api.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
