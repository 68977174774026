import React from "react";
import { withRouter } from "react-router-dom";

import marzipano from "marzipano";
import { Formik } from "formik";
import { format } from "date-fns";
import { arrayBufferToBase64 } from "../../utils/mapper";

import MiniWindowViewer from "./MiniWindowViewer";
import Draggable from "react-draggable";
import Pc from "../../common/breakpoints/Pc";
import Sp from "../../common/breakpoints/Sp";
import DialogViewer from "./DialogViewer";

import "../../common/css/logwalk-veiwer.css";
import { ResizeSensor } from "css-element-queries";
import axios from "axios";
import {
  MODE_DEVICE,
  TYPE_SELECT_IMAGE_3D,
  TYPE_DIALOG,
  IMAGE_3D_LOAD_STATUS
} from "../sites/site/LogWalkViewer/constants";

class ShareUrlPage extends React.Component {
  isMove = null;
  source = null;
  blobExtention = null;
  pano = null;
  geoType = null;
  sasTimeout = null;

  constructor(props) {
    super(props);
    this.state = {
      showDialogViewer: false,
      viewer: undefined,
      heightMiniWindow: 80,
      widthMiniWindow: 120,
      progress: 0,
      positionMiniWindowViewer: { x: 0, y: 0 },
      oldPositionMiniWindowViewer: { x: 0, y: 0 },
      loadPhotoDone: false,
      reloadBlobDataLst403: false,

      spot: null,
      drawing: null,
      blobDataLogWalk: null,
      urlShare: null,
      getShareUrlFail: false,
      currentShootDirection: null,
      editLinkDirection: null
    };

    this.inputRef = React.createRef();
    this.inputRef1 = React.createRef();
    this.miniWindowViewerRef = React.createRef();
  }

  componentDidMount() {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
    var ElementQueries = require("css-element-queries/src/ElementQueries");
    // attaches to DOMLoadContent and does anything for you
    ElementQueries.listen();
    // or if you want to trigger it yourself:
    // 'init' parses all available CSS and attach ResizeSensor to those elements which
    // have rules attached (make sure this is called after 'load' event, because
    // CSS files are not ready when domReady is fired.
    ElementQueries.init();

    document.getElementsByTagName("html")[0].style.overflow = "hidden";

    // const element = document.getElementById("mini-window");
    // new ResizeSensor(element, () => {
    //   this.setState({
    //     widthMiniWindow: element.offsetWidth,
    //     heightMiniWindow: element.offsetHeight
    //   });
    // });

    this.loadInit();

    var pano = this.inputRef.current;

    pano.addEventListener("mousedown", e => {
      this.isMove = false;
    });

    pano.addEventListener("mousemove", e => {
      this.isMove = true;
    });

    // pano.addEventListener("click", e => {
    // });

    document.addEventListener("gesturestart", e => {
      e.preventDefault();
    });
  }

  loadInit = () => {
    // note: goi api get detail share url
    const { match } = this.props;
    const shareUrlId = match.params.id;
    const buildersUC = match.params.buildersUC;
    axios
      .get(`/api/url-share/${buildersUC}/${shareUrlId}/detail/`)
      .then(res => {
        this.setState({
          spot: res.data.Spot,
          urlShare: res.data.UrlShare,
          drawing: res.data.Drawing ? {
            urlPhoto: res.data.Drawing.Path,
            drawingBlob: res.data.Drawing.Blob,
            drawingName: res.data.Drawing.Basename,
            direction: res.data.Drawing.Direction
          } : null,
          getShareUrlFail: false,
          currentShootDirection: res.data.Shoot.Direction,
          editLinkDirection: res.data.Shoot.yaw
        });

        const apiUrl = res.data.Shoot.Path;
        let isBlob = true;
        if (apiUrl.includes("/file?bin")) {
          isBlob = false;
        }

        axios
          .get(apiUrl, isBlob ? {} : { responseType: "arraybuffer" })
          .then(response => {
            if (response.data) {
              if (isBlob) {
                this.setState({
                  blobDataLogWalk: {
                    ...response.data,
                    blob: apiUrl
                  }
                });
              } else {
                this.setState({
                  blobDataLogWalk: {
                    blobDataLst: [
                      `data:image/jpeg;base64,${arrayBufferToBase64(
                        response.data
                      )}`
                    ],
                    geoType: "EQR",
                    refreshSasEstimatedTime: null,
                    blob: apiUrl
                  }
                });
              }
            }
          });
      })
      .catch(err => {
        this.setState({ getShareUrlFail: true });
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { blobDataLogWalk } = this.state;

    if (
      blobDataLogWalk !== prevState.blobDataLogWalk ||
      (blobDataLogWalk &&
        blobDataLogWalk.blob !== prevState.blobDataLogWalk.blob)
    ) {
      this.setPanorama(blobDataLogWalk);
    }
  }

  sasExpiredEvtListner() {
    this.pano.addEventListener("sasExpired", () => {
      if (!this.state.reloadBlobDataLst403) {
        this.setState({ reloadBlobDataLst403: true });

        let isBlob = true;
        if (this.state.blobDataLogWalk.blob.includes("/file?bin")) {
          isBlob = false;
        }

        axios
          .get(
            this.state.blobDataLogWalk.blob,
            isBlob ? {} : { responseType: "arraybuffer" }
          )
          .then(res => {
            if (res.data) {
              let tmpBlobDataLst;

              if (isBlob) {
                tmpBlobDataLst = res.data.blobDataLst;
              } else {
                tmpBlobDataLst = [
                  `data:image/jpeg;base64,${arrayBufferToBase64(res.data)}`
                ];
              }

              this.blobExtention.updateBlobDataLst(tmpBlobDataLst);
              this.setState({ reloadBlobDataLst403: false });
            }
          })
          .catch(() => this.setState({ reloadBlobDataLst403: false }));
      }
    });
  }

  initTileAzBlogStorage(blobDataLogWalk) {
    this.geoType = "Tile";
    this.pano = this.inputRef.current;
    this.sasExpiredEvtListner();
    var viewerOpts = {
      controls: {
        mouseViewMode: "drag"
      }
    };
    this.blobExtention = new marzipano.AzBlobExtension(
      blobDataLogWalk.blobDataLst,
      this.pano
    );

    // Remove scene before create new
    if (this.state.viewer) {
      this.pano.removeChild(this.pano.childNodes[0]);
      this.pano.removeChild(this.pano.childNodes[0]);
    }

    var viewer = new marzipano.Viewer(
      this.pano,
      viewerOpts,
      this.blobExtention
    );

    var urlPrefix = "";
    var source = marzipano.ImageUrlSource.fromString(
      urlPrefix + "/{z}/{f}/{y}/{x}.jpg",
      { cubeMapPreviewUrl: urlPrefix + "/preview.jpg" }
    );
    var LEVELS = [
      {
        tileSize: 256,
        size: 256,
        fallbackOnly: true
      },
      {
        tileSize: 512,
        size: 512
      },
      {
        tileSize: 512,
        size: 1024
      },
      {
        tileSize: 512,
        size: 2048
      }
    ];
    var geometry = new marzipano.CubeGeometry(LEVELS);

    this.setState({ viewer }, () => {
      this.switchPhoto(source, geometry);
    });
  }

  initEQRPano(blobDataLogWalk) {
    this.geoType = "EQR";
    this.pano = this.inputRef.current;
    this.sasExpiredEvtListner();
    var viewerOpts = {
      controls: {
        mouseViewMode: "drag"
      },
      stage: { preserveDrawingBuffer: true }
    };
    this.blobExtention = new marzipano.AzBlobExtension(
      blobDataLogWalk
        ? blobDataLogWalk.blobDataLst
        : [
            "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="
          ],
      this.pano
    );

    // Remove scene before create new
    if (this.state.viewer) {
      this.pano.removeChild(this.pano.childNodes[0]);
      this.pano.removeChild(this.pano.childNodes[0]);
    }

    var viewer = new marzipano.Viewer(
      this.pano,
      viewerOpts,
      this.blobExtention
    );

    const eqrBlobSearchKey = "/eqr";
    var source = marzipano.ImageUrlSource.fromString(eqrBlobSearchKey);
    var geometry = new marzipano.EquirectGeometry([{ width: 4000 }]);

    this.setState({ viewer }, () => {
      this.switchPhoto(source, geometry);
    });
  }

  setPanorama = blobDataLogWalk => {
    if (blobDataLogWalk) {
      this.setState({ loadPhotoDone: false });

      if (blobDataLogWalk.geoType === "Tile") {
        this.initTileAzBlogStorage(blobDataLogWalk);
      } else {
        this.initEQRPano(blobDataLogWalk);
      }
    } else {
      this.setState({ loadPhotoDone: true });
      this.initEQRPano();
    }
  };

  switchPhoto = (source, geometry) => {
    const { viewer } = this.state;

    // Create view.
    var limiter = marzipano.util.compose(
      marzipano.RectilinearView.limit.vfov(
        (Math.PI * 10) / 180,
        (Math.PI * 120) / 180
      ),
      marzipano.RectilinearView.limit.hfov(
        (Math.PI * 10) / 180,
        (Math.PI * 120) / 180
      ),
      marzipano.RectilinearView.limit.pitch(-Math.PI / 2, Math.PI / 2)
    );
    var view = new marzipano.RectilinearView({ yaw: 0 }, limiter);

    var scene = viewer.createScene({
      source: source,
      geometry: geometry,
      view: view
    });

    // Display scene.
    scene.switchTo({}, () => {
      this.setState({
        loadPhotoDone: true
      });
    });

    const destinationViewParameters = {
      fov: "2.09439333333333",
      hfov: "2.09439333333333"
    };

    const compassSetting = this.state.drawing?.direction;

    if (typeof this.state.currentShootDirection === "number") {
      if (typeof compassSetting === "number") {
        destinationViewParameters["yaw"] =
          -((this.state.currentShootDirection + compassSetting) / 180) *
            Math.PI +
          (this.state.editLinkDirection || 0);
        destinationViewParameters["pitch"] = (5 / 180) * Math.PI;
      }
    }

    const options = {
      transitionDuration: 100
    };
    scene.lookTo(destinationViewParameters, options);

    this.setState({ viewer: viewer });
  };

  componentWillUnmount() {
    document.getElementsByTagName("html")[0].style.overflow = null;

    document.removeEventListener("gesturestart", e => {
      e.preventDefault();
    });

    if (this.sasTimeout) {
      clearTimeout(this.sasTimeout);
    }
  }

  onControlledDrag(e, positionMiniWindowViewer) {
    const { x, y } = positionMiniWindowViewer;
    this.setState({
      positionMiniWindowViewer: { x: x, y: y }
    });
  }

  containerClick = () => {
    // this.setState({
    //   changeClickOutsideHistory: !this.state.changeClickOutsideHistory
    // });
  };

  handleStart = () => {
    this.setState({
      oldPositionMiniWindowViewer: this.state.positionMiniWindowViewer
    });
  };

  handleStop = () => {
    this.setState({
      showDialogViewer:
        this.state.positionMiniWindowViewer ===
        this.state.oldPositionMiniWindowViewer
    });

    // this.props.onSetTypeDialog({
    //   typeDialog: TYPE_DIALOG.ZOOM_IMAGE,
    //   modeDevice: MODE_DEVICE.SP
    // });
  };

  handleShowDialogViewer = value => {
    this.setState({ showDialogViewer: value });
  };

  render() {
    return (
      <Formik initialValues={{}} enableReinitialize onSubmit>
        {formik => (
          <div className="parent logwalk-viewer-container">
            <div
              id="container"
              className="image-viewer"
              ref={this.inputRef}
              onClick={this.containerClick}
            />
            {this.state.drawing && (
              <React.Fragment>
                <Sp>
                  <Draggable
                    handle=".image-title-viewer"
                    bounds="body"
                    position={this.state.positionMiniWindowViewer}
                    onStart={this.handleStart}
                    onStop={this.handleStop}
                    // {...dragHandlers}
                    onDrag={this.onControlledDrag.bind(this)}
                  >
                    <div
                      className="mini-window-parent"
                      style={{
                        top: "20px"
                      }}
                      ref={this.miniWindowViewerRef}
                    >
                      <div
                        id="mini-window"
                        className="mini-window"
                        ref={this.inputRef1}
                      >
                        {/* {this.checkShowHand() && (
                                      <strong>
                                        <div className="hand-move"></div>
                                      </strong>
                                    )} */}
                        <MiniWindowViewer
                          modeDevice={MODE_DEVICE.SP}
                          onShowDialogViewer={this.handleShowDialogViewer}
                          urlPhoto={this.state.drawing.urlPhoto}
                          drawingBlob={this.state.drawing.drawingBlob}
                          drawingName={this.state.drawing.drawingName}
                          listSpots={this.state.spot ? [this.state.spot] : []}
                        />
                      </div>
                    </div>
                  </Draggable>
                </Sp>

                <Pc>
                  <div
                    className="mini-window-parent"
                    style={{
                      top: "20px"
                    }}
                  >
                    <div
                      id="mini-window"
                      className="mini-window"
                      ref={this.inputRef1}
                    >
                      <MiniWindowViewer
                        modeDevice={MODE_DEVICE.PC}
                        onShowDialogViewer={this.handleShowDialogViewer}
                        urlPhoto={this.state.drawing.urlPhoto}
                        drawingBlob={this.state.drawing.drawingBlob}
                        drawingName={this.state.drawing.drawingName}
                        listSpots={this.state.spot ? [this.state.spot] : []}
                      />
                    </div>
                  </div>
                </Pc>

                <Pc>
                  <DialogViewer
                    open={this.state.showDialogViewer}
                    modeDevice={MODE_DEVICE.PC}
                    onClose={this.handleShowDialogViewer}
                    urlPhoto={this.state.drawing.urlPhoto}
                    drawingBlob={this.state.drawing.drawingBlob}
                    drawingName={this.state.drawing.drawingName}
                    listSpots={this.state.spot ? [this.state.spot] : []}
                  />
                </Pc>
                <Sp>
                  <DialogViewer
                    open={this.state.showDialogViewer}
                    modeDevice={MODE_DEVICE.SP}
                    onClose={this.handleShowDialogViewer}
                    urlPhoto={this.state.drawing.urlPhoto}
                    drawingBlob={this.state.drawing.drawingBlob}
                    drawingName={this.state.drawing.drawingName}
                    listSpots={this.state.spot ? [this.state.spot] : []}
                  />
                </Sp>
              </React.Fragment>
            )}

            {this.state.loadPhotoDone && this.state.urlShare && (
              <span
                style={{
                  position: "absolute",
                  left: "20px",
                  bottom: "20px",
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  fontSize: "16px",
                  borderRadius: "6px",
                  padding: "4px 12px"
                }}
              >
                {`撮影日 : ${format(
                  new Date(this.state.urlShare.ShootedAt),
                  "yyyy年MM月dd日 HH:mm"
                )}`}
              </span>
            )}

            {this.state.loadPhotoDone &&
              this.state.urlShare &&
              !this.state.urlShare.IsHideName && (
                <React.Fragment>
                  <Pc>
                    <span
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: "50%",
                        transform: "translate(-50%)",
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        fontSize: "16px",
                        borderRadius: "6px",
                        padding: "4px 12px",
                        maxWidth: "40%",
                        display: "-webkit-box",
                        "-webkit-line-clamp": "2",
                        "-webkit-box-orient": "vertical",
                        overflow: "hidden",
                        textOverflow: "wrap",
                        wordBreak: "break-word"
                      }}
                    >
                      {`${this.state.urlShare.SiteName} : ${this.state.urlShare.SpotName}`}
                    </span>
                  </Pc>
                  <Sp>
                    <span
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: "0%",
                        marginLeft: "16px",
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        fontSize: "16px",
                        borderRadius: "6px",
                        padding: "4px 12px",
                        maxWidth: "45%",
                        display: "-webkit-box",
                        "-webkit-line-clamp": "2",
                        "-webkit-box-orient": "vertical",
                        overflow: "hidden",
                        textOverflow: "wrap",
                        wordBreak: "break-word"
                      }}
                    >
                      {`${this.state.urlShare.SiteName} : ${this.state.urlShare.SpotName}`}
                    </span>
                  </Sp>
                </React.Fragment>
              )}

            {this.state.getShareUrlFail && (
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  padding: "0px 20px",
                  fontSize: "18px",
                  fontWeight: "400",
                  transform: "translate(-50%, -50%)",
                  color: "#ffffff",
                  textAlign: "center",
                  minWidth: "300px",
                  borderRadius: "12px",
                  backgroundColor: "#000000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "120px"
                }}
              >
                このURLは公開を終了したか、無効の可能性があります。
              </span>
            )}
          </div>
        )}
      </Formik>
    );
  }
}

export default withRouter(ShareUrlPage);
