import {
  FETCH_STORES_PENDING,
  FETCH_STORES_SUCCESS,
  FETCH_STORES_ERROR,
  AXIOS_ERROR,
  CLEAR_AXIOS_ERROR,
  FETCH_BUILDER_NAME,
  FETCH_BUILDER_FLAG,
  DISABLE_LOADING,
  API_ERROR,
  ENABLE_LOADING,
  FINISH_LOADING,
  FETCH_BUILDER_JOB_SUCCESS,
  POST_USER_ACKNOWLEDGEMENT_SUCCESS,
  FETCH_REGEX_VALIDATE_SUCCESSS,
  FETCH_MESSAGE_VALIDATE_SUCCESSS,
  CLOSE_PASSWORD_DIALOG_SUCCESS,
  PUT_PASSWORD_SP_SUCCESSS,
  FETCH_PIC_SUCCESS,
  FETCH_PIC_ERROR,
  CHECK_AUTH,
  DISABLE_CHECK_AUTH,
  SHOW_LOG_MEET_HISTORY
} from "../constant/constant";

const initialState = {
  pending: false,
  finishLoading: false,
  store: [],
  PIC: [],
  error: null,
  errorStatus: null,
  builderName: "",
  builderFlag: false,
  builderJob: [],
  regexObject: {},
  message: {},
  checkAuth: false,
  showLogMeetHistory: false
};

export const storesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STORES_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_STORES_SUCCESS:
      return {
        ...state,
        pending: false,
        store: action.stores,
        error: null
      };
    case FETCH_STORES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    case FETCH_PIC_SUCCESS:
      return {
        ...state,
        pending: false,
        PIC: action.PIC,
        error: null
      };
    case FETCH_PIC_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    case AXIOS_ERROR:
      return {
        ...state,
        axiosError: action.error,
        pending: false
      };
    case CLEAR_AXIOS_ERROR:
      return {
        ...state,
        axiosError: null
      };
    case FETCH_BUILDER_NAME:
      return {
        ...state,
        builderName: action.data.data.builderName
      };
    case FETCH_BUILDER_FLAG:
      return {
        ...state,
        builderFlag: action.data.data.LogMeetFlag
      };
    case ENABLE_LOADING:
      return {
        ...state,
        pending: true,
        finishLoading: false
      };
    case DISABLE_LOADING:
      return {
        ...state,
        pending: false
      };
    case API_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    case FINISH_LOADING:
      return {
        ...state,
        finishLoading: true
      };
    case FETCH_BUILDER_JOB_SUCCESS:
      return {
        ...state,
        builderJob: action.builderJob,
        error: null
      };
    case POST_USER_ACKNOWLEDGEMENT_SUCCESS:
      // window.location.href = action.data.url;
      // break;
      window.open(action.data.url, "_blank");
      // setInterval(() => {
      //   window.location.reload();
      // }, 800);
      return {
        ...state,
        type: action.type
      };
    case FETCH_REGEX_VALIDATE_SUCCESSS:
      return {
        ...state,
        regexObject: action.regexObject
      };
    case FETCH_MESSAGE_VALIDATE_SUCCESSS:
      return {
        ...state,
        message: action.message
      };
    case CLOSE_PASSWORD_DIALOG_SUCCESS:
      return {
        ...state,
        type: action.type
      };
    case PUT_PASSWORD_SP_SUCCESSS:
      return {
        ...state,
        type: action.type
      };
    case CHECK_AUTH:
      return {
        ...state,
        checkAuth: true
      };
    case DISABLE_CHECK_AUTH:
      return {
        ...state,
        checkAuth: false
      };
    case SHOW_LOG_MEET_HISTORY:
      return {
        ...state,
        showLogMeetHistory: !state.showLogMeetHistory,
        pending: false
      }
    default:
      return state;
  }
};
export const getStores = state => state;
export const getPIC = state => state;
export const getStoresPending = state => state;
export const getStoresError = state => state;
export const getErrorStatus = state => state;
export const getBuilderName = state => state;
export const getBuilderFlag = state => state;
