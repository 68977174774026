import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { Typography } from "@material-ui/core";
import { format } from "date-fns";
import ButtonRobot from "../../common/ButtonRobot";
import PlayCircleOutlineOutlinedIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import "../../common/css/base.css";
import "../../common/css/sites-staft.css";
import { BUTTON_LOGKUN_SP } from "common/constants/constants";
import RobotStatus from "common/RobotStatus";
import Button from "@material-ui/core/Button";
import ReorderIcon from "@material-ui/icons/Reorder";

class IndexBuilderSP extends Component {
  render() {
    const {
      sites,
      to,
      showDialog,
      showDialogRobots,
      robotsUpdate,
      isCompleteSite
    } = this.props;

    return (
      <>
        <Grid container justify="flex-end" className="p-5">
          <Button
            onClick={this.props.fetchSiteList}
            size="small"
            variant="text"
            className="button-add"
          >
            <ReorderIcon className="icon-margin" />
            {isCompleteSite ? "現場一覧へ戻る" : "完了現場一覧"}
          </Button>
        </Grid>
        <Typography
          variant="h5"
          className="dashboard-sp-title font-family text-bold"
        >
          {isCompleteSite ? "完了現場一覧" : "全ての現場"}
        </Typography>
        <MenuList style={{ paddingBottom: 100 }}>
          {sites.map(site => (
            <MenuItem
              onClick={to(site.uniqueCode)}
              key={site.uniqueCode}
              className="dashboard-sp-item"
            >
              <Grid container justify="space-between" wrap="nowrap">
                <Grid className="item-left">
                  <Typography
                    variant="h5"
                    className="text-bold font-family white-space-normal"
                    style={{ fontSize: "1rem" }}
                  >
                    {site.name}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className="font-family grey-light white-space-normal"
                  >
                    {site.lastRecordedAt
                      ? (format(new Date(site.lastRecordedAt), "yyyy年M月d日 HH:mm 更新"))
                      : (format(new Date(site.regisionDate), "yyyy年M月d日 HH:mm 現場登録"))
                    }
                  </Typography>
                </Grid>
                <Grid
                  className="item-right"
                  style={{ flexDirection: "row", alignItems: "center" }}
                  onClick={e => e.stopPropagation()}
                >
                  <Fab
                    className={
                      site.logWalkViewer
                        ? "white-text button-logWalk-sp mx-5"
                        : "white-text button-logWalk-sp-disable mx-5"
                    }
                    size="small"
                    onClick={e => {
                      if (site.logWalkViewer) {
                        this.props.showDialogDrawing(site.uniqueCode);
                      }
                      e.stopPropagation();
                    }}
                  >
                    <PlayCircleOutlineOutlinedIcon
                      style={{ textTransform: "none" }}
                    />
                  </Fab>
                  <RobotStatus
                    typeOfRobotStatus={BUTTON_LOGKUN_SP}
                    robots={site.robots}
                    robotsUpdate={[robotsUpdate]}
                  />
                </Grid>
              </Grid>
            </MenuItem>
          ))}
        </MenuList>
        <Fab className="button-add-sp">
          <label onClick={showDialog} style={{ color: "inherit" }}>
            <AddIcon className="white-text icon" />
          </label>
        </Fab>
      </>
    );
  }
}

IndexBuilderSP.propTypes = {
  sites: PropTypes.array.isRequired,
  to: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired,
  showDialogRobots: PropTypes.func.isRequired
};

export default IndexBuilderSP;
