import React, { useEffect, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import marzipano from "marzipano";
import querySearch from "stringquery";
import { api } from "../../../../common/api/api";

ViewLogWalkImage.propTypes = {};

function ViewLogWalkImage() {
  const { id } = useParams();
  const location = useLocation();
  let history = useHistory();
  const marzipanoRef = useRef(null);

  useEffect(() => {
    if (id) {
      let yaw = 0;
      let pitch = 0;
      if (location.search !== "") {
        yaw = querySearch(location.search).yaw ?? 0;
        pitch = querySearch(location.search).pitch ?? 0;
      }

      const classification = location.pathname.includes("/sp/")
        ? "supplier"
        : "builder";

      api.get(`/${classification}/logwalk-viewer/shoots/${id}`).then(res => {
        const tilesPath = res.data.tile;
        const previewPath = res.data.preview;
        setPanorama(tilesPath, previewPath, yaw, pitch);
      });
    } else {
      history.push("/not-found");
    }
  });

  const setPanorama = (tilesPath, previewPath, yaw, pitch) => {
    let viewer;
    const viewerOpts = {
      controls: {
        mouseViewMode: "drag"
      }
    };
    const panoElement = marzipanoRef.current;

    viewer = new marzipano.Viewer(panoElement, viewerOpts);
    const source = marzipano.ImageUrlSource.fromString(tilesPath, {
      cubeMapPreviewUrl: previewPath
    });

    var levels = [
      {
        tileSize: 256,
        size: 256,
        fallbackOnly: true
      },
      {
        tileSize: 512,
        size: 512
      },
      {
        tileSize: 512,
        size: 1024
      },
      {
        tileSize: 512,
        size: 2048
      }
    ];
    var geometry = new marzipano.CubeGeometry(levels);

    var limiter = marzipano.util.compose(
      marzipano.RectilinearView.limit.vfov(Math.PI*10/180, Math.PI*120/180),
      marzipano.RectilinearView.limit.hfov(Math.PI*10/180, Math.PI*120/180),
      marzipano.RectilinearView.limit.pitch(pitch, pitch),
      marzipano.RectilinearView.limit.yaw(yaw, yaw)
    );

    var view = new marzipano.RectilinearView(
      { yaw: yaw, pitch: pitch },
      limiter
    );

    var scene = viewer.createScene({
      source: source,
      geometry: geometry,
      view: view
    });
    scene.switchTo({}, () => {});

    let destinationViewParameters = {
      yaw: yaw,
      pitch: pitch,
      fov: "2.09439333333333",
      hfov: "2.09439333333333"
    };
    var options = {
      transitionDuration: 100
    };
    scene.lookTo(destinationViewParameters, options);
  };

  return (
    <div
      ref={marzipanoRef}
      style={{ overflow: "hidden", width: "100%", height: "100vh" }}
    />
  );
}

export default ViewLogWalkImage;
