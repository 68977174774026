import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CometChat } from "@cometchat-pro/chat";
import { CometChatGroupListWithMessagesCustom } from "../../../../../../cometchat-pro-react-ui-kit-custom1/CometChatWorkspace/src";
import { api } from "../../../../../../common/api/api";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateShootHotspotInfoNoti } from "../../../action/action"

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    width: "100%"
  },
  sendMessage: {
    width: "100%",
    padding: "10px",
    display: "flex"
  },
  messageInput: {
    backgroundColor: "rgb(240, 240, 240) !important",
    borderRadius: "40px !important",
    padding: "0px 10px",
    "&::placeholder": {
      color: "#333333",
      opacity: 1
    }
  }
});

function TabMessage(props) {
  const { hotspotInfo, scrollTop } = props;
  const classes = useStyles();
  const [isLoginComet, setIsLoginComet] = useState(false);
  const [parentMessage, setParentMessage] = useState(null);
  const [listMention, setListMention] = useState(null);
  const [windowInnerHeight, setWindowInnerHeight] = useState(0);

  const user = useSelector(
    state => state.auth.user
  );

  const cometChatUser = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.isLoginCometChat
  );

  let location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // let authToken = "";
    setWindowInnerHeight(window.innerHeight)
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    api
      .get(
        `/${classification}/logwalk-viewer/infos/${hotspotInfo?.uniqueCode}/mentions`
      )
      .then(res => {
        setListMention(res.data.filter(e => e.uniqueCode !== user.uniqueCode));
      })
      .catch(e => {
        setListMention([]);
      });

  }, []);

  const updateShootHotspotInfoNotiChat = (newHasChat) => {
    const data = {
      uniqueCode: hotspotInfo.uniqueCode,
      hasChat: newHasChat
    }
    dispatch(updateShootHotspotInfoNoti(data))
  }

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return (
    <div className={classes.root}>
      <div
        style={{
          height:
            (/Safari/i.test(userAgent)) ? "100%" : ((/android/i.test(userAgent)) ? window.innerHeight : windowInnerHeight +
              (scrollTop === 0
                ? document.getElementById("chat-block").scrollTop -
                document.getElementById("messages-block").offsetTop + 50
                : scrollTop)),
          width: "100%",
          position: "relative",
          maxHeight:
            (/Safari/i.test(userAgent)) ? "100%" : ((/android/i.test(userAgent)) ? window.innerHeight : windowInnerHeight -
              (document.getElementById("hotspot-header")
                ? document.getElementById("hotspot-header").offsetHeight
                : 0))
        }}
      >
        {
          cometChatUser && listMention && (
            <CometChatGroupListWithMessagesCustom
              guid={props.hotspotInfo.sitesUC}
              parentMessage={{ id: props.messageId }}
              hotspotInfo={props.hotspotInfo}
              listMention={listMention}
              cometChatUser={cometChatUser}
              updateShootHotspotInfoNotiChat={updateShootHotspotInfoNotiChat}
            />
          )
        }
      </div>
    </div>
  );
}

export default TabMessage;
