import React, { Component } from "react";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";
import AppBar from "@material-ui/core/AppBar";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { logoutUser } from "../../components/auth/service/service";
import InputControl from "../../common/InputControl";

import { regexSignUp } from "../../components/auth/service/service";
import { translateMessage } from "utils/mapper";
import {
  messageValidate,
  translateValidate,
  closePasswordDialog,
  putPasswordSP
} from "../../common/Stores/service/service";
import { apiError } from "../../common/Stores/action/action";

import "../css/base.css";
import "../../common/css/header.css";

class HeaderSP extends Component {
  state = {
    anchorEl: null,
    isDialogShow: false,
    isShowDialogLogout: false,
    isShowDialogLicence: false,
    initialValues: {
      password: "",
      password_confirm: ""
    },
    anchorElHelpPage: null
  };

  componentDidMount() {
    this.props.messageValidate();
    this.props.regexSignUp();
    this.props.translateValidate();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type && nextProps.type === "PUT_PASSWORD_SP_SUCCESSS") {
      this.closeDialog();
    }
  }

  closeDialog() {
    this.props.apiError();
    this.props.closePasswordDialog();
    this.setState({
      isDialogShow: false,
      anchorEl: null
    });
  }

  showMenu = e => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  closeMenu = () => {
    this.setState({
      anchorEl: null
    });
  };

  linkTo = () => {
    this.props.history.push("/sp/dashboard");
    this.setState({
      anchorEl: null
    });
  };

  logout = () => {
    this.props.logoutUser();
  };

  togglePasswordDialog = () => {
    this.props.apiError();
    this.setState({
      isDialogShow: !this.state.isDialogShow,
      anchorEl: null
    });
  };

  toggleDialogLogout = () => {
    this.setState({
      isShowDialogLogout: !this.state.isShowDialogLogout,
      anchorEl: null
    });
  };

  toggleDialogLicence = () => {
    this.setState({
      isShowDialogLicence: !this.state.isShowDialogLicence,
      anchorEl: null
    });
  };

  getMessage = (key, message) => {
    return message[key];
  };

  onSubmit = values => {
    const { message, putPasswordSP, regex, regexObject } = this.props;
    const messageError = {
      password: "",
      password_confirm: ""
    };

    const { password, password_confirm } = values;
    if (password || password_confirm) {
      if (password_confirm === password && password.match(regex["password"])) {
        putPasswordSP({ password: password });
      } else {
        if (password && password_confirm !== password) {
          messageError["password_confirm"] = translateMessage(
            this.getMessage("match", message),
            regexObject
          );
        }
      }
    }

    if (password && !password.match(regex["password"])) {
      messageError["password"] = translateMessage(
        this.getMessage("password", message),
        regexObject
      );
    }

    if (!password) {
      messageError["password"] = translateMessage(
        this.getMessage("password", message),
        regexObject
      );
    }

    if (!password_confirm) {
      messageError["password_confirm"] = translateMessage(
        this.getMessage("required", message),
        regexObject
      );
    }

    if (!password && !password_confirm) {
      messageError["password"] = translateMessage(
        this.getMessage("password", message),
        regexObject
      );
      messageError["password_confirm"] = translateMessage(
        this.getMessage("required", message),
        regexObject
      );
    }
    this.props.apiError(messageError);
  };

  showHelpPageMenu = e => {
    this.setState({
      anchorElHelpPage: e.currentTarget
    });
  };

  closeHelpPageMenu = () => {
    this.setState({
      anchorElHelpPage: null
    });
  };

  render() {
    const {
      anchorEl,
      isDialogShow,
      initialValues,
      isShowDialogLogout,
      isShowDialogLicence,
      anchorElHelpPage
    } = this.state;

    const open = Boolean(anchorEl);
    const openHelpPage = Boolean(anchorElHelpPage);
    const id = open ? "simple-popover" : undefined;
    const onClose = () => {
      this.closeDialog();
    };
    return (
      <AppBar position="static" color="default" className="header-menu">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          className="header-sp"
        >
          <IconButton
            size="small"
            edge="start"
            className="header-menu-button"
            aria-label="menu-button"
            onClick={this.showMenu}
          >
            <MenuIcon className="icon white-text" />
          </IconButton>

          <IconButton
            size="small"
            aria-label="menu-button"
            className="white-text reset-link"
            onClick={this.showHelpPageMenu}
          >
            <HelpOutlineIcon className="icon" />
          </IconButton>
        </Grid>

        {isDialogShow && (
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={values => this.onSubmit(values)}
          >
            {formik => (
              <Dialog
                open={isDialogShow}
                aria-labelledby="max-width-dialog-title"
                maxWidth="xs"
                PaperProps={{
                  className: "dialog dialog-sign-up w100"
                }}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    formik.handleSubmit();
                  }
                }}
                onClose={() => {}}
              >
                <Fab
                  onClick={onClose}
                  size="small"
                  className="white-text close-button"
                >
                  <CloseIcon />
                </Fab>

                <DialogContent>
                  <Field
                    label={
                      <Typography variant="subtitle1" className="label">
                        新しいパスワード
                        <span className="required">[必須]</span>
                      </Typography>
                    }
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    variant="filled"
                    className="input-reset"
                    component={InputControl}
                  />

                  {this.props.submitErrors &&
                  this.props.submitErrors["password"] ? (
                    <span className="error">
                      {this.props.submitErrors["password"]}
                    </span>
                  ) : (
                    <span style={{ color: "#909090" }}>
                      半角英字、半角数字を含む8文字以上をご入力ください
                    </span>
                  )}

                  <Field
                    label={
                      <Typography variant="subtitle1" className="label">
                        新しいパスワード（再入力）
                        <span className="required">[必須]</span>
                      </Typography>
                    }
                    name="password_confirm"
                    type="password"
                    onChange={formik.handleChange}
                    variant="filled"
                    className="input-reset"
                    component={InputControl}
                  />
                  {this.props.submitErrors &&
                    this.props.submitErrors["password_confirm"] && (
                      <span className="error">
                        {this.props.submitErrors["password_confirm"]}
                      </span>
                    )}
                </DialogContent>
                <DialogActions className="dialog-action wrap dialog-action-sp">
                  <Button
                    onClick={formik.handleSubmit}
                    size="large"
                    className="dialog-button"
                  >
                    登録
                  </Button>
                  <Grid className="confirm-action">
                    <Button onClick={onClose}>キャンセル</Button>
                  </Grid>
                </DialogActions>
              </Dialog>
            )}
          </Formik>
        )}

        {isShowDialogLogout && (
          <Dialog
            open={isShowDialogLogout}
            aria-labelledby="max-width-dialog-title"
            maxWidth="xs"
            PaperProps={{
              className: "dialog dialog-sign-up w100"
            }}
            onClose={() => {}}
          >
            <Fab
              onClick={this.toggleDialogLogout}
              size="small"
              className="white-text close-button"
            >
              <CloseIcon />
            </Fab>

            <DialogContent>
              <p style={{ fontSize: "1rem", textAlign: "center" }}>
                本当にログアウトしますか？
              </p>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                onClick={this.logout}
                variant="contained"
                style={{
                  width: 150,
                  backgroundColor: "#E8E8E8",
                  fontSize: "1rem"
                }}
              >
                ログアウト
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {isShowDialogLicence && (
          <Dialog
            open={isShowDialogLicence}
            aria-labelledby="max-width-dialog-title"
            maxWidth="xs"
            PaperProps={{
              className: "dialog dialog-sign-up w100"
            }}
            onClose={() => {}}
          >
            <Fab
              onClick={this.toggleDialogLicence}
              size="small"
              className="white-text close-button"
            >
              <CloseIcon />
            </Fab>

            <DialogContent style={{ justifyContent: "center" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "30px 0px"
                }}
              >
                <Button
                  onClick={() => {
                    window.open("https://license.log-system.jp/", "_blank");
                    this.toggleDialogLicence();
                  }}
                  variant="contained"
                  style={{
                    width: "200px",
                    backgroundColor: "#E8E8E8",
                    fontSize: "1rem",
                    textAlign: "center"
                  }}
                >
                  ライセンス情報
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.closeMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuList className="popover-sites">
            <MenuItem className="popover-item" onClick={this.linkTo}>
              ホーム
            </MenuItem>
            <MenuItem
              onClick={this.togglePasswordDialog}
              className="popover-item"
            >
              パスワード変更
            </MenuItem>
            <MenuItem
              onClick={this.toggleDialogLicence}
              className="popover-item"
            >
              法律に基づく情報
            </MenuItem>
            <MenuItem
              onClick={this.toggleDialogLogout}
              className="popover-item"
            >
              ログアウト
            </MenuItem>
          </MenuList>
        </Popover>

        <Popover
          open={openHelpPage}
          anchorEl={anchorElHelpPage}
          onClose={this.closeHelpPageMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuList>
            <div
              style={{
                borderBottom: "1px solid black",
                cursor: "default",
                backgroundColor: "white",
                padding: "6px 16px",
                fontSize: "15px",
                outline: "none",
                userSelect: "none"
              }}
            >
              ヘルプ
            </div>
            <MenuItem
              onClick={() => {
                window.open("https://www.log-build.com/ls-help/sp/", "_blank");
              }}
            >
              マニュアルガイド
            </MenuItem>
            <MenuItem
              onClick={() =>
                window.open(
                  "https://www.log-build.com/ls-help/sp/#link001",
                  "_blank"
                )
              }
            >
              アプリダウンロード
            </MenuItem>
            <MenuItem
              onClick={() =>
                window.open(
                  "https://www.log-build.com/ls-help/sp/#link002",
                  "_blank"
                )
              }
            >
              お問合せ
            </MenuItem>
          </MenuList>
        </Popover>
      </AppBar>
    );
  }
}

const mapStateToProps = state => ({
  submitErrors: state.storesReducer.error,
  regex: state.auth.regex,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message,
  type: state.storesReducer.type
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logoutUser: logoutUser,
      regexSignUp: regexSignUp,
      messageValidate: messageValidate,
      translateValidate: translateValidate,
      apiError: apiError,
      closePasswordDialog: closePasswordDialog,
      putPasswordSP: putPasswordSP
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSP);
