import {
  fetchRobotDetailSuccess,
  fetchRobotEntriesSuccess,
  fetchRobotLogBuildEntriesSuccess,
  fetchRobotLogBuildDetailSuccess,
  fetchLogBuildBuilderEntriesSuccess,
  fetchLogBuildStaffEntriesSuccess,
  fetchLogBuildRobotHistorySuccess,
  postLogBuildRobotHistorySuccess,
  fetchLogBuildRobotLeaseHistorySuccess,
  putLogBuildRobotHistorySuccess,
  deleteLogBuildRobotHistorySuccess,
  fetchRobotHistorySuccess,
  fetchBuilderRobotAvailableSuccess,
  fetchRobotEditSuccess,
  wsUpdatedRobotsSuccess,
  wsClearRobotsSuccess,
  changeRobotUCSuccess
} from "../action/action";
import { api } from "../../../../common/api/api";

import {
  dispatchError,
  enableLoading,
  finishLoading
} from "../../../../common/Stores/service/service";

import { fetchRobotDetailInfo } from "../RobotInfo/service/service";

export const fetchRobotDetail = (id, showLoading) => {
  return dispatch => {
    if (showLoading) dispatch(enableLoading());
    api.get(`/robots/${id}`).then(res => {
      dispatch(fetchRobotDetailSuccess(res.data));
      if (showLoading) dispatch(finishLoading());
    });
  };
};

export const fetchRobotLogBuildDetail = (id, showLoading) => {
  return dispatch => {
    if (showLoading) dispatch(enableLoading());
    api.get(`/logbuild/robots/${id}/summary`).then(res => {
      dispatch(fetchRobotLogBuildDetailSuccess(res.data));
      if (showLoading) dispatch(finishLoading());
    });
  };
};

export const fetchRobotEntries = () => {
  return dispatch => {
    return api
      .get(`/robots/entries`)
      .then(res => {
        dispatch(fetchRobotEntriesSuccess(res.data));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchLogBuildBuilderEntries = () => {
  return dispatch => {
    api
      .get(`/logbuild/builders/entries`)
      .then(res => {
        dispatch(fetchLogBuildBuilderEntriesSuccess(res.data));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchLogBuildStaffEntries = () => {
  return dispatch => {
    api
      .get(`/logbuild/staffs/entries`)
      .then(res => {
        dispatch(fetchLogBuildStaffEntriesSuccess(res.data));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchRobotLogBuildEntries = () => {
  return dispatch => {
    api
      .get(`/logbuild/robots/entries`)
      .then(res => {
        dispatch(fetchRobotLogBuildEntriesSuccess(res.data));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchRobotDetailPage = id => {
  return dispatch => {
    dispatch(enableLoading());
    Promise.all([
      dispatch(fetchRobotDetailInfo(id)),
      dispatch(fetchBuilderRobotHistory(id)),
      dispatch(fetchRobotEntries())
    ])
      .then(() => {
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchRobotLogBuildDetailPage = id => {
  return dispatch => {
    dispatch(enableLoading());
    Promise.all([
      dispatch(fetchRobotLogBuildDetail(id)),
      dispatch(fetchRobotLogBuildEntries()),
      dispatch(fetchLogBuildBuilderEntries()),
      dispatch(fetchLogBuildStaffEntries())
    ])
      .then(res => {
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchRobotHistory = robotsUC => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/robot-leases?robotsUC=${robotsUC}`)
      .then(res => {
        dispatch(fetchLogBuildRobotHistorySuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const postRobotHistory = data => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/logbuild/robot-leases`, data)
      .then(res => {
        dispatch(postLogBuildRobotHistorySuccess(res.data));
        dispatch(fetchRobotHistory(data.robotsUC));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchRobotLeaseHistory = (robotsUC, index) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/robot-leases/${robotsUC}.${index}`)
      .then(res => {
        dispatch(fetchLogBuildRobotLeaseHistorySuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const updateRobotHistory = (data, robotsUC, index) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/logbuild/robot-leases/${robotsUC}.${index}`, data)
      .then(res => {
        dispatch(putLogBuildRobotHistorySuccess(res.data));
        dispatch(fetchRobotHistory(robotsUC));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const deleteRobotHistory = (robotsUC, index) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/logbuild/robot-leases/${robotsUC}.${index}`)
      .then(res => {
        dispatch(deleteLogBuildRobotHistorySuccess(res.data));
        dispatch(fetchRobotHistory(robotsUC));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchBuilderRobotHistory = robotsUC => {
  return dispatch => {
    return api
      .get(`builder/robot-assign-logs?robotsUC=${robotsUC}`)
      .then(res => {
        dispatch(fetchRobotHistorySuccess(res.data));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchBuilderRobotAvailable = robotsUC => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .head(`/logbuild/builder-robot-lease-candidates/${robotsUC}`)
      .then(res => {
        dispatch(fetchBuilderRobotAvailableSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchRobotEdit = id => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/robots/${id}`)
      .then(res => {
        dispatch(fetchRobotEditSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const wsUpdatedRobots = wsUpdatedRobots => {
  return dispatch => {
    dispatch(wsUpdatedRobotsSuccess(wsUpdatedRobots));
  };
};

export const wsClearRobots = () => {
  return dispatch => {
    dispatch(wsClearRobotsSuccess());
  };
};

export const changeRobotUC = robotUC => {
  return dispatch => {
    dispatch(changeRobotUCSuccess(robotUC));
  };
};
