import React from "react";

function IconExpandSelect() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.828"
      height="8.414"
      viewBox="0 0 14.828 8.414"
    >
      <path
        fill="none"
        stroke="#C7C7C7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1.414 1.414l5.974 6 6.026-6"
      ></path>
    </svg>
  );
}

export default IconExpandSelect;