import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PlayCircleOutlineOutlinedIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { format } from "date-fns";
import "../../common/css/base.css";
import "../../common/css/dashboard.css";
import SiteDialog from "../sites/site/SiteDialog";
import CardImage from "../../common/CardImage";
import { fetchSiteDrawings } from "../sites/site/service/service";
import RobotStatus from "common/RobotStatus";
import { BUTTON_LOGKUN } from "common/constants/constants";
import SiteList from "../../common/SiteList/SiteList";
import SiteCardList from "../../common/SiteList/SiteCardList";
import ListIcon from "@material-ui/icons/List";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import SearchIcon from "@material-ui/icons/Search";
import { changeTypeListDashboard } from "./service/service";
const widthItem = 300;

class DashboardBuilder extends Component {
  constructor() {
    super();
    this.state = {
      isDialogShow: false,
      isShowMore: true,
      numberData: 1,
      maxCols: Math.floor(
        (document.documentElement.clientWidth - 70) / widthItem
      ),
      dashboardData: null,
      searching: false
    };
  }

  handleResize = e => {
    this.setState({
      maxCols: Math.floor(
        (document.documentElement.clientWidth - 70) / widthItem
      )
    });
    this.changeWidthContainer(!this.props.isCardList);
  };

  componentWillMount() {
    this.setState({
      dashboardData: this.props.dashboards
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
    this.changeWidthContainer(false);
  }

  showMore = () => {
    this.setState({
      numberData: this.state.numberData + 1
    });
  };

  changeWidthContainer = isTable => {
    const headerEl = document.getElementById("header-builder");
    if (headerEl) {
      if (isTable) {
        const el = document.getElementsByClassName("sites-table")[0];

        headerEl.style.minWidth =
          (el ? el.offsetWidth + el.offsetLeft : 0) + "px";
      } else {
        headerEl.style.minWidth = null;
      }
    }
  };

  changeTypeList = () => {
    this.props.changeTypeListDashboard(!this.props.isCardList);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isCardList !== this.props.isCardList) {
      setTimeout(() => {
        this.changeWidthContainer(!nextProps.isCardList);
      }, 20);
    }

    if (nextProps.history.location.state && nextProps.history.location.state.reload) {
      this.props.changeTypeListDashboard(true);
      nextProps.history.location.state.reload = false;
    }
  }

  searchInput = e => {
    const value = e.target.value.trim();
    this.setState({ searching: true }, () => {
      if (this.inputTimeOut) {
        clearTimeout(this.inputTimeOut);
      }
      this.inputTimeOut = setTimeout(() => {
        const { dashboards } = this.props;
        let dashboardData = dashboards.filter(item =>
          item.name.includes(value)
        );
        this.setState({ dashboardData, searching: false });
      }, 500);
    });
  };

  render() {
    const { isDialogShow, dashboardData, searching } = this.state;
    const { dashboards, robotsUpdate, isCardList } = this.props;
    const showDialog = () => {
      this.setState({
        isDialogShow: true
      });
    };

    const closeDialog = () => {
      this.setState({
        isDialogShow: false
      });
    };

    return (
      <Grid className="dashboard page-content">
        <Grid className="dashboard-head">
          <Typography variant="h5" className="dashboard-title text-bold">
            登録する
          </Typography>
          <Button
            onClick={showDialog}
            size="large"
            className="dashboard-button"
          >
            <Typography variant="h6">現場を登録する</Typography>
          </Button>
        </Grid>
        <Grid>
          <Grid container alignItems="center">
            <Typography variant="h5" className="text-bold display-inline-block">
              担当現場
            </Typography>
            <Fab
              className={`icon-transparent ml-10`}
              size="large"
              onClick={this.changeTypeList}
            >
              {isCardList ? (
                <ListIcon className="icon" />
              ) : (
                <ViewModuleIcon className="icon" />
              )}
            </Fab>
            <Grid className="search-input ml-10">
              <SearchIcon
                className="icon"
                style={{ fontSize: 24, color: "#bebebe" }}
              />
              <input
                type="text"
                className="browser-default"
                placeholder="現場名で検索"
                onChange={this.searchInput}
              ></input>
            </Grid>
          </Grid>

          {!searching && (
            <>
              {isCardList ? (
                <SiteCardList
                  siteData={dashboardData}
                  to={this.props.to}
                  showDialog={this.props.showDialog}
                  showDialogDrawing={this.props.showDialogDrawing}
                  robotsUpdate={robotsUpdate}
                />
              ) : (
                <SiteList
                  siteData={dashboardData}
                  to={this.props.to}
                  showDialog={this.props.showDialog}
                  showDialogDrawing={this.props.showDialogDrawing}
                  robotsUpdate={robotsUpdate}
                  isDashboard={true}
                />
              )}
            </>
          )}
        </Grid>

        {isDialogShow && (
          <SiteDialog
            isDialogShow={isDialogShow}
            onClose={closeDialog}
            isEdit={false}
          />
        )}
      </Grid>
    );
  }
}

DashboardBuilder.propTypes = {
  dashboards: PropTypes.array.isRequired,
  to: PropTypes.func.isRequired,
  shouldEnableRobotButton: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isCardList: state.dashboardsReducer.isCardList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSiteDrawings,
      changeTypeListDashboard
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardBuilder));
