import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from "@material-ui/core/Popover";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import TableRow from "@material-ui/core/TableRow";
import DialogDelete from "../../common/DialogDelete";
import FeatureFlag from "../../common/img/FeatureFlag.svg";
import "../../common/css/base.css";
import "../../common/css/feature-flag.css";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button
} from "@material-ui/core";
import {
  PUT_FEATURE_SUCCESS,
  FETCH_FEATURE_LIST_SUCCESSS,
  GET_FEATURE_DETAIL_SUCCESS
} from "./constant/constant";
import {
  fetchFeatureList,
  putFeature,
  getFeatureDetail
} from "./service/service";
import { dispatchError, finishLoading } from "common/Stores/service/service";
const Element = {
  Info: 1,
  Contract: 2,
  Robot: 3,
  FeatureFlag: 4
};
class Feature extends Component {
  state = {
    anchorEl: null
  };

  componentDidMount() {
    this.props.fetchFeatureList();
  }

  componentWillReceiveProps(nextProps) {
    // if (this.props.match.params.id !== nextProps.match.params.id) {
    //   this.props.fetchBuilderFeatureFlag(nextProps.match.params.id);
    // }
    if (nextProps.type === PUT_FEATURE_SUCCESS) {
      this.setState({ isDialogConfirm: false, anchorEl: null });
    }
    if (nextProps.type === GET_FEATURE_DETAIL_SUCCESS) {
      this.setState({
        isShowDialogDetail: true
      });
    }
  }

  onUpdateStatusSubmit = () => {
    this.props.putFeature(
      {
        Id: this.state.FeatureFlagIdSelected,
        Status: this.state.FeatureFlagStatusSelected
      });

  };
  showPopover = (Id, Status) => e => {
    this.setState({
      anchorEl: e.currentTarget,
      FeatureFlagIdSelected: Id,
      // FeatureFlagStatusSelected: Status 
    });
  };

  showDialogDetail = (Id) => {
    this.props.getFeatureDetail(Id)
  };

  closePopover = () => {
    this.setState({
      anchorEl: null
    });
  };

  to(id) {
    this.props.history.push("/logbuild/builders/" + id + "?tab=featureflag");
  }

  render() {
    const { anchorEl, FeatureFlagStatusSelected, isShowDialogDetail } = this.state;
    const { features, featureDetail } = this.props;

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const days = ['日', '月', '火', '水', '木', '金', '土'];
    const onCloseDialogConfirm = () => {
      this.setState({ isDialogConfirm: false, anchorEl: null });
    };
    const onShowDialogConfirmTrue = () => {
      this.setState({
        isDialogConfirm: true,
        anchorEl: null,
        FeatureFlagStatusSelected: true
      });
    };
    const onShowDialogConfirmFalse = () => {
      this.setState({
        isDialogConfirm: true,
        anchorEl: null,
        FeatureFlagStatusSelected: false
      });
    };
    const closeDialogDetail = () => {
      this.setState({
        isShowDialogDetail: false,
      });
    };
    return (
      <Grid className="dashboard">
        <Typography variant="h5" className="dashboard-title text-bold">
          フィーチャーフラグ一覧
        </Typography>
        <TableContainer className="dashboard-loguibld-table background-white">
          <Grid>
            <TableContainer>
              <Table size="small" aria-label="table">
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell className="white-text text-center">フィーチャー名</TableCell>
                    <TableCell className="white-text text-center">（全工務店） ON / OFF</TableCell>
                    <TableCell></TableCell>
                    <TableCell className="white-text text-center">最終更新日時</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="table-body">
                  {features.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {item.Name}
                      </TableCell>
                      <TableCell className={`text-center ${item.Status ? "color-red" : ""}`}>{item.Status ? "ON" : "OFF"}</TableCell>
                      <TableCell className="padding-last-td s-ta-r text-left">
                        <Fab
                          className={"white-text button-logWalk button-feature-flag mr-5"}
                          variant="extended"
                          onClick={e => {
                            this.showDialogDetail(item.Id)
                          }}
                        >
                          <img src={FeatureFlag} />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;工務店一覧
                        </Fab>
                      </TableCell>

                      <TableCell className="text-center">
                        <p>
                          {item.UpdatedAt && format(new Date(item.UpdatedAt), "yyyy.MM.dd")} ({days[new Date(item.UpdatedAt).getDay()]})
                        </p>
                        <p>
                          {item.UpdatedAt && format(new Date(item.UpdatedAt), "HH:mm")}
                        </p>
                      </TableCell>
                      <TableCell className="padding-last-td s-ta-r">
                        <Fab
                          onClick={this.showPopover(item.Id, item.Status)}
                          size="small"
                          className="more-vert-btn"
                        >
                          <MoreVertIcon />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={this.closePopover}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <MenuList className="popover-sites">
              <MenuItem onClick={onShowDialogConfirmTrue}>ONにする</MenuItem>
              <MenuItem onClick={onShowDialogConfirmFalse}>OFFにする</MenuItem>
            </MenuList>
          </Popover>
          <DialogDelete
            open={this.state.isDialogConfirm}
            onClose={onCloseDialogConfirm}
            textTop={FeatureFlagStatusSelected ? "ONにしますか？" : "OFFにしますか？"}
            textBottom={""}
            titleCancel={"いいえ"}
            titleSubmit={"はい"}
            onDeleteSubmit={this.onUpdateStatusSubmit}
            PaperProps={{
              className: "dialog dialog-sign-up"
            }}
          />
          {isShowDialogDetail && (
            <Grid
              className={`copy-drawing copy-drawing-container `}
            >
              <Grid className="copy-drawing-container-child">
                <Grid
                  className={"copy-drawing-wrapper feature-flag-detail-dialog"}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <React.Fragment>
                    <Fab
                      onClick={closeDialogDetail}
                      size="small"
                      className="white-text close-button-2"
                      style={{ right: "-20px" }}
                    >
                      <CloseIcon />
                    </Fab>

                    <Grid className="p-10">
                      <Typography variant="body2" className="menu-note">
                      ONの工務店一覧 {featureDetail.isOn} / {featureDetail.total}
                      </Typography>
                    </Grid>
                    <Grid className={"menu-content"}>
                      {featureDetail && featureDetail.builders &&
                        featureDetail.builders.map(
                          (item, idx) =>
                            <MenuItem
                              className="menu-item"
                              disabled={false}
                              key={item.UniqueCode}
                              title={item.name}
                              onClick={() => this.to(item.UniqueCode)}
                            >
                              <Grid
                                key={item.UniqueCode}
                                className="menu-item-container"
                              >
                                <Grid className="item-text">
                                  <Typography className="item-name">
                                    {item.Name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </MenuItem>
                        )}
                    </Grid>
                  </React.Fragment>
                </Grid>
              </Grid>
            </Grid>
          )}
        </TableContainer>
      </Grid>

    );
  }
}

const mapStateToProps = state => ({
  features: state.featureReducer.featureListReducer.features,
  featureDetail: state.featureReducer.featureListReducer.featureDetail,
  error: state.storesReducer.error,
  type: state.featureReducer.featureListReducer.type
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchFeatureList,
      putFeature,
      getFeatureDetail,
      dispatchError,
      finishLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Feature);

