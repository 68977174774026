import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import { getStaffs, getUserDetail } from "./reducer/reducer";
import { fetchStaff } from "./service/service";
import { bindActionCreators } from "redux";
import "../../../common/css/base.css";
import "../../../common/css/sites-staft.css";
import { apiError } from "../../../common/Stores/action/action";
import { regexSignUp } from "../../auth/service/service";
import {
  translateValidate,
  messageValidate,
  checkAuth
} from "../../../common/Stores/service/service";
import StaffDialog from "./StaffDialog";
import ChangePasswordDialog from "./ChangePasswordDialog";
import Sp from "../../../common/breakpoints/Sp";
import Pc from "../../../common/breakpoints/Pc";
import StaftSP from "./StaftSP";
import StaftPC from "./StaftPC";
import ErrorDialog from "components/ErrorDialog";
import { AUTH_ERROR } from "./constant/constant";

class Staft extends Component {
  state = {
    showDialogError: false,
    isDialogShow: false,
    isDialogDeleteShow: false,
    isChangePasswordDialog: false,
    checkUpdate: false,
    unicodeEdit: "",
    anchorEl: null,
    staffDatas: [],
    staffsFilter: [],
    initialValues: {
      password: "",
      password_confirmation: ""
    },
    furiganaSearch: "",
    fetchInitSuccess: false
  };

  componentDidMount() {
    const {
      checkAuth,
      fetchStaff,
      regexSignUp,
      translateValidate,
      messageValidate
    } = this.props;
    fetchStaff(() => this.setState({fetchInitSuccess: true}));
    regexSignUp();
    translateValidate();
    messageValidate();
    checkAuth();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.staffs !== this.props.staffs && 
      this.props.staffs.length > 0 &&
      this.state.fetchInitSuccess
    ) {
      this.filter(this.state.furiganaSearch);
    }
  }

  showPopover = value => e => {
    this.props.apiError(null);
    this.setState({
      anchorEl: e.currentTarget,
      unicodeEdit: value.uniqueCode
    });
  };

  closePopover = () => {
    this.setState({
      anchorEl: null
    });
  };

  togglePasswordDialog = () => {
    if (this.state.isChangePasswordDialog) {
      this.props.checkAuth();
      this.props.fetchStaff();
    }
    this.setState({
      isChangePasswordDialog: !this.state.isChangePasswordDialog,
      anchorEl: null
    });
  };

  onChangeData = data => {
    this.setState({
      staffDatas: data
    });
  };

  onChangeSearch = data => {
    this.setState({
      furiganaSearch: data
    });
    this.filter(data);
  };

  filter = searchText => {
    let FilterArray = this.props.staffs.filter(function (element) {
      return element.furigana.includes(searchText) ||element.name.includes(searchText);
    });
    this.setState({
      staffsFilter: FilterArray
    });
  };

  toggleDialogDelete = () => {
    if (this.state.isDialogDeleteShow) {
      this.props.checkAuth();
      this.props.fetchStaff();
    }
    this.setState({
      isDialogDeleteShow: !this.state.isDialogDeleteShow,
      isDialogShow: false
    });
  };

  componentWillReceiveProps(nextProps) {
    if ((nextProps.userDetail.auth !== this.props.userDetail.auth)
      && !this.state.showDialogError
      && this.state.unicodeEdit !== this.props.auth.user.uniqueCode
      && (this.state.isDialogShow || this.state.isChangePasswordDialog
        || this.state.showDialog || this.state.isDialogDeleteShow)) {
      this.setState({
        showDialogError: true
      });
    }
    if ((nextProps.type === AUTH_ERROR)
      && !this.state.showDialogError
      && (this.state.isDialogShow || this.state.isChangePasswordDialog || this.state.showDialog)) {
      this.setState({
        showDialogError: true
      });
    }
  }

  onClose = () => {
    this.setState({
      showDialogError: false,
      isDialogShow: false,
      isChangePasswordDialog: false,
      showDialog: false,
      isDialogDeleteShow: false
    });
    this.props.fetchStaff();
  };

  render() {
    const {
      showDialogError,
      isDialogShow,
      isChangePasswordDialog,
      anchorEl,
      staffDatas,
      isDialogDeleteShow,
      staffsFilter
    } = this.state;
    const { staffs, regex, auth } = this.props;

    const showDialog = () => {
      this.setState({
        isDialogShow: true,
        unicodeEdit: "",
        checkUpdate: false
      });
    };

    const showDialogEdit = () => {
      this.setState({
        isDialogShow: true,
        checkUpdate: true,
        anchorEl: null
      });
    };

    const closeDialog = () => {
      this.setState({
        isDialogShow: false,
        checkUpdate: false
      });
      this.props.checkAuth();
      this.props.fetchStaff();
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const isAdmin = this.props.userDetail.auth === 0;

    return (
      <>
        <Sp>
          <StaftSP
            staffs={staffsFilter}
            showDialog={showDialog}
            showPopover={this.showPopover}
            userUC={auth.user.uniqueCode}
            isAdmin={isAdmin}
            onChangeSearch={this.onChangeSearch}
            furiganaSearch={this.state.furiganaSearch}
          />
        </Sp>
        <Pc>
          <StaftPC
            staffs={staffsFilter}
            staffDatas={staffDatas}
            showDialog={showDialog}
            showPopover={this.showPopover}
            onChangeData={this.onChangeData}
            userUC={auth.user.uniqueCode}
            isAdmin={isAdmin}
            onChangeSearch={this.onChangeSearch}
            furiganaSearch={this.state.furiganaSearch}
          />
        </Pc>

        {(isDialogShow || isDialogDeleteShow) && (
          <StaffDialog
            isDialogShow={isDialogShow}
            isDialogDeleteShow={isDialogDeleteShow}
            toggleDialogDelete={this.toggleDialogDelete}
            onClose={closeDialog}
            isEdit={this.state.checkUpdate}
            staffId={this.state.unicodeEdit}
          />
        )}

        {isChangePasswordDialog && (
          <ChangePasswordDialog
            isDialogShow={isChangePasswordDialog}
            onClose={this.togglePasswordDialog}
            staffId={this.state.unicodeEdit}
            initialValues={this.state.initialValues}
            regex={regex}
          />
        )}

        {showDialogError && (
          <ErrorDialog
            open={showDialogError}
            textContent="権限が変更されたため、操作が許可されませんでした"
            PaperProps={{
              className: "dialog dialog-sign-up"
            }}
            onClose={this.onClose}
          />
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.closePopover}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuList className="popover-sites">
            <MenuItem onClick={showDialogEdit}>編集</MenuItem>
            <MenuItem onClick={this.togglePasswordDialog}>
              パスワード変更
            </MenuItem>
          </MenuList>
        </Popover>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  uniqueCode: state.staffsReducer.uniqueCode,
  staffs: getStaffs(state.staffsReducer.staff),
  regex: state.auth.regex,
  userDetail: getUserDetail(state.staffsReducer.userDetail),
  type: state.staffsReducer.type
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchStaff: fetchStaff,
      apiError,
      regexSignUp: regexSignUp,
      translateValidate: translateValidate,
      messageValidate: messageValidate,
      checkAuth
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Staft));
