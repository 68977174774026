import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Component from "./Common";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getSiteInfo, getDrawingsWithSpots } from "./reducer/reducer";
import { fetchDrawingFile, sortDrawingImage } from "./service/service";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DrawingPhoto from "./DrawingPhoto";
import CompassView from "./compass/CompassView";
import CompassSetting from "./compass/CompassSetting";
import Fab from "@material-ui/core/Fab";
import RoomIcon from "@material-ui/icons/Room";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CompassIcon from "../../../common/img/compassbtnicon.svg";
import CompassIconSeleted from "../../../common/img/compassbtniconseleted.svg";
import { SET_REDIRECT_FROM_URL } from "./constant/constant";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import {
  translateValidate,
  messageValidate
} from "../../../common/Stores/service/service";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { getRedirectFromUrl } from "./reducer/reducer";
import { setRedirectFromUrl } from "./action/action";
import ChooseLogWalkModeButton from "./Maps/ChooseLogWalkModeButton";
import CommonMapOptionDialogSP from "./CommonMapOptionDialogSP";
import ViewTask from "./Maps/ViewTask";
import querySearch from "stringquery";

const maximumDrawing = 50;
const maxNameLength = 8;
const MOUSE_EVENT = { MOUSE_DOWN: 1, MOUSE_MOVE: 2 };
const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle
});
const numbers = new Array(50).fill(1).map((_, index) => index + 1);
class MapsBuilder extends Component {
  touchTimeout = null;
  scrollTimeout = null;
  unTouchTimeout = null;
  cancelSubmission = false;
  scrollData = {
    rangeMove: 0,
    startValue: 0
  };

  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.wrapperRef = React.createRef();
    this.drawingsRef = React.createRef();
    this.imgRef = React.createRef();
    this.handleDrawingPress = this.handleDrawingPress.bind(this);
    this.handleDrawingRelease = this.handleDrawingRelease.bind(this);
    this.onTouchMoveDrawing = this.onTouchMoveDrawing.bind(this);
  }

  state = {
    positions: [],
    drawingsWithSpots: this.props.drawingsWithSpots,
    currentDrawing: 0,
    showHideBtnPrev: false,
    showHideBtnNext: false,
    countFetching: 0,
    fetchDone: false,
    disablePan: true,
    isHoverSpot: false,
    noteText: false,
    isHoldKey: false,
    isCompassSetting: false,
    sitesUC: "",
    isSavingCompassSetting: false,
    selectedDrawing: [],
    isAddPoint: false,
    mouseEvent: null,
    isClickToDrawing: false,
    isMouseDown: false,
    isTouchDrawing: false,
    confirmCloseModeSettingLink: false
  };

  onUpdateTransform = ({ x, y, scale }) => {
    if (this.imgRef.current) {
      const value = make3dTransformValue({ x, y, scale });

      this.imgRef.current.style.setProperty("transform", value);
    }
  };

  componentWillReceiveProps(props) {
    this.setState(
      {
        drawingsWithSpots: this.props.drawingsWithSpots,
        sitesUC: this.props.match.params.id
      },
      () => {
        this.showHideButtonNextPrev();
        if (props.type !== SET_REDIRECT_FROM_URL) this.setPositionMarker();
      }
    );
  }

  componentWillMount() {
    this.setState({
      sitesUC: this.props.match.params.id
    });
  }

  componentDidMount() {
    this.setState({
      sitesUC: this.props.match.params.id
    });
    this.setCurrentDrawing();

    window.addEventListener("resize", this.updateDimensions);
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keyup", this.handleKeyUp);
    document.addEventListener("click", this.handleDrawingClick);

    const paramsSearch = querySearch(this.props.location.search);
    const keys = Object.keys(paramsSearch);
    if (keys.includes("info")) {
      this.props.setIsViewVRSpace(false);
    }
  }

  setCurrentDrawing = () => {
    if (
      this.props.redirectFromUrl.isRedirectFromUrl &&
      this.props.redirectFromUrl.currentDrawingUC
    ) {
      const currentDrawing = this.state.drawingsWithSpots.findIndex(
        item => item.uniqueCode === this.props.redirectFromUrl.currentDrawingUC
      );

      this.setState(
        {
          currentDrawing: currentDrawing === -1 ? 0 : currentDrawing
        },
        () => {
          const container = document.querySelector(".grid-list");
          const currentPosition = 196 * (this.state.currentDrawing + 1);

          if (container && container.clientWidth < currentPosition)
            container.scrollTo(currentPosition - container.clientWidth, 0);
        }
      );
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
    document.removeEventListener("click", this.handleDrawingClick);

    this.cancelSubmission = true;
  }

  onMouseDown = event => {
    const { isHoldKey, isHoverSpot, isAddPoint, isCompassSetting } = this.state;
    if (isHoldKey || isHoverSpot || isAddPoint || isCompassSetting) {
      this.setState({
        disablePan: false,
        noteText: false,
        mouseEvent:
          isAddPoint || isCompassSetting ? MOUSE_EVENT.MOUSE_DOWN : null,
        isMouseDown: true,
        isTouch: false
      });
    } else {
      this.setState({
        disablePan: true,
        noteText: true,
        mouseEvent:
          isAddPoint || isCompassSetting ? MOUSE_EVENT.MOUSE_DOWN : null,
        isMouseDown: true,
        isTouch: false
      });
    }
  };

  onTouchStart = e => {
    const scrollData = {
      rangeMove: e.touches[0].clientY,
      startValue: document.querySelector(".image-content").scrollTop
    };

    if (this.touchTimeout) {
      clearTimeout(this.touchTimeout);
    }

    if (this.unTouchTimeout) {
      clearTimeout(this.unTouchTimeout);
    }

    if (e.targetTouches.length === 2) {
      this.setState({
        touchesDefault: e.targetTouches,
        noteTextByTouch: false
      });
    } else {
      if (
        !this.state.noteTextByTouch &&
        !this.state.isCompassSetting &&
        !this.state.isDraggingCompass
      ) {
        this.touchTimeout = setTimeout(() => {
          this.setState({
            noteTextByTouch: true,
            touchesDefault: []
          });
        }, 0);
        this.scrollData = scrollData;
      }
    }
  };

  onTouchMove = e => {
    if (!e.currentTarget.classList.contains("noteText")) {
      document
        .querySelector(".image-content")
        .scrollTo(
          0,
          this.scrollData.startValue -
            (e.touches[0].clientY - this.scrollData.rangeMove)
        );
    }

    e.stopPropagation();
  };

  onTouchEnd = () => {
    if (this.unTouchTimeout) {
      clearTimeout(this.unTouchTimeout);
    }

    this.scrollData = {
      rangeMove: 0,
      startValue: 0
    };

    this.unTouchTimeout = setTimeout(() => {
      this.setState({
        noteText: false
      });
    }, 1000);
  };

  onMouseMove = () => {
    const { isHoldKey, isAddPoint, mouseEvent, isCompassSetting } = this.state;
    if (
      (isAddPoint || isCompassSetting) &&
      mouseEvent === MOUSE_EVENT.MOUSE_DOWN
    ) {
      this.setState({
        mouseEvent: MOUSE_EVENT.MOUSE_MOVE
      });

      if (!isHoldKey) {
        this.setState({
          disablePan: true,
          noteText: true
        });
      }
    }
  };

  onMouseUp = () => {
    this.setState({ isMouseDown: false });
  };

  mouseLeaveNoteTextEvent = () => {
    setTimeout(() => {
      this.setState({
        noteText: false
      });
    }, 0);
  };

  handleKeyDown = event => {
    if (navigator.userAgent.indexOf("Mac") === -1 && event.ctrlKey) {
      if (this.state.noteText) {
        this.setState({
          noteText: false,
          isHoldKey: true
        });
      }
      if (!this.state.isHoldKey) {
        this.setState({
          isHoldKey: true
        });
      }
    }

    if (navigator.userAgent.indexOf("Mac") !== -1) {
      // [WARN] Do not using 2 condition in same if statement, there will be bug when zoom out on Windows
      if (event.metaKey) {
        this.setState({
          noteText: false,
          isHoldKey: true
        });
      } else {
        this.setState({
          isHoldKey: true
        });
      }
    }
  };

  handleKeyUp = () => {
    if (this.state.isMouseDown) {
      this.setState({
        disablePan: true,
        noteText: true,
        isHoldKey: false
      });
    } else {
      setTimeout(() => {
        this.setState({ isHoldKey: false });
      }, 200);
    }
  };
  zoomEvent = () => {
    const zoomElement = document.querySelector(".react-transform-element");
    let zoom = 1;
    const ZOOM_SPEED = 0.1;

    document.addEventListener("wheel", function (e) {
      if (e.deltaY < 0) {
        zoomElement.style.transform = `scale(${(zoom += ZOOM_SPEED)})`;
      } else {
        zoomElement.style.transform = `scale(${(zoom -= ZOOM_SPEED)})`;
      }
    });
  };

  fetchDone = () => {
    if(!this.cancelSubmission){
      this.setState(
        {
          countFetching: this.state.countFetching + 1
        },
        () => {
          if (this.state.countFetching === this.props.drawingsWithSpots.length) {
            this.setState({ fetchDone: true });
          }
        }
      );
    }
    
  };

  reorder = (list, startIndex, endIndex) => {
    let drawingsList = list;

    const [removed] = drawingsList.splice(startIndex, 1);

    drawingsList.splice(endIndex, 0, removed);

    this.setState({ drawingsWithSpots: drawingsList });

    let result = [];

    drawingsList.forEach(item => {
      result.push(item.uniqueCode);
    });

    return result;
  };

  onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (
      !destination ||
      (source.droppableId === destination.droppableId &&
        source.index === destination.index)
    ) {
      return;
    }

    let uniqueCodes = [];
    if (source.droppableId === destination.droppableId) {
      uniqueCodes = this.reorder(
        this.state.drawingsWithSpots,
        result.source.index,
        result.destination.index
      );
    }
    let siteUC = this.props.match.params.id;
    this.props.sortDrawingImage(siteUC, uniqueCodes);
  }

  updateDimensions = e => {
    this.setPositionMarker();
  };

  setPositionMarker() {
    let { drawingsWithSpots } = this.props;
    let { currentDrawing } = this.state;
    if (drawingsWithSpots != undefined && drawingsWithSpots.length > 0) {
      // If current drawing has been deleted, set current drawing to first
      if (!drawingsWithSpots[currentDrawing]) {
        this.setState({ currentDrawing: 0 });
        return;
      }

      // Drawing preview
      let elementPrimary = document.getElementById("primary-image");
      if (elementPrimary) {
        let naturalWidthPrimary = elementPrimary
          ? elementPrimary.naturalWidth
          : 0;

        let naturalHeightPrimary = elementPrimary
          ? elementPrimary.naturalHeight
          : 0;

        const elMap = document.getElementById("primary-map");
        let scaleMap = elMap ? elMap.offsetWidth / elMap.offsetHeight : 0;
        let isVerticalImg =
          naturalHeightPrimary * scaleMap > naturalWidthPrimary;

        let offsetWidthPrimary = 0;
        let offsetHeightPrimary = 0;
        if (isVerticalImg) {
          offsetWidthPrimary =
            (elMap.offsetHeight * naturalWidthPrimary) / naturalHeightPrimary;

          offsetHeightPrimary = elMap.offsetHeight;
        } else {
          offsetWidthPrimary = elMap.offsetWidth;

          offsetHeightPrimary =
            (elMap.offsetWidth * naturalHeightPrimary) / naturalWidthPrimary;
        }

        let offsetLeftPrimary = (elMap.offsetWidth - offsetWidthPrimary) / 2;

        let offsetTopPrimary = (elMap.offsetHeight - offsetHeightPrimary) / 2;

        let posAfterPrimary = [];
        drawingsWithSpots[currentDrawing].spots.map(pos => {
          posAfterPrimary.push({
            uniqueCode: pos.uniqueCode,
            name: pos.name,
            posX:
              (pos.x * offsetWidthPrimary) / naturalWidthPrimary +
              offsetLeftPrimary,
            posY:
              (pos.y * offsetHeightPrimary) / naturalHeightPrimary +
              offsetTopPrimary,
            hasShoot: pos.hasShoot,
            links: pos.links
          });
        });

        this.setState({
          currentDrawingWithSpots: {
            isVerticalImg: isVerticalImg,
            positions: posAfterPrimary
          }
        });

        let itemsAfter = [];
        let tempDrawingsWithSpots = drawingsWithSpots;

        drawingsWithSpots.map((item, idx_item) => {
          let posAfter = [];
          let element = document.getElementsByClassName("sub-image")[idx_item];
          let offsetWidth = element ? element.offsetWidth : 0;

          let offsetHeight = element ? element.offsetHeight : 0;

          let naturalWidth = element ? element.naturalWidth : 0;

          let naturalHeight = element ? element.naturalHeight : 0;

          let offsetLeft = element ? element.offsetLeft : 0;

          let offsetTop = element ? element.offsetTop : 0;

          tempDrawingsWithSpots[idx_item].isVerticalImg =
            naturalHeight > naturalWidth;

          item.spots.map(pos => {
            posAfter.push({
              uniqueCode: pos.uniqueCode,
              name: pos.name,
              posX: (pos.x * offsetWidth) / naturalWidth + offsetLeft + 2,
              posY: (pos.y * offsetHeight) / naturalHeight + offsetTop + 2, // 2 pixel for border
              hasShoot: pos.hasShoot
            });
          });

          itemsAfter.push(posAfter);
        });
        this.setState({
          positions: itemsAfter,
          drawingsWithSpots: tempDrawingsWithSpots
        });
      }
      this.setEventListenerForSpots();
    }
  }

  loadMarker = () => {
    setTimeout(() => {
      this.setPositionMarker();
    }, 10);
  };

  changeDrawing = offset => {
    this.setState({ currentDrawing: this.state.currentDrawing + offset });
  };

  previousDrawing = () => {
    this.changeDrawing(-1);
  };

  nextDrawing = () => {
    this.changeDrawing(1);
  };

  scroll = (e, isNext) => {
    // if (e.deltaY < 0) {
    //   if (this.state.currentDrawing > 0) this.previousDrawing();
    // } else {
    //   if (this.state.currentDrawing < this.state.drawingsWithSpots.length - 1)
    //     this.nextDrawing();
    // }
    let delta = 1;
    if (isNext) {
      delta = -1;
    }

    const el = document.getElementsByClassName("grid-list")[0];
    if (el)
      document.getElementsByClassName("grid-list")[0].scrollLeft -= delta * 150; // Multiplied by 150
    this.showHideButtonNextPrev();
  };

  showHideButtonNextPrev = () => {
    let hideBtnPrev = false;
    let hideBtnNext = false;
    const el = document.getElementsByClassName("grid-list")[0];

    if (el && el.scrollLeft > 0) {
      hideBtnPrev = true;
    } else {
      hideBtnPrev = false;
    }

    if (el && el.scrollLeft < el.scrollWidth - el.clientWidth) {
      hideBtnNext = true;
    } else {
      hideBtnNext = false;
    }

    this.setState({
      showHideBtnPrev: hideBtnPrev,
      showHideBtnNext: hideBtnNext
    });
  };

  fetch() {}

  addPoint = () => {
    this.setState(
      { isAddPoint: !this.state.isAddPoint },
      this.disableHeader(!this.state.isAddPoint)
    );
  };

  handleCheckHelp = () => {
    this.setState({
      isCheckedHelp: !this.state.isCheckedHelp
    });
  };
  disableHeader = value => {
    if (value) {
      var element = document.createElement("div");
      element.className = "fixed-box-header";
      document.getElementById("header-builder").appendChild(element);
    } else {
      var element = document.getElementsByClassName("fixed-box-header")[0];
      if (element) {
        element.parentNode.removeChild(element);
      }
    }
  };
  compassSettingClick = () => {
    this.setState({ isCompassSetting: !this.state.isCompassSetting });
  };
  showDialogAddPoint = e => {
    this.setState({ isShowingDialogSavePiont: true });
    if (this.state.mouseEvent !== MOUSE_EVENT.MOUSE_MOVE) {
      let element = document.getElementById("primary-image");
      let offsetWidth = element ? element.offsetWidth : 0;
      let offsetHeight = element ? element.offsetHeight : 0;
      let naturalWidth = element ? element.naturalWidth : 0;
      let naturalHeight = element ? element.naturalHeight : 0;
      const scaleData = this.getZoomOffsetAndScale(
        document.getElementById("primary-map")
      );
      const userAgent = window.navigator.userAgent;
      let pos = null;
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/Macintosh/i)
      ) {
        // iPad or iPhone or Macintosh
        pos = {
          x: Math.round(
            (((e.pageX -
              element.getBoundingClientRect().left -
              window.scrollX) /
              scaleData.scale) *
              naturalWidth) /
              offsetWidth
          ),
          y: Math.round(
            (((e.pageY - element.getBoundingClientRect().top - window.scrollY) /
              scaleData.scale) *
              naturalHeight) /
              offsetHeight
          )
        };
      } else {
        // Anything else
        pos = {
          x: Math.round(
            (((e.pageX - element.getBoundingClientRect().left) /
              scaleData.scale) *
              naturalWidth) /
              offsetWidth
          ),
          y: Math.round(
            (((e.pageY - element.getBoundingClientRect().top) /
              scaleData.scale) *
              naturalHeight) /
              offsetHeight
          )
        };
      }

      this.props.toggleDialogAddPoint(
        pos,
        this.state.drawingsWithSpots[this.state.currentDrawing].uniqueCode
      );
    }
  };

  handleCompassDrag = dragStatus => {
    this.setState({
      isDraggingCompass: dragStatus
    });
  };
  wheelEvent = event => {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    if (this.state.isHoldKey) {
      this.setState({ noteText: false });
    } else {
      this.setState({ noteText: true });

      this.scrollTimeout = setTimeout(() => {
        this.setState({ noteText: false });
      }, 1900);
    }
  };

  toggleDialogEditPoint = pos => {
    this.props.toggleDialogEditPoint(
      pos,
      this.state.drawingsWithSpots[this.state.currentDrawing].uniqueCode
    );
  };

  getZoomOffsetAndScale = element => {
    const transform = element.style.transform;

    const offset = transform
      .substring(
        transform.indexOf("translate3d(") + 12,
        transform.lastIndexOf(")")
      )
      .replace(/px/g, "")
      .split(",");

    const offsetX = offset[0] ? offset[0].trim() : 0;
    const offsetY = offset[1] ? offset[1].trim() : 0;
    const scale = transform
      .substring(
        transform.indexOf("scale3d(") + 8,
        transform.lastIndexOf(") translate3d")
      )
      .split(",")[0];

    return {
      offsetX: -offsetX,
      offsetY: -offsetY,
      scale: scale
    };
  };

  returnDefaultSize = () => {
    let currentRef = this.wrapperRef.current;
    if (currentRef != null) {
      // currentRef.context.dispatch.resetTransform();
      currentRef.scaleTo({ x: 0, y: 0, scale: 1 });
    }
  };
  setEventListenerForSpots = () => {
    document.querySelectorAll(".marker").forEach(e => {
      e.addEventListener("mouseenter", () => {
        this.setState({
          isHoverSpot: true
        });
      });

      e.addEventListener("mouseleave", () => {
        this.setState({
          isHoverSpot: false
        });
      });
    });
  };
  handleChangeListDrawing = DrawingUC => {
    let array = [...this.state.selectedDrawing];
    var indexofDrawingUC = array.indexOf(DrawingUC);
    if (indexofDrawingUC !== -1) {
      array.splice(indexofDrawingUC, 1);
      this.setState({ selectedDrawing: array });
    } else {
      this.setState({
        selectedDrawing: [...this.state.selectedDrawing, DrawingUC]
      });
    }
  };
  handleSavingCompass = savingStatus => {
    this.setState({ selectedDrawing: [] });
    this.setState({
      isSavingCompassSetting: savingStatus
    });
    // this.disableHeader(savingStatus)
  };
  handleSavingCompassDirection = savingDirection => {
    this.setState({
      savingDirection: savingDirection
    });
  };
  handleCompassDrag = dragStatus => {
    this.setState({
      isDraggingCompass: dragStatus
    });
  };
  handleDrawingClick = event => {
    if (this.drawingsRef.current) {
      const { target } = event;
      this.setState({
        isClickToDrawing: this.drawingsRef.current.contains(target)
      });
    }
  };
  toggleDialogEditMapCompass = value => {
    this.props.toggleDialogEditMap(value);
  };
  handleDrawingPress() {
    // Press to show message

    this.buttonPressTimer = setTimeout(() => {
      this.setState({ isTouchDrawing: true, isShowingDialogSavePiont: false });
    }, 100);
  }
  handleDrawingRelease() {
    if (!this.state.isShowingDialogSavePiont) {
      this.setState({ isTouchDrawing: false });
    }
    clearTimeout(this.buttonPressTimer);
  }

  onTouchMoveDrawing() {
    clearTimeout(this.buttonPressTimer);
  }

  checkCreateLinkDisable = () => {
    const { currentDrawing } = this.state;
    const { drawingsWithSpots } = this.props;
    if (
      !drawingsWithSpots[currentDrawing] ||
      !drawingsWithSpots[currentDrawing].spots ||
      drawingsWithSpots[currentDrawing].spots.length <= 1 ||
      drawingsWithSpots[currentDrawing].spots.every(
        spot => spot.links && spot.links.length > 0
      )
    )
      return true;

    return false;
  };

  checkDeleteLinkDisable = () => {
    const { currentDrawing } = this.state;
    const { drawingsWithSpots } = this.props;
    if (
      !drawingsWithSpots[currentDrawing] ||
      !drawingsWithSpots[currentDrawing].spots
    )
      return true;

    return !drawingsWithSpots[currentDrawing].spots.some(
      spot => spot.links?.length > 0
    );
  };

  render() {
    const {
      positions,
      drawingsWithSpots,
      currentDrawing,
      currentDrawingWithSpots,
      isAddPoint,
      showHideBtnPrev,
      showHideBtnNext,
      fetchDone,
      isCompassSetting,
      sitesUC,
      selectedDrawing,
      isSavingCompassSetting,
      isClickToDrawing,
      savingDirection,
      noteTextByTouch,
      isTouchDrawing,
      confirmCloseModeSettingLink
    } = this.state;

    const {
      setIsViewVRSpace,
      isViewVRSpace,
      toggleAddDrawingDialog,
      openDialogCopy,
      setSelectedIndexDrawing,
      toggleDialogEditMap,
      isShowDialogCopy,
      closeDialogCopy,
      hasWalkThroughFeature,
      toggleConfirmCreateLink,
      toggleConfirmDeleteLink
    } = this.props;

    return (
      drawingsWithSpots != undefined && (
        <>
          {isAddPoint && <Grid className="fixed-box"></Grid>}

          <Grid
            className={`${
              isSavingCompassSetting
                ? "relative-box-compass-sp"
                : "relative-box"
            } p-10`}
          >
            <Grid className="second-tab">
              <Grid
                container
                className="add-image"
                alignItems="center"
                style={{ height: "60px" }}
              >
                {drawingsWithSpots.some(drawing =>
                  drawing.spots.some(spot => spot.hasShoot)
                ) &&
                  !isAddPoint &&
                  !isCompassSetting && (
                    <Grid item>
                      <ChooseLogWalkModeButton
                        setIsViewVRSpace={setIsViewVRSpace}
                        isViewVRSpace={isViewVRSpace}
                      />
                    </Grid>
                  )}

                <Grid item>
                  <Button
                    onClick={() => {
                      setIsViewVRSpace(true);
                      toggleAddDrawingDialog();
                    }}
                    size="small"
                    variant="text"
                    className={`button-add ${
                      isAddPoint || isCompassSetting ? "display-none" : ""
                    }`}
                    disabled={drawingsWithSpots.length >= maximumDrawing}
                  >
                    <AddIcon className="icon-margin" />
                    <label style={{ color: "inherit" }}>図面の追加</label>
                  </Button>
                  <Button
                    size="small"
                    variant="text"
                    className={`ml-10 button-add ${
                      isCompassSetting ? "display-none" : ""
                    } ${isAddPoint ? "add-point-enable" : ""}`}
                    style={{
                      border: isAddPoint ? "1px solid #162f50" : "none"
                    }}
                    disabled={drawingsWithSpots.length === 0}
                    onClick={() => {
                      if (
                        isAddPoint &&
                        hasWalkThroughFeature &&
                        drawingsWithSpots.some(
                          drawing =>
                            drawing.spots.length > 1 &&
                            drawing.spots.some(
                              spot => !spot.links || spot.links.length === 0
                            )
                        )
                      ) {
                        this.setState({ confirmCloseModeSettingLink: true });
                      } else {
                        setIsViewVRSpace(true);
                        this.addPoint();
                      }
                    }}
                  >
                    {!isAddPoint && <RoomIcon className="icon-margin" />}
                    {!isAddPoint ? `地点情報の設定 ` : `設定を完了`}
                  </Button>
                  <Button
                    size="small"
                    variant="text"
                    className={`ml-10 button-add ${
                      isAddPoint ? "display-none" : ""
                    } ${isCompassSetting ? "add-point-enable" : ""}`}
                    disabled={drawingsWithSpots.length === 0}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsViewVRSpace(true);
                      this.compassSettingClick();
                    }}
                  >
                    {/* <ExploreIcon className="icon-margin" /> */}
                    <img
                      src={!isCompassSetting ? CompassIcon : CompassIconSeleted}
                      alt=""
                    />
                    {!isCompassSetting ? `方位角の設定` : `方位角の設定`}
                  </Button>
                </Grid>

                {isAddPoint && fetchDone && (
                  <Button
                    onClick={e => openDialogCopy(e)}
                    size="small"
                    variant="text"
                    disabled={
                      drawingsWithSpots.length === 1 ||
                      drawingsWithSpots[currentDrawing]?.spots.length === 0
                    }
                    className={`ml-10 button-add ${
                      drawingsWithSpots.length === 1 ||
                      drawingsWithSpots[currentDrawing]?.spots.length === 0
                        ? "btn-copy-spot-disable"
                        : "btn-copy-spot-enable"
                    }`}
                  >
                    <span
                      style={{
                        fontSize: "20px",
                        width: "20px",
                        height: "20px",
                        marginRight: "4px",
                        lineHeight: "20px"
                      }}
                    >
                      <RoomIcon fontSize="inherit" />
                    </span>
                    <label style={{ color: "inherit" }}>地点情報のコピー</label>
                  </Button>
                )}

                {isAddPoint && fetchDone && hasWalkThroughFeature && (
                  <>
                    <Button
                      onClick={() =>
                        toggleConfirmCreateLink(
                          drawingsWithSpots[currentDrawing]?.uniqueCode
                        )
                      }
                      style={
                        this.checkCreateLinkDisable()
                          ? {
                              display: "none"
                            }
                          : {}
                      }
                      disabled={this.checkCreateLinkDisable()}
                      size="small"
                      variant="text"
                      className="ml-10 button-add btn-copy-spot-enable"
                    >
                      <span
                        style={{
                          width: "21px",
                          height: "21px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#001744",
                          fontSize: "24px",
                          color: "white",
                          marginRight: "4px",
                          borderRadius: "9999px"
                        }}
                      >
                        <KeyboardArrowUpIcon
                          fontSize="inherit"
                          color="inherit"
                        />
                      </span>

                      <label style={{ color: "inherit" }}>地点をつなぐ</label>
                    </Button>

                    <Button
                      onClick={() =>
                        toggleConfirmDeleteLink(
                          drawingsWithSpots[currentDrawing]?.uniqueCode
                        )
                      }
                      disabled={this.checkDeleteLinkDisable()}
                      size="small"
                      variant="text"
                      className="ml-10 button-add"
                      style={
                        this.checkDeleteLinkDisable()
                          ? {
                              display: "none"
                            }
                          : {
                              color: "#fff",
                              border: "none",
                              padding: "5px 6px",
                              backgroundColor: "red"
                          }
                      }
                    >
                      <label style={{ color: "inherit" }}>
                        「地点をつなぐ」を解除
                      </label>
                    </Button>

                    {drawingsWithSpots[currentDrawing] &&
                    drawingsWithSpots[currentDrawing].spots.length > 1 &&
                    drawingsWithSpots[currentDrawing].spots.some(
                      spot => !spot.links || spot.links.length === 0
                    ) ? (
                      <p
                        style={{
                          flexGrow: "1",
                          textAlign: "end",
                          color: "rgb(22, 47, 80)"
                        }}
                      >
                        ※「地点をつなぐ」未設定の地点があります
                      </p>
                    ) : null}
                  </>
                )}
              </Grid>
            </Grid>

            {isViewVRSpace ? (
              <Grid>
                {drawingsWithSpots && drawingsWithSpots.length > 0 && (
                  <DragDropContext
                    className={"my-5 sub-map"}
                    onDragEnd={this.onDragEnd}
                  >
                    <Grid>
                      <div
                        id={"draggable-primary"}
                        ref={this.drawingsRef}
                        className={`draggable-primary`}
                        onTouchStart={this.onTouchStart}
                        onTouchEnd={this.onTouchEnd}
                        onTouchMove={this.onTouchMove}
                        onWheel={this.wheelEvent}
                        onMouseDown={this.mouseLeaveNoteTextEvent}
                        style={{position: "relative"}}
                      >
                        <CompassSetting
                          direction={
                            drawingsWithSpots[currentDrawing].direction === null
                              ? 0
                              : drawingsWithSpots[currentDrawing].direction
                          }
                          isNullDirection={
                            drawingsWithSpots[currentDrawing].direction === null
                          }
                          bounds={"draggable-primary"}
                          isCompassSetting={isCompassSetting}
                          sitesUC={sitesUC}
                          currentDrawingUCs={
                            drawingsWithSpots[currentDrawing].uniqueCode
                          }
                          currentDrawing={currentDrawing}
                          drawingsWithSpots={drawingsWithSpots}
                          // selectedDrawing={selectedDrawing}
                          handleSavingParent={this.handleSavingCompass}
                          handleSavingDirectionParent={
                            this.handleSavingCompassDirection
                          }
                          isClickToDrawing={isClickToDrawing}
                          isAddPoint={isAddPoint}
                          noteText={this.state.noteText}
                          toggleDialogEditMapCompass={
                            this.toggleDialogEditMapCompass
                          }
                          handleCompassDragParent={this.handleCompassDrag}
                        />
                        <QuickPinchZoom
                          onUpdate={this.onUpdateTransform}
                          // enabled={!this.state.noteText}
                          ref={this.wrapperRef}
                          tapZoomFactor={0}
                          inertia={false}
                          verticalPadding={200}
                          horizontalPadding={200}
                        >
                          <Grid
                            id={`primary-map`}
                            ref={this.imgRef}
                            className={`${
                              currentDrawingWithSpots?.isVerticalImg &&
                              "vertical-primary-block"
                            }`}
                            // onMouseDown={this.onMouseDown}
                            // onMouseMove={this.onMouseMove}
                            // onMouseUp={this.onMouseUp}
                            // onTouchStart={this.handleDrawingPress}
                            // onTouchEnd={this.handleDrawingRelease}
                            // onTouchMove={this.onTouchMoveDrawing}
                          >
                            <DrawingPhoto
                              id="primary-image"
                              name={drawingsWithSpots[currentDrawing].name}
                              drawingsUC={
                                drawingsWithSpots[currentDrawing].uniqueCode
                              }
                              blob={drawingsWithSpots[currentDrawing].blob}
                              isAddPoint={isAddPoint}
                              showDialogAddPoint={this.showDialogAddPoint}
                              toggleDialogEditPoint={this.toggleDialogEditPoint}
                              loadMarker={this.loadMarker}
                              positions={currentDrawingWithSpots?.positions}
                              isHideMarker={isCompassSetting}
                              currentDrawingUC={
                                drawingsWithSpots[currentDrawing].uniqueCode
                              }
                              showLinks={hasWalkThroughFeature && isAddPoint}
                            />
                          </Grid>
                        </QuickPinchZoom>
                        <Grid
                          className={`noteText${
                            this.state.noteText &&
                            !isCompassSetting &&
                            !noteTextByTouch
                              ? " show"
                              : ""
                          }`}
                          onMouseLeave={this.mouseLeaveNoteTextEvent}
                        >
                          {navigator.platform == "MacIntel" ? (
                            <p>
                              図面をズームするためには、⌘キーを押しながら操作を行ってください
                            </p>
                          ) : (
                            <p>
                              図面をズームするためには、Ctrlキーを押しながら操作を行ってください
                            </p>
                          )}
                        </Grid>

                        <Grid
                          className="noteText"
                          onMouseLeave={this.mouseLeaveNoteTextEvent}
                          onTouchStart={this.onTouchStart}
                          onTouchEnd={this.onTouchEnd}
                          onTouchMove={this.onTouchMove}
                          style={
                            !this.state.noteText &&
                            !isCompassSetting &&
                            !isTouchDrawing &&
                            noteTextByTouch
                              ? {
                                  transitionDuration: "0.3s",
                                  opacity: 1,
                                  zIndex: 2
                                }
                              : { display: "none" }
                          }
                        >
                          <p>
                            図面を操作するためには、２本指で操作を開始してください
                          </p>
                        </Grid>
                      </div>
                    </Grid>

                    <Grid
                      id={"list_drawings"}
                      className="mt-10 position-relative"
                    >
                      {drawingsWithSpots.length > 0 && !isSavingCompassSetting && (
                        <Droppable
                          droppableId="droppable2"
                          direction="horizontal"
                          isDropDisabled={isSavingCompassSetting}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={`grid-list ${
                                isSavingCompassSetting
                                  ? "list-drawing-saving"
                                  : ""
                              }`}
                              ref={provided.innerRef}
                              onScroll={this.showHideButtonNextPrev}
                            >
                              {showHideBtnPrev && (
                                <Fab
                                  size="small"
                                  className="btn-prev-drawing"
                                  onClick={e => this.scroll(e, false)}
                                >
                                  <ArrowBackIcon />
                                </Fab>
                              )}
                              {drawingsWithSpots.map((drawing, index) => (
                                <Draggable
                                  key={drawing.uniqueCode}
                                  draggableId={drawing.uniqueCode}
                                  index={index}
                                  isDragDisabled={isSavingCompassSetting}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={`sub-block ${
                                        (currentDrawing === index ||
                                          (isSavingCompassSetting &&
                                            selectedDrawing.indexOf(
                                              drawing.uniqueCode
                                            ) !== -1)) &&
                                        "current-drawing"
                                      }`}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                      onClick={() => {
                                        if (!isSavingCompassSetting) {
                                          this.setState(
                                            {
                                              currentDrawing: index
                                            },
                                            () => {
                                              setTimeout(
                                                this.returnDefaultSize,
                                                100
                                              );
                                            }
                                          );
                                          setSelectedIndexDrawing(index);
                                          setRedirectFromUrl({
                                            isRedirectFromUrl: false,
                                            isDialog: false,
                                            isCardList: true,
                                            currentDrawingUC: null,
                                            from: ""
                                          });
                                        } else {
                                          this.handleChangeListDrawing(
                                            drawing.uniqueCode
                                          );
                                        }
                                      }}
                                    >
                                      <Grid className="sub-block-title text-center">
                                        <Typography
                                          variant="body2"
                                          variantMapping={{ body2: "span" }}
                                          className="text-bold text-center mb-5"
                                        >
                                          {drawing.name}
                                        </Typography>
                                        {!isAddPoint && (
                                          <span
                                            onClick={e => {
                                              toggleDialogEditMap(
                                                drawing.uniqueCode
                                              );
                                              e.stopPropagation();
                                            }}
                                            className="edit-button"
                                          >
                                            <EditIcon className="icon-margin" />
                                          </span>
                                        )}
                                      </Grid>
                                      <div
                                        id={`sub-img-block-${drawing.uniqueCode}`}
                                        className={`sub-img-block ${
                                          drawing.isVerticalImg
                                            ? "vertical-sub-compass-block"
                                            : ""
                                        }`}
                                      >
                                        <DrawingPhoto
                                          class={`sub-image`}
                                          drawingsUC={drawing.uniqueCode}
                                          fetchDone={this.fetchDone}
                                          alt={drawing.title}
                                          id={drawing.uniqueCode}
                                          blob={drawing.blob}
                                          loadMarker={this.loadMarker}
                                          positions={positions[index]}
                                          isHideMarker={isCompassSetting}
                                          currentDrawingUC={
                                            drawingsWithSpots[currentDrawing]
                                              .uniqueCode
                                          }
                                          isPreventMovingToLWViewer={isAddPoint}
                                        />
                                        <CompassView
                                          direction={drawing.direction}
                                          drawingsUC={drawing.uniqueCode}
                                          isSavingCompassSetting={
                                            isSavingCompassSetting
                                          }
                                          savingDirection={savingDirection}
                                          currentDrawing={
                                            currentDrawing === index
                                          }
                                          isSelected={
                                            selectedDrawing.indexOf(
                                              drawing.uniqueCode
                                            ) !== -1
                                          }
                                          isCompassSetting={isCompassSetting}
                                          isAddPoint={isAddPoint}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}

                              {provided.placeholder}
                              {showHideBtnNext && (
                                <Fab
                                  className="btn-next-drawing"
                                  size="small"
                                  onClick={e => this.scroll(e, true)}
                                >
                                  <ArrowForwardIcon />
                                </Fab>
                              )}
                            </div>
                          )}
                        </Droppable>
                      )}
                    </Grid>
                  </DragDropContext>
                )}
              </Grid>
            ) : (
              <ViewTask
                sitesUC={sitesUC}
                drawingsWithSpots={drawingsWithSpots}
              />
            )}

            {isShowDialogCopy && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  zIndex: "1102",
                  opacity: "0",
                  top: "0",
                  left: "0"
                }}
                onClick={() => closeDialogCopy()}
              ></div>
            )}
          </Grid>

          {confirmCloseModeSettingLink && (
            <CommonMapOptionDialogSP
              open={confirmCloseModeSettingLink}
              onClose={() =>
                this.setState({ confirmCloseModeSettingLink: false })
              }
              title={
                <>
                  <p
                    style={{
                      color: "#333",
                      fontSize: "16px",
                      fontWeight: "400"
                    }}
                  >
                    図面情報の設定を完了しますか？
                  </p>
                  <p
                    style={{
                      color: "#333",
                      fontSize: "16px",
                      fontWeight: "400"
                    }}
                  >
                    「地点をつなぐ」が未設定の地点が存在します。
                  </p>
                </>
              }
              titleClasses="text-center"
              buttonOptions={[
                {
                  buttonTitle: "完了",
                  buttonOnCick: () => {
                    this.setState({ confirmCloseModeSettingLink: false });
                    this.addPoint();
                  },
                  buttonClasses: "confirm-button-blue"
                },
                {
                  buttonTitle: "設定へ戻る",
                  buttonOnCick: () =>
                    this.setState({ confirmCloseModeSettingLink: false }),
                  buttonClasses: "confirm-button-sliver"
                }
              ]}
              PaperProps={{
                className: "dialog dialog-sign-up map-option-dialog"
              }}
            ></CommonMapOptionDialogSP>
          )}
        </>
      )
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  type: state.rootSitesReducer.siteInfoReducer.type,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message,
  siteInfo: getSiteInfo(state.rootSitesReducer.siteInfoReducer.siteInfo),
  drawingsWithSpots: getDrawingsWithSpots(
    state.rootSitesReducer.siteInfoReducer.drawingsWithSpots
  ),
  redirectFromUrl: getRedirectFromUrl(
    state.rootSitesReducer.siteInfoReducer.redirectFromUrl
  )
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      translateValidate,
      messageValidate,
      fetchDrawingFile,
      sortDrawingImage,
      setRedirectFromUrl: data => dispatch(setRedirectFromUrl(data))
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MapsBuilder));
