import React, { memo } from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
const titleButton = "OK";
const ErrorDialog = ({
  title,
  onClose,
  textContent,
  titleSubmit,
  ...rest
}) => {
  return (
    <Dialog
      aria-labelledby="max-width-dialog-title"
      fullWidth
      maxWidth="xs"
      onClose={(() => {})}
      {...rest}
    >
      <Fab
        onClick={onClose}
        size="small"
        className="white-text close-button"
      >
        <CloseIcon />
      </Fab>
      <DialogTitle disableTypography>
        <Typography className="confirm-title">
          <Typography
            className="block text-center"
            variant="body1"
            variantMapping={{ body1: "span" }}
          >
            {textContent}
          </Typography>
        </Typography>
      </DialogTitle>
      <DialogContent className="confirm-content">
        <Button onClick={onClose} size="large" className="btn-red">
          {titleSubmit ?? titleButton}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

ErrorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  titleSubmit: PropTypes.string
};

export default memo(ErrorDialog);
