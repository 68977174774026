import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Photos from "./Photos";
import Files from "./Files";
import Info from "./Info";
import Maps from "./Maps";
import ShareUrl from "./ShareUrl";
import LogMeetSP from "./LogMeetSP";
import Popover from "@material-ui/core/Popover";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonReturn from "../../../common/ButtonReturn";
import ButtonRobot from "../../../common/ButtonRobot";
import PlayCircleOutlineOutlinedIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import { showHideDialogMapFromLogWalk, setIsBack, incompleteSite } from "./service/service";
import RobotStatus from "common/RobotStatus";
import { BUTTON_LOGKUN_SP } from "common/constants/constants";
import { Button } from "@material-ui/core";
import {
  wsUpdatedRobots,
  wsClearRobots
} from "../../robots/robotDetails/service/service";
import { getWebSocketRobotURL } from "utils/mapper";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { format } from "date-fns";
import HomeIcon from "./HomeIcon";
import { isMobile, isTablet } from "react-device-detect";

const Element = {
  None: false,
  Photos: 1,
  Files: 2,
  Info: 3,
  Maps: 4,
  ShareUrl: 5,
  Logmeet: 6
};
const WS_TIME_REPEAT = 10000; //10s

class SiteBuilderSP extends Component {
  sitesUCChange = null;
  ws = null;
  wsInterval = null;
  pingInterval = null;
  robotsUC = [];
  countFetch = 0;

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isShowOptionButtons: true,
      isLogWalkTabClick: false
    };
  }
  componentWillReceiveProps(props) {
    if (props.site && this.sitesUCChange !== props.site.UniqueCode) {
      if (!props.isBack || (props.isBack && this.countFetch === 1)) {
        this.robotsUC = this.updateRobotsChange(props.site.Robots);
        if (this.ws) {
          this.ws.onclose = function () { }; // disable onclose handler first
          this.ws.close();
          this.props.wsClearRobots();
          clearInterval(this.wsInterval);
          clearInterval(this.pingInterval);
        }

        if (this.robotsUC.length > 0) {
          this.openWS(this.robotsUC);

          this.wsInterval = setInterval(() => {
            if (this.ws.readyState === 3) {
              clearInterval(this.pingInterval);
              this.openWS(this.robotsUC);
            }
          }, WS_TIME_REPEAT);
        }

        if (this.countFetch === 1) {
          this.countFetch = 0;
        }
      }

      if (props.isBack) {
        this.countFetch = 1;
      }

      this.sitesUCChange = props.site.UniqueCode;
    }
  }

  updateRobotsChange = robotsNew => {
    let robotsUCNew = [];
    robotsNew.map((robotNew, idx) => {
      robotsUCNew = [...robotsUCNew, robotNew.uniqueCode];
    });

    if (this.robotsUC.length == robotsUCNew.length) {
      robotsUCNew.map((robotUCNew, idx) => {
        if (robotUCNew !== this.robotsUC[idx]) {
          return robotsUCNew;
        }
      });
      return [];
    }
    return robotsUCNew;
  };

  componentWillUnmount() {
    clearInterval(this.wsInterval);
    clearInterval(this.pingInterval);

    if (this.ws) {
      this.ws.onclose = () => { }; // disable onclose handler first
      this.ws.close();
      this.props.wsClearRobots();
    }
    this.props.setIsBack(true);
  }
  ShowOptionButtons = () => {
    if (!this.state.isLogWalkTabClick) {
      this.setState({
        isShowOptionButtons: true,
        isLogWalkTabClick: true
      })
    }
  }
  changeTabClick = () => {
    this.setState({
      isLogWalkTabClick: false,
      isShowOptionButtons: true, // TODO Thoai: fixbug showOptionButtons not show.
    })
  }
  openWS = robotsUC => {
    this.ws = new WebSocket(getWebSocketRobotURL());
    this.ws.addEventListener("open", () => {
      this.ws.send(JSON.stringify(robotsUC));

      this.pingInterval = setInterval(() => {
        this.ws.send("ping");
      }, WS_TIME_REPEAT);
    });

    this.ws.onmessage = s => {
      if (s.data !== "pong") {
        this.props.wsUpdatedRobots(JSON.parse(s.data));
      }
    };
  };
  toggleOptionButtons = () => {
    this.setState({
      isShowOptionButtons: !this.state.isShowOptionButtons
    })
  }

  handleIncompleteSite = () => {
    this.props.incompleteSite(this.props.site.UniqueCode, () => {
      this.props.history.push("/sites", { reload: true, isBack: true, isCompleteSite: true })
    })
  }

  render() {
    const showPopover = e => {
      this.setState({
        anchorEl: e.currentTarget
      });
    };
    const closePopover = e => {
      this.setState({
        anchorEl: null
      });
    };
    const onClickMenuItemEdit = () => {
      closePopover();
      this.props.showDialogEdit();
    };
    const { isShowOptionButtons } = this.state
    const { site, loadPhotoTab, onHideLoading, robotsUpdate } = this.props;

    return (
      <Grid container className="sites-image-sp">
        <Grid container wrap="nowrap" className="sites-right">
          <Grid className="image-content image-content-sp-padding">
            <Grid container wrap="nowrap">
              <Grid container className="header-site-sp">
                {site && site.CompletedAt
                  ? (
                    <React.Fragment>
                      <Grid container>
                        <Grid icon style={{ marginRight: "10px" }}>
                          <HomeIcon size={28} />
                        </Grid>
                        <Grid icon className="max-width-40">
                          <Typography
                            variant="h5"
                            className="onsite-name font-family text-bold"
                          >
                            {site && site.Name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center" className="complete-site">
                        <CheckCircleOutlineIcon className="icon-margin" />
                        <Typography variant="h6" className="dialog-title">
                          完了日：
                          {format(new Date(site.CompletedAt), "yyyy/M/d/eee")}
                        </Typography>

                        <Button
                          className="incomplete-site-btn"
                          onClick={() => this.handleIncompleteSite()}
                          style={{ padding: "4px 8px" }}
                        >未完了に戻す</Button>
                      </Grid>
                    </React.Fragment>

                  ) : (
                    <React.Fragment>

                      <Grid container className="max-width-40">
                        <Grid icon style={{ marginRight: "10px" }}>
                          <HomeIcon size={28} />
                        </Grid>
                        <Grid icon className="max-width-40">
                          <Typography
                            variant="h5"
                            className="onsite-name font-family text-bold"
                          >
                            {site && site.Name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Fab onClick={showPopover} size="small">
                        <MoreVertIcon />
                      </Fab>
                    </React.Fragment>

                  )}
              </Grid>
            </Grid>
            <Grid className="sites-tab" style={{ paddingBottom: 100 }}>
              <Tabs
                className="tabs"
                value={this.props.tabElement}
                onChange={this.props.selectElement}
                // aria-label="simple tabs example"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  className="tab-button tab-height"
                  label="写真"
                  value={Element.Photos}
                  onClick={this.changeTabClick}
                  style={{ padding: "6px 4px" }}
                />
                <Tab
                  className="tab-button tab-height"
                  label="ファイル(PDF)"
                  value={Element.Files}
                  onClick={this.changeTabClick}
                  style={{ padding: "6px 4px" }}
                />
                <Tab
                  className="tab-button tab-height"
                  label="現場情報"
                  value={Element.Info}
                  onClick={this.changeTabClick}
                  style={{ padding: "6px 4px" }}
                />
                <Tab
                  className="tab-button text-transform-none tab-height"
                  label="Log Walk"
                  value={Element.Maps}
                  onClick={this.ShowOptionButtons}
                  style={{ padding: "6px 4px" }}
                />
                {site && site.LogMeetFlag &&  !(isMobile || isTablet) && (
                  <Tab
                    className="tab-button text-transform-none"
                    label="Log Meet"
                    value={Element.Logmeet}
                    style={{ minWidth: "128px" }}
                  />
                )}
                <Tab
                  className="tab-button text-transform-none tab-height"
                  label="URL共有一覧"
                  value={Element.ShareUrl}
                  onClick={this.changeTabClick}
                  style={{ padding: "6px 4px" }}
                />
              </Tabs>
              {site && site.UniqueCode !== undefined && (
                <Grid className="tab-content">
                  {this.props.tabElement === Element.Photos && (
                    <Photos
                      sitesUC={site.UniqueCode}
                      loadPhotoTab={loadPhotoTab}
                      onHideLoading={onHideLoading}
                    />
                  )}
                  {this.props.tabElement === Element.Files && (
                    <Files sitesUC={site.UniqueCode} />
                  )}
                  {this.props.tabElement === Element.Info && (
                    <Info sitesUC={site.UniqueCode} logMeetFlag={site && site.LogMeetFlag} />
                  )}
                  {this.props.tabElement === Element.Maps && (
                    <Maps
                      toggleOptionButtons={this.toggleOptionButtons}
                      sitesUC={site.UniqueCode}
                      istabElementMaps={this.props.tabElement === Element.Maps}
                      isShowOptionButton={this.state.isLogWalkTabClick}
                      hasWalkThroughFeature={site.WalkThrough}
                    />
                  )}
                  {this.props.tabElement === Element.ShareUrl && (
                    <ShareUrl sitesUC={site.UniqueCode} />
                  )}

                  {site.LogMeetFlag && this.props.tabElement === Element.Logmeet && (
                    <LogMeetSP sitesUC={site.UniqueCode} />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {isShowOptionButtons &&
          <>
            <Link to={{ pathname: "/sites", state: { reload: true, isBack: true, isCompleteSite: site && site.CompletedAt ? true : false } }} className="reset-link">
              <ButtonReturn />
            </Link>
            <Fab
              // className={
              //   site && site.LogWalkViewer
              //     ? "white-text button-logWalk-sp mx-5 button-sp fab-button-logwalk-sp"
              //     : "white-text button-logWalk-sp-disable mx-5 button-sp fab-button-logwalk-sp"
              // }
              className={`white-text mx-5 button-sp fab-button-logwalk-sp button-logWalk-sp-center 
              ${site && site.LogWalkViewer ? "button-logWalk-sp" : " button-logWalk-sp-disable"}
            `}
              onClick={e => {
                if (site.LogWalkViewer) {
                  this.props.showDialogDrawing(site.UniqueCode);
                }
                e.stopPropagation();
              }}
            >
              <PlayCircleOutlineOutlinedIcon style={{ textTransform: "none", width: "26px", height: "26px" }} />
            </Fab>
            <RobotStatus
              typeOfRobotStatus={BUTTON_LOGKUN_SP}
              robots={site && site.Robots.length > 0 ? site.Robots : null}
              robotsUpdate={[robotsUpdate]}
              staticPosition={true}
            />
          </>
        }
        <Popover
          id="simple-popover"
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuList className="popover-sites">
            <MenuItem onClick={onClickMenuItemEdit}>編集</MenuItem>
          </MenuList>
        </Popover>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  tabElement: state.rootSitesReducer.siteInfoReducer.tabElement,
  robotsUpdate:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .wsUpdatedRobots,
  isBack: state.rootSitesReducer.siteInfoReducer.isBack
});

export default connect(mapStateToProps, {
  showHideDialogMapFromLogWalk,
  wsUpdatedRobots,
  wsClearRobots,
  setIsBack,
  incompleteSite
})(withRouter(SiteBuilderSP));
