import React, { useEffect, useState } from "react";
import CommonDialog from "../CommonDialog";
import { makeStyles } from "@material-ui/core/styles";
import IconColor from "./IconColor";

const useStyles = makeStyles({
  root: {},
  dialogContent: {
    padding: "0px",
    margin: "0px",
    fontSize: "17px",
    fontWeight: "normal",
    lineHeight: "23px",
    "& p": {
      fontWeight: "bold",
      paddingBottom: "10px",
      marginTop: "20px"
    },
    "& input": {
      width: "100%",
      height: "45px",
      padding: "11px 20px",
      backgroundColor: "#F8F9F9",
      borderRadius: "5px",
      border: "1px solid #C7C7C7 !important",
      boxShadow: "none !important",
      boxSizing: "border-box"
    }
  },
  colorItem: {
    width: "45px",
    height: "45px",
    padding: "2px",
    margin: "6px",
    borderRadius: "5px",
    border: "1px solid white",
    cursor: "pointer"
  },
  saveButton: {
    backgroundColor: "#00346C",
    borderRadius: "4px",
    width: "150px",
    height: "45px",
    lineHeight: "23px",
    padding: "11px 0px",
    textAlign: "center",
    color: "white",
    margin: "auto"
  },
  deleteContentDialog: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    lineHeight: "23px",
    paddingBottom: "15px",
    "& svg": {
      marginBottom: "15px"
    }
  },
  deleteActions: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "& button": {
      width: "120px",
      height: "45px",
      padding: "11px 0px",
      backgroundColor: "#C7C7C7",
      borderRadius: "4px",
      lineHeight: "23px",
      fontWeight: "bold",
      textAlign: "center",
      color: "white"
    }
  }
});

function DeleteConfirmDialog(props) {
  const {
    handleDeleteIconInfo,
    closeIconInfoDialog,
    open,
    iconInfo
  } = props;

  const classes = useStyles();

  return (
    <React.Fragment>
      <CommonDialog
        title={"アイコンの削除"}
        isDialogShow={open}
        onClose={() => closeIconInfoDialog()}
        dialogContent={
          <div className={classes.deleteContentDialog}>
            <IconColor
              width="30px"
              height="30px"
              color={iconInfo.color}
            />
            <span>本当に削除しますか？</span>
            <span>削除すると元に戻すことができません。</span>
          </div>
        }
        dialogActions={
          <div className={classes.deleteActions}>
            <button
              style={{ backgroundColor: "#00346C", marginRight: "20px" }}
              onClick={() => handleDeleteIconInfo(iconInfo.uniqueCode)}
            >
              Yes
            </button>
            <button onClick={() => closeIconInfoDialog()}>No</button>
          </div>
        }
      />
    </React.Fragment>
  );
}

export default DeleteConfirmDialog;