'use strict';
// NOTE: Added by knm.

function AzBlobExtension(blobDataLst, domElement) {
  this.blobDataLst = blobDataLst;
  this.isBlobSasExpired = false;
  this._domElement = domElement;
  this.sasExpiredEvent = new CustomEvent("sasExpired");
};

AzBlobExtension.prototype.dispatchEvent = function () {
  this._domElement.dispatchEvent(this.sasExpiredEvent);
};

AzBlobExtension.prototype.extractAzBlobUrl = function(key) {
  if (key === "/eqr") {
    return this.blobDataLst[0];
  } else {
    var trgBlobUrl = this.blobDataLst.find(blobData => {
      return blobData.layer === key;
    });

    if(!trgBlobUrl){
      return this.blobDataLst[0];
    }
    return trgBlobUrl.url;
  }
};

AzBlobExtension.prototype.getIsBlobSasExpired = function () {
  return this.isBlobSasExpired;
};

AzBlobExtension.prototype.setIsBlobSasExpired = function (isExpired) {
  this.isBlobSasExpired = isExpired;
};

AzBlobExtension.prototype.updateBlobDataLst = function (blobDataLst) {
  this.blobDataLst = blobDataLst;
};

module.exports = AzBlobExtension;
