import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import CommonDialog from "../../../CommonDialog";
import { DIALOG_FIELD_TYPES } from "../../../../common/Stores/constant/constant";
import { getBuilderEntries, getStaffEntries } from "../reducer/robotEntries";
import DialogDelete from "../../../../common/DialogDelete";
import {
  fetchRobotHistory,
  postRobotHistory,
  fetchRobotLeaseHistory,
  updateRobotHistory,
  deleteRobotHistory,
  fetchRobotLogBuildDetail,
  fetchBuilderRobotAvailable
} from "../service/service";
import { apiError as fillError } from "../../../../common/Stores/action/action";
import {
  POST_LOG_BUILD_RBOT_HISTORY_SUCCESS,
  PUT_LOG_BUILD_RBOT_HISTORY_SUCCESS,
  DELETE_LOG_BUILD_ROBOT_HISTORY_SUCCESS
} from "../constant/constant";
import { fetchLogBuildRobotLeaseHistorySuccess } from "../action/action";

class RobotLogBuildHistory extends Component {
  state = {
    isDialogShow: false,
    isEdit: false,
    isDialogDeleteShow: false,
    index: "",
    currentDateStatus: {}
  };

  defaultValues = {
    buildersUC: -1,
    leaseStartUser: -1,
    leaseStartDate: new Date()
  };

  fieldList = [
    {
      name: "buildersUC",
      label: "工務店名",
      type: DIALOG_FIELD_TYPES.DropDownBox,
      isRequired: true,
      options: []
    },
    {
      name: "leaseStartUser",
      label: "貸出担当者",
      type: DIALOG_FIELD_TYPES.DropDownBox,
      isRequired: true,
      options: []
    },
    {
      name: "leaseStartDate",
      label: "貸出日​",
      type: DIALOG_FIELD_TYPES.DateTime,
      isRequired: true
    }
  ];

  fieldListEdit = [
    ...this.fieldList,
    {
      name: "leaseEndDate",
      label: "利用停止日​",
      type: DIALOG_FIELD_TYPES.DateTime,
      guidMessage: "※利用停止日を登録すると、ロボットの貸し出しが解除され、ステータスが保管中に変更されます"
    },
    {
      name: "withdrawalUser",
      label: "回収担当者",
      type: DIALOG_FIELD_TYPES.DropDownBox,
      options: []
    },
    {
      name: "withdrawalDate",
      label: "回収日",
      type: DIALOG_FIELD_TYPES.DateTime
    }
  ];

  componentDidMount() {
    this.props.fetchRobotHistory(this.props.match.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (this.props.type != nextProps.type &&
        (nextProps.type === POST_LOG_BUILD_RBOT_HISTORY_SUCCESS ||
          nextProps.type === PUT_LOG_BUILD_RBOT_HISTORY_SUCCESS)) ||
      nextProps.type === DELETE_LOG_BUILD_ROBOT_HISTORY_SUCCESS
    ) {
      this.setState({
        isDialogShow: false,
        isDialogDeleteShow: false
      });
      this.props.fetchRobotLogBuildDetail(this.props.match.params.id, true);
    }

    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.props.fetchRobotHistory(nextProps.match.params.id);
    }
  }

  showDialog = (isEdit, index) => () => {
    this.props.fetchLogBuildRobotLeaseHistorySuccess();
    this.props.fillError("");
    this.setState({
      isDialogShow: true,
      isEdit: isEdit,
      index: index
    });
    if (isEdit) {
      this.props.fetchRobotLeaseHistory(this.props.match.params.id, index);
    } else {
      this.props.fetchBuilderRobotAvailable(this.props.match.params.id);
    }
  };

  closeDialog = () => {
    this.setState({
      isDialogShow: false
    });
  };

  onSubmitForm = values => {
    const { currentDateStatus } = this.state;
    const newValues = { ...values };
    if (newValues.buildersUC === -1) newValues.buildersUC = "";
    if (newValues.leaseStartUser === -1) newValues.leaseStartUser = "";
    if (newValues.withdrawalUser === -1) newValues.withdrawalUser = "";
    for (let key in currentDateStatus) {
      newValues[key] = "Invalid Date";
    }
    const formValue = { robotsUC: this.props.match.params.id, ...newValues };

    if (this.state.isEdit) {
      this.props.updateRobotHistory(
        newValues,
        this.props.match.params.id,
        this.state.index
      );
    } else {
      this.props.postRobotHistory(formValue);
    }
  };

  currentDate = (fieldName, date) => {
    if (date?.toString() === "Invalid Date") {
      this.setState({
        currentDateStatus: {
          ...this.state.currentDateStatus,
          [fieldName]: ""
        }
      });
    } else {
      const newDateStatus = { ...this.state.currentDateStatus };
      delete newDateStatus[fieldName];
      this.setState({
        currentDateStatus: newDateStatus
      });
    }
  };

  onDeleteRobot = () => {
    this.props.deleteRobotHistory(this.props.match.params.id, this.state.index);
  };

  toggleDialogDelete = () => {
    this.setState({
      isDialogDeleteShow: !this.state.isDialogDeleteShow,
      isDialogShow: false
    });
  };

  render() {
    const { isDialogShow, isEdit, isDialogDeleteShow } = this.state;
    const {
      builderEntries,
      staffEntries,
      robotHistory,
      submitErrors,
      robotLeaseHistory,
      leaseStatus
    } = this.props;

    const nameIndex = this.fieldList.findIndex(
      field => field.name === "buildersUC"
    );
    const lenderIndex = this.fieldList.findIndex(
      field => field.name === "leaseStartUser"
    );
    const withdrawalUserIndex = this.fieldListEdit.findIndex(
      field => field.name === "withdrawalUser"
    );

    this.fieldList[nameIndex].options = builderEntries;
    this.fieldList[lenderIndex].options = staffEntries;
    this.fieldListEdit[withdrawalUserIndex].options = staffEntries;

    let canRenderDialog = false;

    if (!isEdit || robotLeaseHistory) {
      canRenderDialog = true;
    }

    if (robotLeaseHistory && robotLeaseHistory.withdrawalUser === null) {
      robotLeaseHistory.withdrawalUser = -1;
    }

    return (
      <Grid className="mt-25">
        <Grid className="mb-10">
          <Button
            onClick={this.showDialog(false)}
            size="small"
            variant="text"
            disabled={leaseStatus && leaseStatus !== 0 ? true : false}
            className="button-add mb-25"
          >
            <AddIcon className="icon-margin" />
            貸出登録
          </Button>
        </Grid>
        <TableContainer>
          <Table aria-label="table" size="small">
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="white-text padding-first-th">
                  工務店名
                </TableCell>
                <TableCell className="white-text">貸出担当者​</TableCell>
                <TableCell className="white-text">
                  貸出日
                  <ArrowDownwardIcon className="icon-small-size s-va-m margin-left" />
                </TableCell>
                <TableCell className="white-text">利用停止日</TableCell>
                <TableCell className="white-text">回収担当者</TableCell>
                <TableCell className="white-text">回収日</TableCell>
                <TableCell className="white-text"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {robotHistory &&
                robotHistory.map((row, index) => (
                  <TableRow key={index} className="reset-link">
                    <TableCell className="padding-first-td">
                      {row.builderName}
                    </TableCell>
                    <TableCell>{row.leaseStartUserName}</TableCell>
                    <TableCell>
                      {row.leaseStartDate &&
                        format(new Date(row.leaseStartDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell>
                      {row.leaseEndDate &&
                        format(new Date(row.leaseEndDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell>{row.withdrawalUserName}</TableCell>
                    <TableCell>
                      {row.withdrawalDate &&
                        format(new Date(row.withdrawalDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={this.showDialog(true, row.index)}
                        size="small"
                        variant="text"
                        className="button-add"
                      >
                        <EditIcon className="icon-margin icon-small-size" />
                        編集
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid className="mt-10">
          <Button
            onClick={this.showDialog(false)}
            size="small"
            variant="text"
            disabled={leaseStatus && leaseStatus !== 0 ? true : false}
            className="button-add mb-25"
          >
            <AddIcon className="icon-margin" />
            貸出登録
          </Button>
        </Grid>
        {isDialogShow && canRenderDialog && (
          <CommonDialog
            title={isEdit ? "貸出編集" : "貸出登録"}
            isDialogShow={isDialogShow}
            initialValues={
              robotLeaseHistory ? robotLeaseHistory : this.defaultValues
            }
            onSubmit={this.onSubmitForm}
            onClose={this.closeDialog}
            fieldList={robotLeaseHistory ? this.fieldListEdit : this.fieldList}
            submitButtonTitle="登録"
            onDeleteClick={this.toggleDialogDelete}
            isSingleColumn={!isEdit}
            numberRow={4}
            isEdit={isEdit}
            submitErrors={submitErrors}
            currentDate={this.currentDate}
          />
        )}
        {isDialogDeleteShow && (
          <DialogDelete
            open={isDialogDeleteShow}
            onClose={this.toggleDialogDelete}
            onDeleteSubmit={this.onDeleteRobot}
            PaperProps={{
              className: "dialog dialog-sign-up"
            }}
          />
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  builderEntries: getBuilderEntries(
    state.rootRobotsReducer.rootRobotDetailsReducer.robotEntriesReducer
      .builderEntries
  ),
  staffEntries: getStaffEntries(
    state.rootRobotsReducer.rootRobotDetailsReducer.robotEntriesReducer
      .staffEntries
  ),
  robotHistory:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .robotHistory,
  robotLeaseHistory:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .robotLeaseHistory,
  type:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer.type,
  submitErrors: state.storesReducer.error
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRobotHistory,
      postRobotHistory,
      fillError,
      fetchRobotLeaseHistory,
      updateRobotHistory,
      fetchLogBuildRobotLeaseHistorySuccess,
      deleteRobotHistory,
      fetchRobotLogBuildDetail,
      fetchBuilderRobotAvailable
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RobotLogBuildHistory));
