import React, { memo } from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";

const DialogDelete = ({
  onSubmit,
  title,
  onDeleteSubmit,
  onClose,
  isLogWalk = false,
  isCompleteDialog = false,
  isTextBottomLeft = false,
  isTextNoKeepAll = false,

  textTop = "本当に削除しますか？",
  textBottom = "削除すると元に戻すことができません。",
  textBottomLogWalk="※ 図面に登録された写真は全て「未整理写真」に移動します。",
  textContent = "",
  textCompleteTop = "この現場を完了にします。",
  textCompleteBottom = "完了にすると、完了現場一覧に追加されます。",

  titleSubmit = "削除する",
  titleSubmitLogWalk = "図面を削除する",
  titleCancel = "キャンセル",
  ...rest
}) => {
  return (
    <Dialog
      aria-labelledby="max-width-dialog-title"
      fullWidth
      maxWidth="xs"
      onClose={() => {}}
      {...rest}
    >
      <Fab onClick={onClose} size="small" className="white-text close-button">
        <CloseIcon />
      </Fab>

      <DialogTitle disableTypography>
        {textContent.length > 0
          //when have textContent 
          ? <Typography className="confirm-title">
            <Typography
              className="block"
              variant="body1"
              variantMapping={{ body1: "span" }}
            >
              {textContent}
            </Typography>
          </Typography>

          //when no textContent
          : <Typography className={`${isTextBottomLeft ? "text-left" : "center"} confirm-title  ${isTextNoKeepAll ? "" : "keep-all"}`}>
            {isLogWalk
              ? <React.Fragment>
                <Typography
                  className={`block center`}
                  variant="body1"
                  variantMapping={{ body1: "span" }}
                >
                  {textTop}
                </Typography>
                {textBottom}
                <div style={{textAlign: "left", marginTop: "10px", marginBottom: "-10px", wordBreak: "normal"}}>{textBottomLogWalk}</div>
              </React.Fragment>
              : !isCompleteDialog
              ? <React.Fragment>
                <Typography
                  className={`block center`}
                  variant="body1"
                  variantMapping={{ body1: "span" }}
                >
                  {textTop}
                </Typography>
                {textBottom}
              </React.Fragment>
              : <React.Fragment>
                <Typography
                  className="block"
                  variant="body1"
                  variantMapping={{ body1: "span" }}
                  style={{ color: "red" }}
                >
                  {textCompleteTop}
                </Typography>
                <Typography
                  style={{ color: "red" }}
                >
                  {textCompleteBottom}
                </Typography>
              </React.Fragment>
            }
          </Typography>
        }
      </DialogTitle>
      {!isLogWalk && (
        <DialogContent className="confirm-content">
          <Button onClick={onDeleteSubmit} size="large" className="btn-red">
            {titleSubmit}
          </Button>
        </DialogContent>
      )}

      {isLogWalk && (
        <DialogContent className="confirm-content">
          <Button
            onClick={() => {
              onDeleteSubmit(false);
            }}
            size="large"
            className="btn-red my-5"
          >
            {titleSubmitLogWalk}
          </Button>
        </DialogContent>
      )}
      <DialogActions className="confirm-action">
        <Button onClick={onClose}>{titleCancel}</Button>
      </DialogActions>
    </Dialog>
  );
};

DialogDelete.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string
};

export default memo(DialogDelete);
