import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Button, Fab } from "@material-ui/core";
import clock_icon from "../../../common/img/clock_icon.svg"
import pen_icon from "../../../common/img/pen_icon.svg"
import add_icon from "../../../common/img/add_icon.svg"
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { enableLoading, finishLoading } from "common/Stores/service/service";
import { api } from "../../../common/api/api";
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import PhoneIcon from "@material-ui/icons/Phone";
import logmeetConfig from "../../../common/config/LogmeetConfig";
import { configStorage } from "../../../common/storage/storage";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import LogMeetPhoto from "../site/LogMeetPhoto";
import UploadIcon from "@material-ui/icons/CloudUpload";

import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import EditIcon from "@material-ui/icons/Edit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import axios from "axios";
import { saveAs } from "file-saver";
import EditImageDialog from "./editImageDialog";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Swipeable } from "react-swipeable";
import Popover from "@material-ui/core/Popover";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

function LogMeetHistoryDetailSP(props) {
  const { historyDetail, toggleHistoryDetailModal, sitesUC } = props;
  const [title, setTitle] = useState("");
  const [isFocusTitle, setIsFocusTitle] = useState(false);
  const [isFocusMemo, setIsFocusMemo] = useState(false);
  const [isShowMemo, setIsShowMemo] = useState(false);
  const [memo, setMemo] = useState("");
  const [baseHistory, setBaseHistory] = useState(null);
  const [imagesArr, setImagesArr] = useState([]);
  const [receiver, setReceiver] = useState(null);
  const [currentUserUniqueCode, setCurrentUserUniqueCode] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const [isDialogShowImage, setIsDialogShowImage] = useState(false);
  const [isDialogShowImageEdit, setIsDialogShowImageEdit] = useState(false);
  const [isDialogDeleteShow, setIsDialogDeleteShow] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [currentPhoto, setCurrentPhoto] = useState({});
  const [imagesArrLoaded, setImagesArrLoaded] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [onSwiping, setOnSwiping] = useState(null);
  const RIGHT = "-1";
  const LEFT = "+1";

  useEffect(() => {
    const token = api.defaults.headers.common["Authorization"];
    const decoded = jwt_decode(token);
    setCurrentUserUniqueCode(decoded.uniqueCode);
    fetchHistories();
  }, []);


  const fetchHistories = () => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/talk/${historyDetail.uid}`)
      .then(res => {
        setBaseHistory(res.data);
        setTitle(res.data.title);
        setMemo(res.data.memo);
        if (res.data.memo) {
          setIsShowMemo(true);
        }
        setImagesArr(res.data.images);
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  };

  const updateTitle = () => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .patch(`/${classification}/logmeet/talk/${historyDetail.uid}`, {
        title: title
      })
      .then(res => {
        setBaseHistory(res.data);
        setTitle(res.data.title);
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  };

  const updateMemo = () => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .patch(`/${classification}/logmeet/talk/${historyDetail.uid}`, {
        memo: memo
      })
      .then(res => {
        setBaseHistory(res.data);
        setMemo(res.data.memo);
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  };

  const days = ["日", "月", "火", "水", "木", "金", "土"];
  const diffMinutes = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  const uploadPhoto = (event) => {
    const element = event.target;
    const files = element.files;
    const exts = element.accept.split(',').map(ext => ext.toLowerCase());
    var containsInvalidMimeType = false;
    for (var i = 0; i < files.length; i++) {
      var file = files[i];
      containsInvalidMimeType = !exts.some(ext => file.name.toLowerCase().endsWith(ext));
      if (containsInvalidMimeType) {
        break;
      }
    }
    if (containsInvalidMimeType) {
      alert("無効なファイル形式を含んでいます");
      return;
    }
    const formData = new FormData();
    formData.append("postedAt", Date.now());
    for (var i = 0; i < files.length; i++) formData.append("file", files[i]);

    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .post(`/${classification}/logmeet/talk/${historyDetail.uid}/image`, formData)
      .then(res => {
        setImagesArr(res.data.images);
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
    element.value = "";
  }

  const toggleDialogImage = () => {
    setIsDialogShowImage(!isDialogShowImage)
  };

  const onImageClick = (photoId, name, url, postedAt) => {
    setCurrentPhoto({
      photoId: photoId,
      name: name,
      url: url,
      postedAt: postedAt
    })
    toggleDialogImage();
    const currentIndex = imagesArr.findIndex(
      photo => photo.uid === photoId
    );
    setCurrentPhotoIndex(currentIndex);
  };

  const handleKeyDown = event => {

    if ((event.keyCode === 37 && currentPhotoIndex <= 0) || (event.keyCode === 39 && currentPhotoIndex >= imagesArr.length - 1)) {
      return;
    }
    if (event.keyCode === 37) {
      handleChangeImage(false);
    }

    if (event.keyCode === 39) {
      handleChangeImage(true);
    }
  };

  const handleChangeImage = isNext => {
    const size = imagesArr.length;
    const currentIndex = imagesArr.findIndex(
      photo => photo.uid === currentPhoto.photoId
    );
    if (currentIndex !== -1) {
      let newIndex = 0;
      if (isNext) {
        newIndex = currentIndex + 1;
      } else {
        newIndex = currentIndex - 1;
      }
      const newPhoto = imagesArr[newIndex];
      const currentIndexLoaded = imagesArrLoaded.findIndex(
        photo => photo.id === newPhoto.uid
      );
      setCurrentPhotoIndex(newIndex);
      setCurrentPhoto({
        photoId: imagesArrLoaded[currentIndexLoaded].id,
        name: imagesArrLoaded[currentIndexLoaded].name ? imagesArrLoaded[currentIndexLoaded].name : `${imagesArrLoaded[currentIndexLoaded].postedAt}_image`,
        url: imagesArrLoaded[currentIndexLoaded].url,
        postedAt: imagesArrLoaded[currentIndexLoaded].postedAt,
      })
    }
  };

  const imageLoaded = (img) => {
    setImagesArrLoaded(currentArray => [...currentArray, img])
  }

  const downloadCurrentPhoto = () => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/talk/${historyDetail.uid}/image?file=${currentPhoto.photoId}`)
      .then(res => {
        if (res.data.blobData) {
          downloadBlob(res.data.blobData);
        }
        closePopover();
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  }

  const downloadBlob = (blob) => {
    dispatch(enableLoading());
    axios
      .get(blob, {
        responseType: 'arraybuffer'
      })
      .then(res => {
        save(res.data, convertType(currentPhoto.name, "jpg"));
        dispatch(finishLoading());
      })
  }

  const save = (data, name) => {
    const file = new File([data], name);
    saveAs(file);
  };

  const convertType = (name, extension) => {
    const nameArray = name.split(".");
    nameArray.push(extension);
    const newName = nameArray.join(".");
    return newName;
  };

  const showOriginalPhoto = () => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/talk/${historyDetail.uid}/image?file=${currentPhoto.photoId}`)
      .then(res => {
        if (res.data.blobData) {
          var url = res.data.blobData
          var w = window.open('', "_blank");
          w.document.write(`<img src="${url}">`)
        } else {
          var blob = new Blob([res.data], { type: "image/jpg" });
          var url = URL.createObjectURL(blob);
          window.open(url, "_blank");
        }
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  }

  const toggleDialogDelete = () => {
    setIsDialogDeleteShow(!isDialogDeleteShow);
    setIsDialogShowImageEdit(false);
  };

  const openDialogDelete = () => {
    setIsDialogDeleteShow(true);
  };

  const closeEditImageDialogShow = () => {
    setIsDialogDeleteShow(false);
    setIsDialogShowImageEdit(false);
  };

  const openEditImageDialogShow = () => {
    setIsDialogDeleteShow(false);
    setIsDialogShowImageEdit(true);
  };

  const updateImageName = (id, values) => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .patch(`/${classification}/logmeet/talk/${historyDetail.uid}/image?file=${id}`,
        {
          name: values.name
        }
      )
      .then(res => {
        setImagesArr(res.data.images);
        closeEditImageDialogShow();
        toggleDialogImage();
        closePopover();
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  }

  const deleteImages = (id) => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .delete(`/${classification}/logmeet/talk/${historyDetail.uid}/image?file=${id}`)
      .then(res => {
        fetchHistories();
        toggleDialogImage();
        closePopover();
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  }

  const closePopover = e => {
    setAnchorEl(null);
  };
  const showPopover = e => {
    setAnchorEl(e.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onSwiped = (direction) => {
    const change = direction === RIGHT;
    handleChangeImage(!change);
    setTimeout(() => {
      setOnSwiping(false)
    }, 1);
  }

  return (
    <>
      <Grid container className="site-logmeet-tab-detail-container"
      >
        <Grid item xs={12} style={{ display: "flex" }}>
          <span style={{
            fontFamily: "M PLUS 1",
            fontWeight: "normal",
            fontSize: "12.25px",
            textDecoration: "underline",
            cursor: "pointer",
            padding: "20px 0px"
          }}
            onClick={() => { toggleHistoryDetailModal(null) }}
          > {`<通話一覧へ戻る`}</span>
        </Grid>
        <Grid item xs={12}
          style={{
            display: "flex",
            backgroundColor: "#F8F9F9",
            border: "solid 1px #C7C7C7",
            borderRadius: "8px",
            padding: "16px 24px",
            color: "#333333"
          }}
        >
          <Grid container>
            <Grid item xs={12}
              style={{
                fontFamily: "M PLUS 1",
                fontWeight: "normal",
                fontSize: "12.25px",
                color: "#333333",
                marginBottom: "18px"
              }}
            >
              <img src={clock_icon} />
              {format(new Date(historyDetail.startAt), " yyyy年M月d日 ")}
              ({days[new Date(historyDetail.startAt).getDay()]}){" "}
              {format(new Date(historyDetail.startAt), "HH:mm 〜  ")}&nbsp;
              {diffMinutes(new Date(historyDetail.startAt), new Date(historyDetail.endAt))}分
            </Grid>
            <Grid item xs={2}
              style={{
                fontFamily: "M PLUS 1",
                fontWeight: "bold",
                fontSize: "14px",
                color: "#162F50"
              }}
            >
              タイトル
            </Grid>
            <Grid item xs={10}
              style={{
                fontFamily: "M PLUS 1",
                fontWeight: "bold",
                fontSize: "12.25px",
                color: "#162F50",
                display: "flex",
                position: "relative"
              }}
            >
              <input type="text"
                style={{
                  height: "32px",
                  border: "solid 1px #F0F0F0",
                  borderRadius: "8px",
                  width: "100%",
                  backgroundColor: "white",
                  paddingLeft: (!title || title.length === 0) ? "20px" : "8px",
                  fontFamily: "M PLUS 1",
                  fontWeight: "normal",
                  fontSize: "12,25px",
                  color: "#333333",
                }}
                value={title}
                onChange={e => setTitle(e.target.value)}
                placeholder="タイトルを入力（12字まで）"
                onFocus={() => { setIsFocusTitle(true) }}
                onBlur={() => {
                  setIsFocusTitle(false);
                }}
                maxLength={12}
              />
              {(!title || title.length === 0) && (<img src={pen_icon}
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "3px",
                  zIndex: "1005"
                }}
              />)}
            </Grid>
            <Grid item xs={12}
              style={{
                fontFamily: "M PLUS 1",
                fontWeight: "bold",
                fontSize: "14px",
                color: "#162F50",
                textAlign: "right",
                marginBottom: "8px"
              }}
            >
              {baseHistory && title != baseHistory.title && (
                <Button
                  onClick={() => {
                    updateTitle();
                  }}
                  style={{
                    width: "60px",
                    height: "28px",
                    backgroundColor: "#162F50",
                    color: "white",
                    fontFamily: "Noto Sans JP",
                    fontSize: "12.25px",
                    fontWeight: "bold",
                    marginLeft: "24px"
                  }}
                >保存</Button>
              )}

            </Grid>

            {(isShowMemo || (memo && memo.length > 0)) && (
              <>
                <Grid item xs={2}
                  style={{
                    fontFamily: "M PLUS 1",
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#162F50"
                  }}
                >
                  メモ
                </Grid>
                <Grid item xs={10}
                  style={{
                    fontFamily: "M PLUS 1",
                    fontWeight: "bold",
                    fontSize: "12.25px",
                    color: "#162F50",
                    display: "flex",
                    position: "relative"
                  }}
                >
                  <textarea type="text" style={{
                    height: "160px",
                    border: "solid 1px #F0F0F0",
                    borderRadius: "8px",
                    width: "100%",
                    backgroundColor: "white",
                    padding: (!memo || memo.length === 0) ? "4px 20px" : "4px 8px"
                  }}
                    maxLength={1000}
                    value={memo}
                    onChange={e => setMemo(e.target.value)}
                    placeholder="メモを入力（1000文字まで）"
                  />
                  {(!memo || memo.length === 0) && (<img src={pen_icon}
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "3px",
                      zIndex: "1005"
                    }}
                  />)}
                </Grid>
                <Grid item xs={12}
                  style={{
                    fontFamily: "M PLUS 1",
                    fontWeight: "bold",
                    fontSize: "12.25px",
                    color: "#162F50",
                    textAlign: "right",
                    marginTop: "8px"
                  }}
                >
                  {baseHistory && memo != baseHistory.memo && (
                    <Button
                      onClick={() => {
                        updateMemo();
                      }}
                      style={{
                        width: "60px",
                        height: "28px",
                        backgroundColor: "#162F50",
                        color: "white",
                        fontFamily: "Noto Sans JP",
                        fontSize: "12.25px",
                        fontWeight: "bold"
                      }}
                    >保存</Button>
                  )}
                </Grid>
              </>
            )}
            {(!isShowMemo && (!memo || memo.length === 0)) && (
              <>
                <Grid item xs={12}
                  style={{ textAlign: "right" }}
                >
                  <Button
                    onClick={() => {
                      setIsShowMemo(true)
                    }}
                    style={{
                      fontFamily: "M PLUS 1",
                      fontWeight: "normal",
                      fontSize: "11.38px",
                      color: "#333333",
                      marginTop: "8px"
                    }}
                  >
                    <img src={add_icon} />
                    メモ追加
                  </Button>
                </Grid>
              </>
            )}

            <Grid item xs={2}
              style={{
                fontFamily: "M PLUS 1",
                fontWeight: "bold",
                fontSize: "14px",
                color: "#162F50",
                marginTop: "12px"
              }}
            >
              参加者
            </Grid>
            <Grid item xs={10}
              style={{
                marginTop: "12px"
              }}
            >
              <Grid container>
                {baseHistory && (baseHistory.joinUsers
                  .map(joinUsers => (
                    <Grid item
                      style={{
                        fontFamily: "M PLUS 1",
                        fontWeight: "normal",
                        fontSize: "12.25px",
                        color: "#333333",
                        borderRadius: "24px",
                        backgroundColor: "white",
                        height: "36px",
                        padding: "4px 12px",
                        border: "solid 1px #F0F0F0",
                        marginRight: "8px",
                        cursor: currentUserUniqueCode === joinUsers.uid ? "unset" : "pointer"
                      }}
                      onClick={() => {
                        if (currentUserUniqueCode != joinUsers.uid) {
                          setReceiver(joinUsers);
                        }
                      }}
                    >
                      {currentUserUniqueCode != joinUsers.uid && (
                        <>
                          <Fab
                            size="small"
                            style={{ backgroundColor: "#162F50", width: "24px", height: "24px", minHeight: "24px" }}
                            className="more-vert-btn"
                          >
                            <PhoneIcon style={{ color: "white", width: "16px", height: "16px" }} />
                          </Fab>
                          &nbsp;
                        </>
                      )}
                      <span>{joinUsers.name}</span>
                    </Grid>
                  )))}
              </Grid>
            </Grid>
            <Grid item xs={6}
              style={{
                fontFamily: "M PLUS 1",
                fontWeight: "bold",
                fontSize: "14px",
                color: "#162F50",
                marginTop: "12px"
              }}
            >
              写真
            </Grid>

            <Grid item xs={6}
              style={{
                marginTop: "12px",
                textAlign: "right"
              }}
            >
              <input
                type="file"
                id="file"
                accept=".jpg,.jpeg,.png"
                // multiple
                onChange={e => uploadPhoto(e)}
                style={{ display: "none" }}
              />
              <Fab className="button-sp button-upload-sp button-logwalk-menu-center-sp">
                <label htmlFor="file" style={{ color: "inherit" }}>
                  <UploadIcon className="white-text icon" style={{ width: "26px", height: "26px" }} />
                </label>
              </Fab>
            </Grid>

            <Grid item xs={12}
              style={{
                fontFamily: "M PLUS 1",
                fontWeight: "bold",
                fontSize: "16px",
                color: "#162F50",
                marginTop: "12px"
              }}
            >
              <Grid container>
                {imagesArr && (imagesArr
                  // .sort((a, b) => a.postedAt > b.postedAt ? -1 : 1)
                  .map((photo, index) => (
                    <>
                      <Grid item style={{
                        width: "33.33%",
                        position: "relative",
                        paddingTop: "19.075%"
                      }}>
                        <LogMeetPhoto
                          key={photo.uid}
                          alt={photo.uid}
                          PhotosUC={photo.uid}
                          path={photo.path}
                          name={photo.name ? photo.name : `${photo.postedAt}_image`}
                          storage={photo.storage}
                          postedAt={photo.postedAt}
                          style={{
                            top: "0",
                            left: "0",
                            position: "absolute",
                            objectFit: "cover",
                            width: "calc(100% - 3px)",
                            height: "calc(100% - 3px)"
                          }}
                          onImageClick={onImageClick}
                          onImageLoaded={imageLoaded}
                          logmeetDetail={true}
                        />
                      </Grid>
                    </>
                  )))}
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={isDialogShowImage}
        aria-labelledby="max-width-dialog-title"
        PaperProps={{
          className: "dialog image-dialog-edit"
        }}
        onKeyDown={handleKeyDown}
      >
        <DialogTitle disableTypography className="header-dialog">
          <Fab onClick={toggleDialogImage} size="small">
            <ArrowBackIosIcon />
          </Fab>

          <Typography variant="body1">{currentPhoto.name}</Typography>
          <Fab onClick={showPopover} size="small">
            <MoreVertIcon />
          </Fab>
        </DialogTitle>
        <DialogContent className="content-dialog">
          <Grid>
            <Swipeable
              trackMouse
              preventDefaultTouchmoveEvent
              onSwipedLeft={() => onSwiped(LEFT)}
              onSwipedRight={() => onSwiped(RIGHT)}
              onSwiping={onSwiping}
            >
              <img
                onClick={() => showOriginalPhoto()}
                src={currentPhoto.url}
                alt={currentPhoto.photoId}
                draggable={false}
              />
              <Fab
                className="white-text reset-button-image button-prev-dialog"
                disabled={currentPhotoIndex === 0}
                onClick={() => handleChangeImage(false)}
              >
                <FontAwesomeIcon
                  size="3x"
                  icon={faChevronLeft}
                  className="white-text"
                />
              </Fab>
              <Fab
                className="white-text reset-button-image button-next-dialog"
                disabled={currentPhotoIndex === (imagesArr.length - 1)}
                onClick={() => handleChangeImage(true)}
              >
                <FontAwesomeIcon
                  size="3x"
                  icon={faChevronRight}
                  className="white-text"
                />
              </Fab>
            </Swipeable>
          </Grid>
        </DialogContent>
      </Dialog>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MenuList className="popover-sites">
          <MenuItem onClick={openEditImageDialogShow}>編集</MenuItem>
          <MenuItem onClick={() => downloadCurrentPhoto()}>ダウンロード</MenuItem>
        </MenuList>
      </Popover>

      {(isDialogShowImageEdit || isDialogDeleteShow) && (
        <EditImageDialog
          title={"写真情報編集"}
          isDialogShow={isDialogShowImageEdit}
          isDialogDeleteShow={isDialogDeleteShow}
          initialValues={{ name: currentPhoto.name }}
          toggleDialogDelete={toggleDialogDelete}
          openDialogDelete={openDialogDelete}
          onClose={closeEditImageDialogShow}
          submitButtonTitle={"登録"}
          photoId={currentPhoto.photoId}
          islogMeet={true}
          updateImageName={updateImageName}
          deleteImages={deleteImages}
        />
      )}

      {
        receiver && (
          <div className="calling-confirm-modal">
            <div className="calling-confirm-container"
              style={{ textAlign: "center" }}
            >
              <Fab
                onClick={() => { setReceiver(null) }}
                size="small"
                className="close-button"
                style={{ backgroundColor: "#162F50" }}
              >
                <CloseIcon style={{ color: "white" }} />
              </Fab>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold"
                }}
              >この電話をかけますか？</p>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  width: "100%"
                }}
                className="text-overflow-ellipsis"
              >{receiver.name}</p>
              <Button
                onClick={() => {
                  const classification = window.location.pathname.includes("/sp/")
                    ? 1 // Supplier
                    : 0 // Builder;

                  const logMeetUrl = logmeetConfig.getLogMeetURL(window.location.hostname);
                  const url = `${logMeetUrl}?${new URLSearchParams({
                    token: configStorage().replace("Bearer ", ""),
                    siteUC: sitesUC,
                    userUC: receiver.uid,
                    role: classification
                  })}`;
                  window.open(url, '_blank').focus();
                  setReceiver(null);
                }}
                style={{
                  backgroundColor: "#162F50",
                  color: "white",
                  width: "254px",
                  height: "48px",
                  margin: "20px 0"
                }}
              >
                <PhoneIcon style={{ color: "white", width: "22px", height: "22px" }} />
                &nbsp;
                <span
                  style={{
                    fontSize: "16px",
                    fontFamily: "Noto Sans JP",
                    fontWeight: 500
                  }}
                >発信する</span>
              </Button>
            </div>
          </div>
        )
      }
    </>
  );
}

export default LogMeetHistoryDetailSP;