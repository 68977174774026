import setAuthToken from "../../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { GET_ERRORS, USER_LOADING } from "../../../actions/types";
import axios from "axios";
import {
  setCurrentUser,
  forgotPasswordSuccess,
  signupSuccess,
  fetchRegexSuccess,
  fetchAvailableUrlSuccess,
  forgotPasswordSPSuccess,
  forgotPassworLogBuildSuccess
} from "../action/action";
import { dispatchError } from "../../../common/Stores/service/service";
import { api } from "common/api/api";
import OneSignal from "react-onesignal";

// Register User
export const registerUser = (userData, history) => dispatch => {
  axios
    .post("/api/users/register", userData)
    .then(res => history.push("/login")) // re-direct to login on successful register
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Login - get user token
export const loginUser = (userData, autoLogin) => dispatch => {
  axios
    .post("/api/builder/login", userData)
    .then(res => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      const { status } = res;
      if (status === 200 && autoLogin) localStorage.setItem("jwtToken", token);
      if (status === 200) sessionStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      dispatch({
        type: GET_ERRORS,
        payload: ""
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// LoginLogBuild - get user token
export const loginUserLogBuild = (userData, autoLogin) => dispatch => {
  axios
    .post("/api/logbuild/login", userData)
    .then(res => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      const { status } = res;
      if (status === 200 && autoLogin) localStorage.setItem("jwtToken", token);
      if (status === 200) sessionStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      dispatch({
        type: GET_ERRORS,
        payload: ""
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// LoginSP - get user token
export const loginUserSP = (userData, autoLogin) => dispatch => {
  axios
    .post("/api/supplier/login", userData)
    .then(res => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      const { status } = res;
      if (status === 200 && autoLogin) localStorage.setItem("jwtToken", token);
      if (status === 200) sessionStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      dispatch({
        type: GET_ERRORS,
        payload: ""
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Clear errors
export const clearErrors = () => dispatch => {
  dispatch({
    type: GET_ERRORS,
    payload: {}
  });
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// Log user out
export const logoutUser = (unregisterOnesignal = false) => async dispatch => {
  if(unregisterOnesignal){
    const playerID = await OneSignal.getUserId();
    if (playerID) {
      const classification = window.location.pathname.includes("/sp/")
        ? "supplier"
        : "builder";
      api.post(`/${classification}/logmeet/device/unregister`, {
        playerId: playerID
      });
    }
  }

  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  sessionStorage.removeItem("jwtToken");

  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }

  setAuthToken(false);
  dispatch(setCurrentUser({}));
  // Remove auth header for future requests
  // Set current user to empty object {} which will set isAuthenticated to false
};

export const forgotPassword = data => dispatch => {
  axios
    .put(`/api/builder/reset-password`, data)
    .then(res => dispatch(forgotPasswordSuccess(true))) // re-direct to login on successful register
    .catch(err => dispatch(dispatchError(err)));
};

export const signup = (id, data, handleError) => dispatch => {
  api
    .post(`/builder/${id}/signup`, data)
    .then(res => {
      dispatch(signupSuccess(true));
      dispatch(dispatchError(null));
    })
    .catch(err => {
      if (handleError && err.response.status === 500) {
        handleError();
      } else {
        dispatch(dispatchError(err));
      }
    });
};

export const availableUrl = id => dispatch => {
  api
    .head(`/builder/${id}/signup`)
    .then(res => {
      dispatch(fetchAvailableUrlSuccess(true));
      dispatch(dispatchError(null));
    })
    .catch(err => dispatch(dispatchError(err)));
};

export const regexSignUp = () => dispatch => {
  axios
    .get(`/api/common/validator-config`)
    .then(res => {
      dispatch(fetchRegexSuccess(res.data));
    })
    .catch(err => dispatch(dispatchError(err)));
};

export const forgotPasswordSP = data => dispatch => {
  axios
    .put(`/api/supplier/reset-password`, data)
    .then(res => dispatch(forgotPasswordSPSuccess(true))) // re-direct to login on successful register
    .catch(err => dispatch(dispatchError(err)));
};

export const forgotPasswordLogBuild = data => dispatch => {
  axios
    .put(`/api/logbuild/reset-password`, data)
    .then(res => dispatch(forgotPassworLogBuildSuccess(true))) // re-direct to login on successful register
    .catch(err => dispatch(dispatchError(err)));
};
