import React from "react";
import PropTypes from "prop-types";

function IconShareUrl() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#fff" transform="translate(-380 -3279)">
          <g transform="translate(56 160)">
            <path d="M342.239 3120.761a6.014 6.014 0 00-8.505 0l-2.568 2.57 1.417 1.416 2.569-2.568a4.008 4.008 0 015.67 0 4.015 4.015 0 010 5.67l-2.57 2.568 1.418 1.418 2.569-2.57a6.014 6.014 0 000-8.504zm-9.39 15.06a3.98 3.98 0 01-2.835 1.174 3.98 3.98 0 01-2.835-1.174 4.012 4.012 0 010-5.67l2.57-2.568-1.419-1.418-2.569 2.569a6.015 6.015 0 008.506 8.505l2.568-2.569-1.418-1.418-2.569 2.569zm3.414-10.501l1.417 1.417-5.958 5.959-1.417-1.418 5.958-5.958z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

function IconShareUrlHeader(props) {
  return (
    <span
      style={{
        width: "28px",
        height: "28px",
        borderRadius: "50%",
        overflow: "hidden"
      }}
    >
      <IconShareUrl />
    </span>
  );
}
export default IconShareUrlHeader;
