import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  textareaScrollBar: {
    "&::-webkit-scrollbar": {
      backgroundColor: "#fff",
      width: "16px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#babac0",
      borderRadius: "16px",
      border: "4px solid #fff"
    },
    "&::-webkit-scrollbar-button": {
      display: "none"
    }
  }
});

function DescriptionInput(props) {
  const classes = useStyles();
  const { description, setDescription, placeholder } = props;
  return (
    <textarea
      value={description}
      onChange={e => {
        setDescription(e.target.value);
      }}
      rows={3}
      style={{
        width: "100%",
        height: "90px",
        boxSizing: "borde r-box",
        padding: "10px 10px 10px 20px",
        margin: "0px",
        fontSize: "17px",
        lineHeight: "23px",
        fontWeight: "normal",
        border: "1px solid #c7c7c7",
        borderRadius: "4px",
        outline: "none",
        backgroundColor: "#F8F9F9",
        cursor: "pointer",
      }}
      placeholder={placeholder}
      className={classes.textareaScrollBar}
    />
  );
}

DescriptionInput.propTypes = {
  description: PropTypes.string,
  setDescription: PropTypes.func,
  placeholder: PropTypes.string
};

export default DescriptionInput;
