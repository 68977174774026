import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import EditShareUrlDialog from "./EditShareUrlDialog";
import ListShareUrlsTable from "./ListShareUrlsTable";
import qs from 'qs';
import { api } from "../../common/api/api";

function ListShareUrls() {
  const [allShareUrls, setAllShareUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingShareUrl, setEditingShareUrl] = useState(null);
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [pagination, setPagination] = useState(null);

  const location = useLocation();

  useEffect(() => {
    fetchAllShareUrls();
  }, []);

  const fetchAllShareUrls = () => {
    setLoading(true);
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .post(`/${classification}/url-share`, {
        page: paginationNumber,
        per_page: 30
      },
      )
      .then(res => {
        const sortedData = res.data.data.sort(function (a, b) {
          return new Date(b.CreatedAt) - new Date(a.CreatedAt);
        })
        setAllShareUrls(sortedData);
        setPagination(res.data.meta.pagination);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  };

  const editShareUrlSuccess = (id, data) => {
    const newAllShareUrls = allShareUrls.map(item => {
      if (item.Id === id) {
        const newEditingShareUrl = {
          ...item,
          SiteName: data.SiteName,
          SpotName: data.SpotName,
          IsHideName: data.IsHideName
        };
        setEditingShareUrl(newEditingShareUrl);
        return newEditingShareUrl;
      } else {
        return item;
      }
    });

    setAllShareUrls(newAllShareUrls);
  };
  const changePage = (value) => {
    setLoading(true);
    setPaginationNumber(value);
    const classification = location.pathname.includes("/sp/")
    ? "supplier"
    : "builder";
  api
    .post(`/${classification}/url-share`, {
      page: value,
      per_page: 30
    },
    )
    .then(res => {
      const sortedData = res.data.data.sort(function (a, b) {
        return new Date(b.CreatedAt) - new Date(a.CreatedAt);
      })
      setAllShareUrls(sortedData);
      setPagination(res.data.meta.pagination);
      setLoading(false);
    })
    .catch(err => {
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      <div style={{ width: "100%", padding: "30px 20px" }}>
        <p style={{ fontSize: "24px", marginBottom: "16px" }}>
          URL共有一覧<span style={{ fontSize: "16px" }}>{"("}</span>
          <a
            href="https://www.log-build.com/ls-help/share"
            target="_blank"
            style={{ color: "#2abdf2", fontSize: "16px" }}
            rel="noreferrer"
          >
            URL共有とは
          </a>
          <span style={{ fontSize: "16px" }}>{")"}</span>
        </p>
        <ListShareUrlsTable
          shareUrls={allShareUrls}
          paginationNumber={paginationNumber}
          setPaginationNumber={changePage}
          pagination={pagination}
          setEditingShareUrl={setEditingShareUrl}
          stickyHeader
        />
      </div>

      <EditShareUrlDialog
        editingShareUrl={editingShareUrl}
        setEditingShareUrl={setEditingShareUrl}
        editShareUrlSuccess={editShareUrlSuccess}
        inactiveShareUrlSuccess={fetchAllShareUrls}
      />
    </React.Fragment>
  );
}

export default ListShareUrls;
