import {
  fetchBuilderJobSuccess,
  fetchStoresSuccess,
  fetchStoresError,
  fetchPICSuccess,
  fetchPICError,
  fetchBuilderName,
  fetchBuilderFlag,
  apiError,
  showLoadingIndicator,
  hideLoadingIndicator,
  finishLoadingData,
  postUserAcknowledgementSuccess,
  fetchRegexValidateSuccess,
  fetchMessageValidateSuccess,
  closePasswordDialogSuccess,
  putPasswordSpSuccess,
  checkAuthSuccess,
  disCheckAuthSuccess
} from "../action/action";
import axios from "axios";
import { api } from "common/api/api";

export const fetchStores = () => {
  return dispatch =>
    api
      .get(`/stores/entries`)
      .then(res => {
        dispatch(fetchStoresSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchStoresError(err));
      });
};

export const fetchPIC = () => {
  return dispatch =>
    api
      .get(`/builder/builder-staffs/entries`)
      .then(res => {
        dispatch(fetchPICSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchPICError(err));
      });
};

export const fetchUserMyself = () => {
  return dispatch => {
    api.get(`/users/myself`).then(res => {
      dispatch(fetchBuilderName(res));
      dispatch(fetchBuilderFlag(res));
    });
  };
};

export const fetchBuilderStaffJob = () => {
  return dispatch => {
    api
      .get(`/builder/builder-staff-jobs/entries`)
      .then(res => {
        dispatch(fetchBuilderJobSuccess(res.data));
      })
      .catch(err => {
        dispatch(apiError(err.response.data));
      });
  };
};

export const dispatchError = errors => {
  return dispatch => {
    if (errors === null || errors === "") {
      dispatch(apiError(null));
    } else {
      if (!errors || (errors.response && errors.response.data));
      dispatch(apiError(errors.response.data));
    }
  };
};

export const enableLoading = () => {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.storesReducer.pending) dispatch(showLoadingIndicator());
  };
};

export const disableLoading = () => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.storesReducer.pending) dispatch(hideLoadingIndicator());
  };
};

export const finishLoading = () => {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.storesReducer.finishLoading) dispatch(finishLoadingData());
  };
};

export const createUserAcknowledgement = value => {
  return dispatch => {
    api.post(`/userAcknowledgement`, value).then(res => {
      dispatch(postUserAcknowledgementSuccess(res.data));
    });
  };
};

export const translateValidate = () => {
  return dispatch => {
    axios.get(`/api/common/translator-config`).then(res => {
      dispatch(fetchRegexValidateSuccess(res.data));
    });
  };
};

export const messageValidate = () => {
  return dispatch => {
    axios.get(`/api/common/message-config`).then(res => {
      dispatch(fetchMessageValidateSuccess(res.data));
    });
  };
};

export const closePasswordDialog = () => {
  return dispatch => {
    dispatch(closePasswordDialogSuccess());
  };
};

export const putPasswordSP = data => {
  return dispatch => {
    api.put(`/supplier/user-password`, data).then(res => {
      dispatch(putPasswordSpSuccess());
    });
  };
};

export const checkAuth = () => {
  return (dispatch) => {
    dispatch(checkAuthSuccess());
  };
};

