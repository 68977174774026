import { MenuItem, Popover } from "@material-ui/core";
import React, { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/core/styles";
import Brightness1Icon from "@material-ui/icons/Brightness1";
const useStyles = makeStyles({
  selectFieldWrapper: {
    width: "100%",
    height: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #00356D",
    borderRadius: "4px",
    cursor: "pointer",
    "& > div:first-child": {
      flexGrow: "1",
      padding: "0px 12px",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      color: "#00356D"
    },
    "& > span:last-child": {
      color: "#00356D",
      fontSize: "24px",
      lineHeight: "24px",
      width: "24px",
      height: "24px",
      marginRight: "4px"
    }
  },
  menuItem: {
    height: "30px",
    minHeight: "30px",
    textAlign: "center",
    color: "#00356D",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#F8F9F9"
    }
  }
});

function SelectStatus(props) {
  const { completedAt, showConfirmChangeStatus } = props;
  const [anchorElSelect, setAnchorElSelect] = useState(null);

  const classes = useStyles();

  const handleClick = event => {
    setAnchorElSelect(event.currentTarget);
  };

  return (
    <>
      <div style={{ width: "140px" }}>
        <div className={classes.selectFieldWrapper} onClick={handleClick}>
          <div>
            {completedAt ? (
              <span>
                <span
                  style={{
                    fontSize: "10px",
                    color: "red",
                    lineHeight: "inherit",
                    marginRight: "3px"
                  }}
                >
                  <Brightness1Icon color="inherit" fontSize="inherit" />
                </span>
                完了
              </span>
            ) : (
              <span>未完了</span>
            )}
          </div>
          <span>
            <ArrowDropDownIcon fontSize="inherit" color="inherit" />
          </span>
        </div>
      </div>

      <Popover
        open={Boolean(anchorElSelect)}
        anchorEl={anchorElSelect}
        onClose={() => setAnchorElSelect(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            width: "140px",
            overflow: "hidden",
            padding: "0px"
          }
        }}
      >
        <div
          style={{ width: "100%", height: "100%", overflow: "auto" }}
          className={classes.customScrollbar}
        >
          <MenuItem
            onClick={() => {
              if (completedAt) {
                showConfirmChangeStatus();
              } else {
                setAnchorElSelect(null);
              }
            }}
            className={classes.menuItem}
            style={{
              backgroundColor: !completedAt ? "#F8F9F9" : "white"
            }}
          >
            <span>未完了</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (!completedAt) {
                showConfirmChangeStatus();
              } else {
                setAnchorElSelect(null);
              }
            }}
            className={classes.menuItem}
            style={{
              backgroundColor: completedAt ? "#F8F9F9" : "white"
            }}
          >
            <span>
              <span
                style={{
                  fontSize: "10px",
                  color: "red",
                  lineHeight: "inherit",
                  marginRight: "3px"
                }}
              >
                <Brightness1Icon color="inherit" fontSize="inherit" />
              </span>
              完了
            </span>
          </MenuItem>
        </div>
      </Popover>
    </>
  );
}

export default SelectStatus;
