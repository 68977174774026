import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useLocation } from "react-router-dom";
import { api } from "../../common/api/api";
import { Grid, makeStyles, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PhoneIcon from "@material-ui/icons/Phone";
import ReloadIcon from "../../common/img/ReloadIcon.svg";
import ImageIcon from "../../common/img/ImageIcon.svg";
import Pagination from "@material-ui/lab/Pagination";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { enableLoading, finishLoading } from "common/Stores/service/service";
import Fab from "@material-ui/core/Fab";
import logmeetConfig from "../../common/config/LogmeetConfig";
import { configStorage } from "../../common/storage/storage";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles({
  paginationUl: {
    margin: "0",
    display: "flex",
    padding: "0",
    flexWrap: "wrap",
    listStyle: "none",
    alignItems: "center",
    justifyContent: "center"
  },
  truncateText3: {
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "wrap",
    wordBreak: "break-word"
  }
});

function LogMeetHistoryAllSP(props, ref) {
  const { toggleHistoryDetailModal, setCurrentItemIndex } = props;
  const [isShowConfirmJoinCall, setIsShowConfirmJoinCall] = useState(false);
  const [allHistories, setAllHistories] = useState([]);
  const [historiesOffset, setHistoriesOffset] = useState(0);
  const [historiesCount, setHistoriesCount] = useState(20);
  const location = useLocation();
  const NumberOfPage = 20;
  const [pagination, setPagination] = useState(null);
  const [talkDetail, setTalkDetail] = useState(null);
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [currentUserUniqueCode, setCurrentUserUniqueCode] = useState(null);
  const [receiver, setReceiver] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const callStatusClasss = ["", "user-history-calling", "user-history-cancel", "user-history-missing", "user-history-complete"];
  const [disableButtonJoinCall, setDisableButtonJoinCall] = useState(false);
  const [isNobodyInCall, setIsNobodyInCall] = useState(false);

  useEffect(() => {
    const token = api.defaults.headers.common["Authorization"];
    const decoded = jwt_decode(token);
    setCurrentUserUniqueCode(decoded.uniqueCode);
    fetchHistories();
  }, []);

  useImperativeHandle(ref, () => ({
    reloadFromOutside() {
      ReloadPage(paginationNumber);
    },
    reloadFirstFromOutside() {
      changePage(1);
    }
  }), [])

  const fetchHistories = () => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/talkhistories?offset=${historiesOffset}&count=${historiesCount}`)
      .then(res => {
        setAllHistories(res.data.histories);
        if (res.data.total > NumberOfPage) {
          setPagination({
            total_pages: Math.ceil(res.data.total / NumberOfPage),
            paginationNumber: paginationNumber
          })
        }
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  };

  const changePage = (value) => {
    dispatch(enableLoading());
    setPaginationNumber(value);
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/talkhistories?offset=${value === 1 ? 0 : (value - 1) * historiesCount}&count=${historiesCount}`)
      .then(res => {
        setAllHistories(res.data.histories);
        if (res.data.total > NumberOfPage) {
          setPagination({
            total_pages: Math.ceil(res.data.total / NumberOfPage),
            paginationNumber: paginationNumber
          })
        }
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  };

  const diffMinutes = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  const ReloadPage = (page) => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/talkhistories?offset=${page === 1 ? 0 : (page - 1) * historiesCount}&count=${historiesCount}`)
      .then(res => {
        setAllHistories(res.data.histories);
        if (res.data.total > NumberOfPage) {
          setPagination({
            total_pages: Math.ceil(res.data.total / NumberOfPage),
            paginationNumber: paginationNumber
          })
        }
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  }

  const toggleConfirmJoinCallModal = () => {
    setIsShowConfirmJoinCall(!isShowConfirmJoinCall);
  }

  const checkJoinCall = (uid) => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/talk/${uid}/users`)
      .then(res => {
        setTalkDetail(
          {
            ...res.data,
            uid: uid
          });
        var currentUserIndex = res.data.users.findIndex(
          user => user.uid === currentUserUniqueCode && user.status === 1
        );
        var joiner = res.data.users.filter(user => user.status === 1);
        setDisableButtonJoinCall(currentUserIndex >= 0 || joiner.length >= 4);
        setIsNobodyInCall((currentUserIndex >= 0 && joiner.length === 1) || joiner.length === 0);
        dispatch(finishLoading());
        toggleConfirmJoinCallModal();
      })
      .catch(err => {
        setTalkDetail(null)
        dispatch(finishLoading());
      });
  }

  return (
    <>
      <Grid container className="site-logmeet-tab-all-container"
        style={{ position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            top: "-50px",
            right: "0"
          }}
        >
          <Button
            style={{
              color: "#162F50",
              fontFamily: "Noto Sans JP",
              fontWeight: "normal",
              fontSize: "14px",
              cursor: "pointer",
              width: "80px",
              height: "32px",
              borderRadius: "20px",
              border: "solid 1px #162F50"
            }}
            onClick={() => {
              ReloadPage(paginationNumber);
            }}
          >
            更新
            <img src={ReloadIcon} />
          </Button>
        </div>
        <Grid item xs={12}>
          <Grid container className="table-remove-overflow">
            <Grid item xs={12} style={{ backgroundColor: "#162f50", height: "48px", lineHeight: "48px", padding: "0 13px" }}>
              <Grid container >
                <Grid item xs={3}
                  className="white-text text-center"
                >
                  <span style={{ verticalAlign: "middle" }}>通話日時</span>
                </Grid>
                <Grid item xs={3}
                  className="white-text text-center"
                >

                </Grid>
                <Grid
                  item xs={3}
                  className="white-text text-center"
                >
                  発信者
                </Grid>
                <Grid
                  item xs={2}
                  className="white-text text-center"
                >
                  写真
                </Grid>
                <Grid
                  item xs={1}
                  className="white-text text-center"
                >
                </Grid>
              </Grid>
            </Grid>
            {allHistories.length > 0 &&
              allHistories.map(
                (item, index) => (
                  <Grid item xs={12}
                    id={`history-item-${index}`}
                    key={item.id}
                    className={`history-call-row-all-sp ${callStatusClasss[(item.endAt || item.status === 2) ? item.status : 1]}`}
                    onClick={() => {
                      if (!item.endAt && item.status != 2) { // join a call
                        checkJoinCall(item.uid);
                      } else {
                        if (item.status === 4) {// show history detail
                          setCurrentItemIndex(index);
                          toggleHistoryDetailModal(item);
                        };
                        if (item.status === 2 || item.status === 3) { // recall
                          setReceiver({
                            ...item.caller,
                            site: item.site
                          });
                        }
                      }
                    }}
                    style={{
                      cursor: "pointer"
                    }}
                  >
                    <Grid container style={{ height: "100%", verticalAlign: "middle", padding: "5px 16px" }}>
                      <Grid item xs={3}
                        style={{
                          fontSize: "12.5px",
                          fontFamily: "M PLUS 1",
                          fontWeight: "normal",
                          color: "#333333",
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        {format(new Date(item.startAt), "yyyy年M月d日")}
                        <br />
                        {format(new Date(item.startAt), "HH:mm")}
                      </Grid>
                      <Grid item xs={3}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "12.5px",
                          fontFamily: "M PLUS 1",
                          fontWeight: "normal",
                          color: "#333333"
                        }}
                      >
                        <Grid container>
                          {item.status === 2 && (
                            <Grid xs={12}
                              style={{
                                padding: "2px 0",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: "#FA8227",
                                  color: "white",
                                  padding: "2px 10px",
                                  borderRadius: "24px",
                                  fontSize: "12.5px",
                                  height: "22px",
                                  textAlign: "center",
                                  width: "fit-content"
                                }}
                              >キャンセル</span>
                            </Grid>
                          )}
                          {item.status === 3 && (
                            <Grid xs={12}
                              style={{
                                padding: "2px 0",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: "#B00020",
                                  color: "white",
                                  padding: "2px 10px",
                                  borderRadius: "24px",
                                  fontSize: "12.5px",
                                  height: "22px",
                                  textAlign: "center",
                                  width: "fit-content"
                                }}
                              >不在着信</span>
                            </Grid>
                          )}
                          {!item.endAt && item.status != 2 && (
                            <Grid xs={12}
                              style={{
                                padding: "2px 0",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: "#8FC31F",
                                  color: "white",
                                  padding: "2px 10px",
                                  borderRadius: "24px",
                                  fontSize: "12.5px",
                                  height: "22px",
                                  textAlign: "center",
                                  width: "fit-content"
                                }}
                              >通話中</span>
                            </Grid>
                          )}
                          {item.status === 4 && item.endAt && (
                            <Grid xs={12}
                              style={{
                                padding: "2px 0",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                              }}
                            >
                              {diffMinutes(new Date(item.startAt), new Date(item.endAt))}分
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={3}
                        className="text-overflow-ellipsis"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "12.5px",
                          fontFamily: "M PLUS 1",
                          fontWeight: "normal",
                          color: "#333333",
                        }}
                      >
                        <p>
                          {item.caller.name}
                        </p>
                      </Grid>
                      <Grid item xs={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "12.5px",
                          fontFamily: "M PLUS 1",
                          fontWeight: "normal",
                          color: "#333333",
                        }}>
                        {item.images.length > 0 &&
                          (
                            <>
                              <img src={ImageIcon} style={{ verticalAlign: "middle" }} /> ({item.images.length}枚)
                            </>
                          )}
                      </Grid>

                      <Grid item xs={1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "12.5px",
                          fontFamily: "M PLUS 1",
                          fontWeight: "normal",
                          color: "#333333",
                          justifyContent: "center"
                        }}>
                        {((item.endAt && item.status != 4) || (!item.endAt)) && (
                          <Fab
                            size="small"
                            style={{ backgroundColor: "#162F50", width: "24px", height: "24px", minHeight: "24px" }}
                            className="more-vert-btn"
                          >
                            <PhoneIcon style={{ color: "white", width: "16px", height: "16px" }} />
                          </Fab>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )
              )}
          </Grid>
          {pagination && pagination.total_pages > 1 && (
            <Pagination
              classes={{ ul: classes.paginationUl }}
              count={pagination ? pagination.total_pages : 0}
              page={paginationNumber}
              onChange={(event, value) => {
                changePage(value);
              }
              }
              style={{
                border: "none",
                backgroundColor: "#fff",
                boxShadow: "none"
              }}
            />
          )}
        </Grid>
      </Grid>
      {
        isShowConfirmJoinCall && (
          <>
            <div className="join-call-confirm-wrapper">
              <div className="join-call-confirm-container">
                <Grid container>
                  <Fab
                    size="small"
                    className="close-button"
                    style={{ backgroundColor: "#162F50" }}
                    onClick={() => {
                      toggleConfirmJoinCallModal();
                    }}
                  >
                    <CloseIcon style={{ color: "white" }} />
                  </Fab>
                  <Grid item xs={12}
                    style={{
                      textAlign: "center",
                      fontFamily: "M PLUS 1",
                      color: "#333333",
                      fontSize: "16px",
                      fontWeight: "bold"
                    }}>
                    この通話に参加しますか？
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className="user-join-container">
                      {(!isNobodyInCall) && (talkDetail.users.length > 0) &&
                        talkDetail.users.map(
                          (item, index) => {
                            if (item.uid != currentUserUniqueCode && item.status === 1) {
                              return (
                                <Grid item
                                  className={`user-join`}
                                >
                                  <span>{item.name}</span>
                                </Grid>
                              )
                            }
                          }
                        )}
                      {isNobodyInCall && (
                        <Grid item xs={12}
                          className={`text-center`}
                        >
                          <span>通話には誰もいない</span>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Button
                      disabled={disableButtonJoinCall}
                      className={`${disableButtonJoinCall ? "button-disable" : ""}`}
                      onClick={() => {
                        const classification = window.location.pathname.includes("/sp/")
                          ? 1 // Supplier
                          : 0 // Builder;

                        const logMeetUrl = logmeetConfig.getLogMeetURL(window.location.hostname);
                        const url = `${logMeetUrl}?${new URLSearchParams({
                          token: configStorage().replace("Bearer ", ""),
                          talk: talkDetail.uid,
                          role: classification
                        })}`;
                        window.open(url, '_blank').focus();
                        toggleConfirmJoinCallModal();
                      }}
                      style={{
                        backgroundColor: "#162F50",
                        color: "white",
                        width: "254px",
                        height: "48px",
                        margin: "20px 0",
                        borderRadius: "8px"
                      }}
                    >
                      <PhoneIcon style={{ color: "white", width: "22px", height: "22px" }} />
                      &nbsp;
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Noto Sans JP",
                          fontWeight: "normal"
                        }}
                      >参加する</span>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        )
      }

      {
        receiver && (
          <div className="calling-confirm-modal">
            <div className="calling-confirm-container"
              style={{ textAlign: "center" }}
            >
              <Fab
                onClick={() => { setReceiver(null) }}
                size="small"
                className="close-button"
                style={{ backgroundColor: "#162F50" }}
              >
                <CloseIcon style={{ color: "white" }} />
              </Fab>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold"
                }}
              >この電話をかけますか？</p>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  width: "100%"
                }}
                className="text-overflow-ellipsis"
              >{receiver.name}</p>
              <Button
                onClick={() => {
                  const classification = window.location.pathname.includes("/sp/")
                    ? 1 // Supplier
                    : 0 // Builder;

                  const logMeetUrl = logmeetConfig.getLogMeetURL(window.location.hostname);
                  const url = `${logMeetUrl}?${new URLSearchParams({
                    token: configStorage().replace("Bearer ", ""),
                    siteUC: receiver.site ? receiver.site.uid : "",
                    userUC: receiver.uid,
                    role: classification
                  })}`;
                  window.open(url, '_blank').focus();
                  setReceiver(null);
                }}
                style={{
                  backgroundColor: "#162F50",
                  color: "white",
                  width: "254px",
                  height: "48px",
                  margin: "20px 0"
                }}
              >
                <PhoneIcon style={{ color: "white", width: "22px", height: "22px" }} />
                &nbsp;
                <span
                  style={{
                    fontSize: "16px",
                    fontFamily: "Noto Sans JP",
                    fontWeight: 500
                  }}
                >発信する</span>
              </Button>
            </div>
          </div>
        )
      }

    </>
  );
}

export default forwardRef(LogMeetHistoryAllSP)