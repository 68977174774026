import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DrawingPhoto from "./DrawingPhoto";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import Dialog from "@material-ui/core/Dialog";
import CancelIcon from "@material-ui/icons/Cancel";
import ZoomInMapImg from "../../../common/img/zoom-in-map.png";

const useStyles = makeStyles({
  transformWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    position: "relative",
    backgroundColor: "#F2F2F2"
  },
  drawingPhoto: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    margin: "auto",
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    overflow: "hidden",
    display: "inline-block",
    backgroundColor: "#F2F2F2"
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: "#d6d6d6"
    }
  }
});

function DrawingBox(props) {
  const {
    drawingsWithSpots,
    listShootInfo,
    assignCurrentShootToSpot,
    showShoot,
    checkCompleteChange,
    setShowConfirmSaveChange,
    setShowConfirmCancelChange,
  } = props;

  const [currentDrawingIndex, setCurrentDrawingIndex] = useState(0);
  const [spotPositions, setSpotPositions] = useState([]);
  const [spotPositionsDialog, setSpotPositionsDialog] = useState([]);
  const [showDrawingDialog, setShowDrawingDialog] = useState(false);

  const classes = useStyles();
  const transformComponentRef = useRef(null);

  useEffect(() => {
    if (drawingsWithSpots.length > 0) {
      window.addEventListener("resize", () => {
        calculateSpotPositions();
        calculateSpotPositionsDialog();
      });
    }

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [drawingsWithSpots]);

  const nextDrawing = () => {
    if (currentDrawingIndex < drawingsWithSpots.length - 1) {
      setCurrentDrawingIndex(currentDrawingIndex + 1);
      setTimeout(returnDefaultSize, 100);
    }
  };

  const previousDrawing = () => {
    if (currentDrawingIndex > 0) {
      setCurrentDrawingIndex(currentDrawingIndex - 1);
      setTimeout(returnDefaultSize, 100);
    }
  };

  const calculateSpotPositions = () => {
    let element = document.getElementById("drawing-box-image");

    let naturalWidth = element ? element.naturalWidth : 0;

    let naturalHeight = element ? element.naturalHeight : 0;

    const elMap = document.getElementById("drawing-box-wrapper");
    let scaleMap = elMap ? elMap.offsetWidth / elMap.offsetHeight : 0;
    let isVerticalImg = naturalHeight * scaleMap > naturalWidth;

    let offsetWidth = 0;
    let offsetHeight = 0;

    if (isVerticalImg) {
      offsetWidth = elMap
        ? (elMap.offsetHeight * naturalWidth) / naturalHeight
        : 0;

      offsetHeight = elMap ? elMap.offsetHeight : 0;
    } else {
      offsetWidth = elMap ? elMap.offsetWidth : 0;

      offsetHeight = elMap
        ? (elMap.offsetWidth * naturalHeight) / naturalWidth
        : 0;
    }

    let offsetLeft = elMap ? (elMap.offsetWidth - offsetWidth) / 2 : 0;

    let offsetTop = elMap ? (elMap.offsetHeight - offsetHeight) / 2 : 0;

    let positions = [];

    drawingsWithSpots[currentDrawingIndex].spots.map(pos => {
      positions.push({
        ...pos,
        posX: (pos.x * offsetWidth) / naturalWidth + offsetLeft,
        posY: (pos.y * offsetHeight) / naturalHeight + offsetTop
      });
    });

    setSpotPositions(positions);
  };

  const calculateSpotPositionsDialog = () => {
    let element = document.getElementById("drawing-box-image-dialog");

    let naturalWidth = element ? element.naturalWidth : 0;

    let naturalHeight = element ? element.naturalHeight : 0;

    const elMap = document.getElementById("drawing-box-wrapper-dialog");
    let scaleMap = elMap ? elMap.offsetWidth / elMap.offsetHeight : 0;
    let isVerticalImg = naturalHeight * scaleMap > naturalWidth;

    let offsetWidth = 0;
    let offsetHeight = 0;

    if (isVerticalImg) {
      offsetWidth = elMap
        ? (elMap.offsetHeight * naturalWidth) / naturalHeight
        : 0;

      offsetHeight = elMap ? elMap.offsetHeight : 0;
    } else {
      offsetWidth = elMap ? elMap.offsetWidth : 0;

      offsetHeight = elMap
        ? (elMap.offsetWidth * naturalHeight) / naturalWidth
        : 0;
    }

    let offsetLeft = elMap ? (elMap.offsetWidth - offsetWidth) / 2 : 0;

    let offsetTop = elMap ? (elMap.offsetHeight - offsetHeight) / 2 : 0;

    let positions = [];

    drawingsWithSpots[currentDrawingIndex].spots.map(pos => {
      positions.push({
        ...pos,
        posX: (pos.x * offsetWidth) / naturalWidth + offsetLeft,
        posY: (pos.y * offsetHeight) / naturalHeight + offsetTop
      });
    });

    setSpotPositionsDialog(positions);
  };

  const returnDefaultSize = () => {
    let currentRef = transformComponentRef.current;
    if (currentRef != null) {
      currentRef.context.dispatch.resetTransform();
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        >
          <button
            style={{
              backgroundColor: checkCompleteChange() ? "#8fc31f" : "#5A5A5A",
              borderRadius: "6px",
              fontSize: "20px",
              padding: "6px 20px",
              color: "#FFFFFF",
              margin: "12px 20px"
            }}
            onClick={() => {
              if (checkCompleteChange()) {
                setShowConfirmSaveChange(true);
              } else {
                setShowConfirmCancelChange(true);
              }
            }}
          >
            {checkCompleteChange() ? "登録を完了" : "登録をやめる"}
          </button>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span
            style={{
              fontSize: "32px",
              padding: "4px 12px",
              lineHeight: "32px",
              color: "#5A5A5A",
              cursor: currentDrawingIndex > 0 ? "pointer" : "default",
              opacity: currentDrawingIndex > 0 ? "1" : "0.3"
            }}
            onClick={previousDrawing}
          >
            <ArrowBackIcon fontSize="inherit" />
          </span>

          <span
            style={{ fontSize: "20px", color: "#5A5A5A", fontWeight: "500" }}
          >
            {drawingsWithSpots[currentDrawingIndex]?.name}
          </span>

          <span
            style={{
              fontSize: "32px",
              padding: "4px 12px",
              lineHeight: "32px",
              color: "#5A5A5A",
              cursor:
                currentDrawingIndex < drawingsWithSpots.length - 1
                  ? "pointer"
                  : "default",
              opacity:
                currentDrawingIndex < drawingsWithSpots.length - 1 ? "1" : "0.3"
            }}
            onClick={nextDrawing}
          >
            <ArrowForwardIcon fontSize="inherit" />
          </span>
        </div>
        <div
          id="drawing-box-wrapper"
          style={{ flexGrow: "1", border: "2px solid	#696969" }}
        >
          <TransformWrapper defaultScale={1}>
            <div className={classes.transformWrapper}>
              <TransformComponent ref={transformComponentRef}>
                <div className="img-mini-parent-sp">
                  <DrawingPhoto
                    id="drawing-box-image"
                    name={drawingsWithSpots[currentDrawingIndex]?.name}
                    drawingsUC={
                      drawingsWithSpots[currentDrawingIndex]?.uniqueCode
                    }
                    calculateSpotPositions={calculateSpotPositions}
                    listShootInfo={listShootInfo}
                    showShoot={showShoot}
                    positions={spotPositions}
                    class={classes.drawingPhoto}
                    assignCurrentShootToSpot={assignCurrentShootToSpot}
                  />
                </div>
              </TransformComponent>
              <div
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  fontSize: "36px",
                  color: "#5A5A5A",
                  width: "36px",
                  height: "36px",
                  lineHeight: "36px",
                  cursor: "pointer  "
                }}
                onClick={() => setShowDrawingDialog(true)}
              >
                <ZoomOutMapIcon fontSize="inherit" fontWeight="inherit" />
              </div>
            </div>
          </TransformWrapper>
        </div>
        <div
          style={{
            textAlign: "center",
            padding: "10px 20px",
            color: "red",
            fontSize: "20px"
          }}
        >
          写真を登録する地点を選択してください
        </div>
      </div>

      {showDrawingDialog && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={showDrawingDialog}
          onClose={() => setShowDrawingDialog(false)}
          aria-labelledby="max-width-dialog-title"
          PaperProps={{
            style: { height: "80vh", padding: "16px 8px 0px 8px" }
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span
                style={{
                  fontSize: "40px",
                  padding: "4px 6px",
                  lineHeight: "40px",
                  color: "#5A5A5A",
                  cursor: "pointer"
                }}
                onClick={() => setShowDrawingDialog(false)}
              >
                <CancelIcon fontSize="inherit" color="inherit" />
              </span>

              <span
                style={{
                  fontSize: "24px",
                  color: "#5A5A5A",
                  fontWeight: "500"
                }}
              >
                {drawingsWithSpots[currentDrawingIndex]?.name}
              </span>

              <div
                style={{
                  width: "40px",
                  height: "40px",
                  padding: "4px",
                  cursor: "pointer"
                }}
                onClick={() => setShowDrawingDialog(false)}
              >
                <img src={ZoomInMapImg} width={32} height={32} alt="" />
              </div>
            </div>

            <div
              style={{
                flexGrow: "1",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div
                style={{
                  fontSize: "32px",
                  width: "42px",
                  padding: "0px 5px",
                  color: "#5A5A5A",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <span
                  style={{
                    cursor: currentDrawingIndex > 0 ? "pointer" : "default",
                    opacity: currentDrawingIndex > 0 ? "1" : "0.3",
                    width: "32px",
                    height: "32px"
                  }}
                  onClick={previousDrawing}
                >
                  <ArrowBackIcon fontSize="inherit" />
                </span>
              </div>

              <div
                id="drawing-box-wrapper-dialog"
                style={{ flexGrow: "1", border: "2px solid	#696969" }}
              >
                <TransformWrapper defaultScale={1}>
                  <div className={classes.transformWrapper}>
                    <TransformComponent ref={transformComponentRef}>
                      <div className="img-mini-parent-sp">
                        <DrawingPhoto
                          id="drawing-box-image-dialog"
                          name={drawingsWithSpots[currentDrawingIndex]?.name}
                          drawingsUC={
                            drawingsWithSpots[currentDrawingIndex]?.uniqueCode
                          }
                          calculateSpotPositions={calculateSpotPositionsDialog}
                          listShootInfo={listShootInfo}
                          showShoot={showShoot}
                          positions={spotPositionsDialog}
                          class={classes.drawingPhoto}
                          assignCurrentShootToSpot={assignCurrentShootToSpot}
                        />
                      </div>
                    </TransformComponent>
                  </div>
                </TransformWrapper>
              </div>

              <div
                style={{
                  fontSize: "32px",
                  width: "42px",
                  padding: "0px 5px",
                  color: "#5A5A5A",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <span
                  style={{
                    cursor:
                      currentDrawingIndex < drawingsWithSpots.length - 1
                        ? "pointer"
                        : "default",
                    opacity:
                      currentDrawingIndex < drawingsWithSpots.length - 1
                        ? "1"
                        : "0.3",
                    width: "32px",
                    height: "32px"
                  }}
                  onClick={nextDrawing}
                >
                  <ArrowForwardIcon fontSize="inherit" />
                </span>
              </div>
            </div>

            <div
              style={{
                textAlign: "center",
                padding: "10px 20px",
                color: "red",
                fontSize: "20px"
              }}
            >
              写真を登録する地点を選んでください
            </div>
          </div>
        </Dialog>
      )}
    </React.Fragment>
  );
}

export default DrawingBox;
