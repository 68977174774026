import {
  FETCH_FEATURE_LIST_SUCCESSS,
  PUT_FEATURE_SUCCESS,
  GET_FEATURE_DETAIL_SUCCESS
} from "../constant/constant";

export const fetchFeatureListSuccess = features => {
  return {
    type: FETCH_FEATURE_LIST_SUCCESSS,
    features: features
  };
};

export const putFeatureSuccess = data => {
  return {
    type: PUT_FEATURE_SUCCESS,
    data
  };
};

export const getFeatureDetailSuccess = featureDetail => {
  return {
    type: GET_FEATURE_DETAIL_SUCCESS,
    featureDetail
  };
};
