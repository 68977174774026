import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

function LicencePage() {
  return (
    <Grid container justify="center">
      <div style={{ width: "300px", paddingTop: "200px" }}>
        <Button
          variant="contained"
          style={{
            width: "100%",
            backgroundColor: "#E8E8E8",
            fontSize: "16px",
            marginBottom: "30px"
          }}
          onClick={() => {
            window.open("https://license.log-system.jp/", "_blank");
          }}
        >
          ライセンス情報
        </Button>
        {/* <Button variant="contained" style={{width: "100%", backgroundColor: "#E8E8E8", fontSize: "16px"}}>利用規約</Button> */}
      </div>
    </Grid>
  );
}

export default LicencePage;
