import React, { Component } from "react";
import { Button, Grid, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import ChooseInitialColor from "./HotspotInfoDrawer/ChooseInitialColor";
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/ja";

import downIcon from "../../../../common/img/DirectionIcons/down_PC.svg";
import upIcon from "../../../../common/img/DirectionIcons/up_PC.svg";
import leftIcon from "../../../../common/img/DirectionIcons/left_PC.svg";
import rightIcon from "../../../../common/img/DirectionIcons/right_PC.svg";
import zoomInIcon from "../../../../common/img/DirectionIcons/zoomin_PC.svg";
import zoomOutIcon from "../../../../common/img/DirectionIcons/zoomout_PC.svg";
import hideControlIcon from "../../../../common/img/DirectionIcons/hidecontrol_PC.svg";
import showControlIcon from "../../../../common/img/DirectionIcons/showcontrol_PC.svg";
import IconLink from "../../../../common/img/link-dark.png";
import takeScreenshotIcon from "../../../../common/img/take-screenshot.png";
import showBottomOptionsIcon from "../../../../common/img/show-bottom-options.png";
import shareUrlIcon from "../../../../common/img/share-url.png"
import Drawer from "@material-ui/core/Drawer";
import CloseButton from "../Maps/CloseButton";
import shareUrlActiveIcon from "../../../../common/img/share-url-active.png"

class ViewerInfoAreaSP extends Component {
  constructor() {
    super();
    this.state = {
      isShowDatePicker: false,
      isShowTimePicker: false,
      selectedDays: [],
      histories: [],
      historiesAllSpot: [],
      disabledDays: [],
      modifiers: [],
      times: [],
      pickedDay: "",
      currentHistory: null,
      isDisabled: false,
      currentIdx: 0,
      bottomOptionsHeight: 0,
      showBottomOptions: false
    };
  }

  componentDidMount() {
    this.renewHistories();
    this.setState({ isDisabled: false });
  }

  componentWillReceiveProps(nextProps) {
    this.renewHistories();
    if (nextProps.isLoadDone) {
      this.setState({ isDisabled: false });
    }
  }

  sortObjectListDescByCreatedAt = objList => {
    return objList.sort((a, b) => {
      const key1 = a.dateUtc;
      const key2 = b.dateUtc;

      if (key1 > key2) {
        return -1;
      } else if (key1 === key2) {
        // Sort difference on Chrome and Firefox
        return navigator.userAgent.toLowerCase().indexOf("firefox") > -1
          ? 1
          : -1;
      } else {
        return 1;
      }
    });
  };

  renewHistories = () => {
    let histories = [];
    let historiesAllSpot = [];
    this.props.listShootsSpot.map(item => {
      let data = {
        uniqueCode: item.uniqueCode,
        day: item.createdAt.substr(0, 10),
        time: item.createdAt.substr(15, 5),
        dateUtc: item.dateUtc
      };
      historiesAllSpot.push(data);
      if (this.props.checkHasShootPhoto(item.uniqueCode)) {
        histories.push(data);
      }
    });

    this.setState(
      {
        historiesAllSpot: this.sortObjectListDescByCreatedAt(historiesAllSpot),
        histories: this.sortObjectListDescByCreatedAt(histories)
      },
      () => {
        this.setDisabledDays();
        this.setSelectedDays();
        this.getCurrentHistory();
      }
    );
  };

  setSelectedDays = () => {
    let selectedDays = [];
    this.state.histories.map(item => selectedDays.push(new Date(item.day)));
    this.setState({ selectedDays });
  };

  setDisabledDays = () => {
    const { historiesAllSpot, histories } = this.state;

    if (historiesAllSpot && historiesAllSpot.length) {
      let disabledDays = [],
        modifiers = [];

      disabledDays.push({
        after: new Date(historiesAllSpot[0].day),
        before: new Date(historiesAllSpot[historiesAllSpot.length - 1].day)
      });

      if (historiesAllSpot.length > 1) {
        for (let i = 1; i < historiesAllSpot.length; i++) {
          if (historiesAllSpot[i].day !== historiesAllSpot[i - 1].day) {
            disabledDays.push({
              after: new Date(historiesAllSpot[i].day),
              before: new Date(historiesAllSpot[i - 1].day)
            });
          }
        }
      } else {
        disabledDays.push({
          after: new Date(historiesAllSpot[0].day),
          before: new Date(historiesAllSpot[0].day)
        });
      }

      histories.forEach(e => {
        let date = new Date(e.day);
        if (!modifiers.includes(date)) {
          modifiers.push(date);
        }
      });

      this.setState({ disabledDays, modifiers });
    }
  };

  toggleDatePicker = isShow => {
    if (this.state.isShowTimePicker) {
      this.toggleTimePicker();
    }
    this.setState({ isShowDatePicker: isShow });
  };

  toggleTimePicker = () => {
    this.setState({ isShowTimePicker: !this.state.isShowTimePicker });
  };

  onChangeDateHistory = days => {
    const itemChange = this.state.selectedDays.find(
      (
        i => a =>
          a !== days[i] || !++i
      )(0)
    );

    if (itemChange) {
      this.toggleDatePicker();
      this.toggleTimePicker();
    }
  };

  handleDayClick = (day, { selected }) => {
    const { historiesAllSpot, histories, currentHistory } = this.state;
    const historyDay = historiesAllSpot.filter(history =>
      DateUtils.isSameDay(new Date(history.day), day)
    );

    if (historyDay && historyDay.length) {
      const times = historyDay.map(obj => ({
        ...obj,
        hasShoot: histories.find(e => e.uniqueCode === obj.uniqueCode)
          ? true
          : false,
        isCurrentHistory: currentHistory
          ? obj.uniqueCode === currentHistory.uniqueCode
          : false
      }));
      this.setState(
        {
          pickedDay: historyDay[0].day,
          times
        },
        () => {
          this.toggleDatePicker();
          if (times.length > 1) {
            this.toggleTimePicker();
          } else {
            this.handleTimeClick(0);
          }
        }
      );
    }
  };

  handleTimeClick = idx => {
    const currentIdx =
      this.state.historiesAllSpot.findIndex(
        item =>
          item.day === format(new Date(this.state.pickedDay), "yyyy-MM-dd")
      ) + idx;

    const history = this.state.historiesAllSpot[currentIdx];

    this.setState({ isDisabled: true, currentIdx });
    this.props.handleChangeHistory(null, history.uniqueCode);

    if (this.state.isShowTimePicker) {
      this.toggleTimePicker();
    }
  };

  handleChangeHistoryButton = isNext => {
    const { currentHistory, histories, historiesAllSpot } = this.state;

    const historyData = histories.length ? histories : historiesAllSpot;
    let currentIdx = historyData.findIndex(
      item => item.uniqueCode === currentHistory.uniqueCode
    );

    let newIdx = 0;

    if (currentIdx === -1) {
      currentIdx = historyData.findIndex(
        item => item.dateUtc < currentHistory.dateUtc
      );

      if (isNext) {
        switch (currentIdx) {
          case -1:
            newIdx = historyData.length - 1;
            break;
          case 0:
            newIdx = 0;
            break;

          default:
            newIdx = currentIdx - 1;
            break;
        }
      } else {
        switch (currentIdx) {
          case -1:
            newIdx = 0;
            break;
          default:
            newIdx = currentIdx;
            break;
        }
      }
    } else {
      newIdx = isNext ? currentIdx - 1 : currentIdx + 1;
    }

    const history = historyData[newIdx];

    if (
      history &&
      history.uniqueCode !== this.state.currentHistory.uniqueCode
    ) {
      this.setState({ isDisabled: true, currentIdx: newIdx });
      this.props.handleChangeHistory(null, history.uniqueCode);
    }
  };

  getCurrentHistory = () => {
    const { histories, historiesAllSpot } = this.state;

    const historyData = this.state.histories.length
      ? histories
      : historiesAllSpot;

    const currentHistory = this.props.listShootsSpot.find(
      item => item.uniqueCode === this.props.logWalkInfo.recordsUC
    );

    let currentIdx = historyData.findIndex(
      item => item.uniqueCode === this.props.logWalkInfo.recordsUC
    );

    if (currentIdx === -1 && currentHistory) {
      currentIdx = historyData.findIndex(
        item =>
          Date.parse(item.day.replace(/-/g, "/") + " " + item.time) <=
          Date.parse(
            currentHistory.createdAt.substr(0, 10).replace(/-/g, "/") +
            " " +
            currentHistory.createdAt.substr(15, 5)
          )
      );

      if (currentIdx === -1) {
        currentIdx = historyData.length - 1;
      }
    }

    this.setState({ currentHistory, currentIdx });
  };

  formatMonthTitle(d, locale = "jp") {
    return `${d.getFullYear()}年${d.getMonth() + 1}月`;
  }

  toggleShowBottomOptions() {
    this.setState(prevState => ({
      showBottomOptions: !prevState.showBottomOptions
    }));
  }

  hideBottomOptions() {
    this.setState({
      showBottomOptions: false
    });
  }

  render() {
    const {
      imageShootUnAdjusted,
      logWalkInfo,
      listShootsSpot,
      blobDataLogWalk,
      showHistory,
      newHostpotInfoColor,
      isShowDirectionDrawer,
      onShowDirectionDrawer,
      onHideDirectionDrawer,
      showWalkThrough,
      isShowLink,
      toggleShowLink,
      isShowUnfinishedHotspotInfo,
      isShowAccomplishedHotspotInfo,
      toggleShowUnfinishedHotspotInfo,
      toggleShowAccomplishedHotspotInfo,
      showModeEditLink,
      toggleShowModeEditLink,
      logwalkViewerHasLinkIcon,
      openMenuScreenshotVRSpace,
      displayNone,
      showShareUrlDialog
    } = this.props;

    const {
      isShowDatePicker,
      isShowTimePicker,
      histories,
      historiesAllSpot,
      disabledDays,
      modifiers,
      times,
      currentHistory,
      currentIdx,
      showBottomOptions
    } = this.state;

    const historyData = logWalkInfo.spotsUC ? histories : historiesAllSpot;

    return (
      <>
        <div
          className="bottom-area"
          style={{
            bottom: isShowDirectionDrawer && blobDataLogWalk ? "12px" : "-45px",
            touchAction: "manipulation",
            display: displayNone ? "none" : "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
          onClick={e => e.preventDefault()}
        >
          <div style={{ display: "flex", marginBottom: "14px" }}>
            {blobDataLogWalk &&
              !imageShootUnAdjusted.shootCode &&
              showHistory && (
                <ChooseInitialColor
                  handleChooseColor={color =>
                    this.props.chooseNewHotspotInfoColor(color)
                  }
                  newHostpotInfoColor={newHostpotInfoColor}
                />
              )}

            {showHistory && !imageShootUnAdjusted.shootCode && (
              <Grid className="history-control no-select" id="show-date-picker">
                <IconButton
                  size="small"
                  disabled={
                    !historyData ||
                    !historyData.length ||
                    !currentHistory ||
                    (currentHistory.dateUtc <=
                      historyData[historyData.length - 1].dateUtc &&
                      currentIdx >= historyData.length - 1)
                  }
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.handleChangeHistoryButton(false);
                  }}
                  className="no-zoom"
                >
                  <ArrowLeftIcon fontSize="small" />
                </IconButton>
                <Typography
                  variant="subtitle1"
                  className="label current-history"
                  onClick={() => this.toggleDatePicker(!isShowDatePicker)}
                >
                  {currentHistory?.dateUtc
                    ? format(
                      new Date(currentHistory.dateUtc),
                      "yyyy-MM-dd eee HH:mm",
                      { locale: ja }
                    )
                    : "全地点を最新の日付にする"}
                </Typography>
                <IconButton
                  size="small"
                  disabled={
                    !historyData ||
                    !historyData.length ||
                    !currentHistory ||
                    (currentHistory.dateUtc >= historyData[0].dateUtc &&
                      currentIdx <= 0)
                  }
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.handleChangeHistoryButton(true);
                  }}
                  className="no-zoom"
                >
                  <ArrowRightIcon fontSize="small" />
                </IconButton>
              </Grid>
            )}

            {blobDataLogWalk && !imageShootUnAdjusted.shootCode && (
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  right: "-38px",
                  width: "32px",
                  padding: "0px",
                  cursor: "pointer",
                  borderRadius: "9999px",
                  backgroundColor: "#c7c7c7",
                  overflow: "hidden"
                }}
              >
                <img
                  src={showBottomOptionsIcon}
                  width="100%"
                  alt="show"
                  onClick={() => this.toggleShowBottomOptions()}
                />
              </div>
            )}

          </div>

          {logWalkInfo.tagName &&
            !!blobDataLogWalk &&
            !imageShootUnAdjusted.shootCode && (
              <div className="photographer-title header-item-viewer">
                <Typography variant="h6" className="text-center">
                  {logWalkInfo.tagName}
                </Typography>
              </div>
            )}
          {imageShootUnAdjusted.shootCode && imageShootUnAdjusted.tagName && (
            <div className="photographer-title header-item-viewer0">
              <Typography variant="h6" className="text-center">
                {imageShootUnAdjusted.tagName}
              </Typography>
            </div>
          )}

          <Grid
            container
            style={{
              width: "100%",
              minWidth: "270px",
              position: "relative",
              left:'39px',
              backgroundColor: "rgb(255, 255, 255, 0.5)",
              borderRadius: "9999px",
              visibility: blobDataLogWalk ? "visible" : "hidden",
              // opacity: isShowDirectionDrawer ? 1 : 0,
              // transition: "opacity 0.25s ease 0s"
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "-46px",
                width: "16.66%",
                padding: "3px",
                cursor: "pointer"
              }}
            >
              <img
                src={hideControlIcon}
                width="100%"
                alt="hide"
                onClick={onHideDirectionDrawer}
              />
            </div>

            <Grid item xs={2} style={{ padding: "3px", cursor: "pointer" }}>
              <img src={upIcon} width="100%" alt="up" id="viewUp" />
            </Grid>
            <Grid item xs={2} style={{ padding: "3px", cursor: "pointer" }}>
              <img src={downIcon} width="100%" alt="down" id="viewDown" />
            </Grid>
            <Grid item xs={2} style={{ padding: "3px", cursor: "pointer" }}>
              <img src={leftIcon} width="100%" alt="left" id="viewLeft" />
            </Grid>
            <Grid item xs={2} style={{ padding: "3px", cursor: "pointer" }}>
              <img src={rightIcon} width="100%" alt="right" id="viewRight" />
            </Grid>
            <Grid item xs={2} style={{ padding: "3px", cursor: "pointer" }}>
              <img src={zoomInIcon} width="100%" alt="zoom in" id="viewIn" />
            </Grid>
            <Grid item xs={2} style={{ padding: "3px", cursor: "pointer" }}>
              <img
                src={zoomOutIcon}
                width="100%"
                alt="zoom out"
                id="viewOut"
              />
            </Grid>
          </Grid>
        </div>

        {blobDataLogWalk && (
          <Button
            className="show-direction-block-btn no-zoom"
            style={{
              opacity: isShowDirectionDrawer ? 0 : 1,
              transition: !isShowDirectionDrawer ? "opacity 0.5s linear" : "",
              zIndex: "10",
              display: displayNone ? "none" : "flex",
            }}
            onClick={e => {
              onShowDirectionDrawer();
              e.preventDefault();
            }}
          >
            <img src={showControlIcon} alt="show control" width="80px" />
          </Button>
        )}

        {isShowDatePicker && (
          <Grid
            className={`container-history-calendar`}
            onClick={() => this.toggleDatePicker()}
          >
            <Grid
              className={`history-picker`}
              onClick={e => e.stopPropagation()}
              style={{
                bottom: isShowDirectionDrawer ? "114px" : "60px"
              }}
            >
              <Grid className="text-center mt-10">
                <Button
                  size="small"
                  className="button-history-newest"
                  onClick={() => {
                    this.props.handleChangeHistory();
                    this.toggleDatePicker();
                  }}
                >
                  全地点を最新の日付にする
                </Button>
              </Grid>
              <DayPicker
                month={
                  new Date(
                    currentHistory
                      ? new Date(currentHistory.createdAt.substr(0, 10))
                      : listShootsSpot && listShootsSpot.length
                        ? new Date(listShootsSpot[0].createdAt.substr(0, 10))
                        : new Date()
                  )
                }
                fixedWeeks
                selectedDays={
                  currentHistory
                    ? [new Date(currentHistory.createdAt.substr(0, 10))]
                    : []
                }
                disabledDays={
                  disabledDays && disabledDays.length
                    ? disabledDays
                    : { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] }
                }
                modifiers={{ highlighted: modifiers }}
                onDayClick={this.handleDayClick}
                localeUtils={{
                  ...MomentLocaleUtils,
                  formatMonthTitle: this.formatMonthTitle
                }}
                locale={"ja"}
              />
            </Grid>
          </Grid>
        )}

        {isShowTimePicker && (
          <Grid
            className={`container-history-calendar`}
            onClick={() => this.toggleDatePicker()}
          >
            <Grid className={`time-picker`} onClick={e => e.stopPropagation()}>
              <Grid className={`time-items`}>
                {times.map((item, idx) => {
                  return (
                    <Typography
                      variant="subtitle1"
                      className="label time-item no-select"
                      style={{
                        backgroundColor: item.isCurrentHistory
                          ? "rgb(169, 46, 63)"
                          : item.hasShoot
                            ? "rgba(22, 47, 80, 1)"
                            : "",
                        color: item.hasShoot ? "#e5e5e5d9" : ""
                      }}
                      onClick={() => this.handleTimeClick(idx)}
                    >
                      {item.time}
                    </Typography>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        )}

        {
          !displayNone && (
            <Drawer
              anchor="bottom"
              className="custom-Drawer"
              open={showBottomOptions}
              onClose={() => this.hideBottomOptions()}
            >
              <CloseButton handleClose={() => this.hideBottomOptions()} />
              {blobDataLogWalk && !imageShootUnAdjusted.shootCode && (
                <div
                  className="walk-through-options-lw"
                  style={{
                    margin: "0"
                  }}
                >
                  <div style={{
                    margin: "auto",
                    padding: "36px 16px 20px 16px",
                    maxWidth: "460px"
                  }}>
                    <div
                      className="walk-through-option-item"
                      style={{
                        backgroundColor: showModeEditLink ? "#b0b0b0" : ""
                      }}
                      onClick={() => {
                        openMenuScreenshotVRSpace();
                        this.setState({showBottomOptions: false});
                      }}
                    >
                      <span>静止画の保存・共有</span>
                      <div className="walk-through-edit-link-icon">
                        <span>
                          <img src={takeScreenshotIcon} alt="" />
                        </span>
                      </div>
                    </div>
                    <hr />
                    {
                      this.props.classification !== "supplier" && (
                        <React.Fragment>
                          <div
                            className="walk-through-option-item"
                            onClick={() => {
                              showShareUrlDialog();
                              this.setState({showBottomOptions: false});
                            }}
                          >
                            <span>{!this.props.isSharedUrl ? "URLを共有する" : "URLを共有中"}</span>
                            <div className="walk-through-edit-link-icon">
                              <span style={{padding: "5px"}}>
                                <img src={!this.props.isSharedUrl ? shareUrlIcon : shareUrlActiveIcon} alt="" style={{padding: "5px"}} />
                              </span>
                            </div>
                          </div>
                          <hr />                          
                        </React.Fragment>
                      )
                    }

                    <div
                      className="walk-through-option-item"
                      onClick={() => {
                        toggleShowUnfinishedHotspotInfo();
                      }}
                    >
                      <span>未完了インフォタグ</span>
                      <button
                        class={`toggle-show-hotspot-info ${isShowUnfinishedHotspotInfo ? "show-hotspot-info" : ""
                          }`}
                      ></button>
                    </div>
                    <hr />
                    <div
                      className="walk-through-option-item"
                      onClick={() => toggleShowAccomplishedHotspotInfo()}
                    >
                      <span>完了インフォタグ</span>
                      <button
                        class={`toggle-show-hotspot-info ${isShowAccomplishedHotspotInfo ? "show-hotspot-info" : ""
                          }`}
                      ></button>
                    </div>
                    {showWalkThrough && logwalkViewerHasLinkIcon() && (
                      <>
                        <hr />
                        <div
                          className="walk-through-option-item"
                          onClick={() => toggleShowLink()}
                        >
                          <span>リンクアイコン</span>
                          <button
                            className={`toggle-show-hotspot-info ${isShowLink ? "show-hotspot-info" : ""
                              }`}
                          ></button>
                        </div>
                        <hr />
                        <div
                          style={{
                            backgroundColor: showModeEditLink ? "#b0b0b0" : ""
                          }}
                          className="walk-through-option-item"
                          onClick={() => toggleShowModeEditLink()}
                        >
                          <span>リンク表示位置の編集</span>
                          <div className="walk-through-edit-link-icon">
                            <span>
                              <img src={IconLink} alt="" />
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                </div>
              )}
            </Drawer>            
          )
        }

      </>
    );
  }
}

export default ViewerInfoAreaSP;
