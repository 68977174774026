import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import mentionsStyles from  "./MentionsInputData.css";
import MentionManager from "./MentionManager";

const useStyles = makeStyles({});

const mentineManager = new MentionManager();


function MentionsInputData(props) {
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [popoverWidth, setPopoverWidth] = useState("0");
  const selectMentionsRef = useRef(null);

  const { allMentions, addedMentions, setMentions } = props;
  const classes = useStyles();
  const [textMessage, setTextMessage] = useState('');
  const [dialoueEnable, setDialoueTextMessage] = useState(false);
  const [stateSelect, setStateSelect] = useState({userSelect:[],selected:0});

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setPopoverWidth(window.getComputedStyle(selectMentionsRef.current).width);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const checkIsAdded = mention => {
    return (
      addedMentions.findIndex(item => item.uniqueCode === mention.uniqueCode) >
      -1
    );
  };


  const callUserTag =(mention)=> {
   var mentionValue=mentineManager.callUserTagTarget(mention,document.getElementById('smapleValue'));
   if(mentionValue.is_enable){
    setDialoueTextMessage(false);
   }
  };


  const checkFormDataView=(value)=>{
    //console.log("checkFormDataView ",value);
    var checMeintion =mentineManager.checkFormDataView(value,document.getElementById('smapleValue'),allMentions);
    if(checMeintion.is_enable){
      setStateSelect((prev) => ({ ...prev, userSelect: checMeintion.data}));
    }
    setDialoueTextMessage(checMeintion.is_enable);
  }


  const eventPasteText=(e)=>{
    mentineManager.eventPasteText(e);
    mentineManager.checkDataByEventUnexpectedBefore(document.getElementById('smapleValue'));
  }

  const eventCutText=(e)=>{
    mentineManager.eventCutText(e);
    mentineManager.checkDataByEventUnexpectedBefore(document.getElementById('smapleValue'));
  }

  function getSelectionTextInfo(el) {
    var atStart = false, atEnd = false;
    var selRange, testRange;
    if (window.getSelection) {
        var sel = window.getSelection();
        if (sel.rangeCount) {
            selRange = sel.getRangeAt(0);
            testRange = selRange.cloneRange();

            testRange.selectNodeContents(el);
            testRange.setEnd(selRange.startContainer, selRange.startOffset);
            atStart = (testRange.toString() == "");

            testRange.selectNodeContents(el);
            testRange.setStart(selRange.endContainer, selRange.endOffset);
            atEnd = (testRange.toString() == "");
        }
    } 
    else if (document.selection && document.selection.type != "Control") {
        selRange = document.selection.createRange();
        testRange = selRange.duplicate();
        
        testRange.moveToElementText(el);
        testRange.setEndPoint("EndToStart", selRange);
        atStart = (testRange.text == "");

        testRange.moveToElementText(el);
        testRange.setEndPoint("StartToEnd", selRange);
        atEnd = (testRange.text == "");
    }
    return { atStart: atStart, atEnd: atEnd };
  }
  
  
  function checkKey(e) {
    getSelectionTextInfo(document.getElementById('smapleValue'));
    e = e || window.event;
    if (e.keyCode == '38') {
        // up arrow
        if(stateSelect.selected<(stateSelect.userSelect.length-1)) 
          setStateSelect((prev) => ({ ...prev, selected: stateSelect.selected+1 }));
        else 
          setStateSelect((prev) => ({ ...prev, selected: 0 }));
        e.preventDefault();
    }
    else if (e.keyCode == '40') {
        // down arrow
        if(stateSelect.selected==0) 
          setStateSelect((prev) => ({ ...prev, selected: stateSelect.userSelect.length-1 }));
        else 
          setStateSelect((prev) => ({ ...prev, selected: stateSelect.selected-1 }));
        e.preventDefault();
    }
    else if ((e.keyCode == '37')||(e.keyCode == '39')){
      // left  right
      setTimeout(() => {
        var checMeintion =mentineManager.checkFormDataMeintionChange(document.getElementById('smapleValue'),allMentions);
        if(checMeintion.is_enable){
          setStateSelect((prev) => ({ ...prev, userSelect: checMeintion.data}));
        }
        setDialoueTextMessage(checMeintion.is_enable);
      }, 50);
      
    }
    else if (e.keyCode == '13') {
      
        callUserTag(stateSelect.userSelect[stateSelect.selected]);
       // mentineManager.selectLastMention();
        e.preventDefault(); //Prevent default browser behavior
     }

  }


  

  return (
    <React.Fragment>
      <div
        onClick={handleClick}
        ref={selectMentionsRef}
        className={classes.root}
        style={{ paddingBottom: "10px" }}
      >
        <div className={'mentions-box'}>
          {addedMentions.length === 0 ? (
            <span style={{ fontSize: "17px", lineHeight: "20px", color: "#D1D1D1" }}>
              テキストを入力
            </span>
          ) : (
            addedMentions.map(mention => (
              <span
                key={mention.uniqueCode}
                style={{
                  display: "inline-block",
                  padding: "0xp 6px",
                  fontSize: "15px",
                  lineHeight: "20px",
                  color: "#00356D",
                  marginRight: "14px",
                  fontWeight: "bold",
                  whiteSpace: "nowrap"
                }}
              >
                {mention.name}
              </span>
            ))
          )}
        </div>
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            width: popoverWidth,
            backgroundColor: addedMentions.length === 0 ? "white" : "#00356D",
            borderRadius: "4px"
          }
        }}
        transitionDuration={0.1}
      >
        <div className={'search-box'}>
          <div
            className={'search-box-input'}
            style={addedMentions.length === 0 ? { backgroundColor: "white" } : null}
          >
            <span>
              <SearchIcon fontSize="inherit" />
            </span>
                <div contentEditable="true"
                    id={"smapleValue"}
                    textContent={textMessage}
                    onInput={ (e) => { 
                        checkFormDataView(e.target.innerHTML); 
                        //setTextMessage(e.target.innerHTML); 
                        } } 
                    className="chat-box-input"
                    onKeyDown={checkKey}
                    onPaste={eventPasteText}
                    onCut={eventCutText}
                  >
                  </div>
          </div>


          {addedMentions.length > 0 ? (
            <button onClick={handleClose} style={{ color: "white" }}>
              OK
            </button>
          ) : (
            <button
              onClick={handleClose}
              style={{ color: "#333333", backgroundColor: "white" }}
            >
              <CloseIcon fontSize="40" />
            </button>
          )}
        </div>

            {
              dialoueEnable?
              <div className={'filter-mention-box'}>
              <div>
                {stateSelect.userSelect.length === 0 ? (
                  <p style={{ textAlign: "center" }}>
                    該当するメンバーは登録されておりません。
                  </p>
                ) : (
                  <ul>
                    {stateSelect.userSelect.map((mention, index) => (
                      <li
                        key={mention.uniqueCode}
                        className={'mention-item '+  (stateSelect.selected==index?"meintion-user-select":"meintion-user-non-select")  }
                        onClick={() => callUserTag(mention)}
                        style={
                          index !== 0 ? { borderTop: "1px solid #A8A8A8" } : null
                        }
                      >
                        
                        <span>{mention.name}</span>
                        <span
                          style={{
                            display: "block",
                            width: "30px",
                            marginLeft: "auto"
                          }}
                        >
                          {checkIsAdded(mention) ? (
                            <DoneIcon color="primary" />
                          ) : null}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
              :""
            }

        


      </Popover>
    </React.Fragment>
  );
}

MentionsInputData.propTypes = {
  allMentions: PropTypes.array,
  addedMentions: PropTypes.array,
  addMention: PropTypes.func,
  deleteMention: PropTypes.func
};

export default MentionsInputData;
