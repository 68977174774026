import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";

import { CometChatMessageActions, CometChatThreadedMessageReplyCount, CometChatReadReceipt } from "../";
import { CometChatMessageReactions } from "../Extensions";

import { checkMessageForExtensionsData } from "../../../util/common";

import {
	messageContainerStyle,
	messageWrapperStyle,
	messageVideoWrapperStyle,
	messageInfoWrapperStyle,
	messageReactionsWrapperStyle
} from "./style";
import { CometChatContext } from "../../../util/CometChatContext"

class CometChatSenderVideoMessageBubble extends React.Component {
	static contextType = CometChatContext;
	loggedInUser;

	constructor(props, context) {
		super(props, context);

		this.state = {
			isHovering: false,
		};

		this.context.getLoggedinUser().then(user => {
			this.loggedInUser = { ...user };
		});
	}

	shouldComponentUpdate(nextProps, nextState) {

		const currentMessageStr = JSON.stringify(this.props.message);
		const nextMessageStr = JSON.stringify(nextProps.message);

		if (currentMessageStr !== nextMessageStr
			|| this.state.isHovering !== nextState.isHovering) {
			return true;
		}
		return false;
	}

	handleMouseHover = (isHovering) => {
		this.setState({ isHovering });
	};

	render() {
		let messageReactions = null;
		const reactionsData = checkMessageForExtensionsData(this.props.message, "reactions");
		if (reactionsData) {
			if (Object.keys(reactionsData).length) {
				messageReactions = (
					<div css={messageReactionsWrapperStyle()} className="message__reaction__wrapper">
						<CometChatMessageReactions message={this.props.message} actionGenerated={this.props.actionGenerated} />
					</div>
				);
			}
		}

		let toolTipView = null;
		if (this.state.isHovering) {
			toolTipView = <CometChatMessageActions message={this.props.message} actionGenerated={this.props.actionGenerated} />;
		}

		return (
			<div css={messageContainerStyle()} className="sender__message__container message__video" onMouseEnter={() => this.handleMouseHover(true)} onMouseLeave={() => this.handleMouseHover(false)}
				style={{ marginBottom: "10px", maxWidth: "100%" }}>
				{toolTipView}

				<div style={{ display: "flex", alignItems: "flex-end", alignSelf: "flex-end" }}>
					<div css={messageInfoWrapperStyle()} className="message__info__wrapper">
						<CometChatThreadedMessageReplyCount message={this.props.message} actionGenerated={this.props.actionGenerated} />
						<CometChatReadReceipt message={this.props.message} />
					</div>

					<div css={messageWrapperStyle()} className="message__wrapper">
						<div css={messageVideoWrapperStyle()} className="message__video__wrapper">
							<video controls src={this.props.message.data.url}
								onSuspend={this.props.suspendCallback}
								onLoadedData={this.props.loadedCallback}
								onError={this.props.errorCallback}
								style={{ boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)" }}>
							</video>
						</div>
					</div>
				</div>

				{this.props.message?.sender?.uid === this.loggedInUser?.uid &&
					this.props.message.hasOwnProperty("error") &&
					<div style={{ alignSelf: "flex-end", paddingRight: "2px", fontSize: "10px", marginTop: "1px" }}>送信失敗</div>}

				{messageReactions}
			</div>
		);
	}
}

// Specifies the default values for props:
CometChatSenderVideoMessageBubble.defaultProps = {
	actionGenerated: {},
};

CometChatSenderVideoMessageBubble.propTypes = {
	actionGenerated: PropTypes.func.isRequired,
	message: PropTypes.object.isRequired,
};


export { CometChatSenderVideoMessageBubble };