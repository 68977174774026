import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import InputControl from "../../../common/InputControl";
import SelectControl from "../../../common/SelectControl";
import { Formik, Field } from "formik";
import Component from "../../Component";
import { edit, post, put, remove } from "../../../actions/siteActions";
import { fetchStores } from "../../../common/Stores/service/service";
import { getStores } from "../../../common/Stores/reducer/reducer";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";

class Form extends Component {
  action = null;
  uniqueCode = null;
  fields = ["Name", "RegisionDate", "ZipCode", "Address", "StoresUC"];

  constructor(props) {
    super(props);
    const model = {};
    this.fields.forEach(key => (model[key] = ""));
    model.RegisionDate = null;
    this.state = {
      stores: [],
      valueFake: {
        Name: "",
        RegisionDate: "",
        ZipCode: "",
        Address: "",
        StoresUC: -1
      },
      model: {
        Name: "",
        RegisionDate: "",
        ZipCode: "",
        Address: "",
        StoresUC: -1
      },
      errors: {},
      closeDialog: () => {}
    };
  }
  componentDidMount() {
    this.props.fetchStores();
  }
  componentWillReceiveProps(props) {
    if (props.sitesDetail) {
      this.setState({
        valueFake: props.sitesDetail
      });
    }
  }

  onSubmitForm(values) {
    let newValuesForm = { ...values };
    if (newValuesForm.StoresUC === -1) {
      newValuesForm.StoresUC = "";
    }
    this.props.onSubmit(newValuesForm);
  }

  render() {
    const handleDateChange = formik => date => {
      formik.setFieldValue(`RegisionDate`, date);
    };

    const { onClose, checkError, isEdit } = this.props;
    return (
      <Formik
        initialValues={this.state.valueFake}
        enableReinitialize
        onSubmit={values => this.onSubmitForm(values)}
        render={formik => (
          <Dialog
            open={true}
            aria-labelledby="max-width-dialog-title"
            fullWidth
            onClose={() => {}}
            maxWidth="md"
            PaperProps={{
              className: "dialog dialog-sign-up"
            }}
          >
            <Fab
              onClick={onClose}
              size="small"
              className="white-text close-button"
            >
              <CloseIcon />
            </Fab>

            <DialogTitle disableTypography>
              <Typography variant="h4" className="dialog-title">
                現場{isEdit ? "編集" : "登録"}
              </Typography>
            </DialogTitle>
            <DialogContent className="content-wrap">
              <Field
                label={
                  <Typography variant="subtitle1" className="label">
                    現場名
                    <span className="required">[必須]</span>
                  </Typography>
                }
                name="Name"
                onChange={formik.handleChange}
                variant="filled"
                className="input-reset"
                component={InputControl}
              />
              {checkError && checkError.Name ? (
                <span className="error">{checkError.Name}</span>
              ) : (
                <></>
              )}
              <Field
                name="RegisionDate"
                variant="filled"
                onChange={formik.handleChange}
                render={({ field, form }) => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      inputVariant="filled"
                      className="input-reset date-picker"
                      format="yyyy年MM月dd日"
                      views={["year", "month", "date"]}
                      value={field.value}
                      onChange={handleDateChange(formik)}
                      invalidDateMessage
                      label={
                        <Typography variant="subtitle1" className="label">
                          登録日
                          <span className="required">[必須]</span>
                        </Typography>
                      }
                      error={false}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
              {checkError && checkError.RegisionDate ? (
                <span className="error">{checkError.RegisionDate}</span>
              ) : (
                <></>
              )}
              <Field
                label={
                  <Typography variant="subtitle1" className="label">
                    現場郵便番号
                    <span className="required">[必須]</span>
                  </Typography>
                }
                name="ZipCode"
                onChange={formik.handleChange}
                variant="filled"
                className="input-reset"
                component={InputControl}
              />
              {checkError && checkError.ZipCode ? (
                <span className="error">{checkError.ZipCode}</span>
              ) : (
                <></>
              )}
              <Field
                label={
                  <Typography variant="subtitle1" className="label">
                    現場住所
                    <span className="required">[必須]</span>
                  </Typography>
                }
                name="Address"
                onChange={formik.handleChange}
                variant="filled"
                className="input-reset"
                component={InputControl}
              />
              {checkError && checkError.Address ? (
                <span className="error">{checkError.Address}</span>
              ) : (
                <></>
              )}
              <Field
                title={
                  <Typography variant="subtitle1" className="label">
                    所属店舗
                    <span className="required">[必須]</span>
                  </Typography>
                }
                name="StoresUC"
                onChange={formik.handleChange}
                variant="filled"
                className="input-reset reset-select"
                component={SelectControl}
              >
                <MenuItem className="select-item" value={-1}>
                  選択して下さい
                </MenuItem>
                {this.props.store.map(store => (
                  <MenuItem
                    className="select-item"
                    value={store.uniqueCode}
                    key={store.uniqueCode}
                  >
                    {store.name}
                  </MenuItem>
                ))}
              </Field>
              {checkError && checkError.StoresUC ? (
                <span className="error">{checkError.StoresUC}</span>
              ) : (
                <></>
              )}
            </DialogContent>
            <DialogActions className="dialog-action wrap">
              <Button
                onClick={() => formik.handleSubmit()}
                color="primary"
                className="dialog-button"
              >
                編集
              </Button>
              {isEdit && (
                <Grid container justify="flex-end">
                  <Button
                    onClick={this.props.deleteSite}
                    color="primary"
                    className="dialog-button-remove"
                  >
                    <DeleteSharpIcon className="icon-margin" />
                    削除
                  </Button>
                </Grid>
              )}
            </DialogActions>
          </Dialog>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  store: getStores(state.storesReducer.store)
});

export default connect(mapStateToProps, {
  edit,
  post,
  put,
  remove,
  fetchStores
})(withRouter(Form));
