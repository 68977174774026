import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles({
  searchBox: {
    display: "flex",
    borderRadius: "4px",
    overflow: "hidden",
    backgroundColor: "#007CFF",
    "& > button": {
      width: "60px",
      height: "45px",
      backgroundColor: "#007CFF"
    }
  },
  searchBoxInput: {
    display: "flex",
    flexGrow: "1",
    height: "45px",
    backgroundColor: "#007CFF",
    "& > input": {
      width: "100%",
      height: "45px",
      padding: "12px 6px 12px 40px",
      boxSizing: "border-box",
      fontSize: "15px",
      lineHeight: "20px",
      margin: "0px",
      borderRadius: "4px",
      backgroundColor: "white"
    },
    "& > span": {
      position: "absolute",
      fontSize: "30px",
      width: "24px",
      height: "24px",
      left: "4px",
      top: "2px"
    }
  },
  listMentionBox: {
    overflowX: "auto",
    padding: "14px 0px 20px 10px",
    whiteSpace: "nowrap"
  },
  dialogMentionItem: {
    padding: "6px 11px 0px 0px",
    display: "inline-block",
    position: "relative",
    "& > div": {
      padding: "8px 15px",
      fontSize: "15px",
      lineHeight: "20px",
      borderRadius: "18px",
      backgroundColor: "white",
      color: "#00356D"
    },
    "& > span": {
      display: "block",
      width: "20px",
      height: "20px",
      fontSize: "20px",
      position: "absolute",
      top: "0",
      right: "0",
      borderRadius: "50%",
      backgroundColor: "#C7C7C7",
      cursor: "pointer",
      lineHeight: "20px"
    }
  },
  mentionsBox: {
    border: "1px solid #BEBEBE",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "start",
    flexWrap: "nowrap",
    minHeight: "45px",
    maxHeight: "90px",
    overflowX: "auto",
    padding: "12px",
    cursor: "pointer",
    backgroundColor: "rgb(248, 249, 249) "
  },
  filterMentionBox: {
    border: "1px solid #E8E8E8",
    borderRadius: "4px",
    padding: "10px 20px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F8F9F9",
    maxHeight: "250px",
    overflowY: "auto"
  },
  mentionItem: {
    width: "100%",
    height: "45px",
    fontSize: "17px",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  }
});

function MentionsInput(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [popoverWidth, setPopoverWidth] = useState("0");
  const selectMentionsRef = useRef(null);

  const { allMentions, addedMentions, setMentions } = props;
  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setPopoverWidth(window.getComputedStyle(selectMentionsRef.current).width);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filteredMentions = () => {
    return allMentions.filter(item => {
      return (
        item.name.indexOf(filterText) > -1 ||
        item.furigana.indexOf(filterText) > -1
      );
    });
  };

  const filteredMentionsList = filteredMentions();

  // const handleClickMentionItem = mention => {
  //   if (
  //     addedMentions.findIndex(item => item.uniqueCode === mention.uniqueCode) >
  //     -1
  //   ) {
  //     if (mention.uniqueCode === "all") {
  //       setMentions([]);
  //     } else {
  //       if (addedMentions.length === allMentions.length) {
  //         setMentions(
  //           addedMentions.filter(
  //             item =>
  //               item.uniqueCode !== mention.uniqueCode &&
  //               item.uniqueCode !== "all"
  //           )
  //         );
  //       } else {
  //         setMentions(
  //           addedMentions.filter(item => item.uniqueCode !== mention.uniqueCode)
  //         );
  //       }
  //     }
  //   } else {
  //     if (mention.uniqueCode === "all") {
  //       setMentions(allMentions);
  //     } else {
  //       if (
  //         addedMentions.filter(item => item.uniqueCode !== "all").length + 1 >=
  //         allMentions.length - 1
  //       ) {
  //         setMentions(allMentions);
  //       } else {
  //         setMentions(addedMentions.concat([mention]));
  //       }
  //     }
  //   }
  // };

  const handleClickMentionItem = mention => {
    if (
      addedMentions.findIndex(item => item.uniqueCode === mention.uniqueCode) >
      -1
    ) {
      setMentions(
        addedMentions.filter(item => item.uniqueCode !== mention.uniqueCode)
      );
    } else {
      setMentions(addedMentions.concat([mention]));
    }
  };

  const checkIsAdded = mention => {
    return (
      addedMentions.findIndex(item => item.uniqueCode === mention.uniqueCode) >
      -1
    );
  };

  return (
    <React.Fragment>
      <div
        onClick={handleClick}
        ref={selectMentionsRef}
        className={classes.root}
        style={{ paddingBottom: "10px" }}
      >
        <div className={classes.mentionsBox}>
          {addedMentions.length === 0 ? (
            <span style={{ fontSize: "17px", lineHeight: "20px", color: "#D1D1D1" }}>
              テキストを入力
            </span>
          ) : (
            addedMentions.map(mention => (
              <span
                key={mention.uniqueCode}
                style={{
                  display: "inline-block",
                  padding: "0xp 6px",
                  fontSize: "15px",
                  lineHeight: "20px",
                  color: "#00356D",
                  marginRight: "14px",
                  fontWeight: "bold",
                  whiteSpace: "nowrap"
                }}
              >
                {mention.name}
              </span>
            ))
          )}
        </div>
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            width: popoverWidth,
            backgroundColor: addedMentions.length === 0 ? "white" : "#00356D",
            borderRadius: "4px"
          }
        }}
        transitionDuration={0.1}
      >
        <div className={classes.searchBox}>
          <div
            className={classes.searchBoxInput}
            style={addedMentions.length === 0 ? { backgroundColor: "white" } : null}
          >
            <span>
              <SearchIcon fontSize="inherit" />
            </span>
            <input
              value={filterText}
              onChange={e => setFilterText(e.target.value)}
              style={{
                boxShadow: "none",
                border: "1px solid #BEBEBE"
              }}
            />
          </div>
          {addedMentions.length > 0 ? (
            <button onClick={handleClose} style={{ color: "white" }}>
              OK
            </button>
          ) : (
            <button
              onClick={handleClose}
              style={{ color: "#333333", backgroundColor: "white" }}
            >
              <CloseIcon fontSize="40" />
            </button>
          )}
        </div>
        {addedMentions.length > 0 ? (
          <div className={classes.listMentionBox}>
            {addedMentions.map(mention => (
              <div
                className={classes.dialogMentionItem}
                key={mention.uniqueCode}
              >
                <div>{mention.name}</div>
                <span onClick={() => handleClickMentionItem(mention)}>
                  <CloseIcon fontSize="inherit" />
                </span>
              </div>
            ))}
          </div>
        ) : null}

        <div className={classes.filterMentionBox}>
          <div>
            {filteredMentionsList.length === 0 ? (
              <p style={{ textAlign: "center" }}>
                該当するメンバーは登録されておりません。
              </p>
            ) : (
              <ul>
                {filteredMentionsList.map((mention, index) => (
                  <li
                    key={mention.uniqueCode}
                    className={classes.mentionItem}
                    onClick={() => handleClickMentionItem(mention)}
                    style={
                      index !== 0 ? { borderTop: "1px solid #A8A8A8" } : null
                    }
                  >
                    <span>{mention.name}</span>
                    <span
                      style={{
                        display: "block",
                        width: "30px",
                        marginLeft: "auto"
                      }}
                    >
                      {checkIsAdded(mention) ? (
                        <DoneIcon color="primary" />
                      ) : null}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </Popover>
    </React.Fragment>
  );
}

MentionsInput.propTypes = {
  allMentions: PropTypes.array,
  addedMentions: PropTypes.array,
  addMention: PropTypes.func,
  deleteMention: PropTypes.func
};

export default MentionsInput;
