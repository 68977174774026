import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TableRow from "@material-ui/core/TableRow";

import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import "../../../common/css/shop.css";

import HistoryDialog from "./HistoryDialog";
import { fetchAgreementLogs } from "./service/service";
import {
  POST_AGREEMENT_LOGS_SUCCESS,
  PUT_AGREEMENT_LOGS_SUCCESS,
  DELETE_AGREEMENT_LOGS_SUCCESS
} from "./constant/constant";

class ContractHistoryTab extends Component {
  state = {
    isDialogShow: false,
    isEdit: false,
    agreementIndex: null
  };

  toggleDialog = (isEdit, agreementIndex = 0) => () => {
    this.setState({
      isDialogShow: !this.state.isDialogShow,
      isEdit: isEdit,
      agreementIndex: agreementIndex
    });
  };

  componentDidMount() {
    this.props.fetchAgreementLogs(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (
      (this.props.type !== nextProps.type &&
        nextProps.type === POST_AGREEMENT_LOGS_SUCCESS) ||
      nextProps.type === PUT_AGREEMENT_LOGS_SUCCESS ||
      nextProps.type === DELETE_AGREEMENT_LOGS_SUCCESS
    ) {
      this.setState({ isDialogShow: false });
    }
  }

  render() {
    const { isDialogShow, isEdit, agreementIndex } = this.state;
    const { agreements, type } = this.props;

    return (
      <Grid className="contract-history">
        <Grid className="mb-10">
          <Button
            onClick={this.toggleDialog(false)}
            size="small"
            variant="text"
            className="button-add"
          >
            <AddIcon className="icon-margin" />
            契約登録​
          </Button>
        </Grid>
        <TableContainer>
          <Table size="small" aria-label="table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="white-text padding-first-th">
                  契約日
                  <ArrowDownwardIcon className="icon-small-size s-va-m margin-left" />
                </TableCell>
                <TableCell className="white-text padding-first-th">
                  契約更新日​
                </TableCell>
                <TableCell className="white-text padding-first-th">
                  ステータス​
                </TableCell>
                <TableCell className="white-text padding-first-th">
                  契約担当者​
                </TableCell>
                <TableCell className="white-text padding-first-th">
                  解約日​
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {agreements &&
                agreements.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className="padding-first-td">
                      {format(new Date(row.agreementDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell className="padding-first-td">
                      {row.contractRenewDate &&
                        format(new Date(row.contractRenewDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell className="padding-first-td">
                      {row.status}
                    </TableCell>
                    <TableCell className="padding-first-td">
                      {row.agreementUserName}
                    </TableCell>
                    <TableCell className="padding-first-td">
                      {row.cancellationDate &&
                        format(new Date(row.cancellationDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell className="padding-last-td s-ta-r">
                      <Button
                        onClick={this.toggleDialog(true, row.index)}
                        size="small"
                        variant="text"
                        className="button-add"
                      >
                        <EditIcon className="icon-margin icon-small-size" />
                        編集
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container justify="space-between" className="mt-10">
          <Button
            onClick={this.toggleDialog(false)}
            size="small"
            variant="text"
            className="button-add"
          >
            <AddIcon className="icon-margin" />
            契約登録​
          </Button>
        </Grid>
        {isDialogShow && (
          <HistoryDialog
            isDialogShow={isDialogShow}
            onCloseDialog={this.toggleDialog()}
            isEdit={isEdit}
            builderUC={this.props.match.params.id}
            agreementIndex={agreementIndex}
          />
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  agreements: state.builderReducer.builderDetailReducer.agreements,
  type: state.builderReducer.builderDetailReducer.type
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchAgreementLogs }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContractHistoryTab));
