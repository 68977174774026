import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

import CircularProgress from "@material-ui/core/CircularProgress";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";

import ReviewHotspotPosition from "./viewModeComponents/ReviewHotspotPosition";
import ReviewSpotPosition from "./viewModeComponents/ReviewSpotPosition";
import DescriptionInput from "./editModeComponents/DescriptionInput";
import FileInputPreview from "./editModeComponents/FileInputPreview";
import IconStarFavorite from "./hotspotInfoIcons/IconStarFavorite";
import MentionsInput from "./editModeComponents/MentionsInput";
import InfoIconItem from "./editModeComponents/InfoIconItem";
import TitleInput from "./editModeComponents/TitleInput";
import IconInfo from "./hotspotInfoIcons/IconInfo";
import Label from "./editModeComponents/Label";

import {
  openHotspotInfo,
  cancelCreateHotspotInfo,
  getShootSuccess
} from "../../action/action";
import { api } from "../../../../../common/api/api";
import { arrayBufferToBase64 } from "../../../../../utils/mapper";

import IconTabFavorite from "./hotspotInfoIcons/IconTabFavorite";
import IconTabFile from "./hotspotInfoIcons/IconTabFile";
import IconTabMessage from "./hotspotInfoIcons/IconTabMessage";

const mediaQueryTabletOrMobile = "(max-width: 960px)";
const mediaQueryTiniMobile = "(max-width: 380px)";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    paddingBottom: "100px",
    backgroundColor: "#f8f9f9"
  },
  mainForm: {
    padding: "40px 20px 30px",
    backgroundColor: "white",
    "& .create-hotspot-info-title": {
      display: "flex",
      alignItems: "flex-start",
      marginBottom: "24px"
    },
    "& .create-hotspot-info-title > div": {
      flexGrow: "1"
    }
  },
  actionSection: {
    display: "flex",
    justifyContent: "center",
    padding: "0px 20px",

    "& div": {
      width: "203px",
      "& button": {
        width: "100%",
        height: "50px",
        borderRadius: "5px",
        fontWeight: "bold",
        textAlign: "center",
        color: "white"
      }
    }
  },
  buttonTab: {
    width: "165px",
    display: "flex",
    justifyContent: "center",
    borderBottom: "2px solid transparent",
    color: "#C7C7C7",
    fontSize: "18px",
    fontWeight: "normal",
    "& p": {
      marginLeft: "8px"
    }
  },
  buttonTabSP: {
    width: "165px",
    display: "flex",
    justifyContent: "center",
    borderBottom: "2px solid transparent",
    color: "#C7C7C7",
    fontSize: "14px",
    fontWeight: "normal",
    paddingLeft: "2px",
    "& p": {
      marginLeft: "4px"
    }
  },
  customScrollbar: {
    "&::-webkit-scrollbar": {
      backgroundColor: "#fff",
      width: "16px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#babac0",
      borderRadius: "16px",
      border: "4px solid #fff"
    },
    "&::-webkit-scrollbar-button": {
      display: "none"
    }
  }
});

function CreateHotspotInfo(props) {
  const classes = useStyles();
  const {
    newHostpotInfoColor,
    changeNewHostpotInfoColor,
    positionNewHotspotInfo,
    drawerCancelCreateHotspot
  } = props;

  const [allMentions, setAllMentions] = useState([]);
  const [allIcons, setAllIcons] = useState([]);
  const [icon, setIcon] = useState(null);
  const [title, setTitle] = useState("");
  const [isEmptyTitle, setIsEmptyTitle] = useState(false);
  const [description, setDescription] = useState("");
  const [mentions, setMentions] = useState([]);
  const [files, setFiles] = useState([]);
  const [isFavorite, setIsFavorite] = useState(true);
  const [showSelectIcon, setShowSelectIcon] = useState(null);
  const [creator, setCreator] = useState("");
  const [currentDrawer, setCurrentDrawer] = useState("");
  const [currentSpotPosition, setCurrentSpotPosition] = useState({
    x: 0,
    y: 0
  });
  const [creating, setCreating] = useState(false);
  const [shootThumbnailBase64, setShootThumbnailBase64] = useState("");
  const [largeShootThumbnailBase64, setLargeShootThumbnailBase64] =
    useState("");

  const isTabletOrMobile = useMediaQuery({ query: mediaQueryTabletOrMobile });
  const isTiniMobile = useMediaQuery({ query: mediaQueryTiniMobile });

  const { id } = useParams();
  const logWalkInfo = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.logWalkInfo
  );

  const blobDataLogWalk = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.blobDataLogWalk
  );

  const drawingsList = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.drawingsList
  );

  const dispatch = useDispatch();
  let location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setCreating(false);
    setIcon(newHostpotInfoColor);
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    const fetchAllMentions = api.get(
      `/${classification}/logwalk-viewer/sites/${logWalkInfo.sitesUC}/mentions`
    );
    const fetchAllColors = api.get(
      `/${classification}/logwalk-viewer/builders/${logWalkInfo.buildersUC}/info-icons`
    );
    const fetchCreatorInfo = api.get(
      `/${classification}/logwalk-viewer/profiles/${logWalkInfo.buildersUC}`
    );
    Promise.all([fetchAllMentions, fetchAllColors, fetchCreatorInfo]).then(
      res => {
        setAllMentions(res[0].data);
        setAllIcons(res[1].data);
        setCreator(res[2].data.name);
      }
    );

    drawingsList.find(item => {
      const currentSpot = item.spots.find(
        item => item.uniqueCode === logWalkInfo.spotsUC
      );
      if (currentSpot) {
        setCurrentSpotPosition({ x: currentSpot.x, y: currentSpot.y });
        // setTitle(`${item.name}_${currentSpot.name}_01`);

        var isBlob = item.blob ? true : false;
        var path = isBlob ? item.blob.replace(/^\/api/, '') : item.path.slice(4);

        api
          .get(path, { responseType: isBlob ? undefined : "arraybuffer" })
          .then(res => {
            if (res.data) {
              if (res.data) {
                if (isBlob) {
                  setCurrentDrawer(res.data.blobData);
                } else {
                  const base64Data = arrayBufferToBase64(res.data);
                  setCurrentDrawer(`data:image/jpeg;base64,${base64Data}`);
                }
              } else {
                setCurrentDrawer("");
              }
            }
          });
        return true;
      }
      return false;
    });
  }, []);

  useEffect(() => {
    setShootThumbnailBase64('');
    setLargeShootThumbnailBase64('');
  }, [positionNewHotspotInfo])

  const handleCancel = () => {
    resetState();
    drawerCancelCreateHotspot();
    dispatch(cancelCreateHotspotInfo());
  };

  const resetState = () => {
    setTitle("");
    setDescription("");
    setMentions([]);
    setFiles([]);
  };

  const handleCreate = () => {
    if (title === "") {
      setIsEmptyTitle(true);
      return;
    }

    setIsEmptyTitle(false);
    setCreating(true);
    let data = {};
    data.iconsUC = icon.uniqueCode;
    data.title = title;
    data.description = description;
    data.yaw = positionNewHotspotInfo.yaw;
    data.pitch = positionNewHotspotInfo.pitch;
    data.createdAt = Date.now();
    if (mentions === []) {
      data.mentions = [];
    } else {
      data.mentions = mentions.map(item => item.uniqueCode);
    }

    if (files !== []) {
      data.files = files
        .filter(item => item.isUploadFromDevice)
        .map(rawFile => {
          const file = {
            name: rawFile.name,
            file: rawFile.fileBase64,
            createdAt: rawFile.createdAt
          };
          return file;
        });
      data.filesUniqueCode = files
        .filter(item => !item.isUploadFromDevice)
        .map(rawFile => {
          if(rawFile.fileId) {
            return {uniqueCode: rawFile.fileId, name: rawFile.name}
          } else {
            return {uniqueCode: rawFile.photoId, name: rawFile.name}
          }
        });
    } else {
      data.files = [];
    }

    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    api
      .post(`/${classification}/logwalk-viewer/shoots/${id}/infos`, data)
      .then(res_infos => {
        api.get(`/${classification}/logwalk-viewer/shoots/${id}`).then(res => {
          setCreating(false);
          dispatch(getShootSuccess(res.data));
          dispatch(openHotspotInfo(res_infos.data.uniqueCode));
        });
      })
      .catch(() => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        if (location.pathname.includes("/sp/")) {
          history.replace(`/sp/logwalk-viewer/shoots/${id}`);
          history.go(0);
        } else {
          history.replace(`/logwalk-viewer/shoots/${id}`);
          history.go(0);
        }
      });
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div
          className={classes.mainForm}
          style={isTiniMobile ? { padding: "40px 16px 30px" } : null}
        >
          <div className="create-hotspot-info-title">
            {/* <span
              style={{
                width: "30px",
                height: "30px",
                cursor: "pointer",
                marginTop: "5px"
              }}
              onClick={() => {
                setIsFavorite(!isFavorite);
              }}
            >
              <IconStarFavorite isFavorite={isFavorite} />
            </span> */}
            <span
              style={{
                width: "40px",
                height: "40px",
                margin: "0px 8px",
                cursor: "pointer"
              }}
              onClick={e => setShowSelectIcon(e.currentTarget)}
            >
              <IconInfo
                width="40"
                height="40"
                color={icon ? icon.color : newHostpotInfoColor.color}
              />
            </span>
            <div>
              <TitleInput
                title={title}
                setTitle={setTitle}
                requiredError={isEmptyTitle}
                placeholder="タイトル入力"
              />
            </div>
          </div>

          <DescriptionInput
            description={description}
            setDescription={setDescription}
            placeholder="報告内容を入力"
          />

          <div
            style={{
              fontSize: "17px",
              fontWeight: "bold",
              lineHeight: "24px",
              padding: "30px 0px 10px",
              color: "#333333"
            }}
          >
            通知メンバー
          </div>
          <MentionsInput
            allMentions={allMentions}
            addedMentions={mentions}
            setMentions={setMentions}
          />

          <div style={{ width: "100%", textAlign: "right", fontSize: "15px" }}>
            <span
              style={{
                color: "#00356D",
                marginRight: "15px",
                fontWeight: "bold"
              }}
            >
              {creator}
            </span>
            <span style={{ color: "#333333" }}>
              {format(Date.now(), "yyyy/MM/dd (HH:mm)")}
            </span>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              borderBottom: "1px solid #C7C7C7",
              margin: "0px",
              backgroundColor: "white"
            }}
          >
            {/*<Button
              type="button"
              className={
                isTabletOrMobile ? classes.buttonTabSP : classes.buttonTab
              }
            >
              <IconTabMessage smallSize={isTabletOrMobile} />
              <span>チャット</span>
            </Button>*/}

            <Button
              type="button"
              style={{
                color: "#00356D",
                fontWeight: "bold",
                borderBottom: "2px solid #00356de6",
                borderRadius: "0px"
              }}
              className={
                isTabletOrMobile ? classes.buttonTabSP : classes.buttonTab
              }
            >
              <IconTabFile isActive={true} smallSize={isTabletOrMobile} />
              <span style={{}}>ファイル</span>
            </Button>
            {/* <Button
              type="button"
              className={
                isTabletOrMobile ? classes.buttonTabSP : classes.buttonTab
              }
            >
              <IconTabFavorite smallSize={isTabletOrMobile} />
              <span>ャット</span>
            </Button> */}
          </div>
        </div>

        <div>
          <div
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <div style={{ padding: "0px 16px" }}>
              <FileInputPreview
                files={files}
                setFiles={setFiles}
                sitesUC={logWalkInfo.sitesUC}
              />
            </div>
            <div style={{ display: "flex", padding: "0px 16px 20px" }}>
              <div
                style={{ width: "50%", padding: "0px 12px 0px 0px" }}
              >
                <ReviewHotspotPosition
                  miniBlobDataLogWalk={blobDataLogWalk}
                  yaw={positionNewHotspotInfo.yaw}
                  pitch={positionNewHotspotInfo.pitch}
                  iconColor={icon ? icon.color : newHostpotInfoColor.color}
                  base64={shootThumbnailBase64}
                  setBase64={setShootThumbnailBase64}
                  largeBase64={largeShootThumbnailBase64}
                  setLargeBase64={setLargeShootThumbnailBase64}
                />
              </div>
              <div style={{ width: "50%", padding: "0px 0px 0px 12px" }}>
                <ReviewSpotPosition
                  drawing={currentDrawer}
                  spot={currentSpotPosition}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "100%", display: "block" }}>
          <div className={classes.actionSection}>
            <div style={{ marginRight: "34px" }}>
              <button
                style={{
                  backgroundColor: "#00356D",
                  touchAction: "manipulation",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                onClick={() => {
                  if (!creating) {
                    handleCreate();
                  }
                }}
              >
                <span style={{ marginRight: "8px" }}>作成</span>
                {creating && <CircularProgress size="24px" color="inherit" />}
              </button>
              {isEmptyTitle && (
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "15px",
                    lineHeight: "24px",
                    color: "red",
                    marginTop: "8px"
                  }}
                >
                  タイトルが未入力です。
                </p>
              )}
            </div>
            <div>
              <button
                style={{
                  backgroundColor: "#C7C7C7",
                  touchAction: "manipulation"
                }}
                onClick={handleCancel}
              >
                キャンセル
              </button>
            </div>
          </div>
        </div>
      </div>

      <Popover
        open={Boolean(showSelectIcon)}
        anchorEl={showSelectIcon}
        onClose={() => setShowSelectIcon(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            overflow: "hidden",
            width: "240px",
            height: "190px",
            padding: "12px 0px"
          }
        }}
      >
        <div
          style={{ width: "100%", height: "100%", overflow: "auto" }}
          className={classes.customScrollbar}
        >
          {allIcons.map((iconItem, index) => (
            <React.Fragment>
              {index !== 0 && (
                <hr
                  style={{
                    border: "none",
                    borderTop: "1px solid #BEBEBE",
                    margin: "0px 20px"
                  }}
                />
              )}
              <InfoIconItem
                key={iconItem.uniqueCode}
                icon={icon}
                iconItem={iconItem}
                setIcon={iconItem => {
                  setIcon(iconItem);
                  setShowSelectIcon(null);
                  changeNewHostpotInfoColor(iconItem);
                }}
              />
            </React.Fragment>
          ))}
        </div>
      </Popover>
    </React.Fragment>
  );
}

CreateHotspotInfo.propTypes = {};

export default CreateHotspotInfo;
