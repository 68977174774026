import React, { useState, useCallback, useEffect } from "react";
import Pagination from "material-ui-flat-pagination";
import { pagingData } from "../../utils/mapper";

function PaginationControl({ datas, limit, changeData }) {
  const [offSet, setOffSet] = useState(0);
  const changeOffset = useCallback((e, offset) => {
    setOffSet(offset);
  }, []);

  useEffect(() => {
    const newData = pagingData(datas, 0, limit);
    setOffSet(0);
    changeData(newData);
  }, [datas]);

  useEffect(() => {
    const newData = pagingData(datas, offSet, limit);
    setOffSet(offSet);
    changeData(newData);
  }, [offSet]);

  return (
    <Pagination
      limit={limit}
      offset={offSet}
      total={datas.length}
      className="pagination width-auto"
      onClick={changeOffset}
    />
  );
}

export default PaginationControl;
