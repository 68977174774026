import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import InputControl from "../../common/InputControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Formik, Field } from "formik";
import Validator from "validator";
import LoginUtility from "./LoginUtility";
import "../../common/css/base.css";
import "../../common/css/auth.css";
import { translateMessage } from "utils/mapper";
import { Link } from "react-router-dom";

class LoginBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.email,
      password: props.password,
      errors: props.errors,
      nextButtonClick: props.nextButtonClick,
      isPasswordShow: props.isPasswordShow,
      isAutoLoginChecked: props.isAutoLoginChecked
    };
    this.loginUtilityRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    //displaying errors
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = (e, formik) => {
    e.preventDefault();
    this.setState({ [e.target.id]: e.target.value });
    formik.handleChange(e);
    this.props.onChange(e.target.name, e.target.value);
  };

  isAutoLoginChecked() {
    const loginUtility = this.loginUtilityRef.current;
    const checkboxes = loginUtility.checkboxesRef.current;
    return checkboxes.state.checked;
  }

  onSubmit = values => {
    const autoLogin = this.isAutoLoginChecked();
    this.props.onSubmit(values.email, values.password, autoLogin);
  };

  isEmptyEmail(email) {
    return Validator.isEmpty(email);
  }

  isEmailFormat(email) {
    return Validator.isEmail(email);
  }

  validateEmail(email) {
    const key = "email";
    const { message } = this.props;
    const errors = this.state.errors;
    if (this.isEmptyEmail(email))
      errors[key] = translateMessage(
        this.getMessage("required", message),
        this.props.regexObject
      );
    else if (!this.isEmailFormat(email))
      errors[key] = translateMessage(
        this.getMessage("email", message),
        this.props.regexObject
      );
    else delete errors[key];
    this.setState({ errors: errors });
    return this.state.errors === "" || !(key in this.state.errors);
  }

  getMessage = (key, message) => {
    return message[key];
  };

  checktButtonClick = formik => e => {
    e.preventDefault();
    const { values } = formik;
    if (!this.validateEmail(values.email)) return;

    if (this.state.nextButtonClick) {
      formik.handleSubmit();
    } else {
      this.setState({ nextButtonClick: true });
    }
  };

  render() {
    const {
      errors,
      email,
      password,
      nextButtonClick,
      isPasswordShow,
      isAutoLoginChecked
    } = this.state;
    const values = {
      email: email,
      password: password
    };

    const showPassword = () => {
      const isPasswordShow = !this.state.isPasswordShow;
      this.setState({
        isPasswordShow
      });
      this.props.onChange("isPasswordShow", isPasswordShow);
    };

    return (
      <Formik
        initialValues={values}
        enableReinitialize
        onSubmit={values => this.onSubmit(values)}
        render={formik => (
          <form onSubmit={this.checktButtonClick(formik)}>
            <Grid className="area-login-wrap">
              <Grid container justify="space-between">
                <Grid className="area-logo" />
                <Grid className="area-login">
                  <Grid>
                    <h4 className="ttl-login">社員でログイン</h4>
                  </Grid>
                  <Grid className="wrap-form">
                    <Grid>
                      <Field
                        label="メールアドレス"
                        name="email"
                        type="email"
                        onChange={e => this.onChange(e, formik)}
                        variant="filled"
                        className="input-login"
                        component={InputControl}
                      />
                      <span className="red-text">
                        {errors.email}
                        {errors.emailnotfound}
                      </span>
                    </Grid>

                    {nextButtonClick && (
                      <Grid className="relative">
                        <Field
                          label="パスワード"
                          name="password"
                          type={isPasswordShow ? "text" : "password"}
                          onChange={e => this.onChange(e, formik)}
                          variant="filled"
                          // className="input-login"
                          className={classnames("input-login pdr-50", {
                            invalid: errors.password || errors.passwordincorrect
                          })}
                          component={InputControl}
                        />
                        <IconButton
                          color="primary"
                          aria-label="copy"
                          className="show-password"
                          onClick={showPassword}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <span className="red-text">
                          {errors.password}
                          {errors.passwordincorrect}
                        </span>
                      </Grid>
                    )}
                    {nextButtonClick && (
                      <LoginUtility
                        ref={this.loginUtilityRef}
                        isAutoLoginChecked={isAutoLoginChecked}
                        onChange={isAutoLoginChecked =>
                          this.props.onChange(
                            "isAutoLoginChecked",
                            isAutoLoginChecked
                          )
                        }
                      />
                    )}

                    <Grid container justify="center" direction="column" justify="center" alignItems="center">
                      <button
                        type="submit"
                        className="btn-login"
                        onClick={this.checktButtonClick(formik)}
                        style={{marginBottom: "35px"}}
                      >
                        {nextButtonClick ? "ログイン" : "次へ"}
                      </button>
                      <Grid>
                        <p className="red-text">{errors.error}</p>
                      </Grid>
                      <Grid>
                        <Link to="/sp/login" className="txt-link-login">
                          職人の方はこちら
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}
//export default Login;

LoginBuilder.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});
export default connect(mapStateToProps, {})(withRouter(LoginBuilder));
