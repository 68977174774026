import React from "react";

function Icon({ isDisable, isPrevious }) {
  if (!isPrevious)
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 97">
        <defs>
          <filter
            id="a"
            width="110"
            height="97"
            x="0"
            y="0"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="3"></feOffset>
            <feGaussianBlur result="b" stdDeviation="3"></feGaussianBlur>
            <feFlood floodOpacity="0.161"></feFlood>
            <feComposite in2="b" operator="in"></feComposite>
            <feComposite in="SourceGraphic"></feComposite>
          </filter>
        </defs>
        <g
          filter="url(#a)"
          transform="translate(-684.999 -394) translate(685 394)"
        >
          <path
            fill={isDisable ? "#dbdbdb" : "#fff"}
            d="M53.869 65.535H9v-40.07h44.869V6L101 45.5 53.869 85z"
          ></path>
        </g>
      </svg>
    );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 97">
      <defs>
        <filter
          id="a"
          width="110"
          height="97"
          x="0"
          y="0"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3"></feOffset>
          <feGaussianBlur result="b" stdDeviation="3"></feGaussianBlur>
          <feFlood floodOpacity="0.161"></feFlood>
          <feComposite in2="b" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
      </defs>
      <g filter="url(#a)" transform="translate(9 6) translate(-9 -6)">
        <path
          fill={isDisable ? "#dbdbdb" : "#fff"}
          d="M56.131 65.535H101v-40.07H56.131V6L9 45.5 56.131 85z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
