import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Pagination from "@material-ui/lab/Pagination";
import showShareUrlIcon from "../../common/img/show-share-url.png";
import { isTablet, isMobile } from "react-device-detect";

import { format } from "date-fns";

const NumberOfPage = 30;

const mediaQueryTabletOrMobile = "(max-width: 960px)";

const useStyles = makeStyles({
  tableRowHover: {
    backgroundColor: "#F8F9F9",
    "&:hover": {
      backgroundColor: "#d6d6d6"
    }
  },
  paginationUl: {
    margin: "0",
    display: "flex",
    padding: "0",
    flexWrap: "wrap",
    listStyle: "none",
    alignItems: "center",
    justifyContent: "center"
  },
  truncateText3: {
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "wrap",
    wordBreak: "break-word"
  }
});

function ListShareUrlsTable(props) {
  const {
    shareUrls,
    paginationNumber,
    setPaginationNumber,
    setEditingShareUrl,
    stickyHeader,
    pagination
  } = props;

  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: mediaQueryTabletOrMobile });

  return (
    <React.Fragment>
      <TableContainer className="table-remove-overflow">
        <Table
          size="small"
          stickyHeader={stickyHeader}
          aria-label="sticky table"
          style={{ marginBottom: isTabletOrMobile ? "20px" : "0px" }}
        >
          <TableHead>
            <TableRow style={{ backgroundColor: "#162f50" }}>
              <TableCell
                className="white-text text-center"
                style={{
                  top: isMobile || isTablet ? "63px" : "79px",
                  width: "200px",
                  borderRadius: "0px",
                  padding: "6px 10px",
                  minWidth: "76px"
                }}
              >
                現場名
              </TableCell>

              <TableCell
                className="white-text text-center"
                style={{
                  top: isMobile || isTablet ? "63px" : "79px",
                  width: "200px",
                  borderRadius: "0px",
                  padding: "6px 10px",
                  minWidth: "76px"
                }}
              >
                地点名
              </TableCell>

              {!isTabletOrMobile && (
                <TableCell
                  className="white-text text-center"
                  style={{
                    top: isMobile || isTablet ? "63px" : "79px",
                    width: "80px",
                    borderRadius: "0px",
                    padding: "6px 10px"
                  }}
                >
                  発行者
                </TableCell>
              )}

              <TableCell
                className="white-text text-center"
                style={{
                  top: isMobile || isTablet ? "63px" : "79px",
                  width: "80px",
                  borderRadius: "0px",
                  padding: "6px 10px"
                }}
              >
                写真日時
              </TableCell>

              {!isTabletOrMobile && (
                <TableCell
                  className="white-text text-center"
                  style={{
                    top: isMobile || isTablet ? "63px" : "79px",
                    width: "80px",
                    borderRadius: "0px",
                    padding: "6px 10px"
                  }}
                >
                  共有日時
                </TableCell>
              )}
              <TableCell
                style={{
                  width: "76px",
                  borderRadius: "0px",
                  padding: "6px 10px",
                  top: isMobile || isTablet ? "63px" : "79px"
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {shareUrls.length > 0 &&
              shareUrls.map(
                (shareUrl, index) => (
                  <TableRow
                    key={shareUrl.id}
                    className={classes.tableRowHover}
                    onClick={() => setEditingShareUrl(shareUrl)}
                  >
                    <TableCell
                      style={{
                        paddingLeft: "6px",
                        paddingRight: "6px"
                      }}
                    >
                      <p className={classes.truncateText3}>
                        {shareUrl.SiteName}
                      </p>
                    </TableCell>

                    <TableCell
                      style={{
                        paddingLeft: "6px",
                        paddingRight: "6px"
                      }}
                    >
                      <p className={classes.truncateText3}>
                        {shareUrl.SpotName}
                      </p>
                    </TableCell>

                    {!isTabletOrMobile && (
                      <TableCell
                        className="text-center"
                        style={{
                          paddingLeft: "6px",
                          paddingRight: "6px"
                        }}
                      >
                        <p className={classes.truncateText1}>
                          {shareUrl.CreatedBy.name}
                        </p>
                      </TableCell>
                    )}
                    <TableCell
                      className="text-center"
                      style={{
                        paddingLeft: "6px",
                        paddingRight: "6px"
                      }}
                    >
                      <p>
                        {format(new Date(shareUrl.ShootedAt), "yyyy/MM/dd")}
                      </p>
                      <p>{format(new Date(shareUrl.ShootedAt), "HH:mm")}</p>
                    </TableCell>

                    {!isTabletOrMobile && (
                      <TableCell
                        className="text-center"
                        style={{
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          fontSize: isMobile ? "11px" : "14px"
                        }}
                      >
                        <p>
                          {format(new Date(shareUrl.CreatedAt), "yyyy/MM/dd")}
                        </p>
                        <p>{format(new Date(shareUrl.CreatedAt), "HH:mm")}</p>
                      </TableCell>
                    )}

                    <TableCell
                      className="text-center"
                      style={{
                        paddingLeft: "6px",
                        paddingRight: "6px"
                      }}
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={event => {
                          event.preventDefault();
                          event.stopPropagation();
                          window.open(
                            `/url-share/${shareUrl.BuildersUC}/${shareUrl.Id}`,
                            "_blank"
                          );
                        }}
                      >
                        <img
                          src={showShareUrlIcon}
                          alt=""
                          width={30}
                          height={30}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && pagination.total_pages > 1 && (
        <Pagination
          classes={{ ul: classes.paginationUl }}
          count={pagination ? pagination.total_pages : 0}
          page={paginationNumber}
          onChange={(event, value) => {
            setPaginationNumber(value)
          }
          }
          style={{
            border: "none",
            backgroundColor: "#fff",
            boxShadow: "none"
          }}
        />
      )}
    </React.Fragment>
  );
}

export default ListShareUrlsTable;
