import React, { Component } from "react";
import { format } from "date-fns";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

class RobotHistoryPC extends Component {
  render() {
    const { robotHistory } = this.props;
    return (
      <Grid className="mt-25">
        <TableContainer>
          <Table component="div" aria-label="table">
            <TableHead component="div" className="table-head">
              <TableRow component="span">
                <TableCell
                  component="span"
                  className="white-text padding-first-th"
                >
                  現場名
                </TableCell>
                <TableCell component="span" className="white-text">
                  割当担当者
                </TableCell>
                <TableCell component="span" className="white-text">
                  割当日​
                  <ArrowDownwardIcon className="icon-small-size s-va-m margin-left" />
                </TableCell>
                <TableCell component="span" className="white-text">
                  配置終了担当者
                </TableCell>
                <TableCell component="span" className="white-text">
                  配置終了日​
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody component="div" className="table-body">
              {robotHistory &&
                robotHistory.map((row, index) => (
                  <TableRow component="span" key={index}>
                    <TableCell component="span">{row.siteName}</TableCell>
                    <TableCell component="span">
                      {row.assignStartUser}
                    </TableCell>
                    <TableCell component="span">
                      {row.assignStartDate &&
                        format(new Date(row.assignStartDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell component="span">{row.assignEndUser}</TableCell>
                    <TableCell component="span">
                      {row.assignEndDate &&
                        format(new Date(row.assignEndDate), "yyyy年M月d日")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  }
}

export default RobotHistoryPC;

