import React, { useContext, useState, useEffect } from "react";
import dateFormat from "dateformat";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";

import { CometChatAvatar } from "../../Shared";

import { CometChatContext } from "../../../util/CometChatContext";

import { theme } from "../../../resources/theme";
import Translator from "../../../resources/localization/translator";
import { getMessageSentTime } from "../../../util/common";

import {
	messageContainerStyle,
	messageWrapperStyle,
	messageTxtWrapperStyle,
	messageTxtStyle,
	messageInfoWrapperStyle,
	messageTimeStampStyle,
	messageThumbnailStyle,
	messageDetailStyle,
	nameWrapperStyle,
	nameStyle
} from "./style";

const CometChatDeleteMessageBubble = (props) => {

	const context = useContext(CometChatContext);

	const [loggedInUser, setLoggedInUser] = useState(null);

	useEffect(() => {
		context.getLoggedinUser().then(user => {
			setLoggedInUser({ ...user });
		});
	}, [context]);

	let message = null;
	const messageDate = props.message.sentAt;
	if (props.message?.sender?.uid === loggedInUser?.uid) {

		message = (
			<React.Fragment>
				<div style={{ display: "flex", alignItems: "flex-end" }}>
					<div css={messageInfoWrapperStyle(props, loggedInUser)} className="message__info__wrapper">
						<span css={messageTimeStampStyle(context)} className="message__timestamp"
							style={{ color: "rgba(0, 0, 0, 0.87)" }}>
							{getMessageSentTime(messageDate, props.lang)}
						</span>
					</div>
					<div css={messageTxtWrapperStyle(props, context, loggedInUser)} className="message__txt__wrapper"
						style={{ backgroundColor: "#FFFFFF", color: "rgba(0, 0, 0, 0.87)", boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)" }}>
						<p css={messageTxtStyle(context)} className="message__txt">
							{Translator.translate("YOU_DELETED_THIS_MESSAGE", props.lang)}
						</p>
					</div>
				</div>
			</React.Fragment>
		);

	} else {

		let avatar = null,
			name = null;
		if (props.message.receiverType === CometChat.RECEIVER_TYPE.GROUP) {
			// avatar = (
			// 	<div css={messageThumbnailStyle()} className="message__thumbnail">
			// 		<CometChatAvatar user={props.message.sender} />
			// 	</div>
			// );

			if (props.isDisplayAvatar) {
				name = (
					<div css={nameWrapperStyle(props, loggedInUser)} className="message__name__wrapper"
						style={{ display: "flex", alignItems: "center" }}>
						{props.message.sender.metadata?.classification === "builder" &&
							<span style={{
								display: "inline-block",
								width: "8px",
								height: "8px",
								borderRadius: "50%",
								backgroundColor: "#8FC31F"
							}}></span>}&nbsp;
						<span css={nameStyle(context)} className="message__name" style={{ color: "#00356D" }}>
							{props.message.sender.name}{props.message.sender.metadata?.company ? " / " + props.message.sender.metadata?.company : ""}
						</span>
					</div>
				);
			}
		}

		message = (
			<React.Fragment>
				{avatar}
				<div css={messageDetailStyle(props, loggedInUser)} className="message__details">
					{name}
					<div style={{ display: "flex", alignItems: "flex-end" }}>
						<div css={messageTxtWrapperStyle(props, context, loggedInUser)} className="message__txt__wrapper"
							style={{ backgroundColor: "#E1E3EA", boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)" }}>
							<p css={messageTxtStyle(context)} className="message__txt">
								{Translator.translate("THIS_MESSAGE_DELETED", props.lang)}
							</p>
						</div>
						<div css={messageInfoWrapperStyle(props, loggedInUser)} className="message__info__wrapper">
							<span css={messageTimeStampStyle(context)} className="message__timestamp">
								{getMessageSentTime(messageDate, props.lang)}
							</span>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}

	return (
		<div css={messageContainerStyle(props, loggedInUser)} className="message__deleted" style={{ marginBottom: "10px" }}>
			<div css={messageWrapperStyle(props, loggedInUser)} className="message__wrapper">{message}</div>
		</div>
	)
}

// Specifies the default values for props:
CometChatDeleteMessageBubble.defaultProps = {
	lang: Translator.getDefaultLanguage(),
	theme: theme
};

CometChatDeleteMessageBubble.propTypes = {
	lang: PropTypes.string,
	theme: PropTypes.object,
	message: PropTypes.object.isRequired,
};

export { CometChatDeleteMessageBubble };