import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import CommonDialog from "../../CommonDialog";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";
import {
  POST_SITES_SUCCESS,
  UPDATE_SITE_SUCCESS,
  DELETE_SITES_SUCCESS,
  COMPLETE_SITES_SUCCESS
} from "../constant/constant";
import { getUniqueCode } from "../reducer/sites";
import { getPIC } from "../../../common/Stores/reducer/reducer";
import { getSitesDetail } from "./reducer/reducer";
import {
  addSite,
  deleteSite,
  updateSite,
  clearType,
  completeSite
} from "../service/service";
import { fetchSiteDetail } from "./service/service";
import { getSitesDetailSuccess as fillSiteDetail } from "./action/action";
import { dispatchError as fillSiteErrors } from "../../../common/Stores/service/service";
import { fetchPIC } from "../../../common/Stores/service/service";
import { api } from "../../../common/api/api";
import DialogDelete from "../../../common/DialogDelete";
import { GET_SITES_DETAIL_SUCCESS } from "./constant/constant";
import { translateMessage, trimObjValues } from "utils/mapper";
import {
  translateValidate,
  messageValidate
} from "../../../common/Stores/service/service";

class SiteDialog extends React.PureComponent {
  state = {
    currentDateStatus: {},
    siteDetailInitial: null,
    isDialogConfirmImage: false
  };

  fieldList = [
    {
      name: "image",
      label: "プロフィール写真",
      type: DIALOG_FIELD_TYPES.Image
    },
    {
      name: "name",
      label: "現場名",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    },
    {
      name: "startOn",
      label: "着工日",
      type: DIALOG_FIELD_TYPES.DateTime,
      isHalfWidth: true
    },
    {
      name: "endOn",
      label: "完工日",
      type: DIALOG_FIELD_TYPES.DateTime,
      isHalfWidth: true
    },
    {
      name: "address",
      label: "現場住所",
      type: DIALOG_FIELD_TYPES.Text
    },
    {
      name: "pic",
      label: "担当者",
      type: DIALOG_FIELD_TYPES.DropDownBox,
      options: []
    }
  ];

  state = {
    defaultValues: {
      name: "",
      startOn: null,
      endOn: null,
      address: "",
      pic: -1,
      clientError: null
    },
    isDialogShow: false,
    isDialogDeleteShow: false,
    isDialogCompleteShow: false
  };

  componentWillMount() {
    const { fetchPIC, fetchSiteDetail, isEdit, siteId, siteDetail } =
      this.props;
    fetchPIC();
    if (isEdit && siteId !== "") fetchSiteDetail(siteId);

    this.setState({ isDialogShow: this.props.isDialogShow });

    this.props.translateValidate();
    this.props.messageValidate();
  }

  componentWillReceiveProps(nextProps) {
    switch (nextProps.type) {
      case POST_SITES_SUCCESS:
        this.props.onClose();
        this.props.history.push("/sites/" + nextProps.uniqueCode);
        break;
      case UPDATE_SITE_SUCCESS:
        this.props.onUpdate();
        this.props.onClose();
        break;
      case DELETE_SITES_SUCCESS:
        this.props.onClose();
        this.props.history.push("/sites");
        break;
      case COMPLETE_SITES_SUCCESS:
        this.props.onClose();
        break;
    }
  }

  componentWillUnmount() {
    this.props.clearType();
    this.props.fillSiteDetail("");
    this.props.fillSiteErrors("");
  }

  onSubmitForm = values => {
    const { isEdit, siteId } = this.props;
    const { currentDateStatus } = this.state;

    let newValuesForm = { ...values };
    if (newValuesForm.pic === -1) {
      newValuesForm.pic = "";
    }

    for (let key in currentDateStatus) {
      newValuesForm[key] = "Invalid Date";
    }

    let clientError = null;

    const trimedFileValues = trimObjValues(newValuesForm);

    if (!trimedFileValues.name) {
      clientError = {
        ...clientError,
        name: translateMessage(
          this.props.message.required,
          this.props.regexObject
        )
      };
    }

    const startOn = new Date(trimedFileValues.startOn);
    const endOn = new Date(trimedFileValues.endOn);

    if (trimedFileValues.startOn && trimedFileValues.endOn && startOn > endOn) {
      clientError = {
        ...clientError,
        endOn: translateMessage(
          this.props.message.greaterThanOrEqualTo.replace("%s", "startOn"),
          this.props.regexObject
        )
      };
    }

    newValuesForm["startOn"] = trimedFileValues.startOn ? startOn : "";
    newValuesForm["endOn"] = trimedFileValues.endOn ? endOn : "";

    this.setState({
      clientError: clientError
    });

    if (!clientError) {
      let formData = new FormData();

      for (var key in newValuesForm) {
        formData.append(key, newValuesForm[key]);
      }

      if (isEdit && siteId !== "") {
        this.props.updateSite(formData, siteId, () => {
          alert("エラーが発生しました。 お手数ですが、再度お試しください。");
          this.props.history.go(0);
        });
      } else {
        this.props.addSite(formData, () => {
          alert("エラーが発生しました。 お手数ですが、再度お試しください。");
          this.props.history.go(0);
        });
      }
    }
  };

  currentDate = (fieldName, date) => {
    if (date?.toString() === "Invalid Date") {
      this.setState({
        currentDateStatus: {
          ...this.state.currentDateStatus,
          [fieldName]: ""
        }
      });
    } else {
      const newDateStatus = { ...this.state.currentDateStatus };
      delete newDateStatus[fieldName];
      this.setState({
        currentDateStatus: newDateStatus
      });
    }
  };

  render() {
    const { isEdit, submitErrors, PIC, siteDetail, hasLogwalkPhoto } =
      this.props;
    const { defaultValues, clientError } = this.state;
    const fieldList = [...this.fieldList];
    const picIndex = fieldList.findIndex(field => field.name === "pic");
    if (isEdit) {
      delete fieldList[picIndex];
    } else {
      fieldList[picIndex].options = PIC;
    }

    if (siteDetail && siteDetail.pic === null) siteDetail.pic = -1;

    const onDeleteClick = () => {
      this.setState({
        isDialogDeleteShow: true,
        isDialogShow: false,
        isDialogConfirmImage: false
      });
    };
    const onCloseDialogDelete = () => {
      this.setState({
        isDialogDeleteShow: false,
        isDialogShow: false
      });
      this.props.onClose();
    };
    const onDeleteSite = () => {
      if (this.props.isEdit && this.props.siteId !== "") {
        this.props.deleteSite(this.props.siteId, () => {
          alert("エラーが発生しました。 お手数ですが、再度お試しください。");
          this.props.history.go(0);
        });
      }
    };
    //handle complete dialog
    const onCompleteClick = () => {
      this.setState({
        isDialogCompleteShow: true,
        isDialogShow: false
      });
    };
    const onCloseDialogComplete = () => {
      this.setState({
        isDialogCompleteShow: false,
        isDialogShow: false
      });
      this.props.onClose();
    };

    const onShowDialogConfirmImage = () => {
      this.setState({
        isDialogConfirmImage: true,
        isDialogShow: false
      });
    };

    const onCloseDialogConfirmImage = () => {
      this.setState({ isDialogConfirmImage: false, isDialogShow: false });
      this.props.onClose();
    };

    const onCompleteSite = () => {
      if (this.props.isEdit && this.props.siteId !== "") {
        this.props.completeSite(this.props.siteId);
      }
    };
    return (
      <>
        <CommonDialog
          title={isEdit ? "現場編集" : "現場登録"}
          isDialogShow={this.state.isDialogShow}
          initialValues={siteDetail.length === 0 ? defaultValues : siteDetail}
          onSubmit={this.onSubmitForm}
          onClose={this.props.onClose}
          fieldList={fieldList}
          isEdit={isEdit}
          isComplete={isEdit}
          submitButtonTitle={isEdit ? "登録" : "登録"}
          submitErrors={clientError ? clientError : submitErrors}
          onDeleteClick={
            hasLogwalkPhoto ? onShowDialogConfirmImage : onDeleteClick
          }
          onCompleteClick={onCompleteClick}
          isSingleColumn={false}
          deletable={siteDetail?.deletable}
          currentDate={this.currentDate}
          numberRow={1}
        />
        <DialogDelete
          open={this.state.isDialogCompleteShow}
          onClose={onCloseDialogComplete}
          onDeleteSubmit={onCompleteSite}
          isCompleteDialog={true}
          titleSubmit="この現場を完了"
          PaperProps={{
            className: "dialog dialog-sign-up"
          }}
        />

        <DialogDelete
          open={this.state.isDialogConfirmImage}
          onClose={onCloseDialogConfirmImage}
          textTop={"削除しますか？"}
          textBottom={"この現場にはLog Walk写真が存在します。"}
          onDeleteSubmit={onDeleteClick}
          PaperProps={{
            className: "dialog dialog-sign-up"
          }}
        />

        <DialogDelete
          open={this.state.isDialogDeleteShow}
          onClose={onCloseDialogDelete}
          onDeleteSubmit={onDeleteSite}
          PaperProps={{
            className: "dialog dialog-sign-up"
          }}
        />
      </>
    );
  }
}

SiteDialog.propTypes = {
  isDialogShow: PropTypes.bool,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
  siteId: PropTypes.string
};

const mapStateToProps = state => ({
  type: state.rootSitesReducer.sitesReducer.type,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message,
  uniqueCode: getUniqueCode(state.rootSitesReducer.sitesReducer.uniqueCode),
  submitErrors: state.storesReducer.error,
  PIC: getPIC(state.storesReducer.PIC),
  siteDetail: getSitesDetail(state.rootSitesReducer.siteInfoReducer.siteDetail)
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSiteDetail,
      fetchPIC,
      addSite,
      updateSite,
      deleteSite,
      completeSite,
      fillSiteDetail,
      fillSiteErrors,
      clearType,
      translateValidate,
      messageValidate
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SiteDialog));
