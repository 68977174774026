export const messageContainerStyle = () => {

    return {
        alignSelf: "flex-end",
        marginBottom: "16px",
        paddingLeft: "16px",
        paddingRight: "16px",
        clear: "both",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flexShrink: "0",
    }
}

export const messageWrapperStyle = () => {

    return {
        width: "auto",
        flex: "1 1",
        alignSelf: "flex-end",
        display: "flex",
    }
}

export const messageImgWrapper = context => {

    const mq = [...context.theme.breakPoints];

    return {
        display: "inline-block",
        alignSelf: "flex-end",
        maxWidth: "100%",
        height: "200px",
        cursor: "pointer",
        flexShrink: "0",
        img: {
            borderRadius: "8px",
            height: "100%",
            maxWidth: "100%"
        },
        [`@media ${mq[1]}, ${mq[2]}`]: {
            // minWidth: "50px",
            maxWidth: "150px",
            height: "100px",
            padding: "2px 2px",
        },
    };
};

export const messageInfoWrapperStyle = () => {

    return {
        alignSelf: "flex-end",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 8px"
    }
}

export const messageReactionsWrapperStyle = () => {

    return {
        display: "inline-flex",
        alignSelf: "flex-end",
        width: "100%",
        flexWrap: "wrap",
        justifyContent: "flex-end",
    }
}

export const messageTxtWrapperStyle = context => {

    return {
        display: "inline-block",
        borderRadius: "12px",
        backgroundColor: `${context.theme.primaryColor}`,
        color: `${context.theme.color.white}`,
        padding: "8px 16px",
        width: "auto",
    };
};