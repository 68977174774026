import {
  FETCH_CARPENTERS_SUCCESS,
  POST_CARPENTERS_SUCCESS,
  PUT_CARPENTERS_SUCCESS,
  DELETE_CARPENTERS_SUCCESS,
  MOUNT_SUPPLIER_STAFF_PASSWORD_DIALOG_SUCCESS,
  PUT_SUPPLIER_STAFF_PASSWORD_SUCCESS
} from "../../Carpenter/constant/constant";

const initialState = {
  carpenter: []
};
export const carpentersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CARPENTERS_SUCCESS:
      return {
        ...state,
        type: action.type,
        carpenter: action.carpenters
      };

    case POST_CARPENTERS_SUCCESS:
      return {
        ...state,
        type: action.type
      };

    case PUT_CARPENTERS_SUCCESS:
      return {
        ...state,
        type: action.type,
        carpenter: state.carpenter.map(item => {
          let itemNew = item;
          if (item.uniqueCode === state.carpenter.uniqueCode) {
            itemNew = state.carpenter;
          }
          return itemNew;
        })
      };

    case DELETE_CARPENTERS_SUCCESS:
      return {
        ...state,
        type: action.type
      };

    case MOUNT_SUPPLIER_STAFF_PASSWORD_DIALOG_SUCCESS:
      return {
        ...state,
        type: action.type
      };

    case PUT_SUPPLIER_STAFF_PASSWORD_SUCCESS:
      return {
        ...state,
        type: action.type
      };
  
    default:
      return state;
  }
};
export const getCarpenters = state => state;
