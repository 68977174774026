import React, { Component } from "react";
import { Link } from "react-router-dom";
import Checkboxes from "../loginCheckbox.js";

import Grid from "@material-ui/core/Grid";

class LoginUtility extends Component {
  constructor(props) {
    super(props);
    this.checkboxesRef = React.createRef();
  }

  render() {
    return (
      <Grid container alignItems="center">
        <Checkboxes
          ref={this.checkboxesRef}
          isAutoLoginChecked={this.props.isAutoLoginChecked}
          onChange={isAutoLoginChecked => this.props.onChange('isAutoLoginChecked', isAutoLoginChecked)}
        />
        <Grid className="box-forget-password">
          <Link to="/forgot-password" className="txt-forget-passowrd">
            パスワードを忘れましたか？
          </Link>
        </Grid>
      </Grid>
    );
  }
}

export default LoginUtility;
