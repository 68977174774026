import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { api } from "../../../common/api/api";
import emptyImg from "../../../common/img/empty.jpg";

const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
    height: "100%",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    "& img": {
      width: "100%",
      height: "auto",
      position: "absolute",
      left: "0%",
      top: "50%",
      transform: "translate(0%, -50%)"
    }
  },
  fullHeight: {
    width: "100%",
    height: "100%",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    "& img": {
      height: "100%",
      width: "auto",
      position: "absolute",
      left: "50%",
      top: "0%",
      transform: "translate(-50%, 0%)"
    }
  },
  optionNumber: {
    position: "absolute",
    top: "0px",
    right: "0px",
    backgroundColor: "#007CFF",
    color: "white",
    fontSize: "17px",
    padding: "0px 8px"
  }
});

function ViewFileItem(props) {
  const { files, currentFile } = props;

  const [img, setImg] = useState(null);
  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);

  const classes = useStyles();
  useEffect(() => {
    if (currentFile) {
      fetch();
    }
  }, [currentFile]);

  function fetch() {
    var isBlob = currentFile.blob ? true : false;
    const url = isBlob ? currentFile.blob.resized.replace(/^\/api/, '') : currentFile.thumbnail.slice(4);
    api
      .get(url, {
        responseType: isBlob ? undefined : "arraybuffer"
      })
      .then(res => {
        if (res.data === undefined) return Promise.reject();
        if (isBlob) {
          var url = res.data.blobData;
        } else {
          var blob = new Blob([res.data], { type: "image/jpeg" });
          var url = URL.createObjectURL(blob);
        }
        return url;
      })
      .then(url => setImg(renderImg(url)))
      .catch(e => {
        if (e?.response?.status === 404) {
          setImg(renderImg(""));
        }
      });
  }

  function renderImg(data) {
    return (
      <img
        src={data || emptyImg}
        alt=""
        onLoad={e => {
          setWidth(e.currentTarget.naturalWidth);
          setHeight(e.currentTarget.naturalHeight);
        }}
      />
    );
  }

  const renderOptions = () => {
    const allFile = files.length;
    const orderOfCurrentFile = files.findIndex(
      file => file.uniqueCode === currentFile.uniqueCode
    );

    return (
      <div className={classes.optionNumber}>{`${
        orderOfCurrentFile + 1
      }/${allFile}`}</div>
    );
  };

  return (
    <div
      className={
        width / height > 4 / 3 ? classes.fullWidth : classes.fullHeight
      }
    >
      {img}
      {img && renderOptions()}
    </div>
  );
}

export default ViewFileItem;
