import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Formik, Field } from "formik";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import InputControl from "../../common/InputControl";
import SelectControl from "../../common/SelectControl";

import "../../common/css/profile.css";

import { fetchJob } from "../../components/users/Staff/service/service";
import { fetchProfileData, putUserProfile } from "./service/service";
import { PUT_USER_PROFILE_SUCCESS } from "./constant/constant";
import Sp from "../../common/breakpoints/Sp";

class ProfilePage extends React.Component {
  state = {
    defaultValues: {
      name: "",
      furigana: "",
      job: -1,
      phone: "",
      email: ""
    },
    updateSuccess: false,
    submitFlag: false
  };

  componentDidMount() {
    if (!this.props.pathname.includes("logbuild")) {
      this.props.fetchProfileData();
    }
  }

  handleDateChange = (fieldName, formik) => date => {
    formik.setFieldValue(fieldName, date);
  };

  onSubmit = values => {
    if (!this.props.pathname.includes("logbuild")) {
      this.setState({ updateSuccess: false }, () => {
        const profileValues = { ...values };
        if (profileValues.job === -1) profileValues.job = null;
        this.props.putUserProfile(profileValues, () => {
          alert("エラーが発生しました。 お手数ですが、再度お試しください。");
          this.props.history.go(0);
        });
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      !this.state.updateSuccess &&
      nextProps.type === PUT_USER_PROFILE_SUCCESS
    ) {
      this.setState({ updateSuccess: true });
    }
  }

  renderError(fieldName) {
    return (
      <>
        {this.props.submitErrors && this.props.submitErrors[fieldName] && (
          <span className="error">{this.props.submitErrors[fieldName]}</span>
        )}
      </>
    );
  }

  render() {
    const { roles, jobs, profile } = this.props;

    return (
      <Formik
        initialValues={profile ? profile : this.state.defaultValues}
        enableReinitialize
        onSubmit={values => this.onSubmit(values)}
      >
        {formik => (
          <Grid
            className="profile-content"
            tabIndex={1}
            onKeyPress={event => {
              if (event.key === "Enter" && !this.state.submitFlag) {
                formik.handleSubmit(formik.values);
                this.setState({submitFlag:true});
              }
            }}
            
            onClick={()=>{
              this.setState({submitFlag:false});
            }}
          >
            <Sp>
              <Typography
                variant="h5"
                className="font-family text-bold"
                style={{marginTop: -15, marginLeft: -20}}
              >
                プロフィール情報
              </Typography>
            </Sp>
            <Grid container wrap="nowrap">
              <Grid container>
                <Grid container justify="center">
                  <Grid item sm={12} md={6} className="col">
                    <>
                      <Field
                        label={
                          <Typography variant="subtitle1" className="label">
                            氏名
                            <span className="required">[必須]</span>
                          </Typography>
                        }
                        name="name"
                        onChange={formik.handleChange}
                        variant="filled"
                        className="input-reset"
                        component={InputControl}
                      />
                      {this.renderError("name")}
                    </>

                    <>
                      <Field
                        label={
                          <Typography variant="subtitle1" className="label">
                            ふりがな
                            <span className="required">[必須]</span>
                          </Typography>
                        }
                        name="furigana"
                        onChange={formik.handleChange}
                        variant="filled"
                        className="input-reset"
                        component={InputControl}
                      />
                      {this.renderError("furigana")}
                    </>
                    {!this.props.pathname.includes("logbuild") && (
                      <>
                        <Field
                          name="job"
                          title={
                            <Typography variant="subtitle1" className="label">
                              役割
                            </Typography>
                          }
                          onChange={formik.handleChange}
                          variant="filled"
                          className="input-reset reset-select"
                          component={SelectControl}
                        >
                          <MenuItem className="select-item" value={-1}>
                            選択して下さい
                          </MenuItem>

                          {jobs &&
                            jobs.map((item, index) => (
                              <MenuItem
                                className="select-item"
                                value={item.uniqueCode}
                                key={index}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                        </Field>
                        {this.renderError("job")}
                      </>
                    )}

                    <>
                      <Field
                        label={
                          <Typography variant="subtitle1" className="label">
                            個人携帯
                            <span className="required">[必須]</span>
                          </Typography>
                        }
                        name="phone"
                        onChange={formik.handleChange}
                        variant="filled"
                        className="input-reset"
                        component={InputControl}
                      />
                      {this.renderError("phone")}
                    </>

                    <>
                      <Field
                        label={
                          <Typography variant="subtitle1" className="label">
                            メールアドレス
                            <span className="required">[必須]</span>
                          </Typography>
                        }
                        name="email"
                        onChange={formik.handleChange}
                        variant="filled"
                        className="input-reset"
                        component={InputControl}
                      />
                      {this.renderError("email")}
                    </>

                    {this.state.updateSuccess ? (
                      <Grid container className="message-success">
                        保存しました
                      </Grid>
                    ) : null}

                    <Grid className="submit-button">
                      <Button
                        onClick={() => {
                          this.setState({submitFlag:true});
                          formik.handleSubmit();
                          }}
                        color="primary"
                        className="dialog-button"
                        // autoFocus
                        // disableRipple
                        disabled={this.state.submitFlag}
                      >
                        登録
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    );
  }
}

ProfilePage.propTypes = {};

const mapStateToProps = state => ({
  profile: state.profileReducer.profile,
  jobs: state.staffsReducer.job,
  submitErrors: state.storesReducer.error,
  type: state.profileReducer.type
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProfileData,
      putUserProfile,
      fetchJob
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfilePage));
