import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Component from "../../Component";

import {
  showOriginalPhoto,
  postPhoto as postFile
} from "./service/service";

import PropTypes from "prop-types";
import { getPhotos } from "./reducer/reducer";
import {
  dispatchError as fillPhotoError,
  finishLoading
} from "../../../common/Stores/service/service";
import PhotosBuilder from "./PhotosBuilder";
import PhotosBuilderSP from "./PhotosBuilderSP";
import Pc from "../../../common/breakpoints/Pc";
import Sp from "../../../common/breakpoints/Sp";
class Photos extends Component {

  render() {
    const { photos, sitesUC, loadPhotoTab, onHideLoading } = this.props;

    return (
      <>
        <Sp>
          <PhotosBuilderSP
            sitesUC={sitesUC}
            loadPhotoTab={loadPhotoTab}
            onHideLoading={onHideLoading}
          />
        </Sp>
        <Pc>
          <PhotosBuilder
            sitesUC={sitesUC}
            loadPhotoTab={loadPhotoTab}
            onHideLoading={onHideLoading}
          />
        </Pc>
      </>
    );
  }
}

Photos.propTypes = {
  sitesUC: PropTypes.string,
  onHideLoading: PropTypes.func,
  loadPhotoTab: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth,
  photos: getPhotos(state.rootSitesReducer.siteInfoReducer.photos),
  type: state.rootSitesReducer.siteInfoReducer.type,
  loading: state.storesReducer.pending
});

export default connect(mapStateToProps, {
  showOriginalPhoto,
  postFile,
  fillPhotoError,
  finishLoading
})(withRouter(Photos));
