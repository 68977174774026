import {
  FETCH_BUILDER_LIST_SUCCESSS,
  POST_BUILDER_SUCCESS
} from "../constant/constant";

const initialState = {
  builders: []
};

export const builderListReducer = (originState = initialState, action) => {
  const state = { ...originState, type: action.type };
  switch (action.type) {
    case FETCH_BUILDER_LIST_SUCCESSS:
      return {
        ...state,
        builders: action.builders
      };
    case POST_BUILDER_SUCCESS:
      return {
        ...state,
        uniqueCode: action.data.uniqueCode
      };

    default:
      return state;
  }
};

export const getBuilders = state => state.builderReducer.builders;
