import React from "react";
import PropTypes from "prop-types";

function IconColor(props) {
  const { color, width, height } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
    >
      <g
        fill="#fff"
        stroke={color}
        strokeWidth="2"
        transform="translate(-2193 -348) translate(2193 348)"
      >
        <circle cx="20" cy="20" r="20" stroke="none"></circle>
        <circle cx="20" cy="20" r="19" fill="none"></circle>
      </g>
      <path
        fill={color}
        d="M16 0a16 16 0 1016 16A16 16 0 0016 0zm3.33 24.8q-1.235.488-1.971.742a5.2 5.2 0 01-1.709.256 3.407 3.407 0 01-2.326-.73 2.356 2.356 0 01-.828-1.852 6.726 6.726 0 01.061-.893c.042-.3.108-.645.2-1.028l1.031-3.641c.091-.349.169-.681.232-.99a4.39 4.39 0 00.092-.857 1.32 1.32 0 00-.287-.971 1.631 1.631 0 00-1.1-.272 2.89 2.89 0 00-.819.122c-.278.085-.519.163-.717.238l.272-1.122q1.012-.412 1.937-.706a5.722 5.722 0 011.747-.3 3.343 3.343 0 012.292.718 2.385 2.385 0 01.8 1.864q0 .238-.056.836a5.592 5.592 0 01-.206 1.1l-1.025 3.63c-.084.291-.158.624-.226 1a5.271 5.271 0 00-.1.848 1.218 1.218 0 00.324.986 1.812 1.812 0 001.12.263 3.249 3.249 0 00.848-.131 4.821 4.821 0 00.685-.23zm-.182-14.734a2.447 2.447 0 01-1.727.666 2.473 2.473 0 01-1.734-.666 2.127 2.127 0 01-.722-1.616 2.149 2.149 0 01.722-1.62 2.455 2.455 0 011.734-.673 2.427 2.427 0 011.727.673 2.182 2.182 0 010 3.236z"
        transform="translate(-2193 -348) translate(2197 352)"
      ></path>
    </svg>
  );
}

IconColor.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconColor.defaultProps = {
  color: "#06f",
  width: "40",
  height: "40",
};

export default IconColor;
