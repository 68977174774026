import React from "react";
import { Formik, Field } from "formik";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { format } from "date-fns";

import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import UploadIcon from "@material-ui/icons/CloudUpload";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Component from "./../Upload";
import Photo from "./../Photo";
import { getPhotos } from "./../reducer/reducer";
import {
  fetchPhotosSP,
  postPhotoSP as postFile,
  downloadPhotoSP,
  updateSitePhotoSp,
  showOriginalPhotoSP
} from "./../service/service";
import InputControl from "../../../../common/InputControl";
import {
  finishLoading,
  dispatchError as fillPhotoError
} from "../../../../common/Stores/service/service";
import {
  GET_PHOTOS_SP_SUCCESS,
  PUT_SITE_PHOTO_SP_SUCCESS
} from "../constant/constant";
import LazyLoad from "react-lazyload";
import { Swipeable } from "react-swipeable";
import { api } from "../../../../common/api/api";
import axios from "axios";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import {
  translateValidate,
  messageValidate
} from "../../../../common/Stores/service/service";

const RIGHT = "-1";
const LEFT = "+1";

class PhotosSP extends Component {
  ajaxRequest = axios.CancelToken.source();
  state = {
    isEditDialog: false,
    currentPhoto: {},
    anchorEl: null,
    isDialogShow: false,
    initialValues: {
      name: ""
    },
    onSwiping: false,
    typeReload: [PUT_SITE_PHOTO_SP_SUCCESS]
  };

  fetch() {
    this.props.fetchPhotosSP(this.sitesUC);
  }

  componentDidMount() {
    this.props.translateValidate();
    this.props.messageValidate();
  }

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);

    if (this.props.match.params.id !== nextProps.match.params.id) return;

    const oldList = this.props.photosSP;
    const newList = nextProps.photosSP;

    if (
      oldList === undefined ||
      oldList.length === 0 ||
      newList === undefined ||
      newList.length === 0
    )
      return;

    for (let i = 0; i < oldList.length; i++) {
      const newDate = newList.find(date => date.date === oldList[i].date);
      if (newDate === undefined) continue;
      const oldPhotos = oldList[i].photos;
      for (let j = 0; j < oldPhotos.length; j++) {
        const temp = oldPhotos[j];
        const newPhoto = newDate.photos.find(
          photo => photo.photoId === temp.photoId
        );
        if (newPhoto === undefined) continue;
        newPhoto.url = temp.url;
      }
    }
  }

  componentDidUpdate() {
    const { photosSP, loading, finishLoading, type } = this.props;

    if (type !== GET_PHOTOS_SP_SUCCESS) {
      return;
    }

    if (photosSP === undefined || photosSP.length === 0) {
      if (loading) finishLoading();
    }
  }

  onGetUrlPhotoSuccess = (photoId, name, url) => {
    const { photosSP, loading, finishLoading } = this.props;
    const size = photosSP.length;
    for (let i = 0; i < size; i++) {
      const pt = photosSP[i].photos.find(photo => photo.photoId === photoId);

      if (pt !== undefined) {
        pt.url = url;
        pt.name = name;
        break;
      }
    }

    for (let i = 0; i < photosSP.length; i++) {
      if (
        photosSP[i].photos.filter(p => p.url === undefined || p.base64 === null)
          .length > 0
      ) {
        this.shoudHideLoading = false;
        break;
      } else {
        this.shoudHideLoading = true;
      }
    }

    if (this.shoudHideLoading) {
      this.shoudHideLoading = false;
      if (loading) {
        finishLoading();
      }
    }
  };

  onImageClick = (photoId, blob, name, url, extension) => {
    this.setState({
      currentPhoto: {
        photoId: photoId,
        blob: blob,
        name: name,
        url: url,
        extension: extension
      },
      initialValues: {
        name: name
      }
    });
    this.toggleDialogImage();
  };

  toggleDialogImage = () => {
    this.setState({
      isEditDialog: !this.state.isEditDialog
    });
  };

  toggleDialogEdit = () => {
    this.props.fillPhotoError("");
    this.setState({
      isDialogShow: !this.state.isDialogShow,
      anchorEl: null
    });
  };

  closeDialog = () => {
    if (this.state.isDialogShow) {
      this.toggleDialogEdit();
      this.toggleDialogImage();
    }
  };

  showPopover = e => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  isChanged(nextProps) {
    const nextType = nextProps.type;
    const { typeReload } = this.state;
    return (
      this.props.sitesUC !== nextProps.sitesUC ||
      (this.props.type !== nextType && typeReload.includes(nextType))
    );
  }

  closePopover = e => {
    this.setState({
      anchorEl: null
    });
  };

  onSwiped(direction) {
    const change = direction === RIGHT;
    this.handleChangeImage(!change);
    setTimeout(() => {
      this.setState({
        onSwiping: false
      });
    }, 1);
  }

  onSwiping = () => {
    this.setState({
      onSwiping: true
    });
  };

  handleChangeImage = isNext => {
    this.setState({ disalbeNextBack: true });
    const { photosSP } = this.props;
    const { currentPhoto } = this.state;
    const size = photosSP.length;
    for (let i = 0; i < size; i++) {
      const currentIndex = photosSP[i].photos.findIndex(
        photo => photo.photoId === currentPhoto.photoId
      );
      if (currentIndex !== -1) {
        const ptSize = photosSP[i].photos.length;

        let newIndex = 0;
        let dateIndex = i;
        if (isNext) {
          newIndex = currentIndex + 1;
          if (newIndex === ptSize) {
            dateIndex = dateIndex + 1 >= size ? 0 : dateIndex + 1;
            newIndex = 0;
          }
        } else {
          newIndex = currentIndex - 1;
          if (newIndex < 0) {
            dateIndex = dateIndex - 1 < 0 ? size - 1 : dateIndex - 1;
            newIndex = photosSP[dateIndex].photos.length - 1;
          }
        }

        const newPhoto = photosSP[dateIndex].photos[newIndex];

        const newDate = photosSP[dateIndex].date;
        if (newPhoto === undefined) {
          this.setState({ disalbeNextBack: false });
          break;
        }

        if (newPhoto.url === undefined) {
          this.fetchOnDialog(newPhoto.photoId, newPhoto.name, newDate, newPhoto.blob);
        } else {
          this.setState(
            {
              currentPhoto: {
                photoId: newPhoto.photoId,
                blob: newPhoto.blob,
                url: newPhoto.url,
                name: newPhoto.name
              },
              initialValuesEdit: { name: newPhoto.name, regisionDate: newDate }
            },
            () => {
              this.setState({ disalbeNextBack: false });
            }
          );
        }
        break;
      }
    }
  };

  fetchOnDialog(id, name, newDate, blobData) {
    var isBlob = blobData ? true : false;
    api
      .get(
        isBlob
          ? blobData.resized.replace("/api", "")
          : "/builder/site-photos/" + id + "/thumbnail",
        {
          responseType: isBlob ? undefined : "arraybuffer",
          cancelToken: this.ajaxRequest.token
        }
      )
      .then(res => {
        if (res.data === undefined) return Promise.reject();
        var url = "";
        if (res.data.blobData) {
          url = res.data.blobData;
        } else {
          var blob = new Blob([res.data], { type: "image/jpeg" });
          url = URL.createObjectURL(blob);
        }

        this.setState(
          {
            currentPhoto: {
              photoId: id,
              url: url,
              blob: blobData,
              name: name
            },
            initialValuesEdit: { name: name, regisionDate: newDate }
          },
          () => {
            this.setState({ disalbeNextBack: false });
          }
        );

        ReactDOM.render(
          <Grid className="div-photo">
            <img
              src={url}
              alt={id}
              onClick={() => this.onImageClick(id, blob, name, url, newDate)}
            ></img>
          </Grid>,
          document.querySelector(`span[photoid="${id}"]`)
        );
      })
      .catch(e => {
        if (e?.response?.status === 404) {
          this.setState({ url: "" });
        }
      });
  }

  downloadPhoto = () => {
    const { currentPhoto } = this.state;
    this.props.downloadPhotoSP(
      currentPhoto.photoId,
      currentPhoto.blob,
      currentPhoto.name,
      currentPhoto.extension
    );
    this.closePopover();
  };

  showOriginalPhotoSP = currentPhoto => {
    if (!this.state.onSwiping) {
      this.props.showOriginalPhotoSP(currentPhoto.photoId, currentPhoto.blob);
    }
  };

  onSubmit = values => {
    const { currentPhoto } = this.state;
    values.sitesUC = this.props.match.params.id;
    this.props.updateSitePhotoSp(values, currentPhoto.photoId);
  };

  render() {
    const { photosSP } = this.props;

    const {
      isEditDialog,
      currentPhoto,
      anchorEl,
      isDialogShow,
      initialValues,
      disalbeNextBack
    } = this.state;

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <>
        <input
          type="file"
          id="file"
          accept=".jpg,.jpeg,.png"
          multiple
          onChange={e => this.upload(e)}
          style={{ display: "none" }}
        />
        <Grid className="image-wrap-sp">
          {photosSP.map(datePhotos => (
            <Grid className="image-item" key={datePhotos.date}>
              <Typography className="image-cap-sp" variant="body1">
                {format(new Date(datePhotos.date), "yyyy年M月d日")}
              </Typography>
              {datePhotos.photos.map(photo => (
                <Grid
                  className="cursor-pointer"
                  key={photo.photoId}
                  component="span"
                >
                  <LazyLoad
                    offset={200}
                    placeholder={<div className="placeholder-image-sp"></div>}
                  >
                    <Grid className="div-photo-sp">
                      <Photo
                        name={photo.name}
                        alt={photo.photoId}
                        PhotosUC={photo.photoId}
                        blob={photo.blob}
                        onClick={() =>
                          this.onImageClick(
                            photo.photoId,
                            photo.blob,
                            photo.name,
                            photo.url,
                            photo.extension
                          )
                        }
                        onGetSuccess={this.onGetUrlPhotoSuccess}
                      />
                    </Grid>
                  </LazyLoad>
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
        <Fab className="button-sp button-upload-sp">
          <label htmlFor="file" style={{ color: "inherit" }}>
            <UploadIcon className="white-text icon" style={{ width: "26px", height: "26px" }}/>
          </label>
        </Fab>
        <Dialog
          open={isEditDialog}
          aria-labelledby="max-width-dialog-title"
          PaperProps={{
            className: "dialog image-dialog-edit"
          }}
        >
          <DialogTitle disableTypography className="header-dialog">
            <Fab onClick={this.toggleDialogImage} size="small">
              <ArrowBackIosIcon />
            </Fab>
            <Typography variant="body1">{currentPhoto.name}</Typography>
            <Fab onClick={this.showPopover} size="small">
              <MoreVertIcon />
            </Fab>
          </DialogTitle>
          <DialogContent className="content-dialog">
            <Swipeable
              trackMouse
              preventDefaultTouchmoveEvent
              onSwipedLeft={() => this.onSwiped(LEFT)}
              onSwipedRight={() => this.onSwiped(RIGHT)}
              onSwiping={this.onSwiping}
            >
              <img
                src={currentPhoto.url}
                alt={currentPhoto.photoId}
                draggable={false}
                onClick={() => this.showOriginalPhotoSP(currentPhoto)}
              />
              <Fab
                className="white-text reset-button-image button-prev-dialog"
                disabled={disalbeNextBack}
                onClick={() => this.handleChangeImage(false)}
              >
                <FontAwesomeIcon
                  size="3x"
                  icon={faChevronLeft}
                  className="white-text"
                />
              </Fab>
              <Fab
                className="white-text reset-button-image button-next-dialog"
                disabled={disalbeNextBack}
                onClick={() => this.handleChangeImage(true)}
              >
                <FontAwesomeIcon
                  size="3x"
                  icon={faChevronRight}
                  className="white-text"
                />
              </Fab>
            </Swipeable>
          </DialogContent>
        </Dialog>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.closePopover}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuList className="popover-sites">
            <MenuItem onClick={this.toggleDialogEdit}>編集</MenuItem>
            <MenuItem onClick={this.downloadPhoto}>ダウンロード</MenuItem>
          </MenuList>
        </Popover>
        {isDialogShow && (
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={values => this.onSubmit(values)}
          >
            {formik => (
              <Dialog
                open={isDialogShow}
                aria-labelledby="max-width-dialog-title"
                maxWidth="xs"
                PaperProps={{
                  className: "dialog dialog-sign-up w100"
                }}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    formik.handleSubmit();
                  }
                }}
              >
                <DialogContent>
                  <Field
                    label={
                      <Typography variant="subtitle1" className="label">
                        ファイル名
                        <span className="required">[必須]</span>
                      </Typography>
                    }
                    name="name"
                    onChange={formik.handleChange}
                    variant="filled"
                    className="input-reset"
                    component={InputControl}
                  />
                  {this.props.submitErrors &&
                    this.props.submitErrors["name"] && (
                      <span className="error">
                        {this.props.submitErrors["name"]}
                      </span>
                    )}
                </DialogContent>
                <DialogActions className="dialog-action wrap dialog-action-sp">
                  <Button
                    onClick={formik.handleSubmit}
                    size="large"
                    className="dialog-button"
                  >
                    登録
                  </Button>
                  <Grid className="confirm-action">
                    <Button onClick={this.toggleDialogEdit}>キャンセル​</Button>
                  </Grid>
                </DialogActions>
              </Dialog>
            )}
          </Formik>
        )}
      </>
    );
  }
}

PhotosSP.propTypes = {
  sitesUC: PropTypes.string
};

const mapStateToProps = state => ({
  auth: state.auth,
  photosSP: getPhotos(state.rootSitesReducer.siteInfoReducer.photosSP),
  loading: state.storesReducer.pending,
  type: state.rootSitesReducer.siteInfoReducer.type,
  submitErrors: state.storesReducer.error,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPhotosSP: fetchPhotosSP,
      finishLoading,
      postFile,
      downloadPhotoSP,
      updateSitePhotoSp,
      fillPhotoError,
      showOriginalPhotoSP,
      translateValidate,
      messageValidate
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PhotosSP));
