import {
  FETCH_CARPENTERS_SUCCESS,
  POST_CARPENTERS_SUCCESS,
  GET_LIST_DETAIL_SUCCESS,
  PUT_CARPENTERS_SUCCESS,
  DELETE_CARPENTERS_SUCCESS,
  MOUNT_SUPPLIER_STAFF_PASSWORD_DIALOG_SUCCESS,
  PUT_SUPPLIER_STAFF_PASSWORD_SUCCESS
} from "../../Carpenter/constant/constant";

export const fetchCarpentersSuccess = carpenters => {
  return {
    type: FETCH_CARPENTERS_SUCCESS,
    carpenters: carpenters
  };
};

export const postCarpentersSuccess = () => {
  return {
    type: POST_CARPENTERS_SUCCESS
  };
};

export const getCarpentersDetailSuccess = carpenterDetail => {
  return {
    type: GET_LIST_DETAIL_SUCCESS,
    carpenterDetail: carpenterDetail
  };
};

export const putCarpentersSuccess = () => {
  return {
    type: PUT_CARPENTERS_SUCCESS
  };
};

export const deleteCarpentersSuccess = () => {
  return {
    type: DELETE_CARPENTERS_SUCCESS
  };
};

export const mountSupplierStaffPasswordDialogSuccess = () => {
  return {
    type: MOUNT_SUPPLIER_STAFF_PASSWORD_DIALOG_SUCCESS
  };
};

export const putSupplierStaffPasswordSuccess = () => {
  return {
    type: PUT_SUPPLIER_STAFF_PASSWORD_SUCCESS
  };
};
