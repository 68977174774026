import React, { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import PDFJS from "pdfjs-dist/webpack";

import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ArrowBack from "../hotspotInfoIcons/ArrowBack";

import EditFileName from "./../viewModeComponents/EditFileName";
import ChooseExistedFileDialog from "../../ChooseExistedFileDialog";
import MenuUploadFile from "../../ChooseExistedFileDialog/MenuUploadFile/MenuUploadFile";
import ViewThumbnail from "./ViewThumbnail";

import IconPlusUploadFile from "../hotspotInfoIcons/IconPlusUploadFile";
import SvgButtonHover from "../viewModeComponents/SvgButtonHover";
import IconTrashCan from "../hotspotInfoIcons/IconTrashCan";
import IconFullImage from "../hotspotInfoIcons/IconFullImage";
import IconUploadFile from "../hotspotInfoIcons/IconUploadFile";
import nextImg from "../common/img/next_img.svg";
import { isMobile } from "react-device-detect";
import { api } from "../../../../../../common/api/api";

const useStyles = makeStyles({
  uploadFileMode: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    border: "2px dashed #C7C7C7",
    padding: "24px 0px",
    borderRadius: "8px",
    marginBottom: "20px",
    "& p": {
      fontSize: "17px",
      fontWeight: "normal",
      lineHeight: "23px",
      color: "#C7C7C7",
      textAlign: "center"
    },
    "& span": {
      width: "60px",
      height: "60px",
      margin: "36px auto"
    },
    "& button": {
      width: "220px",
      height: "45px",
      fontSize: "17px",
      lineHeight: "45px",
      textAlign: "center",
      color: "white",
      borderRadius: "4px",
      backgroundColor: "#00356D",
      margin: "0px auto"
    }
  },
  backToListView: {
    width: "100%",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    backgroundColor: "#C7C7C7",
    fontSize: "17px",
    fontWeight: "bold",
    margin: "20px 0px"
  },
  viewFileWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  viewFileActions: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0px"
  },
  dialogTitle: {
    textAlign: "center"
  }
});
function FileInputPreview(props) {
  const { files, setFiles, sitesUC } = props;

  const [currentShowFile, setCurrentShowFile] = useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [isShowModeUpload, setIsShowModeUpload] = useState(false);
  const [showMenuUploadFile, setShowMenuUploadFile] = useState(false);
  const [openChooseExistFileDialog, setOpenChooseExistFileDialog] =
    useState(false);

  const classes = useStyles();
  const fileRef = useRef(null);
  const fileCameraRef = useRef(null);
  let location = useLocation();
  let history = useHistory();
  useEffect(() => {
    if (files.length === 0) {
      setIsShowModeUpload(true);
      setCurrentShowFile(null);
    } else {
      setIsShowModeUpload(false);
      setCurrentShowFile(files[0]);
      console.log(files[0]);
    }
  }, [files]);

  const handleUploadFiles = rawFiles => {
    const exts = ".pdf,.jpg,.jpeg,.png"
      .split(",")
      .map(ext => ext.toLowerCase());
    let containsInvalidMimeType = false;
    for (let i = 0; i < rawFiles.length; i++) {
      let file = rawFiles[i];
      containsInvalidMimeType = !exts.some(ext =>
        file.name.toLowerCase().endsWith(ext)
      );
      if (containsInvalidMimeType) {
        break;
      }
    }
    if (containsInvalidMimeType) {
      alert("無効なファイル形式を含んでいます");
      return;
    }

    let getBase64AllFile = [];
    for (let i = 0; i < rawFiles.length; i++) {
      getBase64AllFile.push(getBase64(rawFiles[i]));
    }

    Promise.all(getBase64AllFile).then(data => {
      let filterFilePhase1 = [];
      data.forEach(base64Info => {
        if (
          filterFilePhase1.findIndex(
            item => base64Info.fileBase64 === item.fileBase64
          ) === -1
        ) {
          filterFilePhase1.push(base64Info);
        }
      });

      const filterFilePhase2 = filterFilePhase1.filter(base64Info => {
        return (
          files.findIndex(file => file.fileBase64 === base64Info.fileBase64) ===
          -1
        );
      });
      if (filterFilePhase2.length > 0) {
        setFiles(filterFilePhase2.concat(files));
        setShowMenuUploadFile(false);
      }
    });
  };

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.name.split(".").pop() === "pdf") {
          PDFJS.getDocument(reader.result).promise.then(pdf => {
            getFirstPage(pdf).then(contentFirstPage => {
              resolve({
                isUploadFromDevice: true,
                name: file.name.substr(0, file.name.lastIndexOf(".")),
                fileBase64: reader.result.slice(reader.result.indexOf(",") + 1),
                thumbnail: contentFirstPage,
                fullFile: URL.createObjectURL(file),
                createdAt: Date.now()
              });
            });
          });
        } else {
          resolve({
            isUploadFromDevice: true,
            name: file.name.substr(0, file.name.lastIndexOf(".")),
            fileBase64: reader.result.slice(reader.result.indexOf(",") + 1),
            thumbnail: URL.createObjectURL(file),
            fullFile: URL.createObjectURL(file),
            createdAt: Date.now()
          });
        }
      };

      reader.onerror = error => reject(error);
    });
  };

  const getFirstPage = pdf => {
    return new Promise((resolve, reject) => {
      pdf.getPage(1).then(page => {
        const scale = 1.5;
        const viewport = page.getViewport(scale);
        const canvas = document.createElement("canvas");
        const canvasContext = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        page
          .render({
            canvasContext,
            viewport
          })
          .promise.then(() => {
            resolve(canvas.toDataURL("image/jpeg"));
          });
      });
    });
  };

  const handleDeleteFile = () => {
    if (currentShowFile) {
      if (currentShowFile.fileBase64) {
        setFiles(
          files.filter(file => file.fileBase64 !== currentShowFile.fileBase64)
        );
      } else if (currentShowFile.photoId) {
        setFiles(
          files.filter(file => file.photoId !== currentShowFile.photoId)
        );
      } else {
        setFiles(files.filter(file => file.fileId !== currentShowFile.fileId));
      }
      setOpenConfirmDelete(false);
    }
  };

  const nextFile = () => {
    const currentIndex = files.findIndex(
      file =>
        (file.fileBase64 && currentShowFile.fileBase64 === file.fileBase64) ||
        (file.fileId && currentShowFile.fileId === file.fileId) ||
        (file.photoId && currentShowFile.photoId === file.photoId)
    );

    if (currentIndex === files.length - 1) return;
    setCurrentShowFile(files[currentIndex + 1]);
  };

  const previousFile = () => {
    const currentIndex = files.findIndex(
      file =>
        (file.fileBase64 && currentShowFile.fileBase64 === file.fileBase64) ||
        (file.fileId && currentShowFile.fileId === file.fileId) ||
        (file.photoId && currentShowFile.photoId === file.photoId)
    );

    if (currentIndex === 0) return;
    setCurrentShowFile(files[currentIndex - 1]);
  };

  const showFullFile = () => {
    if (currentShowFile.isUploadFromDevice) {
      window.open(currentShowFile.fullFile, "_blank");
    } else {
      const classification = location.pathname.includes("/sp/")
        ? "supplier"
        : "builder";
      var isBlob = currentShowFile.blob ? true : false;
      api
        .get(
          isBlob ? currentShowFile.blob.org.replace(/^\/api/, '') : 
          `/${classification}/site-${currentShowFile.fileId ? "files" : "photos"}/${
            currentShowFile.fileId || currentShowFile.photoId
          }/download`,
          {
            responseType: isBlob ? undefined : "arraybuffer"
          }
        )
        .then(res => {
          const type =
            currentShowFile.extension === "pdf"
              ? "application/pdf"
              : "image/jpeg";
          if (isBlob) {
            var url = res.data.blobData;
            if (currentShowFile.extension === "pdf") {
              window.open(url, "_blank");
            } else {
              var w = window.open("", "_blank");
              w.document.write(`<img src="${url}">`)
            }
          } else {
            var blob = new Blob([res.data], { type: type });
            var url = URL.createObjectURL(blob);
            window.open(url, "_blank");
          }
        })
        .catch(() => {
          alert("エラーが発生しました。 お手数ですが、再度お試しください。");
          history.go(0);
        });
    }
  };

  const handleSaveName = fileName => {
    const indexOfFile = files.findIndex(
      file =>
        (file.fileBase64 && file.fileBase64 === currentShowFile.fileBase64) ||
        (file.photoId && file.photoId === currentShowFile.photoId) ||
        (file.fileId && file.fileId === currentShowFile.fileId)
    );

    if (indexOfFile > -1) {
      setFiles([
        ...files.slice(0, indexOfFile),
        {
          ...files[indexOfFile],
          name: fileName
        },
        ...files.slice(indexOfFile + 1)
      ]);
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    handleUploadFiles(e.dataTransfer.files);
  };

  const renderViewFileThumbnail = () => {
    const orderOfCurrentFile = files.findIndex(
      file =>
        (file.fileBase64 && currentShowFile.fileBase64 === file.fileBase64) ||
        (file.fileId && currentShowFile.fileId === file.fileId) ||
        (file.photoId && currentShowFile.photoId === file.photoId)
    );
    const allFile = files.length;

    return (
      <div style={{ position: "relative" }}>
        {currentShowFile.isUploadFromDevice ? (
          <img
            src={currentShowFile.thumbnail}
            style={{ width: "100%", height: "auto" }}
            alt=""
          />
        ) : (
          <ViewThumbnail
            id={currentShowFile.photoId || currentShowFile.fileId}
            blob={currentShowFile.blob}
            type={currentShowFile.fileId ? "file" : "photo"}
          />
        )}

        <div
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            backgroundColor: "#007CFF",
            color: "white",
            fontSize: "17px",
            padding: "0px 12px"
          }}
        >{`${orderOfCurrentFile + 1} / ${allFile}`}</div>

        {orderOfCurrentFile !== 0 && (
          <img
            onClick={() => previousFile()}
            style={{
              position: "absolute",
              width: "30px",
              height: "100px",
              top: "50%",
              left: "10px",
              marginTop: "-50px",
              transform: "rotate(180deg)",
              cursor: "pointer"
            }}
            src={nextImg}
            alt=""
          ></img>
        )}

        {orderOfCurrentFile + 1 !== allFile && (
          <img
            onClick={() => nextFile()}
            style={{
              position: "absolute",
              width: "30px",
              height: "100px",
              top: "50%",
              right: "10px",
              marginTop: "-50px",
              cursor: "pointer"
            }}
            src={nextImg}
            alt=""
          ></img>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      {isShowModeUpload ? (
        <React.Fragment>
          <p
            style={{
              textAlign: "center",
              fontSize: "17px",
              color: "#333333",
              margin: "30px 0px"
            }}
          >
            ファイル（画像 or PDF）を追加
          </p>
          <div
            className={classes.uploadFileMode}
            onDrop={handleDrop}
            onDragEnter={e => e.preventDefault()}
            onDragOver={e => e.preventDefault()}
            onDragLeave={e => e.preventDefault()}
          >
            <p>ドラッグ&ドロップまたは</p>
            <span>
              <IconPlusUploadFile />
            </span>
            {isMobile && (
              <button
                onClick={() => {
                  fileCameraRef.current.accept = "image/*";
                  fileCameraRef.current.click();
                }}
                style={{ touchAction: "manipulation", marginBottom: "20px" }}
              >
                写真を撮る
              </button>
            )}
            <button
              onClick={() => {
                setShowMenuUploadFile(true);
              }}
              style={{ touchAction: "manipulation" }}
            >
              ファイルを追加
            </button>
          </div>
          {files.length > 0 && (
            <button
              className={classes.backToListView}
              style={{ backgroundColor: "#C7C7C7" }}
              onClick={() => setIsShowModeUpload(false)}
            >
              <span
                style={{ width: "45px", height: "45px", overflow: "hidden" }}
              >
                <ArrowBack />
              </span>
              <span>戻る</span>
            </button>
          )}
        </React.Fragment>
      ) : (
        <div className={classes.viewFileWrapper}>
          {currentShowFile && (
            <React.Fragment>
              <EditFileName
                currentFile={currentShowFile}
                handleSaveName={handleSaveName}
              />

              {renderViewFileThumbnail()}

              <div className={classes.viewFileActions}>
                <SvgButtonHover
                  Icon={IconFullImage}
                  title="拡大表示"
                  handleClick={showFullFile}
                  activeBg="#00356D"
                />

                <SvgButtonHover
                  Icon={IconUploadFile}
                  title="アップロード"
                  handleClick={() => setIsShowModeUpload(true)}
                  activeBg="#00356D"
                  withBorder
                />

                <SvgButtonHover
                  Icon={IconTrashCan}
                  title="削除"
                  handleClick={() => setOpenConfirmDelete(true)}
                  activeBg="red"
                />
              </div>
            </React.Fragment>
          )}
        </div>
      )}

      {showMenuUploadFile && (
        <MenuUploadFile
          uploadNewFile={() => {
            fileRef.current.accept = ".pdf,.jpg,.jpeg,.png";
            fileRef.current.click();
          }}
          chooseExistedFile={() => setOpenChooseExistFileDialog(true)}
          closeMenu={() => setShowMenuUploadFile(false)}
        />
      )}

      <Dialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ backgroundColor: "#e0e0e0eb" }}
        className={classes.dialogDelete}
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          {"削除しますか?"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleDeleteFile}
            className="btn-dialog-yes"
            style={{ color: "#FFFFFF", backgroundColor: "#00356D" }}
          >
            Yes
          </Button>
          <Button
            onClick={() => setOpenConfirmDelete(false)}
            className="btn-dialog-no"
            autoFocus
            style={{ color: "#FFFFFF", backgroundColor: "#C7C7C7" }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      <ChooseExistedFileDialog
        mode="create-info"
        isOpen={openChooseExistFileDialog}
        onClose={() => setOpenChooseExistFileDialog(false)}
        sitesUC={sitesUC}
        excludeSelects={files.filter(file => !file.isUploadFromDevice)}
        addToTemporary={selectedFiles => {
          setFiles(
            selectedFiles.reverse()
              .map(item => {
                return { ...item, createdAt: Date.now() };
              })
              .concat(files)
          );
          setShowMenuUploadFile(false);
        }}
      />

      <input
        type="file"
        id="file"
        accept=".pdf,.jpg,.jpeg,.png"
        onChange={e => handleUploadFiles(e.target.files)}
        onClick={e => (e.target.value = null)}
        style={{ display: "none" }}
        ref={fileRef}
        multiple
      />
      <input
        type="file"
        accept="image/*"
        onChange={e => handleUploadFiles(e.target.files)}
        onClick={e => (e.target.value = null)}
        style={{ display: "none" }}
        ref={fileCameraRef}
        capture
        multiple
      />
    </React.Fragment>
  );
}

FileInputPreview.propTypes = {};

export default FileInputPreview;
