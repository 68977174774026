export const FETCH_STORES_PENDING = "FETCH_STORES_PENDING";
export const FETCH_STORES_SUCCESS = "FETCH_STORES_SUCCESS";
export const FETCH_STORES_ERROR = "FETCH_STORES_ERROR";
export const FETCH_PIC_SUCCESS = "FETCH_PIC_SUCCESS";
export const FETCH_PIC_ERROR = "FETCH_PIC_ERROR";
export const AXIOS_ERROR = "AXIOS_ERROR";
export const CLEAR_AXIOS_ERROR = "CLEAR_AXIOS_ERROR";
export const FETCH_BUILDER_NAME = "FETCH_BUILDER_NAME";
export const FETCH_BUILDER_FLAG = "FETCH_BUILDER_FLAG";
export const ENABLE_LOADING = "ENABLE_LOADING";
export const DISABLE_LOADING = "DISABLE_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";
export const API_ERROR = "API_ERROR";
export const POST_USER_ACKNOWLEDGEMENT_SUCCESS =
  "POST_USER_ACKNOWLEDGEMENT_SUCCESS";
export const FETCH_BUILDER_JOB_SUCCESS = "FETCH_BUILDER_JOB_SUCCESS";
export const FETCH_REGEX_VALIDATE_SUCCESSS = "FETCH_REGEX_VALIDATE_SUCCESSS";
export const FETCH_MESSAGE_VALIDATE_SUCCESSS =
  "FETCH_MESSAGE_VALIDATE_SUCCESSS";
export const CLOSE_PASSWORD_DIALOG_SUCCESS = "CLOSE_PASSWORD_DIALOG_SUCCESS";
export const PUT_PASSWORD_SP_SUCCESSS = "PUT_PASSWORD_SP_SUCCESSS";
export const CHECK_AUTH = "CHECK_AUTH";
export const DISABLE_CHECK_AUTH= "DISABLE_CHECK_AUTH";
export const SHOW_LOG_MEET_HISTORY= "SHOW_LOG_MEET_HISTORY";
export const DIALOG_FIELD_TYPES = {
  Text: "Text",
  DateTime: "DateTime",
  DropDownBox: "DropDownBox",
  CheckBox: "CheckBox",
  Password: "Password",
  Image: "Image"
};
