import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, keyframes } from "@emotion/core";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";
import { ResizeSensor } from "css-element-queries";
import MenuUploadFile from "components/sites/site/LogWalkViewer/ChooseExistedFileDialog/MenuUploadFile/MenuUploadFile";
import ChooseExistedFileDialog from "components/sites/site/LogWalkViewer/ChooseExistedFileDialog";
import { api } from "../../../../../../common/api/api";
import axios from "axios";
import "emoji-mart/css/emoji-mart.css";

import {
  CometChatSmartReplyPreview,
  CometChatCreatePoll,
  CometChatStickerKeyboard
} from "../Extensions";
import { CometChatEmojiKeyboard } from "../";

import { CometChatContext } from "../../../util/CometChatContext";
import {
  checkMessageForExtensionsData,
  ID,
  getUnixTimestamp
} from "../../../util/common";
import * as enums from "../../../util/enums.js";
import { SoundManager } from "../../../util/SoundManager";

import { theme } from "../../../resources/theme";
import Translator from "../../../resources/localization/translator";

import {
  chatComposerStyle,
  editPreviewContainerStyle,
  previewHeadingStyle,
  previewCloseStyle,
  previewTextStyle,
  composerInputStyle,
  inputInnerStyle,
  messageInputStyle,
  inputStickyStyle,
  stickyAttachmentStyle,
  filePickerStyle,
  fileListStyle,
  fileItemStyle,
  stickyAttachButtonStyle,
  stickyButtonStyle,
  emojiButtonStyle,
  sendButtonStyle,
  reactionBtnStyle,
  stickerBtnStyle
} from "./style";

import roundedPlus from "./resources/add-circle-filled.svg";
import videoIcon from "./resources/video.svg";
import docIcon from "./resources/file-upload.svg";
import insertEmoticon from "./resources/emoji.svg";
import sendBlue from "./resources/send-message.svg";
import pollIcon from "./resources/polls.svg";
import stickerIcon from "./resources/stickers.svg";
import closeIcon from "./resources/close.svg";
import documentIcon from "./resources/collaborative-document.svg";
import whiteboardIcon from "./resources/collaborative-whiteboard.svg";
import heartIcon from "./resources/heart.png";
import AddIcon from "@material-ui/icons/Add";
import SendIcon from "@material-ui/icons/Send";
import cameraIcon from "./resources/camera.svg";
import folderIcon from "./resources/folder.svg";
import imageIcon from "./resources/image.svg";
import atSymbolIcon from "./resources/at-symbol.svg";
import Dialog from "@material-ui/core/Dialog";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./index.css";
import MentionManager from "./MentionManager";
import { isMobile, isWindows } from "react-device-detect";

const mentionManager = new MentionManager();

class CometChatMessageComposerCustom extends React.PureComponent {
  static contextType = CometChatContext;
  handleBlurTimeout = null;
  cancelSubmission = false;

  constructor(props) {
    super(props);
    this.imageUploaderRef = React.createRef();
    this.fileUploaderRef = React.createRef();
    this.cameraRef = React.createRef();
    this.messageInputRef = React.createRef();
    this.cameraOpntionsRef = React.createRef();

    this.isTyping = false;
    this.isInputChange = false;
    this.willRunOnInputEvent = true; // to check typing IME

    this.state = {
      showFilePicker: false,
      messageInput: "",
      messageType: "",
      emojiViewer: false,
      createPoll: false,
      messageToBeEdited: "",
      replyPreview: null,
      stickerViewer: false,
      messageToReact: "",
      shareDocument: false,
      shareWhiteboard: false,
      enableLiveReaction: false,
      enablePolls: false,
      enableTypingIndicator: false,
      enableStickers: false,
      enablePhotosVideos: false,
      enableFiles: false,
      enableEmojis: false,
      enableCollaborativeDocument: false,
      enableCollaborativeWhiteboard: false,

      groupMembers: null,
      fullUserMentions: [],
      showUserMentions: false,
      userMentions: [],
      selectedUserMentionIds: [],
      hoverMentionIndex: 0,
      mentionSearchStart: null,
      mentionSearchEnd: null,
      caretPosition: {},
      isConnect: true,
      widthChatBox: 0,
      heightMessageInput: 0,
      isMentionByMark: false,
      isShowScrollToBottomButton: false,
      showMenuUploadFile: false,
      isSendExistedFile: false,
      fileUploadType: "image",
      messageBefore: ""
    };
  }

  componentDidMount() {
    this.checkPlatform();
    this.getWidthChatBox();

    this.getHeightMessageInput();

    window.addEventListener("resize", this.getWidthChatBox);
    document.addEventListener("click", this.checkElementVisible);
    new ResizeSensor(document.getElementById("hotspot-info"), () => {
      this.getWidthChatBox();
    });

    new ResizeSensor(document.querySelector(".chat__composer"), () => {
      this.getHeightMessageInput();
    });

    document
      .querySelector(".list__wrapper")
      .addEventListener("scroll", this.showHideScrollToBottomButton);

    document
      .querySelector(".list__wrapper")
      .addEventListener("click", this.hideEmojiPicker);

    CometChat.getLoggedinUser()
      .then(user => (this.loggedInUser = user))
      .catch(error =>
        this.props.actionGenerated(
          enums.ACTIONS["ERROR"],
          [],
          "SOMETHING_WRONG"
        )
      );

    this.item =
      this.context.type === CometChat.ACTION_TYPE.TYPE_USER ||
        this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP
        ? this.context.item
        : null;
    this.enableLiveReaction();
    this.enablePolls();
    this.enableTypingIndicator();
    this.enableStickers();
    this.enablePhotosVideos();
    this.enableFiles();
    this.enableEmojis();
    this.enableCollaborativeDocument();
    this.enableCollaborativeWhiteboard();
    setTimeout(() => {
      this.scrollToBottom();
    }, 1300);
    document.getElementById("div_message_input").focus();
    // TODO:
    // this.mentionManager = new MentionManager(userMentions);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.closeMentionListState !== this.props.closeMentionListState) {
      if (props.closeMentionListState) {
        this.setState({ showUserMentions: false });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkPlatform();
    if (prevProps.messageToBeEdited !== this.props.messageToBeEdited) {
      const messageToBeEdited = this.props.messageToBeEdited;

      // this.setState({ "messageInput": messageToBeEdited, "messageToBeEdited": messageToBeEdited });
      this.setState({
        messageInput: messageToBeEdited.text || "",
        messageToBeEdited: messageToBeEdited
      });

      const element = this.messageInputRef.current;
      if (messageToBeEdited) {
        let messageText = messageToBeEdited.text;

        //xss extensions data
        const xssData = checkMessageForExtensionsData(
          messageToBeEdited,
          "xss-filter"
        );
        if (
          xssData &&
          xssData.hasOwnProperty("sanitized_text") &&
          xssData.hasOwnProperty("hasXSS") &&
          xssData.hasXSS === "yes"
        ) {
          messageText = xssData.sanitized_text;
        }

        element.focus();
        element.textContent = "";
        // this.pasteHtmlAtCaret(messageText, false);
        this.pasteMessageToInput(messageText);
      } else {
        element.textContent = "";
      }
    }

    if (prevProps.replyPreview !== this.props.replyPreview) {
      this.setState({ replyPreview: this.props.replyPreview });
    }

    const previousMessageStr = JSON.stringify(prevProps.messageToReact);
    const currentMessageStr = JSON.stringify(this.props.messageToReact);

    if (previousMessageStr !== currentMessageStr) {
      this.setState({ messageToReact: this.props.messageToReact });
    }

    if (this.context.item !== this.item) {
      this.messageInputRef.current.textContent = "";
      this.setState({
        stickerViewer: false,
        emojiViewer: false,
        replyPreview: null,
        messageToBeEdited: "",
        messageInput: ""
      });

      this.focusOnMessageComposer();
    }

    if (prevState.messageInput !== this.state.messageInput) {
      this.focusOnMessageComposer();
    }

    this.item =
      this.context.type === CometChat.ACTION_TYPE.TYPE_USER ||
        this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP
        ? this.context.item
        : null;
    this.enableLiveReaction();
    this.enablePolls();
    this.enableTypingIndicator();
    this.enableStickers();
    this.enablePhotosVideos();
    this.enableFiles();
    this.enableEmojis();
    this.enableCollaborativeDocument();
    this.enableCollaborativeWhiteboard();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.checkElementVisible);
    window.removeEventListener("resize", () => { });
    document
      .querySelector(".chat__composer")
      .removeEventListener("resize", () => { });

    clearTimeout(this.handleBlurTimeout);
    this.cancelSubmission = true;
  }

  checkPlatform = () => {
    const userAgent = window.navigator.userAgent;
    this.setState({
      isSafariAndIOS15:
        userAgent.indexOf("iPhone OS 15") > -1 &&
        userAgent.indexOf("Safari") > -1
    });
  };

  getWidthChatBox = () => {
    if (!this.cancelSubmission) {
      this.setState({
        widthChatBox: document.getElementById("messages-block")
          ? document.getElementById("messages-block").offsetWidth
          : 0
      });
    }
  };
  checkElementVisible = event => {
    const { target } = event;
    const userAgent = window.navigator.userAgent;
    const isAndroid = userAgent.indexOf("Android") >= 0;
    if (
      isAndroid &&
      this.state.showCameraAcceptOptions &&
      (!this.cameraOpntionsRef.current.contains(target) ||
        !this.cameraRef.current.contains(target))
    ) {
      setTimeout(() => {
        this.setState({ showCameraAcceptOptions: false });
      }, 20);
    }
  };

  getHeightMessageInput = () => {
    this.setState({
      heightMessageInput: document.querySelector(".chat__composer").offsetHeight
    });
  };

  /**
   * if live reactions feature is disabled
   */
  enableLiveReaction = () => {
    this.context.FeatureRestriction.isLiveReactionsEnabled()
      .then(response => {
        if (response !== this.state.enableLiveReaction) {
          this.setState({ enableLiveReaction: response });
        }
      })
      .catch(error => {
        if (this.state.enableLiveReaction !== false) {
          this.setState({ enableLiveReaction: false });
        }
      });
  };

  /**
   * if polls feature is disabled
   */
  enablePolls = () => {
    this.context.FeatureRestriction.isPollsEnabled()
      .then(response => {
        if (response !== this.state.enablePolls) {
          this.setState({ enablePolls: response });
        }
      })
      .catch(error => {
        if (this.state.enablePolls !== false) {
          this.setState({ enablePolls: false });
        }
      });
  };

  /**
   * if typing indicator feature is disabled
   */
  enableTypingIndicator = () => {
    this.context.FeatureRestriction.isTypingIndicatorsEnabled()
      .then(response => {
        if (response !== this.state.enableTypingIndicator) {
          this.setState({ enableTypingIndicator: response });
        }
      })
      .catch(error => {
        if (this.state.enableTypingIndicator !== false) {
          this.setState({ enableTypingIndicator: false });
        }
      });
  };

  /**
   * if stickers feature is disabled
   */
  enableStickers = () => {
    this.context.FeatureRestriction.isStickersEnabled()
      .then(response => {
        if (response !== this.state.enableStickers) {
          this.setState({ enableStickers: response });
        }
      })
      .catch(error => {
        if (this.state.enableStickers !== false) {
          this.setState({ enableStickers: false });
        }
      });
  };

  /**
   * if uploding photos, videos feature is disabled
   */
  enablePhotosVideos = () => {
    this.context.FeatureRestriction.isPhotosVideosEnabled()
      .then(response => {
        if (response !== this.state.enablePhotosVideos) {
          this.setState({ enablePhotosVideos: response });
        }
      })
      .catch(error => {
        if (this.state.enablePhotosVideos !== false) {
          this.setState({ enablePhotosVideos: false });
        }
      });
  };

  /**
   * if uploding files feature is disabled
   */
  enableFiles = () => {
    this.context.FeatureRestriction.isFilesEnabled()
      .then(response => {
        if (response !== this.state.enableFiles) {
          this.setState({ enableFiles: response });
        }
      })
      .catch(error => {
        if (this.state.enableFiles !== false) {
          this.setState({ enableFiles: false });
        }
      });
  };

  /**
   * if sending emojis feature is disabled
   */
  enableEmojis = () => {
    this.context.FeatureRestriction.isEmojisEnabled()
      .then(response => {
        if (response !== this.state.enableEmojis) {
          this.setState({ enableEmojis: response });
        }
      })
      .catch(error => {
        if (this.state.enableEmojis !== false) {
          this.setState({ enableEmojis: false });
        }
      });
  };

  /**
   * if sharing collborative document feature is disabled
   */
  enableCollaborativeDocument = () => {
    this.context.FeatureRestriction.isCollaborativeDocumentEnabled()
      .then(response => {
        if (response !== this.state.enableCollaborativeDocument) {
          this.setState({ enableCollaborativeDocument: response });
        }
      })
      .catch(error => {
        if (this.state.enableCollaborativeDocument !== false) {
          this.setState({ enableCollaborativeDocument: false });
        }
      });
  };

  /**
   * if sharing collborative whiteboard feature is disabled
   */
  enableCollaborativeWhiteboard = () => {
    this.context.FeatureRestriction.isCollaborativeWhiteBoardEnabled()
      .then(response => {
        if (response !== this.state.enableCollaborativeWhiteboard) {
          this.setState({ enableCollaborativeWhiteboard: response });
        }
      })
      .catch(error => {
        if (this.state.enableCollaborativeWhiteboard !== false) {
          this.setState({ enableCollaborativeWhiteboard: false });
        }
      });
  };

  focusOnMessageComposer = () => {
    if (this.messageInputRef && this.messageInputRef.current) {
      this.messageInputRef.current.focus();
    }
  };

  pasteHtmlAtCaret(html, selectPastedContent) {
    var sel, range;
    const chatWindow = this.context.UIKitSettings.chatWindow;
    if (chatWindow.getSelection) {
      // IE9 and non-IE
      sel = chatWindow.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);

        // do like skype
        if (range.startContainer.parentElement?.tagName === "SPAN") {
          return;
        }

        range.deleteContents();

        // Range.createContextualFragment() would be useful here but is
        // only relatively recently standardized and is not supported in
        // some browsers (IE9, for one)
        var el = document.createElement("div");
        el.innerText = html;
        var frag = document.createDocumentFragment(),
          node,
          lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        var firstNode = frag.firstChild;
        range.insertNode(frag);

        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          if (selectPastedContent) {
            range.setStartBefore(firstNode);
          } else {
            range.collapse(true);
          }
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    } else if ((sel = document.selection) && sel.type !== "Control") {
      // IE < 9
      var originalRange = sel.createRange();
      originalRange.collapse(true);
      sel.createRange().pasteHTML(html);
      if (selectPastedContent) {
        range = sel.createRange();
        range.setEndPoint("StartToStart", originalRange);
        range.select();
      }
    }
  }

  pasteMessageToInput(html) {
    this.messageInputRef.current.innerHTML = html;

    var sel, range;
    sel = window.getSelection();

    const newRange = document.createRange();
    newRange.selectNodeContents(this.messageInputRef.current);
    newRange.collapse(false);

    sel.removeAllRanges();
    sel.addRange(newRange);

    this.messageInputRef.current.focus();
  }

  emojiClicked = (emoji, event) => {
    if (this.state.messageToReact) {
      this.reactToMessages(emoji);
      return;
    }

    const element = this.messageInputRef.current;
    element.focus();
    this.pasteHtmlAtCaret(emoji.native, false);
    this.setState({ messageInput: element.innerHTML, messageType: "text" });
  };

  changeHandler = (event, isManualExec) => {
    this.isInputChange = true;
    if (isManualExec) {
      // is to be executed manually from handleCompositionEnd in case typing IME
      // do nothing, continue processing
    } else if (!this.willRunOnInputEvent) {
      // don't process when typing IME is not finished
      return;
    }

    this.startTyping();

    const elem = event.currentTarget;
    let messageInput = elem.textContent.trim();

    if (!messageInput.length) {
      event.currentTarget.textContent = messageInput;
      //return false;
    }

    this.setState({ messageInput: elem.innerHTML, messageType: "text" });

    // mentionManager.checkAndSetCaretToOutsideSpan(this.messageInputRef.current);
    this.checkShowMentions(event.target.innerHTML, "oninput");
  };

  handleSelect = ev => {
    if (this.state.showFilePicker) {
      this.toggleFilePicker();
    }
    if (this.isInputChange === true) {
      // don't need check mention because have checked in oninput(changeHandler)
    } else {
      mentionManager.checkAndSetCaretToOutsideSpan(
        this.messageInputRef.current
      );
      let newCaretPosition = mentionManager.getCaretPosition(
        this.messageInputRef.current
      );
      if (
        newCaretPosition.location !== this.state.caretPosition.location ||
        newCaretPosition.totalText !== this.state.caretPosition.totalText
      ) {
        // console.log(newCaretPosition);
        // TODO: fix inputing text on begining of input make text inside span, but need more optimize
        if (
          newCaretPosition.location === 0 &&
          newCaretPosition.totalText === 0
        ) {
          mentionManager.setCaretToStart(this.messageInputRef.current);
          this.setState({ caretPosition: newCaretPosition });
        } else {
          // TODO: bị call mentionManager.getCaretPosition 2 lần nếu vào đây
          this.checkShowMentions(ev.target.innerHTML, "onselect"); // TODO: replace ev.target.innerHTML by this.messageInputRef.current
        }
      } else {
      }
    }

    // TODO: put this into if block above
    this.isInputChange = false;
  };

  handlePaste = e => {
    e.preventDefault();
    var text = e.clipboardData.getData("text/plain");
    document.execCommand("insertText", false, text);

    // to fix dont scroll bottom when paste long text
    this.messageInputRef.current.scrollTop =
      this.messageInputRef.current.scrollHeight;
  };

  handleForcus = () => {
    this.setState({
      isForcusInput: true
    });
  };

  handleBlur = () => {
    this.handleBlurTimeout = setTimeout(() => {
      this.setState({
        isForcusInput: this.state.SendBtnIconClick,
        SendBtnIconClick: false
      });
    }, 100);
  };

  handleCompositionStart = e => {
    this.willRunOnInputEvent = false;
  };

  handleCompositionUpdate = e => {
    this.willRunOnInputEvent = false;
  };

  // onChange run after onCompositionUpdate and before onCompositionEnd

  handleCompositionEnd = e => {
    this.willRunOnInputEvent = true;
    this.changeHandler(e, true);
  };

  checkShowMentions = (value, event) => {
    const messageBeforeChange = this.state.messageInput || "";

    const currentNodes = this.messageInputRef.current.innerHTML.split(
      `<span class="chat-box-input-tag-user">`
    );

    const beforeNodes = messageBeforeChange.split(
      `<span class="chat-box-input-tag-user">`
    );

    let isDeleteByDelete = false;
    let isMentionTagBeforeTag = false;
    if (
      value.length < messageBeforeChange.length &&
      currentNodes.length === beforeNodes.length
    ) {
      for (let i = 0; i < currentNodes.length; i++) {
        if (beforeNodes[i][0] === "@" && currentNodes[i][0] !== "@") {
          isDeleteByDelete = true;
          break;
        }
      }
    }

    if (navigator.userAgent.indexOf("Firefox") !== -1) {
      this.startsWithTagSymbols = false;
      if (value.length - messageBeforeChange.length === 1) {
        for (let i = 0; i < currentNodes.length; i++) {
          if (
            !beforeNodes[i].startsWith("@@") &&
            currentNodes[i].startsWith("@@")
          ) {
            this.startsWithTagSymbols = true;
            break;
          }
        }
      }
    }

    const { checMention, caretPosition } =
      mentionManager.transformInputAndCheckShowMentions(
        value,
        this.messageInputRef.current,
        this.props.listMention,
        event
      );
    if (checMention.is_enable) {
      this.setState({
        showUserMentions: true,
        userMentions: checMention.data,
        hoverMentionIndex: 0,
        isMentionByMark: false,
        emojiViewer: false
      });
      this.props.openMentionList();
    } else {
      if (event === "oninput") {
        this.setState({
          showUserMentions: false,
          showCameraAcceptOptions: false
        });
      }
    }

    if (value) {
      if (
        this.messageInputRef.current.innerHTML.split(
          `<span class="chat-box-input-tag-user">@`
        ).length -
        1 <
        messageBeforeChange.split(`<span class="chat-box-input-tag-user">@`)
          .length -
        1
      ) {
        const countTagsRemove =
          messageBeforeChange.split(`<span class="chat-box-input-tag-user">@`)
            .length -
          1 -
          (this.messageInputRef.current.innerHTML.split(
            `<span class="chat-box-input-tag-user">@`
          ).length -
            1);
        let listMentionsId = [...this.state.selectedUserMentionIds];

        if (event === "onselect") {
          listMentionsId.splice(mentionManager.tagBeforeCaret, countTagsRemove);
        } else {
          const compare_text =
            messageBeforeChange.length -
            `<span class="chat-box-input-tag-user"></span>`.length -
            this.messageInputRef.current.innerHTML.length;

          listMentionsId.splice(
            mentionManager.tagBeforeCaret === 0
              ? 0
              : !isDeleteByDelete &&
                (compare_text === 1 ||
                  compare_text < 0 ||
                  listMentionsId.length === 1 ||
                  navigator.userAgent.indexOf("Firefox") !== -1)
                ? mentionManager.tagBeforeCaret - 1
                : mentionManager.tagBeforeCaret,
            countTagsRemove
          );
        }
        this.setState(
          { selectedUserMentionIds: listMentionsId },
          console.log(listMentionsId)
        );
      }
    } else {
      this.setState({ selectedUserMentionIds: [] }, console.log([]));
    }
    // TODO: can put this to outside this function
    this.setState({
      messageInput: this.messageInputRef.current.innerHTML,
      messageType: "text",
      caretPosition
    });
  };

  mentionByMark = e => {
    e.preventDefault();

    if (this.state.showUserMentions) {
      this.setState({
        showUserMentions: false,
        isMentionByMark: false
        // userMentions: this.props.listMention,
        // hoverMentionIndex: 0
      });
    } else {
      this.setState({
        showUserMentions: true,
        userMentions: this.props.listMention,
        hoverMentionIndex: 0,
        isMentionByMark: true,
        emojiViewer: false
      });
      this.props.openMentionList();
    }
  };

  selectMention = mention => {
    var mentionValue = mentionManager.insertMentionTag(
      mention,
      this.messageInputRef.current,
      this.state.isMentionByMark ? true : false
    );
    if (mentionValue.is_enable) {
      const oldSelectedUserMentionIds = [...this.state.selectedUserMentionIds];
      if (mentionValue.position.typeExactly === 1) {
        oldSelectedUserMentionIds.splice(
          mentionManager.tagBeforeCaret - 1,
          1,
          mention.uniqueCode
        );
      } else {
        if (
          mentionManager.tagBeforeCaret <
          this.state.selectedUserMentionIds.length +
          (this.startsWithTagSymbols ? 1 : 0)
        ) {
          oldSelectedUserMentionIds.splice(
            mentionManager.tagBeforeCaret > 1
              ? mentionManager.tagBeforeCaret - 1
              : mentionManager.tagBeforeCaret,
            0,
            mention.uniqueCode
          );
        } else {
          oldSelectedUserMentionIds.push(mention.uniqueCode);
        }
      }

      this.setState(
        { selectedUserMentionIds: oldSelectedUserMentionIds },
        console.log(oldSelectedUserMentionIds)
      );
      // update: sau khi mention lần đầu, vẫn có thể chọn tiếp, đồng nghĩa với cách hoạt động giống mention by mark
      // this.setState({ showUserMentions: false });
      this.setState({ isMentionByMark: true });
      this.setState({
        messageInput: this.messageInputRef.current.innerHTML,
        messageType: "text"
      });
    }
  };

  toggleFilePicker = () => {
    const currentState = !this.state.showFilePicker;
    this.setState({ showFilePicker: currentState });

    this.setState({ emojiViewer: false, stickerViewer: false });
  };

  toggleCameraAcceptOptions = () => {
    const userAgent = window.navigator.userAgent;
    const isAndroid = userAgent.indexOf("Android") >= 0;
    if (isAndroid) {
      const currentState = this.state.showCameraAcceptOptions;
      this.setState({ showCameraAcceptOptions: !currentState });
    }
  };

  openAndroidCamera = type => {
    if (type === "video") {
      this.setState({ cameraAccept: "video/*" }, () => {
        this.cameraRef.current.click();
      });
    } else {
      this.setState({ cameraAccept: "image/*" }, () => {
        this.cameraRef.current.click();
      });
    }
  };

  openFileDialogue = fileType => {
    switch (fileType) {
      case "image":
        this.imageUploaderRef.current.click();
        break;
      case "file":
        this.fileUploaderRef.current.click();
        break;
      case "camera":
        const userAgent = window.navigator.userAgent;
        const isAndroid = userAgent.indexOf("Android") >= 0;
        this.setState({ cameraAccept: "image/*,video/*" });
        if (!isAndroid) {
          this.cameraRef.current.click();
        } else {
          setTimeout(() => {
            this.toggleCameraAcceptOptions();
            this.setState({ showUserMentions: false, emojiViewer: false });
          }, 10);
        }
        break;
      default:
        break;
    }
  };

  onImageChange = e => {
    if (!this.imageUploaderRef.current.files["0"]) {
      return false;
    }

    const uploadedFile = this.imageUploaderRef.current.files["0"];

    var reader = new FileReader(); // Creating reader instance from FileReader() API
    reader.addEventListener(
      "load",
      event => {
        // Setting up base64 URL on image

        const newFile = new File(
          [reader.result],
          uploadedFile.name,
          uploadedFile
        );
        this.sendMediaMessage(newFile, CometChat.MESSAGE_TYPE.IMAGE);
      },
      false
    );

    reader.readAsArrayBuffer(uploadedFile);
  };

  onFileChange = e => {
    if (!this.fileUploaderRef.current.files["0"]) {
      return false;
    }
    const imageExts = ['.jpg', '.jpeg', '.png'];
    const videoExts = ['.mp4'];

    let fileType = CometChat.MESSAGE_TYPE.FILE;
    
    const uploadedFile = this.fileUploaderRef.current.files["0"];

    var reader = new FileReader(); // Creating reader instance from FileReader() API
    reader.addEventListener(
      "load",
      event => {
        // Setting up base64 URL on image

        const newFile = new File(
          [reader.result],
          uploadedFile.name,
          uploadedFile
        );

        if(imageExts.some(ext => newFile.name.toLowerCase().endsWith(ext))) {
          fileType = CometChat.MESSAGE_TYPE.IMAGE;
        } else if(videoExts.some(ext => newFile.name.toLowerCase().endsWith(ext))) {
          fileType = CometChat.MESSAGE_TYPE.VIDEO;
        }

        this.sendMediaMessage(newFile, fileType);
      },
      false
    );

    reader.readAsArrayBuffer(uploadedFile);
  };

   onVideoChange = e => {
    if (!this.cameraRef.current.files["0"]) {
      return false;
    }

    const uploadedFile = this.cameraRef.current.files["0"];

    var reader = new FileReader(); // Creating reader instance from FileReader() API
    reader.addEventListener(
      "load",
      () => {
        // Setting up base64 URL on image

        const newFile = new File(
          [reader.result],
          uploadedFile.name,
          uploadedFile
        );
         this.sendMediaMessage(
          newFile,
          newFile.type.split("/")[0] === "image"
            ? CometChat.MESSAGE_TYPE.IMAGE
            : CometChat.MESSAGE_TYPE.VIDEO
        );

        this.setState({ showCameraAcceptOptions: false });
      },
      false
    );

    reader.readAsArrayBuffer(uploadedFile);
  };

  onCameraChange = (file, type) => {
    if (!file) {
      return false;
    }

    var reader = new FileReader(); // Creating reader instance from FileReader() API

    reader.addEventListener(
      "load",
      event => {
        this.sendMediaMessage(file, type);
      },
      false
    );

    reader.readAsArrayBuffer(file);
  };

  async postMediaToLogbuild(siteUC, type, file) {
    if (this.source) {
      this.source.cancel();
    }
    const { cometChatUser, hotspotInfo } = this.props;
    const classification = window.location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    let dataCometChat = {
      appID: cometChatUser.appID,
      authToken: cometChatUser.authToken,
      threadID: hotspotInfo.messageId,
      groupID: hotspotInfo.sitesUC
    };
    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();
    this.setState({ FetchDone: true });
    const formData = new FormData();
    formData.append("sitesUC", siteUC);
    formData.append("file", file);
    formData.append("appID", dataCometChat.appID);
    formData.append("authToken", dataCometChat.authToken);
    formData.append("threadID", dataCometChat.threadID);
    formData.append("groupID", dataCometChat.groupID);
    const res = await api
      .post(`/${classification}/${type}`, formData, {
        cancelToken: this.source.token
      })
      .catch(e => {
        console.log(e);
        if (e?.response?.status === 404) {
          this.setState({ FetchDone: false });
        }
      });
    const { data } = await res;
    return data;
  }

  getReceiverDetails = () => {
    let receiverId;
    let receiverType;

    if (this.context.type === CometChat.ACTION_TYPE.TYPE_USER) {
      receiverId = this.context.item.uid;
      receiverType = CometChat.RECEIVER_TYPE.USER;
    } else if (this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP) {
      receiverId = this.context.item.guid;
      receiverType = CometChat.RECEIVER_TYPE.GROUP;
    }

    return { receiverId: receiverId, receiverType: receiverType };
  };

  sendMediaMessage = async (messageInput, messageType) => {
    this.toggleFilePicker();
    this.endTyping(null, null);

    const { receiverId, receiverType } = this.getReceiverDetails();

    let mediaMessage = new CometChat.MediaMessage(
      receiverId,
      messageInput,
      messageType,
      receiverType
    );
    if (this.props.parentMessageId) {
      mediaMessage.setParentMessageId(this.props.parentMessageId);
    }

    mediaMessage.setSender(this.loggedInUser);
    mediaMessage.setReceiver(this.context.type);
    mediaMessage.setType(messageType);
    mediaMessage._composedAt = getUnixTimestamp();
    mediaMessage._id = ID();

    if (
      messageType === CometChat.MESSAGE_TYPE.IMAGE ||
      messageType === CometChat.MESSAGE_TYPE.FILE
    ) {
      const ImageExts = ['.jpg', '.jpeg', '.png'];
      const FileExts = ['.pdf'];
      if (
          messageType === CometChat.MESSAGE_TYPE.IMAGE || 
          ImageExts.some(ext => messageInput.name.toLowerCase().endsWith(ext))
      ) {
        let result = await this.postMediaToLogbuild(
          receiverId,
          "site-photos",
          messageInput
        );
        setTimeout(() => {
          this.getNoticeMembers();
        }, 2500);
        return;
      } else if(FileExts.some(ext => messageInput.name.toLowerCase().endsWith(ext))) {
        let result = await this.postMediaToLogbuild(
          receiverId,
          "site-files",
          messageInput
        );
        setTimeout(() => {
          this.getNoticeMembers();
        }, 2500);
        return;
      }
    }
    SoundManager.play(enums.CONSTANTS.AUDIO["OUTGOING_MESSAGE"], this.context);
    this.props.actionGenerated(enums.ACTIONS["MESSAGE_COMPOSED"], [
      mediaMessage
    ]);
    CometChat.sendMessage(mediaMessage)
      .then(message => {
        setTimeout(() => {
          this.getNoticeMembers();
        }, 2500);
        const newMessageObj = { ...message, _id: mediaMessage._id };
        this.props.actionGenerated(enums.ACTIONS["MESSAGE_SENT"], [
          newMessageObj
        ]);
      })
      .catch(error => {
        const newMessageObj = { ...mediaMessage, error: error };
        this.props.actionGenerated(enums.ACTIONS["ERROR_IN_SENDING_MESSAGE"], [
          newMessageObj
        ]);
      });
  };

  sendMessageOnEnter = event => {
    if (this.state.showUserMentions) {
      // enter or tab
      if ((event.keyCode === 9 || event.keyCode === 13) && !event.shiftKey) {
        event.preventDefault();
        this.selectMention(
          this.state.userMentions[this.state.hoverMentionIndex]
        );
        this.setState({ showUserMentions: false });
        return true;
      }

      // esc
      if (event.keyCode === 27) {
        event.preventDefault();
        this.setState({ showUserMentions: false });
      }

      if (event.keyCode == "38") {
        // up arrow
        event.preventDefault(); // to prevent run into onselect event
        if (this.state.userMentions.length > 0) {
          this.setState(
            {
              hoverMentionIndex:
                this.state.hoverMentionIndex === 0
                  ? this.state.userMentions.length - 1
                  : this.state.hoverMentionIndex - 1
            },
            this.scrollToMentionItemSelected
          );
        } else {
          this.setState(
            { hoverMentionIndex: 0 },
            this.scrollToMentionItemSelected
          );
        }
      } else if (event.keyCode == "40") {
        // down arrow
        event.preventDefault(); // to prevent run into onselect event
        if (this.state.userMentions.length > 0) {
          this.setState(
            {
              hoverMentionIndex:
                this.state.hoverMentionIndex ===
                  this.state.userMentions.length - 1
                  ? 0
                  : this.state.hoverMentionIndex + 1
            },
            () => this.scrollToMentionItemSelected(false)
          );
        } else {
          this.setState({ hoverMentionIndex: 0 }, () =>
            this.scrollToMentionItemSelected(false)
          );
        }
      }
    }

    if (event.keyCode === 13) {
      if (
        (navigator.userAgent.indexOf("Mac") !== -1 && event.metaKey) ||
        (isWindows && event.ctrlKey)
      ) {
        event.preventDefault();
        this.sendTextMessage();
        this.setState({ selectedUserMentionIds: [] }, console.log([]));
        return true;
      }

      if (event.altKey || event.shiftKey) {
        event.preventDefault();
        return true;
      }

      event.preventDefault();
      document.execCommand("insertLineBreak");
      return true;
    }

    // Ctrl + Z
    if (event.keyCode === 90 && event.ctrlKey) {
      this.messageInputRef.current.innerHTML = "";
      event.preventDefault();
      this.messageInputRef.current.focus();
      this.setState({
        messageInput: ""
      });
    }
  };

  getNoticeMembers = () => {
    const infoData = api.get(
      `/${window.location.href.includes("/sp/") ? "supplier" : "builder"
      }/logwalk-viewer/infos/${this.props.hotspotInfo.uniqueCode}`
    );

    Promise.all([infoData]).then(res => {
      if (document.getElementById("mention-count")) {
        document.getElementById(
          "mention-count"
        ).innerHTML = `(${res[0].data.mentions.length})`;
      }
      const mentionsElement = document.getElementById("mention-list");
      mentionsElement.innerHTML = "";

      res[0].data.mentions.forEach(element => {
        let childNode = document.createElement("span");
        let textNode = document.createTextNode(element.name);
        childNode.appendChild(textNode);
        childNode.setAttribute("key", element.uniqueCode);
        childNode.style.marginRight = "12px";
        childNode.style.display = "inline-block";
        mentionsElement.appendChild(childNode);
      });
    });
  }

  showHideScrollToBottomButton = () => {
    const el = document.querySelector(".list__wrapper");
    if (el.scrollTop >= el.scrollHeight - el.offsetHeight) {
      this.setState({ isShowScrollToBottomButton: false });
    } else {
      this.setState({ isShowScrollToBottomButton: true });
    }
  };

  hideEmojiPicker = () => {
    if (this.state.emojiViewer) {
      this.setState({
        emojiViewer: false,
        stickerViewer: false
      });
    }
  };

  scrollToBottom = () => {
    if (document.querySelector(".list__wrapper")) {
      document
        .querySelector(".list__wrapper")
        .scrollTo(0, document.querySelector(".list__wrapper").scrollHeight);
    }
  };

  scrollToMentionItemSelected = (alignTo = true) => {
    const element = document.querySelectorAll(".chat-box-name-user")[
      this.state.hoverMentionIndex
    ];
    element.scrollIntoView(alignTo);
  };

  sendTextMessage = () => {
    this.setState({ SendBtnIconClick: true });
    const uniqueUserMentionIds = [
      ...new Set(this.state.selectedUserMentionIds)
    ];

    console.log(uniqueUserMentionIds);
    if (this.state.emojiViewer) {
      this.setState({ emojiViewer: false });
    }

    if (!this.state.messageInput.trim().length) {
      return false;
    }

    if (this.state.messageToBeEdited) {
      this.editMessage();
      return false;
    }

    this.endTyping(null, null);

    let { receiverId, receiverType } = this.getReceiverDetails();
    let messageInput = this.state.messageInput
      .trim()
      .replace(new RegExp("&nbsp;", "ig"), " ")
      .replace(new RegExp("\\n", "ig"), "<br>");

    // remover <br> at last
    while (messageInput.substr(-4, 4) === "<br>") {
      messageInput = messageInput.slice(0, messageInput.length - 4);
    }

    let textMessage = new CometChat.TextMessage(
      receiverId,
      messageInput,
      receiverType
    );
    if (this.props.parentMessageId) {
      textMessage.setParentMessageId(this.props.parentMessageId);
    }
    textMessage.setSender(this.loggedInUser);
    textMessage.setReceiver(this.context.type);
    textMessage.setText(messageInput);
    textMessage.setData({
      text: messageInput,
      mentions: uniqueUserMentionIds
    });
    textMessage._composedAt = getUnixTimestamp();
    textMessage._id = ID();

    this.props.actionGenerated(enums.ACTIONS["MESSAGE_COMPOSED"], [
      textMessage
    ]);
    this.setState({ messageInput: "", replyPreview: false });

    this.messageInputRef.current.textContent = "";
    SoundManager.play(enums.CONSTANTS.AUDIO["OUTGOING_MESSAGE"], this.context);

    CometChat.sendMessage(textMessage)
      .then(message => {
        const newMessageObj = { ...message, _id: textMessage._id };
        this.props.actionGenerated(enums.ACTIONS["MESSAGE_SENT"], [
          newMessageObj
        ]);

        // setTimeout to make sure mention has updated in DB after callback.
        setTimeout(() => {
          const infoData = api.get(
            `/${window.location.href.includes("/sp/") ? "supplier" : "builder"
            }/logwalk-viewer/infos/${this.props.hotspotInfo.uniqueCode}`
          );

          Promise.all([infoData]).then(res => {
            if (document.getElementById("mention-count")) {
              document.getElementById(
                "mention-count"
              ).innerHTML = `(${res[0].data.mentions.length})`;
            }
            const mentionsElement = document.getElementById("mention-list");
            mentionsElement.innerHTML = "";

            res[0].data.mentions.forEach(element => {
              let childNode = document.createElement("span");
              let textNode = document.createTextNode(element.name);
              childNode.appendChild(textNode);
              childNode.setAttribute("key", element.uniqueCode);
              childNode.style.marginRight = "12px";
              childNode.style.display = "inline-block";
              mentionsElement.appendChild(childNode);
            });
          });
        }, 1500);
      })
      .catch(error => {
        const newMessageObj = { ...textMessage, error: error };
        this.props.actionGenerated(enums.ACTIONS["ERROR_IN_SENDING_MESSAGE"], [
          newMessageObj
        ]);

        if (
          error &&
          error.hasOwnProperty("code") &&
          error.code === "ERR_GUID_NOT_FOUND"
        ) {
          //this.context.setDeletedGroupId(this.context.item.guid);
        }
      });

    this.setState(
      {
        selectedUserMentionIds: []
      },
      console.log([])
    );
    setTimeout(() => {
      this.scrollToBottom();
    }, 1300);
  };

  editMessage = () => {
    this.endTyping(null, null);

    const messageToBeEdited = this.props.messageToBeEdited;

    let { receiverId, receiverType } = this.getReceiverDetails();
    let messageText = this.state.messageInput.trim();
    let textMessage = new CometChat.TextMessage(
      receiverId,
      messageText,
      receiverType
    );
    textMessage.setId(messageToBeEdited.id);

    const newMessage = Object.assign({}, textMessage, {
      messageFrom: messageToBeEdited.messageFrom
    });
    this.props.actionGenerated(enums.ACTIONS["MESSAGE_EDITED"], newMessage);

    this.setState({ messageInput: "" });
    this.messageInputRef.current.textContent = "";
    SoundManager.play(enums.CONSTANTS.AUDIO["OUTGOING_MESSAGE"], this.context);

    this.closeEditPreview();

    CometChat.editMessage(textMessage)
      .then(message => {
        this.props.actionGenerated(enums.ACTIONS["MESSAGE_EDITED"], {
          ...message
        });
      })
      .catch(error =>
        this.props.actionGenerated(
          enums.ACTIONS["ERROR"],
          [],
          "SOMETHING_WRONG"
        )
      );
  };

  closeEditPreview = () => {
    this.props.actionGenerated(enums.ACTIONS["CLEAR_EDIT_PREVIEW"]);
  };

  startTyping = (timer, metadata) => {
    let typingInterval = timer || 5000;

    //if typing indicator feature is disabled
    if (this.state.enableTypingIndicator === false) {
      return false;
    }

    if (this.isTyping) {
      return false;
    }

    let { receiverId, receiverType } = this.getReceiverDetails();
    let typingMetadata = metadata || undefined;

    let typingNotification = new CometChat.TypingIndicator(
      receiverId,
      receiverType,
      typingMetadata
    );
    CometChat.startTyping(typingNotification);

    this.isTyping = setTimeout(() => {
      this.endTyping(null, typingMetadata);
    }, typingInterval);
  };

  endTyping = (event, metadata) => {
    //fixing synthetic issue
    if (event) {
      event.persist();
    }

    //if typing indicator is disabled for chat wigdet in dashboard
    if (this.state.enableTypingIndicator === false) {
      return false;
    }

    let { receiverId, receiverType } = this.getReceiverDetails();

    let typingMetadata = metadata || undefined;

    let typingNotification = new CometChat.TypingIndicator(
      receiverId,
      receiverType,
      typingMetadata
    );
    CometChat.endTyping(typingNotification);

    clearTimeout(this.isTyping);
    this.isTyping = null;
  };

  toggleStickerPicker = () => {
    const stickerViewer = this.state.stickerViewer;
    this.setState({ stickerViewer: !stickerViewer, emojiViewer: false });
  };

  toggleEmojiPicker = () => {
    const emojiViewer = this.state.emojiViewer;
    this.setState({
      emojiViewer: !emojiViewer,
      stickerViewer: false,
      showUserMentions: false
    });
  };

  toggleCreatePoll = () => {
    const createPoll = this.state.createPoll;
    this.setState({ createPoll: !createPoll });
  };

  toggleCollaborativeDocument = () => {
    const { receiverId, receiverType } = this.getReceiverDetails();
    CometChat.callExtension("document", "POST", "v1/create", {
      receiver: receiverId,
      receiverType: receiverType
    })
      .then(response => {
        // Response with document url
        if (response && response.hasOwnProperty("document_url")) {
          this.context.setToastMessage("success", "DOCUMENT_SUCCESS");
        } else {
          this.context.setToastMessage("error", "DOCUMENT_FAIL");
        }
      })
      .catch(error =>
        this.props.actionGenerated(
          enums.ACTIONS["ERROR"],
          [],
          "SOMETHING_WRONG"
        )
      );
  };

  toggleCollaborativeBoard = () => {
    const { receiverId, receiverType } = this.getReceiverDetails();
    CometChat.callExtension("whiteboard", "POST", "v1/create", {
      receiver: receiverId,
      receiverType: receiverType
    })
      .then(response => {
        // Response with board_url
        if (response && response.hasOwnProperty("board_url")) {
          this.context.setToastMessage("success", "WHITEBOARD_SUCCESS");
        } else {
          this.context.setToastMessage("error", "WHITEBOARD_FAIL");
        }
      })
      .catch(error =>
        this.props.actionGenerated(
          enums.ACTIONS["ERROR"],
          [],
          "SOMETHING_WRONG"
        )
      );
  };

  closeCreatePoll = () => {
    this.toggleCreatePoll();
    this.toggleFilePicker();
  };

  actionHandler = (action, message) => {
    switch (action) {
      case enums.ACTIONS["POLL_CREATED"]:
        this.toggleCreatePoll();
        this.toggleFilePicker();
        break;
      case enums.ACTIONS["SEND_STICKER"]:
        this.sendSticker(message);
        this.toggleStickerPicker();
        break;
      case enums.ACTIONS["CLOSE_STICKER_KEYBOARD"]:
        this.toggleStickerPicker();
        break;
      default:
        break;
    }
  };

  sendSticker = stickerMessage => {
    const { receiverId, receiverType } = this.getReceiverDetails();

    const customData = {
      sticker_url: stickerMessage.stickerUrl,
      sticker_name: stickerMessage.stickerName
    };
    const customType = enums.CUSTOM_TYPE_STICKER;

    const customMessage = new CometChat.CustomMessage(
      receiverId,
      receiverType,
      customType,
      customData
    );
    if (this.props.parentMessageId) {
      customMessage.setParentMessageId(this.props.parentMessageId);
    }
    customMessage.setSender(this.loggedInUser);
    customMessage.setReceiver(this.context.type);
    customMessage.setMetadata({ incrementUnreadCount: true });
    customMessage._composedAt = getUnixTimestamp();
    customMessage._id = ID();

    this.props.actionGenerated(enums.ACTIONS["MESSAGE_COMPOSED"], [
      customMessage
    ]);
    SoundManager.play(enums.CONSTANTS.AUDIO["OUTGOING_MESSAGE"], this.context);

    CometChat.sendCustomMessage(customMessage)
      .then(message => {
        const newMessageObj = { ...message, _id: customMessage._id };
        this.props.actionGenerated(enums.ACTIONS["MESSAGE_SENT"], [
          newMessageObj
        ]);
      })
      .catch(error => {
        const newMessageObj = { ...customMessage, error: error };
        this.props.actionGenerated(enums.ACTIONS["ERROR_IN_SENDING_MESSAGE"], [
          newMessageObj
        ]);
      });
  };

  sendReplyMessage = messageInput => {
    let { receiverId, receiverType } = this.getReceiverDetails();

    let textMessage = new CometChat.TextMessage(
      receiverId,
      messageInput,
      receiverType
    );
    if (this.props.parentMessageId) {
      textMessage.setParentMessageId(this.props.parentMessageId);
    }
    textMessage.setSender(this.loggedInUser);
    textMessage.setReceiver(this.context.type);
    textMessage._composedAt = getUnixTimestamp();
    textMessage._id = ID();

    this.props.actionGenerated(enums.ACTIONS["MESSAGE_COMPOSED"], [
      textMessage
    ]);

    SoundManager.play(enums.CONSTANTS.AUDIO["OUTGOING_MESSAGE"], this.context);
    this.setState({ replyPreview: null });

    CometChat.sendMessage(textMessage)
      .then(message => {
        const newMessageObj = { ...message, _id: textMessage._id };
        this.props.actionGenerated(enums.ACTIONS["MESSAGE_SENT"], [
          newMessageObj
        ]);
      })
      .catch(error => {
        const newMessageObj = { ...textMessage, error: error };
        this.props.actionGenerated(enums.ACTIONS["ERROR_IN_SENDING_MESSAGE"], [
          newMessageObj
        ]);
      });
  };

  clearReplyPreview = () => {
    this.setState({ replyPreview: null });
  };

  sendReaction = event => {
    const typingInterval = 1000;

    const typingMetadata = {
      type: enums.CONSTANTS["METADATA_TYPE_LIVEREACTION"],
      reaction: this.props.reaction
    };

    this.startTyping(typingInterval, typingMetadata);
    this.props.actionGenerated(enums.ACTIONS["SEND_LIVE_REACTION"]);

    event.persist();

    setTimeout(() => {
      //this.endTyping(null, typingMetadata);
      this.props.actionGenerated(enums.ACTIONS["STOP_LIVE_REACTION"]);
    }, typingInterval);
  };

  reactToMessages = emoji => {
    CometChat.callExtension("reactions", "POST", "v1/react", {
      msgId: this.state.messageToReact.id,
      emoji: emoji.colons
    })
      .then(response => {
        if (
          response.hasOwnProperty("success") &&
          response["success"] === true
        ) {
          this.toggleEmojiPicker();
          setTimeout(() => {
            const infoData = api.get(
              `/${window.location.href.includes("/sp/") ? "supplier" : "builder"
              }/logwalk-viewer/infos/${this.props.hotspotInfo.uniqueCode}`
            );

            Promise.all([infoData]).then(res => {
              if (document.getElementById("mention-count")) {
                document.getElementById(
                  "mention-count"
                ).innerHTML = `(${res[0].data.mentions.length})`;
              }
              const mentionsElement = document.getElementById("mention-list");
              mentionsElement.innerHTML = "";

              res[0].data.mentions.forEach(element => {
                let childNode = document.createElement("span");
                let textNode = document.createTextNode(element.name);
                childNode.appendChild(textNode);
                childNode.setAttribute("key", element.uniqueCode);
                childNode.style.marginRight = "12px";
                childNode.style.display = "inline-block";
                mentionsElement.appendChild(childNode);
              });
            });
          }, 1500);
        } else {
          this.props.actionGenerated(
            enums.ACTIONS["ERROR"],
            [],
            "SOMETHING_WRONG"
          );
        }
      })
      .catch(error =>
        this.props.actionGenerated(
          enums.ACTIONS["ERROR"],
          [],
          "SOMETHING_WRONG"
        )
      );
  };

  render() {
    let liveReactionBtn = null;
    const liveReactionText = Translator.translate(
      "LIVE_REACTION",
      this.props.lang
    );
    if (enums.CONSTANTS["LIVE_REACTIONS"].hasOwnProperty(this.props.reaction)) {
      const reactionName = this.props.reaction;
      liveReactionBtn = (
        <div
          title={liveReactionText}
          css={reactionBtnStyle()}
          className="button__reactions"
          onClick={this.sendReaction}
        >
          <img src={heartIcon} alt={reactionName} />
        </div>
      );
    }

    let disabledState = false;
    if (this.context.item.blockedByMe) {
      disabledState = true;
    }

    const docText = Translator.translate("ATTACH_FILE", this.props.lang);
    let docs = (
      <div
        title={docText}
        css={fileItemStyle(folderIcon, this.context)}
        className="filelist__item item__file"
        onClick={() => {
          this.setState({ showMenuUploadFile: true, fileUploadType: "file" });
          // this.openFileDialogue("file");
        }}
      >
        <i></i>
        <input
          onChange={this.onFileChange}
          type="file"
          id="file"
          ref={this.fileUploaderRef}
        />
      </div>
    );

    const videoText = Translator.translate("ATTACH_VIDEO", this.props.lang);
    const imageText = Translator.translate("ATTACH_IMAGE", this.props.lang);
    let avp = (
      <React.Fragment>
        {isMobile && (
          <div
            title={videoText}
            css={fileItemStyle(cameraIcon, this.context)}
            className="filelist__item item__video"
            onClick={() => {
              this.openFileDialogue("camera");
            }}
          >
            <i></i>
            <input
              onChange={this.onVideoChange}
              type="file"
              capture
              accept={`${this.state.cameraAccept}`}
              ref={this.cameraRef}
              onClick={e => e.stopPropagation()}
            />
          </div>
        )}
        <div
          title={imageText}
          css={fileItemStyle(imageIcon, this.context)}
          className="filelist__item item__image"
          onClick={() => {
            this.setState({
              showMenuUploadFile: true,
              fileUploadType: "image"
            });
            // this.openFileDialogue("image");
          }}
        >
          <i></i>
          <input
            onChange={this.onImageChange}
            accept="image/*"
            type="file"
            ref={this.imageUploaderRef}
          />
        </div>
      </React.Fragment>
    );

    const pollText = Translator.translate("CREATE_POLL", this.props.lang);
    let createPollBtn = (
      <div
        title={pollText}
        css={fileItemStyle(pollIcon, this.context)}
        className="filelist__item item__poll"
        onClick={this.toggleCreatePoll}
      >
        <i></i>
      </div>
    );

    const collaborativeDocText = Translator.translate(
      "COLLABORATE_USING_DOCUMENT",
      this.props.lang
    );
    let collaborativeDocBtn = (
      <div
        title={collaborativeDocText}
        css={fileItemStyle(documentIcon, this.context)}
        className="filelist__item item__document"
        onClick={this.toggleCollaborativeDocument}
      >
        <i></i>
      </div>
    );

    const collaborativeBoardText = Translator.translate(
      "COLLABORATE_USING_WHITEBOARD",
      this.props.lang
    );
    let collaborativeBoardBtn = (
      <div
        title={collaborativeBoardText}
        css={fileItemStyle(whiteboardIcon, this.context)}
        className="filelist__item item__whiteboard"
        onClick={this.toggleCollaborativeBoard}
      >
        <i></i>
      </div>
    );

    const emojiText = Translator.translate("EMOJI", this.props.lang);
    let emojiBtn = (
      <div
        title={emojiText}
        css={emojiButtonStyle(insertEmoticon, this.context)}
        className="button__emoji"
        style={{ marginLeft: "0px" }}
        onMouseDown={e => {
          e.preventDefault();
          e.stopPropagation();
          this.toggleEmojiPicker();
          this.setState({ messageToReact: "" });
        }}
      >
        <i></i>
      </div>
    );

    const StickerText = Translator.translate("STICKER", this.props.lang);
    let stickerBtn = (
      <div
        title={StickerText}
        css={stickerBtnStyle(stickerIcon, this.context)}
        className="button__sticker"
        onClick={this.toggleStickerPicker}
      >
        <i></i>
      </div>
    );

    const sendMessageText = Translator.translate(
      "SEND_MESSAGE",
      this.props.lang
    );
    let sendBtn = (
      <div
        title={sendMessageText}
        css={sendButtonStyle()}
        className="button__send"
        onClick={this.sendTextMessage}
      >
        <SendIcon className="icon-margin" fontSize="large" />
      </div>
    );

    //if uploading photos, videos feature is disabled
    if (this.state.enablePhotosVideos === false) {
      avp = null;
    }

    //if files upload are disabled for chat widget in dashboard
    if (this.state.enableFiles === false) {
      docs = null;
    }

    //if polls feature is disabled
    if (this.state.enablePolls === false || this.props.parentMessageId) {
      createPollBtn = null;
    }

    //if collaborative_document are disabled for chat widget in dashboard
    if (
      this.state.enableCollaborativeDocument === false ||
      this.props.parentMessageId
    ) {
      collaborativeDocBtn = null;
    }

    //if collaborative_document are disabled for chat widget in dashboard
    if (
      this.state.enableCollaborativeWhiteboard === false ||
      this.props.parentMessageId
    ) {
      collaborativeBoardBtn = null;
    }

    //if emojis are disabled for chat widget in dashboard
    if (this.state.enableEmojis === false) {
      emojiBtn = null;
    }

    //if live reactions is disabled for chat widget in dashboard
    if (
      this.state.enableLiveReaction === false ||
      this.state.messageInput.length ||
      this.props.parentMessageId
    ) {
      liveReactionBtn = null;
    }

    //if stickers is disabled for chat widget in dashboard
    if (this.state.enableStickers === false) {
      stickerBtn = null;
    }

    if (!this.state.messageInput.length) {
      sendBtn = null;
    }

    const attachText = Translator.translate("ATTACH", this.props.lang);
    let attach = (
      <div css={stickyAttachmentStyle()} className="input__sticky__attachment">
        {!this.state.showFilePicker && (
          <div
            css={stickyAttachButtonStyle()}
            className="attachment__icon"
            onClick={this.toggleFilePicker}
            title={attachText}
          >
            <AddIcon className="icon-margin" fontSize="large" />
          </div>
        )}

        <div
          css={filePickerStyle(this.state)}
          className="attachment__filepicker"
          dir={Translator.getDirection(this.props.lang)}
        >
          <div css={fileListStyle()} className="filepicker__filelist">
            {avp}
            {docs}
            {createPollBtn}
            {collaborativeDocBtn}
            {collaborativeBoardBtn}
          </div>
        </div>
      </div>
    );

    if (
      avp === null &&
      docs === null &&
      createPollBtn === null &&
      collaborativeDocBtn === null &&
      collaborativeBoardBtn === null
    ) {
      attach = null;
    }

    let createPoll = null;
    if (this.state.createPoll) {
      createPoll = (
        <CometChatCreatePoll
          close={this.closeCreatePoll}
          actionGenerated={this.actionHandler}
        />
      );
    }

    let editPreview = null;
    if (this.state.messageToBeEdited) {
      let messageText = this.state.messageToBeEdited.text;

      //xss extensions data
      const xssData = checkMessageForExtensionsData(
        this.state.messageToBeEdited,
        "xss-filter"
      );
      if (
        xssData &&
        xssData.hasOwnProperty("sanitized_text") &&
        xssData.hasOwnProperty("hasXSS") &&
        xssData.hasXSS === "yes"
      ) {
        messageText = xssData.sanitized_text;
      }

      //datamasking extensions data
      const maskedData = checkMessageForExtensionsData(
        this.state.messageToBeEdited,
        "data-masking"
      );
      if (
        maskedData &&
        maskedData.hasOwnProperty("data") &&
        maskedData.data.hasOwnProperty("sensitive_data") &&
        maskedData.data.hasOwnProperty("message_masked") &&
        maskedData.data.sensitive_data === "yes"
      ) {
        messageText = maskedData.data.message_masked;
      }

      //profanity extensions data
      const profaneData = checkMessageForExtensionsData(
        this.state.messageToBeEdited,
        "profanity-filter"
      );
      if (
        profaneData &&
        profaneData.hasOwnProperty("profanity") &&
        profaneData.hasOwnProperty("message_clean") &&
        profaneData.profanity === "yes"
      ) {
        messageText = profaneData.message_clean;
      }

      editPreview = (
        <div css={editPreviewContainerStyle(this.context, keyframes)}>
          <div css={previewHeadingStyle()}>
            <div css={previewTextStyle()}>
              {Translator.translate("EDIT_MESSAGE", this.props.lang)}
            </div>
            <span
              css={previewCloseStyle(closeIcon, this.context)}
              onClick={this.closeEditPreview}
            ></span>
          </div>
          <div dangerouslySetInnerHTML={{ __html: messageText }}></div>
        </div>
      );
    }

    let smartReplyPreview = null;
    if (this.state.replyPreview) {
      const message = this.state.replyPreview;

      const smartReplyData = checkMessageForExtensionsData(
        message,
        "smart-reply"
      );
      if (smartReplyData && smartReplyData.hasOwnProperty("error") === false) {
        const options = [
          smartReplyData["reply_positive"],
          smartReplyData["reply_neutral"],
          smartReplyData["reply_negative"]
        ];
        smartReplyPreview = (
          <CometChatSmartReplyPreview
            options={options}
            clicked={this.sendReplyMessage}
            close={this.clearReplyPreview}
          />
        );
      }
    }

    let stickerViewer = null;
    if (this.state.stickerViewer) {
      stickerViewer = (
        <CometChatStickerKeyboard actionGenerated={this.actionHandler} />
      );
    }

    let emojiViewer = null;
    if (this.state.emojiViewer) {
      emojiViewer = (
        <CometChatEmojiKeyboard
          emojiClicked={this.emojiClicked}
          style={{
            position: "fixed",
            bottom: this.state.isSafariAndIOS15 && this.state.isForcusInput ? this.state.heightMessageInput + 54 : this.state.heightMessageInput,
            zIndex: "2",
            width: this.state.widthChatBox
          }}
        />
      );
    }

    let inputWidth = isMobile
      ? this.state.widthChatBox - (this.state.showFilePicker ? 280 : 130)
      : this.state.widthChatBox - (this.state.showFilePicker ? 180 : 130);

    return (
      <>
        {editPreview}
        {smartReplyPreview}
        {stickerViewer}
        {emojiViewer}
        {this.state.showUserMentions && (
          <div
            className="chat-box-container"
            style={{
              top: this.state.height,
              bottom: this.state.isSafariAndIOS15 && this.state.isForcusInput ? this.state.heightMessageInput + 54 : this.state.heightMessageInput,
              width: this.state.widthChatBox
            }}
          >
            {this.state.userMentions.map((item, index) => {
              return (
                // user onMoudseDown to prevent lossing selection (carer position) when click outside
                <div
                  key={index}
                  className="chat-box-imfor"
                  style={{
                    backgroundColor:
                      this.state.hoverMentionIndex === index
                        ? "rgb(206, 228, 229)"
                        : "inherit"
                  }}
                  onMouseOver={() =>
                    this.setState({ hoverMentionIndex: index })
                  }
                  onMouseDown={e => {
                    e.preventDefault();
                    this.selectMention(item);
                  }}
                // onClick={() => this.callUserTag(varr)}
                >
                  <div className="chat-box-name-user">
                    {item.classification === "builder" ? (
                      <span
                        style={{
                          display: "inline-block",
                          width: "8px",
                          height: "8px",
                          borderRadius: "50%",
                          backgroundColor: "#8FC31F"
                        }}
                      ></span>
                    ) : (
                      <span
                        style={{
                          display: "inline-block",
                          width: "8px",
                          height: "8px"
                        }}
                      ></span>
                    )}
                    &nbsp;
                    {item.name + (item.company ? " / " + item.company : "")}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {this.state.showCameraAcceptOptions && (
          <div
            className="chat-box-container"
            style={{
              top: this.state.height,
              bottom: this.state.heightMessageInput,
              width: this.state.widthChatBox
            }}
            ref={this.cameraOpntionsRef}
          >
            <div className="chat-box-imfor">
              <div
                className="chat-box-camera-option"
                onClick={() => {
                  this.openAndroidCamera("image");
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    width: "8px",
                    height: "8px"
                  }}
                ></span>
                &nbsp; Take a photo
              </div>
              <div
                className="chat-box-camera-option"
                onClick={() => {
                  this.openAndroidCamera("video");
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    width: "8px",
                    height: "8px"
                  }}
                ></span>
                &nbsp; Take a video
              </div>
            </div>
          </div>
        )}
        {this.state.isShowScrollToBottomButton && (
          <div
            className="scroll-bottom"
            style={{
              position: "fixed",
              left: this.state.widthChatBox - 42,
              bottom: this.state.heightMessageInput + 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 32,
              height: 32,
              backgroundColor: "#E1E3EA",
              borderRadius: "50%",
              zIndex: 1,
              cursor: "pointer",
              boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)",
              color: "#646672",
              border: "1px solid #0000001a",
              paddingTop: 2
            }}
            onClick={this.scrollToBottom}
          >
            <ExpandMoreIcon fontSize="large" />
          </div>
        )}

        <div
          className="scroll-bottom"
          style={{
            position: "fixed",
            bottom: this.state.heightMessageInput,
            display: "block",
            width: 24,
            height: 24,
            color: "red"
          }}
        ></div>
        <div
          css={chatComposerStyle(this.context)}
          className={`chat__composer ${this.state.isSafariAndIOS15 &&
            this.state.isForcusInput &&
            this.state.isInputClicked
            ? "input_safari_ios15"
            : ""
            }`}
          style={{
            backgroundColor: "#00356D",
            width: this.state.widthChatBox
          }}
          onMouseDown={e => {
            this.setState({
              emojiViewer: false,
              stickerViewer: false
            });
            if (
              e.target.id !== "mention-mark-div" &&
              e.target.id !== "mention-mark-img"
            ) {
              this.setState({
                showUserMentions: false
              });
            }
          }}
        >
          <div css={composerInputStyle()} className="composer__input">
            {attach}
            <div
              tabIndex="-1"
              css={inputInnerStyle(this.props, this.state, this.context)}
              className="input__inner"
              style={{ width: inputWidth }}
            >
              <div
                css={messageInputStyle(disabledState)}
                className="input__message-input"
                id="div_message_input"
                contentEditable="true"
                placeholder={Translator.translate(
                  "ENTER_YOUR_MESSAGE_HERE",
                  this.props.lang
                )}
                dir={Translator.getDirection(this.props.lang)}
                onInput={this.changeHandler}
                onBlur={event => {
                  this.endTyping(event);
                  this.handleBlur();
                }}
                onKeyDown={this.sendMessageOnEnter}
                onSelect={this.handleSelect}
                onPaste={this.handlePaste}
                onFocus={this.handleForcus}
                onCompositionStart={this.handleCompositionStart}
                onCompositionUpdate={this.handleCompositionUpdate}
                onCompositionEnd={this.handleCompositionEnd}
                ref={this.messageInputRef}
                onClick={() => {
                  this.setState({
                    showUserMentions: false,
                    isInputClicked: true,
                    SendBtnIconClick: false
                  });
                }}
              ></div>

              <div
                css={inputStickyStyle(disabledState, attach, this.context)}
                className="input__sticky"
              >
                <div
                  css={stickyButtonStyle(this.state)}
                  className="input__sticky__buttons"
                >
                  {stickerBtn}
                  <div
                    style={{
                      width: "34px",
                      height: "40px",
                      paddingTop: "9px",
                      paddingBottom: "9px",
                      paddingLeft: "12px"
                    }}
                    onMouseDown={this.mentionByMark}
                    id="mention-mark-div"
                  >
                    <img
                      src={atSymbolIcon}
                      alt="@"
                      style={{ width: "22px", height: "22px" }}
                      id="mention-mark-img"
                    />
                  </div>
                  {emojiBtn}
                  {liveReactionBtn}
                </div>
              </div>
            </div>
            <div style={{ width: "80px" }}>{sendBtn}</div>
          </div>
          {createPoll}
        </div>
        {this.state.showMenuUploadFile && (
          <MenuUploadFile
            isSendToComet={true}
            uploadNewFile={() => {
              this.openFileDialogue(this.state.fileUploadType);
              this.setState({ showMenuUploadFile: false });
            }}
            chooseExistedFile={() => {
              this.setState({
                showMenuUploadFile: false,
                isSendExistedFile: true
              });
            }}
            closeMenu={() => {
              this.setState({ showMenuUploadFile: false });
            }}
          />
        )}

        <ChooseExistedFileDialog
          mode="send-to-comet"
          sitesUC={this.props.hotspotInfo.sitesUC}
          hotspotInfo={this.props.hotspotInfo}
          cometChatUser={this.props.cometChatUser}
          isOpen={this.state.isSendExistedFile}
          onClose={() => this.setState({ isSendExistedFile: false })}
          excludeSelects={[]}
          initTab={this.state.fileUploadType}
          doCompletedSendFiles={() => {
            setTimeout(() => {
              this.getNoticeMembers();
            }, 2500);
          }}
        />
      </>
    );
  }
}

// Specifies the default values for props:
CometChatMessageComposerCustom.defaultProps = {
  lang: Translator.getDefaultLanguage(),
  theme: theme,
  reaction: "heart"
};

CometChatMessageComposerCustom.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.object,
  reaction: PropTypes.string
};

export { CometChatMessageComposerCustom };
