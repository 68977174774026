export const POST_LOGIN_START = "POST_LOGIN_START";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_ERROR = "POST_LOGIN_ERROR";
export const POST_REGISTER_START = "POST_REGISTER_START";
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_ERROR = "POST_REGISTER_ERROR";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const FETCH_REGEX_SUCCESS = "FETCH_REGEX_SUCCESS";
export const FETCH_AVAILABLE_URL_SUCCESS = "FETCH_AVAILABLE_URL_SUCCESS";
export const FORGOT_PASSWORD_SP_SUCCESS = "FORGOT_PASSWORD_SP_SUCCESS";
export const FORGOT_PASSWORD_LOGBUILD_SUCCESS =
  "FORGOT_PASSWORD_LOGBUILD_SUCCESS";
