import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import { isTablet, isMobile } from "react-device-detect";
import { format } from "date-fns";
import Typography from "@material-ui/core/Typography";
import { enableLoading, finishLoading } from "common/Stores/service/service";
import { api } from "../../common/api/api";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

const mediaQueryTabletOrMobile = "(max-width: 960px)";

const useStyles = makeStyles({
  tableRowHover: {
    backgroundColor: "#F8F9F9",
    "&:hover": {
      backgroundColor: "#d6d6d6"
    }
  },
  truncateText3: {
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "wrap",
    wordBreak: "break-word"
  }
});

function ListRecordFixSpots(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [allRecordList, setAllRecordList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: mediaQueryTabletOrMobile });
  const days = ["日", "月", "火", "水", "木", "金", "土"];
  const fetchAllRecordList = () => {
    dispatch(enableLoading());
    api
      .get(`/builder/fix-spots/sites/${props.match.params.id}`)
      .then(res => {
        setAllRecordList(res.data);
        dispatch(finishLoading());
        setIsLoading(false);
      })
      .catch(error => {
        dispatch(finishLoading());
        setIsLoading(false);
        if (error.response.status === 403) {
          props.history.push("/not-found");
        }
      });
  };

  useEffect(() => {
    fetchAllRecordList();
  }, []);

  return (
    <div style={{ padding: "20px 38px" }}>
      {isLoading ? (
        <div>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <React.Fragment>
          <Grid container spacing={2} style={{ marginBottom: "8px" }}>
            <Grid item xs={9} style={{}}>
              <Typography
                variant="h6"
                className="primary-font-family  valign-wrapper"
                style={{
                  width: "100%",
                  display: "block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "26px",
                  maxWidth: "70vw"
                }}
              >
                現場名: {allRecordList.name}
              </Typography>
            </Grid>
            <Grid item xs={3} style={{}}>
              <button
                style={{
                  backgroundColor: "#404040",
                  touchAction: "manipulation",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30px",
                  width: "100px",
                  borderRadius: "5px",
                  float: "right"
                }}
                onClick={() => {
                  history.push("/fix-spots");
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowCircleLeft}
                  className="icon-margin"
                  color="white"
                />
                <span
                  style={{
                    color: "white"
                  }}
                >
                  一覧に戻る
                </span>
              </button>
            </Grid>
          </Grid>

          <div>
            <TableContainer className="table-remove-overflow">
              <Table
                size="small"
                stickyHeader={true}
                aria-label="sticky table"
                style={{ marginBottom: isTabletOrMobile ? "20px" : "0px" }}
              >
                <TableBody className="table-body">
                  <TableRow style={{ backgroundColor: "#404040" }}>
                    <TableCell
                      className="white-text text-center"
                      style={{
                        top: isMobile || isTablet ? "63px" : "79px",
                        width: "200px",
                        borderRadius: "0px",
                        padding: "6px 10px",
                        minWidth: "76px",
                        backgroundColor: "#404040 !important"
                      }}
                    >
                      撮影日時
                    </TableCell>

                    <TableCell
                      className="white-text text-center"
                      style={{
                        top: isMobile || isTablet ? "63px" : "79px",
                        width: "200px",
                        borderRadius: "0px",
                        padding: "6px 10px",
                        minWidth: "76px",
                        backgroundColor: "#404040 !important"
                      }}
                    >
                      地点情報修復状態
                    </TableCell>

                    {/* <TableCell
                      className="white-text text-center"
                      style={{
                        top: isMobile || isTablet ? "63px" : "79px",
                        width: "160px",
                        borderRadius: "0px",
                        padding: "6px 10px",
                        backgroundColor: "#404040 !important"
                      }}
                    >
                    </TableCell> */}
                  </TableRow>
                  {allRecordList.records &&
                    allRecordList.records.length > 0 &&
                    allRecordList.records.map(record => (
                      <TableRow
                        key={record.uniqueCode}
                        className={classes.tableRowHover}
                        onClick={() => {
                          history.push(
                            "/fix-spots/records/" + record.uniqueCode
                          );
                        }}
                      >
                        <TableCell
                          className="text-center"
                          style={{
                            padding: "10px"
                          }}
                        >
                          <p>
                            {format(
                              new Date(record.createdAt),
                              "yyyy年MM月dd日"
                            )}{" "}
                            ({days[new Date(record.createdAt).getDay()]}){" "}
                            {format(new Date(record.createdAt), "HH:mm")}
                          </p>
                        </TableCell>

                        <TableCell
                          className="text-center"
                          style={{
                            padding: "10px"
                          }}
                        >
                          <p style={{ color: "red" }}>
                            {record.fixed ? "修復済み" : ""}
                          </p>
                        </TableCell>

                        {/* <TableCell
                          className="text-right"
                          style={{
                            padding: "10px",
                            textAlign: "right"
                          }}
                        >
                          {record.fixed && (<button
                            style={{
                              backgroundColor: "#FF0000",
                              touchAction: "manipulation",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "30px",
                              width: "100px",
                              borderRadius: "5px",
                              float: "right"
                            }}
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            <span style={{
                              color: "white"
                            }}>修復前に戻す</span>
                          </button>)}

                        </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default ListRecordFixSpots;
