import React from "react";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Button,
  Grid,
  Tooltip,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/ChevronRight";

import { TYPE_DIALOG, MODE_DEVICE } from "../sites/site/LogWalkViewer/constants";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DrawingPhoto from "./DrawingPhoto";


class DialogViewer extends React.Component {
  cancelSubmission = false;
  constructor(props) {
    super(props);
    this.state = {
      urlPhoto: undefined,
      positions: [],
      listSpots: [],
      drawingName: "",
      drawing: {},
      isSwitch: false
    };
    this.transformComponentRef = React.createRef();
  }
  componentWillMount() {
    window.addEventListener("resize", this.updateDimensions);
    window.addEventListener("resize", this.setScreenOrientation);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    if (this.state) {
      this.setScreenOrientation();
    }

  }

  componentWillUnmount() {
    this.cancelSubmission = true;
  }

  setPositionMarker = () => {
    if (!this.cancelSubmission) {
      const posAfter = [];
      this.props.listSpots.map(pos => {
        let element = document.getElementById("primary-image-dialog");
        let offsetWidth = element ? element.offsetWidth : 0;

        let offsetHeight = element ? element.offsetHeight : 0;

        let naturalWidth = element ? element.naturalWidth : 0;

        let naturalHeight = element ? element.naturalHeight : 0;

        let offsetLeft = element ? element.offsetLeft : 0;

        let offsetTop = element ? element.offsetTop : 0;

        posAfter.push({
          posX: (pos.x * offsetWidth) / naturalWidth + offsetLeft,
          posY: (pos.y * offsetHeight) / naturalHeight + offsetTop
        });
      });
      this.setState({
        positions: posAfter
      });
    }
  };

  setScreenOrientation = e => {
    if (!this.cancelSubmission) {
      if (window.matchMedia("(orientation: portrait)").matches) {
        this.setState({
          screenOrientation: "portrait"
        });
      }
      if (window.matchMedia("(orientation: landscape)").matches) {
        // window.scrollTo(0, 1);
        this.setState({
          screenOrientation: "landscape"
        });
      }
    }
  };
  updateDimensions = e => {
    this.setPositionMarker();
  };

  returnDefaultSize = () => {
    let currentRef = this.transformComponentRef.current;
    if (currentRef != null) {
      currentRef.context.dispatch.resetTransform();
    }
  };

  handleCloseDialog = () => {
    this.props.onClose(false);
  };

  render() {
    const { screenOrientation } =
      this.state;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleCloseDialog}
        aria-labelledby="max-width-dialog-title"
        fullWidth
        maxWidth="lg"
        scroll="body"
        PaperProps={{
          // className: "dialog-min-height container-mobile-header"
          className:
            this.props.modeDevice === MODE_DEVICE.SP
              ? "  container-mobile-header dialog paper-image drawing-dialog-height-update-sp m-0"
              : " container-mobile-header dialog paper-image drawing-dialog-height-update m-0"
        }}
      >
        {this.props.modeDevice === MODE_DEVICE.SP &&
          screenOrientation === "portrait" && (
            <Grid
              container
              // spacing={2}
              justify="space-between"
              className="container-mobile-header dialog-viewer-header-container mb-5"
            >
              <Grid item className="text-center">
                {
                  this.props.modeDevice === MODE_DEVICE.SP && (
                    <Fab onClick={this.handleCloseDialog} size="small">
                      <ArrowBackIcon />
                    </Fab>
                  )}
              </Grid>
              <Grid item className="viewer-dialog-title">
                <Typography variant="h6"></Typography>
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
          )}
        {(this.props.modeDevice === MODE_DEVICE.PC ||
          (this.props.modeDevice === MODE_DEVICE.SP &&
            screenOrientation === "portrait")) && (
          <div className="drawing-dialog-bg">
            <DialogTitle
              disableTypography
              className={
                this.props.modeDevice === MODE_DEVICE.SP
                  ? "dialog-viewer-header-update-sp"
                  : "dialog-viewer-header-update"
              }
            > 
              <Grid
                container
                spacing={2}
                justify="space-between"
                className="container-mobile-header"
              >
                <Grid item className="text-center position-btn">
                  {
                    this.props.modeDevice !== MODE_DEVICE.SP && (
                      <Fab onClick={this.handleCloseDialog} size="small">
                        <ArrowBackIcon />
                      </Fab>
                    )}
                </Grid>
                <Grid
                  item
                  className="viewer-dialog-title dialog-viewer-header-title"
                >
                  <Typography variant="h6">{this.props.drawingName}</Typography>
                </Grid>
                <Grid item className="text-center position-btn">
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent className="viewer-content-dialog-update overflow-hidden ">
              <Grid
                container
                spacing={0}
                className={
                  this.props.modeDevice !== MODE_DEVICE.SP
                    ? "padding-bottom-8"
                    : ""
                }
              >
                {this.props.modeDevice === MODE_DEVICE.PC && (
                  <Grid item xs={1} className="position-btn">
                  </Grid>
                )}
                <Grid
                  item
                  xs={this.props.modeDevice === MODE_DEVICE.PC ? 10 : 12}
                  md={10}
                >
                  <div
                    className={`text-center 
                    ${
                      this.props.modeDevice !== MODE_DEVICE.SP
                        ? "content-drawing-dialog"
                        : "content-drawing-dialog-sp"
                    } `}
                  >
                    <TransformWrapper defaultScale={1}>
                      <TransformComponent ref={this.transformComponentRef}>
                        <div
                          className={`img-mini-parent-dialog-sp`}
                        >
                          <DrawingPhoto
                            onClick={this.tapToShowFullImage}
                            class="img-mini-dialog"
                            id="primary-image-dialog"
                            alt=""
                            loadMarker={this.setPositionMarker}
                            positions={this.state.positions}
                            drawingUrl={this.props.urlPhoto}
                            drawingBlob={this.props.drawingBlob}
                            drawingName={this.props.drawingName}
                          />
                        </div>
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
                </Grid>
                {this.props.modeDevice === MODE_DEVICE.PC && (
                  <Grid
                    item
                    xs={1}
                    md={1}
                    className="drawing-dialog-btn-landscape"
                    container
                    alignItems="center"
                  >
                  </Grid>
                )}
              </Grid>
              {this.props.modeDevice === MODE_DEVICE.PC && (
                <Grid
                  container
                  spacing={0}
                  justify="space-between"
                  className="container-mobile-header mb-5 drawing-dialog-footer"
                >
                  <Grid item xs={2} className="text-center"></Grid>
                  <Grid item xs={8} className="drawing-dialog-title ">
                  </Grid>
                  <Grid item xs={2} className="text-right ">
                  </Grid>
                </Grid>
              )}
            </DialogContent>
          </div>
        )}
        {this.props.modeDevice === MODE_DEVICE.SP &&
          screenOrientation === "portrait" && (
            <Grid
              container
              spacing={0}
              justify="space-between"
              className="container-mobile-header mb-5 drawing-dialog-footer"
            >
              <Grid
                item
                xs={12}
                className="drawing-dialog-title text-color-white"
              >
              </Grid>
            </Grid>
          )}

        {this.props.modeDevice === MODE_DEVICE.SP &&
          screenOrientation === "landscape" && (
            <Grid
              container
              spacing={0}
              className="drawing-dialog-container-landscap"
            >
              <Grid item xs={2}>
                <Grid
                  container
                  direction="column"
                  justify="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid
                    md={6}
                    alignItems="center"
                    container
                    item
                    className="position-btn"
                  >
                    {
                      this.props.modeDevice === MODE_DEVICE.SP && (
                        <Tooltip
                          title="Exit"
                          className="DialogViewer-btn-header-sp"
                        >
                          <Fab onClick={this.handleCloseDialog} size="small">
                            <ArrowBackIcon />
                          </Fab>
                        </Tooltip>
                      )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} className="">
                <DialogContent className="drawing-dialog-content-landscap">
                  <Grid
                    container
                    direction="column"
                    justify="flex-end"
                    alignItems="center"
                    className=""
                  >
                    <Grid
                      container
                      item
                      className="drawing-dialog-bg viewer-dialog-title dialog-viewer-header-title"
                    >
                      <Grid
                        container
                        spacing={0}
                        justify="space-between"
                        className="container-mobile-header"
                      >
                        <Grid item xs={2} className="text-center position-btn">
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          className="viewer-dialog-title dialog-viewer-header-title"
                        >
                          <Typography variant="h6">
                            {this.props.drawingName}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} className="text-center position-btn">
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      className="viewer-content-dialog-update  drawing-dialog-bg"
                    >
                      <div
                        className={`text-center 
                    ${
                      this.props.modeDevice !== MODE_DEVICE.SP
                        ? "content-drawing-dialog"
                        : "content-drawing-dialog-sp"
                    } `}
                      >
                        <TransformWrapper defaultScale={1}>
                          <TransformComponent ref={this.transformComponentRef}>
                            <div
                              className={`img-mini-parent-dialog-sp`}
                            >
                              <DrawingPhoto
                                onClick={this.tapToShowFullImage}
                                class="img-mini-dialog"
                                id="primary-image-dialog"
                                alt=""
                                loadMarker={this.setPositionMarker}
                                positions={this.state.positions}
                                drawingUrl={this.props.urlPhoto}
                                drawingBlob={this.props.drawingBlob}
                                drawingName={this.props.drawingName}
                              />
                            </div>
                          </TransformComponent>
                        </TransformWrapper>
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Grid>
              <Grid item xs={2}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  style={{ height: "100%" }}
                >
                  <Grid
                    container
                    item
                    className="position-btn justify-content-flex-start"
                    alignItems="center"
                    style={{ height: "50%" }}
                  >
                  </Grid>

                  <Grid
                    container
                    alignItems="center"
                    item
                    className="position-btn justify-content-flex-end"
                    style={{ height: "50%" }}
                  >
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item className="drawing-dialog-title">
              </Grid>
            </Grid>
          )}
      </Dialog>
    );
  }
}

export default withRouter(DialogViewer);
