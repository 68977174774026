import axios from "axios";
import {
  GET_ERRORS,
} from "./types";

const URL = "/api/sites";

export const fetch = id => dispatch => {
  let url = URL;
  if(id != null){
    url += "/" + id;
  }
  return axios
    .get(url)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const index = id => dispatch => {
  return axios
    .get(URL + '/' + id + '/index')
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const entries = () => dispatch => {
  return axios
    .get(URL + '/entries')
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const menus = () => dispatch => {
  return axios
    .get(URL + '/menus')
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const edit = id => dispatch => {
  return axios
    .get(URL + "/" + id + '/edit')
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const info = id => dispatch => {
  return axios
    .get(URL + "/" + id + '/info')
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const post = data => dispatch => {
  return axios
    .post(URL, data)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const put = data => dispatch => {
  const id = data.UniqueCode;
  return axios
    .put(URL + "/" + id, data)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const remove = id => dispatch => {
  return axios
    .delete(URL + "/" + id)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
