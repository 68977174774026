import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import CommonDialog from "../../CommonDialog";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";
import { apiError } from "../../../common/Stores/action/action";
import { translateMessage } from "utils/mapper";
import { updateStaffPaswordLogBuild } from "./service/service";

class ChangePasswordLogBuildDialog extends React.Component {
  fieldList = [
    {
      name: "password",
      label: "新しいパスワード",
      type: DIALOG_FIELD_TYPES.Password,
      isRequired: true
    },
    {
      name: "password_confirmation",
      label: "新しいパスワード（再入力）",
      type: DIALOG_FIELD_TYPES.Password,
      isRequired: true
    }
  ];

  onSubmit = value => {
    const { message } = this.props;
    const { password, password_confirmation } = value;
    const messageError = {
      password: "",
      password_confirmation: ""
    };
    this.props.apiError();
    if (password || password_confirmation) {
      if (
        password_confirmation === password &&
        password.match(this.props.regex["password"])
      ) {
        this.props.updateStaffPaswordLogBuild(this.props.staffId, {
          password: password
        });
      } else {
        if (password && password_confirmation !== password) {
          messageError["password_confirmation"] = translateMessage(
            this.getMessage("match", message),
            this.props.regexObject
          );
        }
      }
    }

    if (password && !password.match(this.props.regex["password"])) {
      messageError["password"] = translateMessage(
        this.getMessage("password", message),
        this.props.regexObject
      );
    }

    if (!password) {
      messageError["password"] = translateMessage(
        this.getMessage("password", message),
        this.props.regexObject
      );
    }

    if (!password_confirmation) {
      messageError["password_confirmation"] = translateMessage(
        this.getMessage("required", message),
        this.props.regexObject
      );
    }

    if (!password && !password_confirmation) {
      messageError["password"] = translateMessage(
        this.getMessage("password", message),
        this.props.regexObject
      );
      messageError["password_confirmation"] = translateMessage(
        this.getMessage("required", message),
        this.props.regexObject
      );
    }
    this.props.apiError(messageError);
  };

  getMessage = (key, message) => {
    return message[key];
  };

  componentWillUnmount() {
    this.props.apiError(null);
  }

  render() {
    const { isDialogShow, submitErrors, initialValues, onClose } = this.props;
    return (
      <CommonDialog
        title={"パスワード変更"}
        isDialogShow={isDialogShow}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        onClose={onClose}
        fieldList={this.fieldList}
        submitButtonTitle={"登録"}
        submitErrors={submitErrors}
        isSingleColumn={true}
        onDeleteClick={this.onDeleteClick}
      />
    );
  }
}

ChangePasswordLogBuildDialog.propTypes = {
  submitErrors: PropTypes.object,
  isDialogShow: PropTypes.bool,
  onClose: PropTypes.func
};

const mapStateToProps = state => ({
  submitErrors: state.storesReducer.error,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      apiError,
      updateStaffPaswordLogBuild
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordLogBuildDialog);
