import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import imageSrc from "../../../../common/img/log.png";

import { convertSerialNumber } from "../../../../utils/mapper";
import { fetchRobotLogBuildDetailInfo } from "./service/service";
import { getRobotDetailInfo } from "./reducer/reducer";

class RobotLogBuildInfo extends Component {
  componentDidMount() {
    this.props.fetchRobotLogBuildDetailInfo(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.props.fetchRobotLogBuildDetailInfo(nextProps.match.params.id);
    }
  }

  render() {
    const { robotLogBuildInfo } = this.props;

    return (
      <Grid className="logbuild-tab-info">
        <Card className="log-box">
          <Grid className="wrap-image">
            <CardMedia image={imageSrc} className="log-image" />
          </Grid>
          <CardContent className="logbuild-robot-info">
            <TableContainer>
              <Table padding="none" aria-label="table">
                <TableBody className="">
                  <TableRow>
                    <TableCell>製造番号</TableCell>
                    <TableCell>
                      {robotLogBuildInfo.robotNo}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>管理番号</TableCell>
                    <TableCell>
                      {robotLogBuildInfo.serialNumber &&
                        `#${convertSerialNumber(
                          robotLogBuildInfo.serialNumber
                        )}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>ロボットID</TableCell>
                    <TableCell>{robotLogBuildInfo.robotId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>シリアルナンバー</TableCell>
                    <TableCell>{robotLogBuildInfo.cerealCode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Skyway ID</TableCell>
                    <TableCell>{robotLogBuildInfo.skyWayAPIKey}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  robotLogBuildInfo: getRobotDetailInfo(
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailInfoReducer
      .robotLogBuildInfo
  )
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRobotLogBuildDetailInfo
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RobotLogBuildInfo));
