import {
  FETCH_ROBOT_ENTRIES_SUCCESS,
  FETCH_ROBOT_LOG_BUILD_ENTRIES_SUCCESS,
  FETCH_LOG_BUILD_BUILDER_ENTRIES_SUCCESS,
  FETCH_LOG_BUILD_STAFF_ENTRIES_SUCCESS
} from "../constant/constant";

const initialState = {
  robotEntries: [],
  builderEntries: [],
  staffEntries: []
};
export const robotEntriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROBOT_ENTRIES_SUCCESS:
      return {
        ...state,
        type: action.type,
        robotEntries: action.robotEntries
      };
    case FETCH_ROBOT_LOG_BUILD_ENTRIES_SUCCESS:
      return {
        ...state,
        type: action.type,
        robotLogBuildEntries: action.robotLogBuildEntries
      };
    case FETCH_LOG_BUILD_BUILDER_ENTRIES_SUCCESS:
      return {
        ...state,
        type: action.type,
        builderEntries: action.builderEntries
      };
    case FETCH_LOG_BUILD_STAFF_ENTRIES_SUCCESS:
      return {
        ...state,
        type: action.type,
        staffEntries: action.staffEntries
      };

    default:
      return state;
  }
};
export const getRobotEntries = state => state;
export const getBuilderEntries = state => state;
export const getStaffEntries = state => state;
