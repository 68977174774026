import { MenuItem, Popover } from "@material-ui/core";
import React, { useRef, useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconInfo from "./icons/IconInfo";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import SearchIcon from "@material-ui/icons/Search";

const mediaQueryShowModeSP = "(max-width: 1560px)";
const useStyles = makeStyles({
  selectFieldWrapper: {
    width: "100%",
    height: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "0.5px solid #707070",
    borderRadius: "4px",
    cursor: "pointer",
    "& > div:first-child": {
      flexGrow: "1",
      padding: "0px 12px",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center"
    },
    "& > span:last-child": {
      color: "#C7C7C7",
      fontSize: "24px",
      lineHeight: "24px",
      width: "24px",
      height: "24px",
      marginRight: "4px"
    }
  },

  fieldLabel: {
    color: "#333333",
    fontSize: "12px",
    marginBottom: "2px"
  },

  customScrollbar: {
    "&::-webkit-scrollbar": {
      backgroundColor: "#fff",
      width: "16px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#babac0",
      borderRadius: "16px",
      border: "4px solid #fff"
    },
    "&::-webkit-scrollbar-button": {
      display: "none"
    }
  },

  resetFilterButton: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    marginTop: "16px",
    "& button": {
      height: "45px",
      width: "270px",
      borderRadius: "4px",
      backgroundColor: "#C7C7C7",
      color: "white",
      fontSize: "15px",
      lineHeight: "26px",
      fontWeight: "normal",
      padding: "9px 0",
      textAlign: "center",
      letterSpacing: "0"
    }
  },

  searchByText: {
    border: "0.5px solid #707070",
    borderRadius: "4px",
    height: "40px",
    overflow: "hidden",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
    backgroundColor: "#f8f9f9"
  },

  searchByTextInput: {
    flexGrow: "1",
    height: "100%",
    border: "none",
    backgroundColor: "#f8f9f9",
    paddingRight: "12px"
  },

  mainGrid: {
    margin: "0px 12px",
    flexGrow: "1",
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "stretch",
    marginBottom: "20px"
  }
});

function SelectField(props) {
  const { value, options, selectValue, label, showModeSP } = props;
  const [anchorElSelect, setAnchorElSelect] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState("0");

  const selectRef = useRef(null);
  const classes = useStyles();

  const handleClick = event => {
    setAnchorElSelect(event.currentTarget);
    setPopoverWidth(window.getComputedStyle(selectRef.current).width);
  };

  const getLabel = () => {
    if (options.length === 0 || !value) return "";
    const option = options.find(
      item => item.value.uniqueCode === value.uniqueCode
    );
    if (option) return option.label;
    return "";
  };

  const isSelected = option => {
    if (!value) return false;
    return value.uniqueCode === option.value.uniqueCode;
  };

  const getPopoverHeight = () => {
    let heightOptions = options.length * 40 + 24;
    if (heightOptions < 200) return heightOptions + "px";
    return "200px";
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <p className={classes.fieldLabel}>{label}</p>

        <div
          className={classes.selectFieldWrapper}
          onClick={handleClick}
          ref={selectRef}
          style={showModeSP ? { height: 35 } : {}}
        >
          <div>{getLabel()}</div>
          <span>
            <ArrowDropDownIcon fontSize="inherit" color="inherit" />
          </span>
        </div>
      </div>

      <Popover
        open={Boolean(anchorElSelect)}
        anchorEl={anchorElSelect}
        onClose={() => setAnchorElSelect(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            width: popoverWidth,
            overflow: "hidden",
            height: getPopoverHeight(),
            padding: "12px 0px"
          }
        }}
      >
        <div
          style={{ width: "100%", height: "100%", overflow: "auto" }}
          className={classes.customScrollbar}
        >
          {options.map(option => (
            <MenuItem
              onClick={() => {
                selectValue(option.value);
                setAnchorElSelect(null);
              }}
              style={{
                backgroundColor: isSelected(option) ? "#F8F9F9" : "white",
                height: "40px",
                minHeight: "40px",
                paddingTop: "0",
                paddingBottom: "0"
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </div>
      </Popover>
    </>
  );
}

function FilterInfoBox(props) {
  const {
    allIcons,
    allAuthors,
    allStatus,

    filterTitle,
    setFilterTitle,

    filterSiteName,
    setFilterSiteName,

    filterIcon,
    setFilterIcon,

    filterStatus,
    setFilterStatus,

    filterAuthor,
    setFilterAuthor,

    resetFilter,
    showModeSP
  } = props;
  const classes = useStyles();

  const getFilterStatuslabel = status => {
    if (status.uniqueCode === "all") return "すべて";
    if (status.uniqueCode === "incomplete") return status.label;
    return (
      <>
        <span
          style={{
            fontSize: "10px",
            color: "red",
            lineHeight: "inherit",
            marginRight: "3px"
          }}
        >
          <Brightness1Icon color="inherit" fontSize="inherit" />
        </span>
        完了
      </>
    );
  };
  return (
    <React.Fragment>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <h3
          style={{
            fontSize: "20px",
            lineHeight: "30px",
            marginBottom: "12px",
            color: "#00356D",
            fontWeight: "bold"
          }}
        >
          検索
        </h3>

        <div
          style={{
            display: "flex",
            flexGrow: showModeSP ? "1" : "unset",
            flexDirection: showModeSP ? "column" : "row",
            margin: "0px -12px"
          }}
        >
          <div className={classes.mainGrid}>
            <div className={classes.searchByText}>
              <div
                style={{
                  width: "28px",
                  height: "28px",
                  fontSize: "28px",
                  color: "#C7C7C7",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "8px"
                }}
              >
                <SearchIcon
                  fontSize="inherit"
                  color="inherit"
                  style={{ lineHeight: "24px" }}
                />
              </div>
              <input
                type="text"
                placeholder="現場名から検索"
                value={filterSiteName}
                className={classes.searchByTextInput}
                onChange={e => setFilterSiteName(e.target.value)}
                style={{
                  margin: "0px",
                  height: "100%",
                  borderBottom:'none',
                  backgroundColor: "#f8f9f9",
                  padding: "0px 12px"
                }}
              />
            </div>
          </div>
          <div className={classes.mainGrid}>
            <div className={classes.searchByText}>
              <div
                style={{
                  width: "28px",
                  height: "28px",
                  fontSize: "28px",
                  color: "#C7C7C7",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "8px"
                }}
              >
                <SearchIcon
                  fontSize="inherit"
                  color="inherit"
                  style={{ lineHeight: "24px" }}
                />
              </div>
              <input
                type="text"
                placeholder="タイトルから検索"
                value={filterTitle}
                className={classes.searchByTextInput}
                onChange={e => setFilterTitle(e.target.value)}
                style={{
                  margin: "0px",
                  height: "100%",
                  borderBottom:'none',
                  backgroundColor: "#f8f9f9",
                  padding: "0px 12px"
                }}
              />
            </div>
          </div>
          <div
            className={classes.mainGrid}
            style={{
              minWidth: "270px"
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={7} style={{ minWidth: "150px" }}>
                <SelectField
                  label="アイコン"
                  value={filterIcon}
                  options={allIcons.map(icon => {
                    return {
                      value: icon,
                      label:
                        icon.uniqueCode === "all" ? (
                          <span style={{ width: "100%" }}>すべて</span>
                        ) : (
                          <React.Fragment>
                            <IconInfo
                              color={icon.color}
                              width="30"
                              height="30"
                            />
                            <span style={{ flexGrow: "1", marginLeft: "10px" }}>
                              {icon.label}
                            </span>
                          </React.Fragment>
                        )
                    };
                  })}
                  selectValue={setFilterIcon}
                  showModeSP={showModeSP}
                />
              </Grid>
              <Grid item xs={5} style={{ minWidth: "100px" }}>
                <SelectField
                  label="状態"
                  value={filterStatus}
                  options={allStatus.map(status => {
                    return {
                      value: status,
                      label: (
                        <span style={{ width: "100%" }}>
                          {getFilterStatuslabel(status)}
                        </span>
                      )
                    };
                  })}
                  selectValue={setFilterStatus}
                  showModeSP={showModeSP}
                />
              </Grid>
            </Grid>
          </div>
          <div
            className={classes.mainGrid}
            style={{
              minWidth: "180px"
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <SelectField
                label="作成者での検索"
                value={filterAuthor}
                options={allAuthors.map(member => {
                  return {
                    value: member,
                    label: (
                      <span>
                        {member.uniqueCode === "all" ? "すべて" : member.name}
                      </span>
                    )
                  };
                })}
                selectValue={setFilterAuthor}
                showModeSP={showModeSP}
              />
            </div>
          </div>
        </div>

        <div
          className={classes.resetFilterButton}
          style={{
            justifyContent: showModeSP ? "center" : "flex-end",
            marginTop: "auto"
          }}
        >
          <button
            style={
              showModeSP
                ? {
                    marginTop: 0,
                    height: 35,
                    lineHeight: "19px"
                  }
                : {
                    marginTop: 0
                  }
            }
            onClick={resetFilter}
          >
            検索条件を元に戻して全て表示する
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FilterInfoBox;
