import React from "react";
import { connect } from "react-redux";
import CommonDialog from "../../CommonDialog";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";
import { apiError } from "../../../common/Stores/action/action";
import { PUT_SUPPLIER_STAFF_PASSWORD_SUCCESS } from "./constant/constant";
import { mountSupplierStaffPasswordDialog, updateSupplierStaffPassword } from "./service/service";
import { bindActionCreators } from "redux";
import { translateMessage } from "utils/mapper";

class CarpenterPasswordDialog extends React.PureComponent {
  state = {
    defaultValues: {
      password: "",
      confirm_password: "",
      validateMessage: {
        password: "",
        confirm_password: ""
      }
    },
    fieldList: [
      {
        name: "password",
        label: "新しいパスワード",
        type: DIALOG_FIELD_TYPES.Password,
        isRequired: true
      },
      {
        name: "confirm_password",
        label: "新しいパスワード（再入力）",
        type: DIALOG_FIELD_TYPES.Password,
        isRequired: true
      }
    ]
  };

  componentWillMount() {
    this.props.mountSupplierStaffPasswordDialog();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type === PUT_SUPPLIER_STAFF_PASSWORD_SUCCESS) {
      this.props.onClose();
    }
  }

  onSubmit = values => {
    const { message } = this.props;
    const { password, confirm_password } = values;
    const messageError = {
      password: "",
      confirm_password: ""
    };
    this.props.apiError();
    if (password || confirm_password) {
      if (
        confirm_password === password &&
        password.match(this.props.regex["password"])
      ) {
        this.props.updateSupplierStaffPassword(
          { password: password },
          this.props.carpenterId
        );
        return;
      } else {
        if (password && confirm_password !== password) {
          messageError["confirm_password"] = translateMessage(
            this.getMessage("match", message),
            this.props.regexObject
          );
        }
      }
    }

    if (password && !password.match(this.props.regex["password"])) {
      messageError["password"] = translateMessage(
        this.getMessage("password", message),
        this.props.regexObject
      );
    }

    if (!password) {
      messageError["password"] = translateMessage(
        this.getMessage("password", message),
        this.props.regexObject
      );
    }

    if (!confirm_password) {
      messageError["confirm_password"] = translateMessage(
        this.getMessage("required", message),
        this.props.regexObject
      );
    }

    if (!password && !confirm_password) {
      messageError["password"] = translateMessage(
        this.getMessage("password", message),
        this.props.regexObject
      );
      messageError["confirm_password"] = translateMessage(
        this.getMessage("required", message),
        this.props.regexObject
      );
    }
    this.props.apiError(messageError);
  };

  getMessage = (key, message) => {
    return message[key];
  };

  render() {
    const { defaultValues, fieldList } = this.state;
    const { isDialogShow, onClose, submitErrors } = this.props;
    return (
      <CommonDialog
        title={"パスワード変更"}
        isDialogShow={isDialogShow}
        initialValues={defaultValues}
        onSubmit={this.onSubmit}
        onClose={onClose}
        fieldList={fieldList}
        submitButtonTitle={"登録"}
        submitErrors={submitErrors}
        isSingleColumn={true}
      />
    );
  }
}

const mapStateToProps = state => ({
  submitErrors: state.storesReducer.error,
  type: state.carpentersReducer.carpentersReducer.type,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      mountSupplierStaffPasswordDialog: mountSupplierStaffPasswordDialog,
      updateSupplierStaffPassword: updateSupplierStaffPassword,
      apiError: apiError
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CarpenterPasswordDialog);
