import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import CommonDialog from "../CommonDialog";
import { DIALOG_FIELD_TYPES } from "../../common/Stores/constant/constant";
import DialogDelete from "../../common/DialogDelete";
import { postBuilder } from "./service/service";
import {
  fetchBuilder,
  putBuilder,
  deleteBuilder
} from "./shopDetail/service/service";
import { dispatchError } from "common/Stores/service/service";

class ShopDialog extends React.Component {
  state = {
    builders: {
      address: "",
      agreeMentDate: null,
      agreementUser: "",
      agreementUserMail: "",
      agreementUserPhone: "",
      cancellationDate: null,
      contractRenewDate: null,
      email: "",
      furigana: "",
      name: "",
      phone: "",
      uniqueCode: "",
      zipCode: ""
    },
    currentDateStatus: {}
  };

  defaultValues = {
    name: "",
    furigana: "",
    zipCode: "",
    address: "",
    phone: "",
    email: "",
    agreeMentDate: new Date(),
    contractRenewDate: null,
    agreementUser: "",
    agreementUserPhone: "",
    agreementUserMail: "",
    cancellationDate: null,
    uniqueCode: ""
  };

  fieldList = [
    {
      name: "name",
      label: "顧客名",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    },
    {
      name: "furigana",
      label: "顧客名（ふりがな全角）​",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    },
    {
      name: "zipCode",
      label: "郵便番号​",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    },
    {
      name: "address",
      label: "住所​",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    },
    {
      name: "phone",
      label: "電話番号​",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    },
    {
      name: "email",
      label: "メールアドレス",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    },
    {
      name: "agreeMentDate",
      label: "登録日​",
      type: DIALOG_FIELD_TYPES.DateTime,
      isRequired: true
    },
    {
      name: "contractRenewDate",
      label: "契約更新日​",
      type: DIALOG_FIELD_TYPES.DateTime,
      isRequired: true
    },
    {
      name: "agreementUser",
      label: "担当者名",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    },
    {
      name: "agreementUserPhone",
      label: "担当者電話番号",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    },
    {
      name: "agreementUserMail",
      label: "担当者メールアドレス",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    }
  ];

  fieldListEdit = [
    ...this.fieldList,
    {
      name: "cancellationDate",
      label: "利用停止日",
      type: DIALOG_FIELD_TYPES.DateTime
    }
  ];

  componentDidMount() {
    const { isEdit, builderUniqueCode, fetchBuilder } = this.props;
    if (isEdit && builderUniqueCode) {
      fetchBuilder(builderUniqueCode);
    }
  }

  componentWillUnmount() {
    this.props.dispatchError("");
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.builders !== nextProps.builder) {
      this.setState({
        builders: nextProps.builder
      });
    }
  }

  toggleDialogDelete = () => {
    this.props.toggleDialogDelete();
  };

  onSubmit = valuesForm => {
    const { currentDateStatus } = this.state;
    const { isEdit, builderUniqueCode, putBuilder, postBuilder } = this.props;
    for (let key in currentDateStatus) {
      valuesForm[key] = "Invalid Date";
    }
    if (isEdit) {
      putBuilder({ ...valuesForm, uniqueCode: builderUniqueCode }, () => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        this.props.history.go(0);
      });
    } else {
      postBuilder(valuesForm, () => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        this.props.history.go(0);
      });
    }
  };

  onDeleteSubmit = () => {
    this.props.deleteBuilder(this.props.builderUniqueCode, () => {
      alert("エラーが発生しました。 お手数ですが、再度お試しください。");
      this.props.history.go(0);
    });
  };

  currentDate = (fieldName, date) => {
    if (date?.toString() === "Invalid Date") {
      this.setState({
        currentDateStatus: {
          ...this.state.currentDateStatus,
          [fieldName]: ""
        }
      });
    } else {
      const newDateStatus = { ...this.state.currentDateStatus };
      delete newDateStatus[fieldName];
      this.setState({
        currentDateStatus: newDateStatus
      });
    }
  };

  getMessage = (key, message) => {
    return message[key];
  };

  render() {
    const {
      isDialogShow,
      onCloseDialog,
      isEdit,
      submitErrors,
      builder,
      isDialogDeleteShow
    } = this.props;
    const { builders } = this.state;
    if (builders?.agreementUser === null) {
      builders.agreementUser = "";
    }

    if (builders?.agreementUserMail === null) {
      builders.agreementUserMail = "";
    }

    if (builders?.agreementUserPhone === null) {
      builders.agreementUserPhone = "";
    }

    return (
      <>
        <CommonDialog
          title={isEdit ? "顧客情報編集" : "顧客情報登録​"}
          isDialogShow={isDialogShow}
          initialValues={isEdit && builder ? builders : this.defaultValues}
          onSubmit={this.onSubmit}
          isEdit={isEdit}
          onClose={onCloseDialog}
          fieldList={isEdit ? this.fieldListEdit : this.fieldList}
          submitButtonTitle={"登録"}
          submitErrors={submitErrors}
          onDeleteClick={this.toggleDialogDelete}
          currentDate={this.currentDate}
        />
        <DialogDelete
          open={isDialogDeleteShow}
          onClose={this.toggleDialogDelete}
          PaperProps={{
            className: "dialog dialog-sign-up"
          }}
          onDeleteSubmit={this.onDeleteSubmit}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  submitErrors: state.storesReducer.error,
  builder: state.builderReducer.builderDetailReducer.builder
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postBuilder,
      fetchBuilder,
      putBuilder,
      dispatchError,
      deleteBuilder
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShopDialog));
