import React, { Component } from "react";
import { format } from "date-fns";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

class RobotHistorySP extends Component {
  render() {
    return (
      <Grid style={{width: '100%', paddingBottom: 50}}>
        {this.props.robotHistory &&
          this.props.robotHistory.map((row, index) => (
            <TableContainer key={index} style={{paddingBottom: 20}}>
              <Table component="div" aria-label="table">
                <TableRow component="span">
                  <TableCell
                    variant="head"
                    component="span"
                    className="white-text table-head"
                  >
                    現場名
                  </TableCell>
                  <TableCell
                    component="span"
                    style={{borderTop: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)'}}
                  >
                    {row.siteName}
                  </TableCell>
                </TableRow>
                <TableRow component="span">
                  <TableCell
                    variant="head"
                    component="span"
                    className="white-text table-head"
                  >
                    割当担当者
                  </TableCell>
                  <TableCell
                    component="span"
                    style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}
                  >
                    {row.assignStartUser}
                  </TableCell>
                </TableRow>
                <TableRow component="span">
                  <TableCell
                    variant="head"
                    component="span"
                    className="white-text table-head"
                  >
                    割当日​
                  </TableCell>
                  <TableCell
                    component="span"
                    style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}
                  >
                    {row.assignStartDate &&
                      format(new Date(row.assignStartDate), "yyyy年M月d日")}
                  </TableCell>
                </TableRow>
                <TableRow component="span">
                  <TableCell
                    variant="head"
                    component="span"
                    className="white-text table-head"
                  >
                    配置終了担当者
                  </TableCell>
                  <TableCell
                    component="span"
                    style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}
                  >
                    {row.assignEndUser}
                  </TableCell>
                </TableRow>
                <TableRow component="span">
                  <TableCell
                    variant="head"
                    component="span"
                    className="white-text table-head"
                  >
                    配置終了日​
                  </TableCell>
                  <TableCell
                    component="span"
                    style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}
                  >
                    {row.assignEndDate &&
                      format(new Date(row.assignEndDate), "yyyy年M月d日")}
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          ))
        }
      </Grid>
    );
  }
}

export default RobotHistorySP;