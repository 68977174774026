import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { api } from "../../../common/api/api";
import IconInfoDialog from "./IconInfoDialog";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import Button from "@material-ui/core/Button";
import { fetchListIconInfos } from "./fakeAPI";
import AddIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "./CustomTableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconColor from "./IconColor";

import IconEdit from "../SvgIcon/IconEdit";
import IconDelete from "../SvgIcon/IconDelete";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  rootPC: {
    padding: "20px 30px 0px"
  },
  rootSP: {
    padding: "20px 10px 0px"
  },

  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "black",
    margin: "10px 0px"
  },
  buttonCreateIcon: {
    fontSize: "14px",
    fontWeight: "bold",
    padding: "6px 8px",
    color: "#162f50",
    borderRadius: "4px"
  },
  listIcons: {
    padding: "12px"
  },
  icon: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    overflow: "hidden"
  },
  activeButton: {
    color: "#00356D",
    // cursor: "pointer"
  },
  disableButton: {
    color: "#C7C7C7",
    // cursor: "default"
  }
});

function IconInfoSetting(props) {
  const { isTabletOrMobile, isTiniMobile } = props;
  const classes = useStyles();
  const history = useHistory();

  const [listIconInfos, setListIconInfos] = useState([]);
  const [createdIconInfo, setCreatedIconInfo] = useState(false);
  const [editIconInfo, setEditIconInfo] = useState(null);
  const [deleteIconInfo, setDeleteIconInfo] = useState(null);
  const [postable, setPostable] = useState(false);

  useEffect(() => {
    loadIconInfo();
  }, []);

  const loadIconInfo = () => {
    Promise.all([
      api.get(`/builder/info-icons`),
      api.get(`/builder/info-icons/state`)
    ]).then(res => {
      setListIconInfos(res[0].data);
      setPostable(res[1].data.postable);
    });
  };
  const handleCreateIconInfo = data => {
    api
      .post(`/builder/info-icons`, data)
      .then(res => {
        closeIconInfoDialog();
        loadIconInfo();
      })
      .catch(error => {
        if (error.response.status !== 404) {
          alert("エラーが発生しました。 お手数ですが、再度お試しください。");
          history.go(0);
        }
      });
  };

  const handleEditIconInfo = (infoIconsUC, data) => {
    api
      .put(`/builder/info-icons/${infoIconsUC}`, data)
      .then(res => {
        closeIconInfoDialog();
        loadIconInfo();
      })
      .catch(error => {
        if (error.response.status !== 404) {
          alert("エラーが発生しました。 お手数ですが、再度お試しください。");
          history.go(0);
        }
      });
  };
  const handleDeleteIconInfo = infoIconsUC => {
    api
      .delete(`/builder/info-icons/${infoIconsUC}`)
      .then(() => {
        closeIconInfoDialog();
        loadIconInfo();
      })
      .catch(error => {
        if (error.response.status !== 404) {
          alert("エラーが発生しました。 お手数ですが、再度お試しください。");
          history.go(0);
        }
      });
  };

  const closeIconInfoDialog = () => {
    setCreatedIconInfo(false);
    setEditIconInfo(null);
    setDeleteIconInfo(null);
  };

  return (
    <React.Fragment>
      <div
        className={isTabletOrMobile ? classes.rootSP : classes.rootPC}
        style={
          isTiniMobile ? { paddingLeft: "0px", paddingRight: "0px" } : null
        }
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={classes.title}>アイコン設定</div>
          <Button
            variant="text"
            className={classes.buttonCreateIcon}
            onClick={() => {
              if (postable) {
                setCreatedIconInfo(true);
              }
            }}
            style={{ color: postable ? "#00356D" : "#C7C7C7" }}
          >
            <AddIcon
              style={{ fontSize: isTabletOrMobile ? 30 : 40 }}
              color={postable ? "#00356D" : "#C7C7C7"}
            />
            アイコン追加
          </Button>
        </div>
        <TableContainer>
          <Table
            component="div"
            aria-label="table"
            className="carpenter-table"
            style={{
              whiteSpace: "nowrap",
              fontSize: isTabletOrMobile ? "12px" : "17px"
            }}
          >
            <TableHead component="div" className="table-head">
              <TableRow component="span">
                <TableCell
                  isTabletOrMobile={isTabletOrMobile}
                  isTiniMobile={isTiniMobile}
                  cellHeader
                >
                  ラベル
                </TableCell>
                <TableCell
                  isTabletOrMobile={isTabletOrMobile}
                  isTiniMobile={isTiniMobile}
                  cellHeader
                >
                  カラー
                </TableCell>
                <TableCell
                  isTabletOrMobile={isTabletOrMobile}
                  isTiniMobile={isTiniMobile}
                  cellHeader
                ></TableCell>
                <TableCell
                  isTabletOrMobile={isTabletOrMobile}
                  isTiniMobile={isTiniMobile}
                  cellHeader
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody component="div" className="table-body">
              {listIconInfos.length !== 0 &&
                listIconInfos.map(row => (
                  <TableRow
                    key={row.uniqueCode}
                    component="span"
                    className="table-row"
                  >
                    <TableCell
                      isTabletOrMobile={isTabletOrMobile}
                      isTiniMobile={isTiniMobile}
                    >
                      {row.label}
                    </TableCell>
                    <TableCell
                      isTabletOrMobile={isTabletOrMobile}
                      isTiniMobile={isTiniMobile}
                    >
                      <div style={{ height: "30px" }}>
                        <IconColor
                          width="30px"
                          height="30px"
                          color={row.color}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      isTabletOrMobile={isTabletOrMobile}
                      isTiniMobile={isTiniMobile}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          color: "#00356D"
                        }}
                      >
                        <span style={{ cursor: "pointer", display: "flex", lineHeight: "32px" }} onClick={() => setEditIconInfo(row)}>
                          編集
                          <IconEdit />
                        </span>
                      </div>
                    </TableCell>
                    <TableCell
                      isTabletOrMobile={isTabletOrMobile}
                      isTiniMobile={isTiniMobile}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        className={
                          row.deletable
                            ? classes.activeButton
                            : classes.disableButton
                        }
                      >
                        <span style={{ cursor: row.preset ? "default" : "pointer", display: "flex", lineHeight: "32px" }} onClick={() => {
                          if (row.deletable) {
                            setDeleteIconInfo(row);
                          }
                        }}>削除
                          <IconDelete
                            color={row.deletable ? "#00356D" : "#C7C7C7"}
                          />
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <IconInfoDialog
        createdIconInfo={createdIconInfo}
        editIconInfo={editIconInfo}
        // deleteIconInfo={deleteIconInfo}
        handleCreateIconInfo={handleCreateIconInfo}
        handleEditIconInfo={handleEditIconInfo}
        handleDeleteIconInfo={handleDeleteIconInfo}
        closeIconInfoDialog={closeIconInfoDialog}
      />

      {deleteIconInfo && <DeleteConfirmDialog
        open={deleteIconInfo ? true : false}
        iconInfo={deleteIconInfo}
        handleDeleteIconInfo={handleDeleteIconInfo}
        closeIconInfoDialog={closeIconInfoDialog}
      />}
    </React.Fragment>
  );
}
IconInfoSetting.propTypes = {};
export default IconInfoSetting;