import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { api } from "../../../../../../common/api/api";
import emptyImg from "../../../../../../common/img/empty.jpg";

function ViewThumbnail(props) {
  const { id, blob, type } = props;

  const [img, setImg] = useState(null);

  const location = useLocation();
  useEffect(() => {
    if (id || blob) {
      fetch();
    }
  }, [id, blob]);

  function fetch() {
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    var isBlob = blob ? true : false;
    const url = isBlob ? blob.resized.replace(/^\/api/, '') : `/${classification}/site-${type === "file" ? "files" : "photos"}/${id}/thumbnail`;
    api
      .get(url, {
        responseType: isBlob ? undefined : "arraybuffer"
      })
      .then(res => {
        if (res.data === undefined) return Promise.reject();
        if (isBlob) {
          var url = res.data.blobData;
        } else {
          var blob = new Blob([res.data], { type: "image/jpeg" });
          var url = URL.createObjectURL(blob);
        }
        return url;
      })
      .then(url => setImg(renderImg(url)))
      .catch(e => {
        if (e?.response?.status === 404) {
          setImg(renderImg(""));
        }
      });
  }

  function renderImg(data) {
    return (
      <img
        src={data || emptyImg}
        style={{ width: "100%", height: "auto" }}
        alt=""
      />
    );
  }

  return img;
}

ViewThumbnail.propTypes = {};

export default ViewThumbnail;
