import React from "react";

function Icon({ check }) {
  return (
    <svg width="16" height="16" viewBox="0 0 482.239 482.239">
      <path d="M465.016 0H17.223C7.703 0 0 7.703 0 17.223v447.793c0 9.52 7.703 17.223 17.223 17.223h447.793c9.52 0 17.223-7.703 17.223-17.223V17.223c0-9.52-7.703-17.223-17.223-17.223zm-17.223 447.793H34.446V34.446h413.348v413.347z"></path>
      {check && (
        <path d="M373.856 184.405l-24.354-24.354-142.828 142.828-73.937-73.937-24.354 24.354 98.291 98.291z"></path>
      )}
    </svg>
  );
}

export default Icon;
