import Component from "../../Component";

export default class Common extends Component {
  sitesUC = null;

  componentWillMount() {
    this.init(this.props);
    this.fetch();
  }

  componentWillReceiveProps(props) {
    if (!this.isChanged(props)) return;
    this.init(props);
    this.fetch();
    this.closeDialog();
  }

  isChanged(props) {
    return this.props.sitesUC !== props.sitesUC;
  }

  init(props) {
    this.sitesUC = props.sitesUC;
  }

  fetch() {}

  closeDialog() {}
}
