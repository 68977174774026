import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import InputControl from "../../common/InputControl";
import Grid from "@material-ui/core/Grid";
import { Field } from "formik";
import "../../common/css/base.css";
import "../../common/css/auth.css";

class ForgotPasswordBuilderPC extends Component {
  render() {
    return (<>
      <Grid className="area-login-wrap">
        <Grid container justify="space-between">
          <Grid className="area-logo" />
          <Grid className="area-login">
            <Grid>
              <h4 className="ttl-login">パスワードのリセット</h4>
              {this.props.emailConfirm ? (
                <p className="txt-login-sub">
                  パスワード再設定のためのメールを送信しました。​
                  <br />
                  メールを確認して下さい。
                </p>
              ) : (
                <p className="txt-login-sub">
                  メールアドレスを入力して下さい。
                  <br />
                  パスワード再設定のためのメールを送信します。
                </p>
              )}
            </Grid>
            <Grid className="wrap-form">
              {!this.props.emailConfirm && (
                <Grid>
                  <Field
                    label="メールアドレス​"
                    name="id"
                    type="text"
                    onChange={this.props.formik.handleChange}
                    variant="filled"
                    className="input-login"
                    component={InputControl}
                  />
                  <span className="required">
                    {this.props.error && this.props.error.id && (
                      <span className="this.props.error">{this.props.error.id}</span>
                    )}
                  </span>
                </Grid>
              )}
              <Grid container justify="center">
                {!this.props.emailConfirm ? (
                  <button type="submit" className="btn-login">
                    メールを送信​
                  </button>
                ) : (
                  <button type="button" className="btn-login btn-link">
                    <Link
                      to="/login"
                      className="white-text decorator-none"
                    >
                      ログイン画面に戻る
                    </Link>
                  </button>
                )}
                {!this.props.emailConfirm && (
                  <Link
                    to="/login"
                    className="return-login text-blue mt-20"
                  >
                    ログイン画面に戻る
                  </Link>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>);
  }
}

ForgotPasswordBuilderPC.propTypes = {
  formik: PropTypes.object.isRequired,
  emailConfirm: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

export default ForgotPasswordBuilderPC;
