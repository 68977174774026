import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonRobot from "../../common/ButtonRobot";
import Fab from "@material-ui/core/Fab";
import PlayCircleOutlineOutlinedIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import "../../common/css/base.css";
import "../../common/css/dashboard.css";
import { format } from "date-fns";
import VisibilityIcon from "@material-ui/icons/Visibility";
import RobotStatus from "common/RobotStatus";
import { BUTTON_LOGKUN_SP } from "common/constants/constants";

class DashboardBuilderSP extends Component {
  render() {
    const {
      dashboards,
      to,
      shouldEnableRobotButton,
      showDialog,
      robotsUpdate
    } = this.props;

    return (
      <Grid className="dashboard-sp">
        <Grid>
          <Typography
            variant="h5"
            className="dashboard-sp-title font-family text-bold"
          >
            担当現場
          </Typography>
          <MenuList>
            {dashboards.map(item => (
              <MenuItem
                onClick={to(item.uniqueCode)}
                key={item.uniqueCode}
                className="dashboard-sp-item"
              >
                <Grid
                  container
                  direction="column"
                  alignItems="stretch"
                  justify="space-between"
                  wrap="nowrap"
                >
                  <Grid item className="item-right">
                    <Typography
                      variant="subtitle2"
                      className="font-family grey-light s-ta-r"
                    >
                      {item.lastRecordedAt
                        ? (format(new Date(item.lastRecordedAt), "yyyy年M月d日 HH:mm 更新"))
                        : (format(new Date(item.regisionDate), "yyyy年M月d日 HH:mm 現場登録"))
                      }
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="space-between"
                      wrap="nowrap"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          className="font-family grey-light white-space-normal"
                        >
                          {item.builderName}
                        </Typography>
                        <Typography
                          variant="h5"
                          className="text-bold font-family white-space-normal text-over-wrap"
                          style={{ fontSize: "1rem" }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className="font-family grey-light white-space-normal text-over-wrap"
                        >
                          {item.address}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className="font-family grey-light white-space-normal"
                        >
                          {item.employeeNames.length > 0
                            ? item.employeeNames.join("・")
                            : "(現場監督未登録)"}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className="font-family grey-light white-space-normal"
                        >
                          {item.workerNames.length > 0
                            ? item.workerNames.join("・")
                            : "(大工未登録)"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="s-ta-r"
                        onClick={e => e.stopPropagation()}
                      >
                        <Fab
                          className={
                            item.logWalkViewer
                              ? "white-text button-logWalk-sp mx-5"
                              : "white-text button-logWalk-sp-disable mx-5"
                          }
                          size="small"
                          onClick={e => {
                            if (item.logWalkViewer) {
                              this.props.showDialogDrawing(item.uniqueCode);
                            }
                            e.stopPropagation();
                          }}
                        >
                          <PlayCircleOutlineOutlinedIcon
                            style={{ textTransform: "none" }}
                          />
                        </Fab>
                        <RobotStatus
                          typeOfRobotStatus={BUTTON_LOGKUN_SP}
                          robots={item.robots}
                          robotsUpdate={[robotsUpdate]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </MenuList>
        </Grid>
      </Grid>
    );
  }
}

DashboardBuilderSP.propTypes = {
  dashboards: PropTypes.array.isRequired,
  to: PropTypes.func.isRequired,
  shouldEnableRobotButton: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired
};

export default connect(null, {})(withRouter(DashboardBuilderSP));
