import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";

import { CometChatMessageActions, CometChatThreadedMessageReplyCount, CometChatReadReceipt } from "../";
import { CometChatMessageReactions } from "../Extensions";
import { CometChatAvatar } from "../../Shared";

import { CometChatContext } from "../../../util/CometChatContext";
import { checkMessageForExtensionsData } from "../../../util/common";

import { theme } from "../../../resources/theme";
import Translator from "../../../resources/localization/translator";
import { api } from "../../../../../../common/api/api";
import axios from "axios";

import {
	messageContainerStyle,
	messageWrapperStyle,
	messageThumbnailStyle,
	messageDetailStyle,
	nameWrapperStyle,
	nameStyle,
	messageFileContainerStyle,
	messageFileWrapperStyle,
	messageInfoWrapperStyle,
	messageReactionsWrapperStyle,
	iconStyle,
	messageTxtContainerStyle,
	messageTxtWrapperStyle
} from "./style";

import fileIcon from "./resources/file-upload.svg";

class CometChatReceiverFileMessageBubble extends React.Component {
	static contextType = CometChatContext;

	constructor(props) {
		super(props);

		this.state = {
			isHovering: false,
		};
	}

	shouldComponentUpdate(nextProps, nextState) {

		const currentMessageStr = JSON.stringify(this.props.message);
		const nextMessageStr = JSON.stringify(nextProps.message);

		if (currentMessageStr !== nextMessageStr
			|| this.state.isHovering !== nextState.isHovering) {

			return true;
		}
		return false;
	}

	handleMouseHover = (isHovering) => {
		this.setState({ isHovering });
	};

	async fetch(id) {
		if (this.source) {
			this.source.cancel();
		}
		const CancelToken = axios.CancelToken;
		this.source = CancelToken.source();
		const classification = window.location.pathname.includes("/sp/")
			? "supplier"
			: "builder";
		var info = await api.get(`/${classification}/site-files/${id}/info`,
			{
				cancelToken: this.source.token
			})
			.then(res => {
				if (res.data === undefined) return Promise.reject();
				return res.data;
			})
			.catch(e => {
				this.setState({ FetchDone: false, isDeleted: true });
				return {
					Status: false,
					Code: e?.response?.status
				};
			});
		var isBlob = info.blob ? true : false;
		var path = isBlob ? info.blob.org.replace(/^\/api/, '') : `/${classification}/site-files/${id}/download`;
		const res = await api.get(path,
			{
				responseType: isBlob ? undefined : "arraybuffer",
				cancelToken: this.source.token
			})
			.then(res => {
				if (res.data === undefined) return Promise.reject();
				if (isBlob) {
					var url = res.data.blobData;
				} else {
					var blob = new Blob([res.data], { type: "application/pdf" });
					var url = URL.createObjectURL(blob);
				}			
				const pdfWindow = window.open();
				pdfWindow.location.href = url;
				return {
					Status: true,
					Code: 200,
					Url: url
				};
			})
			.catch(e => {
				this.setState({ FetchDone: false, isDeleted: true });
				return {
					Status: false,
					Code: e?.response?.status
				};
			});

		return await res;
	}

	open = () => {
		if (this.props.message.data.uniqueCode) {
			this.fetch(this.props.message.data.uniqueCode);
		} else {
			const fileUrl = this.props.message.data.attachments[0].url;
			const pdfWindow = window.open();
			pdfWindow.location.href = fileUrl;
		}
	};

	render() {
		let avatar = null,
			name = null;
		let fileMessageWrapper = null;
		if (this.props.message.receiverType === CometChat.RECEIVER_TYPE.GROUP) {
			// avatar = (
			// 	<div css={messageThumbnailStyle()} className="message__thumbnail">
			// 		<CometChatAvatar user={this.props.message.sender} />
			// 	</div>
			// );

			if (this.props.isDisplayAvatar) {
				name = (
					<div css={nameWrapperStyle(avatar)} className="message__name__wrapper"
						style={{ display: "flex", alignItems: "center" }}>
						{this.props.message.sender.metadata?.classification === "builder" &&
							<span style={{
								display: "inline-block",
								width: "8px",
								height: "8px",
								borderRadius: "50%",
								backgroundColor: "#8FC31F"
							}}></span>}&nbsp;
						<span css={nameStyle(this.context)} className="message__name">
							{this.props.message.sender.name}{this.props.message.sender.metadata?.company ? " / " + this.props.message.sender.metadata?.company : ""}
						</span>
					</div>
				);
			}
		}

		let messageReactions = null;
		const reactionsData = checkMessageForExtensionsData(this.props.message, "reactions");
		if (reactionsData) {
			if (Object.keys(reactionsData).length) {
				messageReactions = (
					<div css={messageReactionsWrapperStyle()} className="message__reaction__wrapper">
						<CometChatMessageReactions message={this.props.message} actionGenerated={this.props.actionGenerated} />
					</div>
				);
			}
		}

		let toolTipView = null;
		if (this.state.isHovering) {
			toolTipView = <CometChatMessageActions message={this.props.message} actionGenerated={this.props.actionGenerated} />;
		}

		if (this.props.message.data.isDeleted) {
			fileMessageWrapper = (<div
				css={messageTxtContainerStyle()}
				className="message__text__container"
			>
				<div
					css={messageTxtWrapperStyle(this.context)}
					onClick={this.open}
					className="message__text__wrapper"
					style={{
						height: "auto",
						boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)",
						borderRadius: "8px"
					}}
				>
					<p className="message__txt">
						⚠️ ファイルが削除されました。
					</p>
				</div>
			</div>)
		}
		else {
			fileMessageWrapper = (
				<div css={messageFileContainerStyle()} className="message__file__container">
					<div css={messageFileWrapperStyle(this.context)} className="message__file__wrapper"
						style={{ backgroundColor: "#E1E3EA", boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)", color: "rgba(0, 0, 0, 0.87)" }}>
						<div onClick={this.open} target="_blank" rel="noopener noreferrer">
							<i css={iconStyle(fileIcon, this.context)}></i>
							<label style={{ color: "rgba(0, 0, 0, 0.87)" }}>{this.props.message.data.attachments[0].name}</label>
						</div>
					</div>
				</div>
			)
		}

		return (
			<div css={messageContainerStyle()} className="receiver__message__container message__file" onMouseEnter={() => this.handleMouseHover(true)} onMouseLeave={() => this.handleMouseHover(false)}
				style={{ marginBottom: "10px" }}>
				<div css={messageWrapperStyle()} className="message__wrapper">
					{avatar}
					<div css={messageDetailStyle()} className="message__details">
						{name}
						{toolTipView}

						<div style={{ display: "flex", alignItems: "flex-end" }}>
							{fileMessageWrapper}
							<div css={messageInfoWrapperStyle()} className="message__info__wrapper">
								<CometChatReadReceipt message={this.props.message} />
								<CometChatThreadedMessageReplyCount message={this.props.message} actionGenerated={this.props.actionGenerated} />
							</div>
						</div>
						{messageReactions}
					</div>
				</div>
			</div>
		);
	}
}

// Specifies the default values for props:
CometChatReceiverFileMessageBubble.defaultProps = {
	lang: Translator.getDefaultLanguage(),
	theme: theme,
	actionGenerated: {},
};

CometChatReceiverFileMessageBubble.propTypes = {
	lang: PropTypes.string,
	theme: PropTypes.object,
	actionGenerated: PropTypes.func.isRequired,
	message: PropTypes.object.isRequired,
};

export { CometChatReceiverFileMessageBubble };