import { format } from "date-fns";

const shareAPI = async (title, text, files, url, shareScreenShootSuccess) => {
  const data = {
    title: title || "",
    text: text || "",
    url: url || undefined,
    files: files || undefined
  };

  if (files && files.length > 0) {
    if (!navigator.canShare || !navigator.canShare({ files })) {
      alert("Error: Unsupported feature: navigator.canShare()");
      return;
    }
  }

  try {
    await navigator.share(data);
    shareScreenShootSuccess();
  } catch (error) {
    console.log("Error sharing: " + error);
  }
};

export const shareScreenShootVRSpace = (siteName, spotName, files, shareScreenShootSuccess) => {
  const title = `${format(new Date(), "yyyyMMdd")}_${siteName}_${spotName}`;
  shareAPI("", title, files, "", shareScreenShootSuccess());
};
