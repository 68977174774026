import React from "react";
import IconButton from "@material-ui/core/IconButton";

function CloseButton({ handleClose }) {
  return (
    <IconButton
      style={{
        width: "30px",
        height: "30px",
        position: "absolute",
        display:'block',
        top: "16px",
        right: "16px",
        padding: "5px"
      }}
      onClick={() => handleClose && handleClose()}
    >
      <span style={{ width: "30px", height: "30px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22px"
          height="22px"
          viewBox="0 0 20.828 20.828"
        >
          <g transform="translate(1.414 1.414)">
            <line
              fill="none"
              stroke="#333"
              strokeLinecap="round"
              strokeWidth="2px"
              ｄ
              x2="18"
              y2="18"
            />
            <line
              fill="none"
              stroke="#333"
              strokeLinecap="round"
              strokeWidth="2px"
              ｄ
              x1="18"
              y2="18"
            />
          </g>
        </svg>
      </span>
    </IconButton>
  );
}

export default CloseButton;
