import {
  fetchSiteInfoSuccess,
  fetchSiteInfoRobotSuccess,
  fetchSiteInfoStaffSuccess,
  fetchSiteInfoSupplierSuccess,
  fetchStaffCandidatesSuccess,
  fetchSupplierCandidatesSuccess,
  fetchSiteInfoRobotCandidateSuccess,
  putSiteInfoRobotSuccess,
  fetchSiteSuccess,
  getSitesDetailSuccess,
  getFileSuccess,
  postFileSuccess,
  serachFileSuccess,
  getPhotosSuccess,
  getPhotoInFoSuccess,
  getFileInFoSuccess,
  postPhotosSuccess,
  fetchEntriesSuccess,
  putFileSuccess,
  deleteFileSuccess,
  deleteFileRefer,
  deletePhotoRefer,
  putPhotoSuccess,
  deletePhotoSuccess,
  getPhotosSPSuccess,
  serachFileSPSuccess,
  fetchSiteRobotSuccess,
  fetchSiteBuilderSPSuccess,
  fetchSiteSupplierSPSuccess,
  fetchPhotoTabSuccess,
  putSitePhotoSpSuccess,
  getOriginalPhotoSuccess,
  showHideDialogInfoRobotSuccess,
  showHideDialogStaffSuccess,
  showHideDialogSupplierSuccess,
  showHideDialogEditInfoSuccess,
  showHideDialogEditFileSuccess,
  showHideDialogDeleteFileSuccess,
  setFileValuesSuccess,
  showHideDialogDeleteInfoSuccess,
  setInfoValuesSuccess,
  fetchSiteDrawingsSuccess,
  fetchSiteRecordsExistsSpotsUCIsNullSuccess,
  fetchDrawingFileSuccess,
  sortDrawingsImageSuccess,
  postDrawingSuccess,
  getDrawingWithSpotsSuccess,
  putDrawingInfoSuccess,
  deleteDrawingSuccess,
  getCategorySuccess,
  getHistorySuccess,
  getListDrawingSuccess,
  getShootSuccess,
  changeShootEditLink,
  deleteSpotSuccess,
  showHideDialogPreviewPdfSuccess,
  showHideDialogMapSuccess,
  showHideDialogEditMapSuccess,
  showHideDialogEditPointSuccess,
  showHideDialogAddPointSuccess,
  showHideDialogDeleteSuccess,
  showHideDialogMapFromLogWalkSuccess,
  getUrlImageShootSuccess,
  setTabElementSuccess,
  getUnAdjustedPhotoSuccess,
  setListAdjustPhoto,
  getListShootsSpot,
  shareShootsUCSuccess,
  resetListAdjustPhoto,
  loadInitNoImage,
  setImageShootUnAdjusted,
  initViewUnAdjustedImage,
  setProgressBar,
  clearDrawingsWithSpotSuccess,
  getSiteSPSuccess,
  setIsCompleteSiteSuccess,
  setIsBackSuccess,
  copyDrawingSuccess,
  setTypeSelectImage3D,
  copyDrawingsWithSpotsSuccess,
  addAdjustImageSuccess,
  compassSettingDirectionSuccess,
  // getUrlImageShootTileSuccess,
  getBlobDataLogWalkSuccess
} from "../action/action";
import { api } from "../../../../common/api/api";
import axios from "axios";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import store from "../../../../common/config/store/store";
import { axiosError } from "../../../../common/Stores/action/action";
import { logoutUser } from "../../../auth/service/service";

import {
  dispatchError,
  enableLoading,
  finishLoading
} from "../../../../common/Stores/service/service";
import { encode } from "base64-arraybuffer";
import {
  arrayBufferToBase64,
  convertType,
  findUnique
} from "../../../../utils/mapper";
import { log } from "three";
import { TYPE_SELECT_IMAGE_3D } from "../LogWalkViewer/constants";
import { getListAdjustPhoto } from "../LogWalkViewer/reducer";

let source = null;

export const fetchSiteInfo = id => {
  return dispatch =>
    api.get(`/sites/${id}/info`).then(res => {
      dispatch(fetchSiteInfoSuccess(res.data));
    });
};

export const fetchSiteInfoRobot = id => {
  return dispatch =>
    api.get(`/site-info-robots/?sitesUC=${id}`).then(res => {
      dispatch(fetchSiteInfoRobotSuccess(res.data));
    });
};

export const fetchSiteInfoStaff = id => {
  return dispatch =>
    api.get(`/site-info-builder-staffs/?sitesUC=${id}`).then(res => {
      dispatch(fetchSiteInfoStaffSuccess(res.data));
    });
};

export const fetchSiteInfoSupplier = id => {
  return dispatch =>
    api.get(`/site-info-supplier-staffs/?sitesUC=${id}`).then(res => {
      dispatch(fetchSiteInfoSupplierSuccess(res.data));
    });
};

export const fetchStaffCandidates = id => {
  return dispatch =>
    api.get(`/site-info-builder-staffs/candidates?sitesUC=${id}`).then(res => {
      dispatch(fetchStaffCandidatesSuccess(res.data));
    });
};

export const fetchSupplierCandidates = id => {
  return dispatch =>
    api.get(`/site-info-supplier-staffs/candidates?sitesUC=${id}`).then(res => {
      dispatch(fetchSupplierCandidatesSuccess(res.data));
    });
};

export const fetchSiteInfoRobotCandidate = id => {
  return dispatch =>
    api.get(`/site-info-robots/candidates/?sitesUC=${id}`).then(res => {
      dispatch(fetchSiteInfoRobotCandidateSuccess(res.data));
    });
};

const reloadAfterUpdateSiteRobots = siteId => {
  return dispatch => {
    Promise.all([
      dispatch(fetchOneSite(siteId)),
      dispatch(fetchSiteInfoRobotCandidate(siteId)),
      dispatch(fetchSiteInfoRobot(siteId))
    ])
      .then(dispatch(finishLoading()))
      .catch(errors => {
        dispatch(dispatchError(errors));
      });
  };
};

export const addSiteInfoRobotCandidate = value => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/site-info-robots`, value)
      .then(res => {
        dispatch(reloadAfterUpdateSiteRobots(value.sitesUC));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const deleteSiteInfoRobotCandidate = value => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/site-info-robots`, {
        data: value
      })
      .then(res => {
        dispatch(reloadAfterUpdateSiteRobots(value.sitesUC));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

const reloadAfterUpdateSiteStaffs = siteId => {
  return dispatch => {
    Promise.all([
      dispatch(fetchStaffCandidates(siteId)),
      dispatch(fetchSiteInfoStaff(siteId)),
      dispatch(fetchSiteInfo(siteId))
    ])
      .then(dispatch(finishLoading()))
      .catch(errors => {
        dispatch(dispatchError(errors));
      });
  };
};

export const addStaffCandidate = (value, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/site-info-builder-staffs`, value)
      .then(res => {
        dispatch(reloadAfterUpdateSiteStaffs(value.sitesUC));
      })
      .catch(err => {
        if(handleError && err.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(err));
        }
      });
  };
};

export const deleteBuilderStaffCandidate = (value, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/site-info-builder-staffs`, {
        data: value
      })
      .then(res => {
        dispatch(reloadAfterUpdateSiteStaffs(value.sitesUC));
      })
      .catch(err => {
        if(handleError && err.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(err));
        }
      });
  };
};

const reloadAfterUpdateSiteSupplier = siteId => {
  return dispatch => {
    Promise.all([
      dispatch(fetchSupplierCandidates(siteId)),
      dispatch(fetchSiteInfoSupplier(siteId))
    ])
      .then(dispatch(finishLoading()))
      .catch(errors => {
        dispatch(dispatchError(errors));
      });
  };
};

export const addSupplierCandidate = (value, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/site-info-supplier-staffs`, value)
      .then(res => {
        dispatch(reloadAfterUpdateSiteSupplier(value.sitesUC));
      })
      .catch(err => {
        if(handleError && err.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(err));
        }
      });
  };
};

export const deleteSupplierStaffCandidate = (value, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/site-info-supplier-staffs`, {
        data: value
      })
      .then(res => {
        dispatch(reloadAfterUpdateSiteSupplier(value.sitesUC));
      })
      .catch(err => {
        if(handleError && err.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(err));
        }
      });
  };
};

const reloadAfterUpdateSiteInfo = siteId => {
  return dispatch => {
    Promise.all([
      dispatch(fetchOneSite(siteId)),
      dispatch(fetchSiteInfoRobot(siteId)),
      dispatch(fetchSiteInfoRobotCandidate(siteId))
    ])
      .then(dispatch(finishLoading()))
      .catch(errors => {
        dispatch(dispatchError(errors));
      });
  };
};

export const updateSiteInfoRobot = value => {
  return dispatch => {
    api
      .put(`/site-info-robots`, value)
      .then(res => {
        dispatch(putSiteInfoRobotSuccess());
        dispatch(reloadAfterUpdateSiteInfo(value.sitesUC));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchOneSite = id => {
  return dispatch =>
    api
      .get(`builder/sites/${id}/index`)
      .then(res => {
        dispatch(fetchSiteSuccess(res.data));
        return res.data;
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
};

export const fetchSiteDetail = id => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/sites/${id}/edit`)
      .then(res => {
        if (res.data.image) dispatch(fetchImageSite(res.data));
        else dispatch(getSitesDetailSuccess(res.data));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchImageSite = siteDetail => {
  var isBlob = siteDetail.profilePic ? true : false;
  var path = isBlob ? siteDetail.profilePic : siteDetail.image;
  if (path) {
    return dispatch => {
      api
        .get(path.substring(5), {
          responseType: isBlob ? undefined : "arraybuffer"
        })
        .then(res => {
          if (res.data === undefined) return Promise.reject();
          if (isBlob) {
            var imageUrl = res.data.blobData;
          } else {
            var blob = new Blob([res.data], { type: "image/jpeg" });
            var imageUrl = URL.createObjectURL(blob);
          }
          siteDetail.image = imageUrl;
          dispatch(getSitesDetailSuccess(siteDetail));
        })
        .catch(err => {
          dispatch(dispatchError(err));
        });
    };
  }
};
export const fetchFile = (id, name) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/builder/site-files/${id}/download`, {
        responseType: "arraybuffer"
      })
      .then(res => {
        var blob = new Blob([res.data], { type: "application/pdf" });
        var url = URL.createObjectURL(blob);
        dispatch(getFileSuccess(url));
        // save(res.data, name);
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const postFile = file => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/builder/site-files`, file)
      .then(res => {
        dispatch(postFileSuccess(res.data));
        dispatch(searchFile(file.get("sitesUC")));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const postScreenshotVRPhoto = (file, classification,  postScreenshotVRPhotoSuccess) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/${classification}/site-photos`, file)
      .then(res => {
        dispatch(finishLoading());
        postScreenshotVRPhotoSuccess();
      })
      .catch(err => {
        dispatch(finishLoading());
        dispatch(dispatchError(err));
        postScreenshotVRPhotoSuccess();
      });
  };
};

export const searchFile = id => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/builder/site-files?sitesUC=${id}`)
      .then(res => {
        dispatch(serachFileSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const searchFileSP = id => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/supplier/site-files?sitesUC=${id}`)
      .then(res => {
        dispatch(serachFileSPSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const downloadFileSP = (id, blob) => {
  return dispatch => {
    dispatch(enableLoading());
    var isBlob = blob ? true : false;
    var path = isBlob ? blob.org.replace(/^\/api/, '') : `/supplier/site-files/${id}/download`;
    api
      .get(path, {
        responseType: isBlob ? undefined : "arraybuffer"
      })
      .then(res => {
        dispatch(getFileSuccess(res.data));
        if (isBlob) {
          var url = res.data.blobData;
        } else {
          var blob = new Blob([res.data], { type: "application/pdf" });
          var url = URL.createObjectURL(blob);
        }
        window.open(url, "_blank");
        // save(res.data, convertType(name, extension));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const downloadFileFromFileShare = (id, name, extension) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/builder/site-files/${id}/download`, {
        responseType: "arraybuffer"
      })
      .then(res => {
        dispatch(getFileSuccess(res.data));
        var blob = new Blob([res.data], { type: "application/pdf" });
        var url = URL.createObjectURL(blob);
        window.open(url, "_blank");
        // save(res.data, convertType(name, extension));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const downloadFileFromBlob = (path) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(path.substring(5))
      .then(res => {
        dispatch(getFileSuccess(res.data));
        var url = res.data.blobData;
        window.open(url, "_blank");
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const downloadFile = (id, blob) => {
  return blob ? downloadFileFromBlob(blob.org) : downloadFileFromFileShare(id);
};

export const showOriginalPhoto = (id, blob) => {
  return dispatch => {
    dispatch(enableLoading());
    var isBlob = blob ? true : false;
    var path = isBlob ? blob.org.replace(/^\/api/, '') : `/builder/site-photos/${id}/download`; 
    api
      .get(path, {
        responseType: isBlob ? undefined : "arraybuffer"
      })
      .then(res => {
        if (isBlob) {
          var url = res.data.blobData
          var w = window.open('', "_blank");
          w.document.write(`<img src="${url}">`)
        } else {
          var blob = new Blob([res.data], { type: "image/jpg" });
          var url = URL.createObjectURL(blob);
          window.open(url, "_blank");
        }
        dispatch(getOriginalPhotoSuccess(url));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const showOriginalPhotoSP = (id, blob) => {
  return dispatch => {
    dispatch(enableLoading());
    var isBlob = blob ? true : false;
    var path = isBlob ? blob.org.replace(/^\/api/, '') : `/supplier/site-photos/${id}/download`;
    api
      .get(path, {
        responseType: isBlob ? undefined : "arraybuffer"
      })
      .then(res => {
        if (isBlob) {
          var url = res.data.blobData;
        } else {
          var blob = new Blob([res.data], { type: "image/jpg" });
          var url = URL.createObjectURL(blob);
        }
        dispatch(getOriginalPhotoSuccess(url));
        window.open(url, "_blank");

        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const downloadPhoto = (id, blob, name, extension) => {
  return async dispatch => {
    dispatch(enableLoading());
    var isBlob = blob ? true : false;
    var path = isBlob ? await api
      .get(blob.org.replace(/^\/api/, ''))
      .then(res => res.data.blobData)
      .catch(err => {
        dispatch(dispatchError(err));
      })
    : `/builder/site-photos/${id}/download`;
    var downloader = isBlob ? axios.create() : api;
    downloader
      .get(path, {
        responseType: "arraybuffer"
      })
      .then(res => {
        dispatch(getFileSuccess(res.data));
        save(res.data, convertType(name, extension));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};
export const downloadPhotoSP = (id, blob, name, extension) => {
  return async dispatch => {
    dispatch(enableLoading());
    var isBlob = blob ? true : false;
    var path = isBlob ? await api
      .get(blob.org.replace(/^\/api/, ''))
      .then(res => res.data.blobData)
      .catch(err => {
        dispatch(dispatchError(err));
      })
    : `/supplier/site-photos/${id}/download`;
    var downloader = isBlob ? axios.create() : api;
    downloader    
      .get(path, {
        responseType: "arraybuffer"
      })
      .then(res => {
        dispatch(getFileSuccess(res.data));
        save(res.data, convertType(name, extension));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchPhotos = siteId => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/builder/site-photos?sitesUC=${siteId}`)
      .then(res => {
        dispatch(getPhotosSuccess(res.data));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchPhotoInfo = photoId => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/builder/site-photos/${photoId}/info`)
      .then(res => {
        dispatch(getPhotoInFoSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchFileInfo = fileId => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/builder/site-files/${fileId}/info`)
      .then(res => {
        dispatch(getFileInFoSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchPhotosSP = siteId => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/supplier/site-photos?sitesUC=${siteId}`)
      .then(res => {
        dispatch(getPhotosSPSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const postPhoto = formPhotoData => {
  return dispatch => {
    api
      .post(`/builder/site-photos`, formPhotoData)
      .then(res => {
        dispatch(postPhotosSuccess());
        dispatch(fetchPhotos(formPhotoData.get("sitesUC")));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const postPhotoSP = formPhotoData => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/supplier/site-photos`, formPhotoData)
      .then(res => {
        dispatch(postPhotosSuccess());
        dispatch(fetchPhotosSP(formPhotoData.get("sitesUC")));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const save = (data, name) => {
  const file = new File([data], name);
  saveAs(file);
};

export const fetchEntries = (isCompleteSite = false) => {
  const url = isCompleteSite
    ? "builder/completed-sites/entries"
    : "builder/incomplete-sites/entries";
  return dispatch =>
    api
      .get(url)
      .then(res => {
        dispatch(fetchEntriesSuccess(res.data));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
};

export const updateSiteFile = (fileId, fileValue) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/builder/site-files/${fileId}`, fileValue)
      .then(res => {
        dispatch(putFileSuccess());
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const deleteSiteFile = (fileId, ForceDelete) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/builder/site-files/${fileId}`, {
        data: {
          ForceDelete: ForceDelete ? true : false
        }
      })
      .then(res => {
        dispatch(deleteFileSuccess());
        dispatch(finishLoading());
      })
      .catch(err => {
        if (err.response.status === 423) {
          dispatch(deleteFileRefer());
          dispatch(finishLoading());
        } else {
          dispatch(dispatchError(err));
        }
      });
  };
};

export const updateSitePhoto = (photoId, photoValues) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/builder/site-photos/${photoId}`, photoValues)
      .then(res => {
        dispatch(putPhotoSuccess());
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const deleteSitePhoto = (photoId, ForceDelete, closeDialog) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/builder/site-photos/${photoId}`, {
        data: {
          ForceDelete: ForceDelete ? true : false
        }
      })
      .then(res => {
        if (closeDialog) {
          closeDialog();
        }
        dispatch(deletePhotoSuccess());
        dispatch(finishLoading());
      })
      .catch(err => {
        if (err.response.status === 423) {
          dispatch(deletePhotoRefer());
          dispatch(finishLoading());
        } else {
          dispatch(dispatchError(err));
        }
      });
  };
};

export const fetchSiteRobotSP = siteUC => {
  return dispatch =>
    api
      .get(`/supplier/site-robots?sitesUC=${siteUC}`)
      .then(res => {
        dispatch(fetchSiteRobotSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
};

export const fetchSiteBuilderSP = siteUC => {
  return dispatch =>
    api
      .get(`/supplier/site-builder-staffs?sitesUC=${siteUC}`)
      .then(res => {
        dispatch(fetchSiteBuilderSPSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
};

export const fetchSiteSupplierSP = siteUC => {
  return dispatch =>
    api
      .get(`/supplier/site-supplier-staffs?sitesUC=${siteUC}`)
      .then(res => {
        dispatch(fetchSiteSupplierSPSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
};

export const fetchSiteDetailSP = id => {
  return dispatch =>
    api
      .get(`/supplier/sites/${id}/detail`)
      .then(res => {
        dispatch(getSitesDetailSuccess(res.data));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
};

export const fetchInfoData = id => {
  return dispatch => {
    dispatch(enableLoading());
    Promise.all([
      dispatch(fetchSiteInfo(id)),
      dispatch(fetchSiteInfoRobot(id)),
      dispatch(fetchSiteInfoRobotCandidate(id)),
      dispatch(fetchSiteInfoStaff(id)),
      dispatch(fetchSiteInfoSupplier(id)),
      dispatch(fetchStaffCandidates(id)),
      dispatch(fetchSupplierCandidates(id))
    ])
      .then(() => {
        dispatch(finishLoading());
      })
      .catch(errors => {
        dispatch(dispatchError(errors));
      });
  };
};

export const fetchInfoDataSP = id => {
  return dispatch => {
    dispatch(enableLoading());

    Promise.all([
      dispatch(fetchSiteDetailSP(id)),
      dispatch(fetchSiteRobotSP(id)),
      dispatch(fetchSiteBuilderSP(id)),
      dispatch(fetchSiteSupplierSP(id))
    ])
      .then(() => {
        dispatch(finishLoading());
      })
      .catch(errors => {
        dispatch(dispatchError(errors));
      });
  };
};

export const fetchPhotoTabData = id => {
  return dispatch => {
    dispatch(enableLoading());
    Promise.all([])
      .then(() => {
        dispatch(fetchPhotoTabSuccess(true));
      })
      .catch(errors => {
        dispatch(dispatchError(errors));
      });
  };
};

export const updateSitePhotoSp = (value, id) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/supplier/site-photos/${id}`, value)
      .then(res => {
        dispatch(putSitePhotoSpSuccess(res));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const showHideDialogInfoRobot = () => {
  return dispatch => {
    dispatch(showHideDialogInfoRobotSuccess());
  };
};

export const showHideDialogStaff = () => {
  return dispatch => {
    dispatch(showHideDialogStaffSuccess());
  };
};

export const showHideDialogSupplier = () => {
  return dispatch => {
    dispatch(showHideDialogSupplierSuccess());
  };
};

export const showHideDialogEditInfo = () => {
  return dispatch => {
    dispatch(showHideDialogEditInfoSuccess());
  };
};

export const showHideDialogDeleteInfo = () => {
  return dispatch => {
    dispatch(showHideDialogDeleteInfoSuccess());
  };
};

export const setInfoValues = infoValues => {
  return dispatch => {
    dispatch(setInfoValuesSuccess(infoValues));
  };
};

export const showHideDialogEditFile = () => {
  return dispatch => {
    dispatch(showHideDialogEditFileSuccess());
  };
};

export const showHideDialogDeleteFile = () => {
  return dispatch => {
    dispatch(showHideDialogDeleteFileSuccess());
  };
};

export const setFileValues = fileValues => {
  return dispatch => {
    dispatch(setFileValuesSuccess(fileValues));
  };
};

export const fetchSiteDrawings = (id, isBuilder = true) => {
  const classification = isBuilder ? "builder" : "supplier";
  return dispatch => {
    dispatch(enableLoading());
    api.get(`/${classification}/sites/${id}/drawings`).then(res => {
      dispatch(fetchSiteDrawingsSuccess(res.data));
      dispatch(finishLoading());
    });
  };
};

export const fetchSiteRecordsExistsSpotsUCIsNull = (id, isBuilder = true) => {
  const classification = isBuilder ? "builder" : "supplier";
  return dispatch => {
    api
      .get(`/${classification}/sites/${id}/records/exists?spotsUC=null`)
      .then(res => {
        dispatch(fetchSiteRecordsExistsSpotsUCIsNullSuccess(res.data.exists));
      });
  };
};

export const fetchDrawingFile = drawingsUC => {
  return dispatch =>
    api
      .get(`/builder/drawings/${drawingsUC}/file?bin`, {
        responseType: "arraybuffer"
      })
      .then(res => {
        if (res.data === undefined) return Promise.reject();
        const blob = new Blob([res.data], { type: "image/jpeg" });
        const url = URL.createObjectURL(blob);
        return url;
      })
      .then(url => dispatch(fetchDrawingFileSuccess(url)));
};

export const sortDrawingImage = (siteUC, drawingsUC) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/builder/sites/${siteUC}/drawings`, drawingsUC)
      .then(res => {
        dispatch(sortDrawingsImageSuccess());
        dispatch(fetchSiteDrawings(siteUC));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const postDrawing = (siteUC, formDrawingData) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/builder/sites/${siteUC}/drawings`, formDrawingData)
      .then(res => {
        dispatch(postDrawingSuccess());
        dispatch(fetchSiteDrawings(siteUC));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const getDrawingWithSpots = drawingsUC => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/builder/drawings/${drawingsUC}?spots`)
      .then(res => {
        dispatch(getDrawingWithSpotsSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const putDrawingInfo = (drawingsUC, sitesUC, values) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/builder/drawings/${drawingsUC}`, values)
      .then(res => {
        dispatch(putDrawingInfoSuccess());
        dispatch(getDrawingWithSpots(drawingsUC));
        dispatch(fetchSiteDrawings(sitesUC));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const postSpot = (drawingsUC, sitesUC, values) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/builder/drawings/${drawingsUC}/spots`, values)
      .then(res => {
        dispatch(putDrawingInfoSuccess());
        dispatch(getDrawingWithSpots(drawingsUC));
        dispatch(fetchSiteDrawings(sitesUC));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const putSpot = (spotsUC, drawingsUC, sitesUC, values) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/builder/spots/${spotsUC}`, values)
      .then(res => {
        dispatch(putDrawingInfoSuccess());
        dispatch(getDrawingWithSpots(drawingsUC));
        dispatch(fetchSiteDrawings(sitesUC));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const postLink = (sitesUC, data, currentdrawingsUC) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/sites/${sitesUC}/links`, data)
      .then(res => {
        dispatch(putDrawingInfoSuccess());
        dispatch(getDrawingWithSpots(currentdrawingsUC));
        dispatch(fetchSiteDrawings(sitesUC));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const deleteLink = (sitesUC, data, currentdrawingsUC) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/sites/${sitesUC}/links`, {
        data: data
      })
      .then(res => {
        dispatch(getDrawingWithSpots(currentdrawingsUC));
        dispatch(fetchSiteDrawings(sitesUC));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(getDrawingWithSpots(currentdrawingsUC));
        dispatch(fetchSiteDrawings(sitesUC));
        dispatch(dispatchError(err));
      });
  };
};

export const deleteDrawing = (drawingsUC, sitesUC, value) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/builder/drawings/${drawingsUC}`, {
        data: { shoots: value ? "delete" : "no-spot" }
      })
      .then(res => {
        dispatch(fetchSiteDrawings(sitesUC));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
        if (err.response.status === 409) {
          window.alert("地点情報の修復中の為、操作がキャンセルされました。")
        };
      });
  };
};

export const incompleteSite = (sitesUC, handleSuccess, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/sites/${sitesUC}/uncomplete`)
      .then(res => {
        if(handleSuccess) {
          handleSuccess();
        }
        dispatch(finishLoading());
      })
      .catch(err => {
        if(handleError) {
          handleSuccess();
        }
        dispatch(dispatchError(err));
      });
  };
};

export const deleteSpot = (spotsUC, drawingsUC, sitesUC, value) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/builder/spots/${spotsUC}`, {
        data: { shoots: value ? "delete" : "no-spot" }
      })
      .then(res => {
        dispatch(deleteSpotSuccess());
        dispatch(getDrawingWithSpots(drawingsUC));
        dispatch(fetchSiteDrawings(sitesUC));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
        if (err.response.status === 409) {
          window.alert("地点情報の修復中の為、操作がキャンセルされました。")
        };
      });
  };
};

export const showHideDialogPreviewPdf = pdfFile => {
  return dispatch => {
    dispatch(showHideDialogPreviewPdfSuccess(pdfFile));
  };
};

export const showHideDialogMap = drawingsUC => {
  return dispatch => {
    dispatch(showHideDialogMapSuccess(drawingsUC));
  };
};

export const showHideDialogEditMap = nameValue => {
  return dispatch => {
    dispatch(showHideDialogEditMapSuccess(nameValue));
  };
};

export const showHideDialogAddPoint = spotData => {
  return dispatch => {
    dispatch(showHideDialogAddPointSuccess(spotData));
  };
};

export const showHideDialogEditPoint = spotData => {
  return dispatch => {
    dispatch(showHideDialogEditPointSuccess(spotData));
  };
};

export const showHideDialogDelete = () => {
  return dispatch => {
    dispatch(showHideDialogDeleteSuccess());
  };
};

export const showHideDialogMapFromLogWalk = () => {
  return dispatch => {
    dispatch(showHideDialogMapFromLogWalkSuccess());
  };
};

export const fetchShootInfo = (
  shootsUC,
  classification,
  redirectNotfound,
  onSetProgressBar,
  redirectNoImage,
  isLastDrawingUnAdjust,
  backToDrawing,
  handleChangeHistory,
  hotSpotData,
  redirectTo,
  isAddUnadjustImage
) => {
  return dispatch => {
    api
      .get(`/${classification}/logwalk-viewer/shoots/${shootsUC}`)
      .then(res => {
        if (hotSpotData && hotSpotData.isRedirectLogin) {
          window.history.pushState(
            {},
            "",
            window.location.href.replace("&isRedirectLogin", "")
          );
        }

        let data = isLastDrawingUnAdjust
          ? {
              createdBy: null,
              blob: null,
              recordsUC: null,
              share: null,
              sitesUC: null,
              spotsUC: null,
              tagName: null,
              infos: [],
              buildersUC: null
            }
          : res.data;
        dispatch(getShootSuccess(data));

        if (res.data) {
          if (isLastDrawingUnAdjust) {
            // dispatch(getUrlImageShootSuccess(""));
            dispatch(fetchShootLogWalk());
            // backToDrawing();
            handleChangeHistory();
            // dispatch(setTypeSelectImage3D(TYPE_SELECT_IMAGE_3D.VIEWER));
          } else {
            let apiUrl = null;
            if (res.data.blob) {
              apiUrl = res.data.blob.replace(`/api`, "");
            }

            if (res.data.image) {
              apiUrl = res.data.image.replace(`/api`, "");
            }
            //   // If have tiles image, get tiles image, else get base64 image
            //   if (res.data.blob) {
            //     dispatch(getUrlImageShootSuccess(""));
            //     dispatch(fetchShootTile(res.data.blob.replace(`/api`, "")));
            //   } else if (res.data.image !== null) {
            //     const api = res.data.image.replace(`/api`, "");
            //     dispatch(fetchShootUrlImage3D(api, onSetProgressBar));
            //     dispatch(fetchShootTile());
            //   } else if (res.data.image === null) {
            //     dispatch(getUrlImageShootSuccess(""));
            //     dispatch(fetchShootTile());
            //   } else {
            //     redirectNoImage();
            //   }
            if (!isAddUnadjustImage) {
              dispatch(fetchShootLogWalk(apiUrl));
            }
          }

          dispatch(fetchInfoLogwalkView(res.data, classification));
        }
      })
      .catch(err => {
        dispatch(dispatchError(err));
        if (err.response && err.response.status === 404) {
          store.dispatch(axiosError(err.response));
          redirectNotfound(404);
        } else if (err.response && err.response.status === 401) {
          if (
            hotSpotData &&
            !hotSpotData.isRedirectLogin &&
            hotSpotData.infoUC
          ) {
            redirectTo(
              `${
                classification === "supplier" ? "" : "/sp"
              }/logwalk-viewer/shoots/${shootsUC}?info=${
                hotSpotData.infoUC
              }&isRedirectLogin`
            );
          } else if (
            hotSpotData &&
            hotSpotData.isRedirectLogin &&
            hotSpotData.infoUC
          ) {
            redirectTo(`${classification === "supplier" ? "" : "/sp"}/login`);
          } else {
            store.dispatch(axiosError(err.response));
            store.dispatch(logoutUser());
          }
        }
      });
  };
};

export const fetchShootUrlImage3D = (apis, onSetProgressBar) => {
  return dispatch => {
    dispatch(
      setProgressBar({
        isShow: true,
        progress: 0
      })
    );
    api
      .get(apis, {
        responseType: "arraybuffer",
        onDownloadProgress: function (progressEvent) {
          // Do whatever you want with the native progress event
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (onSetProgressBar) onSetProgressBar(percentage);
        }
      })
      .then(res => {
        if (res.data) {
          // const blob = new Blob([res.data], { type: "image/jpeg" });
          // const url = URL.createObjectURL(blob);
          const url = arrayBufferToBase64(res.data);
          dispatch(getUrlImageShootSuccess(`data:image/jpeg;base64,${url}`));
        }
      })
      .catch(errors => {
        dispatch(dispatchError(errors));
      })
      .finally(() => {
        setTimeout(() => {
          onSetProgressBar(0);
          dispatch(
            setProgressBar({
              isShow: false,
              progress: 100
            })
          );
        }, 500);
      });
  };
};

export const fetchShootLogWalk = (apiUrl, isRefresh = false) => {
  if(source) {
    source.cancel();
  }

  return dispatch => {
    if (apiUrl) {
      source = axios.CancelToken.source();
      let isBlob = true;
      if (apiUrl.includes("/file?bin")) {
        isBlob = false;
      }
      api
        .get(
          apiUrl,
          isBlob
            ? {cancelToken: source.token}
            : {
                cancelToken: source.token,
                responseType: "arraybuffer"
              }
        )
        .then(res => {
          if (res.data) {
            if (isBlob) {
              dispatch(
                getBlobDataLogWalkSuccess({
                  ...res.data,
                  blob: apiUrl,
                  isRefresh: isRefresh
                })
              );
            } else {
              dispatch(
                getBlobDataLogWalkSuccess({
                  blobDataLst: [
                    `data:image/jpeg;base64,${arrayBufferToBase64(res.data)}`
                  ],
                  geoType: "EQR",
                  refreshSasEstimatedTime: null,
                  blob: apiUrl,
                  isRefresh: isRefresh
                })
              );
            }
          }
        });
    } else {
      dispatch(getBlobDataLogWalkSuccess(null));
    }
  };
};

export const fetchShootLogWalkUpdate = (apiUrl) => {
  if(source) {
    source.cancel();
  }
  
  source = axios.CancelToken.source();
    let isBlob = true;
    if (apiUrl.includes("/file?bin")) {
      isBlob = false;
    }
    return api
      .get(
        apiUrl,
        isBlob
          ? {cancelToken: source.token}
          : {
              cancelToken: source.token,
              responseType: "arraybuffer"
            }
      )
};

export const fetchHistoryInfo = (sitesUC, classification) => {
  return dispatch =>
    api
      .get(`/${classification}/logwalk-viewer/sites/${sitesUC}/records/entries`)
      .then(res => {
        if (res.data) {
          const data = res.data.map(item => ({
            ...item,
            createdAt: format(new Date(item.createdAt), "yyyy-MM-dd eee HH:mm"),
            dateUtc: item.createdAt
          }));
          dispatch(getHistorySuccess(data));
        }
      });
};

export const fetchCategoryInfo = () => {
  return dispatch =>
    api.get(`/builder/logwalk-viewer/logwalk-tags/entries`).then(res => {
      dispatch(getCategorySuccess(res.data));
    });
};

export const fetchListShootsSpot = (sitesUC, classification) => {
  return dispatch =>
    api
      .get(`/${classification}/logwalk-viewer/sites/${sitesUC}/records-shoots`)
      .then(res => {
        if (res.data) {
          let allList = [];
          let data = [];
          res.data.forEach(item => {
            allList = [...allList, ...item.shoots];
            data = [
              ...data,
              {
                ...item,
                createdAt: format(
                  new Date(item.createdAt),
                  "yyyy-MM-dd eee HH:mm"
                ),
                dateUtc: item.createdAt
              }
            ];
          });
          const listActive = findUnique(allList, p => p.spotsUC);
          dispatch(
            getListShootsSpot({
              listSpot: data,
              listShootCurrentActive: listActive
            })
          );
        }
      });
};

export const fetchListDrawingInfo = (sitesUC, spotsUC, classification) => {
  return dispatch =>
    api
      .get(`/${classification}/logwalk-viewer/sites/${sitesUC}/drawings`)
      .then(res => {
        if (res.data) {
          let listSpotAll = [];
          const drawingsList = res.data;
          drawingsList.forEach(item => {
            const listSpotItem = item.spots.map(i => ({
              ...i,
              drawingCode: item.uniqueCode,
              blob: item.blob,
              path: item.path
            }));
            listSpotAll = [...listSpotAll, ...listSpotItem];
          });
          const itemDrawingPhoto = listSpotAll.find(
            item => item.uniqueCode === spotsUC
          );
          const payload = {
            drawingCode:
            itemDrawingPhoto ? itemDrawingPhoto?.drawingCode : drawingsList[0].uniqueCode,
            drawingPhoto: itemDrawingPhoto ? itemDrawingPhoto?.path : drawingsList[0].path,
            drawingBlob: itemDrawingPhoto ? itemDrawingPhoto?.blob : drawingsList[0].blob,
            drawingsList
          };
          dispatch(getListDrawingSuccess(payload));
        }
      });
};

export const fetchInfoLogwalkView = (infoData, classification, isNoImage) => {
  const { sitesUC, spotsUC } = infoData;
  return dispatch => {
    // dispatch(enableLoading());
    Promise.all([
      // dispatch(fetchCategoryInfo()),
      dispatch(fetchHistoryInfo(sitesUC, classification)),
      dispatch(fetchListDrawingInfo(sitesUC, spotsUC, classification)),
      dispatch(fetchListShootsSpot(sitesUC, classification)),
      dispatch(getUnAdjustedPhoto(sitesUC, classification))
    ])
      .then(() => {
        dispatch(finishLoading());
        if (isNoImage) {
          dispatch(loadInitNoImage(spotsUC, sitesUC));
        }
      })
      .catch(errors => {
        dispatch(dispatchError(errors));
      });
  };
};

export const setTabElement = tabElement => {
  return dispatch => {
    dispatch(setTabElementSuccess(tabElement));
  };
};

export const getUnAdjustedPhoto = (sitesUC, classification) => {
  return dispatch =>
    api
      .get(
        `/${classification}/logwalk-viewer/sites/${sitesUC}/spots/null/records`
      )
      .then(res => {
        if (res.data) {
          const historyList = res.data.map(item => ({
            ...item,
            createdAt: format(
              new Date(item.createdAt),
              "yyyy-MM-dd eee HH:mm",
              { locale: ja }
            )
          }));
          dispatch(getUnAdjustedPhotoSuccess(historyList));
        }
      });
};

export const getPhotoShoots = item => {
  const { blob, image, uniqueCode, tagName, createdBy } = item;
  return dispatch => {
    Promise.all([
      dispatch(
        setListAdjustPhoto({
          blob: blob ?? image,
          uniqueCode,
          tagName,
          createdBy
        })
      )
    ]);
  };
};

export const addImageNotAdjustedApi = data => {
  const {
    sitesUC,
    shootsUC,
    spotsUC,
    shootsUCRoot,
    isLastDrawingUnAdjust,
    currentShootsUnAdjust
  } = data.value;
  const {
    onClose,
    classification,
    onRedirectNotfoundPage,
    onSetProgressBar,
    redirectNoImage,
    backToDrawing,
    handleChangeHistory
  } = data;
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(
        `/${classification}/logwalk-viewer/sites/${sitesUC}/spots/null/shoots/${shootsUC}`,
        { spotsUC: spotsUC }
      )
      .then(res => {
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
        if (err.response.status === 409) {
          window.alert("地点情報の修復中の為、操作がキャンセルされました。");
        };
      })
      .finally(() => {
        onClose(false);
        dispatch(resetListAdjustPhoto());
        dispatch(
          fetchShootInfo(
            shootsUCRoot === "no-image" ? shootsUC : shootsUCRoot,
            classification,
            onRedirectNotfoundPage,
            onSetProgressBar,
            redirectNoImage,
            isLastDrawingUnAdjust,
            backToDrawing,
            handleChangeHistory,
            null,
            null,
            true
          )
        );
        dispatch(setImageShootUnAdjusted({}));
      });
  };
};

export const shareShootsUC = data => {
  const { shootsUC, share, classification } = data;
  return dispatch => {
    api
      .put(`/${classification}/logwalk-viewer/shoots/${shootsUC}/share`, {
        share
      })
      .then(res => {
        if (res.status === 200) {
          dispatch(shareShootsUCSuccess(share));
        }
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchListDrawingInfoViewUnAdjusted = (sitesUC, classification) => {
  return dispatch =>
    api
      .get(`/${classification}/logwalk-viewer/sites/${sitesUC}/drawings`)
      .then(res => {
        if (res.data) {
          let listSpotAll = [];
          const drawingsList = res.data.map(item => {
            const blob = new Blob([item.image], { type: "image/jpeg" });
            const url = URL.createObjectURL(blob);
            return {
              ...item
              // image: url,
            };
          });
          const payload = {
            drawingCode: drawingsList[0] && drawingsList[0].uniqueCode,
            drawingPhoto: drawingsList[0] && drawingsList[0].image,
            drawingBlob: drawingsList[0] && drawingsList[0].blob,
            drawingsList
          };
          dispatch(getListDrawingSuccess(payload));
        }
      });
};

export const fetchInitViewUnAdjustedImage = data => {
  const { sitesUC, classification } = data;
  return dispatch => {
    Promise.all([
      dispatch(fetchListDrawingInfoViewUnAdjusted(sitesUC, classification)),
      dispatch(fetchListShootsSpot(sitesUC, classification)),
      dispatch(fetchHistoryInfo(sitesUC, classification)),
      dispatch(getUnAdjustedPhoto(sitesUC, classification))
    ])
      .then(res => {
        dispatch(initViewUnAdjustedImage());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const clearDrawingsWithSpot = () => {
  return dispatch => dispatch(clearDrawingsWithSpotSuccess());
};

export const getSiteSP = sitesUC => {
  return dispatch =>
    api
      .get(`/supplier/sites/${sitesUC}`)
      .then(res => {
        dispatch(getSiteSPSuccess(res.data));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
};

export const editLinkPositionOnShoot = (
  shootsUC,
  classification,
  data,
  cancelModeEditLink
) => {
  return dispatch => {
    api
      .put(`/${classification}/logwalk-viewer/shoots/${shootsUC}/links`, data)
      .then(() => {
        dispatch(changeShootEditLink(data));
        cancelModeEditLink();
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const setIsCompleteSite = isCompleteSite => {
  return dispatch => {
    dispatch(setIsCompleteSiteSuccess(isCompleteSite));
  };
};

export const setIsBack = isBack => {
  return dispatch => {
    dispatch(setIsBackSuccess(isBack));
  };
};

export const copyDrawing = (sitesUC, copyList, spots) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/builder/sites/${sitesUC}/spots`, {
        drawingsUCs: copyList,
        spots: spots
      })
      .then(res => {
        dispatch(copyDrawingSuccess());
        dispatch(fetchSiteDrawings(sitesUC));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const copyDrawingsWithSpot = (sitesUC, drawingsUC, spotsUCs, names) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/builder/drawings/${drawingsUC}/copy`, {
        spotsUCs: spotsUCs,
        names: names
      })
      .then(res => {
        dispatch(copyDrawingsWithSpotsSuccess());
        dispatch(fetchSiteDrawings(sitesUC));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};
export const compassSettingDirection = (sitesUC, value) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/builder/sites/${sitesUC}/directions`, value)
      .then(res => {
        dispatch(fetchSiteDrawings(sitesUC));
        dispatch(compassSettingDirectionSuccess());
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};
