import {
  GET_LIST_DRAWING_SUCCESS,
  GET_CATEGORY_SUCCESS,
  GET_HISTORY_SUCCESS,
  GET_SHOOTS_SUCCESS,
  CHANGE_SHOOTS_EDIT_LINK,
  GET_URL_IMAGE_SHOOT_SUCCESS,
  SET_DRAWING_PHOTO,
  GET_UNADJUSTED_PHOTO_SUCCESS,
  SET_LIST_ADJUST_PHOTO_SELECTED,
  SET_LIST_ADJUST_PHOTO,
  RESET_LIST_ADJUST_PHOTO,
  GET_LIST_SHOOTS_SPOT_SUCCESS,
  SET_HISTORY_VIEWER,
  SET_SPOT_CURRENT,
  OPEN_HOTSPOT_INFO,
  CREATE_HOTSPOT_INFO,
  CREATE_HOTSPOT_INFO_SUCCESS,
  DELETE_HOTSPOT_INFO_SUCCESS,
  CLOSE_HOTSPOT_INFO,
  CANCEL_CREATE_HOTSPOT_INFO,
  SET_TYPE_SELECT_IMAGE_3D,
  SET_TYPE_DIALOG,
  SHARE_SHOOTS_UC_SUCCESS,
  LOAD_INIT_NO_IMAGE,
  SET_IMAGE_SHOOT_UN_ADJUSTED,
  RESET_STORE,
  INIT_VIEW_UNADJUSTED_IMAGE,
  SET_REDIRECT_FROM_URL,
  SET_IS_DEFAULT_HISTORY,
  SET_PROGRESS_BAR_LOAD_IMAGE3D,
  SHOW_DIRECTION_DRAWER,
  HIDE_DIRECTION_DRAWER,
  // GET_URL_IMAGE_SHOOT_TILE_SUCCESS,
  LOGIN_COMETCHAT_SUCCESS,
  LOGOUT_COMETCHAT_SUCCESS,
  GET_BLOB_DATA_LOGWALK_SUCCESS,
  UPDATE_SHOOT_HOTSPOT_INFO_NOTI,
  UPDATE_IS_SHARE_URL
} from "../constant/constant";
import { TYPE_DIALOG, TYPE_SELECT_IMAGE_3D } from "./constants";

const initialState = {
  logWalkInfo: {
    blob: "",
    recordsUC: "",
    tagName: "",
    createdBy: "",
    spotsUC: "",
    share: false,
    sitesUC: "",
    infos: [],
    buildersUC: "",
    walkThrough: false,
    isSharedUrl: false
  },
  historyList: [],
  categoryList: [],
  drawingsList: [],
  drawingPhoto: "",
  drawingBlob: "",
  urlImageShoot: "",
  urlImageShootTile: { tile: "", preview: "", levels: "" },
  imageShootUnAdjusted: {},
  drawingCode: "",
  historyListAdjustPhoto: [],
  listAdjustPhotoSelected: {},
  listAdjustPhoto: [],
  listShootsSpot: [],
  listShootCurrentActive: [],
  listShootCurrentActiveLatest: [],
  spotCurrent: "",
  listSpotsCurrent: [],
  typeSelectImage3D: "",
  typeDialog: "",
  shootsUCNotAdjusted: "",
  isDefaultHistory: true,
  progressLoadImage3D: {
    isShow: false,
    progress: 80
  },
  currentHotspotInfoId: "",
  isCreateHotspotInfo: false,
  isOpenHotspotInfo: false,
  isShowDirectionDrawer: false,
  isDialog: null,
  isLoginCometChat: null
};

export const logWalkViewReducer = (originstate = initialState, action) => {
  const state = { ...originstate, type: action.type };
  switch (action.type) {
    case GET_SHOOTS_SUCCESS: {
      if (
        state.logWalkInfo.recordsUC === "default" &&
        state.typeSelectImage3D === TYPE_SELECT_IMAGE_3D.HISTORY
      ) {
        return {
          ...state,
          spotCurrent: action.shootsInfo && action.shootsInfo.spotsUC,
          logWalkInfo: {
            ...action.shootsInfo,
            recordsUC: "default"
          }
        };
      } else {
        return {
          ...state,
          logWalkInfo: action.shootsInfo,
          spotCurrent: action.shootsInfo && action.shootsInfo.spotsUC
        };
      }
    }
    case CHANGE_SHOOTS_EDIT_LINK:
      return {
        ...state,
        logWalkInfo: {
          ...state.logWalkInfo,
          yaw: action.data.yaw,
          pitch: action.data.pitch
        }
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: action.data
      };
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        historyList: action.data
      };
    case GET_LIST_DRAWING_SUCCESS: {
      const { drawingCode, drawingPhoto, drawingBlob, drawingsList } = action.data;
      return {
        ...state,
        drawingsList,
        drawingCode,
        drawingPhoto,
        drawingBlob
      };
    }
    case GET_URL_IMAGE_SHOOT_SUCCESS:
      return {
        ...state,
        urlImageShoot: action.data
      };
    // case GET_URL_IMAGE_SHOOT_TILE_SUCCESS:
    //   return {
    //     ...state,
    //     urlImageShootTile: action.data
    //   };
    case GET_BLOB_DATA_LOGWALK_SUCCESS:
      return {
        ...state,
        blobDataLogWalk: action.data
      };
    case UPDATE_SHOOT_HOTSPOT_INFO_NOTI:
      if(
        state.logWalkInfo.infos && 
        state.logWalkInfo.infos.length > 0 &&
        state.logWalkInfo.infos.find(item => item.uniqueCode === action.data.uniqueCode)
      ) {
        let temp = state.logWalkInfo.infos.map(
          item => item.uniqueCode === action.data.uniqueCode ? 
            {...item, hasChat: action.data.hasChat} : 
            item
        )
        return {
          ...state,
          logWalkInfo: {
            ...state.logWalkInfo,
            infos: temp
          },
        };        
      } else {
        return state;
      }
      case UPDATE_IS_SHARE_URL:
        return {
          ...state,
          logWalkInfo: {
            ...state.logWalkInfo,
            isSharedUrl: action.data
          },
        };        

    case SET_IMAGE_SHOOT_UN_ADJUSTED:
      return {
        ...state,
        imageShootUnAdjusted: action.data
      };
    case SET_DRAWING_PHOTO:
      return {
        ...state,
        drawingPhoto: action.data.drawingPhoto,
        drawingBlob: action.data.drawingBlob,
        drawingCode: action.data.drawingCode
      };
    case GET_UNADJUSTED_PHOTO_SUCCESS:
      return {
        ...state,
        historyListAdjustPhoto: action.data,
        listAdjustPhotoSelected:
          action.data.length > 0 ? action.data[0] : undefined
      };
    case SET_LIST_ADJUST_PHOTO_SELECTED:
      return {
        ...state,
        listAdjustPhotoSelected: action.data
      };
    case SET_LIST_ADJUST_PHOTO:
      return {
        ...state,
        listAdjustPhoto: [...state.listAdjustPhoto, action.data]
      };
    case RESET_LIST_ADJUST_PHOTO:
      return {
        ...state,
        listAdjustPhoto: []
      };
    case GET_LIST_SHOOTS_SPOT_SUCCESS: {
      const { listSpot, listShootCurrentActive } = action.data;
      const listCurrent = listSpot.find(
        item => item.uniqueCode === state.logWalkInfo.recordsUC
      );
      return {
        ...state,
        listShootsSpot: listSpot,
        listShootCurrentActive:
          // state.isRedirectFromUrl ?
          // (listCurrent && listCurrent.shoots || [])
          state.logWalkInfo.recordsUC === "default"
            ? listShootCurrentActive
            : (listCurrent && listCurrent.shoots) || [],
        listShootCurrentActiveLatest: !state.listShootCurrentActiveLatest.length
          ? listShootCurrentActive
          : state.listShootCurrentActiveLatest
      };
    }
    case SET_HISTORY_VIEWER: {
      const listCurrent = state.listShootsSpot.find(
        item => item.uniqueCode === action.data
      );
      if (action.data === "default") {
        return {
          ...state,
          logWalkInfo: {
            ...state.logWalkInfo,
            recordsUC: action.data
          },
          listShootCurrentActive: state.listShootCurrentActiveLatest
        };
      } else {
        return {
          ...state,
          logWalkInfo: {
            ...state.logWalkInfo,
            recordsUC: action.data
          },
          listShootCurrentActive: (listCurrent && listCurrent.shoots) || []
        };
      }
    }
    case OPEN_HOTSPOT_INFO:
      return {
        ...state,
        currentHotspotInfoId: action.data,
        isCreateHotspotInfo: false,
        isOpenHotspotInfo: true
      };
    case CREATE_HOTSPOT_INFO:
      return {
        ...state,
        isCreateHotspotInfo: true,
        isOpenHotspotInfo: true
      };
    case CREATE_HOTSPOT_INFO_SUCCESS:
      return {
        ...state,
        logWalkInfo: {
          ...state.logWalkInfo,
          infos: [...state.logWalkInfo.infos, action.data]
        }
      };

    case DELETE_HOTSPOT_INFO_SUCCESS:
      return {
        ...state,
        logWalkInfo: {
          ...state.logWalkInfo,
          infos: state.logWalkInfo.infos.filter(
            item => item.uniqueCode !== action.data
          )
        },
        currentHotspotInfoId: "",
        isCreateHotspotInfo: false,
        isOpenHotspotInfo: false
      };

    case CANCEL_CREATE_HOTSPOT_INFO:
      return {
        ...state,
        isCreateHotspotInfo: false,
        isOpenHotspotInfo: false
      };
    case CLOSE_HOTSPOT_INFO:
      return {
        ...state,
        isOpenHotspotInfo: false
      };
    case SET_SPOT_CURRENT:
      return {
        ...state,
        spotCurrent: action.data
      };
    case SET_TYPE_SELECT_IMAGE_3D:
      return {
        ...state,
        typeSelectImage3D: action.data
      };
    case SET_TYPE_DIALOG: {
      if (action.data.typeDialog === TYPE_DIALOG.ZOOM_IMAGE) {
        return {
          ...state,
          typeDialog: action.data.typeDialog
        };
      } else {
        return {
          ...state,
          typeDialog: action.data.typeDialog,
          shootsUCNotAdjusted: action.data.shootsSelected
        };
      }
    }
    case SHARE_SHOOTS_UC_SUCCESS:
      return {
        ...state,
        logWalkInfo: {
          ...state.logWalkInfo,
          share: action.data
        }
      };
    case LOAD_INIT_NO_IMAGE: {
      const historyDefault = state.historyList[0];
      const listCurrent =
        historyDefault &&
        state.listShootsSpot.find(
          item => item.uniqueCode === historyDefault.uniqueCode
        );
      return {
        ...state,
        logWalkInfo: {
          ...state.logWalkInfo,
          recordsUC: historyDefault && historyDefault.uniqueCode,
          spotsUC: action.spotsUC,
          sitesUC: action.sitesUC
        },
        urlImageShoot: "",
        spotCurrent: action.spotsUC,
        listShootCurrentActive: (listCurrent && listCurrent.shoots) || []
      };
    }
    case SET_IS_DEFAULT_HISTORY:
      return {
        ...state,
        isDefaultHistory: false
      };
    case SET_PROGRESS_BAR_LOAD_IMAGE3D:
      return {
        ...state,
        progressLoadImage3D: action.data
      };
    case RESET_STORE:
      return {
        ...initialState
      };

    case SHOW_DIRECTION_DRAWER:
      return {
        ...state,
        isShowDirectionDrawer: true
      };
    case HIDE_DIRECTION_DRAWER:
      return {
        ...state,
        isShowDirectionDrawer: false
      };
    case LOGIN_COMETCHAT_SUCCESS:
      return {
        ...state,
        isLoginCometChat: action.data
      };
    case LOGOUT_COMETCHAT_SUCCESS:
      return {
        ...state,
        isLoginCometChat: null
      };

    default:
      return state;
  }
};

export const getLogWalkInfo = state => state;
export const getHistoryList = state => state;
export const getCategoryList = state => state;
export const getDrawingsList = state => state;
export const getDrawingsPhoto = state => state;
export const getURLShoot = state => state;
export const getBlobDataLogWalk = state => state;
export const getDrawingCode = state => state;
export const getDrawingBlob = state => state;
export const getHistoryListAdjustPhoto = state => state;
export const getListAdjustPhotoSelected = state => state;
export const getListAdjustPhoto = state => state;
export const getListShootsSpot = state => state;
export const getListShootsCurrentActive = state => state;
export const getSpotCurrent = state => state;
export const getTypeSelectImage3D = state => state;
export const getTypeDialog = state => state;
export const getShootsUCNotAdjusted = state => state;
export const getUrlImageShootsUCNotAdjusted = state => state;
export const getIsDefaultHistory = state => state;
export const getProgressLoadImage3D = state => state;
export const getListShootCurrentActiveLatest = state => state;
export const getListSpotsCurrent = state => state;
export const getCurrentHotspotInfoId = state => state;
export const getIsOpenHotspotInfo = state => state;
export const getIsCreateHotspotInfo = state => state;
export const getIsShowDirectionDrawer = state => state;
