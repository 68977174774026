import React from "react";
import Dialog from "@material-ui/core/Dialog";

function ConfirmChangeStatusDialog(props) {
  const { open, handleClose, completedAt, changeStatus } = props;
  return (
    <Dialog onClose={handleClose} open={open} >
      <div style={{ padding: "25px 30px" }}>
        <h4
          style={{
            fontSize: "20px",
            lineHeight: "26px",
            fontWeight: "500",
            color: "#333333",
            marginBottom: "40px",
            textAlign: "center"
          }}
        >
          {completedAt
            ? "このタスクを未完了にしますか？"
            : "このタスクを完了にしますか？"}
        </h4>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <button
            style={{
              width: "108px",
              height: "36px",
              color: "#FFFFFF",
              fontSize: "17px",
              lineHeight: "24px",
              textAlign: "center",
              padding: "6px",
              margin: "0px 20px",
              backgroundColor: "#00346C",
              borderRadius: "3px"
            }}
            onClick={changeStatus}
          >
            Yes
          </button>
          <button
            style={{
              width: "108px",
              height: "36px",
              color: "#FFFFFF",
              fontSize: "17px",
              lineHeight: "24px",
              textAlign: "center",
              padding: "6px",
              margin: "0px 20px",
              backgroundColor: "#C7C7C7",
              borderRadius: "3px"
            }}
            onClick={handleClose}
          >
            No
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default ConfirmChangeStatusDialog;
