import React, { Component } from "react";
import stringquery from "stringquery";
import jwt_decode from "jwt-decode";
import { connect } from "react-redux";
import setAuthToken from "../../utils/setAuthToken";
import { setCurrentUser } from "../auth/action/action"

export class Lp extends Component {

  constructor(props, context, home = '/') {
    super(props, context);
    this.home = home;
  }

  redirectToNotFound() {
    this.props.history.replace(`${this.home}not-found`);
  }

  redirectTo(path) {
    this.props.history.replace(path);
  }

  componentWillMount() {
    const {location} = this.props;
    if (!location.search) {
      return this.redirectToNotFound();
    }
    const queries = stringquery(location.search);
    const {t: encodedJwtToken, p: encodedPath} = queries;
    const jwtToken = encodedJwtToken;
    let decodeJwt = null;
    try {
      decodeJwt = jwt_decode(jwtToken);
    } catch(e) {
      return this.redirectToNotFound();
    }
    if (!encodedPath) {
      return this.redirectToNotFound();
    }
    const path = decodeURIComponent(encodedPath);
    if (path.startsWith('//')) {
      return this.redirectToNotFound();
    }
    if (!path.startsWith(this.home)) {
      return this.redirectToNotFound();
    }
    const token = `Bearer ${jwtToken}`;
    setAuthToken(token);
    this.props.setCurrentUser(decodeJwt);
    return this.redirectTo(path);
  }

  render() {
    return <></>;
  }

}

export default connect(
  () => ({}),
  {setCurrentUser}
)(Lp);
