import {
  FETCH_BUILDER_DETAIL_SUCCESSS,
  FETCH_BUILDER_SUMMARY_SUCCESSS,
  FETCH_BUILDER_ENTRIES_SUCCESS,
  PUT_LOGBUILD_BUIDLER_SUCCESS,
  DELETE_LOGBUILD_BUIDLER_SUCCESS,
  FETCH_BUILDER_SUCCESS,
  FETCH_ROBOT_ENTRIES_SUCCESS,
  FETCH_STAFF_ENTRIES_SUCCESS,
  FETCH_BUILDER_ROBOT_LEASES_SUCCESS,
  POST_BUILDER_ROBOT_LEASES_SUCCESS,
  FETCH_ROBOT_LEASES_SUCCESS,
  PUT_ROBOT_LEASES_SUCCESS,
  DELETE_ROBOT_LEASE_SUCCESS,
  POST_AGREEMENT_LOGS_SUCCESS,
  FETCH_AGREEMENT_LOGS_SUCCESS,
  PUT_AGREEMENT_LOGS_SUCCESS,
  DELETE_AGREEMENT_LOGS_SUCCESS,
  FETCH_AGREEMENT_LOGS_DETAIL_SUCCESS,
  FETCH_BUILDER_FEATURE_FLAG_SUCCESS,
  PUT_BUILDER_FEATURE_FLAG_SUCCESS
} from "../constant/constant";

export const fetchBuilderDetailSuccess = builder => {
  return {
    type: FETCH_BUILDER_DETAIL_SUCCESSS,
    builderDetail: builder
  };
};

export const fetchBuilderEntriesSuccess = entries => {
  return {
    type: FETCH_BUILDER_ENTRIES_SUCCESS,
    entries: entries
  };
};

export const fetchBuilderSummarySuccess = summary => {
  return {
    type: FETCH_BUILDER_SUMMARY_SUCCESSS,
    summary: summary
  };
};

export const putBuilderSuccess = () => {
  return {
    type: PUT_LOGBUILD_BUIDLER_SUCCESS
  };
};

export const deleteBuilderSuccess = () => {
  return {
    type: DELETE_LOGBUILD_BUIDLER_SUCCESS
  };
};

export const fetchBuilderSucess = builder => {
  return {
    type: FETCH_BUILDER_SUCCESS,
    builder: builder
  };
};

export const fetchRobotEntriesSuccess = robotEntries => {
  return {
    type: FETCH_ROBOT_ENTRIES_SUCCESS,
    robotEntries
  };
};

export const fetchStaffEntriesSuccess = staffEntries => {
  return {
    type: FETCH_STAFF_ENTRIES_SUCCESS,
    staffEntries
  };
};

export const fetchBuilderRobotLeasesSuccess = robotLease => {
  return {
    type: FETCH_BUILDER_ROBOT_LEASES_SUCCESS,
    robotLease
  };
};

export const postBuilderRobotLeasesSuccess = () => {
  return {
    type: POST_BUILDER_ROBOT_LEASES_SUCCESS
  };
};

export const fetchRobotLeasesSuccess = robots => {
  return {
    type: FETCH_ROBOT_LEASES_SUCCESS,
    robots
  };
};

export const putRobotLeasesSuccess = () => {
  return {
    type: PUT_ROBOT_LEASES_SUCCESS
  };
};

export const deleteRobotLeasesSuccess = () => {
  return {
    type: DELETE_ROBOT_LEASE_SUCCESS
  };
};

export const postAgreementLogsSuccess = () => {
  return {
    type: POST_AGREEMENT_LOGS_SUCCESS
  };
};

export const fetchAgreementLogsSuccess = agreements => {
  return {
    type: FETCH_AGREEMENT_LOGS_SUCCESS,
    agreements
  };
};

export const putAgreementLogsSuccess = () => {
  return {
    type: PUT_AGREEMENT_LOGS_SUCCESS
  };
};

export const deleteAgreementLogsSuccess = () => {
  return {
    type: DELETE_AGREEMENT_LOGS_SUCCESS
  };
};

export const fetchAgreementLogsDetailSuccess = agreement => {
  return {
    type: FETCH_AGREEMENT_LOGS_DETAIL_SUCCESS,
    agreement
  };
};

export const putAgreementLogSuccess = () => {
  return {
    type: PUT_AGREEMENT_LOGS_SUCCESS
  };
};

export const fetchBuilderFeatureFlagSuccess = featureFlag => {
  return {
    type: FETCH_BUILDER_FEATURE_FLAG_SUCCESS,
    featureFlag
  };
};
export const putBuilderFeatureFlagSuccess = () => {
  return {
    type: PUT_BUILDER_FEATURE_FLAG_SUCCESS
  };
};