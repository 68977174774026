import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import IconEdit from "../hotspotInfoIcons/IconEdit";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Tooltip from "@material-ui/core/Tooltip";
import { format } from "date-fns";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    margin: "12px 0px"
  },
  tooltip: {
    fontSize: "12px"
  }
});

function EditFileName(props) {
  const { currentFile, handleSaveName } = props;
  const classes = useStyles();

  const [fileName, setFileName] = useState("");
  const [showEditName, setShowEditName] = useState(false);

  useEffect(() => {
    if (currentFile) {
      setFileName(currentFile.name);
    }
  }, [currentFile, showEditName]);

  useEffect(() => {
    setShowEditName(false);
  }, [currentFile]);

  return (
    <React.Fragment>
      {showEditName ? (
        <div className={classes.root}>
          <span
            style={{
              width: "26px",
              height: "26px"
            }}
            onClick={() => setShowEditName(false)}
          >
            <ArrowBackIcon />
          </span>
          <input
            value={fileName}
            onChange={e => setFileName(e.target.value)}
            style={{
              flexGrow: "1",
              height: "40px",
              borderRadius: "4px",
              backgroundColor: "#F8F9F9",
              color: "#00356D",
              fontSize: "17px",
              lineHeight: "24px",
              padding: "8px",
              margin: "0px 10px",
              boxSizing: "border-box",
              border: "none"
            }}
          />
          <button
            onClick={() => handleSaveName(fileName.trim())}
            disabled={fileName.trim().length === 0}
            style={fileName.trim().length === 0 ?
              ({
                borderRadius: "4px",
                backgroundColor: "#e0e0e0",
                color: "#808080",
                fontSize: "15px",
                lineHeight: "24px",
                padding: "8px 20px",
                whiteSpace: "nowrap",
              }) :
              ({
                borderRadius: "4px",
                backgroundColor: "#00356D",
                color: "#fff",
                fontSize: "15px",
                lineHeight: "24px",
                padding: "8px 20px",
                whiteSpace: "nowrap",
              })}
          >
            決定
          </button>
        </div>
      ) : (
        <div
          className={classes.root}
          style={{
            fontSize: "17px"
          }}
          onClick={() => setShowEditName(true)}
        >
          <span>
            <IconEdit />
          </span>
          <Tooltip
            arrow
            title={currentFile.name}
            placement="bottom-start"
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              style={{
                color: "#00356D",
                margin: "0px  15px",
                width: "250px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                flexGrow: "1"
              }}
            >
              {currentFile.name}
            </div>
          </Tooltip>

          <div
            style={{
              fontSize: "14px",
              whiteSpace: "nowrap"
            }}
          >
            {currentFile.createdAt && format(new Date(currentFile.createdAt), "yyyy/MM/dd (HH:mm)")}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

EditFileName.propTypes = {};

export default EditFileName;
