import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import copy from "copy-to-clipboard";
import { useLocation } from "react-router-dom";
import { api } from "../../common/api/api";
import shareUrlIcon from "../../common/img/share-url.png";
import {
  Button,
  DialogActions,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { format } from "date-fns";

const mediaQueryTabletOrMobile = "(max-width: 960px)";

const useStyles = makeStyles({
  dialogTitle: {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px"
  },

  createShareUrlForm: {
    display: "flex"
  },

  createShareUrlFormInput: {
    width: "50%",
    padding: "5px 10px",
    "& label": {
      fontSize: "14px"
    },
    marginBottom: "8px"
  },

  editButtonAction: {
    fontSize: "16px",
    color: "white",
    padding: "8px 12px",
    borderRadius: "6px",
    marginRight: "14px",
    cursor: "pointer"
  },
  truncateText: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  textUnderline: {
    "&:hover": {
      textDecoration: "underline"
    }
  },
  truncateText2: {
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "wrap",
    wordBreak: "break-word"
  },
  truncateText3: {
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "wrap",
    wordBreak: "break-word"
  }
});

var showMessageTimeOut = null;

function EditShareUrlDialog(props) {
  const {
    loading,
    editingShareUrl,
    setEditingShareUrl,
    editShareUrlSuccess,
    inactiveShareUrlSuccess
  } = props;

  const [siteName, setSiteName] = useState("");
  const [spotName, setSpotName] = useState("");
  const [isHideName, setIsHideName] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showMessage, setShowMessage] = useState("");

  const classes = useStyles();
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: mediaQueryTabletOrMobile });

  useEffect(() => {
    if (editingShareUrl) {
      setSiteName(editingShareUrl.SiteName);
      setSpotName(editingShareUrl.SpotName);
      setIsHideName(editingShareUrl.IsHideName);
    }
  }, [editingShareUrl]);

  useEffect(() => {
    if (showMessage) {
      if (showMessageTimeOut) {
        clearTimeout(showMessageTimeOut);
      }

      showMessageTimeOut = setTimeout(() => {
        setShowMessage(false);
      }, 2000);
    }
  }, [showMessage]);

  const handleEditShareUrl = data => {
    setIsEditing(true);
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    api
      .put(`/${classification}/url-share/${editingShareUrl.Id}`, data)
      .then(res => {
        editShareUrlSuccess(editingShareUrl.Id, res.data);
        // setShowMessage("successfully changed")
        setIsEditing(false);
      })
      .catch(err => {
        setIsEditing(false);
      });
  };

  const inactiveShareUrl = () => {
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .delete(`/${classification}/url-share/${editingShareUrl.Id}`)
      .then(() => {
        setEditingShareUrl(null);
        inactiveShareUrlSuccess();
      });
  };

  const enableEdit = () => {
    return (
      siteName !== "" &&
      spotName !== "" &&
      (siteName !== editingShareUrl.SiteName ||
        spotName !== editingShareUrl.SpotName ||
        isHideName !== editingShareUrl.IsHideName)
    );
  };

  return (
    <React.Fragment>
      <Dialog
        open={editingShareUrl}
        onClose={() => {}}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            padding: "32px 16px",
            width: !isTabletOrMobile ? "calc(100% - 32px)" : "calc(100% - 8px)",
            margin: !isTabletOrMobile ? "16px" : "4px",
            boxShadow: "none",
            backgroundColor: "rgba(0,0,0,0)"
          }
        }}
      >
        <React.Fragment>
          {loading || !editingShareUrl ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 0px"
              }}
            >
              <CircularProgress size={40} />
            </div>
          ) : (
            <React.Fragment>
              <button
                style={{
                  position: "absolute",
                  top: "16px",
                  right: "0px",
                  width: "32px",
                  height: "32px",
                  backgroundColor: "#162D4F",
                  borderRadius: "999px",
                  cursor: "pointer",
                  color: "white",
                  fontSize: "24px",
                  padding: "4px"
                }}
                onClick={() => setEditingShareUrl(null)}
              >
                <CloseIcon color="inherit" fontSize="inherit" />
              </button>

              {showMessage && (
                <span
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "50%",
                    height: "32px",
                    backgroundColor: "#000000",
                    color: "white",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    transform: "translate(-50%)",
                    padding: "0px 12px",
                    whiteSpace: "nowrap"
                  }}
                >
                  {showMessage}
                </span>
              )}
              <div
                style={{
                  padding: "20px",
                  borderRadius: "12px",
                  backgroundColor: "#ffffff"
                }}
              >
                {isTabletOrMobile ? (
                  <React.Fragment>
                    <Grid
                      container
                      spacing={0}
                      style={{
                        marginRight: "-12px",
                        marginLeft: "-12px",
                        marginBottom: "12px"
                      }}
                    >
                      <Grid item xs={2}>
                        <Grid
                          container
                          direction="column"
                          justify="space-around"
                          alignItems="center"
                          style={{
                            height: "100%",
                            backgroundColor: "#002060",
                            color: "white"
                          }}
                        >
                          <Grid item>現場名</Grid>
                          <Grid item>地点名</Grid>
                          <Grid item>発行者</Grid>
                        </Grid>{" "}
                      </Grid>
                      <Grid item xs={4}>
                        <Grid
                          container
                          direction="column"
                          justify="space-around"
                          style={{ height: "100%" }}
                        >
                          <Grid
                            item
                            className={classes.truncateText2}
                            style={{ paddingLeft: "4px", paddingRight: "4px" }}
                          >
                            {editingShareUrl.SiteName}
                          </Grid>
                          <Grid
                            item
                            className={classes.truncateText2}
                            style={{ paddingLeft: "4px", paddingRight: "4px" }}
                          >
                            {editingShareUrl.SpotName}
                          </Grid>
                          <Grid
                            item
                            className={classes.truncateText2}
                            style={{ paddingLeft: "4px", paddingRight: "4px" }}
                          >
                            {editingShareUrl.CreatedBy.name}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid
                          container
                          direction="column"
                          justify="space-around"
                          alignItems="center"
                          style={{
                            height: "100%",
                            backgroundColor: "#002060",
                            color: "white"
                          }}
                        >
                          <Grid item>写真日時</Grid>
                          <Grid item>共有日時</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid
                          container
                          direction="column"
                          justify="space-around"
                          alignItems="center"
                          className="text-center"
                          style={{ height: "100%" }}
                        >
                          <Grid item style={{ padding: "4px" }}>
                            <p>
                              {format(
                                new Date(editingShareUrl.ShootedAt),
                                "yyyy/MM/dd"
                              )}
                            </p>
                            <p>
                              {format(
                                new Date(editingShareUrl.ShootedAt),
                                "HH:mm"
                              )}
                            </p>
                          </Grid>
                          <Grid item style={{ padding: "4px" }}>
                            <p>
                              {format(
                                new Date(editingShareUrl.CreatedAt),
                                "yyyy/MM/dd"
                              )}
                            </p>
                            <p>
                              {format(
                                new Date(editingShareUrl.CreatedAt),
                                "HH:mm"
                              )}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <hr
                      style={{
                        height: "1px",
                        margin: "16px -16px",
                        backgroundColor: "#000000",
                        border: "none"
                      }}
                    />
                  </React.Fragment>
                ) : (
                  <TableContainer className="table-remove-overflow">
                    <Table
                      size="small"
                      aria-label="sticky table"
                      style={{ marginBottom: "20px" }}
                    >
                      <TableHead>
                        <TableRow style={{ backgroundColor: "#162f50" }}>
                          <TableCell
                            className="white-text text-center"
                            style={{
                              width: "200px",
                              borderRadius: "0px",
                              padding: "6px 10px",
                              minWidth: "100px"
                            }}
                          >
                            現場名
                          </TableCell>

                          <TableCell
                            className="white-text text-center"
                            style={{
                              width: "200px",
                              borderRadius: "0px",
                              padding: "6px 10px",
                              minWidth: "100px"
                            }}
                          >
                            地点名
                          </TableCell>

                          <TableCell
                            className="white-text text-center"
                            style={{
                              width: "120px",
                              borderRadius: "0px",
                              padding: "6px 10px"
                            }}
                          >
                            発行者
                          </TableCell>

                          <TableCell
                            className="white-text text-center"
                            style={{
                              width: "120px",
                              borderRadius: "0px",
                              padding: "6px 10px"
                            }}
                          >
                            写真日時
                          </TableCell>

                          <TableCell
                            className="white-text text-center"
                            style={{
                              width: "120px",
                              borderRadius: "0px",
                              padding: "6px 10px"
                            }}
                          >
                            共有日時
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="table-body">
                        <TableRow key={editingShareUrl.id}>
                          <TableCell
                            style={{
                              paddingLeft: "8px",
                              paddingRight: "8px"
                            }}
                          >
                            <p className={classes.truncateText3}>
                              {editingShareUrl.SiteName}
                            </p>
                          </TableCell>

                          <TableCell
                            style={{
                              paddingLeft: "6px",
                              paddingRight: "14px"
                            }}
                          >
                            <p className={classes.truncateText3}>
                              {editingShareUrl.SpotName}
                            </p>
                          </TableCell>

                          <TableCell
                            className="text-center"
                            style={{
                              paddingLeft: "6px",
                              paddingRight: "14px"
                            }}
                          >
                            <p className={classes.truncateText1}>
                              {editingShareUrl.CreatedBy.name}
                            </p>
                          </TableCell>

                          <TableCell
                            className="text-center"
                            style={{
                              paddingLeft: "8px",
                              paddingRight: "8px"
                            }}
                          >
                            <p>
                              {format(
                                new Date(editingShareUrl.ShootedAt),
                                "yyyy/MM/dd"
                              )}
                            </p>
                            <p>
                              {format(
                                new Date(editingShareUrl.ShootedAt),
                                "HH:mm"
                              )}
                            </p>
                          </TableCell>

                          <TableCell
                            className="text-center"
                            style={{
                              paddingLeft: "8px",
                              paddingRight: "8px"
                            }}
                          >
                            <p>
                              {format(
                                new Date(editingShareUrl.CreatedAt),
                                "yyyy/MM/dd"
                              )}
                            </p>
                            <p>
                              {format(
                                new Date(editingShareUrl.CreatedAt),
                                "HH:mm"
                              )}
                            </p>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                <div className={classes.dialogTitle}>
                  <img
                    src={shareUrlIcon}
                    width={28}
                    height={28}
                    alt="shareUrlIcon"
                    style={{ marginRight: "10px" }}
                  />
                  <span style={{ fontSize: false ? "20px" : "24px" }}>
                    URLは発行済みです
                  </span>
                </div>

                <div style={{ display: "flex", marginBottom: "12px" }}>
                  <div
                    style={{
                      flexGrow: "1",
                      marginRight: "20px",
                      borderRadius: "4px",
                      backgroundColor: "#D9D9D9",
                      padding: "4px 12px",
                      fontSize: "16px",
                      whiteSpace: "nowrap"
                    }}
                    className={classes.truncateText}
                  >
                    {`${window.location.origin}/url-share/${editingShareUrl.BuildersUC}/${editingShareUrl.Id}`}
                  </div>
                  <button
                    style={{
                      padding: "0px 8px",
                      color: "#2ABDF2",
                      fontSize: "18px",
                      cursor: "pointer",
                      flexShrink: "0",
                      whiteSpace: "nowrap"
                    }}
                    className={classes.textUnderline}
                    onClick={() => {
                      copy(
                        `${window.location.origin}/url-share/${editingShareUrl.BuildersUC}/${editingShareUrl.Id}`
                      );
                      setShowMessage("URLをコピーしました");
                    }}
                  >
                    URLをコピー
                  </button>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    paddingLeft: "18px",
                    fontSize: "15px",
                    marginBottom: "12px",
                    color: "#A0A0A0"
                  }}
                >
                  <span style={{ marginRight: "8px" }}>
                    URLを知っている全員がアクセスでき、ログインは不要です
                  </span>
                  <span
                    style={{
                      color: "#2ABDF2",
                      cursor: "pointer"
                    }}
                    className={classes.textUnderline}
                    onClick={() => {
                      window.open(
                        `/url-share/${editingShareUrl.BuildersUC}/${editingShareUrl.Id}`,
                        "_blank"
                      );
                    }}
                  >
                    URLを確認する
                  </span>
                </div>

                <div className={classes.createShareUrlForm}>
                  <div className={classes.createShareUrlFormInput}>
                    <span>現場名</span>
                    <input
                      type="text"
                      style={{
                        backgroundColor: "#F2F2F2",
                        margin: "0px",
                        border: "none",
                        borderRadius: "4px",
                        boxShadow: "none",
                        padding: "0px 12px",
                        fontSize: "20px",
                        width: "100%",
                        boxSizing: "border-box"
                      }}
                      value={siteName}
                      onChange={event => setSiteName(event.target.value)}
                    />
                  </div>

                  <div className={classes.createShareUrlFormInput}>
                    <span>地点名</span>
                    <input
                      type="text"
                      style={{
                        backgroundColor: "#F2F2F2",
                        margin: "0px",
                        border: "none",
                        borderRadius: "4px",
                        boxShadow: "none",
                        padding: "0px 12px",
                        fontSize: "20px",
                        width: "100%",
                        boxSizing: "border-box"
                      }}
                      value={spotName}
                      onChange={event => setSpotName(event.target.value)}
                    />
                  </div>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>
                    <Checkbox
                      checked={isHideName}
                      onClick={() => setIsHideName(!isHideName)}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </span>
                  <span
                    onClick={() => setIsHideName(!isHideName)}
                    style={{ cursor: "pointer", userSelect: "none" }}
                  >
                    現場名と地点名を非表示にする
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center"
                  }}
                >
                  <button
                    className={classes.editButtonAction}
                    style={{ backgroundColor: "#002060" }}
                    onClick={() => {
                      setShowConfirmDelete(true);
                    }}
                  >
                    URLの共有中止
                  </button>
                  <button
                    className={classes.editButtonAction}
                    style={{
                      backgroundColor: enableEdit() ? "#002060" : "#D0CECE",
                      cursor: enableEdit() ? "pointer" : "default"
                    }}
                    onClick={() => {
                      if (enableEdit()) {
                        const data = {
                          SiteName: siteName,
                          SpotName: spotName,
                          IsHideName: isHideName
                        };
                        handleEditShareUrl(data);
                      }
                    }}
                  >
                    変更する
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      </Dialog>
      <Dialog
        open={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ backgroundColor: "#e0e0e0eb" }}
        className={classes.dialogDelete}
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          このURLは無効になりアクセスできなくなります。
          <br />
          URLの共有を中止しますか？
        </DialogTitle>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={() => {
              setShowConfirmDelete(false);
              inactiveShareUrl();
            }}
            className="btn-dialog-yes"
            style={{
              color: "#FFFFFF",
              backgroundColor: "#00356D",
              margin: "0px 8px"
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => setShowConfirmDelete(false)}
            className="btn-dialog-no"
            autoFocus
            style={{
              color: "#FFFFFF",
              backgroundColor: "#C7C7C7",
              margin: "0px 8px"
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default EditShareUrlDialog;
