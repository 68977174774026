import {
  FETCH_LOGBUILD_AUTH_SUCCESS,
  FETCH_LOGBUILD_STAFF_SUCCESS,
  POST_LOGBUILD_STAFF_SUCCESS,
  FETCH_LOGBUILD_STAFF_DETAIL_SUCCESS,
  PUT_LOGBUILD_STAFF_SUCCESS,
  DELETE_LOGBUILD_STAFF_SUCCESS,
  PUT_PASSWORD_LOGBUILD_SUCCESS
} from "../constant/constant";

export const fetchLogBuildAuthSuccess = entries => {
  return {
    type: FETCH_LOGBUILD_AUTH_SUCCESS,
    entries: entries
  };
};

export const fetchLogBuildStaffSuccess = staffLogBuild => {
  return {
    type: FETCH_LOGBUILD_STAFF_SUCCESS,
    staffLogBuild: staffLogBuild
  };
};

export const postLogBuildStaffSuccess = () => {
  return {
    type: POST_LOGBUILD_STAFF_SUCCESS
  };
};

export const fetchLogBuildStaffDetailSuccess = staffLogBuildDetail => {
  return {
    type: FETCH_LOGBUILD_STAFF_DETAIL_SUCCESS,
    staffLogBuildDetail: staffLogBuildDetail
  };
};

export const putLogBuildStaffSuccess = () => {
  return {
    type: PUT_LOGBUILD_STAFF_SUCCESS
  };
};

export const deleteLogBuildStaffSuccess = () => {
  return {
    type: DELETE_LOGBUILD_STAFF_SUCCESS
  };
};

export const putPasswordLogBuildSuccess = () => {
  return {
    type: PUT_PASSWORD_LOGBUILD_SUCCESS
  };
};
