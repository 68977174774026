import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import CommonDialog from "../CommonDialog";
import { DIALOG_FIELD_TYPES } from "../../common/Stores/constant/constant";
import { createRobot, updateRobot, deleteRobot } from "./service/service";
import DialogDelete from "../../common/DialogDelete";
import { dispatchError as fillError } from "../../common/Stores/service/service";
import { fetchRobotLogBuildDetailInfoSuccess as fillRobotDetail } from "./robotDetails/RobotInfo/action/action";
import { convertSerialNumber } from "../../utils/mapper";
import { DELETE_ROBOT_SUCCESS } from "./constant/constant";
import { fetchRobotEdit } from "./robotDetails/service/service";

class RobotDialog extends React.PureComponent {
  fieldList = [
    {
      name: "serialNumber",
      label: "管理番号",
      type: DIALOG_FIELD_TYPES.Text,
      disable: true
    },
    {
      name: "robotId",
      label: "ロボットID",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    },
    {
      name: "cerealCode",
      label: "シリアルナンバー",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    },
    {
      name: "skyWayAPIKey",
      label: "Skyway ID",
      type: DIALOG_FIELD_TYPES.Text
    }
  ];

  state = {
    defaultValues: {
      robotId: "",
      cerealCode: "",
      skyWayAPIKey: ""
    }
  };

  componentWillMount() {
    if (!this.props.isEdit) {
      this.fieldList.splice(0, 1);
    }
  }

  componentDidMount() {
    if (this.props.isEdit) this.props.fetchRobotEdit(this.props.robotId);
  }

  componentWillUnmount() {
    this.props.fillError("");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type === DELETE_ROBOT_SUCCESS) {
      this.props.history.push("/logbuild/robots");
    }
  }

  onSubmitForm = values => {
    const { isEdit, robotId } = this.props;

    if (isEdit && robotId !== "") {
      this.props.updateRobot(robotId, values);
    } else {
      this.props.createRobot(values);
    }
  };

  onDeleteRobot = () => {
    if (this.props.isEdit && this.props.robotId !== "") {
      this.props.deleteRobot(this.props.robotId);
      this.toggleDialogDelete();
    }
  };

  toggleDialogDelete = () => {
    this.props.toggleDialogDelete();
  };

  render() {
    const {
      isDialogShow,
      onClose,
      isEdit,
      submitErrors,
      robotEdit,
      isDialogDeleteShow
    } = this.props;

    const { defaultValues } = this.state;
    const chanchuoi = {
      cerealCode: robotEdit?.cerealCode || "",
      deleteable: robotEdit?.deleteable || "",
      robotId: robotEdit?.robotId || "",
      skyWayAPIKey: robotEdit?.skyWayAPIKey || "",
      uniqueCode: robotEdit?.uniqueCode || "",
      serialNumber: robotEdit?.serialNumber
        ? convertSerialNumber(robotEdit.serialNumber)
        : ""
    };

    if (!chanchuoi.skyWayAPIKey) {
      chanchuoi.skyWayAPIKey = "";
    }

    return (
      <>
        <CommonDialog
          title={isEdit ? "ロボット情報編集" : "ロボット情報登録"}
          isDialogShow={isDialogShow}
          initialValues={isEdit ? chanchuoi : defaultValues}
          onSubmit={this.onSubmitForm}
          onClose={onClose}
          fieldList={this.fieldList}
          isEdit={isEdit}
          submitButtonTitle={isEdit ? "登録" : "登録"}
          submitErrors={submitErrors}
          onDeleteClick={this.toggleDialogDelete}
          isSingleColumn={true}
          deletable={chanchuoi?.deleteable}
        />
        <DialogDelete
          open={isDialogDeleteShow}
          onClose={this.toggleDialogDelete}
          onDeleteSubmit={this.onDeleteRobot}
          PaperProps={{
            className: "dialog dialog-sign-up"
          }}
        />
      </>
    );
  }
}

RobotDialog.propTypes = {
  isDialogShow: PropTypes.bool,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
  robotId: PropTypes.string
};

const mapStateToProps = state => ({
  submitErrors: state.storesReducer.error,
  robotDetail:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailInfoReducer
      .robotLogBuildInfo,
  robotEdit:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .robotEdit,
  type: state.rootRobotsReducer.robotsReducer.type
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createRobot,
      updateRobot,
      fillError,
      fillRobotDetail,
      deleteRobot,
      fetchRobotEdit
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RobotDialog);
