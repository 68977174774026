import {
  fetchBuilderDetailSuccess,
  fetchBuilderEntriesSuccess,
  fetchBuilderSummarySuccess,
  fetchBuilderSucess,
  deleteBuilderSuccess,
  fetchRobotEntriesSuccess,
  fetchStaffEntriesSuccess,
  fetchBuilderRobotLeasesSuccess,
  postBuilderRobotLeasesSuccess,
  fetchRobotLeasesSuccess,
  putRobotLeasesSuccess,
  deleteRobotLeasesSuccess,
  fetchAgreementLogsSuccess,
  postAgreementLogsSuccess,
  fetchAgreementLogsDetailSuccess,
  putAgreementLogsSuccess,
  deleteAgreementLogsSuccess,
  fetchBuilderFeatureFlagSuccess,
  putBuilderFeatureFlagSuccess
} from "../action/action";
import {
  finishLoading,
  dispatchError,
  enableLoading
} from "common/Stores/service/service";
import { api } from "common/api/api";
import { putBuilderSuccess } from "components/shops/shopDetail/action/action";

export const fetchBuilderDetail = uniqueCode => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/builders/${uniqueCode}/detail`)
      .then(res => {
        dispatch(fetchBuilderDetailSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchBuilderEntries = () => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/builders/entries`)
      .then(res => {
        dispatch(fetchBuilderEntriesSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchBuilderSummary = uniqueCode => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/builders/${uniqueCode}/summary`)
      .then(res => {
        dispatch(fetchBuilderSummarySuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchBuilder = uniqueCode => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/builders/${uniqueCode}`)
      .then(res => {
        dispatch(fetchBuilderSucess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const putBuilder = (data, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/logbuild/builders/${data.uniqueCode}`, data)
      .then(res => {
        dispatch(putBuilderSuccess());
        dispatch(fetchBuilderSummary(data.uniqueCode));
        dispatch(fetchBuilderDetail(data.uniqueCode));
        dispatch(finishLoading());
      })
      .catch(err => {
        if(handleError && err.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(err));
        }
      });
  };
};

export const deleteBuilder = (uniqueCode, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/logbuild/builders/${uniqueCode}`)
      .then(res => {
        dispatch(deleteBuilderSuccess());
        dispatch(finishLoading());
      })
      .catch(err => {
        if(handleError && err.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(err));
        }
      });
  };
};

export const fetchRobotEntries = () => {
  return dispatch => {
    api.get(`/logbuild/builder-robot-lease-candidates/entries`).then(res => {
      dispatch(fetchRobotEntriesSuccess(res.data));
    });
  };
};

export const fetchStaffEntries = () => {
  return dispatch => {
    api.get(`/logbuild/staffs/entries`).then(res => {
      dispatch(fetchStaffEntriesSuccess(res.data));
    });
  };
};

export const fetchBuilderRobotLease = id => {
  return dispatch => {
    api.get(`/logbuild/builder-robot-leases?buildersUC=${id}`).then(res => {
      dispatch(fetchBuilderRobotLeasesSuccess(res.data));
    });
  };
};

export const fetchLogBuildRobot = id => {
  return dispatch => {
    dispatch(enableLoading());
    Promise.all([
      dispatch(fetchStaffEntries()),
      dispatch(fetchBuilderRobotLease(id))
    ])
      .then(dispatch(finishLoading()))
      .catch(errors => {
        dispatch(dispatchError(errors));
      });
  };
};

export const postBuilderRobotLease = value => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/logbuild/builder-robot-leases`, value)
      .then(res => {
        dispatch(postBuilderRobotLeasesSuccess(res.data));
        dispatch(fetchBuilderRobotLease(value.buildersUC));
        dispatch(finishLoading());
      })
      .catch(errors => {
        dispatch(dispatchError(errors));
      });
  };
};

export const fetchRobotLeases = urlParams => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/builder-robot-leases/${urlParams}`)
      .then(res => {
        dispatch(fetchRobotLeasesSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const putRobotLeases = (params, robotLeases) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/logbuild/builder-robot-leases/${params}`, robotLeases)
      .then(res => {
        dispatch(putRobotLeasesSuccess(res.data));
        dispatch(fetchBuilderRobotLease(robotLeases.buildersUC));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const deleteRobotLeases = params => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/logbuild/builder-robot-leases/${params}`)
      .then(res => {
        dispatch(deleteRobotLeasesSuccess());
        const builderUC = params.split(".")[0];
        dispatch(fetchBuilderRobotLease(builderUC));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchRobotEntriesEdit = robotsUC => {
  return dispatch => {
    api
      .get(
        `/logbuild/builder-robot-lease-candidates/entries?robotsUC=${robotsUC}`
      )
      .then(res => {
        dispatch(fetchRobotEntriesSuccess(res.data));
      });
  };
};

export const fetchAgreementLogs = builderUC => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/builder-agreement-logs?buildersUC=${builderUC}`)
      .then(res => {
        dispatch(fetchAgreementLogsSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchAgreementDetail = params => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/builder-agreement-logs/${params}`)
      .then(res => {
        dispatch(fetchAgreementLogsDetailSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const postAgreementLogs = agreement => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/logbuild/builder-agreement-logs`, agreement)
      .then(res => {
        dispatch(postAgreementLogsSuccess(res.data));
        dispatch(fetchAgreementLogs(agreement.buildersUC));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const putAgreementDetail = (params, agreement) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/logbuild/builder-agreement-logs/${params}`, agreement)
      .then(res => {
        dispatch(putAgreementLogsSuccess(res.data));
        dispatch(fetchAgreementLogs(agreement.buildersUC));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const deleteAgreement = params => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/logbuild/builder-agreement-logs/${params}`)
      .then(res => {
        dispatch(deleteAgreementLogsSuccess(res.data));
        dispatch(fetchAgreementLogs(params.split(".")[0]));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchBuilderFeatureFlag = id => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/builders/${id}/featureflags`)
      .then(res => {
        dispatch(fetchBuilderFeatureFlagSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const putBuilderFeatureFlag = (params, features) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/logbuild/builders/${params}/featureflags`, features)
      .then(res => {
        dispatch(putBuilderFeatureFlagSuccess(res.data));
        dispatch(fetchBuilderFeatureFlag(params));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};