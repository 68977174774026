import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhotosSP from "./PhotosSP";
import FilesSP from "./FilesSP";
// import { getType } from "../reducer/reducer";
import "../../../../common/css/base.css";
import "../../../../common/css/sites-image.css";
import SiteRobotSP from "./SiteRobotSP";
import { finishLoading } from "../../../../common/Stores/service/service";
import ButtonReturn from "../../../../common/ButtonReturn";
import stringquery from "stringquery";
import DrawingDialog from "../../../DrawingDialog";
import { api } from "../../../../common/api/api";
import Fab from "@material-ui/core/Fab";
import PlayCircleOutlineOutlinedIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import ButtonRobot from "../../../../common/ButtonRobot";
import DialogRobots from "../../../../common/DialogRobots";
import RobotStatus from "common/RobotStatus";
import { BUTTON_LOGKUN_SP } from "common/constants/constants";
import {
  wsUpdatedRobots,
  wsClearRobots
} from "../../../robots/robotDetails/service/service";
import { getSiteSP, setTabElement } from "../service/service";
import { GET_SITE_SP_SUCCESS } from "../constant/constant";
import { getWebSocketRobotURL } from "utils/mapper";
import { MODE_DEVICE } from "components/sites/site/LogWalkViewer/constants";
import { POST_SITES_SUCCESS } from "components/sites/constant/constant";

const WS_TIME_REPEAT = 10000; //10s

const Element = {
  Photos: 1,
  Files: 2,
  Info: 3
};

class indexSP extends Component {
  ws = null;
  wsInterval = null;
  pingInterval = null;

  state = {
    // element: Element.Photos,
    isShowDialogDrawing: false,
    logWalkViewer: false,
    robots: [],
    isDialogRobotsShow: false,
    firstFetch: true
  };

  componentWillMount() {
    const sitesUC = this.props.match.params.id;
    this.props.getSiteSP(sitesUC);
    api
      .get(`/supplier/sites/${sitesUC}`)
      .then(res => {
        const site = res.data;
        this.setState({
          logWalkViewer: site.logWalkViewer,
          robots: site.robots
        });
        const { location } = this.props;
        const queries = stringquery(location.search);
        if (!("logwalk" in queries)) {
          return;
        }
        if (site.logWalkViewer) {
          this.showHideDialogDrawing(sitesUC);
          const element =
            this.props.type === POST_SITES_SUCCESS
              ? Element.Info
              : this.props.tabElement;
          this.props.setTabElement(element);
        }
      })
      .catch(e => {});
  }

  componentWillReceiveProps(props) {
    if (props.type === GET_SITE_SP_SUCCESS) {
      let robotsUC = [];
      props.siteSP.robots.map((robot, idx) => {
        robotsUC = [...robotsUC, robot.uniqueCode];
      });

      if (this.state.firstFetch && robotsUC.length > 0) {
        if (this.ws) {
          this.ws.onclose = function () {}; // disable onclose handler first
          this.ws.close();
          this.props.wsClearRobots();
          clearInterval(this.wsInterval);
          clearInterval(this.pingInterval);
        }

        this.openWS(robotsUC);

        this.wsInterval = setInterval(() => {
          if (this.ws.readyState === 3) {
            clearInterval(this.pingInterval);
            this.openWS(robotsUC);
          }
        }, WS_TIME_REPEAT);

        this.setState({ firstFetch: false });
      }

      this.setState({
        logWalkViewer: props.siteSP.logWalkViewer,
        robots: props.siteSP.robots
      });

      const { location } = props;
      const queries = stringquery(location.search);
      if (!("logwalk" in queries)) {
        return;
      }
      if (props.siteSP.logWalkViewer) {
        const sitesUC = this.props.match.params.id;
        this.showHideDialogDrawing(sitesUC);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.wsInterval);
    clearInterval(this.pingInterval);

    if (this.ws) {
      this.ws.onclose = () => {}; // disable onclose handler first
      this.ws.close();
      this.props.wsClearRobots();
    }
  }

  openWS = robotsUC => {
    this.ws = new WebSocket(getWebSocketRobotURL());
    this.ws.addEventListener("open", () => {
      this.ws.send(JSON.stringify(robotsUC));

      this.pingInterval = setInterval(() => {
        this.ws.send("ping");
      }, WS_TIME_REPEAT);
    });

    this.ws.onmessage = s => {
      if (s.data !== "pong") {
        this.props.wsUpdatedRobots(JSON.parse(s.data));
      }
    };
  };

  showHideDialogDrawing = () => {
    this.setState({
      isShowDialogDrawing: !this.state.isShowDialogDrawing
    });
  };

  selectElement = element => {
    this.props.setTabElement(element);
  };

  render() {
    const {
      element,
      isShowDialogDrawing,
      logWalkViewer,
      robots,
      isDialogRobotsShow
    } = this.state;
    const { match, robotsUpdate, tabElement } = this.props;

    const showDialogRobots = e => {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        isDialogRobotsShow: true
      });
    };

    const closeDialogRobots = () => {
      this.setState({
        isDialogRobotsShow: false
      });
    };

    return (
      <>
        <Grid container className="sites-image-sp">
          <Tabs
            className="tabs"
            value={this.props.tabElement}
            onChange={(e, value) => this.selectElement(value)}
            aria-label="simple tabs example"
          >
            <Tab
              className="tab-button worker-sp"
              label="写真"
              value={Element.Photos}
            />
            <Tab
              className="tab-button worker-sp"
              label="ファイル(PDF)"
              value={Element.Files}
            />
            <Tab
              className="tab-button worker-sp"
              label="現場情報"
              value={Element.Info}
            />
          </Tabs>
          <Grid className="tab-content">
            {tabElement === Element.Photos && (
              <PhotosSP sitesUC={match.params.id} />
            )}
            {tabElement === Element.Files && (
              <FilesSP sitesUC={match.params.id} />
            )}
            {tabElement === Element.Info && (
              <SiteRobotSP
                sitesUC={match.params.id}
                robotsUpdate={robotsUpdate}
              />
            )}
          </Grid>
          <Link to="/sp/dashboard" className="reset-link">
            <ButtonReturn />
          </Link>
          <Fab
            className={
              logWalkViewer
                ? "white-text  button-logWalk-sp mx-5 button-sp fab-button-logwalk-sp logwalk-sp-right"
                : "white-text button-logWalk-sp-disable mx-5 button-sp fab-button-logwalk-sp logwalk-sp-right"
            }
            onClick={e => {
              if (logWalkViewer) {
                this.showHideDialogDrawing();
              }
              e.stopPropagation();
            }}
          >
            <PlayCircleOutlineOutlinedIcon style={{ textTransform: "none" }} />
          </Fab>
          {/* <RobotStatus
            typeOfRobotStatus={BUTTON_LOGKUN_SP}
            robots={robots.length > 0 ? robots : null}
            staticPosition={true}
            robotsUpdate={[robotsUpdate]}
          /> */}
        </Grid>

        {isDialogRobotsShow && (
          <DialogRobots robots={robots} onClose={closeDialogRobots} />
        )}

        {isShowDialogDrawing && (
          // <DrawingDialog
          //   open={isShowDialogDrawing}
          //   onClose={this.showHideDialogDrawing}
          //   sitesUC={match.params.id}
          //   isSp={true}
          // />
          <DrawingDialog
            open={isShowDialogDrawing}
            modeDevice={MODE_DEVICE.SP}
            onClose={this.showHideDialogDrawing}
            sitesUC={match.params.id}
            isSp={true}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  robotsUpdate:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .wsUpdatedRobots,
  type: state.rootSitesReducer.siteInfoReducer.type,
  siteSP: state.rootSitesReducer.siteInfoReducer.siteSP,
  tabElement: state.rootSitesReducer.siteInfoReducer.tabElement
});

export default connect(mapStateToProps, {
  finishLoading,
  wsUpdatedRobots,
  wsClearRobots,
  getSiteSP,
  setTabElement
})(withRouter(indexSP));
