import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Component from "./Upload";
import {
  fetchPhotos,
  fetchPhotoInfo,
  showOriginalPhoto,
  postPhoto as postFile,
  downloadPhoto
} from "./service/service";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";

import { format } from "date-fns";
import Photo from "./Photo";
import { getPhotos } from "./reducer/reducer";
import EditImageDialog from "./editImageDialog";
import {
  PUT_PHOTO_SUCCESS,
  DELETE_PHOTO_SUCCESS,
  GET_PHOTO_INFO_SUCCESS,
  GET_PHOTOS_SUCCESS
} from "./constant/constant";
import {
  dispatchError as fillPhotoError,
  finishLoading
} from "../../../common/Stores/service/service";
import LazyLoad from "react-lazyload";
import { api } from "../../../common/api/api";
import axios from "axios";
import ReactDOM from "react-dom";
import {
  translateValidate,
  messageValidate
} from "../../../common/Stores/service/service";

class PhotosBuilder extends Component {
  ajaxRequest = axios.CancelToken.source();
  shoudHideLoading = true;
  state = {
    isUpload: false,
    isDialogShowImage: false,
    isDialogShowImageEdit: false,
    isDialogDeleteShow: false,
    currentPhoto: {},
    disalbeNextBack: false,
    firstUpload: true,
    initialValuesEdit: {
      name: "",
      regisionDate: ""
    },
    options: [
      {
        name: "1階",
        selected: false
      },
      {
        name: "2階",
        selected: true
      },
      {
        name: "玄関",
        selected: false
      },
      {
        name: "トイレ",
        selected: true
      }
    ],
    typeReload: [PUT_PHOTO_SUCCESS, DELETE_PHOTO_SUCCESS]
  };

  changeStatus = (index, selected) => () => {
    const newValue = Object.assign([], this.state.options);
    newValue[index].selected = !selected;
    this.setState({
      options: newValue
    });
  };

  componentDidMount() {
    this.props.finishLoading();
  }

  fetch() {
    this.props.fetchPhotos(this.sitesUC);
  }

  fetchOnDialog(id, name, newDate, blobData) {
    var isBlob = blobData ? true : false;
    api
      .get(
        isBlob
          ? blobData.resized.replace("/api", "")
          : "/builder/site-photos/" + id + "/thumbnail",
        {
          responseType: isBlob ? undefined : "arraybuffer",
          cancelToken: this.ajaxRequest.token
        }
      )
      .then(res => {
        if (res.data === undefined) return Promise.reject();
        var url = "";
        if (res.data.blobData) {
          url = res.data.blobData;
        } else {
          var blob = new Blob([res.data], { type: "image/jpeg" });
          url = URL.createObjectURL(blob);
        }

        this.setState(
          {
            currentPhoto: {
              photoId: id,
              url: url,
              blob: blobData,
              name: name
            },
            initialValuesEdit: { name: name, regisionDate: newDate }
          },
          () => {
            this.setState({ disalbeNextBack: false });
          }
        );

        ReactDOM.render(
          <Grid className="div-photo">
            <img
              src={url}
              alt={id}
              onClick={() => this.onImageClick(id, name, url, newDate)}
            ></img>
          </Grid>,
          document.querySelector(`span[photoid="${id}"]`)
        );
      })
      .catch(e => {
        if (e?.response?.status === 404) {
          this.setState({ url: "" });
        }
      });
  }

  isChanged(nextProps) {
    const nextType = nextProps.type;
    const { typeReload } = this.state;
    return (
      this.props.sitesUC !== nextProps.sitesUC ||
      (this.props.type !== nextType && typeReload.includes(nextType))
    );
  }

  closeDialog() {
    if (this.state.isDialogShowImageEdit) this.toggleEditImageDialogShow();
  }

  onImageClick = (photoId, blob, name, url, extension, date) => {
    this.setState({
      currentPhoto: {
        photoId: photoId,
        blob: blob,
        name: name,
        url: url,
        extension: extension
      },
      initialValuesEdit: { name: name, regisionDate: date }
    });
    this.toggleDialogImage();
  };

  handleKeyDown = event => {
    if (event.keyCode === 37) {
      this.handleChangeImage(false);
    }

    if (event.keyCode === 39) {
      this.handleChangeImage(true);
    }
  };

  handleChangeImage = isNext => {
    this.setState({ disalbeNextBack: true });
    const { photos } = this.props;
    const { currentPhoto } = this.state;
    const size = photos.length;
    for (let i = 0; i < size; i++) {
      const currentIndex = photos[i].photos.findIndex(
        photo => photo.photoId === currentPhoto.photoId
      );
      if (currentIndex !== -1) {
        const ptSize = photos[i].photos.length;

        let newIndex = 0;
        let dateIndex = i;
        if (isNext) {
          newIndex = currentIndex + 1;
          if (newIndex === ptSize) {
            dateIndex = dateIndex + 1 >= size ? 0 : dateIndex + 1;
            newIndex = 0;
          }
        } else {
          newIndex = currentIndex - 1;
          if (newIndex < 0) {
            dateIndex = dateIndex - 1 < 0 ? size - 1 : dateIndex - 1;
            newIndex = photos[dateIndex].photos.length - 1;
          }
        }

        const newPhoto = photos[dateIndex].photos[newIndex];

        const newDate = photos[dateIndex].date;
        if (newPhoto === undefined) {
          this.setState({ disalbeNextBack: false });
          break;
        }

        if (newPhoto.url === undefined) {
          this.fetchOnDialog(
            newPhoto.photoId,
            newPhoto.name,
            newDate,
            newPhoto.blob
          );
        } else {
          this.setState(
            {
              currentPhoto: {
                photoId: newPhoto.photoId,
                blob: newPhoto.blob,
                url: newPhoto.url,
                name: newPhoto.name,
                extension: newPhoto.extension
              },
              initialValuesEdit: { name: newPhoto.name, regisionDate: newDate }
            },
            () => {
              this.setState({ disalbeNextBack: false });
            }
          );
        }
        break;
      }
    }
  };

  onGetUrlPhotoSuccess = (photoId, name, url) => {
    const { photos, finishLoading, loading, loadPhotoTab } = this.props;
    const size = photos.length;
    for (let i = 0; i < size; i++) {
      const pt = photos[i].photos.find(photo => photo.photoId === photoId);
      if (pt !== undefined) {
        pt.url = url;
        pt.name = name;
        break;
      }
    }

    for (let i = 0; i < photos.length; i++) {
      if (
        photos[i].photos.filter(p => p.url === undefined || p.url === null)
          .length > 0
      ) {
        this.shoudHideLoading = false;
        break;
      } else {
        this.shoudHideLoading = true;
      }
    }
    if (this.shoudHideLoading) {
      this.shoudHideLoading = false;
      if (loading && loadPhotoTab) {
        finishLoading();
      } else this.props.onHideLoading();
    }
  };

  toggleDialogDelete = () => {
    this.props.fillPhotoError("");
    this.setState({
      isDialogDeleteShow: !this.state.isDialogDeleteShow,
      isDialogShowImageEdit: false
    });
  };

  openDialogDelete = () => {
    this.props.fillPhotoError("");
    this.setState({
      isDialogDeleteShow: true
    });
  };

  toggleDialogImage = () => {
    this.setState({
      isDialogShowImage: !this.state.isDialogShowImage
    });
  };

  showOriginalPhoto = currentPhoto => {
    this.props.showOriginalPhoto(currentPhoto.photoId, currentPhoto.blob);
  };

  toggleEditImageDialogShow = () => {
    this.props.fillPhotoError("");
    this.setState({
      isDialogShowImage: false,
      isDialogShowImageEdit: !this.state.isDialogShowImageEdit
    });
  };

  closeEditImageDialogShow = () => {
    this.setState({
      isDialogShowImage: false,
      isDialogShowImageEdit: false
    });
  };

  openEditImageDialogShow = () => {
    this.props.fillPhotoError("");
    this.setState({
      isDialogDeleteShow: false,
      isDialogShowImageEdit: true
    });
  };

  componentWillUnmount() {
    this.ajaxRequest.cancel();

    if (this.props.photos !== undefined) this.props.photos.length = 0;
    finishLoading();
  }

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);
    if (nextProps.type === GET_PHOTO_INFO_SUCCESS) {
      const photoInfo = nextProps.photoInfo;
      if (photoInfo) {
        this.setState(
          {
            initialValuesEdit: {
              name: photoInfo.name,
              regisionDate: photoInfo.createdAt
            }
          },
          () => {
            this.setState({
              isDialogDeleteShow: false,
              isDialogShowImageEdit: true
            });
          }
        );
      }
    }
    if (this.props.match.params.id !== nextProps.match.params.id) return;
    if (nextProps.type === DELETE_PHOTO_SUCCESS) {
      this.toggleDialogDelete();
      this.setState({
        isDialogDeleteShow: false,
        isDialogShowImage: false,
        isDialogShowImageEdit: false
      });
    }

    const oldList = this.props.photos;
    const newList = nextProps.photos;

    if (
      oldList === undefined ||
      oldList.length === 0 ||
      newList === undefined ||
      newList.length === 0
    )
      return;

    for (let i = 0; i < oldList.length; i++) {
      const newDate = newList.find(date => date.date === oldList[i].date);
      if (newDate === undefined) continue;
      const oldPhotos = oldList[i].photos;
      for (let j = 0; j < oldPhotos.length; j++) {
        const temp = oldPhotos[j];
        const newPhoto = newDate.photos.find(
          photo => photo.photoId === temp.photoId
        );
        if (newPhoto === undefined) continue;
        newPhoto.url = temp.url;
      }
    }
  }

  componentDidUpdate() {
    const { finishLoading, type } = this.props;

    if (type !== GET_PHOTOS_SUCCESS) {
      return;
    }

    if (this.state.isUpload) {
      if (!this.state.firstUpload) finishLoading();
      else this.setState({ firstUpload: false });
    }
  }

  uploadPhoto(e) {
    this.upload(e);
    this.setState({ isUpload: true });
  }

  downloadPhoto = () => {
    const { currentPhoto } = this.state;
    this.props.downloadPhoto(
      currentPhoto.photoId,
      currentPhoto.blob,
      currentPhoto.name,
      currentPhoto.extension
    );
  };

  render() {
    const { photos } = this.props;
    const {
      isDialogShowImage,
      currentPhoto,
      disalbeNextBack,
      initialValuesEdit,
      isDialogShowImageEdit,
      isDialogDeleteShow
    } = this.state;

    return (
      <>
        <input
          type="file"
          id="file"
          accept=".jpg,.jpeg,.png"
          multiple
          onChange={e => this.uploadPhoto(e)}
          style={{ display: "none" }}
        />
        <Grid container justify="space-between" className="first-tab">
          <Button size="small" variant="text" className="button-add">
            <AddIcon className="icon-margin" />
            <label htmlFor="file" style={{ color: "inherit" }}>
              写真アップロード
            </label>
          </Button>
        </Grid>
        <Grid className="image-wrap">
          {photos &&
            photos.map(datePhotos => (
              <Grid className="image-item" key={datePhotos.date}>
                {/* [NOTE]: 現場写真一覧の日付隠し対応 */}
                <Typography className="image-cap" variant="h6">
                  {format(new Date(datePhotos.date), "yyyy年M月d日")}
                </Typography>
                {datePhotos.photos.map(photo => (
                  <Grid
                    className="cursor-pointer"
                    key={photo.photoId}
                    photoid={photo.photoId}
                    component="span"
                  >
                    <LazyLoad
                      overflow
                      offset={100}
                      placeholder={<div className="placeholder-image"></div>}
                    >
                      <Grid className="div-photo">
                        <Photo
                          name={photo.name}
                          alt={photo.photoId}
                          PhotosUC={photo.photoId}
                          blob={photo.blob}
                          onClick={() =>
                            this.onImageClick(
                              photo.photoId,
                              photo.blob,
                              photo.name,
                              photo.url,
                              photo.extension,
                              datePhotos.date
                            )
                          }
                          onGetSuccess={this.onGetUrlPhotoSuccess}
                        />
                      </Grid>
                    </LazyLoad>
                  </Grid>
                ))}
              </Grid>
            ))}
        </Grid>
        {(isDialogShowImageEdit || isDialogDeleteShow) && (
          <EditImageDialog
            title={"写真情報編集"}
            isDialogShow={isDialogShowImageEdit}
            isDialogDeleteShow={isDialogDeleteShow}
            initialValues={initialValuesEdit}
            toggleDialogDelete={this.toggleDialogDelete}
            openDialogDelete={this.openDialogDelete}
            onClose={this.closeEditImageDialogShow}
            submitButtonTitle={"登録"}
            photoId={currentPhoto.photoId}
          />
        )}
        <Grid container className="button-bottom">
          <Button size="small" variant="text" className="button-add">
            <AddIcon className="icon-margin" />
            <label htmlFor="file" style={{ color: "inherit" }}>
              写真アップロード
            </label>
          </Button>
        </Grid>
        <Dialog
          open={isDialogShowImage}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          onClose={() => {}}
          maxWidth="lg"
          PaperProps={{
            className: "dialog paper-image"
          }}
          onKeyDown={this.handleKeyDown}
        >
          <DialogTitle
            disableTypography
            className="padding-dialog-image title-image"
          >
            <Grid container justify="space-between" className="flex-nowrap">
              <Grid className="log-title">
                <Typography
                  variant="h4"
                  className="dialog-title white-text mr-15"
                >
                  {currentPhoto.name}
                </Typography>
                <Fab
                  onClick={this.openEditImageDialogShow}
                  size="small"
                  className="white-text log-button-reset"
                >
                  <EditIcon />
                </Fab>
              </Grid>
              <Fab
                size="small"
                className="white-text log-button-reset"
                onClick={this.toggleDialogImage}
              >
                <CloseIcon />
              </Fab>
            </Grid>
          </DialogTitle>
          <DialogContent
            className="image-content-dialog padding-dialog-image"
            style={{ paddingBottom: 44 }}
          >
            <Grid className="image-wrap-content">
              <img
                onClick={() => this.showOriginalPhoto(currentPhoto)}
                src={currentPhoto.url}
                alt={currentPhoto.photoId}
              />
            </Grid>
            <Grid className="button-group-dialog">
              <Fab
                className="white-text reset-button-image"
                disabled={disalbeNextBack}
                onClick={() => this.handleChangeImage(false)}
              >
                <FontAwesomeIcon
                  size="3x"
                  icon={faChevronLeft}
                  className="white-text"
                />
              </Fab>
              <Fab
                className="white-text reset-button-image"
                disabled={disalbeNextBack}
                onClick={() => this.handleChangeImage(true)}
              >
                <FontAwesomeIcon
                  size="3x"
                  icon={faChevronRight}
                  className="white-text"
                />
              </Fab>
            </Grid>
          </DialogContent>
          <DialogTitle
            disableTypography
            className="padding-dialog-image title-image"
            style={{ top: "initial", bottom: 0 }}
          >
            <Grid container justify="space-between" className="flex-nowrap">
              <Grid className="log-title"></Grid>
              <Fab
                className="white-text reset-button-image button-download"
                disabled={disalbeNextBack}
                onClick={this.downloadPhoto}
              >
                <CloudDownloadIcon className="white-text" fontSize="large" />
              </Fab>
            </Grid>
          </DialogTitle>
        </Dialog>
      </>
    );
  }
}

PhotosBuilder.propTypes = {
  sitesUC: PropTypes.string,
  onHideLoading: PropTypes.func,
  loadPhotoTab: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth,
  photos: getPhotos(state.rootSitesReducer.siteInfoReducer.photos),
  photoInfo: getPhotos(state.rootSitesReducer.siteInfoReducer.photoInfo),
  type: state.rootSitesReducer.siteInfoReducer.type,
  loading: state.storesReducer.pending,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPhotos,
      fetchPhotoInfo,
      showOriginalPhoto,
      postFile,
      downloadPhoto,
      fillPhotoError,
      finishLoading
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PhotosBuilder));
