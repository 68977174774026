import React, { createRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Component from "../../../Component";
import { gsap, TweenMax } from "gsap";
import { Draggable } from "gsap/Draggable";
import CompassImage from "../../../../common/img/compass.svg";
gsap.registerPlugin(Draggable);
export class CompassView extends Component {
    constructor(props) {
        super(props);
        this.compassRef = React.createRef();
    }
    state = {
        isSavingCompassSetting: this.props.isSavingCompassSetting,
        currentDrawing: this.props.currentDrawing,
        isSelected: this.props.isSelected,
        direction: this.props.direction
    }
    componentDidMount() {
        Draggable.create(this.compassRef.current, {
            type: "x,y",
            bounds: document.getElementById(`container-${this.props.drawingsUC}`),
            onDragEnd: () => {
            }
        });
        // gsap.set(this.compassRef.current, { rotation: this.props.direction })
        gsap.set(this.compassRef.current, { rotation: this.props.isSavingCompassSetting ? this.props.savingDirection : this.props.direction });

    }

    componentWillReceiveProps(nextProps) {  
    }
    componentDidUpdate() {
        Draggable.create(this.compassRef.current, {
            type: "x,y",
            y: 0,
            x: 0,
            // bounds: document.getElementById(`container-${this.props.drawingsUC}`),
            bounds: document.getElementById(`sub-img-block-${this.props.drawingsUC}`),
            onDragEnd: () => {
            }
        });

        gsap.set(this.compassRef.current, { rotation: this.props.isSavingCompassSetting ? this.props.savingDirection : this.props.direction });

        if (!this.props.isCompassSetting) {
            TweenMax.set(this.compassRef.current, { x: 0, y: 0 });
        }
    }

    render() {
        // const { direction } = this.state
        const {
            drawingsUC,
            isSavingCompassSetting,
            currentDrawing,
            isSelected,
            direction,
            isCompassSetting,
            isAddPoint
        } = this.props;

        return (
            <>
                {((!isSavingCompassSetting && direction !== null) || (isSavingCompassSetting && (isSelected || currentDrawing))) && !isAddPoint && isCompassSetting &&
                    <div id={`container-${drawingsUC}`}
                        className={`compassview-container${isSavingCompassSetting ? "-saving" : ""} ${!isCompassSetting ? " text-left" : !isSavingCompassSetting ? " text-right" : ""}`}>
                        <img
                            ref={this.compassRef}
                            className={`${!isSavingCompassSetting ? "compass-view-icon"
                                : (currentDrawing ? "compass-view-icon-setting-currentDrawing" :
                                    (isSelected ? "compass-view-icon-setting-selected" :
                                        (!isSelected ? "compass-view-icon-setting" : "")))}`}
                            src={CompassImage}
                        />
                    </div>
                }
                {((!isSavingCompassSetting && direction !== null) || (isSavingCompassSetting && (isSelected || currentDrawing))) && !isAddPoint && !isCompassSetting &&
                    <img
                    ref={this.compassRef}
                    className={`
                    ${!isSavingCompassSetting ? "compass-view-icon"
                        : (currentDrawing ? "compass-view-icon-setting-currentDrawing" :
                            (isSelected ? "compass-view-icon-setting-selected" :
                                (!isSelected ? "compass-view-icon-setting" : "")))}`}
                    src={CompassImage}
                />
                }
            </>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    type: state.rootSitesReducer.siteInfoReducer.type
});

export default connect(mapStateToProps)(withRouter(CompassView));
