import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Typography } from "@material-ui/core";
import SearchField from "../../SearchField";
import "../../../common/css/base.css";
import "../../../common/css/sites-staft.css";

class CarpenterSP extends Component {

  onCompanyChange = value => {
    this.props.onCompanyChange(value);
  };

  onFuriganaChange = value => {
    this.props.onFuriganaChange(value);
  };

  render() {
    const { carpenters, showDialog, showPopover, companySearch, furiganaSearch } = this.props;

    return (<>
      <Typography
        variant="h5"
        className="dashboard-sp-title font-family text-bold"
      >
        職人の管理
      </Typography>
      <Grid container justify="flex-start" wrap="wrap">
        <Grid item xs={12} sm={6} style={{padding: "8px 12px"}}>
          <SearchField
            placeholder="会社名から検索"
            setValue={this.onCompanyChange}
            value={companySearch}
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{padding: "8px 12px"}}>
          <SearchField
            placeholder="職人名から検索"
            setValue={this.onFuriganaChange}
            value={furiganaSearch}
          />
        </Grid>
      </Grid>
      <MenuList style={{paddingBottom: 100}}>
        {carpenters.map(carpenter => (
          <MenuItem
            key={carpenter.uniqueCode}
            className="dashboard-sp-item"
          >
            <Grid container justify="space-between" wrap="nowrap">
             <Grid className="item-left">
                <Typography
                  variant="h5"
                  className="text-bold font-family white-space-normal"
                  style={{fontSize: '1rem'}}
                >
                  {carpenter.name}
                  {carpenter.job === 1 && (
                    <Typography variant="caption" className="tag">大工</Typography>
                  )}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="font-family grey-light white-space-normal"
                >
                  {carpenter.supplierName}
                </Typography>
              </Grid>
              <Grid className="item-right" style={{flexDirection: 'row', alignItems: 'center'}}>
                <Fab
                  onClick={showPopover(carpenter)}
                  size="small"
                  className="more-vert-btn"
                >
                  <MoreVertIcon />
                </Fab>
              </Grid>
            </Grid>
          </MenuItem>
        ))}
      </MenuList>
      <Fab className="button-add-sp">
        <label onClick={showDialog} style={{ color: "inherit" }}>
          <AddIcon className="white-text icon" />
        </label>
      </Fab>
    </>);
  }
}

export default CarpenterSP;
