import React from "react";
import IconLink from "../../../../common/img/new-link.png";
import IconInactiveLink from "../../../../common/img/close.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  wrapActions: {
    position: "absolute",
    bottom: "30px",
    left: "50%",
    marginLeft: "-150px",
    width: "300px"
  },
  actions: {
    width: "300px",
    height: "45px",
    display: "flex",
    transition: "bottom 0.25s ease 0s",
    justifyContent: "space-between",
    "& button": {
      width: "140px",
      height: "45px",
      borderRadius: "5px",
      fontSize: "17px",
      fontWeight: "bold",
      textAlign: "center"
    }
  },
  editLinkCenter: {
    width: "60px",
    height: "60px",
    padding: "16px 0px",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-30px",
    marginLeft: "-30px",
    borderRadius: "30px"
  },
  note: {
    height: "45px",
    padding: "0 30px",
    width: "300px",
    lineHeight: "45px",
    borderRadius: "5px",
    marginBottom: "10px",
    fontSize: "17px",
    textAlign: "center",
    background: "rgb(224,224,224,0.6)"
  }
});
function EditLinkPosition(props) {
  const classes = useStyles();
  const { editLinkPosition, toggleShowModeEditLink, selectedLinkForEditing } =
    props;
  return (
    <>
      {selectedLinkForEditing && (
        <div
          className={`${classes.editLinkCenter} show-full`}
        >
          <img
            src={IconLink}
            style={{
              width: "100%",
              height: "100%",
              opacity: selectedLinkForEditing.isActiveSpot
                ? '1'
                : '0.65'
            }}
            alt=""
          />
          <div className="link-hotspot-tooltip" style={{top: "80%"}}>
            {selectedLinkForEditing.name}
          </div>
        </div>
      )}
      <div className={classes.wrapActions}>
        <div className={classes.note}>リンク表示位置の編集</div>
        <div className={classes.actions}>
          <button
            style={{
              backgroundColor: selectedLinkForEditing ? "#00346C" : "#E0E0E0",
              color: selectedLinkForEditing ? "white" : "#A8A8A8"
            }}
            onClick={() => selectedLinkForEditing && editLinkPosition()}
          >
            決定
          </button>
          <button
            style={{
              backgroundColor: "white",
              color: "black"
            }}
            onClick={() => toggleShowModeEditLink()}
          >
            キャンセル
          </button>
        </div>
      </div>
    </>
  );
}

export default EditLinkPosition;
