import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LogWalkViewer from "./LogWalkViewer";
import { compose } from "redux";
import {
  fetchInitViewUnAdjustedImage,
  getPhotoShoots
} from "../service/service";
import {
  getHistoryListAdjustPhoto,
  getListAdjustPhoto,
  getListAdjustPhotoSelected,
  getUrlImageShootsUCNotAdjusted
} from "./reducer";
import { setImageShootUnAdjusted, setRedirectFromUrl } from "../action/action";

class ViewUnAdjustedImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInit: true,
      defaultAdjustedImage: {}
    };
  }

  componentDidMount() {
    if (
      this.props.location.state &&
      this.props.location.state.isRedirectFromUrl
    ) {
      this.props.setRedirectFromUrl({
        isRedirectFromUrl: true,
        isDialog:
          this.props.location.state && this.props.location.state.isDialog,
        isCardList:
          this.props.location.state && this.props.location.state.isCardList,
        logwalk: this.props.location.state && this.props.location.state.logwalk,
        from: this.props.location.state && this.props.location.state.from
      });
    }

    const { match, onInit } = this.props;
    if (match.path.includes("/sp/")) {
      const data = {
        sitesUC: match.params.id,
        classification: "supplier"
      };
      onInit(data);
    } else {
      const data = {
        sitesUC: match.params.id,
        classification: "builder"
      };
      onInit(data);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.listAdjustPhoto !== this.props.listAdjustPhoto &&
      this.props.listAdjustPhoto.length ===
        this.props.listAdjustPhotoSelected.shoots.length
    ) {
      const { listAdjustPhoto, imageShootUnAdjusted } = this.props;
      const params = {
        blob: listAdjustPhoto[0].blob ?? listAdjustPhoto[0].image,
        shootCode: listAdjustPhoto[0].uniqueCode,
        tagName: listAdjustPhoto[0].tagName,
        createdBy: listAdjustPhoto[0].createdBy
      };
    this.setState({ isInit: false });
      if (this.state.isInit) {
        this.props.onSetImageShootUnAdjusted(params);
        this.setState({ defaultAdjustedImage: params });
      } else {
        if (!imageShootUnAdjusted.shootCode) {
          this.props.onSetImageShootUnAdjusted(this.state.defaultAdjustedImage);
        }
      }
    }
  }

  render() {
    return <LogWalkViewer viewerUnAdjustedImage={true} />;
  }
}

const mapStateToProps = state => ({
  listAdjustPhotoSelected: getListAdjustPhotoSelected(
    state.rootSitesReducer.logWalkViewReducer.listAdjustPhotoSelected
  ),
  listAdjustPhoto: getListAdjustPhoto(
    state.rootSitesReducer.logWalkViewReducer.listAdjustPhoto
  ),
  historyListAdjustPhoto: getHistoryListAdjustPhoto(
    state.rootSitesReducer.logWalkViewReducer.historyListAdjustPhoto
  ),
  imageShootUnAdjusted: getUrlImageShootsUCNotAdjusted(
    state.rootSitesReducer.logWalkViewReducer.imageShootUnAdjusted
  )
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    onInit: data => dispatch(fetchInitViewUnAdjustedImage(data)),
    getAdjustPhoto: item => dispatch(getPhotoShoots(item)),
    onSetImageShootUnAdjusted: data => dispatch(setImageShootUnAdjusted(data)),
    setRedirectFromUrl: data => dispatch(setRedirectFromUrl(data))
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(ViewUnAdjustedImage);
