import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { api } from "../../../common/api/api";
import Component from "../../Component";
import axios from "axios";
import { Link } from "react-router-dom";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { GET_DRAWING_WITH_SPOTS_SUCCESS } from "./constant/constant";
import RoomIcon from "@material-ui/icons/Room";
import { TYPE_DIALOG } from "./LogWalkViewer/constants";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isMobileOnly,
  isTablet,
  isDesktop
} from "react-device-detect";
export class DrawingPhoto extends Component {
  drawingsUC = null;
  blob = null;
  alt = "";
  id = null;
  class = null;
  isAddPoint = false;
  source = null;
  ajaxRequest = axios.CancelToken.source();
  cancelSubmission = false;

  constructor (props) {
    super(props);
    this.state = {
      img: null,
      url: "",
      fetching: true,
      isShowAddPointDialog: false
    };
    this.isBuilder = !props.isSp;
    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);
  }

  // onGetSuccess(id, name, url) {
  //   this.setState({ url: url });
  //   this.props.onGetSuccess(id, name, url);
  // }

  onClick () {
    this.props.onClick(this.id, this.name, this.state.url);
  }

  componentWillReceiveProps (props) {
    if (!this.isChange(props)) return;
    this.init(props);
    this.fetch();
  }

  isChange (props) {
    return (
      this.drawingsUC !== props.drawingsUC ||
      this.blob !== props.blob ||
      this.isAddPoint !== (props.isAddPoint ?? false) ||
      this.apiPath !== props.apiPath
    );
  }

  componentWillMount () {
    const { match } = this.props;

    this.init(this.props);
    this.fetch();
  }

  init (props) {
    this.drawingsUC = props.drawingsUC;
    this.blob = props.blob;
    this.alt = props.name === undefined ? "" : props.name;
    this.id = props.id === undefined ? "" : props.id;
    this.apiPath = props.apiPath;

    if (props.class === undefined) {
      if (props.isAddPoint) {
        this.class = "pin-spot";
      } else {
        this.class = "";
      }
    } else {
      this.class = props.class;
    }
    this.isAddPoint = props.isAddPoint === undefined ? false : props.isAddPoint;
  }

  fetch () {
    const classification = this.isBuilder ? "builder" : "supplier";
    var isBlob = this.blob ? true : false;

    // Cancel request if have new request
    if (this.source) {
      this.source.cancel();
    }
    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();
    if (
      this.drawingsUC &&
      ((this.props.isLogWalkViewer && this.apiPath) ||
        !this.props.isLogWalkViewer)
    ) {
      api
        .get(
          isBlob 
            ? this.blob.replace(/^\/api/, "")
            : this.apiPath
            ? this.apiPath.replace("/api", "")
            : `/${classification}/drawings/` + this.drawingsUC + "/file?bin",
          {
            responseType: isBlob ? undefined : "arraybuffer",
            cancelToken: this.source.token
          }
        )
        .then(res => {
          if (!this.cancelSubmission) {
            if (res.data === undefined) return Promise.reject();
            if (isBlob) {
              var url = res.data.blobData;
            } else {
              var blob = new Blob([res.data], { type: "image/jpeg" });
              var url = URL.createObjectURL(blob);
            }
            return url;
          }
        })
        .then(url => this.img(url))
        .then(img => {
          if (!this.cancelSubmission) {
            this.setState({ img });
            this.props.fetchDone();
          }
        })
        .catch(e => {
          if (e?.response?.status === 404) {
            this.setState({ img: this.img("") });
          }
        });
    }
  }

  componentWillUnmount () {
    this.ajaxRequest.cancel();
    this.cancelSubmission = true;
  }

  handleButtonPress (isEdit, e, pos) {
    this.buttonPressTimer = setTimeout(() => {
      if (!isEdit && e) {
        this.props.showDialogAddPoint(e);
      }
      if (isEdit && pos) {
        this.props.toggleDialogEditPoint(pos, this.drawingsUC);
      }
    }, 1000);
  }

  handleButtonRelease () {
    clearTimeout(this.buttonPressTimer);
  }
  handleDrawingClick = e => {
    const userAgent = window.navigator.userAgent;
    let isIos = !userAgent.match(/iPad/i) && !userAgent.match(/iPhone/i);
    let { isAddPoint, isSP, apiPath, isLogWalkViewer } = this.props;
    isLogWalkViewer
      ? this.props.tapToShowFullImage()
      : !isSP && isAddPoint && this.props.showDialogAddPoint(e);
  };
  handleDrawingTouch = e => {
    document.getElementsByTagName("body")[0].classList.add("disable-select");
    let { isAddPoint, isSP, apiPath, isLogWalkViewer } = this.props;
    const userAgent = window.navigator.userAgent;

    if (isAddPoint && isSP) {
      this.handleButtonPress(false, e.changedTouches[0], null);
    }
  };
  handleDrawingTouchEnd = () => {
    let { isAddPoint, isSP, apiPath, isLogWalkViewer } = this.props;
    const userAgent = window.navigator.userAgent;

    if (isAddPoint && isSP) {
      this.handleButtonRelease();
    }
    document.getElementsByTagName("body")[0].classList.remove("disable-select");
  };
  img (data) {
    let { isAddPoint, isSP, apiPath, isLogWalkViewer } = this.props;
    return (
      <React.Fragment>
        <img
          draggable={false}
          // className="img-mini-dialog"
          id={this.id}
          className={this.class}
          src={data}
          alt={this.alt}
          onDragStart={e => e.preventDefault()}
          onLoad={e => {
            this.setState({ fetching: false });
            this.props.loadMarker();
          }}
          onContextMenu={e => {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }}
          onClick={e => {
            this.handleDrawingClick(e);
          }}
          // onClick={e => {
          //   isLogWalkViewer
          //     ? this.props.tapToShowFullImage()
          //     : !isSP && isAddPoint && this.props.showDialogAddPoint(e);
          // }}
          onTouchStart={e => this.handleDrawingTouch(e)}
          onTouchEnd={e => {
            this.handleDrawingTouchEnd();
          }}
          onMouseUp={isSP && this.handleButtonRelease}
          onMouseLeave={isSP && this.handleButtonRelease}
        />
      </React.Fragment>
    );
  }

  render () {
    const {
      positions,
      isDialog,
      isAddPoint,
      isSP,
      home,
      isHideMarker,
      isLogWalkViewer,
      imageShootUnAdjusted,
      spotCurrent,
      typeDialog,
      isCardList,
      logwalk,
      currentDrawingUC,
      showLinks,
      isPreventMovingToLWViewer
    } = this.props;
    const { fetching, img } = this.state;

    const getClassName = pos => {
      if (typeDialog && typeDialog === TYPE_DIALOG.ADJUST_IMAGE) {
        if (this.props.checkActiveSpot(pos.uniqueCode)) {
          return "icon-spot-logwalkViewer";
        } else {
          return "icon-spot-unactive-logwalkViewer";
        }
      } else {
        if (this.props.checkActiveSpot(pos.uniqueCode)) {
          return "icon-spot";
        } else {
          return "icon-spot-unactive";
        }
      }
    };
    const renderSpot = pos => {
      if (isLogWalkViewer) {
        return (
          <React.Fragment>
            <FiberManualRecordIcon
              stroke={"black"}
              stroke-width={1}
              className={`marker ${
                this.props.isPreventMovingToLWViewer ? "no-color" : ""
              }  ${getClassName(pos)}`}
              onClick={() => {
                if (!this.props.isPreventMovingToLWViewer) {
                  isSP
                    ? this.props.tapToShowFullImage()
                    : this.props.chooseShootSpot(
                        typeDialog ? pos.uniqueCode : pos
                      );
                }
              }}
              style={{
                left: pos["posX"],
                top: pos["posY"]
              }}
            />
            {pos.uniqueCode === spotCurrent &&
              (typeDialog === TYPE_DIALOG.ZOOM_IMAGE ||
                !imageShootUnAdjusted.shootCode) && (
                <RoomIcon
                  className='icon-spot-current marker-selected'
                  style={{
                    left: pos["posX"],
                    top: pos["posY"],
                    cursor: this.props.isPreventMovingToLWViewer
                      ? "default"
                      : "pointer"
                  }}
                />
              )}
          </React.Fragment>
        );
      } else if (isAddPoint) {
        return (
          <FiberManualRecordIcon
            stroke={"black"}
            stroke-width={1}
            className={`marker ${isHideMarker ? "display-none" : ""} 
                  ${pos.hasShoot ? "icon-spot" : "icon-spot-unactive"}`}
            onClick={e => {
              if (!isSP) {
                this.props.toggleDialogEditPoint(pos, this.drawingsUC);
                e.stopPropagation();
              }
            }}
            onTouchStart={e => {
              if (isSP) {
                this.handleButtonPress(true, null, pos);
                e.stopPropagation();
              }
            }}
            onTouchEnd={e => {
              if (isSP) {
                this.handleButtonRelease();
                e.stopPropagation();
              }
            }}
            onMouseDown={e => {
              if (isSP) {
                this.handleButtonPress(true, null, pos);
                e.stopPropagation();
              }
            }}
            onMouseUp={e => {
              if (isSP) {
                this.handleButtonRelease();
                e.stopPropagation();
              }
            }}
            onMouseLeave={e => {
              if (isSP) {
                this.handleButtonRelease();
                e.stopPropagation();
              }
            }}
            style={{
              left: pos["posX"],
              top: pos["posY"]
            }}
          ></FiberManualRecordIcon>
        );
      } else {
        if (!isPreventMovingToLWViewer) {
          return (
            <Link
              to={{
                pathname:
                  (home ?? "") + "/logwalk-viewer/spots/" + pos.uniqueCode,
                state: {
                  isDialog,
                  isCardList,
                  currentDrawingUC,
                  logwalk,
                  from: this.props.location.pathname
                }
              }}
              className='reset-link'
            >
              <FiberManualRecordIcon
                stroke={"black"}
                stroke-width={1}
                className={`marker ${isHideMarker ? "display-none" : ""} 
                      ${pos.hasShoot ? "icon-spot" : "icon-spot-unactive"}`}
                onTouchStart={e => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                style={{
                  left: pos["posX"],
                  top: pos["posY"]
                }}
              ></FiberManualRecordIcon>
            </Link>
          );
        } else {
          return (
            <FiberManualRecordIcon
              stroke={"black"}
              stroke-width={1}
              className={`marker no-color ${isHideMarker ? "display-none" : ""} 
                      ${pos.hasShoot ? "icon-spot" : "icon-spot-unactive"}`}
              onTouchStart={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
              style={{
                left: pos["posX"],
                top: pos["posY"]
              }}
            ></FiberManualRecordIcon>
          );
        }
      }
    };

    const renderLink = (spot, link) => {
      const linkTo = positions.find(
        linkToSpot => linkToSpot.uniqueCode === link.spotsUC
      );
      if (linkTo) {
        const length = Math.sqrt(
          (linkTo["posY"] - spot["posY"]) ** 2 +
            (linkTo["posX"] - spot["posX"]) ** 2
        );
        return (
          <div
            style={{
              width: `200px`,
              marginLeft: "-100px",
              height: "1px",
              top: (spot["posY"] + linkTo["posY"]) / 2,
              left: (spot["posX"] + linkTo["posX"]) / 2,
              transform: `rotate(${((link.dir - Math.PI / 2) * 180) /
                Math.PI}deg) scaleX(${length / 200})`,
              backgroundColor: "#0070C0",
              position: "absolute"
            }}
          ></div>
        );
      }

      return null;
    };

    const renderAllLink = () => {
      return positions.map(spot => (
        <React.Fragment>
          {spot.links && (
            <React.Fragment>
              {spot.links.map(link => renderLink(spot, link))}
            </React.Fragment>
          )}
        </React.Fragment>
      ));
    };

    return (
      <>
        {img}
        {!fetching &&
          positions &&
          positions.map(pos => (
            <div key={pos.uniqueCode}>{renderSpot(pos)}</div>
          ))}
        {!fetching && showLinks && positions && renderAllLink()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  type: state.rootSitesReducer.siteInfoReducer.type
});

export default connect(mapStateToProps)(withRouter(DrawingPhoto));
