import {
  fetchCarpentersSuccess,
  postCarpentersSuccess,
  getCarpentersDetailSuccess,
  putCarpentersSuccess,
  deleteCarpentersSuccess,
  mountSupplierStaffPasswordDialogSuccess,
  putSupplierStaffPasswordSuccess
} from "../action/action";
import { api } from "../../../../common/api/api";
import {
  dispatchError,
  enableLoading,
  finishLoading
} from "../../../../common/Stores/service/service";

export const fetchCarpenter = (handleFetchSuccess) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/supplier-staffs`)
      .then(res => {
        if(handleFetchSuccess) handleFetchSuccess();
        dispatch(fetchCarpentersSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(error => {
        dispatch(dispatchError(error));
      });
  };
};

export const addCarpenter = (carpenter, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/supplier-staffs`, carpenter)
      .then(res => {
        dispatch(postCarpentersSuccess(res.data));
        dispatch(fetchCarpenter());
      })
      .catch(error => {
        if(handleError && error.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(error));
        }
      });
  };
};

export const updateCarpenter = (carpenter, id, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/supplier-staffs/${id}`, carpenter)
      .then(res => {
        dispatch(putCarpentersSuccess(res.data));
        dispatch(fetchCarpenter());
      })
      .catch(error => {
        if(handleError && error.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(error));
        }
      });
  };
};

export const fetchCarpenterDetail = id => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/supplier-staffs/${id}`)
      .then(res =>
        dispatch(getCarpentersDetailSuccess(res.data))
      )
      .then(() => {
        dispatch(finishLoading());
      })
      .catch(error => {
        dispatch(dispatchError(error));
      });
  };
};

export const deleteCarpenter = (id, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/supplier-staffs/${id}`)
      .then(res => {
        dispatch(deleteCarpentersSuccess(res.data));
        dispatch(fetchCarpenter());
      })
      .catch(error => {
        if(handleError && error.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(error));
        }
      });
  };
};

export const updateSupplierStaffPassword = (password, uniqueCode) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/builder/supplier-staff-password/${uniqueCode}`, password)
      .then(res =>
        dispatch(putSupplierStaffPasswordSuccess(res.data))
      )
      .then(res => {
        dispatch(finishLoading());
      })
      .catch(error => {
        dispatch(dispatchError(error));
      });
  };
};

export const mountSupplierStaffPasswordDialog = () => {
  return dispatch => {
    dispatch(mountSupplierStaffPasswordDialogSuccess());
  };
};
