import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import "../../../common/css/base.css";
import "../../../common/css/sites-staft.css";
import PaginationControl from "../../../common/Pagination";
import SearchField from "../../SearchField";


class StaftPC extends Component {

  onSearchChange = value => {
    this.props.onChangeSearch(value);
  };
  render() {
    const {
      staffs,
      staffDatas,
      showDialog,
      onChangeData,
      showPopover,
      furiganaSearch
    } = this.props;

    return (
      <Grid container wrap="nowrap" className="sites-staft page-content">
        <Grid container className="sites-staft">
          <Grid container justify="space-between" className="padding-content">
            <Grid item>
              <SearchField
                placeholder="社員名から検索"
                setValue={this.onSearchChange}
                value={furiganaSearch}
              />
            </Grid>
            {this.props.isAdmin &&
              <Grid item>
                <Button
                  onClick={showDialog}
                  size="small"
                  variant="text"
                  className="button-add-new"
                >
                  <AddIcon/>
                  社員登録
                </Button>
              </Grid>

            }
          </Grid>
          <TableContainer>
            <Table size="small" aria-label="table" className="sites-table">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell className="white-text padding-first-th">
                    社員名
                  </TableCell>
                  <TableCell className="white-text">ふりがな</TableCell>
                  <TableCell className="white-text">役割</TableCell>
                  <TableCell className="white-text">個人携帯</TableCell>
                  <TableCell className="white-text"> メールアドレス</TableCell>
                  <TableCell className="white-text">権限</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {staffDatas.map((row, index) => (
                  <TableRow key={index} className="staff-data-row">
                    <TableCell className="padding-first-td">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.furigana}</TableCell>
                    <TableCell>{row.jobName}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.authName}</TableCell>
                    <TableCell className="padding-last-td s-ta-r">
                      {(this.props.isAdmin || this.props.userUC === row.uniqueCode) &&
                        (
                          <Fab
                            onClick={showPopover(row)}
                            size="small"
                            className="more-vert-btn"
                          >
                            <MoreVertIcon />
                          </Fab>
                        )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justify="space-between" className="padding-content">
            {this.props.isAdmin &&
              <Button
                onClick={showDialog}
                size="small"
                variant="text"
                className="button-add-new"
              >
                <AddIcon/>
                社員登録
              </Button>
            }
          </Grid>
          <Grid container justify="center">
            <PaginationControl
              limit={20}
              datas={staffs}
              changeData={onChangeData}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default StaftPC;
