import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useLocation } from "react-router-dom";
import { api } from "../../common/api/api";
import { Grid, makeStyles, Button } from "@material-ui/core";
import ReloadIcon from "../../common/img/ReloadIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { enableLoading, finishLoading } from "common/Stores/service/service";
import Pagination from "@material-ui/lab/Pagination";
import { format } from "date-fns";
import LogMeetPhoto from "./LogMeetPhoto";


function LogMeetHistoryImage(props, ref) {
  const { toggleHistoryDetailModal, setCurrentItemIndex } = props;
  const [loading, setLoading] = useState(false);
  const [allHistories, setAllHistories] = useState([]);
  const [historiesOffset, setHistoriesOffset] = useState(0);
  const [historiesCount, setHistoriesCount] = useState(20);
  const location = useLocation();
  const NumberOfPage = 20;
  const [pagination, setPagination] = useState(null);
  const [paginationNumber, setPaginationNumber] = useState(1);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchHistories();
  }, []);

  useImperativeHandle(ref, () => ({
    reloadFromOutside() {
      ReloadPage(paginationNumber);
    },
    reloadFirstFromOutside() {
      changePage(1);
    }
  }), [])

  const fetchHistories = () => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/talkhistories?offset=${historiesOffset}&count=${historiesCount}&talk_history=1 `)
      .then(res => {
        setAllHistories(res.data.histories);
        if (res.data.total > NumberOfPage) {
          setPagination({
            total_pages: Math.ceil(res.data.total / NumberOfPage),
            paginationNumber: paginationNumber
          })
        }
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  };

  const changePage = (value) => {
    dispatch(enableLoading());
    setPaginationNumber(value);
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/talkhistories?offset=${value === 1 ? 0 : (value - 1) * historiesCount}&count=${historiesCount}&talk_history=1`)
      .then(res => {
        setAllHistories(res.data.histories);
        if (res.data.total > NumberOfPage) {
          setPagination({
            total_pages: Math.ceil(res.data.total / NumberOfPage),
            paginationNumber: paginationNumber
          })
        }
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  };

  const ReloadPage = (page) => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/talkhistories?offset=${page === 1 ? 0 : (page - 1) * historiesCount}&count=${historiesCount}&talk_history=1`)
      .then(res => {
        setAllHistories(res.data.histories);
        if (res.data.total > NumberOfPage) {
          setPagination({
            total_pages: Math.ceil(res.data.total / NumberOfPage),
            paginationNumber: paginationNumber
          })
        }
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  }

  return (
    <Grid container className="site-logmeet-tab-all-container"
      style={{ position: "relative" }}
    >
      <div
        style={{
          position: "absolute",
          top: "-38px",
          right: "0"
        }}
      >
        <Button
          style={{
            color: "#162F50",
            fontFamily: "Noto Sans JP",
            fontWeight: "normal",
            fontSize: "14px",
            cursor: "pointer",
            width: "80px",
            height: "32px",
            borderRadius: "20px",
            border: "solid 1px #162F50"
          }}
          onClick={() => {
            ReloadPage(paginationNumber);
          }}
        >
          更新
          <img src={ReloadIcon} />
        </Button>
      </div>
      <Grid item xs={12} style={{ display: "flex" }}>
        <Grid container>
          {allHistories.length > 0 &&
            allHistories.map(
              (item, index) => (
                <Grid item xs={12}
                  id={`history-item-${index}`}
                  key={item.id}
                  className={`call-image-item-container`}
                  onClick={() => {
                    setCurrentItemIndex(index);
                    toggleHistoryDetailModal(item);
                  }}
                  style={{
                    cursor: "pointer"
                  }}
                >
                  <Grid container>
                    <Grid item xs={3}
                      style={{
                        fontFamily: "M PLUS 1",
                        fontSize: "14px",
                        fontWeight: "normal",
                        color: "#333333"
                      }}
                    >
                      {format(new Date(item.startAt), "yyyy年M月d日 HH:mm")}
                    </Grid>
                    <Grid item xs={8}
                      style={{
                        fontFamily: "M PLUS 1",
                        fontSize: "14px",
                        fontWeight: "normal",
                        color: "#333333"
                      }}
                      className="text-overflow-ellipsis"
                    >
                      <span>参加者：{item.joinUsers.map(joinUsers => joinUsers.name).join('・')}</span>
                    </Grid>
                    {!(item.title && item.title.length) && (
                      <Grid item xs={1}
                        style={{
                          fontFamily: "M PLUS 1",
                          fontSize: "16px",
                          fontWeight: "normal",
                          color: "#333333",
                          textAlign: "right"
                        }}
                      >
                        <span>({item.images.length}枚)</span>
                      </Grid>
                    )}

                    {item.title && item.title.length > 0 && (
                      <>
                        <Grid item xs={11}
                          style={{
                            fontFamily: "M PLUS 1",
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "#162F50"
                          }}
                        >
                          <span>{item.title}</span>
                        </Grid>
                        <Grid item xs={1}
                          style={{
                            fontFamily: "M PLUS 1",
                            fontSize: "16px",
                            fontWeight: "normal",
                            color: "#333333",
                            textAlign: "right"
                          }}
                        >
                          <span>({item.images.length}枚)</span>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <hr style={{ color: "#9FA0A0" }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        {item.images.map((photo, index) => {
                          if (index < 5) {
                            return (<>
                              <Grid item style={{
                                width: "20%",
                                position: "relative",
                                paddingTop: "11.25%"
                              }}>
                                <LogMeetPhoto
                                  alt={photo.uid}
                                  PhotosUC={photo.uid}
                                  path={photo.path}
                                  width={"100%"}
                                  storage={photo.storage}
                                  style={{
                                    top: "0",
                                    left: "0",
                                    position: "absolute",
                                    objectFit: "contain",
                                    width: "calc(100% - 3px)",
                                    height: "calc(100% - 3px)"
                                  }}
                                />
                              </Grid>
                            </>)

                          }
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}
        </Grid>
      </Grid>
      {pagination && pagination.total_pages > 1 && (
        <Pagination
          classes={{ ul: classes.paginationUl }}
          count={pagination ? pagination.total_pages : 0}
          page={paginationNumber}
          onChange={(event, value) => {
            changePage(value);
          }
          }
          style={{
            border: "none",
            backgroundColor: "#fff",
            boxShadow: "none"
          }}
        />
      )}
    </Grid>
  );
}

const useStyles = makeStyles({
  paginationUl: {
    margin: "0",
    display: "flex",
    padding: "0",
    flexWrap: "wrap",
    listStyle: "none",
    alignItems: "center",
    justifyContent: "center"
  },
  truncateText3: {
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "wrap",
    wordBreak: "break-word"
  }
});

export default forwardRef(LogMeetHistoryImage)
