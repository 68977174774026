import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router";
import copy from 'copy-to-clipboard';

import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";

import { deleteHotspotInfoSuccess } from "../../../action/action";
import { api } from "../../../../../../common/api/api";

import IconStar from "../common/img/IconStar.svg";
import DeleteHotspot from "../common/img/deleteHotspot.svg";
import Edithotspot from "../common/img/changeNameFile.svg";
import CopyLink from "../common/img/copylink.svg";
import IconInfo from "../hotspotInfoIcons/IconInfo";
import "../common/css/hostpotInfo.css";

const useStyles = makeStyles({
  headerWrapper: {
    height: "78px",
    display: "flex",
    alignItems: "center",
    padding: "28px 20px 10px 15px"
  },
  headerIconWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "40px",
    backgroundColor: "#94c79b",
    borderRadius: "50%"
  },
  headerIcon: {
    width: "30px",
    height: "30px",
    margin: "0px 5px",
    borderRadius: "30px",
    backgroundColor: "lightblue",
    color: "white",
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "30px",
    textAlign: "center"
  },
  headerIconTitle: {
    textAlign: "left",
    color: "#333333",
    fontWeight: "bold",
    fontSize: "8px",
    lineHeight: "10px"
  },
  headerTitle: {
    color: "#333333",
    fontWeight: "bold",
    fontSize: "22px",
    flexGrow: 1,
    fontWeight: "bold",
    marginLeft: "15px",
    marginRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  chooseColorWrapper: {
    border: "1px solid #00346C",
    borderRadius: "4px",
    width: "150px"
  },
  menuColor: {
    width: "160px"
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between"
  },
  iconWrapperImg: {
    color: "red"
  },
  iconMoreVert: {
    width: "30px",
    height: "30px",
    marginLeft: "20px",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#00346c",
      color: "white"
    }
  },
  menuChange: {
    height: "25px"
  },
  menuChangeLink: {
    height: "25px",
    marginLeft: "33px",
    transform: "rotate(45deg)"
  },
  dialogTitle: {
    textAlign: "center",
    padding: "15px",
    color: "#333333"
  },
  iconStar: {
    paddingRight: "5px"
  },
  star: {
    width: "28px"
  },
  tooltip: {
    fontSize: "12px"
  }
});

function HotspotHeader(props) {
  const classes = useStyles();
  const { title, colorInfo, setShowEdit, shootUC, hotspotInfo, handleSetCompleteSuccess } = props;
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [showDialogSetComplete, setShowDialogSetComplete] = useState(false);

  const dispatch = useDispatch();
  let location = useLocation();
  const currentHotspotInfoId = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.currentHotspotInfoId
  );
  const history = useHistory();
  const { id } = useParams();

  const handleClickMenu = event => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleDelete = () => {
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .delete(`/${classification}/logwalk-viewer/infos/${currentHotspotInfoId}`)
      .then(res => {
        dispatch(deleteHotspotInfoSuccess(currentHotspotInfoId));
      }).catch(err => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        if (location.pathname.includes("/sp/")) {
          history.replace(
            `/sp/logwalk-viewer/shoots/${shootUC}?info=${currentHotspotInfoId}`
          );
          history.go(0);
        } else {
          history.replace(
            `/logwalk-viewer/shoots/${shootUC}?info=${currentHotspotInfoId}`
          );
          history.go(0);
        }
      });
  };

  const handleSetComplete = () => {
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .post(`/${classification}/logwalk-viewer/infos/${currentHotspotInfoId}/${hotspotInfo?.completedAt ? 'uncomplete' : 'complete'}`)
      .then(res => {
        setShowDialogSetComplete(false);
        handleSetCompleteSuccess();
      })
      .catch(err => {
        alert("エラーが発生しました。\nお手数ですが、再度お試しください。");
        if (location.pathname.includes("/sp/")) {
          history.replace(`/sp/logwalk-viewer/shoots/${id}`);
          history.go(0);
        } else {
          history.replace(`/logwalk-viewer/shoots/${id}`);
          history.go(0);
        }
      });
  }

  const copyToClipboard = () => {
    let link = "";
    if (location.pathname.includes("/sp/")) {
      link = `${window.origin}/sp/logwalk-viewer/shoots/${shootUC}?info=${currentHotspotInfoId}`;
    } else {
      link = `${window.origin}/logwalk-viewer/shoots/${shootUC}?info=${currentHotspotInfoId}`;
    }

    copy(link);
  };

  return (
    <>
      <div id="hotspot-header" className={classes.headerWrapper}>
        {/* <div className={classes.iconStar}>
          <img className={classes.star} src={IconStar} />
        </div> */}
        <div className={classes.headerIconWrapper}>
          <IconInfo width="40" height="40" color={colorInfo.color} />
        </div>
        <Tooltip
          arrow
          title={title}
          placement="bottom-start"
          classes={{ tooltip: classes.tooltip }}
        >
          <p className={classes.headerTitle}>{title}</p>
        </Tooltip>
        {hotspotInfo?.completedAt && <div>
          <Button style={{ backgroundColor: '#c7c7c7', color: 'white', fontSize: '16px', height: "28px" }} disabled>完了</Button>
        </div>
        }
        <div
          className={classes.iconMoreVert}
          style={
            Boolean(anchorElMenu)
              ? { backgroundColor: "#00346c", color: "white" }
              : {}
          }
          onClick={handleClickMenu}
        >
          <MoreVertIcon style={{ fontSize: "30px" }} />
        </div>
      </div>
      <Popover
        open={Boolean(anchorElMenu)}
        anchorEl={anchorElMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <div className={classes.menuColor}>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleCloseMenu();
              setShowEdit(true)
            }}
          >
            <span>編集</span>
            <img src={Edithotspot} className={classes.menuChange} />
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => setShowDialogDelete(true)}
          >
            <span>削除</span>
            <img src={DeleteHotspot} className={classes.menuChange} />
          </MenuItem>
          {!location.pathname.includes("/sp/") && <MenuItem
            className={classes.menuItem}
            onClick={() => setShowDialogSetComplete(true)}
          >
            <span>{(hotspotInfo?.completedAt ? '未完了にする' : '完了にする')}</span>
          </MenuItem>}
          <MenuItem
            className={classes.menuItem}
            onClick={() => copyToClipboard()}
          >
            <span>リンクをコピー</span>
            <img src={CopyLink} className={classes.menuChange} />
          </MenuItem>
        </div>
      </Popover>
      <Dialog
        open={showDialogSetComplete}
        onClose={() => setShowDialogSetComplete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ backgroundColor: "#e0e0e0eb" }}
        className="dialog-delete"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          {hotspotInfo?.completedAt ? '未完了にしますか？' : '完了にしますか？'}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleSetComplete}
            className="btn-dialog-yes"
            style={{ color: "#FFFFFF", backgroundColor: "#00356D" }}
          >
            はい
          </Button>
          <Button
            onClick={() => setShowDialogSetComplete(false)}
            className="btn-dialog-no"
            style={{ color: "#FFFFFF", backgroundColor: "#C7C7C7" }}
            autoFocus
          >
            いいえ
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showDialogDelete}
        onClose={() => setShowDialogDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ backgroundColor: "#e0e0e0eb" }}
        className="dialog-delete"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          {"削除しますか?"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleDelete}
            className="btn-dialog-yes"
            style={{ color: "#FFFFFF", backgroundColor: "#00356D" }}
          >
            Yes
          </Button>
          <Button
            onClick={() => setShowDialogDelete(false)}
            className="btn-dialog-no"
            style={{ color: "#FFFFFF", backgroundColor: "#C7C7C7" }}
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HotspotHeader;
