import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import InputControl from "../../common/InputControl";
import SelectControl from "../../common/SelectControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Formik, Field } from "formik";
import isEmail from "validator/lib/isEmail";

import { fetchBuilderStaffJob } from "../../common/Stores/service/service";
import { apiError } from "../../common/Stores/action/action";
import { signup, regexSignUp, availableUrl } from "./service/service";
import { translateMessage, trimObjValues } from "utils/mapper";
import {
  translateValidate,
  messageValidate
} from "../../common/Stores/service/service";

import "../../common/css/base.css";
import "../../common/css/auth.css";

class SignUp extends Component {
  state = {
    isPassword: true,
    isRePassword: true,
    showSubmitErrors: false,
    validateMessage: {
      name: "",
      email: "",
      furigana: "",
      job: "",
      phone: "",
      password: "",
      password_confirmation: ""
    }
  };

  componentDidMount() {
    const {
      match,
      fetchBuilderStaffJob,
      apiError,
      regexSignUp,
      translateValidate,
      availableUrl,
      messageValidate
    } = this.props;
    fetchBuilderStaffJob(match.params.id);
    regexSignUp();
    apiError(null);
    translateValidate();
    messageValidate();
    availableUrl(match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type && nextProps.type === "SIGNUP_SUCCESS") {
      this.props.history.push("/login");
    }
    if (nextProps.type && nextProps.type === "API_ERROR") {
        this.setState({
        showSubmitErrors: true
      });
    }
  }

  showPassword = password => () => {
    if (password) {
      this.setState({
        isPassword: !this.state.isPassword
      });
    } else {
      this.setState({
        isRePassword: !this.state.isRePassword
      });
    }
  };

  checkButtonClick = formik => e => {
    e.preventDefault();
    e.stopPropagation();
    formik.handleSubmit();
  };

  onSubmit = values => {
    this.setState({
      showSubmitErrors: false
    });

    const valuesTrim = trimObjValues(values);
    const { regex, message } = this.props;
    const newValue = {
      email: valuesTrim.email,
      furigana: valuesTrim.furigana,
      job: valuesTrim.job,
      name: valuesTrim.name,
      password: valuesTrim.password,
      phone: valuesTrim.phone
    };

    const {
      name,
      furigana,
      job,
      email,
      phone,
      password,
      password_confirmation
    } = valuesTrim;

    if (newValue.job === -1) newValue.job = null;
    this.required(valuesTrim);
    this.invalid({ phone: phone, furigana: furigana });

    if (password_confirmation && password_confirmation !== password) {
      this.setState({
        validateMessage: {
          ...this.state.validateMessage,
          password_confirmation: translateMessage(
            this.getMessage("match", message),
            this.props.regexObject
          )
        }
      });
    }

    if ((password && !password.match(regex["password"])) || !password) {
      this.setState({
        validateMessage: {
          ...this.state.validateMessage,
          password: translateMessage(
            this.getMessage("password", message),
            this.props.regexObject
          )
        }
      });
    }

    if (email && !isEmail(email)) {
      this.setState({
        validateMessage: {
          ...this.state.validateMessage,
          email: translateMessage(
            this.getMessage("email", message),
            this.props.regexObject
          )
        }
      });
    }

    if (
      name &&
      job >= -1 &&
      isEmail(email) &&
      password_confirmation === password &&
      password.match(regex["password"]) &&
      phone.match(regex["phone"]) &&
      furigana.match(regex["furigana"])
    ) {
      this.props.signup(this.props.match.params.id, newValue, () => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        this.props.history.go(0);
      });
    }
  };

  getMessage = (key, message) => {
    return message[key];
  };

  invalid = values => {
    const { regex, message } = this.props;
    for (const item in values) {
      if (!values[item].match(regex[item]) && values[item]) {
        this.setState({
          validateMessage: {
            ...this.state.validateMessage,
            [item]: translateMessage(
              this.getMessage(`${item}`, message),
              this.props.regexObject
            )
          }
        });
      }
    }
  };

  required = values => {
    const { message } = this.props;
    const newValue = { ...values };
    if (newValue.job < -1) {
      newValue.job = "";
    } else {
      newValue.job = 1;
    }

    for (const item in newValue) {
      if (!newValue[item]) {
        this.setState({
          validateMessage: {
            ...this.state.validateMessage,
            [item]: translateMessage(
              this.getMessage("required", message),
              this.props.regexObject
            )
          }
        });
      } else {
        this.setState({
          validateMessage: {
            ...this.state.validateMessage,
            [item]: ""
          }
        });
      }
    }
  };

  render() {
    const { isPassword, isRePassword, validateMessage, showSubmitErrors } = this.state;
    const { builderJob, submitErrors } = this.props;
    const values = {
      name: "",
      furigana: "",
      job: -1,
      phone: "",
      email: "",
      password: "",
      password_confirmation: ""
    };

    return (
      <Formik
        initialValues={values}
        enableReinitialize
        onSubmit={values => this.onSubmit(values)}
        render={formik => (
          <form
            onSubmit={this.checkButtonClick(formik)}
            className="form-signup"
          >
            <Grid className="area-login-wrap">
              <Grid container justify="space-between">
                <Grid className="area-logo" />
                <Grid className="area-login signup">
                  <Grid>
                    <h4 className="ttl-login">初期登録</h4>
                  </Grid>
                  <Grid className="wrap-form">
                    <Grid>
                      <Field
                        label={
                          <Typography variant="subtitle1" className="label">
                            社員名
                            <span className="required">[必須]</span>
                          </Typography>
                        }
                        name="name"
                        type="text"
                        onChange={formik.handleChange}
                        variant="filled"
                        className="input-login"
                        component={InputControl}
                      />
                      {validateMessage["name"] && (
                        <span className="error">{validateMessage["name"]}</span>
                      )}
                    </Grid>
                    <Grid>
                      <Field
                        label={
                          <Typography variant="subtitle1" className="label">
                            社員名（ふりがな全角）​
                            <span className="required">[必須]</span>
                          </Typography>
                        }
                        name="furigana"
                        type="text"
                        onChange={formik.handleChange}
                        variant="filled"
                        className="input-login"
                        component={InputControl}
                      />
                      {validateMessage["furigana"] && (
                        <span className="error">
                          {validateMessage["furigana"]}
                        </span>
                      )}
                    </Grid>
                    <Grid>
                      <Field
                        title={
                          <Typography variant="subtitle1" className="label">
                            役割
                          </Typography>
                        }
                        name="job"
                        onChange={formik.handleChange}
                        variant="filled"
                        className="input-login reset-select"
                        component={SelectControl}
                      >
                        <MenuItem className="select-item" value={-1}>
                          選択して下さい
                        </MenuItem>

                        {builderJob.map((item, index) => (
                          <MenuItem
                            className="select-item"
                            value={item.uniqueCode}
                            key={index}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Field>
                      {validateMessage["job"] && (
                        <span className="error">{validateMessage["job"]}</span>
                      )}
                    </Grid>
                    <Grid>
                      <Field
                        label={
                          <Typography variant="subtitle1" className="label">
                            個人携帯
                            <span className="required">[必須]</span>
                          </Typography>
                        }
                        name="phone"
                        type="text"
                        onChange={formik.handleChange}
                        variant="filled"
                        className="input-login"
                        component={InputControl}
                      />
                      {validateMessage["phone"] && (
                        <span className="error">
                          {validateMessage["phone"]}
                        </span>
                      )}
                    </Grid>
                    <Grid>
                      <Field
                        label={
                          <Typography variant="subtitle1" className="label">
                            メールアドレス
                            <span className="required">[必須]</span>
                          </Typography>
                        }
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        variant="filled"
                        className="input-login"
                        component={InputControl}
                      />
                      {validateMessage["email"] && (
                        <span className="error">
                          {validateMessage["email"]}
                        </span>
                      )}
                      {showSubmitErrors && submitErrors && submitErrors["email"] && (
                        <span className="error">{submitErrors["email"]}</span>
                      )}
                    </Grid>
                    <Grid className="relative">
                      <Field
                        label={
                          <Typography variant="subtitle1" className="label">
                            パスワード
                            <span className="required">[必須]</span>
                          </Typography>
                        }
                        name="password"
                        type={isPassword ? "password" : "text"}
                        onChange={formik.handleChange}
                        variant="filled"
                        className="input-login"
                        component={InputControl}
                        autoComplete="new-password"
                      />
                      <IconButton
                        color="primary"
                        aria-label="copy"
                        className="show-password"
                        onClick={this.showPassword(true)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      {validateMessage["password"] ? (
                        <span className="error">
                          {validateMessage["password"]}
                        </span>
                      ) : (
                        <span style={{color: "#909090"}}>半角英字、半角数字を含む8文字以上をご入力ください</span>
                      )}
                    </Grid>
                    <Grid className="relative">
                      <Field
                        label={
                          <Typography variant="subtitle1" className="label">
                            パスワード（再入力）
                            <span className="required">[必須]</span>
                          </Typography>
                        }
                        name="password_confirmation"
                        type={isRePassword ? "password" : "text"}
                        onChange={formik.handleChange}
                        variant="filled"
                        className="input-login"
                        component={InputControl}
                      />
                      <IconButton
                        color="primary"
                        aria-label="copy"
                        className="show-password"
                        onClick={this.showPassword(false)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      {validateMessage["password_confirmation"] && (
                        <span className="error">
                          {validateMessage["password_confirmation"]}
                        </span>
                      )}
                    </Grid>
                    <Grid container justify="center">
                      <button
                        type="submit"
                        className="btn-login"
                        onClick={this.checkButtonClick(formik)}
                      >
                        設定完了
                      </button>
                      {/* <span className="red-text">{errors.error}</span> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  regex: state.auth.regex,
  type: state.auth.type,
  builderJob: state.storesReducer.builderJob,
  submitErrors: state.storesReducer.error,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});

export default connect(mapStateToProps, {
  fetchBuilderStaffJob,
  signup,
  apiError,
  regexSignUp,
  translateValidate,
  availableUrl,
  messageValidate
})(withRouter(SignUp));
