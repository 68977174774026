import React, { createRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Component from "../../../Component";
import { gsap, TweenMax } from "gsap";
import { Draggable } from "gsap/Draggable";
import Grid from "@material-ui/core/Grid";
import { compassSettingDirection } from "../service/service";
import RefreshIcon from "@material-ui/icons/Refresh";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CompassImage from "../../../../common/img/compass.svg";
import RotateImage from "../../../../common/img/Rotate.svg";
import Button from "@material-ui/core/Button";
import CompassSettingConfirm from "./CompassSettingConfirm";
import CompassSettingSaveConfirm from "./CompassSettingSaveConfirm";
import CompassSettingConfirmDialog from "./CompassSettingConfirmDialog";
import ScrollContainer from "react-indiana-drag-scroll";
import CompassView from "./CompassView";
import DrawingPhoto from "../DrawingPhoto";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
gsap.registerPlugin(Draggable);
export class CompassSetting extends Component {
  constructor(props) {
    super(props);
    this.compassRef = React.createRef();
    this.compassContainerRef = React.createRef();
    this.controlRef = React.createRef();
    this.RotationRef = React.createRef();
    this.compassViewContainerRef = React.createRef();
    this.compassViewRef = React.createRef();
  }
  state = {
    isCompassSettingMode: true,
    isBottomControl: true,
    Rotation: 0,
    CurrentRotation: 0,
    size: 0, // MiniSize : -1  //NormalSize:0 // MaxSize: 1;
    isShowDialogConfirmDirection: false,
    isShowDialogConfirmSaveDirection: false,
    isConfirmCurrentDrawingDialog: false,
    isConfirmListDrawingDialog: false,
    sitesUC: "",
    currentDrawingUCs: "",
    settingDirection: 0,
    drawingsWithSpots: this.props.drawingsWithSpots,
    selectedDrawing: [],
    isSavingCompassSetting: false,
    compassWidth: 270,
    compassHeight: 310,
    isCompassSetting: this.props.isCompassSetting,
    isCompassSettingCurrent: true,
    isClickedCompassOutSide: true,
    currentX: -1,
    currentY: -1,
    isShowButtonSave: true,
    isClickButtonSave: false,
    isFirstClick: true,
    isClickToDrawing: this.props.isClickToDrawing,
    isCompleteSetting: false,
    noteText: false
  };
  objDraggable;
  componentDidMount() {
    gsap.set(this.compassRef.current, { rotation: this.props.direction });
    gsap.set(this.compassViewRef.current, { rotation: this.props.direction });

    this.insCompassViewer();
    this.ResetCompassPosition();
    this.checkPosition(this.props.direction);
    window.addEventListener("resize", this.ResetCompassPosition);
    document.addEventListener("click", this.handleCompassClick);
    this.setState({ isClickedCompassOutSide: !this.props.isCompassSetting });
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleCompassClick);
  }
  componentWillReceiveProps(nextProps) {
    gsap.set(this.compassViewRef.current, { rotation: nextProps.direction });
    if (this.state.drawingsWithSpots != nextProps.drawingsWithSpots) {
      this.setState({
        drawingsWithSpots: nextProps.drawingsWithSpots
      });
    }
    if (
      this.state.noteText != nextProps.noteText &&
      nextProps.isCompassSetting
    ) {
      this.setState({
        noteText: nextProps.noteText
      });
      var draggableObj = Draggable.create(
        this.compassViewContainerRef.current,
        {
          type: "x,y",
          bounds: document.getElementById(`${this.props.bounds}`),
          onDragStart: () => {
            this.props.handleCompassDragParent(true);
          },
          onDragEnd: () => {
            this.props.handleCompassDragParent(false);
          }
        }
      );
      if (nextProps.noteText) {
        draggableObj[0].disable();
        // TweenMax.set(this.compassViewContainerRef.current, { x: this.state.currentX, y: this.state.currentY });
      }
    }
    // if (this.state.CurrentRotation !== nextProps.direction) {
    //      gsap.set(this.compassRef.current, { rotation: nextProps.direction })
    //     this.setState({
    //         CurrentRotation: nextProps.direction
    //     })
    //     this.checkPosition(nextProps.direction);
    // }
    if (nextProps.isCompassSetting !== this.state.isCompassSettingCurrent) {
      gsap.set(this.compassRef.current, { rotation: nextProps.direction });
      let x =
        document.getElementById("draggable-primary").clientWidth / 2 -
        this.state.compassWidth / 2;
      let y =
        document.getElementById("draggable-primary").clientHeight / 2 -
        this.state.compassHeight / 2;
      this.setState({
        Rotation: nextProps.direction
      });
      TweenMax.set(this.compassContainerRef.current, { x: x, y: y });
      this.setState({
        isCompassSettingCurrent: nextProps.isCompassSetting,
        isShowButtonSave: true,
        isFirstClick: true
      });
      if (!nextProps.isCompassSetting) {
        TweenMax.set(this.compassViewContainerRef.current, { x: 0, y: 0 });
      }
    }
    this.setState({
      sitesUC: nextProps.sitesUC,
      currentDrawingUCs: nextProps.currentDrawingUCs
    });
  }
  ResetCompassPosition = e => {
    let ModalElement = document.getElementById("draggable-primary");
    let x = 0;
    let y = 0;
    if (ModalElement !== null) {
      x = ModalElement.clientWidth / 2 - this.state.compassWidth / 2;
      y = ModalElement.clientHeight / 2 - this.state.compassHeight / 2;
    }
    TweenMax.set(this.compassContainerRef.current, { x: x, y: y });
  };
  checkPosition(Direc) {
    if (Direc <= 0) {
      Direc = 360 + Direc;
    }
    Direc = Math.round(Direc);
    if (Direc == 360) {
      Direc = 0;
    }
    this.setState({
      Rotation: Direc,
      // isBottomControl: !(Direc > 90 && Direc <= 270)
      isBottomControl: true
    });
  }
  loadMarker = () => {};

  insCompassViewer = () => {
    var draggableObj = Draggable.create(this.compassViewContainerRef.current, {
      type: "x,y",
      bounds: document.getElementById(`${this.props.bounds}`),
      onDragStart: () => {
        this.props.handleCompassDragParent(true);
      },
      onDragEnd: () => {
        this.props.handleCompassDragParent(false);
      }
    });
    TweenMax.set(this.compassViewContainerRef.current, { x: 0, y: 0 });
  };
  ChangeMode = () => {
    this.setState({
      isCompassSettingMode: !this.state.isCompassSettingMode
    });
    
    var rotationObj = Draggable.create(this.compassRef.current, {
      type: "rotation",
      onDrag: () => {
        let CurDirection = rotationObj[0].endX % 360;
        this.checkPosition(CurDirection);
      }
    });
    var draggableObj = Draggable.create(this.compassContainerRef.current, {
      type: "x,y",
      bounds: document.getElementById(`${this.props.bounds}`)
    });
    if (!this.state.isCompassSettingMode) {
      draggableObj[0].disable();
    } else {
      rotationObj[0].disable();
    }
  };
  ClickedOutSide = value => {
    this.setState({
      isCompassSettingMode: value
    });
    var rotationObj = Draggable.create(this.compassRef.current, {
      type: "rotation",
      onDrag: () => {
        let CurDirection = rotationObj[0].endX % 360;
        this.checkPosition(CurDirection);
      }
    });
    var draggableObj = Draggable.create(this.compassContainerRef.current, {
      type: "x,y",
      bounds: document.getElementById(`${this.props.bounds}`),
      onDragStart: () => {
        this.setState({
          isDraggComplete: true
        });
      }
    });
    if (value) {
      draggableObj[0].disable();
      if (!this.state.isShowDialogConfirmSaveDirection) {
        if (this.state.isCompleteSetting) {
          if (this.state.isDraggComplete) {
            this.ResetCompassPosition();
          } else {
            let x =
              document.getElementById("draggable-primary").clientWidth -
              this.state.compassWidth;
            TweenMax.set(this.compassContainerRef.current, { x: x, y: 10 });
          }
        }
        this.setState({
          isShowButtonSave: true,
          isCompleteSetting: false
        });
      }
    } else {
      rotationObj[0].disable();
    }
  };
  checkCompassWidth(size) {
    this.setState({
      size: this.state.size + size
    });
  }
  zoomOut = () => {
    if (this.state.size > -2) {
      this.checkCompassWidth(-1);
    }
  };
  zoomIn = () => {
    if (this.state.size < 1) {
      this.checkCompassWidth(1);
    }
  };
  saveCurrentDrawing = () => {
    let value = {
      drawingsUCs: [this.state.currentDrawingUCs],
      direction: this.state.Rotation
    };
    this.props.compassSettingDirection(this.state.sitesUC, value);
    setTimeout(() => {
      this.setState(
        {
          isConfirmCurrentDrawingDialog: false,
          isShowDialogConfirmDirection: false,
          isShowButtonSave: false,
          isClickedCompassOutSide: true,
          isCompleteSetting: true,
          isDraggComplete: false
        },
        this.ClickedOutSide(false)
      );
      this.resetSaveCompassPosition();
    }, 10);
  };
  saveListDrawing = () => {
    let arrayDrawing = this.state.selectedDrawing;
    if (arrayDrawing.indexOf(this.state.currentDrawingUCs) == -1) {
      arrayDrawing.push(this.state.currentDrawingUCs);
    }
    let value = {
      drawingsUCs: arrayDrawing,
      direction: this.state.Rotation
    };
    this.props.compassSettingDirection(this.state.sitesUC, value);
    this.handleSaving(false);
    setTimeout(() => {
      this.setState(
        {
          isShowDialogConfirmDirection: false,
          isShowDialogConfirmSaveDirection: false,
          isConfirmListDrawingDialog: false,
          isClickedCompassOutSide: true,
          isShowButtonSave: false,
          isCompleteSetting: true,
          isDraggComplete: false
        },
        this.ClickedOutSide(false)
      );
      this.resetSaveCompassPosition();
    }, 10);
  };
  resetSaveCompassPosition = () => {
    let x = document.getElementById("draggable-primary").clientWidth - 130;
    TweenMax.set(this.compassContainerRef.current, { x: x, y: 0 });
  };
  handleSaving = value => {
    if (value) {
      this.setState({
        isSavingCompassSetting: true
      });
      setTimeout(() => {
        this.props.handleSavingParent(true);
        this.props.handleSavingDirectionParent(this.state.Rotation);
      }, 100);
    } else {
      this.setState({
        isSavingCompassSetting: false
      });
      this.props.handleSavingParent(false);
    }
  };
  btnSaveClick = () => {
    this.setState({
      isClickButtonSave: true,
      isShowDialogConfirmDirection: !this.state.isShowDialogConfirmDirection,
      isShowButtonSave: !this.state.isShowButtonSave
    });
  };
  handleCompassClick = event => {
    if (this.state.isFirstClick) {
      this.setState({
        isClickedCompassOutSide: false,
        isFirstClick: false
      });
      this.ClickedOutSide(true);
    } else {
      const { target } = event;
      if (
        !this.state.isSavingCompassSetting &&
        !this.compassRef.current.contains(target) &&
        !(
          this.controlRef.current && this.controlRef.current.contains(target)
        ) &&
        this.props.isClickToDrawing
      ) {
        this.setState({ isClickedCompassOutSide: true });
        this.ClickedOutSide(false);
      }
      if (
        (this.compassRef.current.contains(target) ||
          (this.controlRef.current &&
            this.controlRef.current.contains(target))) &&
        this.props.isClickToDrawing
      ) {
        this.setState({ isClickedCompassOutSide: false });
        this.ClickedOutSide(true);
      }
    }
  };
  handleChangeListDrawing = DrawingUC => {
    let array = [...this.state.selectedDrawing];
    var indexofDrawingUC = array.indexOf(DrawingUC);
    if (indexofDrawingUC !== -1) {
      array.splice(indexofDrawingUC, 1);
      this.setState({ selectedDrawing: array });
    } else {
      this.setState({
        selectedDrawing: [...this.state.selectedDrawing, DrawingUC]
      });
    }
  };
  CheckAllDrawing = () => {
    let array = [];
    this.state.drawingsWithSpots.forEach(item => array.push(item.uniqueCode));
    this.setState({ selectedDrawing: array });
  };
  disableHeader = isShow => {
    if (isShow) {
      var element = document.createElement("div");
      element.className = "fixed-box-header";
      document.getElementById("header-builder").appendChild(element);
    } else {
      var element = document.getElementsByClassName("fixed-box-header")[0];
      if (element) {
        element.parentNode.removeChild(element);
      }
    }
  };
  render() {
    const {
      drawingsUC,
      direction,
      isCompassSetting,
      isNullDirection,
      isAddPoint,
      currentDrawing
    } = this.props;
    const {
      isCompassSettingMode,
      isBottomControl,
      Rotation,
      size,
      isShowDialogConfirmDirection,
      isShowDialogConfirmSaveDirection,
      isConfirmCurrentDrawingDialog,
      isConfirmListDrawingDialog,
      isClickedCompassOutSide,
      isClickButtonSave,
      isShowButtonSave,
      isCompleteSetting,
      isSavingCompassSetting,
      selectedDrawing,
      drawingsWithSpots
    } = this.state;
    const toggleConfirmDirectionDialog = () => {
      this.setState({
        isShowDialogConfirmDirection: !this.state.isShowDialogConfirmDirection,
        isShowButtonSave: !this.state.isShowButtonSave
      });
    };
    const toggleConfirmCurrentDrawingDialog = () => {
      this.setState({
        isConfirmCurrentDrawingDialog:
          !this.state.isConfirmCurrentDrawingDialog,
        isShowDialogConfirmDirection: !this.state.isShowDialogConfirmDirection
      });
    };
    const toggleConfirmListDrawingDialog = () => {
      this.disableHeader(this.state.isConfirmListDrawingDialog);
      this.setState({
        isConfirmListDrawingDialog: !this.state.isConfirmListDrawingDialog,
        isShowDialogConfirmSaveDirection:
          !this.state.isShowDialogConfirmSaveDirection
      });
    };
    const toggleConfirmDirectionSaveDialog = () => {
      this.handleSaving(!this.state.isShowDialogConfirmSaveDirection);
      this.setState(
        {
          isShowDialogConfirmSaveDirection:
            !this.state.isShowDialogConfirmSaveDirection,
          isShowDialogConfirmDirection:
            !this.state.isShowDialogConfirmDirection,
          selectedDrawing: []
        },
        this.disableHeader(!this.state.isShowDialogConfirmSaveDirection)
      );
    };
    return (
      <>
        {isShowDialogConfirmSaveDirection && (
          <Grid className="fixed-box"></Grid>
        )}
        <Grid
          ref={this.compassContainerRef} // Draggable object
          id={"compass-setting-container"}
          container
          direction="row"
          className={`compass-setting-container 
                    compass-setting-container-${
                      isCompleteSetting
                        ? "complete"
                        : size === 0
                        ? "normalsize "
                        : size === -1
                        ? "minisize "
                        : size === -2
                        ? "minimumsize "
                        : "maxsize "
                    } 
                    ${!isCompassSetting ? "display-none" : ""}`}
        >
          <Grid item xs={12} ref={input => (this.inputElement = input)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={5}
              ref={this.compassRef} // rotation object
              class={`compass-setting-rotation-container ${
                !isClickedCompassOutSide ? " rotation-container-selected" : ""
              }
                            compass-setting-rotation-container-${
                              isCompleteSetting
                                ? "complete"
                                : size === 0
                                ? "normalsize "
                                : size === -1
                                ? "minisize "
                                : size === -2
                                ? "minimumsize "
                                : "maxsize "
                            }`}
            >
              <Grid
                item
                xs
                className="text-center compass-setting-rotation-icon-container"
              >
                <p>{!isClickedCompassOutSide && <img src={RotateImage} />} </p>
                {/* <p>{isCompassSettingMode && <RefreshIcon className={"compass-setting-button-icon "} />} </p> */}
              </Grid>
              <Grid
                item
                xs
                className={`text-center compass-setting-icon-container`}
              >
                <img
                  className={`compass-setting-icon compass-setting-icon-${
                    isCompleteSetting
                      ? "complete"
                      : size === 0
                      ? "normalsize "
                      : size === -1
                      ? "minisize "
                      : size === -2
                      ? "minimumsize "
                      : "maxsize "
                  }`}
                  src={CompassImage}
                />
              </Grid>
            </Grid>
          </Grid>
          {!isClickedCompassOutSide && (
            <Grid
              item
              xs={12}
              ref={this.controlRef}
              class={`compass-setting-control-container ${
                isBottomControl ? "margin-top-160px" : "margin-top-40px"
              }    `}
            >
              <Grid container spacing={3} direction="row">
                <Grid item xs={6} className={"text-left pd-0px"}>
                  <RemoveCircleIcon
                    onClick={this.zoomOut}
                    className={`compass-setting-button-icon${
                      size === -2 ? "-disable" : ""
                    }`}
                  />
                </Grid>
                <Grid item xs={6} className={"text-right pd-0px"}>
                  <AddCircleIcon
                    onClick={this.zoomIn}
                    className={`compass-setting-button-icon${
                      size === 1 ? "-disable" : ""
                    }`}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {!isClickedCompassOutSide && (
            <Grid
              item
              xs={12}
              ref={this.RotationRef}
              class={`compass-setting-control-container ${
                !isBottomControl ? "margin-top-160px" : "margin-top-40px"
              }    `}
            >
              <Grid container spacing={3} direction="row">
                <Grid item xs={12} className={"text-center pd-0px"}>
                  <p className={"compass-setting-direction-label"}>
                    {Rotation}
                    <sup>o</sup>
                  </p>
                </Grid>
              </Grid>
            </Grid>
          )}
          {isShowButtonSave && (
            <Grid item xs={12} class={`compass-setting-save-button`}>
              <Button
                size="small"
                variant="text"
                onClick={this.btnSaveClick}
                className={`button-add add-point-enable`}
              >
                <label style={{ color: "inherit" }}>決定</label>
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid
          ref={this.compassViewContainerRef} // Draggable object
          id={"compass-setting-container"}
          container
          direction="row"
          className={`compass-setting-container 
                    compass-setting-viewer-container-normalsize 
                    ${
                      isCompassSetting || isNullDirection || isAddPoint
                        ? "display-none"
                        : ""
                    }`}
          onTouchStart={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Grid item xs={12} ref={input => (this.inputElement = input)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={5}
              ref={this.compassViewRef} // rotation object
              class={`compass-setting-viewer-rotation-container-normalsize`}
            >
              <Grid
                item
                xs
                className={`text-center compass-setting-icon-container`}
              >
                <img
                  className={`compass-setting-icon compass-setting-viewer-icon-normalsize`}
                  src={CompassImage}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <CompassSettingConfirm
          open={isShowDialogConfirmDirection}
          onClose={toggleConfirmDirectionDialog}
          title="方位情報の設定先を選択する"
          textButton1="他の図面にも反映"
          onClickButton1={toggleConfirmDirectionSaveDialog}
          textButton2="この図面のみに反映"
          onClickButton2={toggleConfirmCurrentDrawingDialog}
          disabledButton2={false}
          PaperProps={{
            className:
              "dialog dialog-sign-up map-option-dialog compass-setting-confirm-dialog-container"
          }}
        />
        <CompassSettingConfirmDialog
          open={isConfirmCurrentDrawingDialog}
          onClose={toggleConfirmCurrentDrawingDialog}
          title="方位情報を設定します"
          textButton1="Yes"
          onClickButton1={this.saveCurrentDrawing}
          textButton2="No"
          onClickButton2={toggleConfirmCurrentDrawingDialog}
          disabledButton2={false}
          PaperProps={{
            className:
              "dialog dialog-sign-up map-option-dialog compass-setting-dialog-container"
          }}
        />
        <CompassSettingConfirmDialog
          open={isConfirmListDrawingDialog}
          onClose={toggleConfirmListDrawingDialog}
          title="方位情報を設定します"
          textButton1="Yes"
          onClickButton1={this.saveListDrawing}
          textButton2="No"
          onClickButton2={toggleConfirmListDrawingDialog}
          disabledButton2={false}
          PaperProps={{
            className:
              "dialog dialog-sign-up map-option-dialog compass-setting-dialog-container"
          }}
        />
        {isShowDialogConfirmSaveDirection && (
          // <div className="compass-saving-container-div"></div>
          <div className="compass-saving-container">
            <div className="compass-saving-container-div">
              <CompassSettingSaveConfirm
                open={isShowDialogConfirmSaveDirection}
                onClose={toggleConfirmDirectionSaveDialog}
                title="他の図面に方位情報を反映する"
                textButton1="全ての図面に反映する"
                onClickButton1={this.CheckAllDrawing}
                textButton2="決定"
                onClickButton2={toggleConfirmListDrawingDialog}
                disabledButton2={false}
                PaperProps={{
                  className:
                    "dialog dialog-sign-up map-option-dialog compass-setting-confirm-dialog-container-save"
                }}
              />
              <div className="container" horizontal={true} vertical={false}>
                {drawingsWithSpots.length > 0 && isSavingCompassSetting && (
                  <ScrollContainer
                    horizontal={true}
                    vertical={false}
                    className={`grid-list ${
                      isSavingCompassSetting ? "list-drawing-saving" : ""
                    }`}
                  >
                    {drawingsWithSpots.map((drawing, index) => (
                      <div
                        className={`sub-block ${
                          (currentDrawing === index ||
                            (isSavingCompassSetting &&
                              selectedDrawing.indexOf(drawing.uniqueCode) !==
                                -1)) &&
                          "current-drawing"
                        }`}
                        onClick={() => {
                          this.handleChangeListDrawing(drawing.uniqueCode);
                        }}
                      >
                        <Grid className="sub-block-title text-center">
                          <Typography
                            variant="body2"
                            variantMapping={{ body2: "span" }}
                            className="text-bold text-center mb-5"
                          >
                            {drawing.name}
                          </Typography>
                          <span
                            onClick={e => {
                              this.props.toggleDialogEditMapCompass(
                                drawing.uniqueCode
                              );
                              e.stopPropagation();
                            }}
                            className="edit-button"
                          >
                            <EditIcon className="icon-margin" />
                          </span>
                        </Grid>
                        <div
                          className={`sub-img-block ${
                            drawing.isVerticalImg
                              ? "vertical-sub-compass-block"
                              : ""
                          }`}
                        >
                          <DrawingPhoto
                            class={`sub-image`}
                            drawingsUC={drawing.uniqueCode}
                            blob={drawing.blob}
                            fetchDone={this.fetchDone}
                            alt={drawing.title}
                            id={drawing.uniqueCode}
                            loadMarker={this.loadMarker}
                            isHideMarker={true}
                          />
                          <CompassView
                            direction={drawing.direction}
                            drawingsUC={drawing.uniqueCode}
                            isSavingCompassSetting={isSavingCompassSetting}
                            savingDirection={Rotation}
                            currentDrawing={currentDrawing === index}
                            isSelected={
                              selectedDrawing.indexOf(drawing.uniqueCode) !== -1
                            }
                            isCompassSetting={isCompassSetting}
                            isAddPoint={isAddPoint}
                          />
                        </div>
                      </div>
                    ))}
                  </ScrollContainer>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  type: state.rootSitesReducer.siteInfoReducer.type
});

export default connect(mapStateToProps, {
  compassSettingDirection
})(withRouter(CompassSetting));
