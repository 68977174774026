/*
 * Copyright 2016 Google Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
'use strict';

var inherits = require('./util/inherits');

/**
 * @class NetworkError
 * @extends {Error}
 * @classdesc
 *
 * Signals an error that occurred while fetching a URL. This is used by
 * {@link Loader loaders} to distinguish network failures from other errors.
 */
function NetworkError(message) {
  // See: https://stackoverflow.com/questions/1382107/whats-a-good-way-to-extend-error-in-javascript
  this.constructor.super_.apply(this, arguments);
  this.message = message;
}

inherits(NetworkError, Error);

module.exports = NetworkError;
