/*
 * Copyright 2016 Google Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
'use strict';

var WebGlBaseRenderer = require('./WebGlBase');
var inherits = require('../util/inherits');

/**
 * @class WebGlFlatRenderer
 * @implements Renderer
 * @classdesc
 *
 * A renderer for {@link FlatGeometry} and {@link FlatView}, appropriate for a
 * {@link WebGlStage}.
 *
 * Most users do not need to instantiate this class. Renderers are created and
 * destroyed by {@link Stage} as necessary.
 */
function WebGlFlatRenderer() {
  this.constructor.super_.apply(this, arguments);
}

inherits(WebGlFlatRenderer, WebGlBaseRenderer);

module.exports = WebGlFlatRenderer;
