import React, { Component } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "../../common/css/base.css";
import "../../common/css/log.css";
import PaginationControl from "../../common/Pagination";
import RobotStatus from "common/RobotStatus";
import { ICON_LOGKUN } from "common/constants/constants";

class RobotBuilderPC extends Component {
  constructor() {
    super();
    this.state = {
      robotData: []
    };
  }

  onChangeData = data => {
    this.setState({
      robotData: data
    });
  };

  render() {
    const { robotData } = this.state;
    const { robots, to, robotsUpdate } = this.props;

    return (
      <Grid container wrap="nowrap" className="log page-content">
        <Grid container>
          <Grid
            container
            justify="space-between"
            className="padding-content"
          ></Grid>
          <TableContainer>
            <Table
              component="div"
              aria-label="table"
              className="carpenter-table"
            >
              <TableHead component="div" className="table-head">
                <TableRow component="span">
                  <TableCell
                    component="span"
                    className="white-text padding-first-th"
                  >
                    ロボット名
                  </TableCell>
                  <TableCell component="span" className="white-text">
                    現場名
                  </TableCell>
                  <TableCell component="span" className="white-text">
                    レンタル開始日
                  </TableCell>
                  <TableCell component="span" className="white-text">
                    ステータス
                  </TableCell>
                  <TableCell component="span"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody component="div" className="table-body">
                {robotData.map((row, index) => (
                  <TableRow
                    onClick={to(row.uniqueCode)}
                    key={index}
                    component="span"
                    className="table-row"
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell component="span" className="padding-first-td">
                      {row.robotName}
                    </TableCell>
                    <TableCell component="span">{row.siteName}</TableCell>
                    <TableCell component="span">
                      {format(new Date(row.leaseStartDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell component="span">
                      <RobotStatus
                        typeOfRobotStatus={ICON_LOGKUN}
                        robots={[row]}
                        robotsUpdate={robotsUpdate}
                      />
                    </TableCell>
                    <TableCell
                      component="span"
                      className="padding-last-td s-ta-r"
                    ></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justify="center">
            <PaginationControl
              limit={20}
              datas={robots}
              changeData={this.onChangeData}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

RobotBuilderPC.propTypes = {
  robots: PropTypes.array.isRequired,
  to: PropTypes.func.isRequired
};

export default RobotBuilderPC;
