import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { api } from "../../../common/api/api";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles, Button } from "@material-ui/core";
import LogMeetHistoryAll from "./LogMeetHistoryAll";
import LogMeetHistoryImage from "./LogMeetHistoryImage";
import LogMeetHistoryDetail from "./LogMeetHistoryDetail";
const mediaTiniMobile = "(max-width: 430px)";
const useStyles = makeStyles({
  buttonWrapper: {
    width: "144px",
    height: "36px",
    borderRadius: "8px",
    display: "flex",
    position: "relative",
    backgroundColor: "#ECEDED",
    fontFamily: "Noto Sans JP",
    marginRight: "14px",
    fontSize: "14px",
    fontWeight: "normal"
  },
  buttonWrapperTiniSP: {
    width: "144px",
    height: "36px",
    borderRadius: "8px",
    display: "flex",
    position: "relative",
    backgroundColor: "#ECEDED",
    fontFamily: "Noto Sans JP",
    marginRight: "14px",
    fontSize: "14px",
    fontWeight: "normal"
  },
  button: {
    width: "50%",
    height: "100%",
    zIndex: 2,
    color: "white",
    transition: "0.3s"
  },
  bg: {
    borderRadius: "8px",
    backgroundColor: "#162F50",
    position: "absolute",
    height: "100%",
    width: "50%",
    top: "0",
    left: "0",
    transition: "left 0.3s"
  }
});

function LogMeet(props) {
  const historyAllRef = useRef()
  const historyImagesRef = useRef()

  const { sitesUC } = props;
  const [historyDetail, setHistoryDetail] = useState(null);
  const [tabState, setTabState] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [currentItemIndex, setCurrentItemIndex] = useState(null);

  const toggleTabState = () => {
    setTabState(!tabState);
  }

  const toggleHistoryDetailModal = value => {
    setHistoryDetail(value);
  }

  useEffect(() => {
    if (!historyDetail && currentItemIndex) {
      //reload and scroll to current item
      if (!tabState) {
        // historyImagesRef.current.reloadFromOutside();
      }
      const element = document.getElementById(`history-item-${currentItemIndex}`);
      element.scrollIntoView();
    }
  }, [historyDetail]);



  return (
    <>
      <Grid container className="site-logmeet-tab-container"
        style={{
          display: historyDetail ? "none" : "flex"
        }}
      >
        <Grid item xs={12} style={{ display: "flex" }}>
          <span style={{
            fontFamily: "M PLUS 1",
            fontWeight: "normal",
            fontSize: "20px",
            marginRight: "48px"
          }}>現場の通話履歴</span>

          <div className={classes.buttonWrapper}>
            <button
              className={classes.button}
              onClick={() => toggleTabState()}
              style={{
                color: tabState ? "white" : "#808080"
              }}
            >
              すべて
            </button>
            <button
              className={classes.button}
              onClick={() => toggleTabState()}
              style={{
                color: !tabState ? "white" : "#808080"
              }}
            >
              写真
            </button>
            <div
              className={classes.bg}
              style={{ left: tabState ? "0" : "50%" }}
            ></div>
          </div>


        </Grid>
        <Grid item xs={12} style={{ display: "flex", marginTop: "18px" }}>

          {tabState ?
            (
              <LogMeetHistoryAll
                sitesUC={sitesUC}
                toggleHistoryDetailModal={toggleHistoryDetailModal}
                setCurrentItemIndex={setCurrentItemIndex}
              />
            )
            :
            (
              <LogMeetHistoryImage
                ref={historyImagesRef}
                sitesUC={sitesUC}
                toggleHistoryDetailModal={toggleHistoryDetailModal}
                setCurrentItemIndex={setCurrentItemIndex}
              />
            )
          }
        </Grid>
      </Grid>
      {historyDetail && (
        <LogMeetHistoryDetail
          sitesUC={sitesUC}
          historyDetail={historyDetail}
          toggleHistoryDetailModal={toggleHistoryDetailModal}
        />
      )}
    </>
  );
}

export default LogMeet;