import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const useStyles = makeStyles({
  fullWidth: {
    position: "absolute",
    top: "50%",
    left: "0%",
    transform: "translate(0%, -50%)",
    "& img": {
      width: "100%",
      height: "auto",
      maxWidth: "100%",
      maxHeight: "100%"
    }
  },
  fullHeight: {
    position: "absolute",
    top: "0%",
    left: "50%",
    height: "100%",
    transform: "translate(-50%, 0%)",
    "& img": {
      width: "auto",
      height: "100%",
      maxWidth: "100%",
      maxHeight: "100%"
    }
  }
});
function ReviewSpotPosition(props) {
  const { drawing, spot } = props;
  const classes = useStyles();

  const [drawingImg, setDrawingImg] = useState(null);
  const [drawingImgWidth, setDrawingImgWidth] = useState(0);
  const [drawingImgHeight, setDrawingImgHeight] = useState(0);

  useEffect(() => {
    if (drawing !== "") {
      setDrawingImg(renderImg(drawing));
    } else {
      setDrawingImg(renderImg(""));
    }
  }, [drawing]);

  function renderImg(data) {
    return (
      <img
        src={data}
        onLoad={e => {
          setDrawingImgWidth(e.currentTarget.naturalWidth);
          setDrawingImgHeight(e.currentTarget.naturalHeight);
        }}
      />
    );
  }
  return (
    <div style={{ width: "100%", paddingTop: "75%", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0"
        }}
      >
        <div
          style={{
            position: "relative",
            border: "1px dotted #333",
            width: "100%",
            height: "100%"
          }}
        >
          <TransformWrapper defaultScale={1}>
            <TransformComponent>
              <div>
                {drawingImg !== null ? (
                  <div
                    className={
                      drawingImgWidth / drawingImgHeight > 4 / 3
                        ? classes.fullWidth
                        : classes.fullHeight
                    }
                  >
                    {drawingImg}
                    <FiberManualRecordIcon
                      stroke={"black"}
                      stroke-width={1}
                      style={{
                        left: (spot.x / drawingImgWidth) * 100 + "%",
                        top: (spot.y / drawingImgHeight) * 100 + "%",
                        position: "absolute",
                        color: "#007CFF",
                        fontSize: "14px",
                        marginRight: "-7px",
                        margin: "-7px"
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </TransformComponent>
          </TransformWrapper>
        </div>
      </div>
    </div>
  );
}

ReviewSpotPosition.propTypes = {};

export default ReviewSpotPosition;
