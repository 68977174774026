import React from "react";
import PropTypes from "prop-types";

function TitleInput(props) {
  const { title, setTitle, placeholder, requiredError } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <input
        value={title}
        onChange={e => {
          setTitle(e.target.value);
        }}
        style={{
          width: "100%",
          height: "45px",
          boxSizing: "border-box",
          padding: "0px 20px",
          margin: "0px",
          fontSize: "17px",
          fontWeight: "normal",
          border: "1px solid #c7c7c7",
          borderRadius: "4px",
          boxShadow: "none",
          backgroundColor: "#F8F9F9",
          cursor: "pointer"
        }}
        placeholder={placeholder}
      />
      {requiredError && (
        <span
          style={{
            color: "red",
            display: "inline-block",
            width: "100%",
            fontSize: "15px",
            lineHeight: "22px"
          }}
        >
          入力必須項目です。
        </span>
      )}
    </div>
  );
}

TitleInput.propTypes = {
  title: PropTypes.string,
  setTitle: PropTypes.func,
  placeholder: PropTypes.string
};

export default TitleInput;
