import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Grid from "@material-ui/core/Grid";

class Checkboxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: typeof props.isAutoLoginChecked === 'boolean' ? props.isAutoLoginChecked : true,
    };
    this.onChange = props.onChange ? props.onChange : () => {};
  }
  componentDidMount() {
    localStorage.setItem("checked", 'true');
  }

  render() {
    const { checked } = this.state;

    const handleChange = event => {
      this.setState({
        checked: event.target.checked
      });
      localStorage.setItem("checked",  event.target.checked);
      this.onChange(event.target.checked);
    };

    return (
      <Grid>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              value="Remember password"
              color="primary"
              onChange={handleChange}
              inputProps={{ "aria-label": "Remember password" }}
            />
          }
          label={
            <span className="txt-login-memory">ログイン情報を記憶する</span>
          }
        />
      </Grid>
    );
  }
}

export default Checkboxes;
