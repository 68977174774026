import React from "react";
import twemoji from "twemoji";
import ReactHtmlParser from "react-html-parser";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";
import { api } from "../../../../../../common/api/api";
import axios from "axios";

import { CometChatMessageActions, CometChatThreadedMessageReplyCount, CometChatReadReceipt, CometChatLinkPreview } from "../";
import { CometChatMessageReactions } from "../Extensions";

import { CometChatContext } from "../../../util/CometChatContext";
import { linkify, checkMessageForExtensionsData } from "../../../util/common";
import * as enums from "../../../util/enums.js";

import Translator from "../../../resources/localization/translator";
import { theme } from "../../../resources/theme";
import Photo from "../../../../../../components/sites/site/Photo";
import {
	messageContainerStyle,
	messageWrapperStyle,
	messageTxtWrapperStyle,
	messageTxtStyle,
	messageInfoWrapperStyle,
	messageReactionsWrapperStyle,
	messageImgWrapper
} from "./style";

const LogbuildCustomMessageType = ["#CustomPhotoMessage", "#CustomFileMessage"];
class CometChatSenderTextMessageBubble extends React.Component {
	static contextType = CometChatContext;
	loggedInUser;

	constructor(props, context) {
		super(props, context);

		this.messageTextRef = React.createRef();
		this.messageImageRef = React.createRef();

		this.state = {
			translatedMessage: "",
			isHovering: false,
			enableLargerSizeEmojis: false,
		};

		this.context.getLoggedinUser().then(user => {
			this.loggedInUser = { ...user };
		});
	}
	shouldComponentUpdate(nextProps, nextState) {
		const currentMessageStr = JSON.stringify(this.props.message);
		const nextMessageStr = JSON.stringify(nextProps.message);

		if (currentMessageStr !== nextMessageStr
			|| this.state.isHovering !== nextState.isHovering
			|| this.state.enableLargerSizeEmojis !== nextState.enableLargerSizeEmojis) {
			return true;
		}

		return false;
	}

	componentDidMount() {
		let dt = this.getData();
		this.enableLargerSizeEmojis();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.message !== this.props.message) {
			this.setState({ translatedMessage: "" });
		}

		this.enableLargerSizeEmojis();
	}

	async fetch(id, type) {
		if (this.source) {
			this.source.cancel();
		}
		const CancelToken = axios.CancelToken;
		this.source = CancelToken.source();
		this.setState({ FetchDone: true });
		const res = await api.get(`/builder/${type}/${id}/thumbnail`,
			{
				responseType: "arraybuffer",
				cancelToken: this.source.token
			})
			.then(res => {
				this.setState({ FetchDone: true });
				if (res.data === undefined) return Promise.reject();
				var blob = new Blob([res.data], { type: "image/jpeg" });
				var url = URL.createObjectURL(blob);
				return {
					Status: true,
					Code: 200,
					Url: url
				};
			})
			.catch(e => {
				this.setState({ FetchDone: false, isDeleted: true });
				return {
					Status: false,
					Code: e?.response?.status
				};
			});

		return await res;
	}

	async getData() {
		try {
			if (this.props.message.data?.tags && LogbuildCustomMessageType.includes(this.props.message.data?.tags[0]) && !this.state.isDeleted) {
				this.setState({
					isCustomMediaMessage: true
				})
				let data = await this.fetch(
					this.props.message.data?.tags[1],
					this.props.message.data?.tags[0] === LogbuildCustomMessageType[0]
						? "site-photos"
						: "site-files");
				if (data.Status) {
					this.setState({
						blobData: data.Url,
						FetchDone: true
					}, () => {
						this.handleMouseHover(true);
					})
				} else {
					this.setState({
						FetchDone: false
					}, () => {
						this.handleMouseHover(true);
					})
				}

				return data;
			}
		} catch (err) {
			console.log(err);
			return false;
		}
	}

	getMessageText = () => {
		let messageText = this.props.message.text;
		//xss extensions data
		const xssData = checkMessageForExtensionsData(this.props.message, "xss-filter");
		if (xssData && xssData.hasOwnProperty("sanitized_text") && xssData.hasOwnProperty("hasXSS") && xssData.hasXSS === "yes") {
			messageText = xssData.sanitized_text;
		}

		//datamasking extensions data
		const maskedData = checkMessageForExtensionsData(this.props.message, "data-masking");
		if (maskedData && maskedData.hasOwnProperty("data") && maskedData.data.hasOwnProperty("sensitive_data") && maskedData.data.hasOwnProperty("message_masked") && maskedData.data.sensitive_data === "yes") {
			messageText = maskedData.data.message_masked;
		}

		//profanity extensions data
		const profaneData = checkMessageForExtensionsData(this.props.message, "profanity-filter");
		if (profaneData && profaneData.hasOwnProperty("profanity") && profaneData.hasOwnProperty("message_clean") && profaneData.profanity === "yes") {
			messageText = profaneData.message_clean;
		}

		const formattedText = linkify(messageText);

		const emojiParsedMessage = twemoji.parse(formattedText, { folder: "svg", ext: ".svg" });
		const parsedMessage = ReactHtmlParser(emojiParsedMessage);
		const emojiMessage = parsedMessage.filter(message => message instanceof Object && message.type === "img");

		let showVariation = true;
		//if larger size emojis feature is disabled
		if (this.state.enableLargerSizeEmojis === false) {
			showVariation = false;
		}
		messageText = (<div css={messageTxtWrapperStyle(this.context)} className="message__txt__wrapper"
			style={{ backgroundColor: "#FFFFFF", color: "rgba(0, 0, 0, 0.87)", boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)" }}>
			<p css={messageTxtStyle(this.props, parsedMessage, emojiMessage, showVariation)} className="message__txt">
				{parsedMessage}
				{ReactHtmlParser(this.state.translatedMessage)}

			</p>
		</div>);


		return messageText;
	};

	translateMessage = message => {
		const messageId = message.id;
		const messageText = message.text;

		const browserLanguageCode = Translator.getBrowserLanguage().toLowerCase();
		let translateToLanguage = browserLanguageCode;
		if (browserLanguageCode.indexOf("-") !== -1) {
			const browserLanguageArray = browserLanguageCode.split("-");
			translateToLanguage = browserLanguageArray[0];
		}

		let translatedMessage = "";
		CometChat.callExtension("message-translation", "POST", "v2/translate", {
			msgId: messageId,
			text: messageText,
			"languages": ['ja']
		})
			.then(result => {
				if (result.hasOwnProperty("language_original") && result["language_original"] !== translateToLanguage) {
					if (result.hasOwnProperty("translations") && result.translations.length) {
						const messageTranslation = result.translations[0];
						if (messageTranslation.hasOwnProperty("message_translated")) {
							translatedMessage = `\n(${messageTranslation["message_translated"]})`;
						}
					} else {
						this.props.actionGenerated(enums.ACTIONS["ERROR"], [], "SOMETHING_WRONG");
					}
				} else {
					this.props.actionGenerated(enums.ACTIONS["INFO"], [], "SAME_LANGUAGE_MESSAGE");
				}

				this.setState({ translatedMessage: translatedMessage });
			})
			.catch(error => this.props.actionGenerated(enums.ACTIONS["ERROR"], [], "SOMETHING_WRONG"));
	};

	enableLargerSizeEmojis = () => {
		this.context.FeatureRestriction.isLargerSizeEmojisEnabled()
			.then(response => {
				if (response !== this.state.enableLargerSizeEmojis) {
					this.setState({ enableLargerSizeEmojis: response });
				}
			})
			.catch(error => {
				if (this.state.enableLargerSizeEmojis !== false) {
					this.setState({ enableLargerSizeEmojis: false });
				}
			});
	};

	handleMouseHover = (isHovering) => {
		this.setState({ isHovering });
	};

	actionHandler = (action, message) => {
		switch (action) {
			case enums.ACTIONS["REACT_TO_MESSAGE"]:
				this.props.actionGenerated(action, message);
				break;
			case enums.ACTIONS["VIEW_THREADED_MESSAGE"]:
				this.props.actionGenerated(action, message);
				break;
			case enums.ACTIONS["DELETE_MESSAGE"]:
				this.props.actionGenerated(action, message);
				break;
			case enums.ACTIONS["EDIT_MESSAGE"]:
				this.props.actionGenerated(action, message);
				break;
			case enums.ACTIONS["TRANSLATE_MESSAGE"]:
				this.translateMessage(message);
				break;
			default:
				break;
		}
	};

	render() {
		const { isCustomMediaMessage, FetchDone, isDeleted, blobData } = this.state;
		let messageText = this.getMessageText();

		//linkpreview extensions data
		const linkPreviewData = checkMessageForExtensionsData(this.props.message, "link-preview");
		if (linkPreviewData && linkPreviewData.hasOwnProperty("links") && linkPreviewData["links"].length) {
			messageText = <CometChatLinkPreview message={this.props.message} messageText={messageText} />;
		}

		//messagereactions extensions data
		let messageReactions = null;
		const reactionsData = checkMessageForExtensionsData(this.props.message, "reactions");
		if (reactionsData) {
			if (Object.keys(reactionsData).length) {
				messageReactions = (
					<div css={messageReactionsWrapperStyle()} className="message__reaction__wrapper">
						<CometChatMessageReactions message={this.props.message} actionGenerated={this.props.actionGenerated} />
					</div>
				);
			}
		}

		let toolTipView = null;
		if (this.state.isHovering) {
			toolTipView = <CometChatMessageActions message={this.props.message} actionGenerated={this.actionHandler} />;
		}

		return (
			<div css={messageContainerStyle()} className="sender__message__container message__text message_abcd"
				onMouseEnter={() => this.handleMouseHover(true)} onMouseLeave={() => this.handleMouseHover(false)}
				// id={`message_bubble_${this.props.message.id}`}
				style={{ marginBottom: "10px" }}
				onLoad={this.props.loadedCallback}
			>
				{toolTipView}

				<div style={{ display: "flex", alignItems: "flex-end", alignSelf: "flex-end" }}>
					<div css={messageInfoWrapperStyle()} className="message__info__wrapper">
						<CometChatThreadedMessageReplyCount message={this.props.message} actionGenerated={this.props.actionGenerated} />
						<CometChatReadReceipt message={this.props.message} />
					</div>

					<div css={messageWrapperStyle()} className="message__wrapper" ref={this.messageTextRef}>
						{messageText}
						{/* {!isCustomMediaMessage && messageText} */}
						{/* {isCustomMediaMessage && FetchDone &&
							(
								<div css={messageImgWrapper(this.context)} onClick={this.open} className="message__img__wrapper">
									<img
										src={blobData}
										alt={blobData}
										style={{ boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)" }}
									/>
								</div>
							)}
						{isCustomMediaMessage && !FetchDone &&
							(
								<div css={messageTxtWrapperStyle(this.context)} className="message__txt__wrapper"
									style={{ backgroundColor: "#FFFFFF", color: "rgba(0, 0, 0, 0.87)", boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)" }}>
									<p className="message__txt">
										⚠️ ファイルが削除されました。
									</p>
								</div>
							)} */}
					</div>
				</div>

				{this.props.message?.sender?.uid === this.loggedInUser?.uid &&
					this.props.message.hasOwnProperty("error") &&
					<div style={{ alignSelf: "flex-end", paddingRight: "2px", fontSize: "10px", marginTop: "1px" }}>送信失敗</div>}

				{messageReactions}
			</div>
		);
	}
}

// Specifies the default values for props:
CometChatSenderTextMessageBubble.defaultProps = {
	theme: theme,
	actionGenerated: {},
};

CometChatSenderTextMessageBubble.propTypes = {
	theme: PropTypes.object,
	actionGenerated: PropTypes.func.isRequired,
	message: PropTypes.object.isRequired,
};

export { CometChatSenderTextMessageBubble };