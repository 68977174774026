import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { api } from "../../../../../common/api/api";
import Component from "../../../../Component";
import axios from "axios";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SearchIcon from "@material-ui/icons/Search";

export class DrawingPhoto extends Component {
  drawingsUC = null;
  blob = null;
  alt = "";
  id = null;
  class = null;
  isAddPoint = false;
  source = null;
  ajaxRequest = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      img: null,
      url: "",
      fetching: true
    };
    this.isBuilder = props.isBuilder;
  }

  componentWillReceiveProps(props) {
    if (this.drawingsUC !== props.drawingsUC || this.blob !== props.blob) {
      this.init(props);
      this.fetch();
    }
  }

  componentWillMount() {
    this.init(this.props);
    this.fetch();
  }

  init(props) {
    this.drawingsUC = props.drawingsUC;
    this.blob = props.blob;
    this.alt = props.name === undefined ? "" : props.name;
    this.id = props.id === undefined ? "" : props.id;
  }

  fetch() {
    const classification = this.isBuilder ? "builder" : "supplier";
    var isBlob = this.blob ? true : false;
    var path = isBlob ? this.blob.replace(/^\/api/, '') : `/${classification}/drawings/${this.drawingsUC}/file?bin`;

    // Cancel request if have new request
    if (this.source) {
      this.source.cancel();
    }
    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();
    if (path) {
      api
        .get(path, {
          responseType: isBlob ? undefined : "arraybuffer",
          cancelToken: this.source.token
        })
        .then(res => {
          if (res.data === undefined) return Promise.reject();
          if (isBlob) {
            var url = res.data.blobData;
          } else {
            var blob = new Blob([res.data], { type: "image/jpeg" });
            var url = URL.createObjectURL(blob);
          }
          return url;
        })
        .then(url => this.img(url))
        .then(img => {
          this.setState({ img });
        })
        .catch(e => {
          if (e?.response?.status === 404) {
            this.setState({ img: this.img("") });
          }
        });
    }
  }

  componentWillUnmount() {
    this.ajaxRequest.cancel();
  }

  img(data) {
    return (
      <>
        <img
          draggable={false}
          id={this.id}
          src={data}
          alt={this.alt}
          onDragStart={e => e.preventDefault()}
          onLoad={() => {
            this.setState({ fetching: false });
            this.props.calculateSpotPositions();
          }}
          className={this.props.class}
        />
      </>
    );
  }

  render() {
    const { positions, selectSpot, selectedSpots } = this.props;
    const { fetching, img } = this.state;

    const renderSpot = pos => {
      if (selectedSpots.find(spot => spot.uniqueCode === pos.uniqueCode)) {
        return (
          <span
            style={{
              left: pos["posX"],
              top: pos["posY"],
              width: "1.5em",
              height: "1.5em",
              position: "absolute",
              margin: "-20px -11px",
              transform: "translateY(47%)",
              zIndex: "99",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 124, 255, 0.3)"
            }}
            onClick={() => selectSpot(pos)}
          >
            <span
              style={{
                width: "1.2em",
                height: "1.2em",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 124, 255, 1)",
                fontSize: "1em",
                color: "white"
              }}
            >
              <SearchIcon
                fontSize="inherit"
                color="inherit"
                style={{ lineHeight: "1em" }}
              />
            </span>
          </span>
        );
      }

      return (
        <FiberManualRecordIcon
          stroke={"black"}
          stroke-width={1}
          onClick={() => selectSpot(pos)}
          style={{
            left: pos["posX"],
            top: pos["posY"],
            position: "absolute",
            margin: "-20px -11px",
            fontSize: "1.5em",
            transform: "translateY(47%)",
            zIndex: "99",
            color: pos.hotspotCount > 0 ? "#00fa00" : "#737070"
          }}
        ></FiberManualRecordIcon>
      );
    };

    return (
      <>
        {img}
        {!fetching &&
          positions &&
          positions.map(pos => (
            <div key={pos.uniqueCode}>{renderSpot(pos)}</div>
          ))}
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  type: state.rootSitesReducer.siteInfoReducer.type
});

export default connect(mapStateToProps)(withRouter(DrawingPhoto));
