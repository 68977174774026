import React, { useEffect } from "react";
import IconInfoTemp from "../../../../common/img/info.png";

function CreateInfoMouseIcon(props) {
  const { mousePosition, color } = props;

  useEffect(() => {
    var hotspotInfos = document.querySelectorAll(".info-hotspot-wrapper");
    hotspotInfos.forEach(function (e) {
      e.addEventListener("mouseover", hideHotpostCursor);
    });
    hotspotInfos.forEach(function (e) {
      e.addEventListener("mouseout", displayHotpostCursor);
    });

    var hotspotLinks = document.querySelectorAll(".link-hotspot");
    hotspotLinks.forEach(function (e) {
      e.addEventListener("mouseover", hideHotpostCursor);
    });
    hotspotLinks.forEach(function (e) {
      e.addEventListener("mouseout", displayHotpostCursor);
    });
    var imageViewer = document.querySelector(".image-viewer");

    imageViewer.classList.add("hide-cursor");

    return () => {
      var hotspotInfos = document.querySelectorAll(".info-hotspot-wrapper");
      hotspotInfos.forEach(function (e) {
        e.removeEventListener("mouseover", hideHotpostCursor);
      });
      hotspotInfos.forEach(function (e) {
        e.removeEventListener("mouseout", displayHotpostCursor);
      });

      var hotspotLinks = document.querySelectorAll(".link-hotspot");
      hotspotLinks.forEach(function (e) {
        e.removeEventListener("mouseover", hideHotpostCursor);
      });
      hotspotLinks.forEach(function (e) {
        e.removeEventListener("mouseout", displayHotpostCursor);
      });
      var imageViewer = document.querySelector(".image-viewer");
      imageViewer.classList.remove("hide-cursor");
    };
  }, []);

  const hideHotpostCursor = () => {
    if (document.querySelector(".create-hotspot-cursor")) {
      document.querySelector(".create-hotspot-cursor").style.display = "none";
    }
  };

  const displayHotpostCursor = () => {
    if (document.querySelector(".create-hotspot-cursor")) {
      document
        .querySelector(".create-hotspot-cursor")
        .style.removeProperty("display");
    }
  };

  return (
    <div
      className="create-hotspot-cursor"
      style={{
        left: `${mousePosition.x}px`,
        top: `${mousePosition.y}px`,
        backgroundColor: color
      }}
    >
      <img src={IconInfoTemp} style={{ width: "40px", height: "40px" }} />
    </div>
  );
}

export default CreateInfoMouseIcon;
