import React, { useRef, useState } from "react";

export default class MentionManager {
    constructor(props){
        this.textMessageBefore="";
        this.oldMentionBefore=[];
        this.mentionValue='@';
        this.mentionStart='<span class="chat-box-input-tag-user">@';
        this.mentionEnd1='</span>';
        this.mentionEnd2='</span>&nbsp;';
        this.currentTarget=null;
        this.specialText=[{text:'&',detail:'&amp;'},{text:'<',detail:'&lt;'},{text:'>',detail:'&gt;'}];

    }

    setCaretToEnd =(target/*: HTMLDivElement*/)=> {
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(target);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
        target.focus();
        range.detach(); // optimization
        // set scroll to the end if multiline
        target.scrollTop = target.scrollHeight; 
    }

    createRange=(node, chars, range)=> {
      if (!range) {
          range = document.createRange()
          range.selectNode(node);
          range.setStart(node, 0);
      }
  
      if (chars.count === 0) {
          range.setEnd(node, chars.count);
      } else if (node && chars.count >0) {
          if (node.nodeType === Node.TEXT_NODE) {
              if (node.textContent.length < chars.count) {
                  chars.count -= node.textContent.length;
              } else {
                   range.setEnd(node, chars.count);
                   chars.count = 0;
              }
          } else {
              for (var lp = 0; lp < node.childNodes.length; lp++) {
                  range = this.createRange(node.childNodes[lp], chars, range);
  
                  if (chars.count === 0) {
                     break;
                  }
              }
          }
     } 
  
     return range;
  }
  
  setCurrentCursorPosition(target,chars) {
      if (chars >= 0) {
          var selection = window.getSelection();
        var  range = this.createRange(target.parentNode, { count: chars });
  
          if (range) {
              range.collapse(false);
              selection.removeAllRanges();
              selection.addRange(range);
          }
      }
  };

    setCaretToLocation =(target/*: HTMLDivElement*/,location)=> {
      try{
       
     /*   var range = document.createRange();
        var sel = window.getSelection();
        range.setStart(target.childNodes[location.colume], location.location);
        range.collapse(true);
        
        //range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        target.focus();
        range.detach();
        target.scrollTop = location.total; */

        this.setCurrentCursorPosition(target,location.total);

       /* var range = document.createRange();
        var sel = window.getSelection();
        range.setStart(target.childNodes[location.colume], location.location);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);   
        target.focus();
        range.detach(); // optimization*/
      }
      catch(ie){

      }
       
    }

    callUserTag =(text,mention)=> {
        var meintionValue={is_enable:false,data:""};
        var myArr = text.split(this.mentionValue);
        var string_data =myArr[0];
        for(var i=1;i<(myArr.length-1);i++){
              string_data = string_data+this.mentionValue + myArr[i];
        }
        if(!myArr[myArr.length-1].includes(this.mentionEnd1)){
          string_data = string_data +this.mentionStart + mention.name + this.mentionEnd2;
          meintionValue.is_enable=true;
          meintionValue.data=string_data;
          this.setMessageBefore(string_data);
          this.compairtextChange(string_data);
        }
        return meintionValue;
    }

    callUserTagPosition =(text,mention,location)=> {
      if((location.type!=0)||(!!!mention)||(!!!mention.name)) {
        return {is_enable:false,data:"",lengAdd:0};
      }
     // var realText = this.convertHTmlToAnotherType(text,'@','',''); 
      var realText= this.replaceAllSpecialText(text);
      var textChangeIn= realText.substring(0, location.realStart+location.location);
      var textChangeLast=realText.substring(location.realStart+location.location,text.length);
      var myArr = textChangeIn.split(this.mentionValue);
      var meintionValue={is_enable:false,data:"", posistion:location};
      var myArr = textChangeIn.split(this.mentionValue);
      var string_data =myArr[0];
      for(var i=1;i<(myArr.length-1);i++){
            string_data = string_data+this.mentionValue + myArr[i] ;
      }
      if(!myArr[myArr.length-1].includes(this.mentionEnd1)){
        string_data = string_data +this.mentionStart + mention.name + this.mentionEnd2   +textChangeLast;
        var lengthAdd=mention.name.length-myArr[myArr.length-1].length+1;
        if(myArr.length==1) lengthAdd = mention.name.length+1;
       // lengthAdd++;
        meintionValue.posistion.colume+=2;
        meintionValue.posistion.location =1;
        meintionValue.posistion.type = 0;
        meintionValue.posistion.total  =meintionValue.posistion.total  + lengthAdd;
        meintionValue.posistion.overLocation++;

        meintionValue.is_enable=true;
        meintionValue.data=string_data;
        this.setMessageBefore(string_data);
        this.compairtextChange(string_data);

      }
      return meintionValue;
  }

    checkTextLocationFistCompaire=(textBefore,textAffter)=>{
        for(var i=0;i<textAffter.length;i++){
          if((i<textBefore.length)&&(i<textBefore.length)){
            if((textAffter[i].value!=textBefore[i].value)||(textAffter[i].type!=textBefore[i].type)){
              return i;
            }
          }
        }
        return textAffter.length;
    }
    
    checkTextLocationlastCompaire=(textBefore,textAffter)=>{
        for(var i=textBefore.length-1,j=textAffter.length-1;i<textAffter.length;i--,j--){
          if((i>-1)&&(j>-1)){
            if((textAffter[j].value!=textBefore[i].value)||(textAffter[j].type!=textBefore[i].type)){
              return j;
            }
          }
          else
          {
            return j;
          }
        }
        return 0;
    }

    
    infoSeperate=(stringValue)=>{
        var arr = stringValue.split(this.mentionStart);
        var stringType=[];
        if(arr.length>0){
        // type text 
        stringType.push({type:0,value :arr[0]});
        }
        for(var i=1;i<arr.length;i++)
        {
            if(arr[i].includes(this.mentionEnd1)){
                var endText= arr[i].split(this.mentionEnd1);
                endText= arr[i].split(this.mentionEnd1);
                if(endText.length==1){
                    stringType.push({type:1,value :endText[0]});
                }
                else if(endText.length==0)
                {
                    stringType.push({type:1,value :""});
                }
                else  if(endText.length>1)
                {
                    stringType.push({type:1,value :endText[0]});
                    var newString="";
                    for(var j=1;j<endText.length;j++){
                        if(j>1) {
                            newString += this.mentionEnd1;
                        }
                        newString += endText[j];
                    }
                    stringType.push({type:0,value :newString});
                }
            }
            else
            {
                stringType.push({type:0,value :arr[i]});
            }
        }

        return stringType;
    }

    replaceAllSpecialText=(text)=>{
      var newText= text.replaceAll('&nbsp;',' '); 
      for(var i=0;i<this.specialText.length;i++){
        newText= newText.replaceAll(this.specialText[i].detail,this.specialText[i].text); 
      }
      return newText;
    }

    setFormRuleToHtml=(arrayData)=>{
        var stringData="";
        for(var i=0;i<arrayData.length;i++){
          if(arrayData[i].type==0){
            stringData += arrayData[i].value;
          } else  if(arrayData[i].type==1){
            stringData +=(this.mentionStart +arrayData[i].value + this.mentionEnd1);
          }
        }
        this.oldMentionBefore=arrayData;
        return stringData;
    }

    checkMeintionView=(value,allMentions)=>{
        var mention={is_enable:false,data:[]};
        var myArr = value.split(this.mentionValue);
        //console.log("checkMeintionView",myArr);
        if(myArr.length>1){
            var textValue=myArr[myArr.length-1];
            var data = allMentions.filter(o=>o.name.toUpperCase().includes(textValue.toUpperCase()));
           // console.log("data",data);
            if(data.length>0){
              var textValueBefore=myArr[myArr.length-2];
              if((textValueBefore.length)>0){
                var charctureCompaire=textValueBefore.charAt(textValueBefore.length-1)
                if((charctureCompaire==' ')||(charctureCompaire==this.mentionValue)||(charctureCompaire==';')){
                  mention.is_enable=true;
                  mention.data=data;
                }
              }
              else
              {
                mention.is_enable=true;
                mention.data=data;
              }
            }
        }
        return mention;
    }


    

    checkMeintionViewLocation=(value,allMentions,location)=>{
      if(location.type!=0) {
        return {is_enable:false,data:[]};
      }
      var realText = this.replaceAllSpecialText(this.convertHTmlToAnotherType(value,'@','','')); 
      var textChangeIn=realText.substring(0, location.total);
      var mention={is_enable:false,data:[]};
      var myArr = textChangeIn.split(this.mentionValue);
      //console.log("checkMeintionView",myArr);
      if(myArr.length>1){
          var textValue=myArr[myArr.length-1];
     //     console.log("checkMeintionViewLocation .textValue...",textValue.length,textValue);
          var data = allMentions.filter(o=>o.name.toUpperCase().includes(textValue.toUpperCase()));
          if(location.lastType>0){
            mention.is_enable=true;
            mention.data=data;
          }
          if(data.length>0){
            var textValueBefore=myArr[myArr.length-2];
            if((textValueBefore.length)>0){
              var charctureCompaire=textValueBefore.charAt(textValueBefore.length-1);
              if((charctureCompaire==' ')||(charctureCompaire==this.mentionValue)||(charctureCompaire==';')){
                mention.is_enable=true;
                mention.data=data;
              }
            }
            else
            {
              mention.is_enable=true;
              mention.data=data;
            }
          }
      }
      return mention;
  }

    
   compairtextChange=(textAffter)=>{
        var arrTextBefore =  this.infoSeperate(this.getMessageBefore());// textBefore.split("@");
        var arrTextAffter = this.infoSeperate(textAffter); //textAffter.split("@");
        var locationFistChange=this.checkTextLocationFistCompaire(arrTextBefore,arrTextAffter);
        var locationLastChange=this.checkTextLocationlastCompaire(arrTextBefore,arrTextAffter);
        if(locationFistChange==arrTextAffter.length){
          return this.setFormRuleToHtml(arrTextAffter);
        }
        // if it is have same loacation
        if(locationFistChange==locationLastChange){
        let valueBefore=arrTextBefore[locationFistChange];
        let valueAffter=arrTextAffter[locationFistChange];
        if((valueBefore.type==0)&&(valueAffter.type==0)){
            // return text
            return this.setFormRuleToHtml(arrTextAffter);
        }
         else if((valueBefore.type==1)&&(valueAffter.type==1)){
            // return text
            if(valueBefore.value!=valueAffter.value){
              valueAffter.type=0
              valueAffter.value=this.mentionValue+valueAffter.value;
            } 
            return this.setFormRuleToHtml(arrTextAffter);
        }
        else 
            return this.setFormRuleToHtml(arrTextAffter);
        }
        else if(locationFistChange<locationLastChange)
        { // txt 1,text 2 ... text edit ... text n
        // this is action add text or coppy paste
        for(var i=locationFistChange;i<locationLastChange;i++){
            let valueAffter=arrTextAffter[i];
            if(valueAffter.type>0){
              valueAffter.type=0
              valueAffter.value=this.mentionValue+valueAffter.value;
            } 
        }
        return this.setFormRuleToHtml(arrTextAffter);
        }
        else{
          let valueAffter=arrTextAffter[locationFistChange];
          valueAffter.type=0
          valueAffter.value=this.mentionValue+valueAffter.value;
          return this.setFormRuleToHtml(arrTextAffter);
        }
    }

    selectLastMention=()=>{
        if (window.getSelection) {
            var selection = window.getSelection(),
                range = selection.getRangeAt(0),
               // br = document.createElement("br"),
                textNode = document.createTextNode("\u00a0"); //Passing " " directly will not end up being shown correctly
            range.deleteContents();//required or not?
           // range.insertNode(br);
            range.collapse(false);
            range.insertNode(textNode);
            range.selectNodeContents(textNode);
      
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }

    setMessageBefore=(text)=>{

        this.textMessageBefore=text;
    }

    getMessageBefore=()=>{
       return this.textMessageBefore;
    }
    // convert text box mention to another type
     // sample stringValue= <span class="chat-box-input-tag-user">@現場参加のメンバー全員</span>&nbsp;SDASDJ <span class="chat-box-input-tag-user">@現場参加のメンバー全員</span>&nbsp;s
     // data= convertHTmlToAnotherType(stringValue,'@',"<btm>","</btm>") = 
     // data =<btm>@現場参加のメンバー全員</btm>SDASDJ <btm>@現場参加のメンバー全員<btm/> s
    convertHTmlToAnotherType(stringValue,mention,startMeinTion,EndMeinTion){
        var arrayData=this.infoSeperate(stringValue);
        var stringData="";
        for(var i=0;i<arrayData.length;i++){
          if(arrayData[i].type==0){
            stringData +=arrayData[i].value;
          } else  if(arrayData[i].type==1){
            stringData +=(startMeinTion+mention +arrayData[i].value +EndMeinTion);
          }
        }
        return stringData;
    }

    sortDataArray=(data)=>{
      var dataOut=[];
      if(data.length>0){
        dataOut.push(data[0]);
        for(var i=1;i<data.length;i++){
          if(dataOut[dataOut.length-1].type==0){
            if(data[i].type==0){
              dataOut[dataOut.length-1].value  = dataOut[dataOut.length-1].value+ data[i].value;
            }
            else
            {
              dataOut.push(data[i]);
            }
          }
          else
          {
            dataOut.push(data[i]);
          }
        }
      }
      return dataOut;
    }

    getCaretPosition=(element)=>{
      var caretOffset = 0;
      var doc = element.ownerDocument || element.document;
      var win = doc.defaultView || doc.parentWindow;
      var sel;
      var locationDetail={colume:0,location:0,total:0,detail:null,type:0 ,overLocation:0,realStart:0,lastType:0};
      if (typeof win.getSelection != "undefined") {
          sel = win.getSelection();
          if (sel.rangeCount > 0) {
              var range = win.getSelection().getRangeAt(0);
              var preCaretRange = range.cloneRange();
              preCaretRange.selectNodeContents(element);
              preCaretRange.setEnd(range.endContainer, range.endOffset);
              caretOffset = preCaretRange.toString().length;
              locationDetail.detail=preCaretRange;
          }
      } else if ( (sel = doc.selection) && sel.type != "Control") {
          var textRange = sel.createRange();
          var preCaretTextRange = doc.body.createTextRange();
          preCaretTextRange.moveToElementText(element);
          preCaretTextRange.setEndPoint("EndToEnd", textRange);
          caretOffset = preCaretTextRange.text.length;
          locationDetail.detail=preCaretTextRange;
      }
      locationDetail.total=caretOffset;
      locationDetail.location=0;
      locationDetail.realStart=0;
      var locationExacly= caretOffset;
      var _oldMentionBefore =this.sortDataArray(this.oldMentionBefore);
      var lastType=0;
      for(var i=0;i<_oldMentionBefore.length;i++){
        locationDetail.colume =i;
        locationDetail.type = _oldMentionBefore[i].type;
        if(_oldMentionBefore[i].type>0){
          locationDetail.overLocation +=1;
        }
        var length= this.replaceAllSpecialText(_oldMentionBefore[i].value).length;
        if(_oldMentionBefore[i].type>0) length +=1;
        if(locationExacly>length){
          locationExacly=locationExacly-length;
         // console.log("locationExacly 1",locationExacly);
        }
        else{
          locationDetail.location=locationExacly;
          if(_oldMentionBefore[i].value.length>1)  locationDetail.lastType =0;
          return locationDetail;
        }
        lastType= _oldMentionBefore[i].type;
        locationDetail.lastType = lastType;
        if(lastType>0){
          locationDetail.realStart +=(length+this.mentionStart.length+ this.mentionEnd1.length-1);
        }
        else 
        {
          locationDetail.realStart +=length;
        }
        
      }
      return locationDetail;
    }

    callUserTagTarget =(mention,target)=> {
      var posistion = this.getCaretPosition(target);
      var html = target.innerHTML;
      var mentionValue=this.callUserTagPosition(html,mention,posistion);
      //callUserTagPosition
      if(mentionValue.is_enable){
        target.innerHTML = mentionValue.data;
        this.compairtextChange( mentionValue.data);
        target.innerHTML=mentionValue.data;
        this.setCaretToLocation(target,mentionValue.posistion);
      }
      return mentionValue;
    }

    checkFormDataView=(value,target,allMentions)=>{
      var textMessageData =this.compairtextChange(value);
      var posistion = this.getCaretPosition(target);
      target.innerHTML=textMessageData;
      
      this.setMessageBefore(textMessageData);
      var checMeintion =this.checkMeintionViewLocation(textMessageData,allMentions,posistion);
      if(textMessageData.length!=0)this.setCaretToLocation(target,posistion);
      return checMeintion;
    }

    checkFormDataMeintionChange=(target,allMentions)=>{
      var textMessageData = target.innerHTML
      var posistion = this.getCaretPosition(target);
      var checMeintion =this.checkMeintionViewLocation(textMessageData,allMentions,posistion);
      return checMeintion;
    }

    checkDataByEventUnexpectedBefore=(target)=>{
      var posistion = this.getCaretPosition(target);
      var textMessageData = this.compairtextChange(target.innerHTML)
      target.innerHTML=textMessageData;
      this.setCaretToLocation(target,posistion);
    }


    eventPasteText=(e)=>{
      e.preventDefault();
      // Get the copied text from the clipboard
      const text = ((e.clipboardData)
          ? (e.originalEvent || e).clipboardData.getData('text/plain')
          // For IE
          : (window.clipboardData ? window.clipboardData.getData('Text') : ''))
          .replaceAll('<div>','').replaceAll('<br>','').replaceAll('</div>','')
          .replace(/(\r\n|\n|\r)/gm, "");;
      if (document.queryCommandSupported('insertText')) {
          document.execCommand('insertText', false, text);
      } else {
          // Insert text at the current position of caret
          const range = document.getSelection().getRangeAt(0);
          range.deleteContents();
  
          const textNode = document.createTextNode(text);
          range.insertNode(textNode);
          range.selectNodeContents(textNode);
          range.collapse(false);
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
      }
    }

    eventCutText=(event)=>{
      const selection = document.getSelection();
      event.clipboardData.setData('text/plain', selection.toString().toUpperCase());
      selection.deleteFromDocument();
      event.preventDefault();
    }
    
    getSelectionTextInfo=(el)=> {
      var atStart = false, atEnd = false;
      var selRange, testRange;
      if (window.getSelection) {
          var sel = window.getSelection();
          if (sel.rangeCount) {
              selRange = sel.getRangeAt(0);
              testRange = selRange.cloneRange();
  
              testRange.selectNodeContents(el);
              testRange.setEnd(selRange.startContainer, selRange.startOffset);
              atStart = (testRange.toString() == "");
  
              testRange.selectNodeContents(el);
              testRange.setStart(selRange.endContainer, selRange.endOffset);
              atEnd = (testRange.toString() == "");
          }
      } 
      else if (document.selection && document.selection.type != "Control") {
          selRange = document.selection.createRange();
          testRange = selRange.duplicate();
          
          testRange.moveToElementText(el);
          testRange.setEndPoint("EndToStart", selRange);
          atStart = (testRange.text == "");
  
          testRange.moveToElementText(el);
          testRange.setEndPoint("StartToEnd", selRange);
          atEnd = (testRange.text == "");
      }
      return { atStart: atStart, atEnd: atEnd };
    }

};


