import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { api } from "../../../../../../common/api/api";
import { useLocation } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import { format } from "date-fns";
import LazyLoad from "react-lazyload";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Photo from "./Photo";

const useStyles = makeStyles({
  checkCircle: {
    borderRadius: "50%",
    overflow: "hidden",
    display: "block",
    marginTop: "2px",
    marginRight: "2px"
  }
});

function ChooseExistedPhotoTab(props) {
  const {
    sitesUC,
    selectedPhotos,
    selectPhoto,
    excludeSelects,
    isTabletOrMobile
  } = props;

  const [loading, setLoading] = useState(false);
  const [allPhotoInfos, setAllPhotoInfos] = useState([]);

  const location = useLocation();
  const classes = useStyles();
  useEffect(() => {
    loadAllPhotoInfos();
  }, []);

  const loadAllPhotoInfos = () => {
    setLoading(true);
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    api
      .get(`/${classification}/site-photos?sitesUC=${sitesUC}`)
      .then(res => {
        setAllPhotoInfos(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {loading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : allPhotoInfos.length !== 0 ? (
        allPhotoInfos.map(datePhotos => (
          <div
            style={{ width: "100%", display: "block" }}
            key={datePhotos.date}
          >
            <Typography className="image-cap" variant="h6">
              {format(new Date(datePhotos.date), "yyyy年M月d日")}
            </Typography>
            <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
              {datePhotos.photos.map(photoInfo => (
                <div
                  key={photoInfo.uniqueCode}
                  style={{
                    width: isTabletOrMobile
                      ? "calc(100% / 3)"
                      : "calc(100% / 6)",
                    cursor: "pointer",
                    display: "block"
                  }}
                  onClick={() => selectPhoto({...photoInfo, regisionDate: datePhotos.date})}
                >
                  <div
                    style={{
                      width: "100%",
                      paddingTop: "75%",
                      position: "relative"
                    }}
                    
                  >
                    <LazyLoad overflow offset={100} keyzz={photoInfo.photoId}>
                      <Photo photoInfo={photoInfo} />
                      <span
                        style={{
                          position: "absolute",
                          top: isTabletOrMobile ? "2px" : "6px",
                          right: isTabletOrMobile ? "2px" : "6px",
                          padding: "0",
                          lineHeight: isTabletOrMobile ? "24px" : "30px",
                          fontSize: isTabletOrMobile ? "24px" : "30px"
                        }}
                      >
                        {excludeSelects.find(
                          item =>
                            item.uniqueCode === photoInfo.photoId ||
                            item.photoId === photoInfo.photoId
                        ) ? (
                          <span
                            className={classes.checkCircle}
                            style={{
                              color: "#D0CECE",
                              width: isTabletOrMobile ? "20px" : "26px",
                              height: isTabletOrMobile ? "20px" : "26px",
                              backgroundColor: "white"
                            }}
                          >
                            <CheckCircleIcon
                              fontSize="inherit"
                              color="inherit"
                              style={{
                                margin: "-2px 0px 0px -2px"
                              }}
                            />
                          </span>
                        ) : selectedPhotos.find(
                            item => item.photoId === photoInfo.photoId
                          ) ? (
                          <span
                            className={classes.checkCircle}
                            style={{
                              color: "#92D050",
                              width: isTabletOrMobile ? "20px" : "26px",
                              height: isTabletOrMobile ? "20px" : "26px",
                              backgroundColor: "white"
                            }}
                          >
                            <CheckCircleIcon
                              fontSize="inherit"
                              color="inherit"
                              style={{
                                margin: "-2px 0px 0px -2px"
                              }}
                            />
                          </span>
                        ) : (
                          <span
                            className={classes.checkCircle}
                            style={{
                              color: "#FFF",
                              width: isTabletOrMobile ? "20px" : "26px",
                              height: isTabletOrMobile ? "20px" : "26px",
                              boxShadow:
                                "rgba(0, 0, 0, 0.6) 0px 0px 5px 0px, rgba(0, 0, 0, 0.6) 0px 0px 1px 0px"
                            }}
                          >
                            <RadioButtonUncheckedIcon
                              fontSize="inherit"
                              color="inherit"
                              style={{
                                margin: "-2px 0px 0px -2px"
                              }}
                            />
                          </span>
                        )}
                      </span>
                    </LazyLoad>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <Box sx={{ display: "flex" }}>
          <span>写真はありません</span>
        </Box>
      )}
    </div>
  );
}

export default ChooseExistedPhotoTab;
