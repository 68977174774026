import { combineReducers } from "redux";
import {sitesReducer} from './sites';
import {siteInfoReducer} from '../site/reducer/reducer';
import {logWalkViewReducer} from '../site/LogWalkViewer/reducer';
const rootSitesReducer = combineReducers({
    sitesReducer: sitesReducer,
    siteInfoReducer: siteInfoReducer,
    logWalkViewReducer: logWalkViewReducer,
});
export default rootSitesReducer;
