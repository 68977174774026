import React from "react";

function IconUploadFile(props) {
  const { isActive, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
    >
      <defs>
        <filter
          id="a"
          width="48"
          height="48"
          x="0"
          y="0"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3"></feOffset>
          <feGaussianBlur result="b" stdDeviation="3"></feGaussianBlur>
          <feFlood floodOpacity="0.161"></feFlood>
          <feComposite in2="b" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
      </defs>
      <g filter="url(#a)" transform="translate(-8630 -788) translate(8630 788)">
        <path fill="none" d="M0 0H30V30H0z" transform="translate(9 6)"></path>
      </g>
      <g stroke={isActive ? "#fff" : "#333"}>
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M8671.964 806.809v12.67h23.7v-12.67"
          transform="translate(-8630 -788) translate(-29.686 -.108)"
        ></path>
        <path
          fill={isActive ? "#fff" : "#333"}
          d="M8683.601 798.21l-4.675 4.675a.551.551 0 10.778.778l3.647-3.647v12.695a.551.551 0 101.102 0v-12.87l3.818 3.819a.551.551 0 10.778-.778l-4.67-4.67a.551.551 0 00-.778-.002z"
          transform="translate(-8630 -788) translate(-29.686 -.108)"
        ></path>
      </g>
    </svg>
  );
}

IconUploadFile.defaultProps = {
  size: "40"
}

export default IconUploadFile;
