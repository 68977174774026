export const FETCH_BUILDER_DETAIL_SUCCESSS = "FETCH_BUILDER_DETAIL_SUCCESSS";

export const FETCH_BUILDER_ENTRIES_SUCCESS = "FETCH_BUILDER_ENTRIES_SUCCESS";

export const FETCH_BUILDER_SUMMARY_SUCCESSS = "FETCH_BUILDER_SUMMARY_SUCCESSS";

export const FETCH_BUILDER_SUCCESS = "FETCH_BUILDER_SUCCESS";

export const PUT_LOGBUILD_BUIDLER_SUCCESS = "PUT_LOGBUILD_BUIDLER_SUCCESS";

export const DELETE_LOGBUILD_BUIDLER_SUCCESS =
  "DELETE_LOGBUILD_BUIDLER_SUCCESS";

export const FETCH_ROBOT_ENTRIES_SUCCESS = "FETCH_ROBOT_ENTRIES_SUCCESS";

export const FETCH_STAFF_ENTRIES_SUCCESS = "FETCH_STAFF_ENTRIES_SUCCESS";

export const FETCH_BUILDER_ROBOT_LEASES_SUCCESS =
  "FETCH_BUILDER_ROBOT_LEASES_SUCCESS";

export const POST_BUILDER_ROBOT_LEASES_SUCCESS =
  "POST_BUILDER_ROBOT_LEASES_SUCCESS";

export const FETCH_ROBOT_LEASES_SUCCESS = "FETCH_ROBOT_LEASES_SUCCESS";

export const PUT_ROBOT_LEASES_SUCCESS = "PUT_ROBOT_LEASES_SUCCESS";

export const FETCH_ROBOT_ENTRIES_CREATE_SUCCESS =
  "FETCH_ROBOT_ENTRIES_CREATE_SUCCESS";

export const FETCH_ROBOT_ENTRIES_EDIT_SUCCESS =
  "FETCH_ROBOT_ENTRIES_EDIT_SUCCESS";

export const DELETE_ROBOT_LEASE_SUCCESS = "DELETE_ROBOT_LEASE_SUCCESS";

export const FETCH_AGREEMENT_LOGS_SUCCESS = "FETCH_AGREEMENT_LOGS_SUCCESS";

export const POST_AGREEMENT_LOGS_SUCCESS = "POST_AGREEMENT_LOGS_SUCCESS";

export const PUT_AGREEMENT_LOGS_SUCCESS = "PUT_AGREEMENT_LOGS_SUCCESS";

export const DELETE_AGREEMENT_LOGS_SUCCESS = "DELETE_AGREEMENT_LOGS_SUCCESS";

export const FETCH_AGREEMENT_LOGS_DETAIL_SUCCESS =
  "FETCH_AGREEMENT_LOGS_DETAIL_SUCCESS";

export const FETCH_BUILDER_FEATURE_FLAG_SUCCESS = "FETCH_BUILDER_FEATURE_FLAG_SUCCESS";

export const PUT_BUILDER_FEATURE_FLAG_SUCCESS = "PUT_BUILDER_FEATURE_FLAG_SUCCESS";
