import React, { Component } from "react";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RobotInfoBuilderSP from "./RobotInfo/RobotInfoBuilderSP";
import RobotHistorySP from "./RobotHistory/RobotHistorySP";
import ButtonRobot from "../../../common/ButtonRobot";
import ButtonReturn from "../../../common/ButtonReturn";
import "../../../common/css/base.css";
import "../../../common/css/log.css";
import RobotStatus from "common/RobotStatus";
import { BUTTON_LOGKUN_SP, ICON_LOGKUN } from "common/constants/constants";

class RobotDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  render() {
    const showPopover = e => {
      this.setState({
        anchorEl: e.currentTarget
      });
    };

    const closePopover = () => {
      this.setState({
        anchorEl: null
      });
    };

    return (
      <>
        <Grid
          container
          justify="space-between"
          wrap="nowrap"
          style={{ padding: "0 15px", marginTop: 20 }}
        >
          <Grid className="item-left">
            <Typography
              variant="h5"
              className="text-bold font-family white-space-normal"
            >
              <RobotStatus
                typeOfRobotStatus={ICON_LOGKUN}
                robotsUpdate={[this.props.robotsUpdate]}
                robots={[this.props.robotDetails]}
                noText={true}
              />

              {/* <div
                className={`status ${
                  this.props.robotDetails.useStatus === 1
                    ? "status-green"
                    : "status-yellow"
                }`}
                style={{ display: "inline-block" }}
              /> */}
              {this.props.robotDetails.robotName}
            </Typography>
          </Grid>
          <Grid className="item-right">
            <Fab onClick={showPopover} size="small" className="more-vert-btn">
              <MoreVertIcon />
            </Fab>
          </Grid>
        </Grid>
        <Grid container wrap="nowrap" className="log">
          <Grid className="tab-content">
            <Grid container className="sites-image-sp pd-15">
              <Tabs
                className="tabs"
                value={this.props.tabValue}
                onChange={this.props.tabChange}
                aria-label="simple tabs example"
              >
                <Tab
                  className="tab-button"
                  label="ロボット情報"
                  value="ロボット情報"
                  style={{ width: "50%" }}
                />
                <Tab
                  className="tab-button"
                  label="現場割当履歴"
                  value="現場割当履歴"
                  style={{ width: "50%" }}
                />
              </Tabs>
              {this.props.tabValue === "ロボット情報" ? (
                <RobotInfoBuilderSP
                  robotsUpdate={[this.props.robotsUpdate]}
                  robotInfo={this.props.robotInfo}
                />
              ) : (
                <RobotHistorySP robotHistory={this.props.robotHistory} />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Popover
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuList onClick={closePopover}>
            <MenuItem onClick={this.props.showEditForm}>編集</MenuItem>
            {/* NOTE(knm): hid "logbuildに問い合わせる" for the 1st release, it will be shown after setting inquiry flow.@2021/02/02 */}
            {/* <MenuItem onClick={this.props.showDialogContactRepair}>logbuildに問い合わせる</MenuItem> */}
            {/* NOTE(knm): hid "返却" for the 1st release.@2021/02/02 */}
            {/* <MenuItem onClick={this.props.toggleRequestDialog} style={{color: 'rgba(242, 39, 53, 1)'}}>返却</MenuItem> */}
          </MenuList>
        </Popover>

        <ButtonReturn onClick={this.props.onClickButtonReturn} />

        {/* <ButtonRobot
          robot={this.props.robotDetails}
          className="fab-button-sp"
          onClick={this.props.onClickButtonRobot}
        /> */}

        <RobotStatus
          typeOfRobotStatus={BUTTON_LOGKUN_SP}
          robotsUpdate={[this.props.robotsUpdate]}
          robots={[this.props.robotDetails]}
          staticPosition={true}
          forceCreateUserAcknowledgement={true}
        />
      </>
    );
  }
}

export default RobotDetail;
