import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser } from "./components/auth/action/action";
import { logoutUser } from "./components/auth/service/service";

import { Provider } from "react-redux";
import store from "./common/config/store/store";

import Landing from "./common/layout/Landing";
import Register from "./components/auth/Register";
import SitesIndex from "./components/sites/Index";
import SiteIndex from "./components/sites/site/Index";
import SiteSP from "./components/sites/site/siteSP/indexSP";
import SiteForm from "./components/sites/site/Form";
import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import LoginSP from "./components/auth/LoginSP";
import ForgotPasswordSP from "./components/auth/ForgotPasswordSP";
import LoginLogBuild from "./components/auth/LoginLogBuild";
import ForgotPasswordLogBuild from "./components/auth/ForgotPasswordLogBuild";
import PrivateRoute from "./common/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import DashboardSP from "./components/dashboard/DashboardSP";
import Staft from "./components/users/Staff/Staft";
import Carpenter from "./components/users/Carpenter/Carpenter";
import Robot from "./components/robots/Robot";
import RobotLogBuild from "./components/robots/RobotLogBuild";
import RobotDetail from "./components/robots/robotDetails/RobotDetail";
import RobotlogBuildDetail from "./components/robots/robotDetails/RobotlogBuildDetail";
import Profile from "./components/profile/Profile";
import ErrorPage from "./components/error/Index";
import Shop from "./components/shops";
import Feature from "./components/features";
import StaffLogBuild from "./components/users/StaffLogBuild/StaffLogBuild";

import ShopDetail from "./components/shops/shopDetail/Index";
import DashboardLogbuild from "components/dashboard/DashboardLogbuild";
import SignUp from "./components/auth/SignUp";
import LogWalkViewer from "./components/sites/site/LogWalkViewer/LogWalkViewer";
import RedirectLogWalkViewer from "./components/sites/site/RedirectLogWalkViewer";
import ViewUnAdjustedImage from "./components/sites/site/LogWalkViewer/ViewUnAdjustedImage";
import LogWalkLp from "./components/logwalk/Lp";
import LogWalkSpLp from "./components/logwalk/sp/Lp";
import ViewLogWalkImage from "./components/sites/site/LogWalkViewer/ViewLogWalkImage";
import ShareUrlPage from "components/shareUrlPage/ShareUrlPage";

import Settings from "./components/settings/Settings";
import ListInfoHotspots from "./components/infoHotspots/ListInfoHotspots";
import ListShareUrls from "./components/shareUrls/ListShareUrls";

import FixSpots from "components/fixSpots/fixSpotsPage/FixSpots";
import ListSiteFixSpots from "components/fixSpots/ListSiteFixSpots";
import ListRecordFixSpots from "components/fixSpots/ListRecordFixSpots";

import LogMeet from "./components/call-history/LogMeet";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);

  // Decode token and get user info and exp
  const decoded = jwt_decode(token);

  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds

  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}
if (sessionStorage.jwtToken) {
  // Set auth token header auth
  const token = sessionStorage.jwtToken;
  setAuthToken(token);

  // Decode token and get user info and exp
  const decoded = jwt_decode(token);

  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds

  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/login" component={Login} />
              <Route path="/sp/login" component={LoginSP} />
              <Route path="/sp/forgot-password" component={ForgotPasswordSP} />
              <Route path="/logbuild/login" component={LoginLogBuild} />
              <Route exact path="/logwalk/lp" component={LogWalkLp} />
              <Route exact path="/sp/logwalk/lp" component={LogWalkSpLp} />
              <Route
                exact
                path="/logbuild/forgot-password"
                component={ForgotPasswordLogBuild}
              />
              <Route
                exact
                path="/url-share/:buildersUC/:id"
                isNoHeader={true}
                titlePage="share-vr-space"
                component={ShareUrlPage}
              />
              <PrivateRoute
                exact
                path="/logwalk-viewer/spots/:spotsUC"
                isNoHeader={true}
                component={RedirectLogWalkViewer}
              />
              <PrivateRoute
                exact
                path="/sp/logwalk-viewer/spots/:spotsUC"
                isNoHeader={true}
                component={RedirectLogWalkViewer}
              />
              <PrivateRoute
                exact
                path="/dashboard"
                titlePage="担当現場"
                component={Dashboard}
              />
              <PrivateRoute
                exact
                path="/sp/dashboard"
                isHeaderSP={true}
                component={DashboardSP}
              />
              <PrivateRoute
                exact
                path="/sites"
                titlePage="全ての現場"
                component={SitesIndex}
              />
              <PrivateRoute
                exact
                path="/call-history"
                titlePage="自分の通話履歴"
                component={LogMeet}
              />
              <PrivateRoute
                exact
                path="/sites/create"
                titlePage="全ての現場"
                component={SiteForm}
              />
              <PrivateRoute
                exact
                path="/sites/:id"
                titlePage="現場詳細"
                component={SiteIndex}
              />
              <PrivateRoute
                exact
                path="/sp/sites/:id"
                isHeaderSP={true}
                component={SiteSP}
              />
              <PrivateRoute
                exact
                path="/sites/:id/edit"
                titlePage="現場詳細"
                component={SiteForm}
              />
              <PrivateRoute
                exact
                path="/logwalk-viewer/view-image/shoots/:id"
                isNoHeader={true}
                titlePage="logwalk-viewer-image"
                component={ViewLogWalkImage}
              />
              <PrivateRoute
                exact
                path="sp/logwalk-viewer/view-image/shoots/:id"
                isNoHeader={true}
                titlePage="logwalk-viewer-image"
                component={ViewLogWalkImage}
              />
              <PrivateRoute
                exact
                path="/logwalk-viewer/shoots/:id"
                isNoHeader={true}
                titlePage="logwalk-viewer"
                component={LogWalkViewer}
              />
              <PrivateRoute
                exact
                path="/sp/logwalk-viewer/shoots/:id"
                isNoHeader={true}
                titlePage="logwalk-viewer"
                isLogwalkViewerSP={true}
                component={LogWalkViewer}
              />
              <PrivateRoute
                exact
                path="/logwalk-viewer/sites/:id/spots/null"
                isNoHeader={true}
                titlePage="logwalk-viewer"
                component={ViewUnAdjustedImage}
              />
              <PrivateRoute
                exact
                path="/sp/logwalk-viewer/sites/:id/spots/null"
                isNoHeader={true}
                titlePage="logwalk-viewer"
                component={ViewUnAdjustedImage}
              />
              <PrivateRoute
                exact
                path="/employees"
                titlePage="社員の管理"
                component={Staft}
              />
              <PrivateRoute
                exact
                path="/workers"
                titlePage="職人の管理"
                component={Carpenter}
              />
              <PrivateRoute
                exact
                path="/robots"
                titlePage="ロボットの管理"
                component={Robot}
              />
              <PrivateRoute
                exact
                path="/settings"
                titlePage="各種設定"
                component={Settings}
              />
              <PrivateRoute
                exact
                path="/info-hotspots"
                titlePage="タスク一覧"
                component={ListInfoHotspots}
              />
              <PrivateRoute
                exact
                path="/share-urls"
                titlePage="URL共有一覧"
                component={ListShareUrls}
              />
              <PrivateRoute
                exact
                path="/logbuild/robots"
                titlePage="ロボットの管理"
                isHeaderLogBuild={true}
                component={RobotLogBuild}
              />
              <PrivateRoute
                exact
                path="/robots/:id"
                titlePage="ロボットの管理"
                component={RobotDetail}
              />
              <PrivateRoute
                exact
                path="/logbuild/robots/:id"
                titlePage="ロボットの管理"
                isHeaderLogBuild={true}
                component={RobotlogBuildDetail}
              />
              <PrivateRoute
                exact
                path="/mypage/profile"
                titlePage="プロフィール情報"
                component={Profile}
              />
              <PrivateRoute
                exact
                path="/mypage/password"
                titlePage="パスワード変更"
                component={Profile}
              />
              <PrivateRoute
                exact
                path="/licence"
                titlePage="法律に基づく情報"
                component={Profile}
              />
              <PrivateRoute
                exact
                path="/mypage/logout"
                titlePage=""
                component={Profile}
              />
              <PrivateRoute
                exact
                path="/logbuild/mypage/password"
                titlePage="パスワード変更"
                isHeaderLogBuild={true}
                component={Profile}
              />
              <PrivateRoute
                exact
                path="/logbuild/mypage/profile"
                titlePage="プロフィール情報"
                isHeaderLogBuild={true}
                component={Profile}
              />
              <PrivateRoute
                exact
                path="/logbuild/mypage/logout"
                titlePage=""
                isHeaderLogBuild={true}
                component={Profile}
              />
              <PrivateRoute
                exact
                path="/logbuild/builders"
                isHeaderLogBuild={true}
                component={Shop}
                titlePage="顧客の管理​"
              />
              <PrivateRoute
                exact
                path="/logbuild/features"
                isHeaderLogBuild={true}
                component={Feature}
                titlePage="フィーチャーフラグ​の管理​"
              />
              <PrivateRoute
                exact
                path="/logbuild/employees"
                titlePage="社員の管理"
                isHeaderLogBuild={true}
                component={StaffLogBuild}
              />

              <PrivateRoute
                exact
                path="/logbuild/builders/:id"
                titlePage="顧客の管理"
                isHeaderLogBuild={true}
                component={ShopDetail}
              />

              <PrivateRoute
                exact
                path="/logbuild/dashboard"
                titlePage="ダッシュボード"
                isHeaderLogBuild={true}
                component={DashboardLogbuild}
              />
              <PrivateRoute
                exact
                path="/:id/signup"
                component={SignUp}
                noRedirect={true}
              />
              <PrivateRoute
                exact
                path="/fix-spots"
                titlePage="地点情報の修復"
                component={ListSiteFixSpots}
                isFixSpot={true}
              />
              <PrivateRoute
                exact
                path="/fix-spots/sites/:id"
                titlePage="地点情報の修復"
                component={ListRecordFixSpots}
                isFixSpot={true}
              />
              <PrivateRoute
                exact
                path="/fix-spots/records/:id"
                isNoHeader={true}
                component={FixSpots}
              />

              <PrivateRoute component={ErrorPage} />
              <PrivateRoute exact path="/not-found" component={ErrorPage} />
              <PrivateRoute exact path="/sp/not-found" component={ErrorPage} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
