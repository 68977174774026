import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import CommonDialog from "../../CommonDialog";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";
import DialogDelete from "../../../common/DialogDelete";
import { checkAuth } from "../../../common/Stores/service/service";
import { fetchAuths } from "../../../common/Select-Auth/service/service";
import { getAuths } from "../../../common/Select-Auth/reducer/reducer";
import { getJobs, getStaffsDetail, getUserDetail } from "./reducer/reducer";
import {
  addStaff,
  updateStaff,
  deleteStaff,
  fetchJob,
  fetchStaffDetail
} from "./service/service";
import { getStaffsDetailSuccess as fillStaffDetailSuccess } from "./action/action";
import { apiError } from "../../../common/Stores/action/action";
import { translateMessage, trimObjValues } from "utils/mapper";
import { regexSignUp } from "../../auth/service/service";
import isEmail from "validator/lib/isEmail";
import { POST_STAFFS_SUCCESS, PUT_STAFFS_SUCCESS, DELETE_STAFFS_SUCCESS, AUTH_ERROR } from "./constant/constant";
import { withRouter } from "react-router";

class StaffDialog extends React.Component {
  state = {
    defaultValues: {
      name: "",
      furigana: "",
      job: -1,
      phone: "",
      email: "",
      auth: -1,
      password: "",
      confirm_password: ""
    },
    validateMessage: {
      name: "",
      furigana: "",
      job: "",
      phone: "",
      email: "",
      auth: "",
      password: "",
      confirm_password: ""
    },
    fieldList: [
      {
        name: "name",
        label: "社員名",
        type: DIALOG_FIELD_TYPES.Text,
        isRequired: true
      },
      {
        name: "furigana",
        label: "社員名（ふりがな全角）",
        type: DIALOG_FIELD_TYPES.Text,
        isRequired: true
      },
      {
        name: "job",
        label: "役割",
        type: DIALOG_FIELD_TYPES.DropDownBox,
        options: [],
        isRequired: false
      },
      {
        name: "phone",
        label: "個人携帯",
        type: DIALOG_FIELD_TYPES.Text,
        isRequired: false
      },
      {
        name: "email",
        label: "メールアドレス",
        type: DIALOG_FIELD_TYPES.Text,
        isRequired: true
      },
      {
        name: "auth",
        label: "権限",
        type: DIALOG_FIELD_TYPES.DropDownBox,
        options: [],
        isRequired: true
      }
    ],
    fieldListCreate: []
  };

  componentWillMount() {
    this.props.checkAuth();
    this.props.fetchJob();
    this.props.fetchAuths();
    // Only fetch staff detail in case edit
    if (this.props.staffId !== "")
      this.props.fetchStaffDetail(this.props.staffId);
  }

  componentDidMount() {
    this.props.regexSignUp();
    this.setState({
      fieldListCreate: [
        ...this.state.fieldList,
        {
          name: "password",
          label: "パスワード",
          type: DIALOG_FIELD_TYPES.Password,
          isShow: false,
          isIcon: true,
          isRequired: true
        },
        {
          name: "confirm_password",
          label: "パスワード（再入力）",
          type: DIALOG_FIELD_TYPES.Password,
          isShow: false,
          isIcon: true,
          isRequired: true
        }
      ]
    });
  }

  componentWillUnmount() {
    // Clear staff error and detail in store
    this.props.fillStaffDetailSuccess("");
    this.props.apiError(null);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.type === POST_STAFFS_SUCCESS ||
      nextProps.type === PUT_STAFFS_SUCCESS
    ) {
      this.onDialogClose();
      return;
    }
    if (nextProps.type === DELETE_STAFFS_SUCCESS) {
      this.toggleDialogDelete();
      return;
    }
    if (nextProps.type !== this.props.type && nextProps.type === AUTH_ERROR) {
      this.props.checkAuth();
    }
    // Apply data from api to original list for display combox box
    if (nextProps.auth && nextProps.jobs) {
      const authIndex = this.state.fieldList.findIndex(
        field => field.name === "auth"
      );
      const jobIndex = this.state.fieldList.findIndex(
        field => field.name === "job"
      );

      const newFieldList = [...this.state.fieldList];
      newFieldList[authIndex].options = nextProps.auth;
      newFieldList[authIndex].disabled = !nextProps.isAdmin;
      newFieldList[jobIndex].options = nextProps.jobs;
      this.setState({
        fieldList: newFieldList,
        fieldListCreate: [
          ...newFieldList,
          {
            name: "password",
            label: "パスワード",
            type: DIALOG_FIELD_TYPES.Password,
            isShow: false,
            isIcon: true,
            isRequired: true
          },
          {
            name: "confirm_password",
            label: "パスワード（再入力）",
            type: DIALOG_FIELD_TYPES.Password,
            isShow: false,
            isIcon: true,
            isRequired: true
          }
        ]
      });
    }
  }

  toggleDialogDelete = () => {
    this.props.toggleDialogDelete();
  };

  onDeleteStaff = () => {
    if (this.props.isEdit && this.props.staffId !== "") {
      this.props.deleteStaff(this.props.staffId, () => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        this.props.history.go(0);
      });
    }
  };

  onDeleteClick = () => {
    this.toggleDialogDelete();
  };

  onDialogClose = () => {
    // Clear staff error and detail in store
    this.props.fillStaffDetailSuccess("");
    this.props.apiError(null);
    this.props.onClose();
  };

  onSubmit = valuesForm => {
    const valueFormTrim = trimObjValues(valuesForm);
    let newValuesForm = { ...valueFormTrim };
    const { regex, message } = this.props;
    const {
      name,
      furigana,
      job,
      phone,
      email,
      auth,
      password,
      confirm_password
    } = valueFormTrim;

    if (newValuesForm.job === -1) {
      newValuesForm.job = null;
    }
    if (newValuesForm.job === -1) {
      newValuesForm.job = "";
    }
    if (!this.props.isAdmin) {
      delete newValuesForm.auth;
    } else if (newValuesForm.auth === -1) {
      newValuesForm.auth = "";
    }

    this.setState({
      validateMessage: {}
    });

    var { ...requiredValueFormTrim } = { ...valueFormTrim };
    delete requiredValueFormTrim.phone;
    delete requiredValueFormTrim.job;
    this.required(requiredValueFormTrim);

    this.invalid({
      ...(phone !== '' ? { phone: phone } : {}),
      furigana: furigana
    });

    if (confirm_password && confirm_password !== password) {
      this.setState({
        validateMessage: {
          ...this.state.validateMessage,
          confirm_password: translateMessage(
            this.getMessage("match", message),
            this.props.regexObject
          )
        }
      });
    }

    if ((password && !password.match(regex["password"])) || !password) {
      this.setState({
        validateMessage: {
          ...this.state.validateMessage,
          password: translateMessage(
            this.getMessage("password", message),
            this.props.regexObject
          )
        }
      });
    }

    if (email && !isEmail(email)) {
      this.setState({
        validateMessage: {
          ...this.state.validateMessage,
          email: translateMessage(
            this.getMessage("email", message),
            this.props.regexObject
          )
        }
      });
    }

    if (
      this.props.staffId &&
      name &&
      job >= -1 &&
      auth >= 0 &&
      isEmail(email) &&
      (phone === '' || phone.match(regex["phone"])) &&
      furigana.match(regex["furigana"])
    ) {
      this.setState({ validateMessage: null });

      this.props.updateStaff(newValuesForm, this.props.staffId, () => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        this.props.history.go(0);
      });
    }

    if (
      name &&
      job >= -1 &&
      auth >= 0 &&
      isEmail(email) &&
      confirm_password === password &&
      password.match(regex["password"]) &&
      (phone === '' || phone.match(regex["phone"])) &&
      furigana.match(regex["furigana"])
    ) {
      this.setState({ validateMessage: null });

      this.props.addStaff(newValuesForm, () => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        this.props.history.go(0);
      });
    }
  };

  invalid = values => {
    const { regex, message } = this.props;
    for (const item in values) {
      if (!values[item].match(regex[item]) && values[item]) {
        this.setState({
          validateMessage: {
            ...this.state.validateMessage,
            [item]: translateMessage(
              this.getMessage(`${item}`, message),
              this.props.regexObject
            )
          }
        });
      }
    }
  };

  required = values => {
    const { message } = this.props;
    const newValue = { ...values };

    if (newValue.auth <= -1) {
      newValue.auth = "";
    } else {
      newValue.auth = 1;
    }

    for (const item in newValue) {
      if (!newValue[item]) {
        this.setState({
          validateMessage: {
            ...this.state.validateMessage,
            [item]: translateMessage(
              this.getMessage("required", message),
              this.props.regexObject
            )
          }
        });
      } else {
        this.setState({
          validateMessage: {
            ...this.state.validateMessage,
            [item]: ""
          }
        });
      }
    }
  };

  getMessage = (key, message) => {
    return message[key];
  };

  showPassword = (index, position) => () => {
    const newFieldList = [...this.state.fieldListCreate];
    const newIndex = position === "right" ? index + 5 : index;
    newFieldList[newIndex].isShow = !this.state.fieldListCreate[newIndex]
      .isShow;
    this.setState({
      fieldListCreate: newFieldList
    });
  };

  render() {
    const {
      isDialogShow,
      isEdit,
      staffsDetail,
      submitErrors,
      isDialogDeleteShow
    } = this.props;

    const {
      defaultValues,
      fieldList,
      fieldListCreate,
      validateMessage
    } = this.state;

    return (
      <>
        <CommonDialog
          title={isEdit ? "社員情報編集" : "社員情報登録"}
          isDialogShow={isDialogShow}
          initialValues={
            staffsDetail.length === 0 ? defaultValues : staffsDetail
          }
          onSubmit={this.onSubmit}
          onClose={this.onDialogClose}
          fieldList={isEdit ? fieldList : fieldListCreate}
          isEdit={isEdit}
          submitButtonTitle={"登録"}
          submitErrors={validateMessage ? validateMessage : submitErrors}
          onDeleteClick={this.onDeleteClick}
          showPassword={this.showPassword}
          numberRow={5}
        />
        <DialogDelete
          open={isDialogDeleteShow}
          onClose={this.toggleDialogDelete}
          onDeleteSubmit={this.onDeleteStaff}
          PaperProps={{
            className: "dialog dialog-sign-up"
          }}
        />
      </>
    );
  }
}

StaffDialog.propTypes = {
  isDialogShow: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
  submitErrors: PropTypes.object,
  onDeleteClick: PropTypes.func
};

const mapStateToProps = state => ({
  userDetail: getUserDetail(state.staffsReducer.userDetail),
  isAdmin: state.staffsReducer.userDetail.auth === 0,
  type: state.staffsReducer.type,
  submitErrors: state.storesReducer.error,
  auth: getAuths(state.authsReducer.auth),
  jobs: getJobs(state.staffsReducer.job),
  regex: state.auth.regex,
  staffsDetail: getStaffsDetail(state.staffsReducer.staffDetail),
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addStaff,
      updateStaff,
      deleteStaff,
      fetchAuths,
      fetchJob,
      fetchStaffDetail,
      fillStaffDetailSuccess,
      apiError,
      regexSignUp,
      checkAuth
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StaffDialog));
