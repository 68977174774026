import React from "react";
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../../common/css/base.css";
import "../../common/css/sites-staft.css";

CommonDialog.propTypes = {};

function CommonDialog(props) {
  const { isDialogShow, onClose, title, dialogContent, dialogActions } = props;
  return (
    <Dialog
      open={isDialogShow}
      aria-labelledby="max-width-dialog-title"
      onClose={() => {}}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: { padding: "15px 0px", overflowY: "unset" }
      }}
    >
      <Fab onClick={onClose} size="small" className="white-text close-button">
        <CloseIcon />
      </Fab>
      {title && (
        <DialogTitle disableTypography>
          <Typography variant="h4" align="center" className="dialog-title">
            {title}
          </Typography>
        </DialogTitle>
      )}

      <DialogContent>{dialogContent}</DialogContent>

      {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
    </Dialog>
  );
}

CommonDialog.propTypes = {
  title: PropTypes.string,
  isDialogShow: PropTypes.bool,
  onClose: PropTypes.func
};

export default CommonDialog;
