import {
  FETCH_STAFFS_SUCCESS,
  POST_STAFFS_SUCCESS,
  PUT_STAFFS_SUCCESS,
  DELETE_STAFFS_SUCCESS,
  FETCH_JOBS_SUCCESS,
  GET_STAFFS_DETAIL_SUCCESS,
  GET_USER_DETAIL_SUCCESS,
  MOUNT_PASSWORD_DIALOG_SUCCESS,
  PUT_PASSWORD_SUCCESS,
  AUTH_ERROR
} from "../constant/constant";

export const fetchStaffsSuccess = staffs => {
  return {
    type: FETCH_STAFFS_SUCCESS,
    staffs: staffs
  };
};

export const postStaffsSuccess = staff => {
  return {
    type: POST_STAFFS_SUCCESS,
    staff: staff
  };
};

export const putStaffsSuccess = () => {
  return {
    type: PUT_STAFFS_SUCCESS
  };
};

export const authError = () => {
  return {
    type: AUTH_ERROR
  };
};

export const deleteStaffsSuccess = () => {
  return {
    type: DELETE_STAFFS_SUCCESS
  };
};

export const fetchJobsSuccess = jobs => {
  return {
    type: FETCH_JOBS_SUCCESS,
    jobs: jobs
  };
};

export const getStaffsDetailSuccess = staffDetail => {
  return {
    type: GET_STAFFS_DETAIL_SUCCESS,
    staffDetail: staffDetail
  };
};

export const getUserDetailSuccess = userDetail => {
  return {
    type: GET_USER_DETAIL_SUCCESS,
    userDetail: userDetail
  };
};


export const mountPasswordDialogSuccess = () => {
  return {
    type: MOUNT_PASSWORD_DIALOG_SUCCESS
  };
};

export const putPasswordSuccess = password => {
  return {
    type: PUT_PASSWORD_SUCCESS
  };
};
