import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { api } from "../../common/api/api";
import Component from "../Component";
import axios from "axios";

export class LogMeetPhoto extends Component {
  id = null;
  storage = null;
  postedAt = null;
  width = "";
  height = "";
  style = {};
  alt = "";
  name = "";
  ajaxRequest = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      img: null,
      url: ""
    };
  }

  onGetSuccess(id, name, url) {
    this.setState({ url: url });
    this.props.onGetSuccess(id, name, url);
  }

  onClick() {
    this.props.onImageClick(this.id, this.name, this.state.url, this.postedAt);
  }

  componentWillMount() {
    const { match } = this.props;
    this.init(this.props);
    if (!match.path.includes("sp")) {
      this.fetch();
    } else {
      // this.fetchSP();
    }
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    this.init(this.props);
    if (prevProps.path != this.props.path)
      if (!match.path.includes("sp")) {
        this.fetch();
      }
  }

  init(props) {
    this.id = props.PhotosUC;
    this.storage = props.storage;
    this.width = props.width;
    this.height = props.height;
    this.style = props.style;
    this.alt = props.alt;
    this.path = props.path;
    this.postedAt = props.postedAt;
    this.name = props.name === undefined ? "" : props.name;
  }

  fetch() {
    var path = this.path.replace("/api", "")
    const { onImageLoaded, PhotosUC, logmeetDetail } = this.props;
    api
      .get(path, {
        responseType: this.storage === "file" ? "arraybuffer" : undefined,
        cancelToken: this.ajaxRequest.token
      })
      .then(res => {
        if (res.data === undefined) return Promise.reject();
        if (res.data.blobData) {
          var url = res.data.blobData;
        }
        else {
          var blob = new Blob([res.data], { type: "image/jpeg" });
          var url = URL.createObjectURL(blob);
        }
        this.setState({ url })
        return url;
      })
      .then(url => {
        if (logmeetDetail) {
          onImageLoaded({
            id: PhotosUC,
            url: url,
            name: this.name,
            postedAt: this.postedAt
          });
        }
        return this.img(url)
      })
      .then(img => {
        this.setState({ img })
      })
      .catch(e => {
        this.setState({ img: this.img("") });
      });
  }

  componentWillUnmount() {
    this.ajaxRequest.cancel();
  }

  img(data) {
    return (
      <img
        src={data}
        width={this.width}
        height={this.height}
        style={this.style}
        alt={this.alt}
        onClick={() => this.onClick()}
      />
    );
  }

  render() {
    return this.state.img;
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(withRouter(LogMeetPhoto));
