import React from 'react';
import MediaQuery from 'react-responsive';

import {
  isTablet
} from "react-device-detect";

const mediaQuery = '(min-width: 960px)';

export default function Pc(props) {
  return (
    isTablet ? 
    <></>
    :
    <MediaQuery query={mediaQuery}>
        {props.children}
    </MediaQuery>
  );
}
