import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';

import TableRow from "@material-ui/core/TableRow";

import "../../../common/css/shop.css";

import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import CommonDialog from "../../CommonDialog";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";
import {
  POST_BUILDER_ROBOT_LEASES_SUCCESS,
  DELETE_ROBOT_LEASE_SUCCESS,
  PUT_ROBOT_LEASES_SUCCESS
} from "./constant/constant";
import DialogDelete from "../../../common/DialogDelete";
import {
  postBuilderRobotLease,
  fetchLogBuildRobot,
  fetchRobotLeases,
  putRobotLeases,
  fetchRobotEntries,
  fetchRobotEntriesEdit,
  deleteRobotLeases
} from "./service/service";
import { convertSerialNumber } from "../../../utils/mapper";
import { dispatchError, finishLoading } from "common/Stores/service/service";

class ShopRobotTab extends Component {
  state = {
    isDialogShow: false,
    isEdit: false,
    isDialogDeleteShow: false,
    currentParamsEdit: "",
    currentDateStatus: {}
  };

  defaultValues = {
    robotsUC: -1,
    leaseStartDate: null,
    leaseStartUser: -1,
    withdrawalUser: -1
  };

  fieldListAdd = [
    {
      name: "robotsUC",
      label: "管理番号",
      type: DIALOG_FIELD_TYPES.DropDownBox,
      isRequired: true,
      options: [],
      sharp: true
    },
    {
      name: "leaseStartDate",
      label: "貸出日",
      type: DIALOG_FIELD_TYPES.DateTime,
      isRequired: true
    },
    {
      name: "leaseStartUser",
      label: "契約担当者",
      type: DIALOG_FIELD_TYPES.DropDownBox,
      isRequired: true,
      options: []
    }
  ];

  fieldListEdit = [
    ...this.fieldListAdd,
    {
      name: "leaseEndDate",
      label: "利用停止日",
      type: DIALOG_FIELD_TYPES.DateTime ,
      guidMessage: "※利用停止日を登録すると、ロボットの貸し出しが解除され、ステータスが保管中に変更されます"
    },
    {
      name: "withdrawalUser",
      label: "回収担当者",
      type: DIALOG_FIELD_TYPES.DropDownBox,
      options: []
    },
    {
      name: "withdrawalDate",
      label: "回収日",
      type: DIALOG_FIELD_TYPES.DateTime
    }
  ];

  componentDidMount() {
    this.props.fetchLogBuildRobot(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.props.fetchLogBuildRobot(nextProps.match.params.id);
    }
    if (
      nextProps.type === POST_BUILDER_ROBOT_LEASES_SUCCESS ||
      nextProps.type === PUT_ROBOT_LEASES_SUCCESS
    ) {
      this.setState({
        isDialogShow: false
      });
    }

    if (nextProps.type === DELETE_ROBOT_LEASE_SUCCESS) {
      this.setState(
        {
          isDialogDeleteShow: false
        },
        () => this.props.finishLoading()
      );
    }
  }

  toggleDialogDelete = () => {
    this.setState({
      isDialogDeleteShow: !this.state.isDialogDeleteShow,
      isDialogShow: false
    });
  };

  toggleDialog = isEdit => () => {
    this.setState({
      isDialogShow: !this.state.isDialogShow,
      isEdit: isEdit
    });
  };

  onSubmit = values => {
    const { match, postBuilderRobotLease, putRobotLeases } = this.props;
    const { isEdit, currentParamsEdit, currentDateStatus } = this.state;
    const newValues = { buildersUC: match.params.id, ...values };

    if (newValues.robotsUC === -1) newValues.robotsUC = "";
    if (newValues.leaseStartUser === -1) newValues.leaseStartUser = "";
    for (let key in currentDateStatus) {
      newValues[key] = "Invalid Date";
    }
    if (isEdit) {
      if (newValues.withdrawalUser === -1) newValues.withdrawalUser = "";
      putRobotLeases(currentParamsEdit, newValues);
    } else {
      postBuilderRobotLease(newValues);
    }
  };

  currentDate = (fieldName, date) => {
    if (date?.toString() === "Invalid Date") {
      this.setState({
        currentDateStatus: {
          ...this.state.currentDateStatus,
          [fieldName]: ""
        }
      });
    } else {
      const newDateStatus = { ...this.state.currentDateStatus };
      delete newDateStatus[fieldName];
      this.setState({
        currentDateStatus: newDateStatus
      });
    }
  };

  onRobotEditClick = robot => () => {
    const {
      fetchRobotEntriesEdit,
      fetchRobotLeases,
      dispatchError
    } = this.props;
    const paramsEdit = `${this.props.match.params.id}.${robot.robotsUC}.${robot.index}`;
    dispatchError("");
    fetchRobotEntriesEdit(robot.robotsUC);
    fetchRobotLeases(paramsEdit);
    this.setState({
      isDialogShow: true,
      isEdit: true,
      currentParamsEdit: paramsEdit
    });
  };

  onRobotCreateClick = () => {
    const { fetchRobotEntries, dispatchError } = this.props;
    dispatchError("");
    fetchRobotEntries();
    this.setState({
      isDialogShow: true,
      isEdit: false
    });
  };

  onDeleteSubmit = () => {
    this.props.deleteRobotLeases(this.state.currentParamsEdit);
  };

  render() {
    const { isDialogShow, isEdit, isDialogDeleteShow } = this.state;
    const {
      robotEntries,
      staffEntries,
      robotLease,
      error,
      robotLeases
    } = this.props;

    const robotIndex = this.fieldListAdd.findIndex(
      field => field.name === "robotsUC"
    );
    const leaseEndIndex = this.fieldListAdd.findIndex(
      field => field.name === "leaseStartUser"
    );
    const withdrawalUserIndex = this.fieldListEdit.findIndex(
      field => field.name === "withdrawalUser"
    );

    const convertRobotEntries = robotEntries.map(item => {
      return {
        ...item,
        name: convertSerialNumber(item.name)
      };
    });

    this.fieldListAdd[robotIndex].options = convertRobotEntries;
    this.fieldListAdd[leaseEndIndex].options = staffEntries;
    this.fieldListEdit[withdrawalUserIndex].options = staffEntries;

    if (robotLeases && robotLeases.withdrawalUser === null)
      robotLeases.withdrawalUser = -1;

    return (
      <Grid className="shop-robot">
        <Grid>
          <Grid className="mb-10" container justify="space-between">
            <Button
              onClick={this.onRobotCreateClick}
              size="small"
              variant="text"
              className="button-add"
            >
              <AddIcon className="icon-margin" />
              貸出登録
            </Button>
          </Grid>
          <TableContainer>
            <Table size="small" aria-label="table">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell className="white-text ">
                    製造番号
                    <ArrowUpwardSharpIcon className="icon-small-size s-va-m margin-left arrow-up" />
                  </TableCell>
                  <TableCell className="white-text">貸出担当者</TableCell>
                  <TableCell className="white-text ">
                    貸出日
                  </TableCell>
                  <TableCell className="white-text">利用停止日</TableCell>
                  <TableCell className="white-text">回収担当者</TableCell>
                  <TableCell className="white-text">回収日</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {robotLease.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {item.robotNo}
                    </TableCell>
                    <TableCell>{item.leaseStartUserName}</TableCell>
                    <TableCell>
                      {item.leaseStartDate &&
                        format(new Date(item.leaseStartDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell>
                      {item.leaseEndDate &&
                        format(new Date(item.leaseEndDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell>{item.withdrawalUserName}</TableCell>
                    <TableCell>
                      {item.withdrawalDate &&
                        format(new Date(item.withdrawalDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell className="s-ta-r">
                      <Button
                        onClick={this.onRobotEditClick(item)}
                        size="small"
                        variant="text"
                        className="button-add"
                      >
                        <EditIcon className="icon-margin icon-small-size" />
                        編集
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justify="space-between" className="mt-10">
            <Button
              onClick={this.onRobotCreateClick}
              size="small"
              variant="text"
              className="button-add"
            >
              <AddIcon className="icon-margin" />
              貸出登録
            </Button>
          </Grid>
        </Grid>
        {isDialogShow && (
          <CommonDialog
            title={isEdit ? "ロボット貸出編集" : "ロボット貸出登録"}
            isDialogShow={isDialogShow}
            initialValues={
              isEdit && robotLeases ? robotLeases : this.defaultValues
            }
            onSubmit={this.onSubmit}
            isEdit={isEdit}
            onClose={this.toggleDialog()}
            fieldList={isEdit ? this.fieldListEdit : this.fieldListAdd}
            submitButtonTitle={"登録"}
            onDeleteClick={this.toggleDialogDelete}
            isSingleColumn={true}
            submitErrors={error}
            currentDate={this.currentDate}
          />
        )}
        {isDialogDeleteShow && (
          <DialogDelete
            open={isDialogDeleteShow}
            onClose={this.toggleDialogDelete}
            onDeleteSubmit={this.onDeleteSubmit}
            PaperProps={{
              className: "dialog dialog-sign-up"
            }}
          />
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  robotEntries: state.builderReducer.builderDetailReducer.robotEntries,
  staffEntries: state.builderReducer.builderDetailReducer.staffEntries,
  robotLease: state.builderReducer.builderDetailReducer.robotLease,
  error: state.storesReducer.error,
  type: state.builderReducer.builderDetailReducer.type,
  robotLeases: state.builderReducer.builderDetailReducer.robotLeases
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postBuilderRobotLease,
      fetchLogBuildRobot,
      fetchRobotLeases,
      putRobotLeases,
      fetchRobotEntries,
      fetchRobotEntriesEdit,
      deleteRobotLeases,
      dispatchError,
      finishLoading
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShopRobotTab));
