import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchField from "../../SearchField";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

function InfoSupplierDialog(props) {
  const {
    isShowDialogSupplier,
    toggleDialogSupplier,
    tableClass,
    supplierCandidates,
    siteInfoSupplier,
    handleAddSupplierCandidate,
    isSP
  } = props;

  const [searchFurigana, setSearchFurigana] = useState("");
  const [searchCompany, setSearchCompany] = useState("");
  const [onlyShowCurrentSupplier, setOnlyShowCurrentSupplier] = useState(false);

  const [allSuppliers, setAllSuppliers] = useState([]);
  const [showSuppliers, setShowSuppliers] = useState([]);

  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [showConfirmExit, setShowConfirmExit] = useState(false);

  useEffect(() => {
    let tempArr = [...siteInfoSupplier, ...supplierCandidates];
    tempArr = tempArr.sort((a, b) => {
      return (
        a.furigana.localeCompare(b.furigana, "jp") ||
        a.uniqueCode.localeCompare(b.uniqueCode, "en")
      );
    });
    setAllSuppliers(tempArr);
  }, [siteInfoSupplier, supplierCandidates]);

  useEffect(() => {
    if (onlyShowCurrentSupplier) {
      setShowSuppliers(
        selectedSuppliers.filter(
          element =>
            (element.furigana.includes(searchFurigana) ||
              element.name.includes(searchFurigana)) &&
            (element.supplierName.includes(searchCompany) ||
              element.supplierNameFurigana.includes(searchCompany))
        )
      );
    } else {
      setShowSuppliers(
        allSuppliers.filter(
          element =>
            (element.furigana.includes(searchFurigana) ||
              element.name.includes(searchFurigana)) &&
            (element.supplierName.includes(searchCompany) ||
              element.supplierNameFurigana.includes(searchCompany))
        )
      );
    }
  }, [
    allSuppliers,
    searchFurigana,
    onlyShowCurrentSupplier,
    searchCompany,
    selectedSuppliers
  ]);

  const toggleSelectSupplier = supplier => {
    if (siteInfoSupplier.find(item => item.uniqueCode === supplier.uniqueCode))
      return;
    if (
      selectedSuppliers.find(item => item.uniqueCode === supplier.uniqueCode)
    ) {
      setSelectedSuppliers(
        selectedSuppliers.filter(
          item => item.uniqueCode !== supplier.uniqueCode
        )
      );
    } else {
      setSelectedSuppliers(
        [...selectedSuppliers, supplier].sort((a, b) => {
          return (
            a.furigana.localeCompare(b.furigana, "jp") ||
            a.uniqueCode.localeCompare(b.uniqueCode, "en")
          );
        })
      );
    }
  };

  const checkAllShowAreSelected = () => {
    for (let i = 0; i < showSuppliers.length; i++) {
      const element = showSuppliers[i];
      if (
        !(
          siteInfoSupplier.find(
            item => item.uniqueCode === element.uniqueCode
          ) ||
          selectedSuppliers.find(item => item.uniqueCode === element.uniqueCode)
        )
      ) {
        return false;
      }
    }
    return true;
  };

  const checkDisableSelectAll = () => {
    if (showSuppliers.length === 0) return true;

    for (let i = 0; i < showSuppliers.length; i++) {
      const element = showSuppliers[i];
      if (
        !siteInfoSupplier.find(item => item.uniqueCode === element.uniqueCode)
      ) {
        return false;
      }
    }
    return true;
  };

  const selectAll = () => {
    if (checkAllShowAreSelected()) {
      setSelectedSuppliers(
        selectedSuppliers.filter(
          selectedSupplier =>
            !showSuppliers.find(
              item => item.uniqueCode === selectedSupplier.uniqueCode
            )
        )
      );
    } else {
      const tempAdd = showSuppliers.filter(
        showSupplier =>
          !(
            siteInfoSupplier.find(
              item => item.uniqueCode === showSupplier.uniqueCode
            ) ||
            selectedSuppliers.find(
              item => item.uniqueCode === showSupplier.uniqueCode
            )
          )
      );

      setSelectedSuppliers(
        [...selectedSuppliers, ...tempAdd].sort((a, b) => {
          return (
            a.furigana.localeCompare(b.furigana, "jp") ||
            a.uniqueCode.localeCompare(b.uniqueCode, "en")
          );
        })
      );
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={isShowDialogSupplier}
        aria-labelledby="max-width-dialog-title"
        fullWidth
        onClose={() => {}}
        maxWidth="lg"
        PaperProps={{
          className: "dialog dialog-sign-up dialog-min-height",
          style: { height: "76vh" }
        }}
      >
        <Fab
          onClick={() => {
            if (selectedSuppliers.length > 0) {
              setShowConfirmExit(true);
            } else {
              toggleDialogSupplier();
            }
          }}
          size="small"
          className="white-text close-button"
        >
          <CloseIcon />
        </Fab>

        <DialogTitle disableTypography>
          <Grid container justify="space-between" className="mb-15">
            <Typography variant="h4" className="dialog-title">
              職人を追加
            </Typography>
          </Grid>
          <Grid container justify="space-between" wrap="nowrap">
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  <Grid
                    container
                    justify="flex-start"
                    alignItems="cennter"
                    wrap="wrap"
                  >
                    <Grid
                      item
                      style={{ marginRight: "24px", marginBottom: "12px" }}
                    >
                      <SearchField
                        value={searchCompany}
                        setValue={setSearchCompany}
                        placeholder="会社名から検索"
                      />
                    </Grid>
                    <Grid
                      item
                      style={{ marginRight: "24px", marginBottom: "6px" }}
                    >
                      <SearchField
                        value={searchFurigana}
                        setValue={setSearchFurigana}
                        placeholder="職人名から検索"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  handleAddSupplierCandidate(
                    selectedSuppliers.map(item => item.uniqueCode)
                  );
                  toggleDialogSupplier();
                }}
                disabled={selectedSuppliers.length === 0}
                style={{
                  backgroundColor:
                    selectedSuppliers.length === 0 ? "#808080" : "#162f50",
                  color: "#ffffff",
                  padding: "4px 10px",
                  fontSize: "1rem",
                  fontWeight: "normal"
                }}
                size="small"
                variant="text"
                className="button-add-new"
              >
                追加
              </Button>
            </Grid>
          </Grid>
          <Grid container style={{ margin: "4px 0px" }}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={onlyShowCurrentSupplier}
                    onChange={e => setOnlyShowCurrentSupplier(e.target.checked)}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={`選択中の職人（${selectedSuppliers.length}人）`}
              />
            </Grid>
          </Grid>
          <Grid container style={{ margin: "4px 0px" }}>
            <Grid item>
              <Button
                onClick={() => selectAll()}
                style={{
                  backgroundColor: checkAllShowAreSelected()
                    ? "#ffffff"
                    : "#162f50",
                  color: checkAllShowAreSelected() ? "#162f50" : "#fff",
                  padding: "4px 10px",
                  fontSize: "1rem",
                  fontWeight: "normal",
                  border: "1px solid #162f50"
                }}
                disabled={checkDisableSelectAll()}
                size="small"
                variant="text"
              >
                {checkAllShowAreSelected() ? "すべて解除" : "すべて選択"}
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table size="small" aria-label="table" className={tableClass}>
              <TableHead className="table-head">
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell className="white-text">会社名</TableCell>
                  <TableCell className="white-text">職人名</TableCell>
                  <TableCell className="white-text">個人携帯</TableCell>
                  <TableCell className="white-text">メールアドレス</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {showSuppliers.map(supplier => (
                  <TableRow
                    key={supplier.uniqueCode}
                    hover
                    onClick={() => toggleSelectSupplier(supplier)}
                    style={{
                      opacity: siteInfoSupplier.find(
                        item => item.uniqueCode === supplier.uniqueCode
                      )
                        ? "0.4"
                        : "1"
                    }}
                  >
                    <TableCell align="right" className={"info-cell-more"}>
                      {siteInfoSupplier.find(
                        item => item.uniqueCode === supplier.uniqueCode
                      ) ? (
                        <span style={{ fontSize: "30px", color: "#3c3c3c" }}>
                          <CheckCircleIcon fontSize="inherit" color="inherit" />
                        </span>
                      ) : selectedSuppliers.find(
                          item => item.uniqueCode === supplier.uniqueCode
                        ) ? (
                        <span style={{ fontSize: "30px", color: "#92D050" }}>
                          <CheckCircleIcon fontSize="inherit" color="inherit" />
                        </span>
                      ) : (
                        <span style={{ fontSize: "30px", color: "#D0CECE" }}>
                          <RadioButtonUncheckedIcon
                            fontSize="inherit"
                            color="inherit"
                          />
                        </span>
                      )}
                    </TableCell>
                    <TableCell>{supplier.supplierName}</TableCell>
                    <TableCell>
                      <Grid
                        container
                        alignItems="center"
                        className="inline-block"
                      >
                        {supplier.job === 1 ? (
                          <Typography
                            variant="caption"
                            className="tag"
                            style={{
                              ...(isSP
                                ? {
                                    padding: 2,
                                    marginLeft: 0,
                                    marginRight: 2
                                  }
                                : { marginLeft: 0, marginRight: 10 })
                            }}
                          >
                            {supplier.jobName}
                          </Typography>
                        ) : (
                          <></>
                        )}
                        {supplier.name}
                      </Grid>
                    </TableCell>

                    <TableCell>{supplier.phone}</TableCell>
                    <TableCell>{supplier.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showConfirmExit}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ backgroundColor: "#e0e0e0eb" }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
            marginBottom: "20px"
          }}
        >
          現場への職人追加が完了していません。
          <br />
          終了してよろしいですか。
        </DialogTitle>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={() => {
              setShowConfirmExit(false);
              toggleDialogSupplier();
            }}
            className="btn-dialog-yes"
            style={{
              color: "#FFFFFF",
              backgroundColor: "#00356D",
              margin: "0px 8px"
            }}
          >
            はい
          </Button>
          <Button
            onClick={() => setShowConfirmExit(false)}
            className="btn-dialog-no"
            autoFocus
            style={{
              color: "#FFFFFF",
              backgroundColor: "#C7C7C7",
              margin: "0px 8px"
            }}
          >
            いいえ
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default InfoSupplierDialog;
