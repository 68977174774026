import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DialogDelete from "../DialogDelete";
import { returnRobot } from "../../components/robots/robotDetails/RobotInfo/service/service";
import { getType } from "../../components/robots/robotDetails/RobotInfo/reducer/reducer";
import { RETURN_ROBOT_SUCCESS } from "../../components/robots/robotDetails/RobotInfo/constant/constant";

class DialogRobotReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.open !== nextProps.open) {
      this.setState({
        open: nextProps.open,
      })
    }
    switch (nextProps.type) {
      case RETURN_ROBOT_SUCCESS:
        this.props.onSuccess();
        this.onClose();
        break;
    }
  }

  onClose() {
    this.setState({
      open: false,
    });
    this.props.onClose();
  }

  render() {
    const onClose = () => {
      this.onClose();
    };

    const onDeleteSubmit = () => {
      const data = { robotsUC: this.props.uniqueCode };
      this.props.returnRobot(data);
    };

    return (
      <DialogDelete
        open={this.state.open}
        onClose={onClose}
        onDeleteSubmit={onDeleteSubmit}
        textTop="ロボットをlogbuildに​"
        textBottom="返却しますか？​"
        titleSubmit="返却する"
        PaperProps={{
          className: "dialog dialog-sign-up"
        }}
      />            
    );
  }
};

DialogRobotReturn.propTypes = {
  open: PropTypes.bool.isRequired,
  uniqueCode: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  type: getType(state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailInfoReducer.type),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      returnRobot,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogRobotReturn);
