import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Field } from "formik";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import InputControl from "../../common/InputControl";
import { apiError } from "../../common/Stores/action/action";
import { translateValidate } from "../../common/Stores/service/service";
import {
  updateUserPassword,
  updateUserLogBuildPassword
} from "./service/service";

import { putPasswordStart } from "./action/action";

import "../../common/css/profile.css";
import { regexSignUp } from "../auth/service/service";
import {
  PUT_USER_PASSWORD_BUILDER_SUCCESS,
  PUT_USER_PASSWORD_LOGBUILD_SUCCESS
} from "./constant/constant";
import { translateMessage } from "utils/mapper";
import Sp from "../../common/breakpoints/Sp";

class PassWordPage extends React.Component {
  state = {
    defaultValues: {
      password: "",
      password_confirmation: "",
      updateSuccess: false,
      submitFlag: false,
    }
  };

  handleDateChange = (fieldName, formik) => date => {
    formik.setFieldValue(fieldName, date);
  };

  onSubmit = values => {
    const { message } = this.props;
    this.props.putPasswordStart();
    const messageError = {
      password: "",
      password_confirmation: ""
    };
    this.setState({ updateSuccess: false }, () => {
      const { password, password_confirmation } = values;
      this.props.apiError();
      if (password || password_confirmation) {
        if (
          password_confirmation === password &&
          password.match(this.props.regex["password"])
        ) {
          if (this.props.pathname.includes("logbuild")) {
            this.props.updateUserLogBuildPassword(password);
          } else {
            this.props.updateUserPassword(password);
          }
        } else {
          if (password && password_confirmation !== password) {
            messageError["password_confirmation"] = translateMessage(
              this.getMessage("match", message),
              this.props.regexObject
            );
          }
        }
      }

      if (password && !password.match(this.props.regex["password"])) {
        messageError["password"] = translateMessage(
          this.getMessage("password", message),
          this.props.regexObject
        );
      }

      if (!password) {
        messageError["password"] = translateMessage(
          this.getMessage("password", message),
          this.props.regexObject
        );
      }

      if (!password_confirmation) {
        messageError["password_confirmation"] = translateMessage(
          this.getMessage("required", message),
          this.props.regexObject
        );
      }

      if (!password && !password_confirmation) {
        messageError["password"] = translateMessage(
          this.getMessage("password", message),
          this.props.regexObject
        );
        messageError["password_confirmation"] = translateMessage(
          this.getMessage("required", message),
          this.props.regexObject
        );
      }

      this.props.apiError(messageError);
    });
  };

  getMessage = (key, message) => {
    return message[key];
  };

  componentWillReceiveProps(nextProps) {
    if (
      !this.state.updateSuccess &&
      (nextProps.type === PUT_USER_PASSWORD_BUILDER_SUCCESS ||
        nextProps.type === PUT_USER_PASSWORD_LOGBUILD_SUCCESS)
    ) {
      this.setState({ updateSuccess: true });
    }
  }

  componentDidMount() {
    this.props.apiError(null);
    this.props.regexSignUp();
    this.props.translateValidate();
    this.props.putPasswordStart();
  }

  renderError(fieldName) {
    return (
      <>
        {this.props.submitErrors && this.props.submitErrors[fieldName]
          ? (
            <span className="error">{this.props.submitErrors[fieldName]}</span>
          )
          : fieldName === "password" && (
            <span style={{ color: "#909090" }}>半角英字、半角数字を含む8文字以上をご入力ください</span>
          )
        }
      </>
    );
  }

  render() {
    return (
      <Formik
        initialValues={this.state.defaultValues}
        enableReinitialize
        onSubmit={values => this.onSubmit(values)}
      >
        {formik => (
          <Grid
            className="profile-content"
            tabIndex={1}
            onKeyPress={event => {
              if (event.key === "Enter" && !this.state.submitFlag) {
                formik.handleSubmit(formik.values);
                this.setState({ submitFlag: true });
              }
            }}

            onClick={() => {
              this.setState({ submitFlag: false });
            }}
          >
            {!this.props.pathname.includes("logbuild") && (
              <Sp>
                <Typography
                  variant="h5"
                  className="font-family text-bold"
                  style={{ marginTop: -15, marginLeft: -20 }}
                >
                  パスワード変更
                </Typography>
              </Sp>
            )}
            <Grid container wrap="nowrap">
              <Grid container>
                <Grid container justify="center">
                  <Grid item sm={12} md={6} className="col" style={{ width: '100%' }}>
                    <Field
                      label={
                        <Typography variant="subtitle1" className="label">
                          パスワード変更
                        </Typography>
                      }
                      name="password"
                      type="password"
                      onChange={formik.handleChange}
                      variant="filled"
                      className="input-reset"
                      component={InputControl}
                    />
                    {this.renderError("password")}
                    <Field
                      label={
                        <Typography variant="subtitle1" className="label">
                          パスワード変更（確認）
                        </Typography>
                      }
                      name="password_confirmation"
                      type="password"
                      onChange={formik.handleChange}
                      variant="filled"
                      className="input-reset"
                      component={InputControl}
                      autoComplete="new-password"
                    />

                    {this.renderError("password_confirmation")}

                    {this.state.updateSuccess ? (
                      <Grid container className="message-success">
                        保存しました
                      </Grid>
                    ) : null}

                    <Grid className="submit-button">
                      <Button
                        onClick={() => {
                          this.setState({ submitFlag: true });
                          formik.handleSubmit();
                        }}
                        color="primary"
                        className="dialog-button"
                        // autoFocus
                        // disableRipple
                        disabled={this.state.submitFlag}
                      >
                        登録
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    );
  }
}

PassWordPage.propTypes = {};

const mapStateToProps = state => ({
  submitErrors: state.storesReducer.error,
  type: state.profileReducer.type,
  regex: state.auth.regex,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      apiError,
      updateUserPassword: updateUserPassword,
      updateUserLogBuildPassword: updateUserLogBuildPassword,
      putPasswordStart: putPasswordStart,
      regexSignUp: regexSignUp,
      translateValidate: translateValidate
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PassWordPage);
