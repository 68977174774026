import {
  FETCH_ROBOT_SUCCESS,
  FETCH_ROBOT_LOG_BUILD_SUCCESS,
  POST_ROBOT_SUCCESS,
  PUT_ROBOT_SUCCESS,
  PUT_ROBOT_START,
  PUT_ROBOT_ERROR,
  ROBOT_CLEAR_TYPE,
  DELETE_ROBOT_SUCCESS
} from "../constant/constant";

const initialState = {
  robot: [],
  robotLogBuild: [],
  uniqueCode: ""
};
export const robotsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROBOT_SUCCESS:
      return {
        ...state,
        type: action.type,
        robot: action.robot
      };
    case FETCH_ROBOT_LOG_BUILD_SUCCESS:
      return {
        ...state,
        type: action.type,
        robotLogBuild: action.robotLogBuild
      };
    case POST_ROBOT_SUCCESS:
      return {
        ...state,
        type: action.type,
        uniqueCode: action.robot.uniqueCode
      };
    case PUT_ROBOT_SUCCESS:
      return {
        ...state,
        type: action.type
      };
    case PUT_ROBOT_START:
      return {
        ...state,
        type: action.type
      };
    case PUT_ROBOT_ERROR:
      return {
        ...state,
        type: action.type
      };
    case ROBOT_CLEAR_TYPE:
      return {
        ...state,
        type: ""
      };
    case DELETE_ROBOT_SUCCESS:
      return {
        ...state,
        type: action.type
      };

    default:
      return state;
  }
};
export const getRobots = state => state;
export const getRobotLogBuild = state => state;
export const getUniCode = state => state;
