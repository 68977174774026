import { AcUnitTwoTone } from "@material-ui/icons";
import {
  FETCH_ROBOT_DETAIL_SUCCESS,
  FETCH_ROBOT_ENTRIES_SUCCESS,
  FETCH_ROBOT_LOG_BUILD_DETAIL_SUCCESS,
  FETCH_ROBOT_LOG_BUILD_ENTRIES_SUCCESS,
  FETCH_LOG_BUILD_BUILDER_ENTRIES_SUCCESS,
  FETCH_LOG_BUILD_STAFF_ENTRIES_SUCCESS,
  FETCH_LOG_BUILD_RBOT_HISTORY_SUCCESS,
  POST_LOG_BUILD_RBOT_HISTORY_SUCCESS,
  PUT_LOG_BUILD_RBOT_HISTORY_SUCCESS,
  FETCH_LOG_BUILD_RBOT_LEASE_HISTORY_SUCCESS,
  DELETE_LOG_BUILD_ROBOT_HISTORY_SUCCESS,
  FETCH_ROBOT_HISTORY_SUCCESS,
  FETCH_BUILDER_ROBOT_AVAILABLE_SUCCESS,
  FETCH_ROBOT_EDIT_SUCCESS,
  WS_UPDATED_ROBOTS_SUCCESS,
  WS_CLEAR_ROBOTS_SUCCESS,
  ROBOT_UC_CHANGE_SUCCESS
} from "../constant/constant";

export const fetchRobotDetailSuccess = robotDetails => {
  return {
    type: FETCH_ROBOT_DETAIL_SUCCESS,
    robotDetails: robotDetails
  };
};

export const fetchRobotEntriesSuccess = robotEntries => {
  return {
    type: FETCH_ROBOT_ENTRIES_SUCCESS,
    robotEntries: robotEntries
  };
};

export const fetchRobotLogBuildDetailSuccess = robotLogBuildDetails => {
  return {
    type: FETCH_ROBOT_LOG_BUILD_DETAIL_SUCCESS,
    robotLogBuildDetails: robotLogBuildDetails
  };
};

export const fetchRobotLogBuildEntriesSuccess = robotLogBuildEntries => {
  return {
    type: FETCH_ROBOT_LOG_BUILD_ENTRIES_SUCCESS,
    robotLogBuildEntries: robotLogBuildEntries
  };
};

export const fetchLogBuildBuilderEntriesSuccess = builderEntries => {
  return {
    type: FETCH_LOG_BUILD_BUILDER_ENTRIES_SUCCESS,
    builderEntries: builderEntries
  };
};

export const fetchLogBuildStaffEntriesSuccess = staffEntries => {
  return {
    type: FETCH_LOG_BUILD_STAFF_ENTRIES_SUCCESS,
    staffEntries: staffEntries
  };
};

export const fetchLogBuildRobotHistorySuccess = robotHistory => {
  return {
    type: FETCH_LOG_BUILD_RBOT_HISTORY_SUCCESS,
    robotHistory: robotHistory
  };
};

export const postLogBuildRobotHistorySuccess = () => {
  return {
    type: POST_LOG_BUILD_RBOT_HISTORY_SUCCESS
  };
};

export const fetchLogBuildRobotLeaseHistorySuccess = robotLeaseHistory => {
  return {
    type: FETCH_LOG_BUILD_RBOT_LEASE_HISTORY_SUCCESS,
    robotLeaseHistory: robotLeaseHistory
  };
};

export const putLogBuildRobotHistorySuccess = () => {
  return {
    type: PUT_LOG_BUILD_RBOT_HISTORY_SUCCESS
  };
};

export const deleteLogBuildRobotHistorySuccess = () => {
  return {
    type: DELETE_LOG_BUILD_ROBOT_HISTORY_SUCCESS
  };
};

export const fetchRobotHistorySuccess = robotHistory => {
  return {
    type: FETCH_ROBOT_HISTORY_SUCCESS,
    robotHistory: robotHistory
  };
};

export const fetchBuilderRobotAvailableSuccess = () => {
  return {
    type: FETCH_BUILDER_ROBOT_AVAILABLE_SUCCESS
  };
};

export const fetchRobotEditSuccess = robotEdit => {
  return {
    type: FETCH_ROBOT_EDIT_SUCCESS,
    robotEdit
  };
};

export const wsUpdatedRobotsSuccess = wsUpdatedRobots => {
  return {
    type: WS_UPDATED_ROBOTS_SUCCESS,
    wsUpdatedRobots
  };
};

export const wsClearRobotsSuccess = () => {
  return {
    type: WS_CLEAR_ROBOTS_SUCCESS
  };
};

export const changeRobotUCSuccess = robotUC => {
  return {
    type: ROBOT_UC_CHANGE_SUCCESS,
    robotUC
  };
};
