import {
  FETCH_ROBOT_DETAIL_SUCCESS,
  FETCH_ROBOT_LOG_BUILD_DETAIL_SUCCESS,
  FETCH_LOG_BUILD_RBOT_HISTORY_SUCCESS,
  POST_LOG_BUILD_RBOT_HISTORY_SUCCESS,
  FETCH_LOG_BUILD_RBOT_LEASE_HISTORY_SUCCESS,
  PUT_LOG_BUILD_RBOT_HISTORY_SUCCESS,
  DELETE_LOG_BUILD_ROBOT_HISTORY_SUCCESS,
  FETCH_ROBOT_HISTORY_SUCCESS,
  FETCH_ROBOT_EDIT_SUCCESS,
  WS_UPDATED_ROBOTS_SUCCESS,
  WS_CLEAR_ROBOTS_SUCCESS,
  ROBOT_UC_CHANGE_SUCCESS
} from "../constant/constant";

const initialState = {
  robotDetails: [],
  robotLogBuildDetails: [],
  robotHistory: [],
  wsUpdatedRobots: [],
  robotUC: null
};
export const robotDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROBOT_DETAIL_SUCCESS:
      return {
        ...state,
        type: action.type,
        robotDetails: action.robotDetails
      };
    case FETCH_ROBOT_LOG_BUILD_DETAIL_SUCCESS:
      return {
        ...state,
        type: action.type,
        robotLogBuildDetails: action.robotLogBuildDetails
      };
    case FETCH_LOG_BUILD_RBOT_HISTORY_SUCCESS:
      return {
        ...state,
        type: action.type,
        robotHistory: action.robotHistory
      };
    case POST_LOG_BUILD_RBOT_HISTORY_SUCCESS:
      return {
        ...state,
        type: action.type
      };
    case FETCH_LOG_BUILD_RBOT_LEASE_HISTORY_SUCCESS:
      return {
        ...state,
        type: action.type,
        robotLeaseHistory: action.robotLeaseHistory
      };
    case PUT_LOG_BUILD_RBOT_HISTORY_SUCCESS:
      return {
        ...state,
        type: action.type
      };
    case DELETE_LOG_BUILD_ROBOT_HISTORY_SUCCESS:
      return {
        ...state,
        type: action.type
      };
    case FETCH_ROBOT_HISTORY_SUCCESS:
      return {
        ...state,
        type: action.type,
        builderRobotHistory: action.robotHistory
      };
    case FETCH_ROBOT_EDIT_SUCCESS:
      return {
        ...state,
        type: action.type,
        robotEdit: action.robotEdit
      };
      break;
    case WS_UPDATED_ROBOTS_SUCCESS:
      let wsRobots = state.wsUpdatedRobots;
      let isNew = true;

      for (var i = 0; i < wsRobots.length; i++) {
        if (wsRobots[i].robotsUC === action.wsUpdatedRobots.robotsUC) {
          wsRobots[i].useStatus = parseInt(action.wsUpdatedRobots.useStatus);
          isNew = false;
          break;
        }
      }
      if (isNew)
        wsRobots.push({
          robotsUC: action.wsUpdatedRobots.robotsUC,
          useStatus: parseInt(action.wsUpdatedRobots.useStatus)
        });
      return {
        ...state,
        type: action.type,
        wsUpdatedRobots: wsRobots
      };
    case WS_CLEAR_ROBOTS_SUCCESS:
      return {
        ...state,
        type: action.type,
        wsUpdatedRobots: []
      };
    case ROBOT_UC_CHANGE_SUCCESS:
      return {
        ...state,
        type: action.type,
        robotUC: action.robotUC
      };
    default:
      return state;
  }
};
export const getRobotDetails = state => state;
export const getRobotsUpdate = state => state;
