import {
  fetchDashBoardsSuccess,
  postDashBoardsSuccess,
  fetchDashBoardPCSuccess,
  fetchDashBoardLBSuccess,
  changeTypeListDashboardSuccess
} from "../action/action";
import { api } from "../../../common/api/api";
import {
  dispatchError,
  enableLoading,
  finishLoading
} from "../../../common/Stores/service/service";

export const fetchDashBoard = () => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/builder/dashboard/sites`)
      .then(res => {
        dispatch(fetchDashBoardsSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(error => {
        dispatch(dispatchError(error));
      });
  };
};

export const fetchDashBoardSP = () => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/supplier/dashboard`)
      .then(res => {
        dispatch(fetchDashBoardPCSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(error => {
        dispatch(dispatchError(error));
      });
  };
};

export const addDashBoard = dashboard => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/sites`, dashboard)
      .then(res => {
        dispatch(postDashBoardsSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(error => {
        dispatch(dispatchError(error));
      });
  };
};

export const fetchDashBoardLB = () => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/user-logs`)
      .then(res => {
        dispatch(fetchDashBoardLBSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(error => {
        dispatch(dispatchError(error));
      });
  };
};

export const changeTypeListDashboard = isCardList => {
  return dispatch => {
    dispatch(changeTypeListDashboardSuccess(isCardList));
  };
};
