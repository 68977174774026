import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import uploadFromDeviceIcon from "../../../../../../common/img/upload_from_device.png";
import chooseExistedIcon from "../../../../../../common/img/choose_existed.png";

const useStyles = makeStyles({
  sendFindCometBackDrop: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    zIndex: "500"
  },
  menuUploadFileBackDrop: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "500"
  },
  menuUploadFile: {
    width: "360px",
    borderRadius: "12px",
    boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    backgroundColor: "white",
    overflow: "hidden"
  },
  menuUploadFileItem: {
    width: "100%",
    height: "80px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "0px 12px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D0D0D0"
    }
  }
});

function MenuUploadFile(props) {
  const { uploadNewFile, chooseExistedFile, closeMenu, isSendToComet } = props;

  useEffect(() => {
    document.addEventListener("keydown", escFunction);
    return () => window.removeEventListener("keydown", escFunction);
  }, []);

  const escFunction = e => {
    if (e.keyCode === 27) {
      closeMenu();
    }
  };
  const classes = useStyles();
  return (
    <div
      className={
        isSendToComet
          ? classes.sendFindCometBackDrop
          : classes.menuUploadFileBackDrop
      }
      onClick={closeMenu}
    >
      <div
        className={classes.menuUploadFile}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={
          isSendToComet
            ? { position: "fixed", bottom: "64px", left: "24px" }
            : {}
        }
      >
        <div
          className={classes.menuUploadFileItem}
          onClick={() => {
            uploadNewFile();
            closeMenu();
          }}
        >
          <img src={uploadFromDeviceIcon} width="50" height="50" alt="" />
          <span style={{ marginLeft: "20px", fontSize: "16px" }}>
            からファイルを追加
          </span>
        </div>
        <hr
          style={{
            height: "0px",
            margin: "0px",
            backgroundColor: "#A8A8A8"
          }}
        />
        <div
          className={classes.menuUploadFileItem}
          onClick={() => {
            chooseExistedFile();
            closeMenu();
          }}
        >
          <img src={chooseExistedIcon} width="50" height="50" alt="" />
          <span style={{ marginLeft: "20px", fontSize: "16px" }}>
            からファイルを追加
          </span>
        </div>
      </div>
    </div>
  );
}

export default MenuUploadFile;
