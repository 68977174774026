import React from "react";

function IconTabFavorite(props) {
  const { isActive, smallSize } = props;
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={smallSize ? "30" : "40"}
      height={smallSize ? "30" : "40"}
      viewBox="0 0 40 40"
    >
      <g fill="none" transform="translate(89 -413.308)">
        <path d="M0 0H40V40H0z" transform="translate(-89 413.308)"></path>
        <path
          d="M15.744 3.224a2 2 0 013.513 0l3.376 6.2a2 2 0 001.434 1.017l7.043 1.15a2 2 0 011.08 3.4l-4.9 4.809a2 2 0 00-.575 1.728l1.03 6.739a2 2 0 01-2.837 2.108l-6.548-3.117a2 2 0 00-1.72 0l-6.548 3.119a2 2 0 01-2.837-2.108l1.03-6.739a2 2 0 00-.575-1.73l-4.9-4.815a2 2 0 011.08-3.4l7.043-1.15a2 2 0 001.435-1.015z"
          transform="translate(-86 417.308)"
        ></path>
        <path
          fill={isActive ? "#00356D" : "#c7c7c7"}
          d="M17.5 3.28c-.16 0-.56.046-.79.47l-3.376 6.196a3.08 3.08 0 01-2.222 1.577l-7.043 1.15a.878.878 0 00-.713.616.878.878 0 00.227.914l4.898 4.815a3.108 3.108 0 01.89 2.679l-1.029 6.738a.867.867 0 00.2.717c.174.202.431.323.688.323a.9.9 0 00.389-.091l6.548-3.12a3.12 3.12 0 011.333-.3c.458 0 .92.104 1.333.3l6.548 3.12a.9.9 0 00.39.091c.256 0 .513-.12.687-.323a.867.867 0 00.2-.717l-1.03-6.738a3.108 3.108 0 01.891-2.68l4.898-4.814a.878.878 0 00.227-.914.878.878 0 00-.713-.616l-7.043-1.15a3.08 3.08 0 01-2.222-1.577L18.29 3.75a.881.881 0 00-.79-.47m0-1.1a1.98 1.98 0 011.756 1.044l3.376 6.196a2 2 0 001.434 1.017l7.042 1.15c1.616.264 2.248 2.252 1.08 3.4l-4.898 4.815a2 2 0 00-.574 1.729l1.03 6.738c.194 1.274-.819 2.306-1.976 2.306-.284 0-.576-.062-.862-.198l-6.548-3.12a2 2 0 00-1.72 0l-6.548 3.12a1.996 1.996 0 01-.862.198c-1.157 0-2.17-1.032-1.975-2.306l1.03-6.738a2 2 0 00-.575-1.729l-4.898-4.815c-1.168-1.148-.536-3.136 1.08-3.4l7.042-1.15a2 2 0 001.434-1.017l3.376-6.196A1.98 1.98 0 0117.5 2.18z"
          transform="translate(-86 417.308)"
        ></path>
      </g>
    </svg>
  );
}

IconTabFavorite.defaultProps = {
  isActive: false,
  smallSize: false
};

export default IconTabFavorite;
