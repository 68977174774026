import { combineReducers } from "redux";
import authReducers from "../../components/auth/reducer/reducer";
import errorReducers from "./errorReducers";
import { staffsReducer } from "../../components/users/Staff/reducer/reducer";
import { staffLogBuildReducer } from "../../components/users/StaffLogBuild/reducer/reducer";
import { dashboardsReducer } from "../../components/dashboard/reducer/reducer";
import rootCarpentersReducer from "../../components/users/Carpenter/reducer/reducer";
import { storesReducer } from "../Stores/reducer/reducer";
import { authsReducer } from "../Select-Auth/reducer/reducer";
import rootSitesReducer from "../../components/sites/reducer/reducer";
import rootRobotsReducer from "../../components/robots/reducer/reducer";
import { profileReducer } from "../../components/profile/reducer/reducer";
import { builderReducer } from "components/shops/reducer/reducer";
import { featureReducer } from "components/features/reducer/reducer";

export default combineReducers({
  auth: authReducers,
  errors: errorReducers,
  staffsReducer: staffsReducer,
  carpentersReducer: rootCarpentersReducer,
  dashboardsReducer: dashboardsReducer,
  storesReducer: storesReducer,
  authsReducer: authsReducer,
  rootSitesReducer: rootSitesReducer,
  rootRobotsReducer: rootRobotsReducer,
  profileReducer: profileReducer,
  builderReducer: builderReducer,
  featureReducer: featureReducer,
  staffLogBuildReducer: staffLogBuildReducer
});
