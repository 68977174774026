import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Component from "../Common";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { format } from "date-fns";
import { getType } from "../reducer/reducer";
import imageSrc from "../../../../common/img/log.png";

import { fetchInfoDataSP } from "../service/service";

import { Card, CardMedia, CardContent } from "@material-ui/core";
import { createUserAcknowledgement } from "../../../../common/Stores/service/service";
import RobotStatus from "common/RobotStatus";
import { BUTTON_LOGKUN, ICON_LOGKUN } from "common/constants/constants";
import { getWebSocketRobotURL } from "utils/mapper";
import {
  wsUpdatedRobots,
  wsClearRobots
} from "../../../robots/robotDetails/service/service";
import { FETCH_SITE_ROBOT_SP_SUCCESS } from "../constant/constant";
import PhoneIcon from "@material-ui/icons/Phone";
import logmeetConfig from "../../../../common/config/LogmeetConfig";
import { configStorage } from "../../../../common/storage/storage";

class SiteRobotSP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogInfoRobotShow: false,
      isDialogDeleteShow: false,
      popoverValue: {},
      userType: "",
      isDialogStaffShow: false,
      isDialogSupplierShow: false,
      isDialogEdit: false,
      anchorEl: null,
      valuesEdit: {
        robotName: "",
        location: "",
        assignEndDate: new Date()
      },
      checkError: ""
    };
  }

  fetch() {
    const { fetchInfoDataSP, sitesUC } = this.props;
    fetchInfoDataSP(sitesUC);
  }

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);
    if (nextProps.type === "PUT_SITE_INFO_ROBOT_ERROR") {
      this.setState({
        checkError: nextProps.error
      });
    }
    if (nextProps.type === "PUT_SITE_INFO_ROBOT_SUCCESS") {
      this.setState({
        isDialogEdit: false
      });
    }

    if (nextProps.type === FETCH_SITE_ROBOT_SP_SUCCESS) {
    }
  }

  onCreateUserAcknowledgement = (id, status) => () => {
    const value = {
      uniqueCode: id
    };
    if (status) {
      this.props.createUserAcknowledgement(value);
    }
  };

  render() {
    const {
      robots,
      builders,
      suppliers,
      siteDetail,
      robotsUpdate
    } = this.props;

    return (
      <Grid className="pd-15">
        <Grid container>
          <Grid item md={12}>
            <Typography
              variant="subtitle1"
              className="font-family mb-15 text-bold section-title"
            >
              詳細情報
            </Typography>

            <Typography variant="subtitle1" className="font-family mb-15 text-over-wrap">
              <Typography
                variant="subtitle2"
                component="span"
                className="field-name-info font-family"
              >
                現場名
              </Typography>
              {siteDetail.name}
            </Typography>
            <Typography variant="subtitle1" className="font-family mb-15">
              <Typography
                variant="subtitle2"
                component="span"
                className="field-name-info font-family"
              >
                現場監督
              </Typography>
              {siteDetail.employeeNames && siteDetail.employeeNames.join("・")}
            </Typography>

            <Typography variant="subtitle1" className="font-family mb-15 text-over-wrap">
              <Typography
                variant="subtitle2"
                component="span"
                className="field-name-info font-family"
              >
                住所
              </Typography>
              {siteDetail.address}
            </Typography>
          </Grid>
        </Grid>
        <Grid className="third-tab-table mt-25">
          <Grid container className="mb-25">
            <Typography
              variant="subtitle1"
              className="font-family mb-15 text-bold section-title"
            >
              ロボット情報
            </Typography>
            {robots &&
              robots.map((robot, index) => (
                <Card className="robot-box mb-25" key={index}>
                  <Grid className="wrap-image">
                    <CardMedia image={imageSrc} className="robot-image" />
                  </Grid>
                  <CardContent className="robot-info">
                    <Grid>
                      <Typography
                        variant="subtitle1"
                        className="font-family text-bold section-title"
                      >
                        {robot.robotName}
                      </Typography>
                      <RobotStatus
                        typeOfRobotStatus={ICON_LOGKUN}
                        robots={[robot]}
                        robotsUpdate={[robotsUpdate]}
                      />
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography
                            variant="subtitle1"
                            className="font-family"
                          >
                            現場登録日
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            className="font-family"
                          >
                            {robot.assignStartDate &&
                              format(
                                new Date(robot.assignStartDate),
                                "yyyy/M/d"
                              )}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            align="right"
                            variant="subtitle1"
                            className="font-family"
                          >
                            配置終了日
                          </Typography>
                          <Typography
                            align="right"
                            variant="subtitle1"
                            className="font-family"
                          >
                            {robot.assignEndDate &&
                              format(new Date(robot.assignEndDate), "yyyy/M/d")}
                          </Typography>
                        </Grid>
                        {/* <RobotStatus
                          typeOfRobotStatus={BUTTON_LOGKUN}
                          robots={[robot]}
                          isFullWidth={true}
                          robotsUpdate={[robotsUpdate]}
                          forceCreateUserAcknowledgement={true}
                        /> */}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
          </Grid>
          <Grid className="mb-25">
            <Typography
              variant="subtitle1"
              className="font-family mb-15 text-bold section-title"
            >
              担当者情報
            </Typography>
            <TableContainer>
              <Table size="small" aria-label="table" className="sites-table">
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell className="white-text head-30">
                      担当者名
                    </TableCell>
                    <TableCell className="white-text head-30">役割</TableCell>
                    <TableCell className="white-text head-30">
                      現場配属日
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="table-body">
                  {builders &&
                    builders.map(builder => (
                      <TableRow key={builder.uniqueCode}>
                        <TableCell className="width-30">
                          {builder.name}
                        </TableCell>
                        <TableCell className="width-30">
                          {builder.jobName}
                        </TableCell>
                        <TableCell className="width-40">
                          {builder.assignDate &&
                            format(
                              new Date(builder.assignDate),
                              "yyyy年M月d日"
                            )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid className="mb-25">
            <Typography
              variant="subtitle1"
              className="font-family mb-15 text-bold section-title"
            >
              職人情報
            </Typography>
            <TableContainer>
              <Table size="small" aria-label="table" className="sites-table">
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell className="white-text head-30">会社名</TableCell>
                    <TableCell className="white-text head-30">職人名</TableCell>
                    <TableCell className="white-text head-40">
                      個人携帯
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="table-body">
                  {suppliers &&
                    suppliers.map(supplier => (
                      <TableRow key={supplier.uniqueCode}>
                        <TableCell className="width-30">
                          {supplier.supplierName}
                        </TableCell>
                        <TableCell className="width-30">
                          <Grid container alignItems="center">
                            {supplier.name}
                            {supplier.job === 1 ? (
                              <Typography variant="caption" className="tag">
                                {supplier.jobName}
                              </Typography>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell className="width-40">
                          <a href={`tel:${supplier.phone}`} className="phone">
                            {supplier.phone}
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  robots: state.rootSitesReducer.siteInfoReducer.siteRobotSp,
  builders: state.rootSitesReducer.siteInfoReducer.siteBuilderSp,
  suppliers: state.rootSitesReducer.siteInfoReducer.siteSupplierSp,
  siteDetail: state.rootSitesReducer.siteInfoReducer.siteDetail,
  type: getType(state.rootSitesReducer.sitesReducer.type),
  robotsUpdate:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .wsUpdatedRobots
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInfoDataSP,
      createUserAcknowledgement,
      wsUpdatedRobots,
      wsClearRobots
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SiteRobotSP));
