import React, { Component } from "react";
import { api } from "../../../common/api/api";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { dispatchError as fillError } from "../../../common/Stores/service/service";
import { setRedirectFromUrl } from "./action/action";
import querySearch from "stringquery";

class RedirectLogWalkViewer extends Component {
  sitesUC = null;

  constructor(props) {
    super(props);
    this.isBuilder = !props.match.path.startsWith("/sp/");
    this.home = this.isBuilder ? "" : "/sp";
    this.classification = this.isBuilder ? "builder" : "supplier";
  }

  to(lastShootsUC, sitesUC, spotsUC) {
    let params = lastShootsUC;

    if (!lastShootsUC) {
      params = `no-image?sitesUC=${sitesUC}&spotsUC=${spotsUC}`;
    }

    this.props.history.replace(this.home + "/logwalk-viewer/shoots/" + params);
  }

  componentWillMount() {
    const spotsUC = this.props.match.params.spotsUC;
    const paramsSearch = querySearch(this.props.location.search);
    api
      .get(`/${this.classification}/logwalk-viewer/spots/${spotsUC}`)
      .then(res => {
        if (res.data === undefined) return Promise.reject();
        const data = res.data;
        this.to(data.lastShootsUC, data.sitesUC, spotsUC);
      })
      .catch(e => {
        if (e?.response?.status === 404) {
          this.props.fillError(e);
        }
      });
  }

  componentWillUnmount() {
    this.props.setRedirectFromUrl({
      isRedirectFromUrl: true,
      isDialog: this.props.location.state && this.props.location.state.isDialog,
      isCardList:
        this.props.location.state && this.props.location.state.isCardList,
      currentDrawingUC:
        this.props.location.state && this.props.location.state.currentDrawingUC,
      logwalk: this.props.location.state && this.props.location.state.logwalk,
      from: this.props.location.state && this.props.location.state.from
    });
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  fillError,
  setRedirectFromUrl
})(withRouter(RedirectLogWalkViewer));
