import {
  POST_REGISTER_START,
  POST_LOGIN_ERROR,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_START,
  POST_REGISTER_SUCCESS,
  POST_REGISTER_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  SIGNUP_SUCCESS,
  FETCH_REGEX_SUCCESS,
  FETCH_AVAILABLE_URL_SUCCESS,
  FORGOT_PASSWORD_SP_SUCCESS,
  FORGOT_PASSWORD_LOGBUILD_SUCCESS
} from "../constant/constant";
import { SET_CURRENT_USER } from "../../../actions/types";

export const registerStart = () => {
  return {
    type: POST_REGISTER_START
  };
};

export const registerSuccess = () => {
  return {
    type: POST_REGISTER_SUCCESS
  };
};

export const registerError = () => {
  return {
    type: POST_REGISTER_ERROR
  };
};

export const loginStart = () => {
  return {
    type: POST_LOGIN_START
  };
};

export const loginSuccess = () => {
  return {
    type: POST_LOGIN_SUCCESS
  };
};

export const loginError = () => {
  return {
    type: POST_LOGIN_ERROR
  };
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const forgotPasswordSuccess = status => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    status: status
  };
};

export const signupSuccess = status => {
  return {
    type: SIGNUP_SUCCESS,
    status: status
  };
};

export const fetchRegexSuccess = regex => {
  return {
    type: FETCH_REGEX_SUCCESS,
    regex: regex
  };
};

export const fetchAvailableUrlSuccess = () => {
  return {
    type: FETCH_AVAILABLE_URL_SUCCESS
  };
};

export const forgotPasswordSPSuccess = status => {
  return {
    type: FORGOT_PASSWORD_SP_SUCCESS,
    status: status
  };
};

export const forgotPassworLogBuildSuccess = status => {
  return {
    type: FORGOT_PASSWORD_LOGBUILD_SUCCESS,
    status: status
  };
};
