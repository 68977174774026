import React, { memo } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Field } from "formik";
import InputControl from "../InputControl";
import RadioGroupControl from "../RadioGroupControl";
import Fab from "@material-ui/core/Fab";
import CloseIcon from '@material-ui/icons/Close';

const DialogContactRepair = props => {
  const initialValues = {
    name: "",
    phone: "",
    radio: "",
    email: "",
    description: ""
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={props.onClose}
    >
      {formik => (
        <Dialog
          open={props.open}
          onClose={() => {}}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="lg"
          PaperProps={{
            className: "dialog dialog-sign-up dialog-min-height"
          }}
        >
          <Fab
            onClick={props.onClose}
            size="small"
            className="white-text close-button"
          >
            <CloseIcon />
          </Fab>

          <DialogTitle disableTypography>
            <Grid container justify="space-between" alignItems="center">
              <Typography variant="h4" className="dialog-title">
                現場登録
              </Typography>
              <Grid className="phone-contact">
                <Typography>
                  <Typography className="block" variant="caption">
                    現場登録 現場登録 現場登録
                  </Typography>
                  <Typography className="block" variant="caption">
                    現場登録 現場登録 現場登録
                  </Typography>
                </Typography>
                <Typography variant="h5" className="phone-number">
                  <FontAwesomeIcon icon={faPhoneAlt} />
                  123-456-789
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className="content-wrap">
            {props.dialogType === "Contact" ? (
              <Field
                label="現場名"
                name="name"
                onChange={formik.handleChange}
                variant="filled"
                className="input-reset"
                component={InputControl}
              />
            ) : (
              <Field
                label="現場名"
                name="radio"
                onChange={formik.handleChange}
                options={["Male", "Female"]}
                variant="filled"
                className="radio-group-reset"
                component={RadioGroupControl}
              />
            )}
            <Field
              label="現場名"
              name="phone"
              onChange={formik.handleChange}
              variant="filled"
              className="input-reset"
              component={InputControl}
            />
            <Field
              label="現場名"
              name="email"
              onChange={formik.handleChange}
              variant="filled"
              className="input-reset"
              component={InputControl}
            />
            <Field
              label="現場名"
              name="description"
              onChange={formik.handleChange}
              variant="filled"
              multiline={true}
              rows={4}
              rowsMax={4}
              className="input-reset"
              component={InputControl}
            />
          </DialogContent>
          <DialogActions className="dialog-action wrap">
            <Button
              onClick={formik.handleSubmit}
              color="primary"
              className="dialog-button"
            >
              登録
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

DialogContactRepair.propTypes = {
  uniqueCode: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dialogType: PropTypes.string
};

export default memo(DialogContactRepair);
