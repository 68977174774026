import React from "react";

function IconTabFile(props) {
  const { isActive, smallSize } = props;
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={smallSize ? "30" : "40"}
      height={smallSize ? "30" : "40"}
      viewBox="0 0 40 40"
    >
      <g transform="translate(89 -413.308)">
        <path
          fill="none"
          d="M0 0H40V40H0z"
          transform="translate(-89 413.308)"
        ></path>
        <path
          fill={isActive ? "#00356D" : "#c7c7c7"}
          d="M-54.643 428.757h-1.843v-3.31a1.444 1.444 0 00-1.443-1.439H-71l-2.64-3.7h-9.917A1.445 1.445 0 00-85 421.751v23.068a1.348 1.348 0 001.351 1.363h23.638a1.341 1.341 0 001.294-.957l5.4-14.805v-.093a1.414 1.414 0 00-1.326-1.57zm-29.301-7.006a.387.387 0 01.387-.387h9.374l2.64 3.7h13.614a.387.387 0 01.387.387v3.31h-20.736a1.354 1.354 0 00-1.293.957l-4.373 11.922zm24.221 23.157a.3.3 0 01-.288.22h-23.635a.292.292 0 01-.237-.117.286.286 0 01-.065-.215l5.381-14.76a.3.3 0 01.289-.228h23.635c.2 0 .252.288.265.432z"
        ></path>
      </g>
    </svg>
  );
}

IconTabFile.defaultProps = {
  isActive: false,
  smallSize: false
};

export default IconTabFile;
