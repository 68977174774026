import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Popover from "@material-ui/core/Popover";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import "../../../common/css/carpenter.css";
import "../../../common/css/base.css";
import { bindActionCreators } from "redux";
import { getCarpenters } from "./reducer/carpenter";
import { fetchCarpenter } from "./service/service";
import { fetchAuths } from "../../../common/Select-Auth/service/service";
import CarpenterDialog from "./CarpenterDialog";
import CarpenterPasswordDialog from "./CarpenterPasswordDialog";
import { regexSignUp } from "../../auth/service/service";
import {
  translateValidate,
  messageValidate
} from "../../../common/Stores/service/service";
import { apiError } from "../../../common/Stores/action/action";
import Sp from "../../../common/breakpoints/Sp";
import Pc from "../../../common/breakpoints/Pc";
import CarpenterSP from "./CarpenterSP";
import CarpenterPC from "./CarpenterPC";

class Carpenter extends Component {
  state = {
    isDialogShow: false,
    isDialogDeleteShow: false,
    isDialogPasswordShow: false,
    unicodeEdit: "",
    checkUpdate: false,
    carpenterData: [],
    anchorEl: null,
    carpentersFilter: [],
    furiganaSearch: "",
    companySearch: "",
    fetchInitSuccess: false
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.carpenters !== this.props.carpenters && 
      this.props.carpenters.length > 0 &&
      this.state.fetchInitSuccess
    ) {
      
      this.filter(
        {
          companySearch: this.state.companySearch,
          furiganaSearch: this.state.furiganaSearch
        });
    }
  }

  componentDidMount() {
    const {
      fetchCarpenter,
      regexSignUp,
      translateValidate,
      messageValidate
    } = this.props;
    fetchCarpenter(() => this.setState({fetchInitSuccess: true}));
    regexSignUp();
    translateValidate();
    messageValidate();
  }

  onChangeData = data => {
    this.setState({
      carpenterData: data
    });
  };

  onFuriganaChange = data => {
    this.setState({
      furiganaSearch: data
    });
  
    this.filter(
      {
        furiganaSearch: data,
        companySearch: this.state.companySearch
      });
  };

  onCompanyChange = data => {
    this.setState({
      companySearch: data
    });

    this.filter(
      {
        companySearch: data,
        furiganaSearch: this.state.furiganaSearch
      });
  };

  filter = data => {
    let FilterArray = [];
    FilterArray = this.props.carpenters.filter(function (element) {
      return (
        (element.furigana.includes(data.furiganaSearch) ||
          element.name.includes(data.furiganaSearch)) &&
        (element.supplierName.includes(data.companySearch) ||
          element.supplierNameFurigana.includes(data.companySearch))
      );
    });
    this.setState({
      carpentersFilter: FilterArray
    });
  };

  showPopover = row => event => {
    this.props.apiError(null);
    this.setState({
      anchorEl: event.currentTarget,
      unicodeEdit: row.uniqueCode,
      checkUpdate: true
    });
  };

  closePopover = () => {
    this.setState({
      anchorEl: null
    });
  };

  showDialog = () => {
    this.setState({
      isDialogShow: true,
      unicodeEdit: "",
      checkUpdate: false
    });
  };

  closeDialog = () => {
    this.setState({
      isDialogShow: false,
      checkUpdate: false
    });
  };

  showDialogEdit = row => {
    this.setState({
      isDialogShow: true,
      anchorEl: null
    });
  };

  toggleDialogPassword = () => {
    this.setState({
      isDialogPasswordShow: !this.state.isDialogPasswordShow,
      anchorEl: null
    });
  };

  toggleDialogDelete = () => {
    this.setState({
      isDialogDeleteShow: !this.state.isDialogDeleteShow,
      isDialogShow: false
    });
  };

  render() {
    const {
      isDialogShow,
      isDialogPasswordShow,
      carpenterData,
      anchorEl,
      isDialogDeleteShow,
      carpentersFilter
    } = this.state;
    const { regex } = this.props;

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (<>
      <Sp>
        <CarpenterSP
          carpenters={carpentersFilter}
          showPopover={this.showPopover}
          showDialog={this.showDialog}
          furiganaSearch={this.state.furiganaSearch}
          companySearch={this.state.companySearch}
          onFuriganaChange={this.onFuriganaChange}
          onCompanyChange={this.onCompanyChange}
        />
      </Sp>
      <Pc>
        <CarpenterPC
          carpenters={carpentersFilter}
          carpenterData={carpenterData}
          showPopover={this.showPopover}
          showDialog={this.showDialog}
          onChangeData={this.onChangeData}
          furiganaSearch={this.state.furiganaSearch}
          companySearch={this.state.companySearch}
          onFuriganaChange={this.onFuriganaChange}
          onCompanyChange={this.onCompanyChange}
        />
      </Pc>

      {(isDialogShow || isDialogDeleteShow) && (
        <CarpenterDialog
          isDialogShow={isDialogShow}
          isDialogDeleteShow={isDialogDeleteShow}
          toggleDialogDelete={this.toggleDialogDelete}
          onClose={this.closeDialog}
          isEdit={this.state.checkUpdate}
          carpenterId={this.state.unicodeEdit}
        />
      )}

      {isDialogPasswordShow && (
        <CarpenterPasswordDialog
          isDialogShow={isDialogPasswordShow}
          onClose={this.toggleDialogPassword}
          carpenterId={this.state.unicodeEdit}
          regex={regex}
        />
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={this.closePopover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MenuList className="popover-sites">
          <MenuItem onClick={this.showDialogEdit} className="popover-item">
            編集
          </MenuItem>
          <MenuItem onClick={this.toggleDialogPassword}>
            パスワード変更
          </MenuItem>
        </MenuList>
      </Popover>
    </>);
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  carpenters: getCarpenters(
    state.carpentersReducer.carpentersReducer.carpenter
  ),
  regex: state.auth.regex,
  type: state.carpentersReducer.carpentersReducer.type
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCarpenter: fetchCarpenter,
      fetchAuths: fetchAuths,
      regexSignUp: regexSignUp,
      translateValidate: translateValidate,
      apiError: apiError,
      messageValidate: messageValidate
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Carpenter));
