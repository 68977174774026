import { connect } from "react-redux";
import { Lp as SuperLp } from "../Lp";
import { setCurrentUser } from "../../auth/action/action"

export class Lp extends SuperLp {

  constructor(props, context) {
    super(props, context, '/sp/');
  }

}

export default connect(
  () => ({}),
  {setCurrentUser}
)(Lp);
