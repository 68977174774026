import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import IconTabFavorite from "../hotspotInfoIcons/IconTabFavorite";
import IconTabFile from "../hotspotInfoIcons/IconTabFile";
import IconTabMessage from "../hotspotInfoIcons/IconTabMessage";

import "../common/css/hostpotInfo.css";

const mediaQueryTabletOrMobile = "(max-width: 960px)";

const useStyles = makeStyles({
  hotspotTab: {
    margin: "0px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "calc(100vh - 22px)"
  },
  buttonTab: {
    width: "165px",
    display: "flex",
    justifyContent: "center",
    borderBottom: "2px solid transparent",
    color: "#C7C7C7",
    fontSize: "18px",
    fontWeight: "normal",
    "& span": {
      marginLeft: "4px"
    }
  },
  buttonTabSP: {
    width: "165px",
    display: "flex",
    justifyContent: "center",
    borderBottom: "2px solid transparent",
    color: "#C7C7C7",
    fontSize: "14px",
    fontWeight: "normal",
    paddingLeft: "2px",
    "& span": {
      marginLeft: "4px"
    }
  }
});

function HotspotTabs(props) {
  const { tabFile, tabMessage, currentTab, setCurrentTab } = props;

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowHeight(window.innerHeight);
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);
  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: mediaQueryTabletOrMobile });
  return (
    <div
      className={classes.hotspotTab}
      style={
        isTabletOrMobile
          ? { height: windowHeight - 32 }
          : { height: windowHeight - 22 }
      }
    >
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #C7C7C7",
          margin: "0px",
          backgroundColor: "white"
        }}
      >
        <Button
          type="button"
          onClick={() => {
            if (currentTab !== "message") {
              setCurrentTab("message");
            }
          }}
          style={
            currentTab === "message"
              ? {
                  color: "#00356D",
                  fontWeight: "bold",
                  borderBottom: "2px solid #00356de6",
                  borderRadius: "0px"
                }
              : null
          }
          className={isTabletOrMobile ? classes.buttonTabSP : classes.buttonTab}
        >
          <IconTabMessage
            isActive={currentTab === "message"}
            smallSize={isTabletOrMobile}
          />
          <span>チャット</span>
        </Button>

        <Button
          type="button"
          onClick={() => {
            if (currentTab !== "file") {
              setCurrentTab("file");
            }
          }}
          style={
            currentTab === "file"
              ? {
                  color: "#00356D",
                  fontWeight: "bold",
                  borderBottom: "2px solid #00356de6",
                  borderRadius: "0px"
                }
              : null
          }
          className={isTabletOrMobile ? classes.buttonTabSP : classes.buttonTab}
        >
          <IconTabFile
            isActive={currentTab === "file"}
            smallSize={isTabletOrMobile}
          />
          <span style={{}}>ファイル</span>
        </Button>
        {/*<Button
          type="button"
          className={isTabletOrMobile ? classes.buttonTabSP : classes.buttonTab}
        >
          <IconTabFavorite />
          <span>ャット</span>
        </Button>*/}
      </div>
      <div
        id="messages-block"
        style={{ flexGrow: "1", display: "flex", height: "100%" }}
      >
        {currentTab === "file" ? tabFile : tabMessage}
      </div>
    </div>
  );
}

HotspotTabs.propTypes = {};

export default HotspotTabs;
