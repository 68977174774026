import React, { memo } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
const CompassSettingConfirm = ({
  onClose,
  title,
  textButton1,
  onClickButton1,
  disabledButton1,
  textButton2,
  onClickButton2,
  disabledButton2,
  ...rest
}) => {
  return (
    <Dialog
      aria-labelledby="max-width-dialog-title"
      fullWidth
      maxWidth="xs"
      onClose={() => {}}
      {...rest}

    >
      <Fab onClick={onClose} size="small" className="white-text close-button">
        <CloseIcon />
      </Fab>
      <DialogTitle disableTypography>
        <Typography variant="h5" className="dialog-title white-text text-center">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent className="confirm-content">
        <Button
          disabled={disabledButton1}
          onClick={onClickButton1}
          className="compass-setting-confirm-dialog-btn-option"
        >
          {textButton1}
        </Button>
        <Button
          disabled={disabledButton2}
          onClick={onClickButton2}
          className="compass-setting-confirm-dialog-btn-option"
        >
          {textButton2}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

CompassSettingConfirm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  textButton1: PropTypes.string,
  textButton2: PropTypes.string
};

export default memo(CompassSettingConfirm);
