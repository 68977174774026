import { Component as Base } from "react";

export default class Component extends Base {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if(!this.props.auth.isAuthenticated)
      this.props.history.push("/login");
  }
}
