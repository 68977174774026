import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";

const mediaQueryTabletOrMobile = "(max-width: 960px)";
const mediaQueryTiniMobile = "(max-width: 380px)";

const useStyles = makeStyles({
  pc: {
    width: "150px",
    height: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#333",
    backgroundColor: "#f8f9f9"
  },
  sp: {
    width: "150px",
    height: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#333",
    backgroundColor: "#f8f9f9"
  }
});

function SvgButtonHover(props) {
  const { Icon, title, handleClick, activeBg, withBorder } = props;
  const [isActive, setIsActive] = useState(false);
  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: mediaQueryTabletOrMobile });
  const isTiniMobile = useMediaQuery({ query: mediaQueryTiniMobile });

  const getStyle = () => {
    if (isActive && withBorder)
      return {
        color: "white",
        backgroundColor: activeBg,
        borderLeft: "1px solid black",
        borderRight: "1px solid black",
        width: "180px"
      };
    else if (isActive && !withBorder) {
      return {
        color: "white",
        backgroundColor: activeBg
      };
    } else if (!isActive && withBorder) {
      return {
        borderLeft: "1px solid black",
        borderRight: "1px solid black",
        width: "180px"
      };
    }
    return {};
  };

  return (
    <button
      onMouseOver={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      onTouchStart={() => setIsActive(true)}
      onTouchEnd={() => setIsActive(false)}
      onClick={() => handleClick()}
      className={isTabletOrMobile ? classes.sp : classes.pc}
      style={getStyle()}
    >
      <Icon isActive={isActive} size={isTabletOrMobile ? "30" : "40"} />
      <span style={isTiniMobile ? { fontSize: "12px" } : null}>{title}</span>
    </button>
  );
}

export default SvgButtonHover;
