export const FETCH_ROBOT_DETAIL_INFO_SUCCESS =
  "FETCH_ROBOT_DETAIL_INFO_SUCCESS";
export const FETCH_ROBOT_LOG_BUILD_DETAIL_INFO_SUCCESS =
  "FETCH_ROBOT_LOG_BUILD_DETAIL_INFO_SUCCESS";
export const FETCH_ROBOT_INFO_EDIT_SUCCESS = "FETCH_ROBOT_INFO_EDIT_SUCCESS";
export const PUT_ROBOT_INFO_STARTED = "PUT_ROBOT_INFO_STARTED";
export const PUT_ROBOT_INFO_SUCCESS = "PUT_ROBOT_INFO_SUCCESS";
export const DELETE_ROBOT_INFO_STARTED = "DELETE_ROBOT_INFO_STARTED";
export const DELETE_ROBOT_INFO_SUCCESS = "DELETE_ROBOT_INFO_SUCCESS";
export const RETURN_ROBOT_SUCCESS = "RETURN_ROBOT_SUCCESS";
