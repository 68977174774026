import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { PUT_PASSWORD_SUCCESS, AUTH_ERROR } from "./constant/constant";
import { mountPasswordDialog, putPassword } from "../Staff/service/service";
import CommonDialog from "../../CommonDialog";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";
import { apiError } from "../../../common/Stores/action/action";
import { translateMessage } from "utils/mapper";
import { checkAuth } from "common/Stores/service/service";

class ChangePasswordDialog extends React.Component {
  fieldList = [
    {
      name: "password",
      label: "新しいパスワード",
      type: DIALOG_FIELD_TYPES.Password,
      isRequired: true
    },
    {
      name: "password_confirmation",
      label: "新しいパスワード（再入力）",
      type: DIALOG_FIELD_TYPES.Password,
      isRequired: true
    }
  ];

  componentWillMount() {
    this.props.mountPasswordDialog();
    if (this.props.auth.user.uniqueCode !== this.props.staffId) {
      this.props.checkAuth();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type !== this.props.type && nextProps.type === AUTH_ERROR) {
      this.props.checkAuth();
    }
    if (nextProps.type === PUT_PASSWORD_SUCCESS) {
      this.props.onClose();
    }
  }

  onSubmit = value => {
    const { message } = this.props;
    const { password, password_confirmation } = value;
    const messageError = {
      password: "",
      password_confirmation: ""
    };
    this.props.apiError();
    if (password || password_confirmation) {
      if (
        password_confirmation === password &&
        password.match(this.props.regex["password"])
      ) {
        this.props.putPassword(this.props.staffId, password);
        return;
      } else {
        if (password && password_confirmation !== password) {
          messageError["password_confirmation"] = translateMessage(
            this.getMessage("match", message),
            this.props.regexObject
          );
        }
      }
    }

    if (password && !password.match(this.props.regex["password"])) {
      messageError["password"] = translateMessage(
        this.getMessage("password", message),
        this.props.regexObject
      );
    }

    if (!password) {
      messageError["password"] = translateMessage(
        this.getMessage("password", message),
        this.props.regexObject
      );
    }

    if (!password_confirmation) {
      messageError["password_confirmation"] = translateMessage(
        this.getMessage("required", message),
        this.props.regexObject
      );
    }

    if (!password && !password_confirmation) {
      messageError["password"] = translateMessage(
        this.getMessage("password", message),
        this.props.regexObject
      );
      messageError["password_confirmation"] = translateMessage(
        this.getMessage("required", message),
        this.props.regexObject
      );
    }
    this.props.apiError(messageError);
  };

  getMessage = (key, message) => {
    return message[key];
  };

  render() {
    const { isDialogShow, submitErrors, initialValues, onClose } = this.props;
    return (
      <CommonDialog
        title={"パスワード変更"}
        isDialogShow={isDialogShow}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        onClose={onClose}
        fieldList={this.fieldList}
        submitButtonTitle={"登録"}
        submitErrors={submitErrors}
        isSingleColumn={true}
        onDeleteClick={this.onDeleteClick}
      />
    );
  }
}

ChangePasswordDialog.propTypes = {
  submitErrors: PropTypes.object,
  isDialogShow: PropTypes.bool,
  onClose: PropTypes.func
};

const mapStateToProps = state => ({
  submitErrors: state.storesReducer.error,
  type: state.staffsReducer.type,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message,
  auth:state.auth
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      mountPasswordDialog: mountPasswordDialog,
      putPassword: putPassword,
      apiError,
      checkAuth
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordDialog);
