import {
  FETCH_ROBOT_DETAIL_INFO_SUCCESS,
  FETCH_ROBOT_INFO_EDIT_SUCCESS,
  PUT_ROBOT_INFO_STARTED,
  PUT_ROBOT_INFO_SUCCESS,
  FETCH_ROBOT_LOG_BUILD_DETAIL_INFO_SUCCESS,
  RETURN_ROBOT_SUCCESS,
  DELETE_ROBOT_INFO_SUCCESS
} from "../constant/constant";

const initialState = {
  robotInfo: [],
  robotLogBuildInfo: [],
  robotInfoEdit: {
    uniqueCode: "",
    robotName: ""
  },
  type: ""
};

export const robotDetailInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROBOT_DETAIL_INFO_SUCCESS:
      return {
        ...state,
        type: "",
        robotInfo: action.robotInfo
      };

    case FETCH_ROBOT_LOG_BUILD_DETAIL_INFO_SUCCESS:
      return {
        ...state,
        type: "",
        robotLogBuildInfo: action.robotLogBuildInfo
      };

    case FETCH_ROBOT_INFO_EDIT_SUCCESS:
      return {
        ...state,
        type: action.type,
        robotInfoEdit: action.robotInfoEdit
      };
    case PUT_ROBOT_INFO_STARTED:
      return {
        ...state,
        type: action.type
      };
    case PUT_ROBOT_INFO_SUCCESS:
      return {
        ...state,
        type: action.type
      };
    case RETURN_ROBOT_SUCCESS:
      return {
        ...state,
        type: action.type
      };

    case DELETE_ROBOT_INFO_SUCCESS:
      return {
        ...state,
        type: action.type
      };
  
    default:
      return state;
  }
};

export const getRobotDetailInfo = state => state;
export const getRobotInfoEdit = state => state;
export const getType = state => state;
