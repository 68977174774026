import {
  FETCH_BUILDER_LIST_SUCCESSS,
  POST_BUILDER_SUCCESS
} from "../constant/constant";

export const fetchBuilderListSuccess = builders => {
  return {
    type: FETCH_BUILDER_LIST_SUCCESSS,
    builders: builders
  };
};

export const postBuilderSuccess = data => {
  return {
    type: POST_BUILDER_SUCCESS,
    data
  };
};
