import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Component from "./Upload";
import { search, download, post } from "../../../actions/fileActions";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";

import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { format } from "date-fns";
import {
  downloadFile,
  fetchFile,
  postFile,
  searchFile,
  updateSiteFile,
  deleteSiteFile,
  setFileValues
} from "./service/service";
import { dispatchError as putFileError } from "../../../common/Stores/service/service";
import { getFiles } from "./reducer/reducer";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";
import {
  DELETE_FILE_SUCCESS,
  PUT_FILE_SUCCESS,
  GET_FILE_SUCCESS
} from "./constant/constant";
import PaginationControl from "../../../common/Pagination";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import {
  faChevronRight,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {
  translateValidate,
  messageValidate
} from "../../../common/Stores/service/service";

class FilesBuilder extends Component {
  state = {
    fileData: [],
    typeReload: [PUT_FILE_SUCCESS, DELETE_FILE_SUCCESS],
    isDialogPDFOpen: false,
    disalbeNextBack: false,
    currentFile: null,
    clientError: null
  };

  fieldList = [
    {
      name: "name",
      label: "ファイル名",
      type: DIALOG_FIELD_TYPES.Text,
      isRequired: true
    }
  ];

  componentDidMount() {}

  onFileClick(id, blob) {
    this.props.downloadFile(id, blob);
  }

  closeDialogPDF = () => {
    this.setState({ isDialogPDFOpen: false });
  };

  fetch() {}

  showPopover = model => event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget,
      fileValues: {
        id: model.fileId,
        name: model.name
      }
    });
  };

  closePopover = () => {
    this.setState({
      anchorEl: null
    });
  };

  onChangeData = data => {
    this.setState({
      fileData: data
    });
  };

  handleChangeFile(isNext = false) {
    this.setState({ disalbeNextBack: true });
    const { files, fetchFile } = this.props;
    const { currentFile } = this.state;
    const currentIndex = currentFile.index;
    let newIndex = 0;
    if (isNext) {
      newIndex = currentIndex + 1 >= files.length ? 0 : currentIndex + 1;
    } else {
      newIndex = currentIndex - 1 < 0 ? files.length - 1 : currentIndex - 1;
    }
    const newFile = files[newIndex];
    if (newFile) {
      this.setState({
        currentFile: { index: newIndex, name: newFile.name },
        fileValues: {
          id: newFile.fileId,
          name: newFile.name
        }
      });
      fetchFile(newFile.fileId, newFile.name);
    }
  }

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);
    if (
      this.props.type !== nextProps.type &&
      nextProps.type === GET_FILE_SUCCESS
    ) {
      this.setState({ disalbeNextBack: false });
    }
  }

  render() {
    const { files, submitErrors, file } = this.props;
    const {
      anchorEl,
      fileValues,
      isDialogShow,
      isDialogDeleteShow,
      fileData,
      isDialogPDFOpen,
      disalbeNextBack,
      currentFile,
      clientError
    } = this.state;

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <>
        <input
          type="file"
          id="file"
          accept=".pdf"
          multiple
          onChange={e => this.upload(e)}
          style={{ display: "none" }}
        />
        <Grid className="second-tab">
          <Grid container justify="space-between" className="add-image">
            <Button size="small" variant="text" className="button-add">
              <AddIcon className="icon-margin" />
              <label htmlFor="file" style={{ color: "inherit" }}>
                ファイル追加
              </label>
            </Button>
          </Grid>
          <TableContainer>
            <Table size="small" aria-label="table" className="sites-table">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell className="white-text padding-first-th">
                    ファイル名
                  </TableCell>
                  <TableCell className="white-text">作成者</TableCell>
                  <TableCell className="white-text">
                    登録日
                    <ArrowDownwardIcon className="icon-small-size s-va-m margin-left" />
                  </TableCell>
                  <TableCell className="white-text"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {fileData.map((model, index) => (
                  <TableRow
                    key={model.fileId}
                    onClick={() =>
                      this.onFileClick(model.fileId, model.blob)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell className="padding-first-td">
                      {model.name}
                    </TableCell>
                    <TableCell>{model.creator}</TableCell>
                    <TableCell>
                      {format(new Date(model.date), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell align="right">
                      <Fab
                        onClick={this.props.showPopover(model)}
                        size="small"
                        className="more-vert-btn"
                      >
                        <MoreVertIcon />
                      </Fab>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid className="add-image">
            <Button size="small" variant="text" className="button-add">
              <AddIcon className="icon-margin" />
              <label htmlFor="file" style={{ color: "inherit" }}>
                ファイル追加
              </label>
            </Button>
          </Grid>
          <Grid container justify="center">
            <PaginationControl
              limit={20}
              datas={files}
              changeData={this.onChangeData}
            />
          </Grid>
        </Grid>
        {/* {isDialogShow && (
          <CommonDialog
            title={"ファイル情報編集"}
            isDialogShow={isDialogShow}
            initialValues={fileValues}
            onClose={this.closeDialogEdit}
            fieldList={this.fieldList}
            submitButtonTitle={"登録"}
            isSingleColumn={true}
            onSubmit={this.onSubmit}
            submitErrors={clientError ? clientError : submitErrors}
          />
        )}
        <DialogDelete
          open={isDialogDeleteShow}
          onClose={this.closeDialogDelete}
          onDeleteSubmit={this.onDeleteSubmit}
          PaperProps={{
            className: "dialog-sign-up"
          }}
        /> */}
        <Dialog
          open={isDialogPDFOpen}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          onClose={() => {}}
          maxWidth="lg"
          PaperProps={{
            className: "dialog paper-image"
          }}
        >
          <Fab
            onClick={this.closeDialogPDF}
            size="small"
            className="white-text close-button"
          >
            <CloseIcon />
          </Fab>

          <DialogTitle disableTypography className="padding-dialog-image">
            <Grid container justify="space-between">
              <Grid className="log-title">
                <Typography
                  variant="h4"
                  className="dialog-title white-text mr-15"
                >
                  {currentFile && currentFile.name}
                </Typography>
                <Fab
                  onClick={this.props.openDialogEdit}
                  size="small"
                  className="white-text log-button-reset"
                >
                  <EditIcon />
                </Fab>
              </Grid>
              <Fab
                size="small"
                className="white-text log-button-reset"
                onClick={this.closeDialogPDF}
              >
                <CloseIcon />
              </Fab>
            </Grid>
          </DialogTitle>
          <DialogContent className="image-content-dialog padding-dialog-image">
            <iframe src={file} width="100%" height="700px"></iframe>
            <Grid className="button-group-dialog">
              <Fab
                className="white-text reset-button-image"
                disabled={disalbeNextBack}
                onClick={() => this.handleChangeFile(false)}
              >
                <FontAwesomeIcon
                  size="3x"
                  icon={faChevronLeft}
                  className="white-text"
                />
              </Fab>
              <Fab
                className="white-text reset-button-image"
                disabled={disalbeNextBack}
                onClick={() => this.handleChangeFile(true)}
              >
                <FontAwesomeIcon
                  size="3x"
                  icon={faChevronRight}
                  className="white-text"
                />
              </Fab>
            </Grid>
          </DialogContent>
        </Dialog>
        {/* <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.closePopover}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuList className="popover-sites">
            <MenuItem onClick={this.openDialogEdit} className="popover-item">
              編集
            </MenuItem>
            <MenuItem onClick={this.openDialogDelete} className="popover-item">
              削除
            </MenuItem>
          </MenuList>
        </Popover> */}
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  files: getFiles(state.rootSitesReducer.siteInfoReducer.files),
  file: state.rootSitesReducer.siteInfoReducer.file,
  type: state.rootSitesReducer.siteInfoReducer.type,
  fileValues: state.rootSitesReducer.siteInfoReducer.fileValues,
  submitErrors: state.storesReducer.error,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});

export default connect(mapStateToProps, {
  search,
  post,
  download,
  downloadFile,
  fetchFile,
  postFile,
  searchFile,
  updateSiteFile,
  setFileValues,
  putFileError,
  deleteSiteFile,
  translateValidate,
  messageValidate
})(withRouter(FilesBuilder));
