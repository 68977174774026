import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import CustomerInfoTab from "./CustomerInfoTab";
import ContractHistoryTab from "./ContractHistoryTab";
import ShopRobotTab from "./ShopRobotTab";
import ShopFeatureFlagTab from "./ShopFeatureFlagTab";
import { fetchBuilderEntries, fetchBuilderSummary } from "./service/service";

import { finishLoading } from "../../../common/Stores/service/service";

const Element = {
  Info: 1,
  Contract: 2,
  Robot: 3,
  FeatureFlag: 4
};

class Index extends React.PureComponent {
  storesUC = null;

  constructor(props) {
    super(props);
    this.state = {
      model: {},
      sites: [],
      element: Element.Info,
      isDialogShow: false,
      isDialogConfirmShow: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.fetchData(nextProps.match.params.id);
    }
  }

  fetchData = id => {
    this.props.fetchBuilderSummary(id);
    this.props.fetchBuilderEntries();
  };
  componentDidMount() {
    this.fetchData(this.props.match.params.id);
    var url_string = window.location.href;
    var url = new URL(url_string);
    var tab = url.searchParams.get("tab");
    if (tab === "featureflag") {
      this.setState({ element: Element.FeatureFlag });
    }
  }

  selectElement(element) {
    this.setState({ element });
  }

  reloadElement() {
    const element = this.state.element;
    this.setState({ element: Element.None });
    this.setState({ element });
  }

  to(id) {
    this.props.history.push("/logbuild/builders/" + id);
    if (this.state.element !== Element.Info)
      this.setState({ element: Element.Info });
  }

  toSitesPage() {
    this.props.history.push("/sites");
  }

  clearFilter(models) {
    models.forEach(model => (model.match = true));
  }

  filter() {
    const sites = this.state.sites;
    this.clearFilter(sites);
    this.setState({ sites });
  }

  selectStore(storesUC) {
    this.storesUC = storesUC;
    this.filter();
  }

  shouldEnableRobotButton = robots => {
    for (let i = 0; i < robots.length; i++) {
      if (robots[i].useStatus === 1) return true;
    }
    return false;
  };

  render() {
    const { element } = this.state;
    const { entries, match, summary } = this.props;

    return (
      <Grid container className="sites-image">
        <Grid container wrap="nowrap" className="sites-right">
          <Grid className="image-list-wrap">
            <List disablePadding className="image-list">
              {entries.map(entry => (
                <ListItem
                  onClick={() => this.to(entry.uniqueCode)}
                  selected={match.params.id === entry.uniqueCode}
                  key={entry.uniqueCode}
                  button
                  className="image-list-item"
                >
                  <ListItemText className="clip-text" primary={entry.name} />
                </ListItem>
              ))}
            </List>
            <Link to="/logbuild/builders" className="reset-link">
              <Fab variant="extended" className="back-btn">
                <FontAwesomeIcon
                  icon={faArrowCircleLeft}
                  className="icon-margin"
                />
                一覧に戻る
              </Fab>
            </Link>
          </Grid>
          <Grid className="image-content">
            <Grid container wrap="nowrap">
              <Grid container>
                <Typography variant="h4" className="image-title">
                  {summary && summary.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid className="sites-tab">
              <Tabs
                className="tabs"
                value={element}
                onChange={(e, value) => this.selectElement(value)}
                aria-label="simple tabs example"
              >
                <Tab
                  className="tab-button"
                  label="顧客情報​"
                  value={Element.Info}
                />
                {/* <Tab
                  className="tab-button"
                  label="契約履歴"
                  value={Element.Contract}
                /> */}

                <Tab
                  className="tab-button"
                  label="フィーチャーフラグ​"
                  value={Element.FeatureFlag}
                />
                <Tab
                  className="tab-button"
                  label="ロボット​"
                  value={Element.Robot}
                />
              </Tabs>
              {summary && summary.uniqueCode !== undefined && (
                <Grid>
                  {element === Element.Info && (
                    <CustomerInfoTab fetchSumaryData={this.fetchData} />
                  )}
                  {/* {element === Element.Contract && <ContractHistoryTab />} */}
                  {element === Element.Robot && <ShopRobotTab />}
                  {element === Element.FeatureFlag && <ShopFeatureFlagTab />}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  loading: state.storesReducer.pending,
  entries: state.builderReducer.builderDetailReducer.entries,
  summary: state.builderReducer.builderDetailReducer.summary
});

export default connect(mapStateToProps, {
  finishLoading,
  fetchBuilderEntries,
  fetchBuilderSummary
})(withRouter(Index));
