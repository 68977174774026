import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Fab from "@material-ui/core/Fab";
import VisibilityIcon from "@material-ui/icons/Visibility";

import "../../common/css/base.css";
import "../../common/css/dashboard.css";
import { fetchDashBoardSP } from "./service/service";
import { bindActionCreators } from "redux";
import { format } from "date-fns";
import { createUserAcknowledgement } from "../../common/Stores/service/service";
import CloseIcon from "@material-ui/icons/Close";
import { showHideDialogMapFromLogWalk } from "../sites/site/service/service";
import PlayCircleOutlineOutlinedIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import DrawingDialog from "../DrawingDialog";
import RobotStatus from "common/RobotStatus";
import { BUTTON_LOGKUN_SP } from "common/constants/constants";
import { FETCH_DASHBOARDS_SP_SUCCESS } from "./constant/constant";
import { getWebSocketRobotURL } from "utils/mapper";
import { MODE_DEVICE } from "components/sites/site/LogWalkViewer/constants";
import Pc from "../../common/breakpoints/Pc";
import Sp from "../../common/breakpoints/Sp";
import {
  wsUpdatedRobots,
  wsClearRobots
} from "../robots/robotDetails/service/service";
const WS_TIME_REPEAT = 10000; //10s

class DashboardSP extends Component {
  ws = null;
  wsInterval = null;
  pingInterval = null;
  firstFetch = true;
  constructor() {
    super();
    this.state = {
      sitesUC: null,
      isShowDialogDrawing: false,
      isDialogShow: false,
      isDialogConfirmShow: false,
      robots: [],
      times: new Date("2014-08-18T21:11:54")
    };
  }

  componentWillReceiveProps(props) {
    if (props.type === FETCH_DASHBOARDS_SP_SUCCESS && this.firstFetch) {
      if (this.ws) {
        this.ws.onclose = function () { }; // disable onclose handler first
        this.ws.close();
        this.props.wsClearRobots();
        clearInterval(this.wsInterval);
        clearInterval(this.pingInterval);
      }

      let robotsUC = [];
      props.dashboardSP.map((item, idx) => {
        item.robots.map((robot, robot_idx) => {
          robotsUC = [...robotsUC, robot.uniqueCode];
        });
      });

      if (robotsUC.length > 0) this.openWS(robotsUC);

      this.wsInterval = setInterval(() => {
        if (this.ws && this.ws.readyState === 3) {
          clearInterval(this.pingInterval);
          this.openWS(robotsUC);
        }
      }, WS_TIME_REPEAT);

      this.firstFetch = false;
    }
  }

  componentWillUnmount() {
    clearInterval(this.wsInterval);
    clearInterval(this.pingInterval);

    if (this.ws) {
      this.ws.onclose = () => { }; // disable onclose handler first
      this.ws.close();
      this.props.wsClearRobots();
    }
  }

  componentDidMount() {
    this.props.fetchDashBoardSP();
  }

  openWS = robotsUC => {
    this.ws = new WebSocket(getWebSocketRobotURL());
    this.ws.addEventListener("open", () => {
      this.ws.send(JSON.stringify(robotsUC));

      this.pingInterval = setInterval(() => {
        this.ws.send("ping");
      }, WS_TIME_REPEAT);
    });

    this.ws.onmessage = s => {
      if (s.data !== "pong") {
        this.props.wsUpdatedRobots(JSON.parse(s.data));
      }
    };
  };

  to = id => () => {
    this.props.history.push("/sp/sites/" + id);
  };

  shouldEnableRobotButton = robots => {
    for (let i = 0; i < robots.length; i++) {
      if (robots[i].useStatus === 1) return true;
    }
    return false;
  };

  showDialog = robots => e => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.shouldEnableRobotButton(robots)) return;
    this.setState({
      robots: robots,
      isDialogConfirmShow: true
    });
  };

  closeDialog = e => {
    this.setState({
      isDialogConfirmShow: false
    });
  };

  onCreateUserAcknowledgement = (id, status) => () => {
    const value = {
      uniqueCode: id
    };
    if (status) {
      this.props.createUserAcknowledgement(value);
    }
  };

  showHideDialogDrawing = (sitesUC = null) => {
    this.setState({
      isShowDialogDrawing: !this.state.isShowDialogDrawing,
      sitesUC
    });
  };

  render() {
    const { dashboardSP, robotsUpdate } = this.props;
    const {
      isDialogConfirmShow,
      robots,
      isShowDialogDrawing,
      sitesUC
    } = this.state;
    return (
      <Grid className="dashboard-sp">
        <Grid>
          <Typography
            variant="h5"
            className="dashboard-sp-title font-family text-bold"
          >
            担当現場
          </Typography>
          <MenuList>
            {dashboardSP.map(item => (
              <MenuItem
                onClick={this.to(item.uniqueCode)}
                key={item.uniqueCode}
                className="dashboard-sp-item"
              >
                <Grid container justify="space-between" wrap="nowrap">
                  <Grid className="item-left">
                    <Typography
                      variant="subtitle2"
                      className="font-family grey-light white-space-normal"
                    >
                      {item.builderName}
                    </Typography>
                    <Typography
                      variant="h5"
                      className="text-bold font-family white-space-normal"
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      variant="h6"
                      className="font-family grey-deep white-space-normal text-over-wrap"
                    >
                      {item.address}
                    </Typography>
                    {item.employeeNames.length > 0 && (
                      <Typography
                        variant="h6"
                        className="font-family grey-deep white-space-normal mt-20 lh-1-2"
                      >
                        {item.employeeNames.join("・")}
                      </Typography>
                    )}
                  </Grid>
                  <Grid className="item-right">
                    <Typography
                      variant="subtitle2"
                      className="font-family grey-light"
                    >
                      {item.lastRecordedAt
                        ? (format(new Date(item.lastRecordedAt), "yyyy年M月d日 HH:mm 更新"))
                        : (format(new Date(item.regisionDate), "yyyy年M月d日 HH:mm 現場登録"))
                      }
                    </Typography>
                    <Grid
                      item
                      xs={6}
                      className="s-ta-r "
                      onClick={e => e.stopPropagation()}
                    >
                      <Fab
                        className={
                          item.logWalkViewer
                            ? "white-text button-logWalk-sp mx-5"
                            : "white-text button-logWalk-sp-disable mx-5"
                        }
                        size="small"
                        onClick={e => {
                          if (item.logWalkViewer) {
                            this.showHideDialogDrawing(item.uniqueCode);
                          }
                          e.stopPropagation();
                        }}
                      >
                        <PlayCircleOutlineOutlinedIcon
                          style={{ textTransform: "none" }}
                        />
                      </Fab>
                      {/* <RobotStatus
                        typeOfRobotStatus={BUTTON_LOGKUN_SP}
                        robots={item.robots}
                        robotsUpdate={[robotsUpdate]}
                      /> */}
                    </Grid>
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </MenuList>
        </Grid>
        <Pc>
          {isShowDialogDrawing && (
            // <DrawingDialog
            //   open={isShowDialogDrawing}
            //   onClose={this.showHideDialogDrawing}
            //   sitesUC={sitesUC}
            //   isSp={true}
            // />
            <DrawingDialog
              open={isShowDialogDrawing}
              modeDevice={MODE_DEVICE.PC}
              onClose={this.showHideDialogDrawing}
              sitesUC={sitesUC}
              isSp={true}
            />
          )}
        </Pc>
        <Sp>
          {isShowDialogDrawing && (
            // <DrawingDialog
            //   open={isShowDialogDrawing}
            //   onClose={this.showHideDialogDrawing}
            //   sitesUC={sitesUC}
            //   isSp={true}
            // />
            <DrawingDialog
              open={isShowDialogDrawing}
              modeDevice={MODE_DEVICE.SP}
              onClose={this.showHideDialogDrawing}
              sitesUC={sitesUC}
              isSp={true}
            />
          )}
        </Sp>

        <Dialog
          open={isDialogConfirmShow}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          onClose={() => { }}
          maxWidth="xs"
          PaperProps={{
            className: "dialog dialog-sign-up dialog-confirm"
          }}
        >
          <Fab
            onClick={this.closeDialog}
            size="small"
            className="white-text close-button"
          >
            <CloseIcon />
          </Fab>

          <DialogTitle disableTypography>
            <Typography className="center confirm-title">
              どのロボットを起動しますか？
            </Typography>
          </DialogTitle>
          <DialogContent className="confirm-content">
            {robots.map((robot, index) => (
              <Button
                key={index}
                size="large"
                className={
                  robot.useStatus === 1
                    ? "confirm-button-green"
                    : "confirm-button-grey"
                }
                onClick={this.onCreateUserAcknowledgement(
                  robot.uniqueCode,
                  robot.useStatus
                )}
              >
                {robot.robotName}
                {robot.robotName && robot.location && ` | ${robot.location}`}
              </Button>
            ))}
          </DialogContent>
          <DialogActions className="confirm-action">
            <Button className="button-text-blue" onClick={this.closeDialog}>
              キャンセル
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  dashboardSP: state.dashboardsReducer.dashboardSP,
  type: state.rootSitesReducer.siteInfoReducer.type,
  robotsUpdate:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .wsUpdatedRobots
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDashBoardSP: fetchDashBoardSP,
      showHideDialogMapFromLogWalk: showHideDialogMapFromLogWalk,
      createUserAcknowledgement: createUserAcknowledgement,
      wsUpdatedRobots: wsUpdatedRobots,
      wsClearRobots: wsClearRobots
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardSP));
