import React from "react";
import { Formik, Field } from "formik";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import InputControl from "../common/InputControl";
import SelectControl from "../common/SelectControl";
import DateFnsUtils from "@date-io/date-fns";
import { isValid } from "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import "../common/css/base.css";
import "../common/css/sites-staft.css";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import CheckCircleOutlineSharpIcon from "@material-ui/icons/CheckCircleOutlineSharp";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import jaLocale from "date-fns/locale/ja";
import format from "date-fns/format";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { main: "#162f50" }
  }
});
class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, "yyyy MMM", { locale: this.locale });
  }
  getDatePickerHeaderText(date) {
    return format(date, "MMMd日", { locale: this.locale });
  }
}

class CommonDialog extends React.PureComponent {
  state = {
    file: null,
    isValidFileType: false,
    submitFlag: false
  };
  handleDateChange = (fieldName, formik) => date => {
    if (this.props.currentDate) {
      this.props.currentDate(fieldName, date);
    }
    formik.setFieldValue(fieldName, date);
  };

  renderField(fieldList, formik, position) {
    const error = {
      ...this.state?.clientError,
      ...(this.state?.clientError ? null : this.props?.submitErrors)
    };

    const dragOver = e => {
      e.preventDefault();
    };

    const dragEnter = e => {
      e.preventDefault();
    };

    const dragLeave = e => {
      e.preventDefault();
    };

    const fileDrop = e => {
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files.length) {
        handleFiles(files);
      }
    };

    const handleFiles = files => {
      for (let i = 0; i < files.length; i++) {
        if (validateFile(files[i])) {
          this.setState({
            file: files[0],
            isValidFileType: true
          });
          previewFile(files[0]);
          return true;
        } else {
          this.setState({ isValidFileType: false });
          alert("無効なファイル形式です");
          return false;
        }
      }
    };

    const validateFile = file => {
      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (validTypes.indexOf(file.type) === -1) {
        return false;
      }
      return true;
    };

    const previewFile = file => {
      var reader = new FileReader();
      reader.onload = function () {
        document.getElementById("image-file").src = reader.result;
        // image editing
        // ...
      };
      reader.readAsDataURL(file);
    };

    const onDelete = (e, formik) => {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ file: null });
      formik.setFieldValue("image", null);
    };

    return (
      <>
        {fieldList.map((item, index) => {
          switch (item.type) {
            case fieldTypes.Text:
              return (
                <React.Fragment key={index}>
                  <Field
                    label={
                      <Typography variant="subtitle1" className="label">
                        {item.label}
                        {item.isRequired && (
                          <span className="required">[必須]</span>
                        )}
                      </Typography>
                    }
                    name={item.name}
                    InputProps={{
                      readOnly: item.disable,
                      maxLength: item.maxLength
                    }}
                    onChange={formik.handleChange}
                    variant="filled"
                    className={`input-reset ${item.disable && "sharp-character text-gray"
                      }`}
                    component={InputControl}
                  />
                  {error && error[item.name] && (
                    <span className="error">{error[item.name]}</span>
                  )}
                </React.Fragment>
              );
            case fieldTypes.DateTime:
              return (
                <React.Fragment key={index}>
                  <Grid className={`${item.isHalfWidth && "date-range"}`}>
                    <Field
                      name={item.name}
                      variant="filled"
                      onChange={formik.handleChange}
                    >
                      {({ field }) => (
                        <ThemeProvider theme={defaultMaterialTheme}>
                          <MuiPickersUtilsProvider
                            utils={ExtendedUtils}
                            locale={jaLocale}
                          >
                            <>
                              <KeyboardDatePicker
                                autoOk
                                okLabel="決定"
                                cancelLabel="キャンセル"
                                inputVariant="filled"
                                className={`input-reset date-picker`}
                                format={
                                  item.format ? item.format : "yyyy年MM月dd日"
                                }
                                views={["year", "month", "date"]}
                                value={field.value}
                                onChange={this.handleDateChange(
                                  item.name,
                                  formik
                                )}
                                invalidDateMessage=""
                                label={
                                  <Typography
                                    variant="subtitle1"
                                    className="label"
                                  >
                                    {item.label}
                                    {item.isRequired && (
                                      <span className="required">[必須]</span>
                                    )}
                                  </Typography>
                                }
                                error={false}
                                InputAdornmentProps={
                                  item.isHalfWidth && { position: "start" }
                                }
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </>
                          </MuiPickersUtilsProvider>
                        </ThemeProvider>
                      )}
                    </Field>
                    <span className="guid-message">{item.guidMessage}</span>
                    {this.props.submitErrors &&
                      this.props.submitErrors[item.name] && (
                        <span
                          className={`error ${item.isHalfWidth && "error-resize"
                            }`}
                        >
                          {this.props.submitErrors[item.name]}
                        </span>
                      )}
                  </Grid>
                </React.Fragment>
              );

            case fieldTypes.DropDownBox:
              return (
                <React.Fragment key={index}>
                  <Field
                    name={item.name}
                    title={
                      <Typography variant="subtitle1" className="label">
                        {item.label}
                        {item.isRequired && (
                          <span className="required">[必須]</span>
                        )}
                      </Typography>
                    }
                    onChange={formik.handleChange}
                    variant="filled"
                    className={`input-reset reset-select ${item.sharp && "sharp-character text-gray"
                      }`}
                    component={SelectControl}
                    disabled={item.disabled}
                  >
                    <MenuItem className="select-item" value={-1}>
                      選択して下さい
                    </MenuItem>

                    {item.options.map((itemOption, indexOption) => (
                      <MenuItem
                        className="select-item"
                        value={itemOption.uniqueCode}
                        key={indexOption}
                      >
                        {itemOption.name}
                      </MenuItem>
                    ))}
                  </Field>
                  {this.props.submitErrors &&
                    this.props.submitErrors[item.name] && (
                      <span className="error">
                        {this.props.submitErrors[item.name]}
                      </span>
                    )}
                </React.Fragment>
              );

            case fieldTypes.CheckBox:
              return (
                <React.Fragment key={index}>
                  <Field
                    name="job"
                    color="primary"
                    onChange={e => {
                      let job = 0;
                      if (e.target.checked) {
                        job = 1;
                      }
                      formik.setFieldValue("job", job);
                    }}
                    checked={formik.values.job === 1 ? true : false}
                    component={this.renderCheckBox}
                  />
                </React.Fragment>
              );

            case fieldTypes.Password:
              return (
                <React.Fragment key={index}>
                  <Grid className="relative">
                    <Field
                      label={
                        <Typography variant="subtitle1" className="label">
                          {item.label}
                          {item.isRequired && (
                            <span className="required">[必須]</span>
                          )}
                        </Typography>
                      }
                      name={item.name}
                      type={item.isShow ? "text" : "password"}
                      onChange={formik.handleChange}
                      variant="filled"
                      className={`input-reset ${item.isIcon ? "pdr-50" : ""}`}
                      component={InputControl}
                      autoComplete="new-password"
                    />
                    {item.isIcon && (
                      <IconButton
                        color="primary"
                        aria-label="copy"
                        className="show-password"
                        onClick={this.props.showPassword(index, position)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    )}
                    {this.props.submitErrors && this.props.submitErrors[item.name] ? (
                      <span className="error">
                        {this.props.submitErrors[item.name]}
                      </span>
                    ) : item.name === "password" && (
                      <span style={{ color: "#909090" }}>半角英字、半角数字を含む8文字以上をご入力ください</span>
                    )}
                  </Grid>
                </React.Fragment>
              );
            case fieldTypes.Image:
              return (
                <React.Fragment key={index}>
                  <Grid className="image-div mt-20 p-20">
                    <Grid className="image-title">
                      <Typography variant="subtitle1" className="label">
                        {item.label}
                        {/* {item.isRequired && (
                        <span className="required">[必須]</span>
                      )} */}
                      </Typography>
                    </Grid>
                    <Grid
                      className="drop-area"
                      onDragOver={dragOver}
                      onDragEnter={dragEnter}
                      onDragLeave={dragLeave}
                      onDrop={fileDrop}
                      onClick={() => {
                        this.upload.click();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {(this.state.file || formik.values.image) && (
                        <Fab
                          onClick={e => onDelete(e, formik)}
                          size="small"
                          className="white-text delete-button"
                        >
                          <CloseIcon />
                        </Fab>
                      )}
                      <input
                        type="file"
                        id="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={e => {
                          if (handleFiles(e.target.files)) {
                            formik.setFieldValue(item.name, e.target.files[0]);
                          }
                        }}
                        onClick={e => (e.target.value = null)}
                        style={{ display: "none" }}
                        ref={ref => (this.upload = ref)}
                      />
                      {this.state.file || formik.values.image ? (
                        <div className="image-form">
                          <img
                            id="image-file"
                            src={this.state.file ?? formik.values.image ?? ""}
                            alt="image"
                          />
                        </div>
                      ) : (
                        <div className="drop-message">
                          <div>
                            <div className="upload-icon"></div>
                            Drag & Drop files here or click to upload
                          </div>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            default:
              return null;
          }
        })}
      </>
    );
  }

  renderCheckBox = ({ field, form, ...props }) => {
    return (
      <Grid className="mt-25">
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              {...props}
              inputProps={{ "aria-label": "Checked" }}
            />
          }
          label={<span className="txt-login-memory">大工として登録する</span>}
        />
      </Grid>
    );
  };

  render() {
    const {
      isDialogShow,
      onClose,
      fieldList,
      isEdit,
      isComplete,
      initialValues,
      title,
      submitButtonTitle,
      onDeleteClick,
      onCompleteClick,
      isSingleColumn,
      numberRow = 6,
      deletable = true
    } = this.props;

    const leftFieldList =
      fieldList.length > numberRow
        ? fieldList.slice(0, numberRow)
        : fieldList.slice(0, fieldList.length);

    const rightFieldList =
      fieldList.length > numberRow
        ? fieldList.slice(numberRow, fieldList.length)
        : [];

    let dialogMaxWidth = isSingleColumn ? "md" : "lg";

    let contentClassName = "content-wrap";

    if (!isSingleColumn) {
      contentClassName += " width-lg";
    }

    const onSubmit = values => {
      let newValuesForm = { ...values };
      if (!this.state.file && newValuesForm["image"]) {
        delete newValuesForm["image"];
      } else {
        if (
          fieldList.some(e => e.type === "Image") &&
          !newValuesForm["image"]
        ) {
          newValuesForm["image"] = this.state.file;
        }
      }
      this.props.onSubmit(newValuesForm);
    };

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={values => onSubmit(values)}
      >
        {formik => (
          <Dialog
            open={isDialogShow}
            aria-labelledby="max-width-dialog-title"
            fullWidth
            onClose={() => { }}
            maxWidth={dialogMaxWidth}
            PaperProps={{
              className: "dialog dialog-sign-up"
            }}
            onKeyPress={event => {
              if (event.key === "Enter" && !this.state.submitFlag) {
                formik.handleSubmit(formik.values);
                this.setState({ submitFlag: true });
              }
            }}
            onClick={() => {
              this.setState({ submitFlag: false });
            }}
          >
            <Fab
              onClick={onClose}
              size="small"
              className="white-text close-button"
            >
              <CloseIcon />
            </Fab>

            <DialogTitle disableTypography>
              <Typography variant="h4" className="dialog-title">
                {title}
              </Typography>
            </DialogTitle>
            <DialogContent className={contentClassName}>
              <Grid container style={{ justifyContent: "center" }}>
                <Grid container className="row">
                  <Grid
                    item
                    sm={12}
                    md={isSingleColumn ? 12 : 6}
                    className="col"
                    style={{ width: "100%" }}
                  >
                    {this.renderField(leftFieldList, formik, "left")}
                  </Grid>

                  {!isSingleColumn && rightFieldList.length > 0 && (
                    <Grid
                      item
                      sm={12}
                      md={6}
                      className="col"
                      style={{ width: "100%" }}
                    >
                      {this.renderField(rightFieldList, formik, "right")}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="dialog-action wrap">
              <Button
                onClick={() => {
                  this.setState({ submitFlag: true });
                  formik.handleSubmit();
                }}
                color="primary"
                className="dialog-button"
                disabled={this.state.submitFlag}
              >
                {submitButtonTitle}
              </Button>

              <Grid container className="dialog-complete-delete-tab">
                {isComplete && (
                  <Button
                    color="primary"
                    className="dialog-button-complete"
                    onClick={onCompleteClick}
                  >
                    <CheckCircleOutlineSharpIcon className="icon-margin" />
                    完了現場にする
                  </Button>
                )}

                {isEdit && (
                  <Button
                    color="primary"
                    className="dialog-button-remove"
                    onClick={onDeleteClick}
                    disabled={!deletable}
                  >
                    <DeleteSharpIcon className="icon-margin" />
                    削除
                  </Button>
                )}
              </Grid>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    );
  }
}

const fieldTypes = {
  Text: "Text",
  DateTime: "DateTime",
  DropDownBox: "DropDownBox",
  CheckBox: "CheckBox",
  Password: "Password",
  Image: "Image"
};

CommonDialog.propTypes = {
  title: PropTypes.string,
  isDialogShow: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
  isComplete: PropTypes.bool,
  fieldList: PropTypes.array.isRequired,
  submitButtonTitle: PropTypes.string,
  onDeleteClick: PropTypes.func,
  onCompleteClick: PropTypes.func,
  isSingleColumn: PropTypes.bool,
  currentDate: PropTypes.func
};

export default CommonDialog;
