import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Component from "./Common";

import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Popover from "@material-ui/core/Popover";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

import VisibilityIcon from "@material-ui/icons/Visibility";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import PhoneIcon from "@material-ui/icons/Phone";

import { format } from "date-fns";

import {
  fetchSiteInfo,
  fetchSiteInfoRobot,
  fetchSiteInfoStaff,
  fetchSiteInfoSupplier,
  fetchStaffCandidates,
  fetchSupplierCandidates,
  fetchSiteInfoRobotCandidate,
  addSiteInfoRobotCandidate,
  deleteSiteInfoRobotCandidate,
  addStaffCandidate,
  addSupplierCandidate,
  deleteBuilderStaffCandidate,
  deleteSupplierStaffCandidate,
  updateSiteInfoRobot,
  fetchInfoData
} from "./service/service";

import {
  getSiteInfo,
  getSiteInfoRobot,
  getSiteInfoStaff,
  getSiteInfoSupplier,
  getStaffCandidates,
  getSupplierCandidates,
  getSiteInfoRobotCandidates,
  getType
} from "./reducer/reducer";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";

import CommonDialog from "../../CommonDialog";
import { PUT_SITE_INFO_ROBOT_SUCCESS } from "./constant/constant";

import {
  dispatchError as fillError,
  createUserAcknowledgement
} from "../../../common/Stores/service/service";

import { translateMessage, trimObjValues } from "utils/mapper";
import {
  translateValidate,
  messageValidate
} from "../../../common/Stores/service/service";
import { BUTTON_LOGKUN, ICON_LOGKUN } from "common/constants/constants";
import RobotStatus from "common/RobotStatus";

class InfoBuilder extends Component {
  fetch() {}

  componentWillMount() {
    super.componentDidMount();
    this.props.setPC();
  }

  onCreateUserAcknowledgement = (id, status) => () => {
    const value = {
      uniqueCode: id
    };
    if (status) {
      this.props.createUserAcknowledgement(value);
    }
  };

  render() {
    const {
      siteInfo,
      siteInfoRobot,
      siteInfoStaff,
      siteInfoSupplier,
      robotsUpdate,
      currentUserUniqueCode,
      toggleCallModal,
      logMeetFlag
    } = this.props;

    // const siteInfoStaffSorted = siteInfoStaff.sort((a, b) => {
    //   return a.furigana.localeCompare(b.furigana, "jp") || a.uniqueCode.localeCompare(b.uniqueCode, "en");
    // });
    // const siteInfoSupplierSorted = siteInfoSupplier.sort((a, b) => {
    //   return a.furigana.localeCompare(b.furigana, "jp") || a.uniqueCode.localeCompare(b.uniqueCode, "en");
    // });

    return (
      <Grid className="third-tab mt-25">
        <Grid container className="third-tab-info">
          <Grid item md={5}>
            <Typography variant="subtitle1" className="font-family mb-25 text-over-wrap">
              <Typography
                variant="subtitle2"
                component="span"
                className="field-name-info font-family"
              >
                現場名
              </Typography>
              {siteInfo.Name}
            </Typography>
            <Typography variant="subtitle1" className="font-family mb-25">
              <Typography
                variant="subtitle2"
                component="span"
                className="field-name-info font-family"
              >
                現場監督
              </Typography>
              {siteInfo.EmployeeNames && siteInfo.EmployeeNames.join("・")}
            </Typography>
          </Grid>
          <Grid item md={7}>
            <Typography variant="subtitle1" className="font-family mb-25 ml-10 text-over-wrap">
              <Typography
                variant="subtitle2"
                component="span"
                className="field-name-info font-family"
              >
                住所
              </Typography>
              {siteInfo.Address}
            </Typography>
          </Grid>
        </Grid>
        <Grid className="third-tab-table">
          <Grid className="mb-25">
            <TableContainer>
              <Table size="small" aria-label="table" className="sites-table">
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell className="white-text padding-first-th">
                      ロボット名
                    </TableCell>
                    <TableCell className="white-text">設置場所</TableCell>
                    <TableCell className="white-text">
                      現場登録日
                      <ArrowDownwardIcon className="icon-small-size s-va-m margin-left" />
                    </TableCell>
                    <TableCell className="white-text">配置終了日​</TableCell>
                    <TableCell className="white-text">ステータス</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="table-body">
                  {siteInfoRobot.map(info => (
                    <TableRow key={info.uniqueCode}>
                      <TableCell className="padding-first-td">
                        {info.robotName}
                      </TableCell>
                      <TableCell>{info.location}</TableCell>
                      <TableCell>
                        {info.assignStartDate &&
                          format(
                            new Date(info.assignStartDate),
                            "yyyy年M月d日"
                          )}
                      </TableCell>
                      <TableCell>
                        {info.assignEndDate &&
                          format(new Date(info.assignEndDate), "yyyy年M月d日")}
                      </TableCell>
                      <TableCell>
                        <RobotStatus
                          typeOfRobotStatus={ICON_LOGKUN}
                          robots={[info]}
                          robotsUpdate={[robotsUpdate]}
                        />
                      </TableCell>
                      <TableCell align="right" width="192px">
                        <RobotStatus
                          typeOfRobotStatus={BUTTON_LOGKUN}
                          robots={[info]}
                          robotsUpdate={[robotsUpdate]}
                          forceCreateUserAcknowledgement={true}
                        />
                        <Fab
                          onClick={this.props.showPopover(
                            { robotsUC: info.uniqueCode, ...info },
                            "Robot"
                          )}
                          size="small"
                          className="more-vert-btn"
                        >
                          <MoreVertIcon />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              onClick={this.props.showDialogInfoRobot}
              size="small"
              variant="text"
              className="button-add"
            >
              <AddIcon className="icon-margin" />
              ロボット追加
            </Button>
          </Grid>
          <Grid className="mb-25">
            <Grid container justify="flex-end">
              <Grid item style={{padding: "4px 0px"}}>
                <Button
                  onClick={this.props.toggleDialogStaff}
                  size="small"
                  variant="text"
                  className="button-add"
                >
                  <AddIcon className="icon-margin" />
                  担当者追加
                </Button>
              </Grid>
            </Grid>
            <TableContainer>
              <Table size="small" aria-label="table" className="sites-table">
                <TableHead className="table-head">
                  <TableRow>
                    {logMeetFlag && (
                      <TableCell style={{ width: 30 }}></TableCell>
                    )}
                    <TableCell className="white-text padding-first-th">
                      担当者名
                    </TableCell>
                    <TableCell className="white-text">役割</TableCell>
                    <TableCell className="white-text">
                      現場配属日
                      <ArrowDownwardIcon className="icon-small-size s-va-m margin-left" />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="table-body">
                  {siteInfoStaff.map(staff => (
                    <TableRow key={staff.uniqueCode}>
                      {logMeetFlag && (
                        <TableCell align="center" style={{ padding: "0px" }}>
                          {currentUserUniqueCode !== staff.uniqueCode && (
                            <Fab
                              onClick={() => {
                                toggleCallModal(staff);
                              }}
                              size="small"
                              style={{ backgroundColor: "#162F50", width: "24px", height: "24px", minHeight: "24px" }}
                              className="more-vert-btn"
                            >
                              <PhoneIcon style={{ color: "white", width: "16px", height: "16px" }} />
                            </Fab>
                          )}
                        </TableCell>
                      )}

                      <TableCell className="padding-first-td">
                        {staff.name}
                      </TableCell>
                      <TableCell>{staff.jobName}</TableCell>
                      <TableCell>
                        {staff.assignDate &&
                          format(new Date(staff.assignDate), "yyyy年M月d日")}
                      </TableCell>
                      <TableCell align="right">
                        <Fab
                          onClick={this.props.showPopover(
                            { usersUC: staff.uniqueCode },
                            "Builder"
                          )}
                          size="small"
                          className="more-vert-btn"
                        >
                          <MoreVertIcon />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              onClick={this.props.toggleDialogStaff}
              size="small"
              variant="text"
              className="button-add"
            >
              <AddIcon className="icon-margin" />
              担当者追加
            </Button>
          </Grid>
          <Grid className="mb-25">
            <Grid container justify="flex-end">
              <Grid item style={{padding: "4px 0px"}}>
                <Button
                  onClick={this.props.toggleDialogSupplier}
                  size="small"
                  variant="text"
                  className="button-add"
                >
                  <AddIcon className="icon-margin" />
                  職人追加
                </Button>
              </Grid>
            </Grid>
            <TableContainer>
              <Table size="small" aria-label="table" className="sites-table">
                <TableHead className="table-head">
                  <TableRow>
                    {logMeetFlag && (
                      <TableCell style={{ width: 30 }}></TableCell>
                    )}
                    <TableCell className="white-text padding-first-th">
                      会社名
                    </TableCell>
                    <TableCell className="white-text">
                      職人名
                      <ArrowDownwardIcon className="icon-small-size s-va-m margin-left" />
                    </TableCell>
                    <TableCell className="white-text">会社電話</TableCell>
                    <TableCell className="white-text">個人携帯</TableCell>
                    <TableCell className="white-text">メール</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="table-body">
                  {siteInfoSupplier.map(supplier => (
                    <TableRow key={supplier.uniqueCode}>
                      {logMeetFlag && (currentUserUniqueCode !== supplier.uniqueCode) && (
                        <>
                          <TableCell align="center" style={{ padding: "0px" }}>
                            <Fab
                              onClick={() => {
                                toggleCallModal(supplier);
                              }}
                              style={{ backgroundColor: "#162F50", width: "24px", height: "24px", minHeight: "24px" }}
                              size="small"
                              className="more-vert-btn"
                            >
                              <PhoneIcon style={{ color: "white", width: "16px", height: "16px" }} />
                            </Fab>
                          </TableCell>
                        </>
                      )}
                      <TableCell className="padding-first-td">
                        {supplier.supplierName}
                      </TableCell>
                      <TableCell>
                        <Grid
                          container
                          alignItems="center"
                          className="inline-block"
                        >
                          {supplier.job === 1 ? (
                            <Typography
                              variant="caption"
                              className="tag"
                              style={{ marginLeft: 0, marginRight: 10 }}
                            >
                              {supplier.jobName}
                            </Typography>
                          ) : (
                            <></>
                          )}
                          {supplier.name}
                        </Grid>
                      </TableCell>
                      <TableCell>{supplier.supplierPhone}</TableCell>
                      <TableCell>{supplier.phone}</TableCell>
                      <TableCell>{supplier.email}</TableCell>
                      <TableCell align="right">
                        <Fab
                          onClick={this.props.showPopover(
                            { usersUC: supplier.uniqueCode },
                            "Supplier"
                          )}
                          size="small"
                          className="more-vert-btn"
                        >
                          <MoreVertIcon />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              onClick={this.props.toggleDialogSupplier}
              size="small"
              variant="text"
              className="button-add"
            >
              <AddIcon className="icon-margin" />
              職人追加
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  finishLoading: state.storesReducer.finishLoading,
  siteInfo: getSiteInfo(state.rootSitesReducer.siteInfoReducer.siteInfo),
  siteInfoRobot: getSiteInfoRobot(
    state.rootSitesReducer.siteInfoReducer.siteInfoRobot
  ),
  siteInfoStaff: getSiteInfoStaff(
    state.rootSitesReducer.siteInfoReducer.siteInfoStaff
  ),
  siteInfoSupplier: getSiteInfoSupplier(
    state.rootSitesReducer.siteInfoReducer.siteInfoSupplier
  ),
  staffCandidates: getStaffCandidates(
    state.rootSitesReducer.siteInfoReducer.staffCandidates
  ),
  supplierCandidates: getSupplierCandidates(
    state.rootSitesReducer.siteInfoReducer.supplierCandidates
  ),
  siteInfoRobot: getSiteInfoRobot(
    state.rootSitesReducer.siteInfoReducer.siteInfoRobot
  ),
  siteInfoRobotCandidate: getSiteInfoRobotCandidates(
    state.rootSitesReducer.siteInfoReducer.siteInfoRobotCandidate
  ),
  error: state.storesReducer.error,
  type: getType(state.rootSitesReducer.siteInfoReducer.type),
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message,
  robotsUpdate:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .wsUpdatedRobots
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSiteInfo: fetchSiteInfo,
      fetchSiteInfoRobot: fetchSiteInfoRobot,
      fetchSiteInfoStaff: fetchSiteInfoStaff,
      fetchSiteInfoSupplier: fetchSiteInfoSupplier,
      fetchStaffCandidates: fetchStaffCandidates,
      fetchSupplierCandidates: fetchSupplierCandidates,
      fetchSiteInfoRobotCandidate: fetchSiteInfoRobotCandidate,
      addSiteInfoRobotCandidate: addSiteInfoRobotCandidate,
      deleteSiteInfoRobotCandidate: deleteSiteInfoRobotCandidate,
      addStaffCandidate: addStaffCandidate,
      addSupplierCandidate: addSupplierCandidate,
      deleteBuilderStaffCandidate: deleteBuilderStaffCandidate,
      deleteSupplierStaffCandidate: deleteSupplierStaffCandidate,
      updateSiteInfoRobot: updateSiteInfoRobot,
      fetchInfoData,
      fillError,
      createUserAcknowledgement,
      translateValidate,
      messageValidate
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InfoBuilder));
