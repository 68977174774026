import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Component from "./Common";

import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Popover from "@material-ui/core/Popover";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

import VisibilityIcon from "@material-ui/icons/Visibility";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import PhoneIcon from "@material-ui/icons/Phone";
import logmeetConfig from "../../../common/config/LogmeetConfig";
import { configStorage } from "../../../common/storage/storage";
import DialogDelete from "../../../common/DialogDelete";

import { format } from "date-fns";

import {
  fetchSiteInfo,
  fetchSiteInfoRobot,
  fetchSiteInfoStaff,
  fetchSiteInfoSupplier,
  fetchStaffCandidates,
  fetchSupplierCandidates,
  fetchSiteInfoRobotCandidate,
  addSiteInfoRobotCandidate,
  deleteSiteInfoRobotCandidate,
  addStaffCandidate,
  addSupplierCandidate,
  deleteBuilderStaffCandidate,
  deleteSupplierStaffCandidate,
  updateSiteInfoRobot,
  fetchInfoData,
  showDialogEdit
} from "./service/service";

import {
  getSiteInfo,
  getSiteInfoRobot,
  getSiteInfoStaff,
  getSiteInfoSupplier,
  getStaffCandidates,
  getSupplierCandidates,
  getSiteInfoRobotCandidates,
  getType
} from "./reducer/reducer";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";

import CommonDialog from "../../CommonDialog";
import { PUT_SITE_INFO_ROBOT_SUCCESS } from "./constant/constant";

import {
  dispatchError as fillError,
  createUserAcknowledgement
} from "../../../common/Stores/service/service";

import { translateMessage, trimObjValues } from "utils/mapper";
import {
  translateValidate,
  messageValidate
} from "../../../common/Stores/service/service";

class InfoBuilderSP extends Component {
  fetch() { }

  componentWillMount() {
    super.componentDidMount();
    this.props.setSP();
  }

  onCreateUserAcknowledgement = (id, status) => () => {
    const value = {
      uniqueCode: id
    };
    if (status) {
      this.props.createUserAcknowledgement(value);
    }
  };

  render() {
    const {
      siteInfo,
      siteInfoRobot,
      siteInfoStaff,
      siteInfoSupplier,
      currentUserUniqueCode,
      toggleCallModal,
      logMeetFlag
    } = this.props;

    // const siteInfoStaffSorted = siteInfoStaff.sort((a, b) => {
    //   return a.furigana.localeCompare(b.furigana, "jp") || a.uniqueCode.localeCompare(b.uniqueCode, "en");
    // });
    // const siteInfoSupplierSorted = siteInfoSupplier.sort((a, b) => {
    //   return a.furigana.localeCompare(b.furigana, "jp") || a.uniqueCode.localeCompare(b.uniqueCode, "en");
    // });

    return (
      <Grid className="third-tab-table">
        <Grid item className="mb-25">
          <Typography
            variant="h5"
            className="text-bold white-space-normal font-family"
          >
            詳細情報
          </Typography>
        </Grid>
        <Grid className="mb-25">
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family">
              <Typography
                variant="subtitle2"
                component="span"
                className="field-name-info font-family"
              >
                現場名
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family mb-15 text-over-wrap">
              {siteInfo.Name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family">
              <Typography
                variant="subtitle2"
                component="span"
                className="field-name-info font-family"
              >
                現場監督
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family mb-15">
              {siteInfo.EmployeeNames && siteInfo.EmployeeNames.length > 0 ? siteInfo.EmployeeNames.join("・") : '(未登録)'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family">
              <Typography
                variant="subtitle2"
                component="span"
                className="field-name-info font-family"
              >
                住所
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="font-family mb-15 text-over-wrap">
              {siteInfo.Address}
            </Typography>
          </Grid>
        </Grid>

        <Grid item className="mb-25">
          <Typography
            variant="h5"
            className="text-bold white-space-normal font-family"
          >
            ロボット情報
          </Typography>
        </Grid>
        <Grid className="mb-25">
          <TableContainer>
            <Table size="small" aria-label="table" className="sites-table-sp">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell className="white-text">ロボット名</TableCell>
                  <TableCell className="white-text">設置場所</TableCell>
                  <TableCell className="white-text">
                    現場登録日
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {siteInfoRobot.map(info => (
                  <TableRow key={info.uniqueCode}>
                    <TableCell>{info.robotName}</TableCell>
                    <TableCell>{info.location}</TableCell>
                    <TableCell>
                      {info.assignStartDate &&
                        format(
                          new Date(info.assignStartDate),
                          "yyyy年M月d日"
                        )}
                    </TableCell>
                    <TableCell align="right" className="info-cell-more">
                      <Fab
                        onClick={this.props.showPopover(
                          { robotsUC: info.uniqueCode, ...info },
                          "Robot"
                        )}
                        size="small"
                        className="more-vert-btn"
                      >
                        <MoreVertIcon />
                      </Fab>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={this.props.showDialogInfoRobot}
            size="small"
            variant="text"
            className="button-add"
          >
            <AddIcon className="icon-margin" />
            ロボット追加
          </Button>
        </Grid>

        <Grid container justify="space-between">
          <Grid item className="mb-25">
            <Typography
              variant="h5"
              className="text-bold white-space-normal font-family"
            >
              担当者情報
            </Typography>
          </Grid>
          <Grid item style={{ paddingBottom: "4px", marginTop: "-4px", alignSelf: "flex-end" }}>
            <Button
              onClick={this.props.toggleDialogStaff}
              size="small"
              variant="text"
              className="button-add"
            >
              <AddIcon className="icon-margin" />
              担当者追加
            </Button>
          </Grid>
        </Grid>
        <Grid className="mb-25">
          <TableContainer>
            <Table size="small" aria-label="table" className="sites-table-sp">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell className="white-text">担当者名</TableCell>
                  <TableCell className="white-text">役割</TableCell>
                  <TableCell className="white-text">
                    現場配属日
                  </TableCell>
                  <TableCell></TableCell>
                  {/* <TableCell></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {siteInfoStaff.map(staff => (
                  <TableRow key={staff.uniqueCode}>
                    <TableCell>
                      {logMeetFlag && (currentUserUniqueCode !== staff.uniqueCode) && (
                        <> <Fab
                          onClick={() => {
                            toggleCallModal(staff);
                          }}
                          size="small"
                          style={{ backgroundColor: "#162F50", width: "24px", height: "24px", minHeight: "24px" }}
                          className="more-vert-btn"
                        >
                          <PhoneIcon style={{ color: "white", width: "16px", height: "16px" }} />
                        </Fab>
                          &nbsp;
                        </>
                      )}
                      {staff.name}
                    </TableCell>
                    <TableCell>{staff.jobName}</TableCell>
                    <TableCell>
                      {staff.assignDate &&
                        format(new Date(staff.assignDate), "yyyy年M月d日")}
                    </TableCell>
                    <TableCell align="right" className="info-cell-more">
                      <Fab
                        onClick={this.props.showPopover(
                          { usersUC: staff.uniqueCode },
                          "Builder"
                        )}
                        size="small"
                        className="more-vert-btn"
                      >
                        <MoreVertIcon />
                      </Fab>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={this.props.toggleDialogStaff}
            size="small"
            variant="text"
            className="button-add"
          >
            <AddIcon className="icon-margin" />
            担当者追加
          </Button>
        </Grid>

        <Grid container justify="space-between">
          <Grid item className="mb-25">
            <Typography
              variant="h5"
              className="text-bold white-space-normal font-family"
            >
              職人情報
            </Typography>
          </Grid>
          <Grid item style={{ paddingBottom: "4px", marginTop: "-4px", alignSelf: "flex-end" }}>
            <Button
              onClick={this.props.toggleDialogSupplier}
              size="small"
              variant="text"
              className="button-add"
            >
              <AddIcon className="icon-margin" />
              職人追加
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <TableContainer>
            <Table size="small" aria-label="table" className="sites-table-sp">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell className="white-text">会社名</TableCell>
                  <TableCell className="white-text">
                    職人名
                  </TableCell>
                  <TableCell className="white-text">個人携帯</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {siteInfoSupplier.map(supplier => (
                  <TableRow key={supplier.uniqueCode}>
                    <TableCell>
                      {logMeetFlag && (currentUserUniqueCode !== supplier.uniqueCode) && (
                        <>
                          <Fab
                            onClick={() => {
                              toggleCallModal(supplier);
                            }}
                            style={{ backgroundColor: "#162F50", width: "24px", height: "24px", minHeight: "24px" }}
                            size="small"
                            className="more-vert-btn"
                          >
                            <PhoneIcon style={{ color: "white", width: "16px", height: "16px" }} />
                          </Fab>
                          &nbsp;
                        </>
                      )}
                      {supplier.supplierName}
                    </TableCell>
                    <TableCell>
                      <Grid
                        container
                        alignItems="center"
                        className="inline-block"
                      >
                        {supplier.job === 1 ? (
                          <Typography variant="caption" className="tag" style={{ padding: 2, marginLeft: 0, marginRight: 2 }}>
                            {supplier.jobName}
                          </Typography>
                        ) : (
                          <></>
                        )}
                        {supplier.name}
                      </Grid>
                    </TableCell>
                    <TableCell>{supplier.phone}</TableCell>
                    <TableCell align="right" className="info-cell-more">
                      <Fab
                        onClick={this.props.showPopover(
                          { usersUC: supplier.uniqueCode },
                          "Supplier"
                        )}
                        size="small"
                        className="more-vert-btn"
                      >
                        <MoreVertIcon />
                      </Fab>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={this.props.toggleDialogSupplier}
            size="small"
            variant="text"
            className="button-add"
          >
            <AddIcon className="icon-margin" />
            職人追加
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  finishLoading: state.storesReducer.finishLoading,
  siteInfo: getSiteInfo(state.rootSitesReducer.siteInfoReducer.siteInfo),
  siteInfoRobot: getSiteInfoRobot(
    state.rootSitesReducer.siteInfoReducer.siteInfoRobot
  ),
  siteInfoStaff: getSiteInfoStaff(
    state.rootSitesReducer.siteInfoReducer.siteInfoStaff
  ),
  siteInfoSupplier: getSiteInfoSupplier(
    state.rootSitesReducer.siteInfoReducer.siteInfoSupplier
  ),
  staffCandidates: getStaffCandidates(
    state.rootSitesReducer.siteInfoReducer.staffCandidates
  ),
  supplierCandidates: getSupplierCandidates(
    state.rootSitesReducer.siteInfoReducer.supplierCandidates
  ),
  siteInfoRobot: getSiteInfoRobot(
    state.rootSitesReducer.siteInfoReducer.siteInfoRobot
  ),
  siteInfoRobotCandidate: getSiteInfoRobotCandidates(
    state.rootSitesReducer.siteInfoReducer.siteInfoRobotCandidate
  ),
  error: state.storesReducer.error,
  type: getType(state.rootSitesReducer.siteInfoReducer.type),
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSiteInfo: fetchSiteInfo,
      fetchSiteInfoRobot: fetchSiteInfoRobot,
      fetchSiteInfoStaff: fetchSiteInfoStaff,
      fetchSiteInfoSupplier: fetchSiteInfoSupplier,
      fetchStaffCandidates: fetchStaffCandidates,
      fetchSupplierCandidates: fetchSupplierCandidates,
      fetchSiteInfoRobotCandidate: fetchSiteInfoRobotCandidate,
      addSiteInfoRobotCandidate: addSiteInfoRobotCandidate,
      deleteSiteInfoRobotCandidate: deleteSiteInfoRobotCandidate,
      addStaffCandidate: addStaffCandidate,
      addSupplierCandidate: addSupplierCandidate,
      deleteBuilderStaffCandidate: deleteBuilderStaffCandidate,
      deleteSupplierStaffCandidate: deleteSupplierStaffCandidate,
      updateSiteInfoRobot: updateSiteInfoRobot,
      fetchInfoData,
      fillError,
      createUserAcknowledgement,
      translateValidate,
      messageValidate
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InfoBuilderSP));
