import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";

import {
  CometChatMessageActions,
  CometChatThreadedMessageReplyCount,
  CometChatReadReceipt
} from "../";
import { CometChatMessageReactions } from "../Extensions";
import { CometChatAvatar } from "../../Shared";

import { CometChatContext } from "../../../util/CometChatContext";
import * as enums from "../../../util/enums.js";
import { checkMessageForExtensionsData } from "../../../util/common";

import { theme } from "../../../resources/theme";
import Translator from "../../../resources/localization/translator";

import {
  messageContainerStyle,
  messageWrapperStyle,
  messageThumbnailStyle,
  messageDetailStyle,
  nameWrapperStyle,
  nameStyle,
  messageImgContainerStyle,
  messageImgWrapperStyle,
  messageInfoWrapperStyle,
  messageReactionsWrapperStyle,
  messageTxtContainerStyle,
  messageTxtWrapperStyle
} from "./style";
import { api } from "../../../../../../common/api/api";
import axios from "axios";
import srcIcon from "./resources/1px.png";

class CometChatReceiverImageMessageBubble extends React.Component {
  static contextType = CometChatContext;

  constructor(props) {
    super(props);
    this._isMounted = false;
    this.imgRef = React.createRef();

    this.state = {
      imageUrl: srcIcon,
      isHovering: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const currentMessageStr = JSON.stringify(this.props.message);
    const nextMessageStr = JSON.stringify(nextProps.message);

    if (
      currentMessageStr !== nextMessageStr ||
      this.state.imageUrl !== nextState.imageUrl ||
      this.state.isHovering !== nextState.isHovering
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.getData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  chooseImage = thumbnailGenerationObject => {
    const smallUrl = thumbnailGenerationObject["url_small"];
    const mediumUrl = thumbnailGenerationObject["url_medium"];

    const mq = window.matchMedia(this.props.theme.breakPoints[0]);

    let imageToDownload = mediumUrl;
    if (mq.matches) {
      imageToDownload = smallUrl;
    }

    return imageToDownload;
  };

  setImage = () => {
    const thumbnailGenerationData = checkMessageForExtensionsData(
      this.props.message,
      "thumbnail-generation"
    );
    if (thumbnailGenerationData) {
      const mq = window.matchMedia(this.props.theme.breakPoints[0]);
      mq.addListener(() => {
        const imageToDownload = this.chooseImage(thumbnailGenerationData);
        let img = new Image();
        img.src = imageToDownload;
        img.onload = () => {
          if (this._isMounted) {
            this.setState({ imageUrl: img.src });
          }
        };
      });

      const imageToDownload = this.chooseImage(thumbnailGenerationData);
      this.downloadImage(imageToDownload)
        .then(response => {
          let img = new Image();
          img.src = imageToDownload;
          img.onload = () => {
            if (this._isMounted) {
              this.setState({ imageUrl: img.src });
            }
          };
        })
        .catch(error => console.error(error));
    } else {
      this.setMessageImageUrl();
    }
  };

  setMessageImageUrl = async () => {
    let img = new Image();
    if (this.props.message.data.uniqueCode) {
      let data = await this.fetch(this.props.message.data.uniqueCode);
      if (data.Url) {
        img.src = data.Url;
      }
    } else {
      img.src = this.props.message.data.url;
    }
    img.onload = () => {
      if (this._isMounted) {
        this.setState({ imageUrl: img.src });
      }
    };
  };

  downloadImage(imgUrl) {
    const promise = new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", imgUrl, true);
      xhr.responseType = "blob";

      xhr.onload = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            this.timer = null;
            resolve(xhr.response);
          } else if (xhr.status === 403) {
            this.timer = setTimeout(() => {
              this.downloadImage(imgUrl)
                .then(response => resolve(response))
                .catch(error => reject(error));
            }, 800);
          }
        } else {
          reject(xhr.statusText);
        }
      };

      xhr.onerror = event =>
        reject(new Error("There was a network error.", event));
      xhr.ontimeout = event =>
        reject(new Error("There was a timeout error.", event));
      xhr.send();
    });

    return promise;
  }

  async fetch(id) {
    if (this.source) {
      this.source.cancel();
    }
    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();
    const classification = window.location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
		var info = await api.get(`/${classification}/site-photos/${id}/info`,
			{
				cancelToken: this.source.token
			})
			.then(res => {
				if (res.data === undefined) return Promise.reject();
				return res.data;
			})
			.catch(e => {
				this.setState({ FetchDone: false, isDeleted: true });
				return {
					Status: false,
					Code: e?.response?.status
				};
			});
		if (info.Status === false) {
			return info;
		}
		var isBlob = info.blob ? true : false;
		var path = isBlob ? info.blob.resized.replace(/^\/api/, '') : `/${classification}/site-photos/${id}/thumbnail`;
		const res = await api.get(path,
			{
				responseType: isBlob ? undefined : "arraybuffer",
				cancelToken: this.source.token
			})
			.then(res => {
				if (res.data === undefined) return Promise.reject();
				if (isBlob) {
					var url = res.data.blobData;
				} else {
					var blob = new Blob([res.data], { type: "image/jpeg" });
					var url = URL.createObjectURL(blob);
				}
        return {
          Status: true,
          Code: 200,
          Url: url
        };
      })
      .catch(e => {
        this.setState({ FetchDone: false, isDeleted: true });
        return {
          Status: false,
          Code: e?.response?.status
        };
      });

    return await res;
  }

  open = () => {
    this.props.actionGenerated(
      enums.ACTIONS["VIEW_ORIGINAL_IMAGE"],
      this.props.message
    );
  };

  handleMouseHover = (isHovering) => {
    this.setState({ isHovering });
  };

  async getData() {
    try {
      if (this.props.message.data.isDeleted) {
        this.setState({
          isDeleted: true
        }, () => {
          this.setImage();
        })
      } else {
        this.setImage();
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  render() {
    let avatar = null,
      name = null;
    let ImageMessageWrapper = null;
    if (this.props.message.receiverType === CometChat.RECEIVER_TYPE.GROUP) {
      // avatar = (
      // 	<div css={messageThumbnailStyle()} className="message__thumbnail">
      // 		<CometChatAvatar user={this.props.message.sender} />
      // 	</div>
      // );

      if (this.props.isDisplayAvatar) {
        name = (
          <div
            css={nameWrapperStyle(avatar)}
            className="message__name__wrapper"
            style={{ display: "flex", alignItems: "center" }}
          >
            {this.props.message.sender.metadata?.classification ===
              "builder" && (
                <span
                  style={{
                    display: "inline-block",
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                    backgroundColor: "#8FC31F"
                  }}
                ></span>
              )}
            &nbsp;
            <span css={nameStyle(this.context)} className="message__name">
              {this.props.message.sender.name}
              {this.props.message.sender.metadata?.company
                ? " / " + this.props.message.sender.metadata?.company
                : ""}
            </span>
          </div>
        );
      }

      if (this.props.message.data.isDeleted) {
        ImageMessageWrapper = (
          <div
            css={messageTxtContainerStyle()}
            className="message__text__container"
          >
            <div
              css={messageTxtWrapperStyle(this.context)}
              onClick={this.open}
              className="message__text__wrapper"
              style={{
                height: "auto",
                boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)",
                borderRadius: "8px"
              }}
            >
              <p className="message__txt">
                ⚠️ ファイルが削除されました。
              </p>
            </div>
          </div>
        )
      }
      else {
        ImageMessageWrapper = (<div
          css={messageImgContainerStyle()}
          className="message__image__container"
        >
          <div
            css={messageImgWrapperStyle(this.context)}
            onClick={this.open}
            className="message__image__wrapper"
            style={{
              height: "auto",
              boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)",
              borderRadius: "8px"
            }}
          >
            <img
              src={this.state.imageUrl}
              alt={this.state.imageUrl}
              ref={el => {
                this.imgRef = el;
              }}
              style={{ width: "100%", height: "auto" }}
              onLoad={this.props.loadedCallback}
            />
          </div>
        </div>)
      }
    }

    let messageReactions = null;
    const reactionsData = checkMessageForExtensionsData(
      this.props.message,
      "reactions"
    );
    if (reactionsData) {
      if (Object.keys(reactionsData).length) {
        messageReactions = (
          <div
            css={messageReactionsWrapperStyle()}
            className="message__reaction__wrapper"
          >
            <CometChatMessageReactions
              message={this.props.message}
              actionGenerated={this.props.actionGenerated}
            />
          </div>
        );
      }
    }

    let toolTipView = null;
    if (this.state.isHovering) {
      toolTipView = (
        <CometChatMessageActions
          message={this.props.message}
          actionGenerated={this.props.actionGenerated}
        />
      );
    }

    return (
      <div
        css={messageContainerStyle()}
        className="receiver__message__container message__image"
        onMouseEnter={() => this.handleMouseHover(true)}
        onMouseLeave={() => this.handleMouseHover(false)}
        style={{ marginBottom: "10px" }}
      >
        <div css={messageWrapperStyle()} className="message__wrapper">
          {avatar}
          <div css={messageDetailStyle(name)} className="message__details">
            {name}
            {toolTipView}

            <div style={{ display: "flex", alignItems: "flex-end" }}>
              {ImageMessageWrapper}
              <div
                css={messageInfoWrapperStyle()}
                className="message__info__wrapper"
              >
                <CometChatReadReceipt message={this.props.message} />
                <CometChatThreadedMessageReplyCount
                  message={this.props.message}
                  actionGenerated={this.props.actionGenerated}
                />
              </div>
            </div>

            {messageReactions}
          </div>
        </div>
      </div>
    );
  }
}

// Specifies the default values for props:
CometChatReceiverImageMessageBubble.defaultProps = {
  lang: Translator.getDefaultLanguage(),
  theme: theme,
  actionGenerated: {}
};

CometChatReceiverImageMessageBubble.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.object,
  actionGenerated: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired
};

export { CometChatReceiverImageMessageBubble };
