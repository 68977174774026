import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import ViewFileItem from "./ViewFileItem";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { api } from "../../../common/api/api";

const useStyles = makeStyles({
  label: {
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "20px",
    color: "#333333"
  },
  changFileButton: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& button": {
      width: "28px",
      minWidth: "28px",
      height: "28px",
      minHight: "28px",
      borderRadius: "50%",
      overFlow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "22px",
      color: "white"
    }
  },
  filenName: {
    color: "#00356D",
    fontSize: "15px",
    lineHeight: "20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
});
function ViewFile(props) {
  const { files, fetchDetailHotspot } = props;
  const classes = useStyles();
  const [currentShowFile, setCurrentShowFile] = useState(null);

  useEffect(() => {
    if (files.length > 0) {
      setCurrentShowFile(files[0]);
    } else {
      setCurrentShowFile(null);
    }
  }, [files]);

  const nextFile = () => {
    const orderOfCurrentFile = files.findIndex(
      file => file.uniqueCode === currentShowFile.uniqueCode
    );

    if (orderOfCurrentFile !== files.length - 1) {
      setCurrentShowFile(files[orderOfCurrentFile + 1]);
    }
  };

  const previousFile = () => {
    const orderOfCurrentFile = files.findIndex(
      file => file.uniqueCode === currentShowFile.uniqueCode
    );

    if (orderOfCurrentFile !== 0) {
      setCurrentShowFile(files[orderOfCurrentFile - 1]);
    }
  };

  const hideBtnNext = () => {
    if (!currentShowFile) {
      return false;
    }

    const orderOfCurrentFile = files.findIndex(
      file => file.uniqueCode === currentShowFile.uniqueCode
    );
    return orderOfCurrentFile === files.length - 1;
  };

  const hideBtnPrev = () => {
    if (!currentShowFile) {
      return false;
    }
    const orderOfCurrentFile = files.findIndex(
      file => file.uniqueCode === currentShowFile.uniqueCode
    );
    return orderOfCurrentFile === 0;
  };

  const showFullFile = () => {
    if (currentShowFile) {
      var isBlob = currentShowFile.blob ? true : false;
      var path = isBlob ? currentShowFile.blob.org.replace(/^\/api/, '') : currentShowFile.file.slice(4);      
      api
        .get(path, {
          responseType: isBlob ? undefined : "arraybuffer"
        })
        .then(res => {
          const type =
            currentShowFile.extension === "pdf"
              ? "application/pdf"
              : "image/jpeg";
          if (isBlob) {
            var url = res.data.blobData;
            if (currentShowFile.extension === "pdf") {
              window.open(url, "_blank");
            } else {
              var w = window.open('', "_blank");
              w.document.write(`<img src="${url}">`);
            }
          } else {
            var blob = new Blob([res.data], { type: type });
            var url = URL.createObjectURL(blob);
            window.open(url, "_blank");
          }
        })
        .catch(() => {
          alert("エラーが発生しました。 お手数ですが、再度お試しください。");
          fetchDetailHotspot();
        });
    }
  };

  return (
    <React.Fragment>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          <span className={classes.label}>ファイル</span>
        </Grid>
        <Grid item xs={9}>
          <div className={classes.filenName}>
            {currentShowFile && currentShowFile.name}
          </div>
        </Grid>
      </Grid>
      {files.length > 0 && (
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            {files[0].uniqueCode !== currentShowFile?.uniqueCode && (
              <div className={classes.changFileButton}>
                <button
                  style={{
                    marginLeft: "-8px",
                    backgroundColor: "#00356D",
                  }}
                  onClick={previousFile}
                >
                  <ArrowForwardIosIcon
                    fontSize="inherit"
                    color="inherit"
                    style={{ transform: "rotate(180deg)" }}
                  />
                </button>
              </div>
            )}
          </Grid>
          <Grid item xs={8}>
            <div
              style={{
                width: "100%",
                paddingTop: "75%",
                position: "relative",
                zIndex: 100
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  bottom: "0",
                  right: "0",
                  zIndex: "-1"
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    display: "block",
                    cursor: "pointer"
                  }}
                  onClick={showFullFile}
                >
                  {currentShowFile && (
                    <ViewFileItem files={files} currentFile={currentShowFile} />
                  )}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}>
            {files[files.length - 1].uniqueCode !==
                      currentShowFile?.uniqueCode && (
              <div className={classes.changFileButton}>
                <button
                  style={{
                    marginRight: "-8px",
                    backgroundColor: "#00356D",
                  }}
                  onClick={nextFile}
                >
                  <ArrowForwardIosIcon fontSize="inherit" color="inherit" />
                </button>
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default ViewFile;
