import {
  fetchFeatureListSuccess,
  putFeatureSuccess,
  getFeatureDetailSuccess
} from "../action/action";
import {
  finishLoading,
  dispatchError,
  enableLoading
} from "common/Stores/service/service";
import { api } from "common/api/api";

export const fetchFeatureList = () => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/features`)
      .then(res => {
        dispatch(fetchFeatureListSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const putFeature = values => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/logbuild/features`, values)
      .then(res => {
        dispatch(putFeatureSuccess(res.data));
        dispatch(fetchFeatureList());
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const getFeatureDetail = params => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/features/${params}`)
      .then(res => {
        dispatch(getFeatureDetailSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};
