import React, { memo } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import BackImage from "../../../../common/img/back_icon.svg";
const CompassSettingSaveConfirmSP = ({
  onClose,
  title,
  textButton1,
  onClickButton1,
  disabledButton1,
  textButton2,
  onClickButton2,
  disabledButton2,
  isPortrait,
  ...rest
}) => {
  return (
    <>
      <Grid className={`${isPortrait ? "compass-setting-confirm-dialog-container-save-sp" : "display-none"}`}>

        <DialogTitle className={"compass-dialog-sp-title"} disableTypography>

          <Typography variant="h5" className="compass-dialog-title-sp white-text text-center">
            <img src={BackImage} onClick={onClose} className={"white-text close-button-compass-sp"} />
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent className="confirm-content confirm-content-sp">
          <Button
            disabled={disabledButton1}
            onClick={onClickButton1}
            size="large"
            className="compass-setting-confirm-save-dialog-btn-option-sp1"
          >
            {textButton1}
          </Button>
          <Typography variant="h6" className="white-text text-center compass-dialog-text-sp">
            Or
          </Typography>
          <Typography variant="h6" className="white-text text-center compass-dialog-text-sp">
            方位情報の反映対象図面を選択してください
          </Typography>
          <Typography variant="h6" className="white-text text-center compass-dialog-text-sp">
            ※複数選択可能
          </Typography>
          <Button
            disabled={disabledButton2}
            onClick={onClickButton2}
            size="large"
            className="compass-setting-confirm-dialog-sp"
          >
            {textButton2}
          </Button>
        </DialogContent>
      </Grid>

      <Grid container className={`${!isPortrait ? "compass-setting-confirm-dialog-container-save-sp-landscape" : "display-none"}`}>

        <Grid item className="text-center compass-setting-confirm-div-left">
          <Grid container className="text-center">
            <Grid item xs={1}>
              <img src={BackImage} onClick={onClose} className={"white-text close-button-compass-sp-landscape"} />
            </Grid>
            <Grid item xs={11} className={"compass-dialog-sp-title-landscape text-center"}>
              <Typography variant="h5" className="compass-dialog-title-sp white-text text-right">
                {title}
              </Typography>
              <Button
                disabled={disabledButton1}
                onClick={onClickButton1}
                size="large"
                className="compass-setting-confirm-save-dialog-btn-option-sp1-landscape"
              >
                {textButton1}
              </Button>
            </Grid>
          </Grid>

        </Grid>
        <Grid item  className="text-center compass-dialog-sp-title-center-landscape">
          <Typography variant="h6" className="white-text text-center compass-dialog-text-sp margin-top-50">
            Or
          </Typography>
        </Grid>
        <Grid item  className="text-center compass-setting-confirm-div-right">
          <Grid container className="text-center">
            <Grid item xs={12} className={"compass-dialog-sp-title-landscape"}>
              <Typography variant="h6" className="white-text text-left compass-dialog-text-sp">
                方位情報の反映対象図面を選択してください
              </Typography>
              <Typography variant="h6" className="white-text text-left compass-dialog-text-sp">
                ※複数選択可能
              </Typography>
              <Button
                disabled={disabledButton2}
                onClick={onClickButton2}
                size="large"
                className="compass-setting-confirm-dialog-sp-landscape"
              >
                {textButton2}
              </Button>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </>
  );
};

CompassSettingSaveConfirmSP.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  textButton1: PropTypes.string,
  textButton2: PropTypes.string
};

export default memo(CompassSettingSaveConfirmSP);
