import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser, clearErrors } from "./service/service";
import Validator from "validator";
import "../../common/css/base.css";
import "../../common/css/auth.css";
import { translateMessage } from "utils/mapper";
import {
  translateValidate,
  messageValidate
} from "../../common/Stores/service/service";
import Pc from "../../common/breakpoints/Pc";
import Sp from "../../common/breakpoints/Sp";
import LoginBuilder from "./LoginBuilder";
import LoginBuilderSP from "./LoginBuilderSP";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      nextButtonClick: false,
      isPasswordShow: false,
      isAutoLoginChecked: true
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
      return true;
    }
    this.props.translateValidate();
    this.props.messageValidate();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard"); // push user to dashboard when they login
    }

    //displaying errors
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  onChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmit = (id, password, autoLogin) => {
    this.props.loginUser({ id, password }, autoLogin);
  };

  isEmptyEmail(email) {
    return Validator.isEmpty(email);
  }

  isEmailFormat(email) {
    return Validator.isEmail(email);
  }

  validateEmail(email) {
    const key = "email";
    const { message } = this.props;
    const errors = this.state.errors;
    if (this.isEmptyEmail(email))
      errors[key] = translateMessage(
        this.getMessage("required", message),
        this.props.regexObject
      );
    else if (!this.isEmailFormat(email))
      errors[key] = translateMessage(
        this.getMessage("email", message),
        this.props.regexObject
      );
    else delete errors[key];
    this.setState({ errors: errors });
    return !(key in this.state.errors);
  }

  getMessage = (key, message) => {
    return message[key];
  };

  checktButtonClick = formik => e => {
    e.preventDefault();
    const { values } = formik;
    if (!this.validateEmail(values.email)) return;

    if (this.state.nextButtonClick) {
      formik.handleSubmit();
    } else {
      this.setState({ nextButtonClick: true });
    }
  };

  render() {
    const {
      errors,
      email,
      password,
      nextButtonClick,
      isPasswordShow,
      isAutoLoginChecked
    } = this.state;

    return (
      <>
        <Sp>
          <LoginBuilderSP
            email={email}
            password={password}
            isPasswordShow={isPasswordShow}
            isAutoLoginChecked={isAutoLoginChecked}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            errors={errors}
          />
        </Sp>
        <Pc>
          <LoginBuilder
            email={email}
            password={password}
            nextButtonClick={password ? true : false}
            isPasswordShow={isPasswordShow}
            isAutoLoginChecked={isAutoLoginChecked}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            errors={errors}
          />
        </Pc>
      </>
    );
  }
}
//export default Login;

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message
});
export default connect(mapStateToProps, {
  loginUser,
  clearErrors,
  translateValidate,
  messageValidate
})(Login);
