import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import imageSrc from "../../../../common/img/log.png";

class RobotInfoBuildePC extends Component {
  render() {
    return (
      <Grid container className="mt-25">
        <Card className="log-box">
          <Grid className="wrap-image">
            <CardMedia image={imageSrc} className="log-image" />
          </Grid>
          <CardContent className="log-info">
            <TableContainer>
              <Table padding="none" aria-label="table" className="log-table">
                <TableBody className="log-table-body">
                  <TableRow>
                    <TableCell>ロボット名</TableCell>
                    <TableCell>{this.props.robotInfo.robotName}</TableCell>
                    <TableCell>
                      <Fab
                        onClick={this.props.showEditForm}
                        disableRipple
                        variant="extended"
                        className="log-button-reset"
                      >
                        <EditIcon className="icon-margin" />
                        編集
                      </Fab>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>ロボットID</TableCell>
                    <TableCell>{this.props.robotInfo.robotId}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>シリアルナンバー</TableCell>
                    <TableCell>{this.props.robotInfo.cerealCode}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {/* NOTE(knm): hid "logbuildに問い合わせる" for the 1st release, it will be shown after setting inquiry flow.@2021/02/02 */}
                      {/* <Fab
                        classes={{ label: "text-tranform-none" }}
                        onClick={this.props.showDialogContactRepair}
                        className="white-text button-log"
                        variant="extended"
                      >
                        logbuildに問い合わせる
                      </Fab> */}
                    </TableCell>
                    <TableCell>
                      {/* NOTE(knm): hid "返却" for the 1st release.@2021/02/02 */}
                      {/* <Fab
                        onClick={this.props.toggleRequestDialog}
                        className="white-text button-log btn-red pX-50"
                        variant="extended"
                      >
                        返却
                      </Fab> */}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default RobotInfoBuildePC;
