import {
  FETCH_LOGBUILD_AUTH_SUCCESS,
  FETCH_LOGBUILD_STAFF_SUCCESS,
  POST_LOGBUILD_STAFF_SUCCESS,
  FETCH_LOGBUILD_STAFF_DETAIL_SUCCESS,
  PUT_LOGBUILD_STAFF_SUCCESS,
  DELETE_LOGBUILD_STAFF_SUCCESS,
  PUT_PASSWORD_LOGBUILD_SUCCESS
} from "../constant/constant";

const initialState = {
  authEntries: [],
  staffLogBuild: [],
  staffLogBuildDetail: {}
};

export const staffLogBuildReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOGBUILD_AUTH_SUCCESS:
      return {
        ...state,
        authEntries: action.entries
      };
    case FETCH_LOGBUILD_STAFF_SUCCESS: {
      return {
        ...state,
        staffLogBuild: action.staffLogBuild,
        type: action.type
      };
    }
    case POST_LOGBUILD_STAFF_SUCCESS:
      return {
        ...state,
        type: action.type
      };
    case FETCH_LOGBUILD_STAFF_DETAIL_SUCCESS:
      return {
        ...state,
        type: action.type,
        staffLogBuildDetail: action.staffLogBuildDetail
      };
    case PUT_LOGBUILD_STAFF_SUCCESS:
      return {
        ...state,
        type: action.type
      };
    case DELETE_LOGBUILD_STAFF_SUCCESS:
      return {
        ...state,
        type: action.type
      };
    case PUT_PASSWORD_LOGBUILD_SUCCESS:
      return {
        ...state,
        type: action.type
      };

    default:
      return state;
  }
};
