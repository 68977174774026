import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import ShowEditHotspotInfo from "./ShowEditHotspotInfo";
import HotspotHeader from "./viewModeComponents/HotspotHeader";
import HotspotBaseInfo from "./viewModeComponents/HotspotBaseInfo";
import HotspotTabs from "./viewModeComponents/HotspotTabs";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams, useLocation } from "react-router-dom";
import TabFile from "./viewModeComponents/TabFile";
import TabMessage from "./viewModeComponents/TabMessage";
import { arrayBufferToBase64 } from "../../../../../utils/mapper";

import { api } from "../../../../../common/api/api";
import { useDispatch, useSelector } from "react-redux";
import { getShootSuccess } from "../../action/action";
import querySearch from "stringquery";

function ShowExistedHotspotInfo(props) {
  const {
    currentHotspotInfoId,
    onResetFlagRedirectNoImage,
    rotateToHotspotPosition,
    backToDrawing,
    logWalkInfoImage
  } = props;
  const [showEdit, setShowEdit] = useState(false);
  const [hotspotInfo, setHotspotInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [currentTab, setCurrentTab] = useState("file");
  const [miniBlobDataLogWalk, setMiniBlobDataLogWalk] = useState({
    blobDataLst: [],
    geoType: ""
  });
  const [shootThumbnailBase64, setShootThumbnailBase64] = useState("");
  const [largeShootThumbnailBase64, setLargeShootThumbnailBase64] =
    useState("");

  const chatBlockRef = useRef(null);

  const isOpenHotspotInfo = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.isOpenHotspotInfo
  );

  const blobDataLogWalk = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.blobDataLogWalk
  );

  const logWalkInfo = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.logWalkInfo
  );

  let location = useLocation();

  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !(hotspotInfo === null && !logWalkInfoImage) &&
      currentHotspotInfoId &&
      currentHotspotInfoId !== hotspotInfo?.uniqueCode
    ) {
      setShowEdit(false);
      setLoading(true);
      setMiniBlobDataLogWalk({ blobDataLst: [], geoType: "" });
      setShootThumbnailBase64("");
      setLargeShootThumbnailBase64("");

      const classification = location.pathname.includes("/sp/")
        ? "supplier"
        : "builder";

      api
        .get(`/${classification}/logwalk-viewer/infos/${currentHotspotInfoId}`)
        .then(res => {
          setHotspotInfo({ ...res.data, uniqueCode: currentHotspotInfoId });
          setLoading(false);

          const searchObj = querySearch(location.search);
          const keys = Object.keys(searchObj);
          if (keys.includes("info") && keys.includes("chat")) {
            // && searchObj.chat === undefine
            setCurrentTab("message");
          }

          if (res.data.shoot.uniqueCode === id) {
            setMiniBlobDataLogWalk(blobDataLogWalk);
          } else {
            let apiUrl = "";
            if (res.data.shoot.file) {
              apiUrl = res.data.shoot.file.slice(4);
            } else if (res.data.shoot.blob) {
              apiUrl = res.data.shoot.blob.slice(4);
            }

            if (apiUrl !== "") {
              let isBlob = true;
              if (apiUrl.includes("/file?bin")) {
                isBlob = false;
              }

              api
                .get(
                  apiUrl,
                  isBlob
                    ? {}
                    : {
                        responseType: "arraybuffer"
                      }
                )
                .then(resImg => {
                  if (resImg.data) {
                    if (isBlob) {
                      setMiniBlobDataLogWalk({
                        ...resImg.data,
                        blob: apiUrl,
                        isRefresh: false
                      });
                    } else {
                      setMiniBlobDataLogWalk({
                        blobDataLst: [
                          `data:image/jpeg;base64,${arrayBufferToBase64(
                            resImg.data
                          )}`
                        ],
                        geoType: "EQR",
                        refreshSasEstimatedTime: null,
                        blob: apiUrl,
                        isRefresh: false
                      });
                    }
                  }
                });
            }
          }
        });
    }
  }, [currentHotspotInfoId, logWalkInfoImage]);

  useEffect(() => {
    if (
      !isOpenHotspotInfo &&
      hotspotInfo &&
      id === hotspotInfo.shoot.uniqueCode
    ) {
      rotateToHotspotPosition(hotspotInfo.yaw, hotspotInfo.pitch, true);
    }
  }, [isOpenHotspotInfo]);

  const loadHotspotInfo = () => {
    setLoading(true);

    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    api
      .get(`/${classification}/logwalk-viewer/infos/${currentHotspotInfoId}`)
      .then(res => {
        setHotspotInfo({ ...res.data, uniqueCode: currentHotspotInfoId });
        setLoading(false);
      });
  };

  const handleEditSuccess = () => {
    setLoading(true);

    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    api
      .get(`/${classification}/logwalk-viewer/infos/${currentHotspotInfoId}`)
      .then(res => {
        setHotspotInfo({ ...res.data, uniqueCode: currentHotspotInfoId });
        setShowEdit(false);
        setLoading(false);
      });
    if (
      id &&
      currentHotspotInfoId &&
      logWalkInfo.infos && 
      logWalkInfo.infos.find(item => item.uniqueCode === currentHotspotInfoId)
    ) {
      api.get(`/${classification}/logwalk-viewer/shoots/${id}`).then(res => {
        dispatch(getShootSuccess(res.data));
      });
    }
  };

  const handleCloseEdit = () => {
    loadHotspotInfo();
    setShowEdit(false);

    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    if (
      id &&
      currentHotspotInfoId &&
      logWalkInfo.infos && 
      logWalkInfo.infos.find(item => item.uniqueCode === currentHotspotInfoId)
    ) {
      api.get(`/${classification}/logwalk-viewer/shoots/${id}`).then(res => {
        dispatch(getShootSuccess(res.data));
      });
    }
  };

  const deleteFileSuccess = uniqueCode => {
    setHotspotInfo({
      ...hotspotInfo,
      files: hotspotInfo.files.filter(file => file.uniqueCode !== uniqueCode)
    });

    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    if (
      id &&
      currentHotspotInfoId &&
      logWalkInfo.infos && 
      logWalkInfo.infos.find(item => item.uniqueCode === currentHotspotInfoId)
    ) {
      api.get(`/${classification}/logwalk-viewer/shoots/${id}`).then(res => {
        dispatch(getShootSuccess(res.data));
        loadHotspotInfo();
      });
    }
  };

  const saveFileNameSuccess = (uniqueCode, fileName) => {
    const indexOfFile = hotspotInfo.files.findIndex(
      file => file.uniqueCode == uniqueCode
    );
    if (indexOfFile > -1) {
      setHotspotInfo({
        ...hotspotInfo,
        files: [
          ...hotspotInfo.files.slice(0, indexOfFile),
          {
            ...hotspotInfo.files[indexOfFile],
            name: fileName
          },
          ...hotspotInfo.files.slice(indexOfFile + 1)
        ]
      });
    }
    loadHotspotInfo();
  };

  const uploadFileSuccess = () => {
    loadHotspotInfo();
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    if (
      id &&
      currentHotspotInfoId &&
      logWalkInfo.infos && 
      logWalkInfo.infos.find(item => item.uniqueCode === currentHotspotInfoId)
    ) {
      api.get(`/${classification}/logwalk-viewer/shoots/${id}`).then(res => {
        dispatch(getShootSuccess(res.data));
      });
    }
  };

  const readedHotspot = () => {
    setHotspotInfo({
      ...hotspotInfo,
      version: "readed"
    });
  };

  const resetFlagRedirectNoImage = () => {
    onResetFlagRedirectNoImage();
  };

  const onScroll = () => {
    if (chatBlockRef.current) {
      setScrollTop(
        chatBlockRef.current.scrollTop -
          document.getElementById("messages-block").offsetTop +
          50
      );
    }
  };
  return (
    <React.Fragment>
      {loading ? (
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60px",
            height: "60px"
          }}
        >
          <CircularProgress />
        </span>
      ) : (
        <React.Fragment>
          {hotspotInfo && (
            <React.Fragment>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <HotspotHeader
                  title={hotspotInfo.title}
                  colorInfo={hotspotInfo.icon}
                  setShowEdit={val => {
                    setShowEdit(val);
                  }}
                  shootUC={hotspotInfo?.shoot?.uniqueCode}
                  hotspotInfo={hotspotInfo}
                  handleSetCompleteSuccess={handleEditSuccess}
                />
                <div
                  id="chat-block"
                  style={{
                    flexGrow: "1",
                    overflowY: "auto",
                    backgroundColor: "#f8f9f9",
                    display: "flex",
                    flexDirection: "column"
                  }}
                  ref={chatBlockRef}
                  onScroll={onScroll}
                >
                  <HotspotBaseInfo
                    description={hotspotInfo.description}
                    mentions={hotspotInfo.mentions}
                    createdAt={hotspotInfo.createdAt}
                    createdBy={hotspotInfo.createdBy}
                  />
                  <div style={{ flexGrow: "1", display: "flex" }}>
                    <HotspotTabs
                      tabFile={
                        <TabFile
                          hotspotInfo={hotspotInfo}
                          currentHotspotInfoId={currentHotspotInfoId}
                          uploadFileSuccess={uploadFileSuccess}
                          saveFileNameSuccess={saveFileNameSuccess}
                          deleteFileSuccess={deleteFileSuccess}
                          readedHotspot={readedHotspot}
                          onResetFlagRedirectNoImage={resetFlagRedirectNoImage}
                          rotateToHotspotPosition={(yaw, pitch) =>
                            rotateToHotspotPosition(yaw, pitch, false)
                          }
                          backToDrawing={() => backToDrawing()}
                          miniBlobDataLogWalk={miniBlobDataLogWalk}
                          shootThumbnailBase64={shootThumbnailBase64}
                          setShootThumbnailBase64={setShootThumbnailBase64}
                          largeShootThumbnailBase64={largeShootThumbnailBase64}
                          setLargeShootThumbnailBase64={
                            setLargeShootThumbnailBase64
                          }
                        />
                      }
                      tabMessage={
                        !showEdit ? (
                          <TabMessage
                            isLoginCometChat={props.isLoginCometChat}
                            messageId={hotspotInfo.messageId}
                            hotspotInfo={hotspotInfo}
                            scrollTop={scrollTop}
                          />
                        ) : null
                      }
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                      scrollTop={scrollTop}
                    />
                  </div>
                </div>
              </div>
              {showEdit ? (
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    bottom: "0",
                    right: "0",
                    zIndex: "120"
                  }}
                >
                  <ShowEditHotspotInfo
                    editHotspotInfo={hotspotInfo}
                    currentHotspotInfoId={currentHotspotInfoId}
                    handleEditSuccess={handleEditSuccess}
                    handleCloseEdit={handleCloseEdit}
                  />
                </div>
              ) : null}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

ShowExistedHotspotInfo.propTypes = {};

export default ShowExistedHotspotInfo;
