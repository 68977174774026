import {
  FETCH_USER_PROFILE_SUCCESS,
  PUT_USER_PROFILE_SUCCESS,
  PUT_USER_PASSWORD_LOGBUILD_SUCCESS,
  PUT_USER_PASSWORD_BUILDER_SUCCESS,
  PUT_USER_PROFILE_START,
  PUT_USER_PASSWORD_START
} from "../constant/constant";

const initialState = {
  pending: false,
  profile: null,
  type: null
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        type: action.type,
        profile: action.profile
      };
    case PUT_USER_PASSWORD_START:
    case PUT_USER_PROFILE_START:
    case PUT_USER_PASSWORD_BUILDER_SUCCESS:
    case PUT_USER_PASSWORD_LOGBUILD_SUCCESS:
    case PUT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        type: action.type
      };

    default:
      return state;
  }
};

export const getProfile = state => state.profileReducer.profile;
export const getType = state => state.profileReducer.type;
