import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { api } from "../../common/api/api";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../css/master.css";
import { checkAuth, disableLoading } from "../Stores/service/service";
import { getUserDetail } from "components/users/Staff/reducer/reducer";
import { fetchUserDetail } from "components/users/Staff/service/service";
import { disCheckAuthSuccess } from "common/Stores/action/action";
import OneSignal from "react-onesignal";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import userImage from "../../common/img/user.png";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import PhoneIcon from "@material-ui/icons/Phone";
import logmeetConfig from "../../common/config/LogmeetConfig";
import { configStorage } from "../../common/storage/storage";
import jwt_decode from "jwt-decode";

class MasterLayout extends Component {
  intervalCallingDismiss = null;

  constructor(props) {
    super(props);
    this.state = {
      fetchDone: false,
      externalID: null,
      playerID: null,
      isCalling: false,
      callData: null,
      currentUserUniqueCode: null
    };
  }

  componentWillMount() {
    if (
      !window.location.pathname.includes("/logbuild/") &&
      !window.location.pathname.includes("/sp/") &&
      window.location.hostname != "localhost" &&
      window.location.hostname != "127.0.0.1"
    ) {
      this.handleRegisterDevices();
    }

    const token = localStorage.jwtToken
      ? localStorage.jwtToken
      : api.defaults.headers.common["Authorization"];
    const decoded = jwt_decode(token);
    this.setState({
      currentUserUniqueCode: decoded.uniqueCode
    });

    if (this.props.isFixSpot) {
      this.checkAccessFixSpot();
    } else {
      this.setState({ fetchDone: true });
    }
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });
    let talksUCParam = params.talksUC;
    let userNameParam = params.userName;
    let companyNameParam = params.companyName;
    let expireTimeParam = params.expireTime;
    const timetic = new Date(parseInt(expireTimeParam)) - new Date();
    if (talksUCParam && timetic > 0) {
      this.setState(
        {
          isCalling: true,
          callData: {
            data: {
              talksUC: talksUCParam,
              userName: userNameParam,
              companyName: companyNameParam,
              expireTime: expireTimeParam
            }
          }
        },
        () => {
          clearInterval(this.intervalCallingDismiss);
          this.intervalCallingDismiss = setInterval(
            this.handleCallingDismiss,
            timetic
          );
        }
      );
    }
  }

  handleRegisterDevices = async () => {
    const appID = await this.registerDevice("", 2);
    await OneSignal.init({
      appId: appID,
      autoResubscribe: true
    });
    OneSignal.getUserId().then(playerID => {
      if (playerID) {
        this.setState({
          playerID: playerID
        });
        this.registerDevice(playerID, 2);
      }
    });
    OneSignal.on("subscriptionChange", async () => {
      const playerID = await OneSignal.getUserId();
      if (playerID) {
        this.setState({
          playerID: playerID
        });
        this.registerDevice(playerID, 2);
      }
    });
    await OneSignal.on("notificationDisplay", event => {
      this.handleCalling(event);
    });
  };

  registerDevice = async (playerID, deviceType) => {
    const classification = window.location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();
    let appID = await api
      .post(`/${classification}/logmeet/device/register`, {
        deviceType: deviceType,
        deviceId: playerID
      })
      .then(res => res.data.appId);
    return appID;
  };

  unregisterDevice = async playerID => {
    if (!playerID) {
      return;
    }
    const classification = window.location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();
    await api.post(`/${classification}/logmeet/device/unregister`, {
      playerId: playerID
    });
  };

  handleCalling = e => {
    console.log("handle calling", e.data);
    // show calling modal
    if (!e.data.callStatus) {
      const timetic = new Date(parseInt(e.data.expireTime)) - new Date();
      console.log("show modal", timetic);
      if (!this.state.callData && timetic > 0) {
        this.setState(
          {
            isCalling: true,
            callData: e
          },
          () => {
            clearInterval(this.intervalCallingDismiss);
            this.intervalCallingDismiss = setInterval(
              this.handleCallingDismiss,
              timetic
            );
          }
        );
      }
    }
    // join call -> hide modal,  stop call -> hide modal
    if (
      e.data.callStatus === "1" ||
      e.data.callStatus === "3" ||
      e.data.callStatus === "2"
    ) {
      console.log("hide modal", e.data.callStatus);

      this.handleCancleCall();
    }
  };

  handleCallingDismiss = e => {
    this.setState({
      isCalling: false,
      callData: null
    });
  };

  componentDidUpdate() {
    if (this.props.loading && this.props.finishLoading) {
      this.props.disableLoading();
    }
    if (this.props.check) {
      this.props.disCheckAuthSuccess();
      this.props.fetchUserDetail(this.props.auth.user.uniqueCode);
    }
  }

  checkAccessFixSpot() {
    api
      .get(`/builder/fix-spots`)
      .then(res => {
        this.setState({ fetchDone: true });
      })
      .catch(err => {
        this.props.history.push(`/not-found`);
        this.setState({ fetchDone: true });
      });
  }

  handleAcceptCall = () => {
    const classification = window.location.pathname.includes("/sp/")
      ? 1 // Supplier
      : 0; // Builder;

    const logMeetUrl = logmeetConfig.getLogMeetURL(window.location.hostname);
    const url = `${logMeetUrl}?${new URLSearchParams({
      token: configStorage().replace("Bearer ", ""),
      talk: this.state.callData.data.talksUC,
      role: classification
    })}`;
    window.open(url, "_blank").focus();
    this.setState({
      isCalling: false,
      callData: null
    });
  };

  handleCancleCall = () => {
    this.setState({
      isCalling: false,
      callData: null
    });
  };

  cancleCall = () => {
    this.setState({
      isCalling: false,
      callData: null
    });
    const classification = window.location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();
    api.post(
      `/${classification}/logmeet/talk/${this.state.callData.data.talksUC}/cancel`,
      {
        usersUC: this.state.callData.data.uid
      }
    );
  };

  render() {
    const { fetchDone, isCalling, callData } = this.state;
    const { loading } = this.props;

    return fetchDone ? (
      <div className="root">
        <div>
          {loading && (
            <div className="loading">
              <div className="loadingCircular">
                <CircularProgress variant="indeterminate" />
              </div>
            </div>
          )}
          {this.props.children}
          {isCalling && (
            <div className="call-receive-modal">
              <Grid container className="call-receive-container">
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold"
                    }}
                  >
                    {callData.data.userName}
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px"
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500"
                    }}
                  >
                    着信中
                  </span>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Fab
                    onClick={this.cancleCall}
                    size="small"
                    className="cancle-call-button"
                  >
                    <CloseIcon style={{ color: "white", fontSize: 24 }} />
                  </Fab>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Fab
                    onClick={this.handleAcceptCall}
                    size="small"
                    className="accept-call-button"
                  >
                    <PhoneIcon style={{ color: "white", fontSize: 24 }} />
                  </Fab>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </div>
    ) : (
      <></>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.storesReducer.pending,
  finishLoading: state.storesReducer.finishLoading,
  userDetail: getUserDetail(state.staffsReducer.userDetail),
  check: state.storesReducer.checkAuth,
  auth: state.auth
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      disableLoading,
      fetchUserDetail,
      checkAuth,
      disCheckAuthSuccess
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MasterLayout));
