// TODO: need use span for both mention node and text node like skype: <span>@mention</span><span>text</span>
export default class MentionManager {
  constructor() {
    this.oldHtmlValue = "";
    // this.newHtmlValue = "";
    this.splitHtmlBySpan = []; // this property to get caret position detail
    this.mentionValue = "@";
    this.mentionStart = '<span class="chat-box-input-tag-user">@';
    this.mentionEnd1 = "</span>";
    this.mentionEnd2 = "</span>&nbsp;";
    this.specialCharacters = [
      { value1: "&", value2: "&amp;" },
      { value1: "<", value2: "&lt;" },
      { value1: ">", value2: "&gt;" }
    ];
    this.tempTagStart =
      '<font color="#0000ff"><span style="background-color: rgb(128, 128, 128);">';
    this.tempTagEnd = "</span></font>";
    this.tempTagStart2 = '<font color="#3f51b5">';
    this.tempTagEnd2 = "</font>";
    this.tempTagStart3 = '<font color="rgba(0, 0, 0, 0.870588235294118)">';
    this.tagBeforeCaret = 0;
    this.tempTagStart4 = '<span style="caret-color: rgb(63, 81, 181);">';
  }

  transformInputAndCheckShowMentions = (value, target, allMentions, event) => {
    let result, caretPosition;

    let valueToCaret = this.getHtmlToCaret(target);
    let oldValue = this.getOldHtmlValue();
    // find firt change location
    let k = 0;
    for (
      let i = 0, j = 0;
      i < valueToCaret.length, j < oldValue.length;
      i++, j++
    ) {
      if (valueToCaret[i] !== oldValue[j]) {
        k = i;
        break;
      }
    }

    this.countTagBeforeCaret(valueToCaret);

    if (event === "oninput" || event === "oninputManual") {

      // transform value due to special situations
      value = this.removeTempTag(value);

      // bug typing in beginning of input when have already a mention there make text inside span, error on chrome/edge
      // do caret đặt sai vị trí khi onselect
      // TODO: gộp 2 cái if này vào 1
      // TODO: need check browser for easier
      if (
        value.startsWith('<span class="chat-box-input-tag-user">') &&
        value.charAt(38) !== "@" &&
        value.charAt(39) === "@" &&
        oldValue.startsWith('<span class="chat-box-input-tag-user">')
      ) {
        let mentionStartLength = this.mentionStart.length;
        if (
          value.substring(0, mentionStartLength) !==
            oldValue.substring(0, mentionStartLength) ||
          value.substring(0, mentionStartLength + 1) ===
            '<span class="chat-box-input-tag-user">@@'
        ) {
          value =
            value.substring(mentionStartLength - 1, mentionStartLength) +
            value.substring(0, mentionStartLength - 1) +
            value.substring(mentionStartLength, value.length);
        }
      }
      // bug typing in beginning of new line when have already a mention there make text inside span, error on chrome/edge
      else if (
        valueToCaret.substring(
          valueToCaret.length - this.mentionStart.length,
          valueToCaret.length - 1
        ) === '<span class="chat-box-input-tag-user">'
      ) {
        value =
          valueToCaret.substring(
            0,
            valueToCaret.length - this.mentionStart.length
          ) +
          value.charAt(valueToCaret.length - 1) +
          '<span class="chat-box-input-tag-user">' +
          value.substring(valueToCaret.length, value.length);
      }
      // NOTE: these cases for only chrome/edge
      else {
        if (k != valueToCaret.length && valueToCaret.slice(-7) === "</span>") {
          let textChange = value.substring(k, valueToCaret.length - 7);
          // console.log('khi @mention, xóa space hoặc di chuyển caret về @mention{caret}, xuống dòng', textChange)
          value =
            value.substring(0, k) +
            "</span>" +
            textChange +
            value.substring(valueToCaret.length, value.length);
        } else if (
          k != valueToCaret.length &&
          oldValue.substring(k, k + 7) === "</span>" &&
          valueToCaret.slice(-38) !== '<span class="chat-box-input-tag-user">'
        ) {
          let textChange = valueToCaret.substring(k, valueToCaret.length);
          // console.log('khi @mention, xóa space hoặc di chuyển caret về @mention{caret}, gõ text', textChange)
          value =
            value.substring(0, k) +
            "</span>" +
            textChange +
            value.substring(k + textChange.length + 7, value.length);
        } else if (
          k != valueToCaret.length &&
          oldValue.substring(k, k + 7) === "</span>" &&
          valueToCaret.slice(-38) === '<span class="chat-box-input-tag-user">'
        ) {
          let textChange = valueToCaret.substring(
            k,
            valueToCaret.length - 38 - 7
          );
          // console.log('khi @mention{caret}@mention, xuống dòng', textChange)
          value =
            value.substring(0, k) +
            "</span>" +
            textChange +
            value.substring(k + textChange.length + 7, value.length);
        }
      }

      let newHtmlValue = this.generateNewHtmlValue(value);

      this.setOldHtmlValue(newHtmlValue);

      caretPosition = this.getCaretPosition(target); // after generateNewHtmlValue
      target.innerHTML = newHtmlValue; // TODO: why getCaretPosition wrong if after this line
      // truyền valueToCaret vào ko thật sự đúng vì giá trị input đã thay đổi rồi, nhưng đang chỉ check mấy ký tự cuối nên ok
      result = this.checkShowMentionsAtPosition(
        newHtmlValue,
        allMentions,
        caretPosition,
        valueToCaret
      );

      // because typing can lead unmention, so need set new caret position
      if (newHtmlValue.length != 0) {
        if (
          caretPosition.nodeTypeOfRange === 1 &&
          (caretPosition.nodeOfRange.nodeName === "BR" ||
            caretPosition.nodeOfRange.nodeName === "DIV")
        ) {
          // mean caret at between two br, occure on firefox when breakline , ex: 1 <br><br>
          this.setCaretWhenBreakline(target, caretPosition.offsetOfRange);
        } else {
          this.setCaretToLocation(target, caretPosition);
        }
      }
    } else {
      // onselect: value not changed
      this.splitHtmlBySpan = this.infoSeperate(value);
      caretPosition = this.getCaretPosition(target);
      result = this.checkShowMentionsAtPosition(
        value,
        allMentions,
        caretPosition,
        valueToCaret
      );
    }

    return { checMention: result, caretPosition };
  };

  escapeHTML = text => {
    var textRaw = text;
    const hasHead = textRaw.startsWith(this.mentionStart);
    const hasTail = textRaw.endsWith(this.mentionEnd2);

    if (hasHead) {
      textRaw = textRaw.slice(this.mentionStart.length);
    }

    if (hasTail) {
      textRaw = textRaw.slice(0, -this.mentionEnd2.length);
    }

    var replacements = {
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;"
    };

    const textRawEncode = text
      .replace(/[<>"]/g, function (character) {
        return replacements[character];
      })
      .replace(
        /&lt;span class=&quot;chat-box-input-tag-user&quot;&gt;@/g,
        this.mentionStart
      )
      .replace(/&lt;\/span&gt;/g, this.mentionEnd1);

    return textRawEncode;
  };

  // TODO: think can use range.insertNode, why dont do that
  insertMentionTag = (mention, target, isMentionByMark) => {
    var htmlValue = target.innerHTML;
    var caretPosition = this.getCaretPosition(target);
    var mentionValue = isMentionByMark
      ? this.insertMentionTagToPositionByMark(htmlValue, mention, caretPosition)
      : this.insertMentionTagToPosition(htmlValue, mention, caretPosition);

    if (mentionValue.is_enable) {
      var innerHTML = this.escapeHTML(mentionValue.data);

      // [WARN] Replace to fix bug new line on firefox not working
      if(navigator.userAgent.indexOf("Firefox") !== -1){
        innerHTML = innerHTML.replaceAll(
          " &lt;br&gt;<span class=\"chat-box-input-tag-user\">@",
          " <br><span class=\"chat-box-input-tag-user\">@",
        ).replaceAll("&lt;br&gt;", "<br>");
      }
      target.innerHTML = innerHTML;
      this.setCaretToLocation(target, mentionValue.position);
    }
    return mentionValue;
  };

  // this function has issue: convert &nbsp; to """
  getHtmlToCaret = el => {
    const sel = window.getSelection();
    const range = sel.getRangeAt(0);
    const newRange = document.createRange();
    newRange.selectNodeContents(el);
    newRange.setEnd(range.endContainer, range.endOffset);
    let htmlToCaret = "";
    for (let i = 0; i < el.childNodes.length; i++) {
      if (
        el.childNodes[i].nodeType === 1 &&
        el.childNodes[i].nodeName !== "FONT"
      ) {
        // nodeName: SPAN or BR
        if (el.childNodes[i] == newRange.endContainer) {
          htmlToCaret += el.childNodes[i].outerHTML;
          break;
        } else if (el.childNodes[i].firstChild == newRange.endContainer) {
          htmlToCaret +=
            '<span class="chat-box-input-tag-user">' +
            el.childNodes[i].firstChild.data.substring(0, range.endOffset);
          break;
        } else {
          htmlToCaret += el.childNodes[i].outerHTML.replace(
            this.tempTagStart3,
            ""
          );
        }
      } else if (el.childNodes[i].nodeType == 3) {
        if (el.childNodes[i] == newRange.endContainer) {
          htmlToCaret += el.childNodes[i].data.substring(0, range.endOffset);
          break;
        } else {
          htmlToCaret += el.childNodes[i].data;
        }
      } else if (
        el.childNodes[i].nodeType === 1 &&
        el.childNodes[i].nodeName === "FONT"
      ) {
        // sometimes font tag is inserted auto, so remove it and recognize as text node
        if (el.childNodes[i].firstChild == newRange.endContainer) {
          htmlToCaret += el.childNodes[i].firstChild.data.substring(
            0,
            range.endOffset
          );
          break;
        } else {
          htmlToCaret += el.childNodes[i].firstChild.data;
        }
      }
    }
    return htmlToCaret;
  };

  generateNewHtmlValue = value => {
    let splitOldValue = this.separateHtml(this.oldHtmlValue);
    let splitNewValue = this.separateHtml(value);
    // console.log(splitOldValue, splitNewValue)

    // find first and last change position
    let firstChange, lastChange;
    for (
      let i = 0, j = 0;
      i < splitNewValue.length, j < splitOldValue.length;
      i++, j++
    ) {
      if (
        !splitNewValue[i] ||
        this.escapeHTML(splitNewValue[i].value) !==
          this.escapeHTML(splitOldValue[j].value) ||
        splitNewValue[i].type !== splitOldValue[j].type
      ) {
        firstChange = i;
        break;
      }
    }
    for (
      let i = splitNewValue.length - 1, j = splitOldValue.length - 1;
      i >= 0 && j >= 0;
      i--, j--
    ) {
      if (
        this.escapeHTML(splitNewValue[i].value) !==
          this.escapeHTML(splitOldValue[j].value) ||
        splitNewValue[i].type !== splitOldValue[j].type
      ) {
        lastChange = i;
        break;
      }
    }
    // console.log(firstChange, lastChange)

    // generate new value, use check change
    // update splitNewValue from firstChange to lastChange
    let updateSplitNewValue = [...splitNewValue];
    if (firstChange !== undefined && lastChange !== undefined) {
      if (firstChange === lastChange) {
        if (splitNewValue[firstChange].type === 1) {
          updateSplitNewValue[firstChange].type = 0;
        }
      } else if (firstChange < lastChange) {
        for (let i = firstChange; i <= lastChange; i++) {
          if (splitNewValue[i].type === 1) {
            updateSplitNewValue[i].type = 0;
          }
        }
      }
    }
    let resul0 = "";
    for (let i = 0; i < updateSplitNewValue.length; i++) {
      if (updateSplitNewValue[i].type === 0) {
        resul0 = resul0 + updateSplitNewValue[i].value;
      } else {
        resul0 =
          resul0 +
          '<span class="chat-box-input-tag-user">' +
          updateSplitNewValue[i].value +
          "</span>";
      }
    }

    // update splitHtmlBySpan, TODO: can dont use splitHtmlBySpan?
    let bnm = [],
      c = resul0;
    while (c.indexOf('<span class="chat-box-input-tag-user">') !== -1) {
      let a = c.indexOf('<span class="chat-box-input-tag-user">');
      let b = c.indexOf("</span>");
      if (a !== -1) {
        if (a > 0) {
          bnm.push({ type: 0, value: c.slice(0, a) });
        }
        bnm.push({ type: 1, value: c.slice(a + 39, b) });
        c = c.slice(b + 7, c.length);
      }
    }
    bnm.push({ type: 0, value: c });
    this.splitHtmlBySpan = bnm;

    return resul0;
  };

  checkShowMentionsAtPosition = (
    htmlValue,
    allMentions,
    caretPosition,
    valueToCaret
  ) => {
    // bởi vì đã tranform input nên check show mention bằng cách đọc data, nếu ko có thể check bằng selection, range
    var mention = { is_enable: false, data: [] };

    // if onselect inside mention span
    if (caretPosition.type === 1) {
      return { is_enable: false, data: [] };
    }

    const indexOfSpan = caretPosition.valueToCaret.lastIndexOf("</span>");
    const indexOfBrAtSign = valueToCaret.lastIndexOf("<br>@");
    // new case: show mention list when last element is a mention span
    if (
      indexOfSpan !== -1 &&
      caretPosition.valueToCaret[indexOfSpan + 7] === "@"
    ) {
      let searchString = caretPosition.valueToCaret.substring(
        indexOfSpan + 8,
        caretPosition.valueToCaret.length
      );
      let data = allMentions.filter(o => {
        if (o.name.toUpperCase().startsWith(searchString.toUpperCase())) {
          return true;
        }
        if (
          o.furigana &&
          o.furigana.toUpperCase().startsWith(searchString.toUpperCase())
        ) {
          return true;
        }
        return false;
      });
      if (data.length > 0) {
        return {
          is_enable: true,
          data
        };
      }
    }
    // when mention at beginning of new break line in firefox
    else if (indexOfBrAtSign !== -1) {
      let searchString = valueToCaret.substring(
        indexOfBrAtSign + 5,
        valueToCaret.length
      );
      let data = allMentions.filter(o => {
        if (o.name.toUpperCase().startsWith(searchString.toUpperCase())) {
          return true;
        }
        if (
          o.furigana &&
          o.furigana.toUpperCase().startsWith(searchString.toUpperCase())
        ) {
          return true;
        }
        return false;
      });
      if (data.length > 0) {
        return {
          is_enable: true,
          data
        };
      }
    }

    // get text value to caret
    const value = this.convertHtmlToAnotherType(htmlValue, "@", "", ""); // text <span class=\"chat-box-input-tag-user\">@mention</span>&nbsp; =>  text @mention&nbsp;
    const removedBrValue = this.replaceAll(value, "<br>", ""); // breakline in firefox
    var textValue = this.replaceAllSpecialText(removedBrValue); // text @mention
    var textValueToCaret = textValue.substring(0, caretPosition.totalText); // get text value from begin to caret position => text @mention text @

    // spliting only use two last values, maybe need refactor
    var myArr = textValueToCaret.split(this.mentionValue); // ["text ", "mention text ", ""]
    if (myArr.length > 1) {
      var lastValue = myArr[myArr.length - 1]; // @ | @me..
      var data = allMentions.filter(o => {
        if (o.name.toUpperCase().startsWith(lastValue.toUpperCase())) {
          return true;
        }
        if (
          o.furigana &&
          o.furigana.toUpperCase().startsWith(lastValue.toUpperCase())
        ) {
          return true;
        }
        return false;
      });

      // comment to fix bug 2 mentions then delete 1
      // if (location.lastType > 0) { // TODO: what that mean lasttype
      //   mention.is_enable = true;
      //   mention.data = data;
      // }

      if (data.length > 0) {
        var textValueBefore = myArr[myArr.length - 2];
        if (textValueBefore.length == 0) {
          // 1. @ at the beginning of input
          mention.is_enable = true;
          mention.data = data;
        } else {
          // 2. @ at the middle of input, need check the text which before @ must be {space, @, &nbsp;, <br>}
          var lastCharacter = textValueBefore.charAt(
            textValueBefore.length - 1
          );
          if (
            lastCharacter == " " ||
            lastCharacter == this.mentionValue ||
            lastCharacter == ";" ||
            lastCharacter == "\n"
            // || valueToCaret.substring(valueToCaret.length - 5, htmlValue.length) == "<br>@"
          ) {
            mention.is_enable = true;
            mention.data = data;
          }
        }
      }
    }
    // TODO: khi nào length = 1

    return mention;
  };

  // Count tags before caret
  countTagBeforeCaret = valueToCaret => {
    if (valueToCaret === this.mentionStart) {
      // For case caret at the beginning message
      this.tagBeforeCaret = 0;
    } else {
      // For other cases
      this.tagBeforeCaret = valueToCaret.split(this.mentionStart).length - 1;
    }
  };

  removeTempTag = value => {
    return value
      .replace(this.tempTagStart4, "")
      .replace(this.tempTagStart, "")
      .replace(this.tempTagEnd, "")
      .replace(this.tempTagStart2, "")
      .replace(this.tempTagEnd2, "")
      .replace(this.tempTagStart3, "");
  };

  // this to obtain a array which is used to get caret postion detail TODO: will shoud use another
  // split html value by span
  // response: [{value: "text ", type: 0}, {value: "@mention", type: 1}, {value: "&nbsp;text @", type: 0}]
  infoSeperate = htmlValue => {
    // type = 0: normal text
    // type = 1: text inside mention span, ex: @mention
    let arr = htmlValue.split(this.mentionStart);
    let stringType = [];
    if (arr.length > 0) {
      // type text
      stringType.push({ type: 0, value: arr[0] });
    }
    for (let i = 1; i < arr.length; i++) {
      if (arr[i].includes(this.mentionEnd1)) {
        let endText = arr[i].split(this.mentionEnd1);
        endText = arr[i].split(this.mentionEnd1);
        if (endText.length == 1) {
          stringType.push({ type: 1, value: endText[0] });
        } else if (endText.length == 0) {
          stringType.push({ type: 1, value: "" });
        } else if (endText.length > 1) {
          stringType.push({ type: 1, value: endText[0] });
          let newString = "";
          for (let j = 1; j < endText.length; j++) {
            if (j > 1) {
              newString += this.mentionEnd1;
            }
            newString += endText[j];
          }
          stringType.push({ type: 0, value: newString });
        }
      } else {
        stringType.push({ type: 0, value: arr[i] });
      }
    }

    return stringType;
  };

  // response ex: [type: 0, value: '1234', type: 1, value: "mention"]
  separateHtml = value => {
    let result = [];
    const mentionStart = '<span class="chat-box-input-tag-user">';
    const mentionend = "</span>";
    while (value.indexOf(mentionStart) !== -1) {
      let a = value.indexOf(mentionStart);
      let b = value.indexOf(mentionend);
      if (a !== -1) {
        if (a > 0) {
          result.push({ type: 0, value: value.slice(0, a) });
        }
        result.push({
          type: 1,
          value: value.slice(a + mentionStart.length, b)
        });
        value = value.slice(b + mentionend.length, value.length);
      }
    }
    result.push({ type: 0, value: value });
    return result;
  };

  insertMentionTagToPosition = (text, mention, caretPosition) => {
    // when caret at inside span
    if (caretPosition.type != 0 || !!!mention || !!!mention.name) {
      return { is_enable: false, data: "", lengAdd: 0 };
    }

    var mentionValue = { is_enable: false, data: "", position: caretPosition };

    // var realText = this.convertHTmlToAnotherType(text,'@','','');
    const removeBrText = this.replaceAll(text, "<br>", ""); // breakline in firefox
    var realText = this.replaceAllSpecialText(removeBrText);
    var textToCaret = realText.substring(
      0,
      caretPosition.totalHtmlToLastestCloseSpan + caretPosition.textNodeOffset
    );

    // breakline in firefox
    const splitByBr = this.replaceAllSpecialText(text).split("<br>");
    const { textToCaretContainBr, textRemain } = this.separateTextContainBr(
      splitByBr,
      textToCaret
    );
    const indexOfLastAtSign = textToCaretContainBr.lastIndexOf("@");
    const textToBeforeCaretAtSign = textToCaretContainBr.substring(
      0,
      indexOfLastAtSign
    );

    var myArr = textToCaret.split(this.mentionValue);
    // var string_data = myArr[0];
    var string_data;
    // for (var i = 1; i < (myArr.length - 1); i++) {
    //   string_data = string_data + this.mentionValue + myArr[i];
    // }

    if (!myArr[myArr.length - 1].includes(this.mentionEnd1)) {
      // string_data = string_data + this.mentionStart + mention.name + this.mentionEnd2 + textRemain;
      string_data =
        textToBeforeCaretAtSign +
        this.mentionStart +
        mention.name +
        this.mentionEnd2 +
        textRemain;

      mentionValue.is_enable = true;
      mentionValue.data = string_data;
      // mentionValue.data = hahaha(string_data, 1, 0, "<br>");

      var lengthAdd = mention.name.length - myArr[myArr.length - 1].length + 1;
      if (myArr.length == 1) {
        lengthAdd = mention.name.length + 1;
      }
      // lengthAdd++;
      mentionValue.position.textNodeOffset = 1; // TODO: why
      mentionValue.position.type = 0;
      mentionValue.position.totalText =
        mentionValue.position.totalText + lengthAdd;

      this.setOldHtmlValue(string_data);
      // this.generateNewHtmlValue(string_data);
    } else {
      // when run into this?
    }
    return mentionValue;
  };

  insertMentionTagToPositionByMark = (text, mention, caretPosition) => {
    let mentionValue = { is_enable: false, data: "", position: caretPosition };

    console.log(this.tagBeforeCaret);
    // when caret at inside span
    if (
      caretPosition.type === 1 &&
      caretPosition.type === caretPosition.typeExactly
    ) {
      // var realText = this.convertHTmlToAnotherType(text,'@','','');
      const removeBrText1 = this.replaceAll(text, "<br>", ""); // breakline in firefox
      var realText1 = this.replaceAllSpecialText(removeBrText1);
      var textToCaret1 = realText1.substring(
        0,
        caretPosition.totalHtmlToLastestSpan + caretPosition.textNodeOffset
      );

      // breakline in firefox
      const splitByBr1 = this.replaceAllSpecialText(text).split("<br>");
      const { textToCaretContainBr, textRemain } = this.separateTextContainBr(
        splitByBr1,
        textToCaret1,
        caretPosition.nodeTypeOfRange
      );
      const textToBeforeCaretAtSign1 = textToCaretContainBr;

      const countBr = textToBeforeCaretAtSign1.split("<br>").length - 1;
      let string_data1 =
        textToBeforeCaretAtSign1.substring(
          0,
          caretPosition.totalHtmlToLastestSpan -
            this.mentionStart.length +
            1 +
            countBr * 4
        ) +
        textToBeforeCaretAtSign1.substring(
          caretPosition.totalHtmlToLastestSpan + countBr * 4,
          textToBeforeCaretAtSign1.length
        ) +
        this.mentionStart +
        mention.name +
        this.mentionEnd2 +
        textRemain.substring(0, caretPosition.textRemainLength) +
        textRemain.substring(
          caretPosition.textRemainLength + this.mentionEnd1.length,
          textRemain.length
        );

      mentionValue.is_enable = true;
      mentionValue.data = string_data1;

      var lengthAdd1 = mention.name.length + 2;
      mentionValue.position.textNodeOffset = 1;
      mentionValue.position.type = 0;
      mentionValue.position.totalText =
        mentionValue.position.totalText + lengthAdd1;

      this.setOldHtmlValue(string_data1);

      return mentionValue;
    }
    // when caret at: <span>@mention{caret}</span>
    else if (caretPosition.type !== caretPosition.typeExactly) {
      const removeBrText1 = this.replaceAll(text, "<br>", ""); // breakline in firefox
      var realText1 = this.replaceAllSpecialText(removeBrText1);
      var textToCaret1 = caretPosition.valueToCaret;

      // breakline in firefox
      const splitByBr1 = this.replaceAllSpecialText(text).split("<br>");
      const { textToCaretContainBr, textRemain } = this.separateTextContainBr(
        splitByBr1,
        textToCaret1,
        caretPosition.nodeTypeOfRange
      );
      const textToBeforeCaretAtSign1 = textToCaretContainBr;

      let string_data1 =
        textToBeforeCaretAtSign1 +
        this.mentionStart +
        mention.name +
        this.mentionEnd2 +
        textRemain;

      mentionValue.is_enable = true;
      mentionValue.data = string_data1;

      var lengthAdd1 = mention.name.length + 2;
      mentionValue.position.textNodeOffset = 1;
      mentionValue.position.type = 0;
      mentionValue.position.totalText =
        mentionValue.position.totalText + lengthAdd1;

      this.setOldHtmlValue(string_data1);

      return mentionValue;
    }

    // var realText = this.convertHTmlToAnotherType(text,'@','','');
    const removeBrText = this.replaceAll(text, "<br>", ""); // breakline in firefox
    var realText = this.replaceAllSpecialText(removeBrText);
    var textToCaret = realText.substring(
      0,
      caretPosition.totalHtmlToLastestCloseSpan + caretPosition.textNodeOffset
    );

    // breakline in firefox
    const splitByBr = this.replaceAllSpecialText(text).split("<br>");
    const { textToCaretContainBr, textRemain } = this.separateTextContainBr(
      splitByBr,
      textToCaret,
      caretPosition.nodeTypeOfRange
    );
    const textToBeforeCaretAtSign = textToCaretContainBr;

    let string_data =
      textToBeforeCaretAtSign +
      this.mentionStart +
      mention.name +
      this.mentionEnd2 +
      textRemain;
    mentionValue.is_enable = true;
    mentionValue.data = string_data;

    var lengthAdd = mention.name.length + 2;
    mentionValue.position.textNodeOffset = 1;
    mentionValue.position.type = 0;
    mentionValue.position.totalText =
      mentionValue.position.totalText + lengthAdd;

    this.setOldHtmlValue(string_data);

    return mentionValue;
  };

  separateTextContainBr = (splitByBr, textToCaret, nodeTypeOfRange) => {
    let a = "",
      textToCaretContainBr = "",
      textRemain = "",
      e = textToCaret.length,
      j;
    for (let i = 0; i < splitByBr.length; i++) {
      if (a.length + splitByBr[i].length < textToCaret.length) {
        e = textToCaret.length - (a.length + splitByBr[i].length);
        a = a + splitByBr[i];
        textToCaretContainBr = textToCaretContainBr + splitByBr[i] + "<br>";
      } else {
        textToCaretContainBr =
          textToCaretContainBr + splitByBr[i].substring(0, e);
        textRemain = splitByBr[i].substring(e, splitByBr[i].length) + "<br>";
        j = i + 1;
        break;
      }
    }
    let z = 0;
    for (let i = j; i < splitByBr.length; i++) {
      z++;
      textRemain = textRemain + splitByBr[i];
      if (i !== splitByBr.length - 1) {
        textRemain = textRemain + "<br>";
      }
    }
    if (z === 0) {
      textRemain = textRemain.substr(0, textRemain.length - 4);
    }
    // when caret between two br in firefox
    if (
      navigator.userAgent.indexOf("Firefox") !== -1 &&
      nodeTypeOfRange === 1 &&
      textRemain.length >= 4
    ) {
      textToCaretContainBr += "<br>";
      textRemain = textRemain.slice(4);
    }
    return { textToCaretContainBr, textRemain };
  };

  // get caret position by: element, this.splitHtmlBySpan
  getCaretPosition = element => {
    var result = {
      totalText: 0, // total only text from beginning to caret
      totalHtmlToLastestCloseSpan: 0, // total from beginning to end of lastest close span (</span>) to caret. need when insert mention inside text node
      totalHtmlToLastestSpan: 0, // total from beginning to end of lastest span (</span> or <span>) to caret. need when insert mention inside span node
      textNodeOffset: 0, // caret position of current node
      textRemainLength: 0, // only when caret inside span: = textNodeLength - textNodeOffset
      type: 0, // type of user-defined node, 0: text, 1: span // TODO: rename
      typeExactly: 0, // only difference `type` when caret at: @mention{caret} text
      nodeOfRange: {}, // TODO: nodeOfRange = {nodeTypeOfRange, offsetOfRange}
      nodeTypeOfRange: null, // nodeType of range at caret, use for firefox
      offsetOfRange: null, // offset of range at caret, use for firefox
      detail: null,
      lastType: 0,
      currentNode: null,
      valueToCaret: "" // html value to caret, ignore <br>
      // valueToCaret.length = totalHtmlToLastestSpan + location
    };

    var doc = element.ownerDocument || element.document;
    var win = doc.defaultView || doc.parentWindow;
    var sel;
    var textLengthToCaret = 0;

    if (typeof win.getSelection != "undefined") {
      sel = win.getSelection();
      if (sel.rangeCount > 0) {
        var range = win.getSelection().getRangeAt(0);

        // to check breakline in firefox
        result.nodeOfRange = range.startContainer;
        result.nodeTypeOfRange = range.startContainer.nodeType;
        result.offsetOfRange = range.startOffset;

        var preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        textLengthToCaret = preCaretRange.toString().length;
        result.detail = preCaretRange;
      }
    } else if ((sel = doc.selection) && sel.type != "Control") {
      // TODO: dont understand when run into this
      console.log('(sel = doc.selection) && sel.type != "Control"');
      var textRange = sel.createRange();
      var preCaretTextRange = doc.body.createTextRange();
      preCaretTextRange.moveToElementText(element);
      preCaretTextRange.setEndPoint("EndToEnd", textRange);
      textLengthToCaret = preCaretTextRange.text.length;
      result.detail = preCaretTextRange;
    }

    result.totalText = textLengthToCaret;
    result.totalHtmlToLastestCloseSpan = 0;
    result.totalHtmlToLastestSpan = 0;
    result.textNodeOffset = 0;

    let newSplitHtmlBySpan = this.splitHtmlBySpan; // TODO:
    let textNodeOffset = textLengthToCaret;

    for (var i = 0; i < newSplitHtmlBySpan.length; i++) {
      result.type = newSplitHtmlBySpan[i].type;
      result.typeExactly = newSplitHtmlBySpan[i].type;

      let textValue = this.replaceAll(newSplitHtmlBySpan[i].value, "<br>", ""); // breakline on firefox
      let replaceAllSpecialText = this.replaceAllSpecialText(textValue);
      let lengthOfNode = replaceAllSpecialText.length;
      if (newSplitHtmlBySpan[i].type === 1) {
        lengthOfNode += 1; // mention => @mention
      }

      // for case mention by mark
      if (newSplitHtmlBySpan[i].type === 1) {
        if (textNodeOffset >= lengthOfNode) {
          result.totalHtmlToLastestSpan +=
            this.mentionStart.length +
            lengthOfNode +
            this.mentionEnd1.length -
            1;
          result.valueToCaret +=
            this.mentionStart + replaceAllSpecialText + this.mentionEnd1;
          if (textNodeOffset === lengthOfNode) {
            result.typeExactly = 0;
          }
        } else {
          result.totalHtmlToLastestSpan += this.mentionStart.length - 1;
          result.valueToCaret +=
            this.mentionStart +
            replaceAllSpecialText.substring(1, textNodeOffset);
          result.textRemainLength = lengthOfNode - textNodeOffset;
        }
      } else {
        if (textNodeOffset > lengthOfNode) {
          result.totalHtmlToLastestSpan += lengthOfNode;
          result.valueToCaret += replaceAllSpecialText;
        } else {
          result.totalHtmlToLastestSpan += 0;
          result.valueToCaret += replaceAllSpecialText.substring(
            0,
            textNodeOffset
          );
        }
      }

      if (textNodeOffset > lengthOfNode) {
        textNodeOffset -= lengthOfNode;
      } else {
        if (textValue.length > 1) {
          result.lastType = 0; // TODO: mean what?, similar typeExactly?
        }

        result.textNodeOffset = textNodeOffset;
        return result;
      }

      // TODO: why this at below
      if (newSplitHtmlBySpan[i].type === 1) {
        result.totalHtmlToLastestCloseSpan +=
          lengthOfNode + this.mentionStart.length + this.mentionEnd1.length - 1;
      } else {
        result.totalHtmlToLastestCloseSpan += lengthOfNode;
      }
      result.lastType = newSplitHtmlBySpan[i].type;
    }

    return result;
  };

  setCaretToLocation = (target /*: HTMLDivElement*/, location) => {
    try {
      this.setCurrentCursorPosition(target, location.totalText);
    } catch (e) {}
  };

  // by answer: https://stackoverflow.com/a/41034697/7231033
  setCurrentCursorPosition(target, chars) {
    if (chars >= 0) {
      var selection = window.getSelection();
      var range = this.createRange(target, { count: chars });

      if (range) {
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }

  createRange = (node, chars, range) => {
    if (!range) {
      range = document.createRange();
      range.selectNode(node);
      range.setStart(node, 0);
    }

    if (chars.count === 0) {
      range.setEnd(node, chars.count);
    } else if (node && chars.count > 0) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.length < chars.count) {
          chars.count -= node.textContent.length;
        } else {
          range.setEnd(node, chars.count);
          chars.count = 0;
        }
      } else {
        for (var lp = 0; lp < node.childNodes.length; lp++) {
          range = this.createRange(node.childNodes[lp], chars, range);

          if (chars.count === 0) {
            break;
          }
        }
      }
    }

    return range;
  };

  setCaretToStart = target => {
    if (target.childNodes.length === 0) {
      return;
    }
    const range = document.createRange();
    const sel = window.getSelection();
    range.setStart(target, 0);
    range.setEnd(target.childNodes[0], 0);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
    target.focus();
  };

  setCaretToEnd = (target /*: HTMLDivElement*/) => {
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(target);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
    target.focus();
    range.detach(); // optimization
    // set scroll to the end if multiline
    target.scrollTop = target.scrollHeight;
  };

  // breakline in firefox
  setCaretWhenBreakline = (target, offset) => {
    const range = document.createRange();
    const sel = window.getSelection();
    range.setStart(target, 0);
    range.setEnd(target, offset);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
    target.focus();
  };

  // when caret at here <span>@mention{caret}</span>
  checkAndSetCaretToOutsideSpan = (target, offset) => {
    const sel = window.getSelection();
    const range = sel.getRangeAt(0);
    if (
      range.startContainer.parentElement.tagName === "SPAN" &&
      range.startContainer.nodeValue.length === range.startOffset
    ) {
      let i = Array.from(target.childNodes).indexOf(
        range.startContainer.parentElement
      );
      // sometimes error on chrome
      if (target.childNodes.length - 1 < i + 1) {
        return;
      }
      const newRange = document.createRange();
      newRange.setStart(target, 0);
      newRange.setEnd(target.childNodes[i + 1], 0);
      newRange.collapse(false);
      sel.removeAllRanges();
      sel.addRange(newRange);
      target.focus();
    }
  };

  replaceAllSpecialText = text => {
    var newText = this.replaceAll(text, "&nbsp;", " ");
    for (var i = 0; i < this.specialCharacters.length; i++) {
      newText = this.replaceAll(
        newText,
        this.specialCharacters[i].value2,
        this.specialCharacters[i].value1
      );
    }
    return newText;
  };

  setOldHtmlValue = text => {
    this.oldHtmlValue = text;
  };

  getOldHtmlValue = () => {
    return this.oldHtmlValue;
  };

  // convert text box mention to another type
  // sample stringValue= <span class="chat-box-input-tag-user">@現場参加のメンバー全員</span>&nbsp;SDASDJ <span class="chat-box-input-tag-user">@現場参加のメンバー全員</span>&nbsp;s
  // data= convertHtmlToAnotherType(stringValue,'@',"<btm>","</btm>") =
  // data =<btm>@現場参加のメンバー全員</btm>SDASDJ <btm>@現場参加のメンバー全員<btm/> s
  convertHtmlToAnotherType(stringValue, mention, startMention, endMention) {
    var arrayData = this.infoSeperate(stringValue);
    var stringData = "";
    for (var i = 0; i < arrayData.length; i++) {
      if (arrayData[i].type == 0) {
        stringData += arrayData[i].value;
      } else if (arrayData[i].type == 1) {
        stringData += startMention + mention + arrayData[i].value + endMention;
      }
    }
    return stringData;
  }

  replaceAll = (value, searchValue, replaceValue) => {
    return value.replace(new RegExp(searchValue, "ig"), replaceValue);
  };
  // checkFormDataMeintionChange = (target, allMentions) => {
  //   var textMessageData = target.innerHTML
  //   var posistion = this.getCaretPosition(target);
  //   var checMeintion = this.checkMentionViewLocation(textMessageData, allMentions, posistion);
  //   return checMeintion;
  // }

  // checkDataByEventUnexpectedBefore = (target) => {
  //   var posistion = this.getCaretPosition(target);
  //   var textMessageData = this.generateNewHtmlValue(target.innerHTML)
  //   target.innerHTML = textMessageData;
  //   this.setCaretToLocation(target, posistion);
  // }

  // eventPasteText = (e) => {
  //   e.preventDefault();
  //   // Get the copied text from the clipboard
  //   const text = ((e.clipboardData)
  //     ? (e.originalEvent || e).clipboardData.getData('text/plain')
  //     // For IE
  //     : (window.clipboardData ? window.clipboardData.getData('Text') : ''))
  //     .replace(/<div>/ig, '').replace(/<br>/ig, '').replace(/<\/div>/ig, '')
  //     .replace(/(\r\n|\n|\r)/gm, "");;
  //   if (document.queryCommandSupported('insertText')) {
  //     document.execCommand('insertText', false, text);
  //   } else {
  //     // Insert text at the current position of caret
  //     const range = document.getSelection().getRangeAt(0);
  //     range.deleteContents();

  //     const textNode = document.createTextNode(text);
  //     range.insertNode(textNode);
  //     range.selectNodeContents(textNode);
  //     range.collapse(false);
  //     const selection = window.getSelection();
  //     selection.removeAllRanges();
  //     selection.addRange(range);
  //   }
  // }

  // eventCutText = (event) => {
  //   const selection = document.getSelection();
  //   event.clipboardData.setData('text/plain', selection.toString().toUpperCase());
  //   selection.deleteFromDocument();
  //   event.preventDefault();
  // }
}
