import React, { Component } from "react";
import Fab from "@material-ui/core/Fab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Menu,
  MenuItem
} from "@material-ui/core";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  BUTTON_LOGKUN,
  BUTTON_LOGKUN_SP,
  ICON_LOGKUN
} from "common/constants/constants";
import DialogRobots from "common/DialogRobots";
import { createUserAcknowledgement } from "../Stores/service/service";
import { format } from "date-fns";
import { ja } from "date-fns/locale";

class RobotStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogRobotsShow: false,
      robots: null,
      lastUpdated: new Date()
    };
  }

  componentWillMount() {
    this.setResponse(this.props.robots, this.props.robotsUpdate);
  }

  componentWillReceiveProps(props) {
    this.setResponse(props.robots, props.robotsUpdate);
  }

  // Set response to state
  setResponse = (robots, robotsUpdate) => {
    let robotsInfo = robots;

    if (robotsInfo && robotsUpdate) {
      for (let i = 0; i < robotsInfo.length; i++) {
        let idx = robotsUpdate[0].findIndex(
          x => x.robotsUC === robotsInfo[i].uniqueCode
        );

        if (idx != -1) {
          robotsInfo[i].useStatus = robotsUpdate[0][idx].useStatus;
          robotsInfo[i].lastUpdated = new Date();
        }
      }
    }
    this.setState({ robots: robotsInfo });
  };

  // Check enable button LogKun
  shouldEnableRobotButton = robots => {
    for (let i = 0; i < robots.length; i++) {
      if (robots[i].useStatus === 1) return true;
    }
    return false;
  };

  showDialogRobots = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isDialogRobotsShow: true
    });
  };

  closeDialogRobots = () => {
    this.setState({
      isDialogRobotsShow: false
    });
  };

  onCreateUserAcknowledgement = (id, status) => {
    const value = {
      uniqueCode: id
    };
    if (status) {
      this.props.createUserAcknowledgement(value);
    }
  };

  // Render component with status type
  renderComponent = typeOfRobotStatus => {
    const { robots } = this.state;
    const {
      staticPosition,
      isFullWidth,
      noText,
      isSmall,
      forceCreateUserAcknowledgement
    } = this.props;

    switch (typeOfRobotStatus) {
      // For button 「Log Kun接続」
      case BUTTON_LOGKUN:
        return (
          <Fab
            className={`white-text ${robots?.length > 0 && this.shouldEnableRobotButton(robots)
                ? isFullWidth
                  ? "button-log-robot full-width"
                  : "button-log"
                : isFullWidth
                  ? "button-log-disalbe full-width"
                  : "button-log-disalbe"
              } ${this.props.class}`}
            variant="extended"
            onClick={e => {
              if (robots?.length > 0 && this.shouldEnableRobotButton(robots)) {
                if (forceCreateUserAcknowledgement) {
                  this.onCreateUserAcknowledgement(
                    robots[0].uniqueCode,
                    robots[0].useStatus
                  );
                } else {
                  this.showDialogRobots(e);
                }
              }
            }}
          >
            <VisibilityIcon
              className={`${isFullWidth ? "" : "icon"} icon-margin`}
            />
            Log Kun接続
          </Fab>
        );
      // For button 「Log Kun接続」on SP
      case BUTTON_LOGKUN_SP:
        return (
          <Fab
            className={`white-text ${robots?.length > 0 && this.shouldEnableRobotButton(robots)
                ? "button-log-sp"
                : "button-log-sp-disable"
              } ${staticPosition && "fab-button-sp"} ${this.props.class}`}
            size={staticPosition ? "" : "small"}
            onClick={e => {
              if (robots?.length > 0 && this.shouldEnableRobotButton(robots)) {
                if (forceCreateUserAcknowledgement) {
                  this.onCreateUserAcknowledgement(
                    robots[0].uniqueCode,
                    robots[0].useStatus
                  );
                } else {
                  this.showDialogRobots(e);
                }
              }
            }}
          >
            <VisibilityIcon style={{ textTransform: "none", width: "26px", height: "26px" }} />
          </Fab>
        );
      // For icon status
      case ICON_LOGKUN:
        return robots ? this.renderIconLogKun(robots, noText, isSmall) : <></>;
      default:
        return null;
    }
  };

  renderIconLogKun = (robots, noText = false, isSmall = false) => {
    switch (robots[0].useStatus) {
      case 1:
        return (
          <Grid
            container
            alignItems="center"
            className={noText ? "display-inline" : ""}
          >
            <Tooltip title="LogKun接続可能">
              <Grid className="status-block">
                <Grid
                  className={`${isSmall ? "status-small" : "status"
                    } status-yellow-green`}
                />
                {!noText && "スタンバイ"}
              </Grid>
            </Tooltip>
          </Grid>
        );
      case 2:
        return (
          <Grid
            container
            alignItems="center"
            className={noText ? "display-inline" : ""}
          >
            <Tooltip title="LogKun使用中">
              <Grid className="status-block">
                <Grid
                  className={`${isSmall ? "status-small" : "status"
                    } status-grey`}
                />
                {!noText && "操作中"}
              </Grid>
            </Tooltip>
          </Grid>
        );
      case 9:
        return (
          <Grid
            container
            alignItems="center"
            className={noText ? "display-inline" : ""}
          >
            <Tooltip title="LogKunの電源OFF">
              <Grid className="status-block">
                <Grid
                  className={`${isSmall ? "status-small" : "status"
                    } status-black`}
                />
                {!noText && "電源OFF"}
              </Grid>
            </Tooltip>
          </Grid>
        );
      case 10:
        return (
          <Grid
            container
            alignItems="center"
            className={noText ? "display-inline" : ""}
          >
            <Tooltip title="LogKunのネットワーク切断 / あるいは予期せぬエラーが発生">
              <Grid className="status-block">
                <Grid
                  className={`${isSmall ? "status-small" : "status"
                    } status-red`}
                />
                {!noText && (
                  <>
                    取得失敗
                    <span className="disconnect-time ml-10">
                      最終取得：
                      {format(
                        robots[0].lastUpdated ?? this.state.lastUpdated,
                        "yyyy/MM/dd/E/HH:mm",
                        { locale: ja }
                      )}
                    </span>
                  </>
                )}
              </Grid>
            </Tooltip>
          </Grid>
        );
      default:
        return null;
    }
  };

  render() {
    const { robots, isDialogRobotsShow } = this.state;
    const { typeOfRobotStatus, robotsUpdate } = this.props;
    return (
      <>
        {this.renderComponent(typeOfRobotStatus)}
        {isDialogRobotsShow && (
          <DialogRobots
            robots={robots}
            robotsUpdate={robotsUpdate}
            onClose={this.closeDialogRobots}
          ></DialogRobots>
        )}
      </>
    );
  }
}

RobotStatus.propTypes = {
  typeOfRobotStatus: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  // type: getType(
  //   state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailInfoReducer.type
  // )
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { createUserAcknowledgement: createUserAcknowledgement },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RobotStatus);
