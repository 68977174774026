import React from "react";
import Fab from "@material-ui/core/Fab";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

export default function (props) {
  return (
    <Fab
      className="button-add-sp"
      style={{ left: 15 }}
      onClick={props.onClick}
    >
      <KeyboardReturnIcon className="white-text icon" style={{ width: "26px", height: "26px" }} />
    </Fab>
  );
}
