import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";

import { CometChatMessageActions, CometChatThreadedMessageReplyCount, CometChatReadReceipt } from "../";
import { CometChatMessageReactions } from "../Extensions";

import { CometChatContext } from "../../../util/CometChatContext";
import { checkMessageForExtensionsData } from "../../../util/common";

import Translator from "../../../resources/localization/translator";
import { theme } from "../../../resources/theme";

import { api } from "../../../../../../common/api/api";
import axios from "axios";
import {
	messageContainerStyle,
	messageWrapperStyle,
	messageFileWrapper,
	messageInfoWrapperStyle,
	messageReactionsWrapperStyle,
	iconStyle,
	messageTxtWrapperStyle
} from "./style";


import fileIcon from "./resources/file-upload.svg";

class CometChatSenderFileMessageBubble extends React.Component {
	static contextType = CometChatContext;
	loggedInUser;

	constructor(props, context) {
		super(props, context);

		this.state = {
			isHovering: false,
		};

		this.context.getLoggedinUser().then(user => {
			this.loggedInUser = { ...user };
		});
	}

	shouldComponentUpdate(nextProps, nextState) {

		const currentMessageStr = JSON.stringify(this.props.message);
		const nextMessageStr = JSON.stringify(nextProps.message);

		if (currentMessageStr !== nextMessageStr
			|| this.state.isHovering !== nextState.isHovering) {
			return true;
		}
		return false;
	}

	handleMouseHover = (isHovering) => {
		this.setState({ isHovering });
	};

	async fetch(id) {
		if (this.source) {
			this.source.cancel();
		}
		const CancelToken = axios.CancelToken;
		this.source = CancelToken.source();
		const classification = window.location.pathname.includes("/sp/")
			? "supplier"
			: "builder";
		var info = await api.get(`/${classification}/site-files/${id}/info`,
			{
				cancelToken: this.source.token
			})
			.then(res => {
				if (res.data === undefined) return Promise.reject();
				return res.data;
			})
			.catch(e => {
				this.setState({ FetchDone: false, isDeleted: true });
				return {
					Status: false,
					Code: e?.response?.status
				};
			});
		var isBlob = info.blob ? true : false;
		var path = isBlob ? info.blob.org.replace(/^\/api/, '') : `/${classification}/site-files/${id}/download`;
		const res = await api.get(path,
			{
				responseType: isBlob ? undefined : "arraybuffer",
				cancelToken: this.source.token
			})
			.then(res => {
				if (res.data === undefined) return Promise.reject();
				if (isBlob) {
					var url = res.data.blobData;
				} else {
					var blob = new Blob([res.data], { type: "application/pdf" });
					var url = URL.createObjectURL(blob);
				}
				const pdfWindow = window.open();
				pdfWindow.location.href = url;
				return {
					Status: true,
					Code: 200,
					Url: url
				};
			})
			.catch(e => {
				this.setState({ FetchDone: false, isDeleted: true });
				return {
					Status: false,
					Code: e?.response?.status
				};
			});

		return await res;
	}

	open = () => {
		if (this.props.message.data.uniqueCode) {
			this.fetch(this.props.message.data.uniqueCode);
		} else {
			const fileUrl = this.props.message.data.attachments[0].url;
			const pdfWindow = window.open();
			pdfWindow.location.href = fileUrl;
		}
	};

	render() {
		const { isDeleted, fetchDone } = this.state;
		let messageReactions = null;
		const reactionsData = checkMessageForExtensionsData(this.props.message, "reactions");
		if (reactionsData) {
			if (Object.keys(reactionsData).length) {
				messageReactions = (
					<div css={messageReactionsWrapperStyle()} className="message__reaction__wrapper">
						<CometChatMessageReactions message={this.props.message} actionGenerated={this.props.actionGenerated} />
					</div>
				);
			}
		}

		let toolTipView = null;
		if (this.state.isHovering) {
			toolTipView = <CometChatMessageActions message={this.props.message} actionGenerated={this.props.actionGenerated} />;
		}

		let fileMessage = null;
		let fileMessageWrapper = null;
		if (this.props.message.data.hasOwnProperty("attachments") && this.props.message.data.attachments.length) {
			const fileName = this.props.message.data.attachments[0].name;
			const fileUrl = this.props.message.data.attachments[0].url;

			fileMessage = (
				// href={fileUrl}
				<div onClick={this.open} target="_blank" rel="noopener noreferrer" className="message__file"
					style={{ color: "rgba(0, 0, 0, 0.87)" }}>
					<i css={iconStyle(fileIcon, this.context)} style={{ backgroundColor: "#808080" }}></i>
					<p>{fileName}</p>
				</div>
			);
		} else {
			const fileName = this.props.message.data.name || "";

			fileMessage = (
				<div className="message__file">
					<i css={iconStyle(fileIcon, this.context)}></i>
					<p>{fileName}</p>
				</div>
			);
		}

		if (this.props.message.data.isDeleted) {
			fileMessageWrapper = (<div css={messageTxtWrapperStyle(this.context)} className="message__txt__wrapper"
				style={{ backgroundColor: "#FFFFFF", color: "rgba(0, 0, 0, 0.87)", boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)" }}>
				<p className="message__txt">
					⚠️ ファイルが削除されました。
				</p>
			</div>)
		}
		else {
			fileMessageWrapper = (<div css={messageFileWrapper(this.context)} className="message__file__wrapper"
				style={{
					backgroundColor: "#FFFFFF",
					boxShadow: "4px 4px 10px -3px rgba(0,0,0,0.75)",
					color: "rgba(0, 0, 0, 0.87)"
				}}>
				{fileMessage}
			</div>)
		}

		return (
			<div css={messageContainerStyle()} className="sender__message__container message__file" onMouseEnter={() => this.handleMouseHover(true)} onMouseLeave={() => this.handleMouseHover(false)}
				style={{ marginBottom: "10px" }}>
				{toolTipView}

				<div style={{ display: "flex", alignItems: "flex-end", alignSelf: "flex-end" }}>
					<div css={messageInfoWrapperStyle()} className="message__info__wrapper">
						<CometChatThreadedMessageReplyCount message={this.props.message} actionGenerated={this.props.actionGenerated} />
						<CometChatReadReceipt message={this.props.message} />
					</div>

					<div css={messageWrapperStyle()} className="message__wrapper">
						{fileMessageWrapper}
					</div>
				</div>

				{this.props.message?.sender?.uid === this.loggedInUser?.uid &&
					this.props.message.hasOwnProperty("error") &&
					<div style={{ alignSelf: "flex-end", paddingRight: "2px", fontSize: "10px", marginTop: "1px" }}>送信失敗</div>}

				{messageReactions}
			</div>
		);
	}
}

// Specifies the default values for props:
CometChatSenderFileMessageBubble.defaultProps = {
	lang: Translator.getDefaultLanguage(),
	theme: theme,
	actionGenerated: {},
};

CometChatSenderFileMessageBubble.propTypes = {
	lang: PropTypes.string,
	theme: PropTypes.object,
	actionGenerated: PropTypes.func.isRequired,
	message: PropTypes.object.isRequired,
};

export { CometChatSenderFileMessageBubble };