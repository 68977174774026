import {
  fetchStaffsSuccess,
  postStaffsSuccess,
  putStaffsSuccess,
  deleteStaffsSuccess,
  fetchJobsSuccess,
  getStaffsDetailSuccess,
  getUserDetailSuccess,
  mountPasswordDialogSuccess,
  putPasswordSuccess,
  authError
} from "../action/action";
import { api } from "../../../../common/api/api";
import {
  dispatchError,
  enableLoading,
  finishLoading
} from "../../../../common/Stores/service/service";

export const fetchStaff = (handleFetchSuccess) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/builder-staffs`)
      .then(res => {
        if(handleFetchSuccess) handleFetchSuccess();
        dispatch(fetchStaffsSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(error => {
        dispatch(dispatchError(error));
      });
  };
};

export const addStaff = (staff, handleError)  => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/builder-staffs`, staff)
      .then(res => {
        dispatch(postStaffsSuccess(res.data));
      })
      .catch(error => {
        if (error.response.status === 403) {
          dispatch(authError());
        } else {
          if(handleError && error.response.status === 500) {
            handleError();
          } else {
            dispatch(dispatchError(error));
          }
        }
      });
  };
};

export const updateStaff = (staff, id, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/builder-staffs/${id}`, staff)
      .then(res => {
        dispatch(putStaffsSuccess(res.data));
      })
      .catch(error => {
        if (error.response.status === 403) {
          dispatch(authError());
        } else {
          if(handleError && error.response.status === 500) {
            handleError();
          } else {
            dispatch(dispatchError(error));
          }
        }
      });
  };
};

export const deleteStaff = (id, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/builder-staffs/${id}`)
      .then(res => {
        dispatch(deleteStaffsSuccess(res.data));
      })
      .catch(error => {
        if (error.response.status === 403) {
          dispatch(authError());
        } else {
          if(handleError && error.response.status === 500) {
            handleError();
          } else {
            dispatch(dispatchError(error));
          }
        }
      });
  };
};

export const fetchJob = () => {
  return dispatch =>
    api
      .get(`/builder-staff-jobs/entries`)
      .then(res => {
        dispatch(fetchJobsSuccess(res.data));
      })
      .catch(error => {
        dispatch(dispatchError(error));
      });
};

export const fetchStaffDetail = id => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/builder-staffs/${id}`)
      .then(res =>
        dispatch(getStaffsDetailSuccess(res.data))
      )
      .then(() => {
        dispatch(finishLoading());
      })
      .catch(error => {
        dispatch(dispatchError(error));
      });
  };
};

export const fetchUserDetail = id => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/builder-staffs/${id}`)
      .then(res =>
        dispatch(getUserDetailSuccess(res.data))
      )
      .then(() => {
        dispatch(finishLoading());
      })
      .catch(error => {
        dispatch(dispatchError(error));
      });
  };
};

export const putPassword = (id, newPassWord) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/builder-staff-password/${id}`, { password: newPassWord })
      .then(res =>
        dispatch(putPasswordSuccess(res.data))
      )
      .then(() => {
        dispatch(finishLoading());
      })
      .catch(error => {
        if (error.response.status === 403) {
          dispatch(authError());
        }
        dispatch(dispatchError(error));
      });
  };
};

export const mountPasswordDialog = () => {
  return dispatch => {
    dispatch(mountPasswordDialogSuccess());
  };
};
