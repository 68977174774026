const getLogMeetURL = (hostname) =>{
  if (hostname.includes('bazaar.log-system.jp')) {
    return "https://witty-cliff-06e4f3700.1.azurestaticapps.net";
  } else if (hostname.includes('bazaar-staging.log-system.jp')) {
    return "https://witty-cliff-06e4f3700-prerelease.eastasia.1.azurestaticapps.net";
  } else if (hostname.includes('bazaar-dev.log-system.jp')) {
    return "https://nice-wave-0482a0400.1.azurestaticapps.net";
  } else if (hostname.includes('bazaar-ienterdev.log-system.jp')) {
    return "https://nice-wave-0482a0400-ienterdev.eastasia.1.azurestaticapps.net";
  } else {
    return "http://localhost:3000";
  };
}

module.exports = {
  getLogMeetURL
};
