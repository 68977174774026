import {
  FETCH_STAFFS_SUCCESS,
  POST_STAFFS_SUCCESS,
  PUT_STAFFS_SUCCESS,
  DELETE_STAFFS_SUCCESS,
  FETCH_JOBS_SUCCESS,
  GET_STAFFS_DETAIL_SUCCESS,
  GET_USER_DETAIL_SUCCESS,
  MOUNT_PASSWORD_DIALOG_SUCCESS,
  PUT_PASSWORD_SUCCESS,
  AUTH_ERROR
} from "../constant/constant";
const initialState = {
  staff: [],
  staffDetail: [],
  userDetail: [],
};
export const staffsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STAFFS_SUCCESS:
      return {
        ...state,
        type: action.type,
        staff: action.staffs
      };

    case POST_STAFFS_SUCCESS:
      return {
        ...state,
        type: action.type
      };

    case PUT_STAFFS_SUCCESS:
      return {
        ...state,
        type: action.type,
        staff: state.staff.map(item => {
          let itemNew = item;
          if (item.uniqueCode === state.staff.uniqueCode) {
            itemNew = state.staff;
          }
          return itemNew;
        })
      };

    case AUTH_ERROR:
      return {
        ...state,
        type: action.type
      };

    case DELETE_STAFFS_SUCCESS:
      return {
        ...state,
        type: action.type
      };

    case FETCH_JOBS_SUCCESS:
      return {
        ...state,
        job: action.jobs
      };

    case GET_STAFFS_DETAIL_SUCCESS:
      return {
        ...state,
        staffDetail: action.staffDetail
      };

    case GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.userDetail
      };

    case MOUNT_PASSWORD_DIALOG_SUCCESS:
      return {
        ...state,
        type: action.type
      };

    case PUT_PASSWORD_SUCCESS:
      return {
        ...state,
        type: action.type
      };

    default:
      return state;
  }
};

export const getStaffs = state => state;
export const getJobs = state => state;
export const getStaffsDetail = state => state;
export const getUserDetail = state=> state;
