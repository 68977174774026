import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import uploadFromDeviceIcon from "../../../../common/img/upload_from_device.png";
import chooseExistedIcon from "../../../../common/img/choose_existed.png";
import { isMobile, isTablet } from "react-device-detect";
import { format } from "date-fns";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  sendFindCometBackDrop: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    zIndex: "500"
  },
  menuUploadFileBackDrop: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: "500"
  },
  menuUploadFile: {
    width: "360px",
    borderRadius: "12px",
    boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    backgroundColor: "white",
    overflow: "hidden"
  },
  menuUploadFileItem: {
    width: "100%",
    height: "80px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "0px 12px",
    cursor: "pointer",
    "&:hover": {
      // backgroundColor: "#D0D0D0"
    }
  },
  closeMenuUploadFileItem: {
    width: "260px",
    height: "48px",
    border: "1px solid black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 12px",
    cursor: "pointer",
    marginTop: "20px",
    backgroundColor: "white",
    borderRadius: "6px",
    fontSize: "16px",
    fontWeight: "500"
  }
});

function MenuScreenshotVRSpace(props) {
  const {
    closeMenu,
    screenshotVRBase64,
    takeScreenshotVRAndDownoad,
    takeScreenshotVRAndShare,
    takeScreenshotVRAndUploadToLogBuild
  } = props;

  const [canShare, setCanShare] = useState(false);
  const [filesToShare, setFilesToShare] = useState([]);

  const logWalkInfo = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.logWalkInfo
  );

  useEffect(() => {
    async function checkCanShare() {
      const blob = await (await fetch(screenshotVRBase64)).blob();

      const siteName = logWalkInfo.siteName;
      const spotName = logWalkInfo.spotName;
      const file = new File(
        [blob],
        `${format(new Date(), "yyyyMMdd")}_${siteName}_${spotName}.png`,
        { type: blob.type }
      );
      const files = [file];
      setFilesToShare(files);
      setCanShare(
        (isMobile || isTablet) &&
        navigator.canShare &&
        navigator.canShare({ files })
      );
    }

    checkCanShare();

    return () => {};
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction);
    return () => window.removeEventListener("keydown", escFunction);
  }, []);

  const escFunction = e => {
    if (e.keyCode === 27) {
      closeMenu();
    }
  };
  const classes = useStyles();

  return (
    <div className={classes.menuUploadFileBackDrop}>
      <div
        className={classes.menuUploadFile}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div
          className={classes.menuUploadFileItem}
          onClick={() => {
            if (canShare && filesToShare.length !== 0) {
              takeScreenshotVRAndShare(filesToShare);
            } else {
              takeScreenshotVRAndDownoad();
            }
          }}
        >
          <img src={uploadFromDeviceIcon} width="50" height="50" alt="" />
          <span style={{ marginLeft: "20px", fontSize: "16px" }}>
            {canShare ? "端末にそのまま共有" : "端末にそのまま保存"}
          </span>
        </div>
        <hr
          style={{
            height: "0px",
            margin: "0px",
            backgroundColor: "#A8A8A8"
          }}
        />
        <div
          className={classes.menuUploadFileItem}
          onClick={() => takeScreenshotVRAndUploadToLogBuild(filesToShare)}
        >
          <img src={chooseExistedIcon} width="50" height="50" alt="" />
          <span style={{ marginLeft: "20px", fontSize: "16px" }}>
            Log Systemに保存
          </span>
        </div>
      </div>
      
      <button
        className={classes.closeMenuUploadFileItem}
        onClick={() => closeMenu()}
        style={{backgroundColor: "white"}}
      >
        キャンセル
      </button>
    </div>
  );
}

export default MenuScreenshotVRSpace;
