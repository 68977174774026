import React, { useEffect, useState } from "react";

import { Drawer, Grid, Paper } from "@material-ui/core";
import FilterInfoBox from "./FilterInfoBox";
import DrawingBox from "./DrawingBox/DrawingBox";
import SelectDrawingAndPoint from "./SelectDrawingAndPoint";
import CloseButton from "./CloseButton";
import { api } from "../../../../common/api/api";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  setRedirectFromUrl,
  saveConditionSiteInfoSuccess
} from "../../../sites/site/action/action";
import InfoTagListBox from "./InfoTagListBox";
const allStatus = [
  { uniqueCode: "all", label: "すべて" },
  { uniqueCode: "incomplete", label: "未完了" },
  { uniqueCode: "complete", label: "完了" }
];

function ViewTask (props) {
  const {
    sitesUC,
    isSP,
    openFilterInfoBox,
    setOpenFilterInfoBox,
    openDrawingBox,
    setOpenDrawingBox,
    drawingsWithSpots
  } = props;

  const [openSelectDrawingAndPoint, setOpenSelectDrawingAndPoint] = useState(
    false
  );

  const [filterTitle, setFilterTitle] = useState("");
  const [filterIcon, setFilterIcon] = useState(null);
  const [allIcons, setAllIcons] = useState([]);
  const [filterStatus, setFilterStatus] = useState(null);
  const [filterMember, setFilterMember] = useState(null);
  const [allMembers, setAllMembers] = useState([]);
  const [filterIsCreator, setFilterIsCreator] = useState(false);

  const [selectedSpots, setSelectedSpots] = useState([]);
  const [allHotspots, setAllHotspots] = useState([]);
  const [hotspotToShow, setHotspotToShow] = useState(null);
  const [loadInit, setLoadInit] = useState(true);

  const location = useLocation();
  const dispatch = useDispatch();
  const conditionSelector = useSelector(
    state => state.rootSitesReducer.siteInfoReducer.conditionSiteInfo
  );

  const redirectFromUrl = useSelector(
    state => state.rootSitesReducer.siteInfoReducer.redirectFromUrl
  );

  useEffect(() => {
    setLoadInit(true)
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    const fetchAllColors = api.get(`/${classification}/info-icons`);

    const fetchAllMembers = api.get(
      `/${classification}/logwalk-viewer/sites/${sitesUC}/mentions`
    );

    const fetchAllHotspots = api.get(`/sites/${sitesUC}/hotspots`);

    Promise.all([fetchAllColors, fetchAllMembers, fetchAllHotspots]).then(
      res => {
        let tempAllIcons = res[0].data;
        tempAllIcons.unshift({ uniqueCode: "all" });
        setAllIcons(tempAllIcons);
        setAllMembers(res[1].data);
        setAllHotspots(
          res[2].data.filter(item => item.site.uniqueCode === sitesUC)
        );
        if (
          redirectFromUrl &&
          redirectFromUrl.isRedirectFromUrl &&
          redirectFromUrl.from.includes(`/sites/${sitesUC}`) &&
          conditionSelector
        ) {
          setFilterTitle(conditionSelector.title || "");
          setFilterIcon(conditionSelector.icon || { uniqueCode: "all" });
          setFilterStatus(
            conditionSelector.status || {
              uniqueCode: "incomplete",
              label: "未完了"
            }
          );
          setFilterMember(conditionSelector.member || "");
          setFilterIsCreator(conditionSelector.isCreator || false);
          setSelectedSpots(conditionSelector.selectedSpots || [])
          
          dispatch(
            setRedirectFromUrl({
              isRedirectFromUrl: false,
              isDialog: false,
              isCardList: true,
              currentDrawingUC: null,
              from: ""
            })
          );
        } else {
          setFilterIcon({ uniqueCode: "all" });
          setFilterMember(
            res[1].data.find(member => member.uniqueCode === "all")
          );
          setFilterStatus({ uniqueCode: "incomplete", label: "未完了" });
        }
        setLoadInit(false)
      }
    );

    dispatch(
      setRedirectFromUrl({
        isRedirectFromUrl: true,
        from: `${
          location.pathname.includes("/sp/") ? "/sp" : ""
        }/sites/${sitesUC}?tab=4`
      })
    );
  }, []);

  useEffect(() => {
    if(!loadInit) {
      setHotspotToShow(filterHotspot);
    }
  }, [
    filterTitle,
    allHotspots,
    filterIcon,
    filterStatus,
    filterMember,
    filterIsCreator,
    selectedSpots,
    loadInit
  ]);

  const filterHotspot = () => {
    let tempShowHotspot = allHotspots;

    if (filterTitle !== "") {
      tempShowHotspot = tempShowHotspot.filter(item =>
        item.info.title.includes(filterTitle)
      );
    }

    if (filterIcon && filterIcon.uniqueCode !== "all") {
      tempShowHotspot = tempShowHotspot.filter(
        item => item.icon.uniqueCode === filterIcon.uniqueCode
      );
    }

    if (filterStatus && filterStatus.uniqueCode !== "all") {
      if (filterStatus.uniqueCode === "incomplete") {
        tempShowHotspot = tempShowHotspot.filter(
          item => !item.info.completedAt
        );
      } else if (filterStatus.uniqueCode === "complete") {
        tempShowHotspot = tempShowHotspot.filter(item => item.info.completedAt);
      }
    }

    if (filterMember && filterMember.uniqueCode !== "all") {
      if (filterIsCreator) {
        tempShowHotspot = tempShowHotspot.filter(
          item => item.info.createdByUniqueCode === filterMember.uniqueCode
        );
      } else {
        tempShowHotspot = tempShowHotspot.filter(item => {
          if (
            item.mentions.find(
              mention =>
                mention.uniqueCode === "all" ||
                mention.uniqueCode === filterMember.uniqueCode
            )
          )
            return true;

          return false;
        });
      }
    }

    if (selectedSpots.length > 0) {
      tempShowHotspot = tempShowHotspot.filter(item =>
        selectedSpots.find(
          selectedSpot => selectedSpot.uniqueCode === item.spot.uniqueCode
        )
      );
    }

    return tempShowHotspot;
  };

  const selectSpot = spot => {
    if (
      selectedSpots.find(
        selectedSpot => selectedSpot.uniqueCode === spot.uniqueCode
      )
    ) {
      setSelectedSpots(
        selectedSpots.filter(
          selectedSpot => selectedSpot.uniqueCode !== spot.uniqueCode
        )
      );
    } else {
      setSelectedSpots(selectedSpots.concat([spot]));
    }
  };

  const selectDrawing = drawing => {
    if (
      drawing.spots.every(spot =>
        selectedSpots.find(
          selectedSpot => selectedSpot.uniqueCode === spot.uniqueCode
        )
      )
    ) {
      setSelectedSpots(
        selectedSpots.filter(
          selectedSpot =>
            !drawing.spots.find(
              spot => spot.uniqueCode === selectedSpot.uniqueCode
            )
        )
      );
    } else {
      let newSelectedSpots = drawing.spots.filter(
        spot =>
          !selectedSpots.find(
            selectedSpot => selectedSpot.uniqueCode === spot.uniqueCode
          )
      );
      setSelectedSpots(selectedSpots.concat(newSelectedSpots));
    }
  };

  const reloadAllHotspots = () => {
    api
      .get(`/sites/${sitesUC}/hotspots`)
      .then(res =>
        setAllHotspots(
          res.data.filter(item => item.site.uniqueCode === sitesUC)
        )
      );
  };

  const selectAllSpot = () => {
    if (
      drawingsWithSpots.every(drawing =>
        drawing.spots.every(spot =>
          selectedSpots.find(
            selectedSpot => selectedSpot.uniqueCode === spot.uniqueCode
          )
        )
      )
    ) {
      setSelectedSpots([]);
    } else {
      let allSpot = [];
      drawingsWithSpots.forEach(drawing => {
        allSpot = allSpot.concat(drawing.spots);
      });
      setSelectedSpots(allSpot);
    }
  };

  const resetFilter = () => {
    setFilterTitle("");
    setFilterIcon({ uniqueCode: "all" });
    setFilterMember(allMembers.find(member => member.uniqueCode === "all"));
    setFilterStatus({ uniqueCode: "incomplete", label: "未完了" });
    setFilterIsCreator(false);
    setSelectedSpots([]);
  };

  const saveCondition = () => {
    dispatch(
      saveConditionSiteInfoSuccess({
        title: filterTitle,
        icon: filterIcon,
        status: filterStatus,
        member: filterMember,
        isCreator: filterIsCreator,
        selectedSpots: selectedSpots
      })
    );
  };

  return (
    <React.Fragment>
      {isSP ? (
        <React.Fragment>
          <Drawer
            anchor='bottom'
            open={openFilterInfoBox}
            onClose={() => setOpenFilterInfoBox(false)}
          >
            <React.Fragment>
              <CloseButton handleClose={() => setOpenFilterInfoBox(false)} />
              <div style={{ padding: "30px 38px", maxHeight: "75vh" }}>
                <FilterInfoBox
                  drawingsWithSpots={drawingsWithSpots}
                  setOpenSelectDrawingAndPoint={setOpenSelectDrawingAndPoint}
                  allIcons={allIcons}
                  allMembers={allMembers}
                  allStatus={allStatus}
                  filterTitle={filterTitle}
                  setFilterTitle={setFilterTitle}
                  filterIcon={filterIcon}
                  setFilterIcon={setFilterIcon}
                  filterStatus={filterStatus}
                  setFilterStatus={setFilterStatus}
                  filterMember={filterMember}
                  setFilterMember={setFilterMember}
                  filterIsCreator={filterIsCreator}
                  setFilterIsCreator={setFilterIsCreator}
                  selectedSpots={selectedSpots}
                  resetFilter={resetFilter}
                />
              </div>
            </React.Fragment>
          </Drawer>

          <Drawer
            anchor='right'
            open={openDrawingBox}
            onClose={() => setOpenDrawingBox(false)}
            PaperProps={{
              style: {
                width: "100vw",
                height: "100vh"
              }
            }}
          >
            <React.Fragment>
              <CloseButton handleClose={() => setOpenDrawingBox(false)} />
              <DrawingBox
                drawingsWithSpots={drawingsWithSpots}
                selectedSpots={selectedSpots}
                selectSpot={selectSpot}
                handleClose={() => setOpenDrawingBox(false)}
              />
            </React.Fragment>
          </Drawer>
        </React.Fragment>
      ) : (
        <Grid container spacing={3}>
          <Grid item md={7} sm={12}>
            <Paper style={{ padding: "5px 38px 20px" }}>
              <FilterInfoBox
                drawingsWithSpots={drawingsWithSpots}
                setOpenSelectDrawingAndPoint={setOpenSelectDrawingAndPoint}
                allIcons={allIcons}
                allMembers={allMembers}
                allStatus={allStatus}
                filterTitle={filterTitle}
                setFilterTitle={setFilterTitle}
                filterIcon={filterIcon}
                setFilterIcon={setFilterIcon}
                filterStatus={filterStatus}
                setFilterStatus={setFilterStatus}
                filterMember={filterMember}
                setFilterMember={setFilterMember}
                filterIsCreator={filterIsCreator}
                setFilterIsCreator={setFilterIsCreator}
                selectedSpots={selectedSpots}
                resetFilter={resetFilter}
              />
            </Paper>
          </Grid>
          <Grid item md={5} sm={12}>
            <DrawingBox
              drawingsWithSpots={drawingsWithSpots}
              selectedSpots={selectedSpots}
              selectSpot={selectSpot}
            />
          </Grid>
        </Grid>
      )}

      <SelectDrawingAndPoint
        drawings={drawingsWithSpots}
        open={openSelectDrawingAndPoint}
        handleClose={() => setOpenSelectDrawingAndPoint(false)}
        selectedSpots={selectedSpots}
        selectSpot={selectSpot}
        selectDrawing={selectDrawing}
        selectAllSpot={selectAllSpot}
      />

      <Grid container style={{ marginTop: "20px" }}>
        <InfoTagListBox
          hostpotInfos={hotspotToShow}
          isSP={isSP}
          reloadAllHotspots={reloadAllHotspots}
          allHotspots={allHotspots}
          saveCondition={saveCondition}
        />
      </Grid>
    </React.Fragment>
  );
}

export default ViewTask;
