import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { getBuilderName } from "../Stores/reducer/reducer";
import "../css/base.css";
import "../../common/css/header.css";
import imageSrc from "../../common/img/logo.svg";
import ClockIcon from "../../common/img/clock_icon.svg";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { MenuItem, MenuList, Popover } from "@material-ui/core";
import { showLogMeetHistory } from "../../common/Stores/action/action";
class HeaderBuilderPC extends Component {
  state = {
    anchorElHelpPage: null
  };

  componentWillMount() {
    this.props.onMount();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disableHeader !== this.props.disableHeader && this.props.disableHeader) {
      var element = document.getElementsByClassName("fixed-box-header")[0];
      if (element) {
        element.parentNode.removeChild(element);
      }
    }
  }
  showHelpPageMenu = e => {
    this.setState({
      anchorElHelpPage: e.currentTarget
    });
  };

  closeHelpPageMenu = () => {
    this.setState({
      anchorElHelpPage: null
    });
  };

  render() {
    const { titlePage, builderName, showDrawer, isFixSpot, builderFlag, showLogMeetHistory } = this.props;
    const { anchorElHelpPage } = this.state;
    const openHelpPage = Boolean(anchorElHelpPage);
    return (
      <React.Fragment>
        <AppBar
          position="sticky"
          color="default"
          className={`header-menu ${isFixSpot ? "header-menu-fix-spot" : ""}`}
          id="header-builder"
        >
          <Grid container wrap="nowrap">
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="header-left valign-wrapper"
            >
              {(!isFixSpot) && (<IconButton
                size="small"
                onClick={showDrawer}
                edge="start"
                className="header-menu-button"
                aria-label="menu-button"
              >
                <MenuIcon className="icon" />
              </IconButton>)}

              <Link to="/dashboard" className="reset-link">
                <img alt="Logo" height="50px" src={imageSrc} />
              </Link>
            </Grid>
            <Grid className="header-right valign-wrapper">
              <Typography
                variant="h5"
                className="primary-font-family white-text text-bold"
                id="title-page-builder"
              >
                {titlePage}
              </Typography>
              <Grid className="valign-wrapper">
                <Typography
                  variant="h6"
                  className="primary-font-family white-text mr-15 text-bold valign-wrapper"
                >
                  {builderName}
                </Typography>
                {(!isFixSpot) && (
                  <>
                    <Link to="/mypage/profile" className="reset-link">
                      <IconButton
                        size="small"
                        aria-label="menu-button"
                        className="white-text"
                      >
                        <AccountCircleIcon className="icon" />
                      </IconButton>
                    </Link>
                    <IconButton
                      size="small"
                      aria-label="menu-button"
                      className="white-text reset-link"
                      onClick={this.showHelpPageMenu}
                    >
                      <HelpOutlineIcon className="icon" />
                    </IconButton>
                    {builderFlag && (<Link to="/call-history" className="reset-link">
                      <Button
                        size="small"
                        aria-label="menu-button"
                        className="white-text"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                          width: "124px",
                          height: "40px",
                          marginLeft: "12px"
                        }}
                        onClick={ showLogMeetHistory }
                      >
                        <img src={ClockIcon} />
                        <span
                          style={{
                            color: "#162F50",
                            fontFamily: "Noto Sans JP",
                            fontWeight: "bold",
                            fontSize: "16px",
                            lineHeight: "28px"
                          }}
                        >
                          &nbsp;
                          通話履歴
                        </span>
                      </Button>
                    </Link>)}
                  </>
                )}
              </Grid>

            </Grid>
          </Grid>
        </AppBar>
        <Popover
          open={openHelpPage}
          anchorEl={anchorElHelpPage}
          onClose={this.closeHelpPageMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuList>
            <div
              style={{
                borderBottom: "1px solid black",
                cursor: "default",
                backgroundColor: "white",
                padding: "6px 16px",
                fontSize: "15px",
                outline: "none",
                userSelect: "none"
              }}
            >
              ヘルプ
            </div>
            <MenuItem
              onClick={() => {
                window.open("https://www.log-build.com/ls-help/", "_blank");
              }}
            >
              マニュアルガイド
            </MenuItem>
            <MenuItem
              onClick={() =>
                window.open(
                  "https://www.log-build.com/ls-help/#link001",
                  "_blank"
                )
              }
            >
              動画マニュアル
            </MenuItem>
            <MenuItem
              onClick={() =>
                window.open(
                  "https://www.log-build.com/ls-help/#link002",
                  "_blank"
                )
              }
            >
              アプリダウンロード
            </MenuItem>
            <MenuItem
              onClick={() =>
                window.open("https://www.log-build.com/profile/", "_blank")
              }
            >
              新着情報
            </MenuItem>
            <MenuItem
              onClick={() =>
                window.open("https://www.log-build.com/user-support/", "_blank")
              }
            >
              お問合せ
            </MenuItem>
          </MenuList>
        </Popover>
      </React.Fragment>
    );
  }
}

HeaderBuilderPC.propTypes = {};

const mapStateToProps = state => ({
  builderName: getBuilderName(state.storesReducer.builderName)
});
const mapDispatchToProps = dispatch => bindActionCreators({
  showLogMeetHistory
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBuilderPC);
