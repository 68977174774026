import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CommonDialog from "../../CommonDialog";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";
import DialogDelete from "../../../common/DialogDelete";
import {
  fetchLogBuildAuth,
  addStaffLogBuild,
  fetchLogBuildStaffDetail,
  updateStaffLogBuild,
  deleteStaffLogBuild
} from "./service/service";
import { fetchLogBuildStaffDetailSuccess } from "./action/action";
import { apiError } from "../../../common/Stores/action/action";
import { translateMessage, trimObjValues } from "utils/mapper";
import isEmail from "validator/lib/isEmail";
import { isValid } from "date-fns";
import { withRouter } from "react-router";

class StaffLogBuildDialog extends React.Component {
  state = {
    defaultValues: {
      name: "",
      furigana: "",
      phone: "",
      email: "",
      auth: -1,
      retirementDate: null,
      password: "",
      confirm_password: ""
    },
    validateMessage: {
      name: "",
      furigana: "",
      phone: "",
      email: "",
      auth: "",
      retirementDate: "",
      password: "",
      confirm_password: ""
    },
    fieldList: [
      {
        name: "name",
        label: "社員名",
        type: DIALOG_FIELD_TYPES.Text,
        isRequired: true
      },
      {
        name: "furigana",
        label: "社員名（ふりがな全角）​",
        type: DIALOG_FIELD_TYPES.Text,
        isRequired: true
      },
      {
        name: "email",
        label: "メールアドレス",
        type: DIALOG_FIELD_TYPES.Text,
        isRequired: true
      },
      {
        name: "phone",
        label: "個人電話番号",
        type: DIALOG_FIELD_TYPES.Text,
        isRequired: true
      },
      {
        name: "retirementDate",
        label: "退社日",
        type: DIALOG_FIELD_TYPES.DateTime
      },
      {
        name: "auth",
        label: "権限",
        type: DIALOG_FIELD_TYPES.DropDownBox,
        options: [],
        isRequired: true
      }
    ],
    fieldListCreate: []
  };

  componentWillMount() {
    this.props.fetchLogBuildAuth();
    if (this.props.staffId)
      this.props.fetchLogBuildStaffDetail(this.props.staffId);
  }

  componentDidMount() {
    this.setState({
      fieldListCreate: [
        ...this.state.fieldList,
        {
          name: "password",
          label: "パスワード",
          type: DIALOG_FIELD_TYPES.Password,
          isShow: false,
          isIcon: true,
          isRequired: true
        },
        {
          name: "confirm_password",
          label: "パスワード（再入力）",
          type: DIALOG_FIELD_TYPES.Password,
          isShow: false,
          isIcon: true,
          isRequired: true
        }
      ]
    });
  }

  componentWillUnmount() {
    // Clear staff error and detail in store
    this.props.fetchLogBuildStaffDetailSuccess("");
    this.props.apiError(null);
  }

  componentWillReceiveProps(nextProps) {
    // Apply data from api to original list for display combox box
    if (nextProps.authEntries) {
      const authIndex = this.state.fieldList.findIndex(
        field => field.name === "auth"
      );
      const newFieldList = [...this.state.fieldList];
      newFieldList[authIndex].options = nextProps.authEntries;
      this.setState({
        fieldList: newFieldList,
        fieldListCreate: [
          ...newFieldList,
          {
            name: "password",
            label: "パスワード",
            type: DIALOG_FIELD_TYPES.Password,
            isShow: false,
            isIcon: true,
            isRequired: true
          },
          {
            name: "confirm_password",
            label: "パスワード（再入力）",
            type: DIALOG_FIELD_TYPES.Password,
            isShow: false,
            isIcon: true,
            isRequired: true
          }
        ]
      });
    }
  }

  onDialogClose = () => {
    // Clear staff error and detail in store
    this.props.fetchLogBuildStaffDetailSuccess("");
    this.props.apiError(null);
    this.props.onCloseDialog();
  };

  onSubmit = valuesForm => {
    const valueFormTrim = trimObjValues(valuesForm);
    const { regex, message } = this.props;
    const newValuesForm = {
      auth: valueFormTrim.auth,
      email: valueFormTrim.email,
      furigana: valueFormTrim.furigana,
      name: valueFormTrim.name,
      password: valueFormTrim.password,
      phone: valueFormTrim.phone,
      retirementDate: valueFormTrim.retirementDate
    };

    const {
      furigana,
      phone,
      auth,
      name,
      email,
      password,
      confirm_password,
      retirementDate
    } = valueFormTrim;

    this.props.apiError();
    if (newValuesForm.auth === -1) {
      newValuesForm.auth = "";
    }

    this.required(valueFormTrim);
    this.invalid({ phone: phone, furigana: furigana });

    if (confirm_password && confirm_password !== password) {
      this.setState({
        validateMessage: {
          ...this.state.validateMessage,
          confirm_password: translateMessage(
            this.getMessage("match", message),
            this.props.regexObject
          )
        }
      });
    }

    if ((password && !password.match(regex["password"])) || !password) {
      this.setState({
        validateMessage: {
          ...this.state.validateMessage,
          password: translateMessage(
            this.getMessage("password", message),
            this.props.regexObject
          )
        }
      });
    }

    if (email && !isEmail(email)) {
      this.setState({
        validateMessage: {
          ...this.state.validateMessage,
          email: translateMessage(
            this.getMessage("email", message),
            this.props.regexObject
          )
        }
      });
    }

    if (retirementDate && !isValid(new Date(retirementDate))) {
      this.setState({
        validateMessage: {
          ...this.state.validateMessage,
          retirementDate: translateMessage(
            this.getMessage("date", message),
            this.props.regexObject
          )
        }
      });
    }

    if (
      this.props.staffId &&
      name &&
      auth >= 0 &&
      isEmail(email) &&
      phone.match(regex["phone"]) &&
      furigana.match(regex["furigana"]) &&
      isValid(new Date(retirementDate))
    ) {
      this.props.updateStaffLogBuild(this.props.staffId, newValuesForm, () => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        this.props.history.go(0);
      });
    }

    if (
      name &&
      auth >= 0 &&
      isEmail(email) &&
      confirm_password === password &&
      password.match(regex["password"]) &&
      phone.match(regex["phone"]) &&
      furigana.match(regex["furigana"]) &&
      isValid(new Date(retirementDate))
    ) {
      this.props.addStaffLogBuild(newValuesForm, () => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        this.props.history.go(0);
      });
    }
  };

  invalid = values => {
    const { regex, message } = this.props;
    for (const item in values) {
      if (values[item] && !values[item].match(regex[item])) {
        this.setState({
          validateMessage: {
            ...this.state.validateMessage,
            [item]: translateMessage(
              this.getMessage(`${item}`, message),
              this.props.regexObject
            )
          }
        });
      }
    }
  };

  required = values => {
    const { message } = this.props;
    const newValue = { ...values };

    if (newValue.auth <= -1) {
      newValue.auth = "";
    } else {
      newValue.auth = 1;
    }
    for (const item in newValue) {
      if (!newValue[item] && item !== "retirementDate") {
        this.setState({
          validateMessage: {
            ...this.state.validateMessage,
            [item]: translateMessage(
              this.getMessage("required", message),
              this.props.regexObject
            )
          }
        });
      } else {
        this.setState({
          validateMessage: {
            ...this.state.validateMessage,
            [item]: ""
          }
        });
      }
    }
  };

  getMessage = (key, message) => {
    return message[key];
  };

  toggleDialogDelete = () => {
    this.props.toggleDialogDelete();
  };

  onDeleteStaff = () => {
    if (this.props.isEdit && this.props.staffId !== "") {
      this.props.deleteStaffLogBuild(this.props.staffId, () => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        this.props.history.go(0);
      });
      this.toggleDialogDelete();
    }
  };

  showPassword = (index, position) => () => {
    const newFieldList = [...this.state.fieldListCreate];
    const newIndex = position === "right" ? index + 5 : index;
    newFieldList[newIndex].isShow = !this.state.fieldListCreate[newIndex]
      .isShow;
    this.setState({
      fieldListCreate: newFieldList
    });
  };

  render() {
    const {
      defaultValues,
      fieldList,
      fieldListCreate,
      validateMessage
    } = this.state;
    const {
      isDialogShow,
      isEdit,
      submitErrors,
      staffLogBuildDetail,
      isDialogDeleteShow
    } = this.props;

    if (staffLogBuildDetail && staffLogBuildDetail.auth === null) {
      staffLogBuildDetail.auth = -1;
    }

    return (
      <>
        <CommonDialog
          title={isEdit ? "社員情報編集" : "社員情報登録"}
          isDialogShow={isDialogShow}
          initialValues={
            !staffLogBuildDetail.name ? defaultValues : staffLogBuildDetail
          }
          onSubmit={this.onSubmit}
          onClose={this.onDialogClose}
          fieldList={isEdit ? fieldList : fieldListCreate}
          submitButtonTitle={"登録​"}
          submitErrors={submitErrors ? submitErrors : validateMessage}
          isEdit={isEdit}
          numberRow={5}
          onDeleteClick={this.toggleDialogDelete}
          showPassword={this.showPassword}
        />
        <DialogDelete
          open={isDialogDeleteShow}
          onClose={this.toggleDialogDelete}
          textTop={`${staffLogBuildDetail?.name}を`}
          textBottom={"削除しますか？​"}
          onDeleteSubmit={this.onDeleteStaff}
          PaperProps={{
            className: "dialog dialog-sign-up"
          }}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  submitErrors: state.storesReducer.error,
  authEntries: state.staffLogBuildReducer.authEntries,
  regexObject: state.storesReducer.regexObject,
  staffLogBuildDetail: state.staffLogBuildReducer.staffLogBuildDetail,
  regex: state.auth.regex,
  message: state.storesReducer.message
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLogBuildAuth: fetchLogBuildAuth,
      addStaffLogBuild: addStaffLogBuild,
      fetchLogBuildStaffDetail: fetchLogBuildStaffDetail,
      apiError: apiError,
      fetchLogBuildStaffDetailSuccess: fetchLogBuildStaffDetailSuccess,
      updateStaffLogBuild: updateStaffLogBuild,
      deleteStaffLogBuild: deleteStaffLogBuild
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StaffLogBuildDialog));
