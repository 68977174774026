import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { format } from "date-fns";

import IconInfo from "./icons/IconInfo";

import NewHS from "../../../infoHotspots/imgs/new_HS.svg";
import NoHS from "../../../infoHotspots/imgs/no_HS.svg";
import NewMessage from "../../../infoHotspots/imgs/new_message.svg";
import NoMessage from "../../../infoHotspots/imgs/no_message.svg";
import Popover from "@material-ui/core/Popover";
import { Dialog, MenuItem } from "@material-ui/core";
import DetailHotspotInfoDialog from "./DetailHotspotInfoDialog/DetailHotspotInfoDialog";
import { useHistory } from "react-router";
import ConfirmChangeStatusDialog from "./ConfirmChangeStatusDialog";
import { api } from "../../../../common/api/api";
import { useLocation, useParams } from "react-router";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import querySearch from "stringquery";
import HotspotInfoDrawer from "../LogWalkViewer/HotspotInfoDrawer";
import { setRedirectFromUrl } from "../../../sites/site/action/action";
import CloseButton from "./CloseButton";

const useStyles = makeStyles({
  showMoreButton: {
    width: "100%",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "#2b57d0",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#f1f1f1"
    }
  },
  truncateText2: {
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "wrap",
    wordBreak: "break-word"
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: "#d6d6d6"
    }
  },
  menuItem: {
    height: "50px",
    width: "100%",
    fontSize: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgba(0, 53, 109, 0.1)"
    }
  }
});

function InfoTagListBox(props) {
  const { hostpotInfos, isSP, reloadAllHotspots, allHotspots, saveCondition } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [anchorElMoreMenu, setAnchorElMoreMenu] = useState(null);
  const [openPopoverFor, setOpenPopoverFor] = useState(null);
  const [openConfirmChangeStatus, setOpenConfirmChangeStatus] = useState(false);
  const [showDetail, setShowDetail] = useState(null);
  const [showMessageInfoHasBeenDeleted, setShowMessageInfoHasBeenDeleted] = useState(false);

  useEffect(() => {
    if(hostpotInfos !== null) {
      const paramsSearch = querySearch(location.search);
      if (paramsSearch.info && hostpotInfos !== null) {
        const currentHotSpot = allHotspots.find(
          item => item.info.uniqueCode === paramsSearch.info
        );
        
        if (currentHotSpot) {
          if(paramsSearch.detail == 1) {
            setShowDetail(currentHotSpot);
          } else {
            history.replace(history.location.pathname);
          }
          setShowMessageInfoHasBeenDeleted(false);
        } else {
          setShowMessageInfoHasBeenDeleted(true);
          setShowDetail(null);
        }
      }      
    }
  }, [hostpotInfos]);

  const nextHotspot = () => {
    if (!showDetail) return;
    const orderOfCurrentHotspot = hostpotInfos.findIndex(
      item => item.info.uniqueCode === showDetail.info.uniqueCode
    );
    if (
      orderOfCurrentHotspot !== -1 &&
      orderOfCurrentHotspot < hostpotInfos.length - 1
    ) {
      setShowDetail(hostpotInfos[orderOfCurrentHotspot + 1]);
    }
  };

  const previousHotspot = () => {
    if (!showDetail) return;
    const orderOfCurrentHotspot = hostpotInfos.findIndex(
      item => item.info.uniqueCode === showDetail.info.uniqueCode
    );
    if (orderOfCurrentHotspot > 0) {
      setShowDetail(hostpotInfos[orderOfCurrentHotspot - 1]);
    }
  };

  const changeStatus = () => {
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    api
      .post(
        `/${classification}/logwalk-viewer/infos/${
          openPopoverFor.info.uniqueCode
        }/${openPopoverFor.info.completedAt ? "uncomplete" : "complete"}`
      )
      .then(() => {
        history.replace(history.location.pathname);
        setOpenConfirmChangeStatus(false);
        setAnchorElMoreMenu(null);
        setTimeout(() => {
          setOpenPopoverFor(null);
          reloadAllHotspots();
        }, 300);
      })
      .catch(() => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        reloadAllHotspots();
      });
  };

  return (
    <React.Fragment>
      <TableContainer>
        <Table size="small" aria-label="table">
          <TableHead>
            <TableRow style={{ backgroundColor: "#162f50" }}>
              {!isSP && (
                <TableCell
                  style={{
                    width: "40px",
                    borderRadius: "0px",
                    padding: "6px 16px"
                  }}
                />
              )}

              <TableCell
                style={{
                  width: "80px",
                  borderRadius: "0px",
                  padding: "6px 16px"
                }}
              />
              <TableCell
                className="white-text text-center"
                style={{
                  width: "240px",
                  borderRadius: "0px",
                  padding: "6px 16px"
                }}
              >
                タイトル
              </TableCell>
              <TableCell
                className="white-text text-center"
                style={{
                  width: "120px",
                  borderRadius: "0px",
                  padding: "6px 16px"
                }}
              >
                状態
              </TableCell>
              <TableCell
                className="white-text text-center"
                style={{
                  width: "120px",
                  borderRadius: "0px",
                  padding: "6px 16px"
                }}
              >
                作成者
              </TableCell>

              {!isSP && (
                <React.Fragment>
                  <TableCell
                    className="white-text text-center"
                    style={{
                      width: "120px",
                      borderRadius: "0px",
                      padding: "6px 16px"
                    }}
                  >
                    図面名
                  </TableCell>
                  <TableCell
                    className="white-text text-center"
                    style={{
                      width: "120px",
                      borderRadius: "0px",
                      padding: "6px 16px"
                    }}
                  >
                    地点名
                  </TableCell>
                  <TableCell
                    className="white-text text-center"
                    style={{
                      width: "120px",
                      borderRadius: "0px",
                      padding: "6px 16px"
                    }}
                  >
                    撮影日時
                  </TableCell>
                  <TableCell
                    className="white-text text-center"
                    style={{
                      width: "120px",
                      borderRadius: "0px",
                      padding: "6px 16px"
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto"
                      }}
                    >
                      <span>更新日時</span>
                    </span>
                  </TableCell>
                </React.Fragment>
              )}

              <TableCell style={{ width: "80px", borderRadius: "0px" }} />
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {
              hostpotInfos !==  null &&
              hostpotInfos.length > 0 &&
              hostpotInfos.map(hotspot => (
                <TableRow
                  key={hotspot.info.uniqueCode}
                  className={classes.tableRowHover}
                  onClick={() => setShowDetail(hotspot)}
                >
                  {!isSP && (
                    <TableCell
                      style={{
                        width: "40px",
                        paddingLeft: "8px",
                        paddingRight: "8px"
                      }}
                      className="padding-first-td"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          fontSize: "24px"
                        }}
                      >
                        <img
                          alt="hotspot"
                          src={
                            hotspot.hotspotnotification.hotspot.unread
                              ? NewHS
                              : NoHS
                          }
                          style={{
                            width: hotspot.hotspotnotification.hotspot.unread
                              ? "22px"
                              : "20px",
                            height: hotspot.hotspotnotification.hotspot.unread
                              ? "22px"
                              : "20px",
                            marginBottom: "4px"
                          }}
                        />
                        <img
                          alt="chat"
                          src={
                            hotspot.hotspotnotification.chat.unread
                              ? NewMessage
                              : NoMessage
                          }
                          style={{
                            width: hotspot.hotspotnotification.chat.unread
                              ? "22px"
                              : "20px",
                            height: hotspot.hotspotnotification.chat.unread
                              ? "22px"
                              : "20px"
                          }}
                        />
                      </div>
                    </TableCell>
                  )}

                  <TableCell
                    className="text-center"
                    style={{
                      width: "90px",
                      paddingLeft: "8px",
                      paddingRight: "8px"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                      }}
                    >
                      <IconInfo
                        width="32px"
                        height="32px"
                        color={hotspot.icon.color}
                      />
                      <p style={{ marginTop: "4px", whiteSpace: "nowrap" }}>
                        {hotspot.icon.label}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      paddingLeft: "8px",
                      paddingRight: "8px"
                    }}
                  >
                    <p className={classes.truncateText2}>
                      {hotspot.info.title}
                    </p>
                  </TableCell>
                  <TableCell
                    className="text-center"
                    style={{
                      paddingLeft: "6px",
                      paddingRight: "14px"
                    }}
                  >
                    {hotspot.info.completedAt ? (
                      <span>
                        <span
                          style={{
                            fontSize: "10px",
                            color: "red",
                            lineHeight: "inherit",
                            marginRight: "3px"
                          }}
                        >
                          <Brightness1Icon color="inherit" fontSize="inherit" />
                        </span>
                        完了
                      </span>
                    ) : (
                      <span>未完了</span>
                    )}
                  </TableCell>
                  <TableCell
                    className="text-center"
                    style={{
                      paddingLeft: "6px",
                      paddingRight: "14px"
                    }}
                  >
                    <p
                      className={classes.truncateText2}
                      style={{ "-webkit-line-clamp": "2" }}
                    >
                      {hotspot.info.createdBy}
                    </p>
                  </TableCell>

                  {!isSP && (
                    <TableCell
                      style={{
                        paddingLeft: "8px",
                        paddingRight: "8px"
                      }}
                    >
                      <p className={classes.truncateText2}>
                        {hotspot.drawing.name}
                      </p>
                    </TableCell>
                  )}

                  {!isSP && (
                    <TableCell
                      style={{
                        paddingLeft: "8px",
                        paddingRight: "8px"
                      }}
                    >
                      <p className={classes.truncateText2}>
                        {hotspot.spot.name}
                      </p>
                    </TableCell>
                  )}

                  {!isSP && (
                    <TableCell
                      className="text-center"
                      style={{
                        paddingLeft: "8px",
                        paddingRight: "8px"
                      }}
                    >
                      <p>
                        {format(
                          new Date(hotspot.record.createdAt),
                          "yyyy/MM/dd"
                        )}
                      </p>
                      <p>
                        {format(new Date(hotspot.record.createdAt), "HH:mm")}
                      </p>
                    </TableCell>
                  )}
                  {!isSP && (
                    <TableCell
                      className="text-center"
                      style={{
                        paddingLeft: "8px",
                        paddingRight: "8px"
                      }}
                    >
                      <p>
                        {format(new Date(hotspot.info.updatedAt), "yyyy/MM/dd")}
                      </p>
                      <p>{format(new Date(hotspot.info.updatedAt), "HH:mm")}</p>
                    </TableCell>
                  )}

                  <TableCell
                    className="text-center"
                    style={{
                      paddingLeft: "8px",
                      paddingRight: "8px"
                    }}
                  >
                    <button
                      style={{
                        width: "44px",
                        height: "44px",
                        fontSize: "36px",
                        lineHeight: "36px",
                        padding: "4px",
                        backgroundColor:
                          hotspot.info.uniqueCode ===
                          openPopoverFor?.info?.uniqueCode
                            ? "#00356D"
                            : "unset",
                        color:
                          hotspot.info.uniqueCode ===
                          openPopoverFor?.info?.uniqueCode
                            ? "#FFFFFF"
                            : "#00356D"
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpenPopoverFor(hotspot);
                        setAnchorElMoreMenu(e.currentTarget);
                      }}
                    >
                      <MoreVertIcon color="inherit" fontSize="inherit" />
                    </button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        open={Boolean(anchorElMoreMenu)}
        anchorEl={anchorElMoreMenu}
        onClose={() => {
          setAnchorElMoreMenu(null);
          setOpenPopoverFor(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <div style={{ width: "170px" }}>
          <MenuItem
            style={{ color: "#00356D" }}
            className={classes.menuItem}
            onClick={() => setOpenConfirmChangeStatus(true)}
          >
            <span>
              {openPopoverFor?.info?.completedAt ? "未完了へ" : "完了へ"}
            </span>
          </MenuItem>
          <hr style={{ margin: "0" }} />
          <MenuItem
            style={{ color: "#FF8675" }}
            className={classes.menuItem}
            onClick={() => {
              dispatch(
                setRedirectFromUrl({
                  isRedirectFromUrl: true,
                  from: `${
                    location.pathname.includes("/sp/") ? "/sp" : ""
                  }/sites/${id}?tab=4&info=${openPopoverFor?.info?.uniqueCode}&detail=0`
                })
              );
              saveCondition();
              history.push(
                "/logwalk-viewer/shoots/" +
                  openPopoverFor.shoot.uniqueCode +
                  "?info=" +
                  openPopoverFor.info.uniqueCode
              );
            }}
          >
            <span>ここを見る</span>
          </MenuItem>
        </div>
      </Popover>
      {
        hostpotInfos !== null && (
          <DetailHotspotInfoDialog
            hotspot={showDetail}
            allHotspots={hostpotInfos}
            onClose={() => {
              setShowDetail(null);
              history.replace(history.location.pathname);
            }}
            nextHotspot={nextHotspot}
            previousHotspot={previousHotspot}
            isSP={isSP}
            reloadAllHotspots={reloadAllHotspots}
            saveCondition={saveCondition}
          />          
        )
      }

      <ConfirmChangeStatusDialog
        open={openConfirmChangeStatus && openPopoverFor}
        handleClose={() => setOpenConfirmChangeStatus(false)}
        completedAt={openPopoverFor && openPopoverFor.info.completedAt}
        changeStatus={changeStatus}
      />
      <Dialog
        onClose={() => {
          setShowDetail(null);
          setShowMessageInfoHasBeenDeleted(false)
          history.replace(history.location.pathname);
        }}
        open={showMessageInfoHasBeenDeleted}
        fullWidth={true}
        PaperProps={{
          style: {
            overflowY: "unset",
            width: isSP ? "calc(100% - 32px)" : "calc(100% - 64px)",
            maxHeight: isSP ? "calc(100% - 24px)" : "calc(100% - 64px)",
            margin: isSP ? "16px" : "32px"
          }
        }}
      >
        <CloseButton 
          handleClose={() => {
            setShowDetail(null);
            setShowMessageInfoHasBeenDeleted(false)
            history.replace(history.location.pathname);
          }} 
        />
        <div 
          style={{
            margin: "auto", 
            minHeight: "120px",
            paddingTop:  "60px",
            fontSize: "20px"
          }}
        >インフォタグが削除されました</div>
      </Dialog>
    </React.Fragment>
  );
}

export default InfoTagListBox;
