import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Component from "./../Upload";
import { bindActionCreators } from "redux";
import { getFiles } from "./../reducer/reducer";
import { searchFileSP, downloadFileSP } from "./../service/service";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

class FilesSP extends Component {
  fetch() {
    this.props.searchFileSP(this.sitesUC);
  }

  onFileClick = (model) => {
    this.props.downloadFileSP(model.fileId, model.blob);
  };

  render() {
    const { filesSP } = this.props;
    return (
      <TableContainer className="tab2-content-sp">
        <Table aria-label="table" className="sites-table">
          <TableBody className="table-body">
            {filesSP.map(model => (
              <TableRow
                key={model.fileId}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.onFileClick(model)
                }
              >
                <TableCell className="padding-first-td">{model.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  filesSP: getFiles(state.rootSitesReducer.siteInfoReducer.filesSP)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchFileSP: searchFileSP,
      downloadFileSP
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FilesSP));
