import React from "react";

function IconEdit({ color = "#00356D" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <path fill="none" d="M0 0H26V26H0z"></path>
      <g fill={color} transform="rotate(-150 7.605 9.697)">
        <path d="M1.572 0l1.572 4.541H0z"></path>
        <rect
          width="3.144"
          height="13.274"
          rx="1.572"
          transform="translate(0 5.87)"
        ></rect>
      </g>
    </svg>
  );
}

export default IconEdit;
