import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ViewListFile from "./ViewListFile";
import ReviewSpotPosition from "./ReviewSpotPosition";
import ReviewHotspotPosition from "./ReviewHotspotPosition";
import { api } from "../../../../../../common/api/api";
import { arrayBufferToBase64 } from "../../../../../../utils/mapper";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsDefaultHistory,
  setTypeSelectImage3D,
  setImageShootUnAdjusted,

} from "../../../action/action";
import { TYPE_SELECT_IMAGE_3D } from "../../constants";
const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: "0px 20px 80px"
  }
});
function TabFile(props) {
  const {
    hotspotInfo,
    currentHotspotInfoId,
    saveFileNameSuccess,
    uploadFileSuccess,
    deleteFileSuccess,
    readedHotspot,
    onResetFlagRedirectNoImage,
    rotateToHotspotPosition,
    backToDrawing,
    miniBlobDataLogWalk,
    shootThumbnailBase64,
    setShootThumbnailBase64,
    largeShootThumbnailBase64,
    setLargeShootThumbnailBase64
  } = props;
  const classes = useStyles();
  let location = useLocation();
  let history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [drawingImgData, setDrawingImgData] = useState("");

  const imageShootUnAdjusted = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.imageShootUnAdjusted
  );

  useEffect(() => {
    if (hotspotInfo) {
      var isBlob = hotspotInfo.drawing.blob ? true : false;
      var path = isBlob ? hotspotInfo.drawing.blob.replace(/^\/api/, '') : hotspotInfo.drawing.file.slice(4);
      api
        .get(path, { responseType: isBlob ? undefined : "arraybuffer" })
        .then(res => {
          if (res.data) {
            if (res.data) {
              if (isBlob) {
                setDrawingImgData(res.data.blobData);
              } else {
                const base64Data = arrayBufferToBase64(res.data);
                setDrawingImgData(`data:image/jpeg;base64,${base64Data}`);
              }
            } else {
              setDrawingImgData("");
            }
          }
        });
    } else {
      setDrawingImgData("");
    }
  }, [hotspotInfo.drawing]);

  useEffect(() => {
    let timer;
    if (hotspotInfo && hotspotInfo.version !== "readed") {
      timer = setTimeout(() => readedHotspotNotification(), 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [currentHotspotInfoId]);

  const readedHotspotNotification = () => {
    const data = {
      infosUC: currentHotspotInfoId,
      version: hotspotInfo.version,
      category: 'hotspot'
    };

    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    api
      .put(`/${classification}/logwalk-viewer/hotspot-notifications/read`, data)
      .then(() => {
        readedHotspot();
      });
  };

  const redirectToShoot = () => {
    onResetFlagRedirectNoImage();
    dispatch(setIsDefaultHistory());
    dispatch(setTypeSelectImage3D(TYPE_SELECT_IMAGE_3D.SPOT));
    if (hotspotInfo) {
      if (hotspotInfo.shoot.uniqueCode === id && !imageShootUnAdjusted.shootCode) {
        rotateToHotspotPosition(hotspotInfo.yaw, hotspotInfo.pitch);
      } else {
        if (imageShootUnAdjusted.shootCode) {
          backToDrawing();
        }
        if (location.pathname.includes("/sp/")) {
          history.push(
            "/sp/logwalk-viewer/shoots/" +
            hotspotInfo.shoot.uniqueCode +
            "?info=" +
            currentHotspotInfoId
          );
        } else {
          history.push(
            "/logwalk-viewer/shoots/" +
            hotspotInfo.shoot.uniqueCode +
            "?info=" +
            currentHotspotInfoId
          );
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      {hotspotInfo && (
        <React.Fragment>
          <ViewListFile
            files={hotspotInfo.files}
            currentHotspotInfoId={currentHotspotInfoId}
            uploadFileSuccess={() => uploadFileSuccess()}
            shoot={hotspotInfo.shoot}
            sitesUC={hotspotInfo.sitesUC}
            saveFileNameSuccess={(fileUniqueCode, fileName) =>
              saveFileNameSuccess(fileUniqueCode, fileName)
            }
            deleteFileSuccess={fileUniqueCode =>
              deleteFileSuccess(fileUniqueCode)
            }
          />

          <div style={{ display: "flex" }}>
            <div
              style={{ width: "50%", padding: "0px 12px 0px 0px" }}
            >
              <ReviewHotspotPosition
                currentHotspotInfoId={currentHotspotInfoId}
                miniBlobDataLogWalk={miniBlobDataLogWalk}
                base64={shootThumbnailBase64}
                setBase64={setShootThumbnailBase64}
                largeBase64={largeShootThumbnailBase64}
                setLargeBase64={setLargeShootThumbnailBase64}
                yaw={hotspotInfo.yaw}
                pitch={hotspotInfo.pitch}
                iconColor={hotspotInfo.icon.color}
              />
            </div>
            <div style={{ width: "50%", padding: "0px 0px 0px 12px" }}>
              <ReviewSpotPosition
                drawing={drawingImgData}
                spot={hotspotInfo.spot}
              />
              <button
                onClick={redirectToShoot}
                style={{
                  backgroundColor: "#00356D",
                  color: "white",
                  fontSize: "15px",
                  textAlign: "center",
                  lineHeight: "24px",
                  width: "100%",
                  cursor: "pointer",
                  marginTop: "10px",
                  touchAction: "manipulation"
                }}
              >
                この地点へ移動
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

TabFile.propTypes = {};

export default TabFile;
