import React, { useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "./TabPanel";
import { useMediaQuery } from "react-responsive";
import IconInfoSetting from "./iconInfoSetting/IconInfoSetting";

const mediaQueryTabletOrMobile = "(max-width: 960px)";
const mediaQueryTiniMobile = "(max-width: 460px)";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: "20px 35px"
  }
}));

export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const isTabletOrMobile = useMediaQuery({ query: mediaQueryTabletOrMobile });
  const isTiniMobile = useMediaQuery({ query: mediaQueryTiniMobile });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  return (
    <React.Fragment>
      <div style={isTabletOrMobile ? { padding: "20px 20px" } : { padding: "20px 35px" }}>
        <AppBar
          position="static"
          color="default"
          style={{ boxShadow: "none", backgroundColor: "white" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="インフォタグ" {...a11yProps(0)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <IconInfoSetting isTabletOrMobile={isTabletOrMobile} isTiniMobile={isTiniMobile} />
          </TabPanel>
        </SwipeableViews>
      </div>
    </React.Fragment>
  );
}
