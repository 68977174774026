import {
  FETCH_DASHBOARDS_SUCCESS,
  POST_DASHBOARDS_SUCCESS,
  FETCH_DASHBOARDS_SP_SUCCESS,
  FETCH_DASHBOARDS_LB_SUCCESS,
  CHANGE_TYPE_LIST_DASHBOARD_SUCCESS
} from "../constant/constant";

const initialState = {
  pending: false,
  dashboard: [],
  dashboardSP: [],
  dashboardLB: [],
  isCardList: true
};

export const dashboardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARDS_SUCCESS:
      return {
        ...state,
        pending: false,
        dashboard: action.dashboards,
        type: action.type
      };

    case POST_DASHBOARDS_SUCCESS:
      return {
        ...state,
        pending: false,
        type: action.type,
        uniqueCode: action.dashboard.UniqueCode
      };

    case FETCH_DASHBOARDS_SP_SUCCESS:
      return {
        ...state,
        pending: false,
        dashboardSP: action.dashboardSP,
        type: action.type
      };

    case FETCH_DASHBOARDS_LB_SUCCESS:
      return {
        ...state,
        pending: false,
        dashboardLB: action.dashboardLB,
        type: action.type
      };
      case CHANGE_TYPE_LIST_DASHBOARD_SUCCESS:
        return {
          ...state,
          pending: false,
          type: action.type,
          isCardList: action.isCardList
        }
    default:
      return state;
  }
};

export const getDashboards = state => state;
