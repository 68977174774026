import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { format } from "date-fns";
import PropTypes from "prop-types";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PlayCircleOutlineOutlinedIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "../../common/css/base.css";
import "../../common/css/sites.css";
import CardImage from "../CardImage";
import RobotStatus from "common/RobotStatus";
import { BUTTON_LOGKUN } from "common/constants/constants";
class SiteList extends Component {
  constructor() {
    super();
    this.state = {
      numberData: 1
    };
  }

  showMore = () => {
    this.setState({
      numberData: this.state.numberData + 1
    });
  };

  render() {
    const { numberData, maxCols } = this.state;

    const { siteData, robotsUpdate, isDashboard, isCompleteSite } = this.props;

    const datas = siteData;

    return (
      <TableContainer className="dashboard-table site-list">
        <Table size="small" aria-label="table" className="sites-table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell
                className="white-text padding-first-th text-center"
                style={{ width: 300 }}
              >
                現場名
              </TableCell>
              {!isDashboard && (
                <TableCell className="white-text text-center">店舗</TableCell>
              )}
              <TableCell
                className="white-text text-center"
                style={{ minWidth: 90 }}
              >
                更新日
                {(isDashboard || !isCompleteSite) && (
                  <ArrowDownwardIcon className="icon-small-size s-va-m margin-left" />
                )}
              </TableCell>
              <TableCell
                className="white-text text-center"
                style={{ minWidth: 400 }}
              >
                住所
              </TableCell>
              <TableCell
                className="white-text text-center"
                style={{ minWidth: 100 }}
              >
                現場監督
              </TableCell>
              <TableCell
                className="white-text text-center"
                style={{ minWidth: 100 }}
              >
                大工
              </TableCell>
              {!isDashboard && isCompleteSite && (
                <TableCell className="white-text text-center">
                  完了日
                  <ArrowDownwardIcon className="icon-small-size s-va-m margin-left" />
                </TableCell>
              )}
              <TableCell style={{ minWidth: 150 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {siteData
              .filter((item, index) => index < numberData * 20)
              .map(model => (
                <TableRow
                  className="table-row"
                  key={model.uniqueCode}
                  onClick={this.props.to(model.uniqueCode)}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell className="padding-first-td">
                    <Grid className="wrap-text-multi-line">{model.name}</Grid>
                  </TableCell>
                  {!isDashboard && <TableCell>{model.storeName}</TableCell>}
                  <TableCell className="text-center">
                    {model.lastRecordedAt
                      ? (format(new Date(model.lastRecordedAt), "yyyy/MM/dd"))
                      : (format(new Date(model.regisionDate), "yyyy/MM/dd"))
                    }
                  </TableCell>
                  <TableCell>
                    <Grid className="wrap-text-multi-line">
                      {model.address}
                    </Grid>
                  </TableCell>
                  <TableCell className="text-center">
                    {model.employeeNames.map(item => {
                      return <p>{item}</p>;
                    })}
                  </TableCell>
                  <TableCell className="text-center">
                    {model.workerNames.map(item => {
                      return <p>{item}</p>;
                    })}
                  </TableCell>
                  {!isDashboard && isCompleteSite && (
                    <TableCell className="text-center">
                      {model.completedAt &&
                        format(new Date(model.completedAt), "yyyy/MM/dd")}
                    </TableCell>
                  )}
                  <TableCell align="right" className="text-center" onClick={e => e.stopPropagation()}>
                    <Fab
                      className={
                        model.logWalkViewer
                          ? "m-5 white-text button-logWalk"
                          : "m-5 white-text button-logWalk-disable"
                      }
                      onClick={e => {
                        if (model.logWalkViewer) {
                          this.props.showDialogDrawing(model.uniqueCode);
                        }

                        e.stopPropagation();
                      }}
                      variant="extended"
                    >
                      <PlayCircleOutlineOutlinedIcon className="icon icon-margin" />
                      Log Walk起動
                    </Fab>
                    <RobotStatus
                      typeOfRobotStatus={BUTTON_LOGKUN}
                      robots={model.robots}
                      robotsUpdate={[robotsUpdate]}
                      class={"m-5"}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        {numberData * 20 < siteData.length && (
          <Grid className="center">
            <Button
              onClick={this.showMore}
              size="large"
              variant="text"
              className="showmore"
            >
              もっと見る
              <KeyboardArrowDownIcon />
            </Button>
          </Grid>
        )}
      </TableContainer>
    );
  }
}

SiteList.propTypes = {};

export default SiteList;
