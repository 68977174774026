import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import "../../../common/css/carpenter.css";
import "../../../common/css/base.css";
import PaginationControl from "../../../common/Pagination";
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';
import SearchField from "../../SearchField";

class CarpenterPC extends Component {
  onCompanyChange = value => {
    this.props.onCompanyChange(value);
  };

  onFuriganaChange = value => {
    this.props.onFuriganaChange(value);
  };

  render() {
    const { 
      carpenters, 
      carpenterData, 
      showPopover, 
      showDialog, 
      onChangeData,
      furiganaSearch,
      companySearch
    } = this.props;

    return (
      <Grid container wrap="nowrap" className="carpenter page-content">
        <Grid container>
          <Grid container justify="space-between" className="padding-content">
            <Grid item>
              <Grid container spacing={3}>
                <Grid item>
                  <SearchField
                    placeholder="会社名から検索"
                    setValue={this.onCompanyChange}
                    value={companySearch}
                  />
                </Grid>
                <Grid item>
                  <SearchField
                    placeholder="職人名から検索"
                    setValue={this.onFuriganaChange}
                    value={furiganaSearch}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Button
                onClick={showDialog}
                size="small"
                variant="text"
                className="button-add-new"
              >
                <AddIcon />
                職人登録
              </Button>
            </Grid>
          </Grid>
          <TableContainer>
            <Table size="small" aria-label="table" className="carpenter-table">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell className="white-text padding-first-th">
                    会社名
                  </TableCell>
                  <TableCell className="white-text padding-first-th">
                    職人名
                    <ArrowDropDownSharpIcon
                      className="icon-small-size s-va-m margin-left"
                      fontSize="large"
                    />
                  </TableCell>
                  <TableCell className="white-text padding-first-th">
                    会社電話
                  </TableCell>
                  <TableCell className="white-text padding-first-th">
                    会社FAX
                  </TableCell>
                  <TableCell className="white-text padding-first-th">
                    住所
                  </TableCell>
                  <TableCell className="white-text padding-first-th">
                    個人携帯
                  </TableCell>
                  <TableCell className="white-text padding-first-th">
                    メール
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {carpenterData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className="padding-first-td">
                      {row.supplierName}
                    </TableCell>
                    <TableCell className="padding-first-td">
                      <Grid
                        container
                        alignItems="center"
                        className="inline-block"
                      >
                        {row.name}
                        {row.job ? (
                          <Typography variant="caption" className="tag">
                            大工
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </TableCell>
                    <TableCell className="padding-first-td">
                      {row.supplierPhone}
                    </TableCell>
                    <TableCell className="padding-first-td">
                      {row.supplierFax}
                    </TableCell>
                    <TableCell className="padding-first-td">
                      {row.supplierAddress}
                    </TableCell>
                    <TableCell className="padding-first-td">
                      {row.phone}
                    </TableCell>
                    <TableCell className="padding-first-td">
                      {row.email}
                    </TableCell>
                    <TableCell className="padding-last-td s-ta-r">
                      <Fab
                        onClick={showPopover(row)}
                        size="small"
                        className="more-vert-btn"
                      >
                        <MoreVertIcon />
                      </Fab>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justify="space-between" className="padding-content">
            <Button
              onClick={showDialog}
              size="small"
              variant="text"
              className="button-add-new"
            >
              <AddIcon />
              職人登録
            </Button>
          </Grid>
          <Grid container justify="center">
            <PaginationControl
              limit={20}
              datas={carpenters}
              changeData={onChangeData}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default CarpenterPC;
