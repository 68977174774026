import { fetchAuthsSuccess, fetchAuthsError } from "../action/action";
import { api } from "common/api/api";

export const fetchAuths = () => {
  return dispatch => {
    api
      .get(`/master-auths/entries`)
      .then(res => {
        dispatch(fetchAuthsSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchAuthsError(err));
      });
  };
};
