import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { api } from "../../../common/api/api";
import Component from "../../Component";
import axios from "axios";

export class Photo extends Component {
  id = null;
  blob = null;
  width = "";
  height = "";
  style = {};
  alt = "";
  name = "";
  ajaxRequest = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      img: null,
      url: ""
    };
  }

  onGetSuccess(id, name, url) {
    this.setState({ url: url });
    this.props.onGetSuccess(id, name, url);
  }

  onClick() {
    this.props.onClick(this.id, this.name, this.state.url);
  }

  componentWillMount() {
    const { match } = this.props;
    this.init(this.props);
    if (!match.path.includes("sp")) {
      this.fetch();
    } else {
      this.fetchSP();
    }
  }

  init(props) {
    this.id = props.PhotosUC;
    this.blob = props.blob;
    this.width = props.width;
    this.height = props.height;
    this.style = props.style;
    this.alt = props.alt;
    this.name = props.name === undefined ? "" : props.name;
  }

  fetch() {
    var isBlob = this.blob ? true : false;
    var path = isBlob ? this.blob.resized.replace(/^\/api/, '') : "/builder/site-photos/" + this.id + "/thumbnail";
    api
      .get(path, {
        responseType: isBlob ? undefined : "arraybuffer",
        cancelToken: this.ajaxRequest.token
      })
      .then(res => {
        if (res.data === undefined) return Promise.reject();
        if (isBlob) {
          var url = res.data.blobData;
        } else {
          var blob = new Blob([res.data], { type: "image/jpeg" });
          var url = URL.createObjectURL(blob);
        }
        this.onGetSuccess(this.id, this.name, url);
        return url;
      })
      .then(url => this.img(url))
      .then(img => this.setState({ img }))
      .catch(e => {
        if (e?.response?.status === 404) {
          this.setState({ img: this.img("") });
          this.onGetSuccess(this.id, this.name, "");
        }
      });
  }

  componentWillUnmount() {
    this.ajaxRequest.cancel();
  }

  fetchSP() {
    var isBlob = this.blob ? true : false;
    var path = this.blob ? this.blob.resized.replace(/^\/api/, '')  : "/supplier/site-photos/" + this.id + "/thumbnail";
    api
      .get(path, {
        responseType: isBlob ? undefined : "arraybuffer"
      })
      .then(res => {
        if (res.data === undefined) return Promise.reject();
        if (isBlob) {
          var url = res.data.blobData;
        } else {
          var blob = new Blob([res.data], { type: "image/jpeg" });
          var url = URL.createObjectURL(blob);
        }
        this.onGetSuccess(this.id, this.name, url);
        return url;
      })
      .then(url => this.img(url))
      .then(img => this.setState({ img }))
      .catch(e => {
        if (e.response.status === 404) {
          this.setState({ img: this.img("") });
          this.onGetSuccess(this.id, this.name, "");
        }
      });
  }

  img(data) {
    return (
      <img
        src={data}
        width={this.width}
        height={this.height}
        style={this.style}
        alt={this.alt}
        onClick={() => this.onClick()}
      />
    );
  }

  render() {
    return this.state.img;
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(withRouter(Photo));
