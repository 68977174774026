import {
  FETCH_SITE_INFO_SUCCESS,
  FETCH_SITE_INFO_ROBOT_SUCCESS,
  FETCH_SITE_INFO_STAFF_SUCCESS,
  FETCH_SITE_INFO_SUPPLIER_SUCCESS,
  FETCH_STAFF_CANDIDATES_SUCCESS,
  FETCH_SUPPLIER_CANDIDATES_SUCCESS,
  FETCH_SITE_INFO_ROBOT_CANDIDATE_SUCCESS,
  POST_SITE_INFO_ROBOT_CANDIDATE_SUCCESS,
  GET_SITES_DETAIL_SUCCESS,
  GET_FILE_SUCCESS,
  POST_FILE_SUCCESS,
  SEARCH_FILE_SUCCESS,
  GET_PHOTOS_SUCCESS,
  GET_PHOTO_INFO_SUCCESS,
  GET_FILE_INFO_SUCCESS,
  FETCH_SITE_SUCCESS,
  FETCH_ENTRIES_SUCCESS,
  PUT_SITE_INFO_ROBOT_SUCCESS,
  PUT_FILE_SUCCESS,
  DELETE_FILE_SUCCESS,
  DELETE_PHOTO_SUCCESS,
  PUT_PHOTO_SUCCESS,
  GET_PHOTOS_SP_SUCCESS,
  SEARCH_FILE_SP_SUCCESS,
  FETCH_SITE_ROBOT_SP_SUCCESS,
  FETCH_SITE_BUILDER_SP_SUCCESS,
  FETCH_SITE_SUPPLIER_SP_SUCCESS,
  FETCH_PHOTO_TAB_SUCCESS,
  PUT_SITE_PHOTO_SP_SUCCESS,
  GET_ORIGINAL_PHOTO_SUCCESS,
  SHOW_HIDE_DIALOG_INFO_ROBOT_SUCCESS,
  SHOW_HIDE_DIALOG_STAFF_SUCCESS,
  SHOW_HIDE_DIALOG_SUPPLIER_SUCCESS,
  SHOW_HIDE_DIALOG_EDIT_INFO_SUCCESS,
  SHOW_HIDE_DIALOG_EDIT_FILE_SUCCESS,
  SHOW_HIDE_DIALOG_DELETE_FILE_SUCCESS,
  SET_FILE_VALUES_SUCCESS,
  SHOW_HIDE_DIALOG_DELETE_INFO_SUCCESS,
  SET_INFO_VALUES_SUCCESS,
  FETCH_SITE_DRAWINGS_SUCCESS,
  FETCH_SITE_RECORDS_EXISTS_SPOTSUC_IS_NULL_SUCCESS,
  FETCH_DRAWING_IMAGE_SUCCESS,
  SORT_DRAWING_IMAGE_SUCCESS,
  POST_DRAWINGS_SUCCESS,
  GET_DRAWING_WITH_SPOTS_SUCCESS,
  PUT_DRAWING_INFO_SUCCESS,
  POST_SPOT_SUCCESS,
  PUT_SPOT_SUCCESS,
  DELETE_DRAWING_SUCCESS,
  DELETE_SPOT_SUCCESS,
  SHOW_HIDE_DIALOG_PREVIEW_PDF_SUCCESS,
  SHOW_HIDE_DIALOG_MAP_SUCCESS,
  SHOW_HIDE_DIALOG_EDIT_MAP_SUCCESS,
  SHOW_HIDE_DIALOG_ADD_POINT_SUCCESS,
  SHOW_HIDE_DIALOG_EDIT_POINT_SUCCESS,
  SHOW_HIDE_DIALOG_DELETE_SUCCESS,
  SHOW_HIDE_DIALOG_MAP_FROM_LOG_WALK,
  SET_TAB_ELEMENT_SUCCESS,
  CLEAR_DRAWINGS_WITH_SPOTS,
  GET_SITE_SP_SUCCESS,
  SET_IS_COMPLETE_SITE_SUCCESS,
  SET_IS_BACK_SUCCESS,
  COPY_DRAWINGS_SUCCESS,
  COPY_DRAWINGS_WITH_SPOTS_SUCCESS,
  SET_REDIRECT_FROM_URL,
  SAVE_CONDITION_HOTSPOT_INFO_SUCCESS,
  SAVE_CONDITION_SITE_INFO_SUCCESS,
  SET_SHOW_SHARE_URL_DIALOG_EDIT
} from "../constant/constant";

const initialState = {
  siteInfo: [],
  siteInfoRobot: [],
  siteInfoStaff: [],
  siteInfoSupplier: [],
  staffCandidates: [],
  supplierCandidates: [],
  siteInfoRobotCandidate: [],
  type: "",
  error: null,
  siteDetail: "",
  photosSP: [],
  filesSP: [],
  files: [],
  isShowDialogInfoRobot: false,
  isShowDialogStaff: false,
  isShowDialogSupplier: false,
  isShowDialogEditInfo: false,
  isShowDialogDeleteInfo: false,
  infoValues: { robotName: "", location: "", assignEndDate: new Date() },
  isShowDialogEditFile: false,
  isShowDialogDeleteFile: false,
  fileValues: [],
  isShowDialogPreviewPDF: false,
  pdfFile: null,
  isShowDialogMap: false,
  drawingsUC: null,
  isShowDialogEditMap: false,
  isShowDialogAddPoint: false,
  spotData: null,
  isEdit: false,
  isShowDialogDelete: false,
  isShowDialogMapFromLogWalk: false,
  tabElement: 1,
  isCompleteSite: false,
  isBack: false,
  redirectFromUrl: {
    isRedirectFromUrl: false,
    isDialog: false,
    isCardList: true,
    currentDrawingUC: null,
    from: ""
  }, // move from dialog logwalk
  conditionHotSpotInfo: null,
  conditionSiteInfo: null,
  showShareUrlDialogEdit: null
};

export const siteInfoReducer = (originstate = initialState, action) => {
  const state = { ...originstate, type: action.type };
  switch (action.type) {
    case FETCH_SITE_INFO_SUCCESS:
      return {
        ...state,
        pending: false,
        siteInfo: action.siteInfo,
        type: action.type
      };
    case FETCH_SITE_INFO_ROBOT_CANDIDATE_SUCCESS:
      return {
        ...state,
        pending: false,
        siteInfoRobotCandidate: action.siteInfoRobotCandidate
      };
    case POST_SITE_INFO_ROBOT_CANDIDATE_SUCCESS:
      return {
        ...state,
        pending: false,
        type: action.type
      };
    case PUT_SITE_INFO_ROBOT_SUCCESS:
      return {
        ...state,
        pending: false,
        type: action.type
      };
    case FETCH_SITE_INFO_ROBOT_SUCCESS:
      return {
        ...state,
        pending: false,
        type: "",
        siteInfoRobot: action.siteInfoRobot
      };
    case FETCH_SITE_INFO_STAFF_SUCCESS:
      return {
        ...state,
        pending: false,
        siteInfoStaff: action.siteInfoStaff
      };
    case FETCH_SITE_INFO_SUPPLIER_SUCCESS:
      return {
        ...state,
        pending: false,
        siteInfoSupplier: action.siteInfoSupplier
      };
    case FETCH_STAFF_CANDIDATES_SUCCESS:
      return {
        ...state,
        pending: false,
        staffCandidates: action.staffCandidates
      };
    case FETCH_SUPPLIER_CANDIDATES_SUCCESS:
      return {
        ...state,
        pending: false,
        supplierCandidates: action.supplierCandidates
      };
    case GET_SITES_DETAIL_SUCCESS:
      return {
        ...state,
        pending: false,
        siteDetail: action.siteDetail,
        error: null
      };
    case GET_FILE_SUCCESS:
      return {
        ...state,
        pending: false,
        file: action.file,
        type: action.type
      };
    case POST_FILE_SUCCESS:
      return {
        ...state,
        type: action.type,
        pending: false,
        error: null,
        file: action.file
      };
    case SEARCH_FILE_SUCCESS:
      return {
        ...state,
        pending: false,
        files: action.files,
        type: action.type
      };
    case GET_PHOTOS_SUCCESS:
      return {
        ...state,
        pending: false,
        photos: action.photos
      };
    case GET_PHOTO_INFO_SUCCESS:
      return {
        ...state,
        pending: false,
        photoInfo: action.photoInfo,
        type: action.type
      };
    case GET_FILE_INFO_SUCCESS:
      return {
        ...state,
        pending: false,
        fileInfo: action.fileInfo,
        type: action.type
      };
    case FETCH_SITE_SUCCESS:
      return {
        ...state,
        pending: false,
        site: action.site
      };
    case FETCH_ENTRIES_SUCCESS:
      return {
        ...state,
        pending: false,
        entries: action.entries,
        type: action.type
      };
    case PUT_FILE_SUCCESS:
      return {
        ...state,
        pending: false,
        type: action.type
      };
    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        pending: false,
        type: action.type
      };
    case PUT_PHOTO_SUCCESS:
      return {
        ...state,
        pending: false
      };
    case DELETE_PHOTO_SUCCESS:
      return {
        ...state,
        type: action.type,
        pending: false
      };
    case GET_PHOTOS_SP_SUCCESS:
      return {
        ...state,
        pending: false,
        photosSP: action.photosSP
      };
    case SEARCH_FILE_SP_SUCCESS:
      return {
        ...state,
        pending: false,
        filesSP: action.filesSP,
        type: action.type
      };
    case FETCH_SITE_ROBOT_SP_SUCCESS:
      return {
        ...state,
        pending: false,
        siteRobotSp: action.robot
      };
    case FETCH_SITE_BUILDER_SP_SUCCESS:
      return {
        ...state,
        pending: false,
        siteBuilderSp: action.builder
      };
    case FETCH_SITE_SUPPLIER_SP_SUCCESS:
      return {
        ...state,
        pending: false,
        siteSupplierSp: action.supplier
      };
    case FETCH_PHOTO_TAB_SUCCESS:
      return {
        ...state,
        loadPhotoTab: action.success
      };
    case PUT_SITE_PHOTO_SP_SUCCESS:
      return {
        ...state,
        pending: false
      };
    case GET_ORIGINAL_PHOTO_SUCCESS:
      return {
        ...state,
        pending: false,
        url: action.url
      };
    case SHOW_HIDE_DIALOG_INFO_ROBOT_SUCCESS:
      return {
        ...state,
        type: action.type,
        isShowDialogInfoRobot: !state.isShowDialogInfoRobot
      };
    case SHOW_HIDE_DIALOG_STAFF_SUCCESS:
      return {
        ...state,
        type: action.type,
        isShowDialogStaff: !state.isShowDialogStaff
      };
    case SHOW_HIDE_DIALOG_SUPPLIER_SUCCESS:
      return {
        ...state,
        type: action.type,
        isShowDialogSupplier: !state.isShowDialogSupplier
      };
    case SHOW_HIDE_DIALOG_EDIT_INFO_SUCCESS:
      return {
        ...state,
        type: action.type,
        isShowDialogEditInfo: !state.isShowDialogEditInfo
      };
    case SHOW_HIDE_DIALOG_DELETE_INFO_SUCCESS:
      return {
        ...state,
        type: action.type,
        isShowDialogDeleteInfo: !state.isShowDialogDeleteInfo
      };
    case SET_INFO_VALUES_SUCCESS:
      return {
        ...state,
        type: action.type,
        infoValues: action.infoValues
      };
    case SHOW_HIDE_DIALOG_EDIT_FILE_SUCCESS:
      return {
        ...state,
        type: action.type,
        isShowDialogEditFile: !state.isShowDialogEditFile
      };
    case SHOW_HIDE_DIALOG_DELETE_FILE_SUCCESS:
      return {
        ...state,
        type: action.type,
        isShowDialogDeleteFile: !state.isShowDialogDeleteFile
      };
    case SET_FILE_VALUES_SUCCESS:
      return {
        ...state,
        type: action.type,
        fileValues: action.fileValues
      };
    case FETCH_SITE_DRAWINGS_SUCCESS:
      return {
        ...state,
        type: action.type,
        drawingsWithSpots: action.drawingsWithSpots
      };
    case FETCH_SITE_RECORDS_EXISTS_SPOTSUC_IS_NULL_SUCCESS:
      return {
        ...state,
        type: action.type,
        hasRecordOfSpotsUCIsNull: action.hasRecordOfSpotsUCIsNull
      };
    case FETCH_DRAWING_IMAGE_SUCCESS:
      return {
        ...state,
        type: action.type,
        photo: action.photo
      };
    case SORT_DRAWING_IMAGE_SUCCESS:
      return {
        ...state,
        type: action.type,
        pending: false
      };
    case POST_DRAWINGS_SUCCESS:
      return {
        ...state,
        type: action.type,
        pending: false
      };
    case GET_DRAWING_WITH_SPOTS_SUCCESS:
      return {
        ...state,
        type: action.type,
        drawingWithSpots: action.drawingWithSpots
      };
    case PUT_DRAWING_INFO_SUCCESS:
      return {
        ...state,
        type: action.type,
        pending: false
      };
    case POST_SPOT_SUCCESS:
      return {
        ...state,
        type: action.type,
        pending: false
      };
    case PUT_SPOT_SUCCESS:
      return {
        ...state,
        type: action.type,
        pending: false
      };
    case DELETE_DRAWING_SUCCESS:
      return {
        ...state,
        type: action.type,
        pending: false
      };
    case DELETE_SPOT_SUCCESS:
      return {
        ...state,
        type: action.type,
        pending: false
      };
    case SHOW_HIDE_DIALOG_PREVIEW_PDF_SUCCESS:
      return {
        ...state,
        type: action.type,
        isShowDialogPreviewPDF: !state.isShowDialogPreviewPDF,
        pdfFile: action.pdfFile
      };
    case SHOW_HIDE_DIALOG_MAP_SUCCESS:
      return {
        ...state,
        type: action.type,
        isShowDialogMap: !state.isShowDialogMap,
        drawingsUC: action.drawingsUC
      };
    case SHOW_HIDE_DIALOG_EDIT_MAP_SUCCESS:
      return {
        ...state,
        type: action.type,
        isShowDialogEditMap: !state.isShowDialogEditMap,
        nameValue: action.nameValue
      };
    case SHOW_HIDE_DIALOG_ADD_POINT_SUCCESS:
      return {
        ...state,
        type: action.type,
        isShowDialogAddPoint: !state.isShowDialogAddPoint,
        spotData: action.spotData
      };
    case SHOW_HIDE_DIALOG_EDIT_POINT_SUCCESS:
      return {
        ...state,
        type: action.type,
        isShowDialogAddPoint: !state.isShowDialogAddPoint,
        isEdit: !state.isEdit,
        spotData: action.spotData
      };
    case SHOW_HIDE_DIALOG_DELETE_SUCCESS:
      return {
        ...state,
        type: action.type,
        isShowDialogDelete: !state.isShowDialogDelete
      };
    case SHOW_HIDE_DIALOG_MAP_FROM_LOG_WALK:
      return {
        ...state,
        type: action.type,
        isShowDialogMapFromLogWalk: !state.isShowDialogMapFromLogWalk
      };
    case SET_TAB_ELEMENT_SUCCESS:
      return {
        ...state,
        type: action.type,
        tabElement: action.tabElement
      };
    case CLEAR_DRAWINGS_WITH_SPOTS:
      // Add clear dialog
      return {
        ...state,
        type: action.type,
        drawingsWithSpots: [],
        drawingWithSpots: null,
        isShowDialogAddPoint: false,
        isShowDialogEditMap: false,
        isShowDialogDelete: false,
        isShowDialogConfirmDelete: false,
        isShowDialogPreviewPDF: false,
        isShowCopyDrawingDialog: false,
        isShowDialogCopy: false,
        showConfirmCreateLink: false,
        showConfirmDeleteLink: false,
        isShowAddDrawingDialog: false
      };
    case GET_SITE_SP_SUCCESS:
      return {
        ...state,
        type: action.type,
        siteSP: action.siteSP
      };
    case SET_IS_COMPLETE_SITE_SUCCESS:
      return {
        ...state,
        type: action.type,
        isCompleteSite: action.isCompleteSite
      };
    case SET_IS_BACK_SUCCESS:
      return {
        ...state,
        type: action.type,
        isBack: action.isBack
      };
    case COPY_DRAWINGS_SUCCESS:
      return {
        ...state,
        type: action.type,
        pending: false
      };
    case COPY_DRAWINGS_WITH_SPOTS_SUCCESS:
      return {
        ...state,
        type: action.type,
        pending: false
      };
    case SET_REDIRECT_FROM_URL:
      return {
        ...state,
        redirectFromUrl: action.data
      };
    case SAVE_CONDITION_HOTSPOT_INFO_SUCCESS:
      return {
        ...state,
        type: action.type,
        conditionHotSpotInfo: action.data
      };
    case SAVE_CONDITION_SITE_INFO_SUCCESS:
      return {
        ...state,
        type: action.type,
        conditionSiteInfo: action.data
      };
    case SET_SHOW_SHARE_URL_DIALOG_EDIT:
      return {
        ...state,
        showShareUrlDialogEdit: action.data
      };
    default:
      return state;
  }
};

export const getSiteInfo = state => state;
export const getSiteInfoRobot = state => state;
export const getSiteInfoStaff = state => state;
export const getSiteInfoSupplier = state => state;
export const getStaffCandidates = state => state;
export const getSupplierCandidates = state => state;
export const getSiteInfoRobotCandidates = state => state;
export const getFiles = state => state;
export const getPhotos = state => state;
export const getDrawingsWithSpots = state => state;
export const getHasRecordOfSpotsUCIsNull = state => state;
export const getSitesDetail = state => state;
export const getSiteError = state => state;
export const getEntries = state => state;
export const getSite = state => state;
export const getType = state => state;
export const getError = state => state;
export const getPhoto = state => state;
export const getRedirectFromUrl = state => state;
