import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import InputControl from "../../common/InputControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Formik, Field } from "formik";
import { forgotPasswordSP } from "./service/service";
import { forgotPasswordSPSuccess } from "./action/action";
import { dispatchError } from "../../common/Stores/service/service";
import "../../common/css/base.css";
import "../../common/css/auth.css";

class ForgotPasswordSP extends Component {
  componentDidMount() {
    this.props.forgotPasswordSPSuccess(false);
    this.props.dispatchError(null);
  }

  checktButtonClick = formik => e => {
    e.preventDefault();
    formik.handleSubmit();
  };

  onSubmit = id => {
    this.props.forgotPasswordSP(id);
  };

  render() {
    const { error, emailConfirmSP } = this.props;
    const values = {
      id: ""
    };

    return (
      <Formik
        initialValues={values}
        enableReinitialize
        onSubmit={values => this.onSubmit(values)}
        render={formik => (
          <form onSubmit={this.checktButtonClick(formik)}>
            <Grid className="area-login-wrap">
              <Grid container justify="center">
                <Grid className="area-logo-sp" />
                <Grid className="area-login-sp">
                  <Grid>
                    <Typography
                      align="center"
                      variant="h4"
                      className="title-name mb-10"
                    >
                      パスワードのリセット
                    </Typography>
                    {emailConfirmSP ? (
                      <Typography
                        variant="subtitle2"
                        align="center"
                        className="txt-login-sp-sub mb-10 font-family"
                      >
                        パスワード再設定のためのメールを送信しました。​
                        <br />
                        メールを確認して下さい。
                      </Typography>
                    ) : (
                      <Typography
                        variant="subtitle2"
                        align="center"
                        className="txt-login-sp-sub mb-10 font-family"
                      >
                        メールアドレスを入力して下さい。
                        <br />
                        パスワード再設定のためのメールを送信します。
                      </Typography>
                    )}
                  </Grid>
                  <Grid>
                    {!emailConfirmSP && (
                      <Grid>
                        <Field
                          label="メールアドレス​"
                          name="id"
                          type="text"
                          onChange={formik.handleChange}
                          variant="filled"
                          className="input-login input-login-sp"
                          component={InputControl}
                        />
                        <span className="required">
                          {error && error.id && (
                            <span className="error">{error.id}</span>
                          )}
                        </span>
                      </Grid>
                    )}
                    <Grid container justify="center">
                      {!emailConfirmSP ? (
                        <button type="submit" className="btn-login">
                          メールを送信​
                        </button>
                      ) : (
                        <button type="button" className="btn-login btn-link">
                          <Link
                            to="/sp/login"
                            className="white-text decorator-none"
                          >
                            ログイン画面に戻る​
                          </Link>
                        </button>
                      )}
                      {!emailConfirmSP && (
                        <Link
                          to="/sp/login"
                          className="txt-forget-passowrd mt-20"
                        >
                          ログイン画面に戻る
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}

ForgotPasswordSP.propTypes = {};

const mapStateToProps = state => ({
  error: state.storesReducer.error,
  type: state.auth.type,
  emailConfirmSP: state.auth.emailConfirmSP
});

export default connect(mapStateToProps, {
  forgotPasswordSP,
  forgotPasswordSPSuccess,
  dispatchError
})(ForgotPasswordSP);
