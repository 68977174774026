import React, { Component } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Fab from "@material-ui/core/Fab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import RobotInfoBuilderPC from "./RobotInfo/RobotInfoBuilderPC";
import RobotHistoryPC from "./RobotHistory/RobotHistoryPC";
import "../../../common/css/base.css";
import "../../../common/css/log.css";
import RobotStatus from "common/RobotStatus";
import { BUTTON_LOGKUN, ICON_LOGKUN } from "common/constants/constants";

class RobotDetail extends Component {
  render() {
    const { robotsUpdate } = this.props;
    return (
      <Grid container wrap="nowrap" className="log page-content">
        <Grid className="log-list-wrap">
          <List disablePadding className="log-list">
            {this.props.robotEntries &&
              this.props.robotEntries.map(entries => (
                <Link
                  to={`/robots/${entries.uniqueCode}`}
                  key={entries.uniqueCode}
                  className="reset-link"
                >
                  <ListItem
                    selected={
                      entries.uniqueCode === this.props.robotDetails.uniqueCode
                    }
                    button
                    className="image-list-item"
                  >
                    <ListItemText
                      className="clip-text"
                      primary={entries.name}
                    />
                  </ListItem>
                </Link>
              ))}
          </List>
          <Link to="/robots" className="reset-link">
            <Fab variant="extended" className="back-btn">
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                className="icon-margin"
              />
              一覧に戻る
            </Fab>
          </Link>
        </Grid>
        <Grid className="log-content">
          <Grid container wrap="nowrap" justify="space-between">
            <Grid className="log-title">
              <Typography variant="h4" className="title-name">
                {this.props.robotDetails.robotName}
              </Typography>
              <RobotStatus
                typeOfRobotStatus={ICON_LOGKUN}
                robotsUpdate={[robotsUpdate]}
                robots={[this.props.robotDetails]}
              />
            </Grid>
            <RobotStatus
              typeOfRobotStatus={BUTTON_LOGKUN}
              robotsUpdate={[robotsUpdate]}
              robots={[this.props.robotDetails]}
              forceCreateUserAcknowledgement={true}
            />
          </Grid>
          <Grid className="sites-tab">
            <Tabs
              className="tabs"
              value={this.props.tabValue}
              onChange={this.props.tabChange}
              aria-label="simple tabs example"
            >
              <Tab
                className="tab-button"
                label="ロボット情報"
                value="ロボット情報"
              />
              <Tab
                className="tab-button"
                label="現場割当履歴"
                value="現場割当履歴"
              />
            </Tabs>
            {this.props.tabValue === "ロボット情報" ? (
              <RobotInfoBuilderPC
                robotInfo={this.props.robotInfo}
                showEditForm={this.props.showEditForm}
                showDialogContactRepair={this.props.showDialogContactRepair}
                toggleRequestDialog={this.props.toggleRequestDialog}
              />
            ) : (
              <RobotHistoryPC robotHistory={this.props.robotHistory} />
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default RobotDetail;
