import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import PaginationControl from "../../common/Pagination";
import "../../common/css/base.css";
import "../../common/css/sites.css";
import SiteList from "../../common/SiteList/SiteList";
import SiteCardList from "../../common/SiteList/SiteCardList";
import ListIcon from "@material-ui/icons/List";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ReorderIcon from "@material-ui/icons/Reorder";
import { FETCH_SITES_SUCCESS } from "./constant/constant";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SearchIcon from "@material-ui/icons/Search";
import { changeTypeListSites } from "./service/service";

class IndexBuilderPC extends Component {
  inputTimeOut = null;

  constructor() {
    super();
    this.state = {
      isCompleteSite: false,
      siteData: null,
      searching: false
    };
  }

  componentWillMount() {
    this.setState({
      siteData: this.props.sites
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isCardList !== nextProps.isCardList) {
      setTimeout(
        this.changeWidthContainer(
          nextProps.isCompleteSite || !this.props.isCardList
        ),
        20
      );
    }

    if (nextProps.type === FETCH_SITES_SUCCESS) {
      this.setState({ siteData: nextProps.sites });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", () =>
      this.changeWidthContainer(
        this.props.isCompleteSite || !this.props.isCardList
      )
    );

    if(this.props.isCompleteSite === true) {
      document.getElementById("title-page-builder").innerHTML = "完了現場一覧"
    } else {
      document.getElementById("title-page-builder").innerHTML = "全ての現場"
    }
  }

  componentWillUnmount() {
    this.changeWidthContainer(false);
    window.removeEventListener("resize", () =>
      this.changeWidthContainer(
        this.props.isCompleteSite || !this.props.isCardList
      )
    );
  }

  changeWidthContainer = isTable => {
    const headerEl = document.getElementById("header-builder");
    const siteContentEl = document.getElementById("site-content");
    if (headerEl && siteContentEl) {
      if (isTable) {
        const el = document.getElementsByClassName("sites-table")[0];
        headerEl.style.minWidth =
          (el ? el.offsetWidth + el.offsetLeft : 0) + "px";

        siteContentEl.style.minWidth =
          (el ? el.offsetWidth + el.offsetLeft : 0) + "px";
      } else {
        headerEl.style.minWidth = null;
        siteContentEl.style.minWidth = null;
      }
    }
  };

  changeTypeList = () => {
    this.props.changeTypeListSites(!this.props.isCardList);
  };

  changeSiteList = () => {
    this.props.fetchSiteList();
  };

  searchInput = e => {
    const value = e.target.value.trim();
    this.setState({ searching: true }, () => {
      if (this.inputTimeOut) {
        clearTimeout(this.inputTimeOut);
      }

      this.inputTimeOut = setTimeout(() => {
        const { sites } = this.props;
        let siteData = sites.filter(item => item.name.includes(value));

        this.setState({ siteData, searching: false });
      }, 400);
    });
  };

  render() {
    const { siteData, searching } = this.state;
    const {
      sites,
      onChangeData,
      showDialog,
      robotsUpdate,
      isCompleteSite,
      isCardList
    } = this.props;

    const showDialogRobots = robots => {
      this.props.showDialogRobots(robots);
    };
    return (
      <Grid container className="sites-page page-content">
        <Grid className="sites-right">
          <Grid
            container
            justify="space-between"
            id="site-content"
            className="padding-content"
          >
            <Button
              onClick={showDialog}
              size="small"
              variant="text"
              className="button-add"
            >
              <AddIcon className="icon-margin" />
              現場登録
            </Button>
            <Button
              onClick={this.changeSiteList}
              size="small"
              variant="text"
              className="button-add button-change-site"
            >
              <ReorderIcon className="icon-margin" />
              {isCompleteSite ? "現場一覧へ戻る" : "完了現場一覧"}
            </Button>
          </Grid>
          <Grid className="dashboard py-0">
            {!isCompleteSite && (
              <Grid container alignItems="center">
                <Fab
                  className={`icon-transparent ml-10`}
                  size="large"
                  disabled={isCompleteSite}
                  onClick={this.changeTypeList}
                >
                  {isCardList ? (
                    <ListIcon className="icon" />
                  ) : (
                    <ViewModuleIcon className="icon" />
                  )}
                </Fab>
                <Grid className="search-input ml-10">
                  <SearchIcon
                    className="icon"
                    style={{ fontSize: 24, color: "#bebebe" }}
                  />
                  <input
                    type="text"
                    className="browser-default"
                    placeholder="現場名で検索"
                    onChange={this.searchInput}
                  ></input>
                </Grid>
              </Grid>
            )}
            {!searching && (
              <>
                {!isCompleteSite && isCardList ? (
                  <SiteCardList
                    siteData={siteData}
                    to={this.props.to}
                    showDialog={this.props.showDialog}
                    showDialogDrawing={this.props.showDialogDrawing}
                    robotsUpdate={robotsUpdate}
                  />
                ) : (
                  <SiteList
                    siteData={siteData}
                    to={this.props.to}
                    showDialog={this.props.showDialog}
                    showDialogDrawing={this.props.showDialogDrawing}
                    robotsUpdate={robotsUpdate}
                    isCompleteSite={isCompleteSite}
                  />
                )}
              </>
            )}
          </Grid>
          <Grid className="padding-content">
            <Button
              size="small"
              onClick={showDialog}
              variant="text"
              className="button-add"
            >
              <AddIcon className="icon-margin" />
              現場登録
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

IndexBuilderPC.propTypes = {
  sites: PropTypes.array.isRequired,
  showDialog: PropTypes.func.isRequired,
  to: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  type: state.rootSitesReducer.siteInfoReducer.type,
  isCardList: state.rootSitesReducer.sitesReducer.isCardList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeTypeListSites
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(IndexBuilderPC);
