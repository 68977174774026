import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchField from "./SearchField";
import SelectField from "../infoHotspots/SelectField";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import { isTablet, isMobile } from "react-device-detect";
import { format } from "date-fns";
import { enableLoading, finishLoading } from "common/Stores/service/service";
import { api } from "../../common/api/api";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

const mediaQueryTabletOrMobile = "(max-width: 960px)";

const useStyles = makeStyles({
  tableRowHover: {
    backgroundColor: "#F8F9F9",
    "&:hover": {
      backgroundColor: "#d6d6d6"
    }
  },
  truncateText3: {
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "wrap",
    wordBreak: "break-word"
  }
});

function ListSiteFixSpots(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [allSiteList, setAllSiteList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [siteListToShow, setSiteListToShow] = useState([]);
  const [searchIsCompleted, setSearchIsCompleted] = useState(true);
  const [searchSiteName, setSearchSiteName] = useState("");

  const classes = useStyles();
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: mediaQueryTabletOrMobile });

  useEffect(() => {
    fetchAllSiteList();
  }, []);

  useEffect(() => {
    setSiteListToShow(filter());
  }, [allSiteList, searchIsCompleted, searchSiteName]);

  const filter = () => {
    let arrTemp = allSiteList;
    if (searchSiteName !== "") {
      arrTemp = arrTemp.filter(item =>
        item.name.includes(searchSiteName.trim())
      );
    }
    arrTemp = arrTemp.filter(item => item.completed === searchIsCompleted);
    return arrTemp;
  };

  const fetchAllSiteList = () => {
    dispatch(enableLoading());
    api
      .get(`/builder/fix-spots/sites`)
      .then(res => {
        setAllSiteList(res.data);
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(finishLoading());
        setIsLoading(false);
        if (error.response.status === 403) {
          props.history.push("/not-found");
        };
      });
  };
  return (
    <div style={{ padding: "20px 38px" }}>
      {isLoading ? (
        <div>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <React.Fragment>
          <Grid container spacing={2} style={{ marginBottom: "26px" }}>
            <Grid item style={{ minWidth: "150px", maxWidth: "400px" }}>
              <SearchField
                placeholder="現場名で検索"
                setValue={setSearchSiteName}
                value={searchSiteName}
              />
            </Grid>
            <Grid item style={{ minWidth: "150px" }}>
              <SelectField
                label="作成者での検索"
                value={searchIsCompleted}
                options={[
                  { value: false, label: <span>未完了</span> },
                  { value: true, label: <span>完了</span> }
                ]}
                selectValue={setSearchIsCompleted}
              />
            </Grid>
          </Grid>

          <div>
            <TableContainer className="table-remove-overflow">
              <Table
                size="small"
                stickyHeader={true}
                aria-label="sticky table"
                style={{ marginBottom: isTabletOrMobile ? "20px" : "0px" }}
              >
                <TableBody className="table-body">
                <TableRow style={{ backgroundColor: "#404040" }}>
                    <TableCell
                      className="white-text text-center"
                      style={{
                        top: isMobile || isTablet ? "63px" : "79px",
                        width: "200px",
                        borderRadius: "0px",
                        padding: "6px 10px",
                        minWidth: "76px"
                      }}
                    >
                      現場名
                    </TableCell>

                    <TableCell
                      className="white-text text-center"
                      style={{
                        top: isMobile || isTablet ? "63px" : "79px",
                        width: "200px",
                        borderRadius: "0px",
                        padding: "6px 10px",
                        minWidth: "76px"
                      }}
                    >
                      最終更新日
                    </TableCell>

                    <TableCell
                      className="white-text text-center"
                      style={{
                        top: isMobile || isTablet ? "63px" : "79px",
                        width: "160px",
                        borderRadius: "0px",
                        padding: "6px 10px"
                      }}
                    >
                      修復済み撮影履歴数
                    </TableCell>
                  </TableRow>
                  {siteListToShow.length > 0 &&
                    siteListToShow.map(site => (
                      <TableRow
                        key={site.uniqueCode}
                        className={classes.tableRowHover}
                        onClick={() => {
                          history.push("/fix-spots/sites/" + site.uniqueCode);
                        }}
                      >
                        <TableCell
                          className="text-center"
                          style={{
                            padding: "10px"
                          }}
                        >
                          <p className={classes.truncateText3}>{site.name}</p>
                        </TableCell>

                        <TableCell
                          className="text-center"
                          style={{
                            padding: "10px"
                          }}
                        >
                          <p>
                            {format(new Date(site.updatedAt), "yyyy年MM月dd日")}
                          </p>
                        </TableCell>

                        <TableCell
                          className="text-center"
                          style={{
                            padding: "10px"
                          }}
                        >
                          {site.numOfFixedHistories > 0 && (
                            <p style={{ color: "red" }}>
                              {site.numOfFixedHistories}件
                            </p>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default ListSiteFixSpots;
