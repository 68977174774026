import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import shareUrlIcon from "../../../../common/img/share-url.png";
import CloseIcon from "@material-ui/icons/Close";
import copy from "copy-to-clipboard";
import { useHistory, useLocation } from "react-router-dom";
import { api } from "../../../../common/api/api";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setShowShareUrlDialogEdit } from "../action/action";
import { updateIsShareUrl } from "../action/action";

const mediaQueryMobile = "(max-width: 640px)";

const useStyles = makeStyles({
  dialogTitle: {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px"
  },

  createShareUrlForm: {
    display: "flex"
  },

  createShareUrlFormInput: {
    width: "50%",
    padding: "5px 10px",
    "& label": {
      fontSize: "14px"
    },
    marginBottom: "8px"
  },

  editButtonAction: {
    fontSize: "16px",
    color: "white",
    padding: "8px 12px",
    borderRadius: "6px",
    marginRight: "14px",
    cursor: "pointer"
  },
  truncateText: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  textUnderline: {
    "&:hover": {
      textDecoration: "underline"
    }
  }
});

var showCopiedMessageTimeOut = null;

function ShareUrlDialog(props) {
  const { shootsUC, open, handleClose, currentSpotName, currentSiteName } =
    props;

  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [shareUrl, setShareUrl] = useState(null);

  const [siteName, setSiteName] = useState("");
  const [spotName, setSpotName] = useState("");
  const [isHideName, setIsHideName] = useState(false);

  const [isCreateNew, setIsCreateNew] = useState(false);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMediaQuery({ query: mediaQueryMobile });
  const dispatch = useDispatch();
  const logWalkInfo = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.logWalkInfo
  );


  useEffect(() => {
    if (shootsUC) {
      fetchShareUrl(shootsUC);
    }
  }, [shootsUC]);

  useEffect(() => {
    if (showCopiedMessage) {
      if(showCopiedMessageTimeOut){
        clearTimeout(showCopiedMessageTimeOut);
      }

      showCopiedMessageTimeOut = setTimeout(() => {
        setShowCopiedMessage(false)
      }, 2000)
    }
  }, [showCopiedMessage]);

  const fetchShareUrl = id => {
    setIsLoading(true);
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logwalk-viewer/shoots/${id}/url-share`)
      .then(res => {
        if (res.data) {
          setShareUrl(res.data);
          setIsCreateNew(false);
        } else {
          setSiteName(currentSiteName);
          setSpotName(currentSpotName);
          setIsHideName(false);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
      });
  };

  const createShareUrl = data => {
    setIsCreating(true);
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .post(
        `/${classification}/logwalk-viewer/shoots/${shootsUC}/url-share`,
        data
      )
      .then(res => {
        setShareUrl(res.data);
        dispatch(updateIsShareUrl(true));
        setIsCreating(false);
        setIsCreateNew(true);
      })
      .catch(err => {
        setIsCreating(false);
      });
  };

  const checkShowCreateInfo = () => {
    setIsHideName(!isHideName);
  };

  const enableCreate = () => {
    return siteName !== "" && spotName !== "";
  };

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          padding: "32px 16px",
          width: "calc(100% - 32px)",
          margin: "16px",
          boxShadow: "none",
          backgroundColor: "rgba(0,0,0,0)"
        }
      }}
    >
      <React.Fragment>
        {isLoading || isCreating ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 0px"
            }}
          >
            <CircularProgress size={40} />
          </div>
        ) : (
          <React.Fragment>
            {
              showCopiedMessage && (
                <span
                style={{
                    position: "absolute",
                    top: "0px",
                    left: "50%",
                    height: "32px",
                    backgroundColor: "#000000",
                    color: "white",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    transform: "translate(-50%)",
                    padding: "0px 12px",
                    whiteSpace: "nowrap"
                  }}
                >
                  URLをコピーしました
                </span>
              )
            }

            <div
              style={{
                padding: "20px",
                borderRadius: "12px",
                backgroundColor: "#ffffff"
              }}
            >
              {shareUrl ? (
                <React.Fragment>
                  <div className={classes.dialogTitle}>
                    <img
                      src={shareUrlIcon}
                      width={28}
                      height={28}
                      alt="shareUrlIcon"
                      style={{ marginRight: "10px" }}
                    />
                    <span style={{ fontSize: isMobile ? "20px" : "24px" }}>
                      {
                        isCreateNew ? "URLを発行しました" : "URLは発行済です"
                      }
                    </span>
                  </div>

                  <div style={{ display: "flex", marginBottom: "12px" }}>
                    <div
                      style={{
                        flexGrow: "1",
                        marginRight: "20px",
                        borderRadius: "4px",
                        backgroundColor: "#D9D9D9",
                        padding: "4px 12px",
                        fontSize: "16px",
                        whiteSpace: "nowrap"
                      }}
                      className={classes.truncateText}
                    >
                      {`${window.location.origin}/url-share/${shareUrl.BuildersUC}/${shareUrl.Id}`}
                    </div>
                    <button
                      style={{
                        padding: "0px 8px",
                        color: "#2ABDF2",
                        fontSize: "18px",
                        cursor: "pointer",
                        flexShrink: "0",
                        whiteSpace: "nowrap"
                      }}
                      className={classes.textUnderline}
                      onClick={() => {
                        copy(
                          `${window.location.origin}/url-share/${shareUrl.BuildersUC}/${shareUrl.Id}`
                        );
                        setShowCopiedMessage(true);
                      }}
                    >
                      URLをコピー
                    </button>
                  </div>
                  
                  <div 
                    style={{
                      display: "flex", 
                      flexWrap: "wrap",
                      paddingLeft: "18px",
                      fontSize: "15px",
                      marginBottom: "12px",
                      color: "#A0A0A0"                      
                    }}
                  >
                    <span style={{marginRight: "8px"}}>
                      URLを知っている全員がアクセスでき、ログインは不要です
                    </span>    
                    <span
                      style={{
                        color: "#2ABDF2",
                        cursor: "pointer"
                      }}
                      className={classes.textUnderline}
                      onClick={() => {
                        window.open(`/url-share/${shareUrl.BuildersUC}/${shareUrl.Id}`, "_blank");
                      }}
                    >
                      URLを確認する
                    </span>                
                  </div>
                  
                  <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                    <span style={{ paddingLeft: "4px", fontSize: "18px", marginRight: "10px" }}>
                      編集,共有停止はURL共有リストより行えます{"("}
                      <span 
                        style={{ color: "#2ABDF2", cursor: "pointer" }} 
                        className={classes.textUnderline}
                        onClick={() => {
                          dispatch(setShowShareUrlDialogEdit(shareUrl.Id));
                          history.push(`/sites/${logWalkInfo?.sitesUC}?tab=5`);
                        }}
                      >
                        URL共有一覧
                      </span>
                      {")"}
                    </span>
                    <button
                      style={{
                        marginLeft: "auto",
                        marginRight: "10px",
                        padding: "8px 12px",
                        fontSize: "16px",
                        borderRadius: "6px",
                        backgroundColor: "#d0cece",
                        color: "white"
                      }}
                      onClick={() => handleClose()}
                    >閉じる</button>                 
                  </div>

                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className={classes.dialogTitle}>
                    <span>共有時の現場名、地点名を設定</span>
                  </div>

                  <div className={classes.createShareUrlForm}>
                    <div className={classes.createShareUrlFormInput}>
                      <span>現場名</span>
                      <input
                        type="text"
                        style={{
                          backgroundColor: "#F2F2F2",
                          margin: "0px",
                          border: "none",
                          borderRadius: "4px",
                          boxShadow: "none",
                          padding: "0px 12px",
                          fontSize: "20px",
                          width: "100%",
                          boxSizing: "border-box"
                        }}
                        value={siteName}
                        onChange={event => setSiteName(event.target.value)}
                      />
                    </div>

                    <div className={classes.createShareUrlFormInput}>
                      <span>地点名</span>
                      <input
                        type="text"
                        style={{
                          backgroundColor: "#F2F2F2",
                          margin: "0px",
                          border: "none",
                          borderRadius: "4px",
                          boxShadow: "none",
                          padding: "0px 12px",
                          fontSize: "20px",
                          width: "100%",
                          boxSizing: "border-box"
                        }}
                        value={spotName}
                        onChange={event => setSpotName(event.target.value)}
                      />
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>
                      <Checkbox
                        checked={isHideName}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                        onClick={checkShowCreateInfo}
                      />
                    </span>
                    <span 
                      onClick={() => setIsHideName(!isHideName)}
                      style={{cursor: "pointer", userSelect: "none"}}
                    >現場名と地点名を非表示にする</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center"
                    }}
                  >
                    <button
                      className={classes.editButtonAction}
                      style={{ backgroundColor: "#D0CECE" }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      キャンセル
                    </button>
                    <button
                      className={classes.editButtonAction}
                      style={{
                        backgroundColor: enableCreate() ? "#002060" : "#D0CECE"
                      }}
                      onClick={() => {
                        if (enableCreate()) {
                          const data = {
                            SiteName: siteName,
                            SpotName: spotName,
                            IsHideName: isHideName
                          };
                          createShareUrl(data);
                        }
                      }}
                    >
                      共有URLを発行する
                    </button>
                  </div>
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    </Dialog>
  );
}

export default ShareUrlDialog;
