import React from "react";
import { withRouter } from "react-router-dom";
import { format } from "date-fns";
import marzipano from "marzipano";
import Component from "../../Component";
import { ja } from "date-fns/locale";
import { api } from "../../../common/api/api";
import axios from "axios";
import { arrayBufferToBase64 } from "../../../utils/mapper";

class FixSpots360ImgBox extends Component {
  source = null;
  ajaxRequest = axios.CancelToken.source();
  blobExtention = null;
  pano = null;
  geoType = null;
  sasTimeout = null;

  constructor(props) {
    super(props);
    this.state = {
      viewer: undefined,
      panorama: undefined,
      loadPhotoDone: false,
      hasPhoto: "",
      isRefreshSas: false,
      reloadBlobDataLst403: false,
      blobDataLogWalk: "",
      photoNotUploaded: false
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    document.getElementsByTagName("html")[0].style.overflow = "hidden";

    this.loadInit();

    document.addEventListener("gesturestart", e => {
      e.preventDefault();
    });
  }

  loadInit = () => {
    const { showShoot } = this.props;

    if (showShoot) {
      if (showShoot.blob || showShoot.image) {
        this.setState({ photoNotUploaded: false });
        let apiUrl = "";
        if (showShoot.blob) {
          apiUrl = showShoot.blob.replace(`/api`, "");
        }

        if (showShoot.image) {
          apiUrl = showShoot.image.replace(`/api`, "");
        }

        let isBlob = true;
        if (apiUrl.includes("/file?bin")) {
          isBlob = false;
        }

        api
          .get(apiUrl, isBlob ? {} : { responseType: "arraybuffer" })
          .then(response => {
            if (response.data) {
              if (isBlob) {
                this.setState({
                  blobDataLogWalk: {
                    ...response.data,
                    blob: apiUrl
                  }
                });
              } else {
                this.setState({
                  blobDataLogWalk: {
                    blobDataLst: [
                      `data:image/jpeg;base64,${arrayBufferToBase64(
                        response.data
                      )}`
                    ],
                    geoType: "EQR",
                    refreshSasEstimatedTime: null,
                    blob: apiUrl
                  }
                });
              }
            }
          });
      } else {
        this.setState({ photoNotUploaded: true });
        this.setPanorama();
      }
    } else {
      this.setState({ photoNotUploaded: false });
      this.setPanorama();
    }
  };

  sasExpiredEvtListner() {
    this.pano.addEventListener("sasExpired", () => {
      if (!this.state.reloadBlobDataLst403) {
        this.setState({ reloadBlobDataLst403: true });

        let isBlob = true;
        if (this.state.blobDataLogWalk.blob.includes("/file?bin")) {
          isBlob = false;
        }

        api
          .get(
            this.state.blobDataLogWalk.blob,
            isBlob ? {} : { responseType: "arraybuffer" }
          )
          .then(res => {
            if (res.data) {
              let tmpBlobDataLst;

              if (isBlob) {
                tmpBlobDataLst = res.data.blobDataLst;
              } else {
                tmpBlobDataLst = [
                  `data:image/jpeg;base64,${arrayBufferToBase64(res.data)}`
                ];
              }

              this.blobExtention.updateBlobDataLst(tmpBlobDataLst);
              this.setState({ reloadBlobDataLst403: false });
            }
          })
          .catch(() => this.setState({ reloadBlobDataLst403: false }));
      }
    });
  }

  initTileAzBlogStorage(blobDataLogWalk) {
    this.geoType = "Tile";
    this.pano = this.inputRef.current;
    this.sasExpiredEvtListner();
    var viewerOpts = {
      controls: {
        mouseViewMode: "drag"
      }
    };
    this.blobExtention = new marzipano.AzBlobExtension(
      blobDataLogWalk.blobDataLst,
      this.pano
    );

    // Remove scene before create new
    if (this.state.viewer) {
      this.pano.removeChild(this.pano.childNodes[0]);
      this.pano.removeChild(this.pano.childNodes[0]);
    }

    var viewer = new marzipano.Viewer(
      this.pano,
      viewerOpts,
      this.blobExtention
    );

    var urlPrefix = "";
    var source = marzipano.ImageUrlSource.fromString(
      urlPrefix + "/{z}/{f}/{y}/{x}.jpg",
      { cubeMapPreviewUrl: urlPrefix + "/preview.jpg" }
    );
    var LEVELS = [
      {
        tileSize: 256,
        size: 256,
        fallbackOnly: true
      },
      {
        tileSize: 512,
        size: 512
      },
      {
        tileSize: 512,
        size: 1024
      },
      {
        tileSize: 512,
        size: 2048
      }
    ];
    var geometry = new marzipano.CubeGeometry(LEVELS);

    this.setState({ viewer }, () => {
      this.switchPhoto(source, geometry, blobDataLogWalk);
    });
  }

  initEQRPano(blobDataLogWalk) {
    this.geoType = "EQR";
    this.pano = this.inputRef.current;
    this.sasExpiredEvtListner();
    var viewerOpts = {
      controls: {
        mouseViewMode: "drag"
      },
      stage: { preserveDrawingBuffer: true }
    };
    this.blobExtention = new marzipano.AzBlobExtension(
      blobDataLogWalk
        ? blobDataLogWalk.blobDataLst
        : [
            "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="
          ],
      this.pano
    );

    // Remove scene before create new
    if (this.state.viewer) {
      this.pano.removeChild(this.pano.childNodes[0]);
      this.pano.removeChild(this.pano.childNodes[0]);
    }

    var viewer = new marzipano.Viewer(
      this.pano,
      viewerOpts,
      this.blobExtention
    );

    const eqrBlobSearchKey = "/eqr";
    var source = marzipano.ImageUrlSource.fromString(eqrBlobSearchKey);
    var geometry = new marzipano.EquirectGeometry([{ width: 4000 }]);

    this.setState({ viewer }, () => {
      this.switchPhoto(source, geometry, blobDataLogWalk);
    });
  }

  setPanorama = blobDataLogWalk => {
    if (blobDataLogWalk) {
      this.setState({ loadPhotoDone: false });

      if (blobDataLogWalk.geoType === "Tile") {
        this.initTileAzBlogStorage(blobDataLogWalk);
      } else {
        this.initEQRPano(blobDataLogWalk);
      }
    } else {
      this.initEQRPano();
      this.setState({ hasPhoto: false });
    }
  };

  switchPhoto = (source, geometry, hasPhoto) => {
    const { viewer } = this.state;

    // Create view.
    var limiter = marzipano.util.compose(
      marzipano.RectilinearView.limit.vfov(
        (Math.PI * 10) / 180,
        (Math.PI * 120) / 180
      ),
      marzipano.RectilinearView.limit.hfov(
        (Math.PI * 10) / 180,
        (Math.PI * 120) / 180
      ),
      marzipano.RectilinearView.limit.pitch(-Math.PI / 2, Math.PI / 2)
    );
    var view = new marzipano.RectilinearView({ yaw: 0 }, limiter);

    var scene = viewer.createScene({
      source: source,
      geometry: geometry,
      view: view
    });

    // Display scene.
    scene.switchTo({}, () => {
      this.setState({
        loadPhotoDone: true,
        hasPhoto: hasPhoto
      });
    });

    this.setState({ viewer: viewer });

    let destinationViewParameters = {
      fov: "2.09439333333333",
      hfov: "2.09439333333333"
    };
    const currentShootDirection = this.props.showShoot?.direction;
    const compassSetting = this.props.showShoot?.compassSetting;
    const yaw = this.props.showShoot?.yaw;
    if (typeof currentShootDirection === "number") {
      if (typeof compassSetting === "number") {
        destinationViewParameters["yaw"] =
          -((currentShootDirection + compassSetting) / 180) * Math.PI +
          (yaw || 0);
        destinationViewParameters["pitch"] = (5 / 180) * Math.PI;
      }
    }

    var options = {
      transitionDuration: 100
    };
    scene.lookTo(destinationViewParameters, options);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (prevProps.showShoot &&
        prevProps.showShoot.uniqueCode !== this.props.showShoot.uniqueCode) ||
      (!prevProps.showShoot && prevProps.showShoot !== this.props.showShoot)
    ) {
      this.loadInit();
    }

    const { blobDataLogWalk, isRefreshSas } = this.state;

    if (
      (blobDataLogWalk !== prevState.blobDataLogWalk ||
        (blobDataLogWalk &&
          blobDataLogWalk.blob !== prevState.blobDataLogWalk?.blob)) &&
      !isRefreshSas
    ) {
      this.setPanorama(blobDataLogWalk);
    }

    if (
      prevProps.showShoot &&
      prevProps.showShoot.compassSetting !== this.props.showShoot.compassSetting
    ) {
      const scene = this.state.viewer.scene();
      let destinationViewParameters = {
        fov: "2.09439333333333",
        hfov: "2.09439333333333"
      };
      const currentShootDirection = this.props.showShoot?.direction;
      const compassSetting = this.props.showShoot?.compassSetting;
      const yaw = this.props.showShoot?.yaw;
      if (typeof currentShootDirection === "number") {
        if (typeof compassSetting === "number") {
          destinationViewParameters["yaw"] =
            -((currentShootDirection + compassSetting) / 180) * Math.PI +
            (yaw || 0);
          destinationViewParameters["pitch"] = (5 / 180) * Math.PI;
        }
      }

      var options = {
        transitionDuration: 100
      };
      scene.lookTo(destinationViewParameters, options);
    }

    if (
      this.state.blobDataLogWalk &&
      this.state.blobDataLogWalk.refreshSasEstimatedTime !==
        prevState.blobDataLogWalk?.refreshSasEstimatedTime
    ) {
      if (blobDataLogWalk.isRefresh) {
        this.blobExtention.updateBlobDataLst(blobDataLogWalk.blobDataLst);
      }

      if (this.sasTimeout) {
        clearTimeout(this.sasTimeout);
        this.setState({ isRefreshSas: false });
      }

      const countDown =
        Date.parse(blobDataLogWalk.refreshSasEstimatedTime) -
        Date.parse(new Date().toUTCString());
      if (countDown) {
        this.sasTimeout = setTimeout(() => {
          this.setState(
            { isRefreshSas: true },
            this.props.fetchShootLogWalk(this.props.blobDataLogWalk?.blob, true)
          );
        }, countDown);
      }
    }

    if (!blobDataLogWalk && this.sasTimeout) {
      clearTimeout(this.sasTimeout);
    }
  }

  componentWillUnmount() {
    document.getElementsByTagName("html")[0].style.overflow = null;

    window.removeEventListener("resize", this.showToolBarPortrait);

    document.removeEventListener("gesturestart", e => {
      e.preventDefault();
    });

    if (this.sasTimeout) {
      clearTimeout(this.sasTimeout);
    }
  }

  render() {
    const { showShoot, recordInfoInit } = this.props;
    return (
      <React.Fragment>
        <div
          id="container"
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: "block"
          }}
          ref={this.inputRef}
          onClick={this.containerClick}
        ></div>
        {showShoot && showShoot.siteName && (
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "50%",
              backgroundColor: "red",
              color: "white",
              transform: "translate(-50%)",
              padding: "4px 24px",
              whiteSpace: "nowrap",
              display: "flex",
              justifyContent: "center",
              justifyItems: "center"
            }}
          >
            <span
              style={{
                maxWidth: "360px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {showShoot.siteName} 
            </span>
            <span>
              {` / 撮影日時: ${format(
                new Date(recordInfoInit.createdAt),
                "yyyy年M月d日(eeeee)HH:mm",
                { locale: ja }
              )}`}
            </span>
          </div>
        )}

        {this.state.photoNotUploaded && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              color: "white",
              transform: "translate(-50%, 50%)",
              padding: "4px 24px",
              whiteSpace: "nowrap",
              fontSize: "24px"
            }}
          >
            撮影写真はまだ転送されておりません。
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(FixSpots360ImgBox);
