import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { useMediaQuery } from "react-responsive";
import Popover from "@material-ui/core/Popover";

const mediaQueryTiniMobile = "(max-width: 380px)";

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: "0px 20px",
    backgroundColor: "white"
  },
  boxInfo: {
    width: "100%",
    borderRadius: "15px",
    backgroundColor: "#00356D1A"
  },
  description: {
    padding: "15px 15px 0px",
    fontSize: "14px",
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    marginBottom: "15px"
  },
  mentionsInfo: {
    borderTop: "1px solid #D7DADE",
    fontSize: "15px",
    padding: "10px",
    "& p": {
      fontWeight: "bold",
      color: "#333333"
    },
    "& div": {
      fontSize: "15px",
      color: "#00356D"
    }
  },
  tooltip: {
    fontSize: "12px"
  },

  createAtAndBy: {
    width: "100%",
    textAlign: "right",
    fontSize: "15px",
    margin: "10px 0px 0px",
    paddingBottom: "30px"
  },
  popover: {
    pointerEvents: "none"
  },
  paper: {
    padding: "10px",
    backgroundColor: "rgb(0 52 107 / 80%)",
    fontSize: "12px",
    color: "white"
  }
});

function HotspotBaseInfo(props) {
  const { description, mentions, createdBy, createdAt } = props;
  const classes = useStyles();

  const isTiniMobile = useMediaQuery({ query: mediaQueryTiniMobile });
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <div
      id="base-info-group"
      className={classes.root}
      style={isTiniMobile ? { padding: "0px 10px" } : null}
    >
      <div className={classes.boxInfo}>
        <div
          className={classes.description}
          dangerouslySetInnerHTML={{
            __html: description.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/\n/g, "<br>")
          }}
          onMouseEnter={e => setAnchorEl(e.currentTarget)}
          onMouseLeave={() => setAnchorEl(null)}
        />

        <div className={classes.mentionsInfo}>
          <p>
            通知メンバー
            <span id="mention-count" style={{ color: "#00356DE6", paddingLeft: "15px" }}>{`(${
              mentions.length
            })`}</span>
          </p>
          <div id="mention-list">
            {mentions.map(element => (
              <span
                key={element.uniqueCode}
                style={{ marginRight: "12px", display: "inline-block" }}
              >
                {element.name}
              </span>
            ))}
          </div>
        </div>
      </div>

      <div className={classes.createAtAndBy}>
        <span
          style={{
            color: "#00356D",
            marginRight: "15px",
            fontWeight: "bold"
          }}
        >
          {createdBy}
        </span>
        <span style={{ color: "#333333" }}>
          {format(new Date(createdAt), "yyyy/MM/dd (HH:mm)")}
        </span>
      </div>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={Boolean(anchorEl) && description !== ""}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
      >
        <div
          dangerouslySetInnerHTML={{
            __html: description.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/\n/g, "<br>")
          }}
        />
      </Popover>
    </div>
  );
}

export default HotspotBaseInfo;
