import React from "react";

function IconPlusUploadFile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <g
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="#c7c7c7"
        strokeLinecap="round"
        strokeWidth="5"
      >
        <path d="M0 0L0 60" transform="rotate(-90 15 15)"></path>
        <path d="M0 0L0 60" transform="translate(30)"></path>
      </g>
    </svg>
  );
}

export default IconPlusUploadFile;
