import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Component from "../components/Component";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Menu,
  MenuItem
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FilterListIcon from "@material-ui/icons/FilterList";
import Fab from "@material-ui/core/Fab";
import MenuIcon from "@material-ui/icons/Menu";
import RoomIcon from "@material-ui/icons/Room";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/ChevronRight";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { compose } from "redux";
import DrawingPhoto from "./sites/site/DrawingPhoto";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../common/css/drawing-dialog.css";
import {
  fetchSiteDrawings,
  fetchSiteRecordsExistsSpotsUCIsNull
} from "./sites/site/service/service";
import {
  getDrawingsWithSpots,
  getHasRecordOfSpotsUCIsNull
} from "./sites/site/reducer/reducer";
import { Link } from "react-router-dom";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import imageSrc from "../common/img/house_map.jpg";
import {
  FETCH_SITE_DRAWINGS_SUCCESS,
  FETCH_SITE_RECORDS_EXISTS_SPOTSUC_IS_NULL_SUCCESS
} from "./sites/site/constant/constant";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { MODE_DEVICE } from "components/sites/site/LogWalkViewer/constants";
import stringquery from "stringquery";

const ITEM_HEIGHT = 48;

class DrawingDialog extends Component {
  cancelSubmission = false;
  
  constructor(props) {
    super(props);
    this.isBuilder = !props.isSp;
    this.home = props.isSp ? "/sp" : "";
    this.state = {
      currentPage: 0,
      positionIconNoSpot: null,
      fetching: true,
      showList: false,
      fetchingSiteDrawings: true,
      fetchingSiteRecordsExistsSpotsUCIsNull: true,
      isSwitch: false
    };
    this.transformComponentRef = React.createRef();
  }

  componentWillMount() {
    window.addEventListener("resize", this.updateDimensions);
    window.addEventListener("resize", this.setScreenOrientation);
    this.fetch();
    if (document.getElementsByClassName("grid-list-sp").length !== 0) {
      document
        .getElementsByClassName("grid-list-sp")[0]
        .classList.add("scrollbarWidthNone");
    }
  }
  componentDidUpdate() {
    if (this.state.isSwitch) {
      this.setState({
        isSwitch: false
      });
    }
  }
  returnDefaultSize = () => {
    let currentRef = this.transformComponentRef.current;
    if (currentRef != null) {
      currentRef.context.dispatch.resetTransform();
    }
  };
  componentDidMount() {
    if (this.state) {
      this.setScreenOrientation();
    }
    if (!this.props.auth.isAuthenticated)
      this.props.history.push(`${this.home}/login`);

    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    if (document.getElementsByClassName("grid-list-sp").length !== 0) {
      document
        .getElementsByClassName("grid-list-sp")[0]
        .classList.remove("scrollbarWidthNone");
    }

    this.cancelSubmission = true;
  }

  fetch() {
    this.props.fetchSiteDrawings(this.props.sitesUC, this.isBuilder);
    this.props.fetchSiteRecordsExistsSpotsUCIsNull(
      this.props.sitesUC,
      this.isBuilder
    );
  }

  componentWillReceiveProps(props) {
    if (props.type === FETCH_SITE_DRAWINGS_SUCCESS) {
      this.setState({ fetchingSiteDrawings: false });
      this.setState({
        fetching: this.state.fetchingSiteRecordsExistsSpotsUCIsNull
      });
    }
    if (props.type === FETCH_SITE_RECORDS_EXISTS_SPOTSUC_IS_NULL_SUCCESS) {
      this.setState({ fetchingSiteRecordsExistsSpotsUCIsNull: false });
      this.setState({ fetching: this.state.fetchingSiteDrawings });
    }
  }

  updateDimensions = e => {
    this.setPositionMarker();
  };

  setPositionMarker() {
    const { currentPage } = this.state;
    let { drawingsWithSpots } = this.props;

    if (drawingsWithSpots?.length > 0) {
      let positionAfter = [];
      let posAfter = [];

      // Primary image
      drawingsWithSpots[currentPage].spots.map(pos => {
        let element = document.getElementById("primary-drawing");
        let offsetWidth = element ? element.offsetWidth : 0;

        let offsetHeight = element ? element.offsetHeight : 0;

        let naturalWidth = element ? element.naturalWidth : 0;

        let naturalHeight = element ? element.naturalHeight : 0;

        let offsetLeft = element ? element.offsetLeft : 0;

        let offsetTop = element ? element.offsetTop : 0;

        positionAfter.push({
          uniqueCode: pos.uniqueCode,
          name: pos.name,
          posX: (pos.x * offsetWidth) / naturalWidth + offsetLeft,
          posY: (pos.y * offsetHeight) / naturalHeight + offsetTop,
          hasShoot: pos.hasShoot
        });
      });

      this.setState({
        positions: positionAfter
      });
    }
  }

  setScreenOrientation = e => {
    if(!this.cancelSubmission){
      if (window.matchMedia("(orientation: portrait)").matches) {
        this.setState({
          screenOrientation: "portrait"
        });
      }
  
      if (window.matchMedia("(orientation: landscape)").matches) {
        this.setState({
          screenOrientation: "landscape"
        });
      }  
    }
  };

  markMap = e => {
    this.setState({
      isMarkMap: !this.state.isMarkMap
    });
  };

  loadMarker = e => {
    this.setPositionMarker();
  };

  changePage = offset => {
    this.setState(
      {
        currentPage: this.state.currentPage + offset,
        isSwitch: true
      },
      () => {
        setTimeout(this.returnDefaultSize, 100);
      }
    );
  };

  previousPage = () => {
    this.changePage(-1);
  };

  nextPage = () => {
    this.changePage(1);
  };

  nextPrevDrawing = isNext => {
    const { currentPage } = this.state;
    const { drawingsWithSpots } = this.props;

    if (isNext) {
      if (currentPage < drawingsWithSpots.length) {
        this.setState({
          currentPage: currentPage + 1
        });
      } else {
        this.setState({
          currentPage: currentPage - 1
        });
      }
    }
  };

  showListDraw = e => {
    this.setState({ showList: e.currentTarget });
  };

  closeListDraw = e => {
    this.setState({ showList: false });
  };

  handleSwitchDrawing = idx => {
    this.setState({ currentPage: idx });
    this.closeListDraw();
    setTimeout(this.returnDefaultSize, 100);
  };
  handleCloseDialog = () => {
    this.props.onClose(false);
  };
  render() {
    const { currentPage, positions, fetching, showList, screenOrientation } =
      this.state;
    const { drawingsWithSpots, hasRecordOfSpotsUCIsNull, isCardList } =
      this.props;
    const exists = drawingsWithSpots && currentPage < drawingsWithSpots.length;

    return fetching ? (
      <></>
    ) : (
      drawingsWithSpots != undefined && (
        <Dialog
          open={this.props.open}
          onClose={this.handleCloseDialog}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="lg"
          scroll="body"
          PaperProps={{
            // className: "dialog dialog-sign-up drawing-dialog drawing-dialog-new"
            className:
              this.props.modeDevice === MODE_DEVICE.SP
                ? " container-mobile-header dialog paper-image drawing-dialog-height-update-sp m-0"
                : " container-mobile-header dialog paper-image drawing-dialog-height-update m-0"
          }}
        >
          {this.props.modeDevice === MODE_DEVICE.SP &&
            screenOrientation === "portrait" && (
              <Grid
                container
                // spacing={2}
                justify="space-between"
                className="container-mobile-header dialog-viewer-header-container mb-5"
              >
                <Grid item className="text-center">
                  {this.props.modeDevice === MODE_DEVICE.SP && (
                    <Fab onClick={this.handleCloseDialog} size="small">
                      <ArrowBackIcon />
                    </Fab>
                  )}
                </Grid>
                <Grid
                  item
                  className="viewer-dialog-title dialog-viewer-header-title"
                >
                  <Typography variant="h6"></Typography>
                </Grid>
                <Grid item>
                  {/*<Tooltip title="List drawing">*/}
                  {this.props.modeDevice === MODE_DEVICE.SP && (
                    <Fab
                      className="btn-choose-dialog-viewer-header"
                      size="small"
                      aria-controls="simple-menu"
                      onClick={this.showListDraw}
                    >
                      <MenuIcon />
                    </Fab>
                  )}
                </Grid>
              </Grid>
            )}
          {(this.props.modeDevice === MODE_DEVICE.PC ||
            (this.props.modeDevice === MODE_DEVICE.SP &&
              screenOrientation === "portrait")) && (
            <div className="drawing-dialog-bg">
              <DialogTitle
                disableTypography
                className={
                  this.props.modeDevice === MODE_DEVICE.SP
                    ? "dialog-viewer-header-update-sp"
                    : "dialog-viewer-header-update"
                }
              >
                <Grid
                  container
                  spacing={2}
                  justify="space-between"
                  className="container-mobile-header"
                >
                  <Grid item className="text-center position-btn">
                    {(this.props.modeDevice === MODE_DEVICE.PC ||
                      (this.props.modeDevice === MODE_DEVICE.SP &&
                        screenOrientation == "landscape")) && (
                      <Fab onClick={this.handleCloseDialog} size="small">
                        <ArrowBackIcon />
                      </Fab>
                    )}
                    {this.props.modeDevice === MODE_DEVICE.SP &&
                      screenOrientation == "portrait" && (
                        <Button
                          name="prev"
                          className="btn-switch"
                          size="small"
                          onClick={this.previousPage}
                          disabled={currentPage <= 0}
                        >
                          <KeyboardArrowLeftIcon />
                        </Button>
                      )}
                  </Grid>
                  <Grid
                    item
                    className="viewer-dialog-title dialog-viewer-header-title"
                  >
                    <Typography variant="h6">
                      {exists && drawingsWithSpots[currentPage].name}
                    </Typography>
                  </Grid>
                  <Grid item className="text-center position-btn">
                    {(this.props.modeDevice === MODE_DEVICE.PC ||
                      (this.props.modeDevice === MODE_DEVICE.SP &&
                        screenOrientation == "landscape")) && (
                      <Fab
                        className={"btn-choose-dialog-viewer-header"}
                        size="small"
                        aria-controls="simple-menu"
                        onClick={this.showListDraw}
                      >
                        <MenuIcon />
                      </Fab>
                    )}

                    {this.props.modeDevice === MODE_DEVICE.SP &&
                      screenOrientation == "portrait" && (
                        <Button
                          name="next"
                          className="btn-switch"
                          size="small"
                          onClick={this.nextPage}
                          disabled={currentPage >= drawingsWithSpots.length - 1}
                        >
                          <KeyboardArrowRight />
                        </Button>
                      )}
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent className="viewer-content-dialog-update overflow-hidden ">
                <Grid
                  container
                  spacing={0}
                  className={
                    this.props.modeDevice !== MODE_DEVICE.SP
                      ? "padding-bottom-8"
                      : ""
                  }
                >
                  {(this.props.modeDevice === MODE_DEVICE.PC ||
                    (this.props.modeDevice === MODE_DEVICE.SP &&
                      screenOrientation == "landscape")) && (
                    <Grid
                      item
                      xs={this.props.modeDevice === MODE_DEVICE.PC ? 1 : 2}
                      md={1}
                      className="drawing-dialog-btn-landscape"
                      container
                      alignItems="center"
                    >
                      <Button
                        name="prev"
                        className="btn-switch"
                        size="small"
                        onClick={this.previousPage}
                        disabled={currentPage <= 0}
                      >
                        <KeyboardArrowLeftIcon />
                      </Button>
                    </Grid>
                  )}

                  <Grid
                    item
                    className="group-drawing-dialog-sp"
                    xs={
                      this.props.modeDevice === MODE_DEVICE.PC
                        ? 10
                        : this.props.modeDevice === MODE_DEVICE.SP &&
                          screenOrientation == "landscape"
                        ? 8
                        : 12
                    }
                    md={10}
                  >
                    <TransformWrapper defaultScale={1}>
                      <div
                        className={`${
                          this.props.modeDevice === MODE_DEVICE.SP
                            ? "content-drawing-dialog-sp"
                            : "content-drawing-dialog"
                        }`}
                      >
                        <TransformComponent ref={this.transformComponentRef}>
                          <div className="img-mini-parent-sp">
                            <div className="img-mini-dialog-container">
                              {exists && (
                                <DrawingPhoto
                                  id="primary-drawing"
                                  name={drawingsWithSpots[currentPage].name}
                                  drawingsUC={
                                    drawingsWithSpots[currentPage].uniqueCode
                                  }
                                  blob={drawingsWithSpots[currentPage].blob}
                                  loadMarker={this.loadMarker}
                                  isSp={this.props.isSp}
                                  positions={positions}
                                  isDialog={true}
                                  home={this.home}
                                  isCardList={
                                    isCardList === true ||
                                    isCardList === undefined
                                      ? true
                                      : false
                                  }
                                  logwalk={
                                    "logwalk" in
                                    stringquery(this.props.location.search)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </TransformComponent>
                      </div>
                    </TransformWrapper>
                  </Grid>
                  {(this.props.modeDevice === MODE_DEVICE.PC ||
                    (this.props.modeDevice === MODE_DEVICE.SP &&
                      screenOrientation == "landscape")) && (
                    <Grid
                      item
                      xs={this.props.modeDevice === MODE_DEVICE.PC ? 1 : 2}
                      md={1}
                      className="drawing-dialog-btn-landscape"
                      container
                      alignItems="center"
                    >
                      <Button
                        name="next"
                        className="btn-switch"
                        size="small"
                        onClick={this.nextPage}
                        disabled={currentPage >= drawingsWithSpots.length - 1}
                      >
                        <KeyboardArrowRight />
                      </Button>
                      {!hasRecordOfSpotsUCIsNull &&
                        this.props.modeDevice === MODE_DEVICE.SP &&
                        screenOrientation == "landscape" && (
                          <div className="drawing-dialog-btn-adjust">
                            <Fab size="small" disabled={true}>
                              <ControlCameraIcon />
                            </Fab>
                          </div>
                        )}
                      {hasRecordOfSpotsUCIsNull &&
                        this.props.modeDevice === MODE_DEVICE.SP &&
                        screenOrientation == "landscape" && (
                          <div className="drawing-dialog-btn-adjust">
                            <Link
                              to={{
                                pathname: `${this.home}/logwalk-viewer/sites/${this.props.sitesUC}/spots/null`,
                                state: {
                                  isRedirectFromUrl: true,
                                  isDialog: true,
                                  isCardList:
                                    isCardList === true ||
                                    isCardList === undefined
                                      ? true
                                      : false,
                                  logwalk:
                                    "logwalk" in
                                    stringquery(this.props.location.search),
                                  from: this.props.location.pathname
                                }
                              }}
                              className="reset-link"
                            >
                              <Fab size="small" disabled={false}>
                                <ControlCameraIcon />
                              </Fab>
                            </Link>
                          </div>
                        )}
                    </Grid>
                  )}
                </Grid>
                {this.props.modeDevice !== MODE_DEVICE.SP && (
                  <Grid
                    container
                    spacing={2}
                    justify="space-between"
                    className="container-mobile-footer"
                  >
                    <Grid item xs={1} className="text-center"></Grid>
                    <Grid item xs={10} className="text-center">
                      <Typography variant="subtitle1">
                        {exists
                          ? "選択した地点の最新の写真を表示します"
                          : "図面は登録されていません。未整理写真のみ選択可能です。"}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} className="text-right">
                      {!hasRecordOfSpotsUCIsNull && (
                        <Fab size="small" disabled={true}>
                          <ControlCameraIcon />
                        </Fab>
                      )}
                      {hasRecordOfSpotsUCIsNull && (
                        <Link
                          to={{
                            pathname: `${this.home}/logwalk-viewer/sites/${this.props.sitesUC}/spots/null`,
                            state: {
                              isRedirectFromUrl: true,
                              isDialog: true,
                              isCardList:
                                isCardList === true || isCardList === undefined
                                  ? true
                                  : false,
                              logwalk:
                                "logwalk" in
                                stringquery(this.props.location.search),
                              from: this.props.location.pathname
                            }
                          }}
                          className="reset-link"
                        >
                          <Fab size="small" disabled={false}>
                            <ControlCameraIcon />
                          </Fab>
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                )}
              </DialogContent>
            </div>
          )}
          {this.props.modeDevice === MODE_DEVICE.SP &&
            screenOrientation === "portrait" && (
              <Grid
                container
                spacing={0}
                justify="space-between"
                className="container-mobile-header mb-5 drawing-dialog-footer"
              >
                <Grid item xs={2} className="text-center"></Grid>
                <Grid item xs={8} className="drawing-dialog-title text-center">
                  <Typography
                    variant="subtitle1"
                    className="text-color-white text-align-left display-inline-block"
                  >
                    {exists
                      ? "選択した地点の最新の写真を表示します"
                      : "図面は登録されていません。未整理写真のみ選択可能です。"}
                  </Typography>
                </Grid>
                <Grid item xs={2} className="text-right pdr-12">
                  {!hasRecordOfSpotsUCIsNull &&
                    screenOrientation == "portrait" && (
                      <Fab size="small" disabled={true}>
                        <ControlCameraIcon />
                      </Fab>
                    )}
                  {hasRecordOfSpotsUCIsNull && screenOrientation == "portrait" && (
                    <Link
                      to={{
                        pathname: `${this.home}/logwalk-viewer/sites/${this.props.sitesUC}/spots/null`,
                        state: {
                          isRedirectFromUrl: true,
                          isDialog: true,
                          isCardList:
                            isCardList === true || isCardList === undefined
                              ? true
                              : false,
                          logwalk:
                            "logwalk" in
                            stringquery(this.props.location.search),
                          from: this.props.location.pathname
                        }
                      }}
                      className="reset-link"
                    >
                      <Fab size="small" disabled={false}>
                        <ControlCameraIcon />
                      </Fab>
                    </Link>
                  )}
                </Grid>
              </Grid>
            )}

          <Menu
            className="menu-list"
            id="simple-menu"
            anchorEl={showList}
            getContentAnchorEl={null}
            open={Boolean(showList)}
            onClose={this.closeListDraw}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                // border: "2px solid #162f50",
                color: "#162f50",
                borderRadius: 0,
                paddingRight: 10
              }
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            {drawingsWithSpots &&
              drawingsWithSpots.map((item, idx) => (
                // <Grid className="menu-item">
                //   <Grid className="item-icon">
                //     {drawingsWithSpots[currentPage].uniqueCode ===
                //       item.uniqueCode && (
                //         <RoomIcon className="icon-selected" />
                //       )}
                //   </Grid>
                //   <MenuItem
                //     key={item.uniqueCode}
                //     selected={
                //       drawingsWithSpots[currentPage].uniqueCode ===
                //       item.uniqueCode
                //     }
                //     title={item.name}
                //     onClick={() => this.handleSwitchDrawing(idx)}
                //   >
                //     <Typography className="item-name">
                //       {item.name}
                //     </Typography>
                //   </MenuItem>
                // </Grid>
                <MenuItem
                  className="menu-item"
                  key={item.uniqueCode}
                  selected={
                    drawingsWithSpots[currentPage].uniqueCode ===
                    item.uniqueCode
                  }
                  title={item.name}
                  onClick={() => this.handleSwitchDrawing(idx)}
                >
                  <Grid key={item.uniqueCode} className="menu-item-container">
                    <Grid className="item-icon">
                      {drawingsWithSpots[currentPage].uniqueCode ===
                        item.uniqueCode && (
                        <RoomIcon className="icon-selected" />
                      )}
                    </Grid>
                    <Grid className="item-text">
                      <Typography className="item-name">{item.name}</Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
          </Menu>
          {/* SP - Landscape view */}

          {this.props.modeDevice === MODE_DEVICE.SP &&
            screenOrientation === "landscape" && (
              <Grid
                container
                spacing={0}
                className="drawing-dialog-container-landscap"
              >
                <Grid item xs={2}>
                  <Grid
                    container
                    direction="column"
                    justify="flex-end"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid
                      md={6}
                      alignItems="center"
                      container
                      item
                      className="position-btn"
                    >
                      <Fab onClick={this.handleCloseDialog} size="small">
                        <ArrowBackIcon />
                      </Fab>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8} className="">
                  <DialogContent className="drawing-dialog-content-landscap">
                    <Grid
                      container
                      direction="column"
                      justify="flex-end"
                      alignItems="center"
                      className=""
                    >
                      <Grid
                        container
                        item
                        className="drawing-dialog-bg viewer-dialog-title dialog-viewer-header-title"
                      >
                        <Grid
                          container
                          spacing={0}
                          justify="space-between"
                          className="container-mobile-header"
                        >
                          <Grid
                            item
                            xs={2}
                            className="text-center position-btn"
                          >
                            <Button
                              name="prev"
                              className="btn-switch"
                              size="small"
                              onClick={this.previousPage}
                              disabled={currentPage <= 0}
                            >
                              <KeyboardArrowLeftIcon />
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            className="viewer-dialog-title dialog-viewer-header-title"
                          >
                            <Typography variant="h6">
                              {exists && drawingsWithSpots[currentPage].name}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            className="text-center position-btn"
                          >
                            <Button
                              name="next"
                              className="btn-switch"
                              size="small"
                              onClick={this.nextPage}
                              disabled={
                                currentPage >= drawingsWithSpots.length - 1
                              }
                            >
                              <KeyboardArrowRight />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        className="viewer-content-dialog-update  drawing-dialog-bg"
                      >
                        <TransformWrapper defaultScale={1}>
                          <div
                            className={`${
                              this.props.modeDevice === MODE_DEVICE.SP
                                ? "content-drawing-dialog-sp"
                                : "content-drawing-dialog"
                            }`}
                          >
                            <TransformComponent
                              ref={this.transformComponentRef}
                            >
                              <div className="img-mini-parent-sp">
                                <div className="img-mini-dialog-container">
                                  {exists && (
                                    <DrawingPhoto
                                      id="primary-drawing"
                                      name={drawingsWithSpots[currentPage].name}
                                      drawingsUC={
                                        drawingsWithSpots[currentPage]
                                          .uniqueCode
                                      }
                                      blob={drawingsWithSpots[currentPage].blob}
                                      loadMarker={this.loadMarker}
                                      isSp={this.props.isSp}
                                      positions={positions}
                                      isDialog={true}
                                      isCardList={
                                        isCardList === true ||
                                        isCardList === undefined
                                          ? true
                                          : false
                                      }
                                      logwalk={
                                        "logwalk" in
                                        stringquery(this.props.location.search)
                                      }
                                      home={this.home}
                                    />
                                  )}
                                </div>
                              </div>
                            </TransformComponent>
                          </div>
                        </TransformWrapper>
                      </Grid>
                      <Grid container item className="drawing-dialog-title">
                        <Typography
                          variant="subtitle1"
                          className="text-color-white dialog-title-landscap-w100"
                        >
                          {exists
                            ? "選択した地点の最新の写真を表示します"
                            : "図面は登録されていません。未整理写真のみ選択可能です。"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </DialogContent>
                </Grid>
                <Grid item xs={2}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <Grid
                      container
                      item
                      className="position-btn justify-content-flex-start"
                      alignItems="center"
                      style={{ height: "50%" }}
                    >
                      <Fab
                        className={"btn-choose-dialog-viewer-header"}
                        size="small"
                        aria-controls="simple-menu"
                        onClick={this.showListDraw}
                      >
                        <MenuIcon />
                      </Fab>
                    </Grid>

                    <Grid
                      container
                      alignItems="center"
                      item
                      className="position-btn justify-content-flex-end"
                      style={{ height: "45%" }}
                    >
                      {!hasRecordOfSpotsUCIsNull &&
                        this.props.modeDevice === MODE_DEVICE.SP &&
                        screenOrientation == "landscape" && (
                          <Fab size="small" disabled={true}>
                            <ControlCameraIcon />
                          </Fab>
                        )}
                      {hasRecordOfSpotsUCIsNull &&
                        this.props.modeDevice === MODE_DEVICE.SP &&
                        screenOrientation == "landscape" && (
                          <Link
                            to={{
                              pathname: `${this.home}/logwalk-viewer/sites/${this.props.sitesUC}/spots/null`,
                              state: {
                                isRedirectFromUrl: true,
                                isDialog: true,
                                isCardList:
                                  isCardList === true ||
                                  isCardList === undefined
                                    ? true
                                    : false,
                                logwalk:
                                  "logwalk" in
                                  stringquery(this.props.location.search),
                                from: this.props.location.pathname
                              }
                            }}
                            className="reset-link"
                          >
                            <Fab size="small" disabled={false}>
                              <ControlCameraIcon />
                            </Fab>
                          </Link>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Dialog>
      )
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  type: state.rootSitesReducer.siteInfoReducer.type,
  submitErrors: state.storesReducer.error,
  regexObject: state.storesReducer.regexObject,
  message: state.storesReducer.message,
  drawingsWithSpots: getDrawingsWithSpots(
    state.rootSitesReducer.siteInfoReducer.drawingsWithSpots
  ),
  hasRecordOfSpotsUCIsNull: getHasRecordOfSpotsUCIsNull(
    state.rootSitesReducer.siteInfoReducer.hasRecordOfSpotsUCIsNull
  ),
  error: state.storesReducer.error
});

export default connect(mapStateToProps, {
  fetchSiteDrawings,
  fetchSiteRecordsExistsSpotsUCIsNull
})(withRouter(DrawingDialog));
