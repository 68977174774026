import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CommonDialog from "../../CommonDialog";
import { DIALOG_FIELD_TYPES } from "../../../common/Stores/constant/constant";
import DialogDelete from "../../../common/DialogDelete";
import {
  fetchCarpenterDetail,
  addCarpenter,
  updateCarpenter,
  deleteCarpenter
} from "./service/service";
import { fetchAuths } from "../../../common/Select-Auth/service/service";
import { getAuths } from "../../../common/Select-Auth/reducer/reducer";
import { getDetailCarpenters } from "./reducer/carpenterDetail";
import { bindActionCreators } from "redux";
import { getCarpentersDetailSuccess as fillCarpenterDetail } from "./action/action";
import { apiError } from "../../../common/Stores/action/action";
import { POST_CARPENTERS_SUCCESS, PUT_CARPENTERS_SUCCESS } from "./constant/constant";
import { withRouter } from "react-router-dom";

class CarpenterDialog extends React.PureComponent {
  state = {
    defaultValues: {
      supplierName: "",
      supplierFurigana: "",
      supplierPhone: "",
      supplierZipCode: "",
      supplierAddress: "",
      supplierFax: "",
      name: "",
      furigana: "",
      phone: "",
      email: "",
      auth: -1,
      job: "0"
    },
    fieldList: [
      {
        name: "supplierName",
        label: "会社名",
        type: DIALOG_FIELD_TYPES.Text,
        isRequired: false
      },
      {
        name: "supplierFurigana",
        label: "会社名（ふりがな全角）",
        type: DIALOG_FIELD_TYPES.Text,
        isRequired: false
      },
      {
        name: "supplierPhone",
        label: "会社電話",
        type: DIALOG_FIELD_TYPES.Text,
        isRequired: false
      },
      {
        name: "supplierFax",
        label: "会社FAX",
        type: DIALOG_FIELD_TYPES.Text
      },
      {
        name: "supplierZipCode",
        label: "郵便番号",
        type: DIALOG_FIELD_TYPES.Text
      },
      {
        name: "supplierAddress",
        label: "住所",
        type: DIALOG_FIELD_TYPES.Text
      },
      {
        name: "name",
        label: "職人名",
        type: DIALOG_FIELD_TYPES.Text,
        isRequired: true
      },
      {
        name: "furigana",
        label: "職人名（ふりがな全角）",
        type: DIALOG_FIELD_TYPES.Text,
        isRequired: true
      },
      {
        name: "phone",
        label: "個人携帯",
        type: DIALOG_FIELD_TYPES.Text,
        isRequired: false
      },
      {
        name: "email",
        label: "メールアドレス",
        type: DIALOG_FIELD_TYPES.Text,
        isRequired: true
      },
      {
        name: "auth",
        label: "権限",
        type: DIALOG_FIELD_TYPES.DropDownBox,
        options: [],
        isRequired: true
      },
      {
        name: "job",
        type: DIALOG_FIELD_TYPES.CheckBox
      }
    ]
  };

  componentWillMount() {
    const {
      fetchAuths,
      fetchCarpenterDetail,
      carpenterId,
      isEdit
    } = this.props;
    fetchAuths();
    // Only fetch carpenter detail in case edit
    if (isEdit && carpenterId !== "") fetchCarpenterDetail(carpenterId);
  }

  componentWillUnmount() {
    // Clear carpenter error and detail in store
    this.props.fillCarpenterDetail("");
    this.props.apiError(null);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.type === POST_CARPENTERS_SUCCESS ||
      nextProps.type === PUT_CARPENTERS_SUCCESS
    ) {
      this.onDialogClose();
      return;
    }
    // Apply data from api to original list for display combox box
    if (nextProps.auth) {
      const authIndex = this.state.fieldList.findIndex(
        field => field.name === "auth"
      );
      const newFieldList = Object.assign([], this.state.fieldList);
      newFieldList[authIndex].options = nextProps.auth;
      this.setState({
        fieldList: newFieldList
      });
    }
  }

  toggleDialogDelete = () => {
    this.props.toggleDialogDelete();
  };

  onDeleteCarpenter = () => {
    if (this.props.isEdit && this.props.carpenterId !== "") {
      this.props.deleteCarpenter(this.props.carpenterId, () => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        this.props.history.go(0);
      });
      this.toggleDialogDelete();
    }
  };

  onDialogClose = () => {
    // Clear carpenter error and detail in store
    this.props.fillCarpenterDetail("");
    this.props.apiError("");
    this.props.onClose();
  };

  onSubmit = valuesForm => {
    const { isEdit, carpenterId, updateCarpenter, addCarpenter } = this.props;

    const valueSubmit = {
      ...valuesForm,
      job: valuesForm.job === true || valuesForm.job === 1 ? 1 : 0,
      auth: valuesForm.auth === -1 ? "" : valuesForm.auth
    };
    if (isEdit && carpenterId !== "") {
      updateCarpenter(valueSubmit, carpenterId, () => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        this.props.history.go(0);
      });
    } else {
      addCarpenter(valueSubmit, () => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        this.props.history.go(0);
      });
    }
  };

  render() {
    const {
      isDialogShow,
      isEdit,
      carpenterDetail,
      submitErrors,
      isDialogDeleteShow
    } = this.props;

    const { defaultValues, fieldList } = this.state;

    return (
      <>
        <CommonDialog
          title={isEdit ? "職人情報編集" : "職人情報登録"}
          isDialogShow={isDialogShow}
          initialValues={
            carpenterDetail.length === 0 ? defaultValues : carpenterDetail
          }
          onSubmit={this.onSubmit}
          onClose={this.onDialogClose}
          fieldList={fieldList}
          isEdit={isEdit}
          submitButtonTitle={"登録"}
          submitErrors={submitErrors}
          onDeleteClick={this.toggleDialogDelete}
        />

        <DialogDelete
          open={isDialogDeleteShow}
          onClose={this.toggleDialogDelete}
          onDeleteSubmit={this.onDeleteCarpenter}
          PaperProps={{
            className: "dialog dialog-sign-up"
          }}
        />
      </>
    );
  }
}

CarpenterDialog.propTypes = {
  isDialogShow: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
  submitErrors: PropTypes.object,
  auth: PropTypes.array,
  onDeleteClick: PropTypes.func
};

const mapStateToProps = state => ({
  type: state.carpentersReducer.carpentersReducer.type,
  submitErrors: state.storesReducer.error,
  carpenterDetail: getDetailCarpenters(
    state.carpentersReducer.carpentersDetailReducer.carpenterDetail
  ),
  auth: getAuths(state.authsReducer.auth)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCarpenterDetail,
      addCarpenter,
      updateCarpenter,
      deleteCarpenter,
      fetchAuths,
      fillCarpenterDetail,
      apiError
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CarpenterDialog));
