import React, { memo } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
    fontSize: theme.spacing(1.5)
  },
  form: {
    marginBottom: 0,
    marginTop: theme.spacing(3)
  }
}));

const InputControl = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  defaultProps,
  className,
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth>
      <TextField
        {...defaultProps}
        {...field}
        {...props}
        className={`${classes.form} ${className}`}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{ maxLength: props.InputProps?.maxLength }}
      />
    </FormControl>
  );
};

InputControl.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  required: PropTypes.bool,
  defaultProps: PropTypes.object
};

InputControl.defaultProps = {
  // required: true,
  defaultProps: {
    type: "text",
    variant: "outlined",
    // required: true,
    autoComplete: "off"
  }
};

export default memo(InputControl);
