import React from "react";
import { withRouter } from "react-router-dom";
import { api } from "../../../common/api/api";
import Component from "../../Component";
import axios from "axios";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

export class DrawingPhoto extends Component {
  drawingsUC = null;
  alt = "";
  id = null;
  class = null;
  isAddPoint = false;
  source = null;
  ajaxRequest = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      img: null,
      url: "",
      fetching: true
    };
  }

  componentWillReceiveProps(props) {
    if (this.drawingsUC !== props.drawingsUC) {
      this.init(props);
      this.fetch();
    }
  }
  componentDidMount() {}

  componentWillMount() {
    this.init(this.props);
    this.fetch();
  }

  init(props) {
    this.drawingsUC = props.drawingsUC;
    this.alt = props.name === undefined ? "" : props.name;
    this.id = props.id === undefined ? "" : props.id;
  }

  fetch() {
    if (this.source) {
      this.source.cancel();
    }
    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();
    if (this.drawingsUC) {
      api
        .get(`/builder/drawings/${this.drawingsUC}/file?bin`, {
          responseType: "arraybuffer",
          cancelToken: this.source.token
        })
        .then(res => {
          if (res.data === undefined) return Promise.reject();
          var blob = new Blob([res.data], { type: "image/jpeg" });
          var url = URL.createObjectURL(blob);
          return url;
        })
        .then(url => this.img(url))
        .then(img => {
          this.setState({ img });
        })
        .catch(e => {
          if (e?.response?.status === 404) {
            this.setState({ img: this.img("") });
          }
        });
    }
  }

  componentWillUnmount() {
    this.ajaxRequest.cancel();
  }

  img(data) {
    return (
      <>
        <img
          draggable={false}
          id={this.id}
          src={data}
          alt={this.alt}
          onDragStart={e => e.preventDefault()}
          onLoad={() => {
            this.setState({ fetching: false });
            this.props.calculateSpotPositions();
          }}
          className={this.props.class}
        />
      </>
    );
  }

  render() {
    const { positions, listShootInfo, assignCurrentShootToSpot, showShoot } =
      this.props;
    const { fetching, img } = this.state;

    const renderSpot = pos => {
      const hasShoot = listShootInfo.find(
        item => item.spotsUC === pos.uniqueCode
      );
      return (
        <FiberManualRecordIcon
          stroke={"black"}
          stroke-width={1}
          onClick={() => {
            if (!hasShoot) {
              assignCurrentShootToSpot(pos);
            }
          }}
          style={{
            left: pos["posX"],
            top: pos["posY"],
            position: "absolute",
            margin: "-20px -11px",
            fontSize: "1.5em",
            transform: "translateY(47%)",
            zIndex: "99",
            color: hasShoot ? "#8fc31f" : "#737070",
            cursor:
              !hasShoot && showShoot && !showShoot.spotsUC
                ? "pointer"
                : "default"
          }}
        ></FiberManualRecordIcon>
      );
    };

    return (
      <>
        {img}
        {!fetching &&
          positions &&
          positions.map(pos => (
            <div key={pos.uniqueCode}>{renderSpot(pos)}</div>
          ))}
      </>
    );
  }
}

export default withRouter(DrawingPhoto);
