import {
  fetchRobotDetailInfoSuccess,
  fetchRobotInfoEditSuccess,
  putRobotInfoStarted,
  putRobotInfoSuccess,
  fetchRobotLogBuildDetailInfoSuccess,
  returnRobotSuccess,
  deleteRobotInfoSuccess
} from "../action/action";
import { api } from "../../../../../common/api/api";
import {
  dispatchError,
  enableLoading,
  finishLoading
} from "../../../../../common/Stores/service/service";

export const fetchRobotDetailInfo = id => {
  return dispatch => {
    return api
      .get(`/robots/${id}/info`)
      .then(res => {
        dispatch(fetchRobotDetailInfoSuccess(res.data));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchRobotLogBuildDetailInfo = id => {
  return dispatch => {
    api
      .get(`/logbuild/robots/${id}/detail`)
      .then(res => {
        dispatch(fetchRobotLogBuildDetailInfoSuccess(res.data));
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const fetchRobotInfoEdit = id => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/robots/${id}/edit`)
      .then(res => {
        dispatch(fetchRobotInfoEditSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const updateRobotInfo = value => {
  return dispatch => {
    dispatch(dispatchError(null));
    dispatch(enableLoading());
    dispatch(putRobotInfoStarted());
    api
      .put(`/robots/${value.uniqueCode}`, value)
      .then(res => {
        dispatch(putRobotInfoSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const returnRobot = data => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/builder/return-robots`, data)
      .then(res => {
        dispatch(finishLoading());
        dispatch(returnRobotSuccess());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};

export const deleteRobot = id => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/robots/${id}`)
      .then(res => {
        dispatch(deleteRobotInfoSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(dispatchError(err));
      });
  };
};