import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";

import FlexibleWidthDrawer from "./FlexibleWidthDrawer";
import ShowExistedHotspotInfo from "./ShowExistedHotspotInfo";
import CreateHotspotInfo from "./CreateHotspotInfo";

import ShowPanelPC from "./common/img/showpanel_PC.svg";
import ShowPanelSP from "./common/img/showpanel_SP.svg";
import "./common/css/hostpotInfo.css";

import { createHotspotInfo, openHotspotInfo } from "../../action/action";

const mediaQueryTabletOrMobile = "(max-width: 960px)";

const useStyles = makeStyles({
  showPanelImage: {
    position: "absolute",
    top: "50%",
    left: "0px",
    marginTop: "-55px",
    cursor: "pointer"
  }
});

function HotspotInfoDrawer(props) {
  const {
    newHostpotInfoColor,
    positionNewHotspotInfo,
    drawerCancelCreateHotspot,
    onResetFlagRedirectNoImage,
    changeNewHostpotInfoColor,
    rotateToHotspotPosition,
    backToDrawing,
    logWalkInfoImage,
    displayNone
  } = props;

  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: mediaQueryTabletOrMobile });

  const dispatch = useDispatch();
  const isCreateHotspotInfo = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.isCreateHotspotInfo
  );
  const currentHotspotInfoId = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.currentHotspotInfoId
  );

  const resetFlagRedirectNoImage = () => {
    onResetFlagRedirectNoImage();
  };

  return (
    <React.Fragment>
      <FlexibleWidthDrawer displayNone={displayNone}>
        {isCreateHotspotInfo ? (
          <CreateHotspotInfo
            newHostpotInfoColor={newHostpotInfoColor}
            positionNewHotspotInfo={positionNewHotspotInfo}
            drawerCancelCreateHotspot={() => drawerCancelCreateHotspot()}
            changeNewHostpotInfoColor={iconColor => changeNewHostpotInfoColor(iconColor)}
          />
        ) : (
          <ShowExistedHotspotInfo
            currentHotspotInfoId={currentHotspotInfoId}
            onResetFlagRedirectNoImage={resetFlagRedirectNoImage}
            rotateToHotspotPosition={(yaw, pitch, isCloseDrawer) => {
              if (isCloseDrawer) {
                if (rotateToHotspotPosition && isTabletOrMobile) {
                  rotateToHotspotPosition(yaw, pitch)
                }
              } else {
                rotateToHotspotPosition(yaw, pitch)
              }
            }}
            backToDrawing={() => backToDrawing()}
            logWalkInfoImage={logWalkInfoImage}
          />
        )}
      </FlexibleWidthDrawer>
      <img
        onClick={(e) => {
          e.preventDefault();
          if (isCreateHotspotInfo) {
            dispatch(createHotspotInfo());
          } else {
            if (currentHotspotInfoId !== "") {
              dispatch(openHotspotInfo(currentHotspotInfoId));
            }
          }
        }}
        src={isTabletOrMobile ? ShowPanelSP : ShowPanelPC}
        className={classes.showPanelImage}
        alt=""
        style={{display: displayNone ? "none" : "inline-block"}}
      />
    </React.Fragment>
  );
}

HotspotInfoDrawer.propTypes = {};

export default HotspotInfoDrawer;
