import {
  FETCH_SITE_INFO_SUCCESS,
  FETCH_SITE_INFO_ROBOT_SUCCESS,
  FETCH_SITE_INFO_ROBOT_CANDIDATE_SUCCESS,
  POST_SITE_INFO_ROBOT_CANDIDATE_SUCCESS,
  DELETE_SITE_INFO_ROBOT_CANDIDATE_SUCCESS,
  FETCH_SITE_INFO_STAFF_SUCCESS,
  FETCH_SITE_INFO_SUPPLIER_SUCCESS,
  FETCH_STAFF_CANDIDATES_SUCCESS,
  FETCH_SUPPLIER_CANDIDATES_SUCCESS,
  FETCH_SITE_SUCCESS,
  GET_SITES_DETAIL_SUCCESS,
  GET_FILE_SUCCESS,
  SEARCH_FILE_SUCCESS,
  GET_PHOTOS_SUCCESS,
  GET_PHOTO_INFO_SUCCESS,
  GET_FILE_INFO_SUCCESS,
  POST_PHOTOS_SUCCESS,
  FETCH_ENTRIES_SUCCESS,
  PUT_SITE_INFO_ROBOT_SUCCESS,
  POST_FILE_SUCCESS,
  PUT_FILE_SUCCESS,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_REFER,
  DELETE_PHOTO_REFER,
  DELETE_PHOTO_SUCCESS,
  PUT_PHOTO_SUCCESS,
  GET_PHOTOS_SP_SUCCESS,
  SEARCH_FILE_SP_SUCCESS,
  FETCH_SITE_ROBOT_SP_SUCCESS,
  FETCH_SITE_SUPPLIER_SP_SUCCESS,
  FETCH_SITE_BUILDER_SP_SUCCESS,
  FETCH_PHOTO_TAB_SUCCESS,
  PUT_SITE_PHOTO_SP_SUCCESS,
  GET_ORIGINAL_PHOTO_SUCCESS,
  SHOW_HIDE_DIALOG_INFO_ROBOT_SUCCESS,
  SHOW_HIDE_DIALOG_STAFF_SUCCESS,
  SHOW_HIDE_DIALOG_SUPPLIER_SUCCESS,
  SHOW_HIDE_DIALOG_EDIT_INFO_SUCCESS,
  SHOW_HIDE_DIALOG_EDIT_FILE_SUCCESS,
  SHOW_HIDE_DIALOG_DELETE_FILE_SUCCESS,
  SET_FILE_VALUES_SUCCESS,
  SHOW_HIDE_DIALOG_DELETE_INFO_SUCCESS,
  SET_INFO_VALUES_SUCCESS,
  FETCH_SITE_DRAWINGS_SUCCESS,
  FETCH_SITE_RECORDS_EXISTS_SPOTSUC_IS_NULL_SUCCESS,
  FETCH_DRAWING_IMAGE_SUCCESS,
  SORT_DRAWING_IMAGE_SUCCESS,
  POST_DRAWINGS_SUCCESS,
  GET_DRAWING_WITH_SPOTS_SUCCESS,
  PUT_DRAWING_INFO_SUCCESS,
  POST_SPOT_SUCCESS,
  PUT_SPOT_SUCCESS,
  DELETE_DRAWING_SUCCESS,
  DELETE_SPOT_SUCCESS,
  SHOW_HIDE_DIALOG_PREVIEW_PDF_SUCCESS,
  SHOW_HIDE_DIALOG_MAP_SUCCESS,
  SHOW_HIDE_DIALOG_EDIT_MAP_SUCCESS,
  SHOW_HIDE_DIALOG_ADD_POINT_SUCCESS,
  SHOW_HIDE_DIALOG_EDIT_POINT_SUCCESS,
  GET_SHOOTS_SUCCESS,
  CHANGE_SHOOTS_EDIT_LINK,
  GET_CATEGORY_SUCCESS,
  GET_HISTORY_SUCCESS,
  GET_LIST_DRAWING_SUCCESS,
  SHOW_HIDE_DIALOG_DELETE_SUCCESS,
  SHOW_HIDE_DIALOG_MAP_FROM_LOG_WALK,
  GET_URL_IMAGE_SHOOT_SUCCESS,
  SET_DRAWING_PHOTO,
  SET_TAB_ELEMENT_SUCCESS,
  GET_UNADJUSTED_PHOTO_SUCCESS,
  SET_LIST_ADJUST_PHOTO_SELECTED,
  SET_LIST_ADJUST_PHOTO,
  RESET_LIST_ADJUST_PHOTO,
  GET_LIST_SHOOTS_SPOT_SUCCESS,
  SET_HISTORY_VIEWER,
  SET_SPOT_CURRENT,
  OPEN_HOTSPOT_INFO,
  CREATE_HOTSPOT_INFO,
  CREATE_HOTSPOT_INFO_SUCCESS,
  DELETE_HOTSPOT_INFO_SUCCESS,
  CANCEL_CREATE_HOTSPOT_INFO,
  CLOSE_HOTSPOT_INFO,
  SET_TYPE_SELECT_IMAGE_3D,
  SET_TYPE_DIALOG,
  ADD_IMAGE_NOT_ADJUSTED,
  SHARE_SHOOTS_UC_SUCCESS,
  LOAD_INIT_NO_IMAGE,
  SET_IMAGE_SHOOT_UN_ADJUSTED,
  RESET_STORE,
  RESET_LIST_SPOTS_CURRENT,
  RESET_LIST_INFO_HOTSPOT_CURRENT,
  INIT_VIEW_UNADJUSTED_IMAGE,
  SET_REDIRECT_FROM_URL,
  SET_IS_DEFAULT_HISTORY,
  SET_PROGRESS_BAR_LOAD_IMAGE3D,
  CLEAR_DRAWINGS_WITH_SPOTS,
  GET_SITE_SP_SUCCESS,
  SET_IS_COMPLETE_SITE_SUCCESS,
  SET_IS_BACK_SUCCESS,
  COPY_DRAWINGS_SUCCESS,
  COPY_DRAWINGS_WITH_SPOTS_SUCCESS,
  ADD_ADJUST_IMAGE_SUCCESS,
  COMPASS_SETTING_DIRECTION_SUCCESS,
  SHOW_DIRECTION_DRAWER,
  HIDE_DIRECTION_DRAWER,
  // GET_URL_IMAGE_SHOOT_TILE_SUCCESS,
  LOGIN_COMETCHAT_SUCCESS,
  LOGOUT_COMETCHAT_SUCCESS,
  GET_BLOB_DATA_LOGWALK_SUCCESS,
  SAVE_CONDITION_HOTSPOT_INFO_SUCCESS,
  SAVE_CONDITION_SITE_INFO_SUCCESS,
  UPDATE_SHOOT_HOTSPOT_INFO_NOTI,
  SET_SHOW_SHARE_URL_DIALOG_EDIT,
  UPDATE_IS_SHARE_URL
} from "../constant/constant";

export const fetchSiteInfoSuccess = siteInfo => {
  return {
    type: FETCH_SITE_INFO_SUCCESS,
    siteInfo: siteInfo
  };
};

export const fetchSiteInfoRobotSuccess = siteInfoRobot => {
  return {
    type: FETCH_SITE_INFO_ROBOT_SUCCESS,
    siteInfoRobot: siteInfoRobot
  };
};

export const fetchSiteInfoStaffSuccess = siteInfoStaff => {
  return {
    type: FETCH_SITE_INFO_STAFF_SUCCESS,
    siteInfoStaff: siteInfoStaff
  };
};

export const fetchSiteInfoSupplierSuccess = siteInfoSupplier => {
  return {
    type: FETCH_SITE_INFO_SUPPLIER_SUCCESS,
    siteInfoSupplier: siteInfoSupplier
  };
};

export const fetchStaffCandidatesSuccess = staffCandidates => {
  return {
    type: FETCH_STAFF_CANDIDATES_SUCCESS,
    staffCandidates: staffCandidates
  };
};

export const fetchSupplierCandidatesSuccess = supplierCandidates => {
  return {
    type: FETCH_SUPPLIER_CANDIDATES_SUCCESS,
    supplierCandidates: supplierCandidates
  };
};

export const fetchSiteInfoRobotCandidateSuccess = siteInfoRobotCandidate => {
  return {
    type: FETCH_SITE_INFO_ROBOT_CANDIDATE_SUCCESS,
    siteInfoRobotCandidate: siteInfoRobotCandidate
  };
};

export const postSiteInfoRobotCandidateSuccess = siteInfoRobotCandidate => {
  return {
    type: POST_SITE_INFO_ROBOT_CANDIDATE_SUCCESS,
    siteInfoRobotCandidate: siteInfoRobotCandidate
  };
};

export const deleteSiteInfoRobotCandidateSuccess = () => {
  return {
    type: DELETE_SITE_INFO_ROBOT_CANDIDATE_SUCCESS
  };
};

export const fetchSiteSuccess = site => {
  return {
    type: FETCH_SITE_SUCCESS,
    site: site
  };
};

export const getSitesDetailSuccess = siteDetail => {
  return {
    type: GET_SITES_DETAIL_SUCCESS,
    siteDetail: siteDetail
  };
};

export const getFileSuccess = file => {
  return {
    type: GET_FILE_SUCCESS,
    file: file
  };
};

export const postFileSuccess = file => {
  return {
    type: POST_FILE_SUCCESS,
    file: file
  };
};

export const serachFileSuccess = files => {
  return {
    type: SEARCH_FILE_SUCCESS,
    files: files
  };
};

export const getPhotosSuccess = photos => {
  return {
    type: GET_PHOTOS_SUCCESS,
    photos: photos
  };
};

export const getPhotoInFoSuccess = photoInfo => {
  return {
    type: GET_PHOTO_INFO_SUCCESS,
    photoInfo: photoInfo
  };
};

export const getFileInFoSuccess = fileInfo => {
  return {
    type: GET_FILE_INFO_SUCCESS,
    fileInfo: fileInfo
  };
};

export const postPhotosSuccess = photos => {
  return {
    type: POST_PHOTOS_SUCCESS,
    photos: photos
  };
};

export const fetchEntriesSuccess = entries => {
  return {
    type: FETCH_ENTRIES_SUCCESS,
    entries: entries
  };
};

export const putSiteInfoRobotSuccess = () => {
  return {
    type: PUT_SITE_INFO_ROBOT_SUCCESS
  };
};

export const putFileSuccess = () => {
  return {
    type: PUT_FILE_SUCCESS
  };
};

export const deleteFileSuccess = () => {
  return {
    type: DELETE_FILE_SUCCESS
  };
};

export const deleteFileRefer = () => {
  return {
    type: DELETE_FILE_REFER
  };
};

export const deletePhotoSuccess = () => {
  return {
    type: DELETE_PHOTO_SUCCESS
  };
};

export const deletePhotoRefer = () => {
  return {
    type: DELETE_PHOTO_REFER
  };
};

export const putPhotoSuccess = () => {
  return {
    type: PUT_PHOTO_SUCCESS
  };
};

export const getPhotosSPSuccess = photosSP => {
  return {
    type: GET_PHOTOS_SP_SUCCESS,
    photosSP: photosSP
  };
};

export const serachFileSPSuccess = filesSP => {
  return {
    type: SEARCH_FILE_SP_SUCCESS,
    filesSP: filesSP
  };
};

export const fetchSiteRobotSuccess = robot => {
  return {
    type: FETCH_SITE_ROBOT_SP_SUCCESS,
    robot: robot
  };
};

export const fetchSiteSupplierSPSuccess = supplier => {
  return {
    type: FETCH_SITE_SUPPLIER_SP_SUCCESS,
    supplier: supplier
  };
};

export const fetchSiteBuilderSPSuccess = builder => {
  return {
    type: FETCH_SITE_BUILDER_SP_SUCCESS,
    builder: builder
  };
};

export const fetchPhotoTabSuccess = success => {
  return {
    type: FETCH_PHOTO_TAB_SUCCESS,
    success: success
  };
};

export const putSitePhotoSpSuccess = () => {
  return {
    type: PUT_SITE_PHOTO_SP_SUCCESS
  };
};

export const getOriginalPhotoSuccess = url => {
  return {
    type: GET_ORIGINAL_PHOTO_SUCCESS,
    url: url
  };
};

export const showHideDialogInfoRobotSuccess = () => {
  return {
    type: SHOW_HIDE_DIALOG_INFO_ROBOT_SUCCESS
  };
};

export const showHideDialogStaffSuccess = () => {
  return {
    type: SHOW_HIDE_DIALOG_STAFF_SUCCESS
  };
};

export const showHideDialogSupplierSuccess = () => {
  return {
    type: SHOW_HIDE_DIALOG_SUPPLIER_SUCCESS
  };
};

export const showHideDialogEditInfoSuccess = () => {
  return {
    type: SHOW_HIDE_DIALOG_EDIT_INFO_SUCCESS
  };
};

export const showHideDialogDeleteInfoSuccess = () => {
  return {
    type: SHOW_HIDE_DIALOG_DELETE_INFO_SUCCESS
  };
};

export const setInfoValuesSuccess = infoValues => {
  return {
    type: SET_INFO_VALUES_SUCCESS,
    infoValues: infoValues
  };
};

export const showHideDialogEditFileSuccess = () => {
  return {
    type: SHOW_HIDE_DIALOG_EDIT_FILE_SUCCESS
  };
};

export const showHideDialogDeleteFileSuccess = () => {
  return {
    type: SHOW_HIDE_DIALOG_DELETE_FILE_SUCCESS
  };
};

export const setFileValuesSuccess = fileValues => {
  return {
    type: SET_FILE_VALUES_SUCCESS,
    fileValues: fileValues
  };
};

export const fetchSiteDrawingsSuccess = drawingsWithSpots => {
  return {
    type: FETCH_SITE_DRAWINGS_SUCCESS,
    drawingsWithSpots: drawingsWithSpots
  };
};

export const fetchSiteRecordsExistsSpotsUCIsNullSuccess = hasRecordOfSpotsUCIsNull => {
  return {
    type: FETCH_SITE_RECORDS_EXISTS_SPOTSUC_IS_NULL_SUCCESS,
    hasRecordOfSpotsUCIsNull: hasRecordOfSpotsUCIsNull
  };
};

export const fetchDrawingFileSuccess = photo => {
  return {
    type: FETCH_DRAWING_IMAGE_SUCCESS,
    photo: photo
  };
};

export const sortDrawingsImageSuccess = () => {
  return {
    type: SORT_DRAWING_IMAGE_SUCCESS
  };
};

export const postDrawingSuccess = () => {
  return {
    type: POST_DRAWINGS_SUCCESS
  };
};

export const getDrawingWithSpotsSuccess = drawingWithSpots => {
  return {
    type: GET_DRAWING_WITH_SPOTS_SUCCESS,
    drawingWithSpots: drawingWithSpots
  };
};

export const putDrawingInfoSuccess = () => {
  return {
    type: PUT_DRAWING_INFO_SUCCESS
  };
};

export const postSpotSuccess = () => {
  return {
    type: POST_SPOT_SUCCESS
  };
};

export const putSpotSuccess = () => {
  return {
    type: PUT_SPOT_SUCCESS
  };
};

export const deleteDrawingSuccess = () => {
  return {
    type: DELETE_DRAWING_SUCCESS
  };
};

export const deleteSpotSuccess = () => {
  return {
    type: DELETE_SPOT_SUCCESS
  };
};

export const showHideDialogPreviewPdfSuccess = pdfFile => {
  return {
    type: SHOW_HIDE_DIALOG_PREVIEW_PDF_SUCCESS,
    pdfFile: pdfFile
  };
};

export const showHideDialogMapSuccess = drawingsUC => {
  return {
    type: SHOW_HIDE_DIALOG_MAP_SUCCESS,
    drawingsUC: drawingsUC
  };
};

export const showHideDialogEditMapSuccess = nameValue => {
  return {
    type: SHOW_HIDE_DIALOG_EDIT_MAP_SUCCESS,
    nameValue: nameValue
  };
};

export const showHideDialogAddPointSuccess = spotData => {
  return {
    type: SHOW_HIDE_DIALOG_ADD_POINT_SUCCESS,
    spotData: spotData
  };
};

export const showHideDialogEditPointSuccess = spotData => {
  return {
    type: SHOW_HIDE_DIALOG_EDIT_POINT_SUCCESS,
    spotData: spotData
  };
};

export const showHideDialogDeleteSuccess = () => {
  return {
    type: SHOW_HIDE_DIALOG_DELETE_SUCCESS
  };
};

export const showHideDialogMapFromLogWalkSuccess = () => {
  return {
    type: SHOW_HIDE_DIALOG_MAP_FROM_LOG_WALK
  };
};

export const getShootSuccess = shootsInfo => {
  return {
    shootsInfo,
    type: GET_SHOOTS_SUCCESS
  };
};

export const changeShootEditLink = data => {
  return {
    data,
    type: CHANGE_SHOOTS_EDIT_LINK
  };
};

export const getHistorySuccess = data => {
  return {
    data,
    type: GET_HISTORY_SUCCESS
  };
};

export const getCategorySuccess = data => {
  return {
    type: GET_CATEGORY_SUCCESS,
    data
  };
};

export const getListDrawingSuccess = data => {
  return {
    data,
    type: GET_LIST_DRAWING_SUCCESS
  };
};

export const getUrlImageShootSuccess = data => {
  return {
    data,
    type: GET_URL_IMAGE_SHOOT_SUCCESS
  };
};

// export const getUrlImageShootTileSuccess = data => {
//   return {
//     data,
//     type: GET_URL_IMAGE_SHOOT_TILE_SUCCESS
//   };
// };

export const getBlobDataLogWalkSuccess = data => {
  return {
    data: data,
    type: GET_BLOB_DATA_LOGWALK_SUCCESS
  };
};

export const updateShootHotspotInfoNoti = data => {
  return {
    data : data,
    type: UPDATE_SHOOT_HOTSPOT_INFO_NOTI
  };
};

export const updateIsShareUrl = data => {
  return {
    data : data,
    type: UPDATE_IS_SHARE_URL
  };
};

export const setTabElementSuccess = tabElement => {
  return {
    type: SET_TAB_ELEMENT_SUCCESS,
    tabElement
  };
};

export const setDrawingPhoto = data => {
  return {
    data,
    type: SET_DRAWING_PHOTO
  };
};

export const getUnAdjustedPhotoSuccess = data => {
  return {
    data,
    type: GET_UNADJUSTED_PHOTO_SUCCESS
  };
};

export const setListAdjustPhotoSelected = data => {
  return {
    data,
    type: SET_LIST_ADJUST_PHOTO_SELECTED
  };
};

export const setListAdjustPhoto = data => {
  return {
    data,
    type: SET_LIST_ADJUST_PHOTO
  };
};

export const resetListAdjustPhoto = () => {
  return {
    type: RESET_LIST_ADJUST_PHOTO
  };
};

export const addAdjustImageSuccess = () => {
  return {
    type: ADD_ADJUST_IMAGE_SUCCESS
  };
};

export const getListShootsSpot = data => {
  return {
    data,
    type: GET_LIST_SHOOTS_SPOT_SUCCESS
  };
};

export const setHistoryViewer = data => {
  return {
    data,
    type: SET_HISTORY_VIEWER
  };
};

export const setSpotCurrent = data => {
  return {
    data,
    type: SET_SPOT_CURRENT
  };
};

export const openHotspotInfo = data => {
  return {
    data,
    type: OPEN_HOTSPOT_INFO
  };
};

export const createHotspotInfo = () => {
  return {
    type: CREATE_HOTSPOT_INFO
  };
};

export const createHotspotInfoSuccess = data => {
  return {
    data,
    type: CREATE_HOTSPOT_INFO_SUCCESS
  };
};

export const deleteHotspotInfoSuccess = data => {
  return {
    data,
    type: DELETE_HOTSPOT_INFO_SUCCESS
  };
};

export const cancelCreateHotspotInfo = () => {
  return {
    type: CANCEL_CREATE_HOTSPOT_INFO
  };
};

export const closeHotspotInfo = () => {
  return {
    type: CLOSE_HOTSPOT_INFO
  };
};

export const setTypeSelectImage3D = data => {
  return {
    data,
    type: SET_TYPE_SELECT_IMAGE_3D
  };
};

export const setTypeDialog = data => {
  return {
    data,
    type: SET_TYPE_DIALOG
  };
};

export const addImageNotAdjusted = data => {
  return {
    data,
    type: ADD_IMAGE_NOT_ADJUSTED
  };
};

export const shareShootsUCSuccess = data => {
  return {
    data,
    type: SHARE_SHOOTS_UC_SUCCESS
  };
};

export const loadInitNoImage = (spotsUC, sitesUC) => {
  return {
    spotsUC,
    sitesUC,
    type: LOAD_INIT_NO_IMAGE
  };
};

export const setImageShootUnAdjusted = data => {
  return {
    data,
    type: SET_IMAGE_SHOOT_UN_ADJUSTED
  };
};

export const resetStore = () => {
  return {
    type: RESET_STORE
  };
};

export const resetListSpotsCurrent = () => {
  return {
    type: RESET_LIST_SPOTS_CURRENT
  };
};

export const resetListInfoHotspotCurrent = () => {
  return {
    type: RESET_LIST_INFO_HOTSPOT_CURRENT
  };
};

export const initViewUnAdjustedImage = () => {
  return {
    type: INIT_VIEW_UNADJUSTED_IMAGE
  };
};

export const setRedirectFromUrl = data => {
  return {
    data,
    type: SET_REDIRECT_FROM_URL
  };
};

export const setIsDefaultHistory = data => {
  return {
    data,
    type: SET_IS_DEFAULT_HISTORY
  };
};

export const setProgressBar = data => {
  return {
    data,
    type: SET_PROGRESS_BAR_LOAD_IMAGE3D
  };
};

export const clearDrawingsWithSpotSuccess = () => {
  return {
    type: CLEAR_DRAWINGS_WITH_SPOTS
  };
};

export const getSiteSPSuccess = siteSP => {
  return {
    siteSP: siteSP,
    type: GET_SITE_SP_SUCCESS
  };
};

export const setIsCompleteSiteSuccess = isCompleteSite => {
  return {
    isCompleteSite: isCompleteSite,
    type: SET_IS_COMPLETE_SITE_SUCCESS
  };
};

export const setIsBackSuccess = isBack => {
  return {
    isBack: isBack,
    type: SET_IS_BACK_SUCCESS
  };
};

export const copyDrawingSuccess = () => {
  return {
    type: COPY_DRAWINGS_SUCCESS
  };
};

export const copyDrawingsWithSpotsSuccess = () => {
  return {
    type: COPY_DRAWINGS_WITH_SPOTS_SUCCESS
  };
};
export const compassSettingDirectionSuccess = () => {
  return {
    type: COMPASS_SETTING_DIRECTION_SUCCESS
  };
};

export const showDirectionDrawer = () => {
  return {
    type: SHOW_DIRECTION_DRAWER
  };
};

export const hideDirectionDrawer = () => {
  return {
    type: HIDE_DIRECTION_DRAWER
  };
};

export const loginCometChatSuccess = data => {
  return {
    data,
    type: LOGIN_COMETCHAT_SUCCESS
  };
};

export const logoutCometChatSuccess = () => {
  return {
    type: LOGOUT_COMETCHAT_SUCCESS
  };
};

export const saveConditionHotSpotInfoSuccess = data => {
  return {
    data,
    type: SAVE_CONDITION_HOTSPOT_INFO_SUCCESS
  };
};

export const saveConditionSiteInfoSuccess = data => {
  return {
    data,
    type: SAVE_CONDITION_SITE_INFO_SUCCESS
  };
};

export const setShowShareUrlDialogEdit = data => {
  return {
    data,
    type: SET_SHOW_SHARE_URL_DIALOG_EDIT
  };
};
