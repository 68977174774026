import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Component from "./Common";
import { Button, Grid, Menu, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { bindActionCreators } from "redux";
import { dispatchError } from "../../../common/Stores/service/service";
import { Formik } from "formik";
import DrawingPhoto from "./DrawingPhoto";
import { isMobile } from "react-device-detect";
import { getDrawingWithSpots } from "./service/service";
import { apiError as fillError } from "../../../common/Stores/action/action";
import { getDrawingsWithSpots } from "./reducer/reducer";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import RoomIcon from "@material-ui/icons/Room";
import CommonMapOptionDialogSP from "./CommonMapOptionDialogSP";
const ITEM_HEIGHT = 48;
class MapDialog extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  state = {
    positions: null,
    drawingWithSpots: this.props.drawingWithSpots,
    isAddPoint: false,
    fetching: true,
    isVerticalImg: false,
    showList: false,
    currentSelect: 0,
    disableButton: false,
    confirmCloseModeSettingLink: false
    // drawing: {}
  };

  componentWillMount() {
    window.addEventListener("resize", this.setScreenOrientation);
    this.init(this.props);
    this.currentDrawing = this.props.currentDrawing;
    this.fetch();
    this.props.dispatchError(null);
  }

  componentWillReceiveProps(props) {
    this.setState({ drawingWithSpots: this.props.drawingWithSpots });
    this.setPositionMarker();

    if (props.currentDrawing !== this.currentDrawing) {
      this.currentDrawing = props.currentDrawing;
      this.fetch();
      this.setState({ disableButton: false });
    }
  }

  componentDidMount() {
    document.getElementsByTagName("body")[0].classList.add("disable-select");
    window.addEventListener("resize", this.updateDimensions);
    if (this.state) {
      this.setScreenOrientation();
    }

    const { drawingsWithSpots, currentDrawing } = this.props;

    if (drawingsWithSpots && currentDrawing)
      this.drawingUC =
        this.props.drawingsWithSpots[this.props.currentDrawing].uniqueCode;
  }

  componentWillUnmount() {
    document.getElementsByTagName("body")[0].classList.remove("disable-select");
    window.removeEventListener("resize", this.updateDimensions);
    // window.removeEventListener("gesturestart", function (event) {}, false);
  }

  updateDimensions = e => {
    this.setPositionMarker();
  };

  setPositionMarker() {
    let { drawingWithSpots } = this.props;
    if (drawingWithSpots !== undefined) {
      let posAfter = [];

      let element = document.getElementById("primary-image-dialog");
      if (element) {
        let naturalWidth = element ? element.naturalWidth : 0;

        let naturalHeight = element ? element.naturalHeight : 0;

        const elMap = document.getElementById("primary-map-dialog");
        let scaleMap = elMap ? elMap.offsetWidth / elMap.offsetHeight : 0;
        let isVerticalImg = naturalHeight * scaleMap > naturalWidth;

        let offsetWidth = 0;
        let offsetHeight = 0;

        if (isVerticalImg) {
          offsetWidth = (elMap.offsetHeight * naturalWidth) / naturalHeight;

          offsetHeight = elMap.offsetHeight;
        } else {
          offsetWidth = elMap.offsetWidth;

          offsetHeight = (elMap.offsetWidth * naturalHeight) / naturalWidth;
        }

        let offsetLeft = (elMap.offsetWidth - offsetWidth) / 2;

        let offsetTop = (elMap.offsetHeight - offsetHeight) / 2;

        drawingWithSpots.spots.map(pos => {
          posAfter.push({
            uniqueCode: pos.uniqueCode,
            name: pos.name,
            posX: (pos.x * offsetWidth) / naturalWidth + offsetLeft,
            posY: (pos.y * offsetHeight) / naturalHeight + offsetTop,
            hasShoot: pos.hasShoot,
            links: pos.links
          });
        });
        this.setState({
          drawingWithSpots: drawingWithSpots,
          positions: posAfter,
          isVerticalImg: isVerticalImg
        });
      }
    }
  }

  setScreenOrientation = e => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      this.setState({
        screenOrientation: "portrait"
      });
    }
    if (window.matchMedia("(orientation: landscape)").matches) {
      this.setState({
        screenOrientation: "landscape"
      });
    }
  };

  loadMarker = e => {
    // this.setPositionMarker();
    setTimeout(() => {
      this.setPositionMarker();
    }, 10);
  };

  viewFile = e => {
    let file = e.target.files;
  };

  fetch() {
    const { drawingsWithSpots, currentDrawing } = this.props;
    if (drawingsWithSpots && currentDrawing !== null) {
      this.props.getDrawingWithSpots(
        drawingsWithSpots[this.currentDrawing].uniqueCode
      );
    }
    this.setState({ drawingWithSpots: this.props.drawingWithSpots });
  }

  addPoint = () => {
    this.setState({ isAddPoint: !this.state.isAddPoint });
  };

  showDialogAddPoint = e => {
    let element = document.getElementById("primary-image-dialog");
    let offsetWidth = element ? element.offsetWidth : 0;
    let offsetHeight = element ? element.offsetHeight : 0;

    let naturalWidth = element ? element.naturalWidth : 0;

    let naturalHeight = element ? element.naturalHeight : 0;

    const scaleData = this.getZoomOffsetAndScale(
      document.getElementsByClassName("react-transform-element")[0]
    );

    const userAgent = window.navigator.userAgent;
    let pos = null;
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/Macintosh/i)
    ) {
      // iPad or iPhone
      pos = {
        x: Math.round(
          (((e.pageX - element.getBoundingClientRect().left - window.scrollX) /
            scaleData.scale) *
            naturalWidth) /
            offsetWidth
        ),
        y: Math.round(
          (((e.pageY - element.getBoundingClientRect().top - window.scrollY) /
            scaleData.scale) *
            naturalHeight) /
            offsetHeight
        )
      };
    } else {
      // Anything else
      pos = {
        x: Math.round(
          (((e.pageX - element.getBoundingClientRect().left) /
            scaleData.scale) *
            naturalWidth) /
            offsetWidth
        ),
        y: Math.round(
          (((e.pageY - element.getBoundingClientRect().top) / scaleData.scale) *
            naturalHeight) /
            offsetHeight
        )
      };
    }

    this.props.toggleDialogAddPoint(
      pos,
      this.props.drawingWithSpots.uniqueCode
    );
  };

  getZoomOffsetAndScale = element => {
    const transform = element.style.transform;

    const offset = transform
      .substring(transform.indexOf("translate(") + 10, transform.indexOf(")"))
      .replace(/px/g, "")
      .split(",");

    const offsetX = offset[0] ? offset[0].trim() : 0;
    const offsetY = offset[1] ? offset[1].trim() : 0;
    const scale = transform.substring(
      transform.indexOf("scale(") + 6,
      transform.lastIndexOf(")")
    );

    return {
      offsetX: -offsetX,
      offsetY: -offsetY,
      scale: scale
    };
  };

  showListDraw = e => {
    this.setState({ showList: e.currentTarget });
  };

  closeListDraw = e => {
    this.setState({ showList: false });
  };

  handleSwitchDrawing = uniqueCode => {
    const { drawingCode, drawingsWithSpots } = this.props;
    this.setState({ disableButton: true });
    const drawing = drawingsWithSpots.find(
      item => item.uniqueCode === uniqueCode
    );
    const currentSelect = drawingsWithSpots.findIndex(
      item => item.uniqueCode === uniqueCode
    );
    if (drawing) {
      this.setState({
        currentSelect: currentSelect,
        listSpots: drawing.spots,
        drawingName: drawing.name,
        drawing: drawing
      });
    }
    this.props.selectDrawing(currentSelect);
    this.closeListDraw();
  };

  returnDefaultSize = () => {
    let currentRef = this.wrapperRef.current;
    if (currentRef != null) {
      currentRef.context.dispatch.resetTransform();
    }
  };

  checkCreateLinkDisable = () => {
    if (
      !this.props.drawingWithSpots ||
      !this.props.drawingWithSpots.spots ||
      this.props.drawingWithSpots.spots.length <= 1 ||
      this.props.drawingWithSpots.spots.every(
        spot => spot.links && spot.links.length > 0
      )
    )
      return true;

    return false;
  };

  checkDeleteLinkDisable = () => {
    if (!this.props.drawingWithSpots || !this.props.drawingWithSpots.spots)
      return true;

    return !this.props.drawingWithSpots.spots.some(
      spot => spot.links?.length > 0
    );
  };

  render() {
    const {
      drawingsWithSpots,
      currentDrawing,
      hasWalkThroughFeature,
      showLinkInfos,
      toggleConfirmCreateLink,
      toggleConfirmDeleteLink
    } = this.props;
    const {
      positions,
      drawingWithSpots,
      isAddPoint,
      isVerticalImg,
      showList,
      disableButton,
      confirmCloseModeSettingLink
    } = this.state;
    const onEditSubmit = values => {
      // TODO: Submit data
    };

    return (
      <Formik enableReinitialize onSubmit={onEditSubmit}>
        {formik => (
          <>
            <Dialog
              open={this.props.open}
              onClose={() => {}}
              aria-labelledby="max-width-dialog-title"
              fullWidth
              maxWidth="lg"
              // scroll="body"
              PaperProps={{
                className:
                  this.state.screenOrientation === "portrait"
                    ? "container-mobile-header dialog paper-image disable-select drawing-dialog-content m-0 drawing-dialog-height-update-sp"
                    : "container-mobile-header dialog paper-image disable-select drawing-dialog-content m-0 drawing-dialog-height-update-sp justify-content-center mapDialog-ui"
              }}
              onKeyPress={event => {
                if (event.key === "Enter") {
                  formik.handleSubmit();
                }
              }}
            >
              <Grid
                container
                item
                xs={2}
                justify="center"
                className={`btn-backdialog ${
                  this.state.screenOrientation === "portrait"
                    ? "adjust-img-btn_back_portrait"
                    : "adjust-img-btn_back_lanscape"
                }`}
              >
                <Fab
                  onClick={() => {
                    if (
                      hasWalkThroughFeature &&
                      showLinkInfos &&
                      drawingsWithSpots.some(
                        drawing =>
                          drawing.spots.length > 1 &&
                          drawing.spots.some(
                            spot => !spot.links || spot.links.length === 0
                          )
                      )
                    ) {
                      this.setState({ confirmCloseModeSettingLink: true });
                    } else {
                      this.props.onClose();
                    }
                  }}
                  size="small"
                >
                  <ArrowBackIcon />
                </Fab>
              </Grid>
              <Grid
                container
                item
                xs={2}
                justify="center"
                className={`${
                  this.state.screenOrientation === "portrait"
                    ? "adjust-img-btn_menu_portrait"
                    : "adjust-img-btn_menu_lanscape"
                }`}
              >
                <Fab
                  className="btn-choose-menu-mapdialog"
                  size="small"
                  aria-controls="simple-menu"
                  onClick={this.showListDraw}
                >
                  <MenuIcon />
                </Fab>
                <Menu
                  className="menu-list"
                  id="simple-menu"
                  anchorEl={showList}
                  getContentAnchorEl={null}
                  open={Boolean(showList)}
                  onClose={this.closeListDraw}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      // border: "2px solid #162f50",
                      color: "#162f50",
                      borderRadius: 0,
                      paddingRight: 10
                    }
                  }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  {drawingsWithSpots &&
                    drawingsWithSpots.map(item => (
                      // <Grid className="menu-item" key={item.uniqueCode}>
                      //   <Grid className="item-icon">
                      //     {drawing.uniqueCode === item.uniqueCode && (
                      //       <RoomIcon className="icon-selected" />
                      //     )}
                      //   </Grid>
                      //   <MenuItem
                      //     key={item.uniqueCode}
                      //     selected={drawing.uniqueCode === item.uniqueCode}
                      //     title={item.name}
                      //     onClick={() =>
                      //       this.handleSwitchDrawing(item.uniqueCode)
                      //     }
                      //   >
                      //     <Typography className="item-name">
                      //       {item.name}
                      //     </Typography>
                      //   </MenuItem>
                      // </Grid>
                      <MenuItem
                        className="menu-item"
                        key={item.uniqueCode}
                        selected={
                          drawingsWithSpots[this.props.currentDrawing]
                            .uniqueCode === item.uniqueCode
                        }
                        title={item.name}
                        onClick={() => {
                          this.returnDefaultSize();
                          setTimeout(() => {
                            this.handleSwitchDrawing(item.uniqueCode);
                          }, 200);
                        }}
                      >
                        <Grid
                          key={item.uniqueCode}
                          className="menu-item-container"
                        >
                          <Grid className="item-icon">
                            {drawingsWithSpots[this.props.currentDrawing]
                              .uniqueCode === item.uniqueCode && (
                              <RoomIcon className="icon-selected" />
                            )}
                          </Grid>
                          <Grid className="item-text">
                            <Typography className="item-name">
                              {item.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                </Menu>
              </Grid>

              <Grid item class="width-100">
                <div className="mapDialog-content">
                  <DialogTitle
                    disableTypography
                    className={`${
                      this.state.screenOrientation === "portrait" ? "" : "p-0"
                    }`}
                  >
                    <Grid
                      container
                      spacing={1}
                      justify="space-between"
                      className="container-mobile"
                    >
                      <Grid item className="text-center">
                        <Button
                          name="prev"
                          className="btn-switch"
                          size="small"
                          onClick={() => {
                            this.setState({ disableButton: true }, () => {
                              this.returnDefaultSize();
                              setTimeout(() => {
                                this.props.previousDrawing();
                              }, 200);
                            });
                          }}
                          disabled={disableButton || currentDrawing === 0}
                        >
                          <KeyboardArrowLeftIcon />
                        </Button>
                      </Grid>
                      <Grid item className="viewer-dialog-title">
                        <Typography variant="h6">
                          {this.state.drawingWithSpots != null
                            ? this.state.drawingWithSpots.name
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          name="next"
                          className="btn-switch"
                          size="small"
                          onClick={() => {
                            this.setState({ disableButton: true }, () => {
                              this.returnDefaultSize();
                              setTimeout(() => {
                                this.props.nextDrawing();
                              }, 200);
                            });
                          }}
                          disabled={
                            disableButton ||
                            currentDrawing >= drawingsWithSpots.length - 1
                          }
                        >
                          <KeyboardArrowRight />
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogTitle>
                  {/* <DialogContent className="image-content-dialog p-0"> */}
                  <DialogContent className="map-dialog-content overflow-hidden ">
                    <Grid
                      container
                      spacing={0}
                      className="container-mobile drawing-sp"
                    >
                      <Grid item xs={12}>
                        <div className="text-center content-drawing-dialog-sp">
                          {drawingWithSpots != undefined && (
                            <TransformWrapper defaultScale={1}>
                              <TransformComponent ref={this.wrapperRef}>
                                <Grid
                                  id="primary-map-dialog"
                                  className={`${isAddPoint ? "selected" : ""} ${
                                    isVerticalImg
                                      ? "vertical-sub-block-dialog"
                                      : ""
                                  }`}
                                >
                                  <DrawingPhoto
                                    id="primary-image-dialog"
                                    name={drawingWithSpots.name}
                                    drawingsUC={drawingWithSpots.uniqueCode}
                                    blob={drawingWithSpots.blob}
                                    isSP={isMobile}
                                    loadMarker={this.loadMarker}
                                    isAddPoint={true}
                                    showDialogAddPoint={this.showDialogAddPoint}
                                    toggleDialogEditPoint={
                                      this.props.toggleDialogEditPoint
                                    }
                                    positions={positions}
                                    showLinks={
                                      showLinkInfos && hasWalkThroughFeature
                                    }
                                  ></DrawingPhoto>
                                </Grid>
                              </TransformComponent>
                            </TransformWrapper>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </DialogContent>
                </div>

                {showLinkInfos && hasWalkThroughFeature ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <Button
                          onClick={() =>
                            toggleConfirmCreateLink(
                              this.props.drawingsWithSpots[
                                this.props.currentDrawing
                              ].uniqueCode
                            )
                          }
                          size="large"
                          className="confirm-button-outline"
                          style={
                            this.checkCreateLinkDisable()
                              ? {
                                  display: "none"
                                }
                              : {
                                width: "200px",
                                margin: "10px 10px 10px 0px",
                                backgroundColor:"white"
                              }
                          }
                          disabled={this.checkCreateLinkDisable()}
                        >
                          地点をつなぐ
                        </Button>
                        <Button
                          onClick={() =>
                            toggleConfirmDeleteLink(
                              this.props.drawingsWithSpots[
                                this.props.currentDrawing
                              ].uniqueCode
                            )
                          }
                          size="large"
                          className="confirm-button-delete"
                          disabled={this.checkDeleteLinkDisable()}
                          style={
                            this.checkDeleteLinkDisable()
                              ? {
                                  display: "none"
                                }
                              : {
                                  width: "200px",
                                  margin: "10px 0px 10px 10px",
                                  paddingLeft: "6px",
                                  paddingRight: "6px",
                                  letterSpacing: "0",
                                  backgroundColor: "red"
                              }
                          }
                        >
                          「地点をつなぐ」を解除
                        </Button>
                      </div>
                    </Grid>
                    {drawingWithSpots &&
                      drawingWithSpots.spots.length > 1 &&
                      drawingWithSpots.spots.some(
                        spot => !spot.links || spot.links.length === 0
                      ) && (
                        <Grid item xs={12} className="text-center white-text">
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "500" }}
                          >
                            ※「地点をつなぐ」未設定の地点があります
                          </Typography>
                        </Grid>
                      )}
                  </Grid>
                ) : (
                  <Grid>
                    <Grid item xs={12} className="text-center white-text">
                      <Typography variant="subtitle1">
                        {isMobile ? "長押しで地点を追加します" : "クリックで地点を追加します"}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Dialog>
            {confirmCloseModeSettingLink && (
              <CommonMapOptionDialogSP
                open={confirmCloseModeSettingLink}
                onClose={() =>
                  this.setState({ confirmCloseModeSettingLink: false })
                }
                title={
                  <>
                    <p
                      style={{
                        color: "#333",
                        fontSize: "16px",
                        fontWeight: "400"
                      }}
                    >
                      図面情報の設定を完了しますか？
                    </p>
                    <p
                      style={{
                        color: "#333",
                        fontSize: "16px",
                        fontWeight: "400"
                      }}
                    >
                      「地点をつなぐ」が未設定の地点が存在します。
                    </p>
                  </>
                }
                titleClasses="text-center"
                buttonOptions={[
                  {
                    buttonTitle: "完了",
                    buttonOnCick: () => {
                      this.setState({ confirmCloseModeSettingLink: false });
                      this.props.onClose();
                    },
                    buttonClasses: "confirm-button-blue"
                  },
                  {
                    buttonTitle: "設定へ戻る",
                    buttonOnCick: () =>
                      this.setState({ confirmCloseModeSettingLink: false }),
                    buttonClasses: "confirm-button-sliver"
                  }
                ]}
                PaperProps={{
                  className: "dialog dialog-sign-up map-option-dialog"
                }}
              ></CommonMapOptionDialogSP>
            )}
          </>
        )}
      </Formik>
    );
  }
}

MapDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

const mapStateToProps = state => ({
  type: state.rootSitesReducer.siteInfoReducer.type,
  error: state.storesReducer.error,
  drawingWithSpots: state.rootSitesReducer.siteInfoReducer.drawingWithSpots,
  drawingsWithSpots: getDrawingsWithSpots(
    state.rootSitesReducer.siteInfoReducer.drawingsWithSpots
  )
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDrawingWithSpots,
      dispatchError,
      fillError
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MapDialog));
