import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Fab from "@material-ui/core/Fab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PreviewImage from "./PreviewImage";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles({
  btnPrevShootFixSpot: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "0",
    zIndex: "299",
    "&:focus": {
      backgroundColor: "#8FC31F"
    }
  },
  
  btnNextShootFixSpot: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "0",
    zIndex: "299",
    "&:focus": {
      backgroundColor: "#8FC31F"
    }
  }
});


function FixSpotsShootList(props) {
  const {
    listShootInfo,
    showShoot,
    setShowShoot,
    drawingsWithSpots,
    setShowConfirmRemoveShootFromSpot
  } = props;

  const classes = useStyles();

  const [showHideButtonNextPrev, setShowHideButtonNextPrev] = useState({
    showHideBtnPrev: false,
    showHideBtnNext: false
  });

  useEffect(() => {
    if (listShootInfo !== []) {
      checkShowHideButtonNextPrev();
    }
  }, [listShootInfo]);

  const checkShowHideButtonNextPrev = () => {
    let hideBtnPrev = false;
    let hideBtnNext = false;
    const el = document.getElementsByClassName("grid-list")[0];

    if (el && el.scrollLeft > 0) {
      hideBtnPrev = true;
    } else {
      hideBtnPrev = false;
    }

    if (el && el.scrollLeft < el.scrollWidth - el.clientWidth) {
      hideBtnNext = true;
    } else {
      hideBtnNext = false;
    }

    setShowHideButtonNextPrev({
      showHideBtnPrev: hideBtnPrev,
      showHideBtnNext: hideBtnNext
    });
  };

  const getShootTitle = shoot => {
    if (!shoot.spotsUC || drawingsWithSpots.length === 0) return "地点未登録";
    const spotsUC = shoot.spotsUC;
    const drawing = drawingsWithSpots.find(drawingItem =>
      drawingItem.spots.find(spot => spot.uniqueCode === spotsUC)
    );
    if (drawing) {
      return `${drawing.name} / ${shoot.spotName}`;
    }
  };

  const scroll = (e, isNext) => {
    let delta = 1;
    if (isNext) {
      delta = -1;
    }

    const el = document.getElementsByClassName("grid-list")[0];
    if (el)
      document.getElementsByClassName("grid-list")[0].scrollLeft -= delta * 280;
    checkShowHideButtonNextPrev();
  };

  return (
    <Grid
      id={"list_drawings"}
      className="position-relative"
      style={{ width: "100%", margin: "20px 0px" }}
    >
      {listShootInfo.length > 0 && (
        <div className={`grid-list`} onScroll={checkShowHideButtonNextPrev}>
          {showHideButtonNextPrev.showHideBtnPrev && (
            <Fab
              size="small"
              style={{
                margin: "10px",
                backgroundColor: "#8FC31F",
                fontSize: "24px",
                color: "#ffffff"
              }}
              className={classes.btnPrevShootFixSpot}
              onClick={e => scroll(e, false)}
            >
              <ArrowBackIcon fontSize="inherit" color="inherit" />
            </Fab>
          )}
          {listShootInfo.map(shootInfo => (
            <div key={shootInfo.uniqueCode} style={{ margin: "0px 10px" }}>
              <Grid container alignItems="center">
                {shootInfo.spotsUC ? (
                  <div
                    style={{
                      width: "28px",
                      height: "28px",
                      lineHeight: "28px",
                      margin: "2px 16px 2px 6px",
                      fontSize: "28px",
                      color: "#8fc31f",
                      cursor: "pointer"
                    }}
                    onClick={() =>
                      setShowConfirmRemoveShootFromSpot(shootInfo.uniqueCode)
                    }
                  >
                    <CancelIcon color="inherit" fontSize="inherit" />
                  </div>
                ) : (
                  <div
                    style={{
                      width: "24px",
                      height: "24px",
                      backgroundColor: "#737070",
                      border: "2px solid #3D3D3D",
                      margin: "4px 18px 4px 8px",
                      borderRadius: "999px"
                    }}
                  />
                )}

                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: shootInfo.spotsUC ? "bold" : "light",
                    maxWidth: "200px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden"
                  }}
                >
                  {getShootTitle(shootInfo)}
                </span>
              </Grid>

              <div
                style={{
                  height: "136px",
                  width: "264px",
                  cursor: "pointer",
                  overflow: "hidden",
                  border:
                    showShoot && showShoot.uniqueCode === shootInfo.uniqueCode
                      ? "4px solid #8FC31F"
                      : "4px solid #FFF"
                }}
                onClick={() => {
                  if (
                    showShoot &&
                    showShoot.uniqueCode === shootInfo.uniqueCode
                  )
                    return;

                  setShowShoot(shootInfo);
                }}
              >
                <PreviewImage
                  apiPath={shootInfo.blob ? shootInfo.blob : shootInfo.image}
                />
              </div>
            </div>
          ))}

          {showHideButtonNextPrev.showHideBtnNext && (
            <Fab
              className={classes.btnNextShootFixSpot}
              size="small"
              style={{
                margin: "10px",
                backgroundColor: "#8FC31F",
                fontSize: "24px",
                color: "#ffffff"
              }}
              onClick={e => scroll(e, true)}
            >
              <ArrowForwardIcon fontSize="inherit" color="inherit" />
            </Fab>
          )}
        </div>
      )}
    </Grid>
  );
}

export default FixSpotsShootList;
