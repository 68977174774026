import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";

const mediaTiniMobile = "(max-width: 430px)";
const useStyles = makeStyles({
  buttonWrapper: {
    width: "236px",
    height: "40px",
    borderRadius: "7px",
    display: "flex",
    position: "relative",
    backgroundColor: "#C7C7C7",
    fontFamily: "roboto",
    marginRight: "14px",
    fontSize: "17px",
    fontWeight: "normal"
  },
  buttonWrapperTiniSP: {
    width: "200px",
    height: "40px",
    borderRadius: "7px",
    display: "flex",
    position: "relative",
    backgroundColor: "#C7C7C7",
    fontFamily: "roboto",
    marginRight: "10px",
    fontSize: "14px",
    fontWeight: "normal",
    letterSpacing: "0px"
  },
  button: {
    width: "50%",
    height: "100%",
    zIndex: 2,
    color: "white"
  },
  bg: {
    borderRadius: "7px",
    backgroundColor: "#00356D",
    position: "absolute",
    height: "100%",
    width: "50%",
    top: "0",
    left: "0",
    transition: "left 0.3s"
  }
});

export default function ChooseLogWalkModeButton(props) {
  const { setIsViewVRSpace, isViewVRSpace } = props;
  const classes = useStyles();
  const isTiniMobile = useMediaQuery({ query: mediaTiniMobile });
  return (
    <div className={ isTiniMobile ? classes.buttonWrapperTiniSP : classes.buttonWrapper}>
      <button
        className={classes.button}
        onClick={() => isViewVRSpace || setIsViewVRSpace(true)}
      >
        VR空間を見る
      </button>
      <button
        className={classes.button}
        onClick={() => isViewVRSpace && setIsViewVRSpace(false)}
      >
        タスクを見る
      </button>
      <div
        className={classes.bg}
        style={{ left: isViewVRSpace ? "0" : "50%" }}
      ></div>
    </div>
  );
}
