import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Typography } from "@material-ui/core";
import SearchField from "../../SearchField";
import "../../../common/css/base.css";
import "../../../common/css/sites-staft.css";

class StaftSP extends Component {

  onSearchChange = value => {
    this.props.onChangeSearch(value);
  };

  render() {
    const { staffs, showDialog, showPopover, furiganaSearch } = this.props;

    return (
      <>
        <Typography
          variant="h5"
          className="dashboard-sp-title font-family text-bold"
        >
          社員の管理
        </Typography>
        <Grid container justify="flex-start">
          <Grid item xs={12} sm={6} style={{padding: "8px 12px"}}>
            <SearchField
              placeholder="社員名から検索"
              setValue={this.onSearchChange}
              value={furiganaSearch}
            />
          </Grid>
        </Grid>
        <MenuList style={{ paddingBottom: 100 }}>
          {staffs.map(staff => (
            <MenuItem key={staff.uniqueCode} className="dashboard-sp-item">
              <Grid container justify="space-between" wrap="nowrap">
                <Grid className="item-left">
                  <Typography
                    variant="h5"
                    className="text-bold font-family white-space-normal"
                    style={{ fontSize: "1rem" }}
                  >
                    {staff.name}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className="font-family grey-light white-space-normal"
                  >
                    {staff.jobName}
                  </Typography>
                </Grid>
                <Grid
                  className="item-right"
                  style={{ flexDirection: "row", alignItems: "center" }}
                >
                  {(this.props.isAdmin || this.props.userUC === staff.uniqueCode) &&
                    (
                      <Fab
                        onClick={showPopover(staff)}
                        size="small"
                        className="more-vert-btn"
                      >
                        <MoreVertIcon />
                      </Fab>
                    )}
                </Grid>
              </Grid>
            </MenuItem>
          ))}
        </MenuList>
        {this.props.isAdmin && 
        <Fab className="button-add-sp">
          <label onClick={showDialog} style={{ color: "inherit" }}>
            <AddIcon className="white-text icon" />
          </label>
        </Fab>
        }
      </>
    );
  }
}

export default StaftSP;
