import React from "react";

function IconFullImage(props) {
  const { isActive, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
    >
      <defs>
        <filter
          id="a"
          width="48"
          height="48"
          x="0"
          y="0"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3"></feOffset>
          <feGaussianBlur result="b" stdDeviation="3"></feGaussianBlur>
          <feFlood floodOpacity="0.161"></feFlood>
          <feComposite in2="b" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
      </defs>
      <g filter="url(#a)" transform="translate(8.871 6) translate(-8.87 -6)">
        <path fill="none" d="M0 0H30V30H0z" transform="translate(9 6)"></path>
      </g>
      <g fill={isActive ? "#fff" : "#333"} stroke={isActive ? "#fff" : "#333"}>
        <path
          d="M1.53.893h4.3a.45.45 0 000-.9H.448A.448.448 0 000 .448v5.379a.45.45 0 00.9 0v-4.3l7.3 7.3a.448.448 0 00.634-.634zM8.201 14.819l-7.3 7.3v-4.3a.45.45 0 00-.9 0v5.379a.448.448 0 00.448.448h5.379a.45.45 0 100-.9h-4.3l7.3-7.3a.448.448 0 00-.634-.634zM23.105 17.378a.448.448 0 00-.448.448v4.3l-7.3-7.3a.448.448 0 00-.634.634l7.3 7.3h-4.3a.45.45 0 000 .9h5.379a.448.448 0 00.448-.448v-5.379a.448.448 0 00-.445-.455zM23.206 0h-5.379a.45.45 0 100 .9h4.2l-7.3 7.3a.448.448 0 10.634.634l7.4-7.4v4.4a.45.45 0 10.9 0V.448A.448.448 0 0023.206 0z"
          transform="translate(8.871 6) translate(3.606 3.713)"
        ></path>
      </g>
    </svg>
  );
}

IconFullImage.defaultProps = {
  size: "40"
}

export default IconFullImage;
