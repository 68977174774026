import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Label from "./editModeComponents/Label";
import SelectInfoIcon from "./editModeComponents/SelectInfoIcon";
import TitleInput from "./editModeComponents/TitleInput";
import MentionsInput from "./editModeComponents/MentionsInput";
import MentionsInputData from "./editModeComponents/MentionsInputData";
import DescriptionInput from "./editModeComponents/DescriptionInput";
import { useSelector } from "react-redux";
import { api } from "../../../../../common/api/api";
import { useLocation, useHistory } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    width: "100%",
    height: "100%",
    backgroundColor: "white"
  },
  header: {
    color: "white",
    backgroundColor: "#00356D",
    fontSize: "17px",
    lineHeight: "40px",
    textAlign: "center"
  },
  mainForm: {
    padding: "30px 20px"
  },
  actionSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    padding: "0px 20px 100px",
    "& button": {
      width: "203px",
      height: "50px",
      borderRadius: "5px",
      fontWeight: "bold",
      textAlign: "center",
      color: "white"
    }
  },
  checkboxNotify: {
    color: "#00356D",
    "&.checked": {
      color: "#00356D"
    }
  }
});

function ShowEditHotspotInfo(props) {
  const classes = useStyles();

  const [allIcons, setAllIcons] = useState([]);
  const [allMentions, setAllMentions] = useState([]);
  const [icon, setIcon] = useState(null);
  const [title, setTitle] = useState("");
  const [isEmptyTitle, setIsEmptyTitle] = useState(false);
  const [description, setDescription] = useState("");
  const [mentions, setMentions] = useState([]);
  const [notify, setNotify] = useState(true);
  const [loadInit, setLoadInit] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [hotspotInfo, setHotspotInfo] = useState(null);
  const { handleEditSuccess, handleCloseEdit, currentHotspotInfoId } = props;

  const logWalkInfo = useSelector(
    state => state.rootSitesReducer.logWalkViewReducer.logWalkInfo
  );
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setIsUpdating(false);

    if (hotspotInfo) {
      setIcon(hotspotInfo.icon);
      setTitle(hotspotInfo.title);
      setDescription(hotspotInfo.description);
      setMentions(hotspotInfo.mentions);
    }
  }, [hotspotInfo]);

  useEffect(() => {
    setLoadInit(true);
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    api
      .get(`/${classification}/logwalk-viewer/infos/${currentHotspotInfoId}`)
      .then(res => {
        setHotspotInfo({ ...res.data, uniqueCode: currentHotspotInfoId });
        const fetchAllMentions = api.get(
          `/${classification}/logwalk-viewer/infos/${currentHotspotInfoId}/mentions`
        );
        const fetchAllColors = api.get(
          `/${classification}/logwalk-viewer/builders/${logWalkInfo.buildersUC}/info-icons`
        );
        Promise.all([fetchAllMentions, fetchAllColors]).then(res => {
          setAllMentions(res[0].data);
          setAllIcons(res[1].data);
          setLoadInit(false);
        });
      });
  }, []);

  const handleCancel = () => {
    handleCloseEdit();
  };

  const handleSave = () => {
    if (title === "") {
      setIsEmptyTitle(true);
      return;
    }

    setIsEmptyTitle(false);
    setIsUpdating(true);
    let data = {};
    data.iconsUC = icon.uniqueCode;
    data.title = title;
    data.description = description;
    if (mentions.length > 0) {
      data.mentions = mentions.map(mention => mention.uniqueCode);
    } else {
      data.mentions = [];
    }
    data.notify = notify;

    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    api
      .put(
        `/${classification}/logwalk-viewer/infos/${currentHotspotInfoId}`,
        data
      )
      .then(() => {
        setIsUpdating(false);
        handleEditSuccess();
      })
      .catch(err => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        history.go(0);
      });
  };

  return (
    <div className={classes.root}>
      {!loadInit && hotspotInfo ? (
        <React.Fragment>
          <div className={classes.header}>編集</div>
          <div className={classes.mainForm}>
            <Label label="アイコンを選択" />
            <SelectInfoIcon allIcons={allIcons} icon={icon} setIcon={setIcon} />

            <Label label="タイトル" />
            <TitleInput
              title={title}
              setTitle={setTitle}
              requiredError={isEmptyTitle}
              placeholder="タイトル入力"
            />

            <Label label="報告内容" />
            <DescriptionInput
              description={description}
              setDescription={setDescription}
              placeholder="報告内容を入力"
            />

            <Label label="通知メンバー" />
            <MentionsInput
              allMentions={allMentions}
              addedMentions={mentions}
              setMentions={setMentions}
            />

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notify}
                    onChange={event => setNotify(event.target.checked)}
                    className={classes.checkboxNotify}
                    color="default"
                  />
                }
                label="この更新を通知する"
                style={notify ? { color: "#383838" } : { color: "#888888" }}
              />
            </div>
          </div>

          <div className={classes.actionSection}>
            <button
              style={{
                backgroundColor: "#00356D",
                marginRight: "34px",
                touchAction: "manipulation",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
              onClick={() => {
                if (!isUpdating) {
                  handleSave();
                }
              }}
            >
              <span style={{ marginRight: "8px" }}>更新</span>
              {isUpdating && <CircularProgress size="24px" color="inherit" />}
            </button>
            <button
              style={{
                backgroundColor: "#C7C7C7",
                touchAction: "manipulation"
              }}
              onClick={handleCancel}
            >
              キャンセル
            </button>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
}

ShowEditHotspotInfo.propTypes = {};

export default ShowEditHotspotInfo;
