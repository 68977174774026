import {
  fetchLogBuildAuthSuccess,
  fetchLogBuildStaffSuccess,
  postLogBuildStaffSuccess,
  fetchLogBuildStaffDetailSuccess,
  putLogBuildStaffSuccess,
  deleteLogBuildStaffSuccess,
  putPasswordLogBuildSuccess
} from "../action/action";
import { api } from "../../../../common/api/api";
import {
  dispatchError,
  enableLoading,
  finishLoading
} from "../../../../common/Stores/service/service";

export const fetchLogBuildAuth = () => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/auths/entries`)
      .then(res => {
        dispatch(fetchLogBuildAuthSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(error => {
        dispatch(dispatchError(error));
      });
  };
};

export const fetchLogBuildStaff = () => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/staffs`)
      .then(res => {
        dispatch(fetchLogBuildStaffSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(error => {
        dispatch(dispatchError(error));
      });
  };
};

export const fetchLogBuildStaffDetail = id => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .get(`/logbuild/staffs/${id}`)
      .then(res => {
        dispatch(fetchLogBuildStaffDetailSuccess(res.data));
        dispatch(finishLoading());
      })
      .catch(error => {
        dispatch(dispatchError(error));
      });
  };
};

export const addStaffLogBuild = (data, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .post(`/logbuild/staffs`, data)
      .then(res => {
        dispatch(postLogBuildStaffSuccess());
        dispatch(fetchLogBuildStaff());
      })
      .catch(error => {
        if(handleError && error.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(error));
        }
      });
  };
};

export const updateStaffLogBuild = (id, data, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/logbuild/staffs/${id}`, data)
      .then(res => {
        dispatch(putLogBuildStaffSuccess());
        dispatch(fetchLogBuildStaff());
      })
      .catch(error => {
        if(handleError && error.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(error));
        }
      });
  };
};

export const deleteStaffLogBuild = (id, handleError) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .delete(`/logbuild/staffs/${id}`)
      .then(res => {
        dispatch(deleteLogBuildStaffSuccess());
        dispatch(fetchLogBuildStaff());
      })
      .catch(error => {
        if(handleError && error.response.status === 500) {
          handleError();
        } else {
          dispatch(dispatchError(error));
        }
      });
  };
};

export const updateStaffPaswordLogBuild = (id, data) => {
  return dispatch => {
    dispatch(enableLoading());
    api
      .put(`/logbuild/staffs/${id}/password`, data)
      .then(res => {
        dispatch(putPasswordLogBuildSuccess());
        dispatch(fetchLogBuildStaff());
      })
      .catch(error => {
        dispatch(dispatchError(error));
      });
  };
};
