export const FETCH_STAFFS_SUCCESS = "FETCH_STAFFS_SUCCESS";
export const POST_STAFFS_SUCCESS = "POST_STAFFS_SUCCESS";
export const PUT_STAFFS_SUCCESS = "PUT_STAFFS_SUCCESS";
export const DELETE_STAFFS_SUCCESS = "DELETE_STAFFS_SUCCESS";
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const GET_STAFFS_DETAIL_SUCCESS = "GET_STAFFS_DETAIL_SUCCESS";
export const MOUNT_PASSWORD_DIALOG_SUCCESS = "MOUNT_PASSWORD_DIALOG_SUCCESS";
export const PUT_PASSWORD_SUCCESS = "PUT_PASSWORD_SUCCESS";
export const GET_USER_DETAIL_SUCCESS= "GET_USER_DETAIL_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
