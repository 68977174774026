export const TYPE_SELECT_IMAGE_3D = {
  HISTORY: 1,
  SPOT: 2,
  VIEWER: 3
};

export const TYPE_DIALOG = {
  ADJUST_IMAGE: 1,
  ZOOM_IMAGE: 2
};

export const MODE_DEVICE = {
  PC: "pc",
  SP: "sp"
};

export const IMAGE_3D_LOAD_STATUS = {
  READY: "ready",
  WAITING: "waiting",
};
