import React, { useEffect, useState } from "react";
import { api } from "../../../../../../common/api/api";
import { useLocation } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import FilePdf from "./FilePdf";
import { Table, TableBody } from "@material-ui/core";

function ChooseExistedFilePdfTab(props) {
  const { sitesUC, selectedFiles, selectFile, excludeSelects, isTabletOrMobile } = props;
  const [loading, setLoading] = useState(false);
  const [allFileInfos, setAllFileInfos] = useState([]);

  const location = useLocation();

  useEffect(() => {
    getAllFileInfos();
  }, []);

  const getAllFileInfos = () => {
    setLoading(true);
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    api
      .get(`/${classification}/site-files?sitesUC=${sitesUC}`)
      .then(res => {
        setAllFileInfos(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {loading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : allFileInfos.length !== 0 ? (
        <Table size="small" aria-label="table" className="sites-table">
          <TableBody className="table-body">
            {allFileInfos.map(fileInfo => (
              <FilePdf
                key={fileInfo.fileId}
                fileInfo={fileInfo}
                selectedFiles={selectedFiles}
                selectFile={selectFile}
                isTabletOrMobile={isTabletOrMobile}
                excludeSelects={excludeSelects}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <Box sx={{ display: "flex" }}>
          <span>ファイルはありません</span>
        </Box>
      )}
    </div>
  );
}

export default ChooseExistedFilePdfTab;
