import React from "react";
import { arrayBufferToBase64 } from "../../../utils/mapper";
import { api } from "../../../common/api/api";

class PreviewImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: ""
    };
  }

  componentWillMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.apiPath !== this.props.apiPath) this.fetch();
  }

  fetch() {
    if (this.props.apiPath) {
      let isBlob = true;
      if (this.props.apiPath.includes("/file?bin")) {
        isBlob = false;
      }
      api
        .get(
          this.props.apiPath.replace("/api", ""),
          isBlob
            ? {}
            : {
                responseType: "arraybuffer"
              }
        )
        .then(res => {
          if (res.data) {
            this.setState({ isTile: res.data.geoType === "Tile" });
            if (isBlob) {
              this.setState({
                imgSrc:
                  res.data.geoType === "EQR"
                    ? res.data.blobDataLst[0]
                    : res.data.blobDataLst.find(o => o.layer === "/preview.jpg")
                        .url
              });
            } else {
              this.setState({
                imgSrc: `data:image/jpeg;base64,${arrayBufferToBase64(
                  res.data
                )}`
              });
            }
          }
        })
        .catch(e => {
          if (e?.response?.status === 404) {
          }
        });
    }
  }

  render() {
    return this.state.imgSrc ? (
      this.state.isTile ? (
        <div style={{transform: "translate(128px, 32px) scale(2)"}}>
          <div
            class="sprite img0"
            style={{
              backgroundImage: `url(${this.state.imgSrc})`
            }}
          ></div>
          <div
            class="sprite img1"
            style={{
              backgroundImage: `url(${this.state.imgSrc})`
            }}
          ></div>
          <div
            class="sprite img2"
            style={{
              backgroundImage: `url(${this.state.imgSrc})`
            }}
          ></div>
        </div>
      ) : (
        <img src={this.state.imgSrc} alt="Preview" className="img-adjust" />
      )
    ) : (
      <div style={{ backgroundColor: "black" }} className="img-adjust"></div>
    );
  }
}

export default PreviewImage;
