import {FETCH_AUTHS_PENDING,FETCH_AUTHS_SUCCESS,FETCH_AUTHS_ERROR} from '../constant/constant';

export const fetchAuthsPending = () => {
  return {
    type: FETCH_AUTHS_PENDING
  }
};

export const fetchAuthsSuccess = (auths) => {
  return {
    type: FETCH_AUTHS_SUCCESS,
    auths: auths
  }
};

export const fetchAuthsError = (error) => {
  return {
    type: FETCH_AUTHS_ERROR,
    error: error
  }
};