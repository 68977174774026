import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { api } from "../../../common/api/api";
import { Grid, makeStyles, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PhoneIcon from "@material-ui/icons/Phone";
import ReloadIcon from "../../../common/img/ReloadIcon.svg";
import ImageIcon from "../../../common/img/ImageIcon.svg";
import Pagination from "@material-ui/lab/Pagination";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { enableLoading, finishLoading } from "common/Stores/service/service";
import Fab from "@material-ui/core/Fab";
import logmeetConfig from "../../../common/config/LogmeetConfig";
import { configStorage } from "../../../common/storage/storage";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles({
  paginationUl: {
    margin: "0",
    display: "flex",
    padding: "0",
    flexWrap: "wrap",
    listStyle: "none",
    alignItems: "center",
    justifyContent: "center"
  },
  truncateText3: {
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "wrap",
    wordBreak: "break-word"
  }
});

function LogMeetHistoryAll(props) {
  const { sitesUC, toggleHistoryDetailModal, setCurrentItemIndex } = props;
  const [isShowConfirmJoinCall, setIsShowConfirmJoinCall] = useState(false);
  const [allHistories, setAllHistories] = useState([]);
  const [historiesOffset, setHistoriesOffset] = useState(0);
  const [historiesCount, setHistoriesCount] = useState(20);
  const location = useLocation();
  const NumberOfPage = 20;
  const [pagination, setPagination] = useState(null);
  const [talkDetail, setTalkDetail] = useState(null);
  const [paginationNumber, setPaginationNumber] = useState(1);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentUserUniqueCode, setCurrentUserUniqueCode] = useState(null);
  const [disableButtonJoinCall, setDisableButtonJoinCall] = useState(false);
  const [isNobodyInCall, setIsNobodyInCall] = useState(false);
  useEffect(() => {
    if (sitesUC) {
      const token = api.defaults.headers.common["Authorization"];
      const decoded = jwt_decode(token);
      setCurrentUserUniqueCode(decoded.uniqueCode);
      fetchHistories();
    }
  }, [sitesUC]);

  const fetchHistories = () => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/site/${sitesUC}/talkhistories?offset=${historiesOffset}&count=${historiesCount}`)
      .then(res => {
        setAllHistories(res.data.talkHistory);
        if (res.data.total > NumberOfPage) {
          setPagination({
            total_pages: Math.ceil(res.data.total / NumberOfPage),
            paginationNumber: paginationNumber
          })
        }
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  };

  const changePage = (value) => {
    dispatch(enableLoading());
    setPaginationNumber(value);
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/site/${sitesUC}/talkhistories?offset=${value === 1 ? 0 : (value - 1) * historiesCount}&count=${historiesCount}`)
      .then(res => {
        setAllHistories(res.data.talkHistory);
        if (res.data.total > NumberOfPage) {
          setPagination({
            total_pages: Math.ceil(res.data.total / NumberOfPage),
            paginationNumber: paginationNumber
          })
        }
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  };

  const diffMinutes = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  const ReloadPage = (page) => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/site/${sitesUC}/talkhistories?offset=${page === 1 ? 0 : (page - 1) * historiesCount}&count=${historiesCount}`)
      .then(res => {
        setAllHistories(res.data.talkHistory);
        if (res.data.total > NumberOfPage) {
          setPagination({
            total_pages: Math.ceil(res.data.total / NumberOfPage),
            paginationNumber: paginationNumber
          })
        }
        dispatch(finishLoading());
      })
      .catch(err => {
        dispatch(finishLoading());
      });
  }

  const toggleConfirmJoinCallModal = () => {
    setIsShowConfirmJoinCall(!isShowConfirmJoinCall);
  }

  const checkJoinCall = (uid) => {
    dispatch(enableLoading());
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";
    api
      .get(`/${classification}/logmeet/talk/${uid}/users`)
      .then(res => {
        setTalkDetail(
          {
            ...res.data,
            uid: uid
          })
        var currentUserIndex = res.data.users.findIndex(
          user => user.uid === currentUserUniqueCode && user.status === 1
        );
        var joiner = res.data.users.filter(user => user.status === 1);
        setDisableButtonJoinCall(currentUserIndex >= 0 || joiner.length >= 4);
        setIsNobodyInCall((currentUserIndex >= 0 && joiner.length === 1) || joiner.length === 0);
        dispatch(finishLoading());
        toggleConfirmJoinCallModal();
      })
      .catch(err => {
        setTalkDetail(null)
        dispatch(finishLoading());
      });
  }

  return (
    <>
      <Grid container className="site-logmeet-tab-all-container">
        <div className="site-logmeet-tab-refresh-button">
          <Button
            style={{
              color: "#162F50",
              fontFamily: "Noto Sans JP",
              fontWeight: "normal",
              fontSize: "14px",
              cursor: "pointer",
              width: "80px",
              height: "32px",
              borderRadius: "20px",
              border: "solid 1px #162F50"
            }}
            onClick={() => {
              ReloadPage(paginationNumber);
            }}
          >
            更新
            <img src={ReloadIcon} />
          </Button>
        </div>
        <Grid item xs={12}>
          <Grid container className="table-remove-overflow">
            <Grid item xs={12} style={{ backgroundColor: "#162f50", height: "48px", lineHeight: "48px", padding: "0 13px" }}>
              <Grid container >
                <Grid item xs={2}
                  className="white-text text-left"
                >
                  <span style={{ verticalAlign: "middle" }}>通話日時</span>
                </Grid>
                <Grid item xs={1}
                  className="white-text text-left"
                >

                </Grid>
                <Grid
                  item xs={3}
                  className="white-text text-left"
                >
                  発信者
                </Grid>
                <Grid
                  item xs={2}
                  className="white-text text-left"
                >
                  写真
                </Grid>
                <Grid
                  item xs={4}
                  className="white-text text-left"
                >
                  タイトル
                </Grid>
              </Grid>
            </Grid>
            {allHistories.length > 0 &&
              allHistories.map(
                (item, index) => (
                  <Grid item xs={12}
                    id={`history-item-${index}`}
                    key={item.id}
                    className={`history-call-row ${!item.endAt ? "history-call-row-calling" : ""}`}
                    onClick={() => {
                      if (!item.endAt) {
                        checkJoinCall(item.uid);
                      }
                      else {
                        setCurrentItemIndex(index);
                        toggleHistoryDetailModal(item);
                      }
                    }}
                    style={{
                      cursor: "pointer"
                    }}
                  >
                    <Grid container style={{ height: "48px", lineHeight: "48px", verticalAlign: "middle", padding: "0 13px" }}>
                      <Grid item xs={2}>
                        <p>
                          {format(new Date(item.startAt), "yyyy年M月d日 HH:mm")}
                        </p>
                      </Grid>
                      <Grid item xs={1} className="text-center">
                        {item.endAt
                          ?
                          (
                            <>
                              {diffMinutes(new Date(item.startAt), new Date(item.endAt))}分
                            </>
                          )
                          :
                          (
                            <>
                              <span
                                style={{
                                  backgroundColor: "#8FC31F",
                                  color: "white",
                                  padding: "4px 10px",
                                  borderRadius: "24px",
                                  fontSize: "14px"
                                }}
                              >通話中</span>
                            </>
                          )
                        }
                      </Grid>
                      <Grid item xs={3}>
                        <p className="text-overflow-ellipsis">
                          {item.caller.name}
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        {item.images.length > 0 &&
                          (
                            <>
                              <img src={ImageIcon} style={{ verticalAlign: "middle" }} /> ({item.images.length}枚)
                            </>
                          )}
                      </Grid>
                      <Grid item xs={4}>
                        <p className="text-overflow-ellipsis">
                          {item.title}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              )}
          </Grid>
          {pagination && pagination.total_pages > 1 && (
            <Pagination
              classes={{ ul: classes.paginationUl }}
              count={pagination ? pagination.total_pages : 0}
              page={paginationNumber}
              onChange={(event, value) => {
                changePage(value);
              }
              }
              style={{
                border: "none",
                backgroundColor: "#fff",
                boxShadow: "none"
              }}
            />
          )}
        </Grid>
      </Grid>
      {isShowConfirmJoinCall && (
        <>
          <div className="join-call-confirm-wrapper">
            <div className="join-call-confirm-container">
              <Grid container>
                <Fab
                  size="small"
                  className="close-button"
                  style={{ backgroundColor: "#162F50" }}
                  onClick={() => {
                    toggleConfirmJoinCallModal();
                  }}
                >
                  <CloseIcon style={{ color: "white" }} />
                </Fab>
                <Grid item xs={12}
                  style={{
                    textAlign: "center",
                    fontFamily: "M PLUS 1",
                    color: "#333333",
                    fontSize: "16px",
                    fontWeight: "bold"
                  }}>
                  この通話に参加しますか？
                </Grid>
                <Grid item xs={12}>
                  <Grid container className="user-join-container">
                    {(!isNobodyInCall) && (talkDetail.users.length > 0) &&
                      talkDetail.users.map(
                        (item, index) => {
                          if (item.uid != currentUserUniqueCode && item.status === 1) {
                            return (
                              <Grid item
                                className={`user-join`}
                              >
                                <span>{item.name}</span>
                              </Grid>
                            )
                          }
                        }
                      )}
                    {isNobodyInCall && (
                      <Grid item xs={12}
                        className={`text-center`}
                      >
                        <span>通話には誰もいない</span>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Button
                    disabled={disableButtonJoinCall}
                    className={`${disableButtonJoinCall ? "button-disable" : ""}`}
                    onClick={() => {
                      const classification = window.location.pathname.includes("/sp/")
                        ? 1 // Supplier
                        : 0 // Builder;

                      const logMeetUrl = logmeetConfig.getLogMeetURL(window.location.hostname);
                      const url = `${logMeetUrl}?${new URLSearchParams({
                        token: configStorage().replace("Bearer ", ""),
                        talk: talkDetail.uid,
                        role: classification
                      })}`;
                      window.open(url, '_blank').focus();
                      toggleConfirmJoinCallModal();
                    }}
                    style={{
                      backgroundColor: "#162F50",
                      color: "white",
                      width: "254px",
                      height: "48px",
                      margin: "20px 0",
                      borderRadius: "8px"
                    }}
                  >
                    <PhoneIcon style={{ color: "white", width: "22px", height: "22px" }} />
                    &nbsp;
                    <span
                      style={{
                        fontSize: "16px",
                        fontFamily: "Noto Sans JP",
                        fontWeight: "normal"
                      }}
                    >参加する</span>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      )}

    </>
  );
}

export default LogMeetHistoryAll;