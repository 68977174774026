import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import InputControl from "../../common/InputControl";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Field } from "formik";
import "../../common/css/base.css";
import "../../common/css/auth.css";

class ForgotPasswordBuilderSP extends Component {
  render() {
    return (<>
      <Grid className="area-login-wrap">
        <Grid container justify="center">
          <Grid className="area-logo-sp" />
          <Grid className="area-login-sp">
            <Grid>
              <Typography
                align="center"
                variant="h4"
                className="title-name mb-10"
              >
                パスワードのリセット
              </Typography>
              {this.props.emailConfirm ? (
                <Typography
                  variant="subtitle2"
                  align="center"
                  className="txt-login-sp-sub mb-10 font-family"
                >
                  パスワード再設定のためのメールを送信しました。​
                  <br />
                  メールを確認して下さい。
                </Typography>
              ) : (
                <Typography
                  variant="subtitle2"
                  align="center"
                  className="txt-login-sp-sub mb-10 font-family"
                >
                  メールアドレスを入力して下さい。
                  <br />
                  パスワード再設定のためのメールを送信します。
                </Typography>
              )}
            </Grid>
            <Grid>
              {!this.props.emailConfirm && (
                <Grid>
                  <Field
                    label="メールアドレス​"
                    name="id"
                    type="text"
                    onChange={this.props.formik.handleChange}
                    variant="filled"
                    className="input-login input-login-sp"
                    component={InputControl}
                  />
                  <span className="required">
                    {this.props.error && this.props.error.id && (
                      <span className="this.props.error">{this.props.error.id}</span>
                    )}
                  </span>
                </Grid>
              )}
              <Grid container justify="center">
                {!this.props.emailConfirm ? (
                  <button type="submit" className="btn-login">
                    メールを送信​
                  </button>
                ) : (
                  <button type="button" className="btn-login btn-link">
                    <Link
                      to="/login"
                      className="white-text decorator-none"
                    >
                      ログイン画面に戻る​
                    </Link>
                  </button>
                )}
                {!this.props.emailConfirm && (
                  <Link
                    to="/login"
                    className="txt-forget-passowrd mt-20"
                  >
                    ログイン画面に戻る
                  </Link>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>);
  }
}

ForgotPasswordBuilderSP.propTypes = {
  formik: PropTypes.object.isRequired,
  emailConfirm: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

export default ForgotPasswordBuilderSP;
