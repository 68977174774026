import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clearError } from "../../common/Stores/action/action";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Sp from "../../common/breakpoints/Sp";
import Pc from "../../common/breakpoints/Pc";
import "../../common/css/error.css";

class ErrorPage extends Component {
  onBackButtonEvent = e => {
    this.props.clearError();
  };

  componentDidMount() {
    //clear axios error

    //handle for browser back button click
    window.onpopstate = this.onBackButtonEvent;

    //handle for switch route
    this.props.history.listen((location, action) => {
      if (this.props.location !== location.pathname) {
        this.props.clearError();
      }
    });
  }

  render() {
    return (
      <Grid container className="axios-error">
        <Grid>
          <Sp>
            <Typography className="error-title" style={{fontSize: '11rem'}}>404</Typography>
          </Sp>
          <Pc>
            <Typography className="error-title">404</Typography>
          </Pc>
          <Typography align="center" className="error-subtitle">
            <Typography className="error-subtitle">404エラー</Typography>
            お探しのページは見つかりませんでした。
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  axiosError: state.storesReducer.axiosError
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearError: clearError
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ErrorPage));
