import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import querySearch from "stringquery";

import TableContainer from "@material-ui/core/TableContainer";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import MenuItem from "@material-ui/core/MenuItem";
import TableRow from "@material-ui/core/TableRow";
import Popover from "@material-ui/core/Popover";
import Table from "@material-ui/core/Table";

import NewMessage from "./imgs/new_message.svg";
import NoMessage from "./imgs/no_message.svg";
import NewHS from "./imgs/new_HS.svg";
import NoHS from "./imgs/no_HS.svg";

import DetailHotspotInfoDialog from "./DetailHotspotInfoDialog/DetailHotspotInfoDialog";
import ConfirmChangeStatusDialog from "./ConfirmChangeStatusDialog";
import IconInfo from "./icons/IconInfo";
import CloseButton from "./CloseButton";

import { setRedirectFromUrl } from "../sites/site/action/action";
import { api } from "../../common/api/api";
import { format } from "date-fns";
import { isTablet } from "react-device-detect";

import Pagination from "@material-ui/lab/Pagination";
import { Dialog, Grid } from "@material-ui/core";

const NumberOfPage = 30;
const useStyles = makeStyles({
  truncateText2: {
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "wrap",
    wordBreak: "break-word"
  },
  tableRowHover: {
    backgroundColor: "#F8F9F9",
    borderTop: "5px solid #fff",
    "&:hover": {
      backgroundColor: "#d6d6d6"
    }
  },
  menuItem: {
    height: "50px",
    width: "100%",
    fontSize: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgba(0, 53, 109, 0.1)"
    }
  },
  paginationUl: {
    margin: "0",
    display: "flex",
    padding: "0",
    flexWrap: "wrap",
    listStyle: "none",
    alignItems: "center",
    justifyContent: "center"
  }
});

function InfoTagListBox (props) {
  const {
    hostpotInfos,
    paginationNumber,
    setPaginationNumber,
    showModeSP,
    reloadAllHotspots,
    allHotspots,
    allNotifications,
    saveCondition
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [anchorElMoreMenu, setAnchorElMoreMenu] = useState(null);
  const [openPopoverFor, setOpenPopoverFor] = useState(null);
  const [openConfirmChangeStatus, setOpenConfirmChangeStatus] = useState(false);
  const [showDetail, setShowDetail] = useState(null);
  const [showMessageInfoHasBeenDeleted, setShowMessageInfoHasBeenDeleted] = useState(false);

  useEffect(() => {
    if(hostpotInfos !== null) {
      const paramsSearch = querySearch(location.search);
      if (paramsSearch.info) {
        const currentHotSpot = hostpotInfos.find(
          item => item.info.uniqueCode === paramsSearch.info
        );

        if (currentHotSpot) {
          if(paramsSearch.detail == 1) {
            setShowDetail(currentHotSpot);
          } else {
            history.replace(history.location.pathname);
          }

          const currentHotSpotIndex = hostpotInfos.findIndex(
            item => item.info.uniqueCode === paramsSearch.info
          );

          setPaginationNumber(Math.ceil((currentHotSpotIndex + 1) / NumberOfPage));

          setShowMessageInfoHasBeenDeleted(false);
        } else {
          const hotspotHasChanged = allHotspots.find(
            item => item.info.uniqueCode === paramsSearch.info
          );

          if(hotspotHasChanged) {
            if(paramsSearch.detail == 1) {
              setShowDetail(hotspotHasChanged);
            } else {
              history.replace(history.location.pathname);
            }
          } else {
            setShowMessageInfoHasBeenDeleted(true);
            setShowDetail(null);            
          }
        }
      }      
    }

  }, [hostpotInfos]);

  const nextHotspot = () => {
    if (!showDetail) return;
    const orderOfCurrentHotspot = hostpotInfos.findIndex(
      item => item.info.uniqueCode === showDetail.info.uniqueCode
    );
    if (
      orderOfCurrentHotspot !== -1 &&
      orderOfCurrentHotspot < hostpotInfos.length - 1
    ) {
      setShowDetail(hostpotInfos[orderOfCurrentHotspot + 1]);
    }
  };

  const previousHotspot = () => {
    if (!showDetail) return;
    const orderOfCurrentHotspot = hostpotInfos.findIndex(
      item => item.info.uniqueCode === showDetail.info.uniqueCode
    );
    if (orderOfCurrentHotspot > 0) {
      setShowDetail(hostpotInfos[orderOfCurrentHotspot - 1]);
    }
  };

  const changeStatus = () => {
    const classification = location.pathname.includes("/sp/")
      ? "supplier"
      : "builder";

    api
      .post(
        `/${classification}/logwalk-viewer/infos/${
          openPopoverFor.info.uniqueCode
        }/${openPopoverFor.info.completedAt ? "uncomplete" : "complete"}`
      )
      .then(() => {
        setOpenConfirmChangeStatus(false);
        setAnchorElMoreMenu(null);

        setTimeout(() => {
          setOpenPopoverFor(null);
          reloadAllHotspots();
        }, 300);
      })
      .catch(() => {
        alert("エラーが発生しました。 お手数ですが、再度お試しください。");
        reloadAllHotspots();
      });
  };

  const getNotificationCells = hotspotsUC => {
    const notis = allNotifications
      ? allNotifications.filter(item => item.infosUC === hotspotsUC)
      : null;

    let hotspotUnread = false;
    let chatUnread = false;
    if (notis && notis.find(noti => noti.category === "hotspot")) {
      hotspotUnread = true;
    }

    if (notis && notis.find(noti => noti.category === "chat")) {
      chatUnread = true;
    }

    return (
      <TableCell
        style={{
          width: "40px",
          paddingLeft: "8px",
          paddingRight: "8px"
        }}
        className='padding-first-td'
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontSize: "24px"
          }}
        >
          <img
            alt='hotspot'
            src={hotspotUnread ? NewHS : NoHS}
            style={{
              width: hotspotUnread ? "22px" : "20px",
              height: hotspotUnread ? "22px" : "20px",
              marginBottom: "4px"
            }}
          />
          <img
            alt='chat'
            src={chatUnread ? NewMessage : NoMessage}
            style={{
              width: chatUnread ? "22px" : "20px",
              height: chatUnread ? "22px" : "20px"
            }}
          />
        </div>
      </TableCell>
    );
  };

  return (
    <React.Fragment>
      <TableContainer className='table-remove-overflow'>
        <Table
          size='small'
          stickyHeader
          aria-label='sticky table'
          style={{ marginBottom: showModeSP ? "20px" : "0px" }}
        >
          <TableHead>
            <TableRow style={{ backgroundColor: "#162f50" }}>
              {!showModeSP && (
                <TableCell
                  style={{
                    top: isTablet || showModeSP ? "63px" : "79px",
                    width: "90px",
                    borderRadius: "0px",
                    padding: "6px 10px"
                  }}
                />
              )}

              <TableCell
                style={{
                  top: isTablet || showModeSP ? "63px" : "79px",
                  width: "80px",
                  borderRadius: "0px",
                  padding: "6px 10px"
                }}
              />
              <TableCell
                className='white-text text-center'
                style={{
                  top: isTablet || showModeSP ? "63px" : "79px",
                  width: "200px",
                  borderRadius: "0px",
                  padding: "6px 10px"
                }}
              >
                現場名
              </TableCell>
              <TableCell
                className='white-text text-center'
                style={{
                  top: isTablet || showModeSP ? "63px" : "79px",
                  width: "200px",
                  borderRadius: "0px",
                  padding: "6px 10px"
                }}
              >
                タイトル
              </TableCell>
              <TableCell
                className='white-text text-center'
                style={{
                  top: isTablet || showModeSP ? "63px" : "79px",
                  width: "120px",
                  borderRadius: "0px",
                  padding: "6px 10px"
                }}
              >
                状態
              </TableCell>

              {!showModeSP && (
                <React.Fragment>
                  <TableCell
                    className='white-text text-center'
                    style={{
                      top: isTablet || showModeSP ? "63px" : "79px",
                      width: "120px",
                      borderRadius: "0px",
                      padding: "6px 10px"
                    }}
                  >
                    作成者
                  </TableCell>

                  <TableCell
                    className='white-text text-center'
                    style={{
                      top: isTablet || showModeSP ? "63px" : "79px",
                      width: "120px",
                      borderRadius: "0px",
                      padding: "6px 10px"
                    }}
                  >
                    図面名
                  </TableCell>
                  <TableCell
                    className='white-text text-center'
                    style={{
                      top: isTablet || showModeSP ? "63px" : "79px",
                      width: "120px",
                      borderRadius: "0px",
                      padding: "6px 10px"
                    }}
                  >
                    地点名
                  </TableCell>
                  <TableCell
                    className='white-text text-center'
                    style={{
                      top: isTablet || showModeSP ? "63px" : "79px",
                      width: "120px",
                      borderRadius: "0px",
                      padding: "6px 10px"
                    }}
                  >
                    撮影日時
                  </TableCell>
                  <TableCell
                    className='white-text text-center'
                    style={{
                      top: isTablet || showModeSP ? "63px" : "79px",
                      width: "120px",
                      borderRadius: "0px",
                      padding: "6px 10px"
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto"
                      }}
                    >
                      <span>更新日時</span>
                    </span>
                  </TableCell>
                </React.Fragment>
              )}

              <TableCell
                style={{
                  width: "80px",
                  borderRadius: "0px",
                  padding: "6px 10px",
                  top: isTablet || showModeSP ? "63px" : "79px"
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody className='table-body'>
            { hostpotInfos !== null &&
              hostpotInfos.length > 0 &&
              hostpotInfos.map(
                (hotspot, index) =>
                  index >= (paginationNumber - 1) * NumberOfPage &&
                  index < paginationNumber * NumberOfPage && (
                    <TableRow
                      key={hotspot.info.uniqueCode}
                      className={classes.tableRowHover}
                      onClick={() => setShowDetail(hotspot)}
                    >
                      {!showModeSP &&
                        getNotificationCells(hotspot.info.uniqueCode)}

                      <TableCell
                        className='text-center'
                        style={{
                          width: "80px",
                          paddingLeft: "8px",
                          paddingRight: "8px"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                          }}
                        >
                          <IconInfo
                            width='32px'
                            height='32px'
                            color={hotspot.icon.color}
                          />
                          {!showModeSP && (
                            <p
                              style={{ marginTop: "4px", whiteSpace: "nowrap" }}
                            >
                              {hotspot.icon.label}
                            </p>
                          )}
                        </div>
                      </TableCell>

                      <TableCell
                        style={{
                          paddingLeft: "8px",
                          paddingRight: "8px"
                        }}
                      >
                        <p className={classes.truncateText2}>
                          {hotspot.site.name}
                        </p>
                      </TableCell>

                      <TableCell
                        style={{
                          paddingLeft: "8px",
                          paddingRight: "8px"
                        }}
                      >
                        <p className={classes.truncateText2}>
                          {hotspot.info.title}
                        </p>
                      </TableCell>

                      <TableCell
                        className='text-center'
                        style={{
                          paddingLeft: "6px",
                          paddingRight: "14px"
                        }}
                      >
                        {hotspot.info.completedAt ? (
                          <span>
                            <span
                              style={{
                                fontSize: "10px",
                                color: "red",
                                lineHeight: "inherit",
                                marginRight: "3px"
                              }}
                            >
                              <Brightness1Icon
                                color='inherit'
                                fontSize='inherit'
                              />
                            </span>
                            完了
                          </span>
                        ) : (
                          <span>未完了</span>
                        )}
                      </TableCell>

                      {!showModeSP && (
                        <TableCell
                          className='text-center'
                          style={{
                            paddingLeft: "6px",
                            paddingRight: "14px"
                          }}
                        >
                          <p
                            className={classes.truncateText2}
                            style={{ "-webkit-line-clamp": "2" }}
                          >
                            {hotspot.info.createdBy}
                          </p>
                        </TableCell>
                      )}

                      {!showModeSP && (
                        <TableCell
                          style={{
                            paddingLeft: "8px",
                            paddingRight: "8px"
                          }}
                        >
                          <p className={classes.truncateText2}>
                            {hotspot.drawing.name}
                          </p>
                        </TableCell>
                      )}

                      {!showModeSP && (
                        <TableCell
                          style={{
                            paddingLeft: "8px",
                            paddingRight: "8px"
                          }}
                        >
                          <p className={classes.truncateText2}>
                            {hotspot.spot.name}
                          </p>
                        </TableCell>
                      )}

                      {!showModeSP && (
                        <TableCell
                          className='text-center'
                          style={{
                            paddingLeft: "8px",
                            paddingRight: "8px"
                          }}
                        >
                          <p>
                            {format(
                              new Date(hotspot.record.createdAt),
                              "yyyy/MM/dd"
                            )}
                          </p>
                          <p>
                            {format(
                              new Date(hotspot.record.createdAt),
                              "HH:mm"
                            )}
                          </p>
                        </TableCell>
                      )}
                      {!showModeSP && (
                        <TableCell
                          className='text-center'
                          style={{
                            paddingLeft: "8px",
                            paddingRight: "8px"
                          }}
                        >
                          <p>
                            {format(
                              new Date(hotspot.info.updatedAt),
                              "yyyy/MM/dd"
                            )}
                          </p>
                          <p>
                            {format(new Date(hotspot.info.updatedAt), "HH:mm")}
                          </p>
                        </TableCell>
                      )}

                      <TableCell
                        className='text-center'
                        style={{
                          paddingLeft: "8px",
                          paddingRight: "8px"
                        }}
                      >
                        <button
                          style={{
                            width: "44px",
                            height: "44px",
                            fontSize: "36px",
                            lineHeight: "36px",
                            padding: "4px",
                            backgroundColor:
                              hotspot.info.uniqueCode ===
                              openPopoverFor?.info?.uniqueCode
                                ? "#00356D"
                                : "unset",
                            color:
                              hotspot.info.uniqueCode ===
                              openPopoverFor?.info?.uniqueCode
                                ? "#FFFFFF"
                                : "#00356D"
                          }}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOpenPopoverFor(hotspot);
                            setAnchorElMoreMenu(e.currentTarget);
                          }}
                        >
                          <MoreVertIcon color='inherit' fontSize='inherit' />
                        </button>
                      </TableCell>
                    </TableRow>
                  )
              )}
            {/* {
              hostpotInfos.length > showNumberHotspots && (
                <TableRow
                  className={classes.tableRowHover}
                  onClick={() => setShowNumberHotspots(showNumberHotspots + 50)}
                >
                  <TableCell colSpan={11}>
                    <button style={{
                      width: "100%",
                      color: "#2b57d0",
                      fontSize: "14px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                      もっと見る
                      <div style={{fontSize: "24px", height: "24px", height: "24px", display: "inline-block"}}>
                        <ExpandMoreIcon fontSize="inherit" color="inherit" />
                      </div>
                    </button>
                  </TableCell>
                </TableRow>
              )
            } */}
          </TableBody>
        </Table>
      </TableContainer>
      {
        hostpotInfos !== null &&
        hostpotInfos.length > NumberOfPage && (
        <Pagination
          classes={{ ul: classes.paginationUl }}
          count={Math.ceil(hostpotInfos.length / NumberOfPage)}
          page={paginationNumber}
          onChange={(event, value) => setPaginationNumber(value)}
          style={{
            border: "none",
            backgroundColor: "#fff",
            boxShadow: "none"
          }}
        />
      )}

      <Popover
        open={Boolean(anchorElMoreMenu)}
        anchorEl={anchorElMoreMenu}
        onClose={() => {
          setAnchorElMoreMenu(null);
          setOpenPopoverFor(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <div style={{ width: "170px" }}>
          <MenuItem
            style={{ color: "#00356D" }}
            className={classes.menuItem}
            onClick={() => setOpenConfirmChangeStatus(true)}
          >
            <span>
              {openPopoverFor?.info?.completedAt ? "未完了へ" : "完了へ"}
            </span>
          </MenuItem>
          <hr style={{ margin: "0" }} />
          <MenuItem
            style={{ color: "#FF8675" }}
            className={classes.menuItem}
            onClick={() => {
              dispatch(
                setRedirectFromUrl({
                  isRedirectFromUrl: true,
                  from: `/info-hotspots?info=${openPopoverFor.info.uniqueCode}&detail=0`,
                  
                })
              );
              saveCondition();
              history.push(
                "/logwalk-viewer/shoots/" +
                  openPopoverFor.shoot.uniqueCode +
                  "?info=" +
                  openPopoverFor.info.uniqueCode
              );
            }}
          >
            <span>ここを見る</span>
          </MenuItem>
        </div>
      </Popover>
      {
        hostpotInfos !== null && (
          <DetailHotspotInfoDialog
            hotspot={showDetail}
            allHotspots={hostpotInfos}
            allNotifications={allNotifications}
            onClose={() => {
              setShowDetail(null);
              history.replace(history.location.pathname);
            }}
            nextHotspot={nextHotspot}
            previousHotspot={previousHotspot}
            showModeSP={showModeSP}
            reloadAllHotspots={reloadAllHotspots}
            saveCondition={saveCondition}
          />          
        )
      }

      <ConfirmChangeStatusDialog
        open={openConfirmChangeStatus && openPopoverFor}
        handleClose={() => setOpenConfirmChangeStatus(false)}
        completedAt={openPopoverFor && openPopoverFor.info.completedAt}
        changeStatus={changeStatus}
      />

      <Dialog
        onClose={() => {
          setShowDetail(null);
          setShowMessageInfoHasBeenDeleted(false)
          history.replace(history.location.pathname);
        }}
        open={showMessageInfoHasBeenDeleted}
        fullWidth={true}
        PaperProps={{
          style: {
            overflowY: "unset",
            width: showModeSP ? "calc(100% - 32px)" : "calc(100% - 64px)",
            maxHeight: showModeSP ? "calc(100% - 24px)" : "calc(100% - 64px)",
            margin: showModeSP ? "16px" : "32px"
          }
        }}
      >
        <CloseButton 
          handleClose={() => {
            setShowDetail(null);
            setShowMessageInfoHasBeenDeleted(false)
            history.replace(history.location.pathname);
          }} 
        />
        <div 
          style={{
            margin: "auto", 
            minHeight: "120px",
            paddingTop:  "60px",
            fontSize: "20px"
          }}
        >インフォタグが削除されました</div>
      </Dialog>
    </React.Fragment>
  );
}

export default InfoTagListBox;
