import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { MenuItem, Popover } from "@material-ui/core";

const useStyles = makeStyles({
  selectFieldWrapper: {
    width: "100%",
    height: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "0.5px solid rgb(22, 47, 80)",
    borderRadius: "4px",
    cursor: "pointer",
    "& > div:first-child": {
      flexGrow: "1",
      padding: "0px 12px",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    "& > span:last-child": {
      color: "#C7C7C7",
      fontSize: "24px",
      lineHeight: "24px",
      width: "24px",
      height: "24px",
      marginRight: "4px"
    }
  }
});

function SelectField(props) {
  const { value, options, selectValue } = props;
  const [anchorElSelect, setAnchorElSelect] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState("0");

  const selectRef = useRef(null);
  const classes = useStyles();

  const handleClick = event => {
    setAnchorElSelect(event.currentTarget);
    setPopoverWidth(window.getComputedStyle(selectRef.current).width);
  };

  const getLabel = () => {
    if (options.length === 0) return "";
    const option = options.find(item => item.value === value);
    if (option) return option.label;
    return "";
  };

  const isSelected = option => {
    if (!value) return false;
    return value === option.value;
  };

  const getPopoverHeight = () => {
    let heightOptions = options.length * 40 + 24;
    if (heightOptions < 200) return heightOptions + "px";
    return "200px";
  };

  return (
    <>
      <div style={{ width: "166px" }}>
        <div
          className={classes.selectFieldWrapper}
          onClick={handleClick}
          ref={selectRef}
        >
          <div>{getLabel()}</div>
          <span>
            <ArrowDropDownIcon fontSize="inherit" color="inherit" />
          </span>
        </div>
      </div>

      <Popover
        open={Boolean(anchorElSelect)}
        anchorEl={anchorElSelect}
        onClose={() => setAnchorElSelect(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            width: popoverWidth,
            overflow: "hidden",
            height: getPopoverHeight(),
            padding: "12px 0px"
          }
        }}
      >
        <div
          style={{ width: "100%", height: "100%", overflow: "auto" }}
          className={classes.customScrollbar}
        >
          {options.map(option => (
            <MenuItem
              onClick={() => {
                selectValue(option.value);
                setAnchorElSelect(null);
              }}
              style={{
                backgroundColor: isSelected(option) ? "#F8F9F9" : "white",
                height: "40px",
                minHeight: "40px",
                paddingTop: "0",
                paddingBottom: "0",
                width: "100%",
                display: "flex",
                alignItems: "cennter",
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </div>
      </Popover>
    </>
  );
}

export default SelectField;
