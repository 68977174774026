import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchSiteList } from "../sites/service/service";
import {
  clearDrawingsWithSpot,
  setIsCompleteSite
} from "./site/service/service";
import { bindActionCreators } from "redux";
import { getSites } from "./reducer/sites";
import SiteDialog from "./site/SiteDialog";
import DialogRobots from "../../common/DialogRobots";
import Sp from "../../common/breakpoints/Sp";
import Pc from "../../common/breakpoints/Pc";
import IndexBuilderSP from "./IndexBuilderSP";
import IndexBuilderPC from "./IndexBuilderPC";
import "../../common/css/base.css";
import "../../common/css/sites.css";
import DrawingDialog from "components/DrawingDialog";
import { FETCH_SITES_SUCCESS } from "./constant/constant";
import { SET_IS_COMPLETE_SITE_SUCCESS } from "./site/constant/constant";
import { MODE_DEVICE } from "components/sites/site/LogWalkViewer/constants";
import {
  wsUpdatedRobots,
  wsClearRobots
} from "../robots/robotDetails/service/service";
import { getWebSocketRobotURL } from "utils/mapper";
const WS_TIME_REPEAT = 10000; //10s

class Index extends Component {
  ws = null;
  wsInterval = null;
  pingInterval = null;

  constructor(props) {
    super(props);
    this.state = {
      robots: [],
      isDialogShow: false,
      isDialogRobotsShow: false,
      siteData: [],
      isShowDialogDrawing: false,
      sitesUC: null,
      isCompleteSite: false,
      fetchDone: false,
      redirectFromUrl: null
    };
  }

  componentWillMount() {
    const { state } = this.props.history.location;
    if (this.props.redirectFromUrl.isRedirectFromUrl) {
      this.setState({ redirectFromUrl: this.props.redirectFromUrl });
      this.props.fetchSiteList(this.props.isCompleteSite);
      this.props.setIsCompleteSite(this.props.isCompleteSite);
    } else {
      this.props.fetchSiteList(
        state && state.isBack ? state.isCompleteSite : false
      );
      this.props.setIsCompleteSite(
        state && state.isBack ? state.isCompleteSite : false
      );
    }
    if (state) {
      state.reload = false;
    } 
  }

  // Fetch when switch mode
  fetchSiteList = () => {
    this.props.fetchSiteList(!this.props.isCompleteSite);
    this.props.setIsCompleteSite(!this.props.isCompleteSite);
  };

  componentWillReceiveProps(props) {
    const { state } = props.history.location;
    if (state && state.reload) {
      // If back from button 一覧に戻る, check site is complete to redirect
      let isCompleteSite = state.isBack ? state.isCompleteSite : false;
      this.props.fetchSiteList(isCompleteSite);
      if (!state.isBack) {
        this.props.setIsCompleteSite(false);
      }
      state.reload = false;

    }
    if (props.type === SET_IS_COMPLETE_SITE_SUCCESS) {
      this.setState({ fetchDone: false });
    }

    if (props.type === FETCH_SITES_SUCCESS) {
      this.setState({ fetchDone: true });
      let robotsUC = [];
      props.sites.map((item, idx) => {
        item.robots.map((robot, robot_idx) => {
          robotsUC = [...robotsUC, robot.uniqueCode];
        });
      });

      if (!this.ws && robotsUC.length > 0) {
        this.openWS(robotsUC);

        this.wsInterval = setInterval(() => {
          if (this.ws.readyState === 3) {
            clearInterval(this.pingInterval);
            this.openWS(robotsUC);
          }
        }, WS_TIME_REPEAT);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.wsInterval);
    clearInterval(this.pingInterval);
    if (this.ws) {
      this.ws.onclose = () => {}; // disable onclose handler first
      this.ws.close();
      this.props.wsClearRobots();
    }
  }

  openWS = robotsUC => {
    this.ws = new WebSocket(getWebSocketRobotURL());
    this.ws.addEventListener("open", () => {
      this.ws.send(JSON.stringify(robotsUC));

      this.pingInterval = setInterval(() => {
        this.ws.send("ping");
      }, WS_TIME_REPEAT);
    });

    this.ws.onmessage = s => {
      if (s.data !== "pong") {
        this.props.wsUpdatedRobots(JSON.parse(s.data));
      }
    };
  };

  showHideDialogDrawing = (sitesUC = null) => {
    this.setState({
      isShowDialogDrawing: !this.state.isShowDialogDrawing,
      sitesUC
    });
  };

  render() {
    const {
      isDialogShow,
      robots,
      isDialogRobotsShow,
      siteData,
      isShowDialogDrawing,
      sitesUC,
      fetchDone,
      redirectFromUrl
    } = this.state;

    const { robotsUpdate, isCompleteSite } = this.props;

    const to = id => () => {
      this.props.history.push("/sites/" + id);
      this.props.clearDrawingsWithSpot();
    };

    const onChangeData = data => {
      this.setState({
        siteData: data
      });
    };

    const showDialog = () => {
      this.setState({
        isDialogShow: true
      });
    };

    const closeDialog = () => {
      this.setState({
        isDialogShow: false
      });
    };

    const showDialogRobots = (robots, e = null) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      this.setState({
        robots: robots,
        isDialogRobotsShow: true
      });
    };

    const closeDialogRobots = () => {
      this.setState({
        robots: [],
        isDialogRobotsShow: false
      });
    };

    return (
      <>
        {fetchDone && (
          <>
            <Sp>
              <IndexBuilderSP
                sites={this.props.sites}
                to={to}
                backType={1}
                showDialog={showDialog}
                showDialogRobots={showDialogRobots}
                showDialogDrawing={this.showHideDialogDrawing}
                robotsUpdate={robotsUpdate}
                isCompleteSite={isCompleteSite}
                fetchSiteList={this.fetchSiteList}
              />
            </Sp>

            <Pc>
              <IndexBuilderPC
                sites={this.props.sites}
                siteData={siteData}
                onChangeData={onChangeData}
                to={to}
                showDialog={showDialog}
                showDialogRobots={showDialogRobots}
                showDialogDrawing={this.showHideDialogDrawing}
                robotsUpdate={robotsUpdate}
                isCompleteSite={isCompleteSite}
                fetchSiteList={this.fetchSiteList}
                redirectFromUrl={redirectFromUrl}
              />
            </Pc>
          </>
        )}

        {isDialogShow && (
          <SiteDialog isDialogShow={true} onClose={closeDialog} />
        )}

        {isDialogRobotsShow && (
          <DialogRobots robots={robots} onClose={closeDialogRobots} />
        )}

        {isShowDialogDrawing && (
          <Pc>
            <DrawingDialog
              open={isShowDialogDrawing}
              modeDevice={MODE_DEVICE.PC}
              onClose={this.showHideDialogDrawing}
              sitesUC={sitesUC}
            />
          </Pc>
        )}
        {isShowDialogDrawing && (
          <Sp>
            <DrawingDialog
              open={isShowDialogDrawing}
              modeDevice={MODE_DEVICE.SP}
              onClose={this.showHideDialogDrawing}
              sitesUC={sitesUC}
            />
          </Sp>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  type: state.rootSitesReducer.siteInfoReducer.type,
  sites: getSites(state.rootSitesReducer.sitesReducer.site),
  robotsUpdate:
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailsReducer
      .wsUpdatedRobots,
  isCompleteSite: state.rootSitesReducer.siteInfoReducer.isCompleteSite,
  redirectFromUrl: state.rootSitesReducer.siteInfoReducer.redirectFromUrl
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSiteList,
      clearDrawingsWithSpot,
      wsUpdatedRobots,
      wsClearRobots,
      setIsCompleteSite
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Index);
