import React from "react";

function IconTabMessage(props) {
  const { isActive, smallSize } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={smallSize ? "30" : "40"}
      height={smallSize ? "30" : "40"}
      viewBox="0 0 40 40"
    >
      <g transform="translate(89 -413.308)">
        <path
          fill="none"
          d="M0 0H40V40H0z"
          transform="translate(-89 413.308)"
        ></path>
        <g fill={isActive ? "#00356D" : "#c7c7c7"}>
          <path
            d="M28.617 10.366h-15.32a.483.483 0 000 .966h15.321a.483.483 0 100-.966zM28.617 14.057h-15.32a.483.483 0 000 .966h15.321a.483.483 0 000-.966zM28.617 17.747h-15.32a.483.483 0 000 .966h15.321a.483.483 0 000-.966z"
            transform="translate(-84.969 419.046)"
          ></path>
          <path
            d="M30.151 6.144H11.763a2.811 2.811 0 00-2.808 2.808v11.562a2.811 2.811 0 002.808 2.808h12.888a.445.445 0 01.351.17l4.162 5.269a.83.83 0 00.654.321.854.854 0 00.281-.048.83.83 0 00.564-.794v-4.966a2.821 2.821 0 002.3-2.76V8.952a2.811 2.811 0 00-2.808-2.808zm1.842 14.37a1.849 1.849 0 01-1.819 1.842.483.483 0 00-.477.483v5.041l-3.938-4.985a1.405 1.405 0 00-1.109-.537H11.763a1.845 1.845 0 01-1.842-1.842V8.952a1.845 1.845 0 011.842-1.842h18.388a1.845 1.845 0 011.842 1.842z"
            transform="translate(-84.969 419.046)"
          ></path>
          <path
            d="M7.113 14.126a1.287 1.287 0 00-.833.479l-3.317 4.2v-4.209a.483.483 0 00-.477-.483 1.547 1.547 0 01-1.52-1.541V2.507A1.543 1.543 0 012.507.966h16.007a1.543 1.543 0 011.541 1.541v1.862a.483.483 0 10.966 0V2.507A2.51 2.51 0 0018.514 0H2.507A2.51 2.51 0 000 2.507v10.065a2.519 2.519 0 002 2.454v4.272a.784.784 0 00.533.75.808.808 0 00.267.042.784.784 0 00.618-.3l3.623-4.587a.326.326 0 01.211-.121.483.483 0 10-.136-.956z"
            transform="translate(-84.969 419.046)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

IconTabMessage.defaultProps = {
  isActive: false,
  smallSize: false
};

export default IconTabMessage;
