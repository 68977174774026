import React, { createRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Component from "../../../Component";
import { gsap, TweenMax } from "gsap";
import { Draggable } from "gsap/Draggable";
import Grid from "@material-ui/core/Grid";
import { compassSettingDirection } from "../service/service";
import RefreshIcon from "@material-ui/icons/Refresh";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CompassImage from "../../../../common/img/compass.svg";
import RotateImage from "../../../../common/img/Rotate.svg";
import Button from "@material-ui/core/Button";
import c from "./CompassSettingConfirmSP";
import CompassSettingSaveConfirmSP from "./CompassSettingSaveConfirmSP";
import CompassSettingConfirmDialog from "./CompassSettingConfirmDialog";
import CompassSettingConfirmSP from "./CompassSettingConfirmSP";

gsap.registerPlugin(Draggable);
export class CompassSettingSP extends Component {
  cancelSubmission = false;
  
  constructor(props) {
    super(props);
    this.compassRef = React.createRef();
    this.compassImgRef = React.createRef();
    this.compassContainerRef = React.createRef();
    this.controlRef = React.createRef();
    this.RotationRef = React.createRef();
    this.compassViewContainerRef = React.createRef();
    this.compassViewRef = React.createRef();
    this.saveCompassButtonRef = React.createRef();

  }
  state = {
    isCompassSettingMode: true,
    isBottomControl: true,
    Rotation: 0,
    CurrentRotation: 0,
    size: 0, // MiniSize : -1  //NormalSize:0 // MaxSize: 1;
    isShowDialogConfirmDirection: false,
    isShowDialogConfirmSaveDirection: false,
    isConfirmCurrentDrawingDialog: false,
    isConfirmListDrawingDialog: false,
    sitesUC: "",
    currentDrawingUCs: "",
    settingDirection: 0,
    drawingsWithSpots: this.props.drawingsWithSpots,
    selectedDrawing: this.props.selectedDrawing,
    isSavingCompassSetting: false,
    isCompassSetting: this.props.isCompassSetting,
    isCompassSettingCurrent: true,
    isClickedCompassOutSide: true,
    currentX: -1,
    currentY: -1,
    isShowButtonSave: true,
    isClickButtonSave: false,
    isFirstClick: true,
    isClickToDrawing: this.props.isClickToDrawing,
    isCompleteSetting: false,
    screenOrientation: "portrait",
    noteText: false
  };
  objDraggable;
  componentDidMount() {
    gsap.set(this.compassRef.current, { rotation: this.props.direction });
    this.insCompassViewer();
    this.setScreenOrientation();
    this.checkPosition(this.props.direction);
    window.addEventListener("resize", this.ResetCompassPosition);
    window.addEventListener("resize", this.setScreenOrientation);
    document.addEventListener("click", this.handleCompassClick);
    // setTimeout(() => {
    //     this.ResetCompassPosition();
    // }, 50);
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleCompassClick);
    document.removeEventListener("resize", this.ResetCompassPosition);
    document.removeEventListener("resize", this.setScreenOrientation);
    this.cancelSubmission = true;
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedDrawing: nextProps.selectedDrawing
    });
    gsap.set(this.compassViewRef.current, { rotation: nextProps.direction });

    if (nextProps.isCompassSetting !== this.state.isCompassSettingCurrent) {
      this.setState({
        Rotation: nextProps.direction
      });
      gsap.set(this.compassRef.current, { rotation: nextProps.direction });
      this.setState(
        {
          isCompassSettingCurrent: nextProps.isCompassSetting,
          isShowButtonSave: true,
          isFirstClick: true
        },
        this.ResetCompassPosition
      );
      if (!nextProps.isCompassSetting) {
        TweenMax.set(this.compassViewContainerRef.current, { x: 0, y: 0 });
      }
    }
    if (this.state.noteText != nextProps.noteText) {
      this.setState({
        noteText: nextProps.noteText
      });
      var draggableObj = Draggable.create(
        this.compassViewContainerRef.current,
        {
          type: "x,y",
          bounds: document.getElementById(`${this.props.bounds}`),
          onDragStart: () => {
            this.props.handleCompassDragParent(true);
          },
          onDragEnd: () => {
            this.props.handleCompassDragParent(false);
          }
        }
      );
      if (nextProps.noteText) {
        draggableObj[0].kill();
      }
    }
    this.setState({
      sitesUC: nextProps.sitesUC,
      currentDrawingUCs: nextProps.currentDrawingUCs
    });
  }
  ResetCompassPosition = e => {
    // let ModalElement = document.getElementById('draggable-primary-sp');
    // let CompassElement = document.getElementById('compass-setting-container');
    // let x = 0;
    // let y = 0;
    // if (ModalElement && CompassElement) {
    //     x = ModalElement.clientWidth / 2 - CompassElement.offsetWidth / 2;
    //     y = ModalElement.clientHeight / 2 - CompassElement.offsetHeight / 2;
    // }
    // TweenMax.set(this.compassContainerRef.current, { x: x, y: y });
    // Set compass position center
    TweenMax.set(this.compassContainerRef.current, {
      x: 0,
      y: 0,
      xPercent: -50,
      yPercent: -50,
      left: "50%",
      top: "50%"
    });
  };
  setScreenOrientation = e => {
    if(!this.cancelSubmission){
      try {
        if (window.matchMedia("(orientation: portrait)").matches) {
          this.setState(
            {
              screenOrientation: "portrait"
            },
            this.ResetCompassPosition
          );
        }
  
        if (window.matchMedia("(orientation: landscape)").matches) {
          this.setState(
            {
              screenOrientation: "landscape"
            },
            this.ResetCompassPosition
          );
        }
      } catch (ie) {
        console.log(ie);
      }
    }    
  };
  checkPosition(Direc) {
    if (Direc <= 0) {
      Direc = 360 + Direc;
    }
    Direc = Math.round(Direc);
    if (Direc == 360) {
      Direc = 0;
    }
    this.setState({
      Rotation: Direc,
      // isBottomControl: !(Direc > 90 && Direc <= 270)
      isBottomControl: true
    });
  }
  insCompassViewer = () => {
    var draggableObj = Draggable.create(this.compassViewContainerRef.current, {
      type: "x,y",
      bounds: document.getElementById(`${this.props.bounds}`),
      onDragStart: () => {
        this.props.handleCompassDragParent(true);
      },
      onDragEnd: () => {
        this.props.handleCompassDragParent(false);
      }
    });
  };
  ChangeMode = () => {
    this.setState({
      isCompassSettingMode: !this.state.isCompassSettingMode
    });
    var rotationObj = Draggable.create(this.compassRef.current, {
      type: "rotation",
      onDrag: () => {
        let CurDirection = rotationObj[0].endX % 360;
        this.checkPosition(CurDirection);
      }
    });
    var draggableObj = Draggable.create(this.compassContainerRef.current, {
      type: "x,y",
      bounds: document.getElementById(`${this.props.bounds}`),
      onDragStart: () => {
        // DragStart event
        // Do something
      },
      onDragEnd: () => {},
      onDrag: () => {},
      onClick: () => {
        // Click event
      }
    });
    if (!this.state.isCompassSettingMode) {
      draggableObj[0].kill();
    } else {
      rotationObj[0].kill();
    }
  };
  ClickedOutSide = value => {
    this.setState({
      isCompassSettingMode: value
    });
    var rotationObj = Draggable.create(this.compassRef.current, {
      type: "rotation",
      onDrag: () => {
        this.checkPosition(rotationObj[0].endX % 360);
      }
    });
    var draggableObj = Draggable.create(this.compassContainerRef.current, {
      type: "x,y",
      bounds: document.getElementById(`${this.props.bounds}`),
      onClick: event => {
        if (!value) {
          this.CompassClick(event);
        }
      }
    });

    if (value) {
      draggableObj[0].kill();
      if (!this.state.isShowDialogConfirmSaveDirection) {
        this.setState({
          isShowButtonSave: true
        });
        if (this.state.isCompleteSetting) {
          this.setState(
            {
              isCompleteSetting: false
            },
            this.ResetCompassPosition
          );
        }
      }
    } else {
      rotationObj[0].kill();
    }
  };
  CompassClick = event => {
    // disable draggable
    var draggableObj = Draggable.create(this.compassContainerRef.current, {
      type: "x,y",
      bounds: document.getElementById(`${this.props.bounds}`)
    });
    draggableObj[0].kill();
    // enable rotation
    var rotationObj = Draggable.create(this.compassRef.current, {
      type: "rotation",
      onDrag: () => {
        this.checkPosition(rotationObj[0].endX % 360);
      }
    });
    // handle element click
    // --- click to compass image
    const { target } = event;
    if (this.compassImgRef.current.contains(target)) {
      this.setState({ isClickedCompassOutSide: false });
      this.ClickedOutSide(true);
    }
    if (
      this.saveCompassButtonRef.current &&
      this.saveCompassButtonRef.current.contains(target)
    ) {
      this.btnSaveClick();
    }
  };
  checkCompassWidth(size) {
    let cSize = this.state.size + size;
    this.setState({
      size: cSize
    });
  }
  zoomOut = () => {
    if (this.state.size > -2) {
      this.checkCompassWidth(-1);
    }
  };
  zoomIn = () => {
    if (this.state.size < 1) {
      this.checkCompassWidth(1);
    }
  };
  saveCurrentDrawing = () => {
    let value = {
      drawingsUCs: [this.state.currentDrawingUCs],
      direction: this.state.Rotation
    };
    this.props.compassSettingDirection(this.state.sitesUC, value);
    setTimeout(() => {
      this.ClickedOutSide(false);
      this.setState({
        isConfirmCurrentDrawingDialog: false,
        isShowDialogConfirmDirection: false,
        isShowButtonSave: false,
        isClickedCompassOutSide: true,
        isCompleteSetting: true
      });
      this.resetCompassPosition();
    }, 10);
  };
  saveListDrawing = () => {
    let arrayDrawing = this.state.selectedDrawing;
    if (arrayDrawing.indexOf(this.state.currentDrawingUCs) == -1) {
      arrayDrawing.push(this.state.currentDrawingUCs);
    }
    let value = {
      drawingsUCs: arrayDrawing,
      direction: this.state.Rotation
    };
    this.props.compassSettingDirection(this.state.sitesUC, value);
    this.handleSaving(false);
    setTimeout(() => {
      this.setState({
        isShowDialogConfirmDirection: false,
        isShowDialogConfirmSaveDirection: false,
        isConfirmListDrawingDialog: false,
        isClickedCompassOutSide: true,
        isShowButtonSave: false,
        isCompleteSetting: true
      });
      this.ClickedOutSide(false);
      this.resetCompassPosition();
    }, 10);
  };
  resetCompassPosition = () => {
    var draggableObj = Draggable.create(this.compassContainerRef.current, {
      type: "x,y",
      bounds: document.getElementById(`${this.props.bounds}`),
      onClick: event => {
        this.CompassClick(event);
      }
    });
    let x = document.getElementById("draggable-primary-sp").clientWidth - 100;
    TweenMax.set(this.compassContainerRef.current, {
      x: x,
      y: 0,
      xPercent: 0,
      yPercent: 0,
      left: "0%",
      top: "0%"
    });
  };
  CheckAllDrawing = () => {
    this.props.handleSavingCompassCheckAll();
  };
  handleSaving = value => {
    if (value) {
      this.setState({
        isSavingCompassSetting: true
      });
      setTimeout(() => {
        this.props.handleSavingParent(true);
        this.props.handleSavingDirectionParent(this.state.Rotation);
      }, 100);
    } else {
      this.setState({
        isSavingCompassSetting: false
      });
      this.props.handleSavingParent(false);
    }
  };
  btnSaveClick = () => {
    this.setState({
      isClickButtonSave: true,
      isShowDialogConfirmDirection: !this.state.isShowDialogConfirmDirection,
      isShowButtonSave: !this.state.isShowButtonSave
    });
  };
  handleCompassClick = event => {
    if (this.state.isFirstClick) {
      this.setState({
        isClickedCompassOutSide: false,
        isFirstClick: false
      });
      this.ClickedOutSide(true);
    } else {
      const { target } = event;
      if (
        !this.state.isSavingCompassSetting &&
        !this.compassRef.current.contains(target) &&
        !(
          this.controlRef.current && this.controlRef.current.contains(target)
        ) &&
        this.props.isClickToDrawing
      ) {
        this.setState({ isClickedCompassOutSide: true });
        this.ClickedOutSide(false);
      }
      if (
        (this.compassImgRef.current.contains(target) ||
          (this.controlRef.current &&
            this.controlRef.current.contains(target))) &&
        this.props.isClickToDrawing
      ) {
        this.setState({ isClickedCompassOutSide: false });
        this.ClickedOutSide(true);
      }
    }
  };
  render() {
    const { drawingsUC, direction, isCompassSetting, isNullDirection } =
      this.props;
    const {
      isCompassSettingMode,
      isBottomControl,
      Rotation,
      size,
      isShowDialogConfirmDirection,
      isShowDialogConfirmSaveDirection,
      isConfirmCurrentDrawingDialog,
      isConfirmListDrawingDialog,
      isClickedCompassOutSide,
      isClickButtonSave,
      isShowButtonSave,
      isCompleteSetting,
      screenOrientation
    } = this.state;
    const toggleConfirmDirectionDialog = () => {
      this.setState({
        isShowDialogConfirmDirection: !this.state.isShowDialogConfirmDirection,
        isShowButtonSave: !this.state.isShowButtonSave
      });
    };
    const toggleConfirmCurrentDrawingDialog = () => {
      this.setState({
        isConfirmCurrentDrawingDialog:
          !this.state.isConfirmCurrentDrawingDialog,
        isShowDialogConfirmDirection: !this.state.isShowDialogConfirmDirection
      });
    };
    const toggleConfirmListDrawingDialog = () => {
      this.setState({
        isConfirmListDrawingDialog: !this.state.isConfirmListDrawingDialog,
        isShowDialogConfirmSaveDirection:
          !this.state.isShowDialogConfirmSaveDirection
      });
    };
    const toggleConfirmDirectionSaveDialog = () => {
      this.handleSaving(!this.state.isShowDialogConfirmSaveDirection);
      this.setState({
        isShowDialogConfirmSaveDirection:
          !this.state.isShowDialogConfirmSaveDirection,
        isShowDialogConfirmDirection: !this.state.isShowDialogConfirmDirection
      });
    };
    return (
      <>
        <Grid
          ref={this.compassContainerRef} // Draggable object
          id={"compass-setting-container"}
          container
          direction="row"
          className={`compass-setting-container 
                    compass-sp-setting-container-${
                      isCompleteSetting
                        ? "complete"
                        : size === 0
                        ? "normalsize"
                        : size === -1
                        ? "minisize"
                        : size === -2
                        ? "minimumsize"
                        : "maxsize"
                    }${screenOrientation === "landscape" ? "-landscape" : ""}
                    ${!isCompassSetting ? " display-none" : ""}`}
        >
          <Grid item xs={12} ref={input => (this.inputElement = input)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={5}
              ref={this.compassRef} // rotation object
              class={`compass-setting-rotation-container ${
                !isClickedCompassOutSide ? " rotation-container-selected" : ""
              }
                            compass-sp-setting-rotation-container-${
                              isCompleteSetting
                                ? "complete"
                                : size === 0
                                ? "normalsize"
                                : size === -1
                                ? "minisize"
                                : size === -2
                                ? "minimumsize"
                                : "maxsize"
                            }${
                screenOrientation === "landscape" ? "-landscape" : ""
              }`}
            >
              <Grid
                item
                xs
                className="text-center compass-setting-rotation-icon-container"
              >
                <p>{!isClickedCompassOutSide && <img src={RotateImage} />} </p>
                {/* <p>{isCompassSettingMode && <RefreshIcon className={"compass-setting-button-icon "} />} </p> */}
              </Grid>
              <Grid
                item
                xs
                className={`text-center compass-setting-icon-container`}
              >
                <img
                  ref={this.compassImgRef}
                  className={`compass-setting-icon compass-sp-setting-icon-${
                    isCompleteSetting
                      ? "complete"
                      : size === 0
                      ? "normalsize"
                      : size === -1
                      ? "minisize"
                      : size === -2
                      ? "minimumsize"
                      : "maxsize"
                  }${screenOrientation === "landscape" ? "-landscape" : ""}`}
                  src={CompassImage}
                />
              </Grid>
            </Grid>
          </Grid>
          {!isClickedCompassOutSide && (
            <Grid
              item
              xs={12}
              ref={this.controlRef}
              class={`compass-setting-control-container ${
                isBottomControl ? "margin-top-160px" : "margin-top-40px"
              }    `}
            >
              <Grid container spacing={3} direction="row">
                <Grid
                  item
                  xs={6}
                  className={"text-left pd-0px"}
                  onClick={e => e.stopPropagation()}
                >
                  <RemoveCircleIcon
                    onClick={this.zoomOut}
                    className={`compass-setting-button-icon${
                      size === -2 ? "-disable" : ""
                    }`}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={"text-right pd-0px"}
                  onClick={e => e.stopPropagation()}
                >
                  <AddCircleIcon
                    onClick={this.zoomIn}
                    className={`compass-setting-button-icon${
                      size === 1 ? "-disable" : ""
                    }`}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {!isClickedCompassOutSide && (
            <Grid
              item
              xs={12}
              ref={this.RotationRef}
              class={`compass-setting-control-container ${
                !isBottomControl ? "margin-top-160px" : "margin-top-40px"
              }    `}
            >
              <Grid container spacing={3} direction="row">
                <Grid item xs={12} className={"text-center pd-0px"}>
                  <p className={"compass-setting-direction-label"}>
                    {Rotation}
                    <sup>o</sup>
                  </p>
                </Grid>
              </Grid>
            </Grid>
          )}
          {isShowButtonSave && (
            <Grid item xs={12} class={`compass-setting-save-button`}>
              <Button
                ref={this.saveCompassButtonRef}
                size="small"
                variant="text"
                onClick={this.btnSaveClick}
                className={`button-add add-point-enable`}
              >
                <label style={{ color: "inherit" }}>決定</label>
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid
          ref={this.compassViewContainerRef} // Draggable object
          id={"compass-setting-container"}
          container
          onTouchStart={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
          direction="row"
          className={`compass-setting-container 
                    compass-setting-viewer-container-normalsize 
                    ${
                      isCompassSetting || isNullDirection ? "display-none" : ""
                    }`}
        >
          <Grid item xs={12} ref={input => (this.inputElement = input)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={5}
              ref={this.compassViewRef} // rotation object
              class={`compass-setting-viewer-rotation-container-normalsize`}
            >
              <Grid
                item
                xs
                className={`text-center compass-setting-icon-container`}
              >
                <img
                  className={`compass-setting-icon compass-setting-viewer-icon-normalsize`}
                  src={CompassImage}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <CompassSettingConfirmSP
          open={isShowDialogConfirmDirection}
          onClose={toggleConfirmDirectionDialog}
          title="方位情報の設定先を選択する"
          textButton1="他の図面にも反映"
          onClickButton1={toggleConfirmDirectionSaveDialog}
          textButton2="この図面のみに反映"
          onClickButton2={toggleConfirmCurrentDrawingDialog}
          disabledButton2={false}
          PaperProps={{
            className:
              "dialog dialog-sign-up map-option-dialog compass-setting-confirm-dialog-container"
          }}
        />
        <CompassSettingConfirmDialog
          open={isConfirmCurrentDrawingDialog}
          onClose={toggleConfirmCurrentDrawingDialog}
          title="方位情報を設定します"
          textButton1="Yes"
          onClickButton1={this.saveCurrentDrawing}
          textButton2="No"
          onClickButton2={toggleConfirmCurrentDrawingDialog}
          disabledButton2={false}
          PaperProps={{
            className:
              "dialog dialog-sign-up map-option-dialog compass-setting-dialog-container"
          }}
        />
        <CompassSettingConfirmDialog
          open={isConfirmListDrawingDialog}
          onClose={toggleConfirmListDrawingDialog}
          title="方位情報を設定します"
          textButton1="Yes"
          onClickButton1={this.saveListDrawing}
          textButton2="No"
          onClickButton2={toggleConfirmListDrawingDialog}
          disabledButton2={false}
          PaperProps={{
            className:
              "dialog dialog-sign-up map-option-dialog compass-setting-dialog-container"
          }}
        />
        {isShowDialogConfirmSaveDirection && (
          <CompassSettingSaveConfirmSP
            open={isShowDialogConfirmSaveDirection}
            onClose={toggleConfirmDirectionSaveDialog}
            title="他の図面に方位情報を反映する"
            textButton1="全ての図面に反映する"
            onClickButton1={this.CheckAllDrawing}
            textButton2="決定"
            onClickButton2={toggleConfirmListDrawingDialog}
            disabledButton2={false}
            isPortrait={screenOrientation === "portrait"}
            PaperProps={{
              className:
                "dialog dialog-sign-up map-option-dialog compass-setting-confirm-dialog-container-save-sp"
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  type: state.rootSitesReducer.siteInfoReducer.type
});

export default connect(mapStateToProps, {
  compassSettingDirection
})(withRouter(CompassSettingSP));
