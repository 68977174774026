import React, { Component } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import CloseIcon from '@material-ui/icons/Close';
import Grid from "@material-ui/core/Grid";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Field } from "formik";
import InputControl from "../InputControl";
import {
  getRobotInfoEdit,
  getType
} from "../../components/robots/robotDetails/RobotInfo/reducer/reducer";
import {
  updateRobotInfo,
  fetchRobotInfoEdit
} from "../../components/robots/robotDetails/RobotInfo/service/service";
import {
  FETCH_ROBOT_INFO_EDIT_SUCCESS,
  PUT_ROBOT_INFO_STARTED,
  PUT_ROBOT_INFO_SUCCESS
} from "../../components/robots/robotDetails/RobotInfo/constant/constant";
import { dispatchError } from "../../common/Stores/service/service";

class DialogRobotEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      robotInfoEdit: {
        uniqueCode: "",
        robotName: ""
      },
      error: {}
    };
  }

  componentWillMount() {
    this.props.dispatchError(null);
    this.props.fetchRobotInfoEdit(this.props.uniqueCode);
  }

  componentWillReceiveProps(nextProps) {
    switch (nextProps.type) {
      case FETCH_ROBOT_INFO_EDIT_SUCCESS:
        this.setState({
          robotInfoEdit: nextProps.robotInfoEdit
        });
        break;
      case PUT_ROBOT_INFO_SUCCESS:
        this.props.onSuccess();
        this.props.onClose();
        break;
      case PUT_ROBOT_INFO_STARTED:
        this.setState({
          error: nextProps.error
        });
        break;
    }
  }

  render() {
    const onEditSubmit = values => {
      this.props.updateRobotInfo(values);
    };

    const initialValues = {
      ...this.state.robotInfoEdit
    };

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onEditSubmit}
      >
        {formik => (
          <Dialog
            open={this.props.open}
            onClose={()=>{}}
            aria-labelledby="max-width-dialog-title"
            fullWidth
            maxWidth="lg"
            PaperProps={{
              className: "dialog dialog-sign-up dialog-min-height"
            }}
            onKeyPress={event => {
              if (event.key === "Enter") {
                formik.handleSubmit();
              }
            }}
          >
            <Fab
              onClick={this.props.onClose}
              size="small"
              className="white-text close-button"
            >
              <CloseIcon />
            </Fab>

            <DialogTitle disableTypography>
              <Typography variant="h4" className="dialog-title">
                ロボット情報編集
              </Typography>
            </DialogTitle>
            <DialogContent className="content-wrap">
              <Field
                label={
                  <Typography variant="subtitle1" className="label">
                    ロボット名
                    <span className="required">[必須]</span>
                  </Typography>
                }
                name="robotName"
                onChange={formik.handleChange}
                variant="filled"
                className="input-reset"
                component={InputControl}
              />
              {this.state.error && this.state.error.robotName && (
                <span className="error">{this.state.error.robotName}</span>
              )}
            </DialogContent>
            <DialogActions className="dialog-action wrap">
              <Button
                onClick={formik.handleSubmit}
                color="primary"
                className="dialog-button"
              >
                登録
              </Button>              
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    );
  }
}

DialogRobotEdit.propTypes = {
  uniqueCode: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClickDelete: PropTypes.func
};

const mapStateToProps = state => ({
  robotInfoEdit: getRobotInfoEdit(
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailInfoReducer
      .robotInfoEdit
  ),
  type: getType(
    state.rootRobotsReducer.rootRobotDetailsReducer.robotDetailInfoReducer.type
  ),
  error: state.storesReducer.error
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchError,
      fetchRobotInfoEdit,
      updateRobotInfo
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DialogRobotEdit);
